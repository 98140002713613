import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSRadio } from "Components/RSInputs";
import {
  CONNECT_CRM,
  FRIENDLY_NAME_EXIST,
  SAVE_AUDIENCE_BY_RDS,
  UPLOAD_COLUMN,
} from "Helper/Constants/endPoint";
import {
  FRIENDLY_NAME,
  PASS_WORD,
  REDIRECTION_URL,
  USER_NAME,
  URL,
  IP_ADDRESS,
} from "Helper/Constants/validationMessage";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { isEmpty, isValidWebsite, validateIPAddress } from "Helper/Utils/Utils";
import { active } from "d3";
import { addEvent } from "highcharts";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConnectionType from "./ConnectionType";
import { RSDropdownList } from "Components/RSDropdowns";
import DedupeSettingModal from "./DedupeSettingModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DynamicCRM = (props) => {
  const context = useContext(authContext);
  // const UPDATED_CYCLE = [
  //   { type: "--Select--", typeId: 0 },
  //   { type: "Immediate", typeId: 1 },
  //   { type: "15 Minutes", typeId: 2 },
  //   { type: "30 Minutes", typeId: 3 },
  //   { type: "Hourly", typeId: 4 },
  //   { type: "Daily", typeId: 5 },
  // ];
  const UPDATED_CYCLE = ["Daily", "Week", "Month"];
  const CHECK_UPDATE = ["Modified date & time"];
  // const CHECK_UPDATE = [
  //   { type: "--Select--", typeId: 0 },
  //   { type: "Modified date & time", typeId: 1, value: "Modified date & time" },
  // ];
  const [friendlyName, setFriendlyName] = useState("");
  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [errMsgFriendlyName, setErrFriendlyName] = useState(null);
  const [errMsgUserName, setErrUserName] = useState(null);
  const [errMsgPassword, setErrPassword] = useState(null);
  const [errURL, setErrURL] = useState(null);
  const [connection, setConnection] = useState(false);
  const [dedupModalFlag, setDedupModalFlag] = useState(false);
  const [importPreference, setImportPreference] = useState("");
  const [updateCycle, setUpdateCycle] = useState(null);
  const [checkForUpdate, setCheckForUpdate] = useState(null);
  const [tableName, setTableName] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const validateForm = () => {
    let error = false;
    if (isEmpty(friendlyName)) {
      setErrFriendlyName(FRIENDLY_NAME);
      error = true;
    }
    if (isEmpty(url)) {
      setErrURL(URL);
      error = true;
    }
    if (isEmpty(userName)) {
      setErrUserName(USER_NAME);
      error = true;
    }
    if (isEmpty(userPassword)) {
      setErrPassword(PASS_WORD);
      error = true;
    }

    return error;
  };

  const handleConnect = () => {
    let valid = !validateForm();
    if (valid) {
      let params = {
        url: url,
        username: userName,
        password: userPassword,
      };
      context.globalStateData.setIsLoadingData(true);
      connectServer({
        path: CONNECT_CRM,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setConnection(true);
          }
        },
        fail: (err) => { },
      });
    }
  };

  const handleFriendlyName = (text) => {
    if (text == "" || text == null) {
      setFriendlyName(text);
      setErrFriendlyName(FRIENDLY_NAME);
      return;
    } else {
      let params = {
        friendlyname: text,
      };
      context.globalStateData.setIsLoadingData(true);
      connectServer({
        path: FRIENDLY_NAME_EXIST,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setFriendlyName(text);
            setErrFriendlyName(null);
          } else {
            setFriendlyName(text);
            setErrFriendlyName("FriendlyName already exists");
          }
        },
        fail: (err) => { },
      });
    }
  };

  const [columnName, setColumnName] = useState("");
  const getColumnVal = (data) => {
    let dummyData = data.map((e) => {
      return e.name;
    });
    setColumnName(dummyData);
    return data;
  };
  const history = useHistory();
  const handleUpload = () => {


    let params = {
      IPAddress: "",
      UserName: userName,
      Password: userPassword,
      FileLocation: url,
      DBName: "",
      TableName: tableName,
      CreatedBy: "1452",
      DepartmentID: 0,
      FriendlyName: friendlyName,
      Columname: columnName.join(","),
      ScheduleFrequency: "2",
      UpdateDatecolumn: checkForUpdate,
      ConnectionType: "C",
      RemotesettingID: 0,
      RemoteDataSourceID: 28,
      IsUpdated: true,
    };
    context.globalStateData.setIsLoadingData(true);
    connectServer({
      path: UPLOAD_COLUMN,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          history.push({
            pathname: "/preferences/add-import-audience",
            state: {
              params: {
                url: url,
                username: userName,
                password: userPassword,
                tablename: tableName,
                columns: columnName.join(","),
              },
              mode: "crm",
              remoteSettingId: res?.data,
            },
          });
        } else {
        }
      },
      fail: (err) => { },
    });
  };

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        <div className="header-title d-flex justify-content-between">
          <h1>Data exchange </h1>
        </div>
        <div className="portlet-box-theme">
          <Row className="even-del mb20">
            <Col className="text-start">
              <label className="label-row">Microsoft Dynamics CRM</label>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <RSInput2
                ph="Friendly name"
                name="friendlyName"
                val={friendlyName}
                disabled={connection}
                required={true}
                max={50}
                cb={(text) => {
                  let errMsg = text.trim() !== "" ? null : FRIENDLY_NAME;
                  setFriendlyName(text);
                  setErrFriendlyName(errMsg);
                }}
                ob={(text) => {
                  handleFriendlyName(text);
                }}
                errorMessage={errMsgFriendlyName}
              />
            </Col>
            <Col md={3}>
              <RSInput2
                ph="URL"
                name="url"
                disabled={connection}
                val={url}
                required={true}
                cb={(text) => {
                  setUrl(text);
                  let errMsg = text ? null : URL;
                  setErrURL(errMsg);
                }}
                ob={(text) => {
                  if (text.length > 0) {
                    if (isValidWebsite(text)) {
                      setUrl(text);
                    } else {
                      setErrURL(URL);
                    }
                  } else {
                    setErrURL(URL);
                  }
                }}
                errorMessage={errURL}
              />
            </Col>
            <Col md={3}>
              <RSInput2
                ph="User name"
                required={true}
                disabled={connection}
                name="userName"
                val={userName}
                max={100}
                cb={(text) => {
                  let errMsg = text.trim() !== "" ? null : USER_NAME;
                  setUserName(text);
                  setErrUserName(errMsg);
                }}
                errorMessage={errMsgUserName}
              />
            </Col>
            <Col md={3}>
              <RSInput2
                ph="Password"
                disabled={connection}
                name="userPassword"
                val={userPassword}
                type="password"
                required={true}
                max={20}
                cb={(text) => {
                  let errMsg = text.trim() !== "" ? null : PASS_WORD;
                  setPassword(text);
                  setErrPassword(errMsg);
                }}
                errorMessage={errMsgPassword}
              />
            </Col>
          </Row>

          <div className="btn-container d-flex justify-content-end">
            <RSSecondaryBtn onClick={() => { }}>Cancel</RSSecondaryBtn>
            <RSPrimaryBtn
              className={connection ? "click-off" : ""}
              onClick={handleConnect}
            >
              Connect
            </RSPrimaryBtn>
          </div>

          {connection && (
            <ConnectionType
              payload={{ url, username: userName, password: userPassword }}
              setTableName={setTableName}
              getColumnVal={getColumnVal}
            />
          )}
        </div>
        {connection && (
          <div className="my15">
            <Row>
              <div className="d-flex align-items-center my15">
                <h4 className="mb0">Import preferences</h4>
                <i
                  className={"icon-settings-large icon-lg blue cp ml10"}
                  onClick={() => {
                    setDedupModalFlag(!dedupModalFlag);
                  }}
                ></i>
              </div>
              <div className="mb5">
                <Row>
                  <div className="position-relative">
                    <RSRadio
                      name="importPreference"
                      lbl="Upload new data - if a match is found, overwrite the older record"
                      className="marginB5"
                      labelId={"ip1"}
                      cb={(event) => {
                        setImportPreference(event.target.value);
                      }}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="position-relative">
                    <RSRadio
                      name="importPreference"
                      lbl="Preserve older data - if a match is found, reject the recent record"
                      className="marginB5"
                      labelId={"ip2"}
                      cb={(event) => {
                        setImportPreference(event.target.value);
                      }}
                    />
                  </div>
                </Row>
              </div>

              <Row className="mt10">
                <Col md={4}>
                  <Row>
                    <Col md={4}>
                      <label className="label-row">Update cycle</label>
                    </Col>
                    <Col md={7}>
                      <RSDropdownList
                        name="updatedCycle"
                        data={UPDATED_CYCLE}
                        defaultItem={"--Select--"}
                        onChange={(e) => {
                          setUpdateCycle(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <label className="label-row">Select the field to check for updates</label>
                    </Col>
                    <Col md={5}>
                      <RSDropdownList
                        name="checkUpdate"
                        data={CHECK_UPDATE}
                        onChange={(e) => {
                          setCheckForUpdate(e.target.value);
                        }}
                        defaultItem={"--Select--"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div
                className={`btn-container d-flex justify-content-end mb20 mr15 ${columnName.length == 0 ? "click-off" : ""
                  } `}
              >
                <RSSecondaryBtn onClick={() => { }}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn className={""} onClick={handleUpload}>
                  Upload
                </RSPrimaryBtn>
              </div>
            </Row>
          </div>
        )}
        {dedupModalFlag && (
          <>
            <DedupeSettingModal
              callBack={(value) => {
                setDedupModalFlag(false);
              }}
              isShowModal={dedupModalFlag}
              title="Configure dedupe input settings"
              PrimaryBtn="Save"
              SecondaryBtn="Cancel"
            />
          </>
        )}
      </Container>
    </LayoutPageWrapper>
  );
};

export default DynamicCRM;
