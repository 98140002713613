import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import RESEditor from 'Components/Editor/RESEditor';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox } from 'Components/RSInputs';
import { CAMPAIGN_TEXT, SCHEDULE_DATE, SELECT_TIMEZONE } from 'Helper/Constants/validationMessage';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { CustomPopup } from 'Components/DateRangePicker/ScheduleCustomPopup';
import { withRouter } from 'react-router-dom';
import { addMinutes, getLsMasterDataDropdown, getUserData } from 'Helper/Utils/Utils';
import { GET_SHORTEN_URL } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';
import Nperview from '../../Components/NPreview';
import { RSPTooltip } from 'Components/Tooltip';
import moment from 'moment';
const approverList = [
    "William(swil****@vi**onb**k.com",
    "Peter(rpe***r@v**ionb***.c**m)",
    "Catherine(cath***@v**onb****.com"];

const MobileSMSSplitContent = props => {

    const context = useContext(authContext)

    const [campaignText, setCampaignText] = useState(props.splitSMSData.previewBody);
    const [previewCampaignText, setPreviewCampaignText] = useState(props.splitSMSData.body);
    const [shortenLink, setShortenLink] = useState("");
    const [smartUrls, setSmartUrls] = useState([]);
    const [errCampaignText, setErrCampaignTextMsg] = useState(props.splitContentError.errBodyMsg);
    const [errTimeZoneMsg, setErrTimeZoneMsg] = useState(props.splitContentError.errTimeZoneMsg);
    const [isEditTimeZone, setIsEditTimeZone] = useState(false);
    const [timeZone, setTimeZone] = useState("");
    const [timeZoneData, setTimeZoneData] = useState([]);
    const [isDayLightSavings, setIsDayLightSavings] = useState(false);
    const [isSendRecommendation, setIsSendRecommendation] = useState(false);
    const [schedule, setSchedule] = useState(props.mobileSMSData.isSendTestSMS !== 2 ? props.splitSMSData.localBlastDateTime : "");
    const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(props.splitContentError.errScheduleDateMsg);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [bodyTextCount, setBodyTextCount] = useState("");
    const [smsCount, setSMSCount] = useState("");

    useEffect(() => {
        getTimeZoneList();
        setErrCampaignTextMsg(props.splitContentError.errBodyMsg);
        setErrScheduleDateMsg(props.splitContentError.errScheduleDateMsg);
        setErrTimeZoneMsg(props.splitContentError.errTimeZoneMsg);
        if (props.history.location && props.history.location.state) {
            const campaignHeader = props.history.location.state.campaignHeader
            setStartDate(new Date(getStartDate(campaignHeader.startDate)))
            setEndDate(new Date(campaignHeader.endDate))
        }
    }, [])

    const getStartDate = (startDate) => {
        let campaignStartDate = new Date(startDate);
        let today = new Date();
        if (campaignStartDate > today) {
            campaignStartDate = new Date(startDate);
        } else {
            campaignStartDate = new Date();
        }
        return campaignStartDate
    }

    const getTimeZoneList = () => {
        const data = getLsMasterDataDropdown();
        const loggedDetails = getUserData();
        let filterTimezone = null;
        if (props.mode === "add") {
            filterTimezone = data.timeZone.filter((item) => item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId)[0];
        } else {
            filterTimezone = data.timeZone.filter((item) => item.timezoneId === props.splitSMSData.timezoneId)[0];
        }
        setTimeZoneData(data.timeZone);
        setTimeZone(filterTimezone)
    }

    return (
        <div>
            <Row className="custom-column marginB20">
                <Col md={3}>
                    <label className="label-row">Communication text</label>
                </Col>
                <Col md={6}>
                    <div className="position-relative">
                        {errCampaignText && (
                            <div className="validation-message yaxis-7">
                                {errCampaignText}
                            </div>
                        )}
                        <RESEditor
                            personalizeTapData={props.personalizeTapData}
                            mobilepush={false}
                            isCampaignText={true}
                            isWhatsappCampaignText={false}
                            bodyContent={props.splitSMSData.previewBody}
                            callBack={(key, item) => {
                                if (key === "bodytext") {
                                    let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                                    // props.splitSMSData.body = item.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                                    let textCount = item.length > 0 ? item.length.toString() + "/" : "";
                                    let smsCount = Math.floor(item.length / 140) + 1;
                                    setBodyTextCount(textCount);
                                    setSMSCount(smsCount.toString());
                                    props.splitSMSData.body = item;
                                    props.splitSMSData.previewBody = item;
                                    props.splitContentError.errBodyMsg = errMsg;
                                    setErrCampaignTextMsg(errMsg);
                                    setCampaignText(item);
                                    setPreviewCampaignText(item);
                                    if (smartUrls.length > 0 && shortenLink.length > 0) {
                                        let tempVal = item.replaceAll(smartUrls[0], shortenLink)
                                        setPreviewCampaignText(tempVal);
                                        props.splitSMSData.body = tempVal;
                                    }
                                } else if (key === "onBlur") {
                                    props.splitSMSData.bodyPosition = item.target.selectionStart;
                                }
                            }} />
                    </div>
                    <RSPTooltip text="Shortern URL" position="top">
                        <i className={`icons-sm icon-link-down-medium icons-md blue float-end cursor-pointer${props.splitSMSData.previewBody.includes("https") ? "" : "click-off"}`}
                            onClick={() => {
                                var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
                                let smartURLs = props.splitSMSData.previewBody.match(urlRegex);
                                let errMsg = smartURLs.length > 1 ? "Accept only one URL in SMS content" : null;
                                setErrCampaignTextMsg(errMsg);
                                setSmartUrls(smartURLs);
                                let params = {
                                    "campaignId": props.campaignId,
                                    "goalNo": 1,
                                    "blastType": props.splitSMSData.splitType,
                                    "smartCode": "",
                                    // "domainUrl": DOMAIN_URL,
                                    "smartAdaptive": "",
                                    "smartUrl": smartURLs[0],
                                    "channelId": 2
                                }
                                connectServer({
                                    path: GET_SHORTEN_URL,
                                    params,
                                    loading: context.globalStateData.setIsLoadingData,
                                    sessionOut: context.globalStateData.setSessionOutData,
                                    context: context,
                                    ok: res => {
                                        if (res.status) {
                                            // let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.mobileSMSData.content[0].bodyPosition);
                                            // props.splitSMSData.body = val;
                                            let tempVal = props.splitSMSData.previewBody.replaceAll(smartURLs[0], res?.data[0]?.smartCodePath)
                                            props.splitSMSData.body = tempVal;
                                            setPreviewCampaignText(tempVal);
                                            setShortenLink(res.data[0].smartCodePath)
                                            // setCampaignText(tempVal);
                                        }
                                    },
                                    fail: err => {
                                    }
                                })
                            }}></i>
                    </RSPTooltip>
                </Col>
                <Col md={3} className="last-column-view mr-sms-split">
                    {props.splitSMSData.previewBody.length > 0 ?
                        <div className='instant-preview'>
                            <Nperview
                                body={previewCampaignText}
                                smartUrls={smartUrls}
                                shortenLink={shortenLink}
                                mobileNumber={""}
                            />

                        </div>

                        : null}
                </Col>
            </Row>
            {/* <Row className="custom-column marginB30">
                <Col md={3}>
                    <label className="label-row">Shortlink</label>
                </Col>
                <Col md={6}>
                    <RSInput2
                        ph="Enter URL"
                        className=""
                        val={smartURL}
                        cb={(text) => {
                            let errMsg = text !== "" ? isValidWebsite(text) ? null : ENTER_VALID_WEBSITE : "Enter valid URL"
                            props.splitSMSData.shortenURL = text;
                            setSmartURL(text);
                            setErrSmartURL(errMsg);
                        }}
                        errorMessage={errSmartURL}
                    />
                </Col>
                <Col md={3}>
                    <RSPrimaryBtn className={isEnableShortenButton(smartURL) ? "" : "click-off"} onClick={() => {
                        if (isEmpty(smartURL)) {
                            setErrSmartURL("Enter URL");
                            return
                        }
                        let params = {
                            "campaignId": props.history.location.state.campaignId,
                            "goalNo": 1,
                            "smartCode": "",
                            "domainUrl": "https://lcouat.mkstr.io/",
                            "smartAdaptive": "",
                            "smartUrl": smartURL,
                            "splitType": props.splitSMSData.splitType,
                            "channelId": 2
                        }
                        connectServer({
                            path: GET_SHORTEN_URL,
                            params,
                            loading: context.globalStateData.setIsLoadingData,
                            sessionOut: context.globalStateData.setSessionOutData,
                            ok: res => {
                                if (res.status) {
                                    let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.splitSMSData.bodyPosition);
                                    props.splitSMSData.body = val;
                                    setCampaignText(val);
                                }
                            },
                            fail: err => {
                            }
                        })
                    }}>Shorten URL</RSPrimaryBtn>
                </Col>
            </Row> */}
            <div className={`schedule-even marginT30`}>
                <Row className="custom-column paddingB10">
                    <Col md={3}>
                        <label className="label-row">Schedule</label>
                    </Col>
                    <Col md={6}>
                        <div className="position-relative">
                            {errScheduleDateMsg && (
                                <div className='validation-message'>
                                    {errScheduleDateMsg}
                                </div>
                            )}
                            <div className="rsptimer-picker">
                                <DateTimePicker
                                    className="required"
                                    placeholder='Select schedule date and time'
                                    value={schedule !== "" ? new Date(schedule) : ""}
                                    // show={true}
                                    // min={startDate}
                                    // min={addMinutes(new Date(startDate), 15)}
                                    min={new Date(startDate).getDate() === new Date().getDate() ? addMinutes(new Date(startDate), 15) : startDate}
                                    max={endDate}
                                    format={"dd MMM, yyyy HH:mm"}
                                    popup={CustomPopup}
                                    onChange={(event) => {
                                        setSchedule(event.target.value);
                                        let localBlastTime = moment(event.target.value).format(getUserData()?.timeFormat === "24 hours" ? "DD MMM, yyyy HH:mm " : "DD MMM, yyyy hh:mm a")
                                        props.splitSMSData.localBlastDateTime = localBlastTime;
                                        let errMsg = props.splitSMSData.localBlastDateTime !== "" ? null : SCHEDULE_DATE
                                        props.splitContentError.errScheduleDateMsg = errMsg;
                                        setErrScheduleDateMsg(errMsg)
                                    }}
                                />
                            </div>
                        </div>
                        <Row className="marginT5">
                            {/* <Col md="8">
                            <div className="d-flex align-items-center marginB30">
                                <RSCheckbox
                                    className="marginB0"
                                    lbl="Use send time recommendation"
                                    checked={isSendRecommendation}
                                    cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                                />
                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                            </div>
                        </Col> */}
                            {isEditTimeZone === false ?
                                <Col>
                                    <div className="float-end">
                                        <label>{timeZone.gmtOffset}</label>
                                        <RSPTooltip text="Edit" position="top" >
                                            <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                                                setIsEditTimeZone(!isEditTimeZone);
                                            }} />
                                        </RSPTooltip>
                                    </div>
                                </Col> : null}
                        </Row>
                    </Col>
                </Row>
                {
                    isEditTimeZone
                        ? <Row className="custom-column paddingT20">
                            <Col md={3}>
                                <label className="label-row">Select time zone</label>
                            </Col>
                            <Col md={6}>
                                <div className="position-relative">
                                    {errTimeZoneMsg && (
                                        <div className='validation-message'>
                                            {errTimeZoneMsg}
                                        </div>
                                    )}
                                    <RSDropdownList
                                        className="marginB0"
                                        data={timeZoneData}
                                        value={timeZone}
                                        defaultItem={{
                                            "timezoneId": 0,
                                            "timezoneName": "-- Select time zone --",
                                        }}
                                        textField="timezoneName"
                                        dataItemKey="timezoneId"
                                        onChange={(e) => {
                                            let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                                            props.splitContentError.errTimeZoneMsg = errMsg;
                                            setErrTimeZoneMsg(errMsg);
                                            setTimeZone(e.target.value);
                                            props.splitSMSData.timezoneId = e.target.value.timezoneId;
                                        }} />
                                </div>
                                <Row className="marginT5">
                                    <Col md="8">
                                        <div className="d-flex align-items-center">
                                            <RSCheckbox
                                                className="marginT0 click-off"
                                                lbl="Daylight saving"
                                                checked={isDayLightSavings}
                                                cb={(value) => { setIsDayLightSavings(!isDayLightSavings) }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        : null
                }
            </div >
        </div >
    );
}
export default withRouter(MobileSMSSplitContent);