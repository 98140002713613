import React from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { GET_WHATSUP_LIST, DELETE_WHATSUP } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";

const dataState = {
  skip: 0,
  take: 5,
};

class WhatsappList extends React.Component {

  static contextType = authContext;

  state = {
    dataResult: null, //process(this.tempList, dataState),
    dataState: dataState,
    whatsappList: [],
    listError: false,
    index: 0,
    mode: "add",
  };

  componentDidMount = () => {
    this.getDataFromServerNew();
  };

  getDataFromServerNew = (params) => {
    this.setState({ listError: false });
    connectServer({
      path: GET_WHATSUP_LIST,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status && res.data?.length) {
          let newTemp = [...res.data];
          let some = process(newTemp, dataState);
          this.setState({
            whatsappList: res.data,
            dataResult: Array.prototype.reverse.call(some),
          });
        } else {
          this.setState({ listError: true });
        }
      },
      fail: (err) => {
        this.setState({ listError: true });
      },
    });
  };

  dataStateChange = (event) => {
    this.setState({
      dataResult: process(this.state.whatsappList, event.dataState),
      dataState: event.dataState,
    });
  };

  handleChange = (fields, value) => {
    let status = value ? "A" : "D";
    let clientId = fields.clientWASettingId;
    fields.status = value;
    let params = {
      clientWASettingId: clientId,
      status: status,
    };
    connectServer({
      path: DELETE_WHATSUP,
      params: params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          this.getDataFromServerNew();
        }
      },
      fail: (err) => { },
    });
  };

  render() {
    return (
      <Container className="p0 mt15">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <h3 className="marginB0">WhatsApp</h3>
          <i
            className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer"
            onClick={() => {
              this.setState({ mode: "add", index: 1 });
              this.props.onChangeIndex(1);
              this.props.onChangeMode("add");
            }}
          ></i>
        </div>
        {this.state.dataResult ? (
          <Grid
            data={this.state.dataResult}
            {...this.state.dataState}
            onDataStateChange={this.dataStateChange}
            scrollable={"none"}
            sortable={false}
            className="grid-pagenation-del grid-replaceable"
            messages={{
              itemsPerPage: "data items per page",
            }}
            onPageChange={this.pageChange}
            pageable={{
              //type: 'input',
              pageSizes: [5, 10, 20],
              previousNext: true,
            }}
          >
            <GridColumn title="Service provider" field="serviceProvider" />
            <GridColumn title="Sender ID" field="senderId" />
            <GridColumn title="Keywords" field="keywords" />
            <GridColumn title="Template name" field="templateName" />
            <GridColumn
              title="Action"
              cell={(props) => {
                return (
                  <td className="camp-icon">
                    {props.dataItem.status ? (
                      <i
                        className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                        onClick={() => {
                          this.setState({ mode: "edit", index: 1 });
                          this.props.onChangeIndex(1);
                          this.props.getClientWaSettingId(
                            props.dataItem.clientWASettingId
                          );
                          this.props.onChangeMode("edit");
                        }}
                      />
                    ) : null}
                  </td>
                );
              }}
            />
            <GridColumn
              title="Status"
              cell={(props) => {
                return (
                  <td>
                    <div>
                      <Switch
                        className="mt0"
                        onChange={(e) =>
                          this.handleChange(props.dataItem, e.target.value)
                        }
                        checked={props.dataItem.status ? true : false}
                      />
                    </div>
                  </td>
                );
              }}
            />
          </Grid>
        ) : (
          <SkeletonTable count={5} isError={this.state.listError} />
        )}
      </Container>
    );
  }
}
export default WhatsappList;
