import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons"
import { RSDropdownList, RSPhoneInput } from "Components/RSDropdowns"
import { RSCheckbox, RSInput2, RSTextInputWithIcon } from "Components/RSInputs"
import { ADD_NEW_USER, GET_USER_PROFILE_DETAILS, IS_EMAIL_EXIST } from "Helper/Constants/endPoint"
import {
    ENTER_FIRST_NAME, ENTER_LAST_NAME, ENTER_FIRST_NAME_3CHARS,
    ENTER_PASSWORD, ENTER_PHONE, ENTER_VALID_PASSWORD, SELECT_JOBFUNCTION, ENTER_VALID_EMAIL,
    SELECT_TITLE,
    SPECIAL_NUM_CHARACTERS
} from "Helper/Constants/validationMessage"
import { connectServer } from "Helper/Network/networkHandler"
import { useHistory } from "react-router"
import authContext from "Helper/StateHandler/auth-context"
import {
    isEmpty, validateEmail, validatePassword, passwordStrengthMeter,
    getAuthClientId, getLsMasterDataDropdown
} from "Helper/Utils/Utils"
import React, { useContext, useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { RSPPophover } from "Components/Tooltip"


export const AddNewUser = props => {

    const context = useContext(authContext)
    const history = useHistory()

    const [isEmailExist, setIsEmailExist] = useState(false)
    const [emailId, setEmailId] = useState("")
    const [statusItem, setStatusItem] = useState({
        titleId: 1,
        title: 'Mr.'
    })
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [countryCode, setCountryCode] = useState("in")
    const [countryCodeMobile, setCountryCodeMobile] = useState("+91")
    const [password, setPassword] = useState("")
    const [randomPassword, setRandomPassword] = useState("")
    const [jobFunctionItem, setJobFunctionItem] = useState({
        jobfunctionId: -1,
        jobfunctionName: '-- Job function --'
    })
    const [jobFunctionData, setJobFunctionData] = useState([])
    const [message, setMessage] = useState("")
    const [generateOTP, setGenerateOTP] = useState(false)
    const [generatePassword, setGeneratePassword] = useState(false)

    const [errMsgEmailId, setErrMsgEmailId] = useState("")
    const [errMsgFirstName, setErrMsgFirstName] = useState("")
    const [errMsgLastName, setErrMsgLastName] = useState("")
    const [errMsgPhoneNo, setErrMsgPhoneNo] = useState("")
    const [errMsgPassword, setErrMsgPassword] = useState("")
    const [errMsgJobFunction, setErrMsgJobFunction] = useState("")
    const [errMsgWelcome, setErrMsgWelcome] = useState("");
    const [errMsgStatus, setErrMsgStatus] = useState("")
    const [pwdStrengthMeter, setPwdStrengthMeter] = useState(0)

    useEffect(() => {

        const masterData = getLsMasterDataDropdown()
        setJobFunctionData(masterData.jobFunction)
        if (props.userId !== undefined && props.userId !== 0) {
            getUserProfileDataFromServer(props.userId)
        }
    }, [context.globalStateData.getIsReloadData]);

    const getUserProfileDataFromServer = (userId) => {

        const params = { userId: userId }

        connectServer({
            path: GET_USER_PROFILE_DETAILS,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setState(res.data)
                }
            },
            fail: err => {
            }
        })
    }

    const setState = (data) => {
        const masterData = getLsMasterDataDropdown()
        setPassword("=AX1Z$B+OF81")
        setPhoneNo(data.phoneNo || '')
        setCountryCode(data.countryCode || '')
        setCountryCodeMobile(data.countryCodeMobile || '')
        setEmailId(data.email || '')
        setIsEmailExist(false)
        setFirstName(data.firstName || '')
        setLastName(data.lastName || '')
        setGenerateOTP(data.otpenableforclient || false)
        setMessage(data.welcomeText || "")
        // setRole(data.securityGroupName || '')
        let jobArray = masterData.jobFunction.filter(obj => obj.jobfunctionId === data.jobFunctionId)
        if (jobArray.length > 0) {
            setJobFunctionItem(jobArray[0])
        }
        let statusArray = status.filter(obj => obj.title === data.title)
        if (statusArray.length > 0) {
            setStatusItem(statusArray[0])
        }
    }

    const generateRandomPwd = () => {
        let length = 12
        const lowerCharset = "abcdefghijklmnopqrstuvwxyz";
        const upperCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numberCharset = "0123456789";
        const symbolCharset = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
        const allCharset = lowerCharset + upperCharset + numberCharset + symbolCharset;
        let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
        randomNumber = randomNumber / 0x100000000;
        // Ensure at least one character from each category
        let password = [
            lowerCharset[Math.floor(randomNumber * lowerCharset.length)],
            upperCharset[Math.floor(randomNumber * upperCharset.length)],
            numberCharset[Math.floor(randomNumber * numberCharset.length)],
            symbolCharset[Math.floor(randomNumber * symbolCharset.length)],
        ];

        // Fill the rest of the password length with random characters
        for (let i = 4; i < length; i++) {
            randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
            randomNumber = randomNumber / 0x100000000;
            password.push(allCharset[Math.floor(randomNumber * allCharset.length)]);
        }

        // Shuffle the password to ensure randomness
        password = password.sort(() => 0.5 - randomNumber).join('');
        setPwdStrengthMeter(passwordStrengthMeter(password))
        setRandomPassword(password)
        return password
    }

    const checkEmailExistInServer = () => {
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            path: IS_EMAIL_EXIST,
            params: { emailId: emailId },
            retry: 2,
            retryTime: 5000,
            ok: data => {
                setIsEmailExist(data.status)
                if (data.status) {
                    setErrMsgEmailId("Email ID already exists")
                }
            },
            fail: err => {
                setErrMsgEmailId("Unexpected happened. Try again later")
            }
        })
    }

    return <div className="portlet-box-theme settings-container s">
        <Row>
            <Col md={8}>

                {/* Row 1 */}
                <Row>
                    <Col md={2}>
                        <div className="position-relative">
                            {errMsgStatus && <div className="validation-message">{errMsgStatus}</div>}
                            <RSDropdownList
                                className='required'
                                data={status}
                                value={statusItem}
                                defaultItem={{
                                    titleId: 0,
                                    title: "-- Title --"
                                }}
                                textField='title'
                                dataItemKey='titleId'
                                // tabIndex={1}
                                onChange={(e) => {
                                    let errMsg = e.target.value.statusId !== 0 ? null : SELECT_TITLE
                                    setErrMsgStatus(errMsg)
                                    setStatusItem(e.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <RSInput2
                            name="firstName"
                            ph="Enter first name"
                            val={firstName}
                            required={true}
                            errorMessage={errMsgFirstName}
                            // tabIndex={2}
                            max={150}
                            cb={text => {
                                if (text.length > 0) {
                                    var regex = /^[a-zA-Z ]*$/;
                                    if (regex.test(text)) {
                                        setFirstName(text)
                                        setErrMsgFirstName(null)
                                    } else {
                                        setErrMsgFirstName(SPECIAL_NUM_CHARACTERS);
                                    }
                                } else {
                                    setFirstName(text);
                                    setErrMsgFirstName(ENTER_FIRST_NAME)
                                }
                            }}
                            ob={(text) => {
                                text = text.trimEnd();
                                text = text.trimStart();
                                let errMsg = isEmpty(text) ? ENTER_FIRST_NAME : text.length >= 3 ? null : ENTER_FIRST_NAME_3CHARS
                                setFirstName(text);
                                setErrMsgFirstName(errMsg)
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <RSInput2
                            name="lastName"
                            ph="Enter last name"
                            val={lastName}
                            required={true}
                            errorMessage={errMsgLastName}
                            // tabIndex={3}
                            max={150}
                            cb={text => {
                                if (text.length > 0) {
                                    var regex = /^[a-zA-Z ]*$/;
                                    if (regex.test(text)) {
                                        setLastName(text)
                                        setErrMsgLastName(null)
                                    } else {
                                        setErrMsgLastName(SPECIAL_NUM_CHARACTERS);
                                    }

                                } else {
                                    setLastName(text);
                                    setErrMsgLastName(ENTER_LAST_NAME)
                                }
                            }}
                            ob={(text) => {
                                text = text.trimEnd();
                                text = text.trimStart();
                                let errMsg = isEmpty(text) ? ENTER_LAST_NAME : null;
                                setLastName(text);
                                setErrMsgLastName(errMsg)
                            }}
                        />
                    </Col>
                </Row>

                {/* Row 2 */}
                <Row>
                    <Col md={6}>
                        <RSInput2
                            name="email address"
                            ph="Enter email address"
                            disabled={(props.userId !== undefined && props.userId !== 0) ? true : false}
                            required={true}
                            val={emailId}
                            errorMessage={errMsgEmailId}
                            // tabIndex={4}
                            max={50}
                            cb={t => {
                                let errMsg = validateEmail(t) ? null : ENTER_VALID_EMAIL
                                setEmailId(t)
                                setErrMsgEmailId(errMsg)
                                setIsEmailExist(false)
                            }}
                            ob={val => {
                                if (val.length > 0 && validateEmail(val)) {
                                    checkEmailExistInServer()
                                } else {
                                    setErrMsgEmailId(ENTER_VALID_EMAIL)
                                }
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        {errMsgPhoneNo && <div className="validation-message mobilenoError">{errMsgPhoneNo}</div>}
                        <RSPhoneInput
                            className="required"
                            country={countryCode}
                            value={countryCodeMobile + phoneNo}
                            // tabIndex={5}
                            onChange={(phone, country) => {
                                let errMsg = null;
                                let phoneNo = phone.slice(country.dialCode.length);
                                if (phoneNo.length > 0) {
                                    setPhoneNo(phoneNo)
                                } else {
                                    setCountryCode(country.countryCode)
                                    setCountryCodeMobile(country.dialCode)
                                }
                                setErrMsgPhoneNo(errMsg)
                            }}
                            onBlur={(e, country) => {
                                let _countryCode = countryCodeMobile.replaceAll("+", "")
                                let phone3 = e.target.value.replaceAll(" ", "");
                                let phone2 = phone3.replaceAll("-", "");
                                let phone = phone2.replaceAll("+", "")
                                let errMsg = phone.slice(_countryCode.length).length >= 8 ? null : ENTER_PHONE;
                                setErrMsgPhoneNo(errMsg)
                            }} />
                    </Col>
                </Row>

                {/* Row 3 */}
                <Row>
                    <Col md={6}>
                        <div className="position-relative">
                            {errMsgJobFunction && <div className="validation-message">{errMsgJobFunction}</div>}
                            <RSDropdownList
                                className='required'
                                data={jobFunctionData}
                                value={jobFunctionItem}
                                defaultItem={{
                                    jobfunctionId: -1,
                                    jobfunctionName: '-- Job function --'
                                }
                                }
                                textField='jobfunctionName'
                                dataItemKey='jobfunctionId'
                                // tabIndex={6}
                                onChange={(e) => {
                                    let errMsg = e.target.value.jobfunctionName.length > 0 ? null : SELECT_JOBFUNCTION
                                    setJobFunctionItem(e.target.value)
                                    setErrMsgJobFunction(errMsg)
                                }}
                            />
                        </div>
                    </Col>
                    <Col md={6} className={(props.userId !== undefined && props.userId !== 0) ? "click-off" : ""}>
                        <span className={`progress-bar-password bottom34 ${pwdStrengthMeter === 100
                            ? "status-good"
                            : pwdStrengthMeter === 80
                                ? "status-average"
                                : pwdStrengthMeter === 60
                                    ? "status-week"
                                    : pwdStrengthMeter === 40
                                        ? "status-low" :
                                        "status-bad"
                            }`}>
                            <i className="icon-sd-lock-medium white" />
                        </span>
                        <RSTextInputWithIcon
                            name="password"
                            type="password"
                            ph="Password"
                            className="marginB10"
                            required={true}
                            disabled={(props.userId !== undefined && props.userId !== 0) ? true : false}
                            icon={"icon-sd-lock-medium input-icon-fill input-icon"}
                            val={generatePassword ? randomPassword : password}
                            errorMessage={errMsgPassword}
                            // tabIndex={7}
                            max={15}
                            eye="mr35 grey"
                            cb={t => {
                                // if (!randomPassword.length) {
                                setPwdStrengthMeter(passwordStrengthMeter(t))
                                let errMsg = validatePassword(t) > 0 ? null : ENTER_VALID_PASSWORD
                                setPassword(t)
                                setErrMsgPassword(errMsg)
                                setGeneratePassword(false)
                                // }
                            }}
                        />
                        <div>
                            <RSCheckbox
                                className={`mb0`}
                                lbl="Generate password"
                                checked={generatePassword}
                                cb={(status) => {
                                    setGeneratePassword(status)
                                    if (status) {
                                        generateRandomPwd()
                                        setErrMsgPassword(null)
                                        setPassword("")
                                    } else {
                                        setRandomPassword('')
                                        setPwdStrengthMeter(passwordStrengthMeter(''))
                                    }
                                }}
                            />
                        </div>
                    </Col>
                </Row>

            </Col>

            {/* Welcome message textarea */}
            <Col md={4}>
                <div className="position-relative">
                    {errMsgWelcome && <div className="validation-message pt2" style={{ height: "33px", lineHeight: "15px", top: '-36px' }}>{errMsgWelcome}</div>}
                    <textarea rows={5} value={message} placeholder="Enter a message welcoming the user"
                        onChange={(event) => {
                            if (event.target.value.length <= 250) {
                                setMessage(event.target.value)
                                setErrMsgWelcome("");
                            } else {
                                setErrMsgWelcome("Welcome message's length should not more than 250")
                            }
                        }}
                        onBlur={(event) => {
                            if (event.target.value.length <= 250) {
                                setMessage(event.target.value)
                                setErrMsgWelcome("");
                            } else {
                                setErrMsgWelcome("Welcome message's length should not more than 250")
                            }
                        }}></textarea>
                </div>
                <RSCheckbox className="marginT35" lbl="Enforce one time password to login" checked={generateOTP} cb={(status) => setGenerateOTP(status)} />
                <RSPPophover
                    text="Enable this option to require users to enter a one-time password (OTP) for logging in"
                    position="top"
                >
                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp"></i>
                </RSPPophover>
            </Col>
        </Row>

        <div className="btn-container d-flex justify-content-end mt0">
            <RSSecondaryBtn onClick={() => history.push(`/preferences`)}>Cancel</RSSecondaryBtn>
            <RSPrimaryBtn onClick={() => {

                if (context.globalStateData.getIsPrototype) {
                    props.cb(2)
                    return
                }
                let isValidate = true;
                if (statusItem.titleId === 0) {
                    setErrMsgStatus(SELECT_TITLE)
                    isValidate = false;
                }
                if (isEmpty(firstName)) {
                    setErrMsgFirstName(ENTER_FIRST_NAME)
                    isValidate = false;
                } else if (firstName.length < 3) {
                    setErrMsgFirstName(ENTER_FIRST_NAME_3CHARS);
                    isValidate = false
                }
                if (isEmpty(lastName)) {
                    setErrMsgLastName(ENTER_LAST_NAME)
                    isValidate = false;
                }
                if (jobFunctionItem.jobfunctionId === -1) {
                    setErrMsgJobFunction(SELECT_JOBFUNCTION)
                    isValidate = false;
                }
                if (isEmpty(emailId)) {
                    setErrMsgEmailId(ENTER_VALID_EMAIL)
                    isValidate = false;
                }
                if (!validateEmail(emailId)) {
                    setErrMsgEmailId(ENTER_VALID_EMAIL)
                    isValidate = false;
                }
                if (isEmailExist) {
                    setErrMsgEmailId("Email ID already exists")
                    isValidate = false;
                }
                if (isEmpty(phoneNo)) {
                    setErrMsgPhoneNo(ENTER_PHONE)
                    isValidate = false;
                } else if (phoneNo.length < 8) {
                    setErrMsgPhoneNo(ENTER_PHONE)
                    isValidate = false;
                }
                if (generatePassword) {
                    if (isEmpty(randomPassword)) {
                        setErrMsgPassword(ENTER_PASSWORD)
                        isValidate = false;
                    }
                    if (!validatePassword(randomPassword)) {
                        setErrMsgPassword(ENTER_VALID_PASSWORD)
                        isValidate = false;
                    } else {
                        setErrMsgPassword(null)
                    }
                } else {
                    if (props.userId !== undefined && props.userId === 0) {
                        if (isEmpty(password)) {
                            setErrMsgPassword(ENTER_PASSWORD)
                            isValidate = false;
                        }
                        if (!validatePassword(password)) {
                            setErrMsgPassword(ENTER_VALID_PASSWORD)
                            isValidate = false;
                        }
                    }
                }
                if (isValidate) {
                    var params = {
                        userId: (props.userId !== undefined && props.userId !== 0) ? props.userId : 0,
                        clientId: getAuthClientId(),
                        firstName: firstName,
                        lastName: lastName,
                        email: emailId,
                        password: generatePassword ? randomPassword : password,
                        title: statusItem.title,
                        jobFunctionId: jobFunctionItem.jobfunctionId,
                        phoneNo: phoneNo,
                        countryCode: countryCode,
                        countryCodeMobile: countryCodeMobile,
                        welcometext: message,
                        otpenableforclient: generateOTP
                    }

                    connectServer({
                        loading: context.globalStateData.setIsLoadingData,
                        path: ADD_NEW_USER,
                        params,
                        sessionOut: context.globalStateData.setSessionOutData,
                        context: context,
                        ok: res => {
                            if (res.status) {
                                props.cb(1)
                            }
                        },
                        fail: err => {
                        }
                    })
                }

            }}>{(props.userId !== undefined && props.userId !== 0) ? 'Update user' : 'Add user'}</RSPrimaryBtn>
        </div>
    </div>
}

export default AddNewUser

const status = [
    {
        titleId: 1,
        title: 'Mr.'
    },
    {
        titleId: 2,
        title: 'Mrs.'
    },
    {
        titleId: 3,
        title: 'Ms.'
    }
]