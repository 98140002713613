import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap"
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { withRouter } from 'react-router-dom';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/custom/world.geo.json";
import NewCharts from 'Components/Charts/NewCharts';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import OverViewMap from '../Components/OverViewMap';
import jsonData from "./QR.json"
import markerImg from 'Assets/Images/maker.png'
import { GET_CAMPAIGN_CHANNEL_DETAILS, NO_MEDIA_IMAGE } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { ch_green } from 'Components/Charts/ChartColors';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { dateTimeFormatWithMomentForReports, getServerConfig, numberWithCommas } from 'Helper/Utils/Utils';
import reachImg from 'Assets/Images/detail-analytics-reach.svg'
import engagementImg from 'Assets/Images/detail-analytics-engagement.svg'
import conversionImg from 'Assets/Images/detail-analytics-conversion.svg'
import { ColumnChartSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import QRReachChart from './Components/QRReachChart';
import QREngagementChart from './Components/QREngagementChart';
import ReportsAlertPopup from '../Components/ReportsAlertPopup';
import QRTopLinks from './Components/QRTopLinks';
import QRConversionChart from './Components/QRConversionChart';


let markerIcon = markerImg;

require("highcharts/modules/map")(Highcharts);
highchartsMap(Highcharts); // Initialize the map module

class QR extends Component {

    static contextType = authContext

    state = {
        data: null,
        rowKey: "",
        tabReachIndex: 0,
        tabDataEngagement: 0,
        tabDaysIndex: 0,
        skip: 0,
        take: 10,
        selectedDropdownValue: "Split A",
        dropdownList: [
            "Split A",
            "Split B",
            "Actual communication"
        ],
        isQRSplitAb: this.props.state.isQRSplitAb,
        imagePath: getServerConfig().slice(0, -1),
        isShowAlert: false,
    }

    componentDidMount = () => {
        if (!this.props.data) {
            this.getQRDataFromServer()
        } else {
            this.setState({ data: this.props.data })
        }
    }

    getQRDataFromServer = () => {
        const { campaignId, blastGuid } = this.props.state
        const params = {
            "campaignId": campaignId,
            "channelId": 3,
            "blastGuid": blastGuid
        }
        connectServer({
            path: GET_CAMPAIGN_CHANNEL_DETAILS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let isShow = res?.data.qrDetails?.keyMetrics?.totalScans > 0 ? false : true;
                    if (isShow) {
                        this.setState({ isShowAlert: isShow })
                    } else {
                        this.setState({ data: res?.data?.qrDetails, rowKey: res.data?.rowKey, isShowAlert: isShow })
                    }
                }
            },
            fail: err => {
            }
        })
    }

    render() {
        return <>
            {this.state.isShowAlert &&
                <ReportsAlertPopup
                    isOpen={this.state.isShowAlert}
                    onChangeIsOpen={(value) => {
                        if (value) {
                            this.props.history.push("/analytics");
                        }
                        this.setState({ isShowAlert: false })
                    }}></ReportsAlertPopup>}
            {this.state.isQRSplitAb &&
                <Row>
                    <Col>
                        <div className="tabs-sublevel bgBlue">
                            <div className="tabs-sub-left">
                                <BootstrapDropdown
                                    data={this.state.dropdownList}
                                    onSelect={(item) => {
                                        this.setState({ selectedDropdownValue: item })
                                    }}
                                    defaultItem={this.state.selectedDropdownValue}
                                    customAlignRight={true}
                                    className="marginR10 fs18-btn"
                                />
                            </div>
                            <div className="tabs-sub-right">
                                <RSPDateRangePicker class="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            }

            {/* Email Dashboard */}
            <div className='d-flex align-items-center'>
                <h3 className="marginB15 mt15">Overview</h3>
                <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(this.state.data?.latestDate)}</span>)</span>
                <div className='d-flex align-items-center'>
                    <RSPPophover
                        position='right'
                        imgClass='campaign-pop-eye-img'
                        image={this.state?.data?.previewImage !== "" ? `${this.state?.data?.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                    >
                        <div>
                            <RSPTooltip position="top" text="Preview">
                                <i className='icon-sd-eye-medium icons-md blue ml5 cp posr top3'></i>
                            </RSPTooltip>
                        </div>
                    </RSPPophover>
                </div>
            </div>

            <Row>
                {
                    this.state.data &&
                    getOverviewCardData(this.state.data).map((item, index) => {
                        return (
                            <OverViewMap
                                key={item}
                                campaignType={item.campaignType}
                                campaignCount={item.campaignCount}
                                data={item.data}
                                icon={item.icon}
                                bgColor={item.bgColor}
                                percentage={item.percentage}
                                infoMsg={item.data.info}
                                displayLabel={item?.displayLabel}
                                isLowPerformance={item?.isLowPerformance}
                            />
                        )
                    })
                }
            </Row>

            <h3 className="marginB15">Communication performance</h3>
            <Row>
                {/* Reach */}
                <Col md={8}>
                    <QRReachChart data={this.state.data} />
                </Col>

                {/* Key metrics */}
                <Col md={4}>
                    <div className="portlet-container keymetrics-portlet keymetrics-md">
                        <h4>Key metrics</h4>
                        <ul className="keymetrics-list key-border">
                            <li>
                                <h3>{numberWithCommas(this.state.data?.keyMetrics?.totalScans) || 0}</h3>
                                <small>Total scans</small>
                            </li>
                            <li>
                                <h3>{numberWithCommas(this.state.data?.keyMetrics?.averageScansPerDay) || 0}</h3>
                                <small>Average scans per day</small>
                            </li>
                        </ul>
                        <p className="mt10 mb5 text-center">User engagement</p>
                        <ul className="keymetrics-list keymetrics-theme">
                            <li className="bgBlue-lighter">
                                <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.kycCount) || 0}<small></small></h3>
                                <small>KYC count</small>
                            </li>
                            {/* <li className="bgBlue-light">
                                <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.calenderDownload) || 0}<small></small></h3>
                                <small>Calendar download</small>
                            </li> */}
                        </ul>
                        <ul className="keymetrics-list key-border pt10">
                            <li className="d-flex align-items-center flex-row ml15">
                                <small>Conversion rate</small>
                                <h3 className="pb0 ml10">{this.state.data?.keyMetrics?.goalConversionRate || 0}<small>%</small></h3>
                            </li>
                        </ul>
                        <p className="mt10 mb5 text-center">Top location scanned</p>
                        <ul className="keymetrics-list keymetrics-theme">
                            <li className="bgMaroon-light">
                                <h3 className="white p0">{this.state.data?.keyMetrics?.topLocationValue || 0}<small>%</small></h3>
                                <small>{this.state.data?.keyMetrics?.topLocation || ''}</small>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>

            <Row>
                {/* Browser */}
                <Col md={6}>
                    <QREngagementChart data={this.state.data} />
                </Col>

                {/* Conversion */}
                <Col md={6}>
                    <QRConversionChart data={this.state.data} />
                </Col>
            </Row >

            <h3 className="marginB15">User engagement</h3>
            <Row>
                {/* Device */}
                <Col md={6}>
                    <div className="portlet-container portlet-md portlet-footer-label">
                        <div className="portlet-header">
                            <h4>Geography</h4>
                        </div>
                        <div className="portlet-body">
                            <div className="portlet-map">
                                <HighchartsReact
                                    constructorType={"mapChart"}
                                    highcharts={Highcharts}
                                    options={worldMapChartOption(this.state.data)}
                                />
                            </div>
                        </div>
                        <div className="portlet-two-label">
                            <ul>
                                <li><span>{this.state.data?.keyMetrics?.topLocationValue}</span><small>%</small></li>
                                <li>of engagement is from users in {this.state.data?.keyMetrics?.topLocation}</li>
                            </ul>
                        </div>
                    </div>
                </Col>

                {/* Email opens by | Client / Device */}
                <Col md={6}>
                    <div className="portlet-container portlet-md portlet-footer-label">
                        <div className="portlet-header">
                            <h4>Top operating systems</h4>
                        </div>
                        <div className="portlet-body">
                            <div className="portlet-chart"><NewCharts options={clientChart(this.state.data)} /></div>
                        </div>
                        <div className="portlet-two-label">
                            <ul>
                                <li><span>{this.state.data?.topOsValue}</span><small>%</small></li>
                                <li>
                                    {
                                        `of qr code have been scanned from ${this.state.data?.topOs} operating systems`
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>

            </Row>

            <Row>
                <Col>
                    <QRTopLinks campaignId={this.props.state.campaignId} campaignGuid={this.props.state.campaignGuid} blastGuid={this.state?.data?.blastguid} rowKey={this.state.rowKey} />
                </Col>
            </Row>

            {/* <Row className="marginT30">
                <Col>
                    <h3 className="marginB15">Delivery status</h3>
                    <Grid
                        data={jsonData[0].campaignStatus.slice(this.state.skip, this.state.take + this.state.skip)}
                        total={jsonData.length}
                        scrollable={"none"}
                        className="grid-pagenation-del"
                        pageable={false}
                    >
                        <GridColumn field="sno" title="S.No." />
                        <GridColumn field="name" title="Name" />
                        <GridColumn field="email" title="Email" />
                        <GridColumn field="opens" title="Opens" />
                        <GridColumn field="clicks" title="Clicks" />
                        <GridColumn field="conversions" title="Conversions" />
                    </Grid>
                </Col>
            </Row> */}
        </>

    }
}

export default withRouter(QR);

const tabDataDays = [
    { "id": 1001, "text": "Client" },
    { "id": 1002, "text": "Device" }
]

// Overall datas
const getOverviewCardData = (data) => {
    const overallDatas = [
        {
            "campaignType": "Reach",
            "campaignCount": data?.reach?.delivered || 0,
            "data": [
                { "text": "Unique scans", "percent": data?.reach?.count || 0 }
            ],
            // "icon": "icon-campaign-target-large icons-lg",
            "icon": reachImg,
            "bgColor": "bgBlue-light",
            "percentage": data?.reach?.previousWeekComparison || 0,
            "displayLabel": (!!data?.reach?.prevDisplayLabel && data?.reach?.prevDisplayLabel !== "") ? data?.reach?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.reach?.isLowReachPerformance
        },
        {
            "campaignType": "Engagement",
            "campaignCount": data?.engagement?.count || 0,
            "data": [
                { "text": "Link clicks", "percent": data?.engagement?.clicks || 0 }
            ],
            // "icon": "icon-engagement-large icons-xl",
            "icon": engagementImg,
            "bgColor": "bgOrange-medium",
            "percentage": data?.engagement?.previousWeekComparison || 0,
            "displayLabel": (!!data?.engagement?.prevDisplayLabel && data?.engagement?.prevDisplayLabel !== "") ? data?.engagement?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.engagement?.isLowEngPerformance
        },
        {
            "campaignType": "Conversion",
            "campaignCount": data?.conversion?.count || 0,
            "data": [{
                "text": "Registration", "percent": data?.conversion?.registration || 0
            }],
            // "icon": "icon-conversion-large icons-xl",
            "icon": conversionImg,
            "bgColor": "bgGreen-medium",
            "percentage": data?.conversion?.previousWeekComparison || 0,
            "displayLabel": (!!data?.conversion?.prevDisplayLabel && data?.conversion?.prevDisplayLabel !== "") ? data?.conversion?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.conversion?.isLowConvPerformance
        }
    ]
    return overallDatas
}
// Client -- pie
const clientChart = data => {
    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Gmail", color: ch_desktopColor, y: 5 },
                //     { name: "Outlook 2010", color: ch_androidPhoneColor, y: 28 },
                //     { name: "Outlook 2007", color: ch_androidTabColor, y: 31 },
                //     { name: "Yahoo", color: ch_iphone, y: 11 },
                //     { name: "Others", color: ch_ipad, y: 7 }
                // ],
                data: data?.osInfoDTOs?.map((item, index) => {
                    let statusData = { name: item.name, legendIndex: index, y: parseFloat(item.value) || 0 }
                    return statusData
                }),
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }
}
// World map
const worldMapChartOption = (data) => {
    return {
        chart: {
            // type: 'map',
            map: "custom/world",
            proj4,
            // map: "countries/in/in-all"
            height: 290
        },
        title: {
            text: " "
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: true
        },
        tooltip: {
            headerFormat: "",
            pointFormat: "<span>{point.name}</span><br>Value: {point.totalClicks}",
            useHTML: true,
            shared: true,
            backgroundColor: '#5f5f5f',
            borderWidth: 0,
            shadow: false,
            style: {
                color: '#fefefe',
                cursor: 'default',
                fontSize: '13px',
                whiteSpace: 'nowrap'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {

            mappoint: {
                marker: {
                    symbol: `url(${markerIcon})`
                },
                dataLabels: {
                    align: 'left',
                    x: 5,
                    verticalAlign: 'middle'
                }
            }
        },
        series: [
            {
                name: "Basemap",
                mapData: mapDataIE,
                borderColor: "#ffffff",
                nullColor: "#749bf0",
                showInLegend: false
            },
            {
                name: "Separators",
                type: "mapline",
                nullColor: "#707070",
                showInLegend: false,
                enableMouseTracking: false
            },
            {
                type: 'mappoint',
                name: 'Cities',
                data: data?.byLocationGeography.map(data => {
                    return {
                        zoomLevel: 5,
                        id: data.country,
                        name: data.city,
                        lat: Number(data.latitude),
                        lon: Number(data.longitude),
                        totalClicks: Number(data.totalClicks)

                    }
                })
            }
        ]
    }
}