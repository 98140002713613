import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { RSInput2, RSTextInputWithIcon } from 'Components/RSInputs';
import { CHECK_CARD_TYPE, POST_PAYMENT_SUBMIT } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { ENTER_NAME_ON_CARD, ENTER_NUMBER_ON_CARD } from 'Helper/Constants/validationMessage'
import iconVisa from 'Assets/Images/icon_visa.svg'
import iconMasterCard from 'Assets/Images/icon_mastercard.svg'
import iconAmex from 'Assets/Images/icon_amex.svg'
import iconDiscover from 'Assets/Images/icon_discover.svg'
import iconJCB from 'Assets/Images/icon_jcb.svg'
import iconDinersClub from 'Assets/Images/icon_dinersclub.svg'
import dummyCard from 'Assets/Images/dummy_cc.svg'
import { formatCreditCardNumber } from 'Helper/Utils/Utils';

class CreditCard extends Component {

    static contextType = authContext

    state = {
        cardNumber: "",
        cardOriginalNumber: '',
        isValidateCardNumber: false,
        cardName: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardType: "",
        errorMessage: {
            cardNumber: null,
            cardName: null,
            cardMonth: null,
            cardYear: null,
            cardCvv: null,
        }
    }

    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({
                errorMessage: this.props.errorMessage
            })
        }
    }

    checkCardType = (cardNumber) => {
        let params = {
            "cardNumber": cardNumber
        }
        connectServer({
            path: CHECK_CARD_TYPE,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let some = cardNumber
                    let arry = [0, 1, 2, 3, 5, 6, 7, 8, 10, 11, 12, 13]
                    arry.forEach(item => {
                        some = some.replace(/./g, (c, i) => i === item ? 'X' : c)
                    })

                    let _cardOriginalNumber = cardNumber
                    _cardOriginalNumber = _cardOriginalNumber.replaceAll("-", '')
                    let tempErr = { ...this.state.errorMessage };
                    tempErr.cardNumber = null;
                    this.setState({
                        cardOriginalNumber: _cardOriginalNumber, cardNumber: some, cardType: res.data.cardName, errorMessage: tempErr, cardName: "",
                        cardMonth: "",
                        cardYear: "",
                        cardCvv: "",
                        isValidateCardNumber: true
                    })
                    this?.props?.getValue('cardType', res.data.cardName)
                    this?.props?.getValue('errMsg', tempErr)
                    this?.props?.getValue('cardNumber', cardNumber)
                    this?.props?.getValue('cardName', "")
                    this?.props?.getValue('cardMonth', "")
                    this?.props?.getValue('cardYear', "")
                    this?.props?.getValue('cardCvv', "")
                } else {
                    let tempErr = { ...this.state.errorMessage };
                    tempErr.cardNumber = res.message;
                    this.setState({
                        errorMessage: tempErr
                    })
                    this?.props?.getValue('errMsg', tempErr)
                }
            },
            fail: err => {
            }
        })
    }

    postPaymentDetailsToServer = () => {

        const params = {
            cardholderName: this.state.cardName,
            cardNumber: this.state.cardOriginalNumber,
            //cardNumber: "4111111111111111",
            month: this.state.cardMonth,
            year: this.state.cardYear,
            // expirationDate: `${this.state.cardMonth}/${this.state.cardYear}`,
            expirationDate: null,
            cvv: this.state.cardCvv,
            clientId: this.props.history.location.state.clientId,
            userId: this.props.history.location.state.userId,
            Amount: this.props.location.state && this.props.location.state.amount
        }
        connectServer({
            path: POST_PAYMENT_SUBMIT,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) { this.props.history.push("thanks") }
            },
            fail: err => {
            }
        })
    }

    cardExpiryValidation = t => {

        if (!this.state.cardYear.length && !this.state.cardMonth.length) { return false }

        let currentYear = new Date().getFullYear()
        if (this.state.cardYear < currentYear) {
            this.setState({ errorMessage: { ...this.state.errorMessage, ...{ cardYear: "Your card expired" } } })
            return false
        } else if (this.state.cardYear > currentYear) {
            if (this.state.cardYear > (currentYear + 50)) {
                this.setState({ errorMessage: { ...this.state.errorMessage, ...{ cardYear: "Enter year below " + (currentYear + 51).toString() } } })
                return false
            } else {
                this.setState({ errorMessage: { ...this.state.errorMessage, ...{ cardYear: null } } })
                return true
            }
        } else {
            let currentMonth = new Date().getMonth() + 1
            if (this.state.cardMonth < currentMonth) {
                this.setState({ errorMessage: { ...this.state.errorMessage, ...{ cardYear: "Your card expired" } } })
                return false
            } else {
                this.setState({ errorMessage: { ...this.state.errorMessage, ...{ cardYear: null } } })
                return true
            }
        }
    }

    validation = () => {

        if (this.state.cardNumber.length > 0) {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardNumber: null }
                }
            })
        } else {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardNumber: ENTER_NUMBER_ON_CARD }
                }
            })
            return
        }

        if (this.state.cardName.length > 0) {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardName: null }
                }
            })
        } else {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardName: 'Enter name on card' }
                }
            })
            return
        }

        if (this.state.cardMonth.toString().length === 2) {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardMonth: null }
                }
            })
        } else {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardMonth: 'Enter month' }
                }
            })
            return
        }

        if (this.state.cardYear.toString().length === 4) {
            let currentYear = new Date().getFullYear();
            if (parseInt(this.state.cardYear, 10) > (currentYear + 50)) {
                this.setState({
                    errorMessage: {
                        ...this.state.errorMessage,
                        ...{ cardYear: "Enter year below " + (currentYear + 51).toString() }
                    }
                })
            } else {
                this.setState({
                    errorMessage: {
                        ...this.state.errorMessage,
                        ...{ cardYear: null }
                    }
                })
            }
        } else {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardYear: 'Enter year' }
                }
            })
            return
        }

        if (this.state.cardCvv.toString().length === 3) {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardCvv: null }
                }
            })
        } else {
            this.setState({
                errorMessage: {
                    ...this.state.errorMessage,
                    ...{ cardCvv: 'Enter cvv' }
                }
            })
            return
        }
        let isValidate = this.cardExpiryValidation();
        if (!isValidate) {
            return
        }
        this.postPaymentDetailsToServer()
    }

    getCardImage = (cardType) => {
        let imageSource = "";
        switch (cardType) {
            case "Visa": imageSource = iconVisa; break;
            case "MasterCard": imageSource = iconMasterCard; break;
            case "AmEx": imageSource = iconAmex; break;
            case "Discover": imageSource = iconDiscover; break;
            case "JCB": imageSource = iconJCB; break;
            case "DinersClub": imageSource = iconDinersClub; break;
            default: imageSource = dummyCard; break;
        }
        return imageSource;
    }

    render() {
        return (
            <div>
                <div className="visa-input">

                    <div className="position-relative">
                        {this.state.errorMessage?.cardNumber && <div className="validation-message">{this.state.errorMessage?.cardNumber}</div>}

                        <RSTextInputWithIcon
                            name="cardNumber"
                            ph="XXXX - XXXX - XXXX - 1234"
                            // type="number"
                            cls2="mt15"
                            val={this.state.cardNumber}
                            img={this.getCardImage(this.state.cardType)}
                            imgClass='visa-card-img'
                            cb={t => {
                                let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardNumber: null } } : { ...this.state.errorMessage, ...{ cardNumber: "Enter number on card" } }
                                if (t.length > 22) {
                                    return
                                }
                                if (t.length > 0) {
                                    let regex = /^[0-9/X-]*$/;
                                    if (!regex.test(t)) {
                                        return;
                                    }
                                }
                                let cardNum = this.state.isValidateCardNumber ? "" : formatCreditCardNumber(t);
                                if (this.state.isValidateCardNumber) {
                                    this?.props?.getValue('cardNumber', cardNum)
                                }
                                this.setState({ cardNumber: cardNum, errorMessage: errMsg, cardType: "", cardOriginalNumber: "", isValidateCardNumber: false })
                                // if (t.length === 19) {
                                //     let some = t
                                //     let arry = [0, 1, 2, 3, 5, 6, 7, 8, 10, 11, 12, 13]
                                //     arry.map(item => {
                                //         some = some.replace(/./g, (c, i) => i === item ? 'X' : c)
                                //     })

                                //     let _cardOriginalNumber = t
                                //     _cardOriginalNumber = _cardOriginalNumber.replaceAll("-", '')
                                //     RSLog("Final _cardOriginalNumber", _cardOriginalNumber, true)
                                //     RSLog("Final replace", some, true)
                                //     this.setState({
                                //         cardOriginalNumber: _cardOriginalNumber, cardNumber: some, errorMessage: errMsg
                                //     })
                                //     this?.props?.getValue('errMsg', errMsg)
                                //     this?.props?.getValue('cardNumber', t)
                                // } else {
                                //     this.setState({ cardNumber: formatCreditCardNumber(t), errorMessage: errMsg })
                                // }
                            }}
                            ob={(value) => {
                                if (this.state.cardOriginalNumber !== "" || value !== "") {
                                    this.checkCardType(this.state.cardOriginalNumber !== "" ? formatCreditCardNumber(this.state.cardOriginalNumber) : value);
                                } else {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardNumber: "Enter number on card" } };
                                    this.setState({ errorMessage: errMsg })
                                    this?.props?.getValue('errMsg', errMsg)
                                }
                            }}
                        />
                    </div>
                    {/* <small>Change?</small> */}
                </div>

                <div className="position-relative">
                    {this.state.errorMessage?.cardName && <div className="validation-message">{this.state.errorMessage?.cardName}</div>}
                    <RSTextInputWithIcon
                        max={100}
                        name="cardName"
                        ph="Name on card"
                        className="input-text-uppercase"
                        val={this.state.cardName}
                        icon={"icon-sd-user-medium icons-md"}
                        ob={t => {
                            let trimedName = t.trimStart().trimEnd();
                            this.setState({ cardName: trimedName.toUpperCase() })
                        }}
                        cb={t => {

                            let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardName: null } } : { ...this.state.errorMessage, ...{ cardName: "Enter name on card" } }

                            const re = /^[a-zA-Z ]{1,150}$/
                            if (t === '' || re.test(t)) {
                                this.setState({ cardName: t.toUpperCase(), errorMessage: errMsg })
                                this?.props?.getValue('errMsg', errMsg)
                                this?.props?.getValue('cardName', t)
                            } else {
                                if (!t.length)
                                    this.setState({
                                        errorMessage: { ...this.state.errorMessage, ...{ cardName: ENTER_NAME_ON_CARD } }
                                    })
                            }
                        }}
                    />
                </div>


                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: 10 }}>
                    <div className="position-relative">
                        {this.state.errorMessage?.cardMonth && <div className="validation-message">{this.state.errorMessage?.cardMonth}</div>}
                        <RSInput2
                            name="month"
                            cls="mb20"
                            ph="MM"
                            required={true}
                            val={this.state.cardMonth}
                            ob={t => {
                                if (t.length === 1) {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardMonth: "" } }
                                    this.setState({
                                        cardMonth: `0${1}`, errorMessage: errMsg
                                    })
                                    this?.props?.getValue('errMsg', errMsg)
                                } else if (t.toString() === "00") {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardMonth: "Invalid month" } }
                                    this.setState({ errorMessage: errMsg })
                                    this?.props?.getValue('errMsg', errMsg)
                                } else if (t.length === 0) {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardMonth: "Enter month" } }
                                    this.setState({ errorMessage: errMsg })
                                    this?.props?.getValue('errMsg', errMsg)
                                }
                                if (this.state.cardYear !== '') {
                                    this.cardExpiryValidation()
                                }
                            }}
                            cb={t => {
                                let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardMonth: null } } : { ...this.state.errorMessage, ...{ cardMonth: 'Enter month' } }
                                this.setState({ errorMessage: errMsg })
                                const re = /^[0-9]{1,2}$/
                                if (t === '' || re.test(t)) {

                                    if (t <= 12) {
                                        if (t < 10 && t > 1) {
                                            let some = `0${parseInt(t, 10)}`
                                            this.setState({
                                                cardMonth: some
                                            })
                                            this?.props?.getValue('cardMonth', some)
                                            this?.props?.getValue('errMsg', errMsg)
                                        } else {
                                            this.setState({
                                                cardMonth: t
                                            })
                                            this?.props?.getValue('cardMonth', t)
                                            this?.props?.getValue('errMsg', errMsg)
                                        }
                                        // this.setState({
                                        //   cardMonth: t
                                        // })
                                    } else {
                                        let errMsg = { ...this.state.errorMessage, ...{ cardMonth: "Enter valid month" } }
                                        this.setState({ errorMessage: errMsg })
                                        this?.props?.getValue('errMsg', errMsg)
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="slashInput">
                        <div className="slash">/</div>
                        <div className="position-relative marginL16">
                            {this.state.errorMessage?.cardYear && <div className="validation-message">{this.state.errorMessage?.cardYear}</div>}
                            <RSInput2
                                name='year'
                                cls='mb20'
                                // max={4}
                                ph='YYYY'
                                required={true}
                                val={this.state.cardYear}
                                ob={t => {
                                    let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardYear: null } } : { ...this.state.errorMessage, ...{ cardYear: "Enter year" } }
                                    if (t.length > 0) {
                                        this.cardExpiryValidation(t)
                                    } else {
                                        this.setState({ errorMessage: errMsg })
                                        this?.props?.getValue('errMsg', errMsg)
                                    }
                                }}
                                cb={t => {
                                    let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardYear: null } } : { ...this.state.errorMessage, ...{ cardYear: "Enter year" } }
                                    this.setState({ errorMessage: errMsg })
                                    const re = /^[0-9]{1,4}$/
                                    if (t === '' || re.test(t)) {
                                        this.setState({
                                            cardYear: t
                                        })
                                        this?.props?.getValue('cardYear', t)
                                        this?.props?.getValue('errMsg', errMsg)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div></div>

                    <div className="position-relative">
                        {this.state.errorMessage?.cardCvv && <div className="validation-message">{this.state.errorMessage?.cardCvv}</div>}

                        <RSTextInputWithIcon
                            name='cvv'
                            ph='CVV'
                            // max={3}
                            type='password'
                            className='mb20'
                            val={this.state.cardCvv}
                            icon={'icon-sd-lock-medium icons-md'}
                            cb={t => {
                                if (t === "") {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardCvv: 'Enter card cvv' } }
                                    this.setState({ cardCvv: "", errorMessage: errMsg })
                                    this?.props?.getValue('cardCvv', t)
                                    this?.props?.getValue('errMsg', errMsg)
                                    return
                                }
                                const rejex = this.state.cardType === "AmEx" ? /^[0-9]{1,4}$/ : /^[0-9]{1,3}$/
                                if (rejex.test(t)) {
                                    let errMsg = t.length > 0 ? { ...this.state.errorMessage, ...{ cardCvv: null } } : { ...this.state.errorMessage, ...{ cardCvv: 'Enter card cvv' } }
                                    if (t.length < (this.state.cardType === "AmEx" ? 5 : 4)) {
                                        this.setState({ cardCvv: t, errorMessage: errMsg })
                                    }
                                    this?.props?.getValue('cardCvv', t)
                                    this?.props?.getValue('errMsg', errMsg)
                                }
                                // }
                            }}
                            ob={(value) => {
                                let digits = this.state.cardType === "AmEx" ? 4 : 3;
                                if (value.length > 0) {
                                    if (value.length < digits) {
                                        this.setState({ cardCvv: value, errorMessage: { ...this.state.errorMessage, ...{ cardCvv: "Need " + digits.toString() + " digits" } } })
                                    } else {
                                        this.setState({ cardCvv: value, errorMessage: { ...this.state.errorMessage, ...{ cardCvv: null } } });
                                    }
                                } else {
                                    let errMsg = { ...this.state.errorMessage, ...{ cardCvv: 'Enter card cvv' } };
                                    this.setState({ cardCvv: value, errorMessage: errMsg });
                                }
                            }}
                        />
                    </div>
                </div>
                {/* {!this.props.isCampaignPayment &&
                    <div className="btn-container d-flex justify-content-end mb10">
                        <RSSecondaryBtn onClick={() => { this.props.history.push({ pathname: `features`, state: { clientId: this.props.history.location.state.clientId || 0, userId: this.props.history.location.state.userId || 0 } }) }}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            this.validation()
                        }}>Pay</RSPrimaryBtn>
                    </div>} */}
            </div>
        );
    }


};
export default withRouter(CreditCard);