import styled from "styled-components";

const RSButton = styled.button.attrs({
    className: 'rsp-default-button',
})`
    line-height: 23px;
    border: 0; 
    padding: 9px 20px;
    letter-spacing: 0.5pt;
    text-align: center;
    outline: 0;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    font-family: "MuktaRegular", sans-serif;
    transition: all 0.3s ease;
`

// export const RSPrimaryBtn = () => {
//     return <>
//         <button className="rsp-button" />
//     </>
// }

export const RSPrimaryBtn = styled(RSButton).attrs({
    className: 'rsp-primary-button',
})`
    // background-color: ${props => props.bgc ? props.bgc : "#722620"};
    color: #fff;
    padding-right: ${props => props.paddingR ? props.paddingR : "20px"};
    transition: all 0.3s ease;
    &:hover {
        // box-shadow: 0 0px 15px #722620;
    }
`

export const RSSecondaryBtn = styled(RSButton).attrs({
    className: 'rsp-secondary-button',
})`
    background-color: transparent;
    margin: 0 10px;
    // color: ${props => props.txtc ? props.txtc : "#fc6a00"};
    padding-right: ${props => props.paddingR ? props.paddingR : "20px"};
    transition: all 0.3s ease;
    &:hover {
        text-decoration: underline;
    }
`

export const RSCancelBtn = styled(RSButton).attrs({
    className: 'rsp-cancel-button',
})`
    margin: 0 30px 0 0;
    &:hover {
        text-decoration: underline;
    }
`