import React from 'react';
import styled from "styled-components"
import paypal from 'Assets/Images/paypal-button.png'

const Paypal = props => {
    return (
        <PaypalImgBox>
            <p>
                We will redirect you to PayPal to enable you to make your payment. You will return to Resulticks to complete your order.
            </p>
            <PaypalImg src={paypal} alt="paypal"></PaypalImg>
        </PaypalImgBox>
    );
};


export default Paypal;

const PaypalImgBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 30px;
`
const PaypalImg = styled.img`
    align-self: flex-end;
`