import React, { useState, useEffect } from 'react';

const Timer = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);
  const [finesh, setFinesh] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          // Optionally, you can perform an action when the timer reaches zero
            setFinesh(false)
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [initialTime]);

  // Convert time to minutes and seconds for display
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return (
    <div className={`d-flex align-items-center justify-content-end mt10 ${finesh ? 'd-block' : 'd-none'}`}>
        <span>
            <i className='icon-sd-time-medium marginR5 icons-md'></i>
        </span>
        <span>{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span>
    </div>
  );
};

export default Timer;