import { GridDetailRow } from "@progress/kendo-react-grid";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { SplitABModal } from "../SplitABModal";
import abIcon from 'Assets/Images/split-ab.png'
import { RSPTooltip } from "Components/Tooltip";

class AdminListingDetailComponent extends GridDetailRow {

  state = {
    splitAb: "Subject line",
    splitAbModal: false
  }

  TableHead = props => {
    return <thead>
      <tr>
        <th className="text-center">Channel</th>
        <th>Status</th>
        <th>Total send</th>
        <th>Delivered</th>
        <th>Reach</th>
        <th>Engagement</th>
        <th className="text-end">Action</th>
      </tr>
    </thead>
  }
  
  render() {

    return (
      <>
        <div className="panel-detail-view">
          <table className="accordian-body">
            <this.TableHead />
            <tbody>
              <tr>
                <td className="text-center"> <i className="blue icons-lg icon-social-web-notification-large"></i> </td>
                <td>
                  <div className="d-flex align-items-center">
                    <img src={abIcon} alt={abIcon} />
                    <p className="rs-link paddingL8" onClick={() => this.setState({ splitAbModal: !this.state.splitAbModal })}>split 10% completed</p>
                  </div>
                </td>
                <td> <p>22,000</p> </td>
                <td> <p>21,905</p> </td>
                <td> <p>21,896</p> </td>
                <td> <p>1,090</p> </td>
                <td>
                  <div className="camp-icon float-end">
                    <ul className="camp-icon-pannel">
                      <li> <i onClick={() => alert("test")} className="icon-sd-eye-medium icons-md blue"></i> </li>
                      <li> <DetailAnalyticIcon /> </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr className="even">
                <td className="text-center"> <i className="icon-mobile-push-notification-large icons-lg blue"></i> </td>
                <td> <p>Mobile push</p> </td>
                <td> <p>24,540</p> </td>
                <td> <p>23,795</p> </td>
                <td> <p>22,235</p> </td>
                <td> <p>2,589</p> </td>

                <td>
                  <div className="camp-icon float-end">
                    <ul className="camp-icon-pannel">
                      <li> <i className="icon-sd-eye-medium icons-md blue"></i> </li>
                      <li> <DetailAnalyticIcon /> </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SplitABModal show={this.state.splitAbModal} closeSplitAb={() => this.setState({ splitAbModal: false })} />
      </>
    );
  }
}
  
export default AdminListingDetailComponent

const DetailAnalyticIcon = (props) => {
  const history = useHistory()
  const match = useRouteMatch()
  return <RSPTooltip text="Report" position="top"><i className="icon-sd-analytics-medium icons-md blue" onClick={() => history.push(`${match.url}/detail-analytics`)}></i></RSPTooltip>
}