import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { Row, Col } from "react-bootstrap";
import { RSPrimaryBtn } from "Components/RSButtons";
import { connectServer } from "Helper/Network/networkHandler";
import { EDM_UPLOAD, IMPORT_CAMPAIGN, VALIDATE_WEBSITE_URL } from "Helper/Constants/endPoint";
import { isEmpty, isValidWebsite } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import {
  FILE_TYPE_MESSAGE,
  URL,
  PRE_HEADER_MESSAGE,
  ENTER_VALID_WEBSITE,
  URL_SCRIPT_ERROR_MSG,
} from "Helper/Constants/validationMessage";
import { RSPTooltip } from "Components/Tooltip";
import LinkVerificationModal from "./LinkVerificationModal";
import SubjectModal from "./SubjectModal";
class ImportTabContent extends Component {
  constructor(props) {
    super(props);
  }
  static contextType = authContext;
  state = {
    edmLinks: [],
    iFrameValue: "",
    errImportFileMsg: null,
    campaignURL: "",
    importZIPFile: {
      data: "",
      fileName: "",
    },
    importFileNameFromAPI: "",
    importFile: this.props.contentData.body !== "" ? true : false,
    push: {
      webUrl: "",
    },
    builderPreview: true,
    isAddFooter: false,
    headerMsg: this.props.headerData,
    isAddViewBrowser: this.props.isAddBrowser,
    errPreHeaderMsg: this.props.defaultError.errPreHeaderMsg,
    isSubjectLineModal: false,
    isLinkVerificationModal: false,
    checkSpamParams: {},
    isEnableGOButton: false,
    errMsgImportUrl: null,

  };
  componentDidMount = () => {
    const htmlContent = this.handleEditableHtmlContent(this.props.contentData.body);
    this.setState({ iFrameValue: htmlContent });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({
        errPreHeaderMsg: this.props.defaultError.errPreHeaderMsg,
        errSendTestMailMsg: this.props.defaultError.errSendTestMailMsg,
        headerMsg: this.props.headerData,
        isAddViewBrowser: this.props.isAddBrowser,
      });
      this.getVerificationLinks(this.state.iFrameValue);
    }
  };
  handleEditableHtmlContent = (tempContent) => {
    let tempElement = document.createElement('div');
    tempElement.id = 'wrapperElement'
    tempElement.innerHTML = tempContent;
    if (tempElement.querySelector("table")) {
      tempElement.querySelectorAll("table").forEach(el => {
        if (!el.querySelector("img")) {
          el.contentEditable = true;
          el.setAttribute('style', 'outline:none');
        }
      })
    }
    return tempElement.innerHTML;
  }
  isValidFileUploaded = (file) => {
    const validExtensions = [
      "zip",
      "x-zip-compressed",
      "rar",
      "gz",
      "7z",
      "sitx",
    ];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  validateWebsiteURL = (websiteURL) => {
    let params = {
      "webUrl": websiteURL
    }
    connectServer({
      path: VALIDATE_WEBSITE_URL,
      params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        this.setState({ errMsgImportUrl: res.data ? null : "Enter valid URL", isEnableGOButton: res.data })
      },
      fail: err => {
      }
    })
  }
  importZip = (event) => {
    if (event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    if (!this.isValidFileUploaded(file)) {
      this.setState({ errImportFileMsg: FILE_TYPE_MESSAGE });
    } else {
      this.setState({ errImportFileMsg: "" });
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      let fileName = event.target.files[0].name;
      let fileBytes = "";
      let fileSize = Math.round((event.target.files[0].size / 1024))
      this.props.contentData.edmFileWeight = fileSize
      reader.onload = (e) => {
        fileBytes = reader.result.split(",").pop();
        this.setState({
          importZIPFile: { data: fileBytes },
        });
        let params = {
          importDescription: "sample adhoc 07Feb2022",
          fileName: fileName,
          fileByte: this.state.importZIPFile.data,
          headers: "",
        };
        connectServer({
          path: EDM_UPLOAD,
          params,
          loading: this.context.globalStateData.setIsLoadingData,
          context: this.context,
          ok: (res) => {
            if (res.status) {
              if (res?.data?.html !== null) {
                this.setState({
                  importFile: true,
                  importZIPFile: { fileName: fileName }
                })
              }
              this.getVerificationLinks(res.data.html);
              this.props.contentData.tempGuid = res.data.tempGuid;
              const htmlContent = this.handleEditableHtmlContent(res.data.html);
              this.setState({
                iFrameValue: htmlContent,
                importFileNameFromAPI: res.data.filePath,
                builderPreview: true,
              });
              this.props.getBodyContent(res.data.html);
              this.props.onShowFooter(true);
              this.props.onValidateSpamCheck && this.props.onValidateSpamCheck(true)
            }
          },
          fail: (err) => { },
        });
      };
    }
  };

  importURL = () => {
    let params = {
      url: this.state.push.webUrl,
      splitType: this.props.contentData.splitType
    }
    connectServer({
      path: IMPORT_CAMPAIGN,
      params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status) {
          if (!res.data.contType) {
            this.getVerificationLinks(res.data.html);
            this.props.contentData.tempGuid = res.data.tempGuid;
            const htmlContent = this.handleEditableHtmlContent(res.data.html);
            this.setState({ iFrameValue: htmlContent, importFileNameFromAPI: res.data.filePath, builderPreview: true })
            this.props.getBodyContent(res.data.html);
            this.props.onShowFooter(true);
            this.props.onValidateSpamCheck && this.props.onValidateSpamCheck(true)
          } else {
            this.setState({ errMsgImportUrl: URL_SCRIPT_ERROR_MSG });
          }
        }
      },
      fail: err => {
      }
    })
  }

  getVerificationLinks = (edmHtml) => {
    let htmlString = edmHtml;
    const htmlDoc = new DOMParser().parseFromString(htmlString, 'text/html');
    const links = Array.from(
      htmlDoc.querySelectorAll('[href]'),
      element => element.getAttribute('href')
    );
    let tempEdmLinks = []
    links.forEach((item) => {
      let isValidLink = item.includes("http") ? item : '';
      if (isValidLink !== '') {
        let tempObject = { "LinkURL": isValidLink, "SDKStatus": false, "LinkStatus": false }
        tempEdmLinks.push(tempObject)
      } else { }
    })
    this.setState({ edmLinks: tempEdmLinks })
    this.props.contentData.linkVerificationLogDetail = tempEdmLinks;
  }
  render() {
    return (
      <> <div className="position-relative">
        {this.props.defaultError.errImportContent && (
          <div className="validation-message top-30">
            {this.props.defaultError.errImportContent}
          </div>
        )}
        <Row className={this.state.iFrameValue ? "click-off" : ""}>
          <Col>
            <Row className="custom-column portlet-import">
              <Col className={`py15 borderR1 rs-radius-left ${this.state.importFile ? "col-md-2 cp" : "col-md-10 bgGrey-L100"}`} onClick={() => this.setState({ importFile: false })} >
                <Row className="align-items-center">
                  <Col className={`d-flex align-items-center paddingL35 ${this.state.importFile ? "col-md-12" : "col-md-3"}`}>
                    <div className="d-flex align-items-center flex-column">
                      <i className="icon-sd-import-link-large icons-lg blue marginB7" />
                      <span>Import URL</span>
                    </div>
                  </Col>
                  {
                    this.state.importFile
                      ? ("")
                      : (
                        <Col md={8} className="pl3">
                          <div className="d-flex align-items-center justify-content-center">
                            <RSInput2
                              ph="Enter URL"
                              val={this.state.push.webUrl}
                              cls="mb0"
                              cls2="mr25"
                              required={true}
                              cb={(text) => {
                                let errMsg = text !== "" ? isValidWebsite(text) ? null : "Enter valid URL" : "Enter URL"
                                this.setState({
                                  push: {
                                    webUrl: text,
                                  },
                                  errMsgImportUrl: errMsg
                                });
                              }}
                              ob={(text) => {
                                if (text.endsWith(".html") || text.endsWith(".htm") || text.endsWith(".aspx") || text.endsWith(".asp")) {
                                  this.validateWebsiteURL(text);
                                } else {
                                  this.setState({ errMsgImportUrl: "URL should end with html, htm, aspx or asp" })
                                }
                              }}
                              errorMessage={this.state.errMsgImportUrl}
                            />
                            <RSPrimaryBtn className={this.state.isEnableGOButton ? "" : "click-off"} onClick={() => {
                              let errMsg = this.state.push?.webUrl !== "" ? isValidWebsite(this.state.push?.webUrl) ? null : "Enter valid URL" : "Enter URL"
                              this.setState({ errMsgImportUrl: errMsg, isEnableGOButton: errMsg !== null ? false : true })
                              if (errMsg === null) {
                                this.importURL()
                              }
                            }}>
                              Go
                            </RSPrimaryBtn>
                          </div>
                        </Col>
                      )
                  }
                </Row>
                <span className="or-divider import-or"></span>
              </Col>
              <Col className={`${this.state.importFile ? "col-md-10 bgGrey-L100 justify-content-start" : "col-md-2 justify-content-center cp"} rs-radius-right d-flex align-items-center`}>
                <div className="d-flex text-center flex-column"
                // onClick={() => {this.setState({ importFile: true });}}
                >
                  <i className="icon-sd-zip-large icons-lg blue cp marginB7" />
                  {this.state.errImportFileMsg && this.state.importFile && (
                    <div className="validation-message">
                      {this.state.errImportFileMsg}
                    </div>
                  )}
                  <span>Import ZIP file</span>
                  <input
                    type="file"
                    className="browse-hidden"
                    accept=".zip, .rar"
                    onChange={this.importZip}
                  />
                </div>
                <span className="upload-lable-text">
                  {this.state.importZIPFile.fileName !== "" &&
                    this.state.importZIPFile.fileName}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.isAddFooter && (
          <div className="mt20 mb30">

            <iframe
              id='footerFrame'
              title="footerFrame"
              srcDoc={this.state.emailFooterHtml}
              style={{
                width: "100%",
                // pointerEvents: "none"
              }}
            ></iframe>
          </div>
        )}
        {this.props.contentData.contentType !== "" && this.state.iFrameValue &&
          <Row>
            <Col>
              <div className='d-flex justify-content-end mt30'>
                {/* <div className='text-center mr15'>
                            <RSPTooltip text="Mailbox preview" position="top">
                                <i
                                className={`icon-responsive-large icons-lg blue`}
                                onClick={() => this.setState({isMailboxPreviewModal: !this.state.isMailboxPreviewModal })}
                                ></i>
                            </RSPTooltip>
                        </div> */}
                <div className='text-center'>
                  <RSPTooltip text="Spam score check" position="top">
                    <i
                      className="icon-sd-spam-assassin-medium icons-md blue cp"
                      onClick={() => {
                        this.props.onValidateSpamCheck && this.props.onValidateSpamCheck(true)
                        if (this.props.isSpamCheckValidate) {
                          let params = {
                            "campaignId": this.props.emailData.campaignId,
                            "campaignGuId": "3f3bd17a-2895-419c-a20a-34b966b0b7cc",
                            "senderName": this.props.emailData.senderName,
                            "senderEmail": this.props.emailData.senderEmail,
                            "replyEmail": this.props.emailData.replyEmail,
                            "testCampaignEmailAddress": this.props.emailData.testCampaignEmailAddress,
                            "targetListTargetAudience": this.props.emailData.targetListTargetAudience,
                            "subjectLine": this.props.contentData.subjectLine,
                            "contentType": this.props.contentData.contentType,
                            "splitType": this.props.contentData.splitType,
                            "body": this.state.iFrameValue,
                            "footerAddress": "Greams Road, Thousand Lights",
                            "preHeaderMessage": this.props.contentData.preHeaderMessage,
                            "isFooterEnabled": this.props.contentData.isFooterEnabled,
                            "emailfooterContent": this.props.emailfooterContent,
                            "isSplitAB": this.props.emailData.isSplitAB,
                            "isViewinBrowserChecked": this.props.contentData.isViewinBrowserChecked,
                            "footerId": this.props.contentData.footerId,
                            "unsubscribeSettingId": this.props.contentData.unsubscribeSettingId,
                            "templateId": this.props.contentData.templateId
                          }
                          this.setState({
                            checkSpamParams: { ...params },
                            isSubjectLineModal: !this.state.isSubjectLineModal
                          })
                        }
                      }}
                    ></i>
                  </RSPTooltip>
                </div>
                <span class="divider-line-v"></span>
                <div className='text-center'>
                  <RSPTooltip text="Link verification" position="top">
                    <i
                      className="icon-sd-link-verification-large icons-md blue cp"
                      onClick={() => this.setState({ isLinkVerificationModal: !this.state.isLinkVerificationModal })}
                    ></i>
                  </RSPTooltip>
                </div>
              </div>
            </Col>
          </Row>}
        {this.state.iFrameValue && (
          <Row className="custom-column mt30">
            <Col md={3}>
              <label className="label-row">Pre header message</label>
            </Col>
            <Col md={6}>
              <RSInput2
                ph="Enter inbox first line message"
                val={this.props.headerData}
                cb={(text) => {
                  let errMsg = text === "" ? PRE_HEADER_MESSAGE : null;
                  this.props.getHeader(text);
                  this.setState({
                    headerMsg: text,
                    errPreHeaderMsg: errMsg,
                  });
                }}
                errorMessage={this.state.errPreHeaderMsg}
              />
            </Col>
            <Col md={4}>
              <RSCheckbox
                className="mb0 mt10"
                lbl="Add view in browser"
                checked={this.props.isAddBrowser}
                cb={(status) => {
                  this.props.getBroweser(status);
                }}
              />
            </Col>
          </Row>
        )}
        {this.state.iFrameValue && (
          <div className="import-iframe-wrapper">
            <div className="import-iframe">
              <iframe
                id="edmFrame"
                title="uploadFrame"
                srcDoc={this.state.iFrameValue}
                style={{ width: "100%", minHeight: "100vh" }}
              ></iframe>
            </div>
          </div>
        )}
      </div>
        {
          this.state.isLinkVerificationModal &&
          <LinkVerificationModal
            edmLinks={this.state.edmLinks}
            isOpen={this.state.isLinkVerificationModal}
            onChangeIsOpen={(value) => {
              this.setState({ isLinkVerificationModal: value });
            }}
          />
        }

        {
          this.state.isSubjectLineModal &&
          <SubjectModal
            isOpen={this.state.isSubjectLineModal}
            onChangeIsOpen={(value) => {
              this.setState({ isSubjectLineModal: value });
            }}
            checkSpamParams={this.state.checkSpamParams}
          />
        }
      </>
    );
  }
}
export default withRouter(ImportTabContent);
