import React, { Component } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { RSDropdownList, RSPhoneInput } from 'Components/RSDropdowns';
import { RSInput2, RSTextInputWithIcon, RSCheckbox } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { withRouter } from "react-router-dom";
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { isEmpty, validateEmail, getAuthUserId, passwordStrengthMeter, getServerConfig, getUserData, convertObjectToBase64 } from 'Helper/Utils/Utils';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';
import { CHANGE_USER_PASSWORD, GET_USER_PROFILE, UPDATE_USER_PROFILE, REQUEST_OTP, VALIDATE_OTP, CHANGE_MOBILE_NUMBER, MOBILE_NO_OTP_VALIDATION, CHANGE_PASSWORD_OTP, GET_STATE_BY_COUNTRY, GET_CITY_BY_STATE } from 'Helper/Constants/endPoint';
import userImg from 'Assets/Images/user.svg'
import { ENTER_CITY, ENTER_EMAIL, ENTER_FIRST_NAME, ENTER_FIRST_NAME_3CHARS, ENTER_LAST_NAME, ENTER_PHONE, ENTER_VALID_EMAIL, ENTER_ZIP, SELECT_COUNTRY, SELECT_CURRENCY, SELECT_DATE_FORMAT, SELECT_JOBFUNCTION, SELECT_LANGUAGE, SELECT_TIMEZONE, SELECT_TIME_FORMAT, ENTER_PASSWORD, ENTER_VALID_PASSWORD, CONFIRM_PASSWORD_NOT_MATCH, ENTER_CONFIRM_PASSWORD, ENTER_ROLE, ENTER_STATE, ENTER_OTP, SPECIAL_CHARACTERS, SPECIAL_NUM_CHARACTERS, SELECT_STATE, SELECT_CITY } from 'Helper/Constants/validationMessage';
import { RSUploadImageComponent } from 'Components/UploadImage/RSUploadImageComponent';
import { getLsMasterDataDropdown, validatePassword } from 'Helper/Utils/Utils';
import { MAX_LOGIN_OTP } from 'Helper/Constants/inputLimit';
import { RSPTimer } from 'Components/RSPTimer';

const RegexForMobileNUmber = "[0-9](?=.*.{0})"

class MyProfile extends Component {

    static contextType = authContext;

    state = {
        ...this.context.globalStateData.getMyProfileData,
        ...{
            isShowCoundown: false,
            isPasswodShowCoundown: false,
            isPasswordShowOTP: false,
            isShowOTP: false,
            userOtp: "",
            passwordUserOtp: "",
            otpSuccess: false,
            passwordOtpSuccess: false,
            otpValidation: false,
            passwordOtpValidation: false,
            passwordOtpToken: "",
            otpToken: "",
            sendOtpErrMsg: '',
            passwordSendOtpErrMsg: '',
            isShowResendBtn: false,
            isPasswordShowResendBtn: false,
            isShowCurrencyField: false,
            isShowLanguageField: false,
            isShowDateField: false,
            isShowTimeField: false,
            isShowTimeZoneField: false,
            maskedEmail: "",
            maskedPhNo: "",
            defaultOtp: true,
            passwordDefaultOtp: true,
            imagePath: getServerConfig().slice(0, -1),
            imageType: ""
        }
    }
    handledropdown = (countryId, stateId, cityId) => {
        const params = {
            countryID: countryId
        }
        connectServer({
            path: GET_STATE_BY_COUNTRY,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        stateDropdownData: res.data, stateDropdownItem: stateId === -1 ? {
                            "stateID": -1,
                            "state": "-- Select state --",
                        } : res.data.filter(
                            item => item.stateID === stateId
                        )[0] || {
                            "stateID": -1,
                            "state": "-- Select state --",
                        }
                    })
                    this.handledropdownForCity(stateId, cityId)
                }
            },
            fail: error => {
            }
        })

    }
    handledropdownForCity = (stateId, cityId) => {
        const params = {
            stateId: stateId
        }
        connectServer({
            path: GET_CITY_BY_STATE,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        cityDropdownData: res.data,
                        cityDropdownItem: cityId === -1 ? {
                            cityId: -1,
                            city: '-- Select city --'
                        } : res.data.filter(
                            item => item.cityId === cityId
                        )[0] || {
                            cityId: -1,
                            city: '-- Select city --'
                        }
                    })
                } else {
                    this.setState({
                        cityDropdownData: [],
                        cityDropdownItem: {
                            cityId: -1,
                            city: '-- Select city --'
                        }
                    })
                }
            },
            fail: error => {
            }
        })

    }
    setStateData = data => {
        if (!data) { return }
        this.handledropdown(data.countryId, data.stateId, data.cityId)
        this.setState({
            emailId: data.email || "",
            maskedEmail: data.maskedEmail,
            massedPhNo: data.maskedPhoneNo,
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            role: data.securityGroupName || "",
            jobFunctionDropdownItem:
                this.state.jobFunctionDropdownData.filter(
                    item => item.jobfunctionId === data.jobFunctionId
                )[0] || null,
            countryDropdownItem:
                this.state.countryDropdownData.filter(
                    item => item.countryId === data.countryId
                )[0] || null,
            currencyDropdownItem:
                this.state.currencyDropdownData.filter(
                    item => item.currencyId === data.currencyId
                )[0] || null,
            isShowCurrencyField: data.currencyId !== null ? true : false,
            languageDropdownItem:
                this.state.languageDropdownData.filter(item => item.languageId === data.languageId)[0] || null,
            isShowLanguageField: data.languageId !== null ? true : false,
            timeFormatDropdownItem:
                this.state.timeFormatDropdownData.filter(item => item.timeFormatId === data.timeFormatId)[0] || null,
            isShowTimeField: data.timeFormatId !== null ? true : false,
            phoneNo: data.phoneNo || "",
            countryCode: data.countryCode || '',
            newCountryCode: data.countryCode || '',
            countryCodeMobile: data.countryCodeMobile || '',
            newCountryCodeMobile: data.countryCodeMobile || '',
            addressLine: data.addressLine || "",
            city: data.city || "",
            state: data.state || "",
            zipCode: data.zipCode || "",
            dateFormatDropdownItem:
                this.state.dateFormatDropdownData.filter(
                    item => item.dateFormatId === data.dateFormatId)[0] || null,
            isShowDateField: data.dateFormatId !== null ? true : false,
            timeZoneDropdownItem: this.state.timeZoneDropdownData.filter(item => item.timezoneId === data.timeZoneId)[0] || null,
            isShowTimeZoneField: data.timeZoneId !== null ? true : false,
            isDayLightSaving: data.isDayLight || false,
            profilePath: data.profilePath || null,
            newImg: data.profilePath || null
        })
        setTimeout(() => {
            const tempNum = this.state.phoneNo
            document.getElementsByClassName('maskedPhoneNo')[0].children[1].value = tempNum.replaceAll(/(..)./g, "$1*")
            if (this.state.mobileChangeShow) {
                document.getElementsByClassName('maskedPhoneNo')[1].children[1].value = tempNum.replaceAll(/(..)./g, "$1*")
            }
        }, 10);

    }
    setDropdownStates = () => {
        // const data = this.context.globalStateData.getMasterDropdownData
        const data = getLsMasterDataDropdown()
        this.setState({
            jobFunctionDropdownData: data.jobFunction || [],
            countryDropdownData: data.countryMaster || [],
            currencyDropdownData: data.currencyMaster || [],
            languageDropdownData: data.languageMaster || [],
            dateFormatDropdownData: data.dateFormat || [],
            timeFormatDropdownData: data.timeFormat || [],
            timeZoneDropdownData: data.timeZone || []
        })
    }
    componentDidMount = props => {
        window.scrollTo(0, 0);
        if (this.context.globalStateData.getIsPrototype) {
            return
        }
        this.setDropdownStates();
        this.getMyProfileData()
    }

    componentDidUpdate = props => {
        const tempNum = this.state.phoneNo
        document.getElementsByClassName('maskedPhoneNo')[0].children[1].value = tempNum.replaceAll(/(..)./g, "$1*")
        if (this.state.mobileChangeShow) {
            document.getElementsByClassName('maskedPhoneNo')[1].children[1].value = tempNum.replaceAll(/(..)./g, "$1*")
        }
    }

    getMyProfileData = () => {
        const params = { userId: getAuthUserId() }
        connectServer({
            path: GET_USER_PROFILE,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let tempUserData = { ...getUserData() };
                    tempUserData.mobileNo = res.data.phoneNo;
                    tempUserData.maskedMobileNo = res.data.maskedPhoneNo;
                    tempUserData.countryCodeMobile = res.data.countryCodeMobile;
                    localStorage.setItem("lsUserData", convertObjectToBase64(tempUserData))
                    this.setStateData(res.data)
                }
            },
            fail: err => {
            }
        })
    }

    validateOtp = () => {

        const params = {
            otpToken: this.state.otpToken,
            otp: this.state.userOtp
            // userId: getAuthUserId()
        }

        connectServer({
            path: VALIDATE_OTP,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        isShowCoundown: true,
                        otpValidation: true,
                        otpSuccess: true
                    })
                } else {
                    this.setState({
                        otpValidation: false,
                        otpSuccess: false,
                        defaultOtp: false,
                        errMsgOTP: res.message,
                    })
                }
            },
            fail: err => {
                this.setState({
                    otpValidation: false,
                    otpSuccess: false, defaultOtp: false,
                    errMsgOTP: err.message,
                })
            }
        })
    }
    passwordValidateOtp = () => {

        const params = {
            otpToken: this.state.passwordOtpToken,
            otp: this.state.passwordUserOtp
            // userId: getAuthUserId()
        }

        connectServer({
            path: VALIDATE_OTP,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        isPasswodShowCoundown: true,
                        passwordOtpValidation: true,
                        passwordOtpSuccess: true
                    })
                } else {
                    this.setState({
                        passwordOtpValidation: false,
                        passwordOtpSuccess: false,
                        passwordDefaultOtp: false,
                        errMsgPasswordOTP: res.message,
                    })
                }
            },
            fail: err => {
                this.setState({
                    passwordOtpValidation: false,
                    passwordOtpSuccess: false, passwordDefaultOtp: false,
                    errMsgPasswordOTP: err.message,
                })
            }
        })
    }

    otpTimer = ({ minutes, seconds, completed }) => {
        if (completed) {
            this.setState({ isShowResendBtn: true })
            return "Expired"
        } else {
            return <span>valid for 2 minutes ({minutes}:{seconds})</span>
        }
    }

    resetCounter = () => {
        return Date.now() + 120000
    }

    reSendOtp = (data) => {

        let newState1 = { ...this.state }
        newState1["newPhoneNumber"] = newState1.newPhoneNumber?.slice(newState1.newCountryCodeMobile?.length)

        const { newPhoneNumber } = newState1

        const params = {
            "countryCodeMobile": `${this.state.newCountryCodeMobile}`,
            "mobileNumber": newPhoneNumber
        }

        connectServer({
            path: REQUEST_OTP,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        otpToken: res?.otpToken,
                        isShowResendBtn: false,
                        userOtp: '',
                        errMsgOTP: 'One time password sent successfully',
                        otpSuccess: false,
                        otpValidation: false,
                        defaultOtp: true
                    })
                } else {
                    this.setState({ sendOtpErrMsg: res?.message })
                }
            },
            fail: err => {
                this.setState({
                    sendOtpErrMsg: err.message
                })
            }
        })

    }

    updateMobileNumber = (oldNumber, newNumber) => {
        const params = {
            otpToken: this.state.otpToken,
            otp: this.state.userOtp,
            userId: getAuthUserId()
        }
        connectServer({
            path: MOBILE_NO_OTP_VALIDATION,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                this.setState({
                    show: false,
                    userOtp: "",
                    newPhoneNumber: "",
                    mobileChangeShow: false,
                    errMsgOTP: null,
                    otpSuccess: false,
                    otpValidation: false,
                    defaultOtp: true,
                    isShowOTP: false,
                })
                this.getMyProfileData()
            },
            fail: err => {

            }
        })

    }

    handleClose = () => this.setState({
        show: false,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        pwdStrengthMeter: "",
        conpwdStrengthMeter: "",
        mobileChangeShow: false,
        newPhoneNumber: "",
        userOtp: "",
        errMsgOTP: null,
        otpSuccess: false,
        otpValidation: false,
        defaultOtp: true,
        isShowOTP: false,
        isPasswodShowCoundown: false,
        isPasswordShowOTP: false,
        passwordUserOtp: "",
        passwordOtpSuccess: false,
        passwordOtpValidation: false,
        passwordOtpToken: "",
        passwordSendOtpErrMsg: '',
        isPasswordShowResendBtn: false,
        passwordDefaultOtp: true,
        errMsgPasswordOTP: null,
        errMsgNewPassword: null,
        errMsgConfirmPassword: null,
        errMsgCurrentPassword: null,
        errMsgNewPhone: null
    });

    handleShow = () => this.setState({ show: true });

    handleChangeMobileShow = () => this.setState({ mobileChangeShow: true })

    changeMobileNumber = (oldMobileNo, oldCountryDialCode, newMobileNo, newCountryDialCode, newCountryCode, oldCountryCode) => {
        this.setState({ isShowResendBtn: false })
        let params = {
            "userId": getAuthUserId(),
            "oldMobileNumber": oldMobileNo,
            "oldCountryCodeMobile": oldCountryDialCode,
            "newMobileNumber": newMobileNo,
            "newCountryCodeMobile": newCountryDialCode,
            "newCountryCode": newCountryCode,
            "OldCountryCode": oldCountryCode
        }
        connectServer({
            path: CHANGE_MOBILE_NUMBER,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        isShowOTP: true,
                        otpToken: res.message,
                        errMsgOTP: 'One time password sent successfully',
                        otpSuccess: false,
                        userOtp: "",
                        otpValidation: false,
                        defaultOtp: true
                    })
                } else {
                    this.setState({ errMsgNewPhone: res.message })
                }
            },
            fail: err => { }
        })
    }

    requestOTPForPasswordChange = (oldPassword, newPassword) => {
        this.setState({ isPasswordShowResendBtn: false })
        let params = {
            "emailId": this.state.emailId,
            "oldPassword": oldPassword,
            "newPassword": newPassword,

        }
        connectServer({
            path: CHANGE_PASSWORD_OTP,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        isPasswordShowResendBtn: false,
                        passwordUserOtp: '',
                        errMsgPasswordOTP: 'One time password sent successfully',
                        isPasswordShowOTP: true,
                        passwordOtpToken: res.data,
                        passwordOtpSuccess: false,
                        passwordDefaultOtp: true,
                        passwordOtpValidation: false
                    })
                } else {
                    this.setState({ errMsgCurrentPassword: res.message })
                }
            },
            fail: err => { }
        })
    }

    handlePasswordChange = () => {
        const { emailId, currentPassword, newPassword, confirmPassword, passwordUpdate, passwordOtpToken, passwordUserOtp } = this.state;
        if (isEmpty(currentPassword)) {
            this.setState({ errMsgCurrentPassword: ENTER_PASSWORD })
            return
        }
        if (!validatePassword(currentPassword)) {
            this.setState({ errMsgCurrentPassword: ENTER_VALID_PASSWORD })
            return
        }
        if (isEmpty(newPassword)) {
            this.setState({ errMsgNewPassword: ENTER_PASSWORD })
            return
        }
        if (!validatePassword(newPassword)) {
            this.setState({ errMsgNewPassword: ENTER_VALID_PASSWORD })
            return
        }
        if (isEmpty(confirmPassword)) {
            this.setState({ errMsgConfirmPassword: ENTER_CONFIRM_PASSWORD })
            return
        }
        if (newPassword !== confirmPassword) {
            this.setState({ errMsgConfirmPassword: CONFIRM_PASSWORD_NOT_MATCH })
            return
        }
        const params = {
            emailId: emailId,
            oldPassword: currentPassword,
            newPassword: newPassword,
            otp: passwordUserOtp,
            otpkey: passwordOtpToken
        }
        connectServer({
            loading: this.context.globalStateData.setIsLoadingData,
            path: CHANGE_USER_PASSWORD,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            retryTime: 5000,
            ok: res => {
                if (res.status) {
                    this.setState({ passwordUpdate: true });
                    this.handleClose();
                    setTimeout(() => {
                        this.setState({ passwordUpdate: false });
                    }, 3000)
                }
            },
            fail: err => {
            }
        })
    }

    render() {
        let { errMsgEmailId, errMsgFirstName, errMsgLastName, errMsgRole, errMsgJobFunction, errMsgPhoneNo,
            errMsgProfilePath, errMsgCurrentPassword, errMsgNewPassword, errMsgConfirmPassword, passwordUpdate,
            pwdStrengthMeter, conpwdStrengthMeter, errMsgNewPhone, errMsgOTP } = this.state
        const remIcon = this.state.newImg && this.state.profilePath
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <h1 className='header-title-margin'>My profile</h1>
                    <div className="portlet-box-theme">
                        <Row>
                            <Col md={2} sm={3}>
                                <div className='stricky-window my-profile'>
                                    <RSUploadImageComponent
                                        className={`enable-remove`}
                                        defaultImg={userImg}
                                        tooltipText='profile picture'
                                        defaultValue={this.state.profilePath}
                                        newImg={this.state.newImg ? `${this.state.imagePath}${this.state.newImg}` : null}
                                        text={`${!this.state.newImg && !this.state.profilePath ? "Upload logo" : "Edit logo"}`}
                                        icon={`${!this.state.newImg && !this.state.profilePath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                        cb={img => this.setState({ profilePath: img, newImg: null, errMsgProfilePath: null })}
                                        getImageType={(value) => {
                                            this.setState({ imageType: value });
                                        }}
                                        error={(value) => {
                                            this.setState({
                                                errMsgProfilePath: value,
                                                newImg: null,
                                                profilePath: this.state.profilePath,
                                            });
                                        }}
                                        errorMessage={errMsgProfilePath}
                                        removeIcon={this.state.profilePath !== null}
                                        handleRemove={() => {
                                            this.setState({
                                                profilePath: null,
                                                newImg: null,
                                            });
                                        }
                                        }
                                    />
                                    {/* <RSUploadImageComponent
                                        className={`enable-remove`}
                                        defaultImg={userImg}
                                        defaultValue={this.state.profilePath}
                                        newImg={this.state.newImg ? `${this.state.imagePath}${this.state.newImg}` : null}
                                        text={`${!this.state.newImg && !this.state.profilePath ? "Upload image" : "Remove image"}`}
                                        icon={`${!this.state.newImg && !this.state.profilePath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                        cb={img => this.setState({ profilePath: img, newImg: null, errMsgProfilePath: null })}
                                        getErrMsg={(errMsg) => { this.setState({ errMsgProfilePath: errMsg }) }}
                                    />
                                    {this.state.profilePath !== null &&
                                        <div className="remove-profile cp" onClick={() => {
                                            this.setState({
                                                profilePath: null,
                                                newImg: null
                                            })
                                        }}>
                                            <i className="icon-sd-circle-close-medium icons-md"></i>
                                            <span className="fs13 cp ml5">Remove</span>
                                        </div>
                                    } */}
                                    {/* <div onClick={() => {
                                        this.setState({
                                            profilePath: null,
                                            newImg: null
                                        })
                                    }}>Remove</div> */}
                                </div>
                            </Col>
                            {/* second column */}
                            <Col md={10}>
                                <Row>
                                    <Col>
                                        <h4 className='mb23'>Personal details</h4>
                                        <RSInput2
                                            name="email"
                                            ph="Enter your email ID"
                                            cls="click-off"
                                            val={this.state.maskedEmail}
                                            required={true}
                                            errorMessage={errMsgEmailId}
                                            cb={t => {
                                                let errMsg = validateEmail(t) ? null : ENTER_VALID_EMAIL
                                                this.setState({ emailId: t, errMsgEmailId: errMsg })
                                            }}
                                        />
                                        <RSInput2
                                            name="first name"
                                            ph="First name"
                                            val={this.state.firstName}
                                            required={true}
                                            max={150}
                                            errorMessage={errMsgFirstName}
                                            cb={text => {
                                                if (!isEmpty(text)) {
                                                    var regex = /^[a-zA-Z ]*$/;
                                                    if (regex.test(text)) {
                                                        this.setState({ firstName: text, errMsgFirstName: null })
                                                    } else {
                                                        this.setState({ errMsgFirstName: SPECIAL_NUM_CHARACTERS })
                                                    }
                                                } else {
                                                    this.setState({ firstName: text, errMsgFirstName: ENTER_FIRST_NAME })
                                                }
                                            }}
                                            ob={t => {
                                                t = t.trimEnd();
                                                t = t.trimStart();
                                                let errMsg =
                                                    isEmpty(t) ? ENTER_FIRST_NAME : t.length >= 3 ? null : ENTER_FIRST_NAME_3CHARS
                                                this.setState({ firstName: t, errMsgFirstName: errMsg })
                                            }}
                                        />
                                        <RSInput2
                                            name="Last name"
                                            ph="Last name"
                                            val={this.state.lastName}
                                            required={true}
                                            max={150}
                                            errorMessage={errMsgLastName}
                                            cb={text => {
                                                if (!isEmpty(text)) {
                                                    var regex = /^[a-zA-Z ]*$/;
                                                    if (regex.test(text)) {
                                                        this.setState({ lastName: text, errMsgLastName: null })
                                                    } else {
                                                        this.setState({ errMsgLastName: SPECIAL_NUM_CHARACTERS })
                                                    }
                                                } else {
                                                    this.setState({ lastName: text, errMsgLastName: ENTER_LAST_NAME })
                                                }
                                            }}
                                            ob={t => {
                                                t = t.trimEnd();
                                                t = t.trimStart();
                                                let erMsg = t.length > 0 ? null : ENTER_LAST_NAME
                                                this.setState({ lastName: t, errMsgLastName: erMsg })
                                            }}
                                        />
                                        <RSInput2
                                            name="address"
                                            ph="Address"
                                            val={this.state.addressLine}
                                            required={true}
                                            max={255}
                                            errorMessage={this.state.errMsgAddressLine}
                                            cb={(t) => {
                                                let errMsg = t.length > 0 ? null : "Enter address"
                                                this.setState({
                                                    addressLine: t,
                                                    errMsgAddressLine: errMsg
                                                })
                                            }}
                                        />
                                        <RSInput2
                                            name="role"
                                            ph="Role"
                                            val={this.state.role}
                                            required={true}
                                            errorMessage={errMsgRole}
                                            cb={t => {
                                                let errMsg = t.length > 0 ? null : ENTER_ROLE
                                                this.setState({ role: t, errMsgRole: errMsg })
                                            }}
                                        />

                                        <div className='position-relative'>
                                            {errMsgJobFunction && (
                                                <div className='validation-message'>
                                                    {errMsgJobFunction}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                value={this.state.jobFunctionDropdownItem}
                                                defaultItem={{
                                                    jobfunctionId: -1,
                                                    jobfunctionName: '-- Select job function --'
                                                }
                                                }
                                                textField='jobfunctionName'
                                                dataItemKey='jobfunctionId'
                                                className='required'
                                                data={this.state.jobFunctionDropdownData}
                                                onChange={e => {
                                                    let errMsg = e.target.value.jobfunctionId !== -1 ? null : SELECT_JOBFUNCTION
                                                    this.setState({
                                                        jobFunctionDropdownItem: e.target.value,
                                                        errMsgJobFunction: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ height: 36 }}></div>
                                        <div style={{ height: 73 }}>
                                            <label className="rs-link orange-medium fs14 mt17" onClick={() => this.handleShow()}>Change password?</label>
                                            {passwordUpdate && <span className='green'> Password updated successfully</span>}
                                        </div>
                                        <div className="position-relative">
                                            {errMsgPhoneNo && <div className="validation-message">{errMsgPhoneNo}</div>}
                                            <RSPhoneInput
                                                disabled={true}
                                                className="required click-off maskedPhoneNo"
                                                country={this.state.countryCode}
                                                // value={this.state.countryCodeMobile + this.state.phoneNo}
                                                errorMessage={this.state.errMsgPhoneNo}
                                                onChange={(phone, country) => {
                                                    let errMsg = null;
                                                    this.setState({
                                                        phoneNo: phone,
                                                        countryCode: country.countryCode,
                                                        countryCodeMobile: country.dialCode,
                                                        errMsgPhoneNo: errMsg
                                                    })
                                                }} />
                                            <small className="input-desc text-end width100p">
                                                {/* OTP will be sent to this mobile number. */}
                                                <label
                                                    className='rs-link orange-medium'
                                                    onClick={() => this.handleChangeMobileShow()}
                                                >
                                                    Change mobile number?
                                                </label>
                                            </small>
                                        </div>
                                        <div className='position-relative'>
                                            {this.state.errMsgCountry && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgCountry}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='country'
                                                dataItemKey='countryId'
                                                className="required"
                                                value={this.state.countryDropdownItem}
                                                data={this.state.countryDropdownData}
                                                defaultItem={{
                                                    countryId: -1,
                                                    country: '-- Select country --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg = e.target.value.countryId !== -1 ? null : SELECT_COUNTRY
                                                    this.handledropdown(e.target.value.countryId, -1, -1)
                                                    this.setState({
                                                        countryDropdownItem: e.target.value,
                                                        errMsgCountry: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='position-relative'>
                                            {this.state.errMsgState && <div className="validation-message">{this.state.errMsgState}</div>}
                                            <RSDropdownList
                                                className="required"
                                                textField="state"
                                                dataItemKey="stateID"
                                                data={this.state.stateDropdownData}
                                                value={this.state.stateDropdownItem}
                                                defaultItem={{
                                                    "stateID": -1,
                                                    "state": "-- Select state --",
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.state.length > 0 ? null : SELECT_STATE
                                                    this.setState({ stateDropdownItem: e.target.value, errMsgState: errMsg });
                                                    this.handledropdownForCity(e.target.value.stateID, -1)
                                                }}
                                            />
                                        </div>
                                        <div className='position-relative'>
                                            {this.state.errMsgCity && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgCity}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='city'
                                                dataItemKey='cityId'
                                                className="required"
                                                value={this.state.cityDropdownItem}
                                                data={this.state.cityDropdownData}
                                                defaultItem={{
                                                    cityId: -1,
                                                    city: '-- Select city --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg = e.target.value.cityId !== -1 ? null : SELECT_CITY
                                                    this.setState({
                                                        cityDropdownItem: e.target.value,
                                                        errMsgCity: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                        <RSInput2
                                            name="Zipcode"
                                            ph={ENTER_ZIP}
                                            max={50}
                                            val={this.state.zipCode}
                                            required={true}
                                            errorMessage={this.state.errMsgZipCode}
                                            cb={t => {
                                                if (t === "") {
                                                    this.setState({ zipCode: "" })
                                                    return
                                                }
                                                var regex = /^[a-zA-Z0-9]+$/;
                                                if (t !== "" && regex.test(t)) {
                                                    let errMsg = t.length > 0 ? null : ENTER_ZIP
                                                    this.setState({ zipCode: t, errMsgZipCode: errMsg })
                                                } else {
                                                    this.setState({ errMsgZipCode: "Accept only alphanumeric" })
                                                }
                                            }}
                                            ob={(text) => {
                                                if (text === "") {
                                                    this.setState({ zipCode: "", errMsgZipCode: ENTER_ZIP })
                                                    return
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4 className='my23'>Localization details</h4>

                                        <div className={`position-relative `}>
                                            {this.state.errMsgCurrency && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgCurrency}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='currencyName'
                                                dataItemKey='currencyId'
                                                className={"required"}
                                                data={this.state.currencyDropdownData}
                                                value={this.state.currencyDropdownItem}
                                                defaultItem={{
                                                    currencyId: -1,
                                                    currencyName: '-- Select currency --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg =
                                                        e.target.value.currencyName.length > 0
                                                            ? null
                                                            : SELECT_CURRENCY
                                                    this.setState({
                                                        currencyDropdownItem: e.target.value,
                                                        errMsgCurrency: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={`position-relative`}>
                                            {this.state.errMsgLanguage && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgLanguage}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='languageName'
                                                dataItemKey='languageId'
                                                data={this.state.languageDropdownData}
                                                value={this.state.languageDropdownItem}
                                                className={"required"}
                                                defaultItem={
                                                    {
                                                        languageId: -1,
                                                        languageName: '-- Select language --'
                                                    }
                                                }
                                                onChange={e => {
                                                    let errMsg =
                                                        e.target.value.languageName.length > 0
                                                            ? null
                                                            : SELECT_LANGUAGE
                                                    this.setState({
                                                        languageDropdownItem: e.target.value,
                                                        errMsgLanguage: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={`position-relative `}>
                                            {this.state.errMsgTimezone && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgTimezone}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='timezoneName'
                                                dataItemKey='timezoneId'
                                                className={"required"}
                                                data={this.state.timeZoneDropdownData}
                                                value={this.state.timeZoneDropdownItem}
                                                defaultItem={{
                                                    timezoneId: -1,
                                                    timezoneName: '-- Select time zone --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg =
                                                        e.target.value.timezoneName.length > 0
                                                            ? null
                                                            : SELECT_TIMEZONE
                                                    this.setState({
                                                        timeZoneDropdownItem: e.target.value,
                                                        errMsgTimezone: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ height: 69 }}> </div>

                                        <div className={`position-relative `}>
                                            {this.state.errMsgDateFormat && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgDateFormat}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='dateFormat'
                                                dataItemKey='dateFormatId'
                                                className={"required"}
                                                data={this.state.dateFormatDropdownData}
                                                value={this.state.dateFormatDropdownItem}
                                                defaultItem={{
                                                    dateFormatId: -1,
                                                    dateFormat: '-- Select date format --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg =
                                                        e.target.value.dateFormat.length > 0
                                                            ? null
                                                            : SELECT_DATE_FORMAT
                                                    this.setState({
                                                        dateFormatDropdownItem: e.target.value,
                                                        errMsgDateFormat: errMsg
                                                    })
                                                }}
                                            />
                                        </div>

                                        <div className={`position-relative `}>
                                            {this.state.errMsgTimeFormat && (
                                                <div className='validation-message'>
                                                    {this.state.errMsgTimeFormat}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                textField='timeFormat'
                                                dataItemKey='timeFormatId'
                                                className={"required"}
                                                data={this.state.timeFormatDropdownData}
                                                value={this.state.timeFormatDropdownItem}
                                                defaultItem={{
                                                    timeFormatId: -1,
                                                    timeFormat: '-- Select time format --'
                                                }
                                                }
                                                onChange={e => {
                                                    let errMsg =
                                                        e.target.value.timeFormat.length > 0
                                                            ? null
                                                            : SELECT_TIME_FORMAT
                                                    this.setState({
                                                        timeFormatDropdownItem: e.target.value,
                                                        errMsgTimeFormat: errMsg
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={`${!this.state.timeZoneDropdownItem?.isDayLight ? "click-off" : ""}`}>
                                            <RSCheckbox
                                                className="mt10"
                                                checked={this.state.isDayLightSaving}
                                                cb={status => {
                                                    this.setState({ isDayLightSaving: status })
                                                }}
                                                lbl="Daylight saving" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="btn-container d-flex justify-content-end">
                        <RSSecondaryBtn onClick={() => {
                            let tempState = { ...this.state }
                            tempState.errMsgEmailId = null;
                            tempState.errMsgDateFormat = null;
                            tempState.errMsgTimeFormat = null;
                            tempState.errMsgLanguage = null;
                            tempState.errMsgCurrency = null;
                            tempState.errMsgFirstName = null;
                            tempState.errMsgLastName = null;
                            tempState.errMsgRole = null;
                            tempState.errMsgCountry = null;
                            tempState.errMsgPhoneNo = null;
                            tempState.errMsgAddressLine = null;
                            tempState.errMsgJobFunction = null;
                            tempState.errMsgCity = null;
                            tempState.errMsgState = null;
                            tempState.errMsgZipCode = null;
                            tempState.errMsgTimezone = null;
                            tempState.errMsgProfilePath = null;
                            tempState.errMsgOTP = null;
                            tempState.errMsgPasswordOTP = null;
                            tempState.errMsgCurrentPassword = null;
                            tempState.errMsgNewPassword = null;
                            tempState.errMsgConfirmPassword = null;
                            tempState.errMsgNewPhone = null;
                            this.context.globalStateData.setMyProfileData(tempState)
                            this.props.history.push(`/preferences`)
                        }}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {

                            if (this.context.globalStateData.getIsPrototype) {
                                this.props.history.push(`/preferences`)
                                return
                            }

                            const { profilePath, imageType, addressLine, role, emailId, firstName, lastName, countryDropdownItem, stateDropdownItem, cityDropdownItem, dateFormatDropdownItem, timeFormatDropdownItem,
                                phoneNo, isDayLightSaving, city, state, zipCode, jobFunctionDropdownItem, currencyDropdownItem, languageDropdownItem, timeZoneDropdownItem, countryCode, countryCodeMobile } = this.state

                            // if (!profilePath) {
                            //     this.setState({ errMsgProfilePath: UPLOAD_PROFILE_IMAGE })
                            //     return
                            // }
                            let isValidate = true;
                            if (isEmpty(emailId)) {
                                this.setState({ errMsgEmailId: ENTER_EMAIL })
                                isValidate = false;
                            }
                            if (!validateEmail(emailId)) {
                                this.setState({ errMsgEmailId: ENTER_VALID_EMAIL })
                                isValidate = false;
                            }
                            if (isEmpty(firstName)) {
                                this.setState({ errMsgFirstName: ENTER_FIRST_NAME })
                                isValidate = false;
                            }
                            if (firstName.length < 3) {
                                this.setState({ errMsgFirstName: ENTER_FIRST_NAME_3CHARS })
                                isValidate = false;
                            }

                            if (isEmpty(lastName)) {
                                this.setState({ errMsgLastName: ENTER_LAST_NAME })
                                isValidate = false;
                            }

                            if (isEmpty(role)) {
                                this.setState({ errMsgRole: ENTER_ROLE })
                                isValidate = false;
                            }

                            if (jobFunctionDropdownItem === null || isEmpty(jobFunctionDropdownItem.jobfunctionName) || jobFunctionDropdownItem.jobfunctionName.toLowerCase().includes("select")) {
                                this.setState({ errMsgJobFunction: SELECT_JOBFUNCTION })
                                isValidate = false;
                            }
                            if (isEmpty(phoneNo)) {
                                this.setState({ errMsgPhoneNo: ENTER_PHONE })
                                isValidate = false;
                            }

                            if (isEmpty(addressLine)) {
                                this.setState({ errMsgAddressLine: "Enter address" })
                                isValidate = false;
                            }
                            // if (isEmpty(city)) {
                            //     this.setState({ errMsgCity: ENTER_CITY })
                            //     isValidate = false;
                            // } else if (city.length < 3) {
                            //     this.setState({ errMsgCity: ENTER_FIRST_NAME_3CHARS });
                            //     isValidate = false;
                            // }

                            if (countryDropdownItem === null || isEmpty(countryDropdownItem.country) || countryDropdownItem.country.toLowerCase().includes("select")) {
                                this.setState({ errMsgCountry: SELECT_COUNTRY })
                                isValidate = false;
                            }
                            if (stateDropdownItem === null || isEmpty(stateDropdownItem.state) || stateDropdownItem.state.toLowerCase().includes("select")) {
                                this.setState({ errMsgState: SELECT_STATE })
                                isValidate = false;
                            }
                            if (cityDropdownItem === null || isEmpty(cityDropdownItem.city) || cityDropdownItem.city.toLowerCase().includes("select")) {
                                this.setState({ errMsgCity: SELECT_CITY })
                                isValidate = false;
                            }

                            if (isEmpty(zipCode)) {
                                this.setState({ errMsgZipCode: ENTER_ZIP })
                                isValidate = false;
                            }
                            if (currencyDropdownItem === null || isEmpty(currencyDropdownItem.currencyName) || currencyDropdownItem.currencyName.toLowerCase().includes("select")) {
                                this.setState({ errMsgCurrency: SELECT_CURRENCY })
                                isValidate = false;
                            }

                            if (languageDropdownItem === null || isEmpty(languageDropdownItem.languageName) || languageDropdownItem.languageName.toLowerCase().includes("select")) {
                                this.setState({ errMsgLanguage: SELECT_LANGUAGE })
                                isValidate = false;
                            }

                            if (timeZoneDropdownItem === null || isEmpty(timeZoneDropdownItem.timezoneName) || timeZoneDropdownItem.timezoneName.toLowerCase().includes("select")) {
                                this.setState({ errMsgTimezone: SELECT_TIMEZONE })
                                isValidate = false;
                            }

                            if (dateFormatDropdownItem === null || isEmpty(dateFormatDropdownItem.dateFormat) || dateFormatDropdownItem.dateFormat.toLowerCase().includes("select")) {
                                this.setState({ errMsgDateFormat: SELECT_DATE_FORMAT })
                                isValidate = false;
                            }

                            if (timeFormatDropdownItem === null || isEmpty(timeFormatDropdownItem.timeFormat) || timeFormatDropdownItem.timeFormat.toLowerCase().includes("select")) {
                                this.setState({ errMsgTimeFormat: SELECT_TIME_FORMAT })
                                isValidate = false;
                            }
                            if (isValidate) {
                                const params =
                                {
                                    "userId": getAuthUserId(),
                                    "firstName": firstName,
                                    "lastName": lastName,
                                    "profilePath": profilePath || "",
                                    "jobFunctionID": jobFunctionDropdownItem.jobfunctionId,
                                    "countryId": countryDropdownItem.countryId,
                                    "stateId": stateDropdownItem.stateID,
                                    "cityId": cityDropdownItem.cityId,
                                    "languageId": languageDropdownItem.languageId,
                                    "timeZoneId": timeZoneDropdownItem.timezoneId,
                                    "currencyId": currencyDropdownItem.currencyId,
                                    "dateFormatId": dateFormatDropdownItem.dateFormatId,
                                    "timeFormatId": timeFormatDropdownItem.timeFormatId,
                                    "state": state,
                                    "city": city,
                                    "address": addressLine,
                                    "zipCode": zipCode,
                                    "isDayLight": isDayLightSaving,
                                    "countryCode": countryCode,
                                    "countryCodeMobile": countryCodeMobile,
                                    "phoneNumber": phoneNo,
                                    "profileImageType": imageType
                                }

                                connectServer({
                                    loading: this.context.globalStateData.setIsLoadingData,
                                    path: UPDATE_USER_PROFILE,
                                    params,
                                    sessionOut: this.context.globalStateData.setSessionOutData,
                                    context: this.context,
                                    retryTime: 5000,
                                    ok: res => {
                                        if (res.status) {
                                            this.props.history.push(`/preferences`)
                                        }
                                    },
                                    fail: err => {
                                        this.props.history.push(`/preferences`)
                                    }
                                })
                            }
                        }}>Save</RSPrimaryBtn>
                    </div>

                    {/* Change password */}
                    {this.state.show &&
                        <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <Modal.Header closeButton>
                                <h2>Change password</h2>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <Row>
                                        <Col>
                                            <RSTextInputWithIcon
                                                name="currentpassword"
                                                type="password"
                                                ph="Current password"
                                                required={true}
                                                val={this.state.currentPassword}
                                                // icon={"icon-sd-lock-medium icons-md"}
                                                max={15}
                                                errorMessage={errMsgCurrentPassword}
                                                eye
                                                cb={t => {
                                                    let errMsg = validatePassword(t) > 0 ? null : ENTER_VALID_PASSWORD
                                                    this.setState({ currentPassword: t, errMsgCurrentPassword: errMsg })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* ${pwdStrengthMeter === 100
                                            ? "status-good"
                                            : pwdStrengthMeter === 50
                                                ? "status-average"
                                                : "status-bad"
                                        } */}
                                        <Col className='position-relative'>
                                            <span className={`progress-bar-password 
                                                ${pwdStrengthMeter === 100
                                                    ? "status-good"
                                                    : pwdStrengthMeter === 80
                                                        ? "status-average"
                                                        : pwdStrengthMeter === 60
                                                            ? "status-week"
                                                            : pwdStrengthMeter === 40
                                                                ? "status-low" :
                                                                "status-bad"
                                                }
                                                `} style={{ right: '50px' }}>
                                                <i className='icon-sd-lock-medium white' />
                                            </span>
                                            <RSTextInputWithIcon
                                                name="newpassord"
                                                type="password"
                                                ph="New password"
                                                val={this.state.newPassword}
                                                required={true}
                                                max={15}
                                                // icon={"icon-sd-lock-medium icons-md input-icon-fill"}
                                                errorMessage={errMsgNewPassword}
                                                eye
                                                cb={t => {
                                                    this.setState({ pwdStrengthMeter: passwordStrengthMeter(t) })
                                                    let errMsg = validatePassword(t) > 0 ? null : ENTER_VALID_PASSWORD
                                                    this.setState({ newPassword: t, errMsgNewPassword: errMsg, confirmPassword: "", conpwdStrengthMeter: "" })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="position-relative">
                                            <span className={`progress-bar-password 
                                            ${this.state.conpwdStrengthMeter === 100
                                                    ? "status-good"
                                                    : this.state.conpwdStrengthMeter === 80
                                                        ? "status-average"
                                                        : this.state.conpwdStrengthMeter === 60
                                                            ? "status-week"
                                                            : this.state.conpwdStrengthMeter === 40
                                                                ? "status-low" :
                                                                "status-bad"
                                                }`} style={{ right: '50px' }}>
                                                <i className='icon-sd-lock-medium white' />
                                            </span>
                                            <RSTextInputWithIcon
                                                name="confirmpassword"
                                                type="password"
                                                ph="Re-enter new password"
                                                val={this.state.confirmPassword}
                                                required={true}
                                                max={15}
                                                // icon={"icon-sd-lock-medium icons-md input-icon-fill"}
                                                eye
                                                errorMessage={errMsgConfirmPassword}
                                                cb={t => {
                                                    let confirmPasswordStrength = false
                                                    if (this.state.newPassword === t) {
                                                        confirmPasswordStrength = true
                                                    }
                                                    this.setState({
                                                        conpwdStrengthMeter: confirmPasswordStrength ? passwordStrengthMeter(t) : ''
                                                    })
                                                    let errMsg = t.length > 0 ? this.state.newPassword === t ? null : CONFIRM_PASSWORD_NOT_MATCH : ENTER_CONFIRM_PASSWORD
                                                    this.setState({ confirmPassword: t, errMsgConfirmPassword: errMsg })
                                                }}
                                                ob={(t) => {
                                                    let confirmPasswordStrength = false
                                                    if (this.state.newPassword === t) {
                                                        confirmPasswordStrength = true
                                                    }
                                                    this.setState({
                                                        conpwdStrengthMeter: confirmPasswordStrength ? passwordStrengthMeter(t) : '', isPasswordShowOTP: false
                                                    })
                                                    let isEnable = true;
                                                    if (isEmpty(this.state.currentPassword)) {
                                                        this.setState({ errMsgCurrentPassword: ENTER_PASSWORD })
                                                        isEnable = false;
                                                    }
                                                    if (!validatePassword(this.state.currentPassword)) {
                                                        this.setState({ errMsgCurrentPassword: ENTER_VALID_PASSWORD })
                                                        isEnable = false;
                                                    }
                                                    if (isEmpty(this.state.newPassword)) {
                                                        this.setState({ errMsgNewPassword: ENTER_PASSWORD })
                                                        isEnable = false;
                                                    } else if (this.state.newPassword === this.state.currentPassword) {
                                                        this.setState({ errMsgNewPassword: "New and current password should not be same" })
                                                        isEnable = false;
                                                    }
                                                    if (!validatePassword(this.state.newPassword)) {
                                                        this.setState({ errMsgNewPassword: ENTER_VALID_PASSWORD })
                                                        isEnable = false;
                                                    }

                                                    if (isEmpty(this.state.confirmPassword)) {
                                                        this.setState({ errMsgConfirmPassword: ENTER_CONFIRM_PASSWORD })
                                                        isEnable = false;
                                                    } if (!isEmpty(this.state.confirmPassword) && this.state.newPassword !== this.state.confirmPassword) {
                                                        this.setState({ errMsgConfirmPassword: CONFIRM_PASSWORD_NOT_MATCH })
                                                        isEnable = false;
                                                    }
                                                    if (isEnable) {
                                                        this.requestOTPForPasswordChange(this.state.currentPassword, this.state.newPassword);
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                this.state.isPasswordShowOTP
                                                    ? <Col md={12} className='mb10'>
                                                        <RSTextInputWithIcon
                                                            required={true}
                                                            disabled={this.state.passwordOtpSuccess ? 'click-off' : ''}
                                                            name='otp'
                                                            // icon={`${this.state.otpSuccess ? "icon-tick-circle-small icons-md green" : ""}`}
                                                            icon={`${this.state.passwordOtpSuccess ? 'icon-sd-circle-tick-mini icons-md green' : this.state.passwordDefaultOtp ? "" : 'icon-sd-close-mini icons-sm red'}`}
                                                            ph={ENTER_OTP}
                                                            max={MAX_LOGIN_OTP}
                                                            keyboardType="number"
                                                            val={this.state.passwordUserOtp}
                                                            success_msg={this.state.errMsgPasswordOTP?.includes("success") ? "success_msg" : ''}
                                                            errorMessage={this.state.errMsgPasswordOTP}
                                                            cb={t => {
                                                                const re = /^[0-9\b]+$/;
                                                                if (t === '' || re.test(t)) {
                                                                    this.setState({ passwordUserOtp: t, errMsgPasswordOTP: null })
                                                                }
                                                            }}
                                                            ob={t => {

                                                                if (!t.length) {
                                                                    this.setState({ passwordUserOtp: t, errMsgPasswordOTP: ENTER_OTP })
                                                                    return
                                                                }
                                                                if (t.length === 6) {
                                                                    this.setState({ passwordUserOtp: t, errMsgPasswordOTP: null, passwordOtpValidation: false })
                                                                }

                                                                this.passwordValidateOtp()
                                                            }}
                                                        />
                                                        {this.state.isPasswordShowOTP ? <>

                                                            {!this.state.passwordOtpSuccess && !this.state.isPasswordShowResendBtn ?
                                                                <small className='input-desc'>
                                                                    One time password is valid for 5 minutes <RSPTimer
                                                                        onComplete={() => {
                                                                            this.setState({ isPasswordShowResendBtn: true, errMsgPasswordOTP: null })
                                                                        }}
                                                                    />
                                                                </small> : null
                                                            }
                                                            {!this.state.passwordOtpSuccess && this.state.isPasswordShowResendBtn ?
                                                                <small className='input-desc-right r-0'>
                                                                    <label
                                                                        className='rs-link orange-medium'
                                                                        onClick={() => {
                                                                            this.requestOTPForPasswordChange(this.state.currentPassword, this.state.newPassword);

                                                                        }}
                                                                    >
                                                                        Resend?
                                                                    </label>
                                                                </small> : null
                                                            }</> : null
                                                        }
                                                    </Col> : null
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="btn-container d-flex justify-content-end">
                                    <RSSecondaryBtn onClick={this.handleClose}>Cancel</RSSecondaryBtn>
                                    <RSPrimaryBtn className={this.state.passwordOtpValidation === false ? 'click-off' : ''} onClick={this.handlePasswordChange}>Apply</RSPrimaryBtn>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    }
                    {/* Change mobile number */}
                    <Modal
                        show={this.state.mobileChangeShow}
                        onHide={this.handleClose}
                        backdrop='static'
                        keyboard={false}
                        size="md"
                        centered
                    >
                        <Modal.Header closeButton>
                            <h2>Change mobile number</h2>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <Row>
                                <Col>
                                    {errMsgPhoneNo && (
                                        <div className='validation-message'>
                                            {errMsgPhoneNo}
                                        </div>
                                    )}
                                    <RSPhoneInput
                                        country={this.state.countryCode}
                                        value={this.state.phoneNo}
                                        errorMessage={this.state.errMsgPhoneNo}
                                        onChange={(phone, country) => {
                                            let errMsg = phone.length > 0 ? null : ENTER_PHONE
                                            this.setState({
                                                phoneNo: phone,
                                                countryCode: country.countryCode,
                                                countryCodeMobile: country.dialCode,
                                                errMsgPhoneNo: errMsg
                                            })
                                        }}
                                    />
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="click-off">
                                    {errMsgPhoneNo && (
                                        <div className='validation-message mobilenoError'>
                                            {errMsgPhoneNo}
                                        </div>
                                    )}
                                    <RSPhoneInput
                                        className="required maskedPhoneNo"
                                        country={this.state.countryCode}
                                        value={this.state.countryCodeMobile + this.state.phoneNo}
                                        errorMessage={this.state.errMsgPhoneNo}
                                        onChange={(phone, country) => {
                                            let errMsg = null;
                                            this.setState({
                                                phoneNo: phone,
                                                countryCode: country.countryCode,
                                                countryCodeMobile: country.dialCode,
                                                errMsgPhoneNo: errMsg
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='position-relative'>
                                    {this.state.errMsgNewPhone && (
                                        <div className='validation-message mobilenoError'>
                                            {this.state.errMsgNewPhone}
                                        </div>
                                    )}
                                    <RSPhoneInput
                                        className="required"
                                        value={this.state.newPhoneNumber}
                                        country={this.state.newCountryCode}
                                        errorMessage={this.state.errMsgNewPhone}
                                        onChange={(phone, country, e) => {
                                            let phone1 = phone.split(" ")
                                            let phone2 = phone1?.splice(1)?.toString()?.replaceAll("-", "")?.replaceAll(",", "")
                                            this.setState({
                                                // newPhoneNumber: phone2 || "",
                                                newCountryCode: country.countryCode,
                                                newCountryCodeMobile: country.dialCode,
                                                errMsgNewPhone: null
                                            })
                                        }}
                                        onBlur={(e) => {
                                            // let phone1 = e.target?.value?.split(" ")
                                            // let phone2 = phone1?.splice(1)?.toString()?.replaceAll("-", "")?.replaceAll(",", "")
                                            // let newNum = this.state.newPhoneNumber
                                            // this.changeMobileNumber(this.state.phoneNo, newNum)
                                            // this.setState({ errMsgNewPhone: null })

                                            let _countryCode = this.state.newCountryCodeMobile.replaceAll("+", "")
                                            let _value = e.target.value.replaceAll("+", "")


                                            if (_value === _countryCode || !_countryCode.length) {
                                                this.setState({ errMsgNewPhone: ENTER_PHONE })
                                                return
                                            }

                                            let phone3 = e.target.value.replaceAll(" ", "");
                                            let phone2 = phone3.replaceAll("-", "")
                                            let phone = phone2.replaceAll("+", "")
                                            let trimNewPhone = phone.slice(_countryCode.length, phone.length)
                                            // let trimOldPhone=this.state.phoneNo.slice(this.state.countryCodeMobile.length,this.state.phoneNo.length)

                                            // this.sendOtp(phone2)
                                            let isOtpShow = this.state.mobileNo !== phone ? true : false;
                                            let errMsg = trimNewPhone.length >= 8 ? null : ENTER_PHONE;
                                            if (errMsg === null) {
                                                errMsg = this.state.phoneNo === trimNewPhone ? 'Please enter a new number' : null
                                                let isEnable = this.state.phoneNo === trimNewPhone ? true : false
                                                // errMsg = trimNewPhone.length > 8 ? null : ENTER_PHONE;
                                                this.setState({
                                                    newPhoneNumber: phone,
                                                    // newCountryCode: country.countryCode,
                                                    // newCountryCodeMobile: country.dialCode,
                                                    errMsgNewPhone: errMsg,
                                                    isShowOTP: false
                                                    // isShowConfirmOTP: isOtpShow
                                                })
                                                if (!isEnable) {
                                                    this.changeMobileNumber(this.state.phoneNo, this.state.countryCodeMobile, trimNewPhone, this.state.newCountryCodeMobile, this.state.newCountryCode, this.state.oldCountryCode)
                                                } else {
                                                    this.setState({ errMsgNewPhone: errMsg })
                                                }
                                            } else {
                                                this.setState({ errMsgNewPhone: errMsg })
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        this.state.isShowOTP
                                            ? <Col md={12} className='mb10'>
                                                <RSTextInputWithIcon
                                                    required={true}
                                                    disabled={this.state.otpSuccess ? 'click-off' : ''}
                                                    name='otp'
                                                    // icon={`${this.state.otpSuccess ? "icon-tick-circle-small icons-md green" : ""}`}
                                                    icon={`${this.state.otpSuccess ? 'icon-sd-circle-tick-mini icons-md green' : this.state.defaultOtp ? "" : 'icon-sd-close-mini icons-sm red'}`}
                                                    ph={ENTER_OTP}
                                                    max={MAX_LOGIN_OTP}
                                                    keyboardType="number"
                                                    val={this.state.userOtp}
                                                    errorMessage={this.state.errMsgOTP}
                                                    success_msg={this.state.errMsgOTP?.includes("success") ? "success_msg" : ''}
                                                    cb={t => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (t === '' || re.test(t)) {
                                                            this.setState({ userOtp: t, errMsgOTP: null, defaultOtp: true })
                                                        }
                                                    }}
                                                    ob={t => {

                                                        if (!t.length) {
                                                            this.setState({ userOtp: t, errMsgOTP: ENTER_OTP })
                                                            return
                                                        }
                                                        if (t.length === 6) {
                                                            this.setState({ userOtp: t, errMsgOTP: null, otpValidation: false })
                                                        }

                                                        this.validateOtp()
                                                    }}
                                                />
                                                {this.state.isShowOTP ? <>

                                                    {!this.state.otpSuccess && !this.state.isShowResendBtn ?
                                                        <small className='input-desc'>
                                                            One time password is valid for 5 minutes <RSPTimer
                                                                onComplete={() => {
                                                                    this.setState({ isShowResendBtn: true, errMsgOTP: null })
                                                                }}
                                                            />
                                                        </small> : null
                                                    }
                                                    {!this.state.otpSuccess && this.state.isShowResendBtn ?
                                                        <small className='input-desc-right r-0'>
                                                            <label
                                                                className='rs-link orange-medium'
                                                                onClick={() => {

                                                                    let newNum = this.state.newCountryCodeMobile + this.state.newPhoneNumber
                                                                    this.reSendOtp(newNum)
                                                                }}
                                                            >
                                                                Resend?
                                                            </label>
                                                        </small> : null
                                                    }</> : null
                                                }
                                            </Col> : null
                                    }
                                </Col>
                                <Row>
                                </Row>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className='btn-container d-flex justify-content-end'>
                                <RSSecondaryBtn onClick={this.handleClose}>Cancel</RSSecondaryBtn>
                                <RSPrimaryBtn
                                    className={this.state.otpValidation === false ? 'click-off' : ''}
                                    onClick={() => this.updateMobileNumber()}
                                >Apply</RSPrimaryBtn>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </LayoutPageWrapper >
        )
    }

}
export default withRouter(MyProfile)