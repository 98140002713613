import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper'
import { PlainSearchView, SearchViewAdvanced } from 'Components/SearchView/SearchView'
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker'
import Email from './components/Email'
import SMS from './components/SMS'
import QR from './components/QR'
import Whatsapp from './components/Whatsapp'
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'
import moment from 'moment'
import { getUserData } from 'Helper/Utils/Utils'
import { RSPTooltip } from 'Components/Tooltip'
import { connectServer, donwloadConnectServer, downloadConnectServer } from 'Helper/Network/networkHandler'
import { CONSUMPTION_DOWNLOAD, DOWNLOAD_COMMUNICATION_STATUS_CSV, GET_CHANNEL_CONSUMPTION_DETAILS } from 'Helper/Constants/endPoint'
import authContext from 'Helper/StateHandler/auth-context'

const ConsumptionChannel = (props) => {

    const history = useHistory()
    const context = useContext(authContext)

    const [channelId, setChannelId] = useState(null)
    const [channelName, setChannelName] = useState("")
    const [showDateRange, setShowDateRange] = useState(true);
    const [isShowDateRangePicker, setShowDateRangePicker] = useState(false)
    const [dropdownMonth, setDropdownMonth] = useState([...Array(3).keys()].map(numb => moment().subtract(numb, "month").format("MMMM")))
    const [selectedMonthItem, setSelectedMonthItem] = useState(months[(history?.location?.state?.month - 1)])
    const [selectedMonth, setSelectedMonth] = useState(1)
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const diff_months = (dt1, dt2) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        let rest = []
        if ((history.location.state.year - dt1.getFullYear()) === 0) {
            if (dt1.getMonth() > dt2.getMonth()) {
                rest = months.slice(dt1.getMonth(), 12)
            } else {
                rest = months.slice(dt1.getMonth(), dt2.getMonth() + 1)
            }
        } else {
            rest = months.slice(0, dt2.getMonth() + 1)
        }
        return rest;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        let userData = getUserData()
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const rest = diff_months(new Date(userData?.activationDate), new Date())
        setDropdownMonth(rest)
        if (history.location.state) {
            setSelectedMonthItem(months[(history?.location?.state?.month - 1)])
            setSelectedMonth(history?.location?.state?.month)
            getDataFromServer(history?.location?.state)
        }
    }, [])

    const getDataFromServer = (obj) => {
        const { channelId, channelName } = obj
        setChannelId(channelId)
        setChannelName(channelName)

    }

    const renderComponent = (channelID) => {
        let renderComponent = {
            1: <Email state={history?.location?.state} selectedMonth={selectedMonth} selectedYear={history?.location?.state.year} searchCampaignName={searchValue} getLastUpdatedDate={(value) => { setLastUpdatedDate(value) }} />,
            2: <SMS state={history?.location?.state} selectedMonth={selectedMonth} selectedYear={history?.location?.state.year} searchCampaignName={searchValue} getLastUpdatedDate={(value) => { setLastUpdatedDate(value) }} />,
            3: <QR state={history?.location?.state} selectedMonth={selectedMonth} selectedYear={history?.location?.state.year} searchCampaignName={searchValue} getLastUpdatedDate={(value) => { setLastUpdatedDate(value) }} />,
            21: <Whatsapp state={history?.location?.state} selectedMonth={selectedMonth} selectedYear={history?.location?.state.year} searchCampaignName={searchValue} getLastUpdatedDate={(value) => { setLastUpdatedDate(value) }} />
        }
        return renderComponent[channelID]
    }
    const donwloadConsumptionCSV = (channelId, channelName) => {
        let fileName = "Consumption Report-" + selectedMonth.toString() + history?.location?.state.year.toString() + "_" + channelName + ".csv";
        let params = {
            "ChannelID": channelId,
            "year": history?.location?.state.year,
            "month": selectedMonth,
        }
        downloadConnectServer({
            path: CONSUMPTION_DOWNLOAD,
            params,
            fileName: fileName,
            sessionOut: context.globalStateData.setSessionOutData,
            ok: res => {
            },
            fail: err => {
            }
        })

    }
    const closeDateRangePicker = (status, dates) => {
        let params = {
            endDate: new Date(dates.end),
            startDate: new Date(dates.start),
            CampaignType: '',
            deliveryType: '',
            createdBy: '',//getAuthUserId(),
            campaignName: '',
            pagination: {
                PageSize: 60,
                TotalCount: 0,
                PageCount: 1
            }
        }
        if (!status)
            this.getDataFromServerNew(params)

        setShowDateRangePicker(status)
    }

    return <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
            <div className="header-title d-flex justify-content-between">
                <h1>Consumptions</h1>
                <div className="back-text" onClick={() => history.push(`/preferences/consumptions`)}>
                    <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                    Back
                </div>
            </div>
            <hr className="blue mb30" />
            <Row>
                <Col>
                    <div className="d-flex justify-content-between marginB20">
                        <div className="d-flex align-items-center">
                            <h5 className="marginB0">{channelName}</h5>
                            {lastUpdatedDate !== "" &&
                                <span className="date-text marginT2 marginR5 marginL5">(As on: {lastUpdatedDate})</span>}
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <PlainSearchView
                                isFromSegmentation={false}
                                enable={lastUpdatedDate !== "" ? true : false}
                                cls="marginR15"
                                onSearch={(searchValue) => {
                                    setSearchValue(searchValue);
                                }}
                            />
                            <div className='mr20'>
                                <BootstrapDropdown
                                    data={dropdownMonth}
                                    defaultItem={selectedMonthItem}
                                    alignRight
                                    customAlignRight={true}
                                    className="fs18-btn"
                                    onSelect={item => {
                                        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                                        setSelectedMonthItem(item)
                                        setSelectedMonth(months.findIndex(mItem => mItem === item) + 1)
                                    }}
                                />
                            </div>
                            <RSPTooltip text="Download CSV" position="top">
                                <i className={`icon-sd-csv-medium icons-md blue cursor-pointer ${lastUpdatedDate === "" ? "click-off" : ""}`} onClick={() => {
                                    donwloadConsumptionCSV(channelId, channelName);
                                }}></i>
                            </RSPTooltip>
                        </div>
                    </div>
                </Col>
                {renderComponent(channelId)}
            </Row>
        </Container>
    </LayoutPageWrapper >
}

export default ConsumptionChannel
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
