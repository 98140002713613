import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { Switch } from '@progress/kendo-react-inputs';
import { RSDropdownList } from "Components/RSDropdowns";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSTextInputWithIcon } from "Components/RSInputs";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import {
    REDIRECTION_URL,
    CAMPAIGN_URL,
    AUDIENCE_REACH,
    MOBILE_NUMBER,
    SMS_CONTENT,
    MAIL_ID,
    SUBJECT_LINE,
    MAIL_CONTENT,
    ENTER_VALID_WEBSITE,
    KYC,
} from "Helper/Constants/validationMessage";
import {
    convertBase64ToObject,
    getUserData,
    isEmpty,
    dateFormat,
    getServerConfig,
    isValidWebsite,
    getAuthUserId,
    convertObjectToBase64,
} from "Helper/Utils/Utils";
import {
    GET_SHORTEN_URL,
    MDC_CANVAS_URL,
    GET_FORM_LIST,
    GET_FORM_HTML_DATA_BY_ID,
    GET_QRCODE_TO_VIEW,
    GENERATE_QR_DOWNLOAD_URL,
    GET_QR_CODE,
    VALIDATE_WEBSITE_URL,
    GET_CAMPAIGN_HEADER,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import QRPreviewNew from "../Notification/MSComponents/QRComponents/QRPreviewNew";
import KYCPreviewComponent from "../Notification/MSComponents/QRComponents/KYCPreviewComponent";
const tabDataItems = [
    { text: 'URL', iconLeft: 'icon-sd-link-large icons-lg', disable: false, },
    { text: 'SMS', iconLeft: 'icon-sd-mobile-sms-large icons-lg', disable: true, },
    { text: 'Email', iconLeft: 'icon-sd-email-large icons-lg', disable: true, }
]
const MdcQRCreation = (props) => {
    const context = useContext(authContext);
    const history = useHistory();


    const { search } = useLocation();
    let queryParam = React.useMemo(() => new URLSearchParams(search), [search]);
    let decodeCampaignId = convertBase64ToObject(queryParam.get("CampaignId"));
    let getTokenFromParam = decodeURIComponent(queryParam.get("accessToken"));
    let campaignMode = decodeURIComponent(queryParam.get("mode"));
    let getChannelDetailFromParam = JSON.parse(
        decodeURIComponent(queryParam.get("ChannelDetails"))
    );
    const [potentialAudienceCount, setPotentialAudienceCount] = useState(0);
    const [campaignName, setCampaignName] = useState("");
    const [startCampaignDate, setStartCampaignDate] = useState("");
    const [endCampaignDate, setEndCampaignDate] = useState("");
    const [disableEdit, setDisableEdit] = useState("");
    const [attributeName, setAttributeName] = useState("");
    const [primayGoal, setPrimaryGoal] = useState("");
    const [toggleScreenTitle, setToggleScreenTitle] = useState("Full screen view");
    const [fullScreen, setFullScreen] = useState(false)
    const [campaignModeAddOrEdit, setCampaignModeAddOrEdit] = useState(campaignMode);
    const [campaignId, setCampaignId] = useState(decodeCampaignId);
    const [accessToken, setAccessToken] = useState(getTokenFromParam);
    const [channelDetailId, setChannelDetailId] = useState(0);
    const [campaignGUID, setCampaignGUID] = useState("");
    const [index, setIndex] = useState(0);
    const [tabData, setTabData] = useState(null);
    const [isShowRefresh, setIsShowRefresh] = useState(false);
    const [isOpenRefreshModal, setIsOpenRefereshModal] = useState(false);
    const [QRData, setQRData] = useState(null);
    const [QRRedirectionalURL, setQRRedirectionURL] = useState("");
    const [webQRURL, setWebQRURL] = useState("");
    const [webQRContent, setWebQRContent] = useState("");
    const [audienceReach, setAudienceReach] = useState("");
    const [campaignURL, setCampaignURL] = useState("");
    const [redirectionURL, setRedirectionURL] = useState("");
    const [webBGColor, setWebBGColor] = useState("");
    const [webFGColor, setWebFGColor] = useState("");
    const [webDownURL, setWebDownURL] = useState("");
    const [KYCSwitch, setKYCSwitch] = useState(false);
    const [KYCType, setKYCType] = useState({
        "formId": 0,
        "formName": "Default KYC",
    });
    const [isShowKYCPreview, setIsShowKYCPreview] = useState(false);
    const [errAudienceReachMsg, setErrAudienceReachMsg] = useState(null);
    const [errCamapignURLMsg, setErrCampaignURLMsg] = useState(null);
    const [errRedirectionURLMsg, setErrRedirectionURLMsg] = useState(null);
    const [errKYCMsg, setErrKYCMsg] = useState(null);
    const [isValidCommunicationURL, setIsValidCommunicationURL] = useState(false)
    const [isValidRedirectionURL, setIsValidRedirectionURL] = useState(false)
    const [isShowQRPreview, setIsShowQRPreview] = useState(false);
    const [sendMailID, setSendMailID] = useState("");
    const [subjectLine, setSubjectLine] = useState("");
    const [message, setMessage] = useState("");
    const [errSendMailIDMsg, setErrSendMailIDMsg] = useState(null);
    const [errSubjectLineMsg, setErrSubjectLineMsg] = useState(null);
    const [errMessageMsg, setErrMessageMsg] = useState(null);
    const [mobileNumber, setMobileNumber] = useState("");
    const [SMSmessage, setSMSMessage] = useState("");
    const [errMobileNumMsg, setErrMobileNumMsg] = useState(null);
    const [errSMSMessageMsg, setErrSMSMessageMsg] = useState(null);
    const [sliderValue, setSliderValue] = useState(200);
    const [smsShortenURL, setSMSShortenURL] = useState("");
    const [QRBase64Content, setQRBase64Content] = useState("");
    const [formsList, setFormsList] = useState([]);
    const [formHTMLContent, setFormHTMLContent] = useState("");
    const [isWebName, setIsWebName] = useState(false);
    const [isWebEmailId, setIsWebEmailId] = useState(false);
    const [isWebMobileNo, setIsWebMobileNo] = useState(false);
    const [isWebPostalCode, setIsWebPostalCode] = useState(false);
    const [webDoText, setWebDoText] = useState("");
    const [webKYCText, setWebKYCText] = useState("");
    const [valiedRedirectUrlCheck, setValiedRedirectUrlCheck] = useState(false)
    const [generatedQR, setGeneratedQR] = useState(null);
    const [isShortCode, setIsShortCode] = useState(true);
    const [qrContentIDs, setQRContentIDs] = useState(null);
    const [qrFormId, setQRFormId] = useState(0);
    const [isShortURLCreated, setIsShortURLCreated] = useState(false);
    let smsTextQRURL = "";

    useEffect(() => {
        getCampaignHeader();
        getPushQRCodeToView(campaignId);
        const str = JSON.stringify(tabDataItems);
        setTabData(JSON.parse(str));
    }, [])

    useEffect(() => {
        getFormList();
    }, [qrFormId])

    useEffect(() => {
        if (QRData !== "") {
            if (campaignURL !== "" && redirectionURL !== "") {
                //fnGetURLQR();
                let webURLQR = getWebURLQR("edit", webQRURL, webDownURL);
                setWebQRContent(webURLQR);
            }
            else {
                let webURLQR = getWebURLQR("default", "", "")
                setWebQRContent(webURLQR);
            }
        }
    }, [QRData])

    const getCampaignHeader = () => {
        const params = {
            userId: getAuthUserId(),
            campaignId: campaignId,
        };
        connectServer({
            path: GET_CAMPAIGN_HEADER,
            params: params,
            sessionOut: context.globalStateData.setSessionOutData,
            loading: context.globalStateData.setIsLoadingData,
            retryTime: 5000,
            context: context,
            ok: (res) => {
                if (res.status) {
                    const {
                        campaignName,
                        startDate,
                        endDate,
                        attributeName,
                        primaryTargetCode,
                    } = res.data;
                    setCampaignName(campaignName);
                    setStartCampaignDate(getStartDate(startDate));
                    //  handleScheduleMinimumDate();
                    setEndCampaignDate(endDate);
                    setAttributeName(attributeName);
                    let goal = goalList.filter(
                        (item) => item.shortCode === primaryTargetCode
                    );
                    setPrimaryGoal(goal[0]["name"]);
                }
            },
            fail: (err) => { },
        });
    };

    const getStartDate = (startDate) => {
        let campaignStartDate = new Date(startDate);
        let today = new Date();
        if (campaignStartDate > today) {
            campaignStartDate = new Date(startDate);
        } else {
            campaignStartDate = new Date();
        }
        return campaignStartDate
    }

    const getFormList = () => {
        connectServer({
            path: GET_FORM_LIST,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setFormsList(res.data)
                    if (qrFormId !== 0) {
                        let FormDetail = res.data.filter(item => item.formId === qrFormId)[0];
                        setKYCType(FormDetail)
                        getFormDataById(qrFormId)
                    }
                }
            },
            fail: err => {

            }
        })
    }

    const getFormDataById = (formId) => {
        let params = {
            formId: formId.toString(),
            dbId: ""
        }
        connectServer({
            path: GET_FORM_HTML_DATA_BY_ID,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setFormHTMLContent(res.data.htmlcodeclient);
                }
            },
            fail: err => {

            }
        })

    }

    const getWebURLQR = (mode, QRcontent, downloadURL) => {
        let webQR = "";
        if (mode === 'default') {

            webQR = "";
        } else {
            if (QRcontent === "") {
                QRcontent = webQRURL;
            }
            // let returnType = true;
            // let intRegex = /^[0-9 ]+$/;
            // let urlRecipients = audienceReach;
            // let QRFormId = KYCType?.formId;
            // if (QRFormId === "" || QRFormId === null || QRFormId === undefined) {
            //     QRFormId = 0;
            // }
            // let webKyc = KYCSwitch;
            // if (webKyc) {
            //     if (QRFormId === 0) {
            //         setErrKYCMsg("Select KYC Type");
            //         returnType = false;
            //         setValiedRedirectUrlCheck(false);
            //     }
            // }
            // let urlrpic = webFGColor;
            // let urlpicbg = webBGColor;
            // let txtUrl = campaignURL;
            // let txtRedirectUrl = redirectionURL;
            // let txtQRWImagesrc = ""
            // if (isEmpty(txtUrl)) {
            //     setErrCampaignURLMsg("Enter Communication URL");
            //     returnType = false;
            // } else {
            //     setErrCampaignURLMsg(null);
            //     returnType = true;
            // }
            // if (isEmpty(txtRedirectUrl)) {
            //     setErrRedirectionURLMsg("Enter redirection URL");
            //     returnType = false;
            // } else {
            //     setErrRedirectionURLMsg(null);
            //     returnType = true;
            // }
            // if (isEmpty(urlRecipients)) {
            //     setErrAudienceReachMsg("Enter reach audience");
            //     returnType = false;
            // } else {
            //     setErrAudienceReachMsg(null);
            //     returnType = true;
            // }
            // if (returnType) {
            //     if (txtUrl.substring(0, 7) !== 'http://' && txtUrl.substring(0, 8) !== 'https://') {
            //         txtUrl = 'http://' + txtUrl;
            //     }
            //     if (txtRedirectUrl.substring(0, 7) !== 'http://' && txtRedirectUrl.substring(0, 8) !== 'https://') {
            //         txtRedirectUrl = 'http://' + txtRedirectUrl;
            //     }
            //     if (txtUrl !== '') {
            //         setErrCampaignURLMsg(null);
            //         returnType = true;
            //         let webregex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            //         if (webregex.test(txtUrl)) {
            //             setErrCampaignURLMsg(null);
            //             returnType = true;
            //         }
            //         else {
            //             setErrCampaignURLMsg("Enter valid URL");
            //             returnType = false;
            //         }
            //     }
            //     else {
            //         setErrCampaignURLMsg("Enter Communication URL");
            //         returnType = false;
            //     }
            //     if (returnType) {
            //         if (txtRedirectUrl !== '') {
            //             setErrRedirectionURLMsg(null);
            //             returnType = true;
            //             setValiedRedirectUrlCheck(true)
            //             let webregex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            //             if (webregex.test(txtRedirectUrl)) {
            //                 //$('#hdnQRRedirecturl').val(txtRedirectUrl);
            //                 setErrRedirectionURLMsg(null);
            //                 returnType = true;
            //                 setValiedRedirectUrlCheck(true)
            //             }
            //             else {
            //                 setErrRedirectionURLMsg("Enter valid URL");
            //                 returnType = false;
            //                 setValiedRedirectUrlCheck(false)
            //             }
            //         }
            //         else {
            //             setErrRedirectionURLMsg("Enter redirection URL");
            //             returnType = false;
            //             setValiedRedirectUrlCheck(false)
            //         }
            //     }
            //     if (returnType) {
            //         // jQuery('#URLQrCode1').html("");
            //         // jQuery('#URLQrCode').html("");
            //         // var o = jQuery;

            //         // let MNSize;
            //         // let qrimg;
            //         // let qrshortenurl;
            //         // let imagebuffer;

            //         // if (jQuery('#li-qr-url').hasClass('active')) {

            //         //     if ($("#hdnQRRedirecturl").val() == null && $("#hdnQRRedirecturl").val() == "") {
            //         //         $('#hdnQRRedirecturl').val('https://www.resulticks.com');
            //         //         $("#txtUrlShortenURL").html();
            //         //     }
            //         //     if ($("#hdnQRRedirecturl").html() != null && $("#hdnQRRedirecturl").html() != "") {
            //         //         $("#txtUrlShortenURL").html($("#hdnQRRedirecturl").val())
            //         //     }

            //         //     qrimg = "#URLQrCode1";
            //         //     qrshortenurl = "#txtUrlShortenURL";
            //         //     imagebuffer = "#img-buffer";
            //         // }
            //         // else {
            //         //     // APP QR code 
            //         //     qrimg = "#AppStoreQrCode1";
            //         //     qrshortenurl = "#txtUrlShortenURLforshortcode";
            //         //     imagebuffer = "#img-bufferApp";

            //         // }


            //         // if ($('#QRImagePath').val() == null || $('#QRImagePath').val() == "") {
            //         //     //$("#msize").val(0);
            //         //     MNSize = .01;
            //         // } else {
            //         //     MNSize = .01 * parseInt(o("#msize").val(), 10);
            //         // }


            //         // var zoption = {
            //         //     render: 'image',
            //         //     ecLevel: 'H',
            //         //     minVersion: 1,
            //         //     fill: '#000',
            //         //     text: o(qrshortenurl).html(),		//$('#hdnQRRedirecturl').val()[0],		
            //         //     size: 200,
            //         //     //radius: .5,
            //         //     quiet: 0,
            //         //     mode: 4,
            //         //     mSize: MNSize,
            //         //     image: o(imagebuffer)[0]
            //         // }
            //         // jQuery("#URLQrCode1").empty().qrcode(zoption);



            //         if (audienceReach !== "") {
            //             setErrAudienceReachMsg(null)
            //             returnType = true;
            //             if (audienceReach === 0) {
            //                 setErrAudienceReachMsg("Enter reach audience");
            //                 returnType = false;
            //             } else {
            //                 setErrAudienceReachMsg(null)
            //                 returnType = true;
            //             }
            //         }
            //         else {
            //             setErrAudienceReachMsg("Enter reach audience");
            //             returnType = false;
            //         }
            //     }
            // }
            // if (returnType) {
            let webKyc = KYCSwitch
            let webEmailId = isWebEmailId;
            let webName = isWebName
            // alert(webEmailId);
            let webMobileNo = isWebMobileNo
            let webPostalCode = isWebPostalCode
            // alert(webMobileNo);
            let webdoText = webDoText
            if (webdoText !== "") {
                webdoText = webDoText
            }
            let urlqrsize = sliderValue
            if (webKyc) {
                if (webdoText === "") {
                    webdoText = "NA";
                }
                webdoText = "^" + webdoText;
            }
            let webUri = campaignURL?.replace("=", "|");
            webQR = "$$W=" + encodeURIComponent(webUri) + "::" + redirectionURL.replace("=", "|") + "::fg:" + webFGColor + "::bg:" + webBGColor + "::" + sliderValue;
            if (campaignURL !== "") {
                if (!!generatedQR) {
                    let downloadURLval = generatedQR.pngUrl.substring(generatedQR.pngUrl.lastIndexOf('/') + 1);
                    webQR += "::" + downloadURLval;
                } else {
                    webQR += "::";
                }
                if (webKyc) {
                    webQR += "::Y";
                    if (webName) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webEmailId) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webMobileNo) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webPostalCode) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webdoText !== "") {
                        webQR += "::" + webdoText + "::";
                    }
                    else {
                        webQR += "::NA::";
                    }
                    // webQR += isShortURLCreated ? campaignURL : webQRURL;
                    webQR += webQRURL;
                }
                else {
                    // webQR += "::N::N::N::N::N::NA::" + isShortURLCreated ? campaignURL : webQRURL;
                    webQR += "::N::N::N::N::N::NA::" + webQRURL;
                }
            }
            else {
                webQR += "::";
                //alert("4 " + twitterQR);
                if (webKyc) {
                    webQR += "::Y";
                    if (webName) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webEmailId) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webMobileNo) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webPostalCode) {
                        webQR += "::Y";
                    }
                    else {
                        webQR += "::N";
                    }
                    if (webdoText !== "") {
                        webQR += "::" + webdoText + "::";
                    }
                    else {
                        webQR += "::NA::";
                    }
                    // webQR += isShortURLCreated ? campaignURL : webQRURL;
                    webQR += webQRURL;
                }
                else {
                    // webQR += "::N:N::N::N::N::NA::" + isShortURLCreated ? campaignURL : webQRURL;
                    webQR += "::N:N::N::N::N::NA::" + webQRURL;
                }
            }
            webQR += "##";
            // var tempDT = webQR.lastIndexOf("-");//faceBookQR.Split("::");
            // //alert(tempDT);
            // if (tempDT !== -1) {
            //     qrCCID = webQR.substring(tempDT + 1, webQR.length - 1).replace("##", "");
            //     //alert('QRCCID : ' + qrCCID);
            // }
            if (mode !== "save") {
                if (downloadURL !== "" && downloadURL !== "N") {
                    let generatedURL = {
                        pngUrl: getServerConfig() + "Uploads/Campaigns/Temp/QR/" + downloadURL,
                        pdfUrl: getServerConfig() + "Uploads/Campaigns/Temp/QR/" + downloadURL.replace("png", "pdf")
                    }
                    setGeneratedQR(generatedURL);
                }
                else {
                    generateQRCode(campaignId, webQR);
                }
            }
            // }
        }
        return webQR;
    }
    const getPushQRCodeToView = (campaignID) => {
        let params = {
            "campaignId": campaignID
        }
        connectServer({
            path: GET_QRCODE_TO_VIEW,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    let qrData = res.data.qrData;
                    let qrDataCollection = qrData.split("&&");
                    setIsShortURLCreated(res.data.isShortenURLEnabled);
                    setChannelDetailId(res.data.qrCodeChannelDetailId);
                    setCampaignGUID(res.data.campaignGUID);
                    setQRContentIDs(res.data.qRContentID);
                    setQRFormId(res.data.qrFormId);
                    // setAudienceReach(res.data.potentialRecipientsCount)
                    qrDataCollection.map((item, index) => {
                        let tempData = item.replaceAll("$$", "");
                        tempData = tempData.replaceAll("##", "");
                        let qrType = tempData.substr(0, 1);
                        if (qrType === "W") {
                            let QRDataSplit = tempData.split("::");
                            if (QRDataSplit.length > 0) {
                                let webURL = decodeURIComponent(QRDataSplit[0].substr(2, tempData.length - 2));
                                let webRedirectURL = QRDataSplit[1].replace("|", "=");
                                let webFGColor = QRDataSplit[2].replace("fg:", "");
                                let webBGColor = QRDataSplit[3].replace("bg:", "");
                                setWebBGColor(webBGColor)
                                setWebFGColor(webFGColor)
                                let webSliderValue = QRDataSplit[4];
                                let webDownURL = QRDataSplit[5];
                                let webQRURL = QRDataSplit[12];
                                let webPRecipients = QRDataSplit[13]
                                if (webSliderValue !== "" && webSliderValue !== "175") {
                                    setSliderValue(webSliderValue);
                                }
                                if (QRDataSplit[6] !== "NA" && QRDataSplit[6] !== "N") {
                                    if (QRDataSplit[6] === "Y") {
                                        setKYCSwitch(true)
                                        if (QRDataSplit[7] === "Y") {
                                            setIsWebName(true);
                                        } else {
                                            setIsWebName(false)
                                        }
                                        if (QRDataSplit[8] === "Y") {
                                            setIsWebEmailId(true);
                                        } else {
                                            setIsWebEmailId(false)
                                        }
                                        if (QRDataSplit[9] === "Y") {
                                            setIsWebMobileNo(true);
                                        } else {
                                            setIsWebMobileNo(false)
                                        }
                                        if (QRDataSplit[10] === "Y") {
                                            setIsWebPostalCode(true);
                                        } else {
                                            setIsWebPostalCode(false)
                                        }
                                        if (QRDataSplit[11] !== "NA") {
                                            var doKYCText = QRDataSplit[11].split("^");
                                            if (doKYCText.length === 1) {
                                                setWebDoText(QRDataSplit[11])
                                            } else {
                                                if (doKYCText.length > 0 && doKYCText[0] !== "NA") {
                                                    setWebKYCText(doKYCText[0]);
                                                }
                                                if (doKYCText.length > 1 && doKYCText[1] !== "NA") {
                                                    setWebDoText(doKYCText[1]);
                                                }
                                            }
                                        }
                                    } else {
                                        setKYCSwitch(false)
                                    }
                                } else {
                                    setKYCSwitch(false)
                                }
                                if (webPRecipients !== "" && webPRecipients !== "0") {
                                    setIsShowRefresh(false);
                                    setAudienceReach(webPRecipients)
                                }
                                if (webURL !== "") {
                                    setCampaignURL(webURL.replace("|", "="));
                                }
                                if (webDownURL !== "") {
                                    setWebDownURL(webDownURL);
                                }
                                if (webQRURL !== "") {
                                    setWebQRURL(webQRURL);
                                    // if (webURL != "" && $("#hdnIsSmartUrlEnabled").val() == 'Y') {
                                    //     $("#txtUrlShortenURLforshortcode").html(webURL);
                                    //     $("#chekshortcode").html(webURL);
                                    //     //$("#txtUrlShortenURL").text(webURL);
                                    // } else {
                                    //     $("#txtUrlShortenURLforshortcode").html(webQRURL);
                                    //     $("#chekshortcode").html(webQRURL);
                                    // }
                                    // if (shortURL4 != null && shortURL4 != "" && shortURL4.substr(0, 17) == "http://t.resu.io/") {
                                    // $("#txtUrlShortenURLforshortcode").html(shortURL4 + 'TH');
                                    // }                          
                                }
                                if (webRedirectURL !== "") {
                                    setRedirectionURL(webRedirectURL);
                                }

                            }
                        }
                    })
                    setQRData(res.data.qrData);
                    setQRRedirectionURL(res?.data?.qrRedirectURL)
                    if (formsList.length > 0) {
                        let FormDetail = formsList.filter(item => item.formId === res.data.qrFormId)[0];
                        setKYCType(FormDetail)
                        getFormDataById(res.data.qrFormId)
                    }
                }
            },
            fail: err => {

            }
        })
    }
    const generateQRCode = (campaignID, strQRContent) => {
        let databaseName = getUserData()?.clientDetailsLocalization?.instanceName
        let qrContentID = index === 0 ? qrContentIDs.wqrContentID : index === 1 ? qrContentIDs.sqrContentID : qrContentIDs.eqrContentID;
        let params = {
            "strHtmlData": QRBase64Content,
            "recipientCount": audienceReach,
            "campaignId": campaignID,
            "strContent": strQRContent,
            "strQRCodeContentID": qrContentID,
            "qrFormId": KYCType?.formId || 0,
            "imageurl": "|,0|,true",
            "isshortcode": isShortCode,
            "QRwebURL": isShortURLCreated ? campaignURL : webQRURL,
            "campignGuid": campaignGUID,
            "databaseName": databaseName,
            "IsShortenURLEnabled": isShortURLCreated
        }
        connectServer({
            path: GENERATE_QR_DOWNLOAD_URL,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setGeneratedQR(res.data);
                }
            },
            fail: err => {

            }
        })
    }
    const getShortenURL = () => {
        let params = {
            "smartUrlsList": [
                {
                    "campaignId": campaignId,
                    "goalNo": 1,
                    "blastType": "",
                    "smartCode": "",
                    "smartAdaptive": "",
                    "smartUrl": campaignURL,
                    "channelId": 3,
                    "blastNo": 1,
                    "actionId": 0,
                    "parentChannelDetailId": 0
                }
            ]
        }
        connectServer({
            path: GET_SHORTEN_URL,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setGeneratedQR(null)
                    setIsShortURLCreated(res.status)
                    setCampaignURL(res.data[0].smartCodePath)
                } else {
                    setIsShortURLCreated(res.status)
                }
            },
            fail: err => {
            }
        })
    }
    const getQRCode = () => {
        let strQRContent = getWebURLQR("save", webQRURL, generatedQR.pngUrl);
        var strAllQRPotentialData = new Array();
        strAllQRPotentialData[0] = "F-"
        strAllQRPotentialData[1] = "T-"
        strAllQRPotentialData[2] = "W-" + audienceReach + "";
        strAllQRPotentialData[3] = "G-"
        strAllQRPotentialData[4] = "A-"
        strAllQRPotentialData[5] = "C-"
        strAllQRPotentialData[6] = "R-"
        strAllQRPotentialData[7] = "S-"
        let params = {
            strAllQRData: strQRContent,
            qrPotentialData: strAllQRPotentialData,
            qrMode: "Save",
            channelDetailId: channelDetailId,
            campaignId: campaignId,
            imagepath: "|,0|,true",
            QRFormID: KYCType?.formId,
            IsShortenURLEnabled: isShortURLCreated,
            channelFriendlyName: getChannelDetailFromParam.ChannelFriendlyName
        }
        connectServer({
            path: GET_QR_CODE,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    let baseURL = getServerConfig();
                    let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                    window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${convertObjectToBase64(
                        campaignId
                    )}&accessToken=${encodeURIComponent(
                        accessToken
                    )}&from=${fromEnvi}&channelResponseDetailId=${convertObjectToBase64(
                        channelDetailId
                    )}&channelType=Q`;
                }
            },
            fail: err => {
            }
        })
    }
    const updateTabState = value => {
        let temp = [...tabData]
        if (value.title === "URL" && value.value) {
            temp[0].disable = false;
            temp[1].disable = true;
            temp[2].disable = true;
            setIsShowRefresh(true);
        } else if (value.title === "SMS" && value.value) {
            temp[0].disable = true;
            temp[1].disable = false;
            temp[2].disable = true;
            setIsShowRefresh(true);
        } else {
            temp[0].disable = true;
            temp[1].disable = true;
            temp[2].disable = false;
            setIsShowRefresh(true);
        }
        setTabData(temp);
    }


    const checkWebsiteExist = (websiteURL, fieldName) => {
        let params = {
            "webUrl": websiteURL
        }
        connectServer({
            path: VALIDATE_WEBSITE_URL,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            params,
            ok: res => {
                if (!res.data) {
                    if (fieldName === "Communication URL") {
                        setErrCampaignURLMsg(ENTER_VALID_WEBSITE)
                        setIsValidCommunicationURL(false)
                    } else {
                        setErrRedirectionURLMsg(ENTER_VALID_WEBSITE)
                        setIsValidRedirectionURL(false)
                    }
                } else {
                    if (fieldName === "Communication URL") {
                        setErrCampaignURLMsg(null);
                        setIsValidCommunicationURL(true)
                    } else {
                        setErrRedirectionURLMsg(null)
                        setIsValidRedirectionURL(true)
                    }
                }
            },
            fail: error => {
                if (fieldName === "Communication URL") {
                    setErrCampaignURLMsg(ENTER_VALID_WEBSITE)
                    setIsValidCommunicationURL(false)
                } else {
                    setErrRedirectionURLMsg(ENTER_VALID_WEBSITE)
                    setIsValidRedirectionURL(false)
                }
            }
        })
    }

    const validation = () => {
        let enable = true;
        if (errAudienceReachMsg === null) {
            if (isEmpty(audienceReach)) {
                setErrAudienceReachMsg(AUDIENCE_REACH);
                enable = false;
            } else {
                let value = parseInt(audienceReach, 10);
                let errMsg = (value < 1 || value > 25000) ? "Audience range should be 1 to 25,000" : null;
                setErrAudienceReachMsg(errMsg);
                enable = errMsg !== null ? false : true;
            }
        } else {
            enable = false
        }
        if (index === 0) {
            if (errCamapignURLMsg === null) {
                if (isEmpty(campaignURL)) {
                    setErrCampaignURLMsg(CAMPAIGN_URL);
                    enable = false;
                }
            } else {
                enable = false
            }
            if (errRedirectionURLMsg === null) {
                if (isEmpty(redirectionURL)) {
                    setErrRedirectionURLMsg(REDIRECTION_URL);
                    enable = false;
                }
            } else {
                enable = false;
            }
            // if (KYCSwitch) {
            //     if (errKYCMsg === null && KYCType.formId === 0) {
            //         setErrKYCMsg(KYC);
            //         enable = true;
            //     } else {
            //         enable = errKYCMsg !== null ? true : true;
            //     }
            // }
        } else if (index === 1) {
            if (errMobileNumMsg === null) {
                if (isEmpty(mobileNumber)) {
                    setErrMobileNumMsg(MOBILE_NUMBER);
                    enable = false;
                }
            } else {
                enable = false
            }
            if (errMessageMsg === null) {
                if (isEmpty(SMSmessage)) {
                    setErrMessageMsg(SMS_CONTENT);
                    enable = false;
                }
            } else {
                enable = false
            }
        } else if (index === 2) {
            if (errSendMailIDMsg === null) {
                if (isEmpty(sendMailID)) {
                    setErrSendMailIDMsg(MAIL_ID);
                    enable = false;
                }
            } else {
                enable = false
            }
            if (errSubjectLineMsg === null) {
                if (isEmpty(subjectLine)) {
                    setErrSubjectLineMsg(SUBJECT_LINE);
                    enable = false;
                }
            } else {
                enable = false
            }
            if (errMessageMsg === null) {
                if (isEmpty(message)) {
                    setErrMessageMsg(MAIL_CONTENT);
                    enable = false;
                }
            } else {
                enable = false
            }
        }
        return enable;
    }

    const backToCanvas = () => {
        const sessionStr = localStorage.getItem("session");
        const sessionObject = JSON.parse(sessionStr);
        const accessToken = sessionObject ? sessionObject.accessToken : null;
        const campId = window.btoa(campaignId);
        let baseURL = getServerConfig();
        let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
        window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${campId}&accessToken=${encodeURIComponent(accessToken)}&from=${fromEnvi}`;
    };
    const toggleFullScreen = () => {
        var doc = window.document;
        var docEl = doc.documentElement;

        var requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullscreen;
        var cancelFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

        if (
            !doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement
        ) {
            requestFullScreen.call(docEl);
            setToggleScreenTitle("Normal view")
            setFullScreen(true)
        } else {
            cancelFullScreen.call(doc);
            setToggleScreenTitle("Full screen view")
            setFullScreen(false)
        }
    }
    return (
        <>
            <LayoutPageWrapper>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="progress-steps-box mr--mdcqr">
                                <div className="steps-label">
                                    <small>Communication name:</small>
                                    <RSPTooltip text={window.atob(campaignName)} position="bottom">
                                        <label className="label-row label-dark">
                                            {window.atob(campaignName)}
                                        </label>
                                    </RSPTooltip>
                                </div>
                                <div className="steps-label">
                                    <small>Duration:</small>
                                    <RSPTooltip
                                        text={` ${dateFormat(startCampaignDate)} - ${dateFormat(
                                            endCampaignDate
                                        )}`}
                                        position="bottom"
                                    >
                                        <label className="label-row label-dark">
                                            {dateFormat(startCampaignDate)} - {dateFormat(endCampaignDate)}
                                        </label>
                                    </RSPTooltip>
                                </div>
                                <div className="steps-label">
                                    <small>Type:</small>
                                    <RSPTooltip text={attributeName} position="bottom">
                                        <label className="label-row label-dark">{attributeName}</label>
                                    </RSPTooltip>
                                </div>
                                <div className="steps-label">
                                    <small>Primary communication goal:</small>
                                    <RSPTooltip text={primayGoal} position="bottom">
                                        <label className="label-row label-dark">{primayGoal}</label>
                                    </RSPTooltip>
                                </div>
                                <div className="steps-label">
                                    <small>Potential audience:</small>
                                    <RSPTooltip text={"NA"} position="bottom">
                                        <label className="label-row label-dark">
                                            {"NA"}
                                        </label>
                                    </RSPTooltip>
                                </div>
                                <div className="">
                                    <RSPTooltip text={toggleScreenTitle} position="bottom">
                                        <div className="steps-label-row-icon d-flex align-items-center justify-content-end">
                                            <i
                                                onClick={() => toggleFullScreen()}
                                                className={`icon-sd-${fullScreen ? "collapse" : "expand"
                                                    }-large icons-md white large-icon cp bgBlue`}
                                            ></i>
                                        </div>
                                    </RSPTooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="header-title d-flex justify-content-between">
                                <h1>QR code URL settings</h1>
                                <div className="back-text">
                                    <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                                    <a onClick={backToCanvas}>Back to canvas</a>
                                </div>
                            </div>
                            <div className={`portlet-box-theme bgWhite padding20 mb50`}>
                                <div className="mt20 ml8">
                                    <Container>
                                        <Row>
                                            <Col md={9}>
                                                <Row className="custom-column-del">
                                                    <Col md={4}>
                                                        <label className="label-row">Expected audience reach</label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <RSInput2
                                                            required={true}
                                                            ph="Max. upto 25,000"
                                                            val={audienceReach}
                                                            errorMessage={errAudienceReachMsg}
                                                            max={8}
                                                            keyboardType='number'
                                                            cb={(text) => {
                                                                let errMsg = text.length !== 0 ? null : AUDIENCE_REACH;
                                                                let regex = /^[0-9]+$/
                                                                if (errMsg === null) {
                                                                    if (regex.test(text)) {
                                                                        let value = parseInt(text, 10);
                                                                        errMsg = (value < 1 || value > 25000) ? "Audience range should be 1 to 25,000" : null;
                                                                        setAudienceReach(text);
                                                                    } else {
                                                                        errMsg = "Accepts numbers only"
                                                                    }
                                                                } else {
                                                                    setAudienceReach(text);
                                                                }
                                                                setErrAudienceReachMsg(errMsg);
                                                            }} />
                                                        <div className="input-outer-set">
                                                            <RSPPophover text="The number of audience in the area where QR code will be placed." position="top">
                                                                <i className='icon-sd-question-mark-mini icons-sm blue cp' />
                                                            </RSPPophover>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {index === 0 &&
                                                    <>
                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Communication URL</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <RSTextInputWithIcon
                                                                    name="website"
                                                                    ph="Enter communication URL"
                                                                    val={campaignURL}
                                                                    required={true}
                                                                    max={150}
                                                                    disabled={isShortURLCreated}
                                                                    errorMessage={errCamapignURLMsg}
                                                                    icon={`${isValidCommunicationURL ? 'icon-sd-circle-tick-mini icons-md green text-end' : ""}`}
                                                                    cb={(text) => {
                                                                        let errMsg = text !== "" ? null : CAMPAIGN_URL;
                                                                        setErrCampaignURLMsg(errMsg);
                                                                        setCampaignURL(text);
                                                                        setIsValidCommunicationURL(false)
                                                                        setGeneratedQR(null);
                                                                    }}
                                                                    ob={(text) => {
                                                                        if (text.length > 0 && isValidWebsite(text)) {
                                                                            checkWebsiteExist(text, "Communication URL")
                                                                        } else {
                                                                            setErrCampaignURLMsg(text.length ? ENTER_VALID_WEBSITE : CAMPAIGN_URL);
                                                                        }
                                                                    }} />
                                                                <div className="input-outer-set">
                                                                    <RSPTooltip text="Shortern URL" position="top">
                                                                        <i
                                                                            className={`icons-sm icon-sd-link-medium icons-md blue float-end cursor-pointer ${(isShortURLCreated || errCamapignURLMsg !== null) ? "click-off" : ""}`}
                                                                            onClick={() => {
                                                                                if (!isShortURLCreated) {
                                                                                    getShortenURL();
                                                                                }
                                                                            }
                                                                            }></i>
                                                                    </RSPTooltip>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Redirection URL</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <RSTextInputWithIcon
                                                                    name="website"
                                                                    ph="Enter redirection URL"
                                                                    // disabled={redirectionURL !== "" ? true : false}
                                                                    val={redirectionURL}
                                                                    required={true}
                                                                    max={150}
                                                                    errorMessage={errRedirectionURLMsg}
                                                                    icon={`${isValidRedirectionURL ? 'icon-sd-circle-tick-mini icons-md green text-end' : ""}`}
                                                                    cb={(text) => {
                                                                        let errMsg = text !== "" ? null : REDIRECTION_URL;
                                                                        setErrRedirectionURLMsg(errMsg);
                                                                        setRedirectionURL(text);
                                                                        setIsValidRedirectionURL(false)
                                                                    }}
                                                                    ob={(text) => {
                                                                        if (text.length > 0 && isValidWebsite(text)) {
                                                                            checkWebsiteExist(text, "Redirection URL")
                                                                        } else {
                                                                            setErrRedirectionURLMsg(text.length ? ENTER_VALID_WEBSITE : REDIRECTION_URL);
                                                                        }

                                                                    }} />
                                                                <div className="input-outer-set">
                                                                    <RSPPophover text="The user will be redirected to this URL if the QR code is scanned after the specified communication duration." position="top">
                                                                        <i className='icon-sd-question-mark-mini icons-sm blue cp' />
                                                                    </RSPPophover>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Know Your Customer (KYC)</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <Switch
                                                                    onLabel={"ON"}
                                                                    offLabel={"OFF"}
                                                                    onChange={(event) => {
                                                                        setKYCSwitch(event.target.value);
                                                                        setKYCType({
                                                                            "formId": 0,
                                                                            "formName": "Default KYC",
                                                                        })
                                                                        setFormHTMLContent("")
                                                                        setErrKYCMsg(null);
                                                                    }}
                                                                    checked={KYCSwitch}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {KYCSwitch &&
                                                            <Row className="mt10 custom-column-del">
                                                                <Col md={4}></Col>
                                                                <Col md={7}>
                                                                    <div className="position-relative">
                                                                        {errKYCMsg && (
                                                                            <div className="validation-message">
                                                                                {errKYCMsg}
                                                                            </div>)}
                                                                        <RSDropdownList
                                                                            data={formsList}
                                                                            value={KYCType}
                                                                            defaultItem={{
                                                                                "formId": 0,
                                                                                "formName": "Default KYC",
                                                                            }}
                                                                            textField="formName"
                                                                            dataItemKey="formId"
                                                                            onChange={(event) => {
                                                                                let errMsg = event.target.value.formId !== -1 ? null : KYC;
                                                                                setErrKYCMsg(errMsg);
                                                                                setKYCType(event.target.value);
                                                                                setFormHTMLContent("");
                                                                                if (event.target.value.formId > 0) {
                                                                                    getFormDataById(event.target.value.formId)
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                    <div className="input-outer-set">
                                                                        <i
                                                                            className={`icon-sd-eye-medium icons-md blue cursor-pointer ${formHTMLContent !== "" ? "" : "click-off"}`}
                                                                            onClick={() => {
                                                                                setIsShowKYCPreview(!isShowKYCPreview);
                                                                            }} />
                                                                    </div>
                                                                </Col>
                                                            </Row>}</>}
                                                {index === 1 &&
                                                    <>
                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Mobile number</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <RSInput2
                                                                    required={true}
                                                                    ph="Enter mobile number"
                                                                    errorMessage={errMobileNumMsg}
                                                                    val={mobileNumber}
                                                                    cb={(text) => {
                                                                        let errMsg = text !== "" ? null : MOBILE_NUMBER;
                                                                        setErrMobileNumMsg(errMsg);
                                                                        setMobileNumber(text);
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        < Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Message</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="position-relative">
                                                                    {errSMSMessageMsg && (
                                                                        <div className="validation-message">
                                                                            {errSMSMessageMsg}
                                                                        </div>)}
                                                                    <textarea
                                                                        placeholder="Enter message"
                                                                        value={SMSmessage}
                                                                        onChange={(event) => {
                                                                            let errMsg = event.target.value !== "" ? null : SMS_CONTENT;
                                                                            setSMSMessage(event.target.value);
                                                                            setErrSMSMessageMsg(errMsg)
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row></>}
                                                {index === 2 &&
                                                    <>
                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Send mail to</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <RSInput2
                                                                    required={true}
                                                                    ph="Enter mail address"
                                                                    val={sendMailID}
                                                                    errorMessage={errSendMailIDMsg}
                                                                    cb={(text) => {
                                                                        let errMsg = text !== "" ? null : MAIL_ID;
                                                                        setErrSendMailIDMsg(errMsg);
                                                                        setSendMailID(text);
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Subject line</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <RSInput2
                                                                    required={true}
                                                                    ph="Enter subject line"
                                                                    val={subjectLine}
                                                                    errorMessage={errSubjectLineMsg}
                                                                    cb={(text) => {
                                                                        let errMsg = text !== "" ? null : SUBJECT_LINE;
                                                                        setErrSubjectLineMsg(errMsg);
                                                                        setSubjectLine(text);
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row className="custom-column-del">
                                                            <Col md={4}>
                                                                <label className="label-row">Message</label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="position-relative">
                                                                    {errMessageMsg && (
                                                                        <div className="validation-message">
                                                                            {errMessageMsg}
                                                                        </div>)}
                                                                    <textarea
                                                                        placeholder="Enter message"
                                                                        value={message}
                                                                        onChange={(event) => {
                                                                            let errMsg = event.target.value !== "" ? null : MAIL_CONTENT;
                                                                            setMessage(event.target.value);
                                                                            setErrMessageMsg(errMsg)
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>}
                                                <Row className='mt25'>
                                                    <Col md={11} className='d-flex justify-content-end'>
                                                        <RSPrimaryBtn className={`${KYCSwitch ? "" : "click-off"}`} onClick={() => {
                                                            let enable = false;
                                                            enable = validation();
                                                            if (enable) {
                                                                if (webQRContent !== "" && !isShortURLCreated) {
                                                                    generateQRCode(campaignId, webQRContent);
                                                                } else {
                                                                    let strQRContent = getWebURLQR("add", "", "");
                                                                    setWebQRContent(strQRContent)
                                                                }
                                                            }
                                                        }}>Generate</RSPrimaryBtn>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} className='pr0'>
                                                {/* <QRPreviewComponent /> */}
                                                <QRPreviewNew
                                                    range={sliderValue}
                                                    isShow={campaignURL !== "" ? true : false}
                                                    isShortCode={isShortCode}
                                                    campaignURL={campaignURL}
                                                    webQRURL={webQRURL}
                                                    isShortURLCreated={isShortURLCreated}
                                                    campaignID={campaignId}
                                                    getBase64Content={(value) => {
                                                        setQRBase64Content(value.split(',')[1]);
                                                    }}
                                                    getSliderValue={(value) => {
                                                        setSliderValue(value)
                                                    }}
                                                    getIsShortCode={(value) => {
                                                        setIsShortCode(value);
                                                    }}
                                                    generatedQR={generatedQR}
                                                />
                                            </Col>
                                        </Row>
                                        {isShowKYCPreview && <KYCPreviewComponent isOpen={isShowKYCPreview} formId={KYCType.formId} formHTMLContent={formHTMLContent} onChangeIsOpen={(value) => { setIsShowKYCPreview(!isShowKYCPreview) }} />}
                                    </Container>
                                </div >
                                <div className="btn-container d-flex justify-content-end mt40">
                                    <RSSecondaryBtn paddingR="0px" onClick={backToCanvas}>
                                        Cancel
                                    </RSSecondaryBtn>
                                    <RSPrimaryBtn className={` ${!!generatedQR ? "" : "click-off"}`}
                                        onClick={() => {
                                            let enable = false;
                                            enable = validation();
                                            if (enable) {
                                                getQRCode();
                                            }
                                        }}
                                    >
                                        {" "}
                                        {campaignModeAddOrEdit === "add"
                                            ? "Save"
                                            : "Update"}
                                    </RSPrimaryBtn>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </LayoutPageWrapper>
            {isOpenRefreshModal &&
                <RSConfirmAlert
                    title="Are you sure you want to refresh?"
                    secondaryButtonText="Cancel"
                    primaryButtonText="OK"
                    buttonClicked={(value) => {
                        if (value) {
                            let temp = [...tabData];
                            temp[0].disable = false;
                            temp[1].disable = false;
                            temp[2].disable = false;
                            setIndex(0);
                            setIsShowRefresh(false);
                        }
                        setIsOpenRefereshModal(false)
                    }}
                />
            }
        </>
    );
};
export default withRouter(MdcQRCreation);
const goalList = [
    { shortCode: "R", name: "Reach" },
    { shortCode: "C", name: "Conversion" },
    { shortCode: "E", name: "Engagement" },
];
