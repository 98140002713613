import React from 'react'
import SpamScale from 'Assets/Images/spam-scale.png'

const SpamScoreRangeSlider = (props) => {
  return (
    <>
        <div className='spam-scale position-relative'>
            <div className=''>
                <img src={SpamScale} alt={SpamScale} />
                <div className='spam-scale-handle top-2' style={{ left: props.spamScore }}></div>
                <div className='d-flex justify-content-between mt10'>
                    <span>0%</span>
                    <span>100%</span>
                </div>
            </div>
        </div>
    </>
  )
}

export default SpamScoreRangeSlider