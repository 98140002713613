import React from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPTooltip } from "Components/Tooltip";
import { GET_SMTP_LIST, DELETE_SMTP } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import DomainMapingModal from "./DomainMapingModal";
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';

const dataState = {
  skip: 0,
  take: 5,
};

class SMTPList extends React.Component {

  static contextType = authContext;

  state = {
    dataResult: null, //process(this.tempList, dataState),
    dataState: dataState,
    smtpList: [],
    listError: false,
    index: 0,
    mode: "add",
    isOpenInfoModal: false
  };

  componentDidMount = () => {
    this.getDataFromServer();
  };

  getDataFromServer = () => {
    this.setState({ dataResult: null, smtpList: [], listError: false });
    connectServer({
      path: GET_SMTP_LIST,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            let newTemp = [...res.data];
            let some = process(newTemp, dataState);
            this.setState({
              smtpList: res.data,
              dataResult: Array.prototype.reverse.call(some),
            });
          } else {
            this.setState({ listError: true });
          }
        }
      },
      fail: (err) => {
        this.setState({ listError: true });
      },
    });
  };

  dataStateChange = (event) => {
    this.setState({
      dataResult: process(this.state.smtpList, event.dataState),
      dataState: event.dataState,
    });
  };

  handleChange = (clientSmsSettingId, value) => {
    var params = {
      clientSmtpId: clientSmsSettingId,
      status: value ? "A" : "D",
    };
    connectServer({
      path: DELETE_SMTP,
      params: params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          this.setState({ dataState: dataState })
          this.getDataFromServer();
        }
      },
      fail: (err) => { },
    });
  };

  CONFILTER = [
    {
      title: 'Server name',
      field: 'serverName',
      width: 300,
    },
    {
      title: 'SMTP type',
      field: 'smtpType',
      // width: 220,
    },
    // {
    //   title: 'SMTP housing',
    //   field: 'smtpHousing',
    // },
    {
      title: 'Status',
      field: 'Status',
      width: 180,
      cell: ({ dataItem }) => (
        <td>
          <div>
            <Switch
              className="mt0"
              onChange={(e) => {
                this.handleChange(
                  dataItem.clientSmtpId,
                  e.target.value
                );
              }}
              checked={dataItem.status ? true : false}
            />
          </div>
        </td>
      ),
    },
    {
      title: 'Action',
      field: 'Action',
      width: 180,
      cell: ({ dataItem }) => (
        <td className="camp-icon">
          <ul className="camp-icon-pannel">
            <li>
              <RSPTooltip text="Edit" position="top">
                <i
                  className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                  onClick={() => {
                    this.setState({ mode: "edit", index: 1 });
                    this.props.onChangeIndex(1);
                    this.props.getSmtpId(dataItem.clientSmtpId);
                    this.props.onChangeMode("edit");
                  }}
                />
              </RSPTooltip>
            </li>
          </ul>
        </td>
      ),
    },
  ]


  render() {
    return (
      <Container className="p0 mt15">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <h3 className="marginB0">SMTP</h3>
          <div className="d-flex align-items-center">
            <RSPTooltip text="SMTP user guide" position="top">
              <i
                className="icon-sd-circle-info-medium icons-lg blue mr10 cursor-pointer icon-shadow"
                onClick={() => {
                  this.setState({ isOpenInfoModal: true });
                }}
              ></i>
            </RSPTooltip>
            <RSPTooltip text="Add" position="top">
              <i
                className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer icon-shadow"
                onClick={() => {
                  this.setState({ mode: "add", index: 1 });
                  this.props.onChangeIndex(1);
                  this.props.onChangeMode("add");
                }}
              ></i>
            </RSPTooltip>
          </div>
        </div>
        {this.state.dataResult?.data ? (
          <>
            <KendoGrid
              data={this.state?.dataResult?.data}
              scrollable={'scrollable'}
              column={this.CONFILTER}
              pageable
              settings={{
                total: this.state?.dataResult?.data?.length
              }}
            />

            {/* <Grid
              data={this.state.dataResult}
              {...this.state.dataState}
              onDataStateChange={this.dataStateChange}
              scrollable={"none"}
              sortable={false}
              className="grid-pagenation-del"
              messages={{
                itemsPerPage: "data items per page",
              }}
              onPageChange={this.pageChange}
              pageable={{
                pageSizes: [5, 10, 20],
                previousNext: true,
              }}
            >
              <GridColumn title="Server name" field="serverName" />
              <GridColumn title="SMTP type" field="smtpType" />
              <GridColumn title="SMTP housing" field="smtpHousing" />
              <GridColumn
                title="Status"
                cell={(props) => {
                  return (
                    <td>
                      <div>
                        <Switch
                          className="mt0"
                          onChange={(e) => {
                            this.handleChange(
                              props.dataItem.clientSmtpId,
                              e.target.value
                            );
                          }}
                          checked={props.dataItem.status ? true : false}
                        />
                      </div>
                    </td>
                  );
                }}
              />
              <GridColumn
                title="Action"
                cell={(props) => {
                  return (
                    <td className="camp-icon">
                      <ul className="camp-icon-pannel">
                        <li>
                          <RSPTooltip text="Edit" position="top">
                            <i
                              className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                              onClick={() => {
                                this.setState({ mode: "edit", index: 1 });
                                this.props.onChangeIndex(1);
                                this.props.getSmtpId(props.dataItem.clientSmtpId);
                                this.props.onChangeMode("edit");
                              }}
                            />
                          </RSPTooltip>
                        </li>
                      </ul>
                    </td>
                  );
                }}
              />
            </Grid> */}
          </>
        ) : (
          <SkeletonTable count={5} isError={this.state.listError} />
        )}
        {this.state.isOpenInfoModal &&
          <DomainMapingModal isOpen={this.state.isOpenInfoModal} onChangeIsOpen={(value) => {
            this.setState({ isOpenInfoModal: value })
          }} />
        }
      </Container>
    );
  }
}
export default SMTPList;