import React from 'react';
import { Container } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import Email from './Tabs/Email/Email';
import Messaging from './Tabs/Messaging/Messaging';
import { RTabbar } from 'Components/RChart/RTabbar';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import FrequencyCap from './FrequencyCap/FrequencyCap';

class CommunicationSettings extends React.Component {

    state = {
        index: 0,
        hTabIndex: 0
    }

    componentDidMount = () => {
        if (this?.props?.location?.state?.from === "MDC") {
            const reloadCount = sessionStorage.getItem('reloadCount');
            if (reloadCount < 2) {
                sessionStorage.setItem('reloadCount', String(reloadCount + 1));
                window.location.reload();
            } else {
                sessionStorage.removeItem('reloadCount');
            }
        }
        window.scrollTo(0, 0);
        this.setState({ index: this.props.location.state !== undefined ? this.props.location.state.hIndex : 0 })
    }
    comSettingsTabComponents = props => {
        switch (this.state.index) {
            case 0:
                return <Email index={this.props.location.state !== undefined ? this.props.location.state.index : 0} cb={status => this.setState({ isAppPermission: status })} />
            case 1:
                return <Messaging cb={status => this.setState({ isAppPermission: status })} />
            default:
                return <Email index={this.props.location.state !== undefined ? this.props.location.state.index : 0} cb={status => this.setState({ isAppPermission: status })} />
        }
    }
    tabs = [
        { text: 'Email', iconLeft: 'icon-sd-email-medium icons-lg' },
        { text: 'Messaging', iconLeft: 'icon-sd-messaging-large icons-lg' },
    ];

    tabsComponent = props => {

        return (
            <>
                <div className="tabs-vertical">
                    <RTabbar
                        defaultSelectedItem={this.state.index}
                        defaultClass="tabDefault"
                        dynamicTab="vertical-tabs"
                        activeClass="tabDefault active"
                        tabData={this.tabs}
                        callBack={(item, index) => { this.setState({ index }) }}
                    />
                </div>
                <div className="tab-vertical-content paddingT0">
                    {this.comSettingsTabComponents()}
                </div>
            </>
        )
    }
    renderComponent = props => {
        switch (this.state.hTabIndex) {
            case 0:
                return this.tabsComponent()
            case 1:
                return <FrequencyCap />
            default:
                return this.tabsComponent()
        }
    }

    horizontalTab = () => {

        const tabData = [
            { "id": 1001, "text": "Channel settings", "disable": false }
        ]

        return (
            <>
                <div className="portlet-box-theme bgWhite border-top-default padding0">
                    <div className="tabs-filled-component-del">
                        {/* <RTabbar
                            defaultSelectedItem={this.state.hTabIndex}
                            defaultClass="tabDefault"
                            dynamicTab="tabs-filled-component"
                            activeClass="tabDefault active"
                            tabData={tabData}
                            callBack={(item, index) => { this.setState({ hTabIndex: index }) }}
                        /> */}
                        <div className="padding0 d-flex">
                            {this.renderComponent(this.state.hTabIndex)}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    render() {
        return (
            <>
                <LayoutPageWrapper>
                    <Container className="page-header box-bottom-space">
                        <div className="header-title d-flex justify-content-between">
                            <h1>Communication settings</h1>
                            <div className="back-text" onClick={() => this.props.history.push(`/preferences`)}>
                                <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                                Back
                            </div>
                        </div>
                        <this.horizontalTab />
                    </Container>
                </LayoutPageWrapper>
            </>
        )
    }
}

export default withRouter(CommunicationSettings);

