import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import UnsubscribeCreation from "./UnsubscribeCreation";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPTooltip } from "Components/Tooltip";
import {
  GET_UNSUBSCRIPTION_LIST,
  DELETE_UNSUBSCRIPTION,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { getAuthUserId } from "Helper/Utils/Utils";
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';

const initialDataState = {
  skip: 0,
  take: 5,
};

const Unsubscribe = (props) => {

  const context = useContext(authContext);

  const [page, setPage] = useState(initialDataState);
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("add");
  const [status, setStatus] = useState("");
  const [unSubscribeId, setUnSubscribeId] = useState();
  const [listData, setListData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [listError, setListError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    getDataFromServer();
  }, [context.globalStateData.getIsReloadData]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const getDataFromServer = () => {
    setListError(false);
    const params = {
      userId: getAuthUserId(),
    };
    connectServer({
      path: GET_UNSUBSCRIPTION_LIST,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            setListData(res.data || []);
          } else {
            setListError(true);
          }
        } else {
          setListError(true);
        }
      },
      fail: (err) => {
        setListError(true);
      },
    });
  };

  const CONFILTER = [
    {
      title: 'Unsubscribe list',
      field: 'unsubscribeName',
    },
    {
      title: 'Action',
      field: 'Action',
      width: 180,
      cell: ({ dataItem }) => (
        <td className="camp-icon">
          <div className="table-action-btn">
            <RSPTooltip text="Edit" position="top">
              <i
                className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                onClick={() => {
                  history.push({
                    pathname: `unsubscribe-creation`,
                    state: {
                      unsubscribeSettingId:
                      dataItem.unsubscribeSettingId,
                      mode: "edit",
                    },
                  });
                }}
              />
            </RSPTooltip>
          </div>
        </td>
      )
    }
  ]

  const UnSubscribeList = (props) => {
    return (
      <Container className="p0 mt15 clearfix float-start">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <div className="d-flex align-items-center position-relative">
            <h3 className="marginB0">Unsubscribe</h3>
            <RSPTooltip text="Create relevant 'Unsubscription' content to be included in email correspondence that unsubscribed users will receive." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
            </RSPTooltip>
          </div>
          <RSPTooltip text="Add" position="top">
            <i
              className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer icon-shadow"
              onClick={() => {
                history.push({
                  pathname: `unsubscribe-creation`,
                  state: {
                    unsubscribeSettingId: 0,
                    mode: "add",
                  },
                });
              }}
            ></i>
          </RSPTooltip>
        </div>
        {listData?.length ? (
          <>
            <KendoGrid
              data={listData}
              scrollable={'scrollable'}
              column={CONFILTER}
              pageable
              settings={{
                total: listData?.length
              }}
            />
            {/* <Grid
              data={listData?.slice(page.skip, page.take + page.skip)}
              skip={page.skip}
              take={page.take}
              total={listData?.length}
              scrollable={"none"}
              sortable={false}
              className="grid-pagenation-del"
              messages={{
                itemsPerPage: "data items per page",
              }}
              onPageChange={pageChange}
              pageable={{
                //type: 'input',
                pageSizes: [5, 10, 20],
                previousNext: true,
              }}
            >
              <GridColumn title="Unsubscribe list" field="unsubscribeName" />
              <GridColumn
                title="Action"
                cell={(props) => {
                  return (
                    <td className="camp-icon">
                      <div className="table-action-btn">
                        <RSPTooltip text="Edit" position="top">
                          <i
                            className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                            onClick={() => {
                              history.push({
                                pathname: `unsubscribe-creation`,
                                state: {
                                  unsubscribeSettingId:
                                    props.dataItem.unsubscribeSettingId,
                                  mode: "edit",
                                },
                              });
                            }}
                          />
                        </RSPTooltip>
                        comment code start -- <li>
                          <RSPTooltip text="Duplicate" position="top">
                            <i className="icon-sd-duplicate-medium icons-md blue cursor-pointer click-off"></i>
                          </RSPTooltip>
                        </li>
                        <li>
                          <RSPTooltip text="Delete" position="top">
                            <i
                              className={`icon-sd-delete-medium icons-md blue cursor-pointer ${props.dataItem.status ? "" : "click-off"
                                }`}
                              onClick={() => {
                                setUnSubscribeId(
                                  props.dataItem.unsubscribeSettingId
                                );
                                setStatus(props.dataItem.status);
                                setIsDeleteModalOpen(!isDeleteModalOpen);
                              }}
                            ></i>
                          </RSPTooltip>
                        </li> -- comment code end
                      </div>
                    </td>
                  );
                }}
              />
            </Grid> */}
          </>
        ) : (
          <SkeletonTable text={"to create Unsubscribe"} count={5} isError={listError} />
        )}
        {isDeleteModalOpen && (
          <RSConfirmAlert
            title="Are you sure you want to delete it?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                let rowStatus = status ? "D" : "A";
                let params = {
                  unSubscribeSettingId: unSubscribeId,
                  status: rowStatus,
                };
                connectServer({
                  path: DELETE_UNSUBSCRIPTION,
                  params: params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: (res) => {
                    if (res.status) {
                      getDataFromServer();
                    }
                  },
                  fail: (err) => {
                  },
                });
              }
              setIsDeleteModalOpen(!isDeleteModalOpen);
            }}
          />
        )}
      </Container>
    );
  };

  const renderComponent = [
    <UnSubscribeList />,
    <UnsubscribeCreation settingId={unSubscribeId} mode={mode} />,
  ];

  return <>{renderComponent[index]}</>;
};
export default withRouter(Unsubscribe);
