// import { RSLog } from 'Helper/Utils/RSLog';
import { excludeSuccess, getServerConfig } from 'Helper/Utils/Utils';

export const connectServer = ({
    path = null,
    ok = null,
    fail = null,
    bUrl = getServerConfig(),
    method = 'POST',
    params = null,
    loading = null,
    sessionOut = null,
    retry = 0,
    retryTime = 0,
    context = null,
}) => {
    if (!path) fail && fail('Path not found')
    let baseUrl = getServerConfig();
    let url = baseUrl + path
    const sessionStr = localStorage.getItem('session')
    const sessionObject = JSON.parse(sessionStr)
    const accessToken = sessionObject ? sessionObject.accessToken : ""
    const isAuth = sessionObject ? sessionObject.isAuth : false

    if (isAuth && !accessToken) {
        fail('accessDenied')
        return
    }
    if (accessToken === "") {
        context.toggleAuth(false)
    }
    const headers = {
        'content-type': 'application/json',
        accessToken: accessToken ? accessToken : ''
    }
    const options = { method, headers }

    // RSLog(
    //     'Server request',
    //     { url, retry, params: params || {}, ...options },
    //     true
    // )

    if (params && method === "POST") options.body = JSON.stringify(params)
    if (params && method === "GET") url = url + "?" + objToQueryString(params)
    if (navigator.onLine) {
        loading && loading(true)
        fetch(url, options).then((response) => {
            // loading && loading(false)
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));

        }).then(({ status, json }) => {
            // RSLog('Server response', json, true)
            if (!excludeSuccess(status, url)) {
                sessionOut && sessionOut(status)
            }
            loading && loading(false)
            if (status === 200 || status === 201) {
                ok && ok(json)
            } else {
                if (retry > 0) {
                    return setTimeout(
                        connectServer({
                            path,
                            ok,
                            fail,
                            bUrl,
                            method,
                            params,
                            loading,
                            sessionOut,
                            retryTime,
                            retry: retry - 1
                        }),
                        retryTime
                    )
                }
                fail(json.message)
            }

        })
        //     if (retry > 0) {
        //         return setTimeout(
        //             connectServer({
        //                 path,
        //                 ok,
        //                 fail,
        //                 bUrl,
        //                 method,
        //                 params,
        //                 loading,
        //                 sessionOut,
        //                 retryTime,
        //                 retry: retry - 1
        //             }),
        //             retryTime
        //         )
        //     }
        //     throw new Error(JSON.stringify({
        //         url,
        //         statusCode: res.status,
        //         statusText: res.statusText,
        //         retry
        //     }))
        // })
        //     .then(data => {
        //         loading && loading(false)
        //         const _data = { url, retry, ...data }
        //         RSLog('Server response', _data, true)
        //         ok && ok(data)
        //     })
        //     .catch(error => {
        //         loading && loading(false)
        //         RSLog('Server error', error, true)
        //         fail && fail(error)
        //     })
    } else {
        loading && loading(false)
        fail('No internet connection')
        // RSLog('Internet connection', 'Unavailable', true)
    }
}

export const downloadConnectServer = ({
    path = null,
    ok = null,
    fail = null,
    bUrl = getServerConfig(),
    method = 'POST',
    params = null,
    loading = null,
    sessionOut = null,
    retry = 0,
    retryTime = 0,
    fileName = ''
}) => {
    if (!path) fail && fail('Path not found')
    let baseUrl = getServerConfig();
    let url = baseUrl + path
    const sessionStr = localStorage.getItem('session')
    const sessionObject = JSON.parse(sessionStr)
    const accessToken = sessionObject ? sessionObject.accessToken : ""
    const isAuth = sessionObject ? sessionObject.isAuth : false

    if (isAuth && !accessToken) {
        fail('accessDenied')
        return
    }
    const headers = {
        'content-type': 'application/json',
        accessToken: accessToken ? accessToken : ''
    }
    const options = { method, headers }

    // RSLog(
    //     'Server request',
    //     { url, retry, params: params || {}, ...options },
    //     true
    // )

    if (params && method === "POST") options.body = JSON.stringify(params)
    if (params && method === "GET") url = url + "?" + objToQueryString(params)
    if (navigator.onLine) {
        loading && loading(true)
        fetch(url, options).then((response) => {
            return new Promise((resolve) => response.blob()
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = fileName !== "" ? fileName : "Consumption.csv";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }));
            loading && loading(false)
        }).then(({ status, json }) => {
            // RSLog('Server response', json, true)
            if (!excludeSuccess(status, url)) {
                sessionOut && sessionOut(status)
            }
            if (status === 200 || status === 201) {
                ok && ok(json)
            } else {
                if (retry > 0) {
                    return setTimeout(
                        connectServer({
                            path,
                            ok,
                            fail,
                            bUrl,
                            method,
                            params,
                            loading,
                            sessionOut,
                            retryTime,
                            retry: retry - 1
                        }),
                        retryTime
                    )
                }
                fail(json.message)
            }
            loading && loading(false)
        })
        //     if (retry > 0) {
        //         return setTimeout(
        //             connectServer({
        //                 path,
        //                 ok,
        //                 fail,
        //                 bUrl,
        //                 method,
        //                 params,
        //                 loading,
        //                 sessionOut,
        //                 retryTime,
        //                 retry: retry - 1
        //             }),
        //             retryTime
        //         )
        //     }
        //     throw new Error(JSON.stringify({
        //         url,
        //         statusCode: res.status,
        //         statusText: res.statusText,
        //         retry
        //     }))
        // })
        //     .then(data => {
        //         loading && loading(false)
        //         const _data = { url, retry, ...data }
        //         RSLog('Server response', _data, true)
        //         ok && ok(data)
        //     })
        //     .catch(error => {
        //         loading && loading(false)
        //         RSLog('Server error', error, true)
        //         fail && fail(error)
        //     })
    } else {
        loading && loading(false)
        fail('No internet connection')
        // RSLog('Internet connection', 'Unavailable', true)
    }
}

const objToQueryString = (obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}