import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { GET_CAMPAIGN_STATUS_DROPDOWN, GET_CAMPAIGN_STATUS_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { dateTimeFormatWithMomentForReports } from 'Helper/Utils/Utils';
import React, { useContext, useEffect, useState } from 'react';
import { RSPTooltip } from 'Components/Tooltip';
import DownloadCSVModal from '../../Components/DownloadCSVModal';
const initialDataState = {
    sort: [
        {
            field: "rowNo",
            dir: "asc"
        },
    ],
    take: 5,
    skip: 0
}
function WhatsAppCommunicationStatus(props) {

    const context = useContext(authContext)
    const [dataState, setDataState] = useState(initialDataState)
    const [campaignStatusList, setCampaignStatusList] = useState(null);
    const [data, setData] = useState(null)
    const [campaignStatusDropdownData, setCampaignStatusDropdownData] = useState([])
    const [defaultDropdownData, setDefaultDropdownData] = useState("Delivered");
    const [defaultDropdownValue, setDefaultDropdownValue] = useState("Delivered");
    const [listStatusError, setListStatusError] = useState(false);
    const [rowKey, setRowKey] = useState(props.rowKey);
    const [campaignId, setCampaignId] = useState(props.campaignId);
    const [latestDate, setLatestDate] = useState(props.latestDate);
    const [isOpenDownloadCSVModal, setIsOpenDownloadCSVModal] = useState(false);

    useEffect(() => {
        getCampaignStatusDropdown();
    }, [])
    useEffect(() => {
        if (rowKey === "") {
            setCampaignId(props.campaignId);
            setRowKey(props.rowKey);
            setLatestDate(props.latestDate);
            let statusParams = {
                status: "Delivered",
                rowKey: props.rowKey
            }
            getCampaignStatus(statusParams)
        }
    }, [props])


    const getCampaignStatusDropdown = () => {

        const params = {
            "channelId": 21
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_DROPDOWN,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status) {
                    setCampaignStatusDropdownData(res?.data?.channelKeyDetailList || [])
                    setDefaultDropdownData(res?.data?.channelKeyDetailList[0].value)
                }
            },
            fail: err => { }
        })
    }

    const getCampaignStatus = data => {
        setListStatusError(false);
        const { campaignId } = props
        const params = {
            "campaignId": campaignId,
            "channelId": 21,
            "status": data?.status,
            "rowkey": data?.rowKey
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_LIST,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status !== undefined && res?.data?.activityList?.length) {
                    setCampaignStatusList(res?.data?.activityList || [])
                } else {
                    setCampaignStatusList([]);
                    setListStatusError(true);
                }
            },
            fail: err => {
                setCampaignStatusList([]);
                setListStatusError(true);
            }
        })

    }
    return (
        <>
            <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    <h3 className="marginB15">Communication status ({campaignStatusList?.length})</h3>
                    <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(data?.latestDate)}</span>)</span>
                </div>
                <div className='d-flex justify-content-end'>
                    <BootstrapDropdown
                        data={campaignStatusDropdownData || []}
                        defaultItem={defaultDropdownData}
                        customAlignRight={true}
                        className="fs18-btn"
                        alignRight
                        fieldKey='name'
                        onSelect={(item, index) => {
                            setCampaignStatusList([]);
                            setDefaultDropdownData(item.name);
                            setDefaultDropdownValue(item.value);
                            let statusParams = {
                                status: item.value,
                                rowKey: rowKey
                            }
                            getCampaignStatus(statusParams)
                        }}
                    />
                    <RSPTooltip text={"Download CSV"} position="top">
                        <i className={`icon-sd-csv-medium icons-md blue ml10 cp ${campaignStatusList?.length !== 0 ? "" : "click-off"}`} onClick={() => {
                            setIsOpenDownloadCSVModal(true);
                        }} />
                    </RSPTooltip>
                </div>
                {isOpenDownloadCSVModal &&
                    <DownloadCSVModal
                        isOpen={isOpenDownloadCSVModal}
                        channelId={21}
                        isForm={false}
                        campaignId={campaignId}
                        rowKey={rowKey}
                        status={defaultDropdownValue}
                        onChangeIsOpen={(value) => {
                            setIsOpenDownloadCSVModal(false)
                        }} />
                }
            </div>
            {
                campaignStatusList?.length ?
                    <Grid
                        data={process(campaignStatusList, dataState || [])}
                        {...dataState}
                        scrollable={"none"}
                        className={`grid-pagenation-del grid-replaceable mb0`}
                        sortable={true}
                        pageable={false}
                        onDataStateChange={(e) => setDataState(e.dataState)}
                    >
                        <GridColumn field="rowNo" filter="text" columnMenu={ColumnMenu} title="S.No." />
                        <GridColumn field="mobileNo" filter="text" columnMenu={ColumnMenu} title="Mobile Number" />
                        {defaultDropdownData === "Total clicks" &&
                            <GridColumn field="totalClicks" filter="text" columnMenu={ColumnMenu} title="No of clicks" />
                        }
                    </Grid>
                    : <SkeletonTable count={4} isError={listStatusError} />
            }</>);

}

export default WhatsAppCommunicationStatus;
const ColumnMenu = props => {

    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;

};