import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import SubscribeCreation from "./SubscribeCreation";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import {
  GET_SUBSCRIPTION_LIST,
  DELETE_SUBSCRIPTION,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { getAuthUserId } from "Helper/Utils/Utils";
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';

const initialDataState = {
  skip: 0,
  take: 5,
};

const Subscribe = (props) => {

  const context = useContext(authContext);
  const history = useHistory();

  const [page, setPage] = useState(initialDataState);
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("add");
  const [status, setStatus] = useState(false);
  const [subscribeId, setSubscribeId] = useState();
  const [listData, setListData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [listError, setListError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    getDataFromServer();
  }, [context.globalStateData.getIsReloadData]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const getDataFromServer = () => {
    setListError(false);
    const params = {
      userId: getAuthUserId(),
    };
    connectServer({
      path: GET_SUBSCRIPTION_LIST,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            setListData(res.data || []);
          } else {
            setListError(true);
          }
        } else {
          setListError(true);
        }
      },
      fail: (err) => {
        setListError(true);
      },
    });
  };

  const CONFILTER = [
    {
      title: 'Subscribe list',
      field: 'subscribeName',
    },
    {
      title: 'Action',
      field: 'Action',
      width: 180,
      cell: ({ dataItem }) => (
        <td className="camp-icon">
          <div className="table-action-btn">
            <RSPTooltip text="Edit" position="top">
              <i
                className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                onClick={() => {
                  history.push({
                    pathname: `subscribe-creation`,
                    state: {
                      subscribeSettingId:
                      dataItem?.subscribeSettingId,
                      mode: "edit"
                    }
                  })
                }}
              />
            </RSPTooltip>
          </div>
        </td>
      )
    }
  ]

  const SubscribeList = (props) => {
    return (
      <Container className="p0 mt15 clearfix float-start">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <div className="d-flex align-items-center position-relative">
            <h3 className="marginB0">Subscribe</h3>
            <RSPTooltip text="Create relevant 'Subscription' content that can be included in emails and delivered to subscribers." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
            </RSPTooltip>
          </div>
          <RSPTooltip text="Add" position="top">
            <i
              className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer icon-shadow"
              onClick={() => {
                history.push({
                  pathname: `subscribe-creation`,
                  state: {
                    subscribeSettingId: 0,
                    mode: "add",
                  },
                });
              }}
            ></i>
          </RSPTooltip>
        </div>
        {listData?.length ? (
          <>
            <KendoGrid
              data={listData}
              scrollable={'scrollable'}
              column={CONFILTER}
              pageable
              settings={{
                total: listData?.length
              }}
            />
            {/* <Grid
              data={listData?.slice(page.skip, page.take + page.skip)}
              skip={page.skip}
              take={page.take}
              total={listData?.length}
              scrollable={"none"}
              sortable={false}
              className="grid-pagenation-del"
              messages={{
                itemsPerPage: "data items per page",
              }}
              onPageChange={pageChange}
              pageable={{
                //type: 'input',
                pageSizes: [5, 10, 20],
                previousNext: true,
              }}
            >
              <GridColumn title="Subscribe list" field="subscribeName" />
              <GridColumn
                title="Action"
                cell={(props) => {
                  return (
                    <td className="camp-icon">
                      <div className="table-action-btn">
                        <RSPTooltip text="Edit" position="top">
                          <i
                            className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                            onClick={() => {
                              history.push({
                                pathname: `subscribe-creation`,
                                state: {
                                  subscribeSettingId:
                                    props.dataItem.subscribeSettingId,
                                  mode: "edit",
                                },
                              });
                            }}
                          />
                        </RSPTooltip>
                        comment code start -- <li>
                        <RSPTooltip text="Duplicate" position="top">
                          <i className="icon-sd-duplicate-medium icons-md blue cursor-pointer click-off"></i>
                        </RSPTooltip>
                      </li>
                        <li>
                        <RSPTooltip text="Delete" position="top">
                          <i
                            className={`icon-sd-delete-medium icons-md blue cursor-pointer ${props.dataItem.status ? "" : "click-off"
                              }`}
                            onClick={() => {
                              setSubscribeId(props.dataItem.subscribeSettingId);
                              setStatus(props.dataItem.status);
                              setIsDeleteModalOpen(!isDeleteModalOpen);
                            }}
                          ></i>
                        </RSPTooltip>
                      </li> -- comment code end
                      </div>
                    </td>
                  );
                }}
              />
            </Grid> */}
          </>
        ) : (
          <SkeletonTable text={"to create subscribe"} count={5} isError={listError} />
        )}
        {isDeleteModalOpen && (
          <RSConfirmAlert
            title="Are you sure you want to delete it?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                let params = {
                  subscribeSettingId: subscribeId,
                  status: status ? "D" : "A",
                };
                connectServer({
                  path: DELETE_SUBSCRIPTION,
                  params: params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: (res) => {
                    if (res.status) {
                      getDataFromServer();
                    }
                  },
                  fail: (err) => { },
                });
              }
              setIsDeleteModalOpen(!isDeleteModalOpen);
            }}
          />
        )}
      </Container>
    );
  };

  const renderComponent = [
    <SubscribeList onChangeIndex={(index) => setIndex(index)} />,
    <SubscribeCreation
      onChangeIndex={(index) => setIndex(index)}
      settingId={subscribeId}
      mode={mode}
    />,
  ];

  return <>{renderComponent[index]}</>;
};
export default Subscribe;