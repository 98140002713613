import React, { useContext, useState } from 'react';
import BrandDetails from './Components/BrandDetails';
import { Container } from "react-bootstrap"
import ContactInfo from './Components/ContactInfo';
import LocationSettings from './Components/LocationSettings';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { useEffect } from 'react';
import authContext from "Helper/StateHandler/auth-context";

const KeyContactInfo = props => {

    const context = useContext(authContext)

    const [index, setIndex] = useState(0)
    const [params, setParams] = useState({})
    const [bodyDataClass, setBodyDataClass] = useState('keycontact')

    const updateDataAndIndex = (index, newParams) => {

        if (newParams) {
            const data = { ...params, ...newParams }
            setParams(data)
        }
        setIndex(index)
    }

    useEffect(()=> {
        context.globalStateData.setSelectedDataStatus('keycontact')
    })

    const componentList = [
        <ContactInfo updateDataAndIndex={updateDataAndIndex} i={index} params={params} />,
        <BrandDetails updateDataAndIndex={updateDataAndIndex} i={index} params={params} />,
        <LocationSettings updateDataAndIndex={updateDataAndIndex} i={index} params={params} />]

    const getSelectedDot = () => {
        if (index === 0) { return "first" }
        else if (index === 1) { return "second" }
        else if (index === 2) { return "third" }
        else { return "first" }
    }
    return (
        <>
            <LayoutPageWrapper>
                {componentList[index]}
                <Container className="rs-dotted-box">
                    <ul className={`rs-dotted ${getSelectedDot()}`}> {/* first, second, third */}
                        <li></li> <li></li> <li></li>
                    </ul>
                </Container>
            </LayoutPageWrapper>
        </>
    );
}


export default KeyContactInfo;

