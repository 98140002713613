import React, { useEffect, useState } from 'react';

const SubscriptionInvoiceById = props => {
    const [invoiceDetails, setInvoiceDetails] = useState(props.invoiceDetails)
    useEffect(() => {
        setInvoiceDetails(props.invoiceDetails)
    }, [props])
    return (
        <div className='invoice-table-wrapper'>
            <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table rs-invoice-table">
                <thead>
                    <tr>
                        {/* <th width="35%">Subscription type</th>
                                        <th width="25%">Price/mo. (USD)</th>
                                        <th width="25%">Payment frequency</th>
                                        <th>Amount (USD)</th> */}
                        <th width="5%">S.No</th>
                        <th width="20%">Name</th>
                        <th width="15%">Type</th>
                        <th width="15%">Price/Month (USD)</th>
                        <th width="10%">Quantity</th>
                        <th width="20%">Payment frequency</th>
                        <th width="15%">Amount (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                                        <td>{invoiceDetails?.description}</td>
                                        <td className="text-end">${invoiceDetails?.pricePerMonth}</td>
                                        <td className="text-start">{invoiceDetails?.paymentFrequencyDescription}</td>
                                        <td className="text-end">${invoiceDetails?.listPrice}</td>
                                    </tr> */}
                    {!invoiceDetails?.isAddOnInv &&
                        <tr>
                            {/* <td>{invoiceDetails?.description}</td> */}
                            <td className="text-end">1</td>
                            <td className="text-start">{invoiceDetails?.description}</td>
                            <td className="text-start">{invoiceDetails?.featureType}</td>
                            <td className="text-end">${invoiceDetails?.pricePerMonth}</td>
                            <td className="text-end">1</td>
                            <td className="text-end">{invoiceDetails?.paymentFrequencyDescription}</td>
                            <td className="text-end">${invoiceDetails?.listPrice}</td>
                        </tr>
                    }
                    {invoiceDetails?.addOns?.length > 0 &&
                        invoiceDetails?.addOns?.map((item, index) => {
                            return <tr key={index} className={(index + 2) % 2 === 0 ? "even" : ""}>
                                <td className="text-end">{invoiceDetails.isAddOnInv ? index + 1 : index + 2}</td>
                                <td className="text-start">{item?.addOnName}</td>
                                <td className="text-start">{item?.featureType}</td>
                                <td className="text-end">${item?.pricePerAddOn}</td>
                                <td className="text-end">{item?.addOnName === "Branded - Short URL" ? 1 : item?.addOnValue}</td>
                                <td className="text-end">{invoiceDetails?.paymentFrequencyDescription}</td>
                                <td className="text-end">${item?.addOnCost}</td>
                            </tr>
                        })

                    }
                    <tr>
                        <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan="5" rowSpan="5"></td>
                        <td className="text-start">Sub total</td>
                        <td className="text-end">${invoiceDetails?.subTotal}</td>
                    </tr>
                    <tr>
                        <td className="text-start">Discount</td>
                        <td className="text-end">${invoiceDetails?.discountAmount}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Total</td>
                        <td className="text-end">${invoiceDetails?.totalAmount}</td>
                    </tr>
                    <tr className="bgWhite even">
                        <td className="text-start"> Tax ({0}%) </td>
                        <td className="text-end">${invoiceDetails?.taxAmount}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Amount payable</td>
                        <td className="text-end blue">${invoiceDetails?.salePrice}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default SubscriptionInvoiceById;