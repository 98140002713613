import authContext from "Helper/StateHandler/auth-context";
import React, { Component } from "react";
import ContentLoader from 'react-content-loader'
import * as constants from "./constants";

const GridView = () => (
  <ContentLoader
  className={`mb${constants.themeSizeSm}`}
    style={{ width: '100%' }}
    viewBox={`0 0 100% ${constants.sheightMd}`}
    backgroundColor={constants.scolor1}
    foregroundColor={constants.scolor2}
    height={constants.sheightMd}
  >
    {/* <circle x="0" y="0" cx="30" cy="30" r="20" width="20" height="20" /> */}
    {/* Only SVG shapes */}
    {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
    <rect x="0" y="0" rx={constants.themeRadiusmd} ry={constants.themeRadiusmd} width="100%" height={constants.sheightMd} />
  </ContentLoader>
)

class SkeletonCampaignList extends Component {

  render() {
    return (
      <div className="no-data-container">
        {
          this.props.isError
            ? <>
              {
                this.props.text?.length
                  ? <div className="nodata-bar">
                    <p className="d-flex align-items-center">Click <i className="icon-sd-circle-plus-fill-edge-medium icon-md blue mx5 position-relative top-4"></i>{this.props.text}</p>
                  </div>
                  : <div className="nodata-bar"><i className="icon-sd-alert-medium icons-md orange-medium" />
                    <p>{"No data available"}</p>
                  </div>
              }
            </>
            : null
        }
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView />
        {/* <br /> */}
        {/* <GridViewPage /> */}
      </div>
    )
  }
}

export default SkeletonCampaignList;


{/* <rect x="0" y="17" rx="2" ry="2" width="29%" height="70" />
    <rect x="30%" y="17" rx="3" ry="3" width="19%" height="70" />
    <rect x="50%" y="17" rx="3" ry="3" width="24%" height="70" />
    <rect x="75%" y="17" rx="3" ry="3" width="9%" height="70" />
    <rect x="85%" y="17" rx="3" ry="3" width="15%" height="70" /> */}