import React, { useState } from 'react';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { Modal } from 'react-bootstrap';
import { RTabbar } from 'Components/RChart/RTabbar';
import { Col, Row } from 'react-bootstrap';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox } from 'Components/RSInputs';
import { RSPTooltip } from "Components/Tooltip";
import { isEmpty, validateEmail } from 'Helper/Utils/Utils';
import { ACCEPT_TERMS, ENTER_CITY, GENDER, MAIL_ID, MOBILE_NUMBER, NAME } from 'Helper/Constants/validationMessage';

const tabData = [
  { text: 'Preview', id: "1001" },
  { text: 'Generate', id: "1002" },
  { text: 'Embed', id: "1003" }
]


const KYCPreviewComponent = props => {
  const [isShow, setIsShow] = useState(props.isOpen);
  const [formId, setFormId] = useState(props.formId);
  const [formHTMLContent, setFormHTMLContent] = useState(props.formHTMLContent);
  const [index, setIndex] = useState(0);


  // Preview Component
  const PreviewComponent = props => {
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mail, setMail] = useState("");
    const [gender, setGender] = useState("");
    const [city, setCity] = useState("");
    const [isterm, setIsTerm] = useState(false);
    const [errNameMsg, setErrNameMsg] = useState(null);
    const [errMobileNumMsg, setErrMobileNumMsg] = useState(null);
    const [errMailMsg, setErrMailMsg] = useState(null);
    const [errGenderMsg, setErrGenderMsg] = useState(null);
    const [errCityMsg, setErrCityMsg] = useState(null);
    const [errIsTermMsg, setErrIsTermMsg] = useState(null);

    const validation = () => {
      if (isEmpty(name)) {
        setErrNameMsg(NAME);
        return false;
      }
      if (isEmpty(mobileNumber)) {
        setErrMobileNumMsg(MOBILE_NUMBER);
        return false;
      }
      if (!validateEmail(mail)) {
        setErrMailMsg(MAIL_ID);
        return false;
      }
      if (isEmpty(gender)) {
        setErrGenderMsg(GENDER);
        return (false);
      }
      if (isEmpty(city)) {
        setErrCityMsg(ENTER_CITY);
        return false;
      }
      if (!isterm) {
        setErrIsTermMsg(ACCEPT_TERMS);
        return false;
      }
    }
    return (
      <div className="kycpreview-wrapper">
        <h3>Your form heading goes here</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat.
        </p>
        <Row className="p15">
          <Col md={12} className="text-center">
            <ul className="kyc-social-connect">
              <li className=''>
                <span className="bgFacebook">
                  <i className='icon-facebook-medium margin-R5' />
                  Connect with Facebook
                </span>
              </li>
              <li className=''>
                <span className='bgTwitter'>
                  <i className='icon-twitter-medium margin-R5' />
                  Connect with Twitter
                </span>
              </li>
              <li className=''>
                <span className="bgLinkedin">
                  <i className='icon-linkedin-medium margin-R5' />
                  Connect with Linkedin
                </span>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="p15">
          <Col md={{ span: 10, offset: 1 }} className="marginT10">
            <div className="choose-or-hr bluebg">
              <span >or</span>
            </div>
          </Col>
        </Row>

        <Row className="custom-column">
          <Col md={4}>
            <label className='label-row'>Name</label>
          </Col>
          <Col md={5}>
            <RSInput2
              ph="Enter name"
              val={name}
              errorMessage={errNameMsg}
              cb={(text) => {
                let errMsg = text !== "" ? null : NAME;
                setErrNameMsg(errMsg);
                setName(text);
              }} />
          </Col>
        </Row>

        <Row className="custom-column">
          <Col md={4}>
            <label className="label-row">Mobile</label>
          </Col>
          <Col md={5}>
            <RSInput2
              ph="Mobile number"
              val={mobileNumber}
              errorMessage={errMobileNumMsg}
              cb={(text) => {
                let errMsg = text !== "" ? null : MOBILE_NUMBER;
                setErrMobileNumMsg(errMsg);
                setMobileNumber(text);
              }} />
          </Col>
        </Row>

        <Row className="custom-column">
          <Col md={4}>
            <label className="label-row">Email</label>
          </Col>
          <Col md={5}>
            <RSInput2
              ph="Enter mail ID"
              val={mail}
              errorMessage={errMailMsg}
              cb={(text) => {
                let errMsg = validateEmail(text) ? null : MAIL_ID;
                setErrMailMsg(errMsg);
                setMail(text);
              }} />
          </Col>
        </Row>

        <Row className="custom-column">
          <Col md={4}>
            <label>Gender</label>
          </Col>
          <Col md={5}>
            <div className="position-relative">
              {errGenderMsg && (
                <div className="validation-message">
                  {errGenderMsg}
                </div>)}
              <Row>
                <Col md={6}>
                  <RSRadio
                    lbl="Male"
                    name={"gender"}
                    labelId={"genderId"}
                    className="marginB20"
                    checked={gender === "Male" ? true : false}
                    cb={(event) => {
                      setErrGenderMsg(null);
                      setGender(event.target.value);
                    }} />
                </Col>
                <Col md={6}>
                  <RSRadio
                    lbl="Female"
                    name={"gender1"}
                    labelId={"genderId2"}
                    className="marginB20"
                    checked={gender === "Female" ? true : false}
                    cb={(event) => {
                      setErrGenderMsg(null);
                      setGender(event.target.value);
                    }} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="custom-column">
          <Col md={4}>
            <label className="label-row">City</label>
          </Col>
          <Col md={5}>
            <div className="position-relative">
              {errCityMsg && (
                <div className="validation-message">
                  {errCityMsg}
                </div>)}
              <RSDropdownList
                data={["San Francisco", "New York", "Los Angeles"]}
                value={city !== "" ? city : "-- Select city --"}
                onChange={(event) => {
                  let errMsg = event.target.value !== "" ? null : ENTER_CITY;
                  setErrCityMsg(errMsg);
                  setCity(event.target.value);
                }} />
            </div>
          </Col>
        </Row>

        <Row className="custom-column">
          <div className="position-relative">
            {errIsTermMsg && (
              <div className="validation-message">
                {errIsTermMsg}
              </div>)}
            <Col md={{ span: 5, offset: 3 }}>
              <RSCheckbox
                className="marginB0"
                lbl="I agree to the Terms & Conditions"
                checked={isterm}
                cb={(status) => {
                  let errMsg = status ? null : ACCEPT_TERMS;
                  setErrIsTermMsg(errMsg);
                  setIsTerm(status);
                }}
              />
            </Col>
          </div>
        </Row>

        <Row>
          <Col md={{ span: 5, offset: 3 }}>
            <div className="btn-container d-flex justify-content align-center marginT20">
              <RSSecondaryBtn
                paddingR="0px"
                onClick={() => {
                  props.onChangeIsOpen(false);
                }}
                className='ml0 pl0 mr25'
              >
                Cancel
              </RSSecondaryBtn>

              <RSPrimaryBtn
                onClick={() => {
                  let enable = validation();
                }}
              >
                Submit
              </RSPrimaryBtn>
            </div>
          </Col>
        </Row>
      </div>
    )

  }

  const GenerateComponent = (props) => {
    return (
      <>
        <div className="generate-wrapper">
          <div className="nav-right box-sm bgGrey-lighter marginT15">
            <ul className="right-nav">
              <li>
                <RSPTooltip text="Copy" position="top">
                  <i
                    className={` icon-copy-large icons-md white large-icon height42 cp bgBlue`}
                    onClick={() => {
                      props.buttonClicked(1);
                    }}
                  />
                </RSPTooltip>
              </li>
              <li>
                <RSPTooltip text="Download" position="top">
                  <i
                    className={`icon-sd-download-medium icons-md white large-icon height42 cp bgBlue`}
                    onClick={() => {
                      props.buttonClicked(2);
                    }}
                  />
                </RSPTooltip>
              </li>
            </ul>
          </div>

          <div style={{
            backgroundColor: "#5ba52a",
            color: "#fff",
            border: "none",
            position: "relative",
            borderRadius: "0px",
            padding: "10px 10px 13px 37px",
            marginBottom: "20px",
            fontSize: "14px",
          }}>
            <span>
              <i className="icon-sd-circle-tick-mini " />
              Code copied <strong>successfully</strong> to the clipboard
            </span>
            <i className="icon-sd-close-mini icon-sm white float-end"></i>
          </div>

          <div>
            <textarea
              color="#95918d"
              rows={10}
              cols={45}
              spellCheck={true}
              value={
                "<form method='Post' action='http://www.resulticks.com/subscribe.aspx?rid=3314&rurl=www.resulticks.com'><div align='center'><p>First Name<br><input type='text' name='first_name' size='20' /></p></div><div align='center'><p>Last Name<br><input type='text' name='last_name' size='20' /></p></div><div align='center'><p>Email Address<br><input type='text' name='email_address' size='20' /></p></div><div align='center'><p>Mobile Number<br><input type='text' name='mobile_number' size='20' /></p></div></form>"
              }
            />
          </div>

        </div>
      </>
    );
  };

  const EmbedComponent = (props) => {
    return (
      <>
        <div className="embed-wrapper">
          <div className="nav-right box-sm bgGrey-lighter marginT15">
            <ul className="right-nav">
              <li>
                <RSPTooltip text="Copy" position="top">
                  <i
                    className={` icon-copy-large icons-md white large-icon height42 cp bgBlue`}
                    onClick={() => {
                      props.buttonClicked(1);
                    }}
                  />
                </RSPTooltip>
              </li>
              <li>
                <RSPTooltip text="Download" position="top">
                  <i
                    className={`icon-sd-download-medium icons-md white large-icon height42 cp bgBlue`}
                    onClick={() => {
                      props.buttonClicked(2);
                    }}
                  />
                </RSPTooltip>
              </li>
            </ul>
          </div>

          <div style={{
            backgroundColor: "#5ba52a",
            color: "#fff",
            border: "none",
            position: "relative",
            borderRadius: "0px",
            padding: "10px 10px 13px 37px",
            marginBottom: "20px",
            fontSize: "14px",
          }}>
            <span>
              <i className="icon-sd-circle-tick-mini " />
              Code copied <strong>successfully</strong> to the clipboard
            </span>
            <i className="icon-sd-close-mini icon-sm white float-end"></i>
          </div>

          <div>
            <textarea
              color="#95918d"
              className="ta-height-2"
              rows={15}
              cols={45}
              spellCheck={true}
              // value={'<!doctype html><html><head><meta charset="UTF-8"><title>Subscription form</title></head><body>http://www.resulticks.com/subscribe.aspx?rid=3314&rurl=www.resulticks.com</body></html>'}
              // value={
              //     "<form method='Post' action='http://www.resulticks.com/subscribe.aspx?rid=3314&rurl=www.resulticks.com'><div align='center'><p>First Name<br><input type='text' name='first_name' size='20' /></p></div><div align='center'><p>Last Name<br><input type='text' name='last_name' size='20' /></p></div><div align='center'><p>Email Address<br><input type='text' name='email_address' size='20' /></p></div><div align='center'><p>Mobile Number<br><input type='text' name='mobile_number' size='20' /></p></div></form>"
              //   }
              defaultValue={`<!doctype html>
                <html>
                    <head>
                        <meta charset="UTF-8">
                        <title>Subscription form</title>
                    </head>
                    <body>
                        http://www.resulticks.com/subscribe.aspx?rid=3314&rurl=www.resulticks.com
                    </body>
                </html>`}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderComponents = props => {
    switch (index) {
      case 0: return <PreviewComponent onChangeIsOpen={() => props.onChangeIsOpen(false)} />
      case 1: return <GenerateComponent />
      case 2: return <EmbedComponent />
    }
  }
  return (
    <Modal backdrop="static" className='modal-top-fixed'
      keyboard={false} show={isShow} centered size='xl' onHide={() => {
        setIsShow(!isShow);
        props.onChangeIsOpen(false);
      }}>
      <Modal.Header closeButton>
        <h2>KYC preview</h2>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-filled-component-del">
          {/* <RTabbar
            defaultSelectedItem={index}
            defaultClass="tabDefault"
            dynamicTab="mini tabs-filled-component marginB0"
            activeClass="tabDefault active"
            tabData={tabData}
            callBack={(item, index) => { setIndex(index) }}
          /> */}
        </div>
        <div className="clearfix"></div>
        {/* {<RenderComponents onChangeIsOpen={() => {
          setIsShow(!isShow)
          props.onChangeIsOpen(false)
        }} />} */}
        {formHTMLContent !== "" &&
          <iframe
            id="edmFrame"
            title="uploadFrame"
            srcDoc={formHTMLContent + "<style>body{pointer-events: none;}</style>"}
            style={{ width: "100%", minHeight: "100vh" }}
          ></iframe>
        }
      </Modal.Body>
      {/* <Modal.Footer>
        <RSSecondaryBtn
          onClick={() => {
            setIsShow(!isShow);
            props.onChangeIsOpen(false);
          }}
        >
          Cancel
        </RSSecondaryBtn>
        <RSPrimaryBtn
          onClick={() => {
          }}
        >
          {index === 0 ? "Generate" : "Preview"}
        </RSPrimaryBtn>
        <RSPrimaryBtn
          onClick={() => {
          }}
        >
          {index === 2 ? "Generate" : "Embed"}
        </RSPrimaryBtn>
      </Modal.Footer> */}
    </Modal>

  )
}
export default KYCPreviewComponent;