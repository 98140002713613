import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap"
import CopyToClipboard from 'react-copy-to-clipboard';
import * as colorsData from './ColorsData';

export const Colors = (props) => {
    return (
        <>
            <Row>
                {
                    jsonData.map((item, index) => {
                        return (
                            <>
                                <ColorsComponent2 data={item.key} title={item.value} />
                            </>
                        )
                    })
                }
            </Row>
        </>
    )
};

const jsonData = [
    {
        key: colorsData.commonColors,
        value: 'Common colors'
    },
    {
        key: colorsData.AudienceListColors,
        value: 'Communication status color'
    },
    {
        key: colorsData.communicationStatusColors,
        value: 'Audience list'
    },
    {
        key: colorsData.ChannelColors,
        value: 'Channel colors'
    },
    {
        key: colorsData.ChannelColors2,
        value: 'Channel colors'
    },
    {
        key: colorsData.SourceColors,
        value: 'Source colors'
    },
    {
        key: colorsData.sentimentColors,
        value: 'Sentiment colors'
    },
    {
        key: colorsData.weekColors,
        value: 'Week colors'
    },
    {
        key: colorsData.dayColors,
        value: 'Day colors'
    },
    {
        key: colorsData.deviceColors,
        value: 'Device colors'
    },
    {
        key: colorsData.knwonColors,
        value: 'Knwon colors'
    },
    {
        key: colorsData.languageColors,
        value: 'Language colors'
    },
    {
        key: colorsData.othersColors,
        value: 'Other colors'
    },
    {
        key: colorsData.chartCommonColors,
        value: 'Chart Common colors'
    }
]

const ColorsComponent = (props) => {
    const datas = props.data
    const portletWrapper = {
        borderWidth: 1,
        borderColor: '#e6e6e6',
        backgroundColor: '#ffffff',
        padding: 20,
        borderStyle: 'solid',
        marginBottom: 30,
        borderRadius: 8
    }
    return (
        <Col md={12}>
            <div style={portletWrapper}>
                <h3 className='font-bold' style={{ marginBottom: 20 }}>{props.title} ({datas.length})</h3>
                <table>
                    <tbody>
                        {
                            datas.map((item, index) => {
                                const { color, name, type, js, scss } = item
                                return (
                                    <>
                                        <ColorComponent
                                            key={index}
                                            color={color}
                                            name={name}
                                            type={type}
                                            js={js}
                                            scss={scss}
                                        />
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Col>
    )
}
const ColorsComponent2 = (props) => {
    const datas = props.data
    const portletWrapper = {
        borderWidth: 0,
        // borderColor: '#e6e6e6',
        // backgroundColor: '#ffffff',
        // padding: 20,
        borderStyle: 'solid',
        marginBottom: 30,
        borderRadius: 8
    }
    return (
        <Col md={12}>
            <div style={portletWrapper}>
                <h3 className='font-bold' style={{ marginBottom: 20 }}>{props.title} ({datas.length})</h3>
                <Row className='color-list'>
                        {
                            datas.map((item, index) => {
                                const { color, name, type, js, scss } = item
                                return (
                                    <>
                                        <ColorComponent2
                                            key={index}
                                            color={color}
                                            name={name}
                                            type={type}
                                            js={js}
                                            scss={scss}
                                        />
                                    </>
                                )
                            })
                        }
                </Row>
            </div>
        </Col>
    )
}

const ColorComponent = (props) => {
    const colorSpan = {
        backgroundColor: props.color,
        width: 30,
        height: 30,
        borderRadius: 50,
        display: 'inline-block',
        marginRight: 10
    }
    const list = {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10
    }
    const { name, color, type, js, scss } = props
    return (
        <tr>
            <td style={list}>
                <span className='color-dotted' style={colorSpan} /><span>{name}
                    <br />{type ? <small><span style={{ color: '#777' }}>{type}</span></small> : null}
                </span>
            </td>
            <td><CopyToClipboard text={`${color}`}><b className='cursor-pointer'>{`${color}`}</b></CopyToClipboard></td>
            {
                js
                    ? <td style={{ paddingLeft: 15 }}>
                        <CopyToClipboard text={`pcolors.${js}`}><span className='cursor-pointer labeljs'>js</span></CopyToClipboard>
                        <CopyToClipboard text={`$${scss}`}><span className='cursor-pointer labelcss'>scss</span></CopyToClipboard>
                        {/* variable: */}
                        <CopyToClipboard text={`${js}`}>
                            <span className='cursor-pointer' style={{ color: '#777' }}>&nbsp;{js}</span>
                        </CopyToClipboard>
                    </td>
                    : null
            }

            {/* {scss ? <td style={{paddingLeft: 15}}>sass: <span style={{ color: '#777' }}>&nbsp;{scss}</span></td> : null} */}
        </tr>
    )
}
const ColorComponent2 = (props) => {
    const colorSpan = {
        backgroundColor: props.color,
        // width: '100%',
        // height: 30,
        // borderRadius: 8,
        // display: 'inline-block',
        // marginRight: 10,
        // height: 80
    }
    const list = {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10
    }
    const { name, color, type, js, scss } = props
    return (
        <Col md={3} className='view-box'>
            <div className='view-box-list'>
                <span className='color-dotted' style={colorSpan}></span>
                <span className='color-text'>
                    {name}
                    <br />
                    <CopyToClipboard text={`${color}`}><b className='cursor-pointer'>{`${color}`}</b></CopyToClipboard>
                    <br />
                    {
                        type 
                        ? <small>
                            <span style={{ color: '#777' }}>{type}</span>
                        </small>
                        : null
                    }
                </span>
                {
                    js
                        ? <div>
                            
                            <CopyToClipboard text={`$${scss}`}><span className='cursor-pointer labelcss'>scss</span></CopyToClipboard>
                            {/* variable: */}
                            <CopyToClipboard text={`${js}`}>
                                <span className='cursor-pointer' style={{ color: '#777' }}>&nbsp;{js}</span>
                            </CopyToClipboard>
                        </div>
                        : null
                }

                {/* {scss ? <td style={{paddingLeft: 15}}>sass: <span style={{ color: '#777' }}>&nbsp;{scss}</span></td> : null} */}
            </div>
        </Col>
    )
}