import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const ErrorModal = (props) => {
    const [isShow, setIsShow] = useState(props.isOpen)
    return (
        <>
            <Modal
                show={isShow}
                backdrop="static"
                keyboard={false}
                onHide={() => {
                    setIsShow(!isShow);
                    props.onChangeIsOpen(false);
                }}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <h2>Alert</h2>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className=''>
                        <i className='icon-sd-thumbs-down-fill-large icons-xxl space-top orange-medium'></i>
                        <p>Some fields are empty, please verify</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ErrorModal