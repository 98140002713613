import React, { useState } from "react"
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

export const RSPTooltip = ({ rootClose, position, text, className, children, show }) => {
    return <OverlayTrigger
        placement={position}
        delay={{ show: 0, hide: 0 }}
        show={show && true}
        // className={className}
        rootClose={rootClose || false}
        overlay={
            <Tooltip className={className ? className : ''} id="tooltip-disabled"> {text} </Tooltip>
        }>
        {children}
    </OverlayTrigger>
}

export const RSPPophover = ({ position, text, className, image, children, pophover, imgClass }) => {
    return <OverlayTrigger
        trigger="click"
        placement={position}
        rootClose={true}
        overlay={
            <Popover onClick={() => document.body.click()} className={`rsp-pophover ${text ? "phover-text" : ""} ${image ? `phover-img` : ""} ${className ? className : ''}`}>
                {text && <Popover.Body><small>{text}</small></Popover.Body>}
                {image && <Popover.Body rootClose={true}><img src={image} alt="" className={imgClass ? imgClass : ''} /></Popover.Body>}
                {pophover && <Popover.Body className="multi-column">{pophover}</Popover.Body>}
            </Popover>
        }>
        {children}
    </OverlayTrigger>
}