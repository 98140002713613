import React, { useState, useEffect } from "react";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";

export const Weekly = (props) => {
  const [weekly, setweekly] = useState(getWeekly());

  function getWeekly() {
    return props.campaignJson?.frequency.filter(
      (obj) => obj.frequency.toLowerCase() === "weekly"
    )[0];
  }

  const [error, setError] = useState(props.weeklyError);

  useEffect(() => {
    setError(props.weeklyError);
  }, [props.weeklyError]);

  let min = new Date(2000, 2, 10, 12, 30);
  let max = new Date(2010, 2, 10, 24, 15);
  let defaultValue = new Date(2010, 2, 10, 10, 0);
  const steps = {
    hour: 1,
    minute: 30,
    second: 5,
  };
  return (
    <>
      <ul className="frequency-list weeklylist">
        <li>
          {" "}
          <label>Every</label>{" "}
        </li>
        <li className={props.editClickOff ? "click-off" : ""}>
          <RSInput2
            ph="Week(s)"
            required="true"
            val={props.campaignJson.frequency[1]["dayInterval"]}
            keyboardType="number"
            validateCls="w-max-conent"
            cb={(text) => {
              let numberOfZeros = (text.match(/0/g) || []).length;
              if (numberOfZeros > 1 && text !== "100") {
                  // More than one zero entered, do nothing
                  return;
              }
              if (text === "0" && props.campaignJson.frequency[1]["dayInterval"] !== "0") {
                  props.onChange("dayinterval", "0");
                  let wy = { ...weekly };
                  wy.dayInterval = "0";
                  setweekly(wy);
              } else if (text === "00") { 
                  props.onChange("dayinterval", "00"); 
              } else if (text >= 101) {
                  props.onChange("dayinterval", "100");
              } else {
                  props.onChange("dayinterval", text);
                  let wy = { ...weekly };
                  wy.dayInterval = text;
                  setweekly(wy);
              }
          }}
            errorMessage={error.errDayInterval}
          />{" "}
        </li>
        <li>
          {" "}
          <label>week(s) @</label>{" "}
        </li>
        <li className={props.editClickOff ? "click-off" : ""}>
          <div className="position-relative">
            {error && error.errHourInterval && (
              <div className="validation-message">{error.errHourInterval}</div>
            )}
            <div className="rsptimer-picker">
              <TimePicker
                steps={steps}
                defaultValue={props.campaignJson.frequency[1]["hourInterval"]}
                className="required"
                onChange={(event) => {
                  let week = { ...weekly };
                  week.hourInterval = event.target.value;
                  setweekly(week);
                  props.onChange("hourinderval", event.target.value);
                }}
              />
            </div>
          </div>
        </li>
        <li>
          {" "}
          <label>hours</label>{" "}
        </li>
      </ul>
      <div className="position-relative">
        {error && error.errWeekDays && (
          <div className="validation-message">{error.errWeekDays}</div>
        )}
        <ul className="frequency-list freq-mlist">
          {weekDays.map((item, index) => {
            item.value = false;
            weekly.weekDays.map((witem, index) => {
              if (witem.toLowerCase() === item.label.toLowerCase()) {
                item.value = true;
              }
            });
            return (
              <li className={props.editClickOff ? "click-off" : ""}>
                {" "}
                <RSCheckbox
                  className=""
                  lbl={item.label}
                  checked={item.value}
                  cb={(status) => {
                    let wy = { ...weekly };
                    if (status === true) {
                      wy.weekDays.push(item.label);
                      setweekly(wy);
                    } else {
                      let myarr = wy.weekDays.filter(
                        (obj) => obj.toLowerCase() !== item.label.toLowerCase()
                      );
                      wy.weekDays = myarr;
                      setweekly(wy);
                    }
                    props.onChange("weekdays", wy.weekDays);
                  }}
                />{" "}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const weekDays = [
  {
    label: "Sun",
    value: false,
  },
  {
    label: "Mon",
    value: false,
  },
  {
    label: "Tue",
    value: false,
  },
  {
    label: "Wed",
    value: false,
  },
  {
    label: "Thu",
    value: false,
  },
  {
    label: "Fri",
    value: false,
  },
  {
    label: "Sat",
    value: false,
  },
];
