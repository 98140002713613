import React, { useState } from 'react';

import { Modal, Row, Col } from 'react-bootstrap';
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { RSCheckbox } from "Components/RSInputs"
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Link } from "react-router-dom";
import { filterBy } from "@progress/kendo-data-query";

const DataProviderModal = (props) => {
    const [show, setShow] = useState(props.isOpen);
    const [dataProviders, setDataProviders] = useState(props.dataProviders)
    const [masterDataProviders, setMasterDataProviders] = useState(props.dataProviders)
    const [selectedDataProviders, setSelectedDataProviders] = useState(props.selectedDataProviders)
    const [errMsg, setErrMsg] = useState(null);
    const [isCheckTerms, setIsCheckTerms] = useState(false);

    const filterChange = (event) => {
        let temp = getRemainingAudienceList();
        setDataProviders(filterBy(temp.slice(), event.filter))
    };

    const getRemainingAudienceList = () => {
        var temp = [...masterDataProviders]
        var listTemp = []
        temp.forEach(obj => {
            let temp2 = selectedDataProviders.filter(
                dataProvider => dataProvider.dataServiceProviderId === obj.dataServiceProviderId
            )
            if (temp2.length === 0) {
                listTemp.push(obj)
            }
        })
        return listTemp
    }
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                setShow(false)
                props.getSelectedDataProviders([])
                props.onChangeOpen(false)
            }}
            centered
            size='md'
        >
            <Modal.Header closeButton>
                <h2>Data services</h2>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <label className="label-row">Data provider</label>
                    </Col>
                    <Col md={6}>
                        <div className="chips-input-container">
                            <div className="chips-input-box required">
                                <div className="position-relative">
                                    {errMsg && (
                                        <div className="validation-message">
                                            {errMsg}
                                        </div>
                                    )}
                                    <MultiSelect
                                        placeholder="-- Select Data provider --"
                                        data={getRemainingAudienceList()}
                                        // data={dataProviders}
                                        textField="dataProviderName"
                                        dataItemKey="dataProviderMasterId"
                                        filterable={true}
                                        onFilterChange={filterChange}
                                        onChange={(event) => {
                                            let errMsg = event.target.value.length === 0 ? "Select Data provider" : null;
                                            setSelectedDataProviders(event.target.value)
                                            setErrMsg(errMsg)
                                        }}
                                        value={selectedDataProviders}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {selectedDataProviders.length > 0 &&
                        <>
                            {props.dataProviderContent !== "" && <p>Your Versium REACH account is enabled by default. Do you wish to proceed?</p>}
                            <div className="position-relative checkbox-alert mt15">
                                <RSCheckbox
                                    className="font-xsm marginB19"
                                    checked={isCheckTerms}
                                    cb={status => {
                                        props.getSelectedDataProviders(selectedDataProviders)
                                        setIsCheckTerms(status)
                                        props.onChangeOpen(status)
                                    }
                                    }
                                    labelClass='mt-1'
                                    lbl="I agree to the selected data provider(s) terms of use."
                                // labelClass="sub-dashed-border"
                                >
                                </RSCheckbox>
                            </div>
                        </>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end marginT30">
                    <RSSecondaryBtn onClick={() => {
                        setShow(false)
                        props.getSelectedDataProviders([])
                        props.onChangeOpen(false)
                    }} variant="outline-success">
                        Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn className={isCheckTerms ? "" : "click-off"} onClick={() => {
                        if (isCheckTerms) {
                            setShow(false)
                            props.getSelectedDataProviders(selectedDataProviders)
                            props.onChangeOpen(true)
                        }
                    }} variant="outline-success">
                        OK
                    </RSPrimaryBtn>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default DataProviderModal