import { Switch } from "@progress/kendo-react-inputs";
import React from "react";
import abIcon from 'Assets/Images/Admin/interakt-logo.png'
import { RSPTooltip } from "Components/Tooltip";

const AdminListingCell = (props) => {
  
    return (
      <td className={`${adminIndicatorStatus[props.dataItem.campaignStatus].className}`}>
        <div className="panelbar-wrapper admin-listing">
  
          <div className="cellPannelInner">
            <div className="brand-logo-admin">
              <img src={abIcon} alt="" />
              {
                props.dataItem.brandType !== "" 
                ? <i className={`help-fixed ${props.dataItem.brandType}`}>
                  {props.dataItem.brandType === "enterprice" && "E"}
                  {props.dataItem.brandType === "pro" && "P"}
                  {props.dataItem.brandType === "free" && "S"}
                </i> 
                : null
              }
              
            </div>
          </div>

          <div className="cellPannelInner">
            <small>Created on: {props.dataItem.createdOn}</small>
            <p>{props.dataItem.brandName}</p>
          </div>
  
          <div className="cellPannelInner">
            <small>{props.dataItem.createdBy}</small>
            <p>{props.dataItem.createdMail}</p>
          </div>
  
          <div className="cellPannelInner">
            <small>{props.dataItem.campaignType}</small>
            <p>{props.dataItem.campaignService}</p>
          </div>
  
          <div className="cellPannelInner">
            <Switch className="switch-lg" onLabel={"Activate"} offLabel={"Deactivate"} />
          </div>
  
          <div className="cellPannelInner">
            <ul className="camp-icon-pannel">
              <li> 
                <RSPTooltip placement="top" text="Settings">
                  <i className="icon-settings-small icons-md blue"></i>
                </RSPTooltip>
              </li>
              <li> 
                <RSPTooltip placement="top" text="Preview">
                  <i className="icon-sd-eye-medium icons-md blue"></i>
                </RSPTooltip>
              </li>
              <li> 
                <RSPTooltip placement="top" text="Costing">
                  <i className="icon-dollar-small icons-md blue"></i>
                </RSPTooltip>
              </li>
              <li> 
                <RSPTooltip placement="top" text="Resend">
                  <i className="icon-key-mini icons-sm blue"></i>
                </RSPTooltip>
              </li>
            </ul>
          </div>
        </div>
      </td>
    );
  };

  export default AdminListingCell;

  const adminIndicatorStatus = {
    1: { title: "Enterprice", className: "activate" },
    2: { title: "Pro", className: "deactivate" }
  }