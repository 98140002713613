import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import CampaignListRowCell from './Components/CampaignListRowCell';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import CampaignListDetailComponent from './Components/CampaignListDetailComponent';
import SkeletonCampaignList from 'Components/Skeleton/SkeletonCampaignList';
import authContext from 'Helper/StateHandler/auth-context'
import { SearchViewAdvanced } from 'Components/SearchView/SearchView';
import { GET_CAMPAIGN_LIST, GET_CAMPPAIGN_CHANNEL } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { RSPTooltip } from 'Components/Tooltip';
import { getAuthUserId, getUserData } from 'Helper/Utils/Utils';

const dataState = {
  skip: 0,
  take: 5,
  sort: [
    {
      field: 'orderDate',
      dir: 'desc',
    },
  ],
};

class CampaignList extends Component {

  static contextType = authContext

  state = {
    showdateRange: true,
    isShowDateRangePicker: false,
    dataResult: null,
    dataState: dataState,
    campaignList: [],
    listError: false,
    dataItem: {},
    totalCount: 0,
    fetchDataParams: {},
    isManualSearch: false
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ fetchDataParams: this.getParams(undefined) });
    this.getDataFromServerNew(this.getParams(undefined))
  }

  componentDidUpdate = () => {
    // if (this.props.context.globalStateData.getIsReloadData) {
    //   this.props.context.globalStateData.setIsReloadData(false)
    //   this.getDataFromServerNew(this.params)
    // }
  }

  getParams = (searchData) => {
    let params = {
      pagination: {
        PageSize: searchData !== undefined && searchData.PageSize !== undefined ? searchData.PageSize : 5,
        TotalCount: 0,
        PageCount: searchData !== undefined && searchData.PageCount !== undefined ? searchData.PageCount : 1
      },
      endDate: searchData !== undefined && searchData.to !== '' ? searchData.to : new Date(),
      startDate: searchData !== undefined && searchData.from !== undefined ? searchData.from : new Date(getUserData()?.activationDate),
      campaignType: searchData !== undefined && searchData.campaignType !== undefined ? searchData.campaignType : null,
      deliveryType: searchData !== undefined && searchData.deliveryType !== undefined ? searchData.deliveryType : '',
      productType: searchData !== undefined && searchData.productType !== undefined ? searchData.productType : null,
      channelType: searchData !== undefined && searchData.channelType !== undefined ? searchData.channelType : null,
      createdBy: searchData !== undefined && searchData.createdBy !== undefined ? searchData.createdBy : getAuthUserId(),
      campaignName: searchData !== undefined && searchData.campaignName !== undefined ? searchData.campaignName : '',
    }
    return params
  }

  getDataFromServerNew = (params) => {
    this.setState({ listError: false, campaignList: [], dataResult: [] })
    connectServer({
      path: GET_CAMPAIGN_LIST,
      params: params,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status && res?.data?.campaignList?.length) {
          let newTemp = [...res?.data?.campaignList]
          const tempDataState = {
            skip: 0,
            take: this.state.dataState?.take,
            sort: [
              {
                field: 'orderDate',
                dir: 'desc',
              },
            ],
          };
          let some = process(newTemp, tempDataState)
          this.setState({
            campaignList: res?.data?.campaignList,
            dataResult: Array.prototype.reverse.call(some),
            totalCount: res?.totalRows
          })
        } else {
          this.setState({ listError: true, campaignList: [], dataResult: [] })
        }
      },
      fail: err => {
        this.setState({ listError: true, campaignList: [], dataResult: [] })
      }
    })
  }

  CampaignListRowComponent = (props) => {
    return (
      <CampaignListRowCell
        {...props}
        onRefresh={() => {
          // this.setState({ fetchDataParams: this.getParams(undefined) });
          this.getDataFromServerNew(this.state.fetchDataParams)
        }}
        navigation={this.props.history}
        context={this.props.context}
        list={this.state.campaignList} />)
  }

  dataStateChange = (event) => {
    const { skip, take } = event.dataState;
    const currentPage = Math.floor(skip / take + 1)
    this.setState({
      dataResult: process(this.state.campaignList, event.dataState),
      dataState: event.dataState
    });
    let tempFetchDataParams = { ...this.state.fetchDataParams };
    tempFetchDataParams.pagination.PageSize = take;
    tempFetchDataParams.pagination.PageCount = currentPage;
    this.setState({ fetchDataParams: tempFetchDataParams })
    this.getDataFromServerNew(tempFetchDataParams)
  };

  expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    if (!isExpanded) {
      this.getChannelDetails(event.dataIndex, event.dataItem.campaignId)
    }
    this.setState({ ...this.state });
  };

  getChannelDetails = (dataIndex, campaignId) => {
    const params = { "campaignId": campaignId }
    connectServer({
      path: GET_CAMPPAIGN_CHANNEL,
      params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status) {
          let clonedData = [...this.state.campaignList]
          let tempDataIndex = Math.floor(dataIndex % this.state.dataState.take)
          let item = clonedData[tempDataIndex]
          item.info = res.data.channels
          this.setState({ data: clonedData })
        }
      },
      fail: err => { }
    })
  }

  closeDateRangePicker = (status, dates) => {
    let tempFetchDataParams = { ...this.state.fetchDataParams }
    tempFetchDataParams.startDate = new Date(dates.start);
    tempFetchDataParams.endDate = new Date(dates.end);
    tempFetchDataParams.pagination.PageSize = 5;
    tempFetchDataParams.pagination.PageCount = 1;
    this.setState({ fetchDataParams: tempFetchDataParams, isManualSearch: true, dataState: dataState });
    if (!status)
      this.getDataFromServerNew(tempFetchDataParams)

    this.setState({ isShowDateRangePicker: status })
  }

  searchBarCallBack = (status, dates) => {
  }

  HeaderView = props => {
    return <Row>
      <div className="d-flex justify-content-between marginT15 padding0">
        <div></div>
        <div className="d-flex align-items-center">
          <RSPDateRangePicker
            class="marginR15"
            isShowDateRangePicker={this.state.isShowDateRangePicker}
            show={this.state.showdateRange}
            enable={true}
            closeDateRangePicker={(status, date) => this.closeDateRangePicker(status, date)}
            cancelBtnClicked={() => {
              this.setState({ isShowDateRangePicker: false })
            }}
          />
          <SearchViewAdvanced
            cls="marginR15"
            enable={true}
            isCampaign={true}
            closeDateRangePicker={this.searchBarCallBack}
            dropdownData={['Communication name', 'Communication type', "Delivery type", 'Product type', 'My communication', 'All communication']}
            onClickOutside={() => { this.setState({ showdateRange: false }) }}
            onDataUpdate={(data) => {
              let tempFetchDataParams = { ...this.state.fetchDataParams };
              tempFetchDataParams.campaignName = (data !== undefined && data.campaignName !== undefined) ? data.campaignName : ""
              tempFetchDataParams.campaignType = (data !== undefined && data.campaignType !== undefined) ? data.campaignType : ""
              tempFetchDataParams.deliveryType = (data !== undefined && data.deliveryType !== undefined) ? data.deliveryType : ""
              tempFetchDataParams.productType = (data !== undefined && data.productType !== undefined) ? data.productType : ""
              tempFetchDataParams.createdBy = (data !== undefined && data.userId !== undefined) ? data.userId === "My communication" ? getAuthUserId() : null : tempFetchDataParams.createdBy
              tempFetchDataParams.pagination.PageSize = 5;
              tempFetchDataParams.pagination.PageCount = 1;
              this.setState({ fetchDataParams: tempFetchDataParams, isManualSearch: true, dataState: dataState });
              this.getDataFromServerNew(tempFetchDataParams);
            }}
          />
          <RSPTooltip text="Create communication" position="top">
            <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow-del"
              onClick={() => {
                this.props.history.push({ pathname: `communication/planning`, state: { mode: "add" } })
              }}
            ></i></RSPTooltip>
        </div>
      </div>
    </Row>
  }

  GridDetailsComponent = props => {
    return (
      <CampaignListDetailComponent
        {...props}
        onRefresh={() => {
          this.setState({ fetchDataParams: this.getParams(undefined) });
          this.getDataFromServerNew(this.getParams(undefined))
        }}
        full={this.state.campaignList}
        data={this.state.campaignList}
        navigation={this.props.history}
        context={this.props.context}
        onButtonClick={(value) => { }}
        deleteChannel={(campaignId, channelId) => {
          let temp = [...this.state.campaignList]
          let tempdata = temp.filter(obj => obj.campaignId === campaignId)
          let tempchannels = tempdata[0]?.channels?.filter(
            obj => obj.channelId !== channelId
          )
          temp.channels = tempchannels?.length > 0 ? tempchannels : []
          let some = process(temp, dataState)
          this.setState({ dataResult: Array.prototype.reverse.call(some) })
        }}
      />
    )
  }

  GridView = props => {
    return <Row>
      <Grid
        className="grid-pagenation-del rs-grid-listing"
        sortable={false}
        scrollable={"none"}
        groupable={false}
        pageable={{
          //type: 'input',
          pageSizes: true,
          previousNext: true,
        }}
        data={this.state.dataResult}
        {...this.state.dataState}
        total={this.state.totalCount}
        onDataStateChange={this.dataStateChange}
        detail={this.GridDetailsComponent}
        expandField="expanded"
        onExpandChange={this.expandChange}
      >
        <GridColumn
          field="customerID"
          cell={this.CampaignListRowComponent}
        />
      </Grid>
    </Row>
  }

  render() {
    return (
      <Container>
        <this.HeaderView />
        {
          this.state.dataResult?.data?.length
            ? <this.GridView context={this.props.context} />
            : <Row>
              <div className="p0 mt15">
                <SkeletonCampaignList text={this.state.isManualSearch ? "" : "to create communication"} isError={this.state.listError} />
              </div>
            </Row>
        }
      </Container>
    )
  }
}
export default withRouter(CampaignList);