export const glyphIcon = [
  "icon-active-key-mini",
  "icon-app-analytics-mini",
  "icon-approval-in-progress-mini",
  "icon-approval-pending-mini",
  "icon-approval-rejection-mini",
  "icon-arrow-left-fill-mini",
  "icon-arrow-right-fill-mini",
  "icon-asterisk-mini",
  "icon-audiencebase-mini",
  "icon-bookmark-mini",
  "icon-calendar-mini",
  "icon-cart-mini",
  "icon-channel-analytics-mini",
  "icon-channel-eshop-mini",
  "icon-channel-extendedsystems-mini",
  "icon-channel-facebook-mini",
  "icon-channel-googleplus-mini",
  "icon-channel-instagram-mini",
  "icon-channel-landingpage-mini",
  "icon-channel-linkedin-mini",
  "icon-channel-mobileapp-mini",
  "icon-channel-mobilepush-mini",
  "icon-channel-paidmedia-mini",
  "icon-channel-pinterest-mini",
  "icon-channel-qrcode-mini",
  "icon-channel-sentiment-mini",
  "icon-channel-sms-mini",
  "icon-channel-supportcall-mini",
  "icon-channel-twitter-mini",
  "icon-channel-voiceassistant-mini",
  "icon-channel-webinar-mini",
  "icon-channel-webpush-mini",
  "icon-channel-youtube-mini",
  "icon-chatbot-mini",
  "icon-circle-cross-mini",
  "icon-circle-mini",
  "icon-circle-tick-mini",
  "icon-connect-mini",
  "icon-control-target-group-mini",
  "icon-coupon-mini",
  "icon-cursor-mini",
  "icon-data-mask-mini",
  "icon-double-opt-in-mini",
  "icon-edit-pencil-mini",
  "icon-email-footer-mini",
  "icon-equal-to-attribute-mini",
  "icon-equal-to-mini",
  "icon-equalto-attribute-mini",
  "icon-expand-mini",
  "icon-filter-mini",
  "icon-gdpr-mini",
  "icon-greater-than-attribute-mini",
  "icon-greater-than-mini",
  "icon-greaterthan-attribute-mini",
  "icon-grouping-mini",
  "icon-heart-mini",
  "icon-in-between-mini",
  "icon-info-fill-mini",
  "icon-info-mini",
  "icon-justify-dropdown-mini",
  "icon-key-mini",
  "icon-lesser-than-attribute-mini",
  "icon-lesser-than-mini",
  "icon-lesserthan-attribute-mini",
  "icon-lifetime-cap-mini",
  "icon-mail-mini",
  "icon-minus-fill-mini",
  "icon-mobile-mini",
  "icon-mobile-push-notification-mini",
  "icon-mobile-sms-mini",
  "icon-not-equal-to-attribute-mini",
  "icon-not-equal-to-mini",
  "icon-notequalto-attribute-mini",
  "icon-offer-data-mini",
  "icon-offline-conversion-mini",
  "icon-pause-mini",
  "icon-play-mini",
  "icon-plus-mini",
  "icon-save-mini",
  "icon-sensitive-data-mini",
  "icon-shrink-mini",
  "icon-smtp-mini",
  "icon-social-web-notification-mini",
  "icon-square-minus-mini",
  "icon-square-plus-mini",
  "icon-star-mini",
  "icon-stop-mini",
  "icon-sub-segment-mini",
  "icon-tag-mini",
  "icon-tag-outline-mini",
  "icon-time-mini",
  "icon-unsubscribe-mini",
  "icon-user-tick-mini",
  "icon-user-tick-star-mini",
  "icon-voice-assistant-mini",
  "icon-xml-mini",
  "icon-youtube-mini",
  "icon-age-small",
  "icon-alert-fill-small",
  "icon-alert-line-small",
  "icon-align-vertical-small",
  "icon-analytics-graph-small",
  "icon-android-small",
  "icon-app-add-event-small",
  "icon-app-analytics-small",
  "icon-app-event-edit-small",
  "icon-app-event-small",
  "icon-app-screen-track-small",
  "icon-app-small",
  "icon-approval-in-progress-small",
  "icon-approval-pending-small",
  "icon-approval-rejection-small",
  "icon-archive-new-small",
  "icon-archive-small",
  "icon-arrow-down-small",
  "icon-arrow-up-small",
  "icon-asterisk-small",
  "icon-audience-overview-small",
  "icon-audiencebase-small",
  "icon-audio-import-small",
  "icon-audio-small",
  "icon-audit-log-small",
  "icon-back-small",
  "icon-backward-small",
  "icon-bar-filter-small",
  "icon-bar-small",
  "icon-bell-small",
  "icon-benchmark-scale-small",
  "icon-benchmark-small",
  "icon-bookmark-small",
  "icon-calendar-small",
  "icon-campaign-edit-small",
  "icon-campaign-fill-small",
  "icon-campaign-fill2-small",
  "icon-campaign-target-small",
  "icon-cart-small",
  "icon-channel-chatbot-small",
  "icon-channel-mobilepush-small",
  "icon-channel-qrcode-small",
  "icon-channel-sms-small",
  "icon-channel-supportcall-small",
  "icon-channel-webpush-small",
  "icon-channel-wise-small",
  "icon-chat-small",
  "icon-chatbot-small",
  "icon-chevron-down-small",
  "icon-chevron-up-small",
  "icon-chrome-small",
  "icon-circle-grid-small",
  "icon-circle-list-small",
  "icon-circle-small",
  "icon-close-fill-small",
  "icon-close-small",
  "icon-cms-small",
  "icon-comment-small",
  "icon-connection-small",
  "icon-content-target-small",
  "icon-contract-old-small",
  "icon-contract-small",
  "icon-control-target-group-small",
  "icon-coupon-small",
  "icon-crm-small",
  "icon-crown-fill-small",
  "icon-crown-small",
  "icon-css-small",
  "icon-csv-download-small",
  "icon-csv-small",
  "icon-custom-attributes-small",
  "icon-data-mask-small",
  "icon-data-wise-small",
  "icon-day-wise-small",
  "icon-delete-small",
  "icon-digital-assets-small",
  "icon-dislike-small",
  "icon-dollar-small",
  "icon-dot-small",
  "icon-double-cross-small",
  "icon-double-opt-in-small",
  "icon-download-small",
  "icon-draft-small",
  "icon-dropdown-small",
  "icon-dropmenu-small",
  "icon-duplicate-small",
  "icon-edit-form-small",
  "icon-edit-pencil-small",
  "icon-email-footer-small",
  "icon-equal-to-small",
  "icon-exclamatory-small",
  "icon-expand-old-small",
  "icon-expand-small",
  "icon-facebook-flat-small",
  "icon-facebook-small",
  "icon-field-selector-small",
  "icon-fire-small",
  "icon-firefox-small",
  "icon-folder-close",
  "icon-folder-open",
  "icon-forward-small",
  "icon-frequency-cap-small",
  "icon-gdpr-small",
  "icon-gender-small",
  "icon-gif",
  "icon-goal-flag-cir-small",
  "icon-goal-flag-small",
  "icon-google-plus-flat-small",
  "icon-greater-than-small",
  "icon-grouping-small",
  "icon-heart-small",
  "icon-horizontal-bar-small",
  "icon-icon-audience-dynamic-lists-medium-small",
  "icon-icon-audience-master-data-small",
  "icon-icon-audience-target-list-small",
  "icon-icon-campaign-create-small",
  "icon-icon-campaign-gallery-small",
  "icon-icon-campaign-listing-small",
  "icon-icon-campaign-planner-small",
  "icon-image",
  "icon-import-history-small",
  "icon-in-between-small",
  "icon-in-between-small-v2",
  "icon-in-process-small",
  "icon-info-small",
  "icon-ios-small",
  "icon-ipsettings-small",
  "icon-job-functions-small",
  "icon-justify-dropdown-small",
  "icon-key-small",
  "icon-language-small",
  "icon-lesser-than-small",
  "icon-lifetime-cap-small",
  "icon-like-small",
  "icon-link-down-small",
  "icon-link-small",
  "icon-listview-small",
  "icon-location-small",
  "icon-lock-filled-small",
  "icon-lock-small",
  "icon-mail-flat-small",
  "icon-mail-forward",
  "icon-mail-small",
  "icon-menu-small",
  "icon-minus-cir-small",
  "icon-minus-circle-small",
  "icon-minus-fill-small",
  "icon-minus-small",
  "icon-mobile-push-notification-small",
  "icon-mobile-push-preview-small",
  "icon-mobile-small",
  "icon-mobile-sms-small",
  "icon-next-small",
  "icon-no-icon",
  "icon-not-equal-to-small",
  "icon-note-small",
  "icon-notifications-small",
  "icon-offer-data-small",
  "icon-offline-conversion-small",
  "icon-paid-media-small",
  "icon-pause-small",
  "icon-personalisation-small",
  "icon-pinterest-flat-small",
  "icon-play-small",
  "icon-plus-circle-small",
  "icon-plus-fill-small",
  "icon-plus-small",
  "icon-poll",
  "icon-ppt-small",
  "icon-preview-image-small",
  "icon-previous-small",
  "icon-profile-completeness-small",
  "icon-purchase-frequency-small",
  "icon-purchase-worth-small",
  "icon-qrcode-small",
  "icon-question-mark-fill-small",
  "icon-question-mark-outline-small",
  "icon-question-mark-small",
  "icon-recent-campaign-small",
  "icon-recent-purchase-small",
  "icon-refresh-small",
  "icon-reload-small",
  "icon-reply-small",
  "icon-report-small",
  "icon-restart-small",
  "icon-retarget-list-small",
  "icon-retweet-small",
  "icon-safari-small",
  "icon-sankey-filter-small",
  "icon-save-small",
  "icon-search-circle-small",
  "icon-search-small",
  "icon-secure-message-tick-small",
  "icon-settings-small",
  "icon-share-link-small",
  "icon-share-small",
  "icon-shrink-small",
  "icon-slideshare-small",
  "icon-smart-link-small",
  "icon-smiley1-small",
  "icon-smiley2-small",
  "icon-smiley3-small",
  "icon-smiley4-small",
  "icon-smiley5-small",
  "icon-smiley6-small",
  "icon-smiley7-small",
  "icon-smiley8-small",
  "icon-smtp-small",
  "icon-snapshot-small",
  "icon-social-web-notification-small",
  "icon-spam-assassin-small",
  "icon-spam-mail-small",
  "icon-star-small",
  "icon-stop-small",
  "icon-sub-segment-small",
  "icon-suppression-list-small",
  "icon-te-arrow-down-small",
  "icon-te-arrow-left-small",
  "icon-te-arrow-right-small",
  "icon-te-arrow-up-small",
  "icon-template-small",
  "icon-text-speech-small",
  "icon-tick-circle-small",
  "icon-tick-small",
  "icon-time-small",
  "icon-transaction-small",
  "icon-twitter-flat-small",
  "icon-twitter-small",
  "icon-unarchive-small",
  "icon-unique-identifier-small",
  "icon-unlock-small",
  "icon-unsubscribe-small",
  "icon-user-analytics-small",
  "icon-user-help-small",
  "icon-user-small",
  "icon-user2-small",
  "icon-users-small",
  "icon-video",
  "icon-view-hide-small",
  "icon-view-report-small",
  "icon-view-small",
  "icon-voice-assistant-small",
  "icon-web-push-preview-small",
  "icon-windows-small",
  "icon-xml-small",
  "icon-youtube-small",
  "icon-zip-code-small",
  "icon-alert-circle-medium",
  "icon-android-medium",
  "icon-app-analytics-medium",
  "icon-app-medium",
  "icon-approval-in-progress-medium",
  "icon-approval-pending-medium",
  "icon-approval-rejection-medium",
  "icon-arrow-medium",
  "icon-assets-medium",
  "icon-attachment-medium",
  "icon-audience-analytics-360-medium",
  "icon-audience-dynamic-lists-medium",
  "icon-audience-laddering-medium",
  "icon-audience-master-data-medium",
  "icon-audience-target-list-medium",
  "icon-audiencebase-medium",
  "icon-audio-import-medium",
  "icon-audio-medium",
  "icon-bar-chart-medium",
  "icon-bar-filter-medium",
  "icon-benchmark-medium",
  "icon-benchmark-scale-medium",
  "icon-browser-medium",
  "icon-bulb-medium",
  "icon-calendar-medium",
  "icon-call-center-medium",
  "icon-call-centers-medium",
  "icon-campaign-create-medium",
  "icon-campaign-gallery-medium",
  "icon-campaign-listing-medium",
  "icon-campaign-medium",
  "icon-campaign-planner-medium",
  "icon-campaign-radar-medium",
  "icon-campaign-response-medium",
  "icon-campaign-target-medium",
  "icon-cart-medium",
  "icon-channel-action-medium",
  "icon-channel-chatbot-medium",
  "icon-channel-eshop-medium",
  "icon-channel-extendedsystems-medium",
  "icon-channel-facebook-medium",
  "icon-channel-googleplus-medium",
  "icon-channel-instagram-medium",
  "icon-channel-landingpage-medium",
  "icon-channel-linkedin-medium",
  "icon-channel-mobileapp-medium",
  "icon-channel-mobilepush-medium",
  "icon-channel-paidmedia-medium",
  "icon-channel-pinterest-medium",
  "icon-channel-qrcode-medium",
  "icon-channel-sentiment-medium",
  "icon-channel-sms-medium",
  "icon-channel-supportcall-medium",
  "icon-channel-twitter-medium",
  "icon-channel-voiceassistant-medium",
  "icon-channel-webinar-medium",
  "icon-channel-webpush-medium",
  "icon-channel-youtube-medium",
  "icon-chat-medium",
  "icon-chatbot-medium",
  "icon-chatbot-voice-medium",
  "icon-chrome-medium",
  "icon-circle",
  "icon-circle-grid-medium",
  "icon-circle-import-history-medium",
  "icon-circle-list-medium",
  "icon-circle-medium",
  "icon-close-fill-medium",
  "icon-close-medium",
  "icon-cms-medium",
  "icon-collapse-medium",
  "icon-company-medium",
  "icon-computer-medium",
  "icon-connection-medium",
  "icon-control-target-group-medium",
  "icon-coupon-medium",
  "icon-creditcard-medium",
  "icon-crm-medium",
  "icon-crm-medium1",
  "icon-css-medium",
  "icon-csv-download-medium",
  "icon-csv-medium",
  "icon-dam-medium",
  "icon-data-mart-medium",
  "icon-data-mask-medium",
  "icon-digital-assets-medium",
  "icon-dmp-medium",
  "icon-double-opt-in-medium",
  "icon-download-medium",
  "icon-draft-medium",
  "icon-dropmenu-medium",
  "icon-email-footer-medium",
  "icon-email-medium",
  "icon-email-preview-medium",
  "icon-event-analytics-medium",
  "icon-event-tracking-medium",
  "icon-exclamatory-medium",
  "icon-expand-large",
  "icon-expand-medium",
  "icon-extended-channel-medium",
  "icon-extended-channels-medium",
  "icon-extended-systems-medium",
  "icon-extended_systems-medium",
  "icon-facebook-ad-medium",
  "icon-facebook-app-medium",
  "icon-facebook-medium",
  "icon-field-selector-medium",
  "icon-filter-medium",
  "icon-firefox-medium",
  "icon-folder-close1",
  "icon-folder-open1",
  "icon-frequency-cap-medium",
  "icon-funnel-chart-medium",
  "icon-gdpr-medium",
  "icon-gender-medium",
  "icon-globe-wireframe-medium",
  "icon-google-ad-medium",
  "icon-google-medium",
  "icon-google-plus-medium",
  "icon-greater-than-medium",
  "icon-grid-align-medium",
  "icon-grid-medium",
  "icon-grouping-medium",
  "icon-horizontal-view-medium",
  "icon-icon-approval-status-medium",
  "icon-icon-campaign-blast-medium",
  "icon-icon-campaign-edit-medium",
  "icon-icon-campaign-pending-for-approval-medium",
  "icon-icon-consumption-medium",
  "icon-icon-daily-trigger-blast-status-medium",
  "icon-icon-data-sync-comparison-medium",
  "icon-icon-demo-request-medium",
  "icon-icon-draft-campaign-detail-medium",
  "icon-icon-en-decrypt-medium",
  "icon-icon-error-campaign-detail-medium",
  "icon-icon-import-file-status-medium",
  "icon-icon-manage-users-medium",
  "icon-icon-recipient-profile-medium",
  "icon-icon-segmentation-status-medium",
  "icon-icon-sms-response-comparision-medium",
  "icon-icon-solar-monitering-medium",
  "icon-icon-tenant-detail-medium",
  "icon-icon-trigger-campaign-blast-status-medium",
  "icon-import-file-medium",
  "icon-import-history-medium",
  "icon-import-url-medium",
  "icon-in-between-medium",
  "icon-in-between-medium-v2",
  "icon-inbox-preview-medium",
  "icon-industry-medium",
  "icon-instagram-medium",
  "icon-ios-medium",
  "icon-ipad-medium",
  "icon-ipsettings-medium",
  "icon-landing-page-medium",
  "icon-language-medium",
  "icon-lesser-than-medium",
  "icon-lifetime-cap-medium",
  "icon-link-down-medium",
  "icon-link-medium",
  "icon-link-verification-medium",
  "icon-linkedin-medium",
  "icon-list-bar-medium",
  "icon-lock-medium",
  "icon-logout-medium",
  "icon-logout2-medium",
  "icon-ls-in-exclude-medium",
  "icon-mail-medium",
  "icon-mail-send-medium",
  "icon-marker-medium",
  "icon-menu-medium",
  "icon-message-settings-medium",
  "icon-minus-fill-medium",
  "icon-mobile-app-medium",
  "icon-mobile-medium",
  "icon-mobile-push-notification-medium",
  "icon-mobile-push-preview-medium",
  "icon-mobile-sms-medium",
  "icon-next-medium",
  "icon-note-medium",
  "icon-notification-medium",
  "icon-odbc-medium",
  "icon-offer-data-medium",
  "icon-offline-conversion-medium",
  "icon-other-network-medium",
  "icon-others-medium",
  "icon-others-medium1",
  "icon-paid-media-medium",
  "icon-pause-medium",
  "icon-payment-failed-medium",
  "icon-paypal-medium",
  "icon-pdf-medium",
  "icon-persona-medium",
  "icon-pim-medium",
  "icon-pinterest-medium",
  "icon-plus-fill-medium",
  "icon-powered-by-medium",
  "icon-poweredby-medium",
  "icon-poweredby-sign-medium",
  "icon-ppt-medium",
  "icon-preview-medium",
  "icon-previous-medium",
  "icon-print-medium",
  "icon-profile-data-medium",
  "icon-progress-medium",
  "icon-qrcode-medium",
  "icon-recent-campaign-medium",
  "icon-recent-purchase-medium",
  "icon-request-approval-pending-medium",
  "icon-request-approval-progress-medium",
  "icon-request-approval-rejection-medium",
  "icon-request-approved-medium",
  "icon-reset-medium",
  "icon-restart-medium",
  "icon-safari-medium",
  "icon-sankey-filter-medium",
  "icon-screenshot-medium",
  "icon-search-circle-medium",
  "icon-search-medium",
  "icon-secure-message-medium",
  "icon-secure-message-medium-old",
  "icon-secure-message-tick-medium",
  "icon-sentiment-medium",
  "icon-share-link-medium",
  "icon-share-medium",
  "icon-share-tick-medium",
  "icon-sharePublish-medium",
  "icon-shrink-medium",
  "icon-slideshare-medium",
  "icon-smart-link-flag-medium",
  "icon-smart-link-medium",
  "icon-smtp-medium",
  "icon-social-follow-medium",
  "icon-social-media-medium",
  "icon-social-web-notification-medium",
  "icon-spam-assassin-medium",
  "icon-star-medium",
  "icon-static-recipentdata-medium",
  "icon-static-recipentdata-medium1",
  "icon-sub-segment-medium",
  "icon-sub-unsub-medium",
  "icon-suppression-list-medium",
  "icon-sync-medium",
  "icon-tab-medium",
  "icon-target-medium",
  "icon-temp-content-target",
  "icon-template-medium",
  "icon-text-analysis-medium",
  "icon-text-document-medium",
  "icon-text-speech-medium",
  "icon-thumbs-up-medium",
  "icon-tick-circle-medium",
  "icon-tick-medium",
  "icon-timeline-medium",
  "icon-total-conversion-value-medium",
  "icon-total-sent-medium",
  "icon-twitter-ad-medium",
  "icon-twitter-medium",
  "icon-unique-data-medium",
  "icon-unlock-medium",
  "icon-unsbscribe-medium",
  "icon-unsubscribe-medium",
  "icon-user-add-medium",
  "icon-user-benchmark-medium",
  "icon-user-tick-medium",
  "icon-user-tick-medium-new",
  "icon-users-medium",
  "icon-vertical-view-medium",
  "icon-video-analytics-medium",
  "icon-voice-assistant-medium",
  "icon-voice-assistant-medium2",
  "icon-wearable-medium",
  "icon-web-analytics-medium",
  "icon-web-push-preview-medium",
  "icon-webinar-medium",
  "icon-windows-medium",
  "icon-youtube-medium",
  "icon-zip-medium",
  "icon-app-analytics-xmedium",
  "icon-approval-in-progress-xmedium",
  "icon-approval-pending-xmedium",
  "icon-approval-rejection-xmedium",
  "icon-atm-xmedium",
  "icon-audiencebase-xmedium",
  "icon-audio-xmedium",
  "icon-auto-push-notifications",
  "icon-auto-push-notifications-xmedium",
  "icon-cart-xmedium",
  "icon-cg-tg-xmedium",
  "icon-channel-supportcall-xmedium",
  "icon-chatbot-xmedium",
  "icon-code-xmedium",
  "icon-control-target-group-xmedium",
  "icon-css-xmedium",
  "icon-data-mask-xmedium",
  "icon-data-wise-xmedium",
  "icon-dmp-xmedium",
  "icon-double-opt-in-xmedium",
  "icon-email-footer-xmedium",
  "icon-expand-xmedium",
  "icon-gdpr-xmedium",
  "icon-grouping-xmedium",
  "icon-html-xmedium",
  "icon-ipsettings-xmedium",
  "icon-ivr-xmedium",
  "icon-lifetime-cap-xmedium",
  "icon-line-xmedium",
  "icon-mail-xmedium",
  "icon-messaging-xmedium",
  "icon-mms-xmedium",
  "icon-mobile-banking-xmedium",
  "icon-mobile-push-notification-xmedium",
  "icon-mobile-sms-xmedium",
  "icon-mobile-xmedium",
  "icon-multiple-device-push-notifications",
  "icon-net-banking-xmedium",
  "icon-notification-xmedium",
  "icon-offer-data-xmedium",
  "icon-offline-conversion-xmedium",
  "icon-other-device-push-notifications",
  "icon-ott-push-notifications",
  "icon-ott-push-notifications-xmedium",
  "icon-print-mailling-list-xmedium",
  "icon-qrcode-xmedium",
  "icon-rich-media-xmedium",
  "icon-service-catalogue-xmedium",
  "icon-shrink-xmedium",
  "icon-sms-xmedium",
  "icon-smtp-xmedium",
  "icon-social-web-notification-xmedium",
  "icon-sub-segment-xmedium",
  "icon-sub-unsub-xmedium",
  "icon-total-conversion-value-xmedium",
  "icon-total-sent-xmedium",
  "icon-unsubscribe-xmedium",
  "icon-viber-xmedium",
  "icon-video-xmedium",
  "icon-voice-assistant-xmedium",
  "icon-wearable-push-notifications-xmedium",
  "icon-wearables-push-notifications .path1",
  "icon-wearables-push-notifications .path2",
  "icon-wearables-push-notifications .path3",
  "icon-wearables-push-notifications .path4",
  "icon-wearables-push-notifications .path5",
  "icon-wearables-push-notifications .path6",
  "icon-wearables-push-notifications .path7",
  "icon-wearables-push-notifications .path8",
  "icon-wearables-push-notifications .path9",
  "icon-whatsapp-xmedium",
  "icon-youtube-xmedium",
  "icon-age-large",
  "icon-ai-campaign-large",
  "icon-ai-campaign-large2",
  "icon-alarm-clock-large",
  "icon-alert-large",
  "icon-alexa-large",
  "icon-analytics-large",
  "icon-analytics-settings-large",
  "icon-android-large",
  "icon-api-attribute-large",
  "icon-app-analytics-large",
  "icon-app-performance-large",
  "icon-approval-in-progress-large",
  "icon-approval-pending-large",
  "icon-approval-rejection-large",
  "icon-attach-large",
  "icon-audiencebase-large",
  "icon-audio-import-large",
  "icon-audio-large",
  "icon-bar-filter-large",
  "icon-barcode-large",
  "icon-beacon-large",
  "icon-benchmark-large",
  "icon-benchmark-scale-large",
  "icon-blast-campaign-large",
  "icon-bookmark-large",
  "icon-brand-large",
  "icon-browser-large",
  "icon-budget-planning-large",
  "icon-calendar-large",
  "icon-call-center-large",
  "icon-call-centers",
  "icon-call-centers-large",
  "icon-campaign-large",
  "icon-campaign-target-large",
  "icon-cart-drop-large",
  "icon-cart-large",
  "icon-cart-not-purchase-large",
  "icon-cart-tick-large",
  "icon-channel-action-large",
  "icon-channel-chatbot-large",
  "icon-channel-dmp-large",
  "icon-channel-eshop-large",
  "icon-channel-extendedsystems-large",
  "icon-channel-facebook-large",
  "icon-channel-googleplus-large",
  "icon-channel-home-network-large",
  "icon-channel-instagram-large",
  "icon-channel-ivr-large",
  "icon-channel-landingpage-large",
  "icon-channel-linkedin-large",
  "icon-channel-mobileapp-large",
  "icon-channel-mobilepush-large",
  "icon-channel-paidmedia-large",
  "icon-channel-pinterest-large",
  "icon-channel-qrcode-large",
  "icon-channel-sms-large",
  "icon-channel-supportcall-large",
  "icon-channel-twitter-large",
  "icon-channel-voiceassistant-large",
  "icon-channel-webinar-large",
  "icon-channel-webpush-large",
  "icon-channel-youtube-large",
  "icon-chat-large",
  "icon-chatbot-large",
  "icon-chrome-large",
  "icon-circle-large",
  "icon-close-large",
  "icon-cms-large",
  "icon-collapse-large",
  "icon-compare-large",
  "icon-computer-large",
  "icon-connector-large",
  "icon-consumption-large",
  "icon-content-management-large",
  "icon-content-targeting-large",
  "icon-content-targeting1-large",
  "icon-control-target-group-large",
  "icon-conversion-large",
  "icon-copy-large",
  "icon-coupon-large",
  "icon-coupon-large1",
  "icon-crm-large",
  "icon-crown-large",
  "icon-custom-attributes-large",
  "icon-data-exchange-large",
  "icon-data-exchange2-large",
  "icon-data-mart-large",
  "icon-data-mask-large",
  "icon-data-wise-large",
  "icon-datahistory-large",
  "icon-date-time-large",
  "icon-delete-large",
  "icon-digital-assets-large",
  "icon-dislike-large",
  "icon-divider-large",
  "icon-dmp-large",
  "icon-double-opt-in-large",
  "icon-double-optin-large",
  "icon-download-large",
  "icon-email-clicked-large",
  "icon-email-cross-arrow-large",
  "icon-email-footer-large",
  "icon-email-large",
  "icon-email-open-large",
  "icon-email-preview-large",
  "icon-engagement-large",
  "icon-expand-large1",
  "icon-expand-large-old",
  "icon-expand-large2",
  "icon-extended-channel-large",
  "icon-extended-channels-large",
  "icon-extended-systems-large",
  "icon-facebook-large",
  "icon-firefox-large",
  "icon-frequency-cap-large",
  "icon-gdpr-large",
  "icon-gender-large",
  "icon-globe-large",
  "icon-globe-wireframe-large",
  "icon-goal-flag-large",
  "icon-google-home-large",
  "icon-google-plus-large",
  "icon-grid-large",
  "icon-grouping-large",
  "icon-help-large",
  "icon-home-network-large",
  "icon-htmlEditor-large",
  "icon-icon-survey-large",
  "icon-import-file-large",
  "icon-import-recipent-large",
  "icon-inbox-preview-large",
  "icon-instagram-large",
  "icon-invoice-large",
  "icon-ios-large",
  "icon-ivr-large",
  "icon-job-functions-large",
  "icon-key-large",
  "icon-landing-page-large",
  "icon-language-large",
  "icon-leadscore-large",
  "icon-left-caption-large",
  "icon-license-info-large",
  "icon-lifetime-cap-large",
  "icon-like-large",
  "icon-linkedin-large",
  "icon-list-attribute-large",
  "icon-list-segmentation-large",
  "icon-mail-bounced-large",
  "icon-mail-clicked-large",
  "icon-mail-closed-large",
  "icon-mail-large",
  "icon-mail-open-large",
  "icon-mail-right-arrow-large",
  "icon-mail-settings-large",
  "icon-mail-spam-large",
  "icon-mail-tick-large",
  "icon-marker-large",
  "icon-metaData-large",
  "icon-minus-circle-large",
  "icon-minus-large",
  "icon-mobile-app-large",
  "icon-mobile-large",
  "icon-mobile-push-notification-large",
  "icon-mobile-push-preview-large",
  "icon-mobile-sms-large",
  "icon-multi-banner-large",
  "icon-multi-dimension-large",
  "icon-multi-status-large",
  "icon-myprofile-large",
  "icon-next-large",
  "icon-offer-data-large",
  "icon-offline-conversion-large",
  "icon-paid-media-large",
  "icon-payment-settings-large",
  "icon-pinterest-large",
  "icon-plus-circle-large",
  "icon-plus-large",
  "icon-polygon-large",
  "icon-preview-image-large",
  "icon-preview-mobile-large",
  "icon-previous-large",
  "icon-qr-code-large",
  "icon-refresh-large",
  "icon-rejected-status-large",
  "icon-responsive-large",
  "icon-restart-large",
  "icon-resulticks-cloud-large",
  "icon-resulticks-cloud-new-large",
  "icon-resulticks-logo-large",
  "icon-right-caption-large",
  "icon-roi-large",
  "icon-roles-permission-large",
  "icon-safari-large",
  "icon-search-circle-large",
  "icon-secure-message-large",
  "icon-secure-message-tick-large",
  "icon-sentiment-analytics-large",
  "icon-sentiment-analytics-up-large",
  "icon-sentiment-analytics2-large",
  "icon-service-catalogue-large",
  "icon-settings-help-large",
  "icon-settings-large",
  "icon-share-large",
  "icon-shrink-large",
  "icon-single-banner-large",
  "icon-single-dimension-large",
  "icon-smart-link-large",
  "icon-sms-large",
  "icon-sms-not-deliverd-large",
  "icon-sms-opened-large",
  "icon-smtp-large",
  "icon-snippet-large",
  "icon-social-follow-large",
  "icon-social-media-large",
  "icon-social-share-large",
  "icon-social-web-notification-large",
  "icon-spanner-large",
  "icon-sub-segment-large",
  "icon-subscription-form-large",
  "icon-subscription-kyc-large",
  "icon-suppression-list-large",
  "icon-survey-large",
  "icon-system-integration-large",
  "icon-system-status-large",
  "icon-tab-large",
  "icon-te-2buttons-large",
  "icon-te-2column-image-text-large",
  "icon-te-2column-text-large",
  "icon-te-2images-large",
  "icon-te-3column-image-text-large",
  "icon-te-3column-text-large",
  "icon-te-3images-large",
  "icon-te-address-large",
  "icon-te-address2-large",
  "icon-te-attachment-large",
  "icon-te-body-background-color-large",
  "icon-te-border-large",
  "icon-te-button-large",
  "icon-te-check-box-large",
  "icon-te-code-large",
  "icon-te-combo-box-large",
  "icon-te-content-targeting-large",
  "icon-te-copyright-large",
  "icon-te-countdown-large",
  "icon-te-coupon-large",
  "icon-te-divider-large",
  "icon-te-footer-large",
  "icon-te-heading-large",
  "icon-te-horizontal-menu-large",
  "icon-te-image-large",
  "icon-te-left-caption-large",
  "icon-te-ordered-list-large",
  "icon-te-page-header-large",
  "icon-te-paragraph-large",
  "icon-te-personalization-large",
  "icon-te-personalization2-large",
  "icon-te-personalization3-large",
  "icon-te-qrcode-large",
  "icon-te-right-caption-large",
  "icon-te-rss-large",
  "icon-te-social-follow-large",
  "icon-te-social-share-large",
  "icon-te-spacer-large",
  "icon-te-sub-segment-large",
  "icon-te-table-large",
  "icon-te-template-background-color-large",
  "icon-te-templates-large",
  "icon-te-text-block-large",
  "icon-te-themes-large",
  "icon-te-unordered-list-large",
  "icon-te-video-large",
  "icon-te-weather-large",
  "icon-te-webinar-large",
  "icon-tell-a-friend-large",
  "icon-tell-a-friends-large",
  "icon-template-large",
  "icon-text-document-large",
  "icon-text-speech-large",
  "icon-thanks-large",
  "icon-thumbdown-large",
  "icon-tick-outline-large",
  "icon-timer-large",
  "icon-total-conversion-value-large",
  "icon-total-sent-large",
  "icon-transaction-attribute-large",
  "icon-transactional-triggers-large",
  "icon-trigger-large",
  "icon-twitter-large",
  "icon-unsubscribe-large",
  "icon-usage-large",
  "icon-user-add-large",
  "icon-user-analytics-large",
  "icon-user-large",
  "icon-user-profile-large",
  "icon-user-template-large",
  "icon-users-large",
  "icon-video-large",
  "icon-view-report-large",
  "icon-vms-large",
  "icon-voice-assistant-large",
  "icon-voice-assistant-large2",
  "icon-wearable-large",
  "icon-web-analytics-large",
  "icon-web-push-preview-large",
  "icon-webhook-large",
  "icon-webniar-large",
  "icon-windows-large",
  "icon-windows-mobile-large",
  "icon-youtube-large",
  "icon-zip-code-large",
  "icon-zip-large",
  "icon-zoom-large",
  "icon-zoom-minus-large",
  "icon-zoom-plus-large",
  "icon-zoom1-large",
  "icon-app-analytics-xlarge",
  "icon-approval-in-progress-xlarge",
  "icon-approval-pending-xlarge",
  "icon-approval-rejection-xlarge",
  "icon-audiencebase-xlarge",
  "icon-cart-xlarge",
  "icon-channel-supportcall-xlarge",
  "icon-chatbot-xlarge",
  "icon-circle-xlarge",
  "icon-communication-settings-xlarge",
  "icon-control-target-group-xlarge",
  "icon-data-mask-xlarge",
  "icon-double-opt-in-xlarge",
  "icon-email-footer-xlarge",
  "icon-email-xlarge",
  "icon-expand-xlarge",
  "icon-gdpr-xlarge",
  "icon-grouping-xlarge",
  "icon-industry-automobile-xlarge",
  "icon-industry-b2b-xlarge",
  "icon-industry-banking-agency-xlarge",
  "icon-industry-banking-enterprise-xlarge",
  "icon-industry-banking-pro-xlarge",
  "icon-industry-banking-startup-xlarge",
  "icon-industry-banking-xlarge",
  "icon-industry-consumer-electronics-xlarge",
  "icon-industry-education-xlarge",
  "icon-industry-electronics-xlarge",
  "icon-industry-healthcare-xlarge",
  "icon-industry-hospitality-xlarge",
  "icon-industry-insurance-xlarge",
  "icon-industry-mutual-funds-xlarge",
  "icon-industry-real-estate-xlarge",
  "icon-industry-retail-xlarge",
  "icon-industry-telco-xlarge",
  "icon-industry-travel-xlarge",
  "icon-ipsettings-xlarge",
  "icon-lifetime-cap-xlarge",
  "icon-mail-xlarge",
  "icon-mobile-push-notification-xlarge",
  "icon-mobile-sms-xlarge",
  "icon-mobile-xlarge",
  "icon-offer-data-xlarge",
  "icon-offline-conversion-xlarge",
  "icon-shrink-xlarge",
  "icon-smtp-xlarge",
  "icon-social-web-notification-xlarge",
  "icon-sub-segment-xlarge",
  "icon-unsubscribe-xlarge",
  "icon-voice-assistant-xlarge",
  "icon-youtube-xlarge",
  "icon-app-analytics-xxlarge",
  "icon-audiencebase-xxlarge",
  "icon-cart-xxlarge",
  "icon-channel-chatbot-xxlarge",
  "icon-channel-eshop-xxlarge",
  "icon-channel-extendedsystems-xxlarge",
  "icon-channel-facebook-xxlarge",
  "icon-channel-googleplus-xxlarge",
  "icon-channel-instagram-xxlarge",
  "icon-channel-landingpage-xxlarge",
  "icon-channel-linkedin-xxlarge",
  "icon-channel-mobileapp-xxlarge",
  "icon-channel-mobilepush-xxlarge",
  "icon-channel-paidmedia-xxlarge",
  "icon-channel-pinterest-xxlarge",
  "icon-channel-qrcode-xxlarge",
  "icon-channel-sms-xxlarge",
  "icon-channel-supportcall-xxlarge",
  "icon-channel-twitter-xxlarge",
  "icon-channel-voiceassistant-xxlarge",
  "icon-channel-webinar-xxlarge",
  "icon-channel-webpush-xxlarge",
  "icon-channel-youtube-xxlarge",
  "icon-chatbot-xxlarge",
  "icon-control-target-group-xxlarge",
  "icon-data-mask-xxlarge",
  "icon-double-opt-in-xxlarge",
  "icon-email-footer-xxlarge",
  "icon-email-xxlarge",
  "icon-expand-xxlarge",
  "icon-extended-channels-xxlarge",
  "icon-facebook-xxlarge",
  "icon-gdpr-xxlarge",
  "icon-grouping-xxlarge",
  "icon-landing-page-xxlarge",
  "icon-lifetime-cap-xxlarge",
  "icon-linkedin-xxlarge",
  "icon-mail-xxlarge",
  "icon-mobile-app-xxlarge",
  "icon-mobile-push-notification-xxlarge",
  "icon-mobile-sms-xxlarge",
  "icon-mobile-xxlarge",
  "icon-offer-data-xxlarge",
  "icon-offline-conversion-xxlarge",
  "icon-paid-media-xxlarge",
  "icon-qr-code-xxlarge",
  "icon-shrink-xxlarge",
  "icon-smtp-xxlarge",
  "icon-social-web-notification-xxlarge",
  "icon-sub-segment-xxlarge",
  "icon-twitter-xxlarge",
  "icon-unsubscribe-xxlarge",
  "icon-voice-assistant-xxlarge",
  "icon-webniar-xxlarge",
  "icon-webpush-xxlarge",
  "icon-youtube-xxlarge",
  "icon-sensitiveData-large .path1",
  "icon-sensitiveData-large .path2",
  "icon-ab-split-small .path1",
  "icon-ab-split-small .path2",
  "icon-ab-split-small .path3",
  "icon-ab-split-small .path4",
  "icon-ab-split-small .path5"
]