import React, { useEffect, useState } from "react";

import { RSPTooltip } from "Components/Tooltip";
import { ListView } from "@progress/kendo-react-listview";

import authContext from "Helper/StateHandler/auth-context";
import { useContext } from "react";
import { dateFormat, getServerConfig, getUserData } from "Helper/Utils/Utils";
import {
  COPY_TEMPLATE,
  GET_TEMPLATEBY_ID,
  GET_TEMPLATE_LIST,
  INSERT_PUBLISH,
  LANDING_BUILDER_URL,
  TEMPLATE_BUILDER_URL,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { PlainSearchView } from "Components/SearchView/SearchView";
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { KendoIconDropdown } from "Components/KendoDropdown/IconDropdown";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import Pagination from "./Pagination";
import { DROP_DATA_EMAIL, DROP_DATA_LANDING } from "./Constants";
import TemplateCreateModal from "./TemplateCreate";
import PublishModal from "./PublishModal";
import Skeleton from "./Skeleton";
import NoImage from "Assets/Images/no_media.png";
import CopyModal from "./CopyModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card } from "react-bootstrap";

const TemplateCard = ({ name, id, page, mainClass }) => {
  const context = useContext(authContext);
  const history = useHistory();
  const [templateCard, setTemplateCard] = useState([]);
  const [copyTemplateModal, setCopyTemplateModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [tempErr, setTemErr] = useState(null);
  const [edmTempId, setEdmTempId] = useState(null);
  const [isError, setIsError] = useState(false);
  const [publishData, setIsPublishData] = useState("");
  const [publishFlag, setPublishFlag] = useState(false);
  const [templateCreateFlag, setTemplateCreateFlage] = useState(false);
  const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);

  const defaultParams = {
    pageNumber: "1",
    recordLimit: "0",
    channelId: page === "email" ? 1 : 32,
    templatecategory: name,
    endDate: new Date(),
    startDate: getUserData()?.activationDate,
    templateName: "",
  };

  useEffect(() => {
    getTemplateCard(defaultParams);
  }, []);

  const getTemplateCard = (params) => {
    setIsError(false);
    setTemplateCard([]);
    connectServer({
      path: GET_TEMPLATE_LIST,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res?.status) {
          setCopyTemplateModal(false);
          if (res.data.templateGalleryList.length) {
            if (id === 3) {
              let rev = res.data.templateGalleryList.reverse();
              setTemplateCard(rev);
            } else {
              setTemplateCard(res.data.templateGalleryList);
            }
          } else {
            setIsError(true);
          }
        } else {
          setIsError(true);
        }
      },
      fail: (err) => {
        setIsError(true);
      },
    });
  };

  const handleCopy = (ele) => {
    if (ele.templateName) {
      setTemplateName(ele.templateName);
      setEdmTempId(ele.edmTemplateID);
      setCopyTemplateModal(true);
    }
  };

  const handleSaveCopy = () => {
    if (tempErr === null) {
      let params = {
        channelId: page === "email" ? 1 : 32,
        templateId: edmTempId,
        templateName: templateName,
      };
      connectServer({
        path: COPY_TEMPLATE,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setCopyTemplateModal(false);
            getTemplateCard(defaultParams);
          } else {
            setTemErr("Template name already exists");
          }
        },
        fail: (err) => { },
      });
    } else {
      setTemErr("Enter template name");
    }
  };

  const handlePreview = (ele) => {
    let params = {
      templateId: ele?.edmTemplateID,
      channelid: 32,
    };
    connectServer({
      path: GET_TEMPLATEBY_ID,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let preview = JSON.parse(res.data);
          var blob = new Blob([preview.Body], {
            type: "text/html; charset=utf-8",
          });
          const url = URL.createObjectURL(blob);
          window.open(url);
        }
      },
      fail: (err) => { },
    });
  };

  const handlePublish = (ele) => {
    let params = {
      lpid: ele?.edmTemplateID,
    };

    connectServer({
      path: INSERT_PUBLISH,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          setIsPublishData(res.data);
          setPublishFlag(true);
        }
      },
      fail: (err) => { },
    });
  };

  const handleDropDown = (e, ele) => {
    if (e.item.id === 1) {
      handleDirect(ele);
      return;
    }
    if (e.item.id === 2) {
      handleCopy(ele);
      return;
    }
    if (e.item.id === 3) {
      history.push({ pathname: `/communication/planning`, state: { mode: "add", templateId: ele.edmTemplateID } })
    }
    if (e.item.id === 4) {
      handlePreview(ele);
      return;
    }
    if (e.item.id === 5) {
      handlePublish(ele);
      return;
    }
  };

  const handleDirect = (ele) => {
    let params = {
      campaignId: ele.campaignID,
      channelid: page === "email" ? 1 : 32,
      templateId: ele.edmTemplateID,
      templateName: ele.templateName,
      SplitType: "",
      channelDetailId: 0,
    };

    let channelDetails = JSON.stringify(params);
    let baseURL = getServerConfig();

    const sessionObject = JSON.parse(localStorage.getItem("session"));
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    let fromEnvi = window.location.href.includes("app")
      ? "app"
      : window.location.href.includes("sit")
        ? "sit"
        : "run";
    window.location.href = `${baseURL}${page === "email" ? TEMPLATE_BUILDER_URL : LANDING_BUILDER_URL
      }?accessToken=${encodeURIComponent(
        accessToken
      )}&channelDetails=${encodeURIComponent(channelDetails)}&from=${fromEnvi}`;
  };

  const handleCancel = () => {
    setCopyTemplateModal(false);
    setTemErr(null);
  };

  const componentCard = (props) => {
    let data = props?.dataItem;
    return (
      <>
        <div className={`card-gallery-box template-card-status`} style={{height:"inherit"}}>
          <div className="box">
            {id !== 3 ? (
              <>
                <div className="gallery-head">
                  <div className="d-flex align-items-center justify-content-between marginB10">
                    <small>Created on: {dateFormat(data?.createdDate)} </small>
                    <KendoIconDropdown
                      className="marginR-16 dropmenu-align"
                      dir="rtl"
                      data={
                        page === "email" ? DROP_DATA_EMAIL : DROP_DATA_LANDING
                      }
                      icon=" icon-sd-menu-dot-medium icons-md blue"
                      onItemClick={(e) => {
                        handleDropDown(e, data);
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <RSPTooltip text={data?.templateName} position="top">
                      <p className="float-start text-wrap-1">
                        {data?.templateName}
                      </p>
                    </RSPTooltip>
                  </div>
                </div>
                <div className="img-bor-wrapper">
                  <div className="img-bor clearfix">
                    <div className="gallery-scrollbar position-relative">
                      <div className="img-wrap css-scrollbar">
                        <img
                          className="gallery-bg-img"
                          src={`${getServerConfig()}${data?.thumbnailPath}`}
                          alt={data?.templateName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="img-bor-wrapper"
                onClick={() => {
                  handleDirect(data);
                }}
              >
                {/* <div className="img-bor clearfix">
                  <div className="gallery-scrollbar position-relative">
                    <div className="img-wrap css-scrollbar">
                      <img
                        className="gallery-bg-img"
                        src={`${getServerConfig()}${data?.thumbnailPath}`}
                        alt={data?.templateName}
                      />
                    </div>
                  </div>
                </div> */}

                <Card className="overlay-button position-relative overlay-card">
                  <div className="overlay-card-inner">
                    <img src={`${getServerConfig()}${data?.thumbnailPath}`} alt={data?.templateName} />
                    <ul>

                    <li>{data.templateName}</li>
                    <li>
                        <RSPrimaryBtn
                          onClick={() => {
                            // templateValidation(data);
                          }}
                        >
                          Select
                        </RSPrimaryBtn>
                      </li>
                    </ul>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleSearch = (val) => {
    let tempSearch = { ...defaultParams };
    tempSearch.templateName = val;
    getTemplateCard(tempSearch);
  };

  const handleDateChange = (status, date) => {
    let tempSearch = { ...defaultParams };
    tempSearch.startDate = new Date(date?.start);
    tempSearch.endDate = new Date(date?.end);
    if (!status) {
      getTemplateCard(tempSearch);
    }
    setIsShowDateRangePicker(status);
  };

  return (
    <div>
      {id !== 3 && (
        <div className="d-flex float-end justify-content-between marginY15">
          <div className="d-flex align-items-center">
            <RSPDateRangePicker
              closeDateRangePicker={(status, date) => {
                handleDateChange(status, date);
              }}
              cancelBtnClicked={() => {
                setIsShowDateRangePicker(false);
              }}
              class="marginR15"
              enable={true}
              show={true}
              isShowDateRangePicker={isShowDateRangePicker}
            />
            <PlainSearchView
              isFromSegmentation={false}
              enable={true}
              cls="marginR15"
              onSearch={(searchValue) => {
                handleSearch(searchValue);
              }}
              placeholder={"By template name"}
            />
          </div>
          <RSPTooltip
            text="Create template"
            className={"float-end"}
            position="top"
          >
            <i
              className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow"
              onClick={() => {
                setTemplateCreateFlage(true);
              }}
            ></i>
          </RSPTooltip>
        </div>
      )}

      {id !== 3 ? (
        <>
          {templateCard?.length > 0 ? (
            <Pagination templateCard={templateCard} cards={componentCard} />
          ) : (
            <SkeletonTable count={8} isError={isError} />
          )}
        </>
      ) : (
        <>
          <div className={`template-form-container mt23`}>
            {templateCard.length > 0 ? (
              <ListView
                className={`gallery-grid-view temp-card ${mainClass}`}
                data={templateCard && templateCard?.slice(0, 4)}
                item={componentCard}
              />
            ) : (
              <Skeleton
                count={8}
                isError={isError}
                id={3}
                templateCreateFlag={templateCreateFlag}
                setTemplateCreateFlage={setTemplateCreateFlage}
              />
            )}
          </div>
        </>
      )}
      {templateCreateFlag && (
        <TemplateCreateModal
          templateCreateFlag={templateCreateFlag}
          setTemplateCreateFlage={setTemplateCreateFlage}
          page={page}
        />
      )}
      <PublishModal
        isShow={publishFlag}
        setIsShow={setPublishFlag}
        data={publishData}
      />
      {copyTemplateModal && (
        <CopyModal
          data={{
            copyTemplateModal,
            setCopyTemplateModal,
            templateName,
            setTemErr,
            tempErr,
            handleCancel,
            handleSaveCopy,
            setTemplateName,
          }}
        />
      )}
    </div>
  );
};

export default TemplateCard;
