import { getUserData } from "Helper/Utils/Utils"
import CryptoES from 'crypto-es';
const secretKey = 'dc4faffc46e2a3910244de2be3ae5b0792abae3957ef7026a7f28dd4a585fbd2'
const CrispTicketComponent = () => {
    let userData = getUserData()

    let hmac = CryptoES.HmacSHA256(userData?.emailId, secretKey)


    return (
        <iframe
            title="Ticket Center"
            src={`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/8fa3d8fa-b47f-4098-aee4-a0cae8d6e699?email=${userData?.emailId}&hmac=${hmac.toString()}`}
            referrerpolicy="origin"
            sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
            width="100%"
            height="600px"
            frameborder="0" >
        </iframe >
    )
}

export default CrispTicketComponent;