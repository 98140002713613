import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSRadio } from 'Components/RSInputs';

const SuppersionModal = props => {

    const [isShow, setIsShow] = useState(props.isOpen);
    const [listType, setListType] = useState("");
    const [listName, setListName] = useState("");
    const [attribute, setAttribute] = useState("");

    return (
        <Modal backdrop="static"
            keyboard={false} show={isShow} centered size='lg' onHide={() => {
                setIsShow(!isShow);
                props.onChangeIsOpen(false);
            }}>
            <Modal.Header closeButton>
                <h2>Apply</h2>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt5">
                    <Col md={3}>
                        <label>Source</label>
                    </Col>
                    <Col md={7}>
                        <Row>
                            <Col md={6}>
                                <RSRadio
                                    lbl="Match"
                                    name={"source"}
                                    labelId={"sourceId"}
                                    className="marginB20"
                                    checked={listType === "Match" ? true : false}
                                    cb={(event) => {
                                        setListType(event.target.value);
                                    }} />
                            </Col>
                            <Col md={6}>
                                <RSRadio
                                    lbl="Suppression"
                                    name={"source1"}
                                    labelId={"sourceId1"}
                                    className="marginB20"
                                    checked={listType === "Suppression" ? true : false}
                                    cb={(event) => {
                                        setListType(event.target.value);
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label className="label-row">List name</label>
                    </Col>
                    <Col md={7}>
                        <RSDropdownList
                            data={["Loans & Fixed deposit awareness", "Ecommerce special offer", "Young professionals", "Fixed deposit renewal", "Retirees", "Credit card holders", "International Travel Card holders"]}
                            value={listName !== "" ? listName : "-- Select --"}
                            onChange={(event) => {
                                setListName(event.target.value);
                            }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label className="label-row">Attribute</label>
                    </Col>
                    <Col md={7}>
                        <RSDropdownList
                            data={["Email", "Mobilenumber", "city", "ZipCode"]}
                            value={attribute !== "" ? attribute : "-- Select --"}
                            onChange={(event) => {
                                setAttribute(event.target.value);
                            }} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <RSSecondaryBtn
                    onClick={() => {
                        setIsShow(!isShow);
                        props.onChangeIsOpen(false);
                    }}
                >
                    Cancel
                </RSSecondaryBtn>
                <RSPrimaryBtn
                    onClick={() => {
                        setIsShow(!isShow);
                        props.onChangeIsOpen(false);
                    }}
                >
                    Submit
                </RSPrimaryBtn>
            </Modal.Footer>
        </Modal>
    )

}
export default SuppersionModal;