import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import React, { Component } from "react";
import { Modal, Row, Col, CloseButton } from "react-bootstrap";
import { process } from "@progress/kendo-data-query";

import jsonData from "./DedupeInputSettings.json";
import { RSInput2 } from "Components/RSInputs";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSPPophover } from "Components/Tooltip";

const createDataState = (dataState) => {
  return {
    result: process(jsonData.slice(0), dataState),
    dataState: dataState,
  };
};

const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};

class DedupeSettingModal extends Component {
  initialState = createDataState({
    take: 5,
    skip: 0,
  });
  state = {
    result: this.initialState.result,
    dataState: this.initialState.dataState,
    isShowModal: this.props.isShowModal,
    isShowDateRangePicker: false,
    isAttribute: false,
    isOpenInfoModal: false,
    isSuppersionModal: false,
    isDeleteModal: false,
    isShareModal: false,
    singleColumnSensitivity: "0.7",
    combineColumnSensitivity: "0.7",
  };
  dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    this.setState({
      result: updatedState.result,
      dataState: updatedState.dataState,
    });
  };

  render() {
    return (
      <>
        {/* Add */}
        <Modal
          show={this.state.isShowModal}
          onHide={() => {
            this.setState(
              {
                isShowModal: false,
              },
              () => this.props.callBack()
            );
          }}
          backdrop="static"
          keyboard={false}
          size="xl"
          centered
        >
          <Modal.Header>
            <h2>{this.props.title}</h2>
            <CloseButton
              className="icon-sd-close-mini"
              onClick={() => {
                this.setState(
                  {
                    isShowModal: false,
                  },
                  () => this.props.callBack(false)
                );
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div>
              <Grid
                data={this.state.result}
                {...this.state.dataState}
                onDataStateChange={this.dataStateChange}
                sortable={true}
                pageable={true}
                pageSize={8}
                className="grid-pagenation-del"
                scrollable={"none"}
              // pageable={{
              //   type: 'input',
              //   pageSizes: [5, 10, 15],
              //   previousNext: true,
              // }}
              >
                <GridColumn
                  field="name"
                  title="Column name"
                  filter="text"
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  field="weight"
                  title="Weight"
                  columnMenu={ColumnMenu}
                  cell={(props) => {
                    return (
                      <td style={{ overflow: "inherit" }}>
                        <RSInput2
                          cls="mb0 w-auto"
                          val={props.dataItem.weight}
                          ph="Enter weight"
                        />
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="compareAs"
                  title="Compare as"
                  columnMenu={ColumnMenu}
                  cell={(props) => {
                    return (
                      <td>
                        {!!props.dataItem.compareAs.length && (
                          <RSDropdownList
                            data={props.dataItem.compareAs}
                            className="mb0"
                            value={props.dataItem.compareAs[0]}
                            onChange={(e) => { }}
                          />
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="N-grams-length"
                  title="N-grams length"
                  columnMenu={ColumnMenu}
                  cell={(props) => {
                    return (
                      <td style={{ overflow: "inherit" }}>
                        {!!props.dataItem["N-grams-length"] && (
                          <RSInput2
                            cls="mb0 w-auto"
                            val={props.dataItem["N-grams-length"]}
                            ph="Enter N-grams length"
                          />
                        )}
                      </td>
                    );
                  }}
                />
              </Grid>
              <Row className="mt25">
                <Col md={4}>
                  <label className="label-row">
                    Single column sensitivity threshold
                  </label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    cls="mb0"
                    val={this.state.singleColumnSensitivity}
                    ph="Enter Single column sensitivity threshold"
                    cb={(text) => {
                      this.setState({ singleColumnSensitivity: text });
                    }}
                  />
                  <div className="input-outer-set">
                    <RSPPophover
                      text="Select a number between 0 and 1 as a threshold for selecting the duplicates within each column. The lower the number, the more sensitive Intellicheck is to duplicates."
                      position="top"
                    >
                      <i className="icon-sd-question-mark-mini question-unfill icon-md blue marginT-6 cursor-pointer"></i>
                    </RSPPophover>
                  </div>
                </Col>
              </Row>
              <Row className="mt10">
                <Col md={4}>
                  <label className="label-row">
                    Combined sensitivity threshold
                  </label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    cls="mb0"
                    val={this.state.combineColumnSensitivity}
                    ph="Enter combine column sensitivity threshold"
                    cb={(text) => {
                      this.setState({ combineColumnSensitivity: text });
                    }}
                  />
                  <div className="input-outer-set">
                    <RSPPophover
                      text="Select a number between 0 and 1 as a combined threshold for selecting duplicates after averaging the matching score of multiple columns. The lower the number, more sensitive Intellicheck is to duplicates."
                      position="top"
                    >
                      <i className="icon-sd-question-mark-mini question-unfill icon-md blue marginT-6 cursor-pointer"></i>
                    </RSPPophover>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-container d-flex justify-content-end">
              <RSSecondaryBtn onClick={() => this.props.callBack(false)}>
                Cancel
              </RSSecondaryBtn>
              <RSPrimaryBtn
                onClick={() => {
                  this.props.callBack(true);
                }}
              >
                Next
              </RSPrimaryBtn>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default DedupeSettingModal;
