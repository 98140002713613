import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { SearchViewAdvanced1 } from "Components/SearchView/SearchView";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { connectServer } from "Helper/Network/networkHandler";
import { CREATE_EMAIL_CAMPAIGN, LIVE_EMAIL_PREVIEW, UPDATE_EMAIL_CAMPAIGN } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { createEmailCampaign } from "../../CamapignWrapper";
import { getUserData } from "Helper/Utils/Utils";
import { SuccessModal } from "./SuccessModal";
import parse from 'html-react-parser';

const EmailPreviewModal = (props) => {

  const context = useContext(authContext)
  const { emailData } = props;
  const [isShow, setIsShow] = useState(props.isOpen);
  const [searchData, setSearchData] = useState(null)
  const [livePreviewData, setLivePreviewData] = useState(null)
  const [isEnable, setIsEnable] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true);


  const liveEmailPreview = (searchDataFromChild) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    emailData.senderEmail = props.senderEmailAddress + "@" + props.activeDomain.domainName;
    if (props.isReplyMail) {
      emailData.replyEmail = props.alternateEmailAddress + "@" + props.activeReplyDomain.domainName;
    } else {
      emailData.replyEmail = ""
    }
    emailData.targetListTargetAudience.map((item, index) => {
      tempAudienceList.push(
        item.segmentationListID
      );
      tempAudienceCount = tempAudienceCount + item.recipientCount;
    });
    let params = {
      campaignId: props.campaignId,
      campaignGuId: emailData.campaignGuid,
      senderName: emailData.senderName,
      senderemail: emailData.senderEmail,
      replyemail: emailData.replyEmail,
      livePreviewType: searchDataFromChild?.id,
      livePreviewInput: searchDataFromChild?.value,
      testcampaignemailaddress: emailData.testCampaignEmailAddress,
      targetListTargetAudience: tempAudienceList,
      subjectLine: props.previewContent.subjectLine,
      contentType: props.previewContent.contentType,
      splitType: props.previewContent.splitType,
      body: props.previewContent.body,
      footerAddress: "Greams Road, Thousand Lights",
      preHeaderMessage: props.previewContent.preHeaderMessage,
      isFooterEnabled: props.previewContent.isFooterEnabled,
      emailfooterContent: props.footerContent,
      isSplitAB: emailData.isSplitAB,
      isViewinBrowserChecked: props.previewContent.isViewinBrowserChecked,
      footerId: props.previewContent.footerId,
      unsubscribeSettingId: props.previewContent.unsubscribeSettingId,
      templateId: props.previewContent.templateId,
    }
    connectServer({
      path: LIVE_EMAIL_PREVIEW,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setLivePreviewData(res.data)
          setIsEnable(true)
          setIsSuccess(true)
        } else {
          setIsEnable(false)
          setIsSuccess(false)
        }
      },
      fail: err => {
        // 
      }
    })
  }

  return (
    <>
      <Modal
        show={isShow}
        onHide={() => {
          setIsShow(!isShow);
          props.onChangeIsOpen(false);
        }}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="modal-top-fixed"
      >
        <Modal.Header closeButton>
          <h2>Live preview</h2>
          <div className="d-flex justify-content-end live-prev-search">
            <SearchViewAdvanced1
              cls={`mr25`}
              modalTooltipCls='modal-tooltip'
              dropdownData={['Email ID', 'Mobile no']}
              onValidateSearch={(status, searchData) => {
                props.onValidateSearch(status)
                if (status) {
                  liveEmailPreview(searchData)
                }
              }}
              isSearchValidate={props.isSearchValidate}
              getSearchData={(item) => {
                setSearchData(item)
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="preview-iframe-wrapper">
            <div className="mb30">
              <iframe
                title="previewFrame"
                srcDoc={livePreviewData ? livePreviewData.body + "<style>body{pointer-events: none;}</style>" : props.previewContent.body + props.footerContent + "<style>body{pointer-events: none;}</style>"}
                style={{ width: "100%", minHeight: "500px" }}
              ></iframe>
              {/* {parse(props.footerContent)} */}

            </div>
            {!isSuccess &&
              <SuccessModal isOkButton={false} isOpen={!isSuccess} title={"Failure"} subTitle={"Invalid audience."} buttonClicked={(value) => {
                setIsSuccess(true);
              }} />
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={`${!isEnable ? ' click-off' : ''}`}>
            <RSSecondaryBtn onClick={() => {
              setIsShow(!isShow);
              props.onChangeIsOpen(false);
            }}>Cancel</RSSecondaryBtn>
            <RSPrimaryBtn onClick={() => {
              let lpeEmailAddress = getUserData()?.emailId + "|" + livePreviewData.passportID;
              let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
              props.emailData.senderEmail = props.senderEmailAddress + "@" + props.activeDomain.domainName;
              if (props.isReplyMail) {
                props.emailData.replyEmail = props.alternateEmailAddress + "@" + props.activeReplyDomain.domainName;
              } else {
                props.emailData.replyEmail = ""
              }
              let params = createEmailCampaign(
                "S",
                props.campaignId,
                props.emailData,
                databaseName,
                lpeEmailAddress,
                false
              );
              connectServer({
                path: props.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: (res) => {
                  if (res.status) {
                    props.getEdmChannelId(res.edmChannelId);
                    setIsShow(!isShow);
                    props.onChangeIsOpen(true);
                  } else {
                    props.onChangeIsOpen(false);
                  }
                },
                fail: (err) => {
                },
              });
            }}>Send to me</RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmailPreviewModal;
