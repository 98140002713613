const DROP_DATA_EMAIL = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Duplicate" },
  { id: 3, text: "Create communication" },
];

const DROP_DATA_LANDING = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Duplicate" },
  { id: 4, text: "Preview" },
  { id: 5, text: "Publish" },
  // { id: 5, text: "Settings" },
];
export { DROP_DATA_EMAIL, DROP_DATA_LANDING };
