import React, { Component } from 'react';
import { ch_chrome, ch_desktopColor, ch_firefox, ch_others } from 'Components/Charts/ChartColors';
import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { NoData, PieChartSkeleton } from 'Components/Skeleton/Skeleton';

class EmailDevicePieChart extends Component {
    state = { tabDaysIndex: 0 }
    render() {
        return (
            <div className="portlet-container portlet-md portlet-footer-label">
                <div className="portlet-header">
                    <h4>Opens by browser</h4>
                    {/* <div className="p-nav-right">
                        <RTabbar
                            defaultClass="tabDefault"
                            dynamicTab="mini marginB0"
                            activeClass="tabDefault active"
                            defaultSelectedItem={this.state.tabDaysIndex}
                            tabData={tabDataDays}
                            callBack={(item, index) => this.setState({ tabDaysIndex: index })}
                        />
                    </div> */}
                </div>
                <div className="portlet-body">
                    {
                        this.state.tabDaysIndex === 0
                            ? <div className="portlet-chart">
                                {this.props.data?.clientsInfoDTOs !== null && this.props.data?.clientsInfoDTOs?.length ? <NewCharts options={clientChart(this.props.data)} />
                                    : <><NoData /><PieChartSkeleton /></>
                                }
                            </div>
                            : <div className="portlet-chart">
                                {this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ? <NewCharts options={deviceChart(this.props.data)} />
                                    : <><NoData /><PieChartSkeleton /></>
                                }
                            </div>
                    }
                </div>
                {this.state.tabDaysIndex === 0 ?
                    <div className={`${this.props.data?.clientsInfoDTOs !== null && this.props.data?.clientsInfoDTOs?.length ? 'portlet-two-label' : ''}`}>
                        {this.props.data?.clientsInfoDTOs !== null && this.props.data?.clientsInfoDTOs?.length ?
                            <ul>
                                <li><span>{this.props?.data?.topEmailClientValue || 0}</span><small>%</small></li>
                                <li>of emails have been opened from {this.props?.data?.topEmailClient}</li>
                            </ul> : ''}
                    </div> :
                    <div className={`${this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ? 'portlet-two-label' : ''}`}>
                        {this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ?
                            <ul>
                                <li><span>{this.props?.data?.topDeviceValue || 0}</span><small>%</small></li>
                                <li>of email opens is from {this.props?.data?.topDevice}</li>
                            </ul> : ''}
                    </div>
                }
            </div>
        );
    }
}

export default EmailDevicePieChart;

const tabDataDays = [
    { "id": 1001, "text": "Browser" }
]


// Device -- pie
const deviceChart = data => {

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Desktop", color: ch_desktopColor, y: 12 },
                //     { name: "Android phone", color: ch_androidPhoneColor, y: 46 },
                //     { name: "Android tablet", color: ch_androidTabColor, y: 18 },
                //     { name: "iPhone", color: ch_iphone, y: 32 },
                //     { name: "iPad", color: ch_ipad, y: 25 }
                // ],
                colors: [ch_desktopColor],
                data: data?.devicesInfoDTOs?.map(item => {
                    return { name: item?.name, y: parseFloat(item?.value) }
                }),
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}

// Client -- pie
const clientChart = data => {
    const browserColor = {
        "Chrome": ch_chrome,
        "Firefox": ch_firefox,
        "Others": ch_others
    }
    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Gmail", color: ch_desktopColor, y: 5 },
                //     { name: "Outlook 2010", color: ch_androidPhoneColor, y: 28 },
                //     { name: "Outlook 2007", color: ch_androidTabColor, y: 31 },
                //     { name: "Yahoo", color: ch_iphone, y: 11 },
                //     { name: "Others", color: ch_ipad, y: 7 }
                // ],
                data: data?.clientsInfoDTOs?.map(item => {
                    return { name: item?.name, y: parseFloat(item?.value), color: browserColor[item?.name] }
                }),
                // colors: [ch_chrome, ch_firefox],
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}