import React, { Component } from 'react'
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { Col, Container, Row } from 'react-bootstrap';
import 'Assets/Stylesheet/scss/modules/_doc.scss'
import { RTabbar } from 'Components/RChart/RTabbar';

import { Button, ButtonProps } from './Button/Button'
import { Layout } from './BootstrapDropdown/Layout'
import { BootstrapDropdown } from './BootstrapDropdown/BootstrapDropdown'
import { Colors } from './Colors/Colors';
import { Font } from './Font/Font';
import { FontFamily } from './FontFamily/FontFamily';
import GlypIcons from './Glyphicons/Glyphicons';
import ImagesView from './Images/Images';

export class Documentation extends Component {

    state = {
        code : `function add(a, b) {\n  return a + b;\n}`,
        tabData: [
            { text: 'Colors' },
            { text: 'Font' },
            { text: 'Font family' },
            { text: 'Glyphicons' },
            { text: 'Images' },
            { text: 'BootstrapDropdown' },
            { text: 'Button' },
            { text: 'Dropdown' },
        ],
        index: 0
    }
    components = [
        <Colors index={this.state.index} />,
        <Font />,
        <FontFamily />,
        <GlypIcons />,
        <ImagesView />,
        <Layout />,
        <><Button /><ButtonProps /></>,
        <BootstrapDropdown />,
    ]
    render() {
        return (
            <LayoutPageWrapper>
                <Container fluid className="mt50">
                    <Row>
                        <Col md={2}>
                            <h2>Components</h2>
                        </Col>
                        <Col md={10}>
                            <h2>Documentations</h2>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            <RTabbar
                                defaultSelectedItem={this.state.index}
                                defaultClass="tabDefault"
                                dynamicTab="doc-tab-list"
                                activeClass="tabDefault active"
                                tabData={this.state.tabData}
                                callBack={(item, index) => { this.setState({ index }) }}
                            />
                        </Col>
                        <Col md={10}>
                            <div className="doc-content">
                                {this.components[this.state.index]}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </LayoutPageWrapper>
        )
    }
}

export default Documentation