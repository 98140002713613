import React, { Component } from 'react';
import moment from 'moment';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { CustomPopup } from './CustomPopup';
import CustomStartDateInput from "./customStartDate";
import CustomEndDateInput from "./customEndDate";
import { getUserData } from 'Helper/Utils/Utils';

const getDateWithDay = value => {
    return moment()
        .add(value, 'd') //replace 2 with number of days you want to add
        .toDate(); //convert it to a Javascript Date Object if you like
}

const getStartDate = value => {
    return new Date(value);
}
class RSPDateRangePicker extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        startDate: "All Time",
        allTimeClicked: "active",
        sevenDaysClicked: "",
        thirtyDaysClicked: "",
        customRangeClicked: "",
        endDate: "",
        customDate: false,
        customDropdown: this.props.isShowDateRangePicker,
        startEndDate: {
            // start: getDateWithDay(-150),
            start: getStartDate(getUserData()?.activationDate),
            end: getDateWithDay(0)
        },
        clicked: false
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleEvent = (event) => {
        let copied = { ...event }
        let startDate = getDateWithDay(-150)
        let endDate = getDateWithDay(0)
        if (copied.value.start !== null) {
            let t = { ...copied.value.start }
            startDate = copied.value.start
        }
        if (copied.value.end !== null) {
            let t = { ...copied.value.end }
            endDate = copied.value.end
        }

        let obj = {
            start: startDate,
            end: endDate
        }
        this.setState({ startEndDate: obj });
    };
    componentDidUpdate() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.state.customDropdown && this.props.isShowDateRangePicker === false) {
            this.closeDateViewCon();
        }
    }

    handleClickOutside = event => {
        if (this.myRef.current && !this.myRef.current.contains(event.target)) {
            if (event.target.className.includes("k-")) {
                return
            }
            this.closeDateViewCon();
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount = props => {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    ranges = {
        "allTime": [getStartDate(getUserData()?.activationDate), getDateWithDay(0)],
        "last7Days": [getDateWithDay(-6), getDateWithDay(0)],
        "last30Days": [getDateWithDay(-29), getDateWithDay(0)]
    };

    getFormattedDate = value => {
        let some = new Date(value).toDateString().slice(4, 15)
        let value1 = some.slice(0, 6)
        let value2 = some.slice(6, 11)
        let newValue = value1 + "," + value2
        return newValue
    }

    onMouseEnter = (start, end) => {
        this.setState({ startEndDate: { start, end } })
        this.props.closeDateRangePicker(true, { start, end })
        this.setState({ ...this.state.startEndDate, start: 'someothername' });
    }

    closeDateView = (status) => {
        if (this.props.closeDateRangePicker) {
            this.props.closeDateRangePicker(status, this.state.startEndDate)

        }
    }

    cancelBtnClicked = () => {
        this.props.cancelBtnClicked()
    }

    closeDateViewCon = () => {
        this.setState({
            customDropdown: false, customDate: false
        })
        if (!this.state.clicked) {
            this.setState({
                startEndDate: {
                    start: getStartDate(getUserData()?.activationDate),
                    end: getDateWithDay(0)
                }
            })
        }
    }

    popupSettings = {
        animate: false,
        anchorAlign: { horizontal: "center", vertical: "bottom", },
        popupAlign: { horizontal: "center", vertical: "top", },
        popupClass: "range-calender"
    }


    CustomRangePickerView = props => {
        return <DateRangePicker
            onChange={this.handleEvent}
            popup={CustomPopup}
            popupSettings={this.popupSettings}
            max={new Date()}
            min={getStartDate(getUserData()?.activationDate)}
            show={this.state.customDate}
            startDateInput={CustomStartDateInput}
            endDateInput={CustomEndDateInput}
            defaultValue={{
                start: new Date(this.state.startEndDate.start),
                end: new Date(this.state.startEndDate.end)
            }}
        />
    }
    render() {
        return (
            <div ref={this.myRef} className={`date-range-view-container 
            ${this.props.enable ? '' : 'click-off'}
            ${this.props.class ? this.props.class : ""} 
            ${this.state.customDropdown ? "open" : ""}`}>

                <div className="date-title" onClick={() => {
                    this.closeDateView(true)
                    this.setState({ customDropdown: !this.state.customDropdown })

                }}>
                    <i className="icon-sd-calendar-medium icons-md blue"></i>
                    <span>{`${this.getFormattedDate(this.state.startEndDate.start)} - ${this.getFormattedDate(this.state.startEndDate.end)}`}</span>
                    <i className="icon-dropdown-small marginL5"></i>
                </div>

                <div ref={this.myReff} className={`date-range-dropdown ${this.state.customDropdown ? "open" : ""} ${this.state.customDate ? "calendar-box" : ''}`}> {/*  class : my-drop */}
                    {
                        this.state.customDate && <div className="date-range-box dr-right">
                            <ul className="d-none">
                                <li>
                                    <small className="text-justify">If the default handling of the dropdown menu and toggle aren't to your liking, you can customize them, by using the more basic Component to explicitly specify the Toggle and Menu components</small>
                                </li>
                            </ul>
                        </div>
                    }

                    <div className="date-range-box dr-left">
                        <ul className="date-list-view">
                            <li className={this.state.allTimeClicked}
                                onMouseEnter={() => this.onMouseEnter(this.ranges.allTime[0], this.ranges.allTime[1])}
                                onClick={() => {
                                    this.setState({ clicked: true, allTimeClicked: "active", sevenDaysClicked: "", thirtyDaysClicked: "", customRangeClicked: "" })
                                    this.closeDateView(false)
                                }}
                                onMouseLeave={() => {
                                    if (!this.state.clicked) {
                                        this.setState({
                                            startEndDate: {
                                                start: getStartDate(getUserData()?.activationDate),
                                                end: getDateWithDay(0)
                                            }
                                        })
                                    }
                                    this.setState({ clicked: false })
                                }}
                            >All time</li>
                            <li className={this.state.sevenDaysClicked}
                                onMouseEnter={() => this.onMouseEnter(this.ranges.last7Days[0], this.ranges.last7Days[1])}
                                onClick={() => {
                                    this.setState({
                                        clicked: true, allTimeClicked: "", sevenDaysClicked: "active", thirtyDaysClicked: "", customRangeClicked: ""
                                    })
                                    this.closeDateView(false)
                                }}
                                onMouseLeave={() => {
                                    if (!this.state.clicked) {
                                        this.setState({
                                            startEndDate: {
                                                start: getStartDate(getUserData()?.activationDate),
                                                end: getDateWithDay(0)
                                            }
                                        })
                                    }
                                    this.setState({ clicked: false })
                                }}
                            >Last 7 days</li>
                            <li
                                className={this.state.thirtyDaysClicked}
                                onMouseEnter={() => this.onMouseEnter(this.ranges.last30Days[0], this.ranges.last30Days[1])}
                                onClick={() => {
                                    this.setState({ clicked: true, allTimeClicked: "", sevenDaysClicked: "", thirtyDaysClicked: "active", customRangeClicked: "" })
                                    this.closeDateView(false)
                                }}
                                onMouseLeave={() => {
                                    if (!this.state.clicked) {
                                        this.setState({
                                            startEndDate: {
                                                start: getStartDate(getUserData()?.activationDate),
                                                end: getDateWithDay(0)
                                            }
                                        })
                                    }
                                    this.setState({ clicked: false })
                                }}
                            >Last 30 days</li>
                            <li className={this.state.customRangeClicked} onClick={() => { this.setState({ customDate: true }) }}>Custom range</li>
                        </ul>
                        <this.CustomRangePickerView />
                        <div className="d-flex justify-content-end btn-small">
                            <RSSecondaryBtn onClick={() => this.cancelBtnClicked()} >Cancel</RSSecondaryBtn>
                            <RSPrimaryBtn onClick={() => {
                                this.setState({ clicked: true, allTimeClicked: "", sevenDaysClicked: "", thirtyDaysClicked: "", customRangeClicked: "active" })
                                if (this.props.closeDateRangePicker) {
                                    let obj = { ... this.state.startEndDate }
                                    obj.start = moment(obj.start).add(1, 'day')
                                    obj.end = moment(obj.end).add(1, 'day')
                                    this.props.closeDateRangePicker(false, obj)

                                }
                                //this.closeDateView(false)
                            }} >Apply</RSPrimaryBtn>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}
export default RSPDateRangePicker;
