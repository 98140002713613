import React from 'react';
import Proptypes from 'prop-types';

const VSCheckbox = (props) => {
    const { errorMessage, type, name, className, labelName, children, onChange, checked, containerClass, ...rest } = props;
    return (
        <div className={`position-relative checkboxContainer my5 test ${!!errorMessage ? 'errorContainerCheckbox' : ''} ${containerClass}`}>
            {errorMessage && <div className="validation-message">{errorMessage}</div>}
            <div className={`${type == 'checkbox' ? 'checkbox-wrapper' : 'radio-wrapper'}`}>
                <label htmlFor={name} className="d-flex align-items-center">
                    <input
                        type={type}
                        name={name}
                        checked={checked}
                        id={name}
                        className="checkmark"
                        onChange={(e) => props.onChange(e.target.checked)}
                        {...rest}
                    />
                    <span className="checkmark top3"></span>
                    {/* <span className={props.className}>{props.labelName}</span> */}
                    <span className={`${className} lbl`}>{labelName}</span>
                    {children}
                </label>
            </div>
        </div>
    );
};

VSCheckbox.defaultProps = {
    checked: false,
    errorMessage: '',
    type: 'checkbox',
    name: '',
    className: '',
    containerClass: '',
    labelName: '',
};

VSCheckbox.propTypes = {
    checked: Proptypes.bool.isRequired,
    errorMessage: Proptypes.string.isRequired,
    type: Proptypes.string,
    name: Proptypes.string,
    className: Proptypes.string,
    containerClass: Proptypes.string,
    labelName: Proptypes.string.isRequired,
};

export default VSCheckbox;
