import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import previewLogoImg from 'Assets/Images/icon-pushnotification.png'
import previewImgBanner from 'Assets/Images/preview-loan-banner.png'
import googleLogo from "Assets/Images/logos/google-logo-icon.png";

export class Demo extends Component {
    state = {

    }
    windowPreview = props => {
        return (
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div className='preview-lg-browser-view'>
                            <div className={`preview-card ${props.android ? 'preview-card-android' : ''}`}>
                                <div className='preview-header'>
                                    <div className='preview-img'><img src={previewLogoImg} /></div>
                                    <div className='preview-text'>
                                        {
                                            props.android
                                                ? <div className='preview-android-tag'>
                                                    <img src={googleLogo} />
                                                    <div className='preview-android-name'>
                                                        <span>Chrome</span>
                                                        <small>1m</small>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <h1>Get a pre-approved loan</h1>
                                        <p>Get personal loan and spend valuable time with your family.</p>
                                    </div>
                                </div>
                                {
                                    props.chrome
                                        ? <div className='preview-body'>
                                            <img src={previewImgBanner} />
                                        </div>
                                        : null
                                }
                                {
                                    props.android
                                        ? <div className='android-btn-con'>
                                            <div className='android-btn'>APPLY NOW</div>
                                            <div className='android-btn'>LEARN MORE</div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        return (
            <>

                <h5 className='text-center width100p white'>Chrome</h5>
                <this.windowPreview chrome />

                <h5 className='text-center width100p white'>Firefox</h5>
                <this.windowPreview firefox />

                <h5 className='text-center width100p white'>Android</h5>
                <this.windowPreview chrome android />
            </>
        )
    }
}
export default Demo
