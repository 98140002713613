
import React, { Component } from 'react';
import { Grid, GridColumn,GridToolbar } from '@progress/kendo-react-grid';
import { process } from "@progress/kendo-data-query";
import ColumnMenu from '../ColumnMenu';
import jsonData from './FilterList.json';
import { RSPrimaryBtn } from 'Components/RSButtons';
import { BootstrapDropdown } from "Components/BootstrapDropdown";
import {  Row, Col } from 'react-bootstrap';
import InfoModal  from './InfoModal';
const createDataState = (dataState) => {
    let tempArray = []
    tempArray = [...jsonData]
    // var val = Object.keys(localStorage).filter(item => item.includes('dlist'))
    // if (val.length > 0) {
    //   val.map(item => {
    //     let ldata = localStorage.getItem(item)
    //     tempArray.push(JSON.parse(ldata))
    //   })
  
    // }
    return {
      result: process(tempArray.reverse().slice(0), dataState),
      dataState: dataState,
    };
  };
class FilterList extends React.Component {

    initialState = createDataState({
        take: 5,
        skip: 0
      });
    
      state = {
        result: this.initialState.result,
        dataState: this.initialState.dataState,
        isOpenInfoModal: false,
        isShowDateRangePicker: false,
        titleDropdown: [
          "All",
          "Original base",
          "Look-alike"
      ]
      };
      dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        this.setState({ result: updatedState.result, dataState: updatedState.dataState });
      };
    render() { 
        return <div>
              <Row className='mt30'>
                <Col>
                <div className='d-flex justify-content-between' >
              <BootstrapDropdown  data={this.state.titleDropdown}
                            defaultItem={"All"}
                            customAlignRight={true}
                            className="fs18-btn"
                            alignRight
                            onSelect={(item) => {
                }} />
              <div className='align-items-end'><i className="icon-csv-medium icons-lg blue"
                onClick={() => { this.setState({isOpenInfoModal:!this.state.isOpenInfoModal})}}></i></div>
              </div>
              </Col>
            </Row>
            <div>
            <Grid
            data={this.state.result}
            {...this.state.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={false}
            pageSize={8}
            className="grid-pagenation-del"
            scrollable={"none"}
            messages={{
                itemsPerPage: 'data items per page'
            }}
            pageable={{
                //type: 'input',
                pageSizes: [5, 10, 15],
                previousNext: true,
            }}>
                <GridColumn field="name" filter="text" columnMenu={ColumnMenu} title="Name" />
                <GridColumn field="mobileNumber" filter="text" columnMenu={ColumnMenu} title="Mobile number" />
                <GridColumn field="creditCardLimit" className="text-end" filter="text" columnMenu={ColumnMenu} title="Credit card limit" />
                <GridColumn field="cardType" className="text-end" filter="text" columnMenu={ColumnMenu} title="Card type" />
                <GridColumn field="interests" className="text-end" filter="text" columnMenu={ColumnMenu} title="Interests" />
                <GridColumn field="city" className="text-end" filter="text" columnMenu={ColumnMenu} title="City" />
            </Grid>
            </div>
            <div className="btn-container d-flex justify-content-end">
                <RSPrimaryBtn onClick={()=>{
                }}>Create list</RSPrimaryBtn>
            </div>
            {this.state.isOpenInfoModal && 
            <InfoModal isOpen={this.state.isOpenInfoModal}
              onChangeIsOpen={(value) => {
                this.setState({ isOpenInfoModal: !this.state.isOpenInfoModal });
              }} />}
        </div>;
    }
}
 
export default FilterList;