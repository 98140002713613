import React from "react"
import { Container } from "react-bootstrap"
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper"
import DLCreation from "./Components/DLCreation/DLCreation"

const NewDynamicList = props => {
    return <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
            <DLCreation mode="add" />
        </Container>
    </LayoutPageWrapper>
}
export default NewDynamicList