import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap"
import { DropDownButton } from '@progress/kendo-react-buttons'
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

export const AdvancedSearchView = props => {

  const [_placeholder, _setPlaceholder] = useState("Campaign name")
  const [_autoCompleteSource, _setAutoCompleteSource] = useState([props.dataSource[0].autoComplete])
  const [_data, _setData] = useState([props.dataSource[0].autoComplete])
  const [_value, _setValue] = useState('')
  const [_filterArray, _setFilterArray] = useState([])
  const [_autoSearchSelectedItem, _setAutoSearchSelectedItem] = useState("")

  const onChange = (event) => {
    const value = event.target.value;
    _setData(filterData(value))
    _setAutoSearchSelectedItem(value)

    _setValue(value)
    props.onSearchInputChange(event.target.value)
  }

  const filterData = (value) => {
    const data = _autoCompleteSource;
    const filter = {
      value: value,
      operator: 'contains',
      ignoreCase: true
    };
    return value ? filterBy(data, filter) : data;
  }

  const onSelect = (event) => {

    if (event.target.value.length === 0) {
    } else {
      let tempObject = { key: _placeholder, value: event.target.value }
      let tempCopiedArray = [..._filterArray]
      tempCopiedArray.push(tempObject)
      _setFilterArray(tempCopiedArray)
    }
  }

  const clearAll = () => {
    _setFilterArray([])
  }
  const clear = (index) => {
    let _tempArray = [..._filterArray]
    _tempArray.splice(index, 1)
    _setFilterArray(_tempArray)
  }

  return (
    <>
      <Row>
        <Col>
          {
            _filterArray.length !== 0 ?
              <div className="fl">
                <ul className="search-filter-menu">
                  <li>Filter</li>
                  <li className="cp" onClick={() => clearAll()}>Clear All</li>
                </ul>
                <ul className="search-filter-list-con">
                  {_filterArray.count === 0 ? null : _filterArray.map((item, index) =>
                    <li className="search-filter-list">
                      <div className="label">{item.key}</div>
                      <span>{item.value} <i onClick={() => clear(index)}></i></span>
                    </li>)}
                </ul>
              </div>
              :
              null
          }

          <div className={`fr ${props.addonClass ? props.addonClass : ""}`}>
            <div className="filter-date fr">
              <div className={`searchContainer dflex ${props.isSearchOpen ? "open" : ""}`}>
                <div className="dropBtn">
                  <DropDownButton
                    items={props.dataSource}
                    className="icon-dropmenu-medium icon-sm kendo-icon-btn" icon="arrow-60-down"
                    onItemClick={(eventType) => {
                      _setAutoSearchSelectedItem("")
                      _setPlaceholder(eventType.item.text)
                      _setAutoCompleteSource(props.dataSource[eventType.itemIndex].autoComplete)
                      _setData(props.dataSource[eventType.itemIndex].autoComplete)
                      props.onDropdownChange(eventType.item, eventType.itemIndex)
                    }} />
                </div>
                <div className="inputCover">
                  <AutoComplete
                    data={_data}
                    value={_autoSearchSelectedItem}
                    onChange={onChange}
                    onClose={onSelect}
                    placeholder={_placeholder}
                  />
                </div>
                <i className="icon-sd-zoom-medium icon-sm adv-search-icon" onClick={() => props.onSearchClick()}></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
