import React, { Component, useContext, useState, useEffect } from "react";
import { Row, Carousel } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import {
  BootstrapDropdown,
} from "Components/BootstrapDropdown/BootstrapDropdown";
import { AdvancedSearchView } from "Components/AdvancedSearchView/AdvancedSearchView";
import { SearchViewAdvanced } from "Components/SearchView/SearchView";
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { GET_CAMPAIGN_GALLERY, DUPLICATE_BY_CAMPAIGN, GET_GALLERY_CHANNEL_FACT, MDC_CANVAS_URL, NO_MEDIA_IMAGE, GET_EDM_GALLERY } from "Helper/Constants/endPoint";
import { DateFormatWithMomentForWhatsApp, TimeFormatWithMomentForWhatsApp, dateFormat, dateFormatWithHyphen, getAuthUserId, getServerConfig, getUserData } from "Helper/Utils/Utils";
import EDMGalleryModal from "./components/EDMGalleryModal";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { KendoIconDropdown } from "Components/KendoDropdown/IconDropdown";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import imageSrc from "Assets/Images/industries/banking/gallery-email3.jpg";
import { RSPTooltip } from "Components/Tooltip";
import { WhatsAppMessage } from "Components/WhatsAppMessage";
import pdfImage from 'Assets/Images/wapdf.png';
import videoImage from 'Assets/Images/wavideo.png';


class Gallery extends Component {

  static contextType = authContext

  state = {
    isSearchOpen: false,
    searchSelectedItem: "",
    searchInputText: "",
    filterArray: [],
    dropdownList: ["Email", "SMS", "WhatsApp", "QR"],
    selectedDropdownValue: "Email",
    customDropdown: false,
    campaignsGalleryList: [],
    isInfoOpen: false,
    listError: false,
    totalCount: 0,
    isShowDateRangePicker: false,
    showdateRange: true,
    fetchDataParams: {},
    skip: 0,
    take: 4
  };

  getDateWithDay = value => {
    return Date.now() - value * 24 * 3600 * 1000;
  }

  componentDidMount() {
    let data = {
      "PageSize": 4,
      "PageCount": 1,
      "channel": "E"
    }
    this.setState({ fetchDataParams: this.getParams(data) })
    this.getCampaignGallery(this.getParams(data))
  }

  getParams = (searchData) => {
    let params = {
      "channel": searchData !== undefined && searchData.channel !== undefined ? searchData.channel : this.state.selectedDropdownValue.charAt(0),
      "endDate": searchData !== undefined && searchData.to !== undefined ? dateFormatWithHyphen(searchData.to) : "",
      "startDate": searchData !== undefined && searchData.from !== undefined ? dateFormatWithHyphen(searchData.from) : "",
      "CampaignType": searchData !== undefined && searchData.campaignType !== undefined ? searchData.campaignType : "",
      "DeliveryType": searchData !== undefined && searchData.deliveryType !== undefined ? searchData.deliveryType : "",
      "ProductType": searchData !== undefined && searchData.productType !== undefined ? searchData.productType : "",
      "createdBy": searchData !== undefined && searchData.createdBy !== undefined ? searchData.createdBy : getAuthUserId(),
      "CampaignName": searchData !== undefined && searchData.campaignName !== undefined ? searchData.campaignName : "",
      "pagination": {
        "PageSize": searchData !== undefined && searchData.PageSize !== undefined ? searchData.PageSize : 4,
        "PageCount": searchData !== undefined && searchData.PageCount !== undefined ? searchData.PageCount : 1,
      }
    }
    return params;
  }

  getCampaignGallery = (params) => {
    this.setState({ campaignsGalleryList: [], listError: false })
    connectServer({
      path: GET_CAMPAIGN_GALLERY,
      params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status && res.data?.campaignsGalleryList?.length) {
          this.setState({
            campaignsGalleryList: res.data.campaignsGalleryList,
            totalCount: res.totalRows
          })
        }
        else {
          this.setState({ listError: true })
        }
      },
      fail: err => {
        this.setState({ listError: true })
      }
    })
  }

  lowerCaseObjectKeys = (value) => {
    return value.map((k) => k.toUpperCase(), value);
  };

  AdvancedSearchComponent = (props) => {
    const searchData = [{ text: "Template name" }];
    return (
      <>
        <AdvancedSearchView
          dataSource={searchData}
          addonClass="mt10"
          onDropdownChange={(item, index) => {
            var newArray = [...this.state.filterArray];
            newArray.push(item.text);
            this.setState({
              filterArray: newArray,
            });
          }}
          onSearchInputChange={(text) =>
            this.setState({ searchInputText: text })
          }
          onSearchClick={() => { }}
          isSearchOpen={this.state.isSearchOpen}
        />
      </>
    );
  }

  closeDateRangePicker = (status, dates) => {

    if (!status) {
      let tempFetchDataParams = { ...this.state.fetchDataParams }
      tempFetchDataParams.channel = this.state.selectedDropdownValue.charAt(0);
      tempFetchDataParams.endDate = dateFormatWithHyphen(new Date(dates.end));
      tempFetchDataParams.startDate = dateFormatWithHyphen(new Date(dates.start));
      tempFetchDataParams.pagination.PageSize = 4;
      tempFetchDataParams.pagination.PageCount = 1;
      this.setState({ fetchDataParams: tempFetchDataParams, skip: 0, take: 4 })
      this.getCampaignGallery(tempFetchDataParams)
    }
    this.setState({ isShowDateRangePicker: status })
  }

  searchBarCallBack = (status, dates) => {
  }

  HeadComponent = props => {
    return <div className="d-flex justify-content-between marginY15">
      <div></div>
      <div className="d-flex align-items-center">
        <RSPDateRangePicker
          closeDateRangePicker={(status, date) => this.closeDateRangePicker(status, date)}
          cancelBtnClicked={() => {
            this.setState({ isShowDateRangePicker: false })
          }}
          class="marginR15"
          enable={true}
          show={this.state.showdateRange}
          isShowDateRangePicker={this.state.isShowDateRangePicker}
        />
        <BootstrapDropdown
          data={this.state.dropdownList}
          alignRight
          onSelect={(item) => {
            let tempFetchDataParams = { ...this.state.fetchDataParams };
            tempFetchDataParams.pagination.PageSize = 4;
            tempFetchDataParams.pagination.PageCount = 1;
            tempFetchDataParams.channel = item.charAt(0);
            this.setState({ selectedDropdownValue: item, fetchDataParams: tempFetchDataParams })
            this.getCampaignGallery(tempFetchDataParams);
          }}
          defaultItem={this.state.selectedDropdownValue}
          customAlignRight={true}
          className="marginR10 fs19-btn"
        />
        <SearchViewAdvanced
          enable={true}
          isCampaign={true}
          onDataUpdate={(data) => {
            let tempFetchDataParams = { ...this.state.fetchDataParams };
            tempFetchDataParams.CampaignName = (data !== undefined && data.campaignName !== undefined) ? data.campaignName : ""
            tempFetchDataParams.CampaignType = (data !== undefined && data.campaignType !== undefined) ? data.campaignType : ""
            tempFetchDataParams.DeliveryType = (data !== undefined && data.deliveryType !== undefined) ? data.deliveryType : ""
            tempFetchDataParams.ProductType = (data !== undefined && data.productType !== undefined) ? data.productType : ""
            tempFetchDataParams.createdBy = (data !== undefined && data.userId !== undefined) ? data.userId === "My communication" ? getAuthUserId() : null : tempFetchDataParams.createdBy
            tempFetchDataParams.pagination.PageSize = 4;
            tempFetchDataParams.pagination.PageCount = 1;
            this.setState({ fetchDataParams: tempFetchDataParams });
            this.getCampaignGallery(tempFetchDataParams)
          }}
          onClickOutside={() => { this.setState({ showdateRange: false }) }}
          closeDateRangePicker={() => this.searchBarCallBack()}
          cls="marginL10"
          dropdownData={['Communication name', 'Communication type', "Delivery type", 'Product type', 'My communication', 'All communication']}
        />
      </div>
    </div>
  }

  render() {
    return (
      <>
        <Row>
          <this.HeadComponent />
        </Row>

        {this.state.selectedDropdownValue.charAt(0) === "E"
          ? <GalleryEmail
            data={this.state.campaignsGalleryList}
            channelName="Email"
            take={this.state.take || 4}
            skip={this.state.skip || 0}
            onChangeIndex={(value) => { this.props.updateIndex(value) }}
            getCampaignGallery={(data, skip, take) => {
              let tempFetchDataParams = { ...this.state.fetchDataParams }
              tempFetchDataParams.pagination.PageSize = data.PageSize;
              tempFetchDataParams.pagination.PageCount = data.PageCount;
              this.setState({ fetchDataParams: tempFetchDataParams, skip: skip, take: take })
              this.getCampaignGallery(tempFetchDataParams)
            }}
            totalCount={this.state.totalCount}
            isError={this.state.listError} />
          : <GalleryMobile
            data={this.state.campaignsGalleryList}
            channelName="SMS"
            take={this.state.take || 4}
            skip={this.state.skip || 0}
            onChangeIndex={(value) => { this.props.updateIndex(value) }}
            getCampaignGallery={(data, skip, take) => {
              let tempFetchDataParams = { ...this.state.fetchDataParams }
              tempFetchDataParams.pagination.PageSize = data.PageSize;
              tempFetchDataParams.pagination.PageCount = data.PageCount;
              this.setState({ fetchDataParams: tempFetchDataParams, skip: skip, take: take })
              this.getCampaignGallery(tempFetchDataParams)
            }}
            totalCount={this.state.totalCount}
            isError={this.state.listError}
          />}
      </>
    );
  }


}
export default withRouter(Gallery);

const CardViewBodyComponent = (props) => {
  const imagePath = getServerConfig().slice(0, -1);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const context = useContext(authContext)
  const [galleryChannelFact, setGalleryChannelFact] = useState(null)

  const getGalleryChannelFact = (blastGuid) => {
    setGalleryChannelFact(null);
    const params = {
      "CampaignId": props.item.campaignID,
      "CampaignGuid": props.item.campaignGUID,
      "Channel": props.item.communicationChannel,
      "BlastGuid": blastGuid
    }
    connectServer({
      path: GET_GALLERY_CHANNEL_FACT,
      params,
      // loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setGalleryChannelFact(res.data || null)
        }
      },
      fail: err => {
      }
    })
  }

  const getEDMGallery = () => {
    const params = {
      "campaignId": props.item.campaignID,
      "blastguid": props.item.channelFacts[0].blastGuid
    }
    connectServer({
      path: GET_EDM_GALLERY,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          props.getEdmGalleryValue(res.data.edmContent)
          props.getIsEdmGalleryOpen(true);
        }
      },
      fail: err => {
      }
    })
  }

  return (
    <>
      {
        <div className="img-bor-wrapper">
          {
            props.item.channelFacts[0]?.communicationChannel === "E"
              ?
              <div className="img-bor clearfix">
                <Carousel
                  indicators={props.item.channelFacts.length > 1 ? true : false}
                  controls={props.item.channelFacts.length > 1 ? true : false}
                  interval={null}
                  className='gallery-slide-popup'
                  onSelect={() => { setIsInfoOpen(false) }}
                >
                  {/* map start */}
                  {props.item.channelFacts.map(item => (

                    <Carousel.Item key={item.blastGuid} >
                      {isInfoOpen
                        ? <CardInfoComponent data={galleryChannelFact} onChangeIsOpen={(value) => { setIsInfoOpen(value) }} />
                        :
                        <div className="gallery-scrollbar">
                          <div className="img-wrap css-scrollbar">
                            <img className="gallery-bg-img" src={(!!item.base64Image && item.base64Image[0] !== "") ? `data:image/jpeg;base64,${item.base64Image[0]}` : `${imagePath + NO_MEDIA_IMAGE}`} alt={imageSrc}
                              onClick={() => {
                                getEDMGallery();
                              }} />
                          </div>
                          <i className="icon-sd-circle-info-medium icons-md blue float-end cp action-info"
                            onClick={() => {
                              setIsInfoOpen(!isInfoOpen)
                              if (!isInfoOpen) {
                                getGalleryChannelFact(item.blastGuid)
                              }
                            }}></i>
                        </div>}
                    </Carousel.Item>
                  ))}
                  {/* map end */}
                </Carousel>

              </div>
              : props.item.channelFacts[0]?.communicationChannel === "S" ?
                <div className="img-bor clearfix">
                  <SMSContent
                    content={props.item.channelFacts}
                    campaignId={props.item.campaignID}
                    campaignGuid={props.item.campaignGUID}
                    channel={props.item.communicationChannel}
                  // isInfoOpen={isInfoOpen}
                  // setIsInfoOpen={(value) => {
                  //   setIsInfoOpen(value)
                  // }}
                  // getGalleryChannelFact={(blastGuid) => {
                  //   getGalleryChannelFact(blastGuid)
                  // }}
                  />

                </div> :
                props.item.channelFacts[0]?.communicationChannel === "W" ?
                  <div className="img-bor clearfix">
                    <WhatsAppContent
                      content={props.item.channelFacts}
                      campaignId={props.item.campaignID}
                      campaignGuid={props.item.campaignGUID}
                      channel={props.item.communicationChannel}
                    // isInfoOpen={isInfoOpen}
                    // setIsInfoOpen={(value) => {
                    //   setIsInfoOpen(value)
                    // }}
                    // getGalleryChannelFact={(blastGuid) => {
                    //   getGalleryChannelFact(blastGuid)
                    // }}
                    />

                  </div> :
                  <div className="img-bor clearfix">
                    <QRContent
                      content={props.item.channelFacts}
                      campaignId={props.item.campaignID}
                      campaignGuid={props.item.campaignGUID}
                      channel={props.item.communicationChannel}
                    // isInfoOpen={isInfoOpen}
                    // setIsInfoOpen={(value) => {
                    //   setIsInfoOpen(value)
                    // }}
                    // getGalleryChannelFact={(blastGuid) => {
                    //   getGalleryChannelFact(blastGuid)
                    // }}
                    />

                  </div>
          }


        </div>
      }
    </>
  );
};

const CardViewHeaderComponent = (props) => {
  const history = useHistory()
  const [editClickOff, setEditClickOff] = useState(false)
  let dropData = [{ text: "Edit" }, { text: "Duplicate" }];
  const context = useContext(authContext)

  const campaignHeader = {
    name: props.item.campaignName,
    startDate: props.item.startDate,
    endDate: props.item.endDate,
    goal: "Conversion",
    type: props.item.attributeName
  }

  if (props.item.campaignType === 'M') {
    dropData = dropData.map(item => {
      item.disabled = (item.text === "Duplicate") ? true : false;
      return item;
    })
  }

  if (props.item.campaignType === 'S' && props.item.statusID !== 7) {
    dropData = dropData.map(item => {
      item.disabled = (item.text === 'Edit') ? true : false;
      return item
    })
  }
  const duplicateByCampaign = () => {
    const channelId = props.item.communicationChannel === "E" ? 1 : props.item.communicationChannel === "S" ? 2 : props.item.communicationChannel === "W" ? 21 : 3
    const params = {
      "campaignId": props.item.campaignID,
      "channelId": channelId
    }
    connectServer({
      path: DUPLICATE_BY_CAMPAIGN,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          props.onChangeIndex(0)
        }
      },
      fail: err => {
      }
    })
  }
  return (
    <div className="gallery-head">
      <div className="d-flex align-items-center justify-content-between marginB10">
        <small>Created on: {dateFormat(props.item.createdDate)}</small>
        <KendoIconDropdown
          className="marginR-16 dropmenu-align"
          dir="rtl"
          data={dropData}
          icon=" icon-sd-menu-dot-medium icons-md blue mr7"
          onItemClick={(e) => {
            if (e.item.text.toLowerCase() === 'edit') {
              if (props.item.campaignType === 'S') {
                history.push({
                  pathname: `communication/create-communication`,
                  state: {
                    mode: "edit",
                    campaignName: props.item.campaignName !== undefined ? props.item.campaignName : '',
                    editClickOff: editClickOff,
                    status: props.item.statusID,
                    Channeltype: [props.channelName === "E" ? "Email" : props.channelName === "S" ? "SMS" : "WhatsApp"],
                    AnalyticsType: ["Web", "App"],
                    mobileApp: {},
                    campaignId: props.item.campaignID,
                    campaignHeader,
                    CampaignType: props.item.campaignType
                  }
                })
              } else {
                const sessionStr = localStorage.getItem("session")
                const sessionObject = JSON.parse(sessionStr)
                const accessToken = sessionObject ? sessionObject.accessToken : null
                const campaignId = window.btoa(props.item.campaignID)
                const mdcUrl = getServerConfig() + MDC_CANVAS_URL
                let fromEnvi = window.location.href.includes("app") ? "app" : window.location.href.includes("sit") ? "sit" : "run";
                window.location.href = mdcUrl + "?CampId=" + campaignId + '&accessToken=' + encodeURIComponent(accessToken) + '&from=' + fromEnvi
              }
            } else {
              duplicateByCampaign()
            }
          }}
        />
        {/* <DropDownButton
          className="gallery-dropdown-button marginR-14"
          onItemClick={(e) => {
            if (e.item.text.toLowerCase() === 'edit') {
              props.navigation.push({
                pathname: `campaign/create-campaign`,
                state: {
                  mode: "edit",
                  campaignName: props.item.campaignName !== undefined ? props.item.campaignName : '',
                  editClickOff: editClickOff,
                  status: props.item.statusID,
                  Channeltype: [props.channelName === "E" ? "Email" : "SMS"],
                  AnalyticsType: ["Web", "App"],
                  mobileApp: {},
                  campaignId: props.item.campaignID,
                  campaignHeader,
                  CampaignType: props.item.campaignType
                }
              })
            } else {
              duplicateByCampaign()
            }
          }}
          items={dropData}
          dir="rtl"
          icon=" icon-align-vertical-small icons-md blue"
        /> */}
      </div>
      <div className="d-flex align-items-center">
        <RSPTooltip text={props.item.campaignName} position="top">
          <p className="float-start text-wrap-1">{props.item.campaignName}</p>
        </RSPTooltip>
      </div>

    </div>
  );
};

const GalleryEmail = (props) => {

  const pageSizes = [4, 8, 20];
  const initialPageState = {
    skip: 0,
    take: 4,
    //type: 'input',
    buttonCount: 4,
    info: false,
    pageSizes: true,
    previousNext: true,
    responsive: true
  }

  const [pageState, setPageState] = useState(initialPageState);
  const [data, setData] = useState(props.data)
  const [isEdmGalleryOpen, setIsEdmGalleryOpen] = useState(false);
  const [edmGalleryValue, setEdmGalleryValue] = useState(null);
  let { skip, take, ...rest } = pageState;

  useEffect(() => {
    setData(props.data)
    setPageState({ ...pageState, skip: props.skip, take: props.take });
  }, [props.data])

  const handlePageChange = (event) => {
    const { skip, take } = event;
    const currentPage = Math.floor(skip / take + 1)
    setPageState({ ...pageState, skip: skip, take: take });
    let data = {
      "PageSize": take,
      "PageCount": currentPage,
      "channel": "E"
    }
    props.getCampaignGallery(data, skip, take)
  };

  const CardViewComponent = (item) => {

    let data = item.dataItem;

    return (
      <div className={`card-gallery-box ${campaignStatus(data?.statusID).className}`}>
        <div className="box">
          <CardViewHeaderComponent onChangeIndex={(value) => { props.onChangeIndex(value) }} item={data} channelName={data.communicationChannel} />
          <CardViewBodyComponent item={data}
            getEdmGalleryValue={(value) => { setEdmGalleryValue(value) }}
            getIsEdmGalleryOpen={(value) => { setIsEdmGalleryOpen(value) }}
          />
          {isEdmGalleryOpen &&
            <EDMGalleryModal
              isEdmGalleryOpen={isEdmGalleryOpen}
              edmGalleryValue={edmGalleryValue}
              onChangeIsOpen={(value) => {
                setIsEdmGalleryOpen(value)
              }}
            />
          }
        </div>
      </div>
    )
  }

  return (
    <>
      {
        data?.length
          ? <>
            <ListView
              className="gallery-grid-view"
              data={data && data?.slice(0, pageState.take)}
              item={CardViewComponent}
            />
            <div className="grid-pagenation-del d-flex">
              <Pager
                skip={pageState.skip}
                take={pageState.take}
                onPageChange={handlePageChange}
                total={props.totalCount && props.totalCount}
                type={pageState.type}
                info={pageState.info}
                buttonCount={pageState.buttonCount}
                pageSizes={pageState.pageSizes ? pageSizes : undefined}
                previousNext={pageState.previousNext}
              />
            </div>
          </>
          : <div className="p0"><SkeletonTable count={8} isError={props.isError} /></div>
      }
    </>
  )
}

const GalleryMobile = props => {
  const pageSizes = [4, 8, 20];
  const initialPageState = {
    skip: 0,
    take: 4,
    //type: 'input',
    buttonCount: 4,
    info: false,
    pageSizes: true,
    previousNext: true,
    responsive: true
  }
  const [pageState, setPageState] = useState(initialPageState);
  const [data, setData] = useState(props.data)
  let { skip, take, ...rest } = pageState;

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const handlePageChange = (event) => {
    const { skip, take } = event;
    const currentPage = Math.floor(skip / take + 1)
    setPageState({ ...pageState, skip: skip, take: take });
    let data = {
      "PageSize": take,
      "PageCount": currentPage,
      "channel": "S"
    }
    props.getCampaignGallery(data)
  };

  const CardViewComponent = (item) => {

    let data = item.dataItem;

    return (
      <div className={`card-gallery-box ${campaignStatus(data?.statusID).className}`}>
        <div className="box">
          <CardViewHeaderComponent onChangeIndex={(value) => { props.onChangeIndex(value) }} item={data} channelName={data.communicationChannel} />
          <CardViewBodyComponent item={data} />
        </div>
      </div>
    )
  }

  return <>
    <div>
      {data?.length
        ? <div>
          <ListView
            className="gallery-grid-view"
            data={data && data?.slice(0, pageState.take)}
            item={CardViewComponent}
          />
          <div className="grid-pagenation-del d-flex">
            <Pager
              skip={pageState.skip}
              take={pageState.take}
              onPageChange={handlePageChange}
              total={props.totalCount && props.totalCount}
              type={pageState.type}
              info={pageState.info}
              buttonCount={pageState.buttonCount}
              pageSizes={pageState.pageSizes ? pageSizes : undefined}
              previousNext={pageState.previousNext}
            />
          </div>
        </div>
        : <div className="p0"><SkeletonTable count={8} isError={props.isError} /></div>
      }</div>
  </>
}

// const GalleryQR = props => {
//   return <>
//     <CardViewComponent item={props.data} channelName="QR" />
//   </>
// }
const SMSContent = (props) => {
  const context = useContext(authContext)
  const [isOpen, setIsOpen] = useState(false);
  const [galleryChannelFact, setGalleryChannelFact] = useState(null)
  const getGalleryChannelFact = (blastGuid) => {
    setGalleryChannelFact(null);
    const params = {
      "CampaignId": props.campaignId,
      "CampaignGuid": props.campaignGuid,
      "Channel": props.channel,
      "BlastGuid": blastGuid
    }
    connectServer({
      path: GET_GALLERY_CHANNEL_FACT,
      params,
      // loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setGalleryChannelFact(res.data || null)
        }
      },
      fail: err => {
      }
    })
  }
  return (
    <>
      <Carousel
        indicators={props.content && props.content.length > 1 ? true : false}
        controls={props.content && props.content.length > 1 ? true : false}
        interval={null}
        className='gallery-slide-popup'
        onSelect={() => { setIsOpen(false) }}
      >
        {props.content.map(item => (
          isOpen
            ? <CardInfoComponent data={galleryChannelFact} onChangeIsOpen={(value) => { setIsOpen(value) }} />
            : <Carousel.Item>
              <div className="gallery-scrollbar">
                <div className='campaign-sms-wrapper border-0'>
                  <div className='campaign-sms-header'>
                    <div className='blue w100p text-center'>{getUserData().clientDetailsLocalization.clientName}</div>
                  </div>
                  <div className='campaign-sms-body'>
                    <div className='chat-bubble-left'>
                      <div className='chat-bubble'>
                        <div className='chat-content'>
                          <span className='campaign-message'>
                            {/* {item.previewImage ? item.previewImage : 'No data'} */}
                            {item.content ? item.content : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <i className="icon-sd-circle-info-medium icons-md blue float-end cp action-info"
                  onClick={() => {
                    setIsOpen(!isOpen)
                    if (!isOpen) {
                      getGalleryChannelFact(item.blastGuid)
                    }
                  }}></i>
              </div>
            </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}
const WhatsAppContent = (props) => {
  const [imageURL, setImageURL] = useState(getServerConfig().slice(0, -1))
  const context = useContext(authContext)
  const [isOpen, setIsOpen] = useState(false);
  const [galleryChannelFact, setGalleryChannelFact] = useState(null)
  const getGalleryChannelFact = (blastGuid) => {
    setGalleryChannelFact(null);
    const params = {
      "CampaignId": props.campaignId,
      "CampaignGuid": props.campaignGuid,
      "Channel": props.channel,
      "BlastGuid": blastGuid
    }
    connectServer({
      path: GET_GALLERY_CHANNEL_FACT,
      params,
      // loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setGalleryChannelFact(res.data || null)
        }
      },
      fail: err => {
      }
    })
  }
  return (
    <>
      <Carousel
        indicators={props.content && props.content.length > 1 ? true : false}
        controls={props.content && props.content.length > 1 ? true : false}
        interval={null}
        className='gallery-slide-popup'
        onSelect={() => { setIsOpen(false) }}
      >
        {props.content.map(item => (
          isOpen
            ? <CardInfoComponent data={galleryChannelFact} onChangeIsOpen={(value) => { setIsOpen(value) }} />
            :
            <Carousel.Item>
              <div className="gallery-scrollbar mt0" style={{ "height": "246px" }}>
                <WhatsAppMessage
                  profile={`${imageURL}${getUserData()?.clientDetailsLocalization?.logoPath}`}
                  username={getUserData().clientDetailsLocalization.clientName}
                  image={item.templateType === 2 ? item.waImagePath !== "" ? item.waImagePath : item.waMediaURL : item.templateType === 3 ? pdfImage : item.templateType === 4 ? videoImage : ""}
                  message={item.content ? item.content : 'No data'}
                />
                <i className="icon-sd-circle-info-medium icons-md blue float-end cp action-info"
                  onClick={() => {
                    setIsOpen(!isOpen)
                    if (!isOpen) {
                      getGalleryChannelFact(item.blastGuid)
                    }
                  }}></i>
              </div>
            </Carousel.Item>
        ))}
      </Carousel >
    </>
  )
}

const QRContent = (props) => {
  const imagePath = getServerConfig().slice(0, -1);
  const context = useContext(authContext)
  const [isOpen, setIsOpen] = useState(false);
  const [galleryChannelFact, setGalleryChannelFact] = useState(null)
  const getGalleryChannelFact = (blastGuid) => {
    setGalleryChannelFact(null);
    const params = {
      "CampaignId": props.campaignId,
      "CampaignGuid": props.campaignGuid,
      "Channel": props.channel,
      "BlastGuid": blastGuid
    }
    connectServer({
      path: GET_GALLERY_CHANNEL_FACT,
      params,
      // loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setGalleryChannelFact(res.data || null)
        }
      },
      fail: err => {
      }
    })
  }
  const [imageURL, setImageURL] = useState(getServerConfig().slice(0, -1))
  return (
    <>
      <Carousel
        indicators={props.content && props.content.length > 1 ? true : false}
        controls={props.content && props.content.length > 1 ? true : false}
        interval={null}
        className='gallery-slide-popup'
        onSelect={() => { setIsOpen(false) }}
      >
        {props.content.map(item => (
          isOpen
            ? <CardInfoComponent data={galleryChannelFact} onChangeIsOpen={(value) => { setIsOpen(value) }} />
            :
            <Carousel.Item>
              <div className="gallery-scrollbar">
                <div className="img-wrap css-scrollbar">
                  <img className="gallery-bg-img" src={item.content !== "" ? `${item.content}` : `${imagePath + NO_MEDIA_IMAGE}`} alt={imageSrc}
                    onClick={() => {
                      // getEDMGallery();
                    }} />
                </div>
                <i className="icon-sd-circle-info-medium icons-md blue float-end cp action-info"
                  onClick={() => {
                    setIsOpen(!isOpen)
                    if (!isOpen) {
                      getGalleryChannelFact(item.blastGuid)
                    }
                  }}></i>
              </div>
            </Carousel.Item>
        ))}
      </Carousel >
    </>
  )
}

const CardInfoComponent = (props) => {
  return (
    <>
      {props.data
        ? <div className="img-bor clearfix justify-content-between">
          <i onClick={() => {
            props.onChangeIsOpen(false)
          }} className="icon-sd-close-mini icons-sm blue cp position-absolute top10 right10" />
          <div className="text-center d-block w100p border1 radius">
            <div className="fs14 lh28">
              <ul className="borderB1 m10 pb10">
                <li>Delivery method:</li>
                <li>{props?.data?.campaignType === "S" ? "Single dimension" : "Multi dimension"}</li>
                <li>Communication type:</li>
                <li>{props?.data?.campaignAttribute || ''}</li>
              </ul>
              <ul className="m10">
                <li>Total audience: {props?.data?.noOfRecipientsCount || 0}</li>
                <li>Reach: {props?.data?.reachCount || 0}</li>
                <li>Engagement: {props?.data?.interactionCount || 0}</li>
                <li>Conversion: {props?.data?.conversionCount || 0}</li>
              </ul>
            </div>
          </div>
        </div>
        : <div className="img-bor-wrapper img-bor p10 pt15">
          <SkeletonTable count={5} />
        </div>
      }
    </>
  )
}

const campaignStatus = campaignStatusId => {
  return campaignProgressStatus[campaignStatusId]
}

const campaignProgressStatus = {
  0: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-medium' },
  5: {
    title: 'In progress',
    className: 'status-progress',
    icon: 'icon-sd-in-progress-medium'
  },
  51: {
    title: 'Rejected',
    className: 'status-rejected',
    icon: 'icon-sd-circle-close-medium',
    message: ""
  },
  52: {
    title: 'Alert',
    className: 'status-alert',
    icon: 'icon-sd-alert-medium'
  },
  12: {
    title: 'Alert',
    className: 'status-alert',
    icon: 'icon-sd-alert-medium'
  },
  9: {
    title: 'Completed',
    className: 'status-completed',
    icon: 'icon-tick-medium'
  },
  7: {
    title: 'Scheduled',
    className: 'status-scheduled',
    icon: 'icon-sd-time-medium'
  },
  6: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-medium' },
  20: {
    title: 'Multi status',
    className: 'status-multistatus',
    icon: 'icon-multi-status-large'
  }
}