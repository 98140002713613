import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { GET_LICENSE_INFO } from 'Helper/Constants/endPoint'
import authContext from 'Helper/StateHandler/auth-context';
import { dateFormat, getAuthClientId } from 'Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { RSPrimaryBtn } from 'Components/RSButtons';

const LicenseInfo = props => {
    const context = useContext(authContext)
    const history = useHistory();

    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [status, setStatus] = useState(true)
    const [licenseData, setLicenseData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        let params = {
            "clientId": getAuthClientId()
        }
        connectServer({
            path: GET_LICENSE_INFO,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    let resData = [
                        {
                            "key1": "Account/Client",
                            "value1": res.data.clientName,
                            "key2": "Account No",
                            "value2": res.data.accountNo
                        },
                        {
                            "key1": "Start date",
                            "value1": dateFormat(res.data.startDate),
                            "key2": "End date",
                            "value2": dateFormat(res.data.endDate || new Date())
                        },
                        {
                            "key1": "Payment frequency",
                            "value1": getPaymentFrequency(res.data.paymentFrequency),
                            "key2": "License key",
                            "value2": res.data.maskedLicenseKey
                        },
                        {
                            "key1": "Subscription plan",
                            "value1": res.data.licensingType,
                            "key2": "Amount",
                            "value2": "$" + res.data.licensingPrice
                        },
                        {
                            "key1": "Next billing date",
                            "value1": dateFormat(res.data.nextDateTime || new Date())

                        }
                    ]
                    setLicenseData(resData)
                }
            },
            fail: err => {
            }
        })
    }, [])

    const getPaymentFrequency = (frequency) => {
        switch (frequency) {
            case 1: return "Monthly";
            case 2: return "Quarterly";
            case 3: return "Half-yearly";
            case 4: return "Annually";
        }
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="header-title d-flex justify-content-between">
                    <h1>License info</h1>
                    <div className="back-text" onClick={() => history.push(`/preferences`)}>
                        <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                        Back
                    </div>
                </div>
                <hr className="blue mb30" />
                {
                    status && <Container className='position-relative'>
                        <Row>
                            <div className='status-bar-head clearfix status-warning'>
                                <div>
                                    <span className='status-icon-Bg'><i className="icon-sd-alert-medium icons-md" /></span>
                                    <span><p className='paddingT15'>Your license is about to expire. Please renew it to continue using the product/service from the Service catalogue section.</p></span>
                                </div>
                                <div className='license-close'>
                                    <i className="icon-sd-close-mini icons-sm white float-end cp" onClick={() => setStatus(false)} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                }

                <Grid
                    data={licenseData?.slice(skip, take + skip)}
                    total={licenseData?.length}
                    className="grid-pagenation-del grid-replaceable"
                    scrollable={"none"}>
                    <GridColumn title='Account information' width={100}>

                    </GridColumn>
                    <GridColumn headerClassName="border-left-0"
                        cell={(props) => {
                            return (
                                <>
                                    <td className="border-left-0" width="50%">
                                        <table className='no-shadow-imp no-bg-imp'>
                                            <td width="50%" >{props.dataItem.key1}</td>
                                            <td width="50%" >{props.dataItem.value1}</td>
                                        </table>
                                    </td>
                                    <td>
                                        <table className='no-shadow-imp no-bg-imp'>
                                            <td width="50%" >{props.dataItem.key2}</td>
                                            <td width="50%" >{props.dataItem.value2}</td>
                                        </table>
                                    </td>
                                </>
                            )
                        }} />
                </Grid>
                <div className="btn-container d-flex justify-content-end marginT20">
                    <RSPrimaryBtn onClick={() => {
                        history.push("/preferences/service-catalogue");
                    }}>Upgrade</RSPrimaryBtn>
                </div>
            </Container>
        </LayoutPageWrapper>
    )
}

export default LicenseInfo;