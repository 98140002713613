import React from 'react';

const RSPLoader = props => {
    return (
        <>
            <div id="loading" className={`rp-loading ${props.className ? props.className : ''}`} style={props.style}>
                <div className="loading">
                    {/* <div className="loading-content"></div> */}
                <div className="loading-content-circ-path"></div>
                    <div className="loading-tick"></div>
                </div>
            </div>
        </>
    )
}

export default RSPLoader