import React, { Component } from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import {
  SearchView,
  SearchViewUpdated,
} from "Components/SearchView/SearchView";
import { DropDownButton } from "@progress/kendo-react-buttons";

const SELECTED_FIELD = "selected";

const toolbarTools = ["transferTo", "transferFrom"];
// const [isSearchOpen, setIsSearchOpen] = useState(false);

class TableDetails extends Component {
  state = {
    leftColumnValues: this.props.leftColumnValues,
    rightColumnValues:
      this.props.rightColumnValues !== undefined
        ? this.props.rightColumnValues
        : [],
    tableAttributes:
      this.props.tableAttributes !== undefined
        ? this.props.tableAttributes
        : [],
    draggedItem: {},
    searchInput: "",
    isSearchOpen: false,
    errorText: "",
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate = (prevProps, PrevState) => {
    if (prevProps !== this.props) {
      this.setState({ leftColumnValues: this.props.leftColumnValues });
      if (this.props.rightColumnValues !== undefined) {
        this.setState({ rightColumnValues: this.props.rightColumnValues });
      }
    }
    if (this.state.errorText !== PrevState?.errorText) {
      this.props.throwError(this.state.errorText);
    }
  };

  handleItemClick = (event, data, connectedData) => {
    this.setState({
      [data]: this.state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: this.state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };
  handleToolBarClick = (e, data, connectedData) => {
    let result = processListBoxData(
      this.state[data],
      this.state[connectedData],
      e.toolName,
      SELECTED_FIELD
    );
    this.setState({
      [data]: result.listBoxOneData,
      [connectedData]: result.listBoxTwoData,
    });
    this.props.getSelectedData({
      [data]: result.listBoxOneData,
      [connectedData]: result.listBoxTwoData,
    });
  };

  handleDragStart = (e) => {
    e.dataItem.dataCollection = e.target.props.name;
    this.setState({
      draggedItem: e.dataItem,
    });
  };

  handleDrop = (e) => {
    let dragItemData = this.state.draggedItem.dataCollection;
    let dropItemData = e.target.props.name;
    let result = processListBoxDragAndDrop(
      this.state[dragItemData],
      this.state[dropItemData],
      this.state.draggedItem,
      e.dataItem,
      "name"
    );
    this.setState({
      [dragItemData]: result.listBoxOneData,
      [dropItemData]: result.listBoxTwoData,
    });
  };

  itemRender(props) {
    return (
      <>
        {props.dataItem.dataFlag ? (
          <div className="nodata-bar text-center">
            <i className="icon-sd-alert-medium icon-lg orange-medium"></i>
            <p>No data available</p>
          </div>
        ) : (
          <li className="k-item" {...props}>
            <div className="d-flex flex-column align-items-start">
              <span>
                {props.dataItem.name?.includes(".")
                  ? props.dataItem.name?.split(".")[1]
                  : props.dataItem.name}
              </span>
              {props.dataItem.isPrimaryKey && (
                <span className="primaryKey">
                  <i
                    className={`icon-active-key-mini icon-md key-icon color-white ${props.dataItem?.side ? "key" + props.dataItem?.side : ""
                      }`}
                  ></i>
                </span>
              )}
            </div>
          </li>
        )}
      </>
    );
  }

  itemRenderRight(props) {
    return (
      <li className="k-item" {...props}>
        <div className="d-flex flex-column align-items-start table-item">
          {props.dataItem?.tableid && (
            <small>
              <i className="grey">{props.dataItem?.tableid}</i>
            </small>
          )}
          <span>
            {props.dataItem.name?.includes(".")
              ? props.dataItem.name?.split(".")[1]
              : props.dataItem.name}
          </span>
          {props.dataItem.isPrimaryKey && (
            <span className="primaryKey">
              <i
                className={`icon-active-key-mini icon-md key-icon color-white ${props.dataItem?.side ? "key" + props.dataItem?.side : ""
                  }`}
              ></i>
            </span>
          )}
        </div>
      </li>
    );
  }

  render() {
    return (
      <div className="pb15 new-popup-drag">
        <div className="list-container">
          <div className="list-column position-relative">
            <div className="multi-drag-input border0">
              <div className="dataexchange-heading">
                <div className=" d-flex justify-content-between">
                  <h4 className="black-medium pl2">
                    {this.props?.nameLeft
                      ? this.props?.nameLeft
                      : "Available columns/attributes"}
                  </h4>

                  {/* <SearchViewUpdated
                    searchedText={(text) => {
                      this.setState({
                        searchInput: text,
                      });
                    }}
                    width="319px"
                    // width="222px"
                    placeholder="Search attributes"
                  /> */}
                </div>
              </div>
              <ListBox
                style={{ height: 150, width: "100%" }}
                data={
                  this.state.leftColumnValues?.filter((item) =>
                    item.name
                      ?.toLowerCase()
                      ?.includes(this.state.searchInput.toLowerCase())
                  )?.length
                    ? this.state.leftColumnValues?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        ?.includes(this.state.searchInput.toLowerCase())
                    )
                    : this.state.searchInput.length
                      ? [
                        {
                          name: "No data Found",
                          dataFlag: true,
                          selected: false,
                        },
                      ]
                      : []
                }
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) =>
                  this.handleItemClick(
                    e,
                    "leftColumnValues",
                    "rightColumnValues"
                  )
                }
                onDragStart={this.handleDragStart}
                onDrop={this.handleDrop}
                name="leftColumnValues"
                item={this.itemRender}
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={toolbarTools}
                      data={this.state.leftColumnValues}
                      dataConnected={this.state.rightColumnValues}
                      onToolClick={(e) =>
                        this.handleToolBarClick(
                          e,
                          "leftColumnValues",
                          "rightColumnValues"
                        )
                      }
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="list-column">
            <div className="multi-drag-input border0">
              <div className="dataexchange-heading">
                <div className="d-flex justify-content-between">
                  {this.props?.namerigh
                    ? this.props?.namerigh
                    : 
                  <h4 className="black-medium">{"Selected columns/attributes"}</h4>}
                  {this.props.tableAttributes?.length > 0 && (
                    <DropDownButton
                      dir="rtl"
                      className={"gallery-dropdown-button"}
                      popupSettings={{
                        animate: true,
                        popupClass: "new-dropdown-list",
                      }}
                      itemRender={(props) => {
                        const { tableName, tableKey, tableDate } = props.item;
                        return (
                          <li>{`${tableName} - ${tableKey} - ${tableDate}`}</li>
                        );
                      }}
                      items={this.props.tableAttributes}
                      icon=" icon-justify-dropdown-mini icon-md blue cp"
                    />
                  )}
                </div>
              </div>
              <ListBox
                style={{ height: 150, width: "100%" }}
                data={this.state.rightColumnValues}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) =>
                  this.handleItemClick(
                    e,
                    "rightColumnValues",
                    "leftColumnValues"
                  )
                }
                item={this.itemRenderRight}
                onDragStart={this.handleDragStart}
                onDrop={this.handleDrop}
                name="rightColumnValues"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableDetails;

// const UserDetails = ["Cust_ID", "Name", "Email", "Mobile", "Campaign response", "Gender", "Audience laddering", "Maturity date", "Annual income", "Leads", "Marital status", "Decile", "Unsubscribe", "Lead score", "Campaign name"];
// const FixedDeposits = ["Cust_ID", "Fixed deposit opted", "Fixed deposit amount", "Fixed deposit maturity date", "Fixed deposit opted date", "Fixed deposit maturity period", "Product type", "Liquidate fixed deposit"];
// const CreditCards = ["Cust_ID", "Account type", "Credit card opted", "Credit card type", "Current credit limit", "Credit card payment date", "Credit limit utilization", "Credit card eligibility"];
// const Loans = ["Cust_ID", "Loan type", "Loan amount", "Loan period", "EMI amount"];
