import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment';
import NewCharts from 'Components/Charts/NewCharts';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { KendoIconDropdown } from 'Components/KendoDropdown/IconDropdown';
import { connectServer } from 'Helper/Network/networkHandler';
import { DASHBOARD_AUDIENCE_BEHAVIOUR, DASHBOARD_CAMPAIGN_PERFORMANCE, DASHBOARD_CHANNEL_PERFORMANCE, DASHBOARD_LEADS_GENERATED, DASHBOARD_RECENT_CAMPAIGNS } from 'Helper/Constants/endPoint';
import { BubbleChartSkeleton, ColumnChartSkeleton, PieChartSkeleton, SemiPieChartSkeleton } from 'Components/Skeleton/Skeleton';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { ch_chartColor, ch_sunday, ch_monday, ch_tuesday, ch_wednesday, ch_thursday, ch_friday, ch_saturday, ch_emailColor, ch_twitterColor, ch_linkedinColor, ch_qrColor, ch_googleColor, ch_pinterestColor, ch_facebookColor, ch_smsColor, ch_whatsAppColor, ch_mobileColor } from 'Components/Charts/ChartColors';
import authContext from 'Helper/StateHandler/auth-context';


class CampaignDashboard extends Component {
    static contextType = authContext
    state = {
        recentAllCampaigns: null,
        recentSelectedCampaigns: null,
        recentSelectedCampaignError: false,
        performanceAllCampaigns: null,
        performanceSelectedCampaigns: null,
        performanceCampaignError: false,
        performanceSelectedText: 'Top performing communications',
        audienceAllBehavior: null,
        audienceSelectedBehavior: null,
        leadsAllGenerated: null,
        leadsSelectedGenerated: null,
        channelAllPerformance: null,
        channelSelectedPerformance: null,
        channelPerformanceError: false,
        sankeyPortletFullView: false,
        tabBehaviourIndex: 0,
        tabGeographicIndex: 0,
        tabDeviceIndex: 0,
        tabAppOSIndex: 0,
        leadsError: false,
        audienceBehaviorError: false
    }

    componentDidMount = () => {
        // if(this.props?.selectedValue?.value){
        //     const params = {"days": this.props?.selectedValue?.value || 30}
        //     this.getDashboardData(params)
        // }
        const params = { "days": this.props?.selectedValue?.value || 30 }
        this.getDashboardData(params)
    }

    // componentDidUpdate = (prevProps) => {
    //     if(prevProps !== this.props){
    //         if(this.props?.selectedValue?.value){
    //             const params = {"days": this.props?.selectedValue?.value || 30}
    //             this.getDashboardData(params)
    //         }
    //     }
    // }

    getDashboardData = (params) => {
        this.getRecentCampaigns(params)
        // this.getCampaignPerformance(params)
        // this.getAudienceBehaviour(params)
        // this.getLeadsGenerated(params)
        // this.getChannelPerformance(params)
    }

    getRecentCampaigns = (params) => {
        connectServer({
            path: DASHBOARD_RECENT_CAMPAIGNS,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    if (!res.data.isDashboard) {
                        this.props.history.push("/launchpad")
                    }
                    this.setState({
                        recentAllCampaigns: res?.data?.recentCampaigns || null,
                        recentSelectedCampaigns: res?.data["recentCampaigns"] || null,
                        recentSelectedCampaignError: res?.data?.recentCampaigns?.length > 0 ? false : true,
                        performanceAllCampaigns: res?.data?.performanceCampaigns || null,
                        performanceSelectedCampaigns: res?.data?.performanceCampaigns?.topPerformanceCampaigns || null,
                        performanceCampaignError: res?.data?.performanceCampaigns?.topPerformanceCampaigns?.length > 0 ? false : true,
                        audienceAllBehavior: res?.data?.customersPropensity?.week || null,
                        audienceBehaviorError: res?.data?.customersPropensity?.week?.length > 0 ? false : true,
                        audienceSelectedBehavior: !!res?.data?.customersPropensity ? res?.data?.customersPropensity["week"] || null : null,
                        leadsAllGenerated: res?.data?.leadsSource || null,
                        leadsError: res?.data?.leadsSource?.length > 0 ? false : true,
                        leadsSelectedGenerated: res?.data["leadsSource"] || null,
                        channelAllPerformance: !!res.data?.channelPerformance?.reach ? JSON.parse(res.data?.channelPerformance?.reach) || null : null,
                        channelSelectedPerformance: !!res.data?.channelPerformance?.reach ? JSON.parse(res.data?.channelPerformance?.reach) || null : null,
                        channelPerformanceError: !!res.data?.channelPerformance?.reach ? JSON.parse(res.data?.channelPerformance?.reach).categories?.length > 0 ? false : true : true,
                    })
                } else {
                    this.setState({
                        recentSelectedCampaignError: true,
                        channelPerformanceError: true,
                        leadsError: true,
                        audienceBehaviorError: true,
                        performanceCampaignError: true
                    })
                }
            },
            fail: err => {
                this.setState({
                    recentSelectedCampaignError: true,
                    channelPerformanceError: true,
                    leadsError: true,
                    audienceBehaviorError: true,
                    performanceCampaignError: true
                })
            }
        })
    }

    getCampaignPerformance = (params) => {
        connectServer({
            path: DASHBOARD_CAMPAIGN_PERFORMANCE,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        performanceAllCampaigns: res?.data || null,
                        performanceSelectedCampaigns: res?.data["topPerformanceCampaigns"] || null
                    })
                }
            },
            fail: err => {
            }
        })
    }

    getAudienceBehaviour = (params) => {
        this.setState({ audienceBehaviorError: false })
        connectServer({
            path: DASHBOARD_AUDIENCE_BEHAVIOUR,
            params,
            context: this.context,
            ok: res => {
                if (res?.status && res?.data !== null) {
                    this.setState({
                        audienceAllBehavior: res?.data?.week || null,
                        audienceSelectedBehavior: res?.data["week"] || null
                    })
                } else {
                    this.setState({ audienceBehaviorError: true })
                }
            },
            fail: err => {
                this.setState({ audienceBehaviorError: true })
            }
        })
    }

    getLeadsGenerated = (params) => {
        this.setState({ leadsError: true })
        connectServer({
            path: DASHBOARD_LEADS_GENERATED,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        leadsAllGenerated: res?.data?.leadsSource || null,
                        leadsSelectedGenerated: res?.data["leadsSource"] || null
                    })
                } else {
                    this.setState({ leadsError: true })
                }
            },
            fail: err => {
                this.setState({ leadsError: true })
            }
        })
    }

    getChannelPerformance = (params) => {
        connectServer({
            path: DASHBOARD_CHANNEL_PERFORMANCE,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        channelAllPerformance: res?.data?.reach || null,
                        channelSelectedPerformance: res.data["reach"] || null
                    })
                }
            },
            fail: err => {
            }
        })
    }

    getTargetInfoText = (targetInfo) => {
        const word = targetInfo;
        const firstLetter = word.charAt(0)
        const remainingLetters = word.substring(1)
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLettersSmall = remainingLetters.toLowerCase()
        const capitalizedWord = firstLetterCap + remainingLettersSmall
        return capitalizedWord;
    }

    render() {

        const groupedCampaigns = this.state?.recentAllCampaigns?.reduce((res, el, i) => {
            if (i % 3 === 0) {
                res[res.length] = [el];
            } else {
                res[res.length - 1] = [...res[res.length - 1], el];
            }
            return res;
        }, []);

        return (
            <>
                <Row>
                    {/* Recent campaigns */}
                    <Col md={12}>
                        <div className="portlet-container sp-slider">
                            <div className="portlet-header">
                                <h4>Recent communications</h4>
                                {/* <div className="p-nav-right">
                                {this.state.recentAllCampaigns && <KendoIconDropdown
                                        className="marginR-16"
                                        dir="rtl"
                                        data={Object.keys(this.state.recentAllCampaigns).map(item => { return {text: recentCampaignsTitles[item], id: item} })}
                                        icon=" icon-align-vertical-small icons-md blue"
                                        onItemClick={ (e) => {
                                            this.setState({ recentSelectedCampaigns: this.state.recentAllCampaigns[e.item.id] })
                                        }}
                                    />}
                                </div> */}
                            </div>
                            {groupedCampaigns?.length ?
                                <Carousel
                                    variant="dark"
                                    className="slider-dashboard"
                                    // pause='hover'
                                    indicators={true}
                                    indicatorLabels={true}
                                    interval={null}
                                    wrap={false}
                                >
                                    {
                                        groupedCampaigns?.map(items => {
                                            return <Carousel.Item
                                            // interval={1000}
                                            >
                                                <Row>
                                                    {
                                                        items.map(item => {
                                                            let yearMonthStart = item.startDate.split(',')[1]
                                                            let yearMonthEnd = item.endDate.split(',')[1]
                                                            yearMonthStart.split(' ')
                                                            yearMonthEnd.split(' ')

                                                            // StART
                                                            let dayWeekStart = item.startDate.split(',')[0] // Mon
                                                            let yearStart = yearMonthStart.split(' ')[1] // 2022
                                                            let monthStart = yearMonthStart.split(' ')[2] // Jan
                                                            let dayStart = item.startDate.split(',')[2] // 01

                                                            // ENd
                                                            let dayWeekEnd = item.endDate.split(',')[0] // Mon
                                                            let yearEnd = yearMonthEnd.split(' ')[1] // 2022
                                                            let monthEnd = yearMonthEnd.split(' ')[2] // Jan
                                                            let dayEnd = item.endDate.split(',')[2] // 01

                                                            let recentStartDate = `${dayWeekStart}, ${monthStart} ${dayStart}, ${yearStart}`
                                                            let recentEndDate = `${dayWeekEnd}, ${monthEnd} ${dayEnd}, ${yearEnd}`

                                                            // console.log("::DATE::", `${dayWeek}, ${year} ${month},${day}`)

                                                            return <Col md={4} className="padding0">
                                                                <div className="portlet-chart gauge-chart">
                                                                    <NewCharts options={recentCampaignsChart1(item)} />
                                                                    <div className="gauge-label">
                                                                        <RSPTooltip text={item.campaignName} position="top">
                                                                            <p className='text-wrap-1 marginB0'>{item.campaignName || ''}</p>
                                                                        </RSPTooltip>
                                                                        <div className='position-relative d-flex align-items-center justify-content-center'>
                                                                            {/* <label>Mon, Dec 20, 2021 - Wed, Jan 19, 2022</label> */}
                                                                            <small>
                                                                                {`${recentStartDate || ''} - ${recentEndDate || ''}`}
                                                                            </small>
                                                                            <RSPPophover pophover={
                                                                                <>
                                                                                    <small>Channel: {item.channelInfo.replace(/,/g, ', ')}</small>
                                                                                    <small>Target: {this.getTargetInfoText(item.targetInfo)} ({item.primaryTarget}%)</small>
                                                                                    <small>Current: {this.getTargetInfoText(item.targetInfo)} ({item.completion}%)</small>
                                                                                </>
                                                                            } position="top">
                                                                                <i className="icon-sd-circle-info-medium icons-md blue marginL5 cursor-pointer"></i>
                                                                            </RSPPophover>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </Carousel.Item>
                                        })
                                    }
                                </Carousel>
                                :
                                <Row>
                                    <Col md={4}>
                                        <SemiPieChartSkeleton nodata={this.state.recentSelectedCampaignError} />
                                    </Col>
                                    <Col md={4}>
                                        <SemiPieChartSkeleton nodata={this.state.recentSelectedCampaignError} />
                                    </Col>
                                    <Col md={4}>
                                        <SemiPieChartSkeleton nodata={this.state.recentSelectedCampaignError} />
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>

                <Row>
                    {/* Top performing campaigns */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>{this.state.performanceSelectedText}</h4>
                                <div className="p-nav-right">
                                    {this.state.performanceAllCampaigns && <KendoIconDropdown
                                        className="marginR-16"
                                        popupClass="marginR3"
                                        dir="rtl"
                                        data={Object.keys(this.state.performanceAllCampaigns).filter(item => performanceCampaignsTitles[item] !== this.state.performanceSelectedText).map(item => { return { text: performanceCampaignsTitles[item], id: item } })}
                                        icon=" icon-sd-menu-dot-medium icons-md blue"
                                        onItemClick={(e) => {
                                            this.setState({
                                                performanceSelectedCampaigns: this.state.performanceAllCampaigns[e.item.id],
                                                performanceSelectedText: e.item.text
                                            })
                                        }}
                                    />}
                                </div>
                            </div>
                            <div className="portlet-body">
                                {this.state.performanceSelectedCampaigns?.length ?
                                    <div className="portlet-chart">
                                        <ul className="portlet-list">
                                            {this.state.performanceSelectedCampaigns.map(item => {
                                                return <li>
                                                    <p>{item.title || ""}</p>
                                                    <div className="percentage">
                                                        <span>{item.value || 0}</span>
                                                        <small>%</small>
                                                        <i className={`posr top-2 ${this.state.performanceSelectedText === 'Top performing communications' ? 'icon-sd-arrow-up-bold-medium icons-md green-medium' : 'icon-sd-arrow-down-medium icons-md fw-bold maroon-medium'}`}></i>
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                    </div> : <SkeletonTable count={6} isError={this.state.performanceCampaignError} />}
                            </div>
                        </div>
                    </Col>

                    {/* Audience behavior */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Audience behavior</h4>
                                {/* <div className="p-nav-right">
                                {this.state.audienceAllBehavior && <KendoIconDropdown
                                        className="marginR-16"
                                        dir="rtl"
                                        data={Object.keys(this.state.audienceAllBehavior).map(item => { return {text: audienceBehaviorTitles[item], id: item} })}
                                        icon=" icon-align-vertical-small icons-md blue"
                                        onItemClick={ (e) => {
                                            this.setState({ audienceSelectedBehavior: this.state.audienceAllBehavior[e.item.id] })
                                        }}
                                    />}
                                </div> */}
                            </div>
                            <div className="portlet-body">
                                {/* <div className="portlet-image">
                                    <img src={bubbleChart} alt={bubbleChart} />
                                </div> */}
                                {this.state.audienceAllBehavior ?
                                    <div className="portlet-chart">
                                        <NewCharts className="bubbleChart-new-1" options={audienceBehaviorChart(this.state.audienceAllBehavior)} />
                                    </div>
                                    : <BubbleChartSkeleton isError={this.state.audienceBehaviorError} />}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {/* Leads acquisition */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Leads acquisition</h4>
                                {/* <div className="p-nav-right">
                                {this.state.leadsAllGenerated && <KendoIconDropdown
                                        className="marginR-16"
                                        dir="rtl"
                                        data={Object.keys(this.state.leadsAllGenerated).map(item => { return { text: leadGeneratedTitles[item], id: item } })}
                                        icon=" icon-align-vertical-small icons-md blue"
                                        onItemClick={e => {
                                            this.setState({ leadsSelectedGenerated: this.state.leadsAllGenerated[e.item.id] })
                                        }}
                                    />}
                                </div> */}
                            </div>
                            {this.state.leadsAllGenerated?.length ? <div className="portlet-body">
                                <div className="portlet-chart varpie-chart">
                                    <NewCharts options={leadsGeneratedChart(this.state.leadsAllGenerated)} />
                                </div>
                            </div>
                                : <PieChartSkeleton isError={this.state.leadsError} />}
                        </div>
                    </Col>
                    {/* Channel performance */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Channel performance</h4>
                                {/* <div className="p-nav-right">
                                {this.state.channelAllPerformance && <KendoIconDropdown
                                        className="marginR-16"
                                        dir="rtl"
                                        data={Object.keys(this.state.channelAllPerformance).map(item => { return {text: channelPerformanceTitles[item], id: item} })}
                                        icon=" icon-align-vertical-small icons-md blue"
                                        onItemClick={e => {
                                            this.setState({ channelSelectedPerformance : this.state.channelAllPerformance[e.item.id] })
                                        }}
                                    />}
                                </div> */}
                            </div>
                            {this.state.channelAllPerformance?.categories?.length ?
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={channelPerformanceChart(this.state.channelAllPerformance)} />
                                    </div>
                                </div>
                                : <ColumnChartSkeleton isError={this.state.channelPerformanceError} />}
                        </div>
                    </Col>
                </Row>

            </>
        )
    }
}
export default withRouter(CampaignDashboard);

const recentCampaignsTitles = {
    recentCampaigns: "Recent communications",
    recentCompletedCampaigns: "Recent completed communications"
}

const performanceCampaignsTitles = {
    lowPerformanceCampaigns: "Low performing communications",
    topPerformanceCampaigns: "Top performing communications"
}

const audienceBehaviorTitles = {
    week: "Week",
    day: "Day"
}

const leadGeneratedTitles = {
    leadsSource: "Lead source"
}

const channelPerformanceTitles = {
    reach: "Reach",
    engagement: "Engagement",
    conversion: "Conversion"
}

const recentData = [{ text: "Recent campaigns" }, { text: "Recently completed campaigns" }];
const topPerformData = [{ text: "Low performing campaigns" }];
const leadsGeneratedData = [{ text: "All" }, { text: "Flipkart best buying days" }, { text: "Gold bonds" }, { text: "Effortless mobile banking" }];
const channelPerformData = [{ text: "Reach" }, { text: "Engagement" }, { text: "Conversion" }];
const audienceBehaviorData = [{ text: "Week" }, { text: "Day" }];

// Recent camapigns - gaguage - 01
const recentCampaignsChart1 = data => {

    return {
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: null,
        },

        title: {
            text: null
        },
        background: null,
        credits: {
            enabled: false
        },
        plotOptions: {
            gauge: {
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        // var mbps = this.y;
                        // return '<span class="rpGaugeLabel" style="text-align:center;display:block;">' + mbps + '<span style="font-size:13px;text-align:center;display:block;">Warming up</span></span>';
                        return `<span class="rpGaugeLabel font-xs" style="text-align:center;display:block;"><span style="font-family: Muktamedium; text-align:center;display:block;">${data?.status}</span></span>`;
                    },
                    style: {
                        fontFamily: 'Muktaregular',
                        fontWeight: '300',
                        fontSize: '22px',
                        textShadow: '0',
                        color: '#585858'
                    },
                    borderColor: 'transparent',
                },
                dial: {
                    radius: '75%',
                    backgroundColor: '#fd2d32',
                    borderWidth: 0,
                    baseWidth: 5,
                    topWidth: 1,
                    baseLength: '40%', // of radius
                    rearLength: '0%'
                },
                pivot: {
                    backgroundColor: '#b8b8b8',
                    radius: 7
                }
            }
        },
        pane: [
            { startAngle: -90, endAngle: 90, center: ['50%', '50%'], background: null, },
            { startAngle: -100, endAngle: 100, center: ['50%', '65%'], background: null }
        ],
        tooltip: {
            enabled: true,
            // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
            pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        // the value axis
        yAxis: [
            {
                min: 0,
                max: 100,
                lineColor: null,
                minorTickInterval: 'auto',
                minorTickWidth: 0,
                minorTickLength: 0,
                minorTickPosition: 'outside',
                minorTickColor: '#fff',
                tickPixelInterval: '100',
                tickWidth: 0,
                tickLength: 0,
                tickPosition: 'inside',
                tickColor: '#fff',
                labels: {
                    step: 4,
                    distance: -2,
                    useHTML: true,
                    style: {
                        fontFamily: 'Muktaregular',
                        fontWeight: '400',
                        fontSize: '14px',
                        textShadow: '0',
                        color: '#b8b8b8'
                    }
                },
                title: {
                    text: null
                },
                plotBands: [
                    { from: 0, to: 20, color: '#ff5757', innerRadius: '88%', outerRadius: '105%', },
                    { from: 20, to: 40, color: '#fe9041', innerRadius: '88%', outerRadius: '105%' },
                    { from: 40, to: 60, color: '#e7cb60', innerRadius: '88%', outerRadius: '105%' },
                    { from: 60, to: 80, color: '#b2d942', innerRadius: '88%', outerRadius: '105%' },
                    { from: 80, to: 100, color: '#99cc01', innerRadius: '88%', outerRadius: '105%' },
                    { from: 0, to: 100, color: '#e7f2f8', innerRadius: '105%', outerRadius: '113%' }
                ]
            },
            {
                pane: 1,
                min: 0,
                max: 100,
                labels: { enabled: false },
                tickWidth: 0,
                tickLength: 0,
                minorTickWidth: 0,
                minorTickLength: 0,
                lineWidth: 0
            }
        ],

        series: [{
            name: data?.status || '',
            data: [parseInt(data?.completion, 10) || 0],
            tooltip: {
                valueSuffix: '' //  km/h
            }
        }]
    }
}
// Recent camapigns - gaguage - 01
const recentCampaignsChart2 = {
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: null,
    },

    title: {
        text: null
    },
    background: null,
    credits: {
        enabled: false
    },
    plotOptions: {
        gauge: {
            dataLabels: {
                enabled: true,
                useHTML: true,
                formatter: function () {
                    // var mbps = this.y;
                    // return '<span class="rpGaugeLabel" style="text-align:center;display:block;">' + mbps + '<span style="font-size: 13px;text-align:center;display:block;">Warming up</span></span>';
                    return '<span class="rpGaugeLabel font-xs" style="text-align:center;display:block;"><span style="font-family: Muktamedium;text-align:center;display:block;">Achieved</span></span>';
                },
                style: {
                    fontFamily: 'Muktaregular',
                    fontWeight: '300',
                    fontSize: '22px',
                    textShadow: '0',
                    color: '#585858'
                },
                borderColor: 'transparent',
            },
            dial: {
                radius: '75%',
                backgroundColor: '#fd2d32',
                borderWidth: 0,
                baseWidth: 5,
                topWidth: 1,
                baseLength: '40%', // of radius
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: '#b8b8b8',
                radius: 7
            }
        }
    },
    pane: [
        { startAngle: -90, endAngle: 90, center: ['50%', '50%'], background: null, },
        { startAngle: -100, endAngle: 100, center: ['50%', '65%'], background: null }
    ],
    tooltip: {
        enabled: true,
        // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    // the value axis
    yAxis: [
        {
            min: 0,
            max: 100,
            lineColor: null,
            minorTickInterval: 'auto',
            minorTickWidth: 0,
            minorTickLength: 0,
            minorTickPosition: 'outside',
            minorTickColor: '#fff',
            tickPixelInterval: '100',
            tickWidth: 0,
            tickLength: 0,
            tickPosition: 'inside',
            tickColor: '#fff',
            labels: {
                step: 4,
                distance: -2,
                useHTML: true,
                style: {
                    fontFamily: 'Muktaregular',
                    fontWeight: '400',
                    fontSize: '14px',
                    textShadow: '0',
                    color: '#b8b8b8'
                }
            },
            title: {
                text: null
            },
            plotBands: [
                { from: 0, to: 20, color: '#ff5757', innerRadius: '88%', outerRadius: '105%', },
                { from: 20, to: 40, color: '#fe9041', innerRadius: '88%', outerRadius: '105%' },
                { from: 40, to: 60, color: '#e7cb60', innerRadius: '88%', outerRadius: '105%' },
                { from: 60, to: 80, color: '#b2d942', innerRadius: '88%', outerRadius: '105%' },
                { from: 80, to: 100, color: '#99cc01', innerRadius: '88%', outerRadius: '105%' },
                { from: 0, to: 100, color: '#e7f2f8', innerRadius: '105%', outerRadius: '113%' }
            ]
        },
        {
            pane: 1,
            min: 0,
            max: 100,
            labels: { enabled: false },
            tickWidth: 0,
            tickLength: 0,
            minorTickWidth: 0,
            minorTickLength: 0,
            lineWidth: 0
        }
    ],

    series: [{
        name: 'Achieved',
        data: [82],
        tooltip: {
            valueSuffix: '' //  km/h
        }
    }]
}
// Recent camapigns - gaguage - 03
const recentCampaignsChart3 = {
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: null,
    },

    title: {
        text: null
    },
    background: null,
    credits: {
        enabled: false
    },
    tooltip: {
        enabled: true,
        // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
        gauge: {
            dataLabels: {
                enabled: true,
                useHTML: true,
                formatter: function () {
                    // var mbps = this.y;
                    // return '<span class="rpGaugeLabel" style="text-align:center;display:block;">' + mbps + '<span style="font-size:13px;text-align:center;display:block;">Warming up</span></span>';
                    return '<span class="rpGaugeLabel font-xs" style="text-align:center;display:block;"><span style="font-family: Muktamedium; text-align:center;display:block;">Over achieved</span></span>';
                },
                style: {
                    fontFamily: 'Muktaregular',
                    fontWeight: '300',
                    fontSize: '22px',
                    textShadow: '0',
                    color: '#585858'
                },
                borderColor: 'transparent',
            },
            dial: {
                radius: '75%',
                backgroundColor: '#fd2d32',
                borderWidth: 0,
                baseWidth: 5,
                topWidth: 1,
                baseLength: '40%', // of radius
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: '#b8b8b8',
                radius: 7
            }
        }
    },
    pane: [
        { startAngle: -90, endAngle: 90, center: ['50%', '50%'], background: null, },
        { startAngle: -100, endAngle: 100, center: ['50%', '65%'], background: null }
    ],

    // the value axis
    yAxis: [
        {
            min: 0,
            max: 100,
            lineColor: null,
            minorTickInterval: 'auto',
            minorTickWidth: 0,
            minorTickLength: 0,
            minorTickPosition: 'outside',
            minorTickColor: '#fff',
            tickPixelInterval: '100',
            tickWidth: 0,
            tickLength: 0,
            tickPosition: 'inside',
            tickColor: '#fff',
            labels: {
                step: 4,
                distance: -2,
                useHTML: true,
                style: {
                    fontFamily: 'Muktaregular',
                    fontWeight: '400',
                    fontSize: '14px',
                    textShadow: '0',
                    color: '#b8b8b8'
                }
            },
            title: {
                text: null
            },
            plotBands: [
                { from: 0, to: 20, color: '#ff5757', innerRadius: '88%', outerRadius: '105%', },
                { from: 20, to: 40, color: '#fe9041', innerRadius: '88%', outerRadius: '105%' },
                { from: 40, to: 60, color: '#e7cb60', innerRadius: '88%', outerRadius: '105%' },
                { from: 60, to: 80, color: '#b2d942', innerRadius: '88%', outerRadius: '105%' },
                { from: 80, to: 100, color: '#99cc01', innerRadius: '88%', outerRadius: '105%' },
                { from: 0, to: 100, color: '#e7f2f8', innerRadius: '105%', outerRadius: '113%' }
            ]
        },
        {
            pane: 1,
            min: 0,
            max: 100,
            labels: { enabled: false },
            tickWidth: 0,
            tickLength: 0,
            minorTickWidth: 0,
            minorTickLength: 0,
            lineWidth: 0
        }
    ],

    series: [{
        name: 'Over achieved',
        data: [100],
        tooltip: {
            valueSuffix: '' //  km/h
        }
    }]
}
// Leads acquisition -- pie
const leadsGeneratedChart = data => {

    const _colors = {
        E: ch_emailColor,
        Q: ch_qrColor,
        L: ch_linkedinColor,
        P: ch_pinterestColor,
        F: ch_facebookColor,
        T: ch_twitterColor,
        WA: ch_whatsAppColor,
        M: ch_mobileColor,
    }
    const _channelName = {
        E: 'Email',
        Q: 'QR code',
        L: 'LinkedIn',
        P: 'Pinterest',
        F: 'Facebook',
        T: 'Twitter',
        WA: 'WhatsApp',
        M: 'SMS',
    }

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:,.0f}%</b><br/>'
        },
        // plotOptions: {
        //     variablepie: {
        //         dataLabels: {
        //             useHTML: false,
        //             distance: 25,
        //             formatter: function () {

        //                 if (this.color === ch_googleColor) {
        //                     return ('<div class="pieScatterDBContainer googleplus"><i class="miniIcons mi-googleplus"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_emailColor) {
        //                     return ('<div class="pieScatterDBContainer email"><i class="icon-benchmark-scale-small pie-chart-radius pie-email "></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_qrColor) {
        //                     return ('<div class="pieScatterDBContainer qrcode"><i class="icon-link-down-medium pie-chart-radius pie-qr "></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_pinterestColor) {
        //                     return ('<div class="pieScatterDBContainer pinterest"><i class="miniIcons mi-pinterest pie-chart-radius pie-pinterest "></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_facebookColor) {
        //                     return ('<div class="pieScatterDBContainer facebook"><i class="miniIcons mi-facebook pie-chart-radius pie-facebook"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_twitterColor) {
        //                     return ('<div class="pieScatterDBContainer twitter"><i class="icon-service-catalogue-large pie-chart-radius pie-twitter"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_whatsAppColor) {
        //                     return ('<div class="pieScatterDBContainer"><i class="icon-shrink-large pie-chart-radius pie-whatsapp"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }
        //                 else if (this.color === ch_mobileColor) {
        //                     return ('<div class="pieScatterDBContainer"><i class="icon-like-large pie-chart-radius pie-mobile"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
        //                 }

        //             },
        //         }
        //     }
        // },
        series: [
            {
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",

                // minPointSize: 10,
                // startAngle: 180,
                // zMin: 0,
                name: 'Leads acquisition',
                data: data.map(item => {
                    return { name: _channelName[item.chanelImage] || '', color: _colors[item.chanelImage], y: item.percentageScore || 0, z: item.percentageScore || 0 }
                }),
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                },
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(255, 255, 255, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }
}
const _colors = {
    E: ch_emailColor,
    Q: ch_qrColor,
    S: ch_smsColor,
    L: ch_linkedinColor,
    P: ch_pinterestColor,
    F: ch_facebookColor,
    T: ch_twitterColor,
    W: ch_whatsAppColor
}
// Channel performance -- Column
const channelPerformanceChart = data => {
    return {
        chart: {
            type: 'column',
        },
        xAxis: {
            title: { text: '', },
            categories: data?.categories,
        },
        plotOptions: { column: { stacking: false } },
        tooltip: { shared: false },
        yAxis: {
            title: { text: '', },
        },
        series: data?.series?.map((item, index) => {
            return {
                name: item.name || "",
                color: _colors[item.name.charAt(0)],
                data: item.datas || [],
                legendIndex: index
            }
        })
    }
}
// Audience behavior - bubble
const audienceBehaviorChart = data => {

    let arry = []

    if (data?.sundayRange) {
        arry = [
            { name: 'Wed', data: [{ name: "Wed", value: parseFloat(data?.wednesdayRange) || 0 }], color: ch_wednesday, index: 0 },
            { name: 'Tue', data: [{ name: "Tue", value: parseFloat(data?.tuesdayRange) || 0 }], color: ch_tuesday, index: 0 },
            { name: 'Mon', data: [{ name: "Mon", value: parseFloat(data?.mondayRange) || 0 }], color: ch_monday, index: 0 },
            { name: 'Sat', data: [{ name: "Sat", value: parseFloat(data?.saturdayRange) || 0 }], color: ch_saturday, index: 0 },
            { name: 'Thu', data: [{ name: "Thu", value: parseFloat(data?.thursdayRange) || 0 }], color: ch_thursday, index: 7 },
            { name: 'Sun', data: [{ name: "Sun", value: parseFloat(data?.sundayRange) || 0 }], color: ch_sunday, index: 0 },
            { name: 'Fri', data: [{ name: "Fri", value: parseFloat(data?.fridayRange) || 0 }], color: ch_friday, index: 0 },
        ]
    } else {
        arry = [
            { name: 'Morning', data: [{ name: "Morning", value: parseFloat(data?.morning | 0) }], color: ch_wednesday, index: 0 },
            { name: 'E.Morning', data: [{ name: "E.Morning", value: parseFloat(data?.earlyMorning | 0) }], color: ch_tuesday, index: 0 },
            { name: 'After noon', data: [{ name: "After noon", value: parseFloat(data?.afternoon | 0) }], color: ch_monday, index: 0 },
            { name: 'Evening', data: [{ name: "Evening", value: parseFloat(data?.evening | 0) }], color: ch_saturday, index: 0 },
            { name: 'Night', data: [{ name: "Night", value: parseFloat(data?.night | 0) }], color: ch_thursday, index: 0 }
        ]
    }

    return {
        chart: {
            type: 'packedbubble',
            height: 320
        },
        title: {
            text: ''
        },
        tooltip: {
            useHTML: true,
            pointFormat: '<b>{point.name}:</b> {point.value}',
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    useHTML: true,

                    format: '<div class="bubbleChartInsideContainer"><span class="bubbleChartCount">{point.value}<small>%</small></span><span class="bubbleChartName">{series.name}</span</div>',

                    style: {
                        color: '#ffffff',
                        textOutline: 'none',
                        fontSize: '14px',
                        fontWeight: 'normal'
                    }
                }
            },
            packedbubble: {
                minSize: '80px',
                maxSize: '130px',
                zMin: 0,
                zMax: 100,
                draggable: false,
                marker: {
                    fillOpacity: 1 // 0.90
                },
                states: {
                    inactive: { opacity: 1 },
                    hover: { enabled: false }
                },
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.10,
                    friction: 0.20,
                    enableSimulation: false
                },
                dataLabels: {
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: -1
                    }
                }
            }
        },
        legend: { enabled: false },
        series: arry
    }
}
