import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { ProgressQualityBar } from "Components/ProgressSteps/ProgressSteps";
import { RTabbar } from "Components/RChart/RTabbar";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import CampaignHeader from "../../Components/CampaignHeader";
import { RSCheckbox } from "Components/RSInputs";
import ApprovalStaus from "./Components/ApprovalStatus";
import Content from "./Components/Content";
import List from "./Components/List";
import PredictiveAnalytics from "./Components/PredictiveAnalytics";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { CustomPopup } from 'Components/DateRangePicker/ScheduleCustomPopup';
import { RSPPophover } from "Components/Tooltip";
import ApprovalCapmaign from "./Components/ApprovalCampaign";
import { RSDropdownList } from "Components/RSDropdowns";
import { withRouter } from "react-router-dom";
import { connectServer } from "Helper/Network/networkHandler";
import { GET_CAMPAIGN_ANALYZE_LIST, GET_CAMPAIGN_HEADER, MDC_CANVAS_URL } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { getAuthUserId, getLsMasterDataDropdown, getServerConfig, getUserData } from "Helper/Utils/Utils";
import * as skeleton from "Components/Skeleton/Skeleton";

const defaultValue = new Date();
var progressStepsField = [
  {
    status: 'complete',
    steps: '1',
    steps_title: 'Plan'
  },
  {
    status: 'complete',
    steps: '2',
    steps_title: 'Create'
  },
  {
    status: 'inProcess',
    steps: '3',
    steps_title: 'Analyze & execute'
  }
]

class CampaignCompletion extends React.Component {

  static contextType = authContext

  state = {
    campaignHeader: {
      name: "",
      endDate: "",
      type: "",
      goal: "",
      startDate: ""
    },
    tabIndex: 0,
    isFrequencyModal: false,
    isSupperssionModal: false,
    editTimeZone: false,
    isSendRecommendation: true,
    isDaylightSaving: false,
    timeZone: null,
    timeZoneData: [],
    campaignAnalyzeList: {},
    channelDetails: [],
    editClickOff: false,
    tabData: [],
    campaignId: null,
    campaignType: "",
    campaignStatus: 0,
    channelTypes: [],
    noDataError: false
  };

  componentDidMount = (props) => {
    window.scrollTo(0, 0);
    this.getCampaignAnalyzeList()
    if (this.props.history.location && this.props.history.location.state) {
      const state = this.props.history.location.state
      const { campaignHeader } = state
      let channelTypes = []
      this.props.history.location.state.channelType.map((item) => {
        if (item.toLowerCase() === 'email') {
          channelTypes.push({ text: 'Email', channelId: 1 })
        }
        else if (item.toLowerCase() === 'sms') {
          channelTypes.push({ text: 'SMS', channelId: 2 })
        }
        else if (item.toLowerCase() === 'whatsapp') {
          channelTypes.push({ text: 'WhatsApp', channelId: 21 })
        }
        else if (item.toLowerCase() === 'qr') {
          channelTypes.push({ text: 'QR Code', channelId: 3 })
        }
      })
      this.setState({ campaignHeader: campaignHeader, tabData: channelTypes, campaignType: "S" })
    } else {
      this.getCampaignHeader();

    }
  }

  getCampaignHeader = () => {
    const authResult = new URLSearchParams(window.location.search);
    let campId = window.atob(authResult.get("CampaignId"));
    const params = {
      userId: getAuthUserId(),
      campaignId: campId,
    };
    this.setState({ campaignId: campId });
    connectServer({
      path: GET_CAMPAIGN_HEADER,
      params: params,
      retryTime: 5000,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (res?.data) {
            const _campaignHeader = {
              name: window.atob(res.data.campaignName),
              startDate: res.data.startDate,
              endDate: res.data.endDate,
              goal: res.data.primaryTargetCode === "E"
                ? "Engagement"
                : res.data.primaryTargetCode === "R"
                  ? "Reach"
                  : "Conversion",
              type: res.data.attributeName
            }
            this.setState({ campaignHeader: _campaignHeader, campaignType: res.data.campaignType });
          }
        }
      },
      fail: (err) => { },
    });
  }

  getCampaignAnalyzeList = () => {
    const _state = this.props.history.location.state
    let campaignId;
    if (!!_state) {
      campaignId = _state.campaignId;
    } else {
      let authResult = new URLSearchParams(window.location.search);
      campaignId = window.atob(authResult.get("CampaignId"));
    }
    const params = {
      "campaignId": campaignId
    }
    this.setState({ campaignId: campaignId })
    this.setState({
      noDataError: false,
    })
    connectServer({
      path: GET_CAMPAIGN_ANALYZE_LIST,
      params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status && res.data.channelDetails.length > 0) {
          let channelDetails = res.data.channelDetails;
          let channelTypes = []
          let channels = [];
          channelDetails.map((item) => {
            channels.push(item.channelName);
            if (item.channelName.toLowerCase() === 'email') {
              channelTypes.push({ text: 'Email', channelId: 1 })
            }
            else if (item.channelName.toLowerCase() === 'sms') {
              channelTypes.push({ text: 'SMS', channelId: 2 })
            }
            else if (item.channelName.toLowerCase() === 'whatsapp') {
              channelTypes.push({ text: 'WhatsApp', channelId: 21 })
            }
            else if (item.channelName.toLowerCase() === 'qrcode') {
              channelTypes.push({ text: 'QR Code', channelId: 21 })
            }
          })
          if (channels.length > 1) {
            channels.sort((a, b) => {
              let firstOne = a.toLowerCase(),
                secondOne = b.toLowerCase();

              if (firstOne < secondOne) {
                return -1;
              }
              if (firstOne > secondOne) {
                return 1;
              }
              return 0;
            });
          }
          this.setState({
            campaignAnalyzeList: res.data,
            channelDetails: res.data.channelDetails,
            tabData: channelTypes,
            campaignStatus: res.data.campaignStatusID,
            channelTypes: channels
          })
          this.getTimeZoneList()
        } else {
          this.setState({
            noDataError: true,
          })
        }
      },
      fail: err => {
        this.setState({
          noDataError: true,
        })
      }
    })
  }

  getTimeZoneList = () => {
    const data = getLsMasterDataDropdown()
    let filterTimezone = null
    filterTimezone = data.timeZone.filter(
      (item) => item.timezoneId === this.state.channelDetails[this.state.tabIndex]?.timezoneId
    )[0];
    this.setState({ timeZoneData: data.timeZone, timeZone: filterTimezone })
  }

  backToCanvas = (campid) => {
    const sessionStr = localStorage.getItem('session')
    const sessionObject = JSON.parse(sessionStr)
    const accessToken = sessionObject ? sessionObject.accessToken : null
    const campId = window.btoa(campid)
    let fromEnvi = window.location.href.includes("app") ? "app" : window.location.href.includes("sit") ? "sit" : "run";
    window.location.href = getServerConfig() + MDC_CANVAS_URL + "?CampId=" + campId + '&accessToken=' + encodeURIComponent(accessToken) + '&from=' + fromEnvi
  }


  render() {
    return (
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <h1>Communication analysis</h1>
          <div className="marginY20">
            <CampaignHeader progressSteps={this.state.channelDetails.length > 0 ? progressStepsField : null} header={this.state.campaignHeader} type="editplan" editTooltipText="Edit communication" buttonClicked={(value) => {
              if (value === 1) {
                if (this.state.campaignType === "S") {
                  this.props.history.push({
                    pathname: `/communication/create-communication`,
                    state: {
                      mode: "edit",
                      campaignName: this.state.campaignHeader.name,
                      editClickOff: this.state.editClickOff,
                      status: this.props.history.location.state.status,
                      Channeltype: this.state.channelTypes,
                      campaignId: this.state.campaignId,
                      channelId: parseInt(this.state.channelDetails[this.state.tabIndex]?.channelID, 10),
                      index: this.state.tabIndex,
                      campaignHeader: this.state.campaignHeader,
                      CampaignType: this.state.campaignType
                    }
                  });
                } else {
                  this.backToCanvas(this.state.campaignId)
                }
              }
            }} />
          </div>

          {
            this.state.channelDetails.length > 0
              ? <>
                <div>
                  <RTabbar
                    defaultSelectedItem={this.state.tabIndex}
                    defaultClass="tabDefault"
                    dynamicTab="mini float-end"
                    activeClass="tabDefault active"
                    tabData={this.state.tabData}
                    callBack={(item, index) => {
                      this.setState({ tabIndex: index });
                    }}
                  />
                </div>

                <div className="clearfix"></div>

                <ProgressQualityBar progressSteps={analysisSteps}></ProgressQualityBar>

                <Row className="marginT30">
                  <Col md={6}>
                    <List
                      onChangeIsOpen={(value) => {
                        this.setState({ isSupperssionModal: value });
                      }}
                      data={this.state.channelDetails.length > 0 && this.state.channelDetails[this.state.tabIndex] || {}}
                    ></List>
                  </Col>
                  <Col md={6}>
                    <Content
                      campaignType={this.state.campaignType}
                      data={this.state.channelDetails.length > 0 && this.state.channelDetails[this.state.tabIndex] || {}}
                    ></Content>
                  </Col>
                </Row>

                <Row>
                  {/* <Col md={6}>
                    <PredictiveAnalytics></PredictiveAnalytics>
                  </Col> */}
                  <Col md={12}>
                    {
                      <ApprovalStaus
                        campaignType={this.state.campaignType}
                        data={this.state.channelDetails.length > 0 && this.state.channelDetails[this.state.tabIndex] || {}}
                      // data={this.state.channelDetails[this.state.tabIndex]?.rfa}
                      ></ApprovalStaus>
                    }
                    {/* <div className="portlet-container"> */}
                    {/* <div className="portlet-header">
                      <h3>Schedule</h3>
                      </div> */}
                    {/* <Row className="custom-column">
                      <Col md={3}>
                        <label className="label-row">Enter the date and time</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          <div className="rsptimer-picker">
                            <DateTimePicker
                              value={this.state.channelDetails[this.state.tabIndex]?.jobScheduleDate !== ""
                                ? new Date(this.state.channelDetails[this.state.tabIndex]?.jobScheduleDate)
                                : ""}
                              format={"dd MMM, yyyy HH:mm"}
                              popup={CustomPopup}
                              onChange={(event) => {
                                this.setState({
                                  schedule: event.target.value,
                                });
                              }} />
                          </div>
                        </div>
                        <Row className="marginT5">
                          <Col md="8">
                            <div className="d-flex align-items-center marginB30">
                              <RSCheckbox
                                className="marginB0"
                                lbl="Use send time recommendation"
                                checked={this.state.isSendRecommendation}
                                cb={(value) => { this.setState({ isSendRecommendation: !this.state.isSendRecommendation }) }}
                              />
                              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                            </div>
                          </Col>
                          {this.state.editTimeZone === false ?
                            <Col>
                              <div className="float-end">
                                <label>{!!this.state.timeZone &&
                                  this.state.timeZone.gmtOffset}
                                </label>
                                <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                                  this.setState({ editTimeZone: true })
                                }} />
                              </div>
                            </Col> : null}
                        </Row>
                      </Col>
                      <Col md={3}>
                        <RSPPophover text="Get the list approved by the concerned person." position="top">
                          <i className="icon-exclamatory-small icons-md red cursor-pointer" />
                        </RSPPophover>
                      </Col>
                    </Row> */}
                    {/* {this.state.editTimeZone ?
                    <Row className="custom-column pt0">
                      <Col md={3}>
                        <label className="label-row">Select time zone</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          <RSDropdownList
                            className="marginB0"
                            data={this.state.timeZoneData || []}
                            value={this.state.timeZone}
                            defaultItem={{
                              timezoneId: 0,
                              timezoneName: "-- Select time zone --"
                            }}
                            textField='timezoneName'
                            dataItemKey='timezoneId'
                            onChange={(e) => {
                              this.setState({ timeZone: e.target.value });
                            }} />
                        </div>
                        <Row className="marginT5">
                          <Col md="8">
                            <div className="d-flex align-items-center">
                              <RSCheckbox
                                className="marginT0  click-off"
                                lbl="Daylight saving"
                                checked={this.state.isDaylightSaving}
                                cb={(value) => { this.setState({ isDaylightSaving: !this.state.isDaylightSaving }) }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row> : null} */}
                    {/* <hr style={{ borderTop: "1px solid #f7dada", marginBottom: "30px" }}></hr> */}
                    {/* <Row className="custom-column">
                      <div className="portlet-header">
                      <h3>Approval status</h3>
                      </div>
                      <ApprovalCapmaign />
                      <div className="portlet-body paddingX15">
                        <div className="tab-content p-tabs-content-1">
                          <div className="tab-pane active">
                            <ApprovalCapmaign />
                          </div>
                        </div>
                      </div>
                      <ApprovalStaus></ApprovalStaus>
                    </Row> */}
                    {/* </div> */}
                  </Col>
                </Row>

                <div className="btn-container d-flex justify-content-end mt0">
                  <RSSecondaryBtn onClick={() => {
                    this.props.history.push({ pathname: `/communication` })
                  }}>Cancel</RSSecondaryBtn>
                  <RSPrimaryBtn onClick={() => {
                    this.props.history.push({
                      pathname: `/communication/confirm-payment`,
                      state: {
                        mode: "edit",
                        campaignId: this.state.campaignId,
                        campaignHeader: this.state.campaignHeader,
                        channelType: this.state.channelTypes,
                        status: this.state.campaignType === "S" ? this.props.history.location.state.status : this.state.campaignStatus,
                        campaignType: this.state.campaignType
                      }
                    });
                  }}>Next</RSPrimaryBtn>
                </div>
              </>
              : <>
                <skeleton.CommunicationGallerySkeleton isError={this.state.noDataError} text={"Channels not available for this communication."} />
                {/* <div className="alert alert-danger">
                  <span className="icon">
                    <i className="icon-sd-circle-exclamatory-medium" />
                  </span>
                  Channels not available for this communication.
                </div> */}
              </>
          }

        </Container>
      </LayoutPageWrapper>
    );
  }
}

export default withRouter(CampaignCompletion);
const analysisSteps = [
  {
    status: "complete",
    icons: "icon-sd-tick-large icons-lg green",
    steps_title: "List quality",
  },
  {
    status: "complete",
    icons: "icon-sd-tick-large icons-lg green",
    steps_title: "Content analysis",
  }
];
// const tabData = [
//   { id: 1001, text: "Email" },
//   { id: 1002, text: "SMS" },
//   // { id: 1003, text: "WhatsApp" },
//   // { id: 1004, text: "QR" },
// ];
