import React, { useState } from 'react';
import { ColorGradient } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";

const RSColorGradient = (props) => {
    const [value, setValue] = useState("rgba(195, 0, 47, 1)")
    return (
        <>
            <Popup anchor={props.anchor.current} show={props.show} popupClass="popup-content popup-color-picker">

                <ColorGradient defaultValue={value} onChange={(event) => {
                    setValue(event.value)
                }}
                    onHexChange={(hex, value, event) => {

                    }} />
                <div className="btn-container d-flex justify-content-end">

                    <RSSecondaryBtn onClick={() => {
                        props.cancelBtnClicked()
                    }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn onClick={() => {
                        props.applyBtnClicked(value)
                    }}>Apply</RSPrimaryBtn>

                </div>
            </Popup>
        </>

    )
}
export default RSColorGradient;