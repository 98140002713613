import React, { useContext, useState } from "react"
import { useHistory, Link } from "react-router-dom";
import { InputGroup } from "react-bootstrap"
import { RSCheckbox, RSInput2, RSTextInputWithIcon } from "Components/RSInputs"
import { RSPrimaryBtn } from "Components/RSButtons";
import { getLowerCasedNoSpaceString, isEmpty, validateEmail } from "Helper/Utils/Utils";
import { MAX_EMAIL, MAX_ANSWER } from 'Helper/Constants/inputLimit'
import { IS_EMAIL_EXIST, SIGN_UP } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { ACCEPT_TERMS, ENTER_ANSWER, ENTER_CORRECT_ANSWER, BUSINESS_EMAIL, ENTER_VALID_BUSINESS_EMAIL, MAXIMUM_LIMIT, ENTER_BUSINESS_EMAIL } from "Helper/Constants/validationMessage";
import authContext from "Helper/StateHandler/auth-context";
import emailIcon from 'Assets/Images/icons/icon-email.svg'

export const NewUser = props => {

    const context = useContext(authContext)

    const [emailId, setEmailId] = useState("")
    const [sum, setSum] = useState("")
    const [checked, setChecked] = useState(false)
    const [a, setA] = useState(getRandomNumber())
    const [b, setB] = useState(getRandomNumber())
    const [isEMailExist, setIsEmailExist] = useState(null)
    // const [emailErrMsg, setEmailErrMsg] = useState("")
    const [errorMessage, setErrorMessage] = useState({
        email: null,
        answer: null,
        agree: null
    })

    const checkEmailExistInServer = () => {
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            path: IS_EMAIL_EXIST,
            params: { emailId: emailId },
            retry: 2,
            retryTime: 5000,
            ok: res => {
                setIsEmailExist(res.status)
                if (res.status) {
                    var errMsg = { ...errorMessage }
                    errMsg.email = res.message
                    setErrorMessage(errMsg)
                } else {
                    context.globalStateData.setSourceTypeData(res?.data?.sourceType !== "" ? res?.data?.sourceType : "D")
                    if (res.data !== null) {
                        if (res.data.isvalidversium) {
                            let tempData = { ...context.globalStateData.getKeyContactInfoData }
                            tempData.contactInfo.isFromVersium = res.data.isvalidversium
                            context.globalStateData.setKeyContactInfoData(tempData);
                        }
                    } else {
                        let message = res?.message
                        var errMsg = { ...errorMessage }
                        errMsg.email = message
                        setErrorMessage(errMsg)
                    }
                }
            },
            fail: err => {
                var errMsg = { ...errorMessage }
                errMsg.email = "Unexpected happened. Try again later"
                setErrorMessage(errMsg)
            }
        })
    }

    /**
     * View data binding
     */
    return (
        <div className="form-login">
            <div>
                <RSTextInputWithIcon
                    ob={val => {
                        if (val.length > 0) {
                            const _emailId = getLowerCasedNoSpaceString(val)
                            if (validateEmail(_emailId)) {
                                setErrorMessage({ ...errorMessage, ...{ email: null } })
                            } else {
                                setErrorMessage({
                                    ...errorMessage,
                                    ...{ email: ENTER_VALID_BUSINESS_EMAIL }
                                })
                                return
                            }
                            checkEmailExistInServer()
                        }
                    }}
                    of={status => setIsEmailExist(null)}
                    name="email"
                    icon={"icon-sd-email-medium icons-md"}
                    required={true}
                    // img={emailIcon}
                    ph={ENTER_BUSINESS_EMAIL}
                    val={emailId}
                    max={MAX_EMAIL}
                    className={isEMailExist === null
                        ? "success-del"
                        : !isEMailExist ? "success-del" : "warning-del"}
                    errorMessage={errorMessage.email}
                    cb={t => {
                        let _emailId = getLowerCasedNoSpaceString(t)
                        setErrorMessage({ ...errorMessage, ...{ email: null } })
                        if (_emailId.length <= 100) {
                            setEmailId(_emailId)
                        } else {
                            setErrorMessage({ ...errorMessage, ...{ email: MAXIMUM_LIMIT } })
                        }
                        // setEmailId(t)
                        // if (validateEmail(t)) {
                        //     setErrorMessage({ ...errorMessage, ...{ email: null } })
                        // } else {
                        //     setErrorMessage({ ...errorMessage, ...{ email: ENTER_VALID_BUSINESS_EMAIL } })
                        //     return
                        // }
                    }
                    }
                />
                <div className="position-relative">
                    {errorMessage.answer && <div className="validation-message">{errorMessage.answer}</div>}
                    <InputGroup className="validateCount mSpace">
                        <InputGroup>
                            <InputGroup.Text> {`${a} + ${b} = `}</InputGroup.Text>
                        </InputGroup>
                        <RSInput2
                            ph={ENTER_ANSWER}
                            val={sum}
                            required
                            max={MAX_ANSWER}
                            cb={(value) => {
                                let _sum = getLowerCasedNoSpaceString(value)
                                setErrorMessage({ ...errorMessage, ...{ answer: null } })
                                if (_sum.length > 2) {
                                    let some = parseInt(_sum, 10)
                                    setErrorMessage({ ...errorMessage, ...{ answer: MAXIMUM_LIMIT } })
                                    return
                                }

                                if (parseInt(value, 10)) {
                                    let some = parseInt(value, 10)
                                    setSum(some)
                                    setErrorMessage({ ...errorMessage, ...{ answer: null } })
                                } else {
                                    setSum("")
                                }
                            }}
                            ob={value => {
                                if (parseInt(value, 10)) {
                                    let some = parseInt(value, 10)
                                    setSum(some)
                                    if (some === a + b) {
                                        setErrorMessage({ ...errorMessage, ...{ answer: null } })
                                    } else {
                                        if (isEmpty(value)) {
                                            setErrorMessage({
                                                ...errorMessage,
                                                ...{ answer: ENTER_ANSWER }
                                            })
                                        } else {
                                            setErrorMessage({
                                                ...errorMessage,
                                                ...{ answer: ENTER_CORRECT_ANSWER }
                                            })
                                            setA(getRandomNumber())
                                            setB(getRandomNumber())
                                            setSum("")

                                        }
                                        return
                                    }
                                } else {
                                    setSum("")
                                }
                            }}
                        />
                    </InputGroup>
                </div>

                <div className="position-relative checkbox-alert">
                    {errorMessage.agree && <div className="validation-message">{errorMessage.agree}</div>}
                    <RSCheckbox
                        className="font-xsm marginB19"
                        checked={checked}
                        cb={status => {
                            setChecked(status)
                            if (!status) {
                                setErrorMessage({ ...errorMessage, ...{ agree: ACCEPT_TERMS } })
                                return
                            } else {
                                setErrorMessage({ ...errorMessage, ...{ agree: null } })
                            }
                        }
                        }
                        lbl="I agree to the "
                    // labelClass="sub-dashed-border"
                    >
                        <Link
                            to={{
                                pathname: 'https://marketingstar.io/terms-and-conditions.html'
                            }}
                            target='_blank'
                            className='text-orange'
                        >
                            Terms & Conditions.
                        </Link>
                    </RSCheckbox>
                </div>

                <RSSignUpButton
                    email={emailId}
                    checked={checked}
                    a={a}
                    b={b}
                    sum={sum}
                    isEMailExist={isEMailExist}
                    emailErrMsg={errorMessage.email}
                    setErrorMessage={(value) => {
                        setErrorMessage(value);
                    }}
                />
            </div>
        </div>
    )
}

const getRandomNumber = () => {
    let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
    randomNumber = randomNumber / 0x100000000;
    randomNumber = Math.floor((randomNumber * 9) + 1);
    // const number = Math.floor((Math.random() * 9) + 1)
    return randomNumber
}

/**
 * Signup Btn action 
 */
const RSSignUpButton = props => {
    const history = useHistory()
    const context = useContext(authContext)

    /**
     * Request 
     * SignUp User 
     * Email
     */
    const createUser = () => {
        const params = { emailId: props.email }
        connectServer({
            path: SIGN_UP,
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            params,
            ok: res => {
                if (res.status) {
                    context.globalStateData.setUserIdData(res.data.signupId || 0)
                    // history.push({
                    //     pathname: 'thanks-signing-up',
                    //     state: { email: props.email }
                    // })
                    history.push({
                        pathname: 'key-contact-info',
                        state: { email: props.email }
                    })
                }
            },
            fail: error => { }
        })
    }

    const validateParams = () => {
        context.globalStateData.setSelectedDataStatus('keycontact')
        let enable = true;
        let errorMsg = {
            email: null,
            answer: null,
            agree: null
        }
        if (props.email.length === 0) {
            errorMsg.email = ENTER_VALID_BUSINESS_EMAIL
            enable = false;
        } else if (!validateEmail(props.email)) {
            errorMsg.email = ENTER_VALID_BUSINESS_EMAIL
            enable = false;
        } else if (props.isEMailExist || props.isEMailExist === null) {
            errorMsg.email = props.emailErrMsg
            enable = false;
        }
        if (props.sum.length === 0) {
            errorMsg.answer = ENTER_ANSWER;
            enable = false;
        } else if (props.sum !== (props.a + props.b)) {
            errorMsg.answer = ENTER_CORRECT_ANSWER;
            enable = false;
        }
        if (props.checked) {
            errorMsg.agree = null;
        } else {
            errorMsg.agree = ACCEPT_TERMS;
            enable = false;
        }
        if (enable) {
            createUser()
        } else {
            props.setErrorMessage(errorMsg)
        }
    }

    return <RSPrimaryBtn
        className="w-100 marginT10"
        onClick={validateParams}
    >
        Sign Up
    </RSPrimaryBtn>
}

