import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import authContext from "Helper/StateHandler/auth-context";
import ProgressSteps from 'Components/ProgressSteps/ProgressSteps';
import Notification from './Tabs/Notification/Notification';
import MultiDimension from './Tabs/MultiDimension/MultiDimension';
import EventTrigger from './Tabs/EventTrigger/EventTrigger';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RTabbar4 } from 'Components/RChart/RTabbar';
import campaignJson from './CampaignPlan.json'
import campaignNew from './CampaignNew.json'
import { RSPTooltip } from 'Components/Tooltip';
import { getCampaignMaster, setCampaignMaster } from 'Helper/Utils/Utils'
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { GET_CAMPAIGN_ATTRIBUTES_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';

var campaign = {}

const Planning = (props) => {

    let history = useHistory();
    const context = useContext(authContext)

    const [index, setIndex] = useState(-1)
    const [campaignId, setCampaignId] = useState(0)
    const [json, setJson] = useState(null)
    const [tabData, setTabData] = useState(null);
    const [isShowRefresh, setIsShowRefresh] = useState(false);
    // const [loadAttributtes, setLoadAttributes] = useState(context.globalStateData.getCampaignMasterDropdownData);
    const [loadAttributtes, setLoadAttributes] = useState(getCampaignMaster())
    const [isOpenAlertPopup, setIsOpenAlertPopup] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const str = JSON.stringify(tabDataItems)
        setTabData(JSON.parse(str))
        if (loadAttributtes !== null) {
            if (loadAttributtes.productCategorysList.length === 0) {
                setIsOpenAlertPopup(true)
                return
            }
        } else {
            getCampaignMasterData();
        }
        if (history.location.state.mode === 'add' && history.location.state.campaignId === undefined) {
            setJson(campaignNew)
            setIndex(-1);
        } else {
            setJson(campaignJson)
            if (history.location.state.deliveryType.startsWith("S")) {
                setIndex(0);
                const str = JSON.stringify(tabDataItems)
                const newData = JSON.parse(str)
                newData[0].disable = false;
                newData[1].disable = true;
                // newData[2].disable = true;
                setTabData(newData);
            } else if (history.location.state.deliveryType.startsWith("M")) {
                setIndex(1);
                const str = JSON.stringify(tabDataItems)
                const newData = JSON.parse(str)
                newData[0].disable = true;
                newData[1].disable = false;
                // newData[2].disable = true;
                setTabData(newData);
            } else {
                setIndex(2);
                const str = JSON.stringify(tabDataItems)
                const newData = JSON.parse(str)
                newData[0].disable = true;
                newData[1].disable = true;
                // newData[2].disable = false;
                setTabData(newData);
            }

            if (history.location.state.campaignId !== undefined) {
                setCampaignId(history.location.state.campaignId)
            }
        }
    }, []);

    const getCampaignMasterData = () => {

        let params = {
            "campaignId": 0,
        }
        connectServer({
            path: GET_CAMPAIGN_ATTRIBUTES_LIST,
            params: params,
            context: context,
            ok: res => {
                if (res.status) {
                    setLoadAttributes(res.data)
                    if (res?.data?.productCategorysList?.length === 0) {
                        setIsOpenAlertPopup(true)
                        return
                    }
                    context.globalStateData.setCampaignMasterDropdownData(res.data)
                    setCampaignMaster(res.data);
                }
            },
            fail: err => {
            }
        })
    }

    const updateTabState = value => {
        let temp = [...tabData]
        if (value.title === "Single Dimension" && value.value) {
            temp[0].disable = false;
            temp[1].disable = true;
            // temp[2].disable = true;
            setIsShowRefresh(true);
        } else if (value.title === "Multi Dimension" && value.value) {
            temp[0].disable = true;
            temp[1].disable = false;
            // temp[2].disable = true;
            setIsShowRefresh(true);
        } else {
            temp[0].disable = true;
            temp[1].disable = true;
            // temp[2].disable = false;
            setIsShowRefresh(true);
        }
        setTabData(temp);
    }

    const RenderComponent = () => {
        if (json !== null && loadAttributtes !== null) {
            switch (index) {
                case 0:
                    return <Notification
                        json={json}
                        campaignId={campaignId}
                        onDisableChange={value => updateTabState(value)}
                        campaignDetails={history.location.state}
                        attributes={loadAttributtes}
                        onChange={(key, value) => {
                            campaign[key] = value;
                        }} />
                case 1:
                    return <MultiDimension
                        json={json}
                        campaignId={campaignId}
                        onDisableChange={value => updateTabState(value)}
                        campaignDetails={history.location.state}
                        attributes={loadAttributtes}
                        onChange={(key, value) => {
                            campaign[key] = value;
                        }} />
                case 2:
                    return <EventTrigger
                        json={json}
                        campaignId={campaignId}
                        onDisableChange={value => updateTabState(value)}
                        campaignDetails={history.location.state}
                        attributes={loadAttributtes}
                        onChange={(key, value) => {
                            campaign[key] = value;
                        }} />
                default:
                    return null
            }
        }
    }

    const HeadComponent = props => {
        return <div className="header-title d-flex justify-content-between">
            <h1>Communication planning</h1>
            {/* <div className="back-text" onClick={() => history.push(`/communication`)}>
                <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                Back
            </div> */}
        </div>
    }

    const ProgressiveStepsComponent = props => (<div className="campaign-progress-bar">
        <ProgressSteps progressSteps={progressStepsData} />
    </div>)

    const TabbarRowComponent = () => {
        return <div className="campaign-tab-bar even">
            <Row className="custom-column-del smartlink-col-del d-flex align-items-center no-margin">
                <Col md={{ span: 3, offset: 1 }}>
                    <h4 className="marginB0">Delivery method</h4>
                </Col>
                <Col>
                    {tabData === null ? null : <RTabbar4
                        defaultSelectedItem={index}
                        defaultClass="tabDefault"
                        dynamicTab="marginB0 rs-tab-icon campaign-type-tab"
                        activeClass="tabDefault active"
                        tabData={tabData}
                        or={true}
                        callBack={(item, index) => { setIndex(index) }}
                    />}
                </Col>
            </Row>
            {isShowRefresh ?
                <div className="refresh-icon">
                    <RSPTooltip text="Change communication type" position="top">
                        <i className="icon-sd-refresh-medium icons-md blue" onClick={() => {
                            let temp = [...tabData];
                            temp[0].disable = false;
                            temp[1].disable = false;
                            // temp[2].disable = true;
                            setIndex(-1);
                            setTabData(temp);
                            setIsShowRefresh(false);
                        }} />
                    </RSPTooltip>
                </div> : null}

        </div>
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <HeadComponent />
                <div className="portlet-box-theme bgWhite padding0 no-row-margin">
                    <ProgressiveStepsComponent />
                    <TabbarRowComponent />
                    {RenderComponent()}
                    {isOpenAlertPopup &&
                        <RSConfirmAlert
                            title="Please add product categories to continue the communication setup"
                            primaryButtonText="OK"
                            buttonClicked={(value) => {
                                if (value) {
                                    history.push({ pathname: "/preferences/account-settings", state: { index: 2 } })
                                } else {
                                    history.push({ pathname: "/communication" })
                                }
                                setIsOpenAlertPopup(false);
                            }} />
                    }
                </div>
            </Container>
        </LayoutPageWrapper>
    )
}
export default Planning

const tabDataItems = [
    {
        "id": 1001,
        "text": "Single dimension",
        "iconLeft": "icon-sd-communication-sdc-large icons-xl blue d-block marginB10",
        "disable": false,
        "toolTipValue": "Create & execute one-time communications through one or more channels without any follow-ups."
    },
    {
        "id": 1002,
        "text": "Multi dimension",
        "iconLeft": "icon-sd-communication-mdc-large icons-xl blue d-block marginB10",
        "disable": false,
        "toolTipValue": "Execute multi-step, multi-channel communication sequence to retarget audiences."
    }
    // {
    //     "id": 1003,
    //     "text": "Event trigger",
    //     "iconLeft": "icon-trigger-large icons-lg blue d-block marginB10",
    //     "disable": true,
    //     "toolTipValue": "Setup and execute communications triggered by external events"
    // }
]
const progressStepsData = [
    {
        status: 'inProcess',
        steps: '1',
        steps_title: 'Plan'
    },
    {
        status: '',
        steps: '2',
        steps_title: 'Create'
    },
    {
        status: '',
        steps: '3',
        steps_title: 'Analyze & execute'
    }
]