import React, { Component } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuSort } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
// import { ColumnMenu, ColumnMenuCheckboxFilter } from "./Components/ColumnMenu";
import jsonData from "./dynamicList.json";
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { SearchViewAdvanced } from "Components/SearchView/SearchView";
import { RSPTooltip } from "Components/Tooltip";
import DynamicInfo from "./DynamicInfo"
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper"
import { Container } from "react-bootstrap"
import CustomFilter from 'Components/Grid/CustomFilter';
import CustomDateFilter from 'Components/Grid/CustomDateFilter';

const createDataState = (dataState) => {
  let tempArray = []
  tempArray = [...jsonData]
  var val = Object.keys(localStorage).filter(item => item.includes('dlist'))
  if (val.length > 0) {
    tempArray = val.map(item => {
      let ldata = localStorage.getItem(item)
      return tempArray.push(JSON.parse(ldata))
    })

  }
  return {
    result: process(tempArray.reverse().slice(0), dataState),
    dataState: dataState,
  };
};

const DynamicList = (props) => {
  let match = useRouteMatch();
  let history = useHistory();
  return (
    <DynamicList1 history={history} match={match} />
  )
}

class DynamicList1 extends Component {
  initialState = createDataState({
    take: 5,
    skip: 0
  });

  state = {
    result: this.initialState.result,
    dataState: this.initialState.dataState,
    isOpenInfoModal: false,
    isShowDateRangePicker: false,
  };

  dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    this.setState({ result: updatedState.result, dataState: updatedState.dataState });
  };
  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      columnMenuDate: ColumnMenuDate,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? "active"
        : "",
    };
  }
  isColumnActive(field, dataState) {
    return (
      GridColumnMenuFilter.active(field, dataState.filter) ||
      GridColumnMenuSort.active(field, dataState.sort)
    );
  }

  ActionCellComponent = (props) => {
    return (
      <td>
        <div className="camp-icon">
          <ul className="camp-icon-pannel">
            <li>
              <RSPTooltip position="top" text="Edit">
                <i className="icon-sd-pencil-edit-medium icons-md blue"
                  onClick={() => {
                    this.props.history.push({
                      pathname: `audience/new-dynamic-list`,
                      state: {
                        mode: 'edit',
                        listId: props.dataItem.listId
                      }
                    })
                  }}></i>
              </RSPTooltip>
            </li>
            {/* <li><RSPTooltip position="top" text="Approval"><i className="icon-request-approved-medium icons-lg blue"></i></RSPTooltip></li>
            <li><RSPTooltip position="top" text="Duplicate"><i className="icon-sd-duplicate-medium icons-md blue"></i></RSPTooltip></li> */}
            <li><i className="icon-sd-circle-info-medium icons-md blue" onClick={() => { this.setState({ isOpenInfoModal: !this.state.isOpenInfoModal }) }}></i></li>
          </ul>
        </div>
      </td>
    );
  };

  MyCustomCell = (props) => (
    <this.ActionCellComponent
      {...props}
      ishidden={false}
      item={props.item}
      index={props.index}
    />
  );

  closeDateRangePicker = (status) => {
    this.setState({ isShowDateRangePicker: status });
  }

  HeaderView = props => {
    return <>
      <div className="d-flex justify-content-between marginT15 marginB15 padding0">
        <div></div>
        <div className="d-flex align-items-center">
          <RSPDateRangePicker
            isShowDateRangePicker={this.state.isShowDateRangePicker}
            closeDateRangePicker={this.closeDateRangePicker}
            class="marginR15" />
          <SearchViewAdvanced
            closeDateRangePicker={this.closeDateRangePicker}
            cls="marginR15"
            dropdownData={['List name', 'List type', 'Created by']}
          />
          <RSPTooltip placement="top" text="New dynamic list">
            <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer"
              onClick={() => this.props.history.push({ pathname: `${this.props.match.url}/new-dynamic-list`, state: { mode: 'add' } })}></i>
          </RSPTooltip>
        </div>
      </div>
    </>
  }
  render() {
    return (
      <>
        {/* <div className="d-flex justify-content-between marginT15 marginB15">
            <div></div>
            <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer" onClick={() => {this.props.updateIndex(1)}}></i>
        </div> */}
        <this.HeaderView />
        <Grid
          data={this.state.result}
          {...this.state.dataState}
          onDataStateChange={this.dataStateChange}
          sortable={false}
          pageSize={8}
          className="grid-pagenation-del"
          scrollable={"none"}
          messages={{
            itemsPerPage: 'data items per page'
          }}
          pageable={{

            //type: 'input',
            pageSizes: [5, 10, 15],
            previousNext: true,
          }}
        >
          <GridColumn
            // field="name"
            {...this.columnProps("name")}
            // filter="text"
            columnMenu={ColumnMenu}
            title="Name"
          />
          <GridColumn
            // field="createdOn"
            {...this.columnProps("createdOn")}
            // filter="text"
            columnMenu={ColumnMenuDate}
            title="Created on"
          />
          <GridColumn
            field="createdBy"
            filter="text"
            columnMenu={ColumnMenu}
            title="Created by"
          />
          <GridColumn
            field="totalAudience"
            className="text-end"
            filter="text"
            columnMenu={ColumnMenu}
            title="Total audience"
          />
          <GridColumn
            width={100}
            field="campaignLinked"
            className="text-end"
            filter="text"
            columnMenu={ColumnMenu}
            title="Campaign linked"
          />
          {/* <GridColumn field="blastedItems" className="text-end" filter="text" columnMenu={ColumnMenu} title="No.of time blasted" />
          <GridColumn field="avgOpenRate" className="text-end" filter="text" columnMenu={ColumnMenu} title="Avg. open rate" /> */}
          <GridColumn width={100} field="Actions" filterable={false} cell={this.ActionCellComponent} />
        </Grid>

        {this.state.isOpenInfoModal ? (
          <DynamicInfo
            isOpen={this.state.isOpenInfoModal}
            onChangeIsOpen={(value) => {
              this.setState({ isOpenInfoModal: !this.state.isOpenInfoModal });
            }}> </DynamicInfo>
        ) : null}
      </>
    );
  }
}
export default DynamicList;

const ColumnMenu = props => {
  // RSLog("ColumnMenu", props, true)
  return (
    <div>
      {true &&
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          active={true}
          filterable={true}
          hideSecondFilter={true}
          filterUI={CustomFilter}
        />}
    </div>
  )
}
const ColumnMenuDate = props => {
  // RSLog("CustomDateFilter", props, true)
  return (
    <div>
      {true &&
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          active={true}
          filterable={true}
          hideSecondFilter={true}
          filterUI={CustomDateFilter}
        />}
    </div>
  )
}