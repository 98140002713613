import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';

// import RSSkeletonTable from 'Components/RSSkeleton/RSSkeletonTable';

import {
    ColumnMenuCheckboxFilter,
    ColumnMenu,
    isColumnActive,
    PAGER_CONFIG,
    INITIAL_CONFIG,
    userDataState,
} from './constants';

const KendoGrid = ({
    data,
    column,
    settings,
    scrollable,
    pageable,
    isListTable,
    noBoxShadow,
    isDataStateRequired,
    onDataStateChange,
    pageStateChange,
    sortable,
    groupable,
    expandField,
    onExpandChange,
    loading,
    change,
    config,
    flag,
    gridClassName,
}) => {
    const [pageUser, setPageUser] = useState([]);
    const [pageuserGrid, setPageUserGrid] = useState(INITIAL_CONFIG);
    const [pagination, setPagination] = useState(config);
    const scrollSettings = scrollable == 'none' ? 'rs-kendo-fixed-grid' : 'rs-kendo-scrollable-grid';
    const pageConfig = _get(settings, 'total', 0) > 5 ? PAGER_CONFIG : false;
    const pageableSettings = pageable ? pageConfig : false;
    useEffect(() => {
        const filterIcon = document.getElementsByClassName('k-i-more-vertical');
        // const arrowRight = document.getElementsByClassName('k-i-arrow-60-right');
        [...filterIcon].forEach((x) => {
            const element = [...x.classList];
            if (!element.includes('icon-sd-filter-mini')) {
                x.className += ' icon-sd-filter-mini icon-xs';
            }
        });
        // [...arrowRight].forEach((x) => {
        //     const element = [...x.classList];
        //     if (!element.includes('icon-sd-arrow-right-mini')) {
        //         x.className += ' icon-sd-arrow-right-mini icon-xs';
        //     }
        // });
    }, []);

    React.useEffect(() => {
        if (isDataStateRequired) {
            setPageUser(data);
        } else {
            let {
                result,
                dataState: { skip, take },
            } = userDataState(pageuserGrid, data);
            setPageUser(result);
            setPageUserGrid({ skip, take });
            console.log("result:", userDataState(pageuserGrid, data));
        }
    }, [data]);

    const dataStateChange = async (event) => {
        if (flag) {
            setPagination((prevState) => ({
                ...prevState,
                skip: event.dataState.skip,
                take: event.dataState.take,
            }));
            change(event.dataState);
        } else {
            let updatedState = userDataState(event.dataState, data);
            if (isDataStateRequired) {
                await onDataStateChange(event);
            } else {
                setPageUser(updatedState.result);
            }
            setPageUserGrid(updatedState.dataState);
        }
    };
    // const pageStateChangeMethod = async (event) => {
    //     if (isDataStateRequired) {
    //         pageStateChange(event)
    //     }else{
    //         let updatedState = userDataState(event.page, data);
    //         setPageUser(updatedState.result);
    //         setPageUserGrid(updatedState.dataState);
    //     }
    // };

    const columnProps = React.useCallback(
        (field) => ({
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: isColumnActive(field, pageuserGrid) ? 'bg-alert' : '',
        }),
        [pageuserGrid],
    );

    // console.log("data:", data);
    // console.log("pageUser:", pageUser);

    return (
        <div className={`${isListTable ? 'rs-kendo-grid-table' : 'rs-kendo-list-table'} `}>
            <Grid
                data={pageUser}
                onDataStateChange={dataStateChange}
                // onPageChange={pageStateChangeMethod}
                pageable={pageableSettings}
                scrollable={scrollable}
                className={`${noBoxShadow ? 'no-box-shadow' : ''} ${scrollSettings} ${data.length > 5 ? '' : 'mb0'} ${data.length === 0 ? 'noDataAvailable' : ''} ${gridClassName}`}
                sortable={sortable}
                groupable={groupable}
                expandField={expandField}
                onExpandChange={onExpandChange}
                {...pageuserGrid}
                {...settings}
                {...pagination}
                >
                {/* <GridNoRecords>
                    <RSSkeletonTable text message={'No data available'} />
                </GridNoRecords> */}
                {/* filter Types ["text","numeric","boolean","date"]. */}
                {column?.map((column) => {
                    return (
                        <GridColumn
                            key={column.field}
                            {...column}
                            {...columnProps(column.field)}
                            columnMenu={
                                column?.filter && column?.filter !== ''
                                    ? (props) => {
                                          if (column?.filter === 'text') {
                                              return ColumnMenuCheckboxFilter(props, data);
                                          }
                                          return ColumnMenu(props, data);
                                      }
                                    : ''
                            }
                        />
                    );
                })}
            </Grid>
        </div>
    );
};

KendoGrid.defaultProps = {
    gridClassName: '',
    column: [],
    scrollable: 'none',
    pageable: true,
    isListTable: true,
    noBoxShadow: false,
    isDataStateRequired: false,
    onDataStateChange: () => {},
    pageStateChange: () => {},
    loading: false,
    expandField: '',
    sortable: false,
    groupable: false
};
KendoGrid.propTypes = {
    data: PropTypes.array.isRequired,
    column: PropTypes.array,
    settings: PropTypes.object,
    expandField: PropTypes.string,
    filter: PropTypes.string,
    isListTable: PropTypes.bool,
    noBoxShadow: PropTypes.bool,
    onDataStateChange: PropTypes.func,
    pageStateChange: PropTypes.func,
    isDataStateRequired: PropTypes.bool,
    loading: PropTypes.bool,
    sortable: PropTypes.bool,
    groupable: PropTypes.bool
};

export default KendoGrid;
