import React, { Component, useState } from "react";
import { useRouteMatch, useHistory, withRouter } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { TEMPLATE_DATA } from "./constants";
import { RSPTooltip } from "Components/Tooltip";

const TemplateGenerator = () => {
  const history = useHistory();
  let match = useRouteMatch();

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        <div className="header-title d-flex justify-content-between">
          <h1>Choose template builder</h1>
        </div>
        <hr className="blue mb30" />
        <div className="">
          <Row className="">
            {TEMPLATE_DATA.map((item, index) => {
              return (
                <Col
                  md={2}
                  key={index}
                  onClick={() => {
                    history.push(`${item.link}`);

                  }}
                >
                  <div className={`cursor-pointer d-flex flex-column justify-content-center per-listing-box text-center ${item.disable ? "click-off" : ""}`}>
                    <i className={item.icon}>
                      {item.indicate && (
                        <span className="indicator"></span>
                      )}
                    </i>
                    <div>{item.title}</div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </LayoutPageWrapper>
  );
};

export default withRouter(TemplateGenerator);
