import React, { useState } from "react";
import { RTabbar } from "Components/RChart/RTabbar";
import SMS from "./SMS/SMS";
import Whatsapp from './Whatsapp/Whatsapp';

const Messaging = () => {

  const [index, setIndex] = useState(0);
  const tabData = [
    { id: 1001, text: "SMS", iconLeft: "icon-sd-mobile-sms-large icons-lg" },
    // { id: 1002, text: "WhatsApp", iconLeft: "icon-whatsapp-xmedium icons-lg" },
  ];

  const renderComponents = [
    <SMS />,
    <Whatsapp />
  ];

  return (
    <>
      <div className="horizontal-tab-wrapper">
        <RTabbar
          defaultSelectedItem={index}
          defaultClass="tabDefault"
          dynamicTab="rs-tab-icon rs-tab-horizontal"
          activeClass="tabDefault active"
          tabData={tabData}
          callBack={(item, index) => {
            setIndex(index);
          }}
        />
      </div>
      <div className="clearfix"></div>
      <div>{renderComponents[index]}</div>
    </>
  );
};
export default Messaging;
