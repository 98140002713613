import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { RSPrimaryBtn } from 'Components/RSButtons';
import { RSCheckbox } from 'Components/RSInputs';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { Row, Col } from 'react-bootstrap';
import QRCode, { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import React, { useState, useEffect } from 'react';
import { Slider, Switch } from '@progress/kendo-react-inputs';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { GET_SHORTEN_URL } from 'Helper/Constants/endPoint';
import { useContext } from 'react';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';

const QRPreviewNew = ({ register, range, isShow, campaignURL, logo, getBase64Content, webQRURL, generatedQR, getSliderValue, getIsShortCode, isShortCode, isShortURLCreated }) => {
    const context = useContext(authContext)
    const [shortURL, setShortURL] = useState(isShortCode);
    const [isGenerate, setIsGenerate] = useState(false);
    const [type, setType] = useState('');
    const [copyTooltipText, setCopyTooltipText] = useState("Copy URL")

    useEffect(() => {
        getImageBase64()
    }, [campaignURL, range, isShow])



    const qrType = (type) => {
        // if (type === 'PNG') {
        var canvas = document.getElementById('myQR');
        // var caption = document.createElement('caption');
        // caption.innerHTML = value;
        // canvas.appendChild(caption);
        var imgURL = document.createElement('a');
        imgURL.href = canvas.toDataURL();
        imgURL.download = `qr.${type.toLowerCase()}`;
        document.body.appendChild(imgURL);
        imgURL.click();
        document.body.removeChild(imgURL);
        // }
    };

    const getImageBase64 = () => {
        var canvas = document.getElementById('myQR');
        var base64Content = canvas?.toDataURL();
        getBase64Content(base64Content)
    }
    return (
        <div className={`rs-qr-live-preview ${isShow ? '' : 'click-off'}`}>
            {/* <canvas > */}
            <figure className='text-center'>
                <QRCodeCanvas
                    // value={isShortURLCreated ? campaignURL : webQRURL}
                    value={webQRURL}
                    id="myQR"
                    size={200}
                    bgColor={'#ffffff'}
                    // fgColor={'#444072'}
                    fgColor={'#000'}
                    level={'L'}
                    includeMargin={false}
                    style={{
                        width: 190,
                        height: 190
                    }}
                    imageSettings={{
                        src: `${logo}`,
                        x: undefined,
                        y: undefined,
                        height: 30,
                        width: 50,
                        excavate: true,
                    }}
                />
                {
                    shortURL && <RSPTooltip text={isShortURLCreated ? campaignURL : webQRURL} position="top">
                        <span className="short-url-text">{isShortURLCreated ? campaignURL : webQRURL}</span>
                    </RSPTooltip>
                }
                {/* {shortURL && <figcaption>{webQRURL}</figcaption>} */}
            </figure>
            {/* </canvas> */}


            <hr></hr>
            <Row className='mb5'>
                <Col sm={12}>
                    <RSCheckbox
                        lbl={"Short URL :"}
                        name={'short_url'}
                        checked={shortURL}
                        className={"color-primary-blue mb0"}
                        cb={(status) => {
                            setShortURL(!shortURL);
                            getIsShortCode(!shortURL)

                        }}
                    />
                </Col>

            </Row>
            <Row className="">
                <Col sm={10}>
                    <RSPTooltip text={isShortURLCreated ? campaignURL : webQRURL} position="top">
                        <span className='short-url-text'>{isShortURLCreated ? campaignURL : webQRURL}</span>
                    </RSPTooltip>
                    {/* <RSPTooltip text={webQRURL} position="top">
                        <span className='short-url-text'>{webQRURL}</span>
                    </RSPTooltip> */}
                </Col>
                <Col sm={2}>
                    <RSPTooltip text={'Selected short URL included to download as format.'}>
                        <i className={`icon-sd-question-mark-mini icons-sm blue cp`}></i>
                    </RSPTooltip>
                </Col>
            </Row>
            <Row className="mb10 mt10">
                <Col className='d-flex justify-content-end '>
                    <RSPTooltip text={copyTooltipText} position="top">
                        <span className="color-primary-blue d-flex align-items-center">
                            Copy <i className={`icon-sd-copy-medium icons-md blue icon-sd-arrow-left-mini cursor-pointer paddingL10`} onClick={() => {
                                navigator.clipboard.writeText(isShortURLCreated ? campaignURL : webQRURL);
                                // navigator.clipboard.writeText(webQRURL);
                                setCopyTooltipText("Copied")
                            }}></i>
                        </span>
                    </RSPTooltip>
                </Col>
            </Row>

            <span>Select size : {range ? range : 200}px</span>
            <Row>
                <Col sm={10}>
                    <Slider className='w-100' buttons={false} min={1} max={10} step={1} value={Math.round(range / 100)} defaultValue={2} onChange={(event) => {
                        getSliderValue(Math.round(event.value * 100))
                        // this.setState({ sizeValue: Math.round(event.value * 100) })
                    }} />
                    {/* <small>Size:<span>{this.state.sizeValue + "px"}</span></small> */}
                </Col>
                <Col sm={2}>
                    <RSPTooltip text={'Adjust the pixel size. The QR code will be downloaded in the selected pixel size.'}>
                        <i className={`icon-sd-question-mark-mini icons-sm blue cp`}></i>
                    </RSPTooltip>
                </Col>
            </Row>
            {
                !!generatedQR && (
                    <>
                        <Row className='mt5'>
                            <Col className='d-flex justify-content-center'>
                                {/* <RSPrimaryBtn className='download-qr'>
                                    Download */}
                                <DropDownButton
                                    className="rsp-k-icondropdown"
                                    items={["PNG", "PDF"]}
                                    // icon=" icon-sd-download-medium icons-md white"
                                    text={<RSPrimaryBtn>
                                        <span>Download</span>
                                        <i className='icon-sd-download-medium icons-md white ml10' />
                                    </RSPrimaryBtn>}
                                    onItemClick={(e) => {
                                        if (e.item === "PNG") {
                                            window.open(generatedQR.pngUrl, "_blank");
                                        } else {
                                            window.open(generatedQR.pdfUrl, "_blank");
                                        }
                                    }} />
                                {/* </RSPrimaryBtn> */}
                            </Col>
                        </Row>
                        {/* 
                        <RSPrimaryButton>
                            Download <i className={`${Icons.download_large}`}></i>
                        </RSPrimaryButton> */}
                    </>
                )
            }
        </div >
    );
}

export default QRPreviewNew;