import { Switch } from '@progress/kendo-react-inputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSRadio } from 'Components/RSInputs';
import { RSPPophover } from 'Components/Tooltip';
import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

class ApprovalSettings extends Component {
  state = {
    isModalOpen: this.props.isOpen,
    matchType: this.props.approvalSettings.approvalMatchType,
    anyCount: this.props.approvalSettings.approvalMatchCount,
    followHierarchyChecked: this.props.approvalSettings.isFollowHierarchyEnabled,
    approvalListCount: this.props.approvalListCount,
    dropdownData: this.props.approvalListCount > 2 ? [1, 2] : [1]
  }
  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        onHide={() => {
          this.setState({ isModalOpen: !this.state.isModalOpen });
          this.props.onOpenChange(false);
        }}
        show={this.state.isModalOpen} centered size="lg">
        <Modal.Header closeButton>
          <h2>Approval settings</h2>
        </Modal.Header>
        <Modal.Body>

          {/* <ApprovalPopup /> */}
          <Row className="marginY30 paddingL15">
            <Col md={3}>
              <label className="label-row">Get approval from</label>
            </Col>
            <Col md={9}>
              <div className="match-type-input-wrapper">
                <div className="match-type-input">
                  <RSRadio
                    lbl="All"
                    name="rmatchtype"
                    labelId="rall"
                    checked={this.state.matchType.toLowerCase() === "all"}
                    cb={(event) => {
                      this.setState({ matchType: event.target.value });
                      this.setState({ anyCount: 0 });
                    }}
                  />
                </div>
                <div className="match-type-input">
                  <RSRadio
                    lbl="Any"
                    name="rmatchtype"
                    labelId="rany"
                    checked={this.state.matchType.toLowerCase() === "any"}
                    cb={(event) => {
                      this.setState({ matchType: event.target.value, followHierarchyChecked: false, anyCount: this.props.approvalListCount > 2 ? 2 : 1 });
                    }}
                  />
                </div>
                {this.state.matchType.toLowerCase() === "any" ? (
                  <div className="match-type-input">
                    <RSDropdownList
                      data={this.state.dropdownData}
                      value={this.state.anyCount}
                      customAlignRight={true}
                      className="mb0 required"
                      onChange={(e) => {
                        this.setState({ anyCount: e.target.value });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Col>

          </Row>
          <Row className="marginY30 paddingL15">
            <Col md={3}>
              <label className="label-row">Follow hierarchy</label>
            </Col>
            <Col md={2}>
              <Switch
                onChange={() => {
                  this.setState({
                    followHierarchyChecked:
                      !this.state.followHierarchyChecked,
                  });
                }}
                disabled={this.state.matchType.toLowerCase() === "any"}
                checked={this.state.followHierarchyChecked}
              />
              <RSPPophover
                text="Hierarchical order will be determined based on the order of the emails."
                position="top"
              >
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp"></i>
              </RSPPophover>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <RSSecondaryBtn
            onClick={() => {
              this.setState({
                isModalOpen: !this.state.isModalOpen
              });
              this.props.onOpenChange(false);
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              this.props.approvalSettings.approvalMatchType = this.state.matchType;
              this.props.approvalSettings.approvalMatchCount = this.state.anyCount;
              this.props.approvalSettings.isFollowHierarchyEnabled = this.state.followHierarchyChecked
              this.setState({
                isModalOpen: !this.state.isModalOpen
              });
              this.props.onOpenChange(true);
            }}
          >
            Save
          </RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ApprovalSettings;