import { RSDropdownList } from 'Components/RSDropdowns';
import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap"
import { RSInput2, RSTextInputWithIcon } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import authContext from 'Helper/StateHandler/auth-context';
import {
    ENTER_ADDRESS1,
    ENTER_ADDRESS2,
    ENTER_CITY,
    ENTER_COMPANY_NAME,
    ENTER_FIRST_NAME_3CHARS,
    ENTER_SECURE_WEBSITE,
    ENTER_VALID_WEBSITE,
    ENTER_WEBSITE,
    ENTER_ZIP,
    SELECT_BRAND_POSITION,
    SELECT_BUSINESS_TYPE,
    SELECT_CITY,
    SELECT_COUNTRY,
    SELECT_INDUSTRY,
    SELECT_STATE,
    SPECIAL_CHARACTERS,
    SPECIAL_NUM_CHARACTERS,
    UPLOAD_COMPANY_IMAGE
} from 'Helper/Constants/validationMessage';
import { getServerConfig, isEmpty, isValidWebsite } from 'Helper/Utils/Utils';
import { RSUploadImageComponent } from 'Components/UploadImage/RSUploadImageComponent';
import { connectServer } from 'Helper/Network/networkHandler';
import { CHECK_CLIENT_NAME, CHECK_WEBSITE_NAME, GET_CITY_BY_STATE, GET_STATE_BY_COUNTRY } from 'Helper/Constants/endPoint';
import * as images from 'Assets/Images'

class BrandDetails extends Component {

    static contextType = authContext
    state = {
        ...{ brandPositionData: null },
        ...this.context.globalStateData.getKeyContactInfoData.brandDetails,
        imageURL: getServerConfig().slice(0, -1),
        imageType: ""
    }

    componentDidMount = props => {
        window.scrollTo(0, 0);
    }

    vaidateState = () => {

        const { logoPath, imageType, companyName, website, addressLine1, addressLine2, city, zipCode, countryDropdownItem, stateDropdownItem, cityDropdownItem,
            businessTypeDropdownItem, brandPositioningTypeDropdownItem, industryDropdownItem } = this.state
        let enable = true;
        if (!logoPath) {
            this.setState({ errMsgCompanyImgPath: UPLOAD_COMPANY_IMAGE })
            enable = false;
        }
        if (isEmpty(companyName)) {
            this.setState({ errMsgCompanyName: ENTER_COMPANY_NAME });
            enable = false;
        }
        if (this.state.errMsgWebsite === null) {
            if (isEmpty(website)) {
                this.setState({ errMsgWebsite: ENTER_WEBSITE });
                enable = false;
            }
            if (!isValidWebsite(website)) {
                this.setState({ errMsgWebsite: ENTER_VALID_WEBSITE });
                enable = false;
            }
        } else {
            enable = false;
        }
        if (isEmpty(addressLine1)) {
            this.setState({ errMsgAddress1: ENTER_ADDRESS1 });
            enable = false;
        }
        // if (isEmpty(addressLine2)) {
        //     this.setState({ errMsgAddress2: ENTER_ADDRESS2 });
        //     enable = false;
        // }
        // if (isEmpty(city)) {
        //     this.setState({ errMsgCity: ENTER_CITY });
        //     enable = false;
        // } else if (city.length < 3) {
        //     this.setState({ errMsgCity: ENTER_FIRST_NAME_3CHARS });
        //     enable = false;
        // }
        if (isEmpty(zipCode)) {
            this.setState({ errMsgZip: ENTER_ZIP });
            enable = false;
        }
        if (countryDropdownItem === null || isEmpty(countryDropdownItem.country) || countryDropdownItem.country.toLowerCase().includes("country")) {
            this.setState({ errMsgCountry: SELECT_COUNTRY });
            enable = false;
        }
        if (stateDropdownItem === null || isEmpty(stateDropdownItem.state) || stateDropdownItem.state.toLowerCase().includes("select")) {
            this.setState({ errMsgState: SELECT_STATE });
            enable = false;
        }
        if (cityDropdownItem === null || isEmpty(cityDropdownItem.city) || cityDropdownItem.city.toLowerCase().includes("select")) {
            this.setState({ errMsgCity: SELECT_CITY });
            enable = false;
        }
        // if (isEmpty(industry) || industry.toLowerCase().includes("select")) {
        //     this.setState({ errMsgIndustry: SELECT_INDUSTRY }); return
        // }
        if (industryDropdownItem === null || isEmpty(industryDropdownItem.industryName) || industryDropdownItem.industryName.toLowerCase().includes("industry")) {
            this.setState({ errMsgIndustry: SELECT_INDUSTRY });
            enable = false;
        }
        if (businessTypeDropdownItem === null || isEmpty(businessTypeDropdownItem.businessType) || businessTypeDropdownItem.businessType.toLowerCase().includes("business")) {
            this.setState({ errMsB2c: SELECT_BUSINESS_TYPE });
            enable = false;
        }
        if (brandPositioningTypeDropdownItem === null || isEmpty(brandPositioningTypeDropdownItem.brandPositionName) || brandPositioningTypeDropdownItem.brandPositionName.toLowerCase().includes("brand")) {
            this.setState({ errMsgBrandPositioning: SELECT_BRAND_POSITION });
            enable = false;
        }
        if (enable) {
            const params = {
                clientDetail: {
                    clientName: companyName,
                    webUrl: website,
                    clientLogo: logoPath,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: "",
                    cityId: cityDropdownItem.cityId,
                    zipCode: zipCode,
                    countryId: countryDropdownItem.countryId,
                    stateId: stateDropdownItem.stateID,
                    industryId: industryDropdownItem.industryId,
                    businessTypeId: this.state.businessTypeDropdownItem.businessTypeId,
                    brandPositionId: this.state.brandPositioningTypeDropdownItem.brandPositionId,
                    clientLogoType: imageType
                }
            }
            this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ brandDetails: this.state } })
            this.props.updateDataAndIndex(this.props.i + 1, params)
        }
    }
    checkClientExist = clientName => {
        const params = { clientName: clientName }
        connectServer({
            path: CHECK_CLIENT_NAME,
            loading: this.context.globalStateData.setIsLoadingData,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    const some = this.state.companyName
                    this.setState({
                        errMsgCompanyName: `${some} already exists`
                    })
                }
            },
            fail: error => { }
        })
    }

    checkWebsiteExist = websiteName => {
        const params = { website: websiteName }
        connectServer({
            path: CHECK_WEBSITE_NAME,
            loading: this.context.globalStateData.setIsLoadingData,
            params,
            context: this.context,
            ok: res => {
                if (!res.status) {
                    this.setState({
                        errMsgWebsite: ENTER_VALID_WEBSITE
                    })
                } else {
                    this.setState({ isShowTickSymbol: true, errMsgWebsite: null })
                }
            },
            fail: error => {
                this.setState({ errMsgWebsite: ENTER_VALID_WEBSITE })
            }
        })
    }
    handledropdown = (countryId) => {
        const params = {
            countryID: countryId
        }
        connectServer({
            path: GET_STATE_BY_COUNTRY,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        stateDropdownData: res.data
                    });
                }
            },
            fail: error => {
            }
        })

    }
    handledropdownForCity = (stateId) => {
        const params = {
            stateId: stateId
        }
        connectServer({
            path: GET_CITY_BY_STATE,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        cityDropdownData: res.data
                    });
                }
            },
            fail: error => {
            }
        })
    }
    render() {
        const {
            errMsgCompanyName,
            errMsgWebsite,
            errMsgAddress1,
            errMsgAddress2,
            errMsgCity,
            errMsgZip,
            errMsgCountry,
            errMsgState,
            errMsgIndustry,
            errMsB2c,
            errMsgBrandPositioning,
            companyName,
            website,
            errMsgCompanyImgPath,
            addressLine1,
            addressLine2,
            city,
            zipCode,
            isShowTickSymbol
        } = this.state
        return (
            <>
                <Container className='box-bottom-space'>
                    <RSBrandLogo />
                    <h1>Brand details</h1>
                    <div className="portlet-box-theme">
                        <Row>
                            {/* Brand logo */}
                            <Col md={2} sm={3}>
                                <RSUploadImageComponent
                                    className={`enable-remove`}
                                    defaultImg={images.Building}
                                    defaultValue={this.state.logoPath}
                                    newImg={this.state.newImg ? `${this.state.imageURL}${this.state.newImg}` : null}
                                    text={`${!this.state.newImg && !this.state.logoPath ? "Upload logo" : "Edit logo"}`}
                                    icon={`${!this.state.newImg && !this.state.logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                    cb={img => this.setState({ logoPath: img, errMsgCompanyImgPath: null })}
                                    getImageType={(value) => {
                                        this.setState({ imageType: value });
                                    }}
                                    error={(value) => {
                                        this.setState({
                                            errMsgCompanyImgPath: value,
                                            newImg: null,
                                            logoPath: this.state.logoPath,
                                        });
                                    }}
                                    // getErrMsg={(errMsg) => { this.setState({ errMsgCompanyImgPath: errMsg }) }}
                                    errorMessage={errMsgCompanyImgPath}
                                    removeIcon={this.state.logoPath !== null}
                                    handleRemove={() => {
                                        this.setState({
                                            logoPath: null,
                                            newImg: null,
                                        });
                                    }
                                    }
                                />
                                {/* <RSUploadImageComponent
                                    defaultImg={images.User}
                                    defaultValue={this.state.logoPath}
                                    newImg={this.state.newImg ? `${this.state.imageURL}${this.state.newImg}` : null}
                                    text={`${!this.state.newImg && !this.state.logoPath ? "Upload logo" : "Edit logo"}`}
                                    icon={`${!this.state.newImg && !this.state.logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                    cb={img => this.setState({ logoPath: img, errMsgCompanyImgPath: null })}
                                    getErrMsg={(errMsg) => { this.setState({ errMsgCompanyImgPath: errMsg }) }}
                                /> */}
                                {/* {this.state.logoPath !== null &&
                                    <div className="remove-profile cp" onClick={() => {
                                        this.setState({
                                            logoPath: null,
                                            newImg: null
                                        })
                                    }}>
                                        <i className="icon-sd-close-mini"></i>
                                        <span className="fs13 cp ml5">Remove</span>
                                    </div>
                                } */}
                            </Col>

                            <Col md={10}>
                                <Row>
                                    {/* Company name */}
                                    <Col md={6}>
                                        <RSInput2
                                            name="companyName"
                                            ph={ENTER_COMPANY_NAME}
                                            val={companyName}
                                            required={true}
                                            max={255}
                                            icon={"icon-sd-email-medium icon-sm"}
                                            errorMessage={errMsgCompanyName}
                                            cb={text => {
                                                let errMsg = text.length > 0 ? null : ENTER_COMPANY_NAME
                                                if (!errMsg) {
                                                    var regex = /^[A-Za-z0-9\w ]+$/;
                                                    if (regex.test(text)) {
                                                        this.setState({ companyName: text, errMsgCompanyName: errMsg })
                                                    } else {
                                                        this.setState({ errMsgCompanyName: SPECIAL_CHARACTERS })
                                                    }
                                                } else {
                                                    this.setState({ companyName: text, errMsgCompanyName: errMsg })
                                                }
                                            }}
                                            ob={val => {
                                                val = val.trimEnd();
                                                val = val.trimStart();
                                                if (val.length > 0) {
                                                    this.checkClientExist(val)
                                                }
                                            }}
                                        />
                                    </Col>
                                    {/* Website url */}
                                    <Col md={6}>
                                        <RSTextInputWithIcon
                                            name="website"
                                            ph={ENTER_WEBSITE}
                                            val={website}
                                            required={true}
                                            max={150}
                                            errorMessage={errMsgWebsite}
                                            icon={`${isShowTickSymbol ? 'icon-sd-circle-tick-mini icons-md green text-end' : ""}`}
                                            cb={t => {
                                                let errMsg = t.length > 0 ? null : ENTER_WEBSITE
                                                this.setState({ website: t, errMsgWebsite: errMsg, isShowTickSymbol: false })
                                            }}
                                            ob={val => {
                                                const httpsPattern = /https:\/\//g;
                                                if (val.length > 0 && val.match(httpsPattern)?.length === 1) {
                                                    if (isValidWebsite(val)) {
                                                        this.checkWebsiteExist(val)
                                                    } else {
                                                        this.setState({ errMsgWebsite: val.length > 0 ? ENTER_VALID_WEBSITE : ENTER_WEBSITE })
                                                    }
                                                } else {
                                                    const httpPattern = /http:\/\//g;
                                                    this.setState({ errMsgWebsite: val.length > 0 ? val.match(httpPattern)?.length === 1 ? ENTER_SECURE_WEBSITE : ENTER_VALID_WEBSITE : ENTER_WEBSITE })
                                                }
                                            }}
                                        />
                                        <small className="input-desc marginX11 font-italic sub-label">Ex: https://marketingstar.io</small>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* Address 1 */}
                                    <Col md={6}>
                                        <RSInput2
                                            name="address"
                                            ph={ENTER_ADDRESS1}
                                            val={addressLine1}
                                            required={true}
                                            max={255}
                                            icon={"icon-sd-email-medium icon-sm"}
                                            errorMessage={errMsgAddress1}
                                            cb={t => {
                                                let errMsg = t.length > 0 ? null : ENTER_ADDRESS1
                                                this.setState({ addressLine1: t, errMsgAddress1: errMsg })
                                            }}
                                        />
                                    </Col>
                                    {/* Address 2 */}
                                    <Col md={6}>
                                        <RSInput2
                                            name="address"
                                            ph={ENTER_ADDRESS2}
                                            val={addressLine2}
                                            max={255}
                                            icon={"icon-sd-email-medium icon-sm"}
                                            errorMessage={errMsgAddress2}
                                            cb={t => {
                                                this.setState({ addressLine2: t })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {/* Country */}
                                    <Col md={6}>
                                        <div className='position-relative'>
                                            {errMsgCountry && <div className="validation-message">{errMsgCountry}</div>}
                                            <RSDropdownList
                                                className="required"
                                                textField="country"
                                                dataItemKey="countryId"
                                                data={this.state.countryDropdownData}
                                                value={this.state.countryDropdownItem}
                                                defaultItem={{
                                                    "countryId": -1,
                                                    "country": "-- Select country --",
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.country.length > 0 ? null : SELECT_COUNTRY
                                                    this.handledropdown(e.target.value.countryId)
                                                    this.setState({
                                                        countryDropdownItem: e.target.value,
                                                        errMsgCountry: errMsg,
                                                        stateDropdownItem: {
                                                            "stateID": -1,
                                                            "state": "-- Select state --",
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    {/* State */}
                                    <Col md={6}>
                                        <div className='position-relative'>
                                            {errMsgState && <div className="validation-message">{errMsgState}</div>}
                                            <RSDropdownList
                                                className="required"
                                                textField="state"
                                                dataItemKey="stateID"
                                                data={this.state.stateDropdownData}
                                                value={this.state.stateDropdownItem}
                                                defaultItem={{
                                                    "stateID": -1,
                                                    "state": "-- Select state --",
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.state.length > 0 ? null : SELECT_STATE
                                                    this.handledropdownForCity(e.target.value.stateID)
                                                    this.setState({
                                                        stateDropdownItem: e.target.value,
                                                        errMsgState: errMsg,
                                                        cityDropdownItem: {
                                                            "cityId": -1,
                                                            "city": "-- Select city --",
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        {/* City */}
                                        <Row>
                                            <Col md={6}>
                                                {/* <RSInput2
                                                    validateCls="inputValidate"
                                                    name="city"
                                                    ph={ENTER_CITY}
                                                    val={city}
                                                    required={true}
                                                    max={255}
                                                    icon={"icon-sd-email-medium icon-sm"}
                                                    errorMessage={errMsgCity}
                                                    cb={text => {
                                                        let errMsg = text.length > 0 ? null : ENTER_CITY
                                                        if (!errMsg) {
                                                            var regex = /^[a-zA-Z ]*$/;
                                                            if (regex.test(text)) {
                                                                this.setState({ city: text, errMsgCity: errMsg })
                                                            } else {
                                                                this.setState({ errMsgCity: SPECIAL_NUM_CHARACTERS })
                                                            }
                                                        } else {
                                                            this.setState({ city: text, errMsgCity: errMsg })
                                                        }
                                                    }}
                                                    ob={text => {
                                                        let errMsg = text.length > 0 ? null : ENTER_CITY
                                                        if (!errMsg) {
                                                            var regex = /^[a-zA-Z ]*$/;
                                                            if (regex.test(text)) {
                                                                errMsg = text.length >= 3 ? null : ENTER_FIRST_NAME_3CHARS;
                                                                this.setState({ city: text, errMsgCity: errMsg })
                                                            } else {
                                                                this.setState({ errMsgCity: SPECIAL_NUM_CHARACTERS })
                                                            }
                                                        } else {
                                                            this.setState({ city: text, errMsgCity: errMsg })
                                                        }
                                                    }}
                                                /> */}
                                                <div className='position-relative'>
                                                    {errMsgCity && <div className="validation-message">{errMsgCity}</div>}
                                                    <RSDropdownList
                                                        className="required"
                                                        textField="city"
                                                        dataItemKey="cityId"
                                                        data={this.state.cityDropdownData}
                                                        value={this.state.cityDropdownItem}
                                                        defaultItem={{
                                                            "cityId": -1,
                                                            "city": "-- Select city --",
                                                        }}
                                                        onChange={(e) => {
                                                            let errMsg = e.target.value.cityId !== -1 ? null : SELECT_CITY
                                                            this.setState({ cityDropdownItem: e.target.value, errMsgCity: errMsg })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            {/* Zip code */}
                                            <Col md={6}>
                                                <RSInput2
                                                    name="zip"
                                                    ph={ENTER_ZIP}
                                                    max={16}
                                                    // type="number"
                                                    val={zipCode}
                                                    required={true}
                                                    icon={"icon-sd-email-medium icon-sm"}
                                                    errorMessage={errMsgZip}
                                                    cb={t => {
                                                        if (t === "") {
                                                            this.setState({ zipCode: "" })
                                                            return
                                                        }
                                                        var regex = /^[a-zA-Z0-9]+$/;
                                                        if (t !== "" && regex.test(t)) {
                                                            let errMsg = t.length > 0 ? null : ENTER_ZIP
                                                            this.setState({ zipCode: t, errMsgZip: errMsg })
                                                        } else {
                                                            this.setState({ errMsgZip: "Only alphanumeric allowed" })
                                                        }
                                                    }}
                                                    ob={(text) => {
                                                        if (text === "") {
                                                            this.setState({ zipCode: "", errMsgZip: ENTER_ZIP })
                                                            return
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* Industry */}
                                    <Col md={6}>
                                        <div className='position-relative'>
                                            {errMsgIndustry && <div className="validation-message">{errMsgIndustry}</div>}
                                            <RSDropdownList
                                                className="mb0 required"
                                                textField="industryName"
                                                dataItemKey="industryId"
                                                data={this.state.industryDropdownData}
                                                value={this.state.industryDropdownItem}
                                                defaultItem={{
                                                    "industryId": -1,
                                                    "industryName": "-- Select industry --"
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.industryName.length > 0 ? null : SELECT_INDUSTRY
                                                    this.setState({ industryDropdownItem: e.target.value, errMsgIndustry: errMsg })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* Business type */}
                                    <Col md={6}>
                                        <div className='position-relative'>
                                            {errMsB2c && <div className="validation-message">{errMsB2c}</div>}
                                            <RSDropdownList
                                                className="mb0 required"
                                                textField="businessType"
                                                dataItemKey="businessTypeId"
                                                data={this.state.businessTypeDropdownData}
                                                value={this.state.businessTypeDropdownItem}
                                                defaultItem={{
                                                    "businessTypeId": -1,
                                                    "businessType": "-- Business type --",
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.businessType.length > 0 ? null : SELECT_BUSINESS_TYPE
                                                    const brandPositionData = this.state.brandPositioningTypeDropdownData.filter(item => {
                                                        return item.clienttype === e.target.value.businessTypeId
                                                    })
                                                    this.setState({
                                                        businessTypeDropdownItem: e.target.value,
                                                        errMsB2c: errMsg,
                                                        brandPositionData,
                                                        brandPositioningTypeDropdownItem: {
                                                            "brandPositionId": -1,
                                                            "brandPositionName": "-- Brand positioning --",
                                                        }
                                                    })
                                                }} />
                                        </div>
                                    </Col>
                                    {/* Brand positioning */}
                                    <Col md={6}>
                                        <div className='position-relative'>
                                            {errMsgBrandPositioning && <div className="validation-message">{errMsgBrandPositioning}</div>}
                                            <RSDropdownList
                                                className="mb0 required"
                                                textField="brandPositionName"
                                                dataItemKey="brandPositionId"
                                                data={this.state.brandPositionData || []}
                                                value={this.state.brandPositioningTypeDropdownItem}
                                                defaultItem={{
                                                    "brandPositionId": -1,
                                                    "brandPositionName": "-- Brand positioning --",
                                                }}
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.brandPositionName.length > 0 ? null : SELECT_BRAND_POSITION
                                                    this.setState({ brandPositioningTypeDropdownItem: e.target.value, errMsgBrandPositioning: errMsg })
                                                }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                    <div className="btn-container d-flex justify-content-end">
                        <RSSecondaryBtn onClick={() => {
                            this.props.updateDataAndIndex(this.props.i - 1, null)
                            this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ brandDetails: this.state } })
                        }}>Back</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={this.vaidateState}>Next</RSPrimaryBtn>
                    </div>
                </Container>

            </>
        );
    }
}

export default BrandDetails;
