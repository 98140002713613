import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';
import { dateFormat } from 'Helper/Utils/Utils';
import { RSPTooltip } from 'Components/Tooltip';

const initialDataState = {
    skip: 0,
    take: 5,
};
class PreviewList extends Component {
    state = {
        result: null,
        page: initialDataState,
        totalListCount: 0,
        rightTitleMessage: "",
        leftTitleMessage: ""
    }
    componentDidMount = props => {
        this.setState({
            result: this.props.previewList?.data
        })
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            let previewCount = parseInt(this.props.previewList?.totalAudienceCount, 10) > 50 ? "50" : this.props.previewList?.totalAudienceCount;
            let rightMessage = "List preview: Sample audience record of " + previewCount + " audience"
            let leftMessage = "Attribute count - " + this.props.previewList?.headerCount + " / Audience count - " + this.props.previewList?.totalAudienceCount;
            this.setState({
                rightTitleMessage: rightMessage,
                leftTitleMessage: leftMessage,
                result: this.props.previewList?.data,
                totalListCount: this.props.previewList?.data.length
            })
        }
    }
    pageChange = (event) => {
        this.setState({
            page: event.page
        });
    };
    render() {

        const _columnHeaders = []
        const gridData = this.state.result || []
        const allKeys = gridData.length ? Object.keys(gridData[0]) : gridData
        // const removeFields = ['FirstName', 'LastName']
        // const filterField = allKeys.filter((item) => {
        //     return !removeFields.includes(item);
        // });
        allKeys.map((item, index) => {
            if ("rowNo".includes(item)) { return }
            // console.log("fieldItem::", item);

            let newItem = {}
            newItem.isShow = true
            newItem.field = item
            newItem.title = conFilter[item]?.title
            newItem.width = conFilter[item]?.width || 200
            newItem.filter = conFilter[item]?.filter || null
            newItem.cell = conFilter[item]?.cell || null
            // newItem.cell = ({ dataItem, field }) => {
            //     return <td>{dateFormat(dataItem[field])}</td>;
            // },
            _columnHeaders.push(newItem)
        })

        // console.log("allKeys::", gridData);
        // console.log("allKeys::", allKeys);
        // console.log("allKeys::", _columnHeaders);
        // console.log("allKeys2::", this.state.result);
        // console.log("allKeys3::", _columnHeaders.filter(item => item.isShow));

        return (
            <>
                <Row>
                    <Col className='mb20'>{this.state.rightTitleMessage}</Col>
                    <Col className='mb20 text-end'>{this.state.leftTitleMessage}</Col>
                </Row>
                {
                    this.state.result
                        ? (
                            <div className='grid-live-preview-modal'>
                                <div className='mb20'>
                                    <KendoGrid
                                        data={this.state.result}
                                        scrollable={'scrollable'}
                                        column={_columnHeaders.filter(item => item.isShow)}
                                        pageable
                                        settings={{
                                            total: this.state.result.length
                                        }}
                                    />
                                </div>
                                {/* <Grid
                                    data={this.state.result?.slice(this.state.page.skip, this.state.page.take + this.state.page.skip)}
                                    onPageChange={this.pageChange}
                                    className="grid-pagenation-del grid-vertical-noscroll audience-info-grid"
                                    sortable={true}
                                    scrollable={true}
                                    skip={this.state.page.skip}
                                    take={this.state.page.take}
                                    total={this.state.totalListCount}
                                    pageable={{
                                        type: 'input',
                                        pageSizes: [5, 10, 20],
                                        previousNext: true,
                                    }}
                                >
                                    {this.props.PreviewList?.columnName.map((item, index) => {
                                        return (
                                            <GridColumn
                                                title={item}
                                                field={item}
                                            />
                                        )
                                    })}
                                </Grid> */}
                            </div>
                        )
                        : (<SkeletonTable message={this.state.message} text={"to upload data"} count={5} isError={this.state.listError} />)
                }
            </>
        );
    }
}

export default PreviewList;

const ColumnMenu = (props) => {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};

const conFilter = {
    'CustomerID': {
        'title': 'CustomerID',
        'width': 140,
        // 'filter': 'text'
    },
    'FirstName': {
        'title': 'FirstName',
        'width': 220,
        // 'filter': 'text'
    },
    'LastName': {
        'title': 'LastName',
        'width': 200,
        // 'filter': 'text'
    },
    'EmailID': {
        'title': 'EmailID',
        'width': 280,
        // 'filter': 'date',
        'cell': ({ dataItem, field }) => {
            return <td>
                <div className='table-text'>
                    <RSPTooltip text={dataItem[field]} position="top">
                        <p>{dataItem[field]}</p>
                    </RSPTooltip>
                </div>
            </td>;
        }
        // 'cell': ({ dataItem, field }) => {
        //     return <td>{dateFormat(dataItem[field])}</td>;
        // }
    },
    'MobileNo': {
        'title': 'MobileNo',
        'width': 180,
        'filter': false
    },
    'DOB': {
        'title': 'DOB',
        'width': 150,
        // 'filter': 'date'
    },
    'Age': {
        'title': 'Age',
        'width': 120,
        // 'filter': 'date'
    },
    'RefinanceLoanType': {
        'title': 'RefinanceLoanType',
        'width': 180,
        // 'filter': 'date'
    },
    'DwellingType': {
        'width': 280,
    }
}
