import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSPPophover } from 'Components/Tooltip';
import TableDetails from './TableDetails';
import { isEmpty } from "Helper/Utils/Utils";
import DataExchangeModal from './DataExchangeModal';

class DataExchange extends Component {

    state = {
        friendlyName: "",
        api: "",
        userName: "",
        password: "",
        isConnectBtnClicked: false,
        connectionType: "",
        tableDetails: "",
        primaryKey: "",
        foreignKey: "",
        leftColumnValues: [],
        importPreference: "",
        updateCycle: "",
        updateCheck: "",
        CRMtableName: "",
        exportTableName: "",
        isShowModal: false
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
        }
    }

    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className=''>
                        <div className="header-title d-flex justify-content-between">
                            <h1>Data exchange</h1>
                        </div>
                        <div className="portlet-box-theme">
                            <Row>
                                <Col >
                                    <label>{this.props.history.location.state.CRMType === "mscrm" ? "CRM - MSCRM" : "CRM - Salesforce"}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <RSInput2
                                        ph="-- Friendly name --"
                                        val={this.state.friendlyName}
                                        cls="mb0"
                                        cb={(text) => {
                                            this.setState({ friendlyName: text });
                                        }} />
                                </Col>
                                <Col md={3}>
                                    <RSInput2
                                        ph="API"
                                        val={this.state.api}
                                        cls="mb0"
                                        cb={(text) => {
                                            this.setState({ api: text });
                                        }} />
                                </Col>
                                <Col md={3}>
                                    <RSInput2
                                        ph="User name"
                                        val={this.state.userName}
                                        cls="mb0"
                                        cb={(text) => {
                                            this.setState({ userName: text });
                                        }} />
                                </Col>
                                <Col md={3}>
                                    <RSInput2
                                        ph="Password"
                                        val={this.state.password}
                                        cls="mb0"
                                        cb={(text) => {
                                            this.setState({ password: text });
                                        }} />
                                </Col>
                            </Row>
                        </div>
                        <div className="btn-container d-flex justify-content-end">
                            <RSPrimaryBtn className={`${this.state.isConnectBtnClicked ? "click-off" : ""}`} onClick={() => {
                                this.setState({ isConnectBtnClicked: !this.state.isConnectBtnClicked });
                            }}>Connect</RSPrimaryBtn>
                        </div>
                        {this.state.isConnectBtnClicked &&
                            <div>
                                <div className="portlet-box-theme p0 mb20">
                                    <Row className="even py15 mx0">
                                        <Col md={{ span: 2, offset: 3 }} className="text-end">
                                            <label className="label-row">Connection type</label>
                                        </Col>
                                        <Col md={3}>
                                            <RSDropdownList
                                                data={this.props.history.location.state.CRMType === "mscrm" ? MSCRMConnectionTypes : SalesforceConnectionTypes}
                                                className="mb0"
                                                value={this.state.connectionType !== "" ? this.state.connectionType : "-- Select --"}
                                                onChange={(e) => {
                                                    this.setState({ connectionType: e.target.value });
                                                    if (e.target.value.startsWith("R")) {
                                                        if (this.props.history.location.state.CRMType === "mscrm") {
                                                            this.setState({ leftColumnValues: UserDetails });
                                                        } else {
                                                            this.setState({ leftColumnValues: Lead });
                                                        }

                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    {(!isEmpty(this.state.connectionType) && !this.state.connectionType.startsWith("R")) &&
                                        <Row className="even py15 mx0">
                                            <Col md={{ span: 3, offset: 1 }}>
                                                <Row>
                                                    <Col md={4} className="text-end">
                                                        <label className="label-row">Table</label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <RSDropdownList
                                                            data={this.props.history.location.state.CRMType === "mscrm" ? CRMTableDetails : SalesforceTableDetails}
                                                            className="mb0"
                                                            value={this.state.tableDetails !== "" ? this.state.tableDetails : "-- Table details --"}
                                                            onChange={(e) => {
                                                                this.setState({ tableDetails: e.target.value });
                                                                if (this.props.history.location.state.CRMType === "mscrm") {
                                                                    if (e.target.value === "User details") {
                                                                        this.setState({ leftColumnValues: UserDetails });
                                                                    } else if (e.target.value === "Fixed deposits") {
                                                                        this.setState({ leftColumnValues: FixedDeposits });
                                                                    } else if (e.target.value === "Credit cards") {
                                                                        this.setState({ leftColumnValues: CreditCards });
                                                                    } else {
                                                                        this.setState({ leftColumnValues: Loans });
                                                                    }
                                                                } else {
                                                                    if (e.target.value === "Lead") {
                                                                        this.setState({ leftColumnValues: Lead });
                                                                    } else if (e.target.value === "Contact") {
                                                                        this.setState({ leftColumnValues: Contact });
                                                                    } else if (e.target.value === "Account") {
                                                                        this.setState({ leftColumnValues: Account });
                                                                    } else {
                                                                        this.setState({ leftColumnValues: Opportunity });
                                                                    }
                                                                }

                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3}>
                                                <Row>
                                                    <Col md={5} className="text-end">
                                                        <label className="label-row">Primary key</label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <RSDropdownList
                                                            data={Keys}
                                                            className="mb0"
                                                            value={this.state.primaryKey !== "" ? this.state.primaryKey : "-- Select --"}
                                                            onChange={(e) => {
                                                                this.setState({ primaryKey: e.target.value });
                                                            }} />
                                                        <div className="input-outer-set">
                                                            <RSPPophover text="A primary keyword, is a key in a relational database that is unique for each record." position="top">
                                                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                                            </RSPPophover>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <Row>
                                                    <Col md={4} className="text-end">
                                                        <label className="label-row">Foreign key</label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <RSDropdownList
                                                            data={Keys}
                                                            className="mb0"
                                                            value={this.state.foreignKey !== "" ? this.state.foreignKey : "-- Select --"}
                                                            onChange={(e) => {
                                                                this.setState({ foreignKey: e.target.value });
                                                            }} />
                                                        <div className="input-outer-set">
                                                            <RSPPophover text="A foreign key is a field (or collection of fields) in one table that uniquely identifies a row of another table." position="top">
                                                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                                            </RSPPophover>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }

                                    {/* audience/data-exchange */}
                                    {(this.state.tableDetails !== "" || (!isEmpty(this.state.connectionType) && this.state.connectionType.startsWith("R"))) &&
                                        <div className="p15"><TableDetails tabledetails={this.state.tableDetails} leftColumnValues={this.state.leftColumnValues} /></div>
                                    }

                                </div>
                            </div>
                        }
                    </div>



                    {(this.state.tableDetails !== "" || (!isEmpty(this.state.connectionType) && this.state.connectionType.startsWith("R"))) &&
                        <div>
                            <div className="d-flex align-items-center justify-content-between mb15">
                                <div className="d-flex align-items-center">
                                    <h4 className="mb0">
                                        {
                                            (!isEmpty(this.state.connectionType)
                                                && this.state.connectionType.startsWith("R"))
                                                ? "Export preferences"
                                                : "Import preferences"
                                        }
                                    </h4>
                                    {
                                        (!isEmpty(this.state.connectionType)
                                            && !this.state.connectionType.startsWith("R"))
                                        && <RSPPophover text="Dedupe settings" position="top">
                                            <i
                                                className='icon-settings-small icons-md blue cp ml10'
                                                onClick={() => {
                                                    this.setState({
                                                        isShowModal: true
                                                    })
                                                }}
                                            ></i>
                                        </RSPPophover>
                                    }
                                </div>
                                {(!isEmpty(this.state.connectionType) && this.state.connectionType.startsWith("R"))
                                    && <div>
                                        <ul>
                                            <li className="float-end">
                                                <span className="attribute-box"></span>
                                                Attributes derived by Resulticks
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className="mb5">
                                <Row>
                                    <RSRadio
                                        lbl="Upload new data - if a match is found, overwrite the older record"
                                        name={"importPreference"}
                                        labelId={"importPreferenceId"}
                                        className="marginB5"
                                        checked={this.state.importPreference === "Upload new data - if a match is found, overwrite the older record" ? true : false}
                                        cb={(event) => {
                                            this.setState({ importPreference: event.target.value });
                                        }} />
                                </Row>
                                <Row>
                                    <RSRadio
                                        lbl="Preserve older data - if a match is found, reject the recent record"
                                        name={"importPreference1"}
                                        labelId={"importPreferenceId1"}
                                        className="marginB5"
                                        checked={this.state.importPreference === "Preserve older data - if a match is found, reject the recent record" ? true : false}
                                        cb={(event) => {
                                            this.setState({ importPreference: event.target.value });
                                        }} />
                                </Row>
                                {(!isEmpty(this.state.connectionType) && !this.state.connectionType.startsWith("R"))
                                    ? <Row className="mt10">
                                        <Col md={4}>
                                            <Row>
                                                <Col md={4}>
                                                    <label className="label-row">Update cycle</label>
                                                </Col>
                                                <Col md={7}>
                                                    <RSDropdownList
                                                        data={["Immediate", "15 minutes", "30 minutes", "Hourly", "Daily", "Weekly", "Monthly"]}
                                                        className="mb0"
                                                        value={this.state.updateCycle !== "" ? this.state.updateCycle : "-- Select --"}
                                                        onChange={(e) => {
                                                            this.setState({ updateCycle: e.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <label className="label-row">Select the field to check for updates</label>
                                                </Col>
                                                <Col md={5}>
                                                    <RSDropdownList
                                                        data={["Modified date & time"]}
                                                        className="mb0"
                                                        value={this.state.updateCheck !== "" ? this.state.updateCheck : "-- Select --"}
                                                        onChange={(e) => {
                                                            this.setState({ updateCheck: e.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className="mt10">
                                        <Col md={6}>
                                            <Row>
                                                <Col md={4}>
                                                    <label className="label-row">CRM table names</label>
                                                </Col>
                                                <Col md={8}>
                                                    <RSDropdownList
                                                        data={this.props.history.location.state.CRMType === "mscrm" ? ["User details", "Fixed deposits", "Credit cards", "Loans", "Create new table"] : ["Lead", "Contact", "Account", "Opportunity", "Create new table"]}
                                                        className="mb0"
                                                        value={this.state.CRMtableName !== "" ? this.state.CRMtableName : "-- Table details --"}
                                                        onChange={(e) => {
                                                            this.setState({ CRMtableName: e.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            this.state.CRMtableName === "Create new table"
                                            && <Col md={6}>
                                                <Row>
                                                    <Col md={4}>
                                                        <label className="label-row">Create table name</label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <RSInput2
                                                            ph="Enter table name"
                                                            val={this.state.exportTableName}
                                                            cb={(text) => {
                                                                this.setState({ exportTableName: text });
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                    </Row>
                                }

                                <div className="btn-container d-flex justify-content-end">
                                    <RSSecondaryBtn onClick={() => {
                                        this.props.history.push("/preferences/data-exchange");
                                    }}>Cancel</RSSecondaryBtn>
                                    <RSPrimaryBtn onClick={() => {
                                        if ((!isEmpty(this.state.connectionType) && !this.state.connectionType.startsWith("R"))) {
                                            this.props.history.push({ pathname: "/audience", state: { index: 0 } });
                                        } else {
                                            this.props.history.push("/preferences/data-exchange");
                                        }
                                    }}>{(!isEmpty(this.state.connectionType) && !this.state.connectionType.startsWith("R")) ? "Upload" : "Create"}</RSPrimaryBtn>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.isShowModal && <DataExchangeModal callBack={(value) => {
                        this.setState({ isShowModal: false })
                        if (value) {
                            this.props.history.push({ pathname: `/audience/add-audience`, state: { mode: 'add' } })
                        }
                    }} isShowModal={this.state.isShowModal} title="Configure dedupe input settings" PrimaryBtn="Save" SecondaryBtn="Cancel" />}
                </Container>
            </LayoutPageWrapper>
        )
    }


}
export default withRouter(DataExchange);

const MSCRMConnectionTypes = ["CRM to Resulticks", "Resulticks to CRM"];
const SalesforceConnectionTypes = ["Salesforce to Resulticks", "Resulticks to Salesforce"];
const CRMTableDetails = ["User details", "Fixed deposits", "Credit cards", "Loans"];
const SalesforceTableDetails = ["Lead", "Contact", "Account", "Opportunity"];
const UserDetails = [
    { name: "Cust_ID", selected: false },
    { name: "Name", selected: false },
    { name: "Email", selected: false },
    { name: "Mobile", selected: false },
    { name: "Campaign response", selected: false },
    { name: "Gender", selected: false },
    { name: "Audience laddering", selected: false },
    { name: "Maturity date", selected: false },
    { name: "Annual income", selected: false },
    { name: "Leads", selected: false },
    { name: "Marital status", selected: false },
    { name: "Decile", selected: false },
    { name: "Unsubscribe", selected: false },
    { name: "Lead score", selected: false },
    { name: "Campaign name", selected: false }]

const FixedDeposits = [
    { name: "Cust_ID", selected: false },
    { name: "Fixed deposit opted", selected: false },
    { name: "Fixed deposit amount", selected: false },
    { name: "Fixed deposit maturity date", selected: false },
    { name: "Fixed deposit opted date", selected: false },
    { name: "Fixed deposit maturity period", selected: false },
    { name: "Product type", selected: false },
    { name: "Liquidate fixed deposit", selected: false }];
const CreditCards = [
    { name: "Cust_ID", selected: false },
    { name: "Account type", selected: false },
    { name: "Credit card opted", selected: false },
    { name: "Credit card type", selected: false },
    { name: "Current credit limit", selected: false },
    { name: "Credit card payment date", selected: false },
    { name: "Credit limit utilization", selected: false },
    { name: "Credit card eligibility", selected: false }];
const Loans = [
    { name: "Cust_ID", selected: false },
    { name: "Loan type", selected: false },
    { name: "Loan amount", selected: false },
    { name: "Loan period", selected: false },
    { name: "EMI amount", selected: false }];
const Keys = ["Cust_ID", "Name", "Mobile", "City", "Email", "Income", "State", "Country", "Age", "Gender", "Account type"];
const Lead = [
    { name: "Name", selected: false },
    { name: "Email", selected: false },
    { name: "City", selected: false },
    { name: "Annual Revenue", selected: false },
    { name: "Business categories", selected: false },
    { name: "Company", selected: false },
    { name: "Country", selected: false },
    { name: "Current Generator(s)", selected: false },
    { name: "Description", selected: false },
    { name: "Fax", selected: false },
    { name: "First name", selected: false },
    { name: "Individual", selected: false },
    { name: "Industry", selected: false },
    { name: "Job function", selected: false },
    { name: "Last name", selected: false },
    { name: "Lead owner", selected: false },
    { name: "Lead status", selected: false },
    { name: "Mobile", selected: false },
    { name: "No.of licenses", selected: false },
    { name: "No.of employees", selected: false },
    { name: "Number of locations", selected: false },
    { name: "Phone", selected: false },
    { name: "Postal code", selected: false },
    { name: "Primary", selected: false },
    { name: "Product name", selected: false },
    { name: "product interest", selected: false },
    { name: "Rating", selected: false },
    { name: "Salutation", selected: false },
    { name: "State", selected: false },
    { name: "Street", selected: false },
    { name: "Title", selected: false },
    { name: "UnreadBy owner", selected: false },
    { name: "Website", selected: false },
]
const Contact = [
    { name: "Name", selected: false },
    { name: "Email", selected: false },
    { name: "Job function", selected: false },
    { name: "No.of licenses", selected: false },
    { name: "Product name", selected: false },
    { name: "Account ID", selected: false },
    { name: "Assistant", selected: false },
    { name: "Asst. Phone", selected: false },
    { name: "Business categories", selected: false },
    { name: "BirthDate", selected: false },
    { name: "City", selected: false },
    { name: "Department", selected: false },
    { name: "Description", selected: false },
    { name: "Fax", selected: false },
    { name: "First name", selected: false },
    { name: "Home phone", selected: false },
    { name: "Individual", selected: false },
    { name: "Languages", selected: false },
    { name: "Last name", selected: false },
    { name: "Lead source", selected: false },
    { name: "Level", selected: false },
    { name: "Mobile", selected: false },
    { name: "Other Phone", selected: false },
    { name: "Phone", selected: false },
    { name: "Record owner", selected: false },
    { name: "Reports to", selected: false },
    { name: "Salutation", selected: false },
    { name: "Title", selected: false },
]
const Account = [
    { name: "Account Name", selected: false },
    { name: "Account Number", selected: false },
    { name: "Account Site", selected: false },
    { name: "Account Source", selected: false },
    { name: "Account Type", selected: false },
    { name: "Active", selected: false },
    { name: "Annual Revenue", selected: false },
    { name: "Business categories", selected: false },
    { name: "City", selected: false },
    { name: "Country", selected: false },
    { name: "Email", selected: false },
    { name: "Employees Ownership", selected: false },
    { name: "Industry", selected: false },
    { name: "Job Function", selected: false },
    { name: "Name", selected: false },
    { name: "Number of Locations", selected: false },
    { name: "No.of licenses", selected: false },
    { name: "Parent Account", selected: false },
    { name: "Phone", selected: false },
    { name: "Postal Code", selected: false },
    { name: "Record Owner", selected: false },
    { name: "SIS code", selected: false },
    { name: "Upsell Opportunity", selected: false },
    { name: "Website", selected: false },
    { name: "Year Started", selected: false },
]
const Opportunity = [
    { name: "Account", selected: false },
    { name: "Amount", selected: false },
    { name: "Close date", selected: false },
    { name: "Contract", selected: false },
    { name: "Created By", selected: false },
    { name: "Description", selected: false },
    { name: "Expected Revenue", selected: false },
    { name: "Forecast Category Name", selected: false },
    { name: "Last Modified By", selected: false },
    { name: "Lead Source", selected: false },
    { name: "Next stop", selected: false },
    { name: "Name", selected: false },
    { name: "Owner", selected: false },
    { name: "Price Book2", selected: false },
    { name: "Campaign", selected: false },
    { name: "IsPrivate", selected: false },
    { name: "Probability", selected: false },
    { name: "Total Opportunity Quantity", selected: false },
    { name: "Stage name", selected: false },
    { name: "Type", selected: false },
]