import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { GET_CAMPAIGN_STATUS_DROPDOWN, GET_CAMPAIGN_STATUS_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { dateTimeFormatWithMomentForReports } from 'Helper/Utils/Utils';
import React, { Component } from 'react';
import { RSPTooltip } from 'Components/Tooltip';
import DownloadCSVModal from '../../Components/DownloadCSVModal';

const initialDataState = {
    sort: [
        {
            field: "name",
            dir: "asc"
        },
    ],
    take: 5,
    skip: 0
}
class SMSCommunicationStatus extends Component {
    static contextType = authContext
    state = {
        dataState: initialDataState,
        latestDate: this.props.latestDate,
        rowKey: this.props.rowKey,
        listStatusError: false,
        defaultDropdownData: "Delivered",
        defaultDropdownValue: "Delivered",
        campaignStatusDropdownData: [],
        campaignStatusList: null,
        campaignId: this.props.campaignId,
        isOpenDownloadCSVModal: false,
    }

    componentDidMount = () => {
        this.getCampaignStatusDropdown()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.setState({ campaignId: this.props.campaignId, rowKey: this.props.rowKey, latestDate: this.props.latestDate, })
            if (this.props.rowKey !== prevProps.rowKey) {
                let statusParams = {
                    status: "Delivered",
                    rowKey: this.props.rowKey
                }
                this.getCampaignStatus(statusParams)
            }
        }
    }

    getCampaignStatusDropdown = () => {

        const params = {
            "channelId": 2
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_DROPDOWN,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        campaignStatusDropdownData: res?.data?.channelKeyDetailList || [],
                        defaultDropdownData: res?.data?.channelKeyDetailList[0].value
                    })
                }
            },
            fail: err => { }
        })
    }

    getCampaignStatus = data => {

        this.setState({ listStatusError: false });
        const params = {
            "campaignId": this.state.campaignId,
            "channelId": 2,
            "status": data?.status,
            "rowkey": data?.rowKey
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status !== undefined && res?.data?.activityList?.length) {
                    this.setState({ campaignStatusList: res?.data?.activityList || [] })
                } else {
                    this.setState({
                        campaignStatusList: [],
                        listStatusError: true
                    })
                }
            },
            fail: err => {
                this.setState({
                    campaignStatusList: [],
                    listStatusError: true
                })
            }
        })

    }
    render() {
        return (
            <><div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    <h3 className="marginB15">Communication status ({this.state.campaignStatusList?.length})</h3>
                    {this.state.campaignStatusList?.length > 0 && <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(this.state.latestDate)}</span>)</span>}
                </div>
                <div className='d-flex justify-content-end'>
                    <BootstrapDropdown
                        data={this.state.campaignStatusDropdownData || []}
                        defaultItem={this.state.defaultDropdownData}
                        customAlignRight={true}
                        className="fs18-btn"
                        alignRight
                        fieldKey='name'
                        onSelect={(item, index) => {
                            this.setState({
                                campaignStatusList: [],
                                defaultDropdownData: item.name,
                                defaultDropdownValue: item.value
                            })
                            let statusParams = {
                                status: item.value,
                                rowKey: this.state.rowKey
                            }
                            this.getCampaignStatus(statusParams)
                        }}
                    />
                    <RSPTooltip text={"Download CSV"} position="top">
                        <i className={`icon-sd-csv-medium icons-md blue ml10 cp ${this.state.campaignStatusList?.length !== 0 ? "" : "click-off"}`} onClick={() => {
                            this.setState({ isOpenDownloadCSVModal: true })
                        }} />
                    </RSPTooltip>
                </div>
                {this.state.isOpenDownloadCSVModal &&
                    <DownloadCSVModal
                        isOpen={this.state.isOpenDownloadCSVModal}
                        channelId={2}
                        isForm={false}
                        campaignId={this.state.campaignId}
                        rowKey={this.state.rowKey}
                        status={this.state.defaultDropdownValue}
                        onChangeIsOpen={(value) => {
                            this.setState({ isOpenDownloadCSVModal: false })
                        }} />
                }
            </div>
                {this.state.campaignStatusList?.length ?
                    <Grid
                        data={process(this.state.campaignStatusList, this.state.dataState || [])}
                        {...this.state.dataState}
                        scrollable={"none"}
                        className="grid-pagenation-del grid-replaceable mb0"
                        sortable={true}
                        pageable={false}
                        onDataStateChange={(e) => this.setState({ dataState: e.dataState })}
                    >
                        <GridColumn field="rowNo" filter="text" columnMenu={ColumnMenu} title="S.No." />
                        <GridColumn field="mobileNo" filter="text" columnMenu={ColumnMenu} title="Mobile Number" />
                    </Grid>
                    : <SkeletonTable count={4} isError={this.state.listStatusError} />
                }</>
        );
    }
}

export default SMSCommunicationStatus;

const ColumnMenu = props => {

    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;

};