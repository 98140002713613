import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { RSPTooltip } from 'Components/Tooltip';
import React, { useState, useEffect } from 'react';

export const RTabbar = props => {

  const [selected, setSelected] = useState(props.defaultSelectedItem)


  const setSelectedIndex = (index) => {
    props.callBack(props.tabData[index], index)
    setSelected(index)
  }

  const setSelectedTabClass = (index) => {
    if (selected === index) {
      return props.activeClass
    }
    return props.defaultClass
  }
  useEffect(() => {

    if (props.defaultSelectedObjectValue) {
      const index = props.tabData.findIndex(x => x[props.defaultSelectedObjectKey] === props.defaultSelectedObjectValue);
      setSelected(index);
    } else {
      setSelected(props.defaultSelectedItem);
    }

  }, [selected, props.defaultSelectedItem, props.tabData])

  return (
    <ul className={`rs-chart-tab ${props.dynamicTab} ${props.or ? 'or-tab' : ''} ${props.animate ? 'animate-tab' : ''}`}>
      {
        props.tabData.map((item, index) => {
          return (
            <li
              className={`${setSelectedTabClass(index)} ${item.activeTip ? "active-arrow" : ""} ${item.disable === true ? "click-off" : ""}`}
              key={index}
              onClick={() => {
                setSelectedIndex(index)
              }}>
              {item.image && <img src={item.image} alt={item.image} />}

              {item.iconLeft && <i className={`${item.iconLeft}`}></i>}
              {item.text && <span>{`${item.text}`}</span>}

              {props.arrow && <div className="arrowBar"></div>}
              {props.subText && <h3>{`${item.text2}`}</h3>}
              {item.icon && <i className={`${item.icon}`}></i>}
              {props.or && <span className="or-divider user-select-none"></span>}
            </li>
          )
        })
      }
      {props.animate && <span className='animate-key'></span>}
    </ul>
  )
}

export const RTabbar1 = props => {

  const [selected, setSelected] = useState(props.defaultSelectedItem)
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);

  const setSelectedIndex = (index) => {
    props.callBack(props.tabData[index], index)
    setSelected(index)
  }

  const setSelectedTabClass = (index) => {
    if (selected === index) {
      return props.activeClass
    }
    return props.defaultClass
  }
  useEffect(() => {
    setSelected(props.defaultSelectedItem);
  }, [selected, props.defaultSelectedItem])

  return (
    <div className={`${props.mainClass}`}>
      <ul className={`rs-chart-tab rs-chart-tab2 ${props.dynamicTab} ${props.or ? 'or-tab' : ''}`}>
        {
          props.tabData.map((item, index) => {
            return (
              <>
                <li className={`${setSelectedTabClass(index)} ${item.disable ? "click-off" : ""}`}
                  key={index}
                >
                  <div className="tab-text-vt">
                    <div className="tab-text" onClick={() => {
                      setSelectedIndex(index)
                    }}>
                      {item.image && <img src={item.image} alt={item.image} />}

                      {item.iconLeft && <i className={`${item.iconLeft}`}></i>}
                      {item.text && <span>{`${item.text}`}</span>}

                      {props.arrow && <div className="arrowBar"></div>}
                      {props.subText && <h3>{`${item.text2}`}</h3>}
                      {item.icon && <i className={`${item.icon}`}></i>}
                      {props.or && <span className="or-divider"></span>}
                    </div>
                    {props.type === "split" && index > 1 && index === (props.tabData.length - 1) && props.delete &&
                      <div className="remove-btn">
                        <i className={'icon-sd-circle-minus-fill-edge-medium icons-md red'} onClick={() => {
                          setRemoveIndex(index);
                          setIsOpenDeletePopup(!isOpenDeletePopup);
                        }}></i>
                      </div>
                    }
                    {props.type === "goal" && index > 0 && index === (props.tabData.length - 1) && props.delete &&
                      <div className="remove-btn">
                        <i className={'icon-sd-circle-minus-fill-edge-medium icons-md red'} onClick={() => {
                          setRemoveIndex(index);
                          setIsOpenDeletePopup(!isOpenDeletePopup);
                        }}></i>
                      </div>
                    }
                  </div>
                </li>

              </>
            )
          })
        }
      </ul>
      {isOpenDeletePopup ?
        <RSConfirmAlert
          title="Are you sure, you want to delete it?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              props.deleteTabData(removeIndex);
              setSelectedIndex(removeIndex - 1);
            }
            setIsOpenDeletePopup(!isOpenDeletePopup);
          }} /> : null}
    </div>
  )
}

export const RTabbar2 = props => {

  const [selected, setSelected] = useState(props.defaultSelectedItem)
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);

  const setSelectedIndex = (index) => {
    props.callBack(props.tabData[index], index)
    setSelected(index)
  }

  const setSelectedTabClass = (index) => {
    if (selected === index) {
      return props.activeClass
    }
    return props.defaultClass
  }
  useEffect(() => {
  }, [selected])

  return (
    <div>
      <ul className={`rs-chart-tab  ${props.dynamicTab} ${props.or ? 'or-tab' : ''}`}>
        {
          props.tabData.map((item, index) => {
            return (
              <>
                <li className={`${setSelectedTabClass(index)} ${item.disable ? "click-off" : ""}`}
                  key={index}
                >
                  <div className="tab-text-vt">
                    <div className="tab-text" onClick={() => {
                      setSelectedIndex(index)
                    }}>
                      {item.image && <img src={item.image} alt={item.image} />}

                      {item.iconLeft && <i className={`${item.iconLeft}`}></i>}
                      {item.text && <span>{`${item.text}`}</span>}

                      {props.arrow && <div className="arrowBar"></div>}
                      {props.subText && <h3>{`${item.text2}`}</h3>}
                      {item.icon && <i className={`${item.icon}`}></i>}
                      {props.or && <span className="or-divider"></span>}
                    </div>
                    {index !== 0 &&
                      <div className="remove-btn">
                        <i className={'icon-sd-circle-minus-fill-edge-medium icons-md red'} onClick={() => {
                          setRemoveIndex(index);
                          setIsOpenDeletePopup(!isOpenDeletePopup);
                        }}></i>
                      </div>
                    }
                  </div>
                </li>

              </>
            )
          })
        }
      </ul>
      {isOpenDeletePopup ?
        <RSConfirmAlert
          title="Are you sure, you want to delete it?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              props.deleteTabData(removeIndex);
              setSelectedIndex(removeIndex - 1);
            }
            setIsOpenDeletePopup(!isOpenDeletePopup);
          }} /> : null}
    </div>
  )
}
export const RTabbar3 = props => {

  const [selected, setSelected] = useState(props.defaultSelectedItem)
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);

  const setSelectedIndex = (index) => {
    props.callBack(props.tabData[index], index)
    setSelected(index)
  }

  const setSelectedTabClass = (index) => {
    if (selected === index) {
      return props.activeClass
    }
    return props.defaultClass
  }
  useEffect(() => {
  }, [selected])

  return (
    <>
      <ul className={`rs-chart-tab ${props.dynamicTab} ${props.or ? 'or-tab' : ''}`}>
        {
          props.tabData.map((item, index) => {
            return (
              <>
                <li className={`${setSelectedTabClass(index)} ${item.disable ? "click-off" : ""}`}
                  key={index}
                  onClick={() => {
                    setSelectedIndex(index)
                  }}>
                  {item.image && <img src={item.image} alt={item.image} />}

                  {item.iconLeft && <i className={`${item.iconLeft}`}></i>}
                  {item.text && <span>{`${item.text}`}</span>}

                  {props.arrow && <div className="arrowBar"></div>}
                  {props.subText && <h3>{`${item.text2}`}</h3>}
                  {item.icon && <i className={`${item.icon}`}></i>}
                  {props.or && <span className="or-divider"></span>}
                </li>
                {index !== 0 &&
                  <div className="remove-btn">
                    <i className={'icon-sd-circle-minus-fill-edge-medium icons-md red'} onClick={() => {
                      setRemoveIndex(index);
                      setIsOpenDeletePopup(!isOpenDeletePopup);
                    }}></i>
                  </div>
                }
              </>
            )
          })
        }
      </ul>
      {isOpenDeletePopup ?
        <RSConfirmAlert
          title="Are you sure, you want to delete it?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              props.deleteTabData(removeIndex);
              setSelectedIndex(removeIndex - 1);
            }
            setIsOpenDeletePopup(!isOpenDeletePopup);
          }} /> : null}
    </>
  )
}

export const RTabbar4 = props => {

  const [selected, setSelected] = useState(props.defaultSelectedItem)


  const setSelectedIndex = (index) => {
    props.callBack(props.tabData[index], index)
    setSelected(index)
  }

  const setSelectedTabClass = (index) => {
    if (selected === index) {
      return props.activeClass
    }
    return props.defaultClass
  }
  useEffect(() => {

    if (props.defaultSelectedObjectValue) {
      const index = props.tabData.findIndex(x => x[props.defaultSelectedObjectKey] === props.defaultSelectedObjectValue);
      setSelected(index);
    } else {
      setSelected(props.defaultSelectedItem);
    }

  }, [selected, props.defaultSelectedItem, props.tabData])

  return (
    <ul className={`rs-chart-tab ${props.dynamicTab} ${props.or ? 'or-tab' : ''} ${props.animate ? 'animate-tab' : ''}`}>
      {
        props.tabData.map((item, index) => {
          return (
            <RSPTooltip key={index} position="top" text={item.toolTipValue ? item.toolTipValue : ""}>
              <li
                className={`${setSelectedTabClass(index)} ${item.activeTip ? "active-arrow" : ""} ${item.disable === true ? "click-off" : ""}`}
                key={index}
                onClick={() => {
                  setSelectedIndex(index)
                }}>
                {item.image && <img src={item.image} alt={item.image} />}

                {item.iconLeft && <i className={`${item.iconLeft}`}></i>}
                {item.text && <span>{`${item.text}`}</span>}

                {props.arrow && <div className="arrowBar"></div>}
                {props.subText && <h3>{`${item.text2}`}</h3>}
                {item.icon && <i className={`${item.icon}`}></i>}
                {props.or && <span className="or-divider user-select-none"></span>}
              </li>
            </RSPTooltip>
          )
        })
      }
      {props.animate && <span className='animate-key'></span>}
    </ul>
  )
}