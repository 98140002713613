import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";
import { CHECK_NAME_EXIST } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { isEmpty } from "Helper/Utils/Utils";
import React, { memo, useContext } from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const CopyForm = ({ data }) => {
  const {
    copyFormFlag,
    setCopyFormFlag,
    formName,
    setFormNameErr,
    formNameErr,
    handleCancel,
    handleSaveCopy,
    setFormName,
  } = data;
  const context = useContext(authContext);

  const [btnDisable, setBtnDisabled] = useState(true);

  const isNamePresent = () => {
    if (formNameErr === null) {
      let params = {
        formName: formName.trim(),
      };
      connectServer({
        path: CHECK_NAME_EXIST,
        params,
        // loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (!res.status) {
            setFormNameErr(null);
          } else {
            setFormNameErr("Form name already exists");
          }
        },
        fail: (err) => { },
      });
    }
  };


  return (
    <>
      <Modal
        show={copyFormFlag}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
        onHide={() => {
          setCopyFormFlag(false);
          setFormNameErr(null);
          setBtnDisabled(false);
        }}
      >
        <Modal.Header closeButton>
          <h2>Form name</h2>
        </Modal.Header>
        <Modal.Body>
          <Row className="split-ab-modal">
            <Col>
              <RSInput2
                ph="Enter form name"
                required={true}
                val={formName}
                max={50}
                cb={(text) => {
                  var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                  let val = text.trim(" ");
                  if (val === "") {
                    setFormNameErr("Enter form name");
                    setFormName(text);
                  } else if (!regex.test(val)) {
                    setFormNameErr("Special character(s) are not allowed");
                  } else {
                    setFormName(text);
                    setFormNameErr(null);
                  }
                  setBtnDisabled(false);
                }}
                ob={(text) => {
                  if (!isEmpty(text.trim())) {
                    setFormName(text.trim());
                    setFormNameErr(null);
                    isNamePresent();
                  } else {
                    setFormName(text.trim());
                  }
                }}
                errorMessage={formNameErr}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container d-flex justify-content-end">
            <RSSecondaryBtn
              padding="0px"
              onClick={() => {
                setBtnDisabled(false);
                handleCancel();
              }}
            >
              Cancel
            </RSSecondaryBtn>

            <RSPrimaryBtn
              className={formNameErr !== null || btnDisable ? "click-off" : ""}
              onClick={() => {
                handleSaveCopy();
              }}
            >
              Save
            </RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(CopyForm);
