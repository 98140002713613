import React, { Component } from "react";
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSTextInputWithIcon } from "Components/RSInputs";

class CustomFilterUI extends React.Component {
  state = {
    operator: "eq",
    filterValue: "",
  };
  onChange = (event) => {
    const value = event;
    const { firstFilterProps } = this.props;
    firstFilterProps.onChange({
      value,
      operator: "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };
  render() {
    const { firstFilterProps } = this.props;
    const value = firstFilterProps.value;
    return (
      <div>
        <RSDropdownList
          data={filterOptions}
          defaultItem={filterOptions[0]}
          onChange={(value) => this.onChange(value)}
        />
        <RSTextInputWithIcon
          name="firstname"
          cb={(value) => this.onChange(value)}
        />
        {/* <div className="btn-container d-flex justify-content-end">
          <RSSecondaryBtn onClick={() => {}}>Clear</RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              this.props.onApplyClick(this.state.filteredData);
            }}
          >
            Apply
          </RSPrimaryBtn>
        </div> */}
      </div>
    );
  }
}

export default CustomFilterUI;
const filterOptions = ["Starts with", "Is equal to", "Is not equal to"];
