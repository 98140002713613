
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import styled from "styled-components"

export const RSRadio = ({ lbl, children, className, name, labelId, checked, cb }) => {
    return <RSRadioLblContainer className={`radioContainer ${className ? className : ''}`}>
        <RSRadioInput
            value={lbl}
            id={labelId}
            name={name}
            className="rs-radio"
            type="radio"
            checked={checked}
            onChange={e => {
                
                cb(e)
            }}
        />
        <RSRadioLabel htmlFor={labelId}>{lbl}</RSRadioLabel>
        {children}
    </RSRadioLblContainer>
}

export const RSCheckbox = ({ lbl, checked, children, className, icon, cb }) => {
    return <RSCheckboxLblContainer className={`checkboxContainer ${className ? className : ''}`}>
        <RSCheckboxInput id={lbl} className="rs-checkbox" type="checkbox" checked={checked} onChange={e => cb(!checked)} />
        <RSCheckboxLabel className="checkmark" htmlFor={lbl}></RSCheckboxLabel>
        {lbl}
        {children}
    </RSCheckboxLblContainer>
}
export const RSCheckboxIcon2 = (props) => {
    const [checkedItems, setCheckedItems] = useState({});

    const onChecked = (e, checkbox) => {
        let checked = e.target.checked;
        let item = { ...checkbox };
        item["checked"] = checked;
        let items = { ...checkedItems, [e.target.name]: item };

        let mappedArry = Object.keys(items).map(key => items[key].checked && items[key])
        let filteredArry = mappedArry.filter(value => value !== false)
        setCheckedItems(items);
        props.onChecked && props.onChecked(filteredArry);
    };

    return <>
        {
            props.arry.map((checkbox) => {
                return <Col md={{ span: checkbox.span || 3, offset: checkbox.offset || 1 }}>
                    <RSCheckboxLblContainer className={`checkboxContainer ${props.className ? props.className : ''}`}>
                        <input
                            className="rs-checkbox"
                            id={checkbox.label}
                            name={checkbox.name}
                            type="checkbox"
                            onChange={(e) => {
                                onChecked(e, checkbox)
                            }}
                        />
                        <RSCheckboxLabel className="checkmark" htmlFor={checkbox.label}></RSCheckboxLabel>
                        <i className={checkbox.icon}></i>
                        {checkbox.label}
                    </RSCheckboxLblContainer>
                </Col>
            })
        }
    </>
}

export const RSCheckboxIcon = ({ lbl, checked, children, className, icon, cb }) => {
    return <RSCheckboxLblContainer className={`checkboxContainer ${className ? className : ''}`}>
        <RSCheckboxInput id={lbl} className="rs-checkbox" type="checkbox" checked={checked} onChange={e => cb(!checked)} />
        <RSCheckboxLabel className="checkmark" htmlFor={lbl}></RSCheckboxLabel>
        {children}
        {lbl}
    </RSCheckboxLblContainer>
}

export const RSInput2 = props => {
    const { name, ph, val, type, cls, cls2, cb, min, max, pattern, errorMessage,ob,of,disabled,keyboardType } = props
    return <div className={`position-relative w-100 ${cls2}`}>
        {errorMessage && <div className="validation-message">{errorMessage}</div>}
        <RSTextInput
            name={name}
            className={`slashText ${cls}`}
            placeholder={ph}
            required
            value={val}
            type={type ? type : "text"}
            min={min && min}
            max={max && max}
            disabled={disabled}
            onChange={e => {
                if(cb && keyboardType === undefined){
                    cb(e.target.value)
                }
                else if(cb && keyboardType !== undefined && keyboardType === 'number'){
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        cb(e.target.value)
                     }
                }
                
            }}
            onBlur={e => ob && ob(e.target.value)}
            onFocus={e => of && of(true)}
            pattern={pattern && pattern}
            errorMessage={errorMessage}
        />
    </div>
}
export const RSTextInputWithIcon = props => {
    const { name, ph, val, type, img, icon, altName, className, ob, of, cb, min, max, errorMessage } = props
    return <div className="position-relative">
        {errorMessage && <div className="validation-message">{errorMessage}</div>}
        <InputIconWrapper>
            <RSTextInput
                autoComplete="off"
                name={name}
                placeholder={ph}
                required
                className={className}
                value={val}
                type={type ? type : "text"}
                onBlur={e => ob && ob(e.target.value)}
                onFocus={e => of && of(true)}
                onChange={e => cb && cb(e.target.value)}
                min={min && min}
                max={max && max}
            />
            {img && <InputImgIcon src={img} alt={altName} />}
            {icon && <InputIcon className={`${icon} input-icon`}></InputIcon>}
        </InputIconWrapper>
    </div>
}

const RSCheckboxLblContainer = styled.label` `
const RSCheckboxInput = styled.input``
const RSCheckboxLabel = styled.span``

const RSRadioLblContainer = styled.div` `
const RSRadioInput = styled.input``
const RSRadioLabel = styled.label``

const RSTextInput = styled.input`
    margin-bottom: 30px;
`
const InputIconWrapper = styled.div`
    position: relative;
 `
const InputIcon = styled.i`
    position: absolute;
    right: 7px;
    top: 4px;
 `
const InputImgIcon = styled.img`
    position: absolute;
    right: 7px;
    top: 9px;
 `