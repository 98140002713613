import { filter } from "d3";

export const getConditionTypeValues = (fieldType, partnerType) => {
    switch (fieldType) {
        case "8": return filterValue.DateTime;
        case "4": return filterValue.Integer;
        case "3": return filterValue.Integer;
        case "5": return filterValue.Boolean;
        case "9": return partnerType === "range" ? filterValue.Range : filterValue.String;
        default: return filterValue.String;
    }
}
export const getConditionValue = (fieldType, partnerType) => {
    switch (fieldType) {
        case "8": return {
            label: 'Between',
            value: "between",
            id: 7
        };
        case "4": return filterValue.Integer;
        case "3": return filterValue.Integer;
        case "5": return {
            label: 'Is equal to',
            value: "isequalto",
            id: 1
        };
        case "9": return partnerType === "range" ? {
            label: 'Between',
            value: "between",
            id: 7
        } : {
            label: 'Is equal to',
            value: "isequalto",
            id: 1
        };
        default: return {
            label: 'Is equal to',
            value: "isequalto",
            id: 1
        };
    }
}
export const getValueType = (fieldType, partnerType) => {
    let valueType = "String";
    switch (fieldType) {
        case "8": valueType = "DateTime"; break;
        case "4": valueType = "Integer"; break;
        case "3": valueType = "Integer"; break;
        case "5": valueType = "Boolean"; break;
        case "9": valueType = partnerType === "range" ? "Range" : "CategoricalRange"; break;
        case "1": valueType = partnerType === "textbox" ? "Text" : "String"; break;
        default: valueType = "String"; break;
    }
    return valueType
}
export const filterValue = {
    String: [
        {
            label: 'Is equal to',
            value: "isequalto",
            id: 1
        },
        // {
        //     label: 'Is not equal to',
        //     value: "isnotequalto",
        //     id: 2
        // },
        // {
        //     label: 'Contains',
        //     value: "contains",
        //     id: 3
        // },
        // {
        //     label: 'Not contains',
        //     value: "notcontains",
        //     id: 4
        // },
        // {
        //     label: 'Like',
        //     value: "Like",
        //     id: 5
        // },
        // {
        //     label: 'Does not like',
        //     value: "Doesnotlike",
        //     id: 6
        // },
        // {
        //     label: 'Starts with',
        //     value: "Startswith",
        //     id: 7
        // },
        // {
        //     label: 'Ends with',
        //     value: "Endswith",
        //     id: 8
        // },
        // {
        //     label: 'Has no value',
        //     value: "hasnovalue",
        //     id: 9
        // },
        // {
        //     label: 'Has value',
        //     value: "hasvalue",
        //     id: 10
        // }
    ],
    Boolean: [
        {
            label: 'Is equal to',
            value: "isequalto",
            id: 1
        },
        // {
        //     label: 'Is not equal to',
        //     value: "isnotequalto",
        //     id: 2
        // },
        // {
        //     label: 'Contains',
        //     value: "contains",
        //     id: 3
        // },
        // {
        //     label: 'Not contains',
        //     value: "notcontains",
        //     id: 4
        // },
        // {
        //     label: 'Like',
        //     value: "Like",
        //     id: 5
        // },
        // {
        //     label: 'Does not like',
        //     value: "Doesnotlike",
        //     id: 6
        // },
        // {
        //     label: 'Starts with',
        //     value: "Startswith",
        //     id: 7
        // },
        // {
        //     label: 'Ends with',
        //     value: "Endswith",
        //     id: 8
        // },
        // {
        //     label: 'Has no value',
        //     value: "hasnovalue",
        //     id: 9
        // },
        // {
        //     label: 'Has value',
        //     value: "hasvalue",
        //     id: 10
        // }
    ],
    DateTime: [
        // 8
        // {
        //     label: 'Is equal to',
        //     value: "isequalto",
        //     id: 1
        // },
        // {
        //     label: 'Is not equal to',
        //     value: "isnotequalto",
        //     id: 2
        // },
        // {
        //     label: 'Is after or equal to',
        //     value: "isafterorequalto",
        //     id: 3
        // },
        // {
        //     label: 'Is after',
        //     value: "isafter",
        //     id: 4
        // },
        // {
        //     label: 'Is before or equal to',
        //     value: "isbeforeorequalto",
        //     id: 5
        // },
        // {
        //     label: 'Is before',
        //     value: "isbefore",
        //     id: 6
        // },
        {
            label: 'Between',
            value: "between",
            id: 7
        },
        // {
        //     label: 'Not between',
        //     value: "notbetween",
        //     id: 8
        // },
        // {
        //     label: 'Has no value',
        //     value: "hasnovalue",
        //     id: 9
        // },
        // {
        //     label: 'Has value',
        //     value: "hasvalue",
        //     id: 10
        // }
    ],
    Range: [
        // 8
        // {
        //     label: 'Is equal to',
        //     value: "isequalto",
        //     id: 1
        // },
        // {
        //     label: 'Is not equal to',
        //     value: "isnotequalto",
        //     id: 2
        // },
        // {
        //     label: 'Overlaps',
        //     value: "overlaps",
        //     id: 3
        // },
        // {
        //     label: 'Is after',
        //     value: "isafter",
        //     id: 4
        // },
        // {
        //     label: 'Does not Overlaps',
        //     value: "doesnotoverlaps",
        //     id: 5
        // },
        // {
        //     label: 'Is before',
        //     value: "isbefore",
        //     id: 6
        // },
        {
            label: 'Between',
            value: "between",
            id: 7
        },
        // {
        //     label: 'Is contained in',
        //     value: "iscontainedin",
        //     id: 8
        // },
        // {
        //     label: 'Is not contained in',
        //     value: "isnotcontainedin",
        //     id: 9
        // },
        // {
        //     label: 'Has value',
        //     value: "hasvalue",
        //     id: 10
        // },
        //{
        //     label: 'Has No Value',
        //     value: "hasnovalue",
        //     id: 11
        // }
    ],
    Integer: {
        // 4 & 3
        isequalto: 'Is equal to',
        // isnotequalto: 'Is not equal to',
        // isgreaterthanorequalto: 'Is greater than or equal to',
        // isgreaterthan: 'Is greater than',
        // islessthanorequalto: 'Is less than or equal to',
        // islessthan: 'Is less than',
        // between: 'Between',
        // hasnovalue: 'Has no value',
        // hasvalue: 'Has value',
    },
};

const getFieldType = (type) => {
    let fieldType = '1';
    switch (type) {
        case 's' || 'ss':
            fieldType = '1';
            break;
        case 'd' || 'ds':
            fieldType = '3';
            break;
        case 'i' || 'is':
            fieldType = '4';
            break;
        case 'dt' || 'dts':
            fieldType = '8';
            break;
        default:
            return null;
    }
    return fieldType;
};
const getFieldObject = (field, fieldType, category) => {
    let stringValue = field.Value;
    if (fieldType === '1') stringValue = field.Value?.split(',');
    if (fieldType === '8') stringValue = new Date(field.Value);
    const list = {
        category: field.Category,
        name: field.LiUIPrintableName,
        labelText: field.LiUIPrintableName,
        fieldType: fieldType,
        parentChildIdentify: field.ParentChildIdentify,
        solrFieldName: field.SOLRFieldName,
        solrCountValue: field.SOLRCountValue,
        isMultiValue: false,
        cattype: null,
        restype: field.restype,
        parentSOLRFieldName: field.ParentSOLRFieldName,
        seqNo: field.SeqNo,
        valueCollection: null,
        isAnnualReminder: false,
        isvirtualfield: field.IsVirtualField,
        isSwitched: true,
        isLoading: false,
        isStatus: true,
        attributeType: conditionValue[field.ConditionOperator],
        attributeValue: stringValue,
    };
    return list;
};
const conditionValue = {
    isequalto: 'Is equal to',
    isnotequalto: 'Is not equal to',
    contains: 'Contains',
    notcontains: 'Not contains',
    like: 'Like',
    doesnotlike: 'Does not like',
    startswith: 'Starts with',
    endswith: 'Ends with',
    hasnovalue: 'Has no value',
    hasvalue: 'Has value',
    isgreaterthanorequalto: 'Is greater than or equal to',
    isgreaterthan: 'Is greater than',
    islessthanorequalto: 'Is less than or equal to',
    islessthan: 'Is less than',
    between: 'Between',
    isafterorequalto: 'Is after or equal to',
    isafter: 'Is after',
    isbeforeorequalto: 'Is before or equal to',
    isbefore: 'Is before',
    notbetween: 'Not between',
};
export const getallAttributes = (zeroDayLists, inclusionLists, exclusionLists, filterLists) => {
    // const {
    //     filterLists,
    //     zeroDayLists = [],
    //     inclusions = [],
    //     exclusions = [],
    //     lookALikeAudLists = [],
    //     lookALikeAttrLists = [],
    // } = attributes;
    // // const inclusions = inclusionLists?.length ? inclusionLists : [];
    // // const exclusions = exclusionLists?.length ? exclusionLists : [];
    // const getCurrentAttributes = [
    //     ...filterLists,
    //     ...zeroDayLists,
    //     ...inclusions,
    //     ...exclusions,
    //     ...lookALikeAudLists,
    //     ...lookALikeAttrLists,
    // ];
    const getCurrentAttributes = [
        ...filterLists,
        ...zeroDayLists,
        ...inclusionLists,
        ...exclusionLists,
    ];
    return getCurrentAttributes.findIndex(({ attributeValue, attributeValueOne, attributeType, fieldType }) => {
        if (attributeType === 'Has no value' || attributeType === 'Has value') return false;
        if (!attributeValue) return true;
        else if (Array.isArray(attributeValue) && !attributeValue.length) return true;
        else if (
            (fieldType === '3' || fieldType === '4' || fieldType === '8') &&
            (attributeType === 'Between' || attributeType === 'Not between')
        ) {
            if (!attributeValueOne) return true;
        }
        // else if (attributeValue instanceof Date) return false;
        return false;
    });
};