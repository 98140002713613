import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSRadio } from "Components/RSInputs";
import {
  GET_TABLE_COLUMN_DATA,
  TABLE_LIST_CRM,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import TableDetails from "./TableDetails";

const ConnectionType = ({ payload, getColumnVal, setTableName }) => {
  const connectTyepList = [
    {
      id: 0,
      value: "-- Select --",
    },
    {
      id: 1,
      value: "CRM to Resulticks",
    },
    {
      id: 2,
      value: "Resulticks to CRM",
    },
  ];

  const context = useContext(authContext);

  const [connectType, setConnectType] = useState(connectTyepList[0]);
  const [tableList, setTableList] = useState(["a", "b", "contact"]);
  const [table, setTable] = useState(null);
  const [state, setState] = useState({
    leftColumnValues: [],
    rightColumnValues: [],
  });
  const getTableList = () => {
    context.globalStateData.setIsLoadingData(true);
    connectServer({
      path: TABLE_LIST_CRM,
      params: payload,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          setTableList(res.data.split(","));
        }
      },
      fail: (err) => { },
    });
  };
  useEffect(() => {
    getTableList();
  }, []);

  const handleTable = (eve) => {
    let tablename = eve.target.value;
    let params = { ...payload, tablename };
    setTable(tablename);
    setTableName(tablename);
    context.globalStateData.setIsLoadingData(true);
    connectServer({
      path: GET_TABLE_COLUMN_DATA,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let fillterData = res.data.split(",").map((e) => {
            return {
              name: e,
              tablename,
              selected: false,
            };
          });
          setState((pre) => ({ ...pre, leftColumnValues: fillterData }));
        }
      },
      fail: (err) => { },
    });
  };

  return (
    <>
      <div className="portlet-box-theme p0 mt23">
        <Row className="even py15 mx0">
          <Col md={{ span: 2, offset: 3 }} className="text-end label-row">
            <label>connection type</label>
          </Col>
          <Col md={3}>
            <RSDropdownList
              name="connctType"
              data={connectTyepList}
              value={connectType}
              dataItemKey="id"
              textField="value"
              className="mb0 required"
              defaultItem={"--Select--"}
              onChange={(e) => {
                setConnectType(e.target.value);
              }}
            />
          </Col>
        </Row>
        {connectType?.id == 1 && (
          <>
            <Row className="even py15 mx0">
              <Col md={{ span: 2, offset: 3 }} className="text-end label-row">
                <label>Table</label>
              </Col>
              <Col md={3}>
                <RSDropdownList
                  name="table"
                  data={tableList}
                  value={table}
                  className="mb0 required"
                  defaultItem={"--Select--"}
                  onChange={handleTable}
                  disabled={table !== null ? true : false}
                  onBlur={(e) => { }}
                />
              </Col>

              <Col>
                <div className="mt10">
                  <i
                    onClick={() => {
                      setTable(null);
                      setTableName(null);
                      setState((pre) => ({
                        ...pre,
                        leftColumnValues: [],
                        rightColumnValues: [],
                      }));
                    }}
                    className="icon-sd-refresh-medium icons-md blue cursor-pointer"
                  ></i>
                </div>
              </Col>
            </Row>
            <div className="p15">
              <TableDetails
                tabledetails={"Contact"}
                leftColumnValues={state.leftColumnValues}
                rightColumnValues={state.rightColumnValues}
                getSelectedData={(first, second) => {
                  setState({
                    leftColumnValues: first.leftColumnValues,
                    rightColumnValues: first.rightColumnValues,
                  });
                  getColumnVal(first.rightColumnValues);
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConnectionType;
