export const chartSize = '15px';
export const titleSize = '15px';
export const labelSize = '12px';
export const legendSize = '15px';
export const tooltipSize = '13px';
export const pieLabelSize = '20px';
export const guageLabelSize = '22px';

export const primaryFont = 'MuktaRegular';
export const pieLabelFont = 'MuktaRegular';

export const fontLight = 'MuktaLight';
export const fontRegular = 'MuktaRegular';
export const fontMedium = 'MuktaMedium';
export const fontBold = 'MuktaBold';

// ::Font size::
export const rsFontxxs  = 12;
export const rsFontxs   = 15;
export const rsFontxsm  = 16;
export const rsFontsm   = 18; // 19
export const rsFontxmd  = 21;
export const rsFontmd   = 24;
export const rsFontlg   = 32;
export const rsFontxlg  = 42;
export const rsFontxxl  = 52;

// ::Icon size::
export const rsIconssm  = 15;
export const rsIconsmd  = 24;
export const rsIconslg  = 32;