import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { RSInput2 } from 'Components/RSInputs';
import { RSPTooltip } from 'Components/Tooltip';
import { DELETE_DOMAIN_NAME, DOMAIN_NAME_EXISTS, GET_DKIM_STATUS, GET_DKIM_VALUES, SAVE_DKIM_STATUS } from 'Helper/Constants/endPoint';
import { ENTER_DOMAIN_NAME } from 'Helper/Constants/validationMessage';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import * as images from 'Assets/Images';


const initialDataState = {
    skip: 0,
    take: 5,
};

class DomainSettings extends Component {
    static contextType = authContext;
    state = {
        domainName: this.props.domainName,
        domainStatus: this.props.domainStatus,
        domainId: this.props.domainId,
        errDomainName: null,
        domainNameList: [],
        DKIMValues: [],
        dataState: initialDataState,
        isDeleteModalOpen: false,
        index: 0
    }

    componentDidMount = () => {
        if (this.props.domainName !== "") {
            //this.getDKIMValues(this.props.domainName);
        }
    }

    getDKIMValues = (domainName) => {
        let params = {
            "domainName": domainName
        }
        connectServer({
            path: GET_DKIM_VALUES,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let tempdkimValues = res.data;
                    tempdkimValues.map((item, index) => {
                        item.status = "Unverified";
                        item.copyText = "Copy"
                    })
                    this.setState({ index: 1, DKIMValues: tempdkimValues });
                } else {
                    this.setState({ index: 0, DKIMValues: [] });
                }
            },
            fail: err => { }
        })
    }

    getDKIMStatus = (domainName, index) => {
        let params = {
            "domainName": domainName
        }
        connectServer({
            path: GET_DKIM_STATUS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                let tempdkimValues = [...this.state.DKIMValues];
                tempdkimValues[index].status = res.data.message;
                this.setState({ index: 1, DKIMValues: tempdkimValues });
            },
            fail: err => { }
        })
    }
    deleteDomainName = (domainName, smtpSettingsId) => {
        let params = {
            "domainName": domainName,
            "smtpDomainSettingId": smtpSettingsId
        }
        connectServer({
            path: DELETE_DOMAIN_NAME,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                this.props.onChangeIndex(0)
            },
            fail: err => { }
        })
    }
    isDomainNameExists = (domainName) => {
        let params = {
            "domainName": domainName
        }
        connectServer({
            path: DOMAIN_NAME_EXISTS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ errDomainName: null });
                } else {
                    this.setState({ errDomainName: res.message });
                }
            },
            fail: err => { }
        })
    }
    getIsEnableSubmitButton = () => {
        let enable = false;
        this.state.DKIMValues?.map((item) => {
            if (item.status && item.status.toLowerCase() === "verified") {
                enable = true
                return enable;
            } else {
                enable = false
            }
        })
        return enable
    }
    render() {
        return (
            <>
                <Row>
                    <Col md={3}>
                        <label className="label-row">Sender domain name</label>
                    </Col>
                    <Col md={6}>
                        <RSInput2
                            name="websiteName"
                            ph={"Enter domain name"}
                            required={true}
                            disabled={((this.state.domainName !== "" && this.state.errDomainName === null && this.state.domainNameList.length > 0) || this.props.domainId !== 0) ? true : false}
                            val={this.state.domainName}
                            cb={(value) => {
                                let errMsg = value.length ? null : ENTER_DOMAIN_NAME;
                                this.setState({ domainName: value, errDomainName: errMsg });
                            }}
                            ob={(value) => {
                                let errMsg = value.length ? null : ENTER_DOMAIN_NAME;
                                let regex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                                if (errMsg === null) {
                                    if (value.length > 253) {
                                        this.setState({ errDomainName: "Domain name length does not exists 253" });
                                    } else {
                                        let domainArray = value.split(".");
                                        if (domainArray.length > 3) {
                                            this.setState({ errDomainName: "Domain name can have maximum 2 dots only" });
                                        } else {
                                            let morethan = domainArray.filter(item => item.length > 63).length > 0 ? true : false;
                                            if (morethan) {
                                                this.setState({ errDomainName: "Each labels length does not exists 63" });
                                            } else {
                                                if (regex.test(value)) {
                                                    this.setState({ domainName: value, errDomainName: errMsg });
                                                    this.isDomainNameExists(value);
                                                } else {
                                                    this.setState({ errDomainName: "Enter valid domain name" })
                                                }
                                            }
                                        }

                                    }
                                } else {
                                    this.setState({ domainName: value, errDomainName: errMsg });
                                }
                            }}
                            errorMessage={this.state.errDomainName}
                        />
                    </Col>
                </Row>
                <div className="btn-container d-flex justify-content-end marginT15 mb20">
                    <RSSecondaryBtn onClick={() => this.props.onChangeIndex(0)}>
                        Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn className={(this.state.domainName !== "" && this.state.domainNameList.length <= 0 && this.state.errDomainName === null) ? "" : "click-off"}
                        onClick={() => {
                            let domainData = {
                                domainName: this.state.domainName,
                                status: this.state.domainStatus === "SUCCESS" ? "Verified" : "Unverified"
                            }
                            let domainList = [...this.state.domainNameList]
                            domainList.push(domainData);
                            this.setState({ domainNameList: domainList });
                        }}
                    >
                        {this.props.domainName !== "" ? "Update" : "Add"}
                    </RSPrimaryBtn>
                </div>
                {this.state.index === 0 && this.state.domainNameList.length ?
                    <Grid
                        data={this.state.domainNameList?.slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip)}
                        total={this.state.domainNameList?.length}
                        scrollable={"none"}
                        skip={this.state.dataState.skip}
                        take={this.state.dataState.take}
                        sortable={false}
                        className="no-grid-pagenation-del"
                    >
                        <GridColumn title="Sender domain name" field="domainName" />
                        <GridColumn title="Status" field="status" />
                        <GridColumn
                            width={110}
                            title="Action"
                            cell={(props) => {
                                return (
                                    <td className="camp-icon">
                                        <div className="table-action-btn">
                                            <RSPTooltip text="Verify domain" position="top">
                                                <i
                                                    className={`icon-sd-eye-medium icons-md blue cursor-pointer ${props.dataItem.status === "Verified" ? "click-off" : ""}`}
                                                    onClick={() => {
                                                        this.getDKIMValues(this.state.domainName);

                                                    }}
                                                />
                                            </RSPTooltip>
                                            <RSPTooltip text="Edit" position="top">
                                                <i
                                                    className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer click-off"
                                                    onClick={() => {
                                                    }}
                                                />
                                            </RSPTooltip>
                                            <RSPTooltip text="Delete" position="top">
                                                <i
                                                    className="icon-sd-delete-medium icons-md blue cursor-pointer"
                                                    onClick={() => {
                                                        this.setState({ isDeleteModalOpen: true });
                                                    }}
                                                />
                                            </RSPTooltip>
                                        </div>
                                    </td>
                                );
                            }}
                        />
                    </Grid> : this.state.DKIMValues.length ?
                        <>
                            <h3>
                                DNS records - CNAME
                                <RSPTooltip text={"Publish the below CNAME records to your domain's DNS provider."} position="top">
                                    <i className='icon-sd-circle-info-medium icons-md blue ml10 cp' />
                                </RSPTooltip>
                            </h3>
                            <Grid
                                data={this.state.DKIMValues?.slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip)}
                                total={this.state.DKIMValues?.length}
                                scrollable={"none"}
                                skip={this.state.dataState.skip}
                                take={this.state.dataState.take}
                                sortable={false}
                                className="no-grid-pagenation-del dns-recored-grid"
                            >
                                <GridColumn title="Hostname" field="cName" className="text-end" />
                                <GridColumn title="Value" field="value" />
                                <GridColumn title="Status" field="status" />
                                <GridColumn
                                    width={150}
                                    title="Action"
                                    cell={(props) => {
                                        return (
                                            <td className="camp-icon">
                                                <div className="table-action-btn">
                                                    <RSPTooltip text={props.dataItem.copyText} position="top">
                                                        <i
                                                            className="icon-sd-copy-large icon-lg blue cursor-pointer"
                                                            onClick={() => {
                                                                let selectedDataitem = props.dataItem;
                                                                let copyText = {
                                                                    cName: selectedDataitem.cName,
                                                                    value: selectedDataitem.value
                                                                }
                                                                navigator.clipboard.writeText(JSON.stringify(copyText));
                                                                let tempDKYMValues = [...this.state.DKIMValues];
                                                                tempDKYMValues[props.dataIndex].copyText = "Copied";
                                                                this.setState({ DKIMValues: tempDKYMValues })
                                                            }}
                                                        />
                                                    </RSPTooltip>
                                                    <RSPTooltip text="Validate" position="top">
                                                        <img src={images.Request_Approval_Pending} height="28" className="blue cp click-off"
                                                            onClick={() => {
                                                                this.getDKIMStatus(props.dataItem.cName, props.dataIndex)
                                                            }}
                                                        />
                                                    </RSPTooltip>
                                                </div>
                                            </td>
                                        );
                                    }}
                                />
                            </Grid>
                            <div className="btn-container d-flex justify-content-end marginT15 mb20 mt10">
                                <RSSecondaryBtn onClick={() => this.props.onChangeIndex(0)}>
                                    Cancel
                                </RSSecondaryBtn>
                                <RSPrimaryBtn
                                    // className={this.getIsEnableSubmitButton() ? "" : "click-off"}
                                    onClick={() => {
                                        // let domainData = {
                                        //     domainName: this.state.domainName,
                                        //     status: "Unverified"
                                        // }
                                        // let domainList = [...this.state.domainNameList]
                                        // domainList.push(domainData);
                                        // this.setState({ domainNameList: domainList });
                                        let params = {
                                            "domainName": this.state.domainName,
                                            "smtpDomainSettingId": this.state.domainId
                                        }
                                        connectServer({
                                            path: SAVE_DKIM_STATUS,
                                            params,
                                            loading: this.context.globalStateData.setIsLoadingData,
                                            sessionOut: this.context.globalStateData.setSessionOutData,
                                            context: this.context,
                                            ok: res => {
                                                if (res.status) {
                                                    this.props.onChangeIndex(0)
                                                }
                                            },
                                            fail: err => { }
                                        })
                                    }}
                                >
                                    Submit
                                </RSPrimaryBtn>
                            </div>
                        </> : null}
                {this.state.isDeleteModalOpen &&
                    <RSConfirmAlert
                        title="Are you sure you want to delete?"
                        subTitle="Deleting the domain may impact your scheduled communications."
                        secondaryButtonText="Cancel"
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            if (value) {
                                this.deleteDomainName(this.state.domainName, this.state.domainId);
                            }
                            this.setState({ isDeleteModalOpen: false });
                        }} />
                }
            </>
        );
    }
}

export default DomainSettings;