import { dateTimeFormatWithMomentForReports } from "Helper/Utils/Utils";
import React from "react";
import { Col, Row } from "react-bootstrap";

class ApprovalCapmaign extends React.Component {
  state = {
    approvalData: this.props.data
  };

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState({ approvalData: this.props.data })
    }
  }
  getApprovalStatus = (statusId) => {
    var status;
    if (statusId === 0) {
      status = {
        "className": "paddingT20 red",
        "text": "Approval pending"
      };
    } else if (statusId === 1) {
      status = {
        "className": "paddingT20 green",
        "text": "Approved"
      };
    } else {
      status = {
        "className": "paddingT20 orange",
        "text": "Make changes"
      };
    }
    return status;
  }
  render() {
    return (
      <div className="paddingT25">
        {this.state.approvalData?.map((item, index) => {
          return (
            <Row>
              <Col md={5}>
                <h5>
                  <small>{dateTimeFormatWithMomentForReports(item.createdDate)}</small>
                  <p>{item.approvalEmailSentTo}</p>
                </h5>
              </Col>
              <Col md={7}>
                <p className={this.getApprovalStatus(item.statusID).className}>{this.getApprovalStatus(item.statusID).text}</p>
              </Col>
            </Row>)
        })}
      </div>
    );
  }
}

export default ApprovalCapmaign;
