import React from "react";
import { Col, Row } from "react-bootstrap";

class ApprovalList extends React.Component {
  render() {
    return (
      <div className="paddingT25">

        <h3 className="marginB20">Fixed deposit renewal (700,000)</h3>

        <Row>
          <Col md={5}>
            <div>
              <small>Tue, Sep 28, 2021 08:08 PM</small>
              <p>Ryan Ong</p>
            </div>
          </Col>
          <Col md={7}>
            <p className="green">Approved</p>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <div>
              <small>Wed, Sep 29, 2021 08:08 PM</small>
              <p>Lisa Adams</p>
            </div>
          </Col>
          <Col md={7}>
            <p className="green">Approved</p>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <div>
              <small>Sat, Oct 02, 2021 08:08 PM</small>
              <p>Sophia Hale</p>
            </div>
          </Col>
          <Col md={7}>
            <p className="green">Approved</p>
          </Col>
        </Row>

      </div>
    );
  }
}

export default ApprovalList;
