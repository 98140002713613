import { connectServer } from 'Helper/Network/networkHandler'
import { CREATE_PLAN, GET_CAMPAIGN, IS_CAMPAIGN_PRESENT } from 'Helper/Constants/endPoint'
import { getLsMasterDataDropdown, getUserData } from 'Helper/Utils/Utils';
import moment from 'moment';
export const createSmartlinkObject = (isSmartLink) => {
  var object = {
    isSmartlink: isSmartLink,
    campaignId: 0,
    campaignName: "",
    campaignType: "",
    productType: "",
    goal: "",
    goalType: "",
    goalPercentage: "",
    secondaryGoal: "",
    secondaryGoalType: "",
    secondaryGoalPercentage: "",
    startDate: "",
    endDate: "",
    isFrquenencyEnabled: false,
    campaignAttribute: {
      campaignAttributeId: 0,
      attributeName: "-- Select communication type --"
    },
    productCategory: {
      categoryId: 0,
      categoryName: "-- Select product type --"
    },
    dynamicList: {
      dynamicListID: "0000",
      dynamicListName: "-- Select your dynamic list  --"
    },
    nativeChannels: [],
    socialMedia: [],
    paidAds: [],
    channelTypes: [],
    analyticsType: [],
    frequency: {
      frequency: ""
    },
    timeZone: "",
    daylightSavings: false
  }
  return object
}

// export const prepareLocalJSON = (cd, isSmartlink, mode) => {
//   let campaignId = cd.campaignId > 0 ? cd.campaignId : Math.floor(1000 + ((crypto.getRandomValues(new Uint32Array(1))[0]) / 0x100000000) * 9000);
//   let keyName = isSmartlink ? "cpsmartlink" : "cpsmartlink"
//   let channelArray = cd.channelTypes
//   let object = {
//     "allChannels": channelArray,
//     "isSmartLinkCampaign": isSmartlink,
//     "campaignId": campaignId,
//     "campaignName": cd.campaignName,
//     "createdBy": "Karthik " + campaignId,
//     "campaignStatus": "In progress",
//     "campaignStatusId": 0,
//     "campaignType": cd.campaignType,
//     "productType": cd.productType,
//     "deliveryType": isSmartlink ? "SmartLink" : "Notification",
//     "totalAudience": campaignId,
//     "goal": cd.goal,
//     "goalType": cd.goalType,
//     "goalPercentage": cd.goalPercentage,
//     "startDate": cd.startDate,
//     "createdDate": new Date().toISOString(),
//     "endDate": cd.endDate,
//     "isFrquenencyEnabled": false,
//     "dynamicList": cd.dynamicList,
//     "nativeChannels": cd.nativeChannels,
//     "socialMedia": cd.socialMedia,
//     "paidAds": cd.paidAds,
//     "channelTypes": cd.channelTypes && cd.channelTypes.length > 0 ? cd.channelTypes : [],
//     "analyticsType": cd.analyticsType && cd.analyticsType.length > 0 ? cd.analyticsType : [],
//     // "frequency": {
//     //     "frequency": cd.frequency.frequency
//     // },
//     "timeZone": cd.timeZone,
//     "isFrquenencyEnabled": cd.isFrquenencyEnabled,
//     "daylightSavings": cd.daylightSavings,
//     "channels": prepareChannelArray(channelArray, isSmartlink)


//   }
//   if (mode === "edit") {
//     let value = JSON.parse(localStorage.getItem(keyName + campaignId));
//     if (value !== null && value.smartlink !== undefined && value.smartlink !== null) {
//       object.smartlink = value.smartlink;
//     }

//     // let smartLinkobject = {
//     //     "campaignId": campaignId,
//     //     "campaignName": cd.campaignName,
//     //     "allChannels":channelArray,
//     //     "startDate": cd.startDate,
//     //     "endDate": cd.endDate,
//     // }
//   }
//   localStorage.setItem(keyName + campaignId, JSON.stringify(object))
//   return campaignId
// }

// const prepareSmartLink = () => {

// }

const prepareChannelArray = (array, isSmartLink) => {
  let tempArray = []
  array.map((item, index) => {
    let obj = {
      "channelName": item,
      "channelStatus": "In progress",
      "channelStatusId": item.toLowerCase().includes('web push') ? 1 : item.toLowerCase().includes('mobile push') ? 2 : index,
      "isSplitAB": true,
      "isSmartlinkCampaign": isSmartLink,
      "splitPercentage": "10",
      "previewImage": "icon-sd-in-progress-medium",
      "info": "70% blast is completed, rest 30% is scheduled for next highest propensity",
      "scheduleDate": "Mon, Apr 29, 2019 17:12:05"
    }
    tempArray.push(obj)
  })
  return tempArray
  // "channels": [
  //     {
  //        "channelName": "Mobile Push",
  //        "channelStatus": "In progress",
  //        "channelStatusId": 1,
  //        "isSplitAB": true,
  //        "splitPercentage": "10",
  //        "previewImage": "icon-sd-in-progress-medium",
  //        "info": "70% blast is completed, rest 30% is scheduled for next highest propensity",
  //        "scheduleDate": "Mon, Apr 29, 2019 17:12:05"
  //     }
}

export const camapignNameisPresent = async (
  campaignId,
  campaignName,
  context,
  errCampaignName
) => {
  let params = {
    campaignName: campaignName,
    campaignId: campaignId,
    databaseName: 'camp_e25a3241_2b2e_444f_a491_247fb7cbf4fa'
  }
  return connectServer({
    path: IS_CAMPAIGN_PRESENT,
    params: params,
    loading: null,
    sessionOut: context.globalStateData.setSessionOutData,
    context: context,
    ok: res => {
      if (res.status) {
        if (res.message.toLowerCase() === 'campaign name exists') {
          errCampaignName('Communication name already exists')
          return false
        } else {
          errCampaignName("")
        }
        return true
      } else {
        errCampaignName("")
        return true
      }
    },
    fail: err => { }
  })
}
const getConversionType = (goalType) => {
  let conversionType = "";
  switch (goalType) {
    case "Coupon code": conversionType = "P"; break;
    case "Download attachment": conversionType = "D"; break;
    case "Thankyou page": conversionType = "TP"; break;
    case "Video views": conversionType = "VV"; break;
    case "Video view duration": conversionType = "VD"; break;
    case "Offline Store purchase": conversionType = "O"; break;
    case "Make an online purchase": conversionType = "OP"; break;
    case "Form submission": conversionType = "R"; break;
    case "Create an account": conversionType = "CA"; break;
    case "Share": conversionType = "F"; break;
    case "Add to wishlist or favourites": conversionType = "WF"; break;
    case "Attend webinar": conversionType = "W"; break;
    case "Landing page": conversionType = "L"; break;
    case "View more": conversionType = "VM"; break;
    case "Contact us": conversionType = "CU"; break;
    case "Find a store": conversionType = "FS"; break;
    case "Opt-in": conversionType = "OI"; break;
    case "Missed call": conversionType = "MC"; break;
  }
  return conversionType;
}
export const savePlan = (campaignDetails, campaignType, context, status) => {
  let conversionType = getConversionType(campaignDetails.goalType)
  let params = {
    "timeZoneId": campaignDetails.timeZone.timezoneId,
    "userId": context.globalStateData.getLoggedInUserData.userId,
    "industryId": 1,
    "marketId": 1,
    "regionId": 1,
    "customerTypeId": 1,
    "brandAffinityId": 1,
    "regionName": "sng",
    "clientDateTimeFormat": 1,
    "departmentId": "0",
    "databaseName": getUserData()?.clientDetailsLocalization?.instanceName,
    "isSmartlink": campaignDetails.isSmartlink,
    "campaignId": campaignDetails.campaignId !== undefined ? campaignDetails.campaignId : 0,
    "clientId": context.globalStateData.getLoggedInUserData.clientId,
    "clientName": "Marketing star codes",
    "campaignType": campaignType,
    "campaignName": campaignDetails.campaignName,
    "campaignAttributeId": campaignDetails.campaignAttribute.campaignAttributeId,
    "productCategoryId": campaignDetails.productCategory.categoryId,
    "primaryTargetCode": campaignDetails.goal,
    "primaryTargetValue": campaignDetails.goalPercentage,
    "goalType": campaignDetails.goalType,
    "secondaryTargetCode": campaignDetails.secondaryGoal,
    "secondaryTargetValue": campaignDetails.secondaryGoalPercentage,
    "secondaryGoalType": campaignDetails.secondaryGoalType,
    "conversionType": conversionType !== "" ? conversionType : campaignType,
    "isCampaignGroup": "N",
    "startDate": moment(campaignDetails.startDate).format('yyyy-MM-DD hh:mm:ss').toString(),
    "endDate": moment(campaignDetails.endDate).format('yyyy-MM-DD hh:mm:ss').toString(),
    "isFrquenencyEnabled": campaignDetails.isFrquenencyEnabled,
    "dynamicListId": campaignDetails.dynamicList.dynamicListId,
    "channelType": campaignDetails.isSmartLink === true ? campaignDetails.nativeChannels : campaignDetails.channelTypes,
    //    "socialMedia": campaignDetails.socialMedia,
    //    "paidAds": campaignDetails.paidAds,
    //    "analyticsType": campaignDetails.analyticsType,
    "frequency": {},
    "timeZone": campaignDetails.timeZone.timezoneName
  }
  connectServer({
    path: CREATE_PLAN,
    params: params,
    loading: context.globalStateData.setIsLoadingData,
    sessionOut: context.globalStateData.setSessionOutData,
    context: context,
    ok: res => {
      if (res.status) {
        status(res)
      }
    },
    fail: err => {
      let obj = {
        status: false
      }
      status(obj)
    }
  })
}

export const getCampaignPlanById = (campaignId, context, attributes, result) => {
  let params = {
    "campaignId": campaignId,
    "databaseName": "camp_e25a3241_2b2e_444f_a491_247fb7cbf4fa"
  }
  connectServer({
    path: GET_CAMPAIGN,
    params: params,
    loading: context.globalStateData.setIsLoadingData,
    sessionOut: context.globalStateData.setSessionOutData,
    context: context,
    ok: res => {
      if (res.status === true) {
        let tempObject = JSON.parse(res.data.campaignjson)
        result(prepareCampaignPlanDetails(tempObject, attributes, context, res.data.minScheduleDate, res.data.maxScheduleDate))
      }
    },
    fail: err => { }
  })
}

const prepareCampaignPlanDetails = (object, attributes, context, minEndDate, maxEndDate) => {

  // const masterData = context.globalStateData.getMasterDropdownData
  const masterData = getLsMasterDataDropdown()
  let s = object.startDate.includes('T') ? moment(object.startDate).add(-1, 'day').toDate() : moment(object.startDate).add(0, 'day').toDate()
  let s1 = object.endDate.includes('T') ? moment(object.endDate).add(-1, 'day').toDate() : moment(object.endDate).add(0, 'day').toDate()
  let mStartDate = s
  let mEndDate = s1//moment(object.endDate).add(0,'day').toDate()//moment(object.endDate).add(-1,'day').toString()
  let planObject = {
    "isSmartlink": false,
    "campaignId": object.campaignId,
    "campaignName": object.campaignName,
    "campaignAttribute": attributes.campaignAttributeList.filter(obj => obj.campaignAttributeId === object.campaignAttributeId)[0],
    "productCategory": attributes.productCategorysList.filter(obj => obj.categoryId === object.productCategoryId)[0],
    "goal": object.primaryTargetCode,
    "goalType": object.goalType,
    "goalPercentage": object.primaryTargetValue,
    "startDate": mStartDate,
    "endDate": mEndDate,
    "secondaryGoal": object.secondaryTargetCode,
    "secondaryGoalType": object.secondaryGoalType,
    "secondaryGoalPercentage": object.secondaryTargetValue,
    "isFrquenencyEnabled": false,
    "dynamicList": {
      "dynamicListId": "0000",
      "dynamicListName": "-- Select your dynamic list --"
    },
    "nativeChannels": object.channelType.length === 0 ? [] : object.channelType,
    // "socialMedia": object.socialMedia && [],
    // "paidAds": object.paidAds && [],
    "channelTypes": object.channelType.length === 0 ? [] : object.channelType,
    // "analyticsType": object.analyticsType.length === 0 ? [] : object.analyticsType,
    //   "frequency": object.frequency !== undefined ? object.frequency : {frequency:"Immediate"},
    "timeZone": { timezoneId: '0', timezoneName: '-- Select time zone --' },
    "daylightSavings": false,
    "minEndDate": minEndDate,
    "maxEndDate": maxEndDate
  }
  return planObject
}