import { RSPrimaryBtn } from 'Components/RSButtons';
import { RSInput2 } from 'Components/RSInputs';
import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

class DLSaveModal extends Component {
    state = {
        isShow: this.props.isOpen,
        listName: this.props.listName
    }
    render() {
        return (
            <Modal backdrop="static"
                keyboard={false} show={this.state.isShow} onHide={() => {
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen({ isShow: false, listName: this.state.listName });
                }}>
                <Modal.Header closeButton>
                    <h2>Dynamic list</h2>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <label>Give your dynamic list a name</label>
                    </Row>
                    <Row>
                        <RSInput2
                            ph="Enter list name"
                            val={this.state.listName}
                            cb={(text) => {
                                this.setState({ listName: text });
                            }} />
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <RSPrimaryBtn
                        onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen({ isShow: true, listName: this.state.listName });
                        }}>Save
                    </RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DLSaveModal;