import React, { useState, useEffect } from "react";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { RSInput2, RSRadio } from "Components/RSInputs";
import { RSDropdownList } from "Components/RSDropdowns";

export const Monthly = (props) => {
  const getMonthly = () => {
    return props.campaignJson?.frequency.filter(
      (obj) => obj.frequency.toLowerCase() === "monthly"
    );
  };

  const [monthly, setMonthly] = useState(getMonthly());
  const [error, setError] = useState(props.monthlyError);

  useEffect(() => {
    let myArr = [...monthly];
    // myArr[0].hourInterval = defaultValue.toString();
    // myArr[1].hourInterval = defaultValue.toString();
    setMonthly(myArr);
    setError(props.monthlyError);
  }, [props.monthlyError]);

  let min = new Date(2000, 2, 10, 12, 30);
  let max = new Date(2010, 2, 10, 24, 15);
  let defaultValue = new Date(2010, 2, 10, 10, 0);
  const steps = {
    hour: 1,
    minute: 30,
    second: 5,
  };
  return (
    <>
      <ul className="frequency-list monthlylist">
        <li>
          <label>Day</label>
        </li>
        <li className={`${props.editClickOff ? "click-off" : ""} d-none`}>
          <RSRadio
            lbl="Day"
            name="monthly"
            labelId="day"
            checked={monthly[0].isDayEnable}
            cb={(event) => {
              let myArr = [...monthly];
              myArr[0].isDayEnable = true;
              myArr[1].isDayEnable = false;
              setMonthly(myArr);
            }}
          />
        </li>
        <li className={props.editClickOff ? "click-off" : ""}>
          <RSInput2
             ph="Days(s)"
            val={monthly[0].dayInterval}
            keyboardType="number"
            required="true"
            validateCls="w-max-conent"
            cb={(text) => {
              const zeroCount = (text.match(/0/g) || []).length;
              // If there are more than two occurrences of '0', reset the value to "100"
              if (zeroCount > 2) {
                props.onChange("dayinterval", "100");
              }
              else if (101 < text) {
                props.onChange("dayinterval", "100");
              } else {
                let myArr = [...monthly];
                myArr[0].dayInterval = text;
                setMonthly(myArr);
                props.onChange("dayinterval", text);
              }
            }}
            errorMessage={monthly[0].isDayEnable && error.errDayInterval}
          />
        </li>
        <li>
          <label>of every</label>
        </li>
        <li className={props.editClickOff ? "click-off" : ""}>
          <RSInput2
             ph="Month(s)"
            val={monthly[0].monthInterval}
            keyboardType="number"
            required="true"
            validateCls="w-max-conent"
            cb={(text) => {
              if (text === "00") { 
                props.onChange("0", "dayInterval"); 
              }
             else if (101 < text) {
                props.onChange("dayinterval", "100");
              } else {
                let myArr = [...monthly];
                myArr[0].monthInterval = text;
                setMonthly(myArr);
                props.onChange("monthinterval", text);
              }
            }}
            // cb={(text) => {
            //   if (101 < text) {
            //     props.onChange("dayinterval", "100");
            //   } else {
            //     let myArr = [...monthly];
            //     myArr[0].monthInterval = text;
            //     setMonthly(myArr);
            //     props.onChange("monthinterval", text);
            //   }
            // }}
            errorMessage={monthly[0].isDayEnable && error.errMonthInterval}
            // errorMessage={'Enter month interval'}
          />
        </li>
        <li>
          <label>months @</label>
        </li>
        <li className={props.editClickOff ? "click-off" : ""}>
          <div className="position-relative">
            {error && error.errHourInterval && monthly[0].isDayEnable && (
              <div className="validation-message">{error.errHourInterval}</div>
            )}
            <div className="rsptimer-picker">
              <TimePicker
                steps={steps}
                defaultValue={props.campaignJson.frequency[2]["hourInterval"]}
                className="required"
                onChange={(event) => {
                  let myArr = [...monthly];
                  myArr[0].hourInterval = event.target.value;
                  setMonthly(myArr);
                  props.onChange("hourinterval", event.target.value);
                }}
              />
            </div>
          </div>
        </li>
        <li>
        {" "}
        <label>hours</label>{" "}
      </li>
      </ul>
      {/* 
            <ul className="frequency-list">
                <li className={props.editClickOff?"click-off":""}>
                    <RSRadio lbl="The" name="monthly" labelId="the" checked={monthly[1].isDayEnable} cb={(event) => {
                        let myArr = [...monthly]
                        myArr[0].isDayEnable = false;
                        myArr[1].isDayEnable = true;
                        setMonthly(myArr)
                    }} />
                </li>
                <li className={props.editClickOff?"click-off":""}>
                    <div className="position-relative">
                        {error && error.errWeekInterval && monthly[1].isDayEnable && <div className="validation-message">{error.errWeekInterval}</div>}
                        <RSDropdownList className="w6rem" data={["First", "Second", "Third", "Fourth", "Last"]} defaultValue={monthly[1].weekInterval !== undefined && monthly[1].weekInterval !== "" ? monthly[1].weekInterval : "-- Select --"} onChange={(event) => {
                            monthly[1].weekInterval = event.target.value
                            props.onChange('weekinterval', event.target.value)
                        }} />
                    </div>
                </li>
                <li className={props.editClickOff?"click-off":""}>
                    <div className="position-relative">
                        {error && error.errDay && monthly[1].isDayEnable && <div className="validation-message">{error.errDay}</div>}
                        <RSDropdownList className="w10rem" data={["Day", "Week day", "Weekend day", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]} defaultValue={monthly[1].day !== undefined && monthly[1].day !== "" ? monthly[1].day : "-- Select --"} onChange={(event) => {
                            monthly[1].day = event.target.value
                            props.onChange('day', event.target.value)
                        }} />
                    </div>
                </li>
                <li>
                    <label>of every</label>
                </li>
                <li className={props.editClickOff?"click-off":""}>
                    <RSInput2 ph=""
                        val={monthly[1].dayInterval}
                        keyboardType='number'
                        cb={(text) => {
                            let myArr = [...monthly]
                            myArr[1].dayInterval = text
                            setMonthly(myArr)
                            props.onChange('dayinteval', text)
                        }}
                        errorMessage={monthly[1].isDayEnable && error.errDayInterval}
                    />
                </li>
                <li>
                    <label>Month @</label>
                </li>
                <li className={props.editClickOff?"click-off":""}>
                    <div className="position-relative">
                        {error && error.errHourInterval && monthly[1].isDayEnable && <div className="validation-message">{error.errHourInterval}</div>}
                        <div className="rsptimer-picker">
                            <TimePicker
                                min={min}
                                max={max}
                                steps={steps}
                                defaultValue={defaultValue}
                                className="required"
                                onChange={(event) => {
                                    let myArr = [...monthly]
                                    myArr[1].hourInterval = event.target.value
                                    setMonthly(myArr)
                                    props.onChange('hourinterval', event.target.value.toString())
                                }}
                            />
                        </div>
                    </div>
                </li>
            </ul> */}
    </>
  );
};
