export const themeSize     = 23
export const themeSizeSm   = 15

export const themeRadiusmd = 8
export const themeRadius   = 4

export const sheight       = 32
export const sheightSpace  = 15

export const sheightMd = 91.6
export const sheightLg = 362
export const scolor1 = '#e0e5eb' // c2cfe3, e2e7ee
export const scolor2 = '#eeeeee' // eef2f9, dae3ef

export const widthCalc = 100 - themeSizeSm + '%'
export const widthFill = 100 + '%'

export const sspace = 'mb24'

// export const scolor1 = '#c2cfe3' // c2cfe3, e2e7ee
// export const scolor2 = '#dae3ef' // eef2f9, dae3ef
