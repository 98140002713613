import { numberWithCommas } from "Helper/Utils/Utils";
import React from "react";
import { Row, Col } from "react-bootstrap";
import FrequencyModal from "./FrequencyModal";
import SupperssionListModal from "./SupperssionListModal";
import ImgF from "../../../../../../../Assets/Images/icon-user-frequency-calender-large.svg";
import ImgS from "../../../../../../../Assets/Images/icon-user-suppression-list-large.svg";

class List extends React.Component {
  state = {
    isFrequencyModal: false,
    isSupperssionModal: false,
    isWebCustomButton: false,
    isMobileCustomButton: false,
    sendTime: false,
  };
  render() {
    return (
      <div className={`portlet-container portlet-md ${this.state.sendTime ? 'show-mini-chart' : ''}`}>
        <div className="portlet-header">
          <h3> List quality </h3>
        </div>
        <Row className="text-center split-line-col">
          <Col className='split-line mb15'>
            <p className="mb0">Total audience</p>
            <h1 className='mb0'>{numberWithCommas(this.props.data?.totalAudience)}</h1>
          </Col>
          <Col className='split-line mb15'>
            <p className="mb0">Unique audience</p>
            <h1 className='mb0'>{numberWithCommas(this.props.data?.totalUniqAudience)}</h1>
          </Col>
        </Row>
        {this.props.data?.channelID !== 3 &&
          <Row className="text-center split-line-col">
            <Col className='split-line mb15'>
              <p className="mb0">Scrubbed</p>
              <h1 className="mb0">{numberWithCommas(this.props.data?.scrubbed)}</h1>
              <small className="no-padding mb0 grey">{this.props.data?.channelID === 1 ? "(Spam, bounce, unsubscribed)" : this.props.data?.channelID === 2 ? "(DND, SPAM, Unsubscribe)" : "(Unsubscribe)"}</small>
            </Col>
          </Row>
        }
        <Row className="text-center marginT10">
          <p className='mb0'>Potential target audience</p>
          <h1>{numberWithCommas(this.props.data?.potentialTarget)}</h1>
        </Row>

        <hr />

        <div className="text-center">
          <ul className="listStyle">
            <li className="text-center">
              <img src={ImgF} height="40" className="grey cp click-off"
                onClick={() => {
                  this.setState({
                    isFrequencyModal: !this.state.isFrequencyModal
                  });
                  this.props.onChangeIsOpen(this.state.isFrequencyModal);
                }}
              />
              {/* <i className="icon-frequency-cap-medium icons-lg grey cp click-off"
                onClick={() => {
                  this.setState({
                    isFrequencyModal: !this.state.isFrequencyModal
                  });
                  this.props.onChangeIsOpen(this.state.isFrequencyModal);
                }}
              ></i> */}
            </li>
            <li className="text-center">
              <img src={ImgS} height="40" className="grey cp click-off"
                onClick={() => {
                  this.setState({
                    isSupperssionModal: !this.state.isSupperssionModal
                  });
                  this.props.onChangeIsOpen(this.state.isSupperssionModal);
                }}
              />
              {/* <i className="icon-suppression-list-medium icon-lg grey cp click-off"
                onClick={() => {
                  this.setState({
                    isSupperssionModal: !this.state.isSupperssionModal
                  });
                  this.props.onChangeIsOpen(this.state.isSupperssionModal);
                }}
              ></i> */}
            </li>
          </ul>
        </div>

        <div className="info-popup click-off">
          <i className="icon-sd-circle-info-medium icons-md blue cp"
            onClick={() => {
              this.setState({
                sendTime: true
              })
            }}
          ></i>
        </div>

        <div className="hori-column-chart p20">
          <i class="icon-close-small cp close-btn"
            onClick={() => {
              this.setState({
                sendTime: false
              })
            }}></i>
          <div className="portlet-md">
            <h4>Send time propensity</h4>
          </div>
        </div>

        {this.state.isSupperssionModal === true ? (
          <SupperssionListModal
            isOpen={this.state.isSupperssionModal}
            onChangeIsOpen={(value) => {
              this.setState({ isSupperssionModal: value });
            }}
          ></SupperssionListModal>
        ) : null}
        {this.state.isFrequencyModal === true ? (
          <FrequencyModal
            isOpen={this.state.isFrequencyModal}
            onChangeIsOpen={(value) => {
              this.setState({ isFrequencyModal: value });
            }}
          ></FrequencyModal>
        ) : null}
      </div>
    );
  }
}
export default List;