import React from 'react'
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import CampaignListGallery from './CampaignListGallery/CampaignListGallery';
import Planning from './CampaignCreation/Steps/Planning/Planning';
import { Notification } from './CampaignCreation/Steps/Creation/Notification/Notification';
import MdcEmail from './CampaignCreation/Steps/Creation/MDCComponents/MdcEmail';
import MdcSmsCreation from './CampaignCreation/Steps/Creation/MDCComponents/MdcSmsCreation';
import CampaignCompletion from './CampaignCreation/Steps/Analyze-Execution/CampaignCompletion';
import ConfirmPayment from './CampaignCreation/Steps/Analyze-Execution/ConfirmPayment/ConfirmPayment';
import CampaignPayment from './CampaignCreation/Steps/Analyze-Execution/CampaignPayment/CampaignPayment';
import MdcWhatsAppCreation from './CampaignCreation/Steps/Creation/MDCComponents/MdcWhatsAppCreation';
import MdcQRCreation from './CampaignCreation/Steps/Creation/MDCComponents/MdcQRCreation';

const Campaign = props => {
    let match = useRouteMatch();
    let history = useHistory();

    return <Switch>
        <Route exact path={`${match.url}/`}> <CampaignListGallery history={history} />  </Route>
        <Route exact path={`${match.url}/planning`}> <Planning />  </Route>
        <Route exact path={`${match.url}/create-communication`}> <Notification history={history} />  </Route>
        <Route exact path={`${match.url}/mdc-email-creation`}> <MdcEmail />  </Route>
        <Route exact path={`${match.url}/mdc-sms-creation`}> <MdcSmsCreation />  </Route>
        <Route exact path={`${match.url}/mdc-whatsapp-creation`}> <MdcWhatsAppCreation />  </Route>
        <Route exact path={`${match.url}/mdc-qr-creation`}> <MdcQRCreation />  </Route>
        <Route exact path={`${match.url}/completion`}> <CampaignCompletion />  </Route>
        <Route exact path={`${match.url}/confirm-payment`}> <ConfirmPayment /></Route>
        <Route exact path={`${match.url}/communication-payment`}> <CampaignPayment /></Route>
    </Switch>
}
export default Campaign;