import React, { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { RSPTooltip } from "Components/Tooltip";
import UploadComponent from "Container/AuthenticatedModule/Preference/Components/CommunicationSettings/UploadComponent";
import { CHECK_LIST_EXISTS, REMOVE_ADHOC_LIST_FILE, UPLOAD_ADHOC_CSV } from 'Helper/Constants/endPoint';
import { connectServer } from "Helper/Network/networkHandler"
import { getAccessToken, getBase, getSourceTypeForDocs, isEmpty } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import { LIST_NAME_LIMIT, UPDATE_LIST_NAME } from "Helper/Constants/validationMessage";
import { useEffect } from "react";

const CSVImport = ({ parentImportDescription, parentFileImport, parentFirstRow, errImportDesc, errFileUpload, getEnableApplyButton }) => {

  const context = useContext(authContext)

  const [importDescription, setImportDescription] = useState('')
  const [importDescriptionErrMsg, setImportDescriptionErrMsg] = useState(errImportDesc);
  const [errFileUploadMsg, setErrFileUploadMsg] = useState(errFileUpload);
  const [filepath, setFilepath] = useState('')
  const [uploadFilesPath, setUploadFilesPath] = useState([]);
  const [enablepath, setEnablePath] = useState(false)
  const [fileName, setFileName] = useState(false)
  const [fileSize, setFilSize] = useState()
  const [uploadMessage, setFileMessage] = useState('In progress...')
  const [firstRowColoumnLetter, setFirstRowColoumnLetter] = useState(true)
  const [uploadedFileDetails, setUploadedFileDetails] = useState([]);
  const [headers, setHeaders] = useState("");
  const [enableBrowseButton, setEnableBrowseButton] = useState(false);

  useEffect(() => {
    downloadCSV();
  }, [])

  const uploadToServer = () => {
    var fileInput = document.querySelector('[name="uploaded_file"]').files[0];
    if (fileInput.type === "text/csv" || fileInput.type === "application/vnd.ms-excel") {
      let isSameFile = uploadedFileDetails.filter(file => file.fileName === fileInput.name).length > 0 ? false : true;
      if (!isSameFile) {
        setErrFileUploadMsg("File name already exists")
        getEnableApplyButton(false);
        return;
      }
      if (fileInput.size > 20971520) {
        setErrFileUploadMsg("File size exceeded 20MB")
        getEnableApplyButton(false);
        return;
      }
      setFileName(fileInput.name)
      setFilSize(fileInput.size);
      var getFileByte = "";
      var reader = new FileReader();
      reader.readAsDataURL(fileInput);
      reader.onload = function () {
        getFileByte = reader.result.split(',')[1];
        var params = {
          "importDescription": importDescription,
          "fileName": importDescription + uploadFilesPath.length.toString() + ".csv",
          "fileByte": getFileByte,
          "headers": headers
        }
        connectServer({
          path: UPLOAD_ADHOC_CSV,
          params: params,
          loading: context.globalStateData.setIsLoadingData,
          sessionOut: context.globalStateData.setSessionOutData,
          context: context,
          ok: res => {
            if (res.status) {
              let temp = [...uploadFilesPath];
              temp.push(res.data.filePath);
              setUploadFilesPath(temp);
              parentFileImport(temp)
              setHeaders(res.data.attributeNames)
              setFileMessage('Verified - Valid')
              let fileDetails = {
                "filePath": res.data.filePath,
                "statusMessage": res.data.message,
                "fileSize": fileInput.size,
                "fileName": fileInput.name
              }
              let tempFileDetailsArray = [...uploadedFileDetails];
              tempFileDetailsArray.push(fileDetails);
              setUploadedFileDetails(tempFileDetailsArray);
              setErrFileUploadMsg("");
              getEnableApplyButton(((temp.length === tempFileDetailsArray.length) && temp.length > 0) ? true : false)
            } else {
              let fileDetails = {
                "filePath": res.data.filePath,
                "statusMessage": res.data.message,
                "fileSize": fileInput.size,
                "fileName": fileInput.name
              }
              let tempFileDetailsArray = [...uploadedFileDetails];
              tempFileDetailsArray.push(fileDetails);
              setUploadedFileDetails(tempFileDetailsArray);
              setErrFileUploadMsg(res.data.message);
              getEnableApplyButton(((uploadFilesPath.length === tempFileDetailsArray.length) && uploadFilesPath.length > 0) ? true : false)
            }
          },
          fail: err => {

          }
        })

      };
      reader.onerror = function (error) {
      };
    } else {
      setErrFileUploadMsg("Please select a valid CSV file")
    }
  };

  const checkListExists = (importDescription) => {
    let params = {
      "importDescription": importDescription
    }
    connectServer({
      path: CHECK_LIST_EXISTS,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (!res.status) {
          setImportDescriptionErrMsg("List name already exists");
          setEnableBrowseButton(false)
        } else {
          setImportDescriptionErrMsg(null);
          setEnableBrowseButton(true)
        }
      },
      fail: err => {

      }
    })
  }

  const removeAdhocFile = (removeFilePath) => {
    let params = {
      "fileName": removeFilePath
    }
    connectServer({
      path: REMOVE_ADHOC_LIST_FILE,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          let temp = [...uploadedFileDetails];
          temp = temp.filter(file => file.filePath !== removeFilePath);
          setUploadedFileDetails(temp);
          let tempPath = [...uploadFilesPath];
          tempPath = tempPath.filter(filePath => filePath !== removeFilePath);
          setUploadFilesPath(tempPath);
          parentFileImport(tempPath)
          setErrFileUploadMsg(null);
          setFilepath("")
          if (temp.length === 0) {
            setHeaders("");
          }
          getEnableApplyButton(((tempPath.length === temp.length) && tempPath.length > 0) ? true : false)
        }
      },
      fail: err => {

      }
    })
  }

  const readableFileSize = (size) => {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  };

  const downloadCSV = () => {
    let data = {
      EmailID: "testemail@gmail.com",
      FirstName: "John",
      LastName: "Bravo",
      MobileNo: "919989097865",
      Gender: "Male",
      Age: "32",
      City: "Chennai",
      Designation: "Manager",
      Sports: "Football",
      Percentage: "70",
      Food: "Pizza",
      Travel: "Yes",
      NickName: "David"
    };
    let csvRows = [];
    const headers = Object.keys(data);

    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(','));

    // Pushing Object values into array
    // with comma separation
    const values = Object.values(data).join(',');
    csvRows.push(values)

    // Returning the array joining with new line
    const csvData = csvRows.join('\n')
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    const a = document.getElementsByClassName('text-decoration-none')[0].setAttribute('href', url)
  }

  return (
    <>
      <Row className="mx0 mt20">
        <Col md={{ span: 2, offset: 3 }} className="text-end">
          <label className="label-row">List name</label>
        </Col>
        <Col md={5}>
          <div className="d-flex mb17 mt4">
            <Col md={1} className="pr0" style={{ width: "42px" }}>
              <RSInput2
                cls="mb0 posr pl16 color-none"
                val={"UL_"}
                required={true}
                disabled={true} />
            </Col>
            <Col md={6} className="pl0">
              <RSInput2
                cls="mb0 posr pl16 left-border-none"
                validateCls={"left8"}
                ph="Enter list name"
                val={importDescription}
                // required={true}
                cb={(text) => {
                  if (text.length <= 30) {
                    // var regex = /^[A-Za-z0-9 ]+$/;
                    var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                    text = text.trimStart();
                    if (text.length > 0) {
                      if (regex.test(text)) {
                        setImportDescription(text);
                        parentImportDescription(text);
                        setImportDescriptionErrMsg(null);
                        setEnableBrowseButton(false)
                      } else {
                        setImportDescriptionErrMsg("Special character(s) are not allowed")
                      }
                    } else {
                      setImportDescription(text);
                      parentImportDescription(text);
                      setImportDescriptionErrMsg(UPDATE_LIST_NAME);
                      setEnableBrowseButton(false)
                    }
                  } else {
                    setImportDescriptionErrMsg(LIST_NAME_LIMIT)
                    setEnableBrowseButton(false)
                  }
                }}
                ob={(text) => {
                  text = text.trimStart();
                  text = text.trimEnd();
                  setImportDescription(text);
                  parentImportDescription(text);
                  if (!isEmpty(text)) {
                    checkListExists(text);
                  }
                }}
                errorMessage={importDescriptionErrMsg}
              />
              <div className="input-outer-set ml1" style={{ height: '38px' }}>
                <RSPTooltip text="Upload lists are auto-prefixed with UL" position="top">
                  <i className='icon-sd-question-mark-mini icons-sm blue cursor-pointer' />
                </RSPTooltip>
                {/* {isShowTickSymbol &&
                  <i className="icon-sd-circle-tick-medium icons-md green d-block marginT0" />
                } */}
              </div>
            </Col>
          </div>
        </Col>
      </Row>
      <Row className="mx0 mb30">
        <Col md={{ span: 2, offset: 3 }} className="text-end">
          <label className="label-row">Choose your file(s)</label>
        </Col>
        <Col md={6} >
          <Row className={enableBrowseButton ? "" : "click-off"}>
            <Col md={6}>
              <div className="position-relative">
                <RSInput2
                  cls="mb10 disabled"
                  type="text"
                  ph="Up to 5 CSV files allowed"
                  val={filepath !== "" ? filepath : uploadedFileDetails.length > 0 ? uploadedFileDetails[uploadedFileDetails.length - 1].fileName : ""}
                  required={true}
                  cb={(text) => {
                  }}
                  errorMessage={errFileUploadMsg}
                />
                <div className={`input-outer-set ${uploadedFileDetails.length > 0 ? "" : "d-none"}`}>
                  <RSPTooltip text="Reset" position="top">
                    <i
                      className="icon-sd-refresh-medium icons-md blue ml5"
                      onClick={() => {
                        setUploadedFileDetails([])
                        setUploadFilesPath([])
                        setFilepath("")
                        getEnableApplyButton(false)
                        setErrFileUploadMsg("");
                        setHeaders("");
                      }}
                    />
                  </RSPTooltip>
                </div>
              </div>
            </Col>
            <Col className={uploadedFileDetails.length < 5 ? "ml10" : "click-off ml10"}>
              <UploadComponent
                text="Browse"
                onClick={() => { setEnablePath(true) }}
                onChange={(value) => {
                  setFilepath(value)
                  if (uploadedFileDetails.length < 5) {
                    uploadToServer(value);
                  } else {
                    setErrFileUploadMsg("You can select up to 5 files only");
                  }
                  //parentFileImport(value)
                }} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="d-flex align-items-center">
              {/* <RSCheckbox
                className="mb0 fs14 click-off"
                lbl={'Use first row as a column header'}
                checked={true}
                cb={status => {
                  setFirstRowColoumnLetter(status)
                  parentFirstRow(status)
                }} />
              <RSPPophover text="Note: A max of 5 files can be selected per import. Each file size should not be more than 10mb, and overall file size should not exceed 60mb. The column headers should match that in the CSV file in case of multiple file uploads." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-2 cp"></i>
              </RSPPophover> */}
              <a class="text-decoration-none d-flex align-items-center"
                download={"sample.csv"}>
                <small className="blue cp">Sample format</small>
                <RSPTooltip text="Download sample format" position="top">
                  <i className="icon-sd-download-medium icons-md blue cp ml0 mr5" />
                </RSPTooltip>
              </a>
              <RSPTooltip text="Know more" position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-2 cp" onClick={() => {
                  window.open("https://doc.mkstr.io/platform-guide/add-audience-csv?jwt=" + getAccessToken() + "&base=" + getBase() + "&source=" + getSourceTypeForDocs(), "_blank")
                }}></i>
              </RSPTooltip>
            </Col>
            {/* <Col>
              <RSPTooltip text="Download sample format" position="top">
                <i className="icon-csv-download-small icons-md blue cp ml0"></i>
              </RSPTooltip>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row className="mx5">
        {uploadedFileDetails.map((item, index) => {
          return (
            <Col key={index} md={4} className="mb15">
              <div className={item.statusMessage === "Valid" ? "aud-block ufc-success" : "aud-block ufc-failed"}>
                <div
                  className="back-text float-end"
                  onClick={() => {
                    if (item.filePath !== "") {
                      removeAdhocFile(item.filePath)
                    } else {
                      let temp = [...uploadedFileDetails];
                      temp = temp.filter(file => file.fileName !== item.fileName);
                      setUploadedFileDetails(temp);
                      let tempPath = [...uploadFilesPath];
                      setErrFileUploadMsg(null);
                      setFilepath("")
                      if (temp.length === 0) {
                        setHeaders("");
                      }
                      getEnableApplyButton(((tempPath.length === temp.length) && tempPath.length > 0) ? true : false)
                    }
                  }}>
                  <RSPTooltip text="Remove" position="top">
                    <i className="icon-sd-close-mini icon-mini blue cp" />
                  </RSPTooltip>
                </div>
                <p>{item.fileName}</p>
                <div className="aud-kb">{readableFileSize(item.fileSize)}</div>
                <div className="d-flex justify-content-between">
                  <div>{item.statusMessage}</div>
                  {/* <div className="blue">78%</div> */}
                  {item.statusMessage === "Valid" ?
                    <i className="icon-sd-circle-tick-medium icons-md green"></i> :
                    <RSPTooltip text={item.statusMessage} position="top">
                      <i className="icon-sd-circle-exclamatory-medium icons-md red"></i>
                    </RSPTooltip>}
                </div>
                <div className="dprogress-bar-wr">
                  <div style={{ width: '100%' }}></div>
                </div>
              </div>
            </Col>)
        })}</Row>
      {/* {
        fileName ? <Row className="mx5">

          {uploadMessage === 'In progress...' && <Col md={4} className="mb15">
            <div className="aud-block ufc-inprogress">
              <p>{fileName}</p>
              <div className="aud-kb">{fileSize} kb</div>
              <div className="d-flex justify-content-between">
                <div>In progress...</div>
                <div className="blue">78%</div>
              </div>
              <div className="dprogress-bar-wr">
                <div style={{ width: '80%' }}></div>
              </div>
            </div>
          </Col>
          }

          {uploadMessage === 'Verified - Valid' && <Col md={4} className="mb15">
            <div className="aud-block ufc-success">
              <p>{fileName}</p>
              <div className="aud-kb">{fileSize} kb</div>
              <div className="d-flex justify-content-between">
                <div>Verified - Valid</div>
                <i className="icon-sd-circle-tick-medium icons-md green"></i>
              </div>
              <div className="dprogress-bar-wr">
                <div style={{ width: '100%' }}></div>
              </div>
            </div>
          </Col>
          }


          {uploadMessage === 'Invalid headers/column count' && <Col md={4} className="mb15">
            <div className="aud-block ufc-failed">
              <p>{fileName}</p>
              <div className="aud-kb">{fileSize} kb</div>
              <div className="d-flex justify-content-between">
                <div>Invalid headers/column count</div>
                <RSPTooltip text="Invalid headers at line: 852077" position="top">
                  <i className="icon-exclamatory-small icons-md red"></i>
                </RSPTooltip>
              </div>
              <div className="dprogress-bar-wr">
                <div style={{ width: '100%' }}></div>
              </div>
            </div>
          </Col>
          }

        </Row> : null
      } */}

    </>
  )
}
export default CSVImport