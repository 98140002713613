import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";

export const DownloadAlertModal = (props) => {
    const [show, setShow] = useState(true);

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                setShow(false)
                props.buttonClicked(false)
            }}
            centered
            size='md'
        >
            <Modal.Header closeButton>
                <h2>Important !</h2>
            </Modal.Header>
            <Modal.Body>
                {props.title && <h4 className='text-center'>{props.title}</h4>}
                {props.subTitle && <p className='text-center mt30'>{props.subTitle !== "" && props.subTitle}</p>}
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end marginT30">
                    <RSSecondaryBtn onClick={() => {
                        setShow(false)
                        props.buttonClicked(false)
                    }} variant="outline-success">
                        {props.secondaryButtonText}
                    </RSSecondaryBtn>
                    <RSPrimaryBtn onClick={() => {
                        setShow(false)
                        props.buttonClicked(true)
                    }} variant="outline-success">
                        {props.primaryButtonText}
                    </RSPrimaryBtn>
                </div>
            </Modal.Footer>
        </Modal>
    );
}