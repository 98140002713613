import React, { useState } from "react";
import { DUPLICATE_CAMPAIGN, DELETE_CAMPAIGN } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { RSPTooltip } from "Components/Tooltip";
import { dateFormat, getAuthUserId, numberWithCommas } from "Helper/Utils/Utils";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import * as images from "Assets/Images";
import ArchiveMedium from "Components/SVG/ArchiveMedium";

const CampaignListRowCell = (props) => {

    const [isOpenDeletModal, setIsOpenDeleteModal] = useState(false);

    const dublicateCampaign = campaign => {
        props.context.globalStateData.setIsLoadingData(true)
        let params = {
            userId: getAuthUserId(),
            campaignId: campaign.campaignId,
            databaseName: 'camp_e25a3241_2b2e_444f_a491_247fb7cbf4fa'
        }
        connectServer({
            path: DUPLICATE_CAMPAIGN,
            params: params,
            loading: props.context.globalStateData.setIsLoadingData,
            sessionOut: props.context.globalStateData.setSessionOutData,
            context: props.context,
            ok: res => {
                if (res.status) {
                    props.onRefresh();
                }
            },
            fail: err => {
            }
        })
    }

    const deleteByCampaignId = campaignID => {
        let params = {
            campaignId: campaignID
        }
        connectServer({
            path: DELETE_CAMPAIGN,
            params: params,
            loading: props.context.globalStateData.setIsLoadingData,
            sessionOut: props.context.globalStateData.setSessionOutData,
            context: props.context,
            ok: res => {
                if (res.status) {
                    props.onRefresh();
                }
            },
            fail: err => {
            }
        })
    }

    return (
        <td className={`${campaignStatus(props.dataItem.campaignStatusId)?.className}`}>
            <div className="panelbar-wrapper campaign-analytics-list">

                <div className="cellPannelInner">
                    <div className="created-user-id">
                        {/* <span className="campaign-id">{props.dataItem.campaignShortCode}</span> */}
                        <small className="d-flex">
                            <span className="wsn">Created by:</span>
                            {
                                props.dataItem.userFirstName.length > 15
                                    ? <RSPTooltip text={props.dataItem.userFirstName} position="top">
                                        <span className="created-name pl5">{props.dataItem.userFirstName}</span>
                                    </RSPTooltip>
                                    : <span className="created-name pl5">{props.dataItem.userFirstName}</span>
                            }
                            <span className="wsn">, on: {dateFormat(props.dataItem.createdDate)}</span>
                        </small>
                    </div>
                    {
                        props.dataItem.campaignName.length > 35
                            ? <RSPTooltip text={props.dataItem.campaignName} position="top">
                                <p>{props.dataItem.campaignName}</p>
                            </RSPTooltip>
                            : <p>{props.dataItem.campaignName}</p>
                    }

                </div>

                <div className="cellPannelInner">
                    <small>{props.dataItem.deliveryType}</small>
                    <p>{props.dataItem.campaignType}</p>
                </div>

                <div className="cellPannelInner">
                    <small>Total audience</small>
                    <p>{props.dataItem.numberOfRecipients === 0 ? "-NA-" : numberWithCommas(props.dataItem.numberOfRecipients)}</p>
                </div>

                <div className={`statusIndicator ${campaignStatus(props.dataItem.campaignStatusId)?.className}`}>
                    <div className="status-box">
                        <i className={`${campaignStatus(props.dataItem.campaignStatusId)?.icon} icons-md white`}></i>
                        <span className="status-small">{campaignStatus(props.dataItem.campaignStatusId)?.title}</span>
                    </div>
                </div>

                <div className="camp-icon">
                    <ul className="camp-icon-pannel">
                        {
                            true
                            && <li>
                                <RSPTooltip text="Edit" position="top">
                                    <i
                                        className="icon-sd-pencil-edit-medium icons-md blue"
                                        onClick={() =>
                                            props.navigation.push({
                                                pathname: `communication/planning`,
                                                state: {
                                                    mode: "edit",
                                                    deliveryType: props.dataItem.deliveryType,
                                                    campaignName: props.dataItem.campaignName,
                                                    status: props.dataItem.campaignStatusId,
                                                    campaignId: props.dataItem.campaignId
                                                }
                                            })
                                        }
                                    >
                                    </i>
                                </RSPTooltip>
                            </li>
                        }
                        {
                            (props.dataItem.campaignStatusId !== 3
                                && props.dataItem.campaignStatusId !== 4)
                            && <li>
                                <RSPTooltip text="Analytics" position="top">
                                    <i
                                        className={`icon-sd-analytics-medium icons-md blue ${(props.dataItem.campaignStatusId === 9 || props.dataItem.campaignStatusId === 5) ? "" : "click-off"}`}
                                        onClick={
                                            () => {
                                                props.navigation.push({
                                                    pathname: `analytics/detail-analytics`,
                                                    state: {
                                                        campaignId: props.dataItem.campaignId,
                                                        campaignName: props.dataItem.campaignName,
                                                        campaignGuid: props.dataItem.guid,
                                                        startDate: props.dataItem.createdDate,
                                                        endDate: props.dataItem.endDate,
                                                        isEmailSplitAb: props.dataItem.isEmailSplitAB,
                                                        isSmsSplitAb: props.dataItem.isSMSSplitAB
                                                    }
                                                })
                                            }
                                        }
                                    ></i>
                                </RSPTooltip>
                            </li>
                        }
                        {props.dataItem.deliveryType.startsWith("S") && <li><RSPTooltip text="Duplicate" position="top"><i className="icon-sd-duplicate-medium icons-md blue" onClick={() => {
                            dublicateCampaign(props.dataItem);
                        }}></i></RSPTooltip></li>}
                        {/* <li>
                            <RSPTooltip text="Request approval" position="top">
                                <i
                                    className="icon-request-approved-medium icons-md blue click-off"
                                    onClick={() => {

                                    }}>
                                </i>
                            </RSPTooltip>
                        </li> */}
                        {(props.dataItem.campaignStatusId !== 9 && props.dataItem.campaignStatusId !== 5 && props.dataItem.campaignStatusId !== 52 && props.dataItem.campaignStatusId !== 54 && props.dataItem.campaignStatusId !== 12) &&
                            <li>
                                <RSPTooltip text="Archive" position="top">
                                    <div>
                                        <ArchiveMedium color={'th-primary-fill-color'} onClick={() => setIsOpenDeleteModal(true)} />
                                    </div>
                                    {/* <img
                                        className=' cp'
                                        alt="Archive"
                                        src={images.archiveMedium}
                                        onClick={() => setIsOpenDeleteModal(true)}
                                    /> */}
                                    {/* <i
                                        className="icon-archive-new-small icons-md blue"
                                        onClick={() => {
                                            setIsOpenDeleteModal(true);

                                        }}>
                                    </i> */}
                                </RSPTooltip>
                            </li>
                        }
                    </ul>
                </div>
                <div className="expand-plus">
                    <ul className="camp-icon-pannel">
                        <li>
                            <i className="k-icon k-i-plus"></i>
                        </li>
                    </ul>
                </div>
            </div>
            {isOpenDeletModal &&
                <RSConfirmAlert
                    title="Are you sure you want to delete it?"
                    secondaryButtonText="Cancel"
                    primaryButtonText="OK"
                    buttonClicked={(value) => {
                        if (value) {
                            deleteByCampaignId(props.dataItem.campaignId)
                        }
                        setIsOpenDeleteModal(false)
                    }}
                />
            }
        </td>
    );
};

export default CampaignListRowCell;

const campaignStatus = campaignStatusId => {
    return campaignProgressStatus[campaignStatusId]
}

const campaignProgressStatus = {
    0: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-medium' },
    5: {
        title: 'In progress',
        className: 'status-progress',
        icon: 'icon-sd-in-progress-medium'
    },
    51: {
        title: 'Rejected',
        className: 'status-rejected',
        icon: 'icon-sd-circle-close-medium',
    },
    52: {
        title: 'Alert',
        className: 'status-alert',
        icon: 'icon-sd-alert-medium'
    },
    54: {
        title: 'Alert',
        className: 'status-alert',
        icon: 'icon-sd-alert-medium'
    },
    12: {
        title: 'Alert',
        className: 'status-alert',
        icon: 'icon-sd-alert-medium'
    },
    9: {
        title: 'Completed',
        className: 'status-completed',
        icon: 'icon-sd-tick-medium'
    },
    7: {
        title: 'Scheduled',
        className: 'status-scheduled',
        icon: 'icon-sd-time-medium'
    },
    6: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-large' },
    20: {
        title: 'Multi status',
        className: 'status-multistatus',
        icon: 'icon-sd-multi-status-large'
    }
}