import React, { useEffect, useState } from 'react';
import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { ColumnChartSkeleton, HorizontalSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import { ch_greenLightColor, ch_orange } from 'Components/Charts/ChartColors';


function WhatsAppConversionChart(props) {

    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])


    return (
        <div className="portlet-container portlet-md portlet-footer-label">
            <div className="portlet-header">
                <h4>Conversion</h4>
            </div>
            {data?.conversionPerformanceDTOs.categories !== null && data?.conversionPerformanceDTOs?.series !== null ?
                <><div className="portlet-body">
                    <div className="portlet-chart">
                        <NewCharts options={conversionChart(data)} />
                    </div>
                </div>
                    <div className="portlet-two-label">
                        <ul>
                            <li><span>{data?.conversion.registration}</span><small>%</small></li>
                            <li>of conversions happened during this period with {data?.conversion.count} users registered</li>
                        </ul>
                    </div></> : <><NoData /> <ColumnChartSkeleton /></>
            }
        </div>);
}

export default WhatsAppConversionChart;

// Conversion -- area
const conversionChart = data => {

    return {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Date'
            },
            categories: data?.conversionPerformanceDTOs?.categories.map(item => {
                let newDate = new Date(item).toDateString()
                let splitDate = newDate.split(" ")
                return splitDate[2] + " " + splitDate[1]
            }),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 20,
        },
        symbolRadius: 0,
        plotOptions: {
            series: {
                marker: {
                    fillColor: null,
                    lineColor: null,
                    lineWidth: 1,
                    radius: 3,
                    symbol: 'circle',
                }
            },
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: '#fff', radius: 3, lineWidth: 2 },
                name: data?.conversionPerformanceDTOs?.series[0]?.name,
                color: ch_greenLightColor,
                lineWidth: 1,
                data: data?.conversionPerformanceDTOs?.series[0].datas.map(item => item || 0),
                zoneAxis: 'x',
                zones: [{ value: 6 }, { dashStyle: 'dash' }],
            }
        ],
    }

}