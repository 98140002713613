import React, { useState } from "react";

import { Col, Modal, Row } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import {
  IS_NAME_PRESENT,
  LANDING_BUILDER_URL,
  TEMPLATE_BUILDER_URL,
} from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { useContext } from "react";
import { connectServer } from "Helper/Network/networkHandler";
import { getServerConfig } from "Helper/Utils/Utils";

const TemplateCreateModal = ({
  page = "email",
  templateCreateFlag,
  setTemplateCreateFlage,
}) => {
  const context = useContext(authContext);

  const [TemplateModal, setTemplateModal] = useState(templateCreateFlag);
  const [tempErr, setTemErr] = useState(null);
  const [templateName, setTemplateName] = useState("");

  const handleDirect = () => {
    let params = {
      templateName: templateName,
      channelId: page == "email" ? "1" : "32",
      catagoryId: "6",
      templateId: 1,
    };

    let channelDetails = JSON.stringify(params);
    let baseURL = getServerConfig();

    const sessionObject = JSON.parse(localStorage.getItem("session"));
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    let fromEnvi = window.location.href.includes("app")
      ? "app"
      : window.location.href.includes("sit")
        ? "sit"
        : "run";
    window.location.href = `${baseURL}${page == "email" ? TEMPLATE_BUILDER_URL : LANDING_BUILDER_URL
      }?accessToken=${encodeURIComponent(
        accessToken
      )}&channelDetails=${encodeURIComponent(channelDetails)}&from=${fromEnvi}`;
  };

  const handleSave = () => {
    if (tempErr === null) {
      let params = {
        channelId: page === "email" ? 1 : 32,
        templateType: "Template",
        templateName: templateName,
        templateId: 0,
      };
      connectServer({
        path: IS_NAME_PRESENT,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (!res.status) {
            setTemplateModal(false);
            setTemplateCreateFlage(false);
            handleDirect();
            setTemErr("");
          } else {
            setTemErr("Template name already exists");
          }
        },
        fail: (err) => { },
      });
    } else {
      setTemErr("Enter template name");
    }
  };

  const handleCancel = () => {
    setTemplateModal(false);
    setTemErr("");
    setTemplateName("");
    setTemplateCreateFlage(false);
  };

  return (
    <Modal
      show={TemplateModal}
      centered
      backdrop="static"
      keyboard={false}
      size="md"
      onHide={() => {
        setTemplateModal(false);
      }}
    >
      <Modal.Header>
        <h2>Template name</h2>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            setTemplateCreateFlage(false);
          }}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Row className="split-ab-modal">
          <Col>
            <RSInput2
              ph="Enter template name"
              required={true}
              val={templateName}
              max={100}
              cb={(text) => {
                var regex = /^[A-Za-z0-9 ]+$/;
                let val = text.trim(" ");
                if (val.length === 0) {
                  setTemErr("Enter template name");
                  setTemplateName(text);
                } else if (!regex.test(val)) {
                  setTemErr("Special characters are not allowed");
                } else {
                  setTemplateName(text);
                  setTemErr(null);
                }
              }}
              errorMessage={tempErr}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-container d-flex justify-content-end">
          <RSSecondaryBtn
            padding="0px"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            className={(tempErr !== null || templateName.length === 0) ? "click-off" : ""}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </RSPrimaryBtn>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateCreateModal;
