import * as spc from "Components/Colors"

export const commonColors = [
    {
        color:  '#eff3fc',
        name:   'BG color',
        type:   'Background color',
        js:     '',
        scss:   ''
    },
    {
        color:  '#00246d',
        name:   'Head Band',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#0540d3',
        name:   'Primary blue color',
        type:   'Main Heading, icons, save btn',
        js:     '',
        scss:   ''
    },
    {
        color:  '#004fdf',
        name:   'Secondary blue color',
        type:   'Tab, border-top line, text box active lines',
        js:     '',
        scss:   ''
    },
    {
        color:  '#f0f8ff',
        name:   'Tertiary blue color',
        type:   'Tab, border-top line, text box active lines',
        js:     '',
        scss:   ''
    },
    {
        color:  '#c2cfe3',
        name:   'Quaternary color',
        type:   'Portlet border color',
        js:     '',
        scss:   ''
    },
    {
        color:  '#111111',
        name:   'Primary black',
        type:   'Heading text',
        js:     '',
        scss:   ''
    },
    {
        color:  '#333333',
        name:   'Secondry black',
        type:   'Breadcrumbs, Sub heading text',
        js:     '',
        scss:   ''
    },
    {
        color:  '#666666',
        name:   'Primary grey',
        type:   'Place holder, grey text',
        js:     '',
        scss:   ''
    },
    {
        color:  '#999999',
        name:   'Secondary grey',
        type:   'Texbox line, check box, Bg fill',
        js:     '',
        scss:   ''
    },
    {
        color:  '#e9e9e9',
        name:   'Tertiary grey',
        type:   'Tab, section lines',
        js:     '',
        scss:   ''
    },
    {
        color:  '#f4f4f4',
        name:   'Quaternary grey',
        type:   'Inactive bg',
        js:     '',
        scss:   ''
    },
    {
        color:  '#5ba529',
        name:   'Green',
        type:   'Active, tconfirmation color',
        js:     '',
        scss:   ''
    },
    {
        color:  '#fc6a00',
        name:   'Orange',
        type:   'Cancel btn',
        js:     '',
        scss:   ''
    },
    {
        color:  '#005534',
        name:   'Submit btn',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#ff3939',
        name:   'Remove',
        type:   '',
        js:     '',
        scss:   ''
    }
]
export const communicationStatusColors = [
    {
        color:  spc.draftColor,
        name:   'Draft',
        type:   '',
        js:     'draftColor',
        scss:   'draftColor'
    },
    {
        color:  spc.completed,
        name:   'Completed',
        type:   '',
        js:     'completed',
        scss:   'completed'
    },
    {
        color:  spc.scheduled,
        name:   'Scheduled',
        type:   '',
        js:     'scheduled',
        scss:   'scheduled'
    },
    {
        color:  spc.inprogress,
        name:   'Inprogress',
        type:   '',
        js:     'progressColor',
        scss:   'progressColor'
    },
    {
        color:  spc.alert,
        name:   'Alert',
        type:   '',
        js:     'alert',
        scss:   'alert'
    },
    {
        color:  spc.multiStatus,
        name:   'Multi status',
        type:   '',
        js:     'multistatusColor',
        scss:   'multistatusColor'
    }
]
export const languageColors = [
    {
        color:  spc.englishColor,
        name:   'English',
        type:   '',
        js:     'englishColor',
        scss:   'englishColor'
    },
    {
        color:  spc.spanishColor,
        name:   'Spanish',
        type:   '',
        js:     'spanishColor',
        scss:   'spanishColor'
    }
]
export const AudienceListColors = [
    {
        color:  '#008489',
        name:   'All audience',
        type:   ''
    },
    {
        color:  '#40abaf',
        name:   'Seed list',
        type:   ''
    },
    {
        color:  '#70c0c3',
        name:   'Ad-hoc list',
        type:   ''
    },
    {
        color:  '#b786ce',
        name:   'Match list',
        type:   ''
    },
    {
        color:  '#9f65b7',
        name:   'Suppression',
        type:   ''
    },
    {
        color:  '#804097',
        name:   'Auto cluster',
        type:   ''
    }
]
export const ChannelColors = [
    {
        color:  spc.emailColor,
        name:   'Email',
        type:   '',
        js:     'emailColor',
        scss:   'emailColor'
    },
    {
        color:  spc.smsColor,
        name:   'SMS',
        type:   '',
        js:     'smsColor',
        scss:   'smsColor'
    },
    {
        color:  spc.webPushColor,
        name:   'Webpush',
        type:   '',
        js:     'webPushColor',
        scss:   'webPushColor'
    },
    {
        color:  spc.mobilePushColor,
        name:   'Mobile push',
        type:   '',
        js:     'mobilePushColor',
        scss:   'mobilePushColor'
    },
    {
        color:  spc.vmsColor,
        name:   'VMS',
        type:   '',
        js:     'vmsColor',
        scss:   'vmsColor'
    },
    {
        color:  spc.callCenterColor,
        name:   'Call center',
        type:   '',
        js:     'callCenterColor',
        scss:   'callCenterColor'
    },
    {
        color:  spc.whatsappColor,
        name:   'WhatsApp',
        type:   '',
        js:     'whatsappColor',
        scss:   'whatsappColor'
    },
    {
        color:  spc.qrColor,
        name:   'QR code',
        type:   '',
        js:     'qrColor',
        scss:   'qrColor'
    },
    {
        color:  spc.directMailColor,
        name:   'Direct mail',
        type:   '',
        js:     'directMailColor',
        scss:   'directMailColor'
    },
    {
        color:  spc.paidMediaColor,
        name:   'Paid media',
        type:   '',
        js:     'paidMediaColor',
        scss:   'paidMediaColor'
    },
    {
        color:  spc.twitterColor,
        name:   'Twitter',
        type:   '',
        js:     'twitterColor',
        scss:   'twitterColor'
    },
    {
        color:  spc.facebookColor,
        name:   'Facebook',
        type:   '',
        js:     'facebookColor',
        scss:   'facebookColor'
    },
    {
        color:  spc.linkedinColor,
        name:   'Linkedin',
        type:   '',
        js:     'linkedinColor',
        scss:   'linkedinColor'
    },
    {
        color:  spc.instaColor,
        name:   'Insta',
        type:   '',
        js:     'instaColor',
        scss:   'instaColor'
    },
    {
        color:  spc.pinterestColor,
        name:   'Pinterest',
        type:   '',
        js:     'pinterestColor',
        scss:   'pinterestColor'
    },
    {
        color:  spc.notificationColor,
        name:   'Notification',
        type:   '',
        js:     'notificationColor',
        scss:   'notificationColor'
    },
    {
        color:  spc.googleColor,
        name:   'google',
        type:   'not in platform',
        js:     'googleColor',
        scss:   'googleColor'
    }
]
export const ChannelColors2 = [
    {
        color:  '#5f1147',
        name:   'Zip code',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#9e003d',
        name:   'Gender',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#00acde',
        name:   'Male',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#c72079',
        name:   'Female',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#da0034',
        name:   'Age',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#ff4418',
        name:   'Country',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#ffc000',
        name:   'Website',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#cccccc',
        name:   'Others',
        type:   '',
        js:     '',
        scss:   ''
    }
]
export const SourceColors = [
    {
        color:  '#2a79c2',
        name:   'List audience',
        type:   '',
        js:     '',
        scss:   ''
    },
    {
        color:  '#b26aae',
        name:   'Dynamic audience',
        type:   '',
        js:     '',
        scss:   ''
    }
]
export const sentimentColors = [
    {
        color:  spc.positiveColor,
        name:   'Positive',
        type:   '',
        js:     'positiveColor',
        scss:   'positiveColor'
    },
    {
        color:  spc.neutral,
        name:   'Neutral',
        type:   '',
        js:     'neutral',
        scss:   'neutral'
    },
    {
        color:  spc.negative,
        name:   'Negative',
        type:   '',
        js:     'negative',
        scss:   'negative'
    }
]
export const weekColors = [
    {
        color:  spc.monday,
        name:   'Monday',
        type:   '',
        js:     'monday',
        scss:   'monday'
    },
    {
        color:  spc.tuesday,
        name:   'Tueday',
        type:   '',
        js:     'tuesday',
        scss:   'tuesday'
    },
    {
        color:  spc.wednesday,
        name:   'Wednesday',
        type:   '',
        js:     'wednesday',
        scss:   'wednesday'
    },
    {
        color:  spc.thursday,
        name:   'Thursday',
        type:   '',
        js:     'thursday',
        scss:   'thursday'
    },
    {
        color:  spc.friday,
        name:   'Friday',
        type:   '',
        js:     'friday',
        scss:   'friday'
    },
    {
        color:  spc.saturday,
        name:   'Saturday',
        type:   '',
        js:     'saturday',
        scss:   'saturday'
    },
    {
        color:  spc.sunday,
        name:   'Sunday',
        type:   '',
        js:     'sunday',
        scss:   'sunday'
    }
]
export const dayColors = [
    {
        color:  spc.earlyMorningColor,
        name:   'Early morning',
        type:   '',
        js:     'earlyMorningColor',
        scss:   'earlyMorningColor'
    },
    {
        color:  spc.morningColor,
        name:   'Morning',
        type:   '',
        js:     'morningColor',
        scss:   'morningColor'
    },
    {
        color:  spc.afternoonColor,
        name:   'Afternoon',
        type:   '',
        js:     'afternoonColor',
        scss:   'afternoonColor'
    },
    {
        color:  spc.eveningColor,
        name:   'Evening',
        type:   '',
        js:     'eveningColor',
        scss:   'eveningColor'
    },
    {
        color:  spc.nightColor,
        name:   'Night',
        type:   '',
        js:     'nightColor',
        scss:   'nightColor'
    }
]
export const deviceColors = [
    {
        color:  spc.android,
        name:   'Android',
        type:   '',
        js:     'android',
        scss:   'android'
    },
    {
        color:  spc.iosColor,
        name:   'iOS',
        type:   '',
        js:     'iOSColor',
        scss:   'iOSColor'
    },
    {
        color:  spc.macColor,
        name:   'Mac',
        type:   '',
        js:     'macColor',
        scss:   'macColor'
    },
    {
        color:  spc.windowsColor,
        name:   'Windows',
        type:   '',
        js:     'windowsColor',
        scss:   'windowsColor'
    },
    {
        color:  spc.googlePlaystoreColor,
        name:   'Google playStore',
        type:   '',
        js:     'googlePlayStoreColor',
        scss:   'googlePlayStoreColor'
    },
    {
        color:  spc.youtubeWatchpageColor,
        name:   'Youtube watch page',
        type:   '',
        js:     'youtubeWatchpageColor',
        scss:   'youtubeWatchpageColor'
    },
    {
        color:  spc.televisionColor,
        name:   'Television',
        type:   '',
        js:     'televisionColor',
        scss:   'televisionColor'
    },
    {
        color:  spc.fullyConversedColor,
        name:   'Fully conversed',
        type:   'not in platform',
        js:     'fullyConversedColor',
        scss:   'fullyConversedColor'
    }
]
export const knwonColors = [
    {
        color:  spc.knownColor,
        name:   'known',
        type:   '',
        js:     'knownColor',
        scss:   'knownColor'
    },
    {
        color:  spc.unKnownColor,
        name:   'Unknown',
        type:   '',
        js:     'unKnownColor',
        scss:   'unKnownColor'
    },
    {
        color:  spc.identifiedColor,
        name:   'Identified',
        type:   '',
        js:     'identifiedColor',
        scss:   'identifiedColor'
    }
]
export const othersColors = [
    {
        color:  spc.blueColor,
        name:   'Blue',
        type:   '',
        js:     'blueColor',
        scss:   'blueColor'
    },
    {
        color:  spc.blueLightColor,
        name:   'Blue light',
        type:   '',
        js:     'blueLightColor',
        scss:   'blueLightColor'
    },
    {
        color:  spc.greenColor,
        name:   'Green',
        type:   '',
        js:     'greenColor',
        scss:   'greenColor'
    },
    {
        color:  spc.greenLightColor,
        name:   'Green light',
        type:   '',
        js:     'greenLightColor',
        scss:   'greenLightColor'
    },
    {
        color:  spc.yellowColor,
        name:   'Yellow',
        type:   '',
        js:     'yellowColor',
        scss:   'yellowColor'
    },
    {
        color:  spc.orangeColor,
        name:   'Orange',
        type:   '',
        js:     'orangeColor',
        scss:   'orangeColor'
    },
    {
        color:  spc.cyanColor,
        name:   'Cyan',
        type:   '',
        js:     'cyanColor',
        scss:   'cyanColor'
    },
    {
        color:  spc.greyLightColor,
        name:   'Grey light',
        type:   '',
        js:     'greyLightColor',
        scss:   'greyLightColor'
    },
    {
        color:  spc.greyDarkColor,
        name:   'Grey dark',
        type:   '',
        js:     'greyDarkColor',
        scss:   'greyDarkColor'
    },
    {
        color:  spc.othersColor,
        name:   'Others',
        type:   '',
        js:     'othersColor',
        scss:   'othersColor'
    }
]

export const chartCommonColors = [
    {
        color:  spc.chartColor01,
        name:   'Chart 1',
        type:   '',
        js:     'chartColor01',
        scss:   'chartColor01'
    },
    {
        color:  spc.chartColor02,
        name:   'Chart 2',
        type:   '',
        js:     'chartColor02',
        scss:   'chartColor02'
    },
    {
        color:  spc.chartColor03,
        name:   'Chart 3',
        type:   '',
        js:     'chartColor03',
        scss:   'chartColor03'
    },
    {
        color:  spc.chartColor04,
        name:   'Chart 4',
        type:   '',
        js:     'chartColor04',
        scss:   'chartColor04'
    },
    {
        color:  spc.chartColor05,
        name:   'Chart 5',
        type:   '',
        js:     'chartColor05',
        scss:   'chartColor05'
    },
    {
        color:  spc.chartColor06,
        name:   'Chart 6',
        type:   '',
        js:     'chartColor06',
        scss:   'chartColor06'
    },
    {
        color:  spc.chartColor07,
        name:   'Chart 7',
        type:   '',
        js:     'chartColor07',
        scss:   'chartColor07'
    },
    {
        color:  spc.chartColor08,
        name:   'Chart 8',
        type:   '',
        js:     'chartColor08',
        scss:   'chartColor08'
    },
    {
        color:  spc.chartColor09,
        name:   'Chart 9',
        type:   '',
        js:     'chartColor09',
        scss:   'chartColor09'
    }
]