import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Grid, GridColumn, GridRow } from "@progress/kendo-react-grid";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { BootstrapDropdown } from "Components/BootstrapDropdown";
import _map from "lodash/map";
import AddAudienceModal from "./AddAudienceModal";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { numberWithCommas } from "Helper/Utils/Utils";
import {
  GET_AVAILABLE_ATTRIBUTES,
  ATTRIBUTE_MAPING,
  SAVE_AUDIENCE_COLUMN_MAPPING,
  GET_CRM_TABLE_DATA,
  UPDATE_CRM_REMOTE_COLUMN,
  SAVEBQ_REMOTEDATA,
  GET_SQL_TABLE_DATA,
} from "Helper/Constants/endPoint";
import { RSDropdownList } from "Components/RSDropdowns";
import NewAttributeModal from "./NewAttributeModal";
import { KendoIconDropdown } from "Components/KendoDropdown/IconDropdown";
// import HtmlParser from "react-html-parser";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { ReactComponent as Settings } from "Assets/Images/settings-large.svg";
import { ReactComponent as Lock } from "Assets/Images/lock-medium.svg";
import { ReactComponent as User } from "Assets/Images/user-tick-star-mini.svg";
import ErrorModal from "./ErrorModal";
// import { PageHeader } from "Components/PageHeader/PageHeader";
import { RSPTooltip } from "Components/Tooltip";
import { isEmpty } from "lodash";

const AvailableCodes = [
  // { code: "Settings", icon: <Settings />, text: "Settings" },
  { code: "user", icon: <User />, text: "Unique identifier", disabled: true },
  { code: "lock", icon: <Lock />, text: "Sensitive data", disabled: true },
];
function selectURL(mode) {
  switch (mode) {
    case "manualupload":
    case "csv":
      return {
        mappingUrl: ATTRIBUTE_MAPING,
        saveUrl: SAVE_AUDIENCE_COLUMN_MAPPING,
        mode,
      };
    case "crm":
      return {
        mappingUrl: GET_CRM_TABLE_DATA,
        saveUrl: UPDATE_CRM_REMOTE_COLUMN,
        mode,
      };
    case "bigquery": {
      return {
        mappingUrl: SAVEBQ_REMOTEDATA,
        saveUrl: UPDATE_CRM_REMOTE_COLUMN,
        mode,
      };
    }
    case "sql": {
      return {
        mappingUrl: GET_SQL_TABLE_DATA,
        saveUrl: UPDATE_CRM_REMOTE_COLUMN,
        mode,
      };
    }
  }
}

const AddImportAudience = (props) => {
  const history = useHistory();

  const location = useLocation();
  const context = useContext(authContext);
  const [selectedRoleType, setSelectedRoleType] = useState(dropdownRoles[1]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOpenInfoEmailModal, setIsOpenInfoEmailModal] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [audienceDataAttributes, setAudienceDataAttributes] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedIconDropDown, setSelectedIconDropDown] = useState([]);
  const [selectedAttributeData, setselectedAttributeData] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfimation, setDeleteConfirmation] = useState({});
  const [doubleOptCheckbox, setdoubleOptCheckbox] = useState(false);
  const [doubleOptCheckboxError, setdoubleOptCheckboxError] = useState(null);
  const [SaveAttributeErrorModal, setSaveAttributeErrorModal] = useState(false);
  const [dropDownError, setDropDownError] = useState([]);
  const [uniqueKey, setuniquekey] = useState(null);
  const [uniqueKeyError, setUniqueKeyError] = useState(null);
  const [potentialAudience, setPotentialAudience] = useState(0);
  const [availableCodes, setAvailableCodes] = React.useState(AvailableCodes);
  const jobId = location?.state?.jobId || "";
  const isRemoteSourceMode =
    history?.location.state.mode === "remoteDataSource";
  const selectURLForAPI = selectURL(history.location.state?.mode);

  useEffect(() => {
    getDataAttributes();
  }, []);

  useEffect(() => {
    const iconTemp = columnData?.map((column, idx) => {
      const columnName = column.uiPrintableName?.includes(".")
        ? column.uiPrintableName?.split(".")[1]
        : column.uiPrintableName;
      if (columnName === "EmailID" || columnName === "MobileNo") {
        return {
          code: "Settings",
          icon: <Settings />,
          isShow: true,
        };
      } else {
        return {
          code: "Settings",
          icon: <Settings />,
          isShow: false,
        };
      }
    });
    setSelectedIconDropDown(iconTemp);
  }, [columnData]);
  const getTableData = (attributes = []) => {
    connectServer({
      path: selectURLForAPI.mappingUrl,
      params: location?.state?.params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      ok: (res) => {
        if (res?.status) {
          setPotentialAudience(res?.data?.audienceCount);
          let prevData = [...selectedData];
          const data = [...res?.data?.audienceData];
          const temp = [];
          var tempObject = {};
          data[0].split(",")?.forEach((item, index) => {
            tempObject = {
              ...tempObject,
              [item]: item,
            };
            const findAttribute = attributes?.find((attribute, idx) => {
              const isSplit = item?.includes(".")
                ? item?.split(".")?.[1]
                : item;
              if (
                attribute.uiPrintableName?.trim()?.toLowerCase() ===
                isSplit?.trim()?.toLowerCase()
              ) {
                return attribute;
              }
            });
            const isFoundAttribute = !!Object.keys(findAttribute || {})?.length;
            prevData[index] = isFoundAttribute
              ? {
                dataAttributeID: findAttribute?.dataAttributeID,
                uiPrintableName: findAttribute?.uiPrintableName,
                fieldTypeID: findAttribute?.fieldTypeID,
              }
              : {
                dataAttributeID: 0,
                uiPrintableName: "-- Select --",
              };
          });
          setSelectedData(prevData);
          temp.push(tempObject);
          const spliceArr = data.slice(1, data.length);
          const heading = Object.keys(temp[0]);
          spliceArr.forEach((item, index) => {
            let temporary = {};
            item.split(",").forEach((item, index) => {
              temporary = {
                ...temporary,
                [heading[index]]: item,
              };
            });
            temp.push(temporary);
          });
          setAudienceList(temp);
          const column = temp[0];
          setColumnData(
            Object.keys(column).map((item, index) => {
              return {
                dataAttributeID: index + 1,
                uiPrintableName: item,
              };
            })
          );
        } else {
          history.push({
            pathname: "/audience/add-audience",
            state: { audienceType: "rds" },
          });
        }
      },
      fail: (err) => {
        history.push({
          pathname: "/audience/add-audience",
          state: { audienceType: "rds" },
        });
      },
    });
  };
  const getDataAttributes = (name) => {
    const params = {
      departmentId: 0,
    };

    connectServer({
      path: GET_AVAILABLE_ATTRIBUTES,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      ok: (res) => {
        if (res?.status) {
          getTableData(res?.data);
          const newAttribute = {
            dataAttributeID: res?.data.length + 2,
            uiPrintableName: "New Attribute",
          };
          setAudienceDataAttributes([
            ...res?.data.map((item) => ({
              dataAttributeID: item?.dataAttributeID,
              uiPrintableName: item?.uiPrintableName,
              fieldTypeID: item?.fieldTypeID,
            })),
            {
              dataAttributeID: res?.data.length + 1,
              uiPrintableName: "<< Ignore >>",
            },
            newAttribute,
          ]);
          if (Object.keys(selectedAttributeData).length) {
            const findItem = res?.data.find(
              (item) => item?.uiPrintableName === name
            );
            const temp = [...selectedData];
            temp[selectedAttributeData.index] = {
              dataAttributeID: findItem?.dataAttributeID,
              uiPrintableName: findItem?.uiPrintableName,
              fieldTypeID: findItem?.fieldTypeID,
            };
            setSelectedData(temp);
            setselectedAttributeData({});
          }
        } else {
          const newAttribute = {
            dataAttributeID: res?.data.length + 2,
            uiPrintableName: "New Attribute",
          };
          setAudienceDataAttributes([
            newAttribute,
            {
              dataAttributeID: res?.data.length + 1,
              uiPrintableName: "<< Ignore >>",
            },
          ]);
        }
      },
      fail: (err) => { },
    });
  };

  // const getAudienceList = () => {
  //   const params = { departmentId: 1 };

  //   connectServer({
  //     path: GET_DATA_ATTRIBUTES,
  //     params,
  //     sessionOut: context.globalStateData.setSessionOutData,
  //     loading: context.globalStateData.setIsLoadingData,
  //     ok: (res) => {
  //       if (res?.status) {
  //         const data = [...dataResult];
  //         var headerObject = {};
  //         Object.keys(data[0]).forEach((item) => {
  //           headerObject = {
  //             ...headerObject,
  //             [item]: item,
  //           };
  //         });
  //         setAudienceList([headerObject, ...data]);
  //       }
  //     },
  //   });
  // };

  const infoBtnEmailClicked = () => {
    let isValid = true;
    selectedData?.forEach((column, idx) => {
      const currentItem = { ...column };
      if (currentItem?.uiPrintableName?.toLowerCase()?.includes("select")) {
        setDropDownError((prev) => {
          const temp = [...prev];
          temp[idx] = "Select column to proceed";
          return temp;
        });
        isValid = false;
      }
    });
    if (!doubleOptCheckbox) {
      setdoubleOptCheckboxError("Please confirm double opt-in subscription.");
      isValid = false;
    }
    if (
      !(selectURLForAPI.mode === "crm") ||
      !(selectURLForAPI.mode === "bigquery")
    ) {
      if (uniqueKey === null) {
        setUniqueKeyError("Set unique identifier to proceed");
        isValid = false;
      }
    }
    let params;
    if (
      selectURLForAPI.mode === "csv" ||
      selectURLForAPI.mode === "manualupload"
    ) {
      const getUiPrintableName = selectedData.map(
        (item) => item?.uiPrintableName
      );
      params = {
        sourceColumn: Object.keys(audienceList[0])
          .map((item) => item)
          .join(","),
        destinationColumn: getUiPrintableName.join(","),
        jobID: history.location.state?.params?.jobId,
        dataattributeID: selectedData.map((item) => item?.dataAttributeID),
        FieldTypeID: selectedData.map((item) => item?.fieldTypeID),
        // dataattributeID: audienceDataAttributes
        //   .filter((item) => getUiPrintableName.includes(item?.uiPrintableName))
        //   .map((item) => item?.dataAttributeID),
        isUniqueKeyColumn: selectedData
          .map((item, index) => {
            if (index === uniqueKey) return true;
            else return false;
          })
          .join(","),
        DepartmentID: parseInt(localStorage.getItem("departmentID"), 10),
      };
    } else if (selectURLForAPI.mode === "crm") {
      params = {
        SourceColumnName: Object.keys(audienceList[0] || {})
          .map((item) => item)
          .join(","),
        RemoteSettingID:
          history.location.state?.remoteSettingId?.remoteSettingID + "",
        DestinationColumnName: Object.keys(audienceList[0] || {})
          .map((item, index) => selectedData[index]?.uiPrintableName)
          .join(","),
        FieldTypeID: selectedData.map((item) => item?.fieldTypeID).join(","),
      };
    } else if (selectURLForAPI.mode === "bigquery") {
      params = {
        SourceColumnName: Object.keys(audienceList[0] || {})
          .map((item, index) => item)
          .join(","),
        RemoteSettingID: location?.state?.params?.RemoteSettingID,
        DestinationColumnName: Object.keys(audienceList[0] || {})
          .map((item, index) => selectedData[index]?.uiPrintableName)
          .join(","),
      };
    } else if (selectURLForAPI.mode === "sql") {
      params = {
        SourceColumnName: Object.keys(audienceList[0] || {})
          .map((item, index) => item)
          .join(","),
        RemoteSettingID: location?.state?.params?.RemoteSettingID,
        DestinationColumnName: Object.keys(audienceList[0] || {})
          .map((item, index) => selectedData[index]?.uiPrintableName)
          .join(","),
        FieldTypeID: _map(selectedData, (attribute, idx) => {
          return attribute.fieldTypeID;
        }).join(","),
      };
    }
    // setIsOpenInfoEmailModal(!isOpenInfoEmailModal);
    if (isValid) {
      connectServer({
        path: selectURLForAPI.saveUrl,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        ok: (res) => {
          if (res?.status) {
            setIsOpenInfoEmailModal(true);
          }
          // if (
          //   selectURLForAPI.mode === "manualupload" ||
          //   selectURLForAPI.mode === "csv"
          // ) {
          //   history.push({
          //     pathname: "/audience",
          //     state: { index: 0 },
          //   });
          // } else {
          //   history.push({
          //     pathname: "/preferences/data-exchange",
          //   });
          // }
        },
        fail: (err) => {
          setIsOpenInfoEmailModal(false);
          setSaveAttributeErrorModal(true);
        },
      });
    }
  };

  const setIcon = (eventKey, index) => {
    const temp = [...selectedIconDropDown];
    const tempSelectedData = [...selectedData];
    const tempAvailableCodes = [...availableCodes];
    const settingsIcon = { code: "settings", icon: <Settings /> };
    const find = availableCodes?.find((item) => item.code === eventKey);
    if (
      tempSelectedData[index].uiPrintableName?.toLowerCase()?.includes("select")
    ) {
      return;
    }
    if (eventKey === "lock") {
      return;
    }
    if (selectedIconDropDown[index].code === eventKey) {
      const iconTemp = columnData.map((column, idx) => {
        const columnName = column.uiPrintableName?.includes(".")
          ? column.uiPrintableName?.split(".")[1]
          : column.uiPrintableName;
        if (index === idx) {
          return {
            ...settingsIcon,
            isShow: true,
          };
        } else if (
          columnName === "EmailID" ||
          columnName === "MobileNo" ||
          columnName === "BrandID"
        ) {
          return {
            ...settingsIcon,
            isShow: true,
          };
        } else {
          return {
            ...settingsIcon,
            isShow: false,
          };
        }
      });
      const availableCodes = tempAvailableCodes.map((item, itemIdx) => {
        return {
          ...item,
          disabled: true,
        };
      });
      setAvailableCodes(availableCodes);
      setSelectedIconDropDown(iconTemp);
      setuniquekey(null);
    } else {
      const iconTemp = columnData.map((column, idx) => {
        if (index === idx) {
          return {
            ...find,
            isShow: true,
          };
        } else {
          return {
            ...settingsIcon,
            isShow: false,
          };
        }
      });
      const availableCodes = tempAvailableCodes.map((item, itemIdx) => {
        return {
          ...item,
          disabled: true,
        };
      });
      setAvailableCodes(availableCodes);
      setSelectedIconDropDown(iconTemp);
    }

    // setSelectedIconDropDown(temp);

    if (index !== uniqueKey && eventKey === "user") {
      setuniquekey(index);
    }
    if (index === uniqueKey && eventKey !== "user") {
      setuniquekey(null);
    }
    if (uniqueKeyError !== null && eventKey === "user") {
      setUniqueKeyError(null);
    }
  };

  const renderItem = (li) => {
    const itemChildren = (
      <p>
        {li.props.children}{" "}
        {li.props.children.includes("New Attribute") && (
          <i className="icon-sd-circle-plus-fill-edge-medium icon-lg blue cursor-pointer"></i>
        )}
      </p>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const deleteEntry = (props, Index) => {
    setConfirmationModal(true);
    setDeleteConfirmation({
      props: props,
      index: Index,
    });
  };

  const customheader = (props, Index) => {
    return (
      <div
        className={
          "d-flex error-icon-show new-flex-gap  position-relative top-5"
        }
        key={Index}
      >
        <div
          className="delete-icon cursor-pointer"
          onClick={() => deleteEntry(props, Index)}
        >
          <span className="grid-table-remove-icon">
            <RSPTooltip text="Remove" position="top">
              <i className="icon-minus-cir-small red customStyleMinus fs13"></i>
            </RSPTooltip>
          </span>
        </div>
        <div className="position-relative">
          {dropDownError[Index]?.length && (
            <div
              className="validation-message top-18"
              style={{ fontSize: "12px" }}
            >
              {dropDownError[Index]}
            </div>
          )}
          <RSDropdownList
            className="mb0"
            data={audienceDataAttributes && audienceDataAttributes}
            defaultItem={{
              dataAttributeID: -1,
              uiPrintableName: "--Select--",
            }}
            textField="uiPrintableName"
            dataItemKey="dataAttributeID"
            value={selectedData?.[Index]}
            onChange={(e) => {
              const findData = selectedData.find(
                (item) => item?.uiPrintableName === e.value?.uiPrintableName
              );
              const selectedValue =
                e.value?.uiPrintableName !== "--Select--"
                  ? e.value?.uiPrintableName
                  : "";
              if (
                Object.keys(
                  selectedData.find(
                    (item) => item?.uiPrintableName === selectedValue
                  ) || {}
                ).length > 0
              ) {
                setSelectedData((prev) => {
                  const data = [...prev];
                  data[Index] = undefined;
                  return data;
                });
                setDropDownError((prev) => {
                  const temp = [...prev];
                  temp[Index] = "Please select a different attribute";
                  return temp;
                });
                return;
              }
              setDropDownError((prev) => {
                const temp = [...prev];
                temp[Index] = null;
                return temp;
              });
              if (e.value?.uiPrintableName === "New Attribute") {
                setModalOpen(true);
                setselectedAttributeData({
                  props: props,
                  index: Index,
                });
              } else if (e.value?.uiPrintableName === "<< Ignore >>") {
                setSelectedData((prev) => {
                  const data = [...prev];
                  data[Index] = undefined;
                  return data;
                });
              } else {
                setSelectedData((prev) => {
                  const data = [...prev];
                  data[Index] = { ...e?.value };
                  return data;
                });
              }
            }}
            itemRender={renderItem}
          />
        </div>
        <div>
          <Dropdown onSelect={(eventKey) => setIcon(eventKey, Index)}>
            <Dropdown.Toggle
              className={`${selectedIconDropDown?.[Index]?.code?.length ? "active_icon" : ""
                }`}
            >
              {selectedIconDropDown?.[Index]?.icon}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableCodes?.map((item, idx) => {
                return (
                  <RSPTooltip text={item.text} position="top">
                    <Dropdown.Item
                      eventKey={item.code}
                      className={`${selectedIconDropDown?.[Index]?.code !== "user" &&
                        item?.disabled &&
                        !selectedIconDropDown?.[Index]?.isShow
                        ? "click-off"
                        : ""
                        }`}
                    >
                      {item.icon}
                    </Dropdown.Item>
                  </RSPTooltip>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  const customCell = (props, index) => {
    return (
      <td
        style={{
          backgroundColor:
            props.id.startsWith("_ai0") &&
              audienceDataAttributes?.find(
                (item) =>
                  item?.uiPrintableName?.toLowerCase() ===
                  selectedData[index]?.uiPrintableName?.toLowerCase()
              ) &&
              selectedData[index]?.uiPrintableName !== undefined
              ? "#fc6a00"
              : props.id.startsWith("_ai0")
                ? "#e9e9eb"
                : null,
          color:
            props.id.startsWith("_ai0") &&
              audienceDataAttributes?.find(
                (item) =>
                  item?.uiPrintableName?.toLowerCase() ===
                  selectedData[index]?.uiPrintableName?.toLowerCase()
              ) &&
              selectedData[index]?.uiPrintableName !== undefined
              ? "white"
              : null,
          fontStyle: props.id.startsWith("_ai0") ? "italic" : null,
        }}
      >
        {props.dataItem[props.field]}
      </td>
    );
  };

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        {/* <PageHeader
          issubHeading={false}
          issubHeadingDropDown={false}
          title="Audience list"
          isBUs={true}
          isBack={false}
          isBusinessUnit={true}
          isAgency={false}
        /> */}
        {/* <hr className="blue mb10" /> */}

        <Row>
          {isOpenInfoEmailModal ? (
            <AddAudienceModal
              isOpen={isOpenInfoEmailModal}
              onChangeIsOpen={(value) => {
                setIsOpenInfoEmailModal(!isOpenInfoEmailModal);
              }}
              history={history}
              mode={selectURLForAPI?.mode}
            ></AddAudienceModal>
          ) : null}
          <div className="d-flex align-items-end justify-content-between mb15">
            <div>
              <h3 className="black-medium">New audience list</h3>
              <small>
                To map the data header with the available attributes, refer the
                first row.
              </small>
            </div>
            <h4 className="black-medium">
              Potential audience -{" "}
              <span className="font-lg">
                {" "}
                {numberWithCommas(potentialAudience)}
              </span>
            </h4>
          </div>
        </Row>
        <div className="audience-list scroll_audience_list">
          {audienceList?.length === 0 ? (
            <SkeletonTable count={5} />
          ) : (
            <Grid
              className="grid-vertical-noscroll importMappingGrid validation-top"
              data={audienceList}
            >
              {columnData.map((item, index) => {
                return (
                  <GridColumn
                    title={item.uiPrintableName}
                    field={item.uiPrintableName}
                    // width={"240px"}
                    key={index}
                    headerCell={(props) => customheader(props, index)}
                    cell={(props) => customCell(props, index)}
                  />
                );
              })}
            </Grid>
          )}
          <div className="mt23 position-relative">
            {doubleOptCheckboxError?.length && (
              <div className="validation-message transY-5">
                {doubleOptCheckboxError}
              </div>
            )}
            <RSCheckbox
              lbl={"Select all as double opt-in audience."}
              checked={doubleOptCheckbox}
              cb={(e) => {
                setdoubleOptCheckbox(e);
                if (!e) {
                  setdoubleOptCheckboxError(
                    "Please confirm double opt-in subscription."
                  );
                } else {
                  setdoubleOptCheckboxError(null);
                }
              }}
              className="m0"
            />
          </div>
          {uniqueKey !== null && (
            <p className="font-xs color-red text-right mt15">
              {columnData?.[uniqueKey]?.uiPrintableName} has been selected as an
              Unique identifier
            </p>
          )}
          {uniqueKeyError !== null && (
            <p className="font-xs color-red">{uniqueKeyError}</p>
          )}
        </div>

        <Row>
          <div className="d-flex justify-content-end">
            <RSSecondaryBtn
              onClick={() => {
                history.push({
                  pathname: "/audience/add-audience",
                  state: { audienceType: "rds" },
                });
              }}
            >
              Restart
            </RSSecondaryBtn>
            <RSPrimaryBtn
              onClick={() => infoBtnEmailClicked(!isOpenInfoEmailModal)}
              className={`${selectedData.length === columnData.length &&
                selectedData.every(
                  (item) =>
                    item !== null &&
                    Object.keys(item || {}).length !== 0 &&
                    item !== undefined
                )
                ? ""
                : "click-off"
                }`}
            >
              Save
            </RSPrimaryBtn>
          </div>
        </Row>
      </Container>
      {isModalOpen && (
        <NewAttributeModal
          isOpen={isModalOpen}
          onChangeIsOpen={(value) => {
            setModalOpen(value);
          }}
          availData={audienceDataAttributes}
          context={context}
          isReload={(status, SavedData) => {
            if (status) {
              getDataAttributes(SavedData?.name);
            }
          }}
        />
      )}
      {confirmationModal && (
        <ErrorModal
          isOpen={confirmationModal}
          onChangeIsOpen={(status) => {
            setConfirmationModal(status);
          }}
          Confirm={(status) => {
            if (status) {
              const temp = [...columnData].filter((item) => {
                return (
                  item?.uiPrintableName !== deleteConfimation?.props?.field
                );
              });
              const tempSelectedData = [...selectedData].filter(
                (item, index) => {
                  return index !== deleteConfimation?.index;
                }
              );
              const tempDropDownError = [...dropDownError].filter(
                (item, index) => {
                  return index !== deleteConfimation?.index;
                }
              );
              setDropDownError(tempDropDownError);
              setSelectedData(tempSelectedData);
              const audienceTemp = [...audienceList].map((item) => {
                const temp = Object.entries(item);
                temp.splice(deleteConfimation?.index, 1);
                return Object.fromEntries(temp);
              });
              if (Object.keys(audienceTemp[0]).length) {
                setAudienceList(audienceTemp);
              } else {
                history.push({
                  pathname: "/audience/add-audience",
                  state: { audienceType: "rds" },
                });
              }
              setColumnData(temp);
              setuniquekey(null);
            }
          }}
          primary={"Ok"}
          secondaryButton={"Cancel"}
        >
          {Object.keys(audienceList[0]).length === 1
            ? "Are you sure you want to delete the last column"
            : "Are you sure you want to delete?"}
        </ErrorModal>
      )}
      {SaveAttributeErrorModal && (
        <ErrorModal
          isOpen={SaveAttributeErrorModal}
          onChangeIsOpen={(status) => {
            setSaveAttributeErrorModal(status);
          }}
          title={"Error:"}
          secondaryButton={"Close"}
        >
          Unexpected happened. Try again later
        </ErrorModal>
      )}
    </LayoutPageWrapper>
  );
};
export default AddImportAudience;
const dropdownRoles = ["All", "Personal", "Business", "Privilege"];
