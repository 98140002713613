import React from 'react';
import { Popup } from '@progress/kendo-react-popup';

export const CustomPopup = props => {
  return <Popup {...props}
  anchorAlign={{
    horizontal: 'left',
    vertical: 'bottom'
  }} popupAlign={{
    horizontal: 'left',
    vertical: 'top'
  }} />;
};