import React, { useContext, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { RSTextInputWithIcon } from 'Components/RSInputs';
import { GET_OFFER_COUPON } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
const PromoCodeModal = (props) => {

    const context = useContext(authContext)
    const [show, setShow] = useState(props.isOpen);
    const [promoCodeApplied, setPromoCodeApplied] = useState(null)
    const [promoCode, setPromoCode] = useState("")
    const [promoCodeErr, setPromoCodeErr] = useState(null)
    const [promoSuccess, setPromoSuccess] = useState(false)
    const [discountAmount, setDiscountAmount] = useState(0)

    const getOfferCost = () => {
        const params = {
            couponCode: promoCode,
            licensingTypeId: 0,
            frequencyId: 0,
            offerTypeId: 2
        }
        connectServer({
            path: GET_OFFER_COUPON,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    if (!!res.data) {
                        setDiscountAmount(res.data.discountAmount);
                        setPromoCodeApplied(true)
                        setPromoCodeErr("Coupon code applied successfully")
                        setPromoSuccess(true)
                        props.onChangeOpen(true)
                        setTimeout(() => {
                            setPromoCodeErr(null)
                            setPromoSuccess(false)
                            setShow(false)
                            props.onChangeOpen(false)
                        }, 3000);
                    }
                } else {
                    setDiscountAmount(0);
                    setPromoCodeApplied(false)
                    setPromoCodeErr("Invalid coupon code")
                    setPromoSuccess(false)
                }
            },
            fail: error => {
                setDiscountAmount(0);
                setPromoCodeErr("Invalid coupon code")
                setPromoSuccess(false)
                setPromoCodeApplied(false)
                setTimeout(() => {
                    setPromoCodeErr(null)
                }, 3000);
            }
        })
    }
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                setShow(false)
                props.onChangeOpen(false)
            }}
            centered
            size='md'
        >
            <Modal.Header closeButton>
                <h2>Promo code</h2>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <label className="label-row">Promo code</label>
                    </Col>
                    <Col md={6}>
                        <RSTextInputWithIcon
                            ph='Enter coupon code'
                            disabled={promoCodeApplied}
                            val={promoCode}
                            icon={`${promoCodeApplied === null ? '' : promoCodeApplied ? 'icon-sd-circle-tick-medium green mr10' : 'icon-sd-circle-close-fill-medium red'} icons-md mr10`}
                            max={15}
                            onClickIcon={() => {
                                setPromoCode("")
                                setPromoCodeErr(null)
                                setPromoCodeApplied(null)
                                setDiscountAmount(0)
                                setPromoSuccess(false)
                            }}
                            cb={value => {
                                setPromoCode(value)
                                setPromoCodeApplied(null)

                                // if (value.length) {
                                setPromoCodeErr(null)
                                // } else {
                                //   setPromoCodeErr("Enter promo code")
                                // }
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end marginT30">
                    <RSSecondaryBtn onClick={() => {
                        setShow(false)
                        props.onChangeOpen(false)
                    }} variant="outline-success">
                        Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn onClick={() => {
                        getOfferCost()

                    }} variant="outline-success">
                        Apply
                    </RSPrimaryBtn>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default PromoCodeModal