import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap"
import CreditCard from './Components/CreditCard';
import Paypal from './Components/Paypal';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import paypalImg from 'Assets/Images/paypal.png';
import { RTabbar } from 'Components/RChart/RTabbar';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { CHECK_CARD_TYPE, POST_PAYMENT_SUBMIT, SAVE_SUBSCRIBE_PAYMENT } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { ENTER_NUMBER_ON_CARD } from 'Helper/Constants/validationMessage';
import authContext from 'Helper/StateHandler/auth-context';
import { withRouter } from 'react-router-dom';
import { SuccessModal } from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal';
import { getUserData } from 'Helper/Utils/Utils';


const Payment = props => {

    let history = useHistory();
    const context = useContext(authContext)

    const [index, setIndex] = useState(0)
    const [isPaymentFailed, setIsPaymentFailed] = useState(false);
    const [cardNumber, setCardNumber] = useState('')
    const [cardName, setCardName] = useState('')
    const [cardMonth, setCardMonth] = useState('')
    const [cardYear, setCardYear] = useState('')
    const [cardCvv, setCardCvv] = useState('')
    const [cardType, setCardType] = useState("");
    const [errorMessage, setErrorMessage] = useState({
        cardNumber: null,
        cardName: null,
        cardMonth: null,
        cardYear: null,
        cardCvv: null
    })

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);

    }, [])

    const checkCardType = (cardNumber, result) => {
        let params = {
            "cardNumber": cardNumber
        }
        connectServer({
            path: CHECK_CARD_TYPE,
            params,
            context: context,
            ok: res => {
                result(res)
            },
            fail: err => {
            }
        })
    }

    const postPaymentDetailsToServer = (isSkip) => {

        const params = {
            cardholderName: cardName,
            cardNumber: cardNumber,
            month: cardMonth,
            year: cardYear,
            expirationDate: null,
            cvv: cardCvv,
            clientId: props.history.location.state.clientId,
            userId: props.history.location.state.userId,
            amountPayable: history?.location?.state?.licenceTypeId === 1 ? 1 : props.location.state && props.location.state.amount,
            isSkip: isSkip,
            source: 5,
            isAccountActivated: getUserData() ? true : false,
            tenantInvoiceId: 0,
            clientInvoiceId: props.history.location.state.invoiceId,
            pricingDetailsId: props.history.location.state.pricingDetailsId,
            // amountPayable: 525,
            isPlanUpgrade: false,
            isAddOnUpgrade: false,
            isNewAccount: true
        }
        connectServer({
            path: SAVE_SUBSCRIBE_PAYMENT,
            params,
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res.status) {
                    localStorage.removeItem('lsUserData');
                    localStorage.removeItem('isAuth');
                    context.toggleAuth(false);
                    history.push({
                        pathname: 'thanks',
                        state: { payment: isSkip }
                    })
                } else {
                    setIsPaymentFailed(true);
                }
            },
            fail: err => {
            }
        })
    }

    const validation = (isSkip) => {

        let isValidate = true;
        let tempErrorMsg = { ...errorMessage };
        let digits = cardType === "AmEx" ? 4 : 3

        if (cardNumber.length > 0) {
            checkCardType(cardNumber, (result => {
                if (result.status) {
                    tempErrorMsg.cardNumber = null;
                    setCardType(result.data.cardName)
                } else {
                    tempErrorMsg.cardNumber = result.message;
                    isValidate = false;
                }
            }))
        } else {
            tempErrorMsg.cardNumber = ENTER_NUMBER_ON_CARD;
            isValidate = false;
        }
        if (cardName.length > 0) {
            tempErrorMsg.cardName = null;
        } else {
            tempErrorMsg.cardName = 'Enter name on card';
            isValidate = false;
        }

        if (cardMonth.toString().length > 0) {
            if (cardMonth.toString() === "00") {
                tempErrorMsg.cardMonth = 'Invalid month';
                isValidate = false;
            } else {
                tempErrorMsg.cardMonth = null;
            }
        } else {
            tempErrorMsg.cardMonth = 'Enter month';
            isValidate = false;
        }

        if (cardYear.toString().length === 4) {
            tempErrorMsg.cardYear = null;
        } else {
            tempErrorMsg.cardYear = 'Enter year';
            isValidate = false;
        }
        if (cardCvv.toString().length === 0) {
            tempErrorMsg.cardCvv = 'Enter cvv';
            isValidate = false;
        } else if (cardCvv.toString().length === digits) {
            tempErrorMsg.cardCvv = null;
        } else {
            tempErrorMsg.cardCvv = "Need " + digits + " digits";
            isValidate = false;
        }
        if (isValidate) {
            let currentYear = new Date().getFullYear()
            if (cardYear < currentYear) {
                tempErrorMsg.cardYear = "Your card expired";
                isValidate = false;
            } else if (cardYear > currentYear) {
                if (cardYear > (currentYear + 50)) {
                    tempErrorMsg.cardYear = "Enter year below " + (currentYear + 51).toString();
                    isValidate = false;
                } else {
                    tempErrorMsg.cardYear = null;
                    isValidate = true;
                }
            } else {
                let currentMonth = new Date().getMonth() + 1
                if (cardMonth < currentMonth) {
                    tempErrorMsg.cardMonth = "Your card expired";
                    isValidate = false;
                } else {
                    tempErrorMsg.cardMonth = null;
                    isValidate = true;
                }
            }
        }
        setErrorMessage(tempErrorMsg);
        // isValidate = cardExpiryValidation();
        if (!isValidate) {
            return
        }
        postPaymentDetailsToServer(isSkip)
    }
    return (
        <LayoutPageWrapper>
            <Container className={`${localStorage.getItem("isAuth") === "true" ? "page-header box-bottom-space" : ""}`}>
                {localStorage.getItem("isAuth") === "false" ?
                    <RSBrandLogo /> : null}
                <h1 className={localStorage.getItem("isAuth") === "true" ? "header-title-margin" : ""}>Payment & activation</h1>

                <div className="portlet-box-theme mb20">
                    <h4 className="clear">Choose a payment method to complete your account setup.</h4>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <div className="payment-tab-container">
                                <RTabbar
                                    defaultSelectedItem={index}
                                    defaultClass="tabDefault"
                                    dynamicTab="mini"
                                    activeTip={true}
                                    activeClass="tabDefault active"
                                    tabData={tabData}
                                    callBack={(item, index) => { setIndex(index) }}
                                />
                                <div className="payment-body-form">
                                    {index === 0 ? <CreditCard
                                        errorMessage={errorMessage}
                                        getValue={(key, value) => {
                                            if (key === 'cardNumber') {
                                                setCardNumber(value)
                                            } else if (key === "cardName") {
                                                setCardName(value)
                                            } else if (key === 'cardMonth') {
                                                setCardMonth(value)
                                            } else if (key === 'cardYear') {
                                                setCardYear(value)
                                            } else if (key === 'cardCvv') {
                                                setCardCvv(value)
                                            } else if (key === 'errMsg') {
                                                setErrorMessage(value)
                                            } else if (key === "cardType") {
                                                setCardType(value);
                                            }
                                        }} /> : <Paypal />}
                                </div>
                            </div>
                            {history?.location?.state?.licenceTypeId === 1 && <div>
                                <div className="payment-note-container">
                                    <small>Your card will be charged $1 as a verification measure. This is not a subscription fee and will be refunded to your account shortly.</small>
                                </div>
                            </div>
                            }
                        </Col>
                    </Row>
                </div>
                <div className="btn-container d-flex justify-content-end marginT15 box-bottom-space">
                    {history?.location?.state?.licenceTypeId !== 1 &&
                        <RSSecondaryBtn onClick={() => {
                            validation(true)
                        }}>
                            {"Pay later"}
                        </RSSecondaryBtn>
                    }
                    <RSPrimaryBtn
                        onClick={() => {
                            validation(false);
                        }}>
                        Pay
                    </RSPrimaryBtn>
                </div>
                {isPaymentFailed &&
                    <SuccessModal isOkButton={false} isOpen={isPaymentFailed} title={"Failure"} subTitle={"Payment failed.Please try some other card to pay."} buttonClicked={(value) => {
                        setIsPaymentFailed(false);
                    }} />
                }
            </Container>
        </LayoutPageWrapper >
    );
};


export default withRouter(Payment);

const tabData = [
    { "id": 1001, "text": "Credit card", "iconLeft": "icon-sd-credit-card-large icons-lg marginR10", "disable": false },
    { "id": 1002, "image": paypalImg, "disable": true }
]