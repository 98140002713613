import React, { Component } from 'react';
import { ListBox, ListBoxToolbar, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { Container, Row, Col } from 'react-bootstrap';
import { RSInput2 } from 'Components/RSInputs';
import { SearchView } from 'Components/SearchView/SearchView';

const SELECTED_FIELD = "selected";

const toolbarTools = ["transferTo", "transferFrom"];

class TableDetails extends Component {
  state = {
    leftColumnValues: this.props.leftColumnValues,
    rightColumnValues: this.props.rightColumnValues !== undefined ? this.props.rightColumnValues : [],
    draggedItem: {},
    searchInput: ""
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({ leftColumnValues: this.props.leftColumnValues })
      if (this.props.rightColumnValues !== undefined) {
        this.setState({ rightColumnValues: this.props.rightColumnValues });
      }
    }
  }
  handleItemClick = (event, data, connectedData) => {
    this.setState({
      [data]: this.state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          // item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: this.state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };
  handleToolBarClick = (e, data, connectedData) => {
    let result = processListBoxData(
      this.state[data],
      this.state[connectedData],
      e.toolName,
      SELECTED_FIELD
    );
    this.setState({
      [data]: result.listBoxOneData,
      [connectedData]: result.listBoxTwoData,
    });
  };

  handleDragStart = (e) => {
    e.dataItem.dataCollection = e.target.props.name;
    this.setState({
      draggedItem: e.dataItem,
    });
  };

  handleDrop = (e) => {
    let dragItemData = this.state.draggedItem.dataCollection;
    let dropItemData = e.target.props.name;
    let result = processListBoxDragAndDrop(
      this.state[dragItemData],
      this.state[dropItemData],
      this.state.draggedItem,
      e.dataItem,
      "name"
    );
    this.setState({
      [dragItemData]: result.listBoxOneData,
      [dropItemData]: result.listBoxTwoData,
    });
  };
  render() {
    return (
      <div className="list-container">
        <div className="list-column">
          <div className="multi-drag-input">
            <h6 className="list-box-title">Available columns/attributes</h6>
            <SearchView open />
            <ListBox
              style={{ height: 150, width: "100%" }}
              data={this.state.leftColumnValues}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e) =>
                this.handleItemClick(
                  e,
                  "leftColumnValues",
                  "rightColumnValues"
                )
              }
              onDragStart={this.handleDragStart}
              onDrop={this.handleDrop}
              name="leftColumnValues"
              toolbar={() => {
                return (
                  <ListBoxToolbar
                    tools={toolbarTools}
                    data={this.state.leftColumnValues}
                    dataConnected={this.state.rightColumnValues}
                    onToolClick={(e) =>
                      this.handleToolBarClick(
                        e,
                        "leftColumnValues",
                        "rightColumnValues"
                      )
                    }
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="list-column">
          <div className="multi-drag-input">
            <h6 className="list-box-title">Selected columns/attributes</h6>
            <ListBox
              style={{ height: 150, width: "100%" }}
              data={this.state.rightColumnValues}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e) =>
                this.handleItemClick(
                  e,
                  "rightColumnValues",
                  "leftColumnValues"
                )
              }
              onDragStart={this.handleDragStart}
              onDrop={this.handleDrop}
              name="rightColumnValues"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TableDetails;

const UserDetails = ["Cust_ID", "Name", "Email", "Mobile", "Campaign response", "Gender", "Audience laddering", "Maturity date", "Annual income", "Leads", "Marital status", "Decile", "Unsubscribe", "Lead score", "Campaign name"];
const FixedDeposits = ["Cust_ID", "Fixed deposit opted", "Fixed deposit amount", "Fixed deposit maturity date", "Fixed deposit opted date", "Fixed deposit maturity period", "Product type", "Liquidate fixed deposit"];
const CreditCards = ["Cust_ID", "Account type", "Credit card opted", "Credit card type", "Current credit limit", "Credit card payment date", "Credit limit utilization", "Credit card eligibility"];
const Loans = ["Cust_ID", "Loan type", "Loan amount", "Loan period", "EMI amount"];