import React from 'react'
import { Col } from "react-bootstrap"

const MidDetailList = props => {

    return (
        <li>
            <small>{props.text}</small>
            <span>{props.percent}</span>
            {!props.isCount && <small>%</small>}
            {/* {props.info && <i className="icon-sd-circle-info-medium icons-sm grey paddingL5 d-inline-block float-end marginT3 cursor-pointer"></i>} */}
        </li>
    )

}

const OverViewMap = props => {

    return (
        <Col>
            <div className="details-box">
                <div className="detail-start d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={props.icon} alt={`${props.campaignType}`} width={85} height={85}></img>
                        {/* <i className={`${props.icon} grey-medium`}></i> */}
                        <h4 className="marginL10 marginB0">{props.campaignType}</h4>
                    </div>
                    <div>
                        <h3 className="font-lg">{props.campaignCount}</h3>
                    </div>
                </div>
                <div className="detail-center">
                    <ul>
                        {
                            props.data?.map((item, index) => {
                                return (
                                    <MidDetailList
                                        key={index}
                                        text={item.text}
                                        percent={item.percent}
                                        percentSub={item.percentSub}
                                        info={item.info}
                                        isCount={item.isCount}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
                <div className={`detail-end d-flex justify-content-between align-items-center ${props.bgColor}`}>
                    <p className="marginB0">{props.displayLabel}</p>
                    <div>
                        <span>{props.percentage}</span>
                        {props.percentage !== "NA" ? <>
                            <small>%</small>
                            {props.percentage !== "0" ? <i className={`${!props.isLowPerformance ? "icon-sd-arrow-up-mini" : "icon-sd-arrow-down-mini"} icons-sm white`}></i> : ''}</> : ''}
                    </div>
                </div>
            </div>
        </Col>
    )

}

export default OverViewMap