export const ruleValidation = (source, list) => {
    var status = false;
    list.map((item, index) => {

        if (item.rulename !== undefined && item.rulename !== "" && item.rulename.toLowerCase().includes('select') === false) {
            status = true
            if (source.toLowerCase() === 'location') {
                status = localtionValidation(item)
            }
            else if (source.toLowerCase() === 'forms') {
                status = formsValidation(item)
            }
            else if (source.toLowerCase() === 'mobile' || source.toLowerCase() === 'web push') {
                status = mobileValidation(item)
            }

        } else {
            status = false
            return false
        }


    })
    return status

}

export const localtionValidation = (item) => {
    var status = false
    if (item.type.toLowerCase() === 'latlong') {
        if (item.latitude !== undefined && item.latitude !== "" && item.radius !== undefined && item.radius !== "" && item.longitude !== undefined && item.longitude !== "") {
            status = true
        }
    }
    else if (item.type.toLowerCase() === 'beacon') {
        if (item.beaconname !== undefined && item.beaconname !== "" && item.beaconvalue !== undefined && item.beaconvalue !== "") {
            status = true
        }
    }
    else if (item.type.toLowerCase() === 'cityarea') {
        if (item.city !== undefined && item.city !== "" && item.area !== undefined && item.area !== "") {
            status = true
        }
    }
    else if (item.type.toLowerCase() === 'text') {
        if (item.value !== undefined && item.value !== "") {
            status = true
        }
    }
    return status
}

export const formsValidation = (item) => {
    var status = false
    if (item.type.toLowerCase() === 'forms' || item.type.toLowerCase() === "attributes") {
        if (item.formname !== undefined && item.formname !== "" && item.formvalue !== undefined && item.formvalue !== "") {
            status = true

        } else if (item.attributesname !== undefined && item.attributesname !== "" && item.attributesvalue !== undefined && item.attributesvalue !== "") {
            status = true
        }
    }
    else if (item.type.toLowerCase() === 'text') {
        if (item.value !== undefined && item.value !== "") {
            status = true
        }
    }
    return status
}
export const mobileValidation = (item) => {
    var status = false
    if (item.value !== undefined && item.value !== "") {
        status = true
    }
    return status
}

export const validateDynamicList = (listName, list) => {
    var status = [false, "Enter valid dynamic list"];
    if (listName !== "") {
        status = true;
    }
    if (list.length > 0) {
        list.map((item, index) => {
            status = checkListType(item, index)
            if (status[0] === true) {
                if (item.rule.length > 0) {
                    status = ruleValidation(item.trigger, item.rule)

                    if (!status) {
                        status = [false, "Please enter valid rule"]
                    } else {
                        status = [true, ""]
                    }
                }
            }
        })
    }
    return status

}

export const checkListType = (item, index) => {
    var status = [false, ""]
    if (item.matchtype !== "") {
        if (item.matchtype.toLowerCase() === 'all') {
            status = [true, '']
        }
        else if (item.matchtype.toLowerCase() === 'any' && item.anycount !== "") {

            if (item.matchCount < item.rule.length) {
                status = [true, ""]
            } else {
                status = [false, "Any count value & rule attributes mismatch in Rule Group" + index + 1 + ""]
                return status
            }

        } else {
            status = [false, "Please fill any count value"]
            return status
        }

    } else {
        return [false, "Select match type"]
    }
    if (item.trigger !== "") {
        status = [true, ""]
        if (item.trigger.toLowerCase().includes('web') || item.trigger.toLowerCase() === "mobile") {
            if (item.source !== undefined && item.source !== "") {
                status = [true, ""]
            }
            else {
                status = [false, "Select source"]
            }
        }
    } else {
        status = [false, "Select trigger"]
    }
    return status
}
export const createobject = (number) => {

}