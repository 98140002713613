import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import authContext from "Helper/StateHandler/auth-context";
import { getUserData } from "Helper/Utils/Utils";

class LinkPreviewModal extends Component {
    static contextType = authContext;

    state = {
        isShow: this.props.isLinkPreviewOpen,
        linkPreviewData: this.props.linkPreviewData,
        data: this.props.linkPreviewData,
        linkedData: this.props.linkClickData,
    };

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.setState({ linkPreviewData: this.props.linkPreviewData });
        }
    };

    componentDidMount() {
        let htmlString = this.state.data;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        const aTagsToWrap = tempDiv.querySelectorAll("a");
        const overlayHeatMap = tempDiv.querySelectorAll(".overlayHeatMap");
        overlayHeatMap?.forEach((ele, ind) => {
            ele.style.position = "relative";
            ele.style.display = "block";
        });
        let dummy_ = this.state.linkedData?.map((e) => e.url);
        aTagsToWrap.forEach((aTagToWrap, index) => {
            let getInd = dummy_.findIndex((e) => e === aTagToWrap.href);
            if (dummy_.includes(aTagToWrap.href)) {
                const spanElement = document.createElement("span");
                // below code for get dynamic color
                // spanElement.style.backgroundColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                spanElement.style.backgroundColor = "yellow";
                spanElement.style.color = "black";
                let style2 = {
                    background: "#99142d",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "11px",
                    position: "relative",
                    top: "0",
                    marginTop: "20px",
                    marginBottom: "5px",
                    display: "inline-block",
                };
                for (let [key, val] of Object.entries(style2)) {
                    spanElement.style[key] = val;
                }

                spanElement.id = `spanLink_${index}`;
                spanElement.className = `spanLink`;

                let childSpan = document.createElement("span");
                let childSpan_line = document.createElement("span");

                let style = {
                    display: "inline-block",
                    background: "#0bc30b",
                    position: "absolute",
                    bottom: "100%",
                    left: "0px",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                };
                for (let [key, val] of Object.entries(style)) {
                    childSpan.style[key] = val;
                }

                childSpan.className = "spanlinkCount";
                childSpan_line.className = "spanlinkLine";
                childSpan.textContent = `${this.state.linkedData[getInd]?.percentage}% (${this.state.linkedData[getInd]?.uniqueClicks})`;
                if (this.state.linkedData[getInd]?.percentage <= 20) {
                    childSpan.style.backgroundColor = "blue";
                    spanElement.style.backgroundColor = "#0d0de130";
                } else if (this.state.linkedData[getInd]?.percentage <= 40) {
                    childSpan.style.backgroundColor = "green";
                    spanElement.style.backgroundColor = "green";
                } else if (this.state.linkedData[getInd]?.percentage <= 60) {
                    childSpan.style.backgroundColor = "yellow";
                    spanElement.style.backgroundColor = "#eaea0d30";
                } else if (this.state.linkedData[getInd]?.percentage <= 80) {
                    childSpan.style.backgroundColor = "orange";
                    spanElement.style.backgroundColor = "orange";
                } else {
                    childSpan.style.backgroundColor = "red";
                    spanElement.style.backgroundColor = "red";
                }
                childSpan.style.opacity = "75%";
                childSpan.style.text = "75%";
                childSpan.style.transform = "translateY(-1px)%";
                childSpan.style.fontSize = "11px";
                childSpan.style.whiteSpace = "pre";
                spanElement.style.color = "hsl(220, calc(100  1%), calc(81  1%))";
                spanElement.style.fontFamily = " verdana, geneva, sans-serif";
                spanElement.style.letterSpacing = "0";
                aTagToWrap.parentNode.replaceChild(spanElement, aTagToWrap);
                spanElement.appendChild(aTagToWrap);
                spanElement.appendChild(childSpan);
                spanElement.appendChild(childSpan_line);
            }
        });

        // Select all elements with the class "spanLink"
        const spanLinkElements = tempDiv.querySelectorAll(".spanLink");

        // Apply styles to the parent of each element
        spanLinkElements.forEach((element) => {
            const parentElement = element.parentElement;
            parentElement.style.position = "relative";
        });
        htmlString = tempDiv.innerHTML;
        this.setState({
            data: htmlString,
        });
    }

    render() {
        return (
            <>
                <Modal
                    show={this.state.isShow}
                    keyboard={false}
                    centered
                    backdrop="static"
                    size="lg"
                    className="modal-top-fixed"
                    onHide={() => {
                        this.setState({ isShow: false });
                        this.props.onChangeIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <h2>Click map</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="preview-iframe-wrapper">
                            <div className="mb30">
                                <iframe
                                    title="linkPreviewModal"
                                    srcDoc={this.state.data}
                                    style={{ width: "100%", minHeight: "500px" }}
                                ></iframe>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default LinkPreviewModal;
