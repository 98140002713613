import { fontRegular, fontMedium, fontBold } from 'Components/Font'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

export const FontFamily = () => {
    return (
        <>
            <FontSet fFamily={fontRegular} type='Regular' fontVariable="fontRegular" fontScss="$rs-font-regular" />
            <br />
            <FontSet fFamily={fontMedium} type='Medium' fontVariable="fontMedium" fontScss="$rs-font-medium" />
            <br />
            <FontSet fFamily={fontBold} type='Bold' fontVariable="fontBold" fontScss="$rs-font-bold" />
        </>
    )
}

const FontSet = (props) => {
    return (
        <>
            <h4 style={{ fontFamily: `${props.fFamily}` }}>
                Mukta {props.type}
                <CopyToClipboard text={props.fontVariable}><span className='cursor-pointer labeljs'>js</span></CopyToClipboard>
                <CopyToClipboard text={props.fontScss}><span className='cursor-pointer labelcss'>scss</span></CopyToClipboard>
            </h4>
            <p style={{ fontFamily: `${props.fFamily}` }}>“Lorem ipsum dolor sit amet, consectetur adipiscing elit."</p>
            <p style={{ fontFamily: `${props.fFamily}` }}>Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss</p>
            <p style={{ fontFamily: `${props.fFamily}` }}>Tt Uu Vv Ww Xx Yy Zz 0 1 2 3 4 5 6 7 8 9 ( @ & $ * : ; ! ? )</p>
        </>
    )
}