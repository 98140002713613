import * as React from "react";
import { DateInput } from "@progress/kendo-react-dateinputs";

const CustomEndDateInput = (props) => {
  const style = {
    color: props.value !== null ? "green" : "red",
  };
  return (
    <label className="pe-none">
      <span className="fs12">To</span>
      <DateInput {...props} label={undefined} />
    </label>
  );
};

export default CustomEndDateInput;