import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LayoutPageWrapper } from './LayoutWrapper/LayoutPageWrapper';
import { RSPrimaryBtn } from "Components/RSButtons";
import { useHistory } from 'react-router-dom';

import notFound from 'Assets/Images/404.gif'
import './NotFound.css';


const NotFound = () => {

    const history = useHistory()

    let errorStyle = {
        maxWidth: '94%'
    }

    return (
        <LayoutPageWrapper className={`no-data-bg footer-cloud`}>
            <Container className="page-header">
                <Row>
                    <Col>
                        <div>
                            <img src={notFound} alt={notFound} style={errorStyle} />
                        </div>
                    </Col>
                    <Col className="not-found-desc">
                        <div>
                            <h1>404 error!</h1>
                            <h3>Sorry! The page you're looking for <br /> cannot be found. </h3>
                            <p> Either something went wrong or <br /> the page doesn't anymore </p>
                            <RSPrimaryBtn onClick={() => { history.push(`/`) }}>Go to dashboard</RSPrimaryBtn>
                        </div>
                    </Col>
                </Row>
            </Container>
        </LayoutPageWrapper>
    );
};

export default NotFound;