import React, { Component } from "react";
import { Container, Modal, Col, Row } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSCheckbox } from "Components/RSInputs";
import { getServerConfig, getUserData } from "Helper/Utils/Utils";
import * as images from "Assets/Images";
class SubPreview extends Component {

  state = {
    isModalOpen: this.props.isOpen,
    isEnable: this.props.isEnabled,
    title: this.props.title,
    imgPath: this.props.imgPath,
    campaignType: this.props.campaignType,
    productType: this.props.productType,
    channelData: this.props.channelData,
    interestType: this.props.interestType,
    isChecked: this.props.isChecked,
    emailId: "",
    imageURL: getServerConfig().slice(0, -1),
  };

  componentDidMount() {
    let getUserDetails = getUserData();
    this.setState({ emailId: getUserDetails?.emailId });
  }

  handleClose = () => {
    this.props.onChangeIsOpen(false);
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        className="dynamic-list-fill modal-top-fixed"
        show={this.state.isModalOpen}
        onHide={() => this.handleClose()}
        size="xlg"
        centered
      >
        <Modal.Header className='border-0'></Modal.Header>
        <Modal.Body>
          <div className="preview-mode-img">
            {
              this.state.imgPath === null
                ? <img src={images.Building} alt="No image" />
                : <img src={this.props.mode === "edit" ? `${this.state.imageURL}${this.state.imgPath}` : `data:image/jpeg;base64,${this.state.imgPath}`} alt="" />
            }
          </div>
          <Row>
            <Col>
              <h2 style={{ color: "#4a051b" }}>{this.state.title ? this.state.title : "Preview"}</h2>
            </Col>
          </Row>
          <div className="portlet-box-theme p0">
            <Container>
              <Row className="even py15 mb20">
                <Col md={12}>
                  <p className="text-center mb0">
                    {HTMLReactParser(this.props.welcomeText)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="label-row width100p text-end">
                    Email ID
                  </label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="mailId"
                    ph={"Enter mail ID"}
                    val={this.state.emailId}
                    cls="click-off"
                  />
                </Col>
              </Row>
              {!!this.state.campaignType?.length && (
                <Row>
                  <Col md={4}>
                    <label className="label-row width100p text-end">
                      Communication type
                    </label>
                  </Col>
                  <Col md={8}>
                    <ul className="checkbox-listing mt9">
                      {this.state.campaignType?.map((item, index) => {
                        return (
                          <li>
                            <RSCheckbox lbl={item} className="click-off" />
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )}
              {!!this.state.productType?.length && (
                <Row>
                  <Col md={4}>
                    <label className="label-row width100p text-end">
                      Product type
                    </label>
                  </Col>
                  <Col md={8}>
                    <ul className="checkbox-listing mt9">
                      {this.state.productType?.map((item, index) => {
                        return (
                          <li>
                            <RSCheckbox lbl={item} className="click-off" />
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )}
              {!!this.state.channelData?.length && (
                <Row>
                  <Col md={4}>
                    <label className="label-row width100p text-end">
                      Channel type
                    </label>
                  </Col>
                  <Col md={8}>
                    <ul className="checkbox-listing mt9">
                      {this.state.channelData?.map((item, index) => {
                        return (
                          <li>
                            <RSCheckbox lbl={item} className="click-off" />
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )}
              {!!this.state.interestType?.length && (
                <Row>
                  <Col md={4}>
                    <label className="label-row width100p text-end">
                      Interest Type
                    </label>
                  </Col>
                  <Col md={4}>
                    <RSInput2
                      name="interestType"
                      val={this.state.interestType}
                      cls="click-off"
                    />
                  </Col>
                </Row>
              )}
              {this.state.isChecked && (
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <RSCheckbox
                      className="marginB20 click-off"
                      lbl={"I agree to the Terms & Conditions "}
                      checked={this.state.isChecked}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <RSSecondaryBtn
            onClick={() => {
              this.handleClose();
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              this.handleClose();
            }}
            className='click-off'
          >
            Subscribe
          </RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SubPreview;
