import React, { Component } from "react";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import CustomFilterUI from "./CustomFilterUI";

export const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter
        {...props}
        expanded={true}
        filterUI={CustomFilterUI}
      />
    </div>
  );
};
export default ColumnMenu;
