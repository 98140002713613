import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import TableDetails from './TableDetails';
import DataExchangeModal from './DataExchangeModal';

class POSDataExchangeCreation extends Component {
    state = {
        ipAddress: "",
        portNumber: "",
        topicName: "",
        partition: "",
        offset: "",
        dataMode: "",
        isConnectBtnClicked: false,
        topicDetails: "",
        isShowModal: false,
        importPreference: "",
        updateCycle: "",
        updateCheck: ""
    }
    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className=''>
                        <div className="header-title d-flex justify-content-between">
                            <h1>Data exchange</h1>
                        </div>
                        <div className="portlet-box-theme pb0">
                            <label className="fs18 mb10">Extended channel</label>
                            <Row>
                                <Col md={4}>
                                    <RSInput2
                                        ph="IP address"
                                        val={this.state.ipAddress}
                                        cb={(text) => {
                                            this.setState({ ipAddress: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="Port number"
                                        val={this.state.portNumber}
                                        cb={(text) => {
                                            this.setState({ portNumber: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="Topic name"
                                        val={this.state.topicName}
                                        cb={(text) => {
                                            this.setState({ topicName: text });
                                        }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <RSInput2
                                        ph="Partition"
                                        val={this.state.partition}
                                        cb={(text) => {
                                            this.setState({ partition: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="Offset"
                                        val={this.state.offset}
                                        cb={(text) => {
                                            this.setState({ offset: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="Data mode"
                                        val={this.state.dataMode}
                                        cb={(text) => {
                                            this.setState({ dataMode: text });
                                        }} />
                                </Col>
                            </Row>
                        </div>
                        <div className="btn-container d-flex justify-content-end">
                            <RSPrimaryBtn className={this.state.isConnectBtnClicked ? "click-off" : ""} onClick={() => {
                                this.setState({ isConnectBtnClicked: !this.state.isConnectBtnClicked });
                            }}>Connect</RSPrimaryBtn>
                        </div>
                        {this.state.isConnectBtnClicked &&
                            <div className="portlet-box-theme p0 mb15">
                                <Row className="even py15 mx0">
                                    <Col md={{ span: 2, offset: 3 }} className="text-end">
                                        <label className="label-row">Topic</label>
                                    </Col>
                                    <Col md={3}>
                                        <RSDropdownList
                                            data={["User details", "Card data"]}
                                            className="mb0"
                                            value={this.state.topicDetails !== "" ? this.state.topicDetails : "-- Topic Details --"}
                                            onChange={(e) => {
                                                this.setState({ topicDetails: e.target.value });
                                                if (e.target.value === "User details") {
                                                    this.setState({ leftColumnValues: UserDetails })
                                                } else {
                                                    this.setState({ leftColumnValues: CardData })
                                                }
                                            }} />
                                    </Col>
                                </Row>
                                {this.state.topicDetails !== "" &&
                                    <div className="p15"><TableDetails tabledetails={this.state.topicDetails} leftColumnValues={this.state.leftColumnValues} /></div>
                                }
                            </div>
                        }
                    </div>

                    {this.state.topicDetails !== "" &&
                        <   >
                            <div className="d-flex align-items-center mb15">
                                <h4 className="mb0">{"Import preferences"}</h4>
                                <RSPTooltip position="top" text="Dedupe settings">
                                    <i className="icon-settings-small icons-md blue cp ml10" onClick={() => {
                                        this.setState({ isShowModal: true })
                                    }}></i>
                                </RSPTooltip>
                            </div>
                            <div className="mb5">
                                <Row>
                                    <RSRadio
                                        lbl="Upload new data - if a match is found, overwrite the older record"
                                        name={"importPreference"}
                                        labelId={"importPreferenceId"}
                                        className="marginB5"
                                        checked={this.state.importPreference === "Upload new data - if a match is found, overwrite the older record" ? true : false}
                                        cb={(event) => {
                                            this.setState({ importPreference: event.target.value });
                                        }} />
                                </Row>
                                <Row>
                                    <RSRadio
                                        lbl="Preserve older data - if a match is found, reject the recent record"
                                        name={"importPreference1"}
                                        labelId={"importPreferenceId1"}
                                        className="marginB5"
                                        checked={this.state.importPreference === "Preserve older data - if a match is found, reject the recent record" ? true : false}
                                        cb={(event) => {
                                            this.setState({ importPreference: event.target.value });
                                        }} />
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Row>
                                            <Col md={4}>
                                                <label className="label-row">Update cycle</label>
                                            </Col>
                                            <Col md={7}>
                                                <RSDropdownList
                                                    data={["Immediate", "15 minutes", "30 minutes", "Hourly", "Daily", "Weekly", "Monthly"]}
                                                    className="mb0"
                                                    value={this.state.updateCycle !== "" ? this.state.updateCycle : "-- Select --"}
                                                    onChange={(e) => {
                                                        this.setState({ updateCycle: e.target.value });
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                <label className="label-row">Select the field to check for updates</label>
                                            </Col>
                                            <Col md={5}>
                                                <RSDropdownList
                                                    data={["Modified date & time"]}
                                                    className="mb0"
                                                    value={this.state.updateCheck !== "" ? this.state.updateCheck : "-- Select --"}
                                                    onChange={(e) => {
                                                        this.setState({ updateCheck: e.target.value });
                                                    }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="btn-container d-flex justify-content-end">
                                    <RSSecondaryBtn onClick={() => {
                                        this.props.history.push("/preferences/data-exchange");
                                    }}>Cancel</RSSecondaryBtn>
                                    <RSPrimaryBtn onClick={() => {
                                        this.props.history.push({ pathname: "/audience", state: { index: 0 } });
                                    }}>Upload</RSPrimaryBtn>
                                </div>
                            </div>
                        </>
                    }
                    {this.state.isShowModal && <DataExchangeModal callBack={(value) => {
                        this.setState({ isShowModal: false })
                        if (value) {
                            this.props.history.push({ pathname: `/audience/add-audience`, state: { mode: 'add' } })
                        }
                    }} isShowModal={this.state.isShowModal} title="Configure dedupe input settings" PrimaryBtn="Save" SecondaryBtn="Cancel" />}
                </Container>
            </LayoutPageWrapper>
        );
    }
}

export default withRouter(POSDataExchangeCreation);

const UserDetails = [
    { name: "Customer ID", selected: false },
    { name: "Name", selected: false },
];
const CardData = [
    { name: "Terminal ID", selected: false },
    { name: "Transaction amount", selected: false },
    { name: "Transaction time", selected: false },
    { name: "Zipcode", selected: false },
    { name: "Purchase category", selected: false },
    { name: "Purchase type", selected: false },
    { name: "Transaction type", selected: false },
    { name: "Transaction status", selected: false },
    { name: "Transaction ID", selected: false }
];