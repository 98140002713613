import React, { useState } from 'react';
import { DropdownButton, Dropdown } from "react-bootstrap"

export const BootstrapDropdown = props => {

  const { data, onSelect, defaultItem, className, fontSize, fieldKey, alignRight } = props
  const [title, setTitle] = useState(defaultItem)

  // const alignSet = align || false
  const cls = className || ""
  const fsize = fontSize || ''

  return (
    <DropdownButton
      varient=""
      align={`${alignRight ? 'end' : 'start'}`}
      id="dropdown-basic-buttons"
      className={`rs-dropdown ${cls} ${fsize ? fsize : 'test'} ${alignRight ? 'align-right' : ''}`}
      title={defaultItem}
    >
      <div className={`css-scrollbar overflow-dropdown`}>
        {
          data.map((item, index) => {

            return <div key={index}>
              {
                typeof item === "object"
                  ? <Dropdown.Item key={index} onClick={() => {
                    setTitle(item[fieldKey])
                    onSelect && onSelect(item, index)
                  }}>{item[fieldKey]}</Dropdown.Item>
                  : <Dropdown.Item key={index} onClick={() => {
                    setTitle(item)
                    onSelect && onSelect(item, index)
                  }}>{item}</Dropdown.Item>
              }
            </div>


            // return (
            //   <Dropdown.Item key={index} onClick={() => {
            //     setTitle(item)
            //     onSelect(item, index)
            //   }}>{item}</Dropdown.Item>
            // )
          })
        }
      </div>
    </DropdownButton>
  )
}

export const BootstrapDropdownIcon = props => {

  const { data, onSelect, defaultItem, className, fontSize, fieldKey, alignRight, fixedIcon } = props
  const [title, setTitle] = useState(defaultItem)

  // const alignSet = align || false
  const cls = className || ""
  const fsize = fontSize || ''

  return (
    <DropdownButton
      varient=""
      id="dropdown-basic-buttons"
      className={`rs-dropdown ${cls} ${fsize ? fsize : 'test'} ${alignRight ? 'align-right' : ''}`}
      title={fixedIcon}
    >
      <div className={`css-scrollbar overflow-dropdown`}>
        {
          data.map((item, index) => {

            return <div key={index}>
              {
                typeof item === "object"
                  ? <Dropdown.Item key={index}
                    onClick={() => {
                      setTitle(item[fieldKey])
                      onSelect && onSelect(item, index)
                    }}
                  >
                    {item[fieldKey]}</Dropdown.Item>
                  : <Dropdown.Item key={index} onClick={() => {
                    setTitle(item)
                    onSelect(item, index)
                  }}>{item}</Dropdown.Item>
              }
            </div>


            // return (
            //   <Dropdown.Item key={index} onClick={() => {
            //     setTitle(item)
            //     onSelect(item, index)
            //   }}>{item}</Dropdown.Item>
            // )
          })
        }
      </div>
    </DropdownButton>
  )
}

export const BDropdown = props => {
  const { data, onSelect, defaultItem, className, aln } = props
  const [title, setTitle] = useState(defaultItem)
  const cls = className || ""
  const align = aln || "start"
  return (<>
    <Dropdown align={align} >
      <Dropdown.Toggle
        variant=""
        id="dropdown-basic"
        className={`rs-dropdown ${cls}`}
        // title={title}
      >
        {/* <i className="icon-sd-menu-dot-mini icons-sm white"></i> */}
        <i className="icon-dropdown-small icons-sm white marginL5"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className='css-scrollbar bgWhite search-max-drop-h'>
          {
            data.map((item, index) => {
              return (
                <Dropdown.Item key={index} onClick={() => {
                  // setTitle(item)
                  onSelect(item, index)
                }}>{item}</Dropdown.Item>
              )
            })
          }
        </div>
      </Dropdown.Menu>
    </Dropdown>
  </>
  )
}


//==Not using==//
export const BootstrapDropdownWithClass = props => {

  const { data, onSelect, defaultItem, className, customAlignRight } = props
  const [classText, setClassText] = useState(defaultItem)

  const align = customAlignRight || false
  const cls = className || ""

  return (
    <DropdownButton customAlignRight={align} id="dropdown-basic-button" className={`rs-dropdown ${cls} dropdownImg ${classText}`}>
      {
        data.map((item, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => {
              setClassText(item.class)
              onSelect(item, index)
            }}>{item.title}</Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}
export const BootstrapDropdownText = props => {

  const { data, onSelect, defaultItem, className, customAlignRight } = props
  const [title, setTitle] = useState(defaultItem)

  const align = customAlignRight || false
  const cls = className || ""

  return (
    <DropdownButton customAlignRight={align} id="dropdown-basic-button" className={`rs-dropdown ${cls}`} title={title}>
      {
        data.map((item, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => {
              setTitle(item.text)
              onSelect(item, index)
            }}>{item.text}</Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}

// Icon dropdown
export const IconDropdown = props => {
  const { data, onSelect, defaultItem, className, customAlignRight, hideArrow } = props
  const [icon, setIcon] = useState(defaultItem)

  const align = customAlignRight || false
  const cls = className || ""

  return (
    <DropdownButton
      customAlignRight={align}
      id="dropdown-basic-button"
      className={`rs-dropdown ${cls} ${hideArrow ? "arrow-hide" : ""}`}
      title={
        <>
          <i className={icon}></i>
          <i className="icon-dropdown-small icons-sm marginL5"></i>
        </>
      }
    >
      {
        data.map((item, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => {
              setIcon(item)
              onSelect(item, index)
            }}>
              <i className={`${item}`} ></i>
            </Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}