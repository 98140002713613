import React, { useState, useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { RSPPophover } from 'Components/Tooltip';
import { Row, Col } from 'react-bootstrap';
import { isEmpty, getAuthUserId, validateIPAddress, validatePortNumber, validatePassword, } from "Helper/Utils/Utils";
import {
    FRIENDLY_NAME, IP_ADDRESS, IP_ADDRESS_VALID, PORT_NUMBER, PORT_NUMBER_VALID, USER_NAME, FOLDER_PATH, PASS_WORD
} from "Helper/Constants/validationMessage"
import authContext from 'Helper/StateHandler/auth-context';
import { GET_AUDIENCE_BY_RDS_ID, SAVE_AUDIENCE_BY_RDS } from "Helper/Constants/endPoint"
import { connectServer } from "Helper/Network/networkHandler";
import { RSConfirmAlert } from 'Components/RSConfirmAlert';


const FTPAndSFTPCreation = props => {
    const history = useHistory();
    const context = useContext(authContext)
    const [source, setSource] = useState("SFTP");
    const [friendlyName, setFriendlyName] = useState("");
    const [ipAddress, setIPAddress] = useState("");
    const [portNumber, setPortNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [userPassword, setPassword] = useState("");
    const [folderPath, setFolderPath] = useState("");
    const [errMsgFriendlyName, setErrFriendlyName] = useState(null)
    const [errMsgIpAddress, setErrIpAddress] = useState(null)
    const [errMsgPortNumber, setErrPortNumber] = useState(null)
    const [errMsgUserName, setErrUserName] = useState(null)
    const [errMsgPassword, setErrPassword] = useState(null)
    const [errMsgFolderPath, setErrFolderPath] = useState(null)
    const [errMsgCredentails, setErrMsgCredentials] = useState(null)
    const [isOpenErrPopup, setIsOpenErrPopup] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.mode === "edit") {
            let params = {
                remoteSettingId: props.rdsId
            }
            connectServer({
                path: GET_AUDIENCE_BY_RDS_ID,
                params: params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: (res) => {
                    if (res.status) {
                        setFriendlyName(res.data.friendlyName);
                        setIPAddress(res.data.ipAddress);
                        setPortNumber(res.data.port);
                        setUserName(res.data.username);
                        setPassword(res.data.password);
                        setFolderPath(res.data.fileLocation);
                    }
                },
                fail: (err) => { },
            });
        }
    }, [])

    const validateForm = () => {
        let error = false
        if (isEmpty(friendlyName)) {
            setErrFriendlyName(FRIENDLY_NAME)
            error = true
        }
        if (isEmpty(ipAddress)) {
            setErrIpAddress(IP_ADDRESS)
            error = true
        } else if (!validateIPAddress(ipAddress)) {
            setErrIpAddress(IP_ADDRESS_VALID)
            error = true
        }
        if (isEmpty(portNumber)) {
            setErrPortNumber(PORT_NUMBER)
            error = true
        } else if (!validatePortNumber(portNumber)) {
            setErrPortNumber(PORT_NUMBER_VALID)
            error = true
        }
        if (isEmpty(userName)) {
            setErrUserName(USER_NAME)
            error = true
        }
        if (isEmpty(userPassword)) {
            setErrPassword(PASS_WORD)
            error = true
        }
        if (isEmpty(folderPath)) {
            let errMsgFolderPath = FOLDER_PATH
            setErrFolderPath(errMsgFolderPath)
            error = true
        }
        return error
    }
    return (
        <>

            <Row className="mt20">

                <Col md={{ span: 2, offset: 3 }} className="text-end label-row">
                    <label>Source</label>
                </Col>
                {/* <Col md={1}>
                    <RSRadio
                        lbl="FTP"
                        name={"source"}
                        labelId={"sourceId"}
                        className="marginB20 mt9"
                        checked={source === "FTP" ? true : false}
                        cb={(event) => {
                            setSource(event.target.value);
                        }} />
                </Col> */}
                <Col md={2}>
                    <RSRadio
                        lbl="SFTP"
                        name={"source1"}
                        labelId={"sourceId1"}
                        className="marginB20 mt9"
                        checked={source === "SFTP" ? true : false}
                        cb={(event) => {
                            setSource(event.target.value);
                        }} />
                </Col>
            </Row>
            <div className="px50">
                <Row>
                    <Col md={4}>
                        <RSInput2
                            ph="Friendly name"
                            val={friendlyName}
                            required={true}
                            max={50}
                            cb={(text) => {
                                let errMsg = text !== '' ? null : FRIENDLY_NAME
                                setFriendlyName(text);
                                setErrFriendlyName(errMsg)
                            }}
                            ob={(text) => {
                                let errMsg = text !== '' ? null : FRIENDLY_NAME
                                setFriendlyName(text);
                                setErrFriendlyName(errMsg)
                            }}
                            errorMessage={errMsgFriendlyName}
                        />
                    </Col>
                    <Col md={4}>
                        <RSInput2
                            ph="IP address"
                            val={ipAddress}
                            required={true}
                            max={30}
                            ob={(text) => {
                                let errMsg = text !== '' ? null : IP_ADDRESS
                                setIPAddress(text);
                                setErrIpAddress(errMsg)
                            }}
                            cb={(text) => {
                                let errMsg = text !== '' ? null : IP_ADDRESS
                                setIPAddress(text);
                                setErrIpAddress(errMsg)
                            }}
                            errorMessage={errMsgIpAddress}
                        />
                    </Col>
                    <Col md={4}>
                        <RSInput2
                            ph="Port number"
                            val={portNumber}
                            required={true}
                            max={5}
                            ob={(text) => {
                                let errMsg = text !== '' ? null : PORT_NUMBER
                                setPortNumber(text);
                                setErrPortNumber(errMsg)
                            }}
                            cb={(text) => {
                                let errMsg = text !== '' ? null : PORT_NUMBER
                                setPortNumber(text);
                                setErrPortNumber(errMsg)
                            }}
                            errorMessage={errMsgPortNumber} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RSInput2
                            ph="User name"
                            val={userName}
                            required={true}
                            max={100}
                            ob={(text) => {
                                let errMsg = text !== '' ? null : USER_NAME
                                setUserName(text);
                                setErrUserName(errMsg)
                            }}
                            cb={(text) => {
                                let errMsg = text !== '' ? null : USER_NAME
                                setUserName(text);
                                setErrUserName(errMsg)
                            }}
                            errorMessage={errMsgUserName} />
                    </Col>
                    <Col md={4}>
                        <RSInput2
                            ph="Password"
                            val={userPassword}
                            type="password"
                            required={true}
                            max={20}
                            ob={(text) => {
                                let errMsg = text !== '' ? null : PASS_WORD
                                setPassword(text);
                                setErrPassword(errMsg)
                            }}
                            cb={(text) => {
                                let errMsg = text !== '' ? null : PASS_WORD
                                setPassword(text);
                                setErrPassword(errMsg)
                            }}
                            errorMessage={errMsgPassword} />
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center">
                            <RSInput2
                                ph="Folder path"
                                val={folderPath}
                                required={true}
                                max={50}
                                ob={(text) => {
                                    let errMsg = text !== '' ? null : FOLDER_PATH
                                    setFolderPath(text);
                                    setErrFolderPath(errMsg)
                                }}
                                cb={(text) => {
                                    let errMsg = text !== '' ? null : FOLDER_PATH
                                    setFolderPath(text);
                                    setErrFolderPath(errMsg)
                                }}
                                errorMessage={errMsgFolderPath} />
                            <div className="input-outer-set">
                                <RSPPophover
                                    position='top-end'
                                    className=''
                                    text="Note: This refers to the path where the audience data file is stored."
                                >
                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp"></i>
                                </RSPPophover>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <div className="btn-container d-flex justify-content-end mb20 mr15">
                <RSSecondaryBtn onClick={() => {
                    props.onCancelClick(true);
                }}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn onClick={() => {
                    //Validation
                    let isProceed = validateForm()
                    if (!isProceed) {
                        // API CALLING 
                        var params = {
                            "importSource": 3,
                            "listType": 1,
                            "remoteSourceGroupType": 3,
                            "friendlyName": friendlyName,
                            "ipAddress": ipAddress,
                            "port": portNumber,
                            "userName": userName,
                            "password": userPassword,
                            "remoteDirectoryPath": folderPath
                        }
                        context.globalStateData.setIsLoadingData(true)
                        connectServer({
                            path: SAVE_AUDIENCE_BY_RDS,
                            params: params,
                            loading: context.globalStateData.setIsLoadingData,
                            sessionOut: context.globalStateData.setSessionOutData,
                            context: context,
                            ok: (res) => {
                                if (res.status) {
                                    history.push({ pathname: "/audience", state: { index: 0 } });
                                }
                                else {
                                    setIsOpenErrPopup(true)
                                    setErrMsgCredentials(res.message)
                                }
                            },
                            fail: (err) => { },
                        });
                    }
                }}>Connect</RSPrimaryBtn>
            </div>

            {isOpenErrPopup ?
                <RSConfirmAlert
                    title={errMsgCredentails}
                    secondaryButtonText="Cancel"
                    primaryButtonText="OK"
                    buttonClicked={(value) => {
                        setIsOpenErrPopup(false)
                    }} /> : null}
        </>
    );
}
export default withRouter(FTPAndSFTPCreation);