import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { filterBy } from "@progress/kendo-data-query";
import { Switch } from '@progress/kendo-react-inputs';
import { RSDropdownList, RSPhoneInput } from 'Components/RSDropdowns';
import RESEditor from 'Components/Editor/RESEditor';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RTabbar1 } from 'Components/RChart/RTabbar';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import authContext from 'Helper/StateHandler/auth-context';
import MobileSMSSplitContent from './MobileSMSSplitContent';
import { CustomPopup } from 'Components/DateRangePicker/ScheduleCustomPopup';
import SplitSettingsModal from '../EmailComponents/Components/SplitSettingsModal';
import SplitSizeModal from '../EmailComponents/Components/SplitSizeModal';
import {
  APPROVAL_MAIL, AUDIENCE, CAMPAIGN_TEXT, LANGUAGE, SENDER_ID, SEND_TEST_MSG, SCHEDULE_DATE, SELECT_TIMEZONE, SMS_TEMPLATE_ID, SPECIAL_NUM_CHARACTERS, SPECIAL_CHARACTERS, ENTER_VALID_EMAIL
} from 'Helper/Constants/validationMessage';
import {
  addMinutes, convertBase64ToObject, convertObjectToBase64, getAuthClientId, getCurrentTimeWithTimeZone, getLsMasterDataDropdown, getUserData, isEmpty, makeImmutableObject, validateEmail
} from 'Helper/Utils/Utils';
import ApprovalSettings from '../EmailComponents/Components/ApprovalSettings';
import {
  GET_ADHOC_ATTRIBUTES, GET_APPROVER_LIST, GET_AUDIENCE_LIST, GET_SMS_SETTINGS, SAVE_SMS_CAMPAIGN, UPDATE_SMS_CAMPAIGN, GET_SHORTEN_URL
} from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { createMobileSMSCampaign } from '../CamapignWrapper';
import { SuccessModal } from '../EmailComponents/Components/SuccessModal';
import Nperview from '../../Components/NPreview';
import moment from 'moment';
const MobileSMSCreation = props => {

  const context = useContext(authContext)
  const history = useHistory();

  const [isOpenSmsSendSuccessModal, setIsOpenSmsSendSuccessModal] = useState(false);
  const [isSearchValidate, setIsSearchValidate] = useState(false);
  const [senderIDList, setSenderIDList] = useState([]);
  const [senderID, setSenderID] = useState(null);
  const [smsTemplateId, setSmsTemplateId] = useState(null);
  const [smsChannelId, setSmsChannelId] = useState(0);
  const [audienceList, setAudienceList] = useState(props.mobileSMSData.targetList);
  const [language, setLangage] = useState(props.mobileSMSData.languageId);
  const [isSplit, setIsSplit] = useState(props.mobileSMSData.isSplitAB);
  const [campaignText, setCampaignText] = useState(props.mobileSMSData.content[0].previewBody);
  const [previewCampaignText, setPreviewCampaignText] = useState(props.mobileSMSData.content[0].body)
  const [shortenLinks, setShortenLinks] = useState("");
  const [smartUrls, setSmartUrls] = useState([]);
  const [mobileNo, setMobileNo] = useState(props.mobileSMSData.testSmsMobileNo !== "" ? props.mobileSMSData.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryCode, setCountryCode] = useState("in");
  const [countryPhoneNumberLength, setCountryPhoneNumberLength] = useState(0)
  const [countryCodeMobile, setCountryCodeMobile] = useState(props.mobileSMSData.countryCodeMobile !== "" ? props.mobileSMSData.countryCodeMobile : getUserData()?.countryCodeMobile);
  const [isRequestForApproval, setIsRequestApproval] = useState(props.mobileSMSData.isRequestForApprovalEnabled);
  const [isEditRequestForApproval, setIsEditRequestApproval] = useState(props.mobileSMSData.isRequestForApprovalEnabled);
  const [splitTabIndex, setSplitTabIndex] = useState(0);
  const [isFlashMessage, setIsFlashMessage] = useState(props.mobileSMSData.isFlashMessageEnabled);
  const [isEditTimeZone, setIsEditTimeZone] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [isDayLightSavings, setIsDayLightSavings] = useState(false);
  const [isSendRecommendation, setIsSendRecommendation] = useState(false);
  const [schedule, setSchedule] = useState(props.mobileSMSData.isSendTestSMS !== 2 ? props.mobileSMSData.content[0].localBlastDateTime : "");
  const [approverName, setApproverName] = useState("");
  const [isSplitSettings, setIsSplitSettings] = useState(false);
  const [isShowSplitSizeModal, setIsShowSplitSizeModal] = useState(false);
  const [isOpenSplitResetModal, setIsOpenSplitResetModal] = useState(false);
  const [errSenderIdMsg, setErrSenderIdMsg] = useState(null);
  const [errSmsTemplateIdMsg, setErrSmsTemplateIdMsg] = useState(null);
  const [errAudienceMsg, setErrAudienceMsg] = useState(null);
  const [errLanguageMsg, setErrLanguageMsg] = useState(null);
  const [errCampaignText, setErrCampaignTextMsg] = useState(null);
  const [errSendTestMsg, setErrSendTestMsg] = useState(null);
  const [errMbileMsg, setErrMbileMsg] = useState(null);
  const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(null);
  const [errTimeZoneMsg, setErrTimeZoneMsg] = useState(null);
  const [errApproverMsg, setErrApproverMsg] = useState(null);
  const [languageData, setLanguageData] = useState([]);
  const [audienceListFromServer, setAudienceListFromServer] = useState([]);
  const [audienceListForDropDown, setAudienceListForDropDown] = useState([]);
  const [bodyTextCount, setBodyTextCount] = useState("");
  const [smsCount, setSMSCount] = useState("");
  const [subjectLinePersonalizeData, setSubjectLinePersonalizeData] = useState([]);
  const [approverListFromServer, setApproverListFromServer] = useState([]);
  const [editClickOff, setEditClickOff] = useState(false);
  const [splitTabData, setSplitTabData] = useState([
    { id: 10011, text: "Split A" },
    { id: 1002, text: "Split B" },
  ])
  const [approvalList, setApprovalList] = useState([
    {
      approverEmail: {
        "email": "-- Select approval mail --",
        "userid": 0
      },
      ismandatory: false,
      edmworlflowdetails: 0,
      priority: 1,
      ApprovalSentcount: 1,
      name: "approval",
      input: false,
      appErr: null
    }
  ]);
  const [requestForErrorMsg, setRequestForErrorMsg] = useState([
    {
      approverEmailMsg: null
    }
  ]);
  const [approvalSettings, setApprovalSettings] = useState(
    {
      approvalMatchType: "All",
      approvalMatchCount: 0,
      isFollowHierarchyEnabled: false,
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [splitContentError, setSplitContentError] = useState([
    {
      errBodyMsg: null,
      errScheduleDateMsg: null,
      errTimeZoneMsg: null
    },
    {
      errBodyMsg: null,
      errScheduleDateMsg: null,
      errTimeZoneMsg: null
    }
  ]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isShortURLCreated, setIsShortURLCreated] = useState(false);
  const [paymentStatusForList, setPaymentStatusForList] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0);
    const clickOffStatus = props.mode === "add"
      ? false
      : (props.mobileSMSData.channelstatusId !== 12 && props.mobileSMSData.channelstatusId !== 51 && props.mobileSMSData.channelstatusId !== 9 && props.mobileSMSData.channelstatusId !== 5 && props.mobileSMSData.channelstatusId !== 20 && props.mobileSMSData.channelstatusId !== 52)
        ? false
        : true;
    setEditClickOff(clickOffStatus)
    getSMSSettings();
    getLanguageDataFromServer();
    getAudienceList();
    getApproverList();
    getTimeZoneList();
    // if (props.isUpdate) {
    setIsRequestApproval(props.mobileSMSData.channelstatusId !== 54 ? props.mobileSMSData.isRequestForApprovalEnabled : false);
    setIsEditRequestApproval(props.mobileSMSData.channelstatusId !== 54 ? props.mobileSMSData.isRequestForApprovalEnabled : false);
    setSmsTemplateId(props.mobileSMSData.smsProviderTemplateID);
    setIsFlashMessage(props.mobileSMSData.isFlashMessageEnabled);
    setAudienceList(props.mobileSMSData.targetList);
    // let paymentStatus = props.mobileSMSData.targetList[0]?.isPaid ? null : "Payment for the list is pending";
    let paymentStatus = props.mobileSMSData.targetList.length === 0 ? null : props.mobileSMSData.targetList[0]?.isPaid ? null : "Payment for the list is pending";
    setPaymentStatusForList(paymentStatus)
    setLangage(props.mobileSMSData.languageId);
    setSmsChannelId(props.mobileSMSData.content[0].smsChannelDetailId);
    setMobileNo(props.mobileSMSData.testSmsMobileNo !== "" ? props.mobileSMSData.testSmsMobileNo : getUserData()?.mobileNo);
    setCountryCodeMobile(props.mobileSMSData.countryCodeMobile !== "" ? props.mobileSMSData.countryCodeMobile : getUserData()?.countryCodeMobile);
    getSMSCount(props.mobileSMSData.content[0].previewBody);
    getAdhocAttributeHeaders(props.mobileSMSData.targetList[0]?.segmentationListID);
    setCampaignText(props.mobileSMSData.content[0].previewBody);
    setPreviewCampaignText(props.mobileSMSData.content[0].body);
    setIsShortURLCreated(props.mobileSMSData.content[0].isShortenURLEnabled);
    setSchedule((props.mobileSMSData.channelstatusId !== 54 && props.mobileSMSData.isSendTestSMS !== 2) ? props.mobileSMSData.content[0].localBlastDateTime : "")
    // }
    setSenderID(senderIDList.filter(item => item.clientSmsSettingId === props.mobileSMSData.senderId)[0]);
    const campaignHeader = props.campaignHeader
    let startDate = new Date(getStartDate(campaignHeader.startDate));
    setStartDate(startDate)
    // setEndDate(new Date(campaignHeader.endDate))
    setEndDate(new Date(new Date(new Date(campaignHeader.endDate).getFullYear(), new Date(campaignHeader.endDate).getMonth(), new Date(campaignHeader.endDate).getDate(), 23, 59, 59)))
    if (props.mobileSMSData.channelstatusId !== 54 && props.mobileSMSData.isRequestForApprovalEnabled) {
      let requestForApproval = { ...props.mobileSMSData.requestForApproval };
      let approveList = [...requestForApproval.approvarList];
      let tempApprovalList = [];
      let tempApprovalSettings = {
        approvalMatchType: requestForApproval.matchType,
        approvalMatchCount: requestForApproval.matchCount,
        isFollowHierarchyEnabled: requestForApproval.IsFollowHierarchy,
      }
      approveList.map((item, index) => {
        let tempItem = {
          approverEmail: {
            "email": item.approvarName,
            "userid": parseInt(item.approvarId, 10)
          },
          ismandatory: item.ismandatory,
          edmworlflowdetails: 0,
          priority: item.priority,
          ApprovalSentcount: item.ApprovalSentcount || 1,
          name: "approval",
          input: item.approvarId === "" ? true : false,
          appErr: null
        }
        tempApprovalList.push(tempItem)
      })
      setApprovalList(tempApprovalList);
      setApprovalSettings(tempApprovalSettings);
    }

  }, [props.mobileSMSData])

  const getSMSCount = (campaignText) => {

    let textCount = campaignText.length > 0 ? campaignText.length.toString() + "/" : "";
    let smsCount = Math.floor(campaignText.length / 140) + 1;
    setBodyTextCount(textCount);
    setSMSCount(smsCount.toString());
  }

  const getStartDateForSchedule = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (props.mobileSMSData.content[0].timezoneId === 0) {
      filterTimezone = data.timeZone.filter(
        (item) =>
          item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
      )[0];
    } else {
      filterTimezone = data.timeZone.filter(
        (item) => item.timezoneId === props.mobileSMSData.content[0].timezoneId
      )[0];
    }
    const campaignHeader = props.campaignHeader
    let startDate = new Date(getStartDate(campaignHeader.startDate));
    let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
    return startDateWithTime;
  }

  const handleCountryPhoneNumberLength = (country) => {
    let countryFormat = country['format'].split(/ (.*)/s);
    let [countryCodeFormat, countryNumberFormat, empty] = countryFormat;
    let totCount = (countryNumberFormat.match(/\./g) || []).length
    setCountryPhoneNumberLength(totCount);
  }

  const handleMobileNumberValidation = (mobileNumber, maxMobile) => {
    let numberWihoutComma = mobileNumber?.replaceAll(',', '');
    let valid = true;
    let errObj = { err: false, msg: null };
    // let phoneValues = numberWihoutComma?.match(new RegExp('.{1,' + countryPhoneNumberLength + '}', 'g'));
    let phoneValues = mobileNumber.split(/\s*,\s*/);
    if (phoneValues && phoneValues.length <= 5) {
      const numberPattern = /^[+]*[0-9]+$/;
      phoneValues.forEach(item => {
        if (item.length < 5 || item.length > countryPhoneNumberLength || !numberPattern.test(item)) {
          valid = false;
        }
      });
    }
    if (!phoneValues) {
      errObj.err = true;
      errObj.msg = 'Please enter mobile number';
    } else if (!valid) {
      errObj.err = true;
      errObj.msg = 'Enter valid mobile number';
    } else if (maxMobile) {
      errObj.err = true;
      errObj.msg = 'Maximum mobile number reached';
    } else if (phoneValues && phoneValues.length !== new Set(phoneValues).size) {
      errObj.err = true;
      errObj.msg = 'Please remove duplicate number';
    }

    return errObj;
  }

  const getStartDate = (startDate) => {
    let campaignStartDate = new Date(startDate);
    let today = new Date();
    if (campaignStartDate > today) {
      campaignStartDate = new Date(startDate);
    } else {
      campaignStartDate = new Date();
    }
    return campaignStartDate
  }

  const getSMSSettings = () => {
    let params = {
    }
    connectServer({
      path: GET_SMS_SETTINGS,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setSenderIDList(res.data.smsSettingList);
          setSenderID(res.data.smsSettingList.filter(item => item.clientSmsSettingId === props.mobileSMSData.senderId)[0]);
        }
      },
      fail: err => {
      }
    })
  }

  const getLanguageDataFromServer = () => {
    const languageList = localStorage.getItem('lsLanguageData')
    setLanguageData(languageList ? convertBase64ToObject(languageList) : [])
  }

  const getAudienceList = param => {
    let params = {
      searchText: "",
      campaignID: 0,
      channel: "S"
    }
    connectServer({
      path: GET_AUDIENCE_LIST,
      params,
      context: context,
      ok: res => {
        if (res.status && res.message !== "No data") {
          localStorage.setItem(
            'lsAudienceList',
            convertObjectToBase64(res.data)
          )
          setAudienceListFromServer(res.data)
          setAudienceListForDropDown(res.data);
        }
      },
      fail: err => {
      }
    })
  }

  const getApproverList = param => {
    let params = {
      "clinetId": getAuthClientId()
    }
    connectServer({
      path: GET_APPROVER_LIST,
      params,
      context: context,
      ok: res => {
        if (res.status) {
          setApproverListFromServer(res.data)
        }
      },
      fail: err => {
      }
    })
  }

  const getTimeZoneList = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (props.mode === "add" || props.mobileSMSData.content[0].timezoneId === 0) {
      filterTimezone = data.timeZone.filter((item) => item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId)[0];
    } else {
      filterTimezone = data.timeZone.filter((item) => item.timezoneId === props.mobileSMSData.content[0].timezoneId)[0];
    }
    setTimeZoneData(data.timeZone);
    setTimeZone(filterTimezone)
    props.mobileSMSData.content[0].timezoneId = filterTimezone.timezoneId;
  }

  const getAdhocAttributeHeaders = (segmentId) => {
    if (segmentId !== undefined) {
      let params = {
        "segmentationListID": segmentId
      }
      connectServer({
        path: GET_ADHOC_ATTRIBUTES,
        params,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: res => {
          if (res.status) {
            let tempFullPersonal = [];
            res?.data?.full?.forEach((item, index) => {
              tempFullPersonal.push("[[" + item + "]]");
            })
            setSubjectLinePersonalizeData(tempFullPersonal);
          }
        },
        fail: err => {
        }
      })
    }
  }

  const getRemainingAudienceList = () => {
    var temp = [...audienceListFromServer]
    var listTemp = []
    temp.forEach(obj => {
      let temp2 = audienceList.filter(
        audience => audience.segmentationListID === obj.segmentationListID
      )
      if (temp2.length === 0) {
        listTemp.push(obj)
      }
    })
    return listTemp
  }

  const filterChange = (event) => {
    let temp = getRemainingAudienceList();
    setAudienceListForDropDown(filterBy(temp.slice(), event.filter));
  };

  const SplitComponents = (props) => {
    let components = [...props.mobileSMSData.content];
    let errorIndex = 0;
    let tempTabData = [...splitTabData];
    props.mobileSMSData.content.forEach(element => {
      if (splitContentError[errorIndex] === undefined) {
        splitContentError.push(createSplitError());
      }
      if (tempTabData[errorIndex] === undefined) {
        var add = tempTabData.length;
        var charCode = "A".charCodeAt(0);
        add = charCode + add;
        var obj = {
          id: add,
          text: "Split " + String.fromCharCode(add),
        };
        tempTabData.push(obj);
        setSplitTabData(tempTabData);
      }
      errorIndex++;
    });

    return (
      <>
        <Tabs
          activeKey={"tab" + splitTabIndex}
          id="uncontrolled-tab-example"
          className="mb30 borderB0"
        >
          {props.mobileSMSData.content.forEach((item, index1) => {
            return (
              <Tab eventKey={"tab" + index1}>
                <MobileSMSSplitContent
                  campaignId={props.campaignId}
                  personalizeTapData={subjectLinePersonalizeData}
                  mobileSMSData={props.mobileSMSData}
                  splitSMSData={components[index1]}
                  splitContentError={splitContentError[index1]}
                />
              </Tab>
            );
          })}
        </Tabs>
      </>
    );
  }

  const createSplitObject = (sectionName) => {
    let split = {
      splitType: sectionName,
      body: "",
      previewBody: "",
      smsChannelDetailId: 0,
      timezoneId: timeZone.timezoneId,
      localBlastDateTime: "",
    };
    return split;
  };

  const createSplitError = () => {
    let splitError = {
      errBodyMsg: null,
      errScheduleDateMsg: null
    }
    return splitError;
  }

  const splitValidation = (index) => {
    let isValidate = true;
    let splitContent = props.mobileSMSData.content[index];
    if (isEmpty(splitContent.body)) {
      let temp = [...splitContentError];
      temp[index].errBodyMsg = CAMPAIGN_TEXT;
      setSplitContentError(temp);
      isValidate = false;
    } else {
      if (splitContent.body.includes("https") && !splitContent.body.includes("mkstr.io")) {
        let temp = [...splitContentError];
        temp[index].errBodyMsg = "Shorten URL";
        setSplitContentError(temp);
        isValidate = false;
      }
      // if (splitContent.body.length > 500) {
      //   let temp = [...splitContentError];
      //   temp[index].errBodyMsg = "Communication content should not be more that 500";
      //   setSplitContentError(temp);
      //   isValidate = false;
      // }
    }
    if (isEmpty(splitContent.localBlastDateTime)) {
      let temp = [...splitContentError];
      temp[index].errScheduleDateMsg = SCHEDULE_DATE;
      setSplitContentError(temp);
      isValidate = false;
    }
    if (splitContent.timezoneId === 0) {
      let temp = [...splitContentError];
      temp[index].errTimeZoneMsg = SELECT_TIMEZONE;
      setSplitContentError(temp);
      isValidate = false;
    }
    return isValidate;
  };

  const validation = (type) => {
    let isValidate = true;
    if (senderID === undefined || senderID.clientSmsSettingId === 0) {
      setErrSenderIdMsg(SENDER_ID);
      isValidate = false;
    }
    if (smsTemplateId === null || isEmpty(smsTemplateId)) {
      setErrSmsTemplateIdMsg(SMS_TEMPLATE_ID);
      isValidate = false;
    }
    if (audienceList.length === 0) {
      setErrAudienceMsg(AUDIENCE);
      isValidate = false;
    }
    if (language.languageId === 0) {
      setErrLanguageMsg(LANGUAGE);
      isValidate = false;
    }
    // let isSplitComplete = true;
    if (!isSplit) {
      if (isEmpty(previewCampaignText)) {
        setErrCampaignTextMsg(CAMPAIGN_TEXT);
        isValidate = false;
      } else {
        if (previewCampaignText.includes("https") && !isShortURLCreated) {
          setErrCampaignTextMsg("Shorten URL");
          isValidate = false;
        }
        if (previewCampaignText.length > 500) {
          setErrCampaignTextMsg("Text limit exceeded");
          isValidate = false;
        }
      }
      if (type !== 'draft') {
        if (isEmpty(schedule)) {
          setErrScheduleDateMsg(SCHEDULE_DATE);
          isValidate = false;
        } else {
          let currentTime = getCurrentTimeWithTimeZone(new Date(), timeZone.timezoneId, 15)
          if (new Date(schedule) < new Date(currentTime)) {
            setErrScheduleDateMsg("Select a time greater from (" + currentTime.toLocaleString().trim() + ")")
            isValidate = false;
          }
        }
        if (timeZone.timezoneId === 0) {
          setErrTimeZoneMsg(SELECT_TIMEZONE);
          isValidate = false;
        }
      }
    } else {
      let i = 0;
      props.mobileSMSData.content.forEach(element => {
        isValidate = splitValidation(i);
        if (!isValidate) {
          setSplitTabIndex(i);
          return;
        }
        i++;
      });
    }
    // if (isEmpty(mobileNo)) {
    //   setErrSendTestMsg(SEND_TEST_MSG);
    //   isValidate = false;;
    // }
    let enable = true;
    if (isRequestForApproval && requestForErrorMsg.filter((item) => item.approverEmailMsg !== null).length === 0) {
      approvalList.forEach((item, index) => {
        if (item.approverEmail.email === "" || item.approverEmail.email === "-- Select approval mail --") {
          enable = false;
          let tempErrorMsg = [...requestForErrorMsg];
          tempErrorMsg[index].approverEmailMsg = APPROVAL_MAIL;
          setRequestForErrorMsg(tempErrorMsg);
          return;
        } else {
          let tempErrorMsg = [...requestForErrorMsg];
          tempErrorMsg[index].approverEmailMsg = null;
          setRequestForErrorMsg(tempErrorMsg);
          enable = true;
        }
      })
    } else {
      enable = isRequestForApproval ? false : true
    }
    // if (!isSplitComplete || !enable) {
    //   return false;
    // } else {
    //   return true;
    // }
    return enable ? isValidate : false;
  }

  return (
    <>
      <div className="marginT20 marginL20-del">
        <Row className={`custom-column marginB20 ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Sender ID</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errSenderIdMsg && (
                <div className="validation-message">
                  {errSenderIdMsg}
                </div>)}
              <RSDropdownList
                data={senderIDList}
                className="mb10 required"
                value={senderID}
                defaultItem={{
                  "clientSmsSettingId": 0,
                  "clientId": 0,
                  "senderId": "-- Select sender ID --"
                }}
                textField='senderId'
                dataItemKey='clientSmsSettingId'
                onChange={(event) => {
                  let errMsg = event.target.value.clientSmsSettingId !== 0 ? null : SENDER_ID;
                  props.mobileSMSData.senderId = event.target.value.clientSmsSettingId;
                  setErrSenderIdMsg(errMsg);
                  setSenderID(event.target.value);
                }}
              />
            </div>
            <div className="input-outer-set">
              <RSPTooltip text="New sender ID" position="top">
                <i className="icon-sd-settings-medium icons-md blue" onClick={() => {
                  history.push({
                    pathname: '/preferences/communication-settings',
                    state: { hIndex: 1, index: 0 }
                  })
                }} />
              </RSPTooltip>
            </div>
            {/* <RSCheckbox
              className="marginB20"
              lbl="Flash message"
              checked={isFlashMessage}
              cb={(status) => {
                setIsFlashMessage(status);
                props.mobileSMSData.isFlashMessageEnabled = status;
              }}
            /> */}
          </Col>
        </Row>
        <Row className={`custom-column marginB20 ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Template ID</label>
          </Col>
          <Col md={6}>
            <RSInput2
              required={true}
              ph="Enter sender template ID"
              val={smsTemplateId}
              max={50}
              errorMessage={errSmsTemplateIdMsg}
              cb={(value) => {
                let errMsg = value.length > 0 ? null : SMS_TEMPLATE_ID;
                var regex = /^[A-Za-z0-9 ]+$/;
                if (errMsg === null) {
                  if (regex.test(value)) {
                    props.mobileSMSData.smsProviderTemplateID = value;
                    setSmsTemplateId(value);
                    setErrSmsTemplateIdMsg(errMsg);
                  } else {
                    setErrSmsTemplateIdMsg(SPECIAL_CHARACTERS);
                  }
                } else {
                  props.mobileSMSData.smsProviderTemplateID = value;
                  setSmsTemplateId(value);
                  setErrSmsTemplateIdMsg(errMsg);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={`custom-column marginB30 ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Audience</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errAudienceMsg && (
                <div className="validation-message">
                  {errAudienceMsg}
                </div>)}
              <div className="chips-input-container">
                <div className="chips-input-box required">
                  <MultiSelect
                    placeholder="-- Select the list --"
                    // data={getRemainingAudienceList()}
                    data={audienceListForDropDown}
                    textField='recipientMobileDisplayName'
                    dataItemKey='segmentationListID'
                    filterable={true}
                    onFilterChange={filterChange}
                    onChange={(event) => {
                      let errMsg = event.target.value.length === 0 ? AUDIENCE : null;
                      errMsg = event.target.value.length > 1 ? "More than one list is not allowed" : null;
                      if (event.target.value.length <= 1) {
                        // let paymentStatus = event.target.value[0]?.isPaid ? null : "Payment for the list is pending";
                        let paymentStatus = event.target.value.length === 0 ? null : event.target.value[0]?.isPaid ? null : "Payment for the list is pending";
                        setPaymentStatusForList(paymentStatus)
                        props.mobileSMSData.targetList = event.target.value;
                        setErrAudienceMsg(errMsg);
                        setAudienceList(event.target.value);
                        if (event.target.value.length > 0) {
                          getAdhocAttributeHeaders(event.target.value[0].segmentationListID);
                        } else {
                          setSubjectLinePersonalizeData([]);
                        }
                      } else {
                        setErrAudienceMsg(errMsg);
                      }

                    }}
                    value={audienceList} />
                </div>
              </div>
              <small>{paymentStatusForList ? paymentStatusForList : ""}</small>
            </div>
          </Col>
        </Row>
        <Row className={`custom-column ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Language</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errLanguageMsg && (
                <div className="validation-message">
                  {errLanguageMsg}
                </div>)}
              <RSDropdownList
                className='required'
                data={languageData}
                value={language || { languageId: 0, languageName: "-- Select language --" }}
                defaultItem={{ languageId: 0, languageName: "-- Select language --" }}
                textField='languageName'
                dataItemKey='languageId'
                onChange={(event) => {
                  let errMsg = event.target.value.languageId !== 0 ? null : LANGUAGE;
                  props.mobileSMSData.languageId = event.target.value;
                  setErrLanguageMsg(errMsg);
                  setLangage(event.target.value);
                }}
              />
              {/* <RSPTooltip text="Note: The communication text can be drafted in any language and is not restricted to the language selected here." position="top">
                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp ml10"></i>
              </RSPTooltip> */}
            </div>
          </Col>
        </Row>
        <Row className={`custom-column marginB30 mt10 d-none ${editClickOff ? "click-off" : "click-off"}`}>
          <Col md={3}>
            <label>Split A/B test</label>
          </Col>
          <Col md={6}>
            <div className="d-flex align-items-center mb20">
              <Switch
                className={`my0 ${audienceList[0]?.recipientCount > 100 ? "" : "click-off"}`}
                onLabel={"ON"}
                offLabel={"OFF"}
                onChange={(event) => {
                  if (event.target.value) {
                    let temp = [...props.mobileSMSData.content];
                    temp[0].splitType = "A";
                    temp[0].body = "";
                    temp[0].previewBody = "";
                    temp[0].smsChannelDetailId = 0;
                    temp[0].timezoneId = timeZone.timezoneId;
                    temp[0].localBlastDateTime = "";
                    var charCode = "A".charCodeAt(0);
                    let add = charCode + 1;
                    let splitContent = createSplitObject(String.fromCharCode(add));
                    // splitContent.timezoneId = timeZone.timezoneId;
                    temp.push(splitContent);
                    props.mobileSMSData.content = temp;
                    setIsSplit(!isSplit);
                    props.mobileSMSData.isSplitAB = !isSplit;
                  } else {
                    setIsOpenSplitResetModal(!isOpenSplitResetModal);
                  }
                }}
                checked={isSplit}
              />
              {isSplit &&
                <div className="d-flex align-items-center click-off">
                  <i className='icon-dropdown-small cp ml15 click-off' onClick={() => {
                    setIsShowSplitSizeModal(!isShowSplitSizeModal);
                  }}></i>
                  <i className='icon-settings-small icons-md blue cursor-pointer' onClick={() => {
                    setIsSplitSettings(!isSplitSettings);
                  }} />
                </div>
              }
              <RSPTooltip text="A minimum of 100 audience are required for split A/B testing. Check the communication listing page for results." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp ml10"></i>
              </RSPTooltip>
            </div>
          </Col>
        </Row>

        {isSplit ? (
          <div className={`split-container ${editClickOff ? "click-off" : ""}`}>
            <div className="tabs-filled-component-del d-flex align-items-center">
              <RTabbar1
                defaultSelectedItem={splitTabIndex}
                defaultClass="tabDefault"
                dynamicTab="addtab"
                activeClass="tabDefault active"
                delete={true}
                type="split"
                mainClass="even-space-4"
                tabData={splitTabData}
                deleteTabData={(removeIndex) => {
                  let tempData = [...splitTabData];
                  tempData.splice(removeIndex, 1);
                  let temp = [...props.mobileSMSData.content];
                  temp.splice(removeIndex, 1);
                  props.mobileSMSData.content = temp;
                  setSplitTabData(tempData);
                }}
                callBack={(item, index) => {
                  setSplitTabIndex(index);
                }}
              />
              {/* {splitTabData.length < 4
                &&
                <i
                  className={`d-inline-block icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer ml15 ${props.editClickOff ? "click-off" : ""}`}
                  onClick={() => {
                    let enable = true;
                    for (var i = 0; i < props.mobileSMSData.content.length; i++) {
                      enable = splitValidation(i);
                      if (!enable) {
                        setSplitTabIndex(i);
                        break;
                      }
                    }
                    // let enable = this.splitValidation();
                    if (enable) {
                      if (splitTabData.length < 4) {
                        var add = splitTabData.length;
                        var charCode = "A".charCodeAt(0);
                        add = charCode + add;

                        var obj = {
                          id: add,
                          text: "Split " + String.fromCharCode(add),
                        };
                        var list = [...splitTabData];
                        list.push(obj);
                        setSplitTabData(list);

                        let temp = [...props.mobileSMSData.content];
                        let splitContent = createSplitObject(String.fromCharCode(add))
                        // splitContent.timezoneId = timeZone.timezoneId;
                        temp.push(splitContent);
                        props.mobileSMSData.content = temp;
                        let errTemp = [...splitContentError];
                        errTemp.push(createSplitError());
                        setSplitContentError(errTemp);
                        setSplitTabIndex(splitTabData.length);
                      }
                    }
                  }}
                />} */}
            </div>
            {<SplitComponents mobileSMSData={props.mobileSMSData} />}
          </div>
        ) : <>
          <Row className={`custom-column marginB20 ${editClickOff ? "click-off" : ""}`}>
            <Col md={3}>
              <label className="label-row">Communication text</label>
            </Col>
            <Col md={6}>
              <div className="position-relative">
                {errCampaignText && (
                  <div className="validation-message yaxis-7">
                    {errCampaignText}
                  </div>
                )}
                <RESEditor
                  personalizeTapData={subjectLinePersonalizeData}
                  mobilepush={false}
                  isCampaignText={true}
                  isWhatsappCampaignText={false}
                  bodyContent={props.mobileSMSData.content[0].previewBody}
                  mobileNumber={mobileNo}
                  campaignId={props.campaignId}
                  smsChannelId={smsChannelId}
                  mobileSMSData={props.mobileSMSData}
                  onValidateSearch={(status) => {
                    let isValidate = validation("draft");
                    setIsSearchValidate(isValidate);
                  }}
                  isSearchValidate={isSearchValidate}
                  onChangeIsSearchValidate={() => {
                    setIsSearchValidate(false);
                  }}
                  onChangeSmsChannelId={(value) => {
                    props.mobileSMSData.content[0].smsChannelDetailId = value;
                    setSmsChannelId(value)
                  }}
                  callBack={(key, item) => {
                    if (key === "bodytext") {
                      let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                      if (errMsg !== null) {
                        setSmartUrls([]);
                      }
                      let textCount = item.length > 0 ? item.length.toString() + "/" : "";
                      let smsCount = Math.floor(item.length / 140) + 1;
                      setBodyTextCount(textCount);
                      setSMSCount(smsCount.toString());
                      // props.mobileSMSData.content[0].body = item.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                      props.mobileSMSData.content[0].body = item;
                      props.mobileSMSData.content[0].previewBody = item;
                      setErrCampaignTextMsg(errMsg)
                      setCampaignText(item);
                      setPreviewCampaignText(item);
                      if (item.length > 0 && smartUrls.length > 0 && shortenLinks.length > 0) {
                        let tempVal = item;
                        smartUrls.forEach((URL, index) => {
                          if (index < 2) {
                            tempVal = item.replaceAll(URL, shortenLinks[index])
                          }
                        })
                        setPreviewCampaignText(tempVal);
                        props.mobileSMSData.content[0].body = tempVal;
                      }
                    } else if (key === "onBlur") {
                      props.mobileSMSData.content[0].bodyPosition = item.target.selectionStart;
                    }
                  }} />
              </div>
              <RSPTooltip text="Shortern URL" position="top">
                {/* <i className={`icons-sm icon-link-down-medium icons-md blue float-end cursor-pointer */}
                <i className={`position-relative top-3 icon-sd-link-medium icons-md blue float-end cursor-pointer ${props.mobileSMSData.content[0].previewBody.includes("https") ? "" : "click-off"}`} onClick={() => {
                  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
                  let smartURLs = props.mobileSMSData.content[0].previewBody.match(urlRegex);
                  let errMsg = !!smartURLs ? smartURLs.length > 2 ? "Accept only two URL in SMS content" : null : "Invalid URL"
                  if (errMsg === null) {
                    errMsg = smartURLs[0] !== smartURLs[1] ? null : "Give different URLs in SMS content";
                    setErrCampaignTextMsg(errMsg);
                    setSmartUrls(smartURLs);
                    if (errMsg === null) {
                      let smartURLParams = [];
                      smartURLs.forEach((item, index) => {
                        let params = {
                          "campaignId": props.campaignId,
                          "goalNo": index + 1,
                          "blastType": "",
                          "smartCode": "",
                          // "domainUrl": DOMAIN_URL,
                          "smartAdaptive": "",
                          "smartUrl": item,
                          "channelId": 2,
                          "blastNo": 1,
                          "actionId": 0,
                          "parentChannelDetailId": 0
                        }
                        smartURLParams.push(params)
                      })
                      let params = {
                        "smartUrlsList": smartURLParams
                      }
                      connectServer({
                        path: GET_SHORTEN_URL,
                        params,
                        loading: context.globalStateData.setIsLoadingData,
                        sessionOut: context.globalStateData.setSessionOutData,
                        context: context,
                        ok: res => {
                          if (res.status) {
                            let shortenLinks = [];
                            let tempVal = props.mobileSMSData.content[0].previewBody;
                            res.data.forEach((item, index) => {
                              if (index < 2) {
                                tempVal = tempVal.replaceAll(smartURLs[index], item.smartCodePath)
                                shortenLinks.push(item.smartCodePath)
                              }
                            })
                            props.mobileSMSData.content[0].body = tempVal;
                            props.mobileSMSData.content[0].isShortenURLEnabled = res.status;
                            setIsShortURLCreated(res.status)
                            setPreviewCampaignText(tempVal)
                            setShortenLinks(shortenLinks)
                          } else {
                            props.mobileSMSData.content[0].isShortenURLEnabled = res.status;
                            setIsShortURLCreated(res.status)
                            setErrCampaignTextMsg(res.message)
                          }
                        },
                        fail: err => {
                        }
                      })
                    }
                  } else {
                    setErrCampaignTextMsg(errMsg);
                    setSmartUrls(smartURLs || []);
                  }
                }}></i>
              </RSPTooltip>
              <RSPTooltip text="Shorten URL will reflect in SMS preview content." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm float-end blue cp"></i>
              </RSPTooltip>
            </Col>
            <Col md={3} className="last-column-view mr-sms">
              {props.mobileSMSData.content[0].previewBody.length > 0 ?
                <div className='instant-preview'>
                  <Nperview
                    body={previewCampaignText}
                    smartUrls={smartUrls}
                    shortenLinks={shortenLinks}
                    isCampaignText={true}
                    mobileNumber={"+" + countryCodeMobile + " " + mobileNo}
                    getErrMessage={(value) => { setErrCampaignTextMsg(value) }}
                  />
                </div>
                : null}
            </Col>
          </Row>
          {/* <Row className={`custom-column marginB30 ${editClickOff ? "click-off" : ""}`}>
            <Col md={3}>
              <label className="label-row">Shortlink</label>
            </Col>
            <Col md={6}>
              <RSInput2
                ph="Enter URL"
                className=""
                val={smartURL}
                cb={(text) => {
                  let errMsg = text !== "" ? isValidWebsite(text) ? null : ENTER_VALID_WEBSITE : "Enter valid URL"
                  props.mobileSMSData.content[0].shortenURL = text;
                  setSmartURL(text);
                  setErrSmartURL(errMsg);
                }}
                errorMessage={errSmartURL}
              />
            </Col>
            <Col md={3}>
              <RSPrimaryBtn className={isEnableShortenButton(smartURL) ? "" : "click-off"} onClick={() => {
                if (isEmpty(smartURL)) {
                  setErrSmartURL("Enter URL");
                  return
                }
                let params = {
                  "campaignId": props.campaignId,
                  "goalNo": 1,
                  "smartCode": "",
                  "domainUrl": "https://lcouat.mkstr.io/",
                  "smartAdaptive": "",
                  "smartUrl": smartURL,
                  "channelId": 2
                }
                connectServer({
                  path: GET_SHORTEN_URL,
                  params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  ok: res => {
                    if (res.status) {
                      let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.mobileSMSData.content[0].bodyPosition);
                      props.mobileSMSData.content[0].body = val;
                      setCampaignText(val);
                    }
                  },
                  fail: err => {
                  }
                })
              }}>Shorten URL</RSPrimaryBtn>
            </Col>
          </Row> */}
          <div className={`schedule-even marginT30 ${editClickOff ? "click-off" : ""}`}>
            <Row className={`custom-column paddingB10`}>
              <Col md={3}>
                <label className="label-row">Schedule</label>
              </Col>
              <Col md={6}>
                <div className="position-relative">
                  {errScheduleDateMsg && (
                    <div className='validation-message'>
                      {errScheduleDateMsg}
                    </div>
                  )}
                  <div className="rsptimer-picker">
                    <DateTimePicker
                      className="required"
                      placeholder='Select schedule date and time'
                      required={true}
                      value={schedule !== "" ? new Date(schedule) : ""}
                      // show={true}
                      // min={addMinutes(new Date(startDate), 15)}
                      min={getStartDateForSchedule()}
                      max={endDate}
                      format={getUserData()?.timeFormat === "24 hours" ? "dd MMM, yyyy HH:mm " : "dd MMM, yyyy hh:mm a"}
                      popup={CustomPopup}
                      // defaultValue={new Date()}
                      onChange={(event) => {
                        if (event.target.value !== null) {
                          setSchedule(event.target.value);
                          let localBlastTime = moment(event.target.value).format(getUserData()?.timeFormat === "24 hours" ? "DD MMM, yyyy HH:mm " : "DD MMM, yyyy hh:mm a")
                          props.mobileSMSData.content[0].localBlastDateTime = localBlastTime;
                          let errMsg = props.mobileSMSData.content[0].localBlastDateTime !== "" ? null : SCHEDULE_DATE
                          setErrScheduleDateMsg(errMsg)
                        } else {
                          props.mobileSMSData.content[0].localBlastDateTime = "";
                          setSchedule("");
                          setErrScheduleDateMsg(SCHEDULE_DATE)
                        }
                      }}
                    />
                    <div className={`input-outer-set ${(schedule !== "") ? "" : "click-off"} `}>
                      <RSPTooltip text="Reset schedule" position="top">
                        <i
                          className="icon-sd-refresh-medium icons-md blue ml10"
                          onClick={() => {
                            setSchedule("");
                            props.mobileSMSData.content[0].localBlastDateTime = "";
                          }}
                        />
                      </RSPTooltip>
                    </div>
                  </div>
                </div>
                <Row className="marginT5">
                  {/* <Col md="8">
                  <div className="d-flex align-items-center marginB30">
                    <RSCheckbox
                      className="marginB0"
                      lbl="Use send time recommendation"
                      checked={isSendRecommendation}
                      cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                    />
                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                  </div>
                </Col> */}
                  {isEditTimeZone === false ?
                    <Col>
                      <div className="float-end">
                        <label>{timeZone?.gmtOffset}</label>
                        <RSPTooltip text="Edit" position="top">
                          <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                            setIsEditTimeZone(!isEditTimeZone);
                          }} />
                        </RSPTooltip>
                      </div>
                    </Col> : null}
                </Row>
              </Col>
            </Row>
            {
              isEditTimeZone
                ? <Row className={`custom-column mt20 ${editClickOff ? "click-off" : ""}`}>
                  <Col md={3}>
                    <label className="label-row">Select time zone</label>
                  </Col>
                  <Col md={6}>
                    <div className="position-relative">
                      {errTimeZoneMsg && (
                        <div className='validation-message'>
                          {errTimeZoneMsg}
                        </div>
                      )}
                      <RSDropdownList
                        className="marginB0 required"
                        data={timeZoneData}
                        value={timeZone}
                        required={true}
                        defaultItem={{
                          "timezoneId": 0,
                          "timezoneName": "-- Select time zone --",
                        }}
                        textField="timezoneName"
                        dataItemKey="timezoneId"
                        onChange={(e) => {
                          let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                          setErrTimeZoneMsg(errMsg);
                          setTimeZone(e.target.value);
                          props.mobileSMSData.content[0].timezoneId = e.target.value.timezoneId;
                          getStartDateForSchedule();
                        }} />
                    </div>
                    <Row className="marginT5">
                      <Col md="8">
                        <div className="d-flex align-items-center">
                          <RSCheckbox
                            className="marginT0 marginB15 click-off"
                            lbl="Daylight saving"
                            checked={isDayLightSavings}
                            cb={(value) => { setIsDayLightSavings(!isDayLightSavings) }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                : null
            }
          </div>

        </>}
        <Row className={`custom-column mt30 ${(editClickOff || (props.mobileSMSData.channelstatusId === 7 && isEditRequestForApproval)) ? "click-off" : ""} `}>
          <Col md={3}>
            <label className="label-row">Send test message to</label>
          </Col>
          <Col md={6} className="posr zIndex1">
            <div className="position-relative d-flex">
              {errSendTestMsg && (
                <div className="validation-message mobile-valid">
                  {errSendTestMsg}
                </div>
              )}
              <RSPhoneInput
                className="phone-input-short not-required"
                value={countryCodeMobile + mobileNo}
                country={countryCode}
                inputStyle={{ display: 'none' }}
                onMount={(phone, country) => {
                  handleCountryPhoneNumberLength(country)
                }}
                onChange={(phone, country) => {
                  handleCountryPhoneNumberLength(country)
                  let errMsg = phone !== "" ? null : SEND_TEST_MSG;
                  props.mobileSMSData.countryCodeMobile = country.dialCode;
                  setErrSendTestMsg(errMsg);
                  // setMobileNo(phone);
                  // setMobileNo(`+${country.dialCode}`);
                  if (countryCodeMobile !== country.dialCode) {
                    setMobileNo("");
                  }
                  setCountryCode(country.countryCode);
                  setErrMbileMsg(null)
                  setCountryCodeMobile(country.dialCode);
                }}
              // onBlur={(e) => {
              //   let _countryCode = countryCodeMobile.replaceAll("+", "")
              //   let _value = e.target.value.replaceAll("+", "")

              //   if (_value === _countryCode || !_countryCode.length) {
              //     setErrSendTestMsg(SEND_TEST_MSG)
              //     return
              //   }

              //   let phone3 = e.target.value.replaceAll(" ", "");
              //   let phone2 = phone3.replaceAll("-", "")
              //   let phone = phone2.replaceAll("+", "")
              //   let trimNewPhone = phone.slice(_countryCode.length, phone.length)
              //   props.mobileSMSData.testSmsMobileNo = trimNewPhone;
              //   setMobileNo(trimNewPhone);
              // }}
              />
              <RSInput2
                ph="+91 7845984022"
                type="tel"
                className=""
                keyboardType="telePhoneNumber"
                dialCode={countryCodeMobile}
                phoneLength={countryPhoneNumberLength}
                val={`+${countryCodeMobile} ${mobileNo}`}
                cb={(phone, maxMobile) => {
                  setMobileNo(phone);
                  // setMobileNoWithoutDaialCode(phone);
                  let isValidNumber = handleMobileNumberValidation(phone, maxMobile);
                  const { err, msg } = isValidNumber;
                  setErrMbileMsg(msg);

                }}
                errorMessage={errMbileMsg}
              />
            </div>
            {!isRequestForApproval &&
              <div className="input-outer-set">
                <RSPTooltip text="Test SMS can be sent to max. 5 mobile numbers." position="top">
                  <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp" />
                </RSPTooltip>
              </div>
            }
            <div className={`d-flex align-items-center mt-20 mb20 ${(props.mobileSMSData.channelstatusId === 7 && isEditRequestForApproval) ? "click-off" : ""}`}>
              <RSCheckbox
                className="marginB0"
                lbl="Request approval"
                checked={isRequestForApproval}
                cb={(status) => {
                  setIsRequestApproval(status);
                  props.mobileSMSData.isRequestForApprovalEnabled = status;
                  let tempApprovalList = [
                    {
                      approverEmail: {
                        "email": "-- Select approval mail --",
                        "userid": 0
                      },
                      ismandatory: false,
                      edmworlflowdetails: 0,
                      priority: 1,
                      ApprovalSentcount: 1,
                      name: "approval",
                      input: false,
                      appErr: null
                    }
                  ]
                  if (!status) {
                    setApprovalList(tempApprovalList)
                    props.mobileSMSData.requestForApproval = {
                      "IsFollowHierarchy": false,
                      "matchType": "All",
                      "matchCount": "0",
                      "approvarList": [
                        {
                          "approvarId": "0",
                          "approvarName": "-- Select --",
                          "flag": false,
                          "ismandatory": false,
                          "priority": 1,
                          "ApprovalSentcount": 1
                        }
                      ]
                    }
                  } else {
                    setApprovalList(tempApprovalList);
                  }
                }}
              />
              <RSPPophover text="Get the communication content verified and approved by one or more authorized approvers." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer position-relative z-1"></i>
              </RSPPophover>
            </div>
            {isRequestForApproval && approvalList.map((item, index) => {
              return (
                <Row className={`${(props.mobileSMSData.channelstatusId === 7 && isEditRequestForApproval) ? "click-off" : ""}`} key={index}>
                  <Col md={12}>
                    <div className="position-relative">
                      {requestForErrorMsg[index].approverEmailMsg && (
                        <div className="validation-message">
                          {requestForErrorMsg[index].approverEmailMsg}
                        </div>)}
                      {
                        item.approverEmail.userid !== -1 ?
                          <RSDropdownList
                            className={`mb10 ${isRequestForApproval ? "required" : ""}`}
                            data={approverListFromServer}
                            textField="email"
                            dataItemKey="userid"
                            value={item.approverEmail}
                            defaultItem={{
                              "email": "-- Select approval mail --",
                              "userid": 0
                            }}
                            onChange={(event) => {
                              let temp = [...approvalList];
                              temp[index].approverEmail = event.target.value.userid !== -1 ? event.target.value : {
                                "email": "",
                                "userid": -1
                              };
                              let tempErrorMsg = [...requestForErrorMsg];
                              tempErrorMsg[index].approverEmailMsg = null;
                              setApprovalList(temp);
                              setRequestForErrorMsg(tempErrorMsg);
                            }} /> :
                          <div>
                            <RSInput2
                              required={isRequestForApproval}
                              ph="Enter the approver's email address"
                              val={item.approverEmail.email}
                              cls="mb10 paddingR35"
                              cb={(text) => {
                                let tempErrorMsg = [...requestForErrorMsg];
                                tempErrorMsg[index].approverEmailMsg = null;
                                setRequestForErrorMsg(tempErrorMsg);
                                let tempApprovalList = [...approvalList];
                                tempApprovalList[index].approverEmail.email = text;
                                setApprovalList(tempApprovalList);
                              }}
                              ob={(text) => {
                                if (text.split(",").length === 1) {
                                  if (!validateEmail(text)) {
                                    let tempErrorMsg = [...requestForErrorMsg];
                                    tempErrorMsg[index].approverEmailMsg = ENTER_VALID_EMAIL;
                                    setRequestForErrorMsg(tempErrorMsg);
                                  } else {
                                    let tempErrorMsg = [...requestForErrorMsg];
                                    tempErrorMsg[index].approverEmailMsg = null;
                                    setRequestForErrorMsg(tempErrorMsg);
                                  }
                                } else {
                                  let tempErrorMsg = [...requestForErrorMsg];
                                  tempErrorMsg[index].approverEmailMsg = "Accept only one approval mail";
                                  setRequestForErrorMsg(tempErrorMsg);
                                }
                              }}
                              errorMessage={requestForErrorMsg[index].approverEmailMsg}
                            />
                            <div className="input-inner-set">
                              <i className="icon-sd-close-mini icons-sm blue mr-10"
                                onClick={() => {
                                  let tempApprovalList = [...approvalList];
                                  tempApprovalList[index].approverEmail = {
                                    "email": "-- Select approval mail --",
                                    "userid": 0
                                  };
                                  let tempErrorMsg = [...requestForErrorMsg];
                                  tempErrorMsg[index].approverEmailMsg = null;
                                  setRequestForErrorMsg(tempErrorMsg);
                                  setApprovalList(tempApprovalList);
                                }}
                              />
                            </div>
                          </div>
                      }

                    </div>
                    <div className="input-outer-set d-none">
                      <i className={`icons-md blue cp mt10 mr15 d-block ${isRequestForApproval ? "" : "click-off"} 
                                ${index === 0
                          ? `${approvalList.length < 3
                            ? "icon-sd-circle-plus-fill-edge-medium "
                            : "icon-sd-circle-plus-fill-edge-medium click-off "}`
                          : "icon-sd-circle-minus-fill-edge-medium red"}`} onClick={() => {
                            if (index === 0 && approvalList.length < 3) {
                              let enable = true;
                              approvalList.forEach((item, index) => {
                                if (item.approverEmail.userId === 0) {
                                  enable = false;
                                  let tempErrorMsg = [...requestForErrorMsg];
                                  tempErrorMsg[index].approverEmailMsg = APPROVAL_MAIL;
                                  setRequestForErrorMsg(tempErrorMsg)
                                  return;
                                } else {
                                  let tempErrorMsg = [...requestForErrorMsg];
                                  tempErrorMsg[index].approverEmailMsg = null;
                                  setRequestForErrorMsg(tempErrorMsg)
                                  enable = true;
                                }
                              })
                              if (enable) {
                                let temp = [...approvalList];
                                temp.push({
                                  approverEmail: {
                                    "email": "-- Select approval mail --",
                                    "userid": 0
                                  },
                                  ismandatory: false,
                                  edmworlflowdetails: 0,
                                  priority: 1,
                                  ApprovalSentcount: 1
                                });
                                let tempErrMsg = [...requestForErrorMsg];
                                tempErrMsg.push(
                                  {
                                    approverEmailMsg: null
                                  }
                                )
                                setApprovalList(temp);
                                setRequestForErrorMsg(tempErrMsg);
                              }
                            } else {
                              var index1 = approvalList.indexOf(item);
                              var tempList = [...approvalList];
                              tempList.splice(index1, 1);
                              var tempErrMsg = [...requestForErrorMsg];
                              tempErrMsg.splice(index1, 1);
                              setApprovalList(tempList);
                              setRequestForErrorMsg(tempErrMsg);
                            }
                          }} />
                      {approvalSettings.approvalMatchType !== "" && approvalSettings.approvalMatchType.toLowerCase() !== 'all' &&
                        <RSPTooltip text="Set mandatory" position="top">
                          <i className={item.ismandatory ? "icon-asterisk-mini red icon-sm mand-field cursor-pointer" : "icon-asterisk-mini icon-sm mand-field cursor-pointer"}
                            onClick={() => {
                              if (approvalSettings.approvalMatchCount !== approvalList.filter(obj => obj.ismandatory === true).length) {
                                item.ismandatory = !item.ismandatory;
                                let temp = [...approvalList];
                                temp[index].ismandatory = item.ismandatory;
                                setApprovalList(temp);
                              } else if (item.ismandatory) {
                                item.ismandatory = !item.ismandatory;
                                let temp = [...approvalList];
                                temp[index].ismandatory = item.ismandatory;
                                setApprovalList(temp);
                              }
                            }}></i>
                        </RSPTooltip>}
                    </div>
                  </Col>
                </Row>
              );
            })}
            {isRequestForApproval &&
              <RSPTooltip position="top" text="Approval settings">
                <i className={`icon-sd-settings-small icons-md blue cursor-pointer mr0 float-end d-none ${isRequestForApproval ? "" : "click-off"}`} onClick={() => {
                  setIsModalOpen(!isModalOpen);
                }} ></i>
              </RSPTooltip>}
          </Col>
          <Col md={3} className='ml15'>
            <RSPrimaryBtn className={errMbileMsg === null ? "" : "click-off"} onClick={() => {
              props.mobileSMSData.testSmsMobileNo = mobileNo;
              props.mobileSMSData.countryCodeMobile = countryCodeMobile;
              let mobileSMSData = makeImmutableObject(props.mobileSMSData);
              let isEnable = false;
              if (isRequestForApproval) {
                isEnable = validation();
              } else {
                isEnable = validation("draft");
              }
              if (isEnable) {
                if (isRequestForApproval) {
                  let approvarList = [...approvalList].map((item, index) => {
                    return {
                      approvarId: item.approverEmail?.userid || "",
                      approvarName: item.approverEmail.email,
                      flag: false,
                      ismandatory: false,
                      priority: 1,
                      ApprovalSentcount: 1,
                    };
                  });
                  let approval_object = {
                    IsFollowHierarchy: false,
                    matchType: "All",
                    matchCount: 0,
                    approvarList: approvarList,
                  };
                  mobileSMSData.requestForApproval = approval_object;
                }
                let params = createMobileSMSCampaign("S", props.campaignId, mobileSMSData, isRequestForApproval ? false : true, "", "")
                connectServer({
                  path: smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                  params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: res => {
                    if (res.status) {
                      setIsOpenSmsSendSuccessModal(res.status);
                      setSmsChannelId(res.data.smsChannelDetailId)
                      props.mobileSMSData.content[0].smsChannelDetailId = res.data.smsChannelDetailId;
                    }
                  },
                  fail: err => {
                  }
                })
              }

            }}>Send</RSPrimaryBtn>
          </Col>
        </Row>
      </div>
      <div className="btn-container d-flex justify-content-end mt40">
        <RSSecondaryBtn paddingR="0px"
          onClick={() => {
            props.history.push({ pathname: `/communication` })
          }}>Cancel</RSSecondaryBtn>
        <RSSecondaryBtn className={`mx7 blue ${(editClickOff || (props.mode === "edit" && props.mobileSMSData.channelstatusId === 7) || isRequestForApproval) ? "click-off" : ""}`}
          onClick={() => {
            if (editClickOff) {
              props.history.push({ pathname: `/communication` })
            } else {
              setSplitContentError([
                {
                  errBodyMsg: null,
                  errScheduleDateMsg: null
                },
                {
                  errBodyMsg: null,
                  errScheduleDateMsg: null
                }
              ])
              setErrSenderIdMsg(null);
              setErrAudienceMsg(null);
              setErrLanguageMsg(null);
              setErrCampaignTextMsg(null);
              setErrSendTestMsg(null);
              setErrScheduleDateMsg(null);
              let enable = validation('draft');
              if (enable === undefined || enable) {
                props.mobileSMSData.testSmsMobileNo = mobileNo;
                props.mobileSMSData.countryCodeMobile = countryCodeMobile;
                let params = createMobileSMSCampaign("S", props.campaignId, props.mobileSMSData, false, "", "")
                let temp = [...params.content];
                temp.forEach((item, index) => {
                  item.localBlastDateTime = "";
                })
                params.content = temp;
                connectServer({
                  path: props.isUpdate ? UPDATE_SMS_CAMPAIGN : smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                  params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: res => {
                    if (res.status) {
                      props.history.push({ pathname: `/communication` })
                    }
                  },
                  fail: err => {
                  }
                })
              }
            }
          }}>Save as draft</RSSecondaryBtn>
        <RSPrimaryBtn onClick={() => {
          if (editClickOff) {
            props.moveNext(1)
          } else {
            setSplitContentError([
              {
                errBodyMsg: null,
                errScheduleDateMsg: null
              },
              {
                errBodyMsg: null,
                errScheduleDateMsg: null
              }
            ])
            setErrSenderIdMsg(null);
            setErrAudienceMsg(null);
            setErrLanguageMsg(null);
            setErrCampaignTextMsg(null);
            setErrSendTestMsg(null);
            setErrScheduleDateMsg(null);
            let isEnable = false;
            isEnable = validation();
            if (isEnable) {
              if (isRequestForApproval) {
                let approvarList = [...approvalList].map((item, index) => {
                  return {
                    approvarId: item.approverEmail?.userid || "",
                    approvarName: item.approverEmail.email,
                    flag: false,
                    ismandatory: false,
                    priority: 1,
                    ApprovalSentcount: 1,
                  };
                });
                let approval_object = {
                  IsFollowHierarchy: false,
                  matchType: "All",
                  matchCount: 0,
                  approvarList: approvarList,
                };
                props.mobileSMSData.requestForApproval = approval_object;
                props.mobileSMSData.isRequestForApprovalScheduled = (props.mobileSMSData.channelstatusId === 7 && isEditRequestForApproval) ? true : false
              }
              props.mobileSMSData.testSmsMobileNo = mobileNo;
              props.mobileSMSData.countryCodeMobile = countryCodeMobile;
              let params = createMobileSMSCampaign("S", props.campaignId, props.mobileSMSData, false, "", "")
              connectServer({
                path: props.isUpdate ? UPDATE_SMS_CAMPAIGN : smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: res => {
                  if (res.status) {
                    props.moveNext(1)
                  }
                },
                fail: err => {
                }
              })
            }
          }
        }}>Next</RSPrimaryBtn>
      </div>
      {
        isModalOpen &&
        <ApprovalSettings
          isOpen={isModalOpen}
          approvalSettings={approvalSettings}
          onOpenChange={(value) => {
            setIsModalOpen(value);
          }}></ApprovalSettings>
      }
      {
        isSplitSettings &&
        <SplitSettingsModal
          isOpen={isSplitSettings}
          communicationId={props.campaignId}
          communicationData={props.mobileSMSData}
          edmAutoSchedule={props.mobileSMSData.smsAutoSchedule}
          onChangeIsOpen={(value) => {
            setIsSplitSettings(value);
          }} />
      }
      {
        isShowSplitSizeModal &&
        <SplitSizeModal isOpen={isShowSplitSizeModal} onChangeIsOpen={(value) => {
          setIsShowSplitSizeModal(value);
        }} />
      }
      {
        isOpenSmsSendSuccessModal &&
        <SuccessModal
          isOkButton={false}
          isOpen={isOpenSmsSendSuccessModal}
          title={"Success"}
          subTitle={isRequestForApproval ? "Communication has been sent successfully for approval." : "Test communication will be sent shortly."}
          buttonClicked={(value) => {
            if (isRequestForApproval && isOpenSmsSendSuccessModal) {
              setIsOpenSmsSendSuccessModal(false)
              props.moveNext(1)
            } else {
              setIsOpenSmsSendSuccessModal(false)
            }
          }} />
      }
      {
        isOpenSplitResetModal &&
        <RSConfirmAlert
          title="Are you sure you want to reset it?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              let temp = [...props.mobileSMSData.content];
              temp[0].splitType = "";
              temp[0].body = "";
              temp[0].previewBody = "";
              temp[0].smsChannelDetailId = 0;
              temp[0].timezoneId = timeZone.timezoneId;
              temp[0].localBlastDateTime = "";
              props.mobileSMSData.content = [temp[0]];
              props.mobileSMSData.isSplitAB = false;
              setIsSplit(!isSplit);
              let tempSplitError = [];
              tempSplitError.push(createSplitError());
              tempSplitError.push(createSplitError());
              setSplitContentError(tempSplitError);
              setErrSendTestMsg(null);
            }
            setIsOpenSplitResetModal(!isOpenSplitResetModal)
          }} />
      }
    </>
  )
}
export default withRouter(MobileSMSCreation);