import React, { useContext, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { useState } from "react";
import { RSDropdownList } from "Components/RSDropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { RTabbar } from "Components/RChart/RTabbar";
import { Daily } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Planning/Tabs/EventTrigger/Components/Daily";
import { Weekly } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Planning/Tabs/EventTrigger/Components/Weekly";
import { Monthly } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Planning/Tabs/EventTrigger/Components/Monthly";
import { dateFormatWithHyphen } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import { FORM_DOWNLOAD_DATA } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import DownloadCSVModal from "Container/AuthenticatedModule/Reports/DetailAnalytics/Components/DownloadCSVModal";
import RSPDateRangePicker from "./DateRangePicker/RSPDateRangePicker";

const FormCSVDownloadModal = ({
    isOpen,
    onChangeModalOpen,
    formId,
    createdDate
}) => {
    const context = useContext(authContext);

    const [isShow, setIsShow] = useState(isOpen);
    const [campaignJson, setCampaignJson] = useState({
        frequency: [
            {
                frequency: "Daily",
                dayInterval: "",
                hourInterval: "",
            },
            {
                frequency: "Weekly",
                dayInterval: "",
                hourInterval: "",
                weekDays: [],
            },
            {
                frequency: "Monthly",
                dayInterval: "",
                monthInterval: "",
                hourInterval: "",
                isDayEnable: true,
            },
            {
                frequency: "Monthly",
                weekInterval: "",
                day: "",
                dayInterval: "",
                hourInterval: "",
                isDayEnable: false,
            },
        ],
    });
    const [feild, setFeild] = useState([]);
    const [feildList, setFeildList] = useState(null);
    const [switchFlag, setSwitchFlag] = useState(false);
    const [monthlyError, setMonthlyError] = useState({});
    const [weeklyError, setWeeklyError] = useState({});
    const [dailyError, setDailyError] = useState({});
    const [campaignList, setCampaignList] = useState([]);
    const [download_CSV_modal, setDownload_CSV_modal] = useState(false);
    const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);
    const [isShowDateRange, setIsShowDateRange] = useState(true);
    const [fetchDataParams, setFetchDataParams] = useState({
        startDate: "",
        endDate: "",
        allTime: "active",
    });
    const [campaignName, setCampaignName] = useState({ "campaignName": "-- Select communication --", "campaignId": "" });
    useEffect(() => {
        getFormDetails();
    }, [formId]);
    useEffect(() => {
        if (document.querySelectorAll('[role="dialog"]')[0]) {
            document
                .querySelectorAll('[role="dialog"]')[0]
                .removeAttribute("tabIndex");
        }
        return () => {
            if (document.querySelectorAll('[role="dialog"]')[0]) {
                document.querySelectorAll('[role="dialog"]')[0].tabIndex = "tabIndex";
            }
        };
    }, []);
    const getFormDetails = () => {
        let params = {
            formId: formId,
        };
        connectServer({
            path: FORM_DOWNLOAD_DATA,
            params,
            // loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: (res) => {
                if (res.status) {
                    setFeildList(res?.data?.columnName);
                    setCampaignList(res?.data?.campaignDetails);
                }
            },
            fail: (err) => { },
        });
    }

    const handleAddList = (e) => {
        let { value } = e.target;
        if (value === "-- Select --") return;
        let getFeildList = [...feildList];
        let getFeild = [...feild].indexOf(value);
        getFeildList.push(value);
        feild.splice(getFeild, 1);
        setFeildList(getFeildList);
        setFeild([...feild]);
    };

    const handleRemoveList = (eve, ind) => {
        let getList = [...feild];
        getList.push(eve);
        feildList.splice(ind, 1);
        setFeild(getList);
    };

    const [index, setIndex] = useState(1);
    const tabData = [
        { id: "1002", text: "Daily" },
        { id: "1003", text: "Weekly" },
        { id: "1004", text: "Monthly" },
    ];

    // onchange for daily, monthly and weekly

    const handleMonth = (key, value) => {
        let temp1 = { ...monthlyError };
        if (key === "dayinterval") {
            temp1.errDayInterval = value.length > 0 ? null : "Enter day interval";
        } else if (key === "hourinterval") {
            temp1.errHourInterval =
                value.toString().length > 0 ? null : "Enter hour interval";
        } else if (key === "monthinterval") {
            temp1.errMonthInterval =
                value.length > 0 ? null : "Enter month interval";
        } else if (key === "weekinterval") {
            temp1.errWeekInterval = value.length > 0 ? null : "Select week interval";
        } else if (key === "day") {
            temp1.errDay = value.length > 0 ? null : "Select week";
        }
        setMonthlyError(temp1);
    };

    const getMonthValidate = () => {
        let valid = true;
        let temp1 = { ...monthlyError };
        if (temp1?.errDayInterval !== null) {
            valid = false;
            temp1.errDayInterval = "Enter day interval";
        } else if (temp1?.errMonthInterval !== null) {
            valid = false;
            temp1.errMonthInterval = "Select month interval";
        } else if (temp1?.hourInterval == "" || temp1?.errHourInterval !== null) {
            valid = false;
            temp1.errHourInterval = "Enter hour interval";
        } else {
            valid = true;
        }
        setMonthlyError(temp1);
        return valid;
    };
    const getDayValidate = () => {
        let valid = true;
        let temp1 = { ...dailyError };
        if (temp1?.dayInterval !== null) {
            valid = false;
            temp1.dayInterval = "Enter a value";
        } else if (temp1?.hourInterval !== null) {
            valid = false;
            temp1.hourInterval = "Enter hour interval";
        } else {
            valid = true;
        }
        setDailyError(temp1);
        return valid;
    };
    const handleSubmit = () => {

        if (!feildList?.length) return
        if (!switchFlag) {
            setDownload_CSV_modal(true);
            setIsShow(false);
        } else {
            if (index === 1) {
                if (getDayValidate()) {
                    setDownload_CSV_modal(true);
                    setIsShow(false);
                }
            } else if (index === 2) {
                if (getWeekValidate()) {
                    setDownload_CSV_modal(true);
                    setIsShow(false);
                }
            } else if (index === 3) {
                if (getMonthValidate()) {
                    setDownload_CSV_modal(true);
                    setIsShow(false);
                }
            }
        }
    };
    const getWeekValidate = () => {
        let valid = true;
        let temp1 = { ...weeklyError };
        if (temp1?.errDayInterval !== null) {
            valid = false;
            temp1.errDayInterval = "Enter day interval";
        } else if (temp1?.errWeekDays !== null) {
            valid = false;
            temp1.errWeekDays = "Select week interval";
        } else if (temp1?.hourInterval == "" || temp1?.errHourInterval !== null) {
            valid = false;
            temp1.errHourInterval = "Enter hour interval";
        } else {
            valid = true;
        }
        setWeeklyError(temp1);
        return valid;
    };

    const handleWeek = (key, value) => {
        let temp1 = { ...weeklyError };
        let tempJson = campaignJson;
        let tempweekly = tempJson.frequency.filter(
            (obj) => obj.frequency.toLowerCase() === "weekly"
        )[0];
        if (key === "dayinterval") {
            tempweekly.dayInterval = value;
            temp1.errDayInterval = value.length > 0 ? null : "Enter day interval";
        } else if (key === "hourinderval") {
            tempweekly.hourInterval = value;
            temp1.errHourInterval =
                value.toString().length > 0 ? null : "Enter hour interval";
        } else if (key === "weekdays") {
            tempweekly.weekDays = value;
            temp1.errWeekDays = value.length > 0 ? null : "Select week interval";
        }
        setCampaignJson(tempJson);
        setWeeklyError(temp1);
    };

    const handleDaily = (value, key) => {
        let temp1 = { ...dailyError };
        let tempJson = JSON.parse(JSON.stringify(campaignJson));
        tempJson.frequency[0][key] = value;
        temp1[key] = value.toString().length > 0 ? null : "Enter a value";
        setDailyError(temp1);
        setCampaignJson(tempJson);
    };
    const RenderComponent = (index) => {
        switch (index) {
            case 1:
                return (
                    <Daily
                        campaignJson={campaignJson}
                        dailyError={dailyError}
                        onChange={handleDaily}
                        // max={dateDifferences.days}
                    />
                );
            case 2:
                return (
                    <Weekly
                        campaignJson={campaignJson}
                        weeklyError={weeklyError}
                        onChange={handleWeek}
                        // max={dateDifferences.weeks}
                    />
                );
            case 3:
                return (
                    <Monthly
                        campaignJson={campaignJson}
                        monthlyError={monthlyError}
                        onChange={handleMonth}
                        // max={dateDifferences.months}
                    />
                );

            default:
                return null;
        }
    };

    // search and date
    const closeDateRangePicker = (status, dates) => {
        if (!status && !!dates) {
            let tempFetchDataParams = { ...fetchDataParams };
            tempFetchDataParams.startDate = dateFormatWithHyphen(
                new Date(dates?.start)
            );
            tempFetchDataParams.endDate = dateFormatWithHyphen(new Date(dates?.end));
            tempFetchDataParams.allTime = dates.allTime;
            setFetchDataParams(tempFetchDataParams);
        }
        setIsShowDateRangePicker(status);
    };

    // const getDateDifferences = (startDate, endDate) => {
    //     // Ensure valid date objects
    //     if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    //       return null; // Handle invalid input (optional)
    //     }
      
    //     // Calculate the difference in milliseconds
    //     const timeDifference = endDate.getTime() - startDate.getTime();
      
    //     // Calculate days
    //     const daysCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
    //     // Calculate weeks (assuming 7 days per week)
    //     const weeksCount = Math.floor(daysCount / 7);
      
    //     // Calculate months (using a more accurate approach)
    //     let monthsCount = 0;
    //     let currentMonth = startDate.getMonth();
    //     let currentYear = startDate.getFullYear();
    //     let nextMonth = currentMonth;
    //     let nextYear = currentYear;
    //     while (endDate.getTime() > new Date(nextYear, nextMonth, 1).getTime()) {
    //       nextMonth++;
    //       if (nextMonth > 11) {
    //         nextMonth = 0;
    //         nextYear++;
    //       }
    //       monthsCount++;
    //     }
      
    //     return {
    //       days: daysCount,
    //       weeks: weeksCount,
    //       months: monthsCount
    //     };
    // }
    
    // const dateSplit = (date) => {
    //     // Example date string
    //     var dateString = date;

    //     // Split the date string into date and time parts
    //     var parts = dateString.split("T");

    //     // Date part will be in parts[0], which is "2024-04-04"
    //     var datePart = parts[0];

    //     // Split the date part into year, month, and day
    //     var dateComponents = datePart.split("-");

    //     // Year will be in dateComponents[0]
    //     var year = dateComponents[0];

    //     // Month will be in dateComponents[1]
    //     var month = parseInt(dateComponents[1], 10); // Base 10

    //     // Day will be in dateComponents[2]
    //     var day = parseInt(dateComponents[2], 10); // Base 10

    //     // Output the results
    //     // console.log("Year: " + year);
    //     // console.log("Month: " + month);
    //     // console.log("Day: " + day);

    //     return year + ', ' + month + ', ' + day
    // }
    
    // Example usage:
    // const startDate = new Date(dateSplit(createdDate)); // April 5, 2024
    // const endDate = new Date(); // May 10, 2024
    
    // const dateDifferences = getDateDifferences(startDate, endDate);
    // console.log('endDate: ', endDate);
    // console.log('startDate: ', startDate);
    // console.log(dateDifferences); // Output: { days: 35, weeks: 5, months: 1 }
    // setMaxDays(dateDifferences.days)
    // setMaxWeeks(dateDifferences.weeks)
    // setMaxMonths(dateDifferences.months)

    return (
        <>
            <Modal
                show={isShow}
                centered
                backdrop="static"
                className='modal-top-fixed'
                keyboard={false}
                size="xl"
                onHide={() => {
                    setIsShow(false);
                    onChangeModalOpen(false);
                }}
            >
                <Modal.Header closeButton>
                    <h2>Select form fields to download as CSV</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className="custom-column-del">
                            <Col md={3}>
                                <label className="label-row label-dark">Form fields</label>
                            </Col>
                            <Col md={6}>
                                <div className="position-relative">
                                    {null != null && (
                                        <div className="validation-message">{null}</div>
                                    )}
                                    <RSDropdownList
                                        data={feild}
                                        className="required"
                                        onChange={handleAddList}
                                        defaultItem={"-- Select --"}
                                        value={"-- Select --"}
                                        disabled={!feild.length}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="custom-column-del">
                            <div>
                                <span style={{ color: "red" }}>
                                    {feildList?.length == 0 && "Select form field"}
                                </span>
                                <div
                                    className="p10 mb30 d-inline-block w-100"
                                    style={{ border: "solid 1px #c2cfe3", minHeight: "60px" }}
                                >
                                    {feildList &&
                                        feildList?.map((ele, ind) => (
                                            <div
                                                className="mr5 mb5 px10 float-start d-block"
                                                style={{ backgroundColor: "#e9e9e9", height: "24px" }}
                                            >
                                                <span className="mr10 font-xs">{ele}</span>
                                                <i
                                                    className="icon-sd-close-mini cp position-relative top-1"
                                                    style={{ fontSize: "8px" }}
                                                    onClick={() => {
                                                        handleRemoveList(ele, ind);

                                                    }}
                                                ></i>
                                            </div>
                                        ))}
                                </div>
                            </div>{" "}
                        </Row>
                        <Row className="custom-column-del">
                            <Col md={3}>
                                <label className="label-row label-dark">
                                    Communication name
                                </label>
                            </Col>
                            <Col md={6}>
                                <div className="position-relative">
                                    {null != null && (
                                        <div className="validation-message">{null}</div>
                                    )}
                                    <RSDropdownList
                                        data={campaignList}
                                        className=""
                                        defaultValue={"---select---"}
                                        textField="campaignName"
                                        dataItemKey="campaignId"
                                        value={campaignName}
                                        onChange={(e) => setCampaignName(e.target.value)}
                                        disabled={!campaignList?.length}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="custom-column-del">
                            <Col md={3}>
                                <label className="label-row label-dark">Date</label>
                            </Col>
                            <Col md={6}>
                                <RSPDateRangePicker
                                    class="marginR15 float-start"
                                    enable={true}
                                    createdDate={createdDate}
                                    show={isShowDateRange}
                                    isShowDateRangePicker={isShowDateRangePicker}
                                    closeDateRangePicker={(status, date) =>
                                        closeDateRangePicker(status, date)
                                    }
                                    cancelBtnClicked={() => {
                                        setIsShowDateRangePicker(false);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className={`custom-column-del mt20 mb20 align-items-baseline`}>
                            <Col md={3}>
                                <label className="label-row label-dark">
                                    Schedule download
                                </label>
                            </Col>
                            <Col md={1}>
                                <div className="d-flex align-items-center mb20 switchLable">
                                    <Switch
                                        className={`mt0`}
                                        onLabel={"ON"}
                                        offLabel={"OFF"}
                                        onChange={(event) => {
                                            setSwitchFlag(event.target.value);
                                        }}
                                        checked={switchFlag}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {switchFlag && (
                            <Row
                                className={`custom-column-del smartlink-col-del marginT0 ${"" ? "click-off" : ""
                                    }`}
                            >
                                <Col md={3}>
                                    <label className="label-row label-dark">Frequency</label>
                                </Col>
                                <Col>
                                    <RTabbar
                                        defaultSelectedItem={index - 1}
                                        defaultClass="tabDefault"
                                        dynamicTab="rs-btn-tab"
                                        activeClass="tabDefault active"
                                        tabData={tabData}
                                        callBack={(item, index) => {
                                            setIndex(index + 1);
                                            setMonthlyError("")
                                            setDailyError("")
                                            setWeeklyError("")
                                        }}
                                    />
                                    {RenderComponent(index)}
                                </Col>
                            </Row>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer mb={30}>
                    <div className="btn-container d-flex justify-content-end">
                        <RSSecondaryBtn
                            padding="0px"
                            onClick={() => {
                                setIsShow(false);
                                onChangeModalOpen(false);
                            }}
                        >
                            Cancel
                        </RSSecondaryBtn>

                        <RSPrimaryBtn className={""} onClick={handleSubmit}>
                            Ok
                        </RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal>
            {download_CSV_modal && (
                <DownloadCSVModal
                    isOpen={download_CSV_modal}
                    channelId={1}
                    isForm={true}
                    formData={{
                        feildList,
                        campaignName,
                        campaignJson,
                        switchFlag,
                        fetchDataParams,
                        formId,
                        index,
                    }}
                    onChangeIsOpen={(value) => {
                        setDownload_CSV_modal(false);
                        setIsShow(false);
                        onChangeModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default FormCSVDownloadModal;
