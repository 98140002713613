import React, { useState, Component } from "react";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RTabbar } from "Components/RChart/RTabbar";
import { RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import logoFacebook from 'Assets/Images/logos/logo-facebook.jpg'
import ftbLogo from 'Assets/Images/ftp.jpg'
import { GET_ACTIVE_RDS, GET_LIST_DATA_EXCHANGE } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { RSPTooltip } from "Components/Tooltip";
import * as images from 'Assets/Images';
import PromoCodeModal from "./Components/PromoCode";

class DataExchange extends Component {
    static contextType = authContext;
    state = {
        integratedSystem: [],
        allIntegratedSystem: [],
        availableSystem: null,
        pixelName: "",
        pixelId: "",
        isShowModal: false,
        isSDKModalShow: false,
        isSDKViewModalShow: false,
        groupId: "all",
        selectedData: null,
        index: 0,
        apiURL: "",
        userName: "",
        password: "",
        consumerSecretKey: ""
    }

    componentDidMount = props => {
        window.scrollTo(0, 0)
        let some = []

        let keys = Object.keys(json).map(item => {
            return item
        })

        keys.forEach(key => {
            some.push(json[key])
        })

        this.getActiveRemoteDataSource();
        // alert(JSON.stringify(some))
        this.setState({ availableSystem: some })
        if (this.props.history.location.state !== undefined && this.props.history.location.state.selectedData !== undefined) {
            let temp = [...this.state.integratedSystem];
            let some = {
                pixelName: this.state.pixelName,
                pixelId: this.state.pixelId,
                id: this.props.history.location.state.selectedData.id,
                groupId: this.props.history.location.state.selectedData.groupId,
                img: this.props.history.location.state.selectedData.img
            }
            temp.push(some);
            this.setState({ integratedSystem: temp });
        }
    }

    SocialChild = props => {
        return <>
            <RSInput2
                ph="API URL"
                val={this.state.apiURL}
                required
                cb={(value) => this.setState({ apiURL: value })}
            />
            <RSInput2
                ph="User name"
                val={this.state.userName}
                required
                cb={(value) => this.setState({ userName: value })}
            />
            <RSInput2
                ph="Password"
                val={this.state.password}
                required
                cb={(value) => this.setState({ password: value })}
            />
            <RSInput2
                ph="Consumer secret key"
                val={this.state.consumerSecretKey}
                required
                cb={(value) => this.setState({ consumerSecretKey: value })}
            />
        </>
    }

    getFilteredArry = () => {


        // if (this.state.groupId === "all" || this.state.groupId === "remoteDataSource") {
        return this.state.integratedSystem
        // }

        // let some = this.state.integratedSystem.filter(item => {
        //     return item.groupId === this.state.groupId
        // })


        // return some
    }

    getActiveRemoteDataSource = () => {
        let params = { "DepartmentID": 0 }
        connectServer({
            path: GET_ACTIVE_RDS,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res.message !== "No data") {
                    this.setState({ integratedSystem: res.data || [], allIntegratedSystem: res.data || [] });
                }
            },
            fail: err => {

            }
        })
    }
    render() {
        return <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="header-title d-flex justify-content-between">
                    <h1>Data exchange</h1>
                    <div className="back-text" onClick={() => this.props.history.push(`/preferences`)}>
                        <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                        Back
                    </div>
                </div>

                <div className="portlet-box-theme padding0 d-flex">
                    {/* left */}
                    <div className="tabs-vertical">
                        <RTabbar
                            defaultSelectedItem={this.state.index}
                            defaultClass="tabDefault text-center"
                            dynamicTab="vertical-tabs"
                            activeClass="tabDefault active"
                            tabData={tabData}
                            callBack={(item, index) => {
                                this.setState({ index: index })
                                if (item.id === "all") {
                                    let some = []
                                    let keys = Object.keys(json).map(item => {
                                        return item
                                    })
                                    keys.forEach(key => {
                                        some.push(json[key])
                                    })
                                    // alert(JSON.stringify(some))
                                    let tempIntegratedSystem = [...this.state.allIntegratedSystem];
                                    this.setState({ availableSystem: some, groupId: item.id, integratedSystem: tempIntegratedSystem })
                                } else if (item.id === "remoteDataSource") {
                                    let tempIntegratedSystem = [...this.state.allIntegratedSystem];
                                    tempIntegratedSystem = tempIntegratedSystem.filter((item) => item.remoteDataSourceID === 8);
                                    this.setState({ availableSystem: [json[item.id]], groupId: item.id, integratedSystem: tempIntegratedSystem })
                                } else {
                                    let tempIntegratedSystem = [...this.state.allIntegratedSystem];
                                    tempIntegratedSystem = tempIntegratedSystem.filter((item) => item.remoteDataSourceID === 40);
                                    this.setState({ availableSystem: [json[item.id]], groupId: item.id, integratedSystem: tempIntegratedSystem })
                                }
                            }}
                        />
                    </div>

                    {/* right */}
                    <div className="tab-vertical-content">
                        <div className="d-flex justify-content-between">
                            <h3 className="marginB20">Integrated systems</h3>
                        </div>


                        <div className="data-card-view-container">
                            <Row>
                                {
                                    this.state.integratedSystem.length > 0 && this.getFilteredArry().length > 0 ? this.getFilteredArry().map((item, index) => {
                                        return <Col md={4} key={index}>
                                            <DECardView
                                                item={item}
                                                history={this.props.history}
                                                onRefresh={() => {
                                                    this.getActiveRemoteDataSource()
                                                }}
                                                onDelete={index => {
                                                    let newArry = [...this.getFilteredArry()]
                                                    let some = [...this.state.integratedSystem]
                                                    const newindex = some.indexOf(newArry[index])
                                                    some.splice(newindex, 1)
                                                    this.setState({ integratedSystem: some })
                                                }} index={index} />
                                        </Col>
                                    }) : <div className="nodata-add-icon"><p className="d-flex align-items-center">No sources have been connected. Click <i className="icon-sd-circle-plus-fill-edge-medium icon-md blue mx5"></i> icon in the available systems to link the source.</p></div>
                                }
                            </Row>
                        </div>

                        <div className="d-flex justify-content-between marginY20">
                            <h3 className="marginB0">Available systems</h3>
                            {/* <span className="blue cursor-pointer" onClick={() => this.setState({isSDKModalShow: !this.state.isSDKModalShow})}>One line SDK</span> */}
                        </div>


                        <DEBootstrapModal
                            isShowModal={this.state.isSDKModalShow}
                            title="One line SDK"
                            size="lg"
                            close={() => {
                                this.setState({ isSDKModalShow: !this.state.isSDKModalShow })
                            }}
                            primaryTitle="Generate"
                            secondaryTitle="Cancel"
                        >

                            <h4>Web domain</h4>
                            <p>Add web domain to enable the web push notification</p>
                            <Row>
                                <Col md={4}> <RSInput2 cls="label-row" type="text" name="fcmSenderiD" ph="Domail URL"></RSInput2> </Col>
                                <Col md={4}> <RSInput2 cls="label-row" type="text" name="serverKey" ph="Project ID"></RSInput2> </Col>
                                <Col md={4}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <RSInput2 cls="label-row margin0" type="text" name="serverKey" ph="Notification app key"></RSInput2>
                                        <i className="icon-sd-circle-plus-fill-edge-medium icons-md blue marginL15 marginT10 cursor-pointer" title="Add"></i>
                                    </div>
                                </Col>
                            </Row>

                            <h4>Integrated systems</h4>
                            <p>Select integrated systems to include in Resulticks SDK tracking</p>

                            <h4>Web analytics</h4>
                            <div className="data-card-api-box">
                                <Row>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                            {/* <div className="sysicon"> <i className="icon-sd-circle-plus-fill-edge-medium blue icons-lg cursor-pointer"> </i> </div> */}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <h4>Mobile analytics</h4>
                            <div className="data-card-api-box">
                                <Row>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <h4>Tag management</h4>
                            <div className="data-card-api-box">
                                <Row>
                                    <Col md={4}>
                                        <div className="data-card-api border-color-blue">
                                            <figure> <img src={logoFacebook} alt={logoFacebook} /> </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </DEBootstrapModal>


                        <DEBootstrapModal
                            isShowModal={this.state.isSDKViewModalShow}
                            title="One line SDK"
                            size="lg"
                            close={() => {
                                this.setState({ isSDKViewModalShow: !this.state.isSDKViewModalShow })
                            }}
                            primaryTitle="Save"
                            secondaryTitle="Cancel"
                        >

                            <h4 className="marginB15">Web SDK</h4>
                            <Row>
                                <Col md={3}><p>One line SDK</p></Col>
                                <Col md={7}><p className="word-break-all">https://sdk.res.io/handlers/cc33cd4b2fea4b949123bb7d48ff673e.sdk</p></Col>
                                <Col md={2}>
                                    <div className="table-action-btn">
                                        <i className="icon-sd-eye-medium icons-md blue cursor-pointer"></i>
                                        <i className="icon-copy-large icons-md blue cursor-pointer"></i>
                                        <i className="icon-mail-xmedium icons-md blue cursor-pointer"></i>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}><p>Analytics</p></Col>
                                <Col md={3}><p>Resulticks analytics</p></Col>
                                <Col md={2}><i className="icon-tick-medium icon-sm green"></i></Col>
                                <Col md={3}><p>Google analytics</p></Col>
                                <Col md={1}><i className="icon-tick-medium icon-sm green"></i></Col>
                            </Row>
                            <Row>
                                <Col md={3}><p>Tag management</p></Col>
                                <Col md={3}><p>Google tag manager</p></Col>
                                <Col md={3}><i className="icon-tick-medium icon-sm green"></i></Col>
                            </Row>

                            <h4 className="marginT20">Mobile SDK</h4>
                            <Row>
                                <Col md={3}><p>Andriod</p></Col>
                                <Col md={7}><p>compile 'io.resu:ReAndroidSDK_55e5b135-4d1c-4925-97b7-5030bb8235ab:-SNAPSHOT'</p></Col>
                                <Col md={2}>
                                    <div className="table-action-btn">
                                        <i className="icon-sd-eye-medium icons-md blue cursor-pointer"></i>
                                        <i className="icon-copy-large icons-md blue cursor-pointer"></i>
                                        <i className="icon-mail-xmedium icons-md blue cursor-pointer"></i>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}><p>Analytics</p></Col>
                                <Col md={3}><p>App Annie</p></Col>
                                <Col md={2}><i className="icon-tick-medium icon-sm green"></i></Col>
                                <Col md={3}><p>Mixpanel</p></Col>
                                <Col md={1}><i className="icon-tick-medium icon-sm green"></i></Col>
                            </Row>

                        </DEBootstrapModal>


                        {
                            this.state.availableSystem && this.state.availableSystem.map((item, index) => {
                                return <div key={index} className="marginB15">
                                    <p>{item.title || "missing title"}</p>
                                    <div className="data-card-api-box">
                                        <Row>
                                            {
                                                item.data && item.data.map((item, index) => {
                                                    return <Col md={4} key={index}>
                                                        <div className={`data-card-api ${item.id === "versium" ? "click-off" : ""}`}>
                                                            <figure>
                                                                <img src={item.image} alt={item.image} />
                                                            </figure>
                                                            {!item.image && <h3>{item.title}</h3>}
                                                            <div className="sysicon"> <i onClick={() => {
                                                                if (item.id === "mobileApp") {
                                                                    this.props.history.push({ pathname: "/preferences/mobile-data-exchange", state: { selectedData: item } });
                                                                } else if (item.id === "ftp_sftp") {
                                                                    this.props.history.push({ pathname: `/audience/add-audience`, state: { mode: 'add', audienceType: "rds" } });
                                                                } else if (item.id === "Shopify") {
                                                                    this.props.history.push({ pathname: `/audience/data-exchange-creation`, state: { mode: 'add', EcommerceType: item.id } });
                                                                } else if (item.id === "Magento") {
                                                                    this.props.history.push({ pathname: `/audience/data-exchange-creation`, state: { mode: 'add', EcommerceType: item.id } });
                                                                } else if (item.id === "POS") {
                                                                    this.props.history.push(`/audience/pos-data-exchange-creation`);
                                                                } else if (item.id === "d_crm") {
                                                                    this.props.history.push(`/preferences/dynamic-crm`);
                                                                } else if (item.id === "versium") {
                                                                    // this.props.history.push({
                                                                    //     pathname: `/preferences/versium`,
                                                                    //     state: {
                                                                    //         mode: "create",
                                                                    //         id: null,
                                                                    //     },
                                                                    // });
                                                                } else {
                                                                    this.setState({ isShowModal: !this.state.isShowModal, selectedData: item })
                                                                }
                                                            }} className={`icon-sd-circle-plus-fill-edge-medium blue icons-lg cursor-pointer ${item.id === "versium" ? "d-none" : ""}`}> </i> </div>
                                                        </div>
                                                    </Col>
                                                })
                                            }

                                        </Row>
                                    </div>
                                </div>
                            })
                        }


                        <DEModal
                            isShowModal={this.state.isShowModal}
                            title={`API integrated systems`}
                            // ${this.state.selectedData.title} 
                            close={() => {
                                this.setState({ isShowModal: !this.state.isShowModal })
                                let some = {
                                    pixelName: this.state.pixelName,
                                    pixelId: this.state.pixelId,
                                    id: this.state.selectedData.id,
                                    groupId: this.state.selectedData.groupId,
                                    img: this.state.selectedData.img
                                }

                                let newArry = [...this.state.integratedSystem]
                                newArry.push(some)
                                this.setState({ integratedSystem: newArry, pixelName: "", pixelId: "" })

                            }} primaryTitle="Connect">

                            <this.SocialChild />

                        </DEModal>

                    </div>
                </div>

            </Container>
        </LayoutPageWrapper>
    }


}

export default withRouter(DataExchange);

const DECardView = (props) => {
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
    const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleViewClose = () => setView(false);
    const handleViewShow = () => setView(true);

    return (
        <>
            {props.item.remoteDataSourceID === 40 ? (
                <div className="data-card-view">
                    <ul className="api-setting">
                        <li>
                            <RSPTooltip text="Promo code" position="top">
                                <i
                                    className="icon-sd-coupon-mini icons-sm blue cp"
                                    onClick={() => {
                                        setIsOpenCouponModal(true)
                                    }}
                                />
                            </RSPTooltip>
                        </li>
                    </ul>
                    <figure>
                        <img src={images.versium} alt={"Versium"} />
                    </figure>
                    <div className="box-sm">
                        <small>APIs URL: {props.item.fileLocation}</small>
                    </div>
                    <div>
                        {parseInt(props.item.limitsAvailable, 10) > 0 &&
                            <small>{props.item.limitsAvailable + " credits free until"}</small>}
                            <br/>
                        <small>{props.item.expiryDate}</small>
                    </div>
                </div>
            ) : props.item.remoteDataSourceID === 8 ? (
                <div className="data-card-view">
                    <ul className="api-setting">
                        <li>
                            <RSPTooltip text="Edit" position="top">
                                <i
                                    className="icon-sd-pencil-edit-mini icons-sm blue cp"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: `/audience/add-audience`,
                                            state: {
                                                mode: "edit",
                                                audienceType: "rds",
                                                rdsId: props.item.remoteSettingID,
                                            },
                                        });
                                    }}
                                />
                            </RSPTooltip>
                        </li>
                    </ul>
                    <figure>
                        <img src={images.sftp} alt={images.sftp} />
                    </figure>
                    <div className="box-sm">
                        <small>
                            APIs URL: {props.item.ipAddress};{props.item.port}
                        </small>
                    </div>
                </div>
            ) : (<div className="data-card-view">
                <ul className="api-setting">
                    <li>
                        <RSPTooltip text="Edit" position="top">
                            <i
                                className="icon-sd-pencil-edit-mini icons-sm blue cp"
                                onClick={() => {
                                    props.history.push({
                                        pathname: `/preferences/dynamic-crm`,
                                        state: {
                                            mode: "edit",
                                            id: props.item.remoteSettingID,
                                        },
                                    });
                                }}
                            />
                        </RSPTooltip>
                    </li>
                </ul>
                <figure>
                    <img src={images.DynamicCRM} alt={"Dyncmic CRM"} />
                </figure>
                <div className="box-sm">
                    <small>
                        APIs URL: {props.item.friendlyName}
                    </small>
                </div>
            </div>)}

            {/* Info */}
            <DEBootstrapModal
                isShowModal={view}
                title="Domain verification"
                close={handleViewClose}
            >
                <p className="text-center">Domain not verified!</p>
                <div className="progress-style1">
                    <div className="progress-bar"></div>
                </div>
                <p className="marginB7">Validate the following:</p>
                <p className="marginB7">Domain should be active (up and running)</p>
                <p className="marginB0">
                    Tracking scripts not updated on site properly
                </p>
            </DEBootstrapModal>

            {/* View */}
            <DEBootstrapModal
                isShowModal={show}
                title="Generated code"
                close={handleClose}
                primaryTitle="Copy"
            >
                <textarea
                    value="
                            <a title='Real Time Web Analytics' href='http://clicky.com/100642641'><img alt='Real Time Web Analytics' src='//static.getclicky.com/media/links/badge.gif' border='0'></a>
                            <script src='//static.getclicky.com/js'></script>
                            <script>
                                try {
                                    clicky.init(100642641);
                                }
                                catch (e) {}
                            </script>
                            <noscript><img alt='Clicky' width='1' height='1' src='//in.getclicky.com/100642641ns.gif'></noscript>
                        "
                ></textarea>
            </DEBootstrapModal>
            {isOpenCouponModal &&
                <PromoCodeModal
                    isOpen={isOpenCouponModal}
                    onChangeOpen={(value) => {
                        if (value) {
                            props.onRefresh();
                        }
                        setIsOpenCouponModal(false)
                    }} />}
        </>
    );
};

const DEBootstrapModal = props => {

    return (
        <>
            <Modal
                show={props.isShowModal}
                onHide={props.close}
                backdrop="static"
                keyboard={false}
                size={props.size}
                centered
            >
                <Modal.Header closeButton>
                    <h2>{props.title}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {props.children}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-container d-flex justify-content-end">
                        {/* {props.secondaryTitle && <RSSecondaryBtn onClick={() => props.close()}>{props.secondaryTitle}</RSSecondaryBtn>} */}
                        {props.primaryTitle && <RSPrimaryBtn onClick={() => props.close()}>{props.primaryTitle}</RSPrimaryBtn>}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const DEModal = props => {

    return (
        <>
            {/* Add */}
            <Modal
                show={props.isShowModal}
                onHide={() => props.close()}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <h2>{props.title}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {props.children}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-container d-flex justify-content-end">
                        {props.primaryTitle && <RSPrimaryBtn onClick={() => props.close()}>{props.primaryTitle}</RSPrimaryBtn>}
                        {props.secondaryTitle && <RSSecondaryBtn onClick={() => props.close()}>{props.secondaryTitle}</RSSecondaryBtn>}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}


const tabData = [
    { "id": "all", "text": "All", iconLeft: 'icon-sd-grid-large icons-lg' },
    // { "id": "eCommerce", "text": "E-commerce", iconLeft: 'icon-cart-large icons-lg' },
    { "id": "remoteDataSource", "text": "Remote data source", iconLeft: 'icon-sd-data-large  icons-lg' },
    { "id": "dataService", "text": "Data Services", iconLeft: 'icon-asterisk-small  icons-lg' },
    // { "id": "mobileApp", "text": "Mobile app", iconLeft: 'icon-odbc-medium  icons-lg' }
]


const json = {
    // eCommerce: {
    //     title: "E-commerce",
    //     data: [
    //         { title: "Shopify", img: logoShopify, cls: "", id: "Shopify", groupId: "eCommerce" },
    //         { title: "Magento", img: logoMagento, cls: "", id: "Magento", groupId: "eCommerce" },
    //         { title: "POS", cls: "", id: "POS", groupId: "eCommerce" },
    //     ]
    // },
    remoteDataSource: {
        title: "Remote data source",
        data: [
            { title: "SFTP", image: images.sftp, cls: "", id: "ftp_sftp", groupId: "remoteDataSource" },
        ]
    },
    dataService: {
        title: "Data Services",
        data: [
            { title: "Versium", image: images.versium, cls: "", id: "versium", groupId: "Data Services" }
            // { title: "Dynamic CRM", cls: "", id: "d_crm", groupId: "remoteDataSource" }
        ]
    },
    // mobileApp: {
    //     title: "Mobile app",
    //     data: [
    //         { title: "Mobile app", img: logoMobile, cls: "", id: "mobileApp", groupId: "mobileApp" }
    //     ]
    // },
}
