import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import authContext from 'Helper/StateHandler/auth-context';
import { getCampaignMasterData, getEmailAudienceList, getLanguageData, getMobileAudienceList } from 'Helper/Utils/Utils';

const LaunchPad = props => {
    const history = useHistory()
    const context = useContext(authContext);
    useEffect(() => {
        getCampaignMasterData(context)
        getLanguageData(context);
        getEmailAudienceList(context);
        getMobileAudienceList(context);
    }, [])

    return (
        <div>
            {/* content */}
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <Row>
                        <Col>
                            <h1 className="main-title">Manage all your communications with a click</h1>
                            <div className="launchpad-container portlet-box-theme bgWhite">
                                <div className="launchpad-list d-flex">
                                    <div className="mg-camp cp" onClick={() => { history.push({ pathname: `/audience/add-audience`, state: { mode: 'add' } }) }}>
                                        <i className="icon-sd-users-large mg-icon-size blue"></i>
                                        <div className="clear link-text mb0">Import audience</div>
                                    </div>
                                    <div className="mg-camp cp" onClick={() => { history.push({ pathname: `communication/planning`, state: { mode: "add" } }) }}>
                                        <i className="icon-sd-communication-plus-large blue mg-icon-size"></i>
                                        <div className="clear link-text mb0">Create a communication</div>
                                    </div>
                                    <div className="mg-camp cp" onClick={() => { history.push('/analytics') }}>
                                        <i className="icon-sd-analytics-settings-large blue mg-icon-size"></i>
                                        <div className="clear link-text mb0">View analytics</div>
                                    </div>
                                </div>
                                <div className="text-center mb30">
                                    <h4>Before uploading your first audience list, here are some things you might want to explore first!
                                        {/* <button type="button" className="ml15 res-mart btn btn-primary" onClick={() => { history.push('/preferences/communication-settings') }}>Generate</button> */}
                                    </h4>
                                </div>
                                <div className="launchpad-list light-launchpad">
                                    <div className="manage-camp mb30">
                                        <div className="mg-camp bottom-box" onClick={() => { history.push('/preferences/data-exchange') }}>
                                            <div className="bottom-inner-box">
                                                <i className="icon-sd-data-exchange-large blue "></i>
                                                <div className="clear link-text mb0">Data exchange</div>
                                            </div>
                                        </div>
                                        <div className="mg-camp bottom-box" onClick={() => { history.push('/preferences/form-generator') }}>
                                            <div className="bottom-inner-box">
                                                <i className="icon-sd-form-generator-large blue "></i>
                                                <div className="clear link-text mb0">Form builder</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="text-center mb30">
                                    <h4>Before Import, things you may consider to configure!
                                        <button type="button" className="ml15 res-mart btn btn-primary" onClick={() => { history.push('/preferences/communication-settings') }}>Generate</button>
                                    </h4>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </LayoutPageWrapper>
        </div>
    );
};

export default LaunchPad;