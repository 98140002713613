import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Switch } from "@progress/kendo-react-inputs";
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RSPTooltip } from 'Components/Tooltip';
import RESTextEditor from 'Components/Editor/RESTextEditor';
import { connectServer } from "Helper/Network/networkHandler";
import { GET_UNSUBSCRIPTION_LIST, GET_FOOTER_LIST, GET_FOOTER_BY_ID } from "Helper/Constants/endPoint";
import { BootstrapDropdownIcon } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { BODY, UN_SUBSCRIPTION } from 'Helper/Constants/validationMessage';
import ApprovalSettings from './ApprovalSettings';
import ImportTabContent from './ImportTabContent';
import EmailPreviewModal from './EmailPreviewModal';
import authContext from 'Helper/StateHandler/auth-context';
import { SuccessModal } from './SuccessModal';
import Template from './Template';
import parse from 'html-react-parser';

class DefaultContent extends Component {

    static contextType = authContext

    state = {
        isOpenLiveSuccessModal: false,
        headerMsg: this.props.emailData.content[0].preHeaderMessage,
        isAddViewBrowser: this.props.emailData.content[0].isViewinBrowserChecked,
        sendTestMailArray: [],
        isRequestForApproval: this.props.emailData.isRequestForApprovalEnabled,
        isModalOpen: false,
        errBody: this.props.defaultError.errBodyMsg,
        errSendTestMailMsg: this.props.defaultError.errSendTestMailMsg,
        errEnableUnSubMsg: this.props.defaultError.errEnableUnSubMsg,
        errUnSubMsg: this.props.defaultError.errUnSubMsg,
        errImportContent: this.props.defaultError.errImportContent,
        errTemplateContent: this.props.defaultError.errTemplateContent,
        checked: this.props.emailData.content[0].isUnsubscribeEnabled,
        unsubscriptionList: [],
        unsubscription: {
            unsubscribeSettingId: 0,
            unsubscribeName: '-- Select --'
        },
        isAddFooter: this.props.emailData.content[0].isFooterEnabled,
        emailFooterList: [],
        emailFooter: null,
        emailFooterHtml: "",
        isEmailPreviewModal: false,
        isShowFooterComponent: this.props.emailData.content[0].body !== "" ? true : false,
        approvalList: [
            {
                approverName: "",
                ismandatory: false
            }
        ],
        approvalSettings: {
            approvalMatchType: "All",
            approvalMatchCount: 0,
            isFollowHierarchyEnabled: false,
        },
    }

    componentDidMount = () => {
        this.getUnsubscriptionList()
        this.getEmailFooterList()

        this.getIframeHeight()
        // setTimeout(() => {
        //     this.getIframeHeight()
        // }, 5000)

        // console.log("RER:", this.state.emailFooterHtml)
        // console.log("RER c:", this.state.isAddFooter)
    }

    componentDidUpdate = (prevProps) => {
        this.getIframeHeight()
        // setTimeout(() => {
        //     this.getIframeHeight()
        // }, 5000)
        if (prevProps !== this.props) {
            this.setState({
                errBody: this.props.defaultError.errBodyMsg,
                errSendTestMailMsg: this.props.defaultError.errSendTestMailMsg,
                errEnableUnSubMsg: this.props.defaultError.errEnableUnSubMsg,
                errUnSubMsg: this.props.defaultError.errUnSubMsg,
                errImportContent: this.props.defaultError.errImportContent,
                errTemplateContent: this.props.defaultError.errTemplateContent,
                headerMsg: this.props.emailData.content[0].preHeaderMessage,
                isAddViewBrowser: this.props.emailData.content[0].isViewinBrowserChecked,
                isRequestForApproval: this.props.emailData.isRequestForApprovalEnabled,
                isShowFooterComponent: this.props.emailData.content[0].body !== "" ? true : false,
                isAddFooter: this.props.emailData.content[0].isFooterEnabled,
                checked: this.props.emailData.content[0].isUnsubscribeEnabled,
                unsubscription: this.props.emailData.content[0].unsubscribeSettingId !== 0 ? this.state.unsubscriptionList.filter(item => item.unsubscribeSettingId === this.props.emailData.content[0].unsubscribeSettingId)[0] : {
                    unsubscribeSettingId: 0,
                    unsubscribeName: '-- Select --'
                }
            })
        }
    }

    getIframeHeight = () => {
        let iframe = document.getElementById("footerFrame");

        if (iframe !== null) {
            // Get the height of the content inside the iframe
            let contentHeight = iframe?.contentWindow?.document?.body?.scrollHeight;

            // Set the height of the iframe to match the content height
            iframe.style.height = contentHeight + 25 + "px";
        }
    }

    getUnsubscriptionList = () => {
        const params = {}
        connectServer({
            path: GET_UNSUBSCRIPTION_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let unsubscribe = res?.data?.filter(item => item.unsubscribeSettingId === this.props.emailData.content[0].unsubscribeSettingId)[0];
                    this.setState({ unsubscriptionList: res.data || [], unsubscription: unsubscribe })

                }
            },
            fail: err => {
            }
        })
    }

    getEmailFooterList = () => {
        const params = {}
        connectServer({
            path: GET_FOOTER_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.getEmailFooterById(this.props.emailData.content[0].footerId !== 0 ? this.props.emailData.content[0].footerId : res.data.emailfooterList[0].emailfooterID)
                    this.setState({ emailFooterList: res.data.emailfooterList || [] })
                }
            },
            fail: err => {
            }
        })
    }
    getEmailFooterById = (footerId) => {
        const params = {
            "emailFooterID": footerId
        }
        connectServer({
            path: GET_FOOTER_BY_ID,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let footerRawHtml = res.data.emailFooterRawcode
                    this.props.getEmailFooterContent(footerRawHtml);
                    this.setState({ emailFooterHtml: footerRawHtml || '' })
                    setTimeout(() => {
                        this.getIframeHeight()
                    }, 2000)
                }
            },
            fail: err => {
            }
        })
    }

    render() {

        return (
            <div className="marginT23">
                {/* <Row className="custom-column marginT10">
                    <Col md={12}> */}
                {
                    this.props.emailData.content[0].contentType === "R" &&
                    <>
                        <Row className={`custom-column mt30 mb15 ${this.props.editClickOff ? "click-off" : ""}`}>
                            <Col md={6}>
                                {this.state.isAddViewBrowser &&
                                    <p className='float-start'>Email not displaying correctly?
                                        <a href='javascript:;' className='paddingL10'>View in browser</a>
                                    </p>
                                }
                            </Col>
                            <Col md={6} >
                                <RSCheckbox
                                    className="mb0 float-end"
                                    lbl="Add view in browser"
                                    checked={this.state.isAddViewBrowser}
                                    cb={(status) => {
                                        this.props.emailData.content[0].isViewinBrowserChecked = status;
                                        this.setState({ isAddViewBrowser: status });
                                    }} />
                            </Col>
                        </Row>
                    </>
                    // : this.props.emailData.content[0].contentType !== "" &&
                    // <>
                    //     <Row className="custom-column mt30">
                    //         <Col md={3}>
                    //             <label className="label-row">Preheader message</label>
                    //         </Col>
                    //         <Col md={6}>
                    //             <RSInput2
                    //                 ph="Enter inbox first line message"
                    //                 val={this.state.headerMsg}
                    //                 cb={(text) => {
                    //                     this.props.emailData.content[0].preHeaderMessage = text;
                    //                     this.setState({ headerMsg: text });
                    //                 }}
                    //             />
                    //         </Col>
                    //         <Col md={4}>
                    //             <RSCheckbox
                    //                 className="mb0 mt10"
                    //                 lbl="Add view in browser"
                    //                 checked={this.state.isAddViewBrowser}
                    //                 cb={(status) => {
                    //                     this.props.emailData.content[0].isViewinBrowserChecked = status;
                    //                     this.setState({ isAddViewBrowser: status });
                    //                 }} />
                    //         </Col>
                    //     </Row>
                    // </>
                }
                {/* {(this.props.emailData.content[0].contentType !== "" && this.props.emailData.content[0].contentType !== "R" && this.state.isAddViewBrowser) &&
                    <Row className="custom-column mt30">
                        <Col md={6}>
                            {this.state.isAddViewBrowser &&
                                <p className='float-start'>Email not displaying correctly?
                                    <a href=''>View in browser</a>
                                </p>
                            }
                        </Col>
                    </Row>
                } */}
                {this.props.emailData.content[0].contentType === "R" ?
                    <div className={`position-relative editor-view ${this.props.editClickOff ? "click-off" : ""}`}>
                        {this.state.errBody && (
                            <div className="validation-message switch-valid">
                                {this.state.errBody}
                            </div>
                        )}
                        <RESTextEditor
                            personalizeTabData={this.props.personalizeTabData}
                            content={this.props.emailData.content[0].body}
                            callBack={(text, richText) => {
                                let errMsg = text === "" ? BODY : null;
                                this.props.getDefaultContentError("errBodyMsg", errMsg)
                                this.setState({ errBody: errMsg });
                                this.props.emailData.content[0].body = richText;
                                this.props.emailData.content[0].textContent = text;
                            }}
                            getErrorMessage={(errorMessage) => {
                                this.props.getDefaultContentError("errBodyMsg", errorMessage)
                                this.setState({ errBody: errorMessage });
                            }}
                        />
                    </div> : this.props.emailData.content[0].contentType === "Z" ?
                        <div className="position-relative">
                            {this.state.errImportContent && (
                                <div className="validation-message bottomBB10">
                                    {this.state.errImportContent}
                                </div>
                            )}
                            <ImportTabContent
                                emailData={this.props.emailData}
                                contentData={this.props.contentData}
                                defaultError={this.props.defaultError}
                                getBodyContent={(bodyContent) => {
                                    this.props.emailData.content[0].body = bodyContent;
                                    if (this.props.emailData.content[0].body === null) {
                                        this.props.getDefaultContentError("errImportContent", "Invalid file upload");
                                        this.setState({ errImportContent: 'Invalid file upload' });
                                    } else {
                                        this.props.getDefaultContentError("errImportContent", null);
                                        this.props.getDefaultContentError("errBodyMsg", null);
                                        this.setState({ errBody: null, errImportContent: null });
                                    }
                                    // this.props.emailData.content[0].textContent = bodyContent;
                                }}
                                onValidateSearch={(status) => this.props.onValidateSearch(status)}
                                isSearchValidate={this.props.isSearchValidate}
                                onShowFooter={(value) => { this.setState({ isShowFooterComponent: value }) }}
                                onValidateSpamCheck={(status) => this.props.onValidateSpamCheck(status)}
                                isSpamCheckValidate={this.props.isSpamCheckValidate}
                                emailfooterContent={this.state.emailFooterHtml}
                            />
                        </div> : this.props.emailData.content[0].contentType === "T" ?
                            <div className={`position-relative`}>
                                {this.state.errTemplateContent && (
                                    <div className="validation-message top-30">
                                        {this.state.errTemplateContent}
                                    </div>
                                )}
                                <Template
                                    segList={this.props.segList}
                                    validation={this.props.validation}
                                    emailData={this.props.emailData}
                                    campaignId={this.props.campaignId}
                                    isUpdate={this.props.isUpdate}
                                    edmChannelId={this.props.edmChannelId}
                                    activeDomain={this.props.activeDomain}
                                    senderEmailAddress={this.props.senderEmailAddress}
                                    activeReplyDomain={this.props.activeReplyDomain}
                                    alternateEmailAddress={this.props.alternateEmailAddress}
                                    isReplyMail={this.props.isReplyMail}
                                    editClickOff={this.props.editClickOff}
                                />
                            </div> : null
                }
                {/* {
                    this.state.isAddFooter && (
                        <div className="form-group">
                            <div className="d-flex justify-content-center pe-none rs-auth-footer-holder">
                                {parse(this.state.emailFooterHtml)}
                            </div>
                        </div>
                    )} */}
                {
                    this.state.isAddFooter &&
                    <div className={`mt20 mb30`}>
                        <iframe
                            id='footerFrame'
                            title='footerFrame'
                            className='test'
                            srcDoc={this.state.emailFooterHtml + "<style>body{pointer-events: none;}</style>"}
                            style={{ width: "100%" }}
                        ></iframe>
                    </div>
                }
                {
                    (this.props.emailData.content[0].contentType === "R" || this.state.isShowFooterComponent)
                        ?
                        <Row className={`custom-column mt30 mb30 d-flex align-items-center ${this.props.editClickOff ? "click-off" : ""}`}>
                            <Col md={4}>
                                <RSPTooltip text="Unsubscription message" position="top">
                                    <label className="label-row truncate-line-1 top3 posr">Unsubscription message</label>
                                </RSPTooltip>
                            </Col>
                            <Col md={1} >
                                <div className="position-relative">
                                    {this.state.errEnableUnSubMsg && (
                                        <div className="validation-message switch-valid wsp">
                                            {this.state.errEnableUnSubMsg}
                                        </div>
                                    )}
                                    <Switch
                                        className="mt0 mr15"
                                        checked={this.state.checked}
                                        onChange={(e) => {
                                            this.props.getDefaultContentError("errEnableUnSubMsg", null);
                                            this.props.emailData.content[0].isUnsubscribeEnabled = e.value;
                                            this.setState({
                                                checked: e.value, errEnableUnSubMsg: null, unsubscription: {
                                                    unsubscribeSettingId: 0,
                                                    unsubscribeName: '-- Select --'
                                                }
                                            })
                                            if (this.state.isAddFooter && !e.value) {
                                                this.props.emailData.content[0].unsubscribeSettingId = 0;
                                                this.props.getDefaultContentError("errEnableUnSubMsg", "Enable unsubscribe");
                                                this.setState({
                                                    checked: e.value,
                                                    errEnableUnSubMsg: "Enable unsubscribe",
                                                    unsubscription: {
                                                        unsubscribeSettingId: 0,
                                                        unsubscribeName: '-- Select --'
                                                    }
                                                })
                                            } else {
                                                this.props.emailData.content[0].unsubscribeSettingId = 0;
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={3} >
                                {this.state.checked && this.state.unsubscriptionList && (
                                    <div className="position-relative top-3">
                                        {this.state.errUnSubMsg && (
                                            <div className="validation-message top-auto transY-5">
                                                {this.state.errUnSubMsg}
                                            </div>
                                        )}
                                        <RSDropdownList
                                            className="mb0 required"
                                            value={this.state.unsubscription}
                                            data={this.state.unsubscriptionList || []}
                                            defaultItem={{
                                                unsubscribeSettingId: 0,
                                                unsubscribeName: '-- Select --'
                                            }}
                                            textField='unsubscribeName'
                                            dataItemKey='unsubscribeSettingId'
                                            onChange={(event) => {
                                                this.props.emailData.content[0].unsubscribeSettingId = event.target.value.unsubscribeSettingId;
                                                let errMsg = event.target.value.unsubscribeSettingId !== 0 ? null : "Select unsubscribe";
                                                this.props.getDefaultContentError("errUnSubMsg", errMsg);
                                                this.setState({ unsubscription: event.target.value, errUnSubMsg: errMsg })
                                            }}
                                        /></div>)
                                }
                            </Col>
                            <Col className="padding0">
                                <div className="d-flex justify-content-end">
                                    <ul className="d-flex  align-items-center justify-content-center">
                                        <li>
                                            <RSCheckbox
                                                className="mt0 mb0"
                                                lbl="Add email footer"
                                                checked={this.state.isAddFooter}
                                                cb={(status) => {
                                                    let errEnableUnSubMsg = (status && !this.state.checked) ? "Enable unsubscribe" : null;
                                                    this.props.getDefaultContentError("errEnableUnSubMsg", errEnableUnSubMsg);
                                                    this.setState({ errEnableUnSubMsg: errEnableUnSubMsg })
                                                    if (status) {
                                                        this.props.emailData.content[0].isFooterEnabled = status;
                                                        this.props.emailData.content[0].footerId = this.state.emailFooterList.length > 0 ? this.state.emailFooterList[0].emailfooterID : 0;
                                                        this.getEmailFooterById(this.state.emailFooterList.length > 0 ? this.state.emailFooterList[0].emailfooterID : 0)
                                                        this.setState({ isAddFooter: status });
                                                        try {
                                                            setTimeout(() => {
                                                                // Get the height of the content inside the iframe
                                                                let iframe = document.getElementById("footerFrame");
                                                                let contentHeight = iframe?.contentWindow?.document?.body?.scrollHeight;

                                                                // Set the height of the iframe to match the content height
                                                                iframe.style.height = contentHeight + 25 + "px";
                                                            }, 2000)
                                                        } catch (error) {
                                                        }

                                                    } else {
                                                        this.props.emailData.content[0].isFooterEnabled = status;
                                                        this.props.emailData.content[0].footerId = 0;
                                                        this.props.getEmailFooterContent("")
                                                        this.setState({ isAddFooter: status, emailFooterHtml: "" })
                                                    }
                                                }}
                                            />
                                        </li>
                                        <li className="d-flex justify-content-center cp" onClick={() => {
                                            this.props.onChangeIsSearchValidate(false);
                                            this.setState({ isEmailPreviewModal: !this.state.isEmailPreviewModal })
                                        }}>
                                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;Email preview</span>
                                            {/* <RSPTooltip text="Email preview" position="top"> */}
                                            <i className="icon-sd-email-preview-medium icons-md blue ml5 mr15 cp posr top-2" />
                                            {/* </RSPTooltip> */}
                                        </li>
                                        {this.state.isAddFooter &&
                                            <>
                                                <li>
                                                    <RSPTooltip text="Email footer template" position="top">
                                                        <div>
                                                            {this.state.emailFooterList &&
                                                                <BootstrapDropdownIcon
                                                                    data={this.state.emailFooterList}
                                                                    defaultItem={this.state.emailFooter}
                                                                    customAlignRight={true}
                                                                    className="fs18-btn mr15"
                                                                    alignRight
                                                                    fieldKey={'footername'}
                                                                    fixedIcon={<i className="icon-sd-email-footer-medium icons-md blue posr t2" />}
                                                                    onSelect={(item, index) => {
                                                                        this.props.emailData.content[0].footerId = item.emailfooterID;
                                                                        this.getEmailFooterById(item.emailfooterID)
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    </RSPTooltip>
                                                </li>
                                                <li>
                                                    <RSPTooltip text="Create email footer" position="top">
                                                        <i className="icon-sd-circle-plus-fill-edge-medium icons-md blue posr t2 cp click-off"
                                                            onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: '/preferences/communication-settings',
                                                                    state: { index: 2 }
                                                                })
                                                            }}
                                                        />
                                                    </RSPTooltip>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </Col>
                        </Row> : null
                }

                {
                    this.state.isEmailPreviewModal &&
                    <EmailPreviewModal
                        isOpen={this.state.isEmailPreviewModal}
                        previewContent={this.props.emailData.content[0]}
                        emailData={this.props.emailData}
                        campaignId={this.props.campaignId}
                        edmChannelId={this.props.edmChannelId}
                        activeDomain={this.props.activeDomain}
                        senderEmailAddress={this.props.senderEmailAddress}
                        activeReplyDomain={this.props.activeReplyDomain}
                        alternateEmailAddress={this.props.alternateEmailAddress}
                        isReplyMail={this.props.isReplyMail}
                        footerContent={!!this.state.emailFooterHtml ? this.state.emailFooterHtml : ""}
                        onChangeIsOpen={(value) => {
                            if (value) {
                                this.setState({ isOpenLiveSuccessModal: true, isEmailPreviewModal: false });
                            } else {
                                this.setState({ isEmailPreviewModal: false });
                            }
                        }}
                        getEdmChannelId={(value) => {
                            this.props.getEdmChannelId(value);
                        }}
                        onValidateSearch={(status) => this.props.onValidateSearch(status)}
                        isSearchValidate={this.props.isSearchValidate}
                    />
                }
                {/* <Row className="custom-column">
                    <Col md={3}>
                        <label className="label-row">Send test mail to</label>
                    </Col>
                    <Col md={6}>
                        {this.state.approvalList.map((item, index) => {
                            return (
                                <div className="position-relative">
                                    <RSDropdownList
                                        className="mb10"
                                        data={approverList}
                                        value={item.approverName !== "" ? item.approverName : "-- Select --"}
                                        onChange={(event) => {
                                            let temp = [...this.state.approvalList];
                                            temp[index].approverName = event.target.value;
                                            this.setState({ approvalList: temp })
                                        }} />
                                </div>);
                        })}
                        <div className="d-flex align-items-center justify-content-between mb20">
                            <div className="d-flex">
                                <RSCheckbox
                                    className="marginB0"
                                    lbl="Request approval"
                                    checked={this.state.isRequestForApproval}
                                    cb={(status) => {
                                        this.setState({ isRequestForApproval: !this.state.isRequestForApproval });
                                    }}
                                />
                                <RSPPophover text="Get the list approved by the concerned person." position="top">
                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp"></i>
                                </RSPPophover>
                            </div>
                            {<RSPTooltip position="top" text="Approval settings">
                                <i className={`icon-settings-small icons-md blue cursor-pointer mr0 ${this.state.isRequestForApproval ? "" :"click-off"}`} onClick={() => {
                                    this.setState({ isModalOpen: !this.state.isModalOpen });
                                }} ></i></RSPTooltip>}
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="d-flex">
                            {this.state.approvalList.map((item, index) => {
                                return(
                                <i className={`icons-md blue cp mt10 mr15 d-block ${this.state.isRequestForApproval ? "" : "click-off"} 
                                ${index === 0
                                    ? `${this.state.approvalList.length < 3
                                        ? "icon-sd-circle-plus-fill-edge-medium "
                                        : "icon-sd-circle-plus-fill-edge-medium click-off "}`
                                    : "icon-sd-circle-minus-fill-edge-medium red"}`} onClick={() => {
                                        if (index === 0 && this.state.approvalList.length < 3) {
                                            let temp = [...this.state.approvalList];
                                            temp.push({
                                                approverName: "",
                                                ismandatory: false
                                            });
                                            this.setState({ approvalList: temp });
                                        } else {
                                            var index1 = this.state.approvalList.indexOf(item);
                                            var tempList = [...this.state.approvalList];
                                            tempList.splice(index1, 1);
                                            this.setState({ approvalList: tempList });
                                        }
                                    }} />)
                            })}
                            <RSPrimaryBtn onClick={() => { }}>Send</RSPrimaryBtn>
                        </div>
                    </Col>
                </Row> */}
                {
                    this.state.isModalOpen && <ApprovalSettings
                        isOpen={this.state.isModalOpen}
                        approvalSettings={this.state.approvalSettings}
                        onOpenChange={(value) => {
                            this.setState({ isModalOpen: value });
                        }}></ApprovalSettings>
                }
                {
                    this.state.isOpenLiveSuccessModal &&
                    <SuccessModal isOpen={this.state.isOpenLiveSuccessModal} title={"Success"} subTitle={"Live communication will be sent shortly."} buttonClicked={(value) => {
                        this.setState({ isOpenLiveSuccessModal: false })
                    }} />
                }


            </div >
        );
    }
}

export default withRouter(DefaultContent);