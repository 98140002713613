import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getFileToBase64 } from "Helper/Utils/Utils";
import * as icons from "Constants/GlobalConstant/Glyphicons/Glyphicons-v5.0";
import { RSPTooltip } from "Components/Tooltip";

export const RSUploadImageComponent = ({
    props,
    className,
    newImg,
    defaultImg,
    defaultValue,
    alt,
    tooltipText,
    error,
    errorMessage,
    cb,
    text,
    icon,
    note,
    name,
    required,
    setValue,
    removeIcon,
    handleRemove,
    getImageType
}) => {
    const [uploadImg, setUploadImg] = useState(defaultValue || null);
    const [invalidImg, setInvalidImg] = useState(false);
    const [filetype, setFileType] = useState("");
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setUploadImg(defaultValue);
    }, [defaultValue]);


    return (
        <div className="profile-image-wrapper">
            <div
                className={`rs-picture ${errorMessage ? "errorContainer" : ""} ${className ? className : ""
                    } ${required ? "required" : "optional"}`}
            >
                <figure className="">
                    <img
                        // src={newImg !== null ? newImg : defaultValue !== null
                        //     ? `data:image/svg;base64,${uploadImg}`
                        //     : `${defaultImg}`}
                        src={
                            newImg !== null
                                ? newImg
                                : defaultValue !== null
                                    ? `data:${filetype};base64,${uploadImg}`
                                    : `${defaultImg}`
                        }
                        alt={alt || "profile"}
                    />
                </figure>

                <div className="picture-choose-file">
                    <span className="info">
                        <RSPTooltip
                            text={
                                defaultValue !== null ? `Update ${tooltipText}` : `Add ${tooltipText}`
                            }
                            position="top"
                            show={isShow}
                        >
                            <div
                                onMouseEnter={() => { setIsShow(true) }}
                                onMouseLeave={() => { setIsShow(false) }}
                                onClick={() => {
                                    setIsShow(false)
                                }}>
                                <input
                                    type="file"
                                    accept=".jpg,.png,.jpeg"
                                    title=""
                                    onChange={(e) => {
                                        setIsShow(false)
                                        const imgFile = e.target.files[0];
                                        const fileSize = Math.round(imgFile.size / 1024);
                                        const imagePath = imgFile.name
                                            .split(".")
                                            .pop()
                                            .toLowerCase();
                                        setFileType(imgFile.type);
                                        if (
                                            !(
                                                imagePath === "jpg"
                                                || imagePath === "jpeg"
                                                || imagePath === "png"
                                                // || imagePath === "svg"
                                            )
                                        ) {
                                            error && error("Invalid image format");
                                            setTimeout(() => {
                                                error && error(null);
                                            }, 3000);
                                            e.target.value = null; // Clear the file input value
                                            return;
                                        } else if (fileSize > 500) {
                                            error && error("Image size exceeds 500 Kb");
                                            setTimeout(() => {
                                                error && error(null);
                                            }, 3000);
                                            e.target.value = null;
                                            return;
                                        }
                                        const reader = new FileReader();
                                        reader.readAsDataURL(imgFile);
                                        reader.onload = (e) => {
                                            const image = new Image();
                                            image.src = e.target.result;
                                            image.onload = (e) => {
                                                getFileToBase64(
                                                    imgFile,
                                                    (data) => {
                                                        setUploadImg(data);
                                                        cb && cb(data);
                                                        getImageType && getImageType("." + imagePath)
                                                    },
                                                    (err) => {
                                                        // console.log("image to base64 err: ", err);
                                                    }
                                                );

                                                return;
                                            };
                                        };
                                        e.target.value = null;
                                    }}
                                    className="btn"
                                    name="uploaded_file"
                                />
                            </div>
                        </RSPTooltip>
                        {removeIcon ? (
                            <>
                                <span className="pcf-remove-icon">
                                    <RSPTooltip text={`Remove ${tooltipText}`} position="top">
                                        <i
                                            className={`${icons.circle_minus_fill_medium} color-primary-red icons-md`}
                                            onClick={(e) => {
                                                handleRemove();
                                            }}
                                        ></i>
                                    </RSPTooltip>
                                </span>{" "}
                                <i className={`${icons.circle_pencil_medium} blue icons-md`} />
                            </>
                        ) : (
                            <i className={`${icons.circle_plus_fill_medium} blue icons-md`} />
                        )}
                        <span className="pcf-label">{text}</span>
                    </span>
                </div>
                {errorMessage && (
                    <div className="validation-message">{errorMessage}</div>
                )}
            </div>
            {note && (
                <div className="alert alert-warning d-block mt30 px5 py5 text-center">
                    <small className="font-xxs">
                        <b>Allowed formats:</b> JPG, JPEG, PNG. <br/>Max. size : 500Kb
                    </small>
                </div>
            )}
        </div>
    );
};

RSUploadImageComponent.defaultProps = {
    className: "",
    alt: "picture",
    defaultValue: null,
    newImg: null,
    tooltipText: "profile picture",
    text: "Edit profile picture",
    note: true,
    removeIcon: false,
    handleOnRemove: () => { },
};

RSUploadImageComponent.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    name: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    newImg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null, undefined]),
    ]),
    tooltipText: PropTypes.string,
    text: PropTypes.string,
    required: PropTypes.bool,
    note: PropTypes.bool,
    removeIcon: PropTypes.bool,
    handleOnRemove: PropTypes.func,
};

export default React.memo(RSUploadImageComponent);
