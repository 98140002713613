import { convertBase64ToObject, getUserData } from "Helper/Utils/Utils";
import { Buffer } from 'buffer'

export const createEmailCampaign = (type, campaignId, campaignObject, databaseName, sendToMeEmail, isSend) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    campaignObject.targetListTargetAudience.forEach((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCountEmail;
    })
    let tempContent = [...campaignObject.content];
    tempContent[0].splitType = tempContent[0].splitType || "";
    tempContent[0].preHeaderMessage = tempContent[0].preHeaderMessage || "";
    tempContent[0].contentUrl = tempContent[0].contentUrl || "";
    tempContent[0].htmlFilePath = tempContent[0].htmlFilePath || "";
    tempContent[0].zipFilePath = tempContent[0].zipFilePath || "";
    tempContent[0].timezoneId = tempContent[0].timezoneId === 0
        ? getUserData()?.clientDetailsLocalization?.timeZoneId
        : tempContent[0].timezoneId
    let params = {
        "campaignId": campaignId,
        "campaignType": type,
        "campaignGuid": campaignObject.campaignGuid,
        "levelNumber": campaignObject.levelNumber || 1,
        "databaseName": databaseName,
        "senderName": campaignObject.senderName,
        "senderEmail": campaignObject.senderEmail,
        "replyEmail": campaignObject.replyEmail,
        "isRequestForApprovalEnabled": campaignObject.isRequestForApprovalEnabled,
        "isRequestForApprovalScheduled": campaignObject.isRequestForApprovalScheduled,
        "testCampaignEmailAddress": sendToMeEmail !== "" ? sendToMeEmail : campaignObject.testCampaignEmailAddress,
        "isSendTestMailContent": isSend ? 2 : sendToMeEmail !== "" ? 4 : campaignObject.isRequestForApprovalEnabled ? 1 : 0,
        "targetListTargetAudience": tempAudienceList,
        "totalaudience": tempAudienceCount,
        "isReplyMailEnabled": campaignObject.isReplyMailEnabled || false,
        "isSplitAB": campaignObject.isSplitAB || false,
        "edmAutoSchedule": campaignObject.edmAutoSchedule,
        "edmSplit": campaignObject.edmSplit,
        "content": campaignObject.content,
        "requestForApproval": campaignObject.requestForApproval

    }
    return params;
}

export const createMobileSMSCampaign = (type, campaignId, campaignObject, isSend, lpSMSCreatedBY, lpSMSPassportID) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    campaignObject.targetList.forEach((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCountMobile;
    })
    let framedContent = [...campaignObject.content];
    framedContent.forEach((item, index) => {
        item.body = item.body.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        item.previewBody = item.previewBody.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
    })
    campaignObject.content = framedContent;
    let params = {
        "senderId": campaignObject.senderId,
        "isFlashMessageEnabled": campaignObject.isFlashMessageEnabled,
        "campaignId": campaignId,
        "smsProviderTemplateID": campaignObject.smsProviderTemplateID,
        "campaignType": type,
        "campaignGuid": campaignObject.campaignGuid,
        "testCampaignEmailAddress": "murthy.ch14@gmail.com",
        "levelNumber": campaignObject.levelNumber,
        "targetList": tempAudienceList,
        "totalaudience": tempAudienceCount,
        "languageId": campaignObject.languageId.languageId,
        "isSplitAB": campaignObject.isSplitAB,
        "isRequestForApprovalEnabled": campaignObject.isRequestForApprovalEnabled,
        "isRequestForApprovalScheduled": campaignObject.isRequestForApprovalScheduled,
        "testSmsMobileNo": campaignObject.testSmsMobileNo,
        "countryCodeMobile": campaignObject.countryCodeMobile,
        "lpSMSCreatedBY": lpSMSCreatedBY,
        "lpSMSPassportID": lpSMSPassportID,
        "isSendTestSMS": isSend ? 2 : lpSMSPassportID !== "" ? 4 : campaignObject.isRequestForApprovalEnabled ? 1 : 0,
        "isSendTimeOptEnable": false,
        "smsAutoSchedule": campaignObject.smsAutoSchedule,
        "smsSplit": campaignObject.smsSplit,
        "content": campaignObject.content,
        "requestForApproval": campaignObject.requestForApproval

    }
    return params;
}

export const createMobileWhatsAppCampaign = (type, campaignId, campaignObject, isSend, lpWACreatedBY, lpWAPassportID) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    campaignObject.recipientBunchIDs.forEach((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCountMobile;
    })
    let params = {
        "campaignId": campaignId,
        "waChannelDetailID": campaignObject.waChannelDetailID,
        "waContentType": campaignObject.waContentType,
        "clientWASettingId": campaignObject.clientWASettingId,
        "recipientBunchIDs": tempAudienceList,
        "totalRecipientWA": tempAudienceCount,
        "senderID": campaignObject.clientWASettingId,
        "isTemplateUsed": false,
        "templateType": campaignObject.templateType,
        "templateName": campaignObject.templateName,
        "templateContent": campaignObject.waContent,
        "templateNamespace": campaignObject.templateNamespace,
        "templateParameters": campaignObject.templateParameters,
        "waContent": campaignObject.waContent,
        "waPreviewContent": campaignObject.waPreviewContent,
        "languageCode": campaignObject.languageCode,
        "statusId": campaignObject.statusId,
        "tempLanguageCode": campaignObject.languageCode,
        "waTemplateID": campaignObject.waTemplateID,
        "levelNumber": 1,
        "sFrequencyCap": false,
        "splitType": campaignObject.splitType,
        "isSplitABEnabled": campaignObject.isSplitABEnabled,
        "caption": campaignObject.caption,
        "liveURL": campaignObject.liveURL,
        "waMoblieScheduleDate": campaignObject.localBlastDateTime,
        "blastDateTime": campaignObject.blastDateTime,
        "localBlastDateTime": campaignObject.localBlastDateTime,
        "timezoneID": campaignObject.timezoneID,
        "confirmationEmailAddress": campaignObject.confirmationEmailAddress,
        "waScheduleGUID": campaignObject.waScheduleGUID,
        "requestForApproval": campaignObject.requestForApproval,
        "confirmationSMS": campaignObject.confirmationSMS,
        "approvalSentTo": campaignObject.approvalSentTo,
        "approvalEmailSentTo": campaignObject.approvalEmailSentTo,
        "isApproved": campaignObject.isApproved,
        "makeChangesComments": campaignObject.makeChangesComments,
        "validationKey": campaignObject.validationKey,
        "countryID": campaignObject.countryID,
        "campaignType": type,
        "caLanguages": campaignObject.caLanguages,
        "caDayLightList": campaignObject.caDayLightList,
        "clientWappSmsSettingId": campaignObject.clientWappSmsSettingId,
        "campaignName": campaignObject.campaignName,
        "waSenderNameAttributes": campaignObject.waSenderNameAttributes,
        "waRecipientAttributes": campaignObject.waRecipientAttributes,
        "waRecipientId": campaignObject.waRecipientId,
        "domId": campaignObject.domId,
        "flowChannel": campaignObject.flowChannel,
        "isMDCNewFlow": campaignObject.isMDCNewFlow,
        "blastScheduleGuId": campaignObject.blastScheduleGuId,
        "dataSource": campaignObject.dataSource,
        "dynamiclistID": campaignObject.dynamiclistID,
        "channelFriendlyName": campaignObject.channelFriendlyName,
        "parentChannelDetailId": campaignObject.parentChannelDetailId,
        "parentChannelDetailType": campaignObject.parentChannelDetailType,
        "actionId": campaignObject.actionId,
        "actionTime": campaignObject.actionTime,
        "actionTimeDuration": campaignObject.actionTimeDuration,
        "allOrAny": campaignObject.allOrAny,
        "addOnLevel": campaignObject.addOnLevel,
        "waTempLanguages": campaignObject.waTempLanguages,
        "isSendTestWA": isSend ? 2 : lpWAPassportID !== "" ? 4 : 0,
        "testSmsMobileNo": campaignObject.testSmsMobileNo,
        "countryCodeMobile": campaignObject.countryCodeMobile,
        "originalTemplateContent": campaignObject.originalTemplateContent,
        "waImagePath": campaignObject.waImagePath,
        "waMediaURL": campaignObject.waMediaURL,
        "waMediaURLType": campaignObject.waMediaURLType,
        "thumbNailPath": campaignObject.thumbNailPath,
        "isShortenURLEnabled": campaignObject.isShortenURLEnabled,
        "lpWACreatedBY": lpWACreatedBY,
        "lpWAPassportID": lpWAPassportID

    }
    return params;
}

export const prepareEmailData = (object) => {
    const audienceList = convertBase64ToObject(localStorage.getItem('lsAudienceList'))
    let framedAudienceList = [];
    object.targetListTargetAudience?.forEach((item, index) => {
        framedAudienceList.push(audienceList.filter(audience => audience.segmentationListID === item)[0])
    })
    let framedContent = [...object.content];
    framedContent?.forEach((item, index) => {
        item.body = Buffer.from(item.body, 'base64').toString();
        item.textContent = item.body;
    })
    object.content = framedContent;
    let emailData = {
        "campaignId": object.campaignId,
        "channelstatusId": object.channelstatusId,
        "campaignType": object.campaignType,
        "levelNumber": object.levelNumber,
        "senderName": object.senderName,
        "senderEmail": object.senderEmail,
        "replyEmail": object.replyEmail,
        "testCampaignEmailAddress": object.testCampaignEmailAddress,
        "targetListTargetAudience": framedAudienceList,
        "totalaudience": object.totalaudience,
        "isReplyMailEnabled": object.isReplyMailEnabled,
        "isSplitAB": object.isSplitAB,
        "splitAB": object.splitAB,
        "edmSplit": object.edmSplit,
        "content": object.content,
        "emailSchedule": object.emailSchedule,
        "edmAutoSchedule": object.edmAutoSchedule,
        "requestForApproval": object.requestForApproval,
        "isRequestForApprovalEnabled": object.isRequestForApprovalEnabled,
        "isRequestForApprovalScheduled": object.isRequestForApprovalScheduled,
        "isSendTestMailContent": object.isSendTestMailContent,
        "isOfferEnabled": object.isOfferEnabled
    }
    return emailData;

}
export const prepareMobileSMSData = (object) => {
    const audienceList = convertBase64ToObject(localStorage.getItem('lsAudienceList'))
    const languageData = convertBase64ToObject(localStorage.getItem('lsLanguageData'))
    let framedAudienceList = [];
    object.targetList.forEach((item, index) => {
        framedAudienceList.push(audienceList.filter(audience => audience.segmentationListID === item)[0])
    })
    let smsData = {
        "campaignId": object.campaignId,
        "smsProviderTemplateID": object.smsProviderTemplateID,
        "channelstatusId": object.channelstatusId,
        "campaignType": object.campaignType,
        "levelNumber": object.levelNumber,
        "senderId": object.senderId,
        "isFlashMessageEnabled": object.isFlashMessageEnabled,
        "testCampaignEmailAddress": object.testCampaignEmailAddress,
        "targetList": framedAudienceList,
        "totalaudience": object.totalaudience,
        "languageId": languageData.filter(item => item.languageId === object.languageId)[0] || null,
        "isSplitAB": object.isSplitAB,
        "splitAB": object.splitAB,
        "testSmsMobileNo": object.testSmsMobileNo,
        "countryCodeMobile": object.countryCodeMobile,
        "isSendTestSMS": object.isSendTestSMS,
        "isSendTimeOptEnable": object.IsSendTimeOptEnable,
        "smsSplit": object.smsSplit,
        "content": object.content,
        "smsSchedule": object.smsSchedule,
        "smsAutoSchedule": object.smsAutoSchedule,
        "requestForApproval": object.requestForApproval,
        "isRequestForApprovalEnabled": object.isRequestForApprovalEnabled,
        "isRequestForApprovalScheduled": object.isRequestForApprovalScheduled,
    }
    return smsData;
}

export const prepareWhatsAppData = (object) => {
    const audienceList = convertBase64ToObject(localStorage.getItem('lsAudienceList'))
    let framedAudienceList = [];
    object.recipientBunchIDs.forEach((item, index) => {
        framedAudienceList.push(audienceList.filter(audience => audience.segmentationListID === item)[0])
    })

    let whatsAppData = {
        "campaignId": object.campaignId,
        "channelstatusId": object.channelstatusId,
        "waChannelDetailID": object.waChannelDetailID,
        "waContentType": object.waContentType,
        "clientWASettingId": object.clientWASettingId,
        "recipientBunchIDs": framedAudienceList,
        "totalRecipientWA": object.totalRecipientWA,
        "isSendTestWA": object.isSendTestWA,
        "testSmsMobileNo": object.testSmsMobileNo,
        "countryCodeMobile": object.countryCodeMobile,
        "campaignType": object.campaignType,
        "senderID": object.clientWASettingId,
        "isTemplateUsed": object.isTemplateUsed,
        "templateType": object.templateType,
        "templateName": object.templateName,
        "templateContent": object.templateContent,
        "templateNamespace": object.templateNamespace,
        "templateParameters": object.templateParameters,
        "waContent": object.waContent,
        "waPreviewContent": object.waPreviewContent,
        "languageCode": object.languageCode,
        "statusId": object.statusId,
        "tempLanguageCode": object.tempLanguageCode,
        "waTemplateID": object.waTemplateID,
        "levelNumber": object.levelNumber,
        "sFrequencyCap": object.sFrequencyCap,
        "splitType": object.splitType,
        "isSplitABEnabled": object.isSplitABEnabled,
        "caption": object.caption,
        "liveURL": object.liveURL,
        "waMoblieScheduleDate": object.localBlastDateTime,
        "blastDateTime": object.blastDateTime,
        "localBlastDateTime": object.localBlastDateTime,
        "timezoneID": object.timezoneID,
        "confirmationEmailAddress": object.confirmationEmailAddress,
        "waScheduleGUID": object.waScheduleGUID,
        "requestForApproval": object.requestForApproval,
        "confirmationSMS": object.confirmationSMS,
        "approvalSentTo": object.approvalSentTo,
        "approvalEmailSentTo": object.approvalEmailSentTo,
        "isApproved": object.isApproved,
        "makeChangesComments": object.makeChangesComments,
        "validationKey": object.validationKey,
        "countryID": object.countryID,
        "caLanguages": object.caLanguages,
        "caDayLightList": object.caDayLightList,
        "clientWappSmsSettingId": object.clientWappSmsSettingId,
        "campaignName": object.campaignName,
        "waSenderNameAttributes": object.waSenderNameAttributes,
        "waRecipientAttributes": object.waRecipientAttributes,
        "waRecipientId": object.waRecipientId,
        "domId": object.domId,
        "flowChannel": object.flowChannel,
        "isMDCNewFlow": object.isMDCNewFlow,
        "blastScheduleGuId": object.blastScheduleGuId,
        "dataSource": object.dataSource,
        "dynamiclistID": object.dynamiclistID,
        "channelFriendlyName": object.channelFriendlyName,
        "parentChannelDetailId": object.parentChannelDetailId,
        "parentChannelDetailType": object.parentChannelDetailType,
        "actionId": object.actionId,
        "actionTime": object.actionTime,
        "actionTimeDuration": object.actionTimeDuration,
        "allOrAny": object.allOrAny,
        "addOnLevel": object.addOnLevel,
        "waTempLanguages": object.waTempLanguages,
        "originalTemplateContent": object.originalTemplateContent,
        "waImagePath": object.waImagePath,
        "waMediaURL": object.waMediaURL,
        "waMediaURLType": object.waMediaURLType,
        "thumbNailPath": object.thumbNailPath,
        "isShortenURLEnabled": object.isShortenURLEnabled
    }
    return whatsAppData
}