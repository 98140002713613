import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { IMAGE_URL } from "Helper/Constants/validationMessage";
import { isValidWebsite } from "Helper/Utils/Utils";
import { RSDropdownList } from "Components/RSDropdowns";
import { UPLOAD_MEDIA_URL } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import authContext from "Helper/StateHandler/auth-context";

class ImagePicker extends React.Component {
  static contextType = authContext;
  state = {
    isModalOpen: this.props.isOpen,
    imageURL: "",
    imageType: {
      imageId: 0,
      imageType: "-- Select image type --",
    },
    errorImageURL: null,
    errorImageType: null,
    isEnableSaveButton: false

  };
  uploadImageToServer = (mediaUrl) => {
    let params = {
      "imageUrl": mediaUrl,
    }
    connectServer({
      path: UPLOAD_MEDIA_URL,
      params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status) {
          if (parseInt(res.data.value, 10) <= -1) {
            this.setState({ errorImageURL: "Link does not have an image", isEnableSaveButton: false })
          } else if (parseInt(res.data.value, 10) > 1) {
            this.setState({ errorImageURL: "Image size maximum 1 MB", isEnableSaveButton: false })
          } else {
            this.setState({ errorImageURL: null, isEnableSaveButton: true })
          }
        } else {
          this.setState({ isEnableSaveButton: false });
        }
      },
      fail: err => {
      }
    })
  }
  render() {
    return (
      <Modal backdrop="static"
        keyboard={false} show={this.state.isModalOpen} centered size="lg" onHide={() => {
          this.setState({ isModalOpen: !this.state.isModalOpen });
          this.props.onChangeIsOpen(false);
        }}>
        <Modal.Header closeButton>
          <h2>Enter media URL</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={2} >
                <label className="label-row">URL</label>
              </Col>
              <Col md={9} >
                <RSInput2
                  ph="Enter a valid URL"
                  required={true}
                  cb={(text) => {
                    let errMsg = text.length > 0 ? null : IMAGE_URL;
                    this.setState({ errorImageURL: errMsg, imageURL: text });
                  }}
                  ob={(text) => {
                    if (text.length > 0) {
                      let extension = text.split(".").at(-1)
                      let errMsg = (extension === "jpg" || extension === "jpeg" || extension === "png") ? isValidWebsite(text) ? null : "Enter a valid URL" : "URL does not match with type";
                      if (errMsg === null) {
                        this.setState({ imageType: (extension === "jpg" || extension === "jpeg") ? ImageData[0] : ImageData[1] })
                        this.uploadImageToServer(text);
                      } else {
                        this.setState({ errorImageURL: errMsg });
                      }
                    } else {
                      this.setState({ errorImageURL: IMAGE_URL });
                    }
                  }}
                  errorMessage={this.state.errorImageURL}
                />
                <div className="input-outer-set">
                  <RSPTooltip text="Accepts URL formats with .jpg, .png extention" position="top">
                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                  </RSPTooltip>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col md={2} >
                <label className="label-row">Type</label>
              </Col>
              <Col md={9} >
                <div className="position-relative">
                  {this.state.errorImageType && (
                    <div className="validation-message">
                      {this.state.errorImageType}
                    </div>)}
                  <RSDropdownList
                    className="marginB0 required"
                    data={ImageData || []}
                    value={this.state.imageType}
                    defaultItem={{
                      imageId: 0,
                      imageType: "-- Select image type --",
                    }}
                    textField='imageType'
                    dataItemKey='imageId'
                    onChange={(e) => {
                      let errMsg = e.target.value.imageId === 0 ? "Select image type" : null;
                      this.setState({ imageType: e.target.value, errorImageType: errMsg });
                    }}
                  />
                </div>
              </Col>
            </Row> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container marginT20">
            <RSSecondaryBtn
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
              }}
            >
              Cancel
            </RSSecondaryBtn>
            <RSPrimaryBtn
              className={this.state.isEnableSaveButton ? "" : "click-off"}
              onClick={() => {
                // if (this.state.imageType.imageId === 0) {
                //   this.setState({ errorImageType: "Select image type" });
                // } else {
                this.props.onCallback(this.state.imageType.imageType, this.state.imageURL);
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
                // }
              }}
            >
              Save
            </RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ImagePicker;

const ImageData = [
  {
    imageId: 1,
    imageType: "JPG"
  },
  {
    imageId: 2,
    imageType: "PNG",
  }
]