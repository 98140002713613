import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { orderBy } from "@progress/kendo-data-query";
import { dateFormat, getAuthClientId } from "Helper/Utils/Utils";
import { connectServer } from "Helper/Network/networkHandler";
import { DOWNLOAD_CUSUMABALE, GET_CONSUMABLE_INVOICE_LIST } from "Helper/Constants/endPoint";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import authContext from "Helper/StateHandler/auth-context";
import { RSPTooltip } from "Components/Tooltip";
import { SuccessModal } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal";
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";

const initialSort = [
    {
        field: "invoiceNumber",
        dir: "asc",
    },
];

class ActionCell extends React.Component {
    static contextType = authContext;
    myRef = React.createRef();
    state = {
        sort: initialSort,
        isShowSuccessModal: false,
        dropdownAppendTo: null
    }
    downloadPdf = (arg) => {
        let params = {
            consumableId: this.props.dataItem.consumableId,
            // invoiceNo: this.props.dataItem.invoiceNo,
            docType: arg,
        };

        connectServer({
            path: DOWNLOAD_CUSUMABALE,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status) {
                    if (arg === 2) {
                        const byteArray = "data:application/pdf;base64," + res.data;
                        const a = document.createElement("a");
                        a.href = byteArray;
                        a.download =
                            res?.fileName ||
                            `Subscription${new Date().getDate()}-${new Date().getMonth()}`;
                        a.click();
                    } else if (arg === 1) {
                        let print = window.open("", "", "height=500, width=500");
                        // let decode = atob(decode)
                        print.document.write(res.htmlData); // here replate it data.encodedData print.document.write(decode);
                        print.document.close();
                        print.print();
                    } else if (arg === 3) {
                        this.setState({ isShowSuccessModal: true });
                    }
                }
            },
            fail: (fail) => { },
        });
    };

    componentDidMount() {
        this.setState({
            dropdownAppendTo: this.myRef.current
        })
    }

    render() {
        return (
            <>
                <td>
                    <div className="table-action-btn" ref={this.myRef}>
                        <RSPTooltip text="View" position="top">
                            <i
                                className="icon-sd-eye-medium icons-md blue cursor-pointer"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: `${this.props.url}/invoice`,
                                        state: {
                                            invoiceId: this.props.dataItem.type === "List" ? this.props.dataItem.audienceInvoiceId : this.props.dataItem.consumableId,
                                            type: this.props.dataItem.type,
                                        },
                                    });
                                }}
                            ></i>
                        </RSPTooltip>
                        <RSPTooltip text="Download" position="top">
                            <div className="test" >
                                <DropDownButton
                                    className=""
                                    popupSettings={{
                                        appendTo: this.state.dropdownAppendTo
                                    }}
                                    iconClass="icon-sd-download-medium icons-md blue cursor-pointer"
                                    onItemClick={(prop) => {
                                        this.downloadPdf(prop.itemIndex + 1);
                                    }}
                                >
                                    <DropDownButtonItem
                                        iconClass="icon-sd-printer-medium icons-md blue cursor-pointer"
                                        text=""
                                    />
                                    <DropDownButtonItem
                                        iconClass="icon-sd-pdf-medium icons-md blue cursor-pointer"
                                        text=""
                                        title="aaa"
                                    />
                                    <DropDownButtonItem
                                        iconClass="icon-sd-email-mini icons-md blue cursor-pointer"
                                        text=""
                                    />
                                </DropDownButton>
                            </div>
                        </RSPTooltip>
                    </div>
                </td>
                {this.state.isShowSuccessModal && (
                    <SuccessModal
                        isOkButton={false}
                        isOpen={this.state.isShowSuccessModal}
                        title={"Success"}
                        subTitle={"Invoice mailed successfully."}
                        buttonClicked={(value) => {
                            this.setState({ isShowSuccessModal: false });
                        }}
                    />
                )}
            </>
        );
    }
}

const createDataState = (dataState, list) => {
    return {
        result: process(list.slice(0), dataState),
        dataState: dataState,
    };
};

class ConsumableInvoice extends Component {
    initialDataState = {
        skip: 0,
        take: 5,
    };

    state = {
        result: null,
        dataState: createDataState(this.initialDataState, []).dataState,
        invoiceList: [],
        listError: false,
        totalCount: 0,
    };

    static contextType = authContext;

    dataStateChange = (event) => {
        const { skip, take } = event.dataState;
        const currentPage = Math.floor(skip / take + 1);
        this.setState({
            result: process(this.state.invoiceList, event.dataState),
            dataState: event.dataState,
        });
        let params = {
            clientId: getAuthClientId(),
            pageNumber: currentPage,
            itemsPerPage: take,
        };
        this.getDataFromServer(params);
    };

    CustomActionCell = (props) => {
        const { match, history } = this.props;
        return <ActionCell {...props} history={history} url={match.url} />;
    };

    componentDidMount = (props) => {
        let params = {
            pageNumber: 1,
            itemsPerPage: 5,
        };
        this.getDataFromServer(params);
    };

    getDataFromServer = (params) => {
        this.setState({ result: null, listError: false });
        connectServer({
            path: GET_CONSUMABLE_INVOICE_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status && res.data?.consumableInvoiceList?.length > 0) {
                    let currentState = createDataState(
                        {
                            skip: 0,
                            take: this.state.dataState?.take,
                        },
                        res.data.consumableInvoiceList
                    );
                    this.setState({
                        result: currentState.result,
                        // dataState: currentState.dataState,
                        invoiceList: res.data.consumableInvoiceList,
                        totalCount: res.data.totalRowCount,
                    });
                } else {
                    this.setState({ listError: true });
                }
            },
            fail: (err) => {
                this.setState({ listError: true });
            },
        });
    };

    render() {
        return (
            <div className="grid-truncate">
                {this.state.result !== null ? (
                    <Grid
                        data={this.state.result}
                        {...this.state.dataState}
                        total={this.state.totalCount}
                        onDataStateChange={this.dataStateChange}
                        // scrollable={"none"}
                        sort={this.state.sort}
                        onSortChange={(e) => {
                            this.setState({
                                sort: e.sort,
                                result: orderBy(this.state.invoiceList, e.sort),
                            });
                        }}
                        className='grid-pagenation-del grid-replaceable grid-vertical-noscroll'
                        pageable={{
                            //type: 'input',
                            pageSizes: true,
                            previousNext: true,
                        }}
                    >
                        <GridColumn columnMenu={ColumnMenu} field="name" title="Name" width="280px" />
                        <GridColumn columnMenu={ColumnMenu} field="type" title="Invoice type" width="180px" />
                        <GridColumn columnMenu={ColumnMenu} field="invoiceNo" title="Invoice no" filter='text' width="200px" />
                        <GridColumn columnMenu={ColumnMenu} field="invoiceDateTime" cell={DateCell} title="Invoice date" width="180px" />
                        <GridColumn columnMenu={ColumnMenu} field="totalAmount" cell={TotalAmountCell} title="Amount (USD)" width="180px" />
                        <GridColumn columnMenu={ColumnMenu} field="paymentMode" title="Payment mode" width="180px" />
                        <GridColumn columnMenu={ColumnMenu} field="status" title="Status" width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field="dueDate" cell={DateCell} title="Due date" width="180px" />
                        <GridColumn field="action" title="Action" cell={this.CustomActionCell} width="100px" />
                    </Grid>) : <SkeletonTable count={5} isError={this.state.listError} />}
            </div>
        );
    }
}
export default withRouter(ConsumableInvoice);

const ColumnMenu = (props) => {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};
const DateCell = (props) => {
    return <td>{dateFormat(props.dataItem[props.field])}</td>;
};
const TotalAmountCell = (props) => {
    return <td className="text-end">${props.dataItem[props.field]}</td>;
};
