import React, { useState } from "react";

import { Col, Modal, Row } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import {
  EMAIL_FOOTER_EXISTING_NAME,
  IS_NAME_PRESENT,
  SAVE_EMAIL_FOOTER,
} from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { useContext } from "react";
import { connectServer } from "Helper/Network/networkHandler";
import { getServerConfig } from "Helper/Utils/Utils";

const EmailFooterCreateModal = ({
  templateCreateFlag,
  setTemplateCreateFlage,
  containers,
  onChangeIndex,
  getDatFromServer,
  edit,
}) => {
  const context = useContext(authContext);
  const [tempErr, setTemErr] = useState(null);
  const [templateName, setTemplateName] = useState(edit?.footerName || "");


  const handlecreate = () => {
    if (templateName.length === "") {
      setTemErr("Email footer name already exists");
      return;
    }

    let rowdata = document.getElementById("cardId")?.innerHTML

    if (typeof rowdata !== 'string') {
      // If data is not a string, convert it to an empty string
      rowdata = '';
      return;
    }
    let modifiedContent = rowdata.replace(
      /<td[^>]*>\s*<img(?![^>]*\salt="[^"]*")[^>]*>.*?<\/td>/g,
      ''
    );

    let modifiedContent1 = modifiedContent.replace(
      /<table[^>]*>/,
      '<table style="border:1px solid #cccccc;line-height:24px;padding:0 23px;margin-bottom: 15px;font-family:MuktaRegular,sans-serif;font-size:17px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center" class="temp-holderSec emailFooterTemplate" style="background-color:rgb(255,255,255);">'
    );
    let modifiedContent2 = modifiedContent1.replace(
      /<tabel[^>]*>(.*?)<\/tabel>/g,
      '<tabel style="font-size: 18px;margin-left:10px">$1</tabel>'
    );
    let modifiedContent3 = modifiedContent2.replace(
      /(<table[^>]*class="[^"]*mce-item-table[^"]*"[^>]*>)(?![\s\S]*<table[^>]*class="[^"]*mce-item-table[^"]*")/g,
      '$1<style="margin-bottom: 20px;">'
    );

    let modifiedContent4 = modifiedContent3.replace(/border="0"/g, '');

    let params = {
      emailFooterId: edit === null ? 0 : edit?.emailFooterId,
      emailFooterHtml: JSON.stringify(containers),
      footerName: templateName,
      emailFooterRawCode: modifiedContent4
    };

    connectServer({
      path: SAVE_EMAIL_FOOTER,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        handleCancel();
        onChangeIndex(0);
        getDatFromServer();
      },
      fail: (err) => { },
    });
  };

  const handleSave = () => {
    if (tempErr === null) {
      var result = document.getElementById("cardId")?.outerHTML;
      var removeIcons = document.getElementById("cardId");
      removeIcons.classList.remove("hovweIcons");
      let params = {
        emailFooterName: templateName,
      };
      connectServer({
        path: EMAIL_FOOTER_EXISTING_NAME,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setTemplateCreateFlage(false);
            setTemErr(null);
            handlecreate();
          } else {
            setTemErr("Email footer name already exists");
          }
        },
        fail: (err) => { },
      });
    } else {
      setTemErr("Enter template name");
    }
  };

  const handleCancel = () => {
    setTemplateCreateFlage(false);
    setTemErr(null);
    setTemplateName(edit?.footerName || "");
    setTemplateCreateFlage(false);
  };

  return (
    <Modal
      show={templateCreateFlag}
      centered
      backdrop="static"
      keyboard={false}
      size="md"
      onHide={handleCancel}
    >
      <Modal.Header>
        <h2>Email footer name</h2>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Row className="split-ab-modal">
          <Col>
            <RSInput2
              ph="Enter Email footer name"
              required={true}
              val={templateName}
              max={100}
              cb={(text) => {
                var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                let val = text.trim(" ");
                if (val.length === 0) {
                  setTemErr("Enter template name");
                  setTemplateName(text);
                } else if (!regex.test(val)) {
                  setTemErr(
                    "Special characters are not allowed except (_ and -)"
                  );
                } else {
                  setTemplateName(text);
                  setTemErr(null);
                }
              }}
              errorMessage={tempErr}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-container d-flex justify-content-end">
          <RSSecondaryBtn
            padding="0px"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            className={
              tempErr !== null || templateName.length === 0 ? "click-off" : ""
            }
            onClick={() => {
              setTemErr(null);
              if (edit === null) {
                handleSave();
              } else {
                handlecreate();
              }
            }}
          >
            Save
          </RSPrimaryBtn>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailFooterCreateModal;
