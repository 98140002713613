import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { dateFormat, dateFormatWithMomentForInvoice, numberFormatter } from 'Helper/Utils/Utils';
import { GET_PREVIEW_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import PreviewList from './Components/PreviewList';

class SegmentDynamicInfo extends Component {
    static contextType = authContext
    state = {
        isModalOpen: this.props.isOpen,
        attributeListValue: [],
        blastCampaign: this.props.selectedData?.campaignDetailsList,
        blastCount: this.props.selectedData?.blastedCount,
        previewList: null,
        pushtypes: [
            {
                type: "Email",
                count: this.props.selectedData?.recipientCountEmail
            },
            {
                type: "Mobile",
                count: this.props.selectedData?.recipientCountMobile
            }
        ],
        deliveryStatus: [
            {
                deliveryType: "Spam",
                count: this.props.selectedData?.spam
            },
            {
                deliveryType: "Bounced",
                count: this.props.selectedData?.bounced
            },
            {
                deliveryType: "Unsubscribed",
                count: this.props.selectedData?.unsubscribed
            },
            {
                deliveryType: "DND",
                count: this.props.selectedData?.dnd
            }
        ]
    }

    componentDidMount = () => {
        this.getPreviewList(this.props.selectedListID)
        this.getAttributeList();
    }

    getPreviewList = (listId) => {
        let params = {
            "segmentationListId": listId
        }
        connectServer({
            path: GET_PREVIEW_LIST,
            params: params,
            // loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ previewList: res.data })
                }
            },
            fail: err => {
            }
        })
    }
    getAttributeList = (props) => {
        let tempAttributeList = [];
        this.props.selectedData?.adhocAttributesList?.map((item, index) => {
            tempAttributeList.push(item.sourceColumn);
        })
        this.setState({ attributeListValue: tempAttributeList });
    }

    handleClose = () => {
        this.props.onChangeIsOpen(false);
        this.setState({ isModalOpen: false });
    }

    render() {

        return (
            <Modal
                backdrop="static"
                keyboard={false}
                className="dynamic-list-fill modal-top-fixed"
                show={this.state.isModalOpen}
                onHide={() => this.handleClose()}
                size="xlg"
                centered
            >
                <Modal.Header closeButton>
                    <div className='d-flex align-items-center justify-content-between w100p marginT15'>
                        <h3 className='truncate400 mb0'>{this.props.selectedData?.recipientsBunchName}</h3>
                        <div className="float-end">
                            <span>
                                Created by
                                <span> {this.props.selectedData?.createdBy}</span> on
                                <span> {dateFormatWithMomentForInvoice(this.props.selectedData?.createdDate)}</span>
                            </span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='p0 pb20'>
                    <Carousel
                        indicators={true}
                        controls={false}
                        interval={null}
                        className='segment-slide-popup'
                    >
                        <Carousel.Item>
                            <Row className="marginT20">
                                <Col md={6} xs={12}>
                                    <div className='css-scrollbar max-height-aud-channel'>
                                        <h6>Communication published {"(" + this.state.blastCount?.toString() + ")"}</h6>
                                        <ul className="campaign-pop-list">
                                            {this.state.blastCampaign?.map((campaign, index) => {
                                                return <li key={index + ''}>
                                                    <div className='py4'>{dateFormat(campaign.blastDateTime)}</div>
                                                    <div className="py4 blue">{campaign.campaignName}</div>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='css-scrollbar max-height-aud-channel'>
                                        <h6>Audience by channel (<span>{numberFormatter(this.props.selectedData?.recipientCount)}</span>)</h6>
                                        <ul className="campaign-pop-list2 border-bottom-0">
                                            {this.state.pushtypes?.map((pushType, index) => {
                                                return <li key={index + ''}>
                                                    <span className="float-start blue">{pushType.type}</span>
                                                    <span className="float-end blue">{numberFormatter(pushType.count)}</span>
                                                </li>
                                            })}
                                        </ul>
                                        {/* <h6>Deliverability</h6>
                                        <ul className="campaign-pop-list2 border-bottom-0">
                                            {this.state.deliveryStatus?.map((status, index) => {
                                                return <li key={index + ''}>
                                                    <span className="float-start blue">{status.deliveryType}</span>
                                                    <span className="float-end blue">{numberFormatter(status.count)}</span>
                                                </li>
                                            })}
                                        </ul> */}
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className="marginT20">
                                <Col md={12}>
                                    {/* <div>
                                        <h3 className='mb20'>Attributes</h3>
                                        <div className="attr-chips">
                                            <ul className='chips-list'>
                                                {this.state.attributeListValue?.map((item) => { return <li>{item}</li> })}
                                            </ul>
                                        </div>
                                    </div> */}
                                    <PreviewList previewList={this.state.previewList} />
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </Modal.Body>
            </Modal >
        );
    }
}
export default SegmentDynamicInfo;