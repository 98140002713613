export const TEMPLATE_DATA = [
  {
    icon: "icon-sd-email-large",
    title: "Email",
    link: "email-template-gallery",
    tooltip: "Email",
    indicate: false,
    disable: false,
  },
  // {
  //   icon: " icon-sd-messaging-large",
  //   title: "SMS",
  //   link: "sms-builder",
  //   tooltip: "SMS",
  //   indicate: false,
  //   disable: true,
  // },
  // {
  //   icon: "icon-subscription-form-large",
  //   title: "FORM",
  //   link: "form-builder",
  //   tooltip: "FORM",
  //   indicate: false,
  //   disable: true,
  // },
  {
    icon: "icon-sd-landing-page-builder-large",
    title: "Landing page",
    link: "landing-page-gallery",
    tooltip: "Landing page",
    indicate: false,
    disable: false,
  },
  // {
  //   icon: "icon-qrcode-xmedium",
  //   title: "QR",
  //   link: "landing-page",
  //   tooltip: "landing page",
  //   indicate: false,
  //   disable: true,
  // },
];

export const EMAIL_TABS = [
  {
    id: 0,
    text: "All template",
    // iconLeft: "icon-text-document-large icons-lg marginB10",
    disable: false,
    contentType: "A",
  },
  {
    id: 1,
    text: "My template",
    // iconLeft: "icon-text-document-large icons-lg marginB10",
    disable: false,
    contentType: "T",
  },
  {
    id: 2,
    text: "Create new template",
    // iconLeft: "icon-import-file-large icons-lg marginB10",
    disable: false,
    contentType: "M",
  },

];
export const LANDING_TABS = [
  {
    id: 0,
    text: "All template",
    // iconLeft: "icon-text-document-large icons-lg marginB10",
    disable: false,
    contentType: "A",
  },
  {
    id: 1,
    text: "My template",
    // iconLeft: "icon-text-document-large icons-lg marginB10",
    disable: false,
    contentType: "T",
  },
];

