const PaymentPendingMedium = (props) => {
    const { color, className, onClick } = props
    return (
        <div className={`PaymentPendingMedium cp ${className ?? ''} pointer-none`}>
            <svg
                // xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                {...props}
            >
                <path
                    class={`${color ?? 'th-primary-fill-color'}`}
                    d="M9.3 8.68c.35-.27.84-.41 1.45-.41.67 0 1.2.15 1.58.45.32.25.51.69.51 1.2 0 .34.28.62.62.62s.62-.28.62-.62c0-.88-.37-1.7-.99-2.18-.47-.37-1.06-.59-1.75-.67V5.65c0-.34-.28-.62-.62-.62s-.62.28-.62.62v1.43c-.6.08-1.12.29-1.55.61-.63.48-.96 1.12-.96 1.86s.27 1.31.79 1.75c.49.42 1.47.83 2.43 1.04.57.13 1.01.28 1.33.45.26.14.45.29.55.46.1.16.15.38.15.64 0 .39-.15.68-.48.92-.38.28-.9.42-1.55.42-.71 0-1.27-.15-1.68-.45-.35-.26-.52-.58-.52-.98 0-.34-.28-.62-.62-.62s-.62.28-.62.62c0 .81.36 1.5 1.03 1.99.47.34 1.05.56 1.72.65v1.11c0 .34.28.62.62.62s.62-.28.62-.62v-1.1c.68-.07 1.27-.28 1.74-.63a2.3 2.3 0 00.99-1.93c0-.5-.11-.93-.34-1.3-.22-.36-.57-.67-1.03-.91-.42-.22-.95-.41-1.65-.57-.91-.19-1.65-.56-1.9-.77-.24-.2-.34-.45-.34-.8s.15-.63.47-.87zM20.94 5.77c.06-.1.12-.2.17-.31.07-.15.13-.3.17-.46.03-.12.06-.23.07-.35.02-.14.03-.28.03-.42v-.46c.33-.02.59-.29.59-.62s-.28-.62-.62-.62h-5.24a.617.617 0 00-.07 1.23v.65c.01.13.03.27.06.4s.06.26.11.38c.04.11.09.22.14.33l.12.21c.04.06.44.57.83.88l.33.26-.49.39c-.11.08-.21.17-.29.27-.14.15-.27.31-.38.48-.07.11-.13.21-.18.33-.02.04-.12.28-.19.61 0 .02-.02.03-.02.05v.07c-.02.14-.04.3-.04.46v.24c-.31.04-.56.29-.56.61 0 .34.28.62.62.62h5.23c.34 0 .62-.28.62-.62s-.26-.59-.58-.62v-.23c0-.23-.04-.45-.09-.67-.03-.11-.05-.22-.1-.33 0-.02 0-.04-.02-.06 0-.02-.02-.03-.03-.04a4.41 4.41 0 00-.39-.69c-.14-.17-.3-.33-.46-.46l-.49-.41.47-.39c.26-.21.48-.45.66-.72zm-.91 3.18c.07.18.11.38.11.57v.22h-2.85v-.23c0-.08 0-.17.02-.25.04-.23.12-.42.12-.42.03-.06.06-.12.1-.18.06-.1.13-.19.21-.27.05-.05.11-.11.17-.15l.82-.64.79.66c.1.08.19.17.25.24.09.13.19.31.26.46zm.09-4.47c0 .07-.02.13-.04.19-.02.09-.06.18-.1.25a1.712 1.712 0 01-.49.59l-.77.63-.66-.52c-.22-.18-.52-.53-.57-.59l-.05-.09c-.03-.06-.06-.12-.08-.18-.03-.07-.04-.14-.06-.21-.01-.07-.02-.15-.03-.21v-.57h2.85v.46c0 .08 0 .16-.02.24z"
                    fill="#000"
                    strokeWidth={0}
                />
                <path
                    class={`${color ?? 'th-primary-fill-color'}`}
                    d="M14.8 3.5c-.76-.36-1.57-.61-2.41-.75-2.36-.38-4.74.17-6.68 1.57a8.898 8.898 0 00-3.61 5.84c-.79 4.88 2.53 9.5 7.41 10.3a8.916 8.916 0 005.11-.66c.32-.14.46-.51.31-.83a.614.614 0 00-.83-.31c-1.38.62-2.9.82-4.4.57-4.2-.68-7.06-4.66-6.38-8.86a7.647 7.647 0 013.11-5.02A7.596 7.596 0 0112.18 4c.72.12 1.42.33 2.08.65.31.15.68.02.83-.3.15-.31.02-.68-.3-.83zM20.44 18.25l-1.08 1.08v-5.51c0-.34-.28-.62-.62-.62s-.62.28-.62.62v5.51l-1.08-1.08c-.24-.24-.64-.24-.88 0s-.24.64 0 .88l2.15 2.15c.12.12.28.18.44.18s.32-.06.44-.18l2.15-2.15c.24-.24.24-.64 0-.88a.628.628 0 00-.88 0z"
                    fill="#000"
                    strokeWidth={0}
                />
            </svg>
        </div>
    )
}
export default PaymentPendingMedium;