import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const PreviewModal = ({ show, handleClose, data }) => {
  
  const [content, setContent] = useState(data);

  useEffect(() => {
    if (typeof data !== 'string') {
      // If data is not a string, convert it to an empty string
      setContent('');
      return;
    }
  
    let modifiedContent = data.replace(
      /<td[^>]*>\s*<img(?![^>]*\salt="[^"]*")[^>]*>.*?<\/td>/g,
    ''
    );

    // let modifiedContent1 = modifiedContent.replace(
    //   /<td[^>]*>\s*<a[^>]*>.*?<\/a>.*?<\/td>/g,
    //   (match) => {
    //     // Add inline style to the <a> tag
    //     return match.replace('<a', '<a style="color:  var(--th-primary-color);"');
    //   }
    // );

    setContent(modifiedContent);
  }, [data]);
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        className='modal-top-fixed'

        keyboard={false}
        centered
        size="lg"
        onHide={() => {
            handleClose()
        }}
      >
        <Modal.Header closeButton>
          <h2>Email footer Preview</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="email-client-container">
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              style={{ pointerEvents: "none", margin: "50px", border: "1px solid black" }}
            ></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewModal;
