import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import PaymentData from './Payment.json';
import { RTabbar } from 'Components/RChart/RTabbar';
import CreditCard from 'Container/RegistrationModule/Payment/Components/CreditCard';
import Paypal from 'Container/RegistrationModule/Payment/Components/Paypal';
import paypalImg from 'Assets/Images/paypal.png';

const amountPeriod = ["Annually (12x)", "Monthly (1x)"];

const PaymentActivation = props => {
  const history = useHistory();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(13);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [isPayBtnClicked, setIsPayBtnClicked] = useState(false);
  const [index, setIndex] = useState(0);
  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        {/* <RSBrandLogo/> */}
        <h1>Payment & activation</h1>
        {!isPayBtnClicked ?
          <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table" style={{ paddingTop: "20px" }}>
            <thead>
              <tr>
                <th >Subscription (Annual commitment)</th>
                <th width="35%">Amount (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">Email</td>
                <td className="text-end">20.00</td>
              </tr>
              <tr className="even">
                <td className="text-start">SMS</td>
                <td className="text-end">30.00</td>
              </tr>
              <tr>
                <td className="text-start">Two-way SMS</td>
                <td className="text-end">15.00</td>
              </tr>
              <tr className="even">
                <td className="text-start">Whatsapp</td>
                <td className="text-end">30.00</td>
              </tr>
              <tr>
                <td className="text-start">Web push</td>
                <td className="text-end blue">40.00</td>
              </tr>
              <tr className="even">
                <td className="text-start">Mobile push</td>
                <td className="text-end blue">40.00</td>
              </tr>
              <tr>
                <td className="text-start">QR</td>
                <td className="text-end blue">15.00</td>
              </tr>
              <tr className="even">
                <td className="text-start">Dynamic list</td>
                <td className="text-end blue">15.00</td>
              </tr>
              <tr>
                <td className="text-start">Forms</td>
                <td className="text-end blue">10.00</td>
              </tr>
              <tr>
                <td className="text-start">Sub total</td>
                <td className="text-end blue">215.00</td>
              </tr>
              <tr>
                <td className="text-start">Payment frequency</td>
                <td className="text-end blue">
                  <BootstrapDropdown
                    data={amountPeriod}
                    defaultItem={"Annually (12x)"}
                    customAlignRight={true}
                    className="fs16-btn"
                    onSelect={(item, index) => {
                      setSelectedPeriod(index);
                    }} />
                </td>
              </tr>
              <tr>
                <td className="text-start">Discount (5%)</td>
                <td className="text-end blue">- 10.75</td>
              </tr>
              <tr>
                <td className="text-start">Amount payable</td>
                <td className="text-end blue">$204.25</td>
              </tr>
            </tbody>
          </table>
          :
          <>
            <h4>Choose a method of payment to complete setting up your campaign.</h4>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="payment-tab-container">
                  <RTabbar
                    defaultSelectedItem={index}
                    defaultClass="tabDefault"
                    dynamicTab="mini"
                    activeClass="tabDefault active"
                    tabData={tabData}
                    callBack={(item, index) => { setIndex(index) }}
                  />
                  <div className="payment-body-form">
                    {index === 0 ? <CreditCard isCampaignPayment={true} /> : <Paypal />}
                  </div>
                </div>
              </Col>
            </Row></>}
        <div className="btn-container d-flex justify-content-end marginT15">
          <RSSecondaryBtn>
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn onClick={() => {
            if (isPayBtnClicked) {
              history.push("/thanks")
            } else {
              setIsPayBtnClicked(!isPayBtnClicked);
            }
          }}>
            Pay
          </RSPrimaryBtn>
        </div>
      </Container>
    </LayoutPageWrapper>
  )
}

export default PaymentActivation;
const tabData = [
  { "id": 1001, "text": "Credit card", "iconLeft": "icon-creditcard-medium icons-lg marginR10" },
  { "id": 1002, "image": paypalImg }
]