import React, { useContext, useEffect, useState } from 'react';
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { Container } from "react-bootstrap";
import { RSPrimaryBtn } from 'Components/RSButtons';
import CampaignHeader from '../../../Components/CampaignHeader';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import AudienceData from './Audience.json';
import ChannelData from './Channel.json';
import { useHistory, withRouter } from 'react-router-dom';
import { connectServer } from 'Helper/Network/networkHandler';
import { Table } from "react-bootstrap"
import { GET_CONSUMABLE_INVOICE_SUMMARY, POST_CAMPAIGN_PAYMENT_STATUS, SAVE_PAYMENT_DETAILS } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import ResulPayModal from './ResulPayModal';
import { getUserData } from 'Helper/Utils/Utils';

const ConfirmPayment = props => {
  let history = useHistory();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(5);
  const [message, setMessage] = useState('No data received from IFrame, yet.');
  const [transactionId, settransactionId] = useState(0);
  const [isOpenResulPay, setIsOpenResulPay] = useState(false);
  const [invoiceSummary, setInvoiceSummary] = useState(null)
  const [campaignHeader, setCampaignHeader] = useState({
    name: "",
    endDate: "",
    type: "",
    goal: "",
    startDate: ""
  })
  const context = useContext(authContext)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.history.location && props.history.location.state) {
      const state = props.history.location.state
      const { campaignHeader } = state
      setCampaignHeader(campaignHeader)
    }
    getConsumableInvoiceSummary()
  }, [])

  React.useEffect(() => {
    if (message.toLowerCase().startsWith('c')) {
      setIsOpenResulPay(false);
    } else if (message.toLowerCase().startsWith('s')) {
      // const payloads = {
      //   clientId: getAuthClientId(),
      //   orderId: orderId,
      //   userId: getAuthUserId(),
      //   clientName: getUserData().clientDetailsLocalization.clientName,
      //   authorizationCode: transactionId,
      //   paymentMode: 1,
      // };
      // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
      // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
    } else if (message.toLowerCase().startsWith('f')) {
      // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
    } else {
      setIsOpenResulPay(false);
    }
  }, [message]);
  React.useEffect(function () {
    var handler = function (ev) {
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'button-click') return;
      if (!ev.data.message) return;
      setMessage(ev.data.message);
      if (ev.data.message.toLowerCase().startsWith('c')) {
        setIsOpenResulPay(false);
      } else if (ev.data.message.toLowerCase().startsWith('s')) {
        settransactionId(ev.data.transactionId);
        setIsOpenResulPay(false);
        // const payloads = {
        //   clientId: getAuthClientId(),
        //   orderId: orderId,
        //   userId: getAuthUserId(),
        //   clientName: getUserData().clientDetailsLocalization.clientName,
        //   authorizationCode: transactionId,
        //   paymentMode: 1,
        // };
        const paymentParams = {
          "status": true, // payment status (response from resul pay)
          "isTenantSubscription": false, // true if Tenant Subscription payment
          "isClientSubscription": false,// true if client Subscription payment(first time subscription)
          "isConsumable": true,// true if consumable payment
          "isRecipient": false,// true if recipient payment
          "transactionNo": ev.data.transactionId, // response from resul pay
          "orderId": ev.data.orderId,//response from resul pay as string
        }
        savePaymentDetails(paymentParams)
        // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
        // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
      } else if (ev.data.message.toLowerCase().startsWith('f')) {
        // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
      } else {
        setIsOpenResulPay(false);
      }
    };
    window.addEventListener('message', handler);
    // Clean up
    return function () {
      return window.removeEventListener('message', handler);
    }; // The "message" param should match with the iframe.js file
  }, []);

  const savePaymentDetails = (params) => {
    connectServer({
      path: SAVE_PAYMENT_DETAILS,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setIsOpenResulPay(false);
          history.push("/communication");
        }
      },
      fail: err => {
      }
    })
  }

  const UpdatePaymentSkipDetails = (params) => {
    connectServer({
      path: POST_CAMPAIGN_PAYMENT_STATUS,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          history.push("/communication");
        }
      },
      fail: err => {
      }
    })
  }

  const getConsumableInvoiceSummary = () => {
    const { campaignId } = props.history.location.state
    let params = { "campaignId": campaignId }
    connectServer({
      path: GET_CONSUMABLE_INVOICE_SUMMARY,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status && res?.data) {
          setInvoiceSummary(res?.data)
        }
      },
      fail: err => {
      }
    })
  }

  const getIconForChannels = (channelId) => {
    switch (channelId) {
      case 1:
        return "icon-sd-email-medium"
      case 2:
        return "icon-sd-mobile-sms-medium"
      case 21:
        return "icon-sd-social-whatsapp-medium"
      case 3:
        return "icon-sd-qrcode-medium"
      default:
        return "icon-sd-email-medium"
    }
  }

  const getNameForChannels = (channelId) => {
    switch (channelId) {
      case 1:
        return "Email"
      case 2:
        return "SMS"
      case 21:
        return "WhatsApp"
      case 3:
        return "QR code"
      default:
        return "Email"
    }
  }

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        <h1>Payment summary</h1>
        <div className="portlet-container">
          <div className="marginY20">
            <CampaignHeader progressSteps={null} header={campaignHeader} type="editplan" editTooltipText="Edit plan" buttonClicked={(value) => {
              if (value === 1) {
                props.history.push({
                  pathname: `/communication/planning`,
                  state: {
                    mode: "edit",
                    deliveryType: props.history.location.state.campaignType,
                    campaignName: campaignHeader.name,
                    status: props.history.location.state.status,
                    campaignId: props.history.location.state.campaignId
                  }
                });
              }
            }} />
          </div>
          {/* <Grid
          data={AudienceData.slice(skip, take + skip)}
          total={AudienceData.length}
          scrollable={"none"}>
            <GridColumn title="Data subscription" cell={(props)=>{
              return(
              <td className="camp-icon">
                <div>
                    <i className={`mr8 ${props.dataItem.dataSubscription.icon}`}/>
                    <span>{props.dataItem.dataSubscription.text}</span>
                </div>
              </td>)
            }}/>
            <GridColumn title="Units used" cell={(props)=>{
              return(
              <td className="camp-icon">
                <div>
                {props.dataItem.unitUsed}
                <span style={{fontStyle:"italic",display:"block"}}>(5 Attributes)</span>
                </div>
              </td>)
            }}/>
            <GridColumn title="Unit charge (USD)" field="unitCharge"/>
            <GridColumn title="Amount (USD)" field="amount"/>
          </Grid> */}
          {invoiceSummary?.recipientPaymentDetails?.audiencePayments?.length > 0 &&
            <>
              <h2 className='mb15'>Audience</h2>
              <Table className="table rs-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>List name</th>
                    <th>Channel/Data</th>
                    <th>Credits</th>
                    <th>Audience count</th>
                    <th>Unit price (USD)</th>
                    <th>Amount (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceSummary?.recipientPaymentDetails?.audiencePayments?.map((item, index) => {
                    return (item?.paymentMapping?.map((pitem, pindex) => {
                      return <tr>
                        {/* <td className="camp-icon">
                        <div className="d-flex align-items-center">
                          <i className={`icon-sd-users-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} />
                          <span style={{ verticalAlign: 'middle' }}>{"Audience"}</span>
                        </div>
                      </td> */}
                        <td colSpan={pitem?.description === "Consumed data credits" ? "3" : "0"}>
                          {pitem?.description !== "Consumed data credits" ?
                            pitem?.description :
                            <div>
                              {pitem?.description}
                              <br></br>
                              {"(Available data credits : " + pitem?.availableDataCredits + ")"}
                            </div>
                          }
                        </td>
                        <td className={`${pitem?.description === "Consumed data credits" ? "d-none" : ""}`}>{pitem?.listname}</td>
                        <td className={`${pitem?.description === "Consumed data credits" ? "d-none" : ""}`}>
                          <div className='table-text'>
                            <p>{pitem?.channelType}</p>
                          </div>
                        </td>
                        <td className="text-end">{pitem?.contactPoints}</td>
                        <td className="text-end">{pitem?.totalAudienceCount}</td>
                        <td className="text-end">${pitem?.unitPrice}</td>
                        <td className="text-end">{pitem?.description === "Consumed data credits" ? "(-) " : ""}${pitem?.totalRecipientAmount}</td>
                      </tr>
                    }))
                  })}
                  <tr>
                    <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan={5} rowSpan="5"></td>
                    <td className="text-start">Sub total</td>
                    <td className="text-end">${invoiceSummary?.recipientPaymentDetails?.subTotal}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          }
          {invoiceSummary?.consumableMapping &&
            <>
              <h2>Communication</h2>
              <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table" style={{ paddingTop: "20px" }}>
                {invoiceSummary?.campaignType === "M" ?
                  <thead>
                    <tr>
                      <th width="35%">Channel</th>
                      <th width="10%">MDC level</th>
                      <th width="10%">Credits</th>
                      <th width="10%">Audience count</th>
                      <th width="15%">Unit price (USD)</th>
                      <th width="10%">Amount (USD)</th>
                    </tr>
                  </thead>
                  :
                  <thead>
                    <tr>
                      <th width="25%">Channel</th>
                      <th width="10%">Credits</th>
                      <th width="10%">Audience count</th>
                      <th width="15%">Unit price (USD)</th>
                      <th width="15%">Amount (USD)</th>
                    </tr></thead>}
                <tbody>
                  {invoiceSummary?.consumableMapping && invoiceSummary?.consumableMapping?.map(item => {
                    return <tr>
                      <td className="camp-icon">
                        <div className="d-flex align-items-center">
                          {!item?.channelName.includes("credits") &&
                            <i className={`${getIconForChannels(item?.channelId)} icons-md blue mr8`} style={{ verticalAlign: 'middle' }} />}
                          <span style={{ verticalAlign: 'middle' }}>{item?.channelName}</span>
                        </div>
                      </td>
                      {invoiceSummary?.campaignType === "M" && <td className="text-end">{item?.levelNumber}</td>}
                      <td className="text-end">{item?.channelName.includes("credits") ? item?.usedPlanLimit : ""}</td>
                      <td className="text-end">{!item?.channelName.includes("credits") ? item?.numberOfRecipients : ""}</td>
                      <td className="text-end">${item?.price}</td>
                      <td className="text-end">{item?.channelName.includes("credits") ? "(-) " : ""}${item?.totalChannelCost}</td>
                    </tr>
                  })}
                  <tr>
                    <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan={invoiceSummary?.campaignType === "M" ? "4" : "3"} rowSpan="5"></td>
                    <td className="text-start">Sub total</td>
                    <td className="text-end">${invoiceSummary?.subTotal}</td>
                  </tr>
                  <tr className="even">
                    <td className="text-start">Total</td>
                    <td className="text-end">${invoiceSummary?.totalAmount}</td>
                  </tr>
                  <tr className="bgWhite even">
                    <td className="text-start">Tax ({invoiceSummary?.taxPercentage}%) </td>
                    <td className="text-end">(+) ${invoiceSummary?.tax}</td>
                  </tr>
                  <tr className="even">
                    <td className="text-start">Net amount payable</td>
                    <td className="text-end blue">${invoiceSummary?.netPayableAmount}</td>
                  </tr>
                </tbody>
              </table>
            </>}
          {parseFloat(invoiceSummary?.netPayableAmount) >= 0.1 && parseFloat(invoiceSummary?.netPayableAmount) <= 0.5 &&
            <small class="d-flex justify-content-start align-items-start ">
              <b>Attention:</b>
              <div class="d-flex justify-content-center align-items-start flex-column ml10">
                <span>The payable amount is less than USD 50 cents. This amount will be consolidated and</span>
                <span>collected during your next payment.</span>
              </div>
            </small>}
          <div className="btn-container d-flex justify-content-end mt10">
            <RSPrimaryBtn onClick={() => {
              if (invoiceSummary?.isPaymentSkip) {
                let params = {
                  "consumableId": invoiceSummary?.consumableId
                }
                UpdatePaymentSkipDetails(params)
              } else {
                setIsOpenResulPay(true);
              }
              // history.push({
              //   pathname: "/communication/communication-payment",
              //   state: {
              //     mode: props.history.location.state.mode,
              //     campaignId: props.history.location.state.campaignId,
              //     campaignHeader: props.history.location.state.campaignHeader,
              //     channelType: [props.history.location.state.Channeltype],
              //     totalAmount: invoiceSummary?.totalAmount
              //   }
              // });
            }}>Confirm</RSPrimaryBtn>
          </div>
        </div>
        {isOpenResulPay &&
          <ResulPayModal
            isOpen={isOpenResulPay}
            orderId={invoiceSummary?.consumableId}
            isConsumable={true}
            amount={invoiceSummary?.encryptedTotalAmount}
            clientName={getUserData().clientDetailsLocalization.clientName}
            tenantShortCode={getUserData().clientDetailsLocalization.tenantShortCode}
            onChangeIsOpen={(value) => {
              setIsOpenResulPay(value)
            }}
          />
        }
      </Container >
    </LayoutPageWrapper >
  )

}
export default withRouter(ConfirmPayment);