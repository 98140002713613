export const ENTER_FIRST_NAME = "Enter first name";
export const ENTER_FIRST_NAME_3CHARS =
  "Enter at least 3 characters";
export const ENTER_FIRST_NAME_4CHARS =
  "At least four characters are required in the first name";
export const SPECIAL_CHARACTERS = "Special character(s) are not allowed";
export const SPECIAL_NUM_CHARACTERS = "Accept only alphabets"
export const ENTER_LAST_NAME = "Enter last name";
export const ENTER_EMAIL = "Enter a valid username";
export const BUSINESS_EMAIL = "Business email";
export const ENTER_BUSINESS_EMAIL = "Enter business email"
export const ENTER_VALID_EMAIL = "Enter a valid email ID";
export const ENTER_PHONE = "Enter mobile number";
export const ENTER_PASSWORD = "Enter password";
export const CONDITIONS = "Enter conditions";
export const INBOUND_NUMBER = "Enter inbound number";
export const BATCH_AUDIENCE_LIMIT = "Enter batch audience limit";
export const BATCH_AUDIENCE_LENGTH =
  "Batch audience limit should not be greater than 20 digits";
export const SMS_SENDER_ID = "Enter SMS sender ID";
export const OPERATOR_CODE = "Enter operator code";
export const OPERATOR_CODE_LENGTH =
  "Operator code should not be greater than 5 digits";
export const ENTER_DOMAIN_KEY = "Enter domain key";
export const ENTER_DKIMK_KEY = "Enter DKIM key";
export const ENTER_DOMAIN_NAME = "Enter domain name";
export const ENTER_DOMAIN = "Enter domain";
export const SELECT_CONFIG_SETTINGS = "Select the configure settings";
export const ENTER_VALID_PASSWORD = "Enter a valid password";
export const ENTER_CONFIRM_PASSWORD = "Enter confirm password";
export const CONFIRM_PASSWORD_NOT_MATCH = "Confirm password doesn't match";
export const SELECT_TITLE = "Select title";
export const SELECT_JOBFUNCTION = "Select job function";
export const UPLOAD_PROFILE_IMAGE = "Upload profile image";
export const UPLOAD_COMPANY_IMAGE = "Upload company image";
export const ENTER_ANSWER = "Enter answer";
export const ENTER_CORRECT_ANSWER = "Enter the correct answer";
export const ACCEPT_TERMS = "Please agree the Terms & Conditions to proceed";
export const ACCESS_POINT = "Enter access point";
export const AUTHOR_KEY = "Enter authorization key";
export const WHATSAPP_SENDER_ID = "Enter WhatsApp sender ID";
export const WHATSAPP_TEMPLATE_NAME = "Select WhatsApp template name";
export const ENTER_COMPANY_NAME = "Enter the company name";
export const ENTER_WEBSITE = "Enter website";
export const ENTER_VALID_WEBSITE = "Enter a valid website";
export const ENTER_SECURE_WEBSITE = "Enter a secure website URL";
export const ENTER_ADDRESS1 = "Enter address line 1";
export const ENTER_ADDRESS2 = "Enter address line 2";
export const ENTER_CITY = "Enter city";
export const ENTER_ZIP = "Enter zip code";
export const SELECT_DATE_FORMAT = "Select date format";
export const SELECT_TIME_FORMAT = "Select time format";
export const SELECT_TIMEZONE = "Select time zone";
export const SELECT_USERROLE = "Select user role";
export const ENTER_ROLE = "Enter role";
export const ENTER_STATE = "Enter province/state";
export const ENTER_NUMBER_ON_CARD = "Enter number on card"
export const ENTER_NAME_ON_CARD = "Enter name on card"
export const MAXIMUM_LIMIT = "The maximum limit is reached!"
export const ENTER_OTP = "Enter one time password"
export const FILE_TYPE_MESSAGE = "Import only ZIP file"
export const ENTER_VALID_BUSINESS_EMAIL = "Enter a valid business email";
export const SELECT_COUNTRY = "Select country";
export const SELECT_STATE = "Select state";
export const SELECT_CITY = "Select city";
export const SELECT_INDUSTRY = "Select industry";
export const SELECT_BUSINESS_TYPE = "Select business type";
export const SELECT_BRAND_POSITION = "Select brand positioning";
export const SELECT_CURRENCY = "Select currency";
export const SELECT_LANGUAGE = "Select language";

//Campaign
export const CAMPAIGN_NAME = "Enter communication name";
export const ENTER_TEMPLATE_ID = "Enter template ID";
export const CAMPAIGN_TYPE = "Select communication type";
export const PRODUCT_TYPE = "Select product type";
export const PRIMARY_GOAL = "Select primary goal";
export const SECONDARY_GOAL = "Select secondary goal";
export const GOAL_PERCENTAGE = "Enter primary goal percentage";
export const SECONDARY_GOAL_PERCENTAGE = "Enter secondary goal percentage";
export const GOAL_TYPE = "Select type";
export const NATIVE_CHANNEL = "Select native channel(s)";
export const ANALYTICS_TYPE = "Select analytics type";
export const SELECT_SOCIAL_MEDIA = "Select social media";
export const SELECT_PAIDADDS = "Select paid ads";
export const START_DATE = "Select start date";
export const END_DATE = "Select end date";
export const UN_SUBSCRIPTION = "Unsubscription";

// EmailCreation
export const SENDER_NAME = "Enter sender name";
export const SENDER_EMAIL_ADDRESS = "Enter username";
export const REPLY_EMAIL = "Enter alternate email address";
export const AUDIENCE = "Select audience list";
export const SMS_TEMPLATE_ID = "Enter sender template ID";
export const SUBJECT_LINE = "Enter subject line";
export const PRE_HEADER_MESSAGE = "Enter pre header message";
export const SEND_TEST_MAIL = "Enter send text email address";
export const CAMP_PERFORMANCE = "Select communication performance";
export const PERIOD_VALUE = "Enter period value";
export const PERIOD_TYPE = "Enter period type";
export const SCHEDULE_DATE = "Select schedule date and time"
export const EMAIL_CONTENT = "Select a source to add email content"
export const SELECT_DOMAIN = "Select domain"

// MobileCreation
export const SENDER_ID = "Select sender ID";
export const SELECT_SENDER_NAME = "Select sender name";
export const CAMPAIGN_TEXT = "Enter communication text";
export const SEND_TEST_MSG = "Enter mobile number";
export const TEMPLATE_NAME = "Select template";
export const TEMPLATE_LANGUAGE = "Select template language";

export const ENTER_TEMPLATE_NAME = "Enter template name";

// QRCreation
export const AUDIENCE_REACH = "Enter audience reach";
export const CAMPAIGN_URL = "Enter communication URL";
export const REDIRECTION_URL = "Enter redirection URL";
export const VALID_REDIRECTION_URL = "Enter valid URL";
export const KYC = "Select KYC";

export const MOBILE_NUMBER = "Enter mobile number";
export const SMS_CONTENT = "Enter SMS content";

export const MAIL_ID = "Enter mail ID";
export const MAIL_CONTENT = "Enter mail content";

// KYCPreview
export const NAME = "Enter your name";
export const GENDER = "Choose gender";

// SmartLinkCreation

export const ENTER_WEBURL = "Enter web URL";
export const ENTER_MOBILE_ADAPTIVE_URL = "Enter mobile adaptive URL";
export const SELECT_KEYVALUE = "Select key value";
export const ENTER_VALUE = "Enter value";
export const SELECT_DEVICE_TYPE = "Select device type";
export const SELECT_APP_SCREEN = "Select app screen";
export const SELECT_APP_SUB_SCREEN = "Select app sub screen";
//OnAppBoarding

export const APP_NAME = "Enter app name";
export const MOBILE_PLATFORM = "Select mobile platform";
export const STORE_URL_ANDROID = "Enter play store URL";
export const STORE_URL_IOS = "Enter app store URL";
export const NOTIFICATION_PROVIDER = "Choose notification provider";
export const FCM_SENDER_ID = "Enter FCM sender ID";
export const SERVER_NAME = "Enter server name";
export const FCM_SERVER_KEY = "Enter FCM server key";
export const SERVER_FROM_EMAIL = "Enter server from email";
export const SERVER_BOUNCE_EMAIL = "Enter server bounce email";
export const SPF_RECORD = "Enter SPF Record";
export const THROTTLE_SETTING = "Select throttle setting";
export const SMTP_HOUSING = "Select SMTP housing";
export const LANGUAGE = "Select language";
export const HYBRID_LANGUAGE = "Choose hybrid language";
export const CERTIFICATE = "Upload APNS certificate file";
export const CERT_PASSWORD = "Enter CERT password";
export const SERV_PROVIDER = "Select service provider";

//Audience add by csv

export const IMPORT_DESCRIPTION = "Enter the import description";
export const AUDIENCE_FILE_UPLOAD = "Upload audience file";
//Audience add by remote data source

export const SMS_FRIENDLY_NAME = "Enter SMS friendly name";
export const FRIENDLY_NAME = "Enter friendly name";
export const IP_ADDRESS = "Enter the IP address";
export const IP_ADDRESS_VALID = "Enter a valid IP address";
export const PORT_NUMBER = "Enter the port number";
export const PORT_NUMBER_VALID = "Enter a valid port number";
export const USER_NAME = "Enter user name";
export const SMS_TYPE = "Select the SMS type";
export const PASS_WORD = "Enter password";
export const FOLDER_PATH = "Enter folder path";

//AppAnalytics
export const ANALYTICS_PLATFORM = "Select analytic platform";
export const ACCOUNT_MAIL = "Enter mail account";
export const APP_KEY = "Enter app key";
export const APP_SECRET_ID = "Enter app secret ID";

//Mobile Notification
export const MOBILE_APP = "Select mobile app";
export const DELIVERY_TYPE = "Select delivery type";
export const CLASSIFICATION = "Select inbox classification";
export const LAYOUT = "Select layout";
export const POSITION = "Select position";
export const TITLE = "Enter title";
export const SUBTITLE = "Enter subtitle";
export const BODY = "Enter message content";
export const SUCCESS_BODY = "Enter success message";
export const INTER_ACTIVITY_BUTTON1 = "Select first interactivity button";
export const INTER_ACTIVITY_BUTTON2 = "Select second interactivity button";
export const ALERT_SOUND = "Select alert sound";
export const EXPIRY_TYPE = "Select expiry type";
export const EXPIRY_VALUE = "Enter time to expier";
export const HASHTAG = "Enter hashtag";
export const SCHEDULE = "Set schedule time";
export const FREQUENCY_TYPE = "Select frequency type";
export const FREQUENCY_VALUE = "Enter frequency value";

export const CHANNEL_NAME = "Select channel name";
export const CHANNEL_LIMIT = "Select limit";
export const CHANNEL_INTERVAL = "Select time interval";

//Web Notification
export const SEND_PUSH_TO = "Select send push to option";
export const DOMAIN_SITE = "Select domain";
export const URL = "Enter URL";
export const APPROVAL_MAIL = "Select approval mail ID";

//Custom Button
export const BUTTON_NAME = "Enter button name";
export const BEHAVIOUR = "Select click on behaviour";
export const BEHAVIOUR_TYPE = "Select type";
export const PHONE_NUMBER = "Enter mobile number";
export const SMARTLINK = "Enter new link";

//Image Picker
export const IMAGE_URL = "Enter image URL";

//PDF Picker
export const PDF_URL = "Enter PDF URL";

//Video Picker
export const VIDEO_URL = "Enter video URL";

//Web Analytics
export const WEB_ANALYTICS_PLATFORM = "Select analytics platform";
export const DOMAIN_NAME = "Select domain";
export const CONVERSION_ACTION = "Select conversion action";
export const CONVERSION_URL = "Enter conversion URL";

//Mobile Analytics
export const CONVERSION_FORM = "Select conversion form";

export const PRODUCT_CATEGORY = "Select product type";
export const INTEREST_TYPE = "Select interest type";
export const INTEREST_MESSAGE_UNIQUE = "Interest type should be unique";
export const ENTER_INTEREST_TYPE = "Please enter interest type"
export const REASON_TYPE = "Select reason type";
export const REASON_MESSAGE_UNIQUE = "Reason type should be unique";
export const ENTER_REASON_TYPE = "Please enter reason type"
export const CHANNEL_TYPE = "Select channel type";
export const LIST_NAME = "Enter name";

export const KEY_WORDS = "Enter keyword";
export const UPDATE_LIST_NAME = "Enter list name";
export const LIST_NAME_LIMIT = "List name should not be more than 30 characters";
export const URL_SCRIPT_ERROR_MSG = "Given URL content contains external elements(scripts css class) which may not suitable for EDM. So content may not display properly. Using EDM without external element recommended";
export const SELECT_OTP_SOURCE = "Select source";
