import React, { Component, useState } from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import jsonData from "./AlertNotifications.json";
import { Switch } from '@progress/kendo-react-inputs'
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { withRouter } from 'react-router-dom';
import { GET_ALERTS_AND_NOTIFICATIONS, UPDATE_ALERTS_AND_NOTIFICATIONS } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import SkeletonCampaignList from "Components/Skeleton/SkeletonCampaignList";
import authContext from "Helper/StateHandler/auth-context";

const CustomCell = props => {

    const [checked, setChecked] = useState(true)

    return <td>
        <div className="float-end">
            <Switch onChange={() => setChecked(!checked)} checked={checked} className="switch-alert" />
        </div>
    </td>
}

class AlertsAndNotifications extends Component {
    static contextType = authContext
    state = {
        skip: 0, take: 10,
        headingNav: {
            "defaultItem": "Personal",
            "optionValue": ['All', 'Personal', 'Business', 'Privilege']
        },
        resData: null,
        listError: false
        // pageable: GridPagerSettings,
    }

    componentDidMount = () => {
        this.getAlertsAndNotificationsFromServer()
    }

    getAlertsAndNotificationsFromServer = () => {
        connectServer({
            path: GET_ALERTS_AND_NOTIFICATIONS,
            params: {},
            // loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                let sortedArry = res.data?.alertNotifications?.sort((a, b) => a.alertNotificationId > b.alertNotificationId ? 1 : -1).reverse()
                this.setState({ resData: sortedArry || null, listError: false })
            },
            fail: err => {
                // alert(err.message)
                this.setState({ listError: true })
            }
        })
    }

    changeAlertsAndNotificationsStatus = params => {
        connectServer({
            path: UPDATE_ALERTS_AND_NOTIFICATIONS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.getAlertsAndNotificationsFromServer()
                }
            },
            fail: err => {
            }
        })
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    }
    MyCustomCell = (props) => <CustomCell {...props} myProp={this.customData} />

    handleChange = (fields, value) => {
        fields.checked = value
        const params = {
            alertNotificationId: fields?.alertNotificationId,
            isAlert: value
        }
        this.changeAlertsAndNotificationsStatus(params)
    }

    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className="header-title d-flex justify-content-between">
                        <h1>Alerts and notifications</h1>
                        <div className="back-text" onClick={() => this.props.history.push(`/preferences`)}>
                            <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                            Back
                        </div>
                    </div>
                    <hr className="blue" />
                    <div>
                        <p className="font-sm">Manage your preferences for receiving the following alerts and notifications:</p>
                        {this.state.resData ? <Grid
                            data={this.state.resData?.slice(this.state.skip, this.state.take + this.state.skip)}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={jsonData.length}
                            onPageChange={this.pageChange}
                            scrollable={'none'}
                            className="grid-pagenation-del grid-replaceable table-alert-notify"
                        >
                            <GridColumn cell={(props) => {
                                return (
                                    <td>{props.dataItem.alertNotification}</td>
                                )
                            }} />
                            <GridColumn cell={(props) => {
                                return <td className="text-end">
                                    <div>
                                        <Switch className="mt0"
                                            onChange={(e) => this.handleChange(props.dataItem, e.target.value)}
                                            checked={props.dataItem.isAlert} />
                                    </div>
                                </td>
                            }} />
                        </Grid>
                            : <><div className="p0"><SkeletonCampaignList isError={this.state.listError} /></div></>}
                    </div >
                    {/* <div className="btn-container d-flex justify-content-end">
                        <RSSecondaryBtn onClick={() => this.props.history.push(`/preferences`)}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            // console.log(jsonData)
                            this.props.history.push(`/preferences`)
                        }}>Save</RSPrimaryBtn>
                    </div> */}

                </Container>

            </LayoutPageWrapper>
        )
    }
}
export default withRouter(AlertsAndNotifications);
