import React from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPTooltip } from "Components/Tooltip";
import { GET_SMS_LIST, DELETE_SMS } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import DLTInfoComponent from "./DLTInfoModal";

const dataState = {
  skip: 0,
  take: 5,
};

class SMSList extends React.Component {

  static contextType = authContext;

  state = {
    dataResult: null,
    dataState: dataState,
    smsList: [],
    listError: false,
    index: 0,
    mode: "add",
    listError: false,
    isOpenInfoModal: false
  };

  componentDidMount = () => {
    this.getDataFromServerNew();
  };

  getDataFromServerNew = (params) => {
    this.setState({ listError: false });
    connectServer({
      path: GET_SMS_LIST,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            let newTemp = [...res.data];
            let some = process(newTemp, dataState);
            this.setState({
              smsList: res.data,
              dataResult: Array.prototype.reverse.call(some),
            });
          } else {
            this.setState({
              smsList: [],
              dataResult: null,
              listError: true,
            });
          }
        } else {
          this.setState({ listError: true });
        }
      },
      fail: (err) => {
        this.setState({ listError: true });
      },
    });
  };

  dataStateChange = (event) => {
    this.setState({
      dataResult: process(this.state.smsList, event.dataState),
      dataState: event.dataState,
    });
  };

  handleChange = (fields, value) => {
    fields.status = value;
    var params = {
      clientSmsSettingId: fields.clientSmsSettingId,
      status: value ? "A" : "D",
    };
    connectServer({
      path: DELETE_SMS,
      params: params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          this.getDataFromServerNew();
        }
      },
      fail: (err) => { },
    });
  };

  render() {
    return (
      <Container className="p0 mt15">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <h3 className="marginB0">SMPP</h3>
          <div className="d-flex align-items-center">
            <RSPTooltip text="DLT user guide" position="top">
              <i
                className="icon-sd-circle-info-medium icons-lg blue mr10 cursor-pointer icon-shadow"
                onClick={() => {
                  this.setState({ isOpenInfoModal: true });
                }}
              ></i>
            </RSPTooltip>
            <RSPTooltip text="Add" position="top">
              <i
                className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer icon-shadow"
                onClick={() => {
                  this.setState({ mode: "add", index: 1 });
                  this.props.onChangeIndex(1);
                  this.props.onChangeMode("add");
                }}
              ></i>
            </RSPTooltip>
          </div>
        </div>
        {this.state.dataResult ? (
          <Grid
            data={this.state.dataResult}
            {...this.state.dataState}
            scrollable={"none"}
            sortable={false}
            onDataStateChange={this.dataStateChange}
            className="grid-pagenation-del grid-replaceable"
            messages={{
              itemsPerPage: "data items per page",
            }}
            onPageChange={this.pageChange}
            pageable={{
              //type: 'input',
              pageSizes: true,
              previousNext: true,
            }}
          >
            <GridColumn title=" Service provider" field="serviceProvider" />
            <GridColumn title="SMS type" field="smsType" />
            <GridColumn title="Sender ID" field="senderID" />
            <GridColumn title="Keywords" field="keywords" />
            <GridColumn
              title="Status"
              cell={(props) => {
                return (
                  <td>
                    <div>
                      <Switch
                        className="mt0"
                        onChange={(e) => {
                          this.handleChange(props.dataItem, e.target.value);
                        }}
                        checked={props.dataItem.status ? true : false}
                      />
                    </div>
                  </td>
                );
              }}
            />
            <GridColumn
              title="Action"
              cell={(props) => {
                return (
                  <td className="d-flex justify-content-end">
                    {props.dataItem.status ? (
                      <RSPTooltip text="Edit" position="top">
                        <i
                          className="icon-sd-pencil-edit-medium icons-md blue cursor-pointer"
                          onClick={() => {
                            this.setState({ mode: "edit", index: 1 });
                            this.props.onChangeIndex(1);
                            this.props.getClientSmsSettingId(
                              props.dataItem.clientSmsSettingId
                            );
                            this.props.onChangeMode("edit");
                          }}
                        />
                      </RSPTooltip>
                    ) : null}
                  </td>
                );
              }}
            />
          </Grid>
        ) : (
          <SkeletonTable count={5} isError={this.state.listError} />
        )}
        {this.state.isOpenInfoModal &&
          <DLTInfoComponent isOpen={this.state.isOpenInfoModal} onChangeIsOpen={(value) => {
            this.setState({ isOpenInfoModal: value })
          }} />
        }
      </Container>
    );
  }
}
export default SMSList;
