import React from 'react';
import { RSPrimaryBtn } from 'Components/RSButtons';
const UploadComponent = props => {
    return (
        <>
            <div className="btn-file cp" onClick={props.onClick}>
                <RSPrimaryBtn>{props.text ? props.text : 'Upload'}</RSPrimaryBtn>
                <input type="file" accept={props.type === "all" ? props.type : ".csv"} title="" onChange={(e) => {
                    props.onChange(e.target.files[0].name)
                    e.target.value = null
                }} className="btn-browse-edit" name="uploaded_file"></input>
            </div>
        </>
    )
}
export default UploadComponent