export const myProfileInitialState = {
  emailId: "",
  firstName: "",
  lastName: "",
  otp: "",
  role: "",
  position: "",
  jobFunction: "",
  currency: "",
  language: "",
  timezone: "",
  phoneNo: "",
  countryCodeMobile: "",
  countryCode: "in",
  newCountryCode: "",
  newCountryCodeMobile: "",
  newPhoneNumber: "",
  addressLine: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  dateFormat: "",
  timeFormat: "",
  isDaylightSaving: false,
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  profilePath: null,
  jobFunctionDropdownItem: null,
  jobFunctionDropdownData: [],
  countryDropdownItem: null,
  countryDropdownData: [],
  stateDropdownItem: null,
  stateDropdownData: [],
  cityDropdownItem: null,
  cityDropdownData: [],
  currencyDropdownData: [],
  currencyDropdownItem: null,
  languageDropdownData: [],
  languageDropdownItem: null,
  dateFormatDropdownData: [],
  dateFormatDropdownItem: null,
  timeFormatDropdownData: [],
  timeFormatDropdownItem: null,
  timeZoneDropdownData: [],
  timeZoneDropdownItem: null,
  show: false,
  passwordUpdate: false,
  errMsgEmailId: null,
  errMsgDateFormat: null,
  errMsgTimeFormat: null,
  errMsgLanguage: null,
  errMsgCurrency: null,
  errMsgFirstName: null,
  errMsgLastName: null,
  errMsgRole: null,
  errMsgCountry: null,
  errMsgPhoneNo: null,
  errMsgAddressLine: null,
  errMsgJobFunction: null,
  errMsgCity: null,
  errMsgState: null,
  errMsgZipCode: null,
  errMsgTimezone: null,
  errMsgProfilePath: null,
  errMsgOTP: null,
  errMsgPasswordOTP: null,
  errMsgCurrentPassword: null,
  errMsgNewPassword: null,
  errMsgConfirmPassword: null,
  errMsgNewPhone: null
}
export const keyContactInfoInitialState = {
  contactInfo: {
    lastLoginPath: "",
    channelPartner: "",
    partner: "",
    source: "",
    planId: 0,
    emailId: "",
    isFromVersium: false,
    promoCode: "",
    title: {
      titleId: 1,
      title: 'Mr.'
    },
    firstName: "",
    lastName: "",
    countryCodeMobile: "",
    countryCode: "in",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    otp: "",
    otpToken: "",
    otpSuccess: false,
    isShowConfirmOTP: false,
    isShowOTP: false,
    sendOtpErrMsg: null,
    validateOtpErrMsg: null,
    isShowCoundown: true,
    isShowResendBtn: false,
    defaultOtp: true,
    jobFunctionDropdownItem: null,
    jobFunctionDropdownData: [],
    profilePath: null,
    errMsgTitle: null,
    errMsgJobFunctionName: null,
    errMsgFirstName: null,
    errMsgLastName: null,
    errMsgPhone: null,
    errMsgEmail: null,
    errMsgPassword: null,
    errMsgConfirmPassword: null,
    errMsgProfilePath: null,
    errMsgOTP: null
  },
  brandDetails: {
    companyName: "",
    website: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
    countryDropdownItem: null,
    countryDropdownData: [],
    stateDropdownItem: null,
    stateDropdownData: [],
    cityDropdownItem: null,
    cityDropdownData: [],
    industryDropdownItem: null,
    industryDropdownData: [],
    industry: "-- Select industry --",
    businessTypeDropdownItem: null,
    businessTypeDropdownData: [],
    brandPositioningTypeDropdownItem: null,
    brandPositioningTypeDropdownData: [],
    isShowTickSymbol: false,
    logoPath: null,
    errMsgCompanyName: null,
    errMsgWebsite: null,
    errMsgAddress1: null,
    errMsgAddress2: null,
    errMsgCity: null,
    errMsgZip: null,
    errMsgCountry: null,
    errMsgState: null,
    errMsgIndustry: null,
    errMsB2c: null,
    errMsgBrandPositioning: null,
    errMsgCompanyImgPath: null
  },
  locationSettings: {
    countryLocation: null,
    currencyDropdownData: [],
    currencyDropdownItem: null,
    languageDropdownData: [],
    languageDropdownItem: null,
    dateFormatDropdownData: [],
    dateFormatDropdownItem: null,
    timeFormatDropdownData: [],
    timeFormatDropdownItem: null,
    timeZoneDropdownData: [],
    timeZoneDropdownItem: null,
    dayLightSaving: false,
    errMsgCurrency: null,
    errMsgLanguage: null,
    errMsgDateFormat: null,
    errMsgTimeFormat: null,
    errMsgTimeZone: null,
  }
}

const ANALYTICS_MENU = [
  {
    name: "Communication analytics",
    path: "/analytics",
    page: "/analytics",
    icon: 'icon-sd-analytics-large'
  },
  {
    name: "Audit log report",
    path: "/analytics",
    page: "/analytics/auditlog",
    icon: 'icon-sd-log-report-large'

  },
];
export const headerIcons = [
  { name: "Audience", path: "/audience", disable: false, submenu: false },
  {
    name: "Communication",
    path: "/communication",
    disable: false,
    submenu: false,
  },
  { name: "Dashboard", path: "/", disable: false },
  {
    name: "Analytics",
    path: "/analytics",
    disable: false,
    submenu: true,
    submenuData: ANALYTICS_MENU,
  },
  { name: "Preferences", path: "/preferences", disable: false, submenu: false },
];