import * as React from 'react';
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

// export const ColumnMenu = (props) => {
//     return (
//         <div className="rs-kendo-grid-filter-menu rskgfm">
//             <GridColumnMenuFilter {...props} expanded={true} className="aaaa" />
//         </div>
//     );
// };
export const ColumnMenuCheckboxFilter = (props, data) => {
    return (
        <div className="rs-kendo-grid-filter-menu rskgfm">
            <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} className="bbbb" />
        </div>
    );
};

export const PAGER_CONFIG = {
    info: false,
    // type: 'input',
    pageSizes: [5, 10, 20, 50],
    previousNext: true,
    buttonCount: 4,
    className: 'rs-kendo-pager',
};

export const INITIAL_CONFIG = {
    take: 5,
    skip: 0,
};

export const userDataState = (dataState, data) => {
    return {
        result: process(data.slice(0), dataState),
        dataState: dataState,
    };
};

export const ColumnMenu = (props) => {
    return (
        <div className="rs-kendo-grid-filter-menu rskgfm">
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};

export const isColumnActive = (field, dataState) => {
    return GridColumnMenuFilter.active(field, dataState.filter) || GridColumnMenuSort.active(field, dataState.sort);
};
