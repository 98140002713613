import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

class PredictiveAnalytics extends React.Component {
  render() {
    const insightsPoints = points.map((point) => <li>{point}</li>);
    return (
      <div className="portlet-container portlet-md">
        <div className="portlet-header">
          <h3> Predictive analytics </h3>
        </div>
        <div className="paddingX15">
          <Row className="marginB20">
            <Col md={5}>
              <label>Reach</label>
            </Col>
            <Col md={7}>
              <div className="progressbar-box-status reach">
                <ProgressBar now={19} label={`${19}%`}></ProgressBar>
              </div>
            </Col>
          </Row>
          <Row className="marginB20">
            <Col md={5}>
              <label>Engagement</label>
            </Col>
            <Col md={7}>
              <div className="progressbar-box-status engagement">
                <ProgressBar now={12.3} label={`${12.3}%`}></ProgressBar>
              </div>
            </Col>
          </Row>
          <Row className="marginB20">
            <Col md={5}>
              <label>Conversion</label>
            </Col>
            <Col md={7}>
              <div className="progressbar-box-status conversion">
                <ProgressBar now={5.1} label={`${5.1}%`}></ProgressBar>
              </div>
            </Col>
          </Row>
        </div>

        <hr />
        <div className="portlet-header">
          <h3>Insights</h3>
        </div>
        <Col>
          <ul className="insights-list">
            {insightsPoints}
          </ul>
        </Col>
      </div>
    );
  }
}

export default PredictiveAnalytics;

const points = [
  "The campaigns is likely to perform better if it is blasted during weekends",
  "The campaigns is likely to yield better results on web push channel",
];
