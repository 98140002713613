import TableDetails from 'Container/AuthenticatedModule/Audience/Components/AddAudience/Components/TableDetails';
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class TabContent extends Component {
    state = {  } 
    render() { 
        return (
            <Container className="mb15"><TableDetails tabledetails={null} leftColumnValues={AvailableColumns} rightColumnValues={SelectedColumns}></TableDetails></Container>
        );
    }
}
 
export default TabContent;
const AvailableColumns = [
    { name: "City", selected: false },
    { name: "State", selected: false },
    { name: "Country", selected: false }];
const SelectedColumns = [
        { name: "Name", selected: false },
        { name: "Age", selected: false },
        { name: "Mobile", selected: false },
        { name: "Gender", selected: false },
        { name: "Email", selected: false }];