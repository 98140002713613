import React, { Component } from 'react'
import authContext from 'Helper/StateHandler/auth-context'
import WebLiveDashboard from './Components/WebLiveDashboard';
import MobileLiveDashboard from './Components/MobileLiveDashboard';
import { Container } from 'react-bootstrap';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { DropDownButton } from '@progress/kendo-react-buttons';
import CampaignDashboard from './Components/CampaignDashboard';
import { BootstrapDropdown } from "Components/BootstrapDropdown";
import { BASE_URL, GET_APPROVER_LIST, GET_AUDIENCE_LIST, GET_CAMPAIGN_ATTRIBUTES_LIST, GET_LANGUAGE_DATA } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { convertBase64ToObject, convertObjectToBase64, getAuthClientId, getCampaignMasterData, getEmailAudienceList, getLanguageData, getMobileAudienceList, setCampaignMaster } from 'Helper/Utils/Utils'

class Dashboard extends Component {
  static contextType = authContext
  state = {
    index: 0,
    currentWebdomain: null,
    campaignDropdown: [],
    campaignDropdownSelectedItem: null,
    currentData: {
      key: 'Last 30 days'
    },
    titleDropdown: [
      "Last 30 days",
      "Last 90 days",
      "Last 180 days"
    ]
  }

  arry = [<CampaignDashboard selectedData={this.state.campaignDropdownSelectedItem} />, <MobileLiveDashboard />, <WebLiveDashboard />]

  componentDidMount() {
    // let some = localStorage.getItem("lsUserData")
    window.scrollTo(0, 0);
    getCampaignMasterData(this.context);
    getLanguageData(this.context);
    getEmailAudienceList(this.context);
    getMobileAudienceList(this.context);
    const res = [
      {
        key: 'beta.utimf.com',
        Value: 'https://beta.utimf.com',
        Status: 0,
        DepartmentId: 0,
        CreatedBy: null,
        CreatedDate: null,
        ModifiedBy: null,
        ModifiedDate: null
      },
      {
        key: 'utimf.com',
        Value: 'https://www.utimf.com',
        Status: 0,
        DepartmentId: 0,
        CreatedBy: null,
        CreatedDate: null,
        ModifiedBy: null,
        ModifiedDate: null
      },
      {
        key: 'utiswatantra.utimf.com',
        Value: 'https://utiswatantra.utimf.com',
        Status: 0,
        DepartmentId: 0,
        CreatedBy: null,
        CreatedDate: null,
        ModifiedBy: null,
        ModifiedDate: null
      }
    ]
    const domains = res.map(r => {
      return {
        Value: r.Value,
        key: r.key
      }
    })
    this.setState({
      titleDropdown: domains,
      currentWebdomain: domains[1]
    })
    this.context.globalStateData.setCampaignMasterDropdownData(campaignPlanAssets.data);
  }



  render() {
    return <LayoutPageWrapper>

      <Container className="page-header box-bottom-space">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ flexDirection: "column", display: 'flex' }}>
            {/* <h3 className="marginB0">{dropdownData[this.state.index].text}</h3> */}
            <div className="marginB10 d-flex align-items-center" style={{ flexDirection: "row" }}>
              <h1 className="marginB0">Dashboard</h1>
              {/* <DropDownButton
                  className="gallery-dropdown-button marginR-14"
                  items={dropdownData}
                  icon=" icon-align-vertical-small icons-md blue"
                  onItemClick={(e) => this.setState({index: e.itemIndex})}
              /> */}
            </div>
          </div>
          <div className='d-none'>
            <BootstrapDropdown
              data={this.state.campaignDropdown}
              defaultItem={this.state?.currentData?.key}
              customAlignRight={true}
              className="fs18-btn"
              alignRight
              fieldKey='key'
              onSelect={item => this.setState({ campaignDropdownSelectedItem: item })}
            />
          </div>
        </div>

        {this.arry[this.state.index]}
      </Container>
    </LayoutPageWrapper>
  }
}
export default Dashboard;

const campaignDropdownData = [
  { key: 'Last 30 days', value: 30 },
  { key: 'Last 90 days', value: 90 },
  { key: 'Last 180 days', value: 180 }
]
const dropdownData = [{ text: "Campaign" }, { text: "Mobile  live audience" }, { text: "Web live audience" }];
const campaignPlanAssets = {
  "status": true,
  "data": {
    "campaignAttributeList": [
      {
        "campaignAttributeId": 8,
        "attributeName": "Acquisition"
      },
      {
        "campaignAttributeId": 7,
        "attributeName": "Activation"
      },
      {
        "campaignAttributeId": 1,
        "attributeName": "Awareness"
      },
      {
        "campaignAttributeId": 9,
        "attributeName": "Cross Sell"
      },
      {
        "campaignAttributeId": 5,
        "attributeName": "Greetings"
      },
      {
        "campaignAttributeId": 4,
        "attributeName": "Lead generation"
      },
      {
        "campaignAttributeId": 2,
        "attributeName": "New product launch"
      },
      {
        "campaignAttributeId": 3,
        "attributeName": "Promotion"
      },
      {
        "campaignAttributeId": 11,
        "attributeName": "Retention"
      },
      {
        "campaignAttributeId": 12,
        "attributeName": "Service"
      },
      {
        "campaignAttributeId": 10,
        "attributeName": "Usage"
      },
      {
        "campaignAttributeId": 6,
        "attributeName": "Webinar"
      }
    ],
    "productCategorysList": [
      {
        "categoryId": 1,
        "categoryName": "Accounts & Deposits"
      },
      {
        "categoryId": 3,
        "categoryName": "Cards"
      },
      {
        "categoryId": 4,
        "categoryName": "Demat"
      },
      {
        "categoryId": 7,
        "categoryName": "Forex"
      },
      {
        "categoryId": 10,
        "categoryName": "IFB"
      },
      {
        "categoryId": 6,
        "categoryName": "Insurance"
      },
      {
        "categoryId": 5,
        "categoryName": "Investments"
      },
      {
        "categoryId": 2,
        "categoryName": "Loans"
      },
      {
        "categoryId": 8,
        "categoryName": "Premier Banking"
      },
      {
        "categoryId": 9,
        "categoryName": "Private Banking"
      }
    ],
    "dynamicList": [],
    "mobileAppsList": [
      {
        "appName": "Resusltick report",
        "appGuid": "b402cecd-8cce-46c2-a59f-e2fe267882c3"
      },
      {
        "appName": "Vision bank",
        "appGuid": "29aa6d8b-f3fd-485f-b6de-e185441e33f8"
      },
      {
        "appName": "Vision retail",
        "appGuid": "a9fed066-6c48-435c-87b5-fed89ed8bff9"
      }
    ],
    "domainsList": [
      {
        "sdkDomain": "mask.tradeers.com",
        "domainUrl": "https://mask.tradeers.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybanknew.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "mybank.com",
        "domainUrl": "https://mybank.com"
      },
      {
        "sdkDomain": "visionbank.com",
        "domainUrl": "https://visionbank.com"
      },
      {
        "sdkDomain": "visionbank.com",
        "domainUrl": "https://visionbank.com"
      },
      {
        "sdkDomain": "visionbank.com",
        "domainUrl": "https://visionbank.com"
      },
      {
        "sdkDomain": "visionbank.com",
        "domainUrl": "https://visionbank.com"
      }
    ]
  },
  "message": "success"
}
