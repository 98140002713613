import React from 'react'
import msLogoWhite from 'Assets/Images/marketing-star-logo-wt.svg'
import msLogo from 'Assets/Images/marketing-star-logo-color.svg'
import styled from 'styled-components'
import { getServerConfig, getUserData } from 'Helper/Utils/Utils'

export const RSBrandLogo = (props) => {
    const imageUrl = getServerConfig().slice(0, -1)
    return (
        <>
            {
                props.inner
                    ? <>
                        {/* <h1 className="brand-logo-txt">Marketing Sthgfykfygfar</h1> */}
                        {/* <BrandInnerLogo src={(getUserData()?.clientId === 16 || getUserData()?.clientId === 1604) ? imageUrl + getUserData()?.clientDetailsLocalization?.logoPath : msLogoWhite} alt={msLogoWhite} className="" /> */}
                        {/* <BrandInnerLogo src={getUserData() ? imageUrl + getUserData()?.clientDetailsLocalization?.logoPath : msLogoWhite} alt={msLogoWhite} className="" /> */}
                        <BrandInnerLogo src={msLogoWhite} alt={msLogoWhite} className="" />
                    </>
                    : <div className="portlet-box-head">
                        {/* <h1 className="brand-logo-login">Marketing Star</h1> */}
                        <BrandLogo src={msLogo} alt={msLogo} className="" />
                    </div>
            }
        </>
    )
}


export const BrandLogo = styled.img`
    max-width: 235px;
`
export const BrandInnerLogo = styled.img`
    max-width: 235px;
`