import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CampaignAnalytics from "./CampaignAnalytics/CampaignAnalytics";
import DetailAnalytics from "./DetailAnalytics/DetailAnalytics";
import AuditLog from "./AuditLog/Index";

const AnalyticsHome = (props) => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <CampaignAnalytics />
      </Route>
      <Route path={`${match.url}/detail-analytics`}>
        <DetailAnalytics />
      </Route>
      <Route path={`${match.url}/auditlog`}>
        <AuditLog />
      </Route>
    </Switch>
  );
};

export default AnalyticsHome;
