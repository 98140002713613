export const fontData = [
    {
        fdontSize: '12',
        fontType: 'xxs',
        description: 'Breadcrumb, chart label, Portlet footer small text'
    },
    {
        fdontSize: '15',
        fontType: 'xs',
        description: 'Chart heading, small text, chart legend, footer text'
    },
    {
        fdontSize: '16',
        fontType: 'xsm',
        description: 'Text box, default, Label'
    },
    {
        fdontSize: '18', // 19
        fontType: 'sm',
        description: 'Section heading, Tabs, button text, Portlet tab label'
    },
    {
        fdontSize: '21',
        fontType: 'xmd',
        description: 'Portlet heading, Section heading, values, main tab heading'
    },
    {
        fdontSize: '24',
        fontType: 'md',
        description: 'Sub heading'
    },
    {
        fdontSize: '32',
        fontType: 'lg',
        description: 'Page heading'
    },
    {
        fdontSize: '42',
        fontType: 'xl',
        description: 'Login screen subheading'
    },
    {
        fdontSize: '52',
        fontType: 'xxl',
        description: 'Login screen heading'
    }
]

export const iconData = [
    {
        fdontSize: '15',
        fontType: 'sm',
        description: 'small'
    },
    {
        fdontSize: '24',
        fontType: 'md',
        description: 'medium'
    },
    {
        fdontSize: '32',
        fontType: 'lg',
        description: 'large'
    }
]