import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RTabbar } from "Components/RChart/RTabbar";

import { EMAIL_TABS } from "../../constants";
import AllTemplate from "../Components/AllTemplate";
import MyTemplate from "../Components/Mytemplate";
import CreateNewTemplate from "../Components/CreateNewTemplate";

const EmailBuilder = () => {
  const [tabInd, setTabInd] = useState(0);
  return (
    <>
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <div className="d-flex justify-content-between mt15">
            <h1 className="header-title-margin">Email template gallery</h1>
          </div>

          <div className="tabs-filled-component-del">
            <RTabbar
              defaultSelectedItem={tabInd}
              defaultClass="tabDefault"
              dynamicTab="tabs-filled-component marginB0"
              activeClass="tabDefault active"
              tabData={EMAIL_TABS}
              callBack={(item, index) => {
                setTabInd(index);
              }}
            />
          </div>
          {tabInd == 0 && <AllTemplate page={"email"} />}
          {tabInd == 1 && <MyTemplate page={"email"} />}
          {tabInd == 2 && <CreateNewTemplate page={"email"} mainClass='temp-card-create' />}
        </Container>
      </LayoutPageWrapper>
    </>
  );
};

export default withRouter(EmailBuilder);
