import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const RSTinyMceInlineEditor = ({
  name,
  initialValue,
  onNodeChange,
  init,
  handleChange,
  value = "",
  ...rest
}) => {
  return (
    <div className="editor-text">
      <Editor
        apiKey={"xpia03ud0id2qqcbq2ko0z4pu85d5cyd1rzeu1cjwdp1rifl"}
        initialValue={initialValue}
        name={name}
        init={init}
        allowedEvents={"onBlur"}
        // onEditorChange={(content, editor) => {
        //   handleChange(content, editor.name);
        // }}
        onBlur={(content, editor) => {
          handleChange(content, editor);
        }}
        onNodeChange={onNodeChange}
        {...rest}
      />
      <span className="text-danger"> </span>
    </div>
  );
};

RSTinyMceInlineEditor.defaultProps = {
  defaultValue: "",
  className: "",
  required: false,
  handleChange: () => { },
};

export default RSTinyMceInlineEditor;
