import React from "react";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { Container } from "react-bootstrap";
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { withRouter, useHistory } from "react-router-dom";
import { PlainSearchView } from "Components/SearchView/SearchView";
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { CREATE_FORM, GET_FORM_LIST_ALL } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { useContext } from "react";
import { useState } from "react";
import { connectServer } from "Helper/Network/networkHandler";
import { useEffect } from "react";
import CustomCell from "./Components/CustomCell";
import { dateFormatWithHyphen, getServerConfig } from "Helper/Utils/Utils";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { process } from "@progress/kendo-data-query";
import { RSPTooltip } from "Components/Tooltip";
const defaultDataState = {
    skip: 0,
    take: 5,
    sort: [
        {
            field: "orderDate",
            dir: "desc",
        },
    ],
};

const FormGenerator = (props) => {
    const context = useContext(authContext);
    const history = useHistory();
    const dropDownData = ["All", "Personal", "Business", "Privilege"];

    const [formList, setFormList] = useState([]);
    const [listError, setListError] = useState(false);
    const [dataState, setDataState] = useState(defaultDataState);
    const [dataResult, setDataResult] = useState(null);
    const [message, setMessage] = useState("");
    const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);
    const [isShowDateRange, setIsShowDateRange] = useState(true);
    const [fetchDataParams, setFetchDataParams] = useState({
        formName: "",
        startDate: "",
        endDate: "",
        pageNumber: 1,
        itemsPerPage: 5,
    });
    const [totalCount, setTotalCount] = useState(0);
    useEffect(() => {
        getFormListAllData(fetchDataParams);
    }, []);

    const getFormListAllData = (params) => {
        setFormList([]);
        setDataResult(null)
        setListError(false);
        connectServer({
            path: GET_FORM_LIST_ALL,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            params,
            ok: (res) => {
                if (res.status && !!res.data?.formsList) {
                    let newTemp = [...res?.data?.formsList];
                    const tempDataState = {
                        skip: 0,
                        take: params?.itemsPerPage
                    };
                    let some = process(newTemp, tempDataState);
                    setFormList(res?.data?.formsList);
                    setTotalCount(res?.data?.totalCount);
                    setDataResult(Array.prototype.reverse.call(some));
                    setListError(false);
                } else {
                    setDataResult(null)
                    setListError(true);
                }
            },
            fail: (err) => {
                setDataResult(null)
                setListError(true);
            },
        });
    };

    const dataStateChange = (event) => {
        const { skip, take } = event.dataState;
        const currentPage = Math.floor(skip / take + 1);
        setDataResult(process(formList, event.dataState));
        setDataState(event.dataState);
        let tempFetchDataParams = { ...fetchDataParams };
        tempFetchDataParams.itemsPerPage = take;
        tempFetchDataParams.pageNumber = currentPage;
        setFetchDataParams(tempFetchDataParams);
        getFormListAllData(tempFetchDataParams);
    };

    const MyCustomCell = (props) => (
        <CustomCell {...props} getFormListAllData={(value) => { getFormListAllData(fetchDataParams) }} />
    );

    // search and date
    const closeDateRangePicker = (status, dates) => {
        if (!status && !!dates) {
            let tempFetchDataParams = { ...fetchDataParams };
            tempFetchDataParams.startDate = dateFormatWithHyphen(new Date(dates?.start));
            tempFetchDataParams.endDate = dateFormatWithHyphen(new Date(dates?.end));
            tempFetchDataParams.pageNumber = 1;
            tempFetchDataParams.itemsPerPage = 5;
            getFormListAllData(tempFetchDataParams);
            setFetchDataParams(tempFetchDataParams)
            setDataState(dataState)
        }
        setIsShowDateRangePicker(status);
    };

    return (
        <>
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className="header-title d-flex justify-content-between">
                        <h1>Form builder</h1>
                        <div
                            className="back-text"
                            onClick={() => history.push(`/preferences`)}
                        >
                            <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                            Back
                        </div>
                    </div>

                    <hr className="blue" />
                    <div className="d-flex justify-content-between mt15 mb15">
                        <div></div>
                        <div className="d-flex align-items-center">
                            <RSPDateRangePicker
                                class="marginR15"
                                enable={true}
                                show={isShowDateRange}
                                isShowDateRangePicker={isShowDateRangePicker}
                                closeDateRangePicker={(status, date) =>
                                    closeDateRangePicker(status, date)
                                }
                                cancelBtnClicked={() => {
                                    setIsShowDateRangePicker(false);
                                }}
                            />
                            <PlainSearchView
                                isFromSegmentation={false}
                                enable={true}
                                cls="marginR15"
                                onSearch={(searchValue) => {
                                    let temp = { ...fetchDataParams };
                                    temp.formName = searchValue;
                                    temp.pageNumber = 1;
                                    temp.itemsPerPage = 5;
                                    setDataState(defaultDataState)
                                    setFetchDataParams(temp);
                                    getFormListAllData(temp);
                                }}
                            />
                            <RSPTooltip text="Create" position="top">
                                <i
                                    className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer"
                                    onClick={() => {
                                        let baseUrl = getServerConfig();
                                        const sessionObject = JSON.parse(
                                            localStorage.getItem("session")
                                        );
                                        const accessToken = sessionObject
                                            ? sessionObject.accessToken
                                            : null;
                                        let fromEnvi = window.location.origin.includes("app")
                                            ? "app"
                                            : window.location.origin.includes("sit")
                                                ? "sit"
                                                : "run";
                                        window.location.href = `${baseUrl}${CREATE_FORM}?accessToken=${encodeURIComponent(
                                            accessToken
                                        )}&from=${fromEnvi}`;
                                    }}
                                ></i>
                            </RSPTooltip>
                        </div>
                    </div>
                    {dataResult ? (
                        <Grid
                            data={dataResult}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            scrollable={"none"}
                            className="grid-pagenation-del grid-replaceable"
                            sortable={true}
                            total={totalCount}
                            pageable={{
                                //type: 'input',
                                pageSizes: true,
                                previousNext: true,
                            }}
                        >
                            <GridColumn
                                columnMenu={ColumnMenu}
                                field="formName"
                                title="Form name"
                            />
                            <GridColumn columnMenu={ColumnMenu} field="type" title="Type" />
                            <GridColumn
                                columnMenu={ColumnMenu}
                                field="linkedCommunication"
                                title="Linked communication"
                            />
                            <GridColumn
                                width={200}
                                field="status"
                                title="Actions"
                                cell={MyCustomCell}
                            />
                        </Grid>
                    ) : (
                        <SkeletonTable
                            text={"to generate a form"}
                            count={5}
                            isError={listError}
                        />
                    )}
                </Container>
            </LayoutPageWrapper>
        </>
    );
};

export default withRouter(FormGenerator);

const ColumnMenu = (props) => {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};
