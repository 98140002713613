import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { RSInput2 } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { camapignNameisPresent, createSmartlinkObject, getCampaignPlanById, savePlan } from '../SmartLink/SLObjectManager';
import { CAMPAIGN_NAME, CAMPAIGN_TYPE, PRODUCT_TYPE, SECONDARY_GOAL, PRIMARY_GOAL, START_DATE, END_DATE, GOAL_PERCENTAGE, SECONDARY_GOAL_PERCENTAGE } from 'Helper/Constants/validationMessage';
import { getServerConfig, isEmpty } from 'Helper/Utils/Utils';
import * as ConstStr from "Helper/Constants/validationMessage";
import authContext from "Helper/StateHandler/auth-context"
import GoalModal from "../Notification/Components/GoalModal";
import {
  GET_CAMPAIGN_STATUS,
  MDC_CANVAS_URL
} from "Helper/Constants/endPoint";
import { getYearMonthDate } from 'Helper/Utils/Utils';
import { RSPTooltip } from 'Components/Tooltip';
import { connectServer } from 'Helper/Network/networkHandler';

var campaign = {}

const MultiDimension = props => {

  const defaultValue = new Date();
  let history = useHistory();
  const context = useContext(authContext)

  const [mdcUrl, setMdcUrl] = useState(null)
  const [fromEnvironment, setFromEnvironment] = useState(null)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [errCampaignName, setErrCampaignName] = useState(null)
  const [errCampaignType, setErrCampaignType] = useState(null)
  const [errProductType, setErrProductType] = useState(null)
  const [errPrimaryGoal, setErrPrimaryGoal] = useState(null)
  const [errPrimaryGoalType, setErrPrimaryGoalType] = useState(null);
  const [errSecondaryGoal, setErrSecondaryGoal] = useState(null)
  const [errSecondaryGoalType, setErrSecondaryGoalType] = useState(null);
  const [errGoalPercentage, setErrGoalPercentage] = useState(null)
  const [errSecondaryGoalPercentage, setErrSecondaryGoalPercentage] = useState(null)
  const [errStartDate, setErrStartDate] = useState(null);
  const [errEndDate, setErrEndDate] = useState(null);
  const [camapingStartDate, setCampaignStartDate] = useState('')
  const [camapingEndDate, setCampaignEndDate] = useState('')
  const [campaignJson, setCampaignJson] = useState(props.json)
  const [editClickOff, setEditClickOff] = useState(false);
  const [endDateMinValue, setEndDateMinValue] = useState('');
  const [endDateMaxvalue, setEndDateMaxValue] = useState("");
  const [isShowSecondaryGoal, setIsShowSecondaryGoal] = useState(false);
  const [isOpenGoalModal, setIsOpenGoalModal] = useState(false);
  const [isEnableButton, setIsEnableButton] = useState(true)

  useEffect(() => {
    setMdcUrl(getServerConfig() + MDC_CANVAS_URL)
    let fromEnvi = window.location.href.includes("app") ? "app" : window.location.href.includes("sit") ? "sit" : "run";
    setFromEnvironment(fromEnvi);
    if (props.campaignDetails.mode === 'add' && props.campaignId === 0) {
      let object = createSmartlinkObject(false)
      object.campaignId = 0
      setCampaignDetails(object)
    } else {
      getCampaignStatus(props.campaignId)
      getCampaign();
    }
  }, []);

  const getCampaign = () => {
    getCampaignPlanById(props.campaignId, context, props.attributes, (campaignDetails => {
      setCampaignDetails(campaignDetails)
      if (campaignDetails.secondaryGoal !== "") {
        setIsShowSecondaryGoal(true);
      }
      var someDate = new Date(campaignDetails.startDate);
      var numberOfDaysToAdd = 3;
      var maxNumOfDaysToAdd = 180;
      var result = someDate?.setDate(
        someDate.getDate() + numberOfDaysToAdd
      );
      var endResult = someDate?.setDate(
        someDate.getDate() + maxNumOfDaysToAdd
      );

      let date = new Date(result);
      setEndDateMinValue(date);
      setEndDateMaxValue(new Date(endResult))
    }))
  }

  const getCampaignStatus = (campaignID) => {
    let params = {
      "campaignId": campaignID,
    }
    connectServer({
      path: GET_CAMPAIGN_STATUS,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          const clickOffStatus = props.campaignDetails.mode === "add"
            ? false
            : (res.data === 3 || res.data === 4 || res.data === 101)
              ? true
              : false;
          setEditClickOff(clickOffStatus)
        }
      },
      fail: err => { }
    })
  }


  const submit = (save, campaignHeader) => {
    let error = false
    if (isEmpty(campaignDetails.campaignName)) {
      setErrCampaignName(CAMPAIGN_NAME)
      error = true
    }
    if (isEmpty(campaignDetails.campaignAttribute.attributeName) || campaignDetails.campaignAttribute.attributeName.toLowerCase().includes('select')) {
      setErrCampaignType(CAMPAIGN_TYPE)
      error = true
    }
    if (isEmpty(campaignDetails.productCategory.categoryName) || campaignDetails.productCategory.categoryName.toLowerCase().includes('select')) {
      setErrProductType(PRODUCT_TYPE)
      error = true
    }
    if (isShowSecondaryGoal) {
      if (isEmpty(campaignDetails.secondaryGoal) || campaignDetails.secondaryGoal.toLowerCase().includes("select")) {
        setErrSecondaryGoal(SECONDARY_GOAL);
        error = true;
      }
      if (campaignDetails.secondaryGoal !== "Reach") {
        if (isEmpty(campaignDetails.secondaryGoalType) || campaignDetails.secondaryGoalType.toLowerCase().includes("select")) {
          setErrSecondaryGoalType("Select " + campaignDetails.secondaryGoal.toLowerCase() + " type")
          error = true;
        }
      }
    }
    if (isEmpty(campaignDetails.goal) || campaignDetails.goal.toLowerCase().includes("select")) {
      setErrPrimaryGoal(PRIMARY_GOAL);
      error = true;
    }
    if (campaignDetails.goal !== "Reach") {
      if (isEmpty(campaignDetails.goalType) || campaignDetails.goalType.toLowerCase().includes("select")) {
        setErrPrimaryGoalType("Select " + campaignDetails.goal.toLowerCase() + " type")
        error = true;
      }
    }
    if (isEmpty(campaignDetails.startDate.toString())) {
      setErrStartDate(START_DATE);
      error = true;
    }
    if (isEmpty(campaignDetails.endDate.toString())) {
      setErrEndDate(END_DATE);
      error = true;
    }
    if (isEmpty(campaignDetails.goalPercentage)) {
      setErrGoalPercentage(GOAL_PERCENTAGE);
      error = true;
    } else if (Math.abs(campaignDetails.goalPercentage) <= 0.1 && Math.abs(campaignDetails.goalPercentage) >= 100) {
      setErrGoalPercentage("Only values 0.1-100 are accepted");
      error = true;
    }
    if (isShowSecondaryGoal) {
      if (isEmpty(campaignDetails.secondaryGoalPercentage)) {
        setErrSecondaryGoalPercentage(SECONDARY_GOAL_PERCENTAGE);
        error = true;
      } else if (Math.abs(campaignDetails.secondaryGoalPercentage) <= 0.1 && Math.abs(campaignDetails.secondaryGoalPercentage) >= 100) {
        setErrSecondaryGoalPercentage("Only values 0.1-100 are accepted");
        error = true;
      }
    }
    if (isShowSecondaryGoal && campaignDetails.goal === campaignDetails.secondaryGoal) {
      setErrPrimaryGoal("Primary and secondary goal should not be same");
      setErrSecondaryGoal("Primary and secondary goal should not be same");
      error = true;
    }
    if (!error) {
      savePlan(campaignDetails, "M", context, response => {
        if (response.status && response.message === "Campaign saved success") {

          if (save === 0) {
            history.push({ pathname: `/communication` })
          } else {
            const sessionStr = localStorage.getItem('session')
            const sessionObject = JSON.parse(sessionStr)
            const accessToken = sessionObject ? sessionObject.accessToken : null
            const templateId = !!props.campaignDetails.templateId ? window.btoa(props.campaignDetails.templateId) : null
            const campId = window.btoa(response?.data?.campaignId)
            if (templateId !== null) {
              window.location.href = mdcUrl + "?CampId=" + campId + '&accessToken=' + encodeURIComponent(accessToken) + "&templateId=" + templateId + '&from=' + fromEnvironment;
            } else {
              window.location.href = mdcUrl + "?CampId=" + campId + '&accessToken=' + encodeURIComponent(accessToken) + '&from=' + fromEnvironment;
            }
          }
        }
      })

    }
  }

  const ButtonContainer = () => {
    return <Container>
      <Row>
        <div className="btn-container d-flex justify-content-end pr20 mb20">
          <RSSecondaryBtn className="paddingR0" onClick={() => {
            history.push({ pathname: `/communication` });
          }}>Cancel</RSSecondaryBtn>
          <RSSecondaryBtn className={`blue ${isEnableButton ? "" : "click-off"}`}
            onClick={() => {
              if (editClickOff && props.campaignDetails.status === 9) {
                history.push({ pathname: `/communication` });
              } else {
                submit(0);
              }

            }}>Save</RSSecondaryBtn>
          <RSPrimaryBtn className={isEnableButton ? "" : "click-off"} onClick={() => {
            const _campaignHeader = {
              name: campaignDetails.campaignName,
              startDate: campaignDetails.startDate,
              endDate: campaignDetails.endDate,
              goal: campaignDetails.goal,
              type: campaignDetails.campaignAttribute.attributeName
            }
            if (editClickOff && props.campaignDetails.status === 9) {
              const sessionStr = localStorage.getItem('session')
              const sessionObject = JSON.parse(sessionStr)
              const accessToken = sessionObject ? sessionObject.accessToken : null
              const campId = window.btoa(props.campaignId)
              window.location.href = mdcUrl + "?CampId=" + campId + '&accessToken=' + encodeURIComponent(accessToken);

            } else {
              submit(1, _campaignHeader);
            }
          }}>Next</RSPrimaryBtn>
        </div>
      </Row>
    </Container>
  }

  const ProductTypeRowComponent = () => {
    return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
      <Col md={{ span: 3, offset: 1 }}>
        <label className="label-row label-dark">
          Communication / Product type
        </label>
      </Col>
      <Col md={7}>
        <Row>
          <Col md={6}>
            <div className="position-relative">
              {errCampaignType && <div className="validation-message">{errCampaignType}</div>}
              <RSDropdownList
                data={props.attributes.campaignAttributeList}
                className="required"
                value={
                  campaignDetails.campaignAttribute !== undefined &&
                    campaignDetails.campaignAttribute.attributeName !== ''
                    ? campaignDetails.campaignAttribute
                    : '-- Select Target list  --'
                }
                textField='attributeName'
                dataItemKey='campaignAttributeId'
                defaultItem={{ campaignAttributeId: 0, attributeName: "-- Select communication type --" }}
                onChange={e => {
                  props.onChange('camapaignTypes', e.target.value)
                  let cd = { ...campaignDetails }
                  cd.campaignAttribute = e.target.value
                  setCampaignDetails(cd)
                  let errMsg = e.target.value.attributeName.length > 0
                    ? null
                    : CAMPAIGN_TYPE
                  setErrCampaignType(errMsg)
                }} />
            </div>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errProductType && <div className="validation-message">{errProductType}</div>}
              <RSDropdownList
                className="required"
                data={props.attributes.productCategorysList}
                value={
                  campaignDetails.productCategory !== undefined &&
                    campaignDetails.productCategory.categoryName !== ''
                    ? campaignDetails.productCategory
                    : '-- Select  --'
                }
                textField='categoryName'
                dataItemKey='categoryId'
                defaultItem={{ categoryId: 0, categoryName: "-- Select product type --" }}
                onChange={(e) => {
                  let cd = { ...campaignDetails }
                  cd.productCategory = e.target.value
                  setCampaignDetails(cd)
                  props.onChange('productTypes', e.target.value)
                  let errMsg = e.target.value.categoryName.length > 0
                    ? null
                    : CAMPAIGN_TYPE
                  setErrProductType(errMsg)
                }} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  const SecondaryGoalRowComponent = () => {
    return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
      <Col md={{ span: 3, offset: 1 }}>
        <label className="label-row label-dark">Secondary goal</label>
      </Col>
      <Col md={7}>
        <Row>
          <Col md={6}>
            <div className="position-relative">
              {errSecondaryGoal && <div className="validation-message">{errSecondaryGoal}</div>}
              <RSDropdownList
                className="required"
                data={campaignJson.primaryGoal}
                value={campaignDetails.secondaryGoal !== "" ? campaignDetails.secondaryGoal : "-- Goal --"}
                defaultItem={"-- Goal --"}
                onChange={(e) => {
                  if (e.target.value !== "-- Goal --") {
                    let cd = { ...campaignDetails };
                    cd.secondaryGoal = e.target.value;
                    cd.secondaryGoalType = "-- Select --"
                    setErrSecondaryGoalType(null);
                    let errMsg = e.target.value.length > 0 ? null : SECONDARY_GOAL;
                    setErrSecondaryGoal(errMsg);
                    let errGoalMsg =
                      e.target.value.length > 0
                        ? null
                        : SECONDARY_GOAL;
                    setErrSecondaryGoalPercentage(errGoalMsg);
                    setCampaignDetails(cd);
                    props.onChange("reachType", e.target.value);
                    if (e.target.value !== "" && campaignDetails.goal !== "") {
                      if (e.target.value !== campaignDetails.goal) {
                        setErrPrimaryGoal(null);
                      } else {
                        setErrPrimaryGoal(
                          "Primary and secondary goal should not be same"
                        );
                        setErrSecondaryGoal(
                          "Primary and secondary goal should not be same"
                        );
                      }
                    }
                  } else {
                    let cd = { ...campaignDetails };
                    cd.secondaryGoal = "";
                    cd.secondaryGoalType = "-- Select --"
                    setCampaignDetails(cd);
                  }
                }}
              />
            </div>
          </Col>
          <SecondaryGoalPercentage
            editClickOff={editClickOff}
            campaignDetails={campaignDetails}
            errSecondaryGoalPercentage={errSecondaryGoalPercentage}
            getValues={(goalValue, errMsg) => {
              setCampaignDetails(goalValue)
              setErrSecondaryGoalPercentage(errMsg)
            }}
          />
        </Row>
      </Col>
      <Col>
        <RSPTooltip text="Remove" position="top">
          <i className='icon-sd-circle-minus-fill-edge-medium icons-md red posr top10 left5 cp' onClick={() => {
            let cd = { ...campaignDetails };
            cd.secondaryGoal = "";
            cd.secondaryGoalType = ""
            cd.secondaryGoalPercentage = "";
            cd.errSecondaryGoalPercentage = "";
            setCampaignDetails(cd)
            setIsShowSecondaryGoal(!isShowSecondaryGoal)
            setErrPrimaryGoal(null);
            setErrSecondaryGoal(null);
          }}></i>
        </RSPTooltip>
      </Col>
    </Row>
  }

  const SecondaryGoalPercentage = (props) => {
    const [value, setValue] = useState(props.campaignDetails.secondaryGoalPercentage);
    const [errMsg, setErrMsg] = useState(props.errSecondaryGoalPercentage);
    return (
      <Col md={6} className={props.editClickOff ? "click-off" : ""}>
        <RSInput2
          ph="Secondary goal percentage"
          val={value}
          required={true}
          errorMessage={errMsg}
          keyboardType="number"
          cb={text => {
            const re = /^\d*\.?\d*$/;
            if (text.length > 6) {
              setErrMsg("Limit exists");
              return
            }
            if (text === '' || re.test(text)) {
              let errMsg = text.length > 0 ? null : ConstStr.SECONDARY_GOAL_PERCENTAGE;
              setValue(text);
              setErrMsg(errMsg);
            } else {
              let errMsg = text.length > 0 ? null : ConstStr.SECONDARY_GOAL_PERCENTAGE;
              setErrMsg(errMsg);
            }
          }}
          ob={text => {
            let cd = { ...props.campaignDetails };
            if (text !== "") {
              let value = Math.abs(text);
              let errMsg = (value >= 0.1 && value <= 100) ? null : "Only values 0.1-100 are accepted";
              cd.secondaryGoalPercentage = errMsg === null ? value : "";
              setErrMsg(errMsg);
              props.getValues(cd, errMsg);
            } else {
              cd.secondaryGoalPercentage = text;
              setErrMsg(ConstStr.SECONDARY_GOAL_PERCENTAGE);
              props.getValues(cd, ConstStr.SECONDARY_GOAL_PERCENTAGE)
            }

          }}
        />
        <div className="input-outer-set mt4">
          <span>%</span>
        </div>
      </Col>
    );
  };
  const SecondaryCampaignGoalColComponent = () => {
    if (
      campaignDetails.secondaryGoal !== campaignJson.primaryGoal[0] &&
      campaignDetails.secondaryGoal.length > 0
    ) {
      return (
        <Row
          className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""
            }`}
        >
          <Col md={{ span: 3, offset: 1 }}>
            <label className="label-row label-dark">
              {"Secondary " + campaignDetails.secondaryGoal.toLowerCase() + " type"}
            </label>
          </Col>
          <Col md={7}>
            <div className="position-relative">
              {errSecondaryGoalType && <div className="validation-message">{errSecondaryGoalType}</div>}
              <RSDropdownList
                className="required"
                data={
                  campaignDetails.secondaryGoal === campaignJson.primaryGoal[1]
                    ? campaignJson.engamentTypes
                    : campaignJson.conversationTypes
                }
                defaultItem={"-- Select --"}
                value={campaignDetails.secondaryGoalType}
                onChange={(e) => {
                  let cd = { ...campaignDetails };
                  let errMsg = e.target.value === "-- Select --" ? "Select " + cd.secondaryGoal.toLowerCase() + " type" : null;
                  setErrSecondaryGoalType(errMsg);
                  cd.secondaryGoalType = e.target.value;
                  setCampaignDetails(cd);
                }}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const PrimaryGoalRowComponent = () => {
    return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
      <Col md={{ span: 3, offset: 1 }}>
        <label className="label-row label-dark">Primary goal</label>
      </Col>
      <Col md={7}>
        <Row>
          <Col md={6}>
            <div className="position-relative">
              {errPrimaryGoal && <div className="validation-message">{errPrimaryGoal}</div>}
              <RSDropdownList
                className="required"
                data={campaignJson.primaryGoal}
                value={campaignDetails.goal !== "" ? campaignDetails.goal : "-- Goal --"}
                defaultItem={"-- Goal --"}
                onChange={(e) => {
                  if (e.target.value !== "-- Goal --") {
                    let cd = { ...campaignDetails };
                    cd.goal = e.target.value;
                    cd.goalType = "-- Select --"
                    setErrPrimaryGoalType(null);
                    let errMsg = e.target.value.length > 0 ? null : PRIMARY_GOAL;
                    setErrPrimaryGoal(errMsg);
                    let errGoalMsg =
                      e.target.value.length > 0
                        ? null
                        : GOAL_PERCENTAGE;
                    setErrGoalPercentage(errGoalMsg);
                    if (e.target.value !== "" && campaignDetails.secondaryGoal !== "") {
                      if (e.target.value !== campaignDetails.secondaryGoal) {
                        setErrSecondaryGoal(null);
                      } else {
                        setErrPrimaryGoal(
                          "Primary and secondary goal should not be same"
                        );
                        setErrSecondaryGoal(
                          "Primary and secondary goal should not be same"
                        );
                      }
                    }
                    setCampaignDetails(cd);
                    props.onChange("reachType", e.target.value);
                  } else {
                    let cd = { ...campaignDetails };
                    cd.goal = "";
                    cd.goalType = "-- Select --"
                    setCampaignDetails(cd);
                  }
                }}
              />
            </div>
          </Col>
          <GoalPercentage
            editClickOff={editClickOff}
            campaignDetails={campaignDetails}
            errGoalPercentage={errGoalPercentage}
            getValues={(goalValue, errMsg) => {
              setCampaignDetails(goalValue)
              setErrGoalPercentage(errMsg)
            }}
          />
        </Row>
      </Col>
      <Col>
        <RSPTooltip text="Add" position="top">
          <i className={`icon-sd-circle-plus-fill-edge-medium icons-md blue posr top10 left5 cp ${campaignDetails.goalPercentage !== "" ? isShowSecondaryGoal ? "click-off" : "" : "click-off"}`} onClick={() => {
            setIsOpenGoalModal(!isOpenGoalModal);
          }}></i>
        </RSPTooltip>
      </Col>
    </Row>
  }

  const CampaignGoalColComponent = () => {
    return (
      <>
        {(campaignDetails.goal !== campaignJson.primaryGoal[0] && campaignDetails.goal.length > 0) ?
          <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row label-dark">
                {campaignDetails.goal + " type"}
              </label>
            </Col>
            <Col md={7}>
              <div className="position-relative">
                {errPrimaryGoalType && <div className="validation-message">{errPrimaryGoalType}</div>}
                <RSDropdownList
                  className="required"
                  defaultItem={"-- Select --"}
                  data={
                    campaignDetails.goal === campaignJson.primaryGoal[1]
                      ? campaignJson.engamentTypes
                      : campaignJson.conversationTypes
                  }
                  value={
                    campaignDetails.goalType
                  }
                  onChange={(e) => {
                    let cd = { ...campaignDetails };
                    let errMsg = e.target.value === "-- Select --" ? "Select " + cd.goal.toLowerCase() + " type" : null;
                    setErrPrimaryGoalType(errMsg);
                    cd.goalType = e.target.value;
                    setCampaignDetails(cd);
                  }}
                />
              </div>
            </Col>
          </Row>
          : null}
      </>
    )
  }

  const CampaignPeriodRowComponent = () => {
    return <Row className={`custom-column-del smartlink-col-del `}>
      <Col md={{ span: 3, offset: 1 }}>
        <label className="label-row label-dark">Communication period</label>
      </Col>
      <Col md={7}>
        <Row>
          <Col md={6} className={editClickOff ? "click-off" : ""}>
            <div className="position-relative">
              {errStartDate && <div className="validation-message">{errStartDate}</div>}
              <div className="rspdate-picker">
                <Tooltip anchorElement="target" position="top">
                  <DatePicker
                    format={"MMM dd, yyyy"}
                    placeholder={"Start date"}
                    value={campaignDetails.startDate !== '' ? new Date(campaignDetails.startDate) : null}
                    min={defaultValue}
                    onChange={(e) => {
                      let formatDates = getYearMonthDate(e.target.value);
                      setCampaignStartDate(formatDates);
                      var someDate = e.target.value;
                      var numberOfDaysToAdd = 3;
                      var maxNumOfDaysToAdd = 180;
                      var result = someDate.setDate(
                        someDate.getDate() + numberOfDaysToAdd
                      );
                      var endResult = someDate?.setDate(
                        someDate.getDate() + maxNumOfDaysToAdd
                      );
                      let date = new Date(result);
                      setEndDateMinValue(date);
                      setEndDateMaxValue(new Date(endResult))
                      // let cd = { ...campaignDetails };
                      campaignDetails.startDate = formatDates;
                      campaignDetails.endDate = "";
                      let errMsg = e.target.value !== null ? null : START_DATE;
                      setErrStartDate(errMsg)
                    }}
                    className="required"
                  />
                </Tooltip>
              </div>
            </div>
          </Col>
          <Col md={6} className={props.campaignDetails.status === 9 ? "click-off" : ""}>
            <div className="position-relative">
              {errEndDate && <div className="validation-message">{errEndDate}</div>}
              <div className="rspdate-picker">
                <Tooltip anchorElement="target" position="top">
                  <DatePicker
                    disabled={campaignDetails.startDate !== "" ? false : true}
                    format={"MMM dd, yyyy"}
                    placeholder={"End date"}
                    value={campaignDetails.endDate !== '' ? new Date(campaignDetails.endDate) : null}
                    min={endDateMinValue !== '' ? endDateMinValue : defaultValue}
                    max={endDateMaxvalue !== '' ? endDateMaxvalue : defaultValue}
                    onChange={(e) => {
                      let formatDates = getYearMonthDate(e.target.value);
                      setCampaignEndDate(formatDates)
                      // let cd = { ...campaignDetails }
                      campaignDetails.endDate = formatDates
                      let errMsg = e.target.value !== null ? null : END_DATE
                      setErrEndDate(errMsg)
                    }}
                    className="required"
                  />
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  return (
    <>
      {isOpenGoalModal && (
        <GoalModal
          isOpen={isOpenGoalModal}
          onChangeIsOpen={(value) => {
            setIsOpenGoalModal(false);
            if (value)
              setIsShowSecondaryGoal(!isShowSecondaryGoal);
          }}
        ></GoalModal>
      )}
      {campaignDetails && (
        <>
          <CampaignNameRowComponent
            onDisableChange={(value) => { props.onDisableChange(value) }}
            campaignDetails={campaignDetails}
            context={context}
            editClickOff={editClickOff}
            onChange={(key, value) => {
              campaign[key] = value;
            }}
            errCampaignName={errCampaignName}
            setCampaignDetails={data => setCampaignDetails(data)}
            setErrCampaignName={data => setErrCampaignName(data)}
            setIsEnableButton={value => setIsEnableButton(value)} />
          <ProductTypeRowComponent />
          <PrimaryGoalRowComponent />
          <CampaignGoalColComponent />
          {isShowSecondaryGoal && (
            <>
              <SecondaryGoalRowComponent />
              <SecondaryCampaignGoalColComponent />
            </>
          )}
          <CampaignPeriodRowComponent />
          <ButtonContainer />
        </>
      )}
    </>
  )
}
export default MultiDimension;

const CampaignNameRowComponent = props => {

  const [value, setValue] = useState(props.campaignDetails.campaignName)
  const [isShowTickSymbol, setIsShowTickSymbol] = useState(false);

  return <Row className={`custom-column-del smartlink-col-del marginT30 ${props.editClickOff ? " click-off" : ""}`}>
    <Col md={{ span: 3, offset: 1 }}>
      <label className="label-row label-dark">Communication name</label>
    </Col>
    <Col md={7} >
      <RSInput2
        ph="Enter communication name"
        val={props.campaignDetails.campaignName}
        required={true}
        cb={text => {
          setIsShowTickSymbol(false)
          var regex = /^[A-Za-z0-9\w\-\g ]+$/;
          if (text.length > 0 && text.length <= 100) {
            if (regex.test(text)) {
              setValue(text)
              let disablevalue = text.length > 0 ? true : false;
              if (disablevalue) {
                props.onDisableChange({ "title": "Multi Dimension", "value": disablevalue });
              }
              let errMsg = text.length > 0 ? null : CAMPAIGN_NAME;
              props.setErrCampaignName(errMsg);
              let cd = { ...props.campaignDetails };
              cd.campaignName = text;
              props.setCampaignDetails(cd);
            } else {
              let errMsg = text.length > 0 ? "Special characters are not allowed except (_ and -)" : CAMPAIGN_NAME;
              props.setErrCampaignName(errMsg);
            }
          } else {
            let errMsg = text.length > 0 ? "Communication name exceeded the limit" : CAMPAIGN_NAME;
            if (text.length === 0) {
              let cd = { ...props.campaignDetails };
              cd.campaignName = ""
              props.setCampaignDetails(cd);
            }
            props.setErrCampaignName(errMsg);
          }
        }}
        ob={text => {
          text = text.trimStart();
          text = text.trimEnd();
          let errMsg = text !== "" ? null : CAMPAIGN_NAME;
          props.setErrCampaignName(errMsg);
          let cd = { ...props.campaignDetails }
          cd.campaignName = text
          props.setCampaignDetails(cd)
          props.onChange('ncampaingName', text)
          camapignNameisPresent(
            cd.campaignId,
            cd.campaignName,
            props.context,
            errValue => {
              let isEnable = errValue !== "" ? false : true;
              setIsShowTickSymbol(isEnable);
              props.setIsEnableButton(isEnable)
              props.setErrCampaignName(errValue);
            });
        }}
        errorMessage={props.errCampaignName}
      />
      {isShowTickSymbol &&
        <div className="input-outer-set">
          <i className="icon-sd-circle-tick-medium icons-md green d-block marginT0" />
        </div>
      }
    </Col>
  </Row>
}

const GoalPercentage = props => {

  const [value, setValue] = useState(props.campaignDetails.goalPercentage)
  const [errMsg, setErrMsg] = useState(props.errGoalPercentage);
  return <Col md={6} className={props.editClickOff ? "click-off" : ""}>
    <RSInput2
      ph="Primary goal percentage"
      val={value}
      errorMessage={errMsg}
      keyboardType="number"
      required={true}
      max={6}
      cb={text => {
        const re = /^\d*\.?\d*$/;
        if (text.length > 6) {
          setErrMsg("Limit exists");
          return
        }
        if (text === '' || re.test(text)) {
          let errMsg = text.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
          setValue(text);
          setErrMsg(errMsg);
        } else {
          let errMsg = text.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
          setErrMsg(errMsg);
        }
      }}
      ob={text => {
        let cd = { ...props.campaignDetails };
        if (text !== "") {
          let value = Math.abs(text);
          let errMsg = (value >= 0.1 && value <= 100) ? null : "Only values 0.1-100 are accepted";
          cd.goalPercentage = errMsg === null ? value : "";
          setErrMsg(errMsg);
          props.getValues(cd, errMsg);
        } else {
          cd.goalPercentage = text;
          setErrMsg(ConstStr.GOAL_PERCENTAGE);
          props.getValues(cd, ConstStr.GOAL_PERCENTAGE)
        }
      }}
    // ob={text => props.onChange("goalpercentage", text)}
    />
    <div className="input-outer-set mt4">
      <span>%</span>
    </div>
  </Col>
}