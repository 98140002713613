import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { RTabbar } from 'Components/RChart/RTabbar';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import TagsComponent from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/TagsComponent';
import { CAMPAIGN_NAME, SELECT_TIMEZONE, NATIVE_CHANNEL } from 'Helper/Constants/validationMessage';
import { isEmpty, makeImmutableObject } from "Helper/Utils/Utils";
import { Daily } from './Components/Daily';
import { Weekly } from './Components/Weekly';
import { Monthly } from './Components/Monthly';
import authContext from "Helper/StateHandler/auth-context"
import * as ConstStr from "Helper/Constants/validationMessage";
import { camapignNameisPresent, createSmartlinkObject, getCampaignPlanById, savePlan } from '../SmartLink/SLObjectManager';


const EventTrigger = (props) => {

    let history = useHistory();
    const context = useContext(authContext)
    const defaultValue = new Date();

    const [camapingStartDate, setCampaignStartDate] = useState(props.json.campaignDetails.startDate)
    const [camapingEndDate, setCampaignEndDate] = useState(props.json.campaignDetails.endDate)
    const [dynamicListType, setDynamiclistType] = useState(false)
    const [frequency, setFrequency] = useState(false)
    const [campaignDetails, setCampaignDetails] = useState(null)
    const [campaignJson, setCampaignJson] = useState(props.json)

    const [update, setUpdate] = useState(0)
    const [isOpenTagsModal, setOpenTagsModal] = useState(false);
    const [errCampaignName, setErrCampaignName] = useState(null)
    const [errCampaignType, setErrCampaignType] = useState(null)
    const [errPrimaryGoal, setErrPrimaryGoal] = useState(null)
    const [errStartDate, setErrStartDate] = useState(null);
    const [errEndDate, setErrEndDate] = useState(null);
    const [errGoalPercentage, setErrGoalPercentage] = useState(null)
    const [errProductType, setErrProductType] = useState(null)
    const [errNativeChannel, setErrNativeChannel] = useState(null)
    const [errAnalyticsType, setErrAnalyticsType] = useState(null)
    const [errTimezone, setErrTimezone] = useState(null)
    const [dailyError, setDailyError] = useState({})
    const [weeklyError, setWeeklyError] = useState({})
    const [monthlyError, setMonthlyError] = useState({})
    const [editClickOff, setEditClickOff] = useState(false);
    const [endDateMinValue, setEndDateMinValue] = useState('');
    const [index, setIndex] = useState(0)

    useEffect(() => {

        const clickOffStatus = props.campaignDetails.mode === "add"
            ? false : props.campaignDetails.status !== "Scheduled"
                && props.campaignDetails.status !== "Draft"
                && props.campaignDetails.status !== "Alert"
                ? true
                : false;
        setEditClickOff(clickOffStatus)

        const temp = { ...campaignJson }
        let cd = ["Reach"]
        temp.primaryGoal.map(item => {
            cd.push(item)
        })
        temp.primaryGoal = cd
        setCampaignJson(temp)
        if (props.campaignDetails.mode === "add") {
            let object = createSmartlinkObject(false)
            object.campaignId = 0
            setCampaignDetails(object)
        } else {
            getCampaign();
            // let value = JSON.parse(localStorage.getItem(`cpsmartlink${props.campaignId}`))

            // setCampaignDetails(props.json.campaignDetails)
            // if (value !== null) {
            //     value.campaignId = props.campaignId
            //     console.log("ferquency Details", value.isFrquenencyEnabled)
            //     setCampaignDetails(value)
            //     setCampaignStartDate(value.startDate);
            //     setCampaignEndDate(value.endDate);
            //     setIndex(getFrequencyIndex())
            // }
        }
    }, []);

    const getCampaign = () => {

        getCampaignPlanById(props.campaignDetails.campaignId, context, props.attributes, (campaignDetails => {
            setCampaignDetails(campaignDetails)
        }))

    }

    const RenderComponent = index => {

        switch (index) {

            case 1:
                return <Daily
                    campaignDetails={campaignDetails}
                    campaignJson={campaignJson}
                    dailyError={dailyError}
                    onChange={value => {
                        let temp1 = { ...dailyError }
                        temp1.errMsg = value.toString().length > 0 ? null : "Enter a value"
                        setDailyError(temp1)
                    }} />
            case 2:
                return <Weekly
                    campaignDetails={campaignDetails}
                    campaignJson={campaignJson}
                    weeklyError={weeklyError}
                    onChange={(key, value) => {
                        let temp1 = { ...weeklyError }
                        let tempJson = campaignJson
                        let tempweekly = tempJson.frequency.filter((obj) => obj.frequency.toLowerCase() === "weekly")[0]

                        if (key === 'dayinterval') {
                            tempweekly.dayInterval = value
                            temp1.errDayInterval = value.length > 0 ? null : 'Enter day interval'
                        }
                        else if (key === 'hourinderval') {
                            tempweekly.hourInterval = value
                            temp1.errHourInterval = value.length > 0 ? null : 'Enter hour interval'
                        }
                        else if (key === 'weekdays') {
                            tempweekly.weekDays = value
                            temp1.errWeekDays = value.length > 0 ? null : 'Select week interval'
                        }
                        setCampaignJson(tempJson)
                        setWeeklyError(temp1)
                    }} />
            case 3:
                return <Monthly
                    campaignDetails={campaignDetails}
                    campaignJson={campaignJson}
                    monthlyError={monthlyError}
                    onChange={(key, value) => {
                        let temp1 = { ...monthlyError }
                        if (key === 'dayinterval') {
                            temp1.errDayInterval = value.length > 0 ? null : 'Enter day interval'
                        }
                        else if (key === 'hourinterval') {
                            temp1.errHourInterval = value.length > 0 ? null : 'Enter hour interval'
                        }
                        else if (key === 'monthinterval') {
                            temp1.errMonthInterval = value.length > 0 ? null : 'Enter month interval'
                        }
                        else if (key === 'weekinterval') {
                            temp1.errWeekInterval = value.length > 0 ? null : 'Select week interval'
                        }
                        else if (key === 'day') {
                            temp1.errDay = value.length > 0 ? null : 'Select week'
                        }
                        setMonthlyError(temp1)
                    }} />

            default:
                return null
        }
    }

    const getFrequencyIndex = () => {
        let loadIndex = 0
        tabData.forEach((item, index) => {
            if (campaignDetails && campaignDetails.frequency.frequency.toLocaleLowerCase() === item.text.toLocaleLowerCase()) {
                loadIndex = index
                return
            }
        })
        return loadIndex
    }



    const submit = (save) => {

        let error = false
        if (isEmpty(campaignDetails.campaignName) || campaignDetails.campaignName.toLowerCase().includes("select")) {
            setErrCampaignName(CAMPAIGN_NAME)
            error = true
        }
        if (isEmpty(campaignDetails.campaignAttribute.attributeName) || campaignDetails.campaignAttribute.attributeName.toLowerCase().includes('select')) {
            setErrCampaignType(ConstStr.CAMPAIGN_TYPE)
            error = true
        }
        if (isEmpty(campaignDetails.productCategory.categoryName) || campaignDetails.productCategory.categoryName.toLowerCase().includes('select')) {
            setErrProductType(ConstStr.PRODUCT_TYPE)
            error = true
        }
        // if (isEmpty(campaignDetails.goal) || campaignDetails.goal.toLowerCase().includes("select")) {
        //     setErrPrimaryGoal(ConstStr.PRIMARY_GOAL);
        //     error = true;
        // }
        if (isEmpty(campaignDetails.startDate.toString())) {
            setErrStartDate(ConstStr.START_DATE);
            error = true;
        }
        if (isEmpty(campaignDetails.endDate.toString())) {
            setErrEndDate(ConstStr.END_DATE);
            error = true;
        }
        if (campaignDetails.channelTypes.length <= 0) {
            setErrNativeChannel(NATIVE_CHANNEL)
            error = true;
        }
        /* if (campaignDetails.analyticsType.length <= 0) {
            setErrAnalyticsType(ANALYTICS_TYPE)
            error = true
        } */
        if (campaignDetails.frequency.frequency.toLowerCase() === "daily") {
            let dailyData = campaignJson.frequency.filter((obj) => obj.frequency.toLowerCase() === "daily")[0]
            for (let i = 0; i < dailyData.hourInterval.length; i++) {
                let item = dailyData.hourInterval[i]
                if (isEmpty(item)) {
                    let temp = { ...dailyError }
                    temp.errMsg = 'Please select time'
                    temp.index = i
                    setDailyError(temp)
                    setUpdate(update + 1)
                    error = true;
                    break;
                }
            }
        }
        else if (campaignDetails.frequency.frequency.toLowerCase() === "weekly") {
            let weeklyData = campaignJson.frequency.filter((obj) => obj.frequency.toLowerCase() === "weekly")[0]
            let temp = { ...weeklyError }
            if (isEmpty(weeklyData.dayInterval)) {
                temp.errDayInterval = "Enter day interval"
                error = true;
            }
            if (isEmpty(weeklyData.hourInterval)) {
                temp.errHourInterval = "Enter hour interval"
                error = true;
            }
            if (weeklyData.weekDays.length <= 0) {
                error = true
                temp.errWeekDays = "Select weeks"
            }
            setWeeklyError(temp)
            setUpdate(update + 1)
        }
        else if (campaignDetails.frequency.frequency.toLowerCase() === "monthly") {
            let monthlyData = campaignJson.frequency.filter((obj) => obj.frequency.toLowerCase() === "monthly")
            let temp = { ...monthlyError }
            for (let i = 0; i < monthlyData.length; i++) {
                let item = monthlyData[i]

                if (item.isDayEnable && i === 0) {
                    if (isEmpty(item.dayInterval)) {
                        error = true;
                        temp.errDayInterval = "Enter day interval"
                    }
                    if (isEmpty(item.monthInterval)) {
                        error = true;
                        temp.errMonthInterval = "Enter month interval"
                    }
                    if (isEmpty(item.hourInterval)) {
                        error = true;
                        temp.errHourInterval = "Select hours interval"
                    }
                } else if (item.isDayEnable && i === 1) {
                    if (isEmpty(item.dayInterval)) {
                        error = true;
                        temp.errDayInterval = "Enter day interval"
                    }
                    if (isEmpty(item.weekInterval)) {
                        error = true;
                        temp.errWeekInterval = "Select weekly interval"
                    }
                    if (isEmpty(item.hourInterval)) {
                        error = true;
                        temp.errHourInterval = "Select hours interval"
                    }
                    if (isEmpty(item.day)) {
                        error = true;
                        temp.errDay = "Select day"
                    }
                }
            }
            setMonthlyError(temp)
            setUpdate(update + 1)
        }

        if (isEmpty(campaignDetails.timeZone) || campaignDetails.timeZone.toLowerCase().includes('select')) {
            error = true;
            setErrTimezone(SELECT_TIMEZONE)
        }

        if (!error) {
            savePlan(campaignDetails, "T", context, status => {
                //alert('Planning' +status)
                if (status) {
                    context.channels = campaignDetails.nativeChannels.concat(campaignDetails.socialMedia, campaignDetails.paidAds)
                    campaignDetails.allChannels = [...campaignDetails.nativeChannels, ...campaignDetails.socialMedia, ...campaignDetails.paidAds]
                    let campaignId = 0
                    if (save === 0) {
                        history.push({ pathname: `/communication` })
                    } else {
                        if (props.campaignDetails.mode === 'add') {
                            history.push({ pathname: `/communication/create-communication`, state: { mode: 'add', campaignName: props.campaignDetails !== undefined ? props.campaignDetails.campaignName : "", editClickOff: editClickOff, status: props.campaignDetails.status, Channeltype: campaignDetails.channelTypes, AnalyticsType: campaignDetails.analyticsType, mobileApp: {}, campaignId: campaignId, CampaignType: "EventTrigger" } })
                        } else {
                            history.push({ pathname: `/communication/create-communication`, state: { mode: 'edit', campaignName: props.campaignDetails !== undefined ? props.campaignDetails.campaignName : "", editClickOff: editClickOff, status: props.campaignDetails.status, Channeltype: campaignDetails.channelTypes, AnalyticsType: campaignDetails.analyticsType, mobileApp: {}, campaignId: campaignId, CampaignType: "EventTrigger" } })
                        }
                    }
                }
            })

        }
    }

    const GoalPercentage = props => {

        const [value, setValue] = useState(props.campaignDetails.goalPercentage)
        const [err, setErr] = useState(null)

        return <Col md={6} className={props.editClickOff ? "click-off" : ""}>
            <RSInput2
                ph="Goal percentage"
                val={value}
                keyboardType="number"
                cb={text => {
                    setValue(text)
                    let cd = { ...props.campaignDetails };
                    cd.goalPercentage = text;
                    let errMsg = text.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
                    setErr(errMsg)
                }}
                ob={text => {
                    let cd = { ...props.campaignDetails };
                    cd.goalPercentage = text;
                    props.setCampaignDetails(cd);
                }}
                errorMessage={err}
            />
            <div className="input-outer-set mt4">
                <span>%</span>
            </div>
        </Col>
    }

    const PrimaryGoalRowComponent = () => {
        return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
            <Col md={{ span: 3, offset: 1 }}>
                <label className="label-row label-dark">Primary goal</label>
            </Col>
            <Col md={7}>
                <Row>
                    <Col md={6}>
                        <div className="position-relative">
                            {errPrimaryGoal && <div className="validation-message">{errPrimaryGoal}</div>}
                            <RSDropdownList
                                data={campaignJson.primaryGoal}
                                value={campaignDetails.goal !== "" ? campaignDetails.goal : "-- Goal --"}
                                onChange={(e) => {
                                    var percentage = 0;
                                    let cd = { ...campaignDetails };
                                    cd.goal = e.target.value;
                                    if (cd.goal === campaignJson.primaryGoal[0]) {
                                        percentage = 20;
                                    } else if (cd.goal === campaignJson.primaryGoal[2]) {
                                        percentage = 40;
                                    } else if (cd.goal === campaignJson.primaryGoal[3]) {
                                        percentage = 75;
                                    }

                                    cd.goalType = cd.goal === campaignJson.primaryGoal[1]
                                        ? campaignJson.engamentTypes[0]
                                        : campaignJson.conversationTypes[0]

                                    let errMsg = e.target.value.length > 0 ? null : ConstStr.PRIMARY_GOAL;
                                    setErrPrimaryGoal(errMsg);

                                    cd.goalPercentage = percentage;
                                    setCampaignDetails(cd);
                                    props.onChange("goalPercentage", percentage);
                                    props.onChange("reachType", e.target.value);
                                }}
                            />
                        </div>
                    </Col>
                    <GoalPercentage
                        editClickOff={editClickOff}
                        campaignDetails={campaignDetails}
                        errGoalPercentage={errGoalPercentage}
                        setCampaignDetails={data => setCampaignDetails(data)}
                        setErrGoalPercentage={data => setErrGoalPercentage(data)}
                    />
                </Row>
            </Col>
        </Row>
    }

    const CampaignGoalColComponent = () => {
        if (campaignDetails.goal !== campaignJson.primaryGoal[0] && campaignDetails.goal.length > 0) {
            return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                <Col md={{ span: 3, offset: 1 }}>
                    <label className="label-row label-dark">
                        {campaignDetails.goal + " type"}
                    </label>
                </Col>
                <Col md={7}>
                    <RSDropdownList
                        data={
                            campaignDetails.goal === campaignJson.primaryGoal[1]
                                ? campaignJson.engamentTypes
                                : campaignJson.conversationTypes
                        }
                        value={
                            campaignDetails.goalType
                        }
                        onChange={(e) => {
                            let cd = { ...campaignDetails };
                            cd.goalType = e.target.value;
                            setCampaignDetails(cd);
                        }}
                    />
                </Col>
            </Row>
        }
        return null
    }

    return (
        <>
            {/* First Row */}

            {campaignDetails &&
                <>
                    <Row className={`custom-column-del smartlink-col-del marginT30 ${editClickOff ? "click-off" : ""}`}>

                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Communication name</label>
                        </Col>
                        <Col md={7}>
                            <RSInput2
                                ph="Enter campaign name"
                                val={campaignDetails.campaignName}
                                required={true}
                                cb={text => {
                                    if (text.length <= 30) {
                                        let disablevalue = text.length > 0 ? false : true;
                                        if (!disablevalue) {
                                            props.onDisableChange({ "title": "Event Trigger", "value": !disablevalue });
                                        }
                                        let errMsg = text.length > 0 ? null : CAMPAIGN_NAME
                                        setErrCampaignName(errMsg)
                                        let cd = { ...campaignDetails }
                                        cd.campaignName = text
                                        setCampaignDetails(cd)
                                    } else {
                                        let errMsg = "Communication name exceeded the limit";
                                        setErrCampaignName(errMsg);
                                    }
                                }}
                                ob={text => {
                                    props.onChange("ncampaingName", text)
                                    camapignNameisPresent(text, context, errCampaignName)
                                }}
                                errorMessage={errCampaignName}
                            />
                            <div className="input-outer-set d-none">
                                <i onClick={() => {
                                    setOpenTagsModal(!isOpenTagsModal);
                                }} className="icon-tag-outline-mini icons-md grey d-block marginT8" />
                            </div>
                        </Col>
                    </Row>

                    {/* Second Row */}
                    <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Communication / Product type</label>
                        </Col>
                        <Col md={7}>
                            <Row>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errCampaignType && <div className="validation-message">{errCampaignType}</div>}
                                        <RSDropdownList
                                            className="required"
                                            data={props.attributes.campaignAttributeList}
                                            value={
                                                campaignDetails.campaignAttribute !== undefined &&
                                                    campaignDetails.campaignAttribute.attributeName !== ''
                                                    ? campaignDetails.campaignAttribute
                                                    : '-- Select Target list  --'
                                            }
                                            textField='attributeName'
                                            dataItemKey='campaignAttributeId'
                                            onChange={e => {
                                                props.onChange('camapaignTypes', e.target.value)
                                                let cd = { ...campaignDetails }
                                                cd.campaignAttribute = e.target.value
                                                setCampaignDetails(cd)
                                                let errMsg = e.target.value.attributeName.length > 0
                                                    ? null
                                                    : ConstStr.CAMPAIGN_TYPE
                                                setErrCampaignType(errMsg)
                                            }} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errProductType && <div className="validation-message">{errProductType}</div>}
                                        <RSDropdownList
                                            className="required"
                                            data={props.attributes.productCategorysList}
                                            value={
                                                campaignDetails.productCategory !== undefined &&
                                                    campaignDetails.productCategory.categoryName !== ''
                                                    ? campaignDetails.productCategory
                                                    : '-- Select  --'
                                            }
                                            textField='categoryName'
                                            dataItemKey='categoryId'
                                            onChange={(e) => {
                                                let cd = { ...campaignDetails }
                                                cd.productCategory = e.target.value
                                                setCampaignDetails(cd)
                                                props.onChange('productTypes', e.target.value)
                                                let errMsg = e.target.value.categoryName.length > 0
                                                    ? null
                                                    : ConstStr.CAMPAIGN_TYPE
                                                setErrProductType(errMsg)
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Col>
                    </Row>

                    {/* Third Row */}
                    {/* <PrimaryGoalRowComponent /> */}
                    {/* <CampaignGoalColComponent /> */}

                    {/* Fourth Row */}
                    <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Dynamic list</label>
                        </Col>
                        <Col md={7}>
                            <div className="d-flex">
                                {/* <div className="wsn">
                                    <RSRadio
                                        lbl="All"
                                        name="matchtype"
                                        labelId="all"
                                        checked={campaignDetails.dynamicList.dynamicListName.toLowerCase().includes("select") ? true : false}
                                        cb={e => {
                                            let cd = { ...campaignDetails }
                                            cd.dynamicList = {
                                                dynamicListID: "0000",
                                                dynamicListName: "-- Select your dynamic list --"
                                            }
                                            cd.frequency.frequency = ""
                                            cd.dynamicListAll = true;
                                            cd.isFrquenencyEnabled = false
                                            setCampaignDetails(cd)
                                        }}
                                    />
                                </div> */}
                                {!dynamicListType ?
                                    <>
                                        {/* <div className="marginT1">
                                            <div className="choose-or choose-or-small">
                                                <span>or</span>
                                            </div>
                                        </div> */}
                                        <div className="w-100">
                                            <Row>
                                                <Col md={12}>
                                                    <RSDropdownList
                                                        className="marginB5"
                                                        data={props.attributes.dynamicList || []}
                                                        textField="dynamicListName"
                                                        dataItemKey="dynamicListId"
                                                        value={campaignDetails.dynamicList !== undefined
                                                            && campaignDetails.dynamicList.dynamicListName !== ""
                                                            ? campaignDetails.dynamicList
                                                            : "-- Select Target list  --"}
                                                        onChange={e => {
                                                            let cd = { ...campaignDetails }
                                                            cd.frequency.frequency = "Immediately"
                                                            cd.dynamicList = e.target.value
                                                            setCampaignDetails(cd)
                                                        }} />
                                                </Col>
                                            </Row>
                                            <Row className={campaignDetails.dynamicList.dynamicListID === "0000" ? 'click-off' : ''}>
                                                <Col md={12}>
                                                    <RSCheckbox
                                                        className=""
                                                        lbl="Allow audience to be targeted at every assigned frequency"
                                                        checked={campaignDetails.isFrquenencyEnabled}
                                                        cb={status => {
                                                            let cd = { ...campaignDetails }
                                                            cd.isFrquenencyEnabled = status
                                                            setCampaignDetails(cd)
                                                            props.onChange("ferquency", status)
                                                            setFrequency(status)
                                                        }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </Col>
                    </Row>
                    {/* Fivth Row */}
                    <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Communication period</label>
                        </Col>
                        <Col md={7} >
                            <Row>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errStartDate && <div className="validation-message">{errStartDate}</div>}
                                        <div className="rspdate-picker">
                                            <Tooltip anchorElement="target" position="top">
                                                <DatePicker
                                                    format={"MMM dd, yyyy"}
                                                    // show={true}
                                                    placeholder={"Start date"}
                                                    value={camapingStartDate !== '' ? new Date(camapingStartDate) : null}
                                                    min={defaultValue}
                                                    onChange={e => {
                                                        setCampaignStartDate(e.target.value);
                                                        var someDate = e.target.value;
                                                        var numberOfDaysToAdd = 3;
                                                        var result = someDate.setDate(
                                                            someDate.getDate() + numberOfDaysToAdd
                                                        );
                                                        let date = new Date(result);
                                                        setEndDateMinValue(date);
                                                        // let cd = { ...campaignDetails }
                                                        campaignDetails.startDate = e.target.value
                                                        let errMsg = e.target.value !== null ? null : ConstStr.START_DATE
                                                        setErrStartDate(errMsg)
                                                    }}
                                                    className="required"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errEndDate && <div className="validation-message">{errEndDate}</div>}
                                        <div className="rspdate-picker">
                                            <Tooltip anchorElement="target" position="top">
                                                <DatePicker
                                                    format={"MMM dd, yyyy"}
                                                    placeholder={"End date"}
                                                    value={camapingEndDate !== '' ? new Date(camapingEndDate) : null}
                                                    min={endDateMinValue !== '' ? endDateMinValue : defaultValue}
                                                    onChange={e => {
                                                        setCampaignEndDate(e.target.value);
                                                        // let cd = { ...campaignDetails }
                                                        campaignDetails.endDate = e.target.value
                                                        let errMsg = e.target.value !== null ? null : ConstStr.END_DATE
                                                        setErrEndDate(errMsg)
                                                    }}
                                                    className="required"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Col>
                    </Row>

                    {/* Sixth Row */}
                    <Row className={`custom-column-del smartlink-col-del marginT0 ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-dark">Channel type</label>
                        </Col>
                        <Col md={8}>
                            <ul className="checkbox-listing">
                                <div className="position-relative">
                                    {errNativeChannel && <div className="validation-message">{errNativeChannel}</div>}
                                    {channelTypes.map((item, index) => {
                                        item.value = false;
                                        campaignDetails.channelTypes.map((citem, cindex) => {
                                            if (item.label.toLocaleLowerCase() === citem.toLocaleLowerCase()) {
                                                item.value = true;
                                            }
                                        })
                                        return (
                                            <li>
                                                <RSCheckbox
                                                    className=""
                                                    lbl={item.label}
                                                    checked={item.value}
                                                    cb={status => {
                                                        let cd = { ...campaignDetails }
                                                        if (status) {
                                                            cd.channelTypes.push(item.label)
                                                        } else {
                                                            let myarr = cd.channelTypes.filter((obj) => obj.toLocaleLowerCase() !== item.label.toLocaleLowerCase())
                                                            cd.channelTypes = myarr
                                                        }
                                                        let err = cd.channelTypes.length > 0 ? null : NATIVE_CHANNEL
                                                        setErrNativeChannel(err)
                                                        setCampaignDetails(cd)
                                                    }} />
                                            </li>
                                        )
                                    })}
                                </div>
                            </ul>
                        </Col>
                    </Row>

                    {/* seventhRow */}
                    {/* <Row className={`custom-column-del smartlink-col-del marginT10 ${editClickOff ? "click-off" : ""}`}>
                <Col md={{ span: 3, offset: 1 }}>
                    <label className="label-dark">Analytics type</label>
                </Col>
                <Col md={8}>
                    <ul className="checkbox-listing">
                        <div className="position-relative">
                            {errAnalyticsType && <div className="validation-message">{errAnalyticsType}</div>}
                            {analyticsTypes.map((item, index) => {
                                item.value = false
                                campaignDetails.analyticsType.map((citem, cindex) => {
                                    if (item.label.toLocaleLowerCase() === citem.toLocaleLowerCase()) {
                                        item.value = true;
                                    }
                                })
                                return (
                                    <li>
                                        <RSCheckbox
                                            className=""
                                            lbl={item.label}
                                            checked={item.value}
                                            cb={status => {
                                                let cd = { ...campaignDetails }
                                                if (status) {
                                                    cd.analyticsType.push(item.label)
                                                } else {
                                                    let myarr = cd.analyticsType.filter((obj) => obj.toLocaleLowerCase() !== item.label.toLocaleLowerCase())
                                                    cd.analyticsType = myarr
                                                }
                                                let err = cd.analyticsType.length > 0 ? null : ANALYTICS_TYPE
                                                setErrAnalyticsType(err)
                                                setCampaignDetails(cd)
                                            }} />
                                    </li>
                                )
                            })}
                        </div>
                    </ul>
                </Col>
            </Row> */}

                    {/* Eight Row */}
                    {/* {campaignDetails.frequency.frequency !== "" ?  */}
                    <Row className={`custom-column-del smartlink-col-del marginT0 ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Frequency</label>
                        </Col>
                        <Col>
                            <RTabbar
                                defaultSelectedItem={index}
                                defaultClass="tabDefault"
                                dynamicTab="rs-btn-tab"
                                activeClass="tabDefault active"
                                tabData={tabData}
                                callBack={(item, index) => {
                                    setIndex(index)
                                    let temp = { ...campaignDetails }
                                    switch (index) {
                                        case 0:
                                            campaignDetails.frequency.frequency = "Immediately"
                                            break;
                                        case 1:
                                            campaignDetails.frequency.frequency = "Daily"
                                            break;
                                        case 2:
                                            campaignDetails.frequency.frequency = "Weekly"
                                            break;
                                        case 1:
                                            campaignDetails.frequency.frequency = "Monthly"
                                            break;
                                    }
                                    setCampaignDetails(temp)
                                }}
                            />
                            {
                                RenderComponent(index)
                            }

                        </Col>
                    </Row>
                    {/* : null} */}


                    <Row className={`custom-column-del smartlink-col-del marginT20 ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Time zone</label>
                        </Col>
                        <Col md={7}>
                            <>
                                <div className="position-relative">
                                    {errTimezone && <div className="validation-message">{errTimezone}</div>}
                                    <RSDropdownList
                                        className="marginB5"
                                        data={campaignJson.timeZones}
                                        defaultValue={campaignDetails.timeZone !== undefined
                                            && campaignDetails.timeZone !== ""
                                            ? campaignDetails.timeZone
                                            : "--- Select time zone ---"}
                                        onChange={e => {
                                            campaignDetails.timeZone = e.target.value
                                            let err = campaignDetails.timeZone.length > 0 ? null : SELECT_TIMEZONE
                                            setErrTimezone(err)
                                            props.onChange("timezone", e.target.value)
                                        }} />
                                </div>
                                <RSCheckbox
                                    lbl="Daylight saving"
                                    checked={campaignDetails.daylightSavings}
                                    cb={status => {
                                        let cd = { ...campaignDetails }
                                        cd.daylightSavings = status
                                        setCampaignDetails(cd)
                                    }} />
                            </>
                        </Col>
                    </Row>

                    <Container>
                        <Row>
                            <div className="btn-container d-flex justify-content-end">
                                <RSSecondaryBtn className="paddingR0" onClick={() => {
                                    history.push({ pathname: `/communication` })
                                }}>Cancel</RSSecondaryBtn>
                                <RSSecondaryBtn
                                    className="blue"
                                    onClick={() => {
                                        submit(0);
                                    }}>Save</RSSecondaryBtn>
                                <RSPrimaryBtn onClick={() => {
                                    if (editClickOff) {
                                        if (props.mode === 'add') {
                                            history.push({ pathname: `/communication/create-communication`, state: { mode: 'add', campaignName: props.mode !== undefined ? props.mode.campaignName : "", editClickOff: editClickOff, Channeltype: campaignDetails.channelTypes, AnalyticsType: campaignDetails.analyticsType, mobileApp: {}, campaignId: campaignDetails.campaignId, CampaignType: "EventTrigger" } })
                                        } else {
                                            history.push({ pathname: `/communication/create-communication`, state: { mode: 'edit', campaignName: props.mode !== undefined ? props.mode.campaignName : "", editClickOff: editClickOff, Channeltype: campaignDetails.channelTypes, AnalyticsType: campaignDetails.analyticsType, mobileApp: {}, campaignId: campaignDetails.campaignId, CampaignType: "EventTrigger" } })
                                        }
                                    } else {
                                        submit(1);
                                    }
                                }}>Next</RSPrimaryBtn>
                            </div>
                        </Row>
                    </Container>

                    {isOpenTagsModal
                        ? <TagsComponent
                            isOpen={isOpenTagsModal}
                            onChangeIsOpen={(value) => {
                                setOpenTagsModal(value);
                            }}
                        />
                        : null}
                </>}
        </>
    )
}
export default EventTrigger

const tabData = [{ id: '1001', text: "Immediately" }, { id: '1002', text: "Daily" }, { id: '1003', text: "Weekly" }, { id: '1004', text: "Monthly" }]

const channelTypes = makeImmutableObject([
    {
        label: "Email",
        value: false,
    },
    {
        label: "SMS",
        value: false,
    },
    {
        label: "WhatsApp",
        value: false,
    },
    {
        label: "QR",
        value: false,
    }
])

const analyticsTypes = makeImmutableObject([
    {
        label: "Web",
        value: false,
    },
    {
        label: "App",
        value: false,
    }
])
