import React from 'react'
import dummyProfile from 'Assets/Images/profile-pic.png'
import { RSPTooltip } from './Tooltip'


export const WhatsAppMessage = ({
    profile,
    username,
    date,
    time,
    image,
    message,
    fade,
    currentTime,
    popup,
    modal
}) => {
    let liveTime = new Date().toLocaleTimeString('fr', { hour: '2-digit', minute: '2-digit' })
    return (
        <>
            <div className={`preview-mobile-popup whatsapp-portlet ${popup ? 'popup-preview' : ''} ${modal ? 'modal-preview' : ''}`}>
                <div className="mb-content-box sms-popup w-inner-view">
                    {currentTime ? <div className='current-time'>{liveTime}</div> : null}
                    <div className='w-head'>
                        <img className='w-profile' src={profile ? profile : dummyProfile} />
                        <div className='w-user-details'>
                            <RSPTooltip position="top" text={username}>
                                <span className='w-user'>{username}</span>
                            </RSPTooltip>
                            <span className='w-status'>online</span>
                        </div>
                    </div>
                    <div className="mb-box-body w-body css-scrollbar">
                        <ul className="message-box w-lists">
                            {!!date &&
                                <li className='date'>
                                    <small>{date}</small>
                                </li>}
                            <li className='message sent'>
                                {image ? <img className="WhatsAppImage" src={image} alt="Post Image" /> : null}
                                <span>{message ? message : 'No data'}</span>
                                {!!time &&
                                    <span class="metadata">
                                        <span class="time">{time}</span>
                                        <span class="tick">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                                                <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"></path></svg>
                                        </span>
                                    </span>
                                }
                            </li>
                        </ul>
                    </div>
                    {fade ? <div className='fadeout'></div> : null}
                </div>
            </div>
        </>
    )
}