// import * as colors from 'Components/Colors'
import { RSPTooltip } from 'Components/Tooltip'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { glyphIcon } from './GlyphiconsData'


class GlypIcons extends Component {
    state = {
        icons: glyphIcon,
        value: '',
        copied: false,
    }
    render() {
        const ulClass = {
            backgroundColor: '#fff',
            // borderWidth: 1,
            // borderColor: '#b8b8b8',
            // borderStyle: 'solid',
            // backgroundColor: 'rgb(233, 233, 233)',
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: '#b8b8b8',
            borderStyle: 'solid',
            cursor: 'pointer',
            borderRadius: 8,
            padding: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingBottom: 35
        }
        const labelText = {
            color: '#333',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // position: 'absolute',
            // bottom: 10,
            // left: 0,
            width: '100%'
        }
        const label = {
            position: 'absolute',
            top: 4,
            right: 16,
            backgroundColor: '#0540d3',
            width: 24,
            height: 24,
            borderRadius: 20,
            color: '#fff',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
        return (
            <>
                <h3>Glyphicons ({this.state.icons.length})</h3>
                <Row className='theme-space-mt'>
                    {
                        this.state.icons.map((item, index) => {
                            return (
                                <Col md={2} className="marginB15">
                                            {/* <RSPTooltip text='Click to copy...' position='top'> */}
                                            <ul style={ulClass}>
                                                <RSPTooltip text={`${item}`} position='top'>
                                                    <li className='marginR10 float-start text-center'>
                                                        <label style={label}>{index + 1}</label>
                                                        <CopyToClipboard text={`<i className="${item} icons-lg"></i>`}>
                                                            <i className={`icons-lg blue cursor-pointer ${item}`} alt={`${item}`}></i>
                                                        </CopyToClipboard>
                                                        <label style={labelText}>{item}</label>
                                                    </li>
                                                </RSPTooltip>
                                            </ul>
                                            {/* </RSPTooltip> */}
                                </Col>
                            )
                        })
                    }
                </Row>
            </>
        )
    }
}

export default GlypIcons
