import React, { Component } from 'react';
import { ch_desktopColor, ch_windows } from 'Components/Charts/ChartColors';
import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { NoData, PieChartSkeleton } from 'Components/Skeleton/Skeleton';

class EmailPieChart extends Component {
    state = { tabOSIndex: 0 }
    render() {
        return (<div className="portlet-container portlet-md portlet-footer-label">
            <div className="portlet-header">
                <h4>Opens by devices</h4>
                {/* <div className="p-nav-right">
                    <RTabbar
                        defaultClass="tabDefault"
                        dynamicTab="mini marginB0"
                        activeClass="tabDefault active"
                        defaultSelectedItem={this.state.tabOSIndex}
                        tabData={tabDataOS}
                        callBack={(item, index) => this.setState({ tabOSIndex: index })}
                    />
                </div> */}
            </div>
            <div className="portlet-body">
                {
                    this.state.tabOSIndex === 0
                        ? <div className="portlet-chart">
                            {this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ? <NewCharts options={deviceChart1(this.props.data)} />
                                : <><NoData /><PieChartSkeleton /></>
                            }
                        </div>
                        : <div className="portlet-chart">
                            {this.props.data?.osInfoDTOs !== null && this.props.data?.osInfoDTOs?.length ? <NewCharts options={OSChart(this.props.data)} />
                                : <><NoData /><PieChartSkeleton /></>
                            }
                        </div>
                }
            </div>
            {this.state.tabOSIndex === 0 ?
                <div className={`${this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ? 'portlet-two-label' : ''}`}>
                    {this.props.data?.devicesInfoDTOs !== null && this.props.data?.devicesInfoDTOs?.length ?
                        <ul>
                            <li><span>{this.props?.data?.topDeviceValue || 0}</span><small>%</small></li>
                            <li>of the users have opened the communication using {this.props?.data?.topDevice}</li>
                        </ul> : ''}
                </div>
                : <div className={`${this.props.data?.osInfoDTOs !== null && this.props.data?.osInfoDTOs?.length ? 'portlet-two-label' : ''}`}>
                    {this.props.data?.osInfoDTOs !== null && this.props.data?.osInfoDTOs?.length ?
                        <ul>
                            <li><span>{this.props?.data?.topOsValue || 0}</span><small>%</small></li>
                            <li>of the users have opened the communication using {this.props?.data?.topOs}</li>
                        </ul> : ''}
                </div>

            }
        </div>);
    }
}

export default EmailPieChart;

const OSChart = data => {

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Windows", color: ch_androidPhoneColor, y: 28 },
                //     { name: "iOS", color: ch_iphone, y: 11 },
                //     { name: "Others", color: ch_ipad, y: 7 }
                // ],
                data: data?.osInfoDTOs?.map(item => {
                    return { name: item?.name, y: parseFloat(item?.value) }
                }),
                colors: [ch_windows],
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}

// Device -- pie
const deviceChart1 = data => {

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Desktop", color: ch_desktopColor, y: 12 },
                //     { name: "Android phone", color: ch_androidPhoneColor, y: 46 },
                //     { name: "Android tablet", color: ch_androidTabColor, y: 18 },
                //     { name: "iPhone", color: ch_iphone, y: 32 },
                //     { name: "iPad", color: ch_ipad, y: 25 }
                // ],                
                data: data?.devicesInfoDTOs?.map(item => {
                    return { name: item?.name, y: parseFloat(item?.value) }
                }),
                colors: [ch_desktopColor],
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}

const tabDataOS = [
    { "id": 1002, "text": "Device" }
]