import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { AdvancedSearchView } from "Components/AdvancedSearchView/AdvancedSearchView";
import { RSPPophover } from "Components/Tooltip";
import galleryData from "./Gallery.json";
import imageSrc from "Assets/Images/industries/banking/gallery-email3.jpg";

class Gallery extends Component {

  state = {
    isSearchOpen: false,
    searchSelectedItem: "",
    searchInputText: "",
    filterArray: [],
    dropdownList: ["Mobile push", "Web push"],
    selectedDropdownValue: "mobilepush",
    customDropdown: false,
  };

  componentDidMount() {
  }

  lowerCaseObjectKeys = (value) => {
    return value.map((k) => k.toUpperCase(), value);
  };

  AdvancedSearchComponent = (props) => {
    const searchData = [{ text: "Template name" }];
    return (
      <>
        <AdvancedSearchView
          dataSource={searchData}
          addonClass="mt10"
          onDropdownChange={(item, index) => {
            var newArray = [...this.state.filterArray];
            newArray.push(item.text);
            this.setState({
              filterArray: newArray,
            });
          }}
          onSearchInputChange={(text) =>
            this.setState({ searchInputText: text })
          }
          onSearchClick={() => { }}
          isSearchOpen={this.state.isSearchOpen}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <Row></Row>
        <GalleryMobile
          data={galleryData[this.state.selectedDropdownValue]}
          navigation={this.props.history}
        />
      </>
    );
  }
}
export default withRouter(Gallery);

const GalleryMobile = (props) => {
  return (
    <>
      <CardViewComponent
        item={props.data}
        navigation={props.navigation}
        channelName="Mobile push"
      />
    </>
  );
};

const CardViewComponent = (props) => {
  return (
    <Row className="clearfix">
      {props.item?.map((item, index) => {
        return (
          <Col md={3} key={item}>
            <div className="card-gallery-box">
              <div className="box">
                <CardViewBodyComponent />
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

const CardViewBodyComponent = (props) => {
  return (
    <>
      <div className="img-bor clearfix">
        <img src={imageSrc} alt={imageSrc} />
        <RSPPophover
          pophover={
            <>
              <small>Total audience: 2300</small>
              <small>Reach: 1234</small>
              <small>Enagagment: 876</small>
              <small>Conversion: 456</small>
            </>
          }
          position="top"
        >
          <i className="icon-sd-circle-info-medium icons-sm grey float-end cp"></i>
        </RSPPophover>
      </div>
    </>
  );
};
