import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import authContext from 'Helper/StateHandler/auth-context';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import { RSPTooltip } from 'Components/Tooltip';
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_USER_PROFILE, GET_ALERT_NOTIFICATION, SET_ALERT_NOTIFICATION_READ_STATUS, SET_ALERT_NOTIFICATION_ALL_READ_STATUS, GET_THEME, LOGOUT } from 'Helper/Constants/endPoint'
import { dateTimeFormatWithMomentForReports, getAuthUserId, getServerConfig, getUserData, numberWithCommas } from 'Helper/Utils/Utils';
import profileImg from 'Assets/Images/profile-pic.png'
import tagImg from 'Assets/Images/tag-enterprise.svg'
import freeTag from 'Assets/Images/tags/Free trial.svg'
import essentialsTag from 'Assets/Images/tags/Essentials.svg'
import premierTag from 'Assets/Images/tags/Premier.svg'
import ultimateTag from 'Assets/Images/tags/Ultimate.svg'
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';

export const RSHeader = props => {

    const context = useContext(authContext)
    const history = useHistory()

    const [profilePath, setProfilePath] = useState(null)
    const [isShowNotification, setIsShowNotification] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const [readCount, setReadCount] = useState(0)
    const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
    let location = useLocation();
    var pathArray = location.pathname.length > 1 ? location.pathname.split('/') : []
    const imageUrl = getServerConfig().slice(0, -1)
    useEffect(() => {
        getAlertNotification()
        getProfile()
    }, [])

    const getAccountTags = () => {
        switch (getUserData()?.clientDetailsLocalization?.planDetails?.licensingTypeId) {
            case 1: return freeTag;
            case 2: return essentialsTag;
            case 3: return premierTag;
            case 4: return ultimateTag;
            default: return ultimateTag;
        }
    }
    const getProfile = () => {
        const params = { userId: getAuthUserId() }
        connectServer({
            path: GET_USER_PROFILE,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                setProfilePath(res.data?.profilePath)
            },
            fail: err => {

            }
        })
    }

    const getAlertNotification = () => {
        let params = {
            "userId": getAuthUserId(),
            "isClientUser": true,
            endDate: new Date(),
            startDate: new Date(getUserData()?.activationDate),
            pageNumber: 1,
            itemsPerPage: 5
        }
        connectServer({
            path: GET_ALERT_NOTIFICATION,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status && res.data?.alertNotifications?.length) {
                    let sortedArry = res.data?.alertNotifications?.sort((a, b) => a.notificationID > b.notificationID ? 1 : -1).reverse()
                    setNotificationList(sortedArry)
                    setReadCount(res.data.totalUnreadCount)
                }
            },
            fail: error => {

            }
        })
    }

    return <div className={`header-component ${props.clickOff}`}>
        <div className="header-wrapper">
            <div><RSBrandLogo inner /></div>
            {props.children}
            <div className="header-nav-right">
                <span className="tag-account"><img src={getAccountTags()} alt={tagImg} /></span>
                <ul className="header-nav-right-list">
                    <li>
                        <RSPTooltip className="tooltip-head-icon" text="Notifications" position="bottom">
                            <div className="head-ico-wrap h-notify" onClick={() => setIsShowNotification(!isShowNotification)}>
                                <i className="icon-sd-list-bar-medium icons-md white cursor-pointer"></i>
                                {readCount > 0 && <span className="label">{numberWithCommas(readCount)}</span>}
                            </div>
                        </RSPTooltip>
                    </li>
                    <li>
                        <RSPTooltip className="tooltip-head-icon" text="My profile" position="bottom">
                            <div className="head-ico-wrap">
                                <div className="h-profile cursor-pointer"
                                    onClick={() => {
                                        context.globalStateData.setSelectedSubItem(null)
                                        history.push("/preferences/my-profile")
                                    }}>
                                    <img
                                        src={profilePath ? imageUrl + profilePath : profileImg}
                                        alt={"profileImg"}
                                        onError={(event) => event.target.style.display = 'none'} />
                                </div>
                            </div>
                        </RSPTooltip>
                    </li>
                    <li onClick={() => {
                        setIsOpenLogoutModal(true);
                        // localStorage.removeItem('isAuth')
                        // // localStorage.removeItem('session')
                        // context.toggleAuth(false)
                        // history.push("/")
                    }}>
                        <RSPTooltip className="tooltip-head-icon" text="Logout" position="bottom">
                            <div className="head-ico-wrap">
                                <i className="icon-sd-logout-medium icons-md white cursor-pointer"></i>
                            </div>
                        </RSPTooltip>
                    </li>
                </ul>
            </div>
        </div>
        {isOpenLogoutModal &&
            <RSConfirmAlert 
                subTitle="Are you sure you want to logout?"
                subTitleClass="m0"
                primaryButtonText="OK"
                secondaryButtonText="Cancel"
                buttonClicked={(value) => {
                if (value) {
                    let userData = getUserData()
                    let params = {
                        "sessionID": userData.sessionID,
                        "userId": userData.userId
                    }
                    connectServer({
                        path: LOGOUT,
                        params,
                        loading: context.globalStateData.setIsLoadingData,
                        sessionOut: context.globalStateData.setSessionOutData,
                        context: context,
                        ok: res => {
                            if (res.status) {
                                document.body.setAttribute('data-theme', "default")
                                localStorage.removeItem('isAuth')
                                // localStorage.removeItem('session')
                                context.toggleAuth(false)
                                history.push("/")
                            }
                        },
                        fail: error => {

                        }
                    })

                }
                setIsOpenLogoutModal(false)
            }} />
        }
        {
            isShowNotification &&
            <RSPNotificationList
                context={context}
                onChangeOpen={(value) => { setIsShowNotification(!isShowNotification) }}
                readCount={readCount}
                onChangeReadCount={(value) => { setReadCount(value) }}
                notificationList={notificationList} />
        }
        <div className="breadcrumbs">
            <div className="container">
                <ul className="breadcrumb">
                    {pathArray.length > 0 && pathArray.map((item, index) => {
                        let items = item.replace(/-/g, ' ');
                        let capitalize = (str) => {
                            return str.charAt(0).toUpperCase() + str.slice(1)
                        }
                        return (
                            <li
                                key={index}
                                className={index === pathArray.length - 1 ? "active" : ""}
                                onClick={() => {
                                    if (index < pathArray.length - 1) {
                                        let pathName = "";
                                        pathArray.map((item, indexj) => {
                                            if (indexj <= index) {
                                                pathName = pathName + item;
                                                pathName = indexj === index ? pathName : pathName + "/"
                                            }
                                        })
                                        history.push(pathName)
                                    }
                                }}
                            >{items === "" ? "Dashboard" : capitalize(items)}</li>
                        )
                    })}
                    {/* <li>Dashboard</li>
                    <li className="active">Breadcrumb</li> */}
                </ul>
            </div>
        </div>
    </div >
}

const RSPNotificationList = (props) => {
    const context = useContext(authContext)
    const history = useHistory()
    const wrapperRef = useRef(null);
    useOutSideClick(wrapperRef);
    function useOutSideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.onChangeOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref])
    }
    const readNotification = notificationId => {
        const params = {
            "userId": getAuthUserId(),
            "notificationId": notificationId || null
        }
        connectServer({
            path: SET_ALERT_NOTIFICATION_READ_STATUS,
            params,
            loading: props.context.globalStateData.setIsLoadingData,
            sessionOut: props.context.globalStateData.setSessionOutData,
            context: props.context,
            ok: res => {
                context.globalStateData.setSelectedSubItem(null)
                history.push({
                    pathname: `/preferences/notification-details`,
                    state: {
                        notificationList: props.notificationList
                    }
                })
                props.onChangeReadCount(props.readCount - 1);
            },
            fail: err => {
            }
        })
    }
    const readAllNotification = () => {
        const params = {
            "departmentId": 0,
        }
        connectServer({
            path: SET_ALERT_NOTIFICATION_ALL_READ_STATUS,
            params,
            loading: props.context.globalStateData.setIsLoadingData,
            sessionOut: props.context.globalStateData.setSessionOutData,
            context: props.context,
            ok: res => {
                context.globalStateData.setSelectedSubItem(null)
                history.push({
                    pathname: `/preferences/notification-details`,
                    state: {
                        notificationList: props.notificationList
                    }
                })
                props.onChangeReadCount(0);
            },
            fail: err => {
            }
        })
    }
    return <div ref={wrapperRef} className="header-notis">
        <RSPNotificationListHeadComponent count={props.readCount} onChangeOpen={(value) => props.onChangeOpen(value)} />
        {props.notificationList.length === 0
            ? <div className='mt12 mx10'><SkeletonTable count={4} isError={true} message={"Notifications not found"} /> </div>
            : <div>
                <div className='notify-body css-scrollbar'>
                    <ul className="header-notis-list">
                        {
                            props.notificationList.map((item, index) => {
                                return <RSPNotificationListBodyComponent key={index} item={item}
                                    notificationList={props.notificationList}
                                    onChangeOpen={(value) => {
                                        readNotification(item.notificationID);
                                        props.onChangeOpen(value)
                                    }} />
                            })
                        }
                    </ul>
                </div>

                <RSPNotificationListFootComponent
                    readAllStatus={() => readAllNotification()}
                    onChangeOpen={(value) => props.onChangeOpen(value)}
                    notificationList={props.notificationList} />
            </div>
        }
    </div>
}

const RSPNotificationListHeadComponent = props => {
    const history = useHistory()
    const context = useContext(authContext)
    return <div className="header-notis-head">
        <p className="mb0 fs16">Notifications ({numberWithCommas(props.count)})</p>
        <RSPTooltip text="Settings" position="bottom">
            <i
                className="icon-sd-settings-medium icons-md blue cursor-pointer"
                onClick={() => {
                    context.globalStateData.setSelectedSubItem(null)
                    history.push(`/preferences/alerts-and-notifications`);
                    props.onChangeOpen(false);
                }} />
        </RSPTooltip>
    </div>
}

const RSPNotificationListFootComponent = props => {
    const history = useHistory()
    const context = useContext(authContext);
    return <div className="header-notis-footer">
        <RSPrimaryBtn onClick={() => {
            props.readAllStatus()
            props.onChangeOpen(false);
        }}>
            Mark all as read
        </RSPrimaryBtn>
        {/* <p className="blue cursor-pointer" onClick={() => {
            props.readAllStatus()
            props.onChangeOpen(false);
        }}></p> */}
        <RSSecondaryBtn onClick={() => {
            props.readAllStatus()
            // context.globalStateData.setSelectedSubItem(null)
            // history.push({
            //     pathname: `/preferences/notification-details`,
            //     state: {
            //         notificationList: props.notificationList
            //     }
            // })
            props.onChangeOpen(false);
        }}>
            View more
        </RSSecondaryBtn>
    </div>
}

const RSPNotificationListBodyComponent = props => {

    const getTimeInterval = date => {
        let seconds = Math.floor((new Date(dateTimeFormatWithMomentForReports(Date.now())) - date) / 1000);
        let unit = "second";
        let direction = "ago";
        if (seconds < 0) {
            seconds = -seconds;
            direction = "from now";
        }
        let value = seconds;
        if (seconds >= 31536000) {
            value = Math.floor(seconds / 31536000);
            unit = "year";
        } else if (seconds >= 2592000) {
            value = Math.floor(seconds / 2592000);
            unit = "month";
        } else if (seconds >= 86400) {
            value = Math.floor(seconds / 86400);
            unit = "day";
        } else if (seconds >= 3600) {
            value = Math.floor(seconds / 3600);
            unit = "hour";
        } else if (seconds >= 60) {
            value = Math.floor(seconds / 60);
            unit = "minute";
        }
        if (value != 1)
            unit = unit + "(s)";
        return value + " " + unit + " " + direction;
    }

    return <li onClick={() => {

        props.onChangeOpen(false);
    }}>
        {/* <div className="notify-icon"> */}
        {/* <i className={`circle-view-icon icons-md ${props.item.icon ? props.item.icon : 'icon-alert-fill-small'}`}></i> */}
        {/* <i className={campaignProgressStatus[props.item.notificationTypeID].icon}></i> */}
        {/* </div> */}
        <div className="notify-icon">
            {/* <i className={`circle-view-icon ${props.dataItem.icon ? props.dataItem.icon : 'icon-alert-fill-small'} icons-md ${props.dataItem.statusIndicate}`}></i> */}
            <i className={`circle-view-icon ${campaignProgressStatus[props.item.notificationTypeID].icon} ${campaignProgressStatus[props.item.notificationTypeID].color}`}></i>
        </div>
        <div>
            <p>{props.item.description}</p>
            <small>{getTimeInterval(new Date(dateTimeFormatWithMomentForReports(props.item.createdDate)))}</small>
        </div>
    </li>
}

const campaignProgressStatus = {
    1: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    2: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    3: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    4: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    5: { color: 'bgGreen', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    6: { color: 'bgGreen', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    7: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    8: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    9: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    10: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    11: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    12: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    13: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    14: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    15: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    16: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    17: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    18: { color: 'bgOrange', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    19: { color: 'bgOrange', icon: 'icon-sd-fire-medium icons-md' },
    20: { color: 'bgOrange', icon: 'icon-sd-fire-medium icons-md' }
}