import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { GET_CAMPAIGN_STATUS_DROPDOWN, GET_CAMPAIGN_STATUS_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { dateTimeFormatWithMomentForReports } from 'Helper/Utils/Utils';
import React, { Component } from 'react';
import ShowLinksPopup from '../../Components/ShowLinksPopup';
import DownloadCSVModal from '../../Components/DownloadCSVModal';
import { RSPTooltip } from 'Components/Tooltip';

const initialDataState = {
    sort: [
        {
            field: "name",
            dir: "asc"
        }
    ],
    take: 5,
    skip: 0
}
class EmailCommunicationStatus extends Component {

    static contextType = authContext
    state = {
        dataState: initialDataState,
        campaignStatusList: null,
        campaignStatusDropdownData: [],
        defaultDropdownData: "Opened and Clicked",
        defaultDropdownValue: "OpenClicks",
        latestDate: this.props.latestDate,
        isShowLinksOpen: false,
        showLinksIndex: 0,
        rowKey: this.props.rowKey,
        listStatusError: false,
        campaignId: this.props.campaignId,
        isOpenDownloadCSVModal: false,
    }

    componentDidMount = () => {
        if (!!this.state.rowKey) {
            this.getCampaignStatusDropdown()
            this.getCampaignStatus("OpenClicks", this.state.rowKey)
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.setState({ rowKey: this.props.rowKey, campaignId: this.props.campaignId, latestDate: this.props.latestDate });
            if (this.props.rowKey !== prevProps.rowKey) {
                this.getCampaignStatusDropdown()
                this.getCampaignStatus("OpenClicks", this.props.rowKey)
            }
        }
    }

    getCampaignStatusDropdown = () => {

        const params = {
            "channelId": 1
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_DROPDOWN,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res?.data) {
                    this.setState({
                        campaignStatusDropdownData: res?.data?.channelKeyDetailList || [],
                        defaultDropdownData: res?.data?.channelKeyDetailList[2].name
                    })
                }
            },
            fail: err => { }
        })

    }

    getCampaignStatus = (status, rowKey) => {

        this.setState({ listStatusError: false });
        // const { campaignId } = this.props.state
        const params = {
            "campaignId": this.state.campaignId,
            "channelId": 1,
            "status": status,
            "rowkey": rowKey
        }

        connectServer({
            path: GET_CAMPAIGN_STATUS_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res?.data?.activityList?.length) {
                    this.setState({ campaignStatusList: res?.data?.activityList || [] })
                } else {
                    this.setState({
                        campaignStatusList: [],
                        listStatusError: true
                    })
                }
            },
            fail: err => {
                this.setState({
                    campaignStatusList: [],
                    listStatusError: true
                })
            }
        })

    }
    ShowLinksComponent = (props) => {
        return (<td>
            <div className="d-flex justify-content-between">
                <label>{props.dataItem.totalClicks || props.dataItem.totalConversions}</label>
                {(props.dataItem.totalClicks !== 0 || props.dataItem.totalConversions !== 0) &&
                    <span className="marginB0 cursor-pointer text-underline-h blue" onClick={() => {
                        this.setState({ isShowLinksOpen: true, showLinksIndex: props.dataIndex })
                    }}>Show link</span>
                }
            </div>
        </td>)
    }
    ShowLinksConversionsComponent = (props) => {
        return (<td>
            <div className="d-flex justify-content-between">
                <label>{props.dataItem.totalConversions}</label>
                {props.dataItem.totalConversions !== 0 &&
                    <span className="marginB0 cursor-pointer text-underline-h" onClick={() => {
                        this.setState({ isShowLinksOpen: true, showLinksIndex: props.dataIndex })
                    }}>Show link</span>
                }
            </div>
        </td>)
    }
    render() {
        return (
            <><div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    <h3 className="marginB15">Communication status ({this.state.campaignStatusList?.length})</h3>
                    {this.state.campaignStatusList?.length > 0 && <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(this.state.latestDate)}</span>)</span>}
                </div>
                <div className='d-flex justify-content-end'>
                    <BootstrapDropdown
                        data={this.state.campaignStatusDropdownData || []}
                        defaultItem={this.state.defaultDropdownData}
                        customAlignRight={true}
                        className="fs18-btn"
                        alignRight
                        fieldKey='name'
                        onSelect={(item, index) => {
                            this.setState({
                                campaignStatusList: [],
                                defaultDropdownData: item.name,
                                defaultDropdownValue: item.value
                            })
                            this.getCampaignStatus(item.value, this.state.rowKey)
                        }}
                    />
                    <RSPTooltip text={"Download CSV"} position="top">
                        <i className={`icon-sd-csv-medium icons-md blue ml10 cp ${this.state.campaignStatusList?.length !== 0 ? "" : "click-off"}`} onClick={() => {
                            this.setState({ isOpenDownloadCSVModal: true })
                        }} />
                    </RSPTooltip>
                </div>
                {this.state.isShowLinksOpen &&
                    <ShowLinksPopup
                        isOpen={this.state.isShowLinksOpen}
                        linkList={this.state.campaignStatusList[this.state.showLinksIndex].linkClickDetails}
                        onChangeIsOpen={(value) => { this.setState({ isShowLinksOpen: false }) }} />
                }
                {this.state.isOpenDownloadCSVModal &&
                    <DownloadCSVModal
                        isOpen={this.state.isOpenDownloadCSVModal}
                        channelId={1}
                        isForm={false}
                        campaignId={this.state.campaignId}
                        rowKey={this.state.rowKey}
                        status={this.state.defaultDropdownValue}
                        onChangeIsOpen={(value) => {
                            this.setState({ isOpenDownloadCSVModal: false })
                        }} />
                }
            </div>
                {
                    this.state.campaignStatusList?.length ?
                        this.state.defaultDropdownData === "Opened and clicked" ?
                            <Grid
                                className={`grid-pagenation-del grid-replaceable mb0`}
                                data={process(this.state.campaignStatusList, this.state.dataState || [])}
                                {...this.state.dataState}
                                scrollable={"none"}
                                sortable={true}
                                pageable={false}
                                onDataStateChange={e => this.setState({ dataState: e.dataState })}
                            >
                                <GridColumn field="rowNo" filter="text" columnMenu={ColumnMenu} title="S.No" />
                                <GridColumn field="emailId" filter="text" columnMenu={ColumnMenu} title="Email" />
                                <GridColumn field="totalOpen" filter="text" columnMenu={ColumnMenu} title="Total opened" />
                                <GridColumn field="totalClicks" filter="text" columnMenu={ColumnMenu} title="Total clicked" cell={this.ShowLinksComponent} />
                                <GridColumn field="totalConversions" filter="text" columnMenu={ColumnMenu} title="Conversions" cell={this.ShowLinksConversionsComponent} />
                            </Grid> : this.state.defaultDropdownData === "Forwarded" ?
                                <Grid
                                    className="grid-pagenation-del grid-replaceable"
                                    data={process(this.state.campaignStatusList, this.state.dataState || [])}
                                    {...this.state.dataState}
                                    scrollable={"none"}
                                    sortable={true}
                                    pageable={{
                                        //type: 'input',
                                        pageSizes: true,
                                        previousNext: true
                                    }}
                                    onDataStateChange={e => this.setState({ dataState: e.dataState })}
                                >
                                    <GridColumn field="rowNo" filter="text" columnMenu={ColumnMenu} title="S.No" />
                                    <GridColumn field="referrerEmail" filter="text" columnMenu={ColumnMenu} title="Referrer email" />
                                    <GridColumn field="referredEmail" filter="text" columnMenu={ColumnMenu} title="Referred email" />
                                </Grid> :
                                <Grid
                                    className="grid-pagenation-del grid-replaceable"
                                    data={process(this.state.campaignStatusList, this.state.dataState || [])}
                                    {...this.state.dataState}
                                    scrollable={"none"}
                                    sortable={true}
                                    pageable={{
                                        //type: 'input',
                                        pageSizes: true,
                                        previousNext: true
                                    }}
                                    onDataStateChange={e => this.setState({ dataState: e.dataState })}
                                >
                                    <GridColumn field="rowNo" filter="text" columnMenu={ColumnMenu} title="S.No" />
                                    <GridColumn field="emailId" filter="text" columnMenu={ColumnMenu} title="Email" />
                                </Grid>
                        : <SkeletonTable count={4} isError={this.state.listStatusError} />
                }</>
        );
    }
}

export default EmailCommunicationStatus;

const ColumnMenu = props => {

    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;

};