import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

class DynamicInfo extends Component {
    state = {
        isModalOpen: this.props.isOpen,
        blastCampaign: [
            {
                date: "Tue, Nov 23, 2021",
                name: "Effortless Mobile Banking"
            },
            {
                date: "Mon, Nov 01, 2021",
                name: "Fixed deposit renewal"
            },
            {
                date: "Sun, Oct 24, 2021",
                name: "Amazon Great shopping festival"
            },
            {
                date: "Tue, Oct 12, 2021",
                name: "Promotional Offer"
            },
            {
                date: "Tue, Dec 14, 2021",
                name: "Gold bonds"
            }
        ],
        pushtypes: [
            {
                type: "Email",
                count: "15,300"
            },
            {
                type: "Mobile",
                count: "15,300"
            }
        ],
        deliveryStatus: [
            {
                deliveryType: "Spam",
                count: "80"
            },
            {
                deliveryType: "Bounced",
                count: "50"
            },
            {
                deliveryType: "Unsubscribed",
                count: "100"
            },
            {
                deliveryType: "DND",
                count: "75"
            }
        ]
    }
    handleClose = () => {
        setTimeout(() => {
            this.props.onChangeIsOpen()
        }, 500)
        this.setState({ isModalOpen: false });
    }
    render() {
        return (
            <Modal
                backdrop="static"
                keyboard={false}
                className="dynamic-list-fill"
                show={this.state.isModalOpen}
                onHide={() => this.handleClose()}
                size="xlg"
                centered
            >
                <Modal.Header className="pb0" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <h3>Ecommerce special offer</h3>
                        </Col>
                        <Col md={6}>
                            <div className="blue float-end">
                                <small>
                                    Created by:
                                    <span>Sophia Hale</span>, On:
                                    <span>Mon, Nov 01, 2021</span>
                                </small>
                            </div>
                        </Col>
                    </Row>

                    <Row className="marginT20">
                        <Col md={6} xs={12}>
                            <h6>Campaign blasted (5)</h6>
                            <ul className="campaign-pop-list">
                                {this.state.blastCampaign.map((campaign, index) => {
                                    return <li key={index + ''}>
                                        <small>{campaign.date}</small>
                                        <span className="blue">{campaign.name}</span>
                                    </li>
                                })}
                            </ul>
                        </Col>
                        <Col md={6}>
                            <div>
                                <h6>Audience by channel (<span>215,300</span>)</h6>
                            </div>
                            <div>
                                <ul className="campaign-pop-list2">
                                    {this.state.pushtypes.map((pushType, index) => {
                                        return <li key={index + ''}>
                                            <span className="float-start blue">{pushType.type}</span>
                                            <span className="float-end blue">{pushType.count}</span>
                                        </li>
                                    })}
                                </ul>
                                <h6>Deliverability</h6>
                                <ul className="campaign-pop-list2 border-bottom-0">
                                    {this.state.deliveryStatus.map((status, index) => {
                                        return <li key={index + ''}>
                                            <span className="float-start blue">{status.deliveryType}</span>
                                            <span className="float-end blue">{status.count}</span>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        );
    }
}

export default DynamicInfo;