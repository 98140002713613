import { Container } from "react-bootstrap"
import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import AdminListing from "./AdminListing";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";


const Admin = props => {
    let match = useRouteMatch();

    return <Switch>

        <Route exact path={`${match.url}/`}>
            <AdminListing />
        </Route>
        <Route path={`${match.url}/campaign-gate`}>
            <CampaignGate />
        </Route>
        <Route path={`${match.url}/payment-gate`}>
            <PaymentGate />
        </Route>

    </Switch>
}

export default Admin


const CampaignGate = props => {
    return(
        <LayoutPageWrapper>
            <Container className="page-header">
                <h1>Campaign gate</h1>
            </Container>
        </LayoutPageWrapper>
    )
}

const PaymentGate = props => {
    return(
        <LayoutPageWrapper>
            <Container className="page-header">
                <h1>Payment gate</h1>
            </Container>
        </LayoutPageWrapper>
    )
}

