import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import NewCharts from 'Components/Charts/NewCharts';
import { ch_chartColor, ch_emailColor, ch_twitterColor, ch_linkedinColor, ch_qrColor, ch_googleColor, ch_pinterestColor, ch_facebookColor } from 'Components/Charts/ChartColors';

const CampaignSummaryPanel = props => {
    const [isOpen, setIsShow] = useState(props.isOpen);
    let style;
    if (isOpen) {
        style = { display: 'block', width: '25rem', position: 'absolute', top: '22%', right: '0', height: 'auto' }
    } else {
        style = { display: 'none', width: '18rem' }
    }
    return <>
        <Card style={style}
            className="mb-2"
        >
            <Card.Header>Campaign Summary
                <span className="back-text"
                    onClick={() => {
                        setIsShow(!isOpen);
                        props.onChangeIsOpen(false);
                    }}
                >
                    <i className="icon-sd-close-mini icon-sm blue cp"></i>
                </span>
            </Card.Header>
            <Card.Body >
                <Row>
                    <Col md={12}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Audience Summary</h4>
                                <div className="p-nav-right">
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className="portlet-chart varpie-chart"><NewCharts options={leadsGeneratedChart} /></div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Campaign performance</h4>
                                <div className="p-nav-right">
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className="portlet-chart varpie-chart"><NewCharts options={leadsGeneratedChart} /></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>
}
export default CampaignSummaryPanel;

const leadsGeneratedChart = {
    chart: {
        type: "variablepie"
    },
    tooltip: {
        shared: true,
        pointFormat: '<span style="color:{point.color}">●</span>{series.name}: <b>{point.y:,.0f}</b><br/>'
    },
    plotOptions: {
        variablepie: {
            dataLabels: {
                useHTML: true,
                distance: 14,
                formatter: function () {

                    if (this.color === ch_googleColor) {
                        return ('<div class="pieScatterDBContainer googleplus"><i class="miniIcons mi-googleplus"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }
                    else if (this.color === ch_emailColor) {
                        return ('<div class="pieScatterDBContainer email"><i class="miniIcons mi-email"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }
                    else if (this.color === ch_qrColor) {
                        return ('<div class="pieScatterDBContainer qrcode"><i class="miniIcons mi-qrcode"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }
                    else if (this.color === ch_pinterestColor) {
                        return ('<div class="pieScatterDBContainer pinterest"><i class="miniIcons mi-pinterest"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }
                    else if (this.color === ch_facebookColor) {
                        return ('<div class="pieScatterDBContainer facebook"><i class="miniIcons mi-facebook"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }
                    else if (this.color === ch_twitterColor) {
                        return ('<div class="pieScatterDBContainer twitter"><i class="miniIcons mi-twitter"></i> <span class="psPercentage">' + this.y + '<span>%</span></span></div>');
                    }

                },
            }
        }
    },
    series: [
        {
            showInLegend: false,
            borderWidth: 2,
            height: "100%",
            size: "100%",

            minPointSize: 10,
            innerSize: '10%',
            startAngle: 180,
            zMin: 0,
            name: 'Leads acquisition',

            data: [
                { name: "Notifications", color: ch_linkedinColor, y: 6, z: 6 },
                { name: "Email", color: ch_emailColor, y: 7, z: 7 },
                { name: "QR code", color: ch_qrColor, y: 12, z: 12 },
                { name: "Pinterest", color: ch_pinterestColor, y: 15, z: 15 },
                { name: "Facebook", color: ch_facebookColor, y: 20, z: 20 },
                { name: "Twitter", color: ch_twitterColor, y: 40, z: 40 },
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                distance: 25,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false,
                connectorWidth: 0,
                color: "#333",
                style: { color: ch_chartColor, fontSize: '14px', fontWeight: 'normal' }
            },
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    enabled: false,
                }
            }
        },
        {
            borderWidth: 0,
            size: '10%',
            innerSize: '60%',
            data: [{ color: "rgba(255, 255, 255, 0.6)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}