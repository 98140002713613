import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";
import React, { memo } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const CopyModal = ({ data }) => {
  const {
    copyTemplateModal,
    setCopyTemplateModal,
    templateName,
    setTemErr,
    tempErr,
    handleCancel,
    handleSaveCopy,
    setTemplateName,
  } = data;
  return (
    <>
      <Modal
        show={copyTemplateModal}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
        onHide={() => {
          setCopyTemplateModal(false);
          setTemErr("");
        }}
      >
        <Modal.Header closeButton>
          <h2>Template name</h2>
        </Modal.Header>
        <Modal.Body>
          <Row className="split-ab-modal">
            <Col>
              <RSInput2
                ph="Enter template name"
                required={true}
                val={templateName}
                max={50}
                cb={(text) => {
                  var regex = /^[A-Za-z0-9 ]+$/;
                  if (text.length > 0) {
                    if (regex.test(text)) {
                      let errMsg = text.length ? null : "Enter template name";
                      setTemplateName(text);
                      setTemErr(errMsg);
                    } else {
                      setTemErr("Special characters are not allowed");
                    }
                  } else {
                    setTemplateName(text);
                    setTemErr("Enter template name");
                  }
                }}
                errorMessage={tempErr}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container d-flex justify-content-end">
            <RSSecondaryBtn
              padding="0px"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </RSSecondaryBtn>

            <RSPrimaryBtn
              className={tempErr !== null ? "click-off" : ""}
              onClick={() => {
                handleSaveCopy();
              }}
            >
              Save
            </RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(CopyModal);
