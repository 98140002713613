import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { CHANNEL_NAME,CHANNEL_LIMIT,CHANNEL_INTERVAL } from "Helper/Constants/validationMessage"

const FrequencyCap = props => {
    const [frequencyCapDetails, setfrequencyCapDetails] = useState([
    {
        "channel": {
            channelId: 0,
            channelName: "-- Select channel --"
        },
        "limit": {
            limitId: 0,
            limit: "-- Limit --"
        },
        "timeInterval": {
            timeIntervalId: 0,
            timeInterval: "-- Time interval --"
        }
    }
]);
const [errMessRow, setMessRow] = useState([{
    "errMsgChannelName": null,
    "errMsgChannelLimit": null,
    "errMsgChannelInterval": null
}]);
const validation = () => {
    for(let i=0;i<frequencyCapDetails.length;i++)
    {
        if(frequencyCapDetails[i].channel.channelId ===0) {
            let errMsgChannel = CHANNEL_NAME
            let temp=[...errMessRow]
            temp[i].errMsgChannelName=errMsgChannel
            setMessRow(temp)
            return
        }
        if(frequencyCapDetails[i].limit.limitId===0) {
            let errMsgLimit = CHANNEL_LIMIT
            let temp=[...errMessRow]
            temp[i].errMsgChannelLimit=errMsgLimit
            setMessRow(temp)
            return
        }
        if(frequencyCapDetails[i].timeInterval.timeIntervalId===0) {
            let errMsgInterval = CHANNEL_INTERVAL
            let temp=[...errMessRow]
            temp[i].errMsgChannelInterval=errMsgInterval
            setMessRow(temp)
            return
        }
    }
    return true
}
    return (
        <div className="width100p mt30">
            {frequencyCapDetails.map((item, index) => {
                return (
                    <Row className="mb30">
                        <Col md={{ span: 3, offset: 1 }}>
                            {
                                errMessRow[index].errMsgChannelName&& (
                                <div className="validation-message">
                                    { errMessRow[index].errMsgChannelName }
                                </div>)
                            }
                            <RSDropdownList
                                className="mb0"
                                data={channelData}
                                value={item.channel} 
                                defaultItem={{
                                    channelId: 0,
                                    channelName: "-- Select channel --"
                                }}
                                textField='channelName'
                                dataItemKey='channelId'
                                onChange={(e) => {
                                    let temp=[...frequencyCapDetails];
                                    temp.channel=e.target.value;
                                    setfrequencyCapDetails(temp);
                                    item.channel = e.target.value;
                                    let errMsg = item.channel.channelId!==0 ? null : CHANNEL_NAME
                                    let tempError=[...errMessRow];
                                    tempError[index].errMsgChannelName=errMsg;
                                    setMessRow(tempError)
                                }}
                             />
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={4}>
                                        {errMessRow[index].errMsgChannelLimit&& (
                                        <div className="validation-message">
                                            {errMessRow[index].errMsgChannelLimit}
                                        </div>)}
                                    <RSDropdownList
                                        className="mb0"
                                        data={limitData}
                                        value={item.limit}
                                        defaultItem={{
                                            limitId: 0,
                                            limit: '-- Limit --'
                                        }}
                                        textField='limit'
                                        dataItemKey='limitId'
                                        onChange={(e) => {
                                            let temp=[...frequencyCapDetails];
                                            temp.limit=e.target.value;
                                            setfrequencyCapDetails(temp);
                                            item.limit = e.target.value;
                                            let errMsg = item.limit.limitId!==0 ? null : CHANNEL_LIMIT
                                            let tempError=[...errMessRow];
                                            tempError[index].errMsgChannelLimit=errMsg;
                                            setMessRow(tempError)
                                        }}
                                     />
                                </Col>
                                <Col md={1} className="pt8 text-center">
                                    <label>per</label>
                                </Col>
                                <Col md={5}>
                                    { errMessRow[index].errMsgChannelInterval && (
                                            <div className="validation-message">
                                                {errMessRow[index].errMsgChannelInterval}
                                            </div>)
                                    }
                                    <div className="position-relative">
                                        <RSDropdownList
                                            className="mb0"
                                            data={timeIntervalData}
                                            value={item.timeInterval}
                                            defaultItem={{
                                                timeIntervalId: 0,
                                                timeInterval: "-- Time interval --"
                                            }}
                                            textField='timeInterval'
                                            dataItemKey='timeIntervalId'
                                            onChange={(e) => {
                                                let temp=[...frequencyCapDetails];
                                                temp.timeInterval=e.target.value;
                                                setfrequencyCapDetails(temp);
                                                item.timeInterval = e.target.value;
                                                let errMsg = item.timeInterval.timeIntervalId!==0 ? null : CHANNEL_INTERVAL
                                                let tempError=[...errMessRow];
                                                tempError[index].errMsgChannelInterval=errMsg;
                                                setMessRow(tempError)
                                            }}
                                            
                                        />

                                        <div className="input-outer-set">
                                            <i className={`icons-md blue cursor-pointer d-block ml10 ${index === 0 ? `${frequencyCapDetails.length < 5 ? "icon-sd-circle-plus-fill-edge-medium " : "icon-sd-circle-plus-fill-edge-medium click-off "}` : "icon-sd-circle-minus-fill-edge-medium red"}`} onClick={() => {
                                                if (index !== 0) {
                                                    let temp = [...frequencyCapDetails];
                                                    temp.splice(index, 1);
                                                    setfrequencyCapDetails(temp);
                                                } else if (index < 5) {
                                                    let getTruth=validation()
                                                    if(getTruth){
                                                    let temp = [...frequencyCapDetails];
                                                    temp.push({
                                                        "channel": {
                                                            channelId: 0,
                                                            channelName: "-- Select channel --"
                                                        },
                                                        "limit": {
                                                            limitId: 0,
                                                            limit: "-- Limit --"
                                                        },
                                                        "timeInterval": {
                                                            timeIntervalId: 0,
                                                            timeInterval: "-- Time interval --"
                                                        }
                                                    });
                                                    setfrequencyCapDetails(temp);
                                                    let temperror=[...errMessRow]
                                                        temperror.push({
                                                            "errMsgChannelName": null,
                                                            "errMsgChannelLimit": null,
                                                            "errMsgChannelInterval": null
                                                    })
                                                    setMessRow(temperror)
                                                    }
                                                    } 
                                                 
                                            }} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
            <div className="btn-container d-flex justify-content-end mt15 mr20">
                <RSSecondaryBtn onClick={() => {
                }}>
                    Cancel
                </RSSecondaryBtn>
                <RSPrimaryBtn
                    onClick={() => {
                        validation()
                    }}>
                    Save
                </RSPrimaryBtn>
            </div>
        </div>
    );
}
export default FrequencyCap;

const channelData = [
    {
        channelId: 1,
        channelName: "Email"
    },
    {
        channelId: 2,
        channelName: "SMS"
    },
    {
        channelId: 3,
        channelName: "Web push"
    },
    {
        channelId: 4,
        channelName: "Mobile push"
    },
    {
        channelId: 5,
        channelName: 'WhatsApp'
    }
]

const limitData = [
    {
        limitId: 1,
        limit: 1
    },
    {
        limitId: 2,
        limit: 2
    },
    {
        limitId: 3,
        limit: 3
    },
    {
        limitId: 4,
        limit: 4
    },
    {
        limtId: 5,
        limit: 5
    }
]

const timeIntervalData = [
    {
        timeIntervalId: 1,
        timeInterval: "Day"
    },
    {
        timeIntervalId: 2,
        timeInterval: "Week"
    },
    {
        timeIntervalId: 3,
        timeInterval: "Month"
    }
]