const ExpandAllMini = (props) => {
    return (
        <div className="ExpandAllMini">
            <svg
                // xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                // fill="currentColor"
                class="bi bi-google"
                viewBox="0 0 14 14"
            >
                <path class={`st0 ${props.color ?? 'th-primary-fill-color'}`} d="M0,0v14h14V0H0z M12,10H7V9h5V10z M0.8,13.2L1,13V1h12L0.8,13.2z" />
                <polygon class={`st0 ${props.color ?? 'th-primary-fill-color'}`} points="4,7 5,7 5,5 7,5 7,4 5,4 5,2 4,2 4,4 2,4 2,5 4,5 " />
            </svg>
        </div>
    )
}
export default ExpandAllMini;