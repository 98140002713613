import React from 'react';

import Skeleton from 'react-loading-skeleton'
import { Col, Row } from "react-bootstrap";

import * as icons from "Constants/GlobalConstant/Glyphicons/Glyphicons";
import * as constants from "./constants";

import 'react-loading-skeleton/dist/skeleton.css'


const CommonSkeleton = (props) => {
  const { space, height, circle, width, box, checkbox, icon, className, active, tabspace } = props
  return (
    <div className={`${space ? constants.sspace : ''} ${tabspace ? 'mb5' : ''}`}>
      {circle && <Skeleton circle={true} className={`${className ? className : ''} ${active ? 'active' : ''}`} height={height ?? 31} width={width ?? 31} />}

      {box && <Skeleton className={`${className ? className : ''} ${active ? 'active' : ''}`} height={height ?? 31} width={width ?? constants.widthFill} />}

      {checkbox && <div className="circle-line">
        <Skeleton circle={true} className={`${active ? 'active' : ''}`} height={height ?? 20} width={width ?? 20} />
        <Skeleton className={`${active ? 'active' : ''}`} height={height ?? 20} width={width ?? constants.widthFill} />
      </div>}

      {icon && <Skeleton circle={true} className={`${active ? 'active' : ''} ${className ? className : ''}`} height={height ?? 31} width={width ?? 31} />}
    </div>
  )
}

export const CommunicationGallerySkeleton = (props) => {

  return (
    <div className="box-design">
      <div className="skeleton-span-con">
        <Row>
          <Col md={2}><CommonSkeleton space box height={15} /></Col>
          <Col md={3}><CommonSkeleton space box height={15} /></Col>
          <Col md={2}><CommonSkeleton space box height={15} /></Col>
          <Col md={4}><CommonSkeleton space box height={15} /></Col>
          <Col md={1}><CommonSkeleton space box height={15} /></Col>
        </Row>
        <Row>
          <Col md={10}><CommonSkeleton space box height={15} /></Col>
          <Col md={2}><CommonSkeleton space box height={15} /></Col>
        </Row>
        {
          props.isError
            ? <Row>
              <Col md={3}></Col>
              <Col md={6}>
                {
                  props.text?.length
                    ? <div className="nodata-bar">
                      <p className="d-flex align-items-center"><i className="icon-sd-alert-medium icons-md orange-medium" />{props.text}</p>
                    </div>
                    : <div className="nodata-bar"><i className="icon-sd-alert-medium icons-md orange-medium" />
                      <p>{"No data available"}</p>
                    </div>
                }
              </Col>
              <Col md={3}></Col>
            </Row>
            : null
        }
        <Row>
          <Col md={6}>
            <Row>
              <Col md={12}><CommonSkeleton box height={15} /></Col>
              <Col md={12}><CommonSkeleton space box height={10} /></Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}><CommonSkeleton box height={15} /></Col>
              <Col md={12}><CommonSkeleton space box height={10} /></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}><CommonSkeleton box height={50} /></Col>
          <Col md={6}><CommonSkeleton box height={50} /></Col>
        </Row>
      </div>
    </div>
  )
}

export const SkeletonNoData = props => {
  return (
    <div className={`no-data area full skeleton-center ${props.mainClass ? props.mainClass : ""}`}>
      <span className="nodata-bar">
        <i className="icon-sd-alert-medium icons-md orange-medium"></i>
        <p>No data available</p>
      </span>
    </div>
  )
}

export const CampaignSummarySkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span width50p" style={{ height: 200 }}>
          <Skeleton />
        </div>
        <div className="skeleton-span fill">
          <Skeleton />
          <hr className="height5" />
          <Skeleton />
        </div>
      </div>
      <hr />
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={40} />
        </div>
      </div>
    </div>
  )
}

export const AudienceSummarySkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={90} />
          <hr className="height5" />
        </div>
      </div>
      <div className="dflex width100p">
        <div className="skeleton-span width50p" style={{ height: 200 }}>
          <div className="mt25 ml40">
            <Skeleton circle={true} width={170} height={170} />
          </div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={30} />
          <hr className="height5" />
          <Skeleton height={179} />
        </div>
      </div>
    </div>
  )
}


export const CampaignStatusSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p">
          <Skeleton height={96} />
        </div>
      </div>
      <hr />
      <div className="dflex width100p">
        <div className="skeleton-span fill">
          <div className="mr5"><Skeleton height={105} /></div>
          <hr className="height5" />
          <div className="mr5"><Skeleton height={102} /></div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={105} />
          <hr className="height5" />
          <Skeleton height={102} />
        </div>
      </div>
    </div>
  )
}


export const AttributeUtilisationSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p">
          <Skeleton height={96} />
        </div>
      </div>
      <hr />
      <div className="dflex width100p">
        <div className="skeleton-span fill">
          <div className="mr5"><Skeleton height={105} /></div>
          <hr className="height5" />
          <div className="mr5"><Skeleton height={102} /></div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={105} />
          <hr className="height5" />
          <Skeleton height={102} />
        </div>
      </div>
    </div>
  )
}


export const TopProductTypesSkeleton = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span width50p" style={{ height: 200 }}>
          <div className="mt60 ml20">
            <Skeleton circle={true} width={200} height={200} />
          </div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={40} />
          <hr className="height5" />
          <Skeleton height={268} />
        </div>
      </div>
    </div>
  )
}


export const TopProductTypesSkeletonChartData = () => {
  return (
    <>
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="mt60 ml20">
        <Skeleton circle={true} width={200} height={200} />
      </div>
    </>
  )
}

export const TopProductTypesSkeletonTableData = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span fill">
          <Skeleton height={40} />
          <hr className="height5" />
          <Skeleton height={268} />
        </div>
      </div>
    </div>
  )
}


export const TopCampaignTypesSkeleton = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span width50p">
          <Skeleton />
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={40} />
          <hr className="height5" />
          <Skeleton height={268} />
        </div>
      </div>
    </div>
  )
}


export const CampaignDeliveryMethodSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p mr5">
          <Skeleton height={96} />
        </div>
        <div className="skeleton-span fill width100p">
          <Skeleton height={96} />
        </div>
      </div>
      <hr />
      <div className="dflex width100p">
        <div className="skeleton-span fill">
          <div className="mr5"><Skeleton height={103} /></div>
          <hr className="height5" />
          <div className="mr5"><Skeleton height={104} /></div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={103} />
          <hr className="height5" />
          <Skeleton height={104} />
        </div>
      </div>
    </div>
  )
}


export const PlatformUitlizationSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p">
        <div className="skeleton-span fill">
          <div className="mr5"><Skeleton height={154} /></div>
          <hr className="height5" />
          <div className="mr5"><Skeleton height={154} /></div>
        </div>
        <div className="skeleton-span fill">
          <Skeleton height={154} />
          <hr className="height5" />
          <Skeleton height={154} />
        </div>
      </div>
    </div>
  )
}

export const CampaignPerformanceSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={38} />
        </div>
      </div>
    </div>
  )
}


// Mobile / Web live
// *********************
// User status
// Visitor status
export const UserStatusSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      <div className="dflex flex-column width100p">
        <div className="skeleton-span fill center">
          <Skeleton circle={true} width={120} height={120} />
        </div>
        <div className="skeleton-span flex-row fill mt10">
          <div className="skeleton-span fill width40p">
            <Skeleton height={30} />
            <hr className="height5" />
            <Skeleton height={55} />
          </div>
          <div className="skeleton-span fill width40p ml5">
            <Skeleton height={30} />
            <hr className="height5" />
            <Skeleton height={55} />
          </div>
        </div>
      </div>
    </div>
  )
}
// Key metrics small
export const KeyMetricsSmallSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      <div className="dflex flex-column width100p">
        <div className="skeleton-span flex-row fill">
          <div className="skeleton-span fill width40p">
            <Skeleton height={57} />
            <hr className="height5" />
            <Skeleton height={57} />
            <hr className="height20" />
          </div>
          <div className="skeleton-span fill width40p ml5">
            <Skeleton height={57} />
            <hr className="height5" />
            <Skeleton height={57} />
            <hr className="height20" />
          </div>
        </div>
      </div>
    </div>
  )
}
// Active users
export const ActiveUsersSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      <div className="dflex flex-column width100p">
        <div className="skeleton-span flex-row fill">
          <div className="skeleton-span fill width40p">
            <Skeleton height={58} />
            <hr className="height5" />
            <Skeleton height={58} />
            <hr className="height20" />
          </div>
          <div className="skeleton-span fill width40p ml5">
            <Skeleton height={58} />
            <hr className="height5" />
            <Skeleton height={58} />
            <hr className="height20" />
          </div>
          <div className="skeleton-span fill width40p ml5">
            <Skeleton height={58} />
            <hr className="height5" />
            <Skeleton height={58} />
            <hr className="height20" />
          </div>
        </div>
      </div>
    </div>
  )
}
// Traffic breakdown by web visitors
// Audience types
// Known to conversion
// Retention
// Top screen views
// Top event summary
// Location
export const HorizontalSkeleton = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={38} />
        </div>
      </div>
    </div>
  )
}
// Traffic breakdown
export const TrafficBreakdownSkeleton = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={53} />
        </div>
      </div>
    </div>
  )
}
// Operating sytem
// Device
// App versions
// OS versions
// By hours
// App language
export const PieChartSkeleton = props => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="dflex width100p mt30">
        <div className="skeleton-span center" style={{ height: 220 }}>
          <Skeleton circle={true} width={220} height={220} />
          <hr className="height5" />
          <div className="d-flex pie-legend-skeleton-wr justify-content-center mt20 mx-auto pie-legend-skeleton" style={{ height: 220 }}>
            <Skeleton className="ml20" height={10} width={10} />
            <Skeleton height={10} width={40} />
            <Skeleton className="ml20" height={10} width={10} />
            <Skeleton height={10} width={40} />
            <Skeleton className="ml20" height={10} width={10} />
            <Skeleton height={10} width={40} />
          </div>
        </div>
        {props?.isError && <NoData />}
      </div>
    </div>
  )
}
// Recent campaign
export const SemiPieChartSkeleton = props => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      {
        props.nodata ? <NoData /> : null
      }
      <div className="dflex width100p">
        <div className="skeleton-span center" style={{ height: 180 }}>
          <Skeleton circle={true} width={180} height={180} />
          <hr className="height2" />
          <div className="d-flex semipie-legend-skeleton-wr flex-column justify-content-center mt20 mx-auto pie-legend-skeleton" style={{ height: 180 }}>
            <Skeleton height={10} width={100} />
            {/* <hr className="height2" /> */}
            {/* <Skeleton height={10} width={140} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
// Bubble chart
export const BubbleChartSkeleton = props => {
  return (
    <div className="skeleton-span-con">
      {
        props.nodata ? <NoData /> : null
      }
      <div className="dflex width100p">
        <div className="bubble-chart-skeleton" style={{ height: 180 }}>
          <Skeleton circle={true} width={120} height={120} />
          <Skeleton circle={true} width={140} height={140} />
          <Skeleton circle={true} width={100} height={100} />
          <Skeleton circle={true} width={130} height={130} />
          <Skeleton circle={true} width={98} height={98} />
        </div>
        {props.isError ? <NoData /> : null}
      </div>
    </div>
  )
}
// By days
export const ColumnChartSkeleton = props => {
  return (
    <div className="skeleton-span-con marginX20">
      {props?.isError && <NoData />}
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span flex-row fill width100p mt40">
          <Skeleton height={220} width={2} />
          <hr className="height5" />
          <Skeleton className="mt20" height={200} width={50} />
          <hr className="height5" />
          <Skeleton className="mt70" height={150} width={50} />
          <hr className="height5" />
          <Skeleton className="mt20" height={200} width={50} />
          <hr className="height5" />
          <Skeleton className="mt130" height={90} width={50} />
          <hr className="height3" />
          <Skeleton className="mt40 mr50" height={180} width={50} />
        </div>
      </div>
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={2} />
        </div>
      </div>
    </div>
  )
}
// Sankey skeleton
export const SankeyChartSkeleton = () => {
  return (
    <div className="skeleton-span-con">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p d-inline-block">
        <div className="skeleton-span fill width100p mt50">
          <Skeleton className="width50p ml10p" height={30} />
          <hr className="height5" />
          <Skeleton className="width80p" height={30} />
          <hr className="height5" />
          <Skeleton className="width90p ml5p" height={30} />
          <hr className="height5" />
          <Skeleton height={30} />
          <hr className="height5" />
          <Skeleton className="width50p ml30p" height={30} />
          <hr className="height5" />
          <Skeleton className="width80p ml10p" height={30} />
          <hr className="height5" />
          <Skeleton className="width90p ml5p" height={30} />
          <hr className="height5" />
          <Skeleton className="width30p ml40p" height={30} />
        </div>
      </div>
    </div>
  )
}
// No data
export const NoData = () => {
  return (
    <span className="nodata-bar">
      <i className="icon-sd-alert-medium icons-md orange-medium"></i>
      <p>No data available</p>
    </span>
  )
}
// Planning no data
export const SkeletonPlanning = () => {
  return (
    <div className="skeleton-span-con p0">
      {/*<Skeleton width={"40%"} height={30} />*/}
      <div className="width100p">
        <div className="skeleton-span fill width100p">
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={35} />
          <hr className="height5" />
          <Skeleton height={60} />
          <hr className="height5" />
          <Skeleton height={53} />
        </div>
      </div>
    </div>
  )
}