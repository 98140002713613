import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { RTabbar } from 'Components/RChart/RTabbar';
import FTPAndSFTPCreation from './FTPAndSFTPCreation';
import logoMicrosoft from 'Assets/Images/logos/logo-microsoft.jpg';
import logoSalesforce from 'Assets/Images/logos/logo-salesforce.jpg';
import logoSugarcrm from 'Assets/Images/logos/logo-sugarcrm.jpg';
import { GET_ACTIVE_RDS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { useContext } from 'react';
import authContext from 'Helper/StateHandler/auth-context';
import { RSPrimaryBtn } from 'Components/RSButtons';
import { RSPTooltip } from 'Components/Tooltip';
import * as images from 'Assets/Images';
const tabData = [
    { "id": "all", "text": "All", iconLeft: 'icon-sd-grid-large icons-lg' },
    { "id": "rds", "text": "RDS", iconLeft: 'icon-sd-data-large icons-lg' },
]

const RemoteDataSource = props => {

    const history = useHistory();
    const context = useContext(authContext)

    const [index, setIndex] = useState(0);
    const [availableSystem, setAvailableSystem] = useState([]);
    const [integratedSystem, setIntegratedSystem] = useState([]);
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(props.isAddBtnClicked);
    const [id, setID] = useState("ftp");
    const [selectedData, setSelectedData] = useState(null);
    const [groupId, setGroupId] = useState("all");
    const [mode, setMode] = useState(props.mode);
    const [rsId, setRsId] = useState(props.rsId);

    useEffect(() => {
        getActiveRemoteDataSource();
        let some = []

        let keys = Object.keys(json).map(item => {
            return item
        })

        keys.forEach(key => {
            some.push(json[key])
        })

        // alert(JSON.stringify(some))
        setAvailableSystem(some);
    }, [])

    const getActiveRemoteDataSource = () => {
        let params = { "DepartmentID": 0 }
        connectServer({
            path: GET_ACTIVE_RDS,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status && res.message !== "No data") {
                    setIntegratedSystem(res.data || []);
                }
            },
            fail: err => {

            }
        })
    }

    const getFilteredArry = () => {


        if (groupId === "all" || groupId === "remoteDataSource") {
            return integratedSystem
        }

        let some = integratedSystem.filter(item => {
            return item.groupId === this.state.groupId
        })


        return some
    }
    return (
        <>
            {!isAddButtonClicked ?
                <>
                    <div className="padding0 d-flex">
                        <div className="tabs-vertical">
                            <RTabbar
                                defaultSelectedItem={index}
                                defaultClass="tabDefault"
                                dynamicTab="vertical-tabs"
                                activeClass="tabDefault active"
                                tabData={tabData}
                                callBack={(item, index) => {
                                    setIndex(index);
                                    if (item.id === "all") {
                                        let some = []
                                        let keys = Object.keys(json).map(item => {
                                            return item
                                        })
                                        keys.forEach(key => {
                                            some.push(json[key])
                                        })
                                        // alert(JSON.stringify(some))
                                        setAvailableSystem(some);
                                        setGroupId(item.id);
                                    } else {
                                        setAvailableSystem([json[item.id]]);
                                        setGroupId(item.id);
                                        // this.setState({ availableSystem: [json[item.id]], groupId: item.id })
                                    }
                                }}
                            />
                        </div>
                        {/* right */}
                        <div className="tab-vertical-content">
                            <div className="d-flex justify-content-between">
                                <h3 className="marginB20">Integrated systems</h3>
                            </div>
                            <div className="data-card-view-container">
                                <Row>
                                    {
                                        integratedSystem.length !== 0 ? integratedSystem.map((item, index) => {
                                            return <Col md={4} key={index}>
                                                <DECardView
                                                    item={item}
                                                    history={props.history}
                                                    onDelete={index => {
                                                        let newArry = [...getFilteredArry()]
                                                        let some = [...setIntegratedSystem]
                                                        const newindex = some.indexOf(newArry[index])
                                                        some.splice(newindex, 1)
                                                        setIntegratedSystem(some);
                                                        some.splice(newindex, 1)
                                                    }} index={index}
                                                    getEditValues={(mode, audienceType, rdsId) => {
                                                        setID("ftp");
                                                        setRsId(rdsId);
                                                        setMode(mode);
                                                        setIsAddButtonClicked(true);
                                                    }} />
                                            </Col>
                                        }) : <h5 style={{ textAlign: "center", marginTop: "8%", marginBottom: "8%", marginLeft: "0px", marginRight: "0px" }}>
                                            <div className="nodata-add-icon"><p className="d-flex align-items-center">No sources have been connected. Click <i className="icon-sd-circle-plus-fill-edge-medium icon-md blue mx5"></i> icon in the available systems to link the source.</p></div>
                                        </h5>
                                    }
                                </Row>

                            </div>
                            <div className="d-flex justify-content-between marginY20">
                                <h3 className="marginB0">Available systems</h3>
                                {/* <span className="blue cursor-pointer" onClick={() => this.setState({isSDKModalShow: !this.state.isSDKModalShow})}>One line SDK</span> */}
                            </div>
                            {
                                availableSystem && availableSystem.map(item => {
                                    return <div className="marginB15">
                                        <p>{item.title || "missing title"}</p>
                                        <div className="data-card-api-box">
                                            <Row>
                                                {
                                                    item.data && item.data.map((item, index) => {
                                                        return <Col md={4} key={index}>
                                                            <div className="data-card-api">
                                                                <figure>
                                                                    <img src={item.image} alt={item.image} />
                                                                </figure>
                                                                {!item.image && <h3>{item.title}</h3>}
                                                                <div className="sysicon"> <i onClick={() => {
                                                                    setIsAddButtonClicked(!isAddButtonClicked);
                                                                    setID(item.id);
                                                                    setMode("add")
                                                                    if (item.id === "ftp") {
                                                                        props.onClickAddBtn(true);
                                                                    }
                                                                    if (item.id === "sugarcrm") {
                                                                        setSelectedData(item);
                                                                        let some = [];
                                                                        some.push(item);
                                                                        setIntegratedSystem(some);
                                                                    }
                                                                }} className="icon-sd-circle-plus-fill-edge-medium blue icons-lg cursor-pointer"> </i> </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }

                                            </Row>
                                        </div>
                                    </div>
                                })
                            }
                        </div>

                    </div>
                </> :
                id === "ftp" ?
                    <FTPAndSFTPCreation onCancelClick={(value) => {
                        props.onCancelClick(value);
                    }}
                        mode={mode}
                        rdsId={rsId} /> :
                    id !== "sugarcrm"
                        ? history.push({ pathname: "/audience/data-exchange", state: { CRMType: id } })
                        : setIsAddButtonClicked(!isAddButtonClicked)

            }
        </>

    )
}
export default withRouter(RemoteDataSource);

const DECardView = props => {
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleViewClose = () => setView(false);
    const handleViewShow = () => setView(true);

    return (
        <>
            <div className="data-card-view">
                <ul className="api-setting">
                    <li>
                        <RSPTooltip text="Edit" position="top">
                            <i className="icon-sd-pencil-edit-mini icons-sm blue cp" onClick={() => {
                                props.getEditValues("edit", "rds", props.item.remoteSettingID);
                            }} />
                        </RSPTooltip>
                    </li>
                </ul>
                <figure>
                    <img src={images.sftp} alt={images.sftp} />
                </figure>
                <div className="box-sm">
                    <small>API URL: {props.item.ipAddress};{props.item.port}</small>
                </div>
            </div>

            {/* Info */}
            <DEBootstrapModal
                isShowModal={view}
                title="Domain verification"
                close={handleViewClose}
            >
                <p className="text-center">Domain not verified!</p>
                <div className="progress-style1">
                    <div className="progress-bar"></div>
                </div>
                <p className="marginB7">Validate the following:</p>
                <p className="marginB7">Domain should be active (up and running)</p>
                <p className="marginB0">Tracking scripts not updated on site properly</p>
            </DEBootstrapModal>

            {/* View */}
            <DEBootstrapModal
                isShowModal={show}
                title="Generated code"
                close={handleClose}
                primaryTitle="Copy"
            >
                <textarea
                    value="
                            <a title='Real Time Web Analytics' href='http://clicky.com/100642641'><img alt='Real Time Web Analytics' src='//static.getclicky.com/media/links/badge.gif' border='0'></a>
                            <script src='//static.getclicky.com/js'></script>
                            <script>
                                try {
                                    clicky.init(100642641);
                                }
                                catch (e) {}
                            </script>
                            <noscript><img alt='Clicky' width='1' height='1' src='//in.getclicky.com/100642641ns.gif'></noscript>
                        "
                >
                </textarea>
            </DEBootstrapModal>

        </>
    )
}

const DEBootstrapModal = props => {

    return (
        <>
            <Modal
                show={props.isShowModal}
                onHide={props.close}
                backdrop="static"
                keyboard={false}
                size={props.size}
                centered
            >
                <Modal.Header closeButton>
                    <h2>{props.title}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {props.children}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-container d-flex justify-content-end">
                        {/* {props.secondaryTitle && <RSSecondaryBtn onClick={() => props.close()}>{props.secondaryTitle}</RSSecondaryBtn>} */}
                        {props.primaryTitle && <RSPrimaryBtn onClick={() => props.close()}>{props.primaryTitle}</RSPrimaryBtn>}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const json = {
    // crm:{
    //     title:"CRM",
    //     data:[
    //         {
    //             title:"Microsoft CRM",
    //             img:logoMicrosoft,
    //             cls:"",
    //             id:"mscrm",
    //             groupId:"crm"
    //         },
    //         {
    //             title:"Salesforce",
    //             img:logoSalesforce,
    //             cls:"",
    //             id:"salesforce",
    //             groupId:"crm"
    //         },
    //         {
    //             title:"Sugar CRM",
    //             img:logoSugarcrm,
    //             cls:"",
    //             id:"sugarcrm",
    //             groupId:"crm"
    //         }
    //     ]
    // },
    rds: {
        title: "Remote data source",
        data: [
            { title: "SFTP", image: images.sftp, cls: "", id: "ftp", groupId: "rds" },
        ]
    }

}