import React from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom";
import DataExchangeCreation from "../Preference/Components/DataExchange/Components/DataExchangeCreation";
import AudienceHome from "./AudienceHome";
import AddAudience from "./Components/AddAudience/AddAudience";
import DataExchange from "./Components/AddAudience/Components/DataExchange";
import POSDataExchangeCreation from "./Components/AddAudience/Components/POSDataExchangeCreation";
import SegmentationCreation from "./Components/SegmentationCreation/SegmentationCreation";
import NewDynamicList from "./NewDynamicList";
import AudiencePayment from "./Components/SegmentationCreation/Components/AudiencePayment";
import AudiencePay from "./Components/SegmentationCreation/Components/AudiencePay";
import SegmentationCreationRS from "./Components/SegmentationCreation/SegmentationCreationRS";

const Audience = props => {

    let match = useRouteMatch();

    return <Switch>
        <Route exact path={`${match.url}/`}>
            <AudienceHome />
        </Route>
        <Route path={`${match.url}/new-dynamic-list`}>
            <NewDynamicList />
        </Route>
        <Route path={`${match.url}/add-audience`}>
            <AddAudience />
        </Route>
        <Route path={`${match.url}/new-subscription-list`}>
            <SegmentationCreation />
        </Route>
        <Route path={`${match.url}/new-subscription-list-RS`}>
            <SegmentationCreationRS />
        </Route>
        <Route path={`${match.url}/data-exchange`}>
            <DataExchange />
        </Route>
        <Route path={`${match.url}/data-exchange-creation`}>
            <DataExchangeCreation />
        </Route>
        <Route path={`${match.url}/pos-data-exchange-creation`}>
            <POSDataExchangeCreation />
        </Route>
        <Route path={`${match.url}/audience-payment-summary`}>
            <AudiencePayment />
        </Route>
        <Route path={`${match.url}/audience-pay`}>
            <AudiencePay />
        </Route>
    </Switch>
}
export default Audience