import React, { Component, useContext, useState } from "react"
import { Container } from "react-bootstrap"
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid'
import { Switch } from '@progress/kendo-react-inputs'
import '@progress/kendo-react-intl'
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper"
import { withRouter } from "react-router-dom";
import AddNewUser from "../AccountSettings/Tabs/AccountUser/Components/AddNewUser"
import AssignUserRole from "../AccountSettings/Tabs/AccountUser/Components/AssignUserRole"
import { getAuthClientId, getUserData, isAllowToAddUser } from "Helper/Utils/Utils"
import { connectServer } from "Helper/Network/networkHandler"
import { GET_USER_LIST, USER_STATUS_CHANGE } from "Helper/Constants/endPoint"
import authContext from "Helper/StateHandler/auth-context"
import SkeletonTable from "Components/Skeleton/SkeletonTable"
import { RSPTooltip } from "Components/Tooltip"
import { RSConfirmAlert } from "Components/RSConfirmAlert"

const initialDataState = {
    skip: 0,
    take: 5
}

class Users extends Component {



    static contextType = authContext

    state = {
        page: initialDataState,
        currentIndex: 0,
        checked: true,
        usersList: [],
        listError: false,
        userId: 0,
        isAllowToUserAdd: "click-off",
        isOpenAlertModal: false
    }

    componentDidMount = props => {
        let isAllow = isAllowToAddUser();
        this.setState({ isAllowToUserAdd: isAllow ? "" : "click-off" });
        this.getUsersList()
    }
    componentDidCatch = error => {
    }

    StatusCellComponent = (props) => {
        return (
            <StatusCell onStatusUpdate={(value) => { }} {...props} />
        )
    }

    NameComponent = (props) => {
        // <NameCell {...props} />
        return (<td>
            <div className="d-flex justify-content-between">
                <span className="marginB0 cursor-pointer text-underline-h" onClick={() => {
                    this.setState({ userId: props.dataItem.userId, currentIndex: 1 })
                }}>{`${props.dataItem.firstName} ${props.dataItem.lastName}`}</span>
                {props.dataItem.isKeyperson && <i className={`icon-sd-crown-fill-medium icons-md cursor-pointer ${props.dataItem.isKeyperson ? "orange" : "grey"}`}></i>}
            </div>
        </td>)
    }


    getUsersList = () => {
        this.setState({ listError: false })
        const params = { "clientId": getAuthClientId() }
        connectServer({
            path: GET_USER_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ usersList: res?.data })
                } else {
                    this.setState({ listError: true })
                }
            },
            fail: err => {
                this.setState({ listError: true })
            }
        })
    }

    handleChange = (fields, value) => {
        fields.checked = value
        this.forceUpdate();
    }

    pageChange = (e) => {
        this.setState({ page: e.page })
    }

    UserDetails = props => {

        return <>

            <div className="d-flex justify-content-between mt15 mb15">
                <h4 className="mb0">User details</h4>
                <div className={`d-flex align-items-center ${this.state.isAllowToUserAdd}`}>
                    <i className={`icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow`}
                        onClick={() => {
                            if (this.state.usersList?.length === 0 || this.state.usersList?.length < 5) {
                                this.setState({ currentIndex: 1 })
                            } else {
                                this.setState({ isOpenAlertModal: true })
                            }
                        }}>
                    </i>
                </div>
            </div>
            <Grid
                data={this.state.usersList && this.state.usersList.slice(this.state.page.skip, this.state.page.take + this.state.page.skip)}
                skip={this.state.page.skip}
                take={this.state.page.take}
                total={this.state.usersList && this.state.usersList.length}
                scrollable={"none"}
                className="grid-pagenation-del grid-replaceable"
                onPageChange={this.pageChange}
                pageable={{
                    //type: 'input',
                    pageSizes: [5, 10, 20],
                    previousNext: true,
                }}
            >
                <GridColumn columnMenu={ColumnMenu} field="firstName" title="Name" cell={this.NameComponent} />
                <GridColumn columnMenu={ColumnMenu} field="jobFunctionName" title="Job function" />
                <GridColumn columnMenu={ColumnMenu} field="role" title="Role" />
                <GridColumn field="status" title="Status" cell={this.StatusCellComponent} />
            </Grid>
        </>
    }

    updateIndex = index => {
        this.setState({ currentIndex: index })
        if (index === 0) {
            this.getUsersList()
        }
    }

    RenderComponent = () => {
        switch (this.state.currentIndex) {
            case 0:
                return <>
                    <hr className="blue" />
                    <div className="d-flex justify-content-between mt15 mb15">
                        <h4 className="mb0">User details</h4>
                        <div className={`d-flex align-items-center ${this.state.isAllowToUserAdd}`}>
                            <RSPTooltip text='Add' position="top"><i className={`icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow ${this.state.isAllowToUserAdd}`} onClick={() => {
                                if (this.state.usersList?.length === 0 || this.state.usersList?.length < 5) {
                                    this.setState({ currentIndex: 1 })
                                } else {
                                    this.setState({ isOpenAlertModal: true })
                                }
                            }}></i></RSPTooltip>
                        </div>
                    </div>
                    {this.state.usersList.length > 0 ?
                        (<Grid
                            data={this.state.usersList && this.state.usersList.slice(this.state.page.skip, this.state.page.take + this.state.page.skip)}
                            skip={this.state.page.skip}
                            take={this.state.page.take}
                            total={this.state.usersList && this.state.usersList.length}
                            scrollable={"none"}
                            className="grid-pagenation-del grid-replaceable"
                            onPageChange={this.pageChange}
                            pageable={{
                                //type: 'input',
                                pageSizes: [5, 10, 20],
                                previousNext: true,
                            }}
                        >
                            <GridColumn columnMenu={ColumnMenu} field="firstName" title="Name" cell={this.NameComponent} />
                            <GridColumn columnMenu={ColumnMenu} field="jobFunctionName" title="Job function" />
                            <GridColumn columnMenu={ColumnMenu} field="role" title="Role" />
                            <GridColumn field="status" title="Status" cell={this.StatusCellComponent} />
                        </Grid>) :
                        (<SkeletonTable text='to add user' count={5} isError={this.state.listError} />)
                    }
                </>
            case 1:
                return <AddNewUser cb={index => this.updateIndex(index + 1)} userId={this.state.userId} />
            case 2:
                return <AssignUserRole cb={index => this.updateIndex(index - 2)} />
            default:
                return <>
                    <div className="d-flex justify-content-between mt15 mb15">
                        <h4 className="mb0">User details</h4>
                        <div className={`d-flex align-items-center ${this.state.isAllowToUserAdd}`}>
                            <RSPTooltip text='Add' position="top"><i className={`icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow ${this.state.isAllowToUserAdd}`} onClick={() => {
                                if (this.state.usersList?.length === 0 || this.state.usersList?.length < 5) {
                                    this.setState({ currentIndex: 1 })
                                } else {
                                    this.setState({ isOpenAlertModal: true })
                                }
                            }}></i></RSPTooltip>
                        </div>
                    </div>
                    <Grid
                        data={this.state.usersList && this.state.usersList.slice(this.state.page.skip, this.state.page.take + this.state.page.skip)}
                        total={this.state.usersList && this.state.usersList.length}
                        skip={this.state.page.skip}
                        take={this.state.page.take}
                        scrollable={"none"}
                        className="grid-pagenation-del grid-replaceable"
                        onPageChange={this.pageChange}
                        pageable={{
                            //type: 'input',
                            pageSizes: [5, 10, 20],
                            previousNext: true,
                        }}
                    >
                        <GridColumn columnMenu={ColumnMenu} field="firstName" title="Name" cell={this.NameComponent} />
                        <GridColumn columnMenu={ColumnMenu} field="jobFunctionName" title="Job function" />
                        <GridColumn columnMenu={ColumnMenu} field="role" title="Role" />
                        <GridColumn field="status" title="Status" cell={this.StatusCellComponent} />
                    </Grid>
                </>
        }
    }

    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">

                    <div className="header-title d-flex justify-content-between">
                        <h1>{this.state.userId !== 0 ? "Edit user" : titles[this.state.currentIndex]}</h1>
                        <div className="back-text" onClick={() => this.props.history.push("/preferences")}>
                            <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                            Back
                        </div>
                    </div>
                    {/* <hr className="blue" /> */}
                    <this.RenderComponent />
                    {this.state.isOpenAlertModal &&
                        <RSConfirmAlert
                            title="You can add up to 5 users only for this account."
                            primaryButtonText="OK"
                            buttonClicked={(value) => {
                                this.setState({ isOpenAlertModal: false })
                            }} />}
                </Container>
            </LayoutPageWrapper>
        )
    }
}
export default withRouter(Users);

const StatusCell = props => {
    const context = useContext(authContext)
    const [checked, setChecked] = useState(props.dataItem.statusId === 1 ? true : false)

    return <td>
        <div className="col-md-3">
            <Switch
                className="mt0"
                checked={checked}
                onChange={(value) => {
                    setChecked(!checked)
                    const params = {
                        "userId": props.dataItem.userId,
                        "status": !checked ? "Active" : "Inactive"
                    }
                    connectServer({
                        path: USER_STATUS_CHANGE,
                        params,
                        loading: context.globalStateData.setIsLoadingData,
                        context: context,
                        ok: res => {
                            if (res.status) {
                                props.onStatusUpdate(checked)
                            }
                        },
                        fail: err => {
                        }
                    })

                }}
            />
        </div>
    </td>
}

const titles = ["Users", "Add a new user", "Assign role to users"]

const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;
};