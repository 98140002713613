import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import authContext from 'Helper/StateHandler/auth-context';
import { RTabbar } from "Components/RChart/RTabbar";
import { ExistingUser } from "./Components/ExistingUser";
import { NewUser } from "./Components/NewUser";
import { LoginWrapper } from "./Components/Login.styled";
import LayoutBox from "Components/Layout";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSBrandLogo } from "Components/RSBrandLogo/RSBrandLogo";
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import scriptBlock from 'Assets/Images/icons/script-block.svg'
import { convertObjectToBase64, getLsMasterDataDropdown, removeAllLocalStorage } from "Helper/Utils/Utils";
import { GET_KEY_CONTACT_INFO_DROPDOWNS } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";

const bandColors = [
    "login-green",
    "login-red",
    "login-orange",
    "login-metal",
    "login-blue"
]
class LoginComponent extends Component {
    static contextType = authContext;

    state = {
        index: 0,
        email: "",
        password: "",
        defaultPopup: true,
        popup: false,
        randonColor: bandColors[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000) * bandColors.length)]
    }

    componentDidMount = () => {
        let parsed = new URLSearchParams(window.location.search);
        // console.log("Account Type=====", parsed.get("at"))
        // console.log("Scource Type=====", parsed.get("src"))
        // console.log("Channel partner=====", parsed.get("cp"))

        this.setState({ index: parsed.get("at") === "newuser" ? 1 : 0 })
        let tempData = { ...this.context.globalStateData.getKeyContactInfoData }
        // let tempValue = parsed.get("src") === "cp" ? parsed.get("pl") : "";
        // let tempFinalValue = [...tempValue];
        // if (tempFinalValue.length > 0) {
        //     tempFinalValue.splice(1, 1);
        //     tempFinalValue.splice(2, 1);
        //     tempFinalValue.splice(tempFinalValue.length - 2, 1);
        //     tempFinalValue.splice(tempFinalValue.length - 3, 1);
        //     tempValue = tempFinalValue.join("");
        // }
        // let cpValue = window.atob(tempValue);
        // console.log("partner value", cpValue)
        let source = parsed.get("src");
        let partner = source === "partner" ? parsed.get("partner") : ""
        let cpValue = source === "cp" ? parsed.get("cpcode") : "";
        let planId = parsed.get("pId") || 0;
        let promoCode = parsed.get("promoCode") || "";
        tempData.contactInfo.isFromVersium = partner === "versium" ? true : false
        tempData.contactInfo.promoCode = promoCode
        tempData.contactInfo.planId = parseInt(planId, 10);
        tempData.contactInfo.source = source;
        tempData.contactInfo.partner = partner;
        tempData.contactInfo.channelPartner = cpValue;
        tempData.contactInfo.lastLoginPath = window.location.pathname + window.location.search;
        this.context.globalStateData.setKeyContactInfoData(tempData);
        // this.getDefaultIPAddress()
        removeAllLocalStorage()
        if (!getLsMasterDataDropdown()) {
            this.getMasterDataFromServer()
        }
    }

    getDefaultIPAddress = () => {
        fetch("https://ipapi.co/json").
            then(res => { return res.json() }).then(data => {
                if (data !== null && data !== undefined) {
                    localStorage.setItem('bdetails', convertObjectToBase64(data))
                }
            })
    }

    getMasterDataFromServer = () => {
        connectServer({
            path: GET_KEY_CONTACT_INFO_DROPDOWNS,
            context: this.context,
            ok: res => {
                this.context.globalStateData.setMasterDropdownData(res.data || {})
                localStorage.setItem(
                    'lsMasterDropdownData',
                    convertObjectToBase64(res.data)
                )
            },
            fail: err => { }
        })
    }

    render() {

        // const randomBandColor = bandColors[Math.floor(Math.random() * bandColors.length)]
        // RSLog("Random Color", bandColors[Math.floor(Math.random() * bandColors.length)], false)

        return (

            <LayoutPageWrapper>
                <LoginWrapper className="position-relative">
                    <RSBrandLogo />
                    <LayoutBox className="portlet-box-theme marginY15 login-border">
                        <RTabbar
                            defaultSelectedItem={this.state.index}
                            defaultClass="tabDefault"
                            dynamicTab="mini"
                            animate
                            activeClass="tabDefault active"
                            tabData={tabData}
                            callBack={(item, index) => {
                                this.setState({ index })
                            }}
                        />
                        <div className="login-body-form">
                            {this.state.index === 0 ? <ExistingUser /> : <NewUser />}
                        </div>
                    </LayoutBox>
                </LoginWrapper>

                {
                    this.state.defaultPopup
                    && <div className="login-indicator-wrapper">
                        <div className={`login-indicator in-pop-1 ${this.state.randonColor}`}>
                            <img src={scriptBlock} alt="scriptBlock" />
                            <h3>Please disable third-party plug-ins installed in your browser for a better Marketing
                                Star experience.</h3>
                            <RSPrimaryBtn
                                className="mr20 bgBlue"
                                onClick={() => {
                                    this.setState({
                                        defaultPopup: false,
                                        popup: !this.state.popup
                                    })
                                }}>Proceed</RSPrimaryBtn>
                        </div>
                    </div>
                }

                {
                    this.state.popup
                    && <div className="login-indicator-wrapper">
                        <div className={`login-indicator p20 ${this.state.randonColor}`}>
                            <h3 className="mb20">Disclaimer:</h3>
                            <p className="mb20">Access to this platform is granted based on the agreement that the user/receiver shall not modify, make derivative works of, disassemble, reverse compile, or reverse engineer any part of the platform, or access or use the link to build or support, and/or assist a third party in building or supporting, products or services.</p>
                            <p>It is to be noted that the information shared is confidential and is bound by the confidentiality terms agreed. As per these terms, the above also shall not be disclosed, directly or indirectly to any third party without the prior written consent of Marketing Star.</p>
                            <div className="login-btn mt30 d-flex align-items-center justify-content-center">
                                {/* <RSSecondaryBtn className="mx0 cancel-btn" onClick={() => { }}>Cancel</RSSecondaryBtn> */}
                                <RSPrimaryBtn
                                    className="bgBlue"
                                    onClick={() => {
                                        this.setState({
                                            popup: false
                                        })
                                    }}
                                >Proceed</RSPrimaryBtn>
                            </div>
                        </div>
                    </div>
                }

            </LayoutPageWrapper>
        )
    }
}
export default withRouter(LoginComponent);

const tabData = [
    { "id": 1001, "text": "Existing user" },
    { "id": 1002, "text": "New user" }
]
