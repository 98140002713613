import { RSPrimaryBtn } from 'Components/RSButtons';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';


class ReportsAlertPopup extends Component {
    state = {
        isShow: this.props.isOpen
    }
    render() {
        return (
            <Modal show={this.state.isShow}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
                onHide={() => {
                    this.setState({ isShow: false })
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header className='border-0 mt5'>
                </Modal.Header>
                <Modal.Body>
                    <p className='margin-T20 text-center'>
                    <i className='icon-sd-time-large icon-xxl grey-dark space-top orange-medium' />
                    </p>
                    <p className='margin-T20 text-center'>The report generation is under process, <br />Please check after for some time...</p>
                </Modal.Body>
                <Modal.Footer>
                    <RSPrimaryBtn onClick={() => {
                        this.setState({ isShow: false });
                        this.props.onChangeIsOpen(true)
                    }}>Back</RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ReportsAlertPopup;