import * as colors from 'Components/Colors'
import { RSPTooltip } from 'Components/Tooltip'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import * as images from 'Assets/Images'


class ImagesView extends Component {
    state = {
        // icons: overallImages,
        icons: overallImagesString,
        value: '',
        copied: false,
    }
    render() {
        const ulClass = {
            backgroundColor: 'rgb(233, 233, 233)',
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: '#b8b8b8',
            borderStyle: 'solid',
            cursor: 'pointer',
            borderRadius: 8,
            padding: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 35
        }
        const imagesCls = {
            width: 'max-content',
            height: 'max-content',
            maxWidth: '100%',
            maxHeight: 150
        }
        const label = {
            position: 'absolute',
            top: 4,
            right: 16,
            backgroundColor: '#0540d3',
            width: 24,
            height: 24,
            borderRadius: 20,
            color: '#fff',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
        const labelText = {
            color: '#333',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            bottom: 10,
            left: 0,
            width: '100%'
        }
        return (
            <>
                <h3>Images ({this.state.icons.length})</h3>
                <Row className='theme-space-mt'>
                    {
                        this.state.icons.map((item, index) => {
                            const text = item.text
                            const rplText = text.replace('images.', '')
                            return (
                                <Col md={2} className="marginB15" key={index}>
                                    {/* <RSPTooltip text={`${item.text}`} position='top'> */}
                                        <div style={ulClass} className='marginR10 float-start'>
                                            <label style={label}>{index + 1}</label>
                                            <CopyToClipboard text={`<img src={${item.text}} alt="${rplText}" />`}>
                                                <img style={imagesCls} src={item.key} alt={`${item.text}`} />
                                            </CopyToClipboard>
                                            <label style={labelText}>{rplText}</label>
                                        </div>
                                    {/* </RSPTooltip> */}
                                </Col>
                            )
                        })
                    }
                </Row>
            </>
        )
    }
}

export default ImagesView

const overallImagesString = [
    {key: images.Error404, text: 'images.Error404', path: './404.gif'},
    {key: images.Active, text: 'images.Active', path: './active.svg'},
    {key: images.Andriod, text: 'images.Andriod', path: './andriod.jpg'},
    {key: images.Andriodnew, text: 'images.Andriodnew', path: './andriodnew.jpg'},
    {key: images.ArrowBred, text: 'images.ArrowBred', path: './arrow-bred.png'},
    {key: images.AudChart, text: 'images.AudChart', path: './aud-chart-4.jpg'},
    // {key: images.Barcode, text: 'images.Barcode', path: './barcode.jpg'},
    {key: images.BrandLogoWhite, text: 'images.BrandLogoWhite', path: './BrandLogo-White.svg'},
    {key: images.BrandLogo, text: 'images.BrandLogo', path: './BrandLogo.svg'},
    {key: images.BrandPh, text: 'images.BrandPh', path: './brandPh.png'},
    {key: images.ChartClock, text: 'images.ChartClock', path: './chart-clock-modified.svg'},
    {key: images.Checkbox, text: 'images.Checkbox', path: './checkbox.png'},
    {key: images.Cloud, text: 'images.Cloud', path: './cloud.png'},
    {key: images.CloudSvg, text: 'images.CloudSvg', path: './cloud.svg'},
    {key: images.DetailAnalyticsConversion, text: 'images.DetailAnalyticsConversion', path: './detail-analytics-conversion.svg'},
    {key: images.DetailAnalyticsEngagement, text: 'images.DetailAnalyticsEngagement', path: './detail-analytics-engagement.svg'},
    {key: images.DetailAnalyticsReach, text: 'images.DetailAnalyticsReach', path: './detail-analytics-reach.svg'},
    {key: images.EmailPreview, text: 'images.EmailPreview', path: './email-preview.jpg'},
    {key: images.EventsLarge, text: 'images.EventsLarge', path: './events-large.svg'},
    {key: images.FormCol1, text: 'images.FormCol1', path: './form-col1.png'},
    {key: images.FormCol2, text: 'images.FormCol2', path: './form-col2.png'},
    {key: images.GalleryEmail3, text: 'images.GalleryEmail3', path: './gallery-email3.jpg'},
    {key: images.GallerySocial1, text: 'images.GallerySocial1', path: './gallery-social1.jpg'},
    {key: images.GallerySocial2, text: 'images.GallerySocial2', path: './gallery-social2.jpg'},
    {key: images.GraphWeb, text: 'images.GraphWeb', path: './graph-web-analytics.gif'},
    {key: images.HeaderNav, text: 'images.HeaderNav', path: './header-nav-splitter.png'},
    // {key: images.Healthcheck, text: 'images.Healthcheck', path: './healthcheck.png'},
    {key: images.IconPushnotification, text: 'images.IconPushnotification', path: './icon-pushnotification.png'},
    {key: images.IconStatus, text: 'images.IconStatus', path: './icon-status.png'},
    {key: images.IconTool, text: 'images.IconTool', path: './icon-tool-bgcolor.png'},
    {key: images.IconUnknown, text: 'images.IconUnknown', path: './icon-unknown-user.svg'},
    // {key: images.IconVisa, text: 'images.IconVisa', path: './icon-visa.jpg'},
    {key: images.IndustryLogo, text: 'images.IndustryLogo', path: './industry-logo.png'},
    {key: images.InternetCutIcon, text: 'images.InternetCutIcon', path: './internet-cut_icon.svg'},
    {key: images.Ios, text: 'images.Ios', path: './ios.jpg'},
    // {key: images.IOSAppOnBoard, text: 'images.IOSAppOnBoard', path: './iOSAppOnBoard.png'},
    {key: images.Iosnew, text: 'images.Iosnew', path: './iosnew.jpg'},
    {key: images.KendoSprite, text: 'images.KendoSprite', path: './kendo-sprite.png'},
    // {key: images.LoadingIconSd, text: 'images.LoadingIconSd', path: './loading-icon-sd.png'},
    // {key: images.LoadingIcon, text: 'images.LoadingIcon', path: './loading-icon.svg'},
    // {key: images.LoadingSd, text: 'images.LoadingSd', path: './loading-sd.png'},
    {key: images.LoadingTicks, text: 'images.LoadingTicks', path: './loading-ticks.png'},
    {key: images.Loading, text: 'images.Loading', path: './loading.png'},
    // {key: images.LoadingSvg, text: 'images.LoadingSvg', path: './loading.svg'},
    {key: images.Location, text: 'images.Location', path: './location.png'},
    {key: images.Maker, text: 'images.Maker', path: './maker.png'},
    {key: images.MapLocalization, text: 'images.MapLocalization', path: './map-localization.png'},
    {key: images.NoMedia, text: 'images.NoMedia', path: './no_media.png'},
    {key: images.Organisation, text: 'images.Organisation', path: './organisation.svg'},
    {key: images.PaypalButton, text: 'images.PaypalButton', path: './paypal-button.png'},
    {key: images.Paypal, text: 'images.Paypal', path: './paypal.png'},
    {key: images.PoweredBy, text: 'images.PoweredBy', path: './powered-by-new.svg'},
    {key: images.PreviewDummy, text: 'images.PreviewDummy', path: './preview-dummy.jpg'},
    {key: images.PreviewLoan, text: 'images.PreviewLoan', path: './preview-loan-banner.png'},
    {key: images.ProfileDefault, text: 'images.ProfileDefault', path: './profile-default-img.png'},
    {key: images.ProfilePic, text: 'images.ProfilePic', path: './profile-pic.png'},
    {key: images.QrCode, text: 'images.QrCode', path: './qr-code.png'},
    {key: images.QuickPoll, text: 'images.QuickPoll', path: './quick-poll.jpg'},
    {key: images.RatingFeedback, text: 'images.RatingFeedback', path: './rating-feedback.jpg'},
    // {key: images.Rsloading, text: 'images.Rsloading', path: './rsloading.gif'},
    {key: images.SmartDxGn, text: 'images.SmartDxGn', path: './SmartDx-gn-wt.svg'},
    // {key: images.SocialMediaLine, text: 'images.SocialMediaLine', path: './social_media_line.png'},
    // {key: images.SocialMediaList, text: 'images.SocialMediaList', path: './socialmedia-list.png'},
    {key: images.SplitAb, text: 'images.SplitAb', path: './split-ab.png'},
    {key: images.SpriteMiniIcons, text: 'images.SpriteMiniIcons', path: './sprite_mini_icons.png'},
    {key: images.TagEnterprise, text: 'images.TagEnterprise', path: './tag-enterprise.svg'},
    {key: images.Ticket, text: 'images.Ticket', path: './ticket.png'},
    {key: images.User, text: 'images.User', path: './user.svg'},
    {key: images.UserPng, text: 'images.UserPng', path: './user.png'},
    // {key: images.WebAppEvent, text: 'images.WebAppEvent', path: './webAppEvent.png'},
    {key: images.WorldMap, text: 'images.WorldMap', path: './world-map.png'},
    {key: images.WpChromeAndroid, text: 'images.WpChromeAndroid', path: './wp-chrome-android.jpg'},
    {key: images.WpChromeMac, text: 'images.WpChromeMac', path: './wp-chrome-mac.jpg'},
    {key: images.WpChromeWindows, text: 'images.WpChromeWindows', path: './wp-chrome-windows.jpg'},
    {key: images.WpFirefoxMac, text: 'images.WpFirefoxMac', path: './wp-firefox-mac.jpg'},
    {key: images.WpFirefoxWindows, text: 'images.WpFirefoxWindows', path: './wp-firefox-windows.jpg'},

    // ADMIN
    {key: images.IdfcLogo, text: 'images.IdfcLogo', path: './Admin/idfc-logo.png'},
    {key: images.InteraktLogo, text: 'images.InteraktLogo', path: './Admin/interakt-logo.png'},
    {key: images.ManipalHospitalLogo, text: 'images.ManipalHospitalLogo', path: './Admin/manipal-hospital-logo.png'},
    {key: images.MarriottLogo, text: 'images.MarriottLogo', path: './Admin/marriott-logo.png'},
    {key: images.SamsungLogo, text: 'images.SamsungLogo', path: './Admin/samsung-logo.png'},

    // HEADER
    {key: images.HeaderIcon1Disable, text: 'images.HeaderIcon1Disable', path: './header-icon/d-icon-1-disable.svg'},
    {key: images.HeaderIcon1White, text: 'images.HeaderIcon1White', path: './header-icon/d-icon-1-w.svg'},
    {key: images.HeaderIcon1, text: 'images.HeaderIcon1', path: './header-icon/d-icon-1.svg'},
    {key: images.HeaderIcon2Disable, text: 'images.HeaderIcon2Disable', path: './header-icon/d-icon-2-disable.svg'},
    {key: images.HeaderIcon2White, text: 'images.HeaderIcon2White', path: './header-icon/d-icon-2-w.svg'},
    {key: images.HeaderIcon2, text: 'images.HeaderIcon2', path: './header-icon/d-icon-2.svg'},
    {key: images.HeaderIcon3Disable, text: 'images.HeaderIcon3Disable', path: './header-icon/d-icon-3-disable.svg'},
    {key: images.HeaderIcon3White, text: 'images.HeaderIcon3White', path: './header-icon/d-icon-3-w.svg'},
    {key: images.HeaderIcon3, text: 'images.HeaderIcon3', path: './header-icon/d-icon-3.svg'},
    {key: images.HeaderIcon4Disable, text: 'images.HeaderIcon4Disable', path: './header-icon/d-icon-4-disable.svg'},
    {key: images.HeaderIcon4White, text: 'images.HeaderIcon4White', path: './header-icon/d-icon-4-w.svg'},
    {key: images.HeaderIcon4, text: 'images.HeaderIcon4', path: './header-icon/d-icon-4.svg'},
    {key: images.HeaderIcon5Disable, text: 'images.HeaderIcon5Disable', path: './header-icon/d-icon-5-disable.svg'},
    {key: images.HeaderIcon5White, text: 'images.HeaderIcon5White', path: './header-icon/d-icon-5-w.svg'},
    {key: images.HeaderIcon5, text: 'images.HeaderIcon5', path: './header-icon/d-icon-5.svg'},

    // ICONS
    {key: images.AddParticipants, text: 'images.AddParticipants', path: './icons/add-participants.png'},
    {key: images.CompNext, text: 'images.CompNext', path: './icons/comp-next.png'},
    {key: images.CompPrev, text: 'images.CompPrev', path: './icons/comp-prev.png'},
    {key: images.IconCheckbox, text: 'images.IconCheckbox', path: './icons/icon-checkbox.png'},
    {key: images.IconCombobox, text: 'images.IconCombobox', path: './icons/icon-combobox.png'},
    {key: images.IconDateTime, text: 'images.IconDateTime', path: './icons/icon-dateTime.png'},
    {key: images.IconEmail, text: 'images.IconEmail', path: './icons/icon-email.svg'},
    {key: images.IconFemaleSm, text: 'images.IconFemaleSm', path: './icons/icon-female-sm.png'},
    {key: images.IconMaleSm, text: 'images.IconMaleSm', path: './icons/icon-male-sm.png'},
    {key: images.IconPassword, text: 'images.IconPassword', path: './icons/icon-password.svg'},
    {key: images.IconRadio, text: 'images.IconRadio', path: './icons/icon-radio.png'},
    {key: images.IconTempDelete, text: 'images.IconTempDelete', path: './icons/icon-temp-delete.png'},
    {key: images.IconTempEditLarge, text: 'images.IconTempEditLarge', path: './icons/icon-temp-edit-large.png'},
    {key: images.IconTempMove, text: 'images.IconTempMove', path: './icons/icon-temp-move.png'},
    {key: images.IconText, text: 'images.IconText', path: './icons/icon-text.png'},
    {key: images.IconTextfield, text: 'images.IconTextfield', path: './icons/icon-textfield.png'},
    {key: images.IconUpload01, text: 'images.IconUpload01', path: './icons/icon-upload-01.png'},

    // INDUSTRIES
    {key: images.InIndustryLogo, text: 'images.InIndustryLogo', path: './industries/industry-logo.png'},

    // INDUSTRIES - BANKING
    {key: images.BankingGalleryEmail1, text: 'images.BankingGalleryEmail1', path: './industries/banking/gallery-email1.jpg'},
    {key: images.BankingGalleryEmail2, text: 'images.BankingGalleryEmail2', path: './industries/banking/gallery-email2.jpg'},
    {key: images.BankingGalleryEmail3, text: 'images.BankingGalleryEmail3', path: './industries/banking/gallery-email3.jpg'},
    {key: images.BankingGalleryEmail4, text: 'images.BankingGalleryEmail4', path: './industries/banking/gallery-email4.jpg'},
    {key: images.BankingGallerySocial1, text: 'images.BankingGallerySocial1', path: './industries/banking/gallery-social1.jpg'},
    {key: images.BankingGallerySocial2, text: 'images.BankingGallerySocial2', path: './industries/banking/gallery-social2.jpg'},
    {key: images.BankingGallerySocial3, text: 'images.BankingGallerySocial3', path: './industries/banking/gallery-social3.jpg'},
    {key: images.BankingGallerySocial4, text: 'images.BankingGallerySocial4', path: './industries/banking/gallery-social4.jpg'},
    {key: images.BankingGallerySocial5, text: 'images.BankingGallerySocial5', path: './industries/banking/gallery-social5.jpg'},
    {key: images.BankingGallerySocial6, text: 'images.BankingGallerySocial6', path: './industries/banking/gallery-social6.jpg'},
    {key: images.BankingIndustryLogo, text: 'images.BankingIndustryLogo', path: './industries/banking/industry-logo.png'},

    // LOGOS
    {key: images.ApiConnect, text: 'images.ApiConnect', path: './logos/api-connect.png'},
    {key: images.FirefoxLogoIcon, text: 'images.FirefoxLogoIcon', path: './logos/firefox-logo-icon.png'},
    {key: images.GoogleLogoIcon, text: 'images.GoogleLogoIcon', path: './logos/google-logo-icon.png'},
    // {key: images.logoAppAnnie, text: 'images.logoAppAnnie', path: './logos/logo-app-annie.jpg'},
    {key: images.LogoBeam, text: 'images.LogoBeam', path: './logos/logo-Beam.jpg'},
    {key: images.LogoBing, text: 'images.LogoBing', path: './logos/logo-bing.jpg'},
    {key: images.LogoFacebook, text: 'images.LogoFacebook', path: './logos/logo-facebook.jpg'},
    {key: images.LogoGdn, text: 'images.LogoGdn', path: './logos/logo-Gdn.png'},
    {key: images.LogoGmail, text: 'images.LogoGmail', path: './logos/logo-gmail.jpg'},
    {key: images.LogoGoogleAnalytics, text: 'images.LogoGoogleAnalytics', path: './logos/logo-google-analytics.jpg'},
    {key: images.LogoGoogleMap, text: 'images.LogoGoogleMap', path: './logos/logo-Google-Map.png'},
    {key: images.LogoGoogle, text: 'images.LogoGoogle', path: './logos/logo-google.jpg'},
    {key: images.LogoHotmail, text: 'images.LogoHotmail', path: './logos/logo-hotmail.jpg'},
    {key: images.LogoLinkedin, text: 'images.LogoLinkedin', path: './logos/logo-linkedin.jpg'},
    // {key: images.LogoMixpanel, text: 'images.LogoMixpanel', path: './logos/logo-mixpanel.jpg'},
    {key: images.LogoMobile, text: 'images.LogoMobile', path: './logos/logo-mobile.jpg'},
    {key: images.LogoNFC, text: 'images.LogoNFC', path: './logos/logo-NFC.jpg'},
    // {key: images.LogoResulticksAnalytics, text: 'images.LogoResulticksAnalytics', path: './logos/logo-resulticks-analytics.jpg'},
    {key: images.LogoTagGoogle, text: 'images.LogoTagGoogle', path: './logos/logo-tagGoogle.jpg'},
    {key: images.LogoTwitter, text: 'images.LogoTwitter', path: './logos/logo-twitter.jpg'},
    {key: images.LogoYahoo, text: 'images.LogoYahoo', path: './logos/logo-yahoo.jpg'},
    {key: images.LogoYahoomail, text: 'images.LogoYahoomail', path: './logos/logo-yahoomail.jpg'},
    // {key: images.PreviewMobile1, text: 'images.PreviewMobile1', path: './logos/preview-mobile-1.png'},
    // {key: images.PreviewMobileIos, text: 'images.PreviewMobileIos', path: './logos/preview-mobile-ios.png'},
    {key: images.MSmartDxWhite, text: 'images.MSmartDxWhite', path: './ms/SmartDx-White.svg'},
    {key: images.MSmartDx, text: 'images.MSmartDx', path: './ms/SmartDx.svg'},
    {key: images.SSmartDxWhite, text: 'images.SSmartDxWhite', path: './sd/SmartDx-White.svg'},
    {key: images.SSmartDx, text: 'images.SSmartDx', path: './sd/SmartDx.svg'},
]