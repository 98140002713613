import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { RSInput2 } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { UPDATE_LIST_NAME, LIST_NAME_LIMIT } from 'Helper/Constants/validationMessage';
import { getEmailAudienceList, getMobileAudienceList, isEmpty } from 'Helper/Utils/Utils';
import authContext from 'Helper/StateHandler/auth-context'
import { CHECK_LIST_EXISTS, UPDATE_SEG_LIST_NAME } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';

class EditListNameModal extends Component {

    static contextType = authContext

    state = {
        isShow: this.props.isOpen,
        listName: this.props.selectedListName,
        errMsgListName: null,
        isEnableButton: false
    }

    updateSegmentationListName = params => {
        connectServer({
            path: UPDATE_SEG_LIST_NAME,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    getEmailAudienceList(this.context);
                    getMobileAudienceList(this.context);
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen(true);
                }
            },
            fail: err => {
            }
        })
    }

    checkListExists = (importDescription) => {
        let params = {
            "importDescription": importDescription.trim()
        }
        connectServer({
            path: CHECK_LIST_EXISTS,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (!res.status) {
                    this.setState({ errMsgListName: "List name already exists", isEnableButton: false });
                } else {
                    this.setState({ errMsgListName: null, isEnableButton: true });
                }
            },
            fail: err => {

            }
        })
    }

    render() {
        return (
            <Modal
                show={this.state.isShow}
                centered
                size='md'
                backdrop="static"
                keyboard={false}
                onHide={() => {
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header closeButton>
                    <h2>Edit list name</h2>
                </Modal.Header>
                <Modal.Body>
                    <RSInput2
                        name="listName"
                        ph="Enter list name"
                        required={true}
                        val={this.state.listName}
                        errorMessage={this.state.errMsgListName}
                        cb={(text) => {
                            var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                            let errMsg = null;
                            if (text.length <= 30) {
                                if (text.length > 0) {
                                    if (regex.test(text)) {
                                        this.setState({ listName: text })
                                    } else {
                                        errMsg = "Special character(s) are not allowed";
                                    }
                                } else {
                                    this.setState({ listName: text })
                                    errMsg = UPDATE_LIST_NAME;
                                }
                            } else {
                                errMsg = LIST_NAME_LIMIT;
                            }
                            this.setState({ errMsgListName: errMsg, isEnableButton: false });
                        }}
                        ob={(text) => {
                            if (!isEmpty(text.trim()) && (text !== this.props.selectedListName)) {
                                this.checkListExists(text);
                            }
                            this.setState({ listName: text.trim() })
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <RSPrimaryBtn className={this.state.isEnableButton ? "" : "click-off"}
                        onClick={() => {
                            if (isEmpty(this.state.listName)) {
                                this.setState({ errMsgListName: UPDATE_LIST_NAME });
                                return;
                            }
                            if (this.state.listName.length > 30) {
                                this.setState({ errMsgListName: LIST_NAME_LIMIT });
                                return;
                            }
                            let params = {
                                "segmentationListID": this.props.selectedListId,
                                "listName": "UL_" + this.state.listName
                            }
                            this.updateSegmentationListName(params)
                        }}
                    >Update
                    </RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default EditListNameModal;