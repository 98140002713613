import React, { Component } from 'react';
import { RSDropdownList, RSPhoneInput } from 'Components/RSDropdowns';
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { RSInput2, RSTextInputWithIcon } from 'Components/RSInputs'
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons'
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import authContext from 'Helper/StateHandler/auth-context';
import { isEmpty, validateEmail, validatePassword, passwordStrengthMeter, getServerConfig, removeSpace, convertObjectToBase64 } from 'Helper/Utils/Utils';
import {
    CONFIRM_PASSWORD_NOT_MATCH, ENTER_CONFIRM_PASSWORD, ENTER_EMAIL, ENTER_FIRST_NAME, ENTER_FIRST_NAME_3CHARS,
    ENTER_LAST_NAME, ENTER_OTP, ENTER_PASSWORD, ENTER_PHONE, ENTER_VALID_EMAIL, ENTER_VALID_PASSWORD,
    SELECT_JOBFUNCTION, SELECT_TITLE, SPECIAL_NUM_CHARACTERS
} from 'Helper/Constants/validationMessage';
import userImg from 'Assets/Images/user.svg'
import { withRouter } from 'react-router';
import { RSUploadImageComponent } from 'Components/UploadImage/RSUploadImageComponent';
import { getLsMasterDataDropdown } from '../../../../Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_KEY_CONTACT_INFO_DROPDOWNS, REQUEST_OTP, VALIDATE_OTP } from 'Helper/Constants/endPoint'
import { MAX_LOGIN_OTP } from 'Helper/Constants/inputLimit'
import { RSPTimer } from 'Components/RSPTimer';
import { RSPPophover } from 'Components/Tooltip';

class ContactInfo extends Component {

    static contextType = authContext
    state = {
        ...this.context.globalStateData.getKeyContactInfoData.contactInfo,
        ...{
            popupMobileNo: null
        },
        imageURL: getServerConfig().slice(0, -1),
        imageType: ""
    }

    componentDidMount = props => {
        window.scrollTo(0, 0);
        this.setState({
            emailId:
                (this.props.history.location.state &&
                    this.props.history.location.state.email) ||
                ''
        })
        if (getLsMasterDataDropdown() !== null) {
            this.getDataFromServer()
        } else {
            this.getMasterDataFromServer()
        }

    }

    getDataFromServer = () => {

        const brands = getLsMasterDataDropdown()
        const ddVal = brands?.jobFunction

        // Setting contact info slide dropdowns
        this.setState({ jobFunctionDropdownData: ddVal || [] })

        // Setting brand detail slide dropdowns
        var brandInfo = this.context.globalStateData.getKeyContactInfoData.brandDetails
        brandInfo.countryDropdownData = brands?.countryMaster || []
        brandInfo.businessTypeDropdownData = brands?.businessType || []
        brandInfo.brandPositioningTypeDropdownData = brands?.brandPosition || []
        brandInfo.industryDropdownData = brands?.industry || [];

        var locationInfo = this.context.globalStateData.getKeyContactInfoData.locationSettings
        locationInfo.currencyDropdownData = brands?.currencyMaster || []
        locationInfo.languageDropdownData = brands?.languageMaster || []
        locationInfo.dateFormatDropdownData = brands?.dateFormat || []
        locationInfo.timeFormatDropdownData = brands?.timeFormat || []
        locationInfo.timeZoneDropdownData = brands?.timeZone || []

        this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ brandDetails: brandInfo } })
        this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ locationSettings: locationInfo } })


    }

    getMasterDataFromServer = () => {
        connectServer({
            path: GET_KEY_CONTACT_INFO_DROPDOWNS,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                this.context.globalStateData.setMasterDropdownData(res.data || {})
                let brands = res.data;
                const ddVal = brands?.jobFunction

                // Setting contact info slide dropdowns
                this.setState({ jobFunctionDropdownData: ddVal || [] })

                // Setting brand detail slide dropdowns
                var brandInfo = this.context.globalStateData.getKeyContactInfoData
                    .brandDetails
                brandInfo.countryDropdownData = brands?.countryMaster || []
                brandInfo.businessTypeDropdownData = brands?.businessType || []
                brandInfo.brandPositioningTypeDropdownData = brands?.brandPosition || []
                brandInfo.industryDropdownData = brands?.industry || []

                var locationInfo = this.context.globalStateData.getKeyContactInfoData
                    .locationSettings
                locationInfo.currencyDropdownData = brands?.currencyMaster || []
                locationInfo.languageDropdownData = brands?.languageMaster || []
                locationInfo.dateFormatDropdownData = brands?.dateFormat || []
                locationInfo.timeFormatDropdownData = brands?.timeFormat || []
                locationInfo.timeZoneDropdownData = brands?.timeZone || []

                this.context.globalStateData.setKeyContactInfoData({
                    ...this.context.globalStateData.getKeyContactInfoData,
                    ...{ brandDetails: brandInfo }
                })
                this.context.globalStateData.setKeyContactInfoData({
                    ...this.context.globalStateData.getKeyContactInfoData,
                    ...{ locationSettings: locationInfo }
                })
                localStorage.setItem(
                    'lsMasterDropdownData',
                    convertObjectToBase64(res.data)
                )
            },
            fail: err => { }
        })
    }

    onDropdownChange = (e, sKey, errKey, errVal) => {
        let newState = {}
        newState[sKey] = e.target.value
        newState[errKey] = errVal
        this.setState({ ...this.state, ...newState })
    }

    handleClose = () => {
        this.setState({
            isShowOTP: false,
            isShowConfirmOTP: false
        })
    }
    handleOtpShow = (from) => {

        let newState1 = { ...this.state }
        // newState1["mobileNo"] = newState1.mobileNo?.slice(newState1.countryCodeMobile?.length)

        const { mobileNo, emailId, } = newState1

        this.setState({
            // isShowOTP: true,
            isShowConfirmOTP: false,
            isShowResendBtn: false,
            otp: ''
        })
        const params = {
            "countryCodeMobile": `${this.state.countryCodeMobile}`,
            "mobileNumber": `${mobileNo.slice(this.state.countryCodeMobile.length, mobileNo.length)}`,
            "emailId": `${emailId}`,
        }
        connectServer({
            loading: this.context.globalStateData.setIsLoadingData,
            path: REQUEST_OTP,
            params: params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ isShowOTP: true, otpToken: res?.otpToken, errMsgOTP: "One time password sent successfully" })
                } else {
                    this.setState({
                        isShowOTP: false,
                        errMsgPhone: res?.message
                    })
                }
            },
            fail: err => {
                this.setState({
                    errMsgPhone: err.message
                })
            }
        })
    }
    validateOtp = (otp) => {
        if (isEmpty(otp) || isEmpty(this.state.otpToken)) {
            this.setState({ validateOtpErrMsg: "Please enter a valid one time password" })
            return
        }
        const params = {
            otpToken: this.state.otpToken,
            otp: otp
        }

        connectServer({
            path: VALIDATE_OTP,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        isShowCoundown: false,
                        otpSuccess: true, defaultOtp: false
                    })
                } else {
                    this.setState({
                        otpSuccess: false, defaultOtp: false,
                        errMsgOTP: res.message
                    })
                }
            },
            fail: err => {
                this.setState({
                    otpSuccess: false, defaultOtp: false,
                    validateOtpErrMsg: err.message,
                    errMsgOTP: err.message
                })
            }
        })
    }

    resetCounter = () => {
        return Date.now() + 120000
    }

    validateState = () => {
        let newState1 = { ...this.state }

        // newState1["mobileNo"] = newState1.mobileNo?.slice(newState1.countryCodeMobile?.length)

        const { title, firstName, imageType, lastName, mobileNo, emailId, countryCode, countryCodeMobile, jobFunctionDropdownItem,
            password, confirmPassword, profilePath, otp, otpToken, otpSuccess, isShowOTP } = newState1
        let enable = true;

        if (title === null || (title !== null && title.titleId === 0)) {
            this.setState({ errMsgTitle: SELECT_TITLE })
            enable = false;
        }
        if (isEmpty(firstName)) {
            this.setState({ errMsgFirstName: ENTER_FIRST_NAME })
            enable = false;
        } else if (firstName.length < 3) {
            this.setState({ errMsgFirstName: ENTER_FIRST_NAME_3CHARS });
            enable = false
        }
        if (isEmpty(lastName)) {
            this.setState({ errMsgLastName: ENTER_LAST_NAME })
            enable = false;
        }
        if (isEmpty(mobileNo)) {
            this.setState({ errMsgPhone: ENTER_PHONE })
            enable = false;
        }
        // if (isShowOTP) {
        //     if (isEmpty(otp)) {
        //         this.setState({ errMsgOTP: ENTER_OTP })
        //         enable = false;
        //     }
        //     if (!isEmpty(otp) && !otpSuccess) {
        //         this.setState({ errMsgOTP: "One time password failed" })
        //         enable = false;
        //     }
        // }
        if (isEmpty(emailId)) {
            this.setState({ errMsgEmail: ENTER_EMAIL })
            enable = false;
        }
        if (!validateEmail(emailId)) {
            this.setState({ errMsgEmail: ENTER_VALID_EMAIL })
            enable = false;
        }
        if (jobFunctionDropdownItem === null || isEmpty(jobFunctionDropdownItem.jobfunctionName) || jobFunctionDropdownItem.jobfunctionName.toLowerCase().includes("select")) {
            this.setState({ errMsgjobFunctionName: SELECT_JOBFUNCTION })
            enable = false;
        }
        if (isEmpty(password)) {
            this.setState({ errMsgPassword: ENTER_PASSWORD })
            enable = false;
        }
        if (!validatePassword(password)) {
            this.setState({ errMsgPassword: ENTER_VALID_PASSWORD })
            enable = false;
        }
        if (isEmpty(confirmPassword)) {
            this.setState({ errMsgConfirmPassword: ENTER_CONFIRM_PASSWORD })
            enable = false;
        }
        if (password !== confirmPassword) {
            this.setState({ errMsgConfirmPassword: CONFIRM_PASSWORD_NOT_MATCH })
            enable = false;
        }
        // if (enable && this.state.otpSuccess) {
        if (enable) {
            const params = {
                "emailId": emailId || "",
                "title": title.title || "",
                "titleId": title.titleId,
                "firstName": firstName || "",
                "lastName": lastName || "",
                "countryCodeMobile": countryCodeMobile || "",
                "countryCode": countryCode || "",
                "mobileNo": mobileNo.slice(countryCodeMobile.length, mobileNo.length) || "",
                "jobFunctionId": jobFunctionDropdownItem.jobfunctionId || "",
                "password": password || "",
                "confirmPassword": confirmPassword || "",
                "profileImage": profilePath || "",
                "profileImageType": imageType
            }
            this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ contactInfo: this.state } })
            this.props.updateDataAndIndex(this.props.i + 1, params)
        }
    }

    render() {
        const {
            errMsgTitle,
            errMsgFirstName,
            errMsgLastName,
            errMsgPhone,
            errMsgOTP,
            otpSuccess,
            errMsgEmail,
            errMsgjobFunctionName,
            errMsgPassword,
            errMsgConfirmPassword,
            errMsgProfilePath,
            pwdStrengthMeter,
            conPwdStrengthMeter } = this.state

        const otpTimer = ({ minutes, seconds, completed }) => {
            if (completed) {
                this.setState({ isShowResendBtn: true })
                return "Expired"
            } else {
                return <span>valid for 5minutes ({minutes}:{seconds})</span>
            }
        }

        return (
            <Container className='box-bottom-space'>
                <RSBrandLogo />
                <h1>Key contact info</h1>
                <div className="portlet-box-theme">
                    <Row>
                        <Col md={2} sm={3}>
                            <RSUploadImageComponent
                                className={`enable-remove`}
                                defaultImg={userImg}
                                defaultValue={this.state.profilePath}
                                newImg={this.state.newImg ? `${this.state.imageURL}${this.state.newImg}` : null}
                                text={`${!this.state.newImg && !this.state.profilePath ? "Upload logo" : "Edit logo"}`}
                                icon={`${!this.state.newImg && !this.state.profilePath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                cb={img => this.setState({ profilePath: img, errMsgProfilePath: null })}
                                getImageType={(value) => {
                                    this.setState({ imageType: value });
                                }}
                                error={(value) => {
                                    this.setState({
                                        errMsgProfilePath: value,
                                        newImg: null,
                                        profilePath: this.state.profilePath,
                                    });
                                }}
                                // getErrMsg={(errMsg) => { this.setState({ errMsgProfilePath: errMsg }) }}
                                errorMessage={errMsgProfilePath}
                                removeIcon={this.state.profilePath !== null}
                                handleRemove={() => {
                                    this.setState({
                                        profilePath: null,
                                        newImg: null,
                                    });
                                }
                                }
                            />
                            {/* <RSUploadImageComponent
                                        defaultImg={userImg}
                                        defaultValue={this.state.profilePath}
                                        newImg={this.state.newImg ? `${this.state.imageURL}${this.state.newImg}` : null}
                                        text={`${!this.state.newImg && !this.state.profilePath ? "Upload image" : "Edit image"}`}
                                        icon={`${!this.state.newImg && !this.state.profilePath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                                        cb={img => this.setState({ profilePath: img, errMsgProfilePath: null })}
                                        getErrMsg={(errMsg) => { this.setState({ errMsgProfilePath: errMsg }) }}
                                    /> */}
                            {/* {this.state.profilePath !== null &&
                                        <div className="remove-profile cp" onClick={() => {
                                            this.setState({
                                                profilePath: null,
                                                newImg: null
                                            })
                                        }}>
                                            <i className="icon-sd-close-mini"></i>
                                            <span className="fs13 cp ml5">Remove</span>
                                        </div>
                                    } */}
                        </Col>

                        <Col md={10}>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        {/* Title */}
                                        <Col md={4}>
                                            <div className="position-relative">
                                                {errMsgTitle && <div className="validation-message">{errMsgTitle}</div>}
                                                <RSDropdownList
                                                    className='required'
                                                    data={status}
                                                    value={this.state.title}
                                                    defaultItem={
                                                        {
                                                            titleId: 0,
                                                            title: '-- Title --'
                                                        }}
                                                    textField='title'
                                                    dataItemKey='titleId'
                                                    tabIndex={0}
                                                    onChange={e => {
                                                        let errMsg =
                                                            e.target.value.titleId !== 0 ? null : SELECT_TITLE
                                                        this.setState({ title: e.target.value, errMsgTitle: errMsg })
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        {/* First name */}
                                        <Col md={8}>
                                            <RSTextInputWithIcon
                                                name="firstname"
                                                ph={ENTER_FIRST_NAME}
                                                errorMessage={errMsgFirstName}
                                                val={this.state.firstName}
                                                max={150}
                                                tabIndex={1}
                                                required={true}
                                                ob={t => {
                                                    t = t.trimEnd();
                                                    t = t.trimStart();
                                                    let errMsg =
                                                        isEmpty(t) ? ENTER_FIRST_NAME : t.length >= 3 ? null : ENTER_FIRST_NAME_3CHARS
                                                    this.setState({ firstName: t, errMsgFirstName: errMsg })
                                                }}
                                                cb={text => {
                                                    if (!isEmpty(text)) {
                                                        var regex = /^[a-zA-Z ]*$/;
                                                        if (regex.test(text)) {
                                                            this.setState({ firstName: text, errMsgFirstName: null })
                                                        } else {
                                                            this.setState({ errMsgFirstName: SPECIAL_NUM_CHARACTERS })
                                                        }
                                                    } else {
                                                        this.setState({ firstName: text, errMsgFirstName: ENTER_FIRST_NAME })
                                                    }

                                                }}
                                            />
                                        </Col>

                                        {/* Mobile number */}
                                        <Col md={12}>
                                            {errMsgPhone && <div className="validation-message mobile-valid mobilenoError">{errMsgPhone}</div>}
                                            <RSPhoneInput
                                                disabled={!this.state.isShowCoundown ? true : false}
                                                className={`required ${!this.state.isShowCoundown ? "click-off" : ""}`}
                                                // value={this.state.countryCodeMobile + this.state.mobileNo}
                                                // country={this.state.countryCode}
                                                value={this.state.mobileNo}
                                                country={this.state.countryCode}
                                                tabIndex={3}
                                                // onChange={(phone, country, e) => {
                                                //     let errMsg = phone.length > 4 ? null : ENTER_PHONE
                                                //     let phone1 = e?.target?.value?.toString().split(" ")
                                                //     let phone2 = phone1?.splice(1)?.toString()?.replaceAll("-", "")?.replaceAll(",", "")
                                                //     this.setState({
                                                //         mobileNo: phone2,
                                                //         countryCode: country.countryCode,
                                                //         countryCodeMobile: country.dialCode,
                                                //         errMsgPhone: errMsg
                                                //     })
                                                // }}
                                                onChange={(phone, country, e) => {
                                                    let errMsg = null;
                                                    let phone1 = phone.split(" ")
                                                    let phone2 = phone1?.splice(1)?.toString()?.replaceAll("-", "")?.replaceAll(",", "")
                                                    this.setState({
                                                        // newPhoneNumber: phone2 || "",
                                                        countryCode: country.countryCode,
                                                        countryCodeMobile: country.dialCode,
                                                        errMsgPhone: errMsg
                                                    })
                                                }}
                                                onBlur={(e, country) => {
                                                    let _countryCode = this.state.countryCodeMobile.replaceAll("+", "")
                                                    let _value = e.target.value.replaceAll("+", "")

                                                    if (_value === _countryCode || !_countryCode.length) {
                                                        this.setState({ errMsgPhone: ENTER_PHONE })
                                                        return
                                                    }

                                                    let phone3 = e.target.value.replaceAll(" ", "");
                                                    let phone2 = phone3.replaceAll("-", "");
                                                    let phone = phone2.replaceAll("+", "")
                                                    let trimNewPhone = phone.slice(_countryCode.length, phone.length)
                                                    let isOtpShow = this.state.isShowOTP ? this.state.mobileNo !== phone ? true : false : true
                                                    let phone4 = e.target.value.replaceAll("-", "")
                                                    // let errMsg = phone.slice(_countryCode.length).length > 8 ? null : ENTER_PHONE;
                                                    let errMsg = trimNewPhone.length >= 8 ? null : ENTER_PHONE;
                                                    this.setState({ errMsgPhone: errMsg });
                                                    if (errMsg === null) {
                                                        this.setState({
                                                            popupMobileNo: phone4,
                                                            mobileNo: phone,
                                                            countryCode: country.countryCode,
                                                            countryCodeMobile: country.dialCode,
                                                            // isShowConfirmOTP: isOtpShow,
                                                            // isShowOTP: false
                                                        })
                                                    }
                                                }}
                                            />
                                            {/* <small className="input-desc marginX11 font-italic sub-label">A one time password has been sent to both your email and mobile number.</small> */}
                                            {/* <span className="input-verify-icon"><i className="icon-sd-circle-tick-medium icons-sm"></i>Verify</span> */}
                                        </Col>

                                        {/* OTP */}
                                        {/* {
                                            this.state.isShowOTP
                                                ? <Col md={12} className='mb10'>
                                                    <RSTextInputWithIcon
                                                        name='otp'
                                                        ph={ENTER_OTP}
                                                        max={MAX_LOGIN_OTP}
                                                        disabled={otpSuccess ? "click-off" : ""}
                                                        // icon={`${this.state.otpSuccess ? "icon-tick-circle-small icons-md green text-end" : ""}`}
                                                        icon={`${otpSuccess ? 'icon-sd-circle-tick-mini icons-sm green text-end top8' : this.state.defaultOtp ? "" : 'icon-sd-close-mini icons-sm red text-end top7'}`}
                                                        val={this.state.otp}
                                                        keyboardType="number"
                                                        success_msg={errMsgOTP?.includes("success") ? "success_msg" : ''}
                                                        errorMessage={errMsgOTP}
                                                        required={true}
                                                        cb={t => {
                                                            const re = /^[0-9\b]+$/;
                                                            if (t === '' || re.test(t)) {
                                                                if (t.length === 6) {
                                                                    this.validateOtp(t)
                                                                }
                                                                this.setState({ otp: t, errMsgOTP: null })
                                                            }
                                                        }}
                                                        ob={t => {
                                                            if (!t.length) {
                                                                this.setState({ otp: t, errMsgOTP: ENTER_OTP })
                                                                return
                                                            }
                                                            this.setState({ otp: t, errMsgOTP: null })
                                                        }}
                                                    />
                                                    {!this.state.otpSuccess && !this.state.isShowResendBtn ?
                                                        <small className='input-desc marginX11'>
                                                            One time password is valid for 5 minutes <RSPTimer
                                                                onComplete={() => {
                                                                    this.setState({ isShowResendBtn: true })
                                                                }}
                                                            />
                                                        </small>
                                                        : null}
                                                    {!this.state.otpSuccess && this.state.isShowResendBtn ?
                                                        <small className='input-desc-right'>
                                                            <label
                                                                className='rs-link orange-medium'
                                                                onClick={() => {
                                                                    this.handleOtpShow("resend")
                                                                    this.setState({ isShowResendBtn: false, otp: '', errMsgOTP: 'One time password sent successfully' })
                                                                }}
                                                            >
                                                                Resend?
                                                            </label>
                                                        </small> : null
                                                    }
                                                </Col>
                                                : null
                                        } */}

                                        {/* Job function */}
                                        <Col md={12} className=''>
                                            <div className="position-relative">
                                                {errMsgjobFunctionName && <div className="validation-message">{errMsgjobFunctionName}</div>}
                                                <RSDropdownList
                                                    className='required'
                                                    value={this.state.jobFunctionDropdownItem}
                                                    defaultItem={{
                                                        jobfunctionId: 0,
                                                        jobfunctionName: `-- ${SELECT_JOBFUNCTION} --`
                                                    }
                                                    }
                                                    textField="jobfunctionName"
                                                    dataItemKey="jobfunctionId"
                                                    data={this.state.jobFunctionDropdownData}
                                                    onChange={(e) => {
                                                        let errMsg = e.target.value.jobfunctionName.length > 0 ? null : SELECT_JOBFUNCTION
                                                        this.setState({ jobFunctionDropdownItem: e.target.value, errMsgjobFunctionName: errMsg })
                                                    }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <Row>
                                        {/* Last name */}
                                        <Col md={12}>
                                            <RSInput2
                                                name="lastname"
                                                ph={ENTER_LAST_NAME}
                                                max={150}
                                                val={this.state.lastName}
                                                required={true}
                                                errorMessage={errMsgLastName}
                                                tabIndex={2}
                                                cb={text => {
                                                    if (!isEmpty(text)) {
                                                        var regex = /^[a-zA-Z ]*$/;
                                                        if (regex.test(text)) {
                                                            this.setState({ lastName: text, errMsgLastName: null })
                                                        } else {
                                                            this.setState({ errMsgLastName: SPECIAL_NUM_CHARACTERS })
                                                        }
                                                    } else {
                                                        this.setState({ lastName: text, errMsgLastName: ENTER_LAST_NAME })
                                                    }
                                                }}
                                                ob={t => {
                                                    t = t.trimEnd();
                                                    t = t.trimStart();
                                                    let erMsg = t.length > 0 ? null : ENTER_LAST_NAME
                                                    this.setState({ lastName: t, errMsgLastName: erMsg })
                                                }}
                                            />
                                        </Col>

                                        {/* Email */}
                                        <Col md={12}>
                                            <RSTextInputWithIcon
                                                name="email"
                                                ph="Email"
                                                // className="disabled"
                                                disabled={true}
                                                val={this.state.emailId}
                                                icon={"icon-sd-email-medium fs23 mt2"}
                                                // img={emailIcon}
                                                errorMessage={errMsgEmail}
                                                required={true}
                                                max={150}
                                                cb={t => {
                                                    const _email = removeSpace(t)
                                                    let errMsg = validateEmail(_email)
                                                        ? null
                                                        : ENTER_VALID_EMAIL
                                                    this.setState({ emailId: _email, errMsgEmail: null })
                                                }}
                                                ob={t => {
                                                    const _email = removeSpace(t)
                                                    let errMsg = validateEmail(_email)
                                                        ? null
                                                        : ENTER_VALID_EMAIL
                                                    this.setState({ emailId: _email, errMsgEmail: errMsg })
                                                }}
                                            />
                                        </Col>

                                        {/* Password */}
                                        <Col md={12}>
                                            <span className={`progress-bar-password bottom31 
                                            ${pwdStrengthMeter === 100
                                                    ? "status-good"
                                                    : pwdStrengthMeter === 80
                                                        ? "status-average"
                                                        : pwdStrengthMeter === 60
                                                            ? "status-week"
                                                            : pwdStrengthMeter === 40
                                                                ? "status-low" :
                                                                "status-bad"
                                                }`
                                            }>
                                                <i className='icon-sd-lock-medium white' />
                                            </span>
                                            <RSTextInputWithIcon
                                                type="password"
                                                name="password"
                                                ph={ENTER_PASSWORD}
                                                val={this.state.password}
                                                icon={'icon-sd-lock-medium input-icon-fill'}
                                                errorMessage={errMsgPassword}
                                                max={15}
                                                eye="mr35 grey"
                                                required={true}
                                                cb={t => {
                                                    this.setState({ pwdStrengthMeter: passwordStrengthMeter(t) })
                                                    const _pwd = removeSpace(t)

                                                    let errMsg =
                                                        validatePassword(t) > 0
                                                            ? null
                                                            : ENTER_VALID_PASSWORD
                                                    this.setState({ password: _pwd, errMsgPassword: null })
                                                }}
                                                ob={t => {
                                                    this.setState({ pwdStrengthMeter: passwordStrengthMeter(t) })
                                                    const _pwd = removeSpace(t)
                                                    let errMsg =
                                                        validatePassword(t)
                                                            ? null
                                                            : ENTER_VALID_PASSWORD
                                                    this.setState({ password: _pwd, errMsgPassword: errMsg })
                                                }}
                                            />
                                            <small className='input-desc marginX11 font-italic sub-label'>
                                                Min. 8 characters
                                                <RSPPophover
                                                    text="Includes numbers, special characters and a mix of capital and small letters."
                                                    position="top"
                                                >
                                                    <i className="icon-sd-circle-info-medium icons-sm blue ml5 mt0 cp float-end"></i>
                                                </RSPPophover>
                                            </small>
                                        </Col>

                                        {/* Confirm password */}
                                        <Col md={12} className='mt10'>

                                            <span className={`progress-bar-password 
                                                    ${conPwdStrengthMeter === 100
                                                    ? "status-good"
                                                    : conPwdStrengthMeter === 80
                                                        ? "status-average"
                                                        : conPwdStrengthMeter === 60
                                                            ? "status-week"
                                                            : conPwdStrengthMeter === 40
                                                                ? "status-low" :
                                                                "status-bad"
                                                }`
                                            }>
                                                <i className='icon-sd-lock-medium white' />
                                            </span>
                                            <RSTextInputWithIcon
                                                type="password"
                                                name="confirmpassword"
                                                ph={ENTER_CONFIRM_PASSWORD}
                                                val={this.state.confirmPassword}
                                                icon={'icon-sd-lock-medium input-icon-fill'}
                                                errorMessage={errMsgConfirmPassword}
                                                max={15}
                                                eye="mr35 grey"
                                                required={true}
                                                cb={t => {
                                                    const _pwd = removeSpace(t)

                                                    this.setState({
                                                        confirmPassword: _pwd,
                                                        errMsgConfirmPassword: null
                                                    })

                                                    this.setState({ conPwdStrengthMeter: passwordStrengthMeter(t) })
                                                }}
                                                ob={t => {
                                                    const _pwd = removeSpace(t)
                                                    let errMsg = _pwd.length > 0 ? this.state.password === _pwd ? null : CONFIRM_PASSWORD_NOT_MATCH : ENTER_CONFIRM_PASSWORD
                                                    this.setState({
                                                        confirmPassword: _pwd,
                                                        errMsgConfirmPassword: errMsg
                                                    })
                                                    let confirmPasswordStrength = false
                                                    if (this.state.password === this.state.confirmPassword) {
                                                        confirmPasswordStrength = true
                                                    }
                                                    this.setState({
                                                        conPwdStrengthMeter: confirmPasswordStrength ? passwordStrengthMeter(t) : ''
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="btn-container d-flex justify-content-end">
                    <RSPrimaryBtn onClick={this.validateState}>Next</RSPrimaryBtn>
                </div>

                {/* Change mobile number */}
                <Modal
                    show={this.state.isShowConfirmOTP}
                    onHide={this.handleClose}
                    backdrop='static'
                    keyboard={false}
                    size="md"
                    centered
                >
                    <Modal.Header className='no-title-text otp-close' closeButton style={{ borderBottom: '0' }}></Modal.Header>
                    <Modal.Body className='pt0'>
                        <Row>
                            <Col>
                                <p>{`Please confirm your mobile number ${this.state.popupMobileNo} to receive the one time password.`}</p>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btn-container d-flex justify-content-end'>
                            <RSSecondaryBtn onClick={this.handleClose}>Edit</RSSecondaryBtn>
                            <RSPrimaryBtn onClick={() => { this.handleOtpShow("") }}>OK</RSPrimaryBtn>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default withRouter(ContactInfo);

const status = [
    {
        titleId: 1,
        title: 'Mr.'
    },
    {
        titleId: 2,
        title: 'Mrs.'
    },
    {
        titleId: 3,
        title: 'Ms.'
    }
]
