import { Container } from "react-bootstrap";
import React, { useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import MyProfile from "./Components/MyProfile/MyProfile";
import json from "./preference.json";
import AccountSettings from "./Components/AccountSettings/AccountSettings";
import Invoices from "./Components/Invoices/Invoices";
import AlertsAndNotifications from "./Components/AlertAndNotifications/AlertAndNotifications";
import DataExchange from "./Components/DataExchange/DataExchange";
import FormGenerator from "./Components/FormGenerator/FormGenerator";
import CommunicationSettings from "./Components/CommunicationSettings/CommunicationSettings";
import Consumptions from "./Components/Consumptions/Consumptions";
import Users from "./Components/Users";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import NotificationDetails from "./Components/NotificationDetails/NotificationDetails";
import TemplateGenerator from "./Components/TemplateGenerator/TemplateGenerator";

import ErrorBoundary from "Components/ErrorBoundary";
import LicenseInfo from "./Components/LicenseInfo/LicenseInfo";
import PaymentActivation from "./Components/LicenseInfo/PaymentActivation";
import UnsubscribeCreation from "./Components/CommunicationSettings/Tabs/Email/Sub-Unsub/UnsubscribeCreation";
import SubscribeCreation from "./Components/CommunicationSettings/Tabs/Email/Sub-Unsub/SubscribeCreation";
import CompanyList from "./Components/CompanyListing/CompanyList";
import MobileDataExchange from "./Components/DataExchange/Components/MobileDataExchange";
import AudianceList from "./Components/DataExchange/Components/AudianceList";
import { RSPTooltip } from "Components/Tooltip";
import authContext from "Helper/StateHandler/auth-context";
import { useState } from "react";
import {
    getAuthUserId,
    getUserData,
    isAllowToAddUser,
    makeImmutableObject,
} from "Helper/Utils/Utils";
import EmailBuilder from "./Components/TemplateGenerator/Tabs/Email/EmailBuilder";
import LandingPage from "./Components/TemplateGenerator/Tabs/LandingPage/LandingPage";
import DynamicCRM from "./Components/DataExchange/DynamicCRM/DynamicCRM";
import AddImportAudience from "./Components/DataExchange/DynamicCRM/AddImportAudience";
import Versium from "./Components/DataExchange/DynamicCRM/Versium";
import { ServiceCatalogue } from "AppJsImport";

const Preference = (props) => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.url}/`}>
                <PreferenceHome />
            </Route>
            <Route path={`${match.url}/my-profile`}>
                <MyProfile />
            </Route>

            <Route path={`${match.url}/account-settings`}>
                <AccountSettings />
            </Route>

            <Route path={`${match.url}/users`}>
                <ErrorBoundary>
                    <Users />
                </ErrorBoundary>
            </Route>
            <Route path={`${match.url}/company-list`}>
                <CompanyList />
            </Route>
            <Route path={`${match.url}/alerts-and-notifications`}>
                <AlertsAndNotifications />
            </Route>
            <Route path={`${match.url}/communication-settings`}>
                <CommunicationSettings />
            </Route>
            <Route path={`${match.url}/form-generator`}>
                <FormGenerator />
            </Route>
            <Route path={`${match.url}/template-library`}>
                <TemplateGenerator />
            </Route>
            <Route path={`${match.url}/email-template-gallery`}>
                <EmailBuilder />
            </Route>
            <Route path={`${match.url}/landing-page-gallery`}>
                <LandingPage />
            </Route>
            <Route path={`${match.url}/data-exchange`}>
                <DataExchange />
            </Route>
            <Route path={`${match.url}/audiance-list`}>
                <AudianceList />
            </Route>
            <Route path={`${match.url}/consumptions`}>
                <Consumptions />
            </Route>
            <Route path={`${match.url}/invoice-list`}>
                <Invoices />
            </Route>
            <Route path={`${match.url}/license-info`}>
                <LicenseInfo />
            </Route>
            <Route path={`${match.url}/service-catalogue`}>
                <ServiceCatalogue />
            </Route>
            <Route path={`${match.url}/payment-activation`}>
                <PaymentActivation />
            </Route>
            <Route path={`${match.url}/notification-details`}>
                <NotificationDetails />
            </Route>
            <Route path={`${match.url}/unsubscribe-creation`}>
                <UnsubscribeCreation />
            </Route>
            <Route path={`${match.url}/subscribe-creation`}>
                <SubscribeCreation />
            </Route>
            <Route path={`${match.url}/mobile-data-exchange`}>
                <MobileDataExchange />
            </Route>
            <Route path={`${match.url}/dynamic-crm`}>
                <DynamicCRM />
            </Route>
            <Route path={`${match.url}/add-import-audience`}>
                <AddImportAudience />
            </Route>
            <Route path={`${match.url}/versium`}>
                <Versium />
            </Route>
        </Switch>
    );
};

export default Preference;

const PreferenceHome = (props) => {
    const match = useRouteMatch();
    const history = useHistory();
    const context = useContext(authContext);
    const [pJson, setPJson] = useState(makeImmutableObject(json));

    useEffect(() => {
        window.scrollTo(0, 0);
        let temp = { ...pJson };
        let userData = getUserData();
        temp.billing.list[0].indicate = userData?.payStatus === "C" ? false : true;
        temp.billing.list[0].disable = isAllowToAddUser() ? false : true;
        setPJson(temp);
    }, []);

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <h1 className="header-title-margin">Preferences</h1>
                <div className="portlet-box-theme p0">
                    <div className="perference-wrapper">
                        <h4>{pJson.general.title}</h4>
                        <div className="perference-container">
                            {pJson.general.list.map((item, index) => {
                                return (
                                    <RSPTooltip position="top" text={item.tooltip}>
                                        <div
                                            key={index}
                                            onClick={() => {
                                                // history.push(`${match.url}/${item.link}`)
                                                history.push({
                                                    pathname: `${match.url}/${item.link}`,
                                                    state: { index: 0 },
                                                });
                                            }}
                                            className={`per-list ${item.disable ? "click-off" : ""}`}
                                        >
                                            <i className={item.icon}>
                                                {item.indicate === true && (
                                                    <span className="indicator"></span>
                                                )}
                                            </i>
                                            <div>{item.title}</div>
                                        </div>
                                    </RSPTooltip>
                                );
                            })}
                        </div>
                    </div>

                    <hr className="m0" />

                    <div className="perference-wrapper even">
                        <h4>{pJson.setup.title}</h4>
                        <div onClick={() => { }} className="perference-container">
                            {pJson.setup.list.map((item, index) => {
                                return (
                                    <RSPTooltip position="top" text={item.tooltip}>
                                        <div
                                            key={index}
                                            onClick={() => {
                                                history.push(`${match.url}/${item.link}`);
                                            }}
                                            className={`per-list ${item.disable ? "click-off" : ""}`}
                                        >
                                            <i className={item.icon}>
                                                {item.indicate === true && (
                                                    <span className="indicator"></span>
                                                )}
                                            </i>
                                            <div>{item.title}</div>
                                        </div>
                                    </RSPTooltip>
                                );
                            })}
                        </div>
                    </div>

                    <hr className="m0" />

                    <div className="perference-wrapper">
                        <h4>{pJson.billing.title}</h4>
                        <div onClick={() => { }} className="perference-container">
                            {pJson.billing.list.map((item, index) => {
                                return (
                                    <RSPTooltip position="top" text={item.tooltip}>
                                        <div
                                            key={index}
                                            onClick={() => {
                                                if (item.link === "service-catalogue") {
                                                    // context.toggleAuth(false)
                                                    // history.push(`${item.link}`)
                                                    history.push({
                                                        pathname: `${match.url}/${item.link}`,
                                                        state: { isForPaylater: item.indicate },
                                                    });
                                                } else {
                                                    history.push(`${match.url}/${item.link}`);
                                                }
                                            }}
                                            className={`per-list ${item.disable ? "click-off" : ""}`}
                                        >
                                            <i className={item.icon}>
                                                {item.indicate === true && (
                                                    <span className="indicator"></span>
                                                )}
                                            </i>
                                            <div>{item.title}</div>
                                        </div>
                                    </RSPTooltip>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Container>
        </LayoutPageWrapper >
    );
};
