import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap"

import NewCharts from 'Components/Charts/NewCharts';
import activeImg from 'Assets/Images/active.svg'
import byHours from 'Assets/Images/aud-chart-4.jpg'
import { RSDropdownList } from 'Components/RSDropdowns';
import { RTabbar } from "Components/RChart/RTabbar";
import SequenceChart from './SunburstChart/SequenceChart';
import { BootstrapDropdown } from "Components/BootstrapDropdown";
import { ch_chartColor, ch_converted, ch_known, ch_unknown, ch_identified, ch_blueLightColor, ch_yellowColor, ch_facebookColor, ch_orange, ch_cyanColor, ch_green, ch_sunday, ch_monday, ch_tuesday, ch_wednesday, ch_thursday, ch_friday, ch_saturday, ch_desktopColor, ch_androidPhoneColor, ch_androidTabColor, ch_iphone, ch_ipad, ch_blueColor, ch_others } from 'Components/Charts/ChartColors';


class WebLiveDashboard extends Component {

    state = {
        sankeyPortletFullView: false,
        tabDeviceIndex: 0,
        tabDaysIndex: 0,
        pathAnalyserDropdown: ["3 levels", "4 levels", "5 levels"]
    }

    DropDownFilter = () => {
        return (
            <Col className="sankey-filter">
                <RSDropdownList
                    data={pathAnalyserDropdown}
                    defaultItem="-- Select type --"
                // defaultValue="Campaign type"
                />
                <i className="icon-sankey-filter-medium icons-md blue"></i>
            </Col>
        )
    }

    render() {

        return (
            <>

                {/* Web live audience */}

                    <Row>
                        <Col md={4}>
                            <div className="portlet-container portlet-sm">
                                <div className="portlet-header">
                                    <h4>Visitor status</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-sm-body-height">
                                        <div className="v-center-inner">
                                            <img src={activeImg} alt={activeImg} width="120" />
                                            <div className="two-label-container">
                                                <div className="two-label">
                                                    <p>Total web visitors</p>
                                                    <h2 className="user-count">22,237</h2>
                                                </div>
                                                <div className="two-label">
                                                    <p>Live visitors</p>
                                                    <h2 className="user-count">2,477</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Daily status</span>)</div>
                            </div>
                        </Col>

                        {/* Traffic breakdown by active users */}
                        <Col md={8}>
                            <div className="portlet-container portlet-sm">
                                <div className="portlet-header">
                                    <h4>Traffic breakdown by web visitors</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={trafficBreakDownWebChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 7 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Key metrics */}
                        <Col md={4}>
                            <div className="portlet-container keymetrics-portlet keymetrics-sm">
                                <h4>Key metrics</h4>
                                <ul className="keymetrics-list key-border">
                                    <li>
                                        <h3>18.9M</h3>
                                        <small>Sessions</small>
                                    </li>
                                    <li>
                                        <h3>66.3M</h3>
                                        <small>Page views</small>
                                    </li>
                                </ul>
                                <ul className="keymetrics-list keymetrics-theme">
                                    <li className="bgBlue-lighter">
                                        <h3>2m 23s</h3>
                                        <small>Avg time spent</small>
                                    </li>
                                    <li className="bgBlue-light">
                                        <h3>3</h3>
                                        <small>Avg screen/session</small>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        {/* Active users */}
                        <Col md={8}>
                            <div className="portlet-container keymetrics-portlet keymetrics-sm">
                                <h4>Active users</h4>
                                <ul className="keymetrics-list key-border mb50">
                                    <li> <h3>1,097</h3> <span>Avg. DAU</span> </li>
                                    <li> <h3>4,397</h3> <span>WAU</span> </li>
                                    <li> <h3>9,550</h3> <span>MAU</span> </li>
                                </ul>
                                {/* <ul className="keymetrics-list keymetrics-theme">
                                    <li className="bgMaroon-light"> <h3>41<small>sec</small></h3> <small>Avg. load time</small> </li>
                                    <li className="bgMaroon-medium"> <h3>15</h3> <small>Page load error</small> </li>
                                    <li className="bgMaroon-dark"> <h3>12<small>%</small></h3> <small>Bounce rate</small> </li>
                                </ul> */}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Audience types */}
                        <Col md={12}>
                            <div className={`portlet-container sankey-portlet ${this.state.sankeyPortletFullView ? 'fullscreenView' : ''}`}>
                                <div className="portlet-header pt10 pb20 h-auto">
                                    <h4>Path analyzer</h4>
                                    <div className="p-nav-right">
                                        <BootstrapDropdown
                                            data={this.state.pathAnalyserDropdown}
                                            defaultItem={"4 levels"}
                                            alignRight
                                            customAlignRight={true}
                                            className="marginR10 fs18-btn"
                                            onSelect={(item) => {
                                                // console.log(JSON.stringify(item))
                                            }}
                                        />
                                        <div className="p-icon-sm">
                                            <i className="icon-retarget-list-small icons-md blue cursor-pointer"></i>
                                        </div>
                                        <div className="p-icon-sm">
                                            <i className="icon-sd-download-medium icons-md blue cursor-pointer"></i>
                                        </div>
                                        <div className="p-icon-sm">
                                            <i onClick={() => {
                                                this.setState({ sankeyPortletFullView: !this.state.sankeyPortletFullView })
                                            }} className={`${this.state.sankeyPortletFullView ? 'icon-collapse-large' : 'icon-expand-small flip-horizontal'} icons-md blue d-inline-block cursor-pointer`}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <div className="sankey-chart-switch-setting">
                                            <Row>
                                                <this.DropDownFilter />
                                                <this.DropDownFilter />
                                                <this.DropDownFilter />
                                                <this.DropDownFilter />
                                                <this.DropDownFilter />
                                            </Row>
                                        </div>
                                        <div className="sankey-chart-container">
                                            <NewCharts className="sankey-last-col-finder" options={sankeyChart} />
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Audience types */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Audience types</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={audienceTypesWebChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Audience conversion */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Audience conversion</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={audienceConversionWebChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Retention */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Retention</h4>
                                    <div className="p-nav-right">
                                        <div className="p-icon-sm">
                                            <i
                                                // onClick={() => {
                                                //     this.setState({ sankeyPortletFullView: !this.state.sankeyPortletFullView })
                                                // }}
                                            className={`${this.state.sankeyPortletFullView ? 'icon-collapse-large' : 'icon-expand-small flip-horizontal'} icons-md blue d-inline-block cursor-pointer`}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <table className="table table-striped table-hover st-grid-knownUser">
                                            <thead>
                                                <tr>
                                                    <th data-field="tlca_header_sno">Date</th>
                                                    <th data-field="tlca_link">Known</th>
                                                    <th data-field="tlca_total_clicks">1 day</th>
                                                    <th data-field="tlca_total_clicks1">2 day</th>
                                                    <th data-field="tlca_total_clicks2">3 day</th>
                                                    <th data-field="tlca_total_clicks3">4 day</th>
                                                    <th data-field="tlca_total_clicks4">5 day</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID1days">{ }</span></td>
                                                    <td>20</td>
                                                    <td>10.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>35.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>30.00</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>15.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40ad" }}>25.00</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID2days">{ }</span></td>
                                                    <td>24</td>
                                                    <td style={{ backgroundColor: "#fd8f40d1" }}>37.50</td>
                                                    <td style={{ backgroundColor: "#fd8f40" }}>50.00</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>20.83</td>
                                                    <td>9.17</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>16.67</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID3days">{ }</span></td>
                                                    <td>29</td>
                                                    <td style={{ backgroundColor: "#fd8f408f" }}>20.69</td>
                                                    <td>0.00</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>13.79</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>17.24</td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID4days">{ }</span></td>
                                                    <td>46</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>32.61</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>19.57</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>13.04</td>
                                                    <td>4.35</td>
                                                    <td>2.17</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID5days">{ }</span></td>
                                                    <td>57</td>
                                                    <td style={{ backgroundColor: "#fd8f40ad" }}>24.56</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>19.30</td>
                                                    <td>1.75</td>
                                                    <td>1.75</td>
                                                    <td>1.75</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ul className="legendList">
                                            <li className="orange-light knownUserAnal">Known</li>
                                            <li className="green identifiedUserAnal">Identified</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Product analytics */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Product analytics</h4>
                                    <div className="p-nav-right">
                                        <div className="p-icon-sm">
                                            <i
                                            className={`${this.state.sankeyPortletFullView ? 'icon-collapse-large' : 'icon-expand-small flip-horizontal'} icons-md blue d-inline-block cursor-pointer`}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        {/* Product analytics - chart */}
                                        <SequenceChart />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Top page views */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Top page views</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <ul className="portlet-list">
                                            <li>
                                                <div>My account</div>
                                                <div className="percentage"><span>89,942</span></div>
                                            </li>
                                            <li>
                                                <div>Bill pay & recharge</div>
                                                <div className="percentage"><span>62,706</span></div>
                                            </li>
                                            <li>
                                                <div>Apply now</div>
                                                <div className="percentage"><span>58,537</span></div>
                                            </li>
                                            <li>
                                                <div>Locate branches & ATM</div>
                                                <div className="percentage"><span>28,603</span></div>
                                            </li>
                                            <li>
                                                <div>Contact us</div>
                                                <div className="percentage"><span>21,942</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Top conversion events */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Top conversion events</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <ul className="portlet-list">
                                            <li>
                                                <p>Apply credit card</p>
                                                <div className="percentage">
                                                    <span>23,236</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Extend limit</p>
                                                <div className="percentage">
                                                    <span>21,849</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Apply home loan</p>
                                                <div className="percentage">
                                                    <span>19,647</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Pay bill</p>
                                                <div className="percentage">
                                                    <span>16,838</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Apply car loan</p>
                                                <div className="percentage">
                                                    <span>13,871</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* By Interests */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>By Interests</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={byInterestsChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Progressive profile */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Progressive profile</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={progressiveProfileWebChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* By device / By browser */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-text">
                                <div className="portlet-header">
                                    {/* <h4>By device</h4> */}
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabDeviceIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataDevice}
                                            callBack={(item, index) => { this.setState({ tabDeviceIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {
                                        this.state.tabDeviceIndex === 0 ? 
                                        <div className="portlet-chart"><NewCharts options={byDeviceChart} /></div> :
                                        <div className="portlet-chart"><NewCharts options={byBrowserChart} /></div>
                                    }
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Usage behaviour | By days / By hours */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-label portlet-footer-text">
                                <div className="portlet-header">
                                    <h4>Usage behaviour</h4>
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabDaysIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataDays}
                                            callBack={(item, index) => { this.setState({ tabDaysIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                {
                                    this.state.tabDaysIndex === 0 ? 
                                    <div className="portlet-chart"><NewCharts options={byDaysChart} /></div> : 
                                    <div className="portlet-image"><img src={byHours} alt={byHours} /></div>
                                }
                                </div>
                                <div className="portlet-two-label">
                                    <ul>
                                        <li><span>{ this.state.tabDaysIndex === 0 ? '18' : '62' }</span><small>%</small></li>
                                        <li>
                                            {
                                                this.state.tabDaysIndex === 0 ? 
                                                'of the users engage with the website on Sunday':
                                                'of the visitors engage during 12 PM to 15 PM'
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

            </>
        )
    }
}

export default WebLiveDashboard;

const tabDataDevice = [
    { "id": 1001, "text": "By device" },
    { "id": 1002, "text": "By browser" }
]
const tabDataDays = [
    { "id": 1001, "text": "By days" },
    { "id": 1002, "text": "By hours" }
]

// web live audience
// Traffic breakdown by active visitors -- area
const trafficBreakDownWebChart = {
    chart: { type: 'area' },
    xAxis: {
        title: { text: '', },
        categories: [
            "14th Oct",
            "15th Oct",
            "16th Oct",
            "17th Oct",
            "18th Oct",
            "19th Oct",
            "20th Oct"
        ]
    },
    yAxis: {
        title: { text: 'Count', },
        tickInterval: 200,
        labels: { format: '{value}', },
    },
    legend: {
        itemMarginTop: -5,
    },
    series: [{
        name: 'Direct traffic',
        color: ch_blueLightColor,
        marker: { lineColor: ch_blueLightColor, fillColor: 'white', },
        data: [170, 180, 146, 90, 80, 160],
        legendIndex: 0,
    }, {
        name: 'Referring links',
        color: ch_green,
        marker: { lineColor: ch_green, fillColor: 'white', },
        data: [175, 80, 35, 105, 240, 80],
        legendIndex: 1,
    }, {
        name: 'Campaigns',
        color: ch_others,
        marker: { lineColor: ch_others, fillColor: 'white', },
        data: [80, 90, 85, 45, 48, 70],
        legendIndex: 2,
    }, {
        name: 'Organic search',
        color: ch_yellowColor,
        marker: { lineColor: ch_yellowColor, fillColor: 'white', },
        data: [100, 120, 130, 190, 100, 50],
        legendIndex: 3,
    }, {
        name: 'Social networks',
        color: ch_facebookColor,
        marker: { lineColor: ch_facebookColor, fillColor: 'white', },
        data: [70, 70, 80, 128, 100, 120],
        legendIndex: 4,
    }]
}
// Path analyser
const pathAnalyserDropdown = [
    "Campaign type",
    "City",
    "Device",
    "User type",
    "Product category"
];
var pathAnalyserData = [
    { from: 'Awareness', to: 'Los Angeles', weight: 320 },
    { from: 'Awareness', to: 'Chicago', weight: 120 },
    { from: 'Greetings', to: 'California', weight: 420 },
    { from: 'Greetings', to: 'Illinois', weight: 120 },
    { from: 'New product launch', to: 'California', weight: 320 },
    { from: 'Promotion', to: 'California', weight: 120 },
    { from: 'Sale', to: 'Chicago', weight: 350 },
    { from: 'Events', to: 'Los Angeles', weight: 220 },
    { from: 'Events', to: 'Illinois', weight: 450 },

    { from: 'Los Angeles', to: 'Mobile', weight: 112 },
    { from: 'Los Angeles', to: 'Browser', weight: 433 },
    { from: 'Los Angeles', to: 'Tablet', weight: 233 },
    { from: 'Chicago', to: 'iPad', weight: 350 },
    { from: 'California', to: 'Mobile', weight: 756 },
    { from: 'California', to: 'Tablet', weight: 256 },
    { from: 'Illinois', to: 'iPad', weight: 505 },

    { from: 'Mobile', to: 'Known', weight: 622 },
    { from: 'Mobile', to: 'Unknown', weight: 322 },
    { from: 'Browser', to: 'Known', weight: 322 },
    { from: 'Browser', to: 'Unknown', weight: 250 },
    { from: 'Tablet', to: 'Unknown', weight: 121 },
    { from: 'iPad', to: 'Known', weight: 275 },
    { from: 'iPad', to: 'Unknown', weight: 423 },

    { from: 'Known', to: 'Mutual fund', weight: 124 },
    { from: 'Known', to: 'Credit card', weight: 268 },
    { from: 'Known', to: 'Fixed deposit', weight: 246 },
    { from: 'Known', to: 'Loans', weight: 414 },
    { from: 'Unknown', to: 'Mutual fund', weight: 366 },
    { from: 'Unknown', to: 'Credit card', weight: 163 },
    { from: 'Unknown', to: 'Loans', weight: 166 }
]
// var pathAnalyserData = [
//     { color: '#ccc', from: 'Awareness', to: 'Los Angeles', weight: 320 },
//     { color: '#ccc', from: 'Awareness', to: 'Chicago', weight: 120 },
//     { color: '#ccc', from: 'Greetings', to: 'California', weight: 420 },
//     { color: '#ccc', from: 'Greetings', to: 'Illinois', weight: 120 },
//     { color: '#ccc', from: 'New product launch', to: 'California', weight: 320 },
//     { color: '#ccc', from: 'Promotion', to: 'California', weight: 120 },
//     { color: '#ccc', from: 'Sale', to: 'Chicago', weight: 350 },
//     { color: '#ccc', from: 'Events', to: 'Los Angeles', weight: 220 },
//     { color: '#ccc', from: 'Events', to: 'Illinois', weight: 450 },

//     { color: '#ccc', from: 'Los Angeles', to: 'Mobile', weight: 112 },
//     { color: '#ccc', from: 'Los Angeles', to: 'Browser', weight: 433 },
//     { color: '#ccc', from: 'Los Angeles', to: 'Tablet', weight: 233 },
//     { color: '#ccc', from: 'Chicago', to: 'iPad', weight: 350 },
//     { color: '#ccc', from: 'California', to: 'Mobile', weight: 756 },
//     { color: '#ccc', from: 'California', to: 'Tablet', weight: 256 },
//     { color: '#ccc', from: 'Illinois', to: 'iPad', weight: 505 },

//     { color: '#ccc', from: 'Mobile', to: 'Known', weight: 622 },
//     { color: '#ccc', from: 'Mobile', to: 'Unknown', weight: 322 },
//     { color: '#ccc', from: 'Browser', to: 'Known', weight: 322 },
//     { color: '#ccc', from: 'Browser', to: 'Unknown', weight: 250 },
//     { color: '#ccc', from: 'Tablet', to: 'Unknown', weight: 121 },
//     { color: '#ccc', from: 'iPad', to: 'Known', weight: 275 },
//     { color: '#ccc', from: 'iPad', to: 'Unknown', weight: 423 },

//     { color: '#ccc', from: 'Known', to: 'Mutual fund', weight: 124 },
//     { color: '#ccc', from: 'Known', to: 'Credit card', weight: 268 },
//     { color: '#ccc', from: 'Known', to: 'Fixed deposit', weight: 246 },
//     { color: '#ccc', from: 'Known', to: 'Loans', weight: 414 },
//     { color: '#ccc', from: 'Unknown', to: 'Mutual fund', weight: 366 },
//     { color: '#ccc', from: 'Unknown', to: 'Credit card', weight: 163 },
//     { color: '#ccc', from: 'Unknown', to: 'Loans', weight: 166 }
// ]
var sankeyChartColor = [
    '#5f77ac', // ch_facebookAppColor, // '#5f77ac'
    '#31ccfd', // ch_twitterColor, // '#31ccfd'
    '#99cc03', // ch_mobileColor, // '#99cc03'
    '#fa5c5c', // ch_pinterestColor, // '#fa5c5c'
    '#e7ca60', // ch_yellowColor, // '#e7ca60'
    '#fd8f40', // ch_orange, // '#fd8f40'
    '#99cc03', // ch_positive, // '#99cc03'
    '#bc2f20', // ch_googleColor, // '#bc2f20'
    '#4885ed', // ch_googleplaystoreColor, // '#4885ed'
    '#31ccfd', // ch_twitterColor, // '#31ccfd'
    '#e62117', // youtubewatchpageColor, // '#e62117'
    '#2896f0', // ch_television, // '#2896f0'
    '#fd8f40', // ch_books, // '#fd8f40'
    '#ff6900', // ch_fullyConversedColor, // '#ff6900'
    '#274da3', // ch_samsungColor, // '#274da3'
    '#99cc03', // ch_clicksColor, // '#99cc03'
    '#e7ca60', // ch_yellowColor, // '#e7ca60'
    '#a1d255', // ch_wednesday, // '#a1d255'
    '#fe5758', // ch_tuesday, // '#fe5758'
    '#585858', // ch_saturday, // '#585858'
    '#008f94' // ch_mac, // '#008f94'
]
const sankeyChart = {
    chart: { type: 'area' },
    title: {
        text: ''
    },
    accessibility: {
        point: {
            valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
        }
    },
    tooltip: {
        headerFormat: '',
        shared: false
    },
    plotOptions: {
        sankey: {
            nodeWidth: 17,
            dataLabels: {
                enabled: true,
                color: '#000000',
                align: 'left',
                // shadow: true,
                x: 22,
                animation: {
                    defer: 2000
                },
                style: {
                    // textShadow: false,
                    textOutline: false,
                    fontWeight: 'normal',
                    fontFamily: 'MuktaRegular',
                    fontSize: '14px'
                }
            }
        }
    },
    series: [{
        stacking: 'normal',
        borderWidth: 0,
        keys: ['from', 'to', 'weight', 'tooltip'],
        data: pathAnalyserData,
        type: 'sankey',
        name: '',
        colors: sankeyChartColor
    }],
    credits: { enabled: false }
}
// Sankey chart last column finder script
setTimeout(function () {
    document.querySelectorAll('.sankey-last-col-finder [transform]').forEach(el => {
        var val = el.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0];
        if (val > 900) {
            el.classList.add("sankey-last-col")
        } else {
        }
    });
}, 1000);

// Audience types -- pie
const audienceTypesWebChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "Unknown", color: ch_unknown, y: 30 },
                { name: "Known", color: ch_known, y: 40 },
                { name: "Identified", color: ch_identified, y: 30 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// Audience conversion -- pyramid
const audienceConversionWebChart = {
    chart: {
        type: 'pyramid',
        // style: { fontFamily: 'mukta-regular', fontWeight: 'normal', fontSize: '16px', color: '#585858' }
    },
    title: {
        text: '',
        x: -50
    },
    credits: {
        enabled: false
    },
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b>k+<br/>',
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                color: "#333",
                style: { color: ch_chartColor, fontSize: '14px', fontWeight: 'normal' }
            },
            center: ['40%', '50%'],
            width: '70%'
        }, pyramid: { reversed: false }
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Value', borderWidth: 5,
        data: [
            { name: 'Unknown', y: 203, color: ch_unknown },
            { name: 'Known', y: 203, color: ch_known },
            { name: 'Identified', y: 144, color: ch_identified },
            { name: 'Converted', y: 108, color: ch_converted },
        ]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        'stroke-width': 10,
                        dataLabels: {
                            inside: true
                        },
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}
// By interests -- pie
const byInterestsChart = {
    chart: {
        type: "variablepie"
    },
    tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            'Leads acquisition: <b>{point.y}</b><br/>'
    },
    series: [
        {
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            size: "100%",

            minPointSize: 10,
            innerSize: '20%',
            startAngle: 180,
            zMin: 0,
            name: 'countries',

            data: [
                { name: "Sports", color: ch_yellowColor, y: 8, z: 8 },
                { name: "Electronics", color: ch_green, y: 10, z: 10 },
                { name: "Auto", color: ch_cyanColor, y: 15, z: 15 },
                { name: "Travel", color: ch_orange, y: 25, z: 25 },
                { name: "Others", color: ch_others, y: 7, z: 7 },
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                distance: 25,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false,
                color: "#333",
                style: { color: ch_chartColor, fontSize: '20px', fontWeight: 'normal' },
                formatter: function () {
                    return this.y + "%";
                }
            },
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    enabled: false,
                }
            }
        },
        {
            borderWidth: 0,
            innerSize: "82%",
            size: "18%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// Progressive profile -- pyramid
const progressiveProfileWebChart = {
    chart: {
        type: 'pyramid',
        // style: { fontFamily: 'mukta-regular', fontWeight: 'normal', fontSize: '16px', color: '#585858' }
    },
    title: {
        text: '',
        x: -50
    },
    credits: {
        enabled: false
    },
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b>k+<br/>',
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                // formatter: function() {
                //     return this.point.name + ' <span style="font-size: 12px">(' + Highcharts.numberFormat(this.percentage, 2) +'%)</span>';
                // },
                format: '{point.name}',
                color: "#333",
                style: { color: ch_chartColor, fontSize: '14px', fontWeight: 'normal' }
            },
            center: ['40%', '50%'],
            width: '70%'
        }, pyramid: { reversed: true }
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Value', borderWidth: 5,
        data: [
            {name: 'Demographic', y: 2, color: "#66c7ea"},
            {name: 'Engagement', y: 2, color: "#5ab5e6"},
            {name: 'Interest', y: 2, color: "#4e9ce0"},
            {name: 'Social share', y: 2, color: "#3568c6"},
            {name: 'Product propensity', y: 2, color: "#132385"}
        ]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        'stroke-width': 10,
                        dataLabels: {
                            inside: true
                        },
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}
// By device -- pie
const byDeviceChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "Desktop", color: ch_desktopColor, y: 12 },
                { name: "Laptop", color: ch_androidPhoneColor, y: 46 },
                { name: "Mobile", color: ch_androidTabColor, y: 18 },
                { name: "Tablet", color: ch_iphone, y: 32 },
                { name: "Others", color: ch_ipad, y: 25 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// By browser -- pie
const byBrowserChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "Chrome", color: ch_cyanColor, y: 51, legendIndex: 0 },
                { name: "Edge", color: ch_green, y: 39, legendIndex: 1 },
                { name: "Firefox", color: ch_orange, y: 9, legendIndex: 2 },
                { name: "Safari", color: ch_blueColor, y: 9, legendIndex: 2 },
                { name: "Others", color: ch_others, y: 1, legendIndex: 3 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// By days -- Column
const byDaysChart = {
    chart: {
        type: 'column',
    },
    xAxis: {
        title: { text: 'Days', },
        categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        tickInterval: 1,
    },
    plotOptions: {
        column: {
            stacking: false,
            colorByPoint: true
        }
    },
    colors: [ch_sunday, ch_monday, ch_tuesday, ch_wednesday, ch_thursday, ch_friday, ch_saturday],
    tooltip: {
        formatter: function () {
            return this.x + '<div style="text-align:center;font-weight:400;font-size: 19px;">' + this.y + '</div>';
        }
    },
    yAxis: {
        title: { text: 'Engagement ( % )', },
        labels: {
            formatter: function () {
                return this.axis.defaultLabelFormatter.call(this) + '%';
            }
        },
        tickInterval: 15,
    },
    series: [
        {
            showInLegend: false,
            name: 'Campaign period',
            pointWidth: 25,
            data: [25, 8, 13.5, 8, 13, 12.5, 20],
            legendIndex: 0, states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    enabled: false,
                }
            }
        }
    ]
}
