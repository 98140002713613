import NewCharts from 'Components/Charts/NewCharts';
import { ColumnChartSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import React, { Component } from 'react';
import { ch_green } from 'Components/Charts/ChartColors';

class SMSConversionChart extends Component {
    state = {
        data: this.props.data,
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ data: this.props.data })
        }
    }
    render() {
        return (
            <div className="portlet-container portlet-md portlet-footer-label">
                <div className="portlet-header">
                    <h4>Conversion</h4>
                </div>
                {this.state.data?.conversionPerformanceDTOs !== null && this.state.data?.conversionPerformanceDTOs?.categories !== null && this.state.data?.conversionPerformanceDTOs?.series !== null ?
                    <>
                        <div className="portlet-body">
                            <div className="portlet-chart">
                                <NewCharts options={conversionChart(this.state.data)} />
                            </div>
                        </div>
                        <div className="portlet-two-label">
                            <ul>
                                <li><span>{this.state.data?.conversion?.registration || 0}</span><small>%</small></li>
                                <li>of conversions happened during this period with {this.state.data?.conversion?.count || 0} users registered</li>
                            </ul>
                        </div></>
                    : <> <NoData /><ColumnChartSkeleton /></>
                }
            </div>
        )
    }
}

export default SMSConversionChart;

// Conversion -- Column
const conversionChart = data => {

    return {
        chart: {
            type: 'column',
        },
        xAxis: {
            title: { text: 'Date', },
            categories: data?.conversionPerformanceDTOs?.categories.map(item => {
                let newDate = new Date(item).toDateString()
                let spiltDate = newDate.split(" ")
                return spiltDate[2] + " " + spiltDate[1]
            }),
        },
        yAxis: {
            title: { text: 'Count', },
        },
        plotOptions: {
            column: {
                stacking: false
            }
        },
        tooltip: {
            shared: false
        },
        series: [{
            name: data?.conversionPerformanceDTOs?.series[0]?.name,
            pointWidth: 25,
            color: ch_green,
            data: data?.conversionPerformanceDTOs?.series[0].datas.map(item => item || 0),
            showInLegend: false,
        }]
    }

}