import React, { Component } from 'react'
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';
import Email from './Email/Email';
import SMS from './SMS/SMS';
import WhatsApp from './WhatsApp/WhatsApp';
import QR from './QR/QR';
import authContext from 'Helper/StateHandler/auth-context';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_CAMPAIGN_ANALYTICS_CHANNEL_LIST, GET_CAMPAIGN_CHANNEL_DETAILS } from 'Helper/Constants/endPoint';
import { RSPTooltip } from 'Components/Tooltip';

class DetailAnalytics extends Component {

    static contextType = authContext

    state = {
        selectKey: 0,
        emailData: null,
        qrData: null,
        channelList: [],
        rowKey: ""
    }

    selectKey = 0

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getCampaignChannelList()
    }

    getTabs = newData => {

        let renderComponent = {
            1: <Email
                state={this.props.history.location && this.props.history.location.state}
                context={this.props.context}
                channelFriendlyName={newData[this.state.selectKey]?.channelFriendlyName}
            />,
            2: <SMS
                state={this.props.history.location && this.props.history.location.state}
                context={this.props.context}
                channelFriendlyName={newData[this.state.selectKey]?.channelFriendlyName}
            />,
            3: <QR
                state={this.props.history.location && this.props.history.location.state}
                context={this.props.context}
                channelFriendlyName={newData[this.state.selectKey]?.channelFriendlyName}
                data={null}
            />,
            21: <WhatsApp
                state={this.props.history.location && this.props.history.location.state}
                context={this.props.context}
                channelFriendlyName={newData[this.state.selectKey]?.channelFriendlyName}
            />
        }

        return newData?.map((item, index) => ({
            title: (!!item?.channelFriendlyName && item?.channelFriendlyName !== "") ? item.channelFriendlyName : item.channelName === 'Mobile' ? 'SMS' : item.channelName,
            getContent: () => renderComponent[item.channelId],
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
        }));
    }

    getCampaignChannelList = () => {
        const state = this.props.history.location.state
        const { campaignId, selectedIndex } = state
        const params = {
            "campaignId": campaignId,
        }
        connectServer({
            path: GET_CAMPAIGN_ANALYTICS_CHANNEL_LIST,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let selectIndex = selectedIndex
                    this.setState({
                        channelList: res.data?.channelList, selectKey: selectIndex || 0
                    })
                }
            },
            fail: err => err
        })
    }

    getCampaignChannelDetails = () => {
        const state = this.props.history.location.state
        const { campaignId, channelId } = state
        const params = {
            "campaignId": campaignId,
            "channelId": channelId || 1,
        }
        connectServer({
            path: GET_CAMPAIGN_CHANNEL_DETAILS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let selectedIndex = res.data.channelList?.length > 1 ? channelId - 1 : 0
                    this.setState({
                        channelList: res.data?.channelList,
                        emailData: res.data?.emailIDetail,
                        qrData: res.data?.qRDetails,
                        rowKey: res.data?.rowKey,
                        selectKey: selectedIndex || 0
                    })
                }
            },
            fail: err => err
        })
    }

    render() {

        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className="d-flex align-items-center">
                        <RSPTooltip text={this.props.history.location.state.campaignName} position="bottom">
                            <h1 className="marginB8 detail-title">{this.props.history.location.state.campaignName || ''}</h1>
                        </RSPTooltip>
                        {/* <i className="icon-star-small icons-md yellow-medium goldCampaign"></i> */}
                        <h1 className="marginB0 marginL15 marginR10">-</h1>
                        {/* <span>(Mon, Dec 20, 2021 - Wed, Jan 19, 2022)</span> */}
                        <span>
                            (<Moment locale="de" format="ddd, MMM DD, yyyy">{this.props.history.location.state.startDate || ''}</Moment> - <Moment locale="de" format="ddd, MMM DD, yyyy">{this.props.history.location.state.endDate || ''}</Moment>)
                        </span>
                    </div>
                    <hr className="blue marginB20" />

                    {this.state.channelList?.length ?
                        <Tabs items={this.getTabs(this.state?.channelList)} selectedTabKey={this.state.selectKey} onChange={(value) => {
                            this.setState({ selectKey: value })
                        }} />
                        : null
                    }

                </Container>
            </LayoutPageWrapper>
        )
    }

}

export default withRouter(DetailAnalytics);