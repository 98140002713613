import React, { useEffect, useState } from 'react';
import { Table } from "react-bootstrap"
import { dateFormat } from 'Helper/Utils/Utils';
const CommunicationInvoiceById = props => {

    const [invoiceDetails, setInvoiceDetails] = useState(props.invoiceDetails)
    useEffect(() => {
        setInvoiceDetails(props.invoiceDetails)
    }, [props])

    const getIconForChannels = (channelId) => {
        switch (channelId) {
            case 1:
                return "icon-sd-email-medium"
            case 2:
                return "icon-sd-mobile-sms-medium"
            case 21:
                return "icon-sd-social-whatsapp-medium"
            case 3:
                return "icon-sd-qrcode-medium"
            default:
                return "icon-sd-email-medium"
        }
    }
    return (
        <>

            <div>
                <p> Communication name :
                    <span className="blue marginX5">{invoiceDetails?.campaignName}</span>
                    <small>(Created on {dateFormat(invoiceDetails?.createdDate)})</small>
                </p>
                <p> Communication type : <span>{invoiceDetails?.campaignType}</span> </p>
            </div>
            {invoiceDetails?.consumableSummary?.recipientPaymentDetails?.audiencePayments?.length > 0 &&
                <>
                    <h2 className='mb15'>Audience</h2>
                    <Table bordered className="table rs-table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>List name</th>
                                <th>Channel/Data</th>
                                <th>Credits</th>
                                <th>Audience count</th>
                                <th>Unit charge (USD)</th>
                                <th>Amount (USD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceDetails?.consumableSummary?.recipientPaymentDetails?.audiencePayments?.map((item, index) => {
                                return (item?.paymentMapping?.map((pitem, pindex) => {
                                    return <tr>
                                        {/* <td className="camp-icon">
                        <div className="d-flex align-items-center">
                          <i className={`icon-sd-users-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} />
                          <span style={{ verticalAlign: 'middle' }}>{"Audience"}</span>
                        </div>
                      </td> */}
                                        <td colSpan={pitem?.description === "Consumed Data credits" ? "3" : "0"}>
                                            {pitem?.description}
                                        </td>
                                        <td className={`${pitem?.description === "Consumed Data credits" ? "d-none" : ""}`}>{pitem?.listname}</td>
                                        <td className={`${pitem?.description === "Consumed Data credits" ? "d-none" : ""}`}>
                                            <div className='table-text'>
                                                <p>{pitem?.channelType}</p>
                                            </div>
                                        </td>
                                        <td className="text-end">{pitem?.contactPoints}</td>
                                        <td className="text-end">{pitem?.totalAudienceCount}</td>
                                        <td className="text-end">${pitem?.unitPrice}</td>
                                        <td className="text-end">{pitem?.description === "Consumed Data credits" ? "(-) " : ""}${pitem?.totalRecipientAmount}</td>
                                    </tr>
                                }))
                            })}
                        </tbody>
                    </Table>
                </>
            }
            <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table" style={{ paddingTop: "20px" }}>
                {invoiceDetails?.campaignType.startsWith("M") ?
                    <thead>
                        <tr>
                            <th width="35%">Channel</th>
                            <th width="10%">MDC level</th>
                            <th width="10%">Units used</th>
                            <th width="15%">Unit charge (USD)</th>
                            <th width="10%">Amount (USD)</th>
                        </tr>
                    </thead>
                    :
                    <thead>
                        <tr>
                            <th width="35%">Channel</th>
                            <th width="25%">Units used</th>
                            <th width="25%">Unit charge (USD)</th>
                            <th>Amount (USD)</th>
                        </tr></thead>}
                <tbody>
                    {invoiceDetails?.consumableSummary?.consumableMapping && invoiceDetails?.consumableSummary?.consumableMapping?.sort((a, b) => {
                        let firstOne = a.channelName.toLowerCase();
                        let secondOne = b.channelName.toLowerCase();
                        if (firstOne < secondOne) {
                            return -1
                        }
                        if (firstOne > secondOne) {
                            return 1
                        }
                        return 0
                    }).map((item, index) => {
                        return <tr key={index} className={index % 2 === 0 ? "" : "even"}>
                            <td className="camp-icon">
                                <div className="d-flex align-items-center">
                                    <i className={`${getIconForChannels(item?.channelId)} icons-md blue mr8`} style={{ verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle' }}>{item?.channelName}</span>
                                </div>
                            </td>
                            {invoiceDetails?.campaignType.startsWith("M") && <td className="text-end">{item?.levelNumber}</td>}
                            <td className="text-end">{item?.numberOfRecipients}</td>
                            <td className="text-end">${item?.price}</td>
                            <td className="text-end">${item?.totalChannelCost}</td>
                        </tr>
                    })}
                    <tr>
                        <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan={invoiceDetails?.campaignType.startsWith("M") ? "3" : "2"} rowSpan="5"></td>
                        <td className="text-start">Sub total:</td>
                        <td className="text-end">${invoiceDetails?.consumableSummary?.subTotal}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Adjustments :</td>
                        <td className="text-end">${0}</td>
                    </tr>
                    <tr className="bgWhite even">
                        <td className="text-start"> Total: </td>
                        <td className="text-end"> (+) ${invoiceDetails?.consumableSummary?.subTotal}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start"> Tax ({invoiceDetails?.consumableSummary?.taxPercentage}%): </td>
                        <td className="text-end"> (+) ${invoiceDetails?.consumableSummary?.tax}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Amount payable</td>
                        <td className="text-end blue">${invoiceDetails?.totalAmount}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default CommunicationInvoiceById;