import { RSPrimaryBtn } from 'Components/RSButtons';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export const SuccessModal = (props) => {

    const [show, setShow] = useState(props.isOpen);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShow(false)
            props.buttonClicked(false)
        }, 5000);
        return () => clearTimeout(timeoutId);
    })

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            show={show}
            onHide={() => {
                setShow(false)
                props.buttonClicked(false)
            }}
            centered
            size='md'
        >
            <Modal.Header className='border-0 mt5' closeButton>
            </Modal.Header>
            <Modal.Body>
                {props.title === "Failure" ?
                    <p className='margin-T20 text-center'>
                        <i className='icon-sd-thumbs-down-fill-large icons-xxl space-top orange-medium' />
                    </p>
                    : <p className='margin-T20 text-center'>
                        <i className='icon-sd-thumbs-up-fill-large icons-xxl space-top green-medium' />
                    </p>}
                <p className='margin-T20 text-center'>{props.subTitle !== "" && props.subTitle}</p>
            </Modal.Body>
            {(!!props.isOkButton && props.isOkButton) &&
                <Modal.Footer>
                    <RSPrimaryBtn onClick={() => {
                        setShow(false)
                        props.buttonClicked(false)
                    }}>Ok</RSPrimaryBtn>
                </Modal.Footer>
            }
        </Modal>
    );
}
