import React from "react";
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";
import { RSPTooltip } from "Components/Tooltip";
import { dateFormat } from 'Helper/Utils/Utils';

const CampaignAnalyticsCell = props => {

  const history = useHistory()
  const match = useRouteMatch()

  const navigateToDetailAnalytics = () => {
    history.push({
      pathname: `${match.url}/detail-analytics`,
      state: {
        campaignId: props.dataItem.campaignId,
        campaignGuid: props.dataItem.campaignGuid,
        campaignName: props.dataItem.campaignName,
        startDate: props.dataItem.startDate,
        endDate: props.dataItem.endDate,
        isEmailSplitAb: props.dataItem.isEmailSplitAb,
        isSmsSplitAb: props.dataItem.isSmsSplitAb,
        isQRSplitAb: props.dataItem.isQRSplitAb
      }
    })
  }

  return (
    <td className={`${campaignProgressStatus[props.dataItem.statusId].className}`}>
      <div className="panelbar-wrapper campaign-analytics-list">

        <div className="cellPannelInner">
          <div className="created-user-id">
            {/* <span className="campaign-id">{props.dataItem.campaignShortCode}</span> */}
            <small className="d-flex">Created by:&nbsp;{props.dataItem.userFirstName?.length > 15 ? <RSPTooltip text={props.dataItem.userFirstName} position="top"><span className="created-name">{props.dataItem.userFirstName}</span></RSPTooltip> : <span className="created-name">{props.dataItem.userFirstName}</span>}, on: {dateFormat(props.dataItem.createdDate)}</small>
          </div>
          <RSPTooltip text={props.dataItem.campaignName} position="top">
            <p>{props.dataItem.campaignName}</p>
          </RSPTooltip>
        </div>

        <div className="cellPannelInner">
          <small>{props.dataItem.campaignTypeValue}</small>
          <p>{props.dataItem.attributeName}</p>
        </div>

        <div className="cellPannelInner">
          <small>Sent on</small>
          <p>{dateFormat(props.dataItem.startDate)}</p>
        </div>

        <div className={`statusIndicator ${campaignProgressStatus[props.dataItem.statusId].className}`}>
          <div className="status-box">
            <i className={`${campaignProgressStatus[props.dataItem.statusId].icon} icons-md white`}></i>
            <span className="status-small">{campaignProgressStatus[props.dataItem.statusId].title}</span>
          </div>
        </div>

        <div className="camp-icon">
          <ul className="camp-icon-pannel">
            <li><RSPTooltip text="Analytics" position="top"><i onClick={() => navigateToDetailAnalytics()} className="icon-sd-analytics-medium icons-md blue"></i></RSPTooltip></li>
            {/* <li className={`${!props.dataItem.isPdfDownloaded && 'click-off'}`}><RSPTooltip text="Download" position="top"><i className="icon-sd-download-medium icons-md blue"></i></RSPTooltip></li> */}
          </ul>
        </div>

        <div className="expand-plus">
          <ul className="camp-icon-pannel">
            <li>
              <i className="k-icon k-i-plus"></i>
            </li>
          </ul>
        </div>
      </div>
    </td>
  );
};

export default withRouter(CampaignAnalyticsCell);

const campaignStatus = campaignStatusId => {
  return campaignProgressStatus[campaignStatusId]
}

const campaignProgressStatus = {

  0: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-medium' },
  5: { title: 'In progress', className: 'status-progress', icon: 'icon-sd-in-progress-medium' },
  52: { title: 'Alert', className: 'status-alert', icon: 'icon-sd-alert-medium' },
  9: { title: 'Completed', className: 'status-completed', icon: 'icon-sd-tick-medium' },
  7: { title: 'Scheduled', className: 'status-scheduled', icon: 'icon-sd-time-medium' },
  6: { title: 'Draft', className: 'status-draft', icon: 'icon-sd-form-edit-medium' },
  20: { title: 'Multistatus', className: 'status-multistatus', icon: 'icon-sd-multi-status-large' }

}
