// Icon-mini
export const add_events_mini = 'icon-sd-add-events-mini'
export const admin_feature_mini  = 'icon-sd-admin-feature-mini '
export const age_mini = 'icon-sd-age-mini'
export const alert_mini = 'icon-sd-alert-mini'
export const alexa_mini = 'icon-sd-alexa-mini'
export const analytics_mini = 'icon-sd-analytics-mini'
export const analytics_settings_mini = 'icon-sd-analytics-settings-mini'
export const android_mini = 'icon-sd-android-mini'
export const api_settings_mini = 'icon-sd-api-settings-mini'
export const app_store_mini = 'icon-sd-app-store-mini'
export const arrow_down_mini = 'icon-sd-arrow-down-mini'
export const arrow_left_mini = 'icon-sd-arrow-left-mini'
export const arrow_left_right_mini = 'icon-sd-arrow-left-right-mini'
export const arrow_right_mini = 'icon-sd-arrow-right-mini'
export const arrow_up_mini = 'icon-sd-arrow-up-mini'
export const asterisk_mini = 'icon-sd-asterisk-mini'
export const attach_mini = 'icon-sd-attach-mini'
export const audience_dynamic_list_mini = 'icon-sd-audience-dynamic-list-mini'
export const audience_list_mini = 'icon-sd-audience-list-mini'
export const audience_target_list_mini = 'icon-sd-audience-target-list-mini'
export const audio_mini = 'icon-sd-audio-mini'
export const bar_filter_mini = 'icon-sd-bar-filter-mini'
export const barcode_mini = 'icon-sd-barcode-mini'
export const beacon_mini = 'icon-sd-beacon-mini'
export const bell_mini = 'icon-sd-bell-mini'
export const benchmark_scale_mini = 'icon-sd-benchmark-scale-mini'
export const bookmark_mini = 'icon-sd-bookmark-mini'
export const brand_mini = 'icon-sd-brand-mini'
export const burger_menu_mini = 'icon-sd-burger-menu-mini'
export const calendar_mini = 'icon-sd-calendar-mini'
export const caret_mini = 'icon-sd-caret-mini'
export const cart_drop_mini = 'icon-sd-cart-drop-mini'
export const cart_mini = 'icon-sd-cart-mini'
export const cart_not_purchase_mini = 'icon-sd-cart-not-purchase-mini'
export const cart_tick_mini = 'icon-sd-cart-tick-mini'
export const channel_action_mini = 'icon-sd-channel-action-mini'
export const chatbot_mini = 'icon-sd-chatbot-mini'
export const circle_arrow_down_mini = 'icon-sd-circle-arrow-down-mini'
export const circle_arrow_left_mini = 'icon-sd-circle-arrow-left-mini'
export const circle_arrow_mini = 'icon-sd-circle-arrow-mini'
export const circle_arrow_right_fill_mini = 'icon-sd-circle-arrow-right-fill-mini'
export const circle_arrow_right_mini = 'icon-sd-circle-arrow-right-mini'
export const circle_close_edge_mini = 'icon-sd-circle-close-edge-mini'
export const circle_close_fill_edge_mini = 'icon-sd-circle-close-fill-edge-mini'
export const circle_close_fill_mini = 'icon-sd-circle-close-fill-mini'
export const circle_close_mini = 'icon-sd-circle-close-mini'
export const circle_dropdown_mini = 'icon-sd-circle-dropdown-mini'
export const circle_exclamatory_mini = 'icon-sd-circle-exclamatory-mini'
export const circle_filter_mini = 'icon-sd-circle-filter-mini'
export const circle_grid_edge_mini = 'icon-sd-circle-grid-edge-mini'
export const circle_grid_fill_edge_mini = 'icon-sd-circle-grid-fill-edge-mini'
export const circle_grid_fill_mini = 'icon-sd-circle-grid-fill-mini'
export const circle_grid_mini = 'icon-sd-circle-grid-mini'
export const circle_hierarchy_fill_edge_mini = 'icon-sd-circle-hierarchy-fill-edge-mini'
export const circle_hierarchy_fill_mini = 'icon-sd-circle-hierarchy-fill-mini'
export const circle_history_fill_mini = 'icon-sd-circle-history-fill-mini'
export const circle_info_mini = 'icon-sd-circle-info-mini'
export const circle_key_fill_mini = 'icon-sd-circle-key-fill-mini'
export const circle_link_verification_mini = 'icon-sd-circle-link-verification-mini'
export const circle_list_mini = 'icon-sd-circle-list-mini'
export const circle_menu_dot_horizontal_mini = 'icon-sd-circle-menu-dot-horizontal-mini'
export const circle_menu_dot_mini = 'icon-sd-circle-menu-dot-mini'
export const circle_minus_fill_edge_mini = 'icon-sd-circle-minus-fill-edge-mini'
export const circle_minus_fill_mini = 'icon-sd-circle-minus-fill-mini'
export const circle_paid_media_mini = 'icon-sd-circle-paid-media-mini'
export const circle_pencil_mini = 'icon-sd-circle-pencil-mini'
export const circle_plus_edge_mini = 'icon-sd-circle-plus-edge-mini'
export const circle_plus_fill_edge_mini = 'icon-sd-circle-plus-fill-edge-mini'
export const circle_plus_fill_mini = 'icon-sd-circle-plus-fill-mini'
export const circle_plus_mini = 'icon-sd-circle-plus-mini'
export const circle_question_mark_fill_mini = 'icon-sd-circle-question-mark-fill-mini'
export const circle_question_mark_mini = 'icon-sd-circle-question-mark-mini'
export const circle_segmentation_status_mini = 'icon-sd-circle-segmentation-status-mini'
export const circle_smile_mini = 'icon-sd-circle-smile-mini'
export const circle_sub_segment_mini = 'icon-sd-circle-sub-segment-mini'
export const circle_thumbs_up_fill_mini = 'icon-sd-circle-thumbs-up-fill-mini'
export const circle_tick_mini = 'icon-sd-circle-tick-mini'
export const circle_time_mini = 'icon-sd-circle-time-mini'
export const circle_user_mini = 'icon-sd-circle-user-mini'
export const circle_zoom_fill_edge_mini = 'icon-sd-circle-zoom-fill-edge-mini'
export const circle_zoom_fill_mini = 'icon-sd-circle-zoom-fill-mini'
export const close_mini = 'icon-sd-close-mini'
export const code_editor_mini = 'icon-sd-code-editor-mini'
export const collapse_mini = 'icon-sd-collapse-mini'
export const colorpicker_bg_mini = 'icon-sd-colorpicker-bg-mini'
export const colorpicker_text_miini_1 = 'icon-sd-colorpicker-text-miini_1'
export const colorpicker_text_miini = 'icon-sd-colorpicker-text-miini'
export const communication_ai_mini = 'icon-sd-communication-ai-mini'
export const communication_analytics_mini = 'icon-sd-communication-analytics-mini'
export const communication_arrow_mini = 'icon-sd-communication-arrow-mini'
export const communication_calender_mini = 'icon-sd-communication-calender-mini'
export const communication_draft_mini = 'icon-sd-communication-draft-mini'
export const communication_edit_mini = 'icon-sd-communication-edit-mini'
export const communication_list_mini = 'icon-sd-communication-list-mini'
export const communication_mini = 'icon-sd-communication-mini'
export const communication_pending_mini = 'icon-sd-communication-pending-mini'
export const communication_plus_mini = 'icon-sd-communication-plus-mini'
export const communication_response_sync_mini = 'icon-sd-communication-response-sync-mini'
export const communication_retarget_mini = 'icon-sd-communication-retarget-mini'
export const communication_sdc_mini = 'icon-sd-communication-sdc-mini'
export const communication_settings_mini = 'icon-sd-communication-settings-mini'
export const communication_stop_mini = 'icon-sd-communication-stop-mini'
export const communication_target_mini = 'icon-sd-communication-target-mini'
export const communication_tick_mini = 'icon-sd-communication-tick-mini'
export const communication_trigger_mini = 'icon-sd-communication-trigger-mini'
export const components_mini = 'icon-sd-components-mini'
export const content_management_mini = 'icon-sd-content-management-mini'
export const content_target_mini = 'icon-sd-content-target-mini'
export const conversion_mini = 'icon-sd-conversion-mini'
export const copy_mini = 'icon-sd-copy-mini'
export const coupon_mini = 'icon-sd-coupon-mini'
export const credit_card_mini = 'icon-sd-credit-card-mini'
export const crown_fill_mini = 'icon-sd-crown-fill-mini'
export const crown_mini = 'icon-sd-crown-mini'
export const css_mini = 'icon-sd-css-mini'
export const csv_mini = 'icon-sd-csv-mini'
export const data_download_mini = 'icon-sd-data-download-mini'
export const data_group_mini = 'icon-sd-data-group-mini'
export const data_history_mini = 'icon-sd-data-history-mini'
export const data_log_mini = 'icon-sd-data-log-mini'
export const data_mini = 'icon-sd-data-mini'
export const data_sync_comparison_mini = 'icon-sd-data-sync-comparison-mini'
export const delete_mini = 'icon-sd-delete-mini'
export const desktop_mini = 'icon-sd-desktop-mini'
export const divider_mini = 'icon-sd-divider-mini'
export const dlr_ftp_logs_mini = 'icon-sd-dlr-ftp-logs-mini'
export const dmp_mini = 'icon-sd-dmp-mini'
export const dollar_mini = 'icon-sd-dollar-mini'
export const download_mini = 'icon-sd-download-mini'
export const duplicate_mini = 'icon-sd-duplicate-mini'
export const email_arrow_left_mini = 'icon-sd-email-arrow-left-mini'
export const email_arrow_right_mini = 'icon-sd-email-arrow-right-mini'
export const email_bounced_mini = 'icon-sd-email-bounced-mini'
export const email_click_mini = 'icon-sd-email-click-mini'
export const email_close_mini = 'icon-sd-email-close-mini'
export const email_footer_mini = 'icon-sd-email-footer-mini'
export const email_list_mini = 'icon-sd-email-list-mini'
export const email_mini = 'icon-sd-email-mini'
export const email_preview_mini = 'icon-sd-email-preview-mini'
export const email_settings_mini = 'icon-sd-email-settings-mini'
export const email_spam_mini = 'icon-sd-email-spam-mini'
export const email_tick_mini = 'icon-sd-email-tick-mini'
export const engagement_mini = 'icon-sd-engagement-mini'
export const equal_to_attribute_mini = 'icon-sd-equal-to-attribute-mini'
export const equal_to_mini = 'icon-sd-equal-to-mini'
export const event_tracking_mini = 'icon-sd-event-tracking-mini'
export const events_mini = 'icon-sd-events-mini'
export const expand_mini = 'icon-sd-expand-mini'
export const eye_hide_mini = 'icon-sd-eye-hide-mini'
export const eye_mini = 'icon-sd-eye-mini'
export const field_selector_mini = 'icon-sd-field-selector-mini'
export const file_lock_mini = 'icon-sd-file-lock-mini'
export const file_tick_mini = 'icon-sd-file-tick-mini'
export const file_user_mini = 'icon-sd-file-user-mini'
export const filter_mini = 'icon-sd-filter-mini'
export const form_edit_mini = 'icon-sd-form-edit-mini'
export const fullscreen_collapse_mini = 'icon-sd-fullscreen-collapse-mini'
export const globe_mini = 'icon-sd-globe-mini'
export const goal_achieved_mini = 'icon-sd-goal-achieved-mini'
export const goal_summary_data_view_mini = 'icon-sd-goal-summary-data-view-mini'
export const google_home_mini = 'icon-sd-google-home-mini'
export const greater_than_attribute_mini = 'icon-sd-greater-than-attribute-mini'
export const grid_mini = 'icon-sd-grid-mini'
export const heart_fill_mini = 'icon-sd-heart-fill-mini'
export const html_editor_mini = 'icon-sd-html-editor-mini'
export const image_mini = 'icon-sd-image-mini'
export const import_audio_mini = 'icon-sd-import-audio-mini'
export const import_file_mini = 'icon-sd-import-file-mini'
export const import_link_mini = 'icon-sd-import-link-mini'
export const in_between_mini = 'icon-sd-in-between-mini'
export const in_progress_mini = 'icon-sd-in-progress-mini'
export const ios_mini = 'icon-sd-ios-mini'
export const ipad_mini = 'icon-sd-ipad-mini'
export const ipsettings_mini = 'icon-sd-ipsettings-mini'
export const ivr_mini = 'icon-sd-ivr-mini'
export const justify_dropdown_mini = 'icon-sd-justify-dropdown-mini'
export const key_fill_mini = 'icon-sd-key-fill-mini'
export const key_mini = 'icon-sd-key-mini'
export const lesser_than_attribute_mini = 'icon-sd-lesser-than-attribute-mini'
export const like_1_fill_mini = 'icon-sd-like-1-fill-mini'
export const like_2_fill_mini = 'icon-sd-like-2-fill-mini'
export const like_3_fill_mini = 'icon-sd-like-3-fill-mini'
export const like_4_fill_mini = 'icon-sd-like-4-fill-mini'
export const like_5_fill_mini = 'icon-sd-like-5-fill-mini'
export const link_mini = 'icon-sd-link-mini'
export const link_verification_mini = 'icon-sd-link-verification-mini'
export const list_attribute_mini = 'icon-sd-list-attribute-mini'
export const lock_mini = 'icon-sd-lock-mini'
export const map_mini = 'icon-sd-map-mini'
export const menu_dot_mini = 'icon-sd-menu-dot-mini'
export const messaging_mini = 'icon-sd-messaging-mini'
export const minus_mini = 'icon-sd-minus-mini'
export const mms_mini = 'icon-sd-mms-mini'
export const mobile_analytics_mini = 'icon-sd-mobile-analytics-mini'
export const mobile_mini = 'icon-sd-mobile-mini'
export const mobile_notification_mini = 'icon-sd-mobile-notification-mini'
export const mobile_sms_mini = 'icon-sd-mobile-sms-mini'
export const mobile_sms_response_mini = 'icon-sd-mobile-sms-response-mini'
export const move_mini = 'icon-sd-move-mini'
export const my_profile_mini  = 'icon-sd-my-profile-mini '
export const not_equal_to_attribute_mini = 'icon-sd-not-equal-to-attribute-mini'
export const not_equal_to_mini = 'icon-sd-not-equal-to-mini'
export const notification_mini = 'icon-sd-notification-mini'
export const offline_conversion_mini = 'icon-sd-offline-conversion-mini'
export const ott_push_notifications_mini = 'icon-sd-ott-push-notifications-mini'
export const pause_mini = 'icon-sd-pause-mini'
export const paypal_mini = 'icon-sd-paypal-mini'
export const pdf_mini = 'icon-sd-pdf-mini'
export const pencil_edit_mini = 'icon-sd-pencil-edit-mini'
export const play_fill_mini = 'icon-sd-play-fill-mini'
export const play_mini = 'icon-sd-play-mini'
export const plus_mini = 'icon-sd-plus-mini'
export const preview_mini = 'icon-sd-preview-mini'
export const publishers_network_mini = 'icon-sd-publishers-network-mini'
export const purchase_pattern_mini = 'icon-sd-purchase-pattern-mini'
export const qrcode_mini = 'icon-sd-qrcode-mini'
export const question_mark_mini = 'icon-sd-question-mark-mini'
export const recycle_mini = 'icon-sd-recycle-mini'
export const redo_mini = 'icon-sd-redo-mini'
export const refresh_mini = 'icon-sd-refresh-mini'
export const report_mini = 'icon-sd-report-mini'
export const responsive_mini = 'icon-sd-responsive-mini'
export const restart_mini = 'icon-sd-restart-mini'
export const roi_mini = 'icon-sd-roi-mini'
export const save_mini = 'icon-sd-save-mini'
export const sentiment_mini = 'icon-sd-sentiment-mini'
export const settings_help_mini = 'icon-sd-settings-help-mini'
export const settings_mini = 'icon-sd-settings-mini'
export const share_mini = 'icon-sd-share-mini'
export const share_publish_mini = 'icon-sd-share-publish-mini'
export const share_tick_mini = 'icon-sd-share-tick-mini'
export const shield_mini = 'icon-sd-shield-mini'
export const shield_tick_mini = 'icon-sd-shield-tick-mini'
export const shrink_mini = 'icon-sd-shrink-mini'
export const smart_link_mini = 'icon-sd-smart-link-mini'
export const smiliey_1_mini = 'icon-sd-smiliey-1-mini'
export const smiliey_2_mini = 'icon-sd-smiliey-2-mini'
export const smiliey_3_mini = 'icon-sd-smiliey-3-mini'
export const smiliey_4_mini = 'icon-sd-smiliey-4-mini'
export const smiliey_5_mini = 'icon-sd-smiliey-5-mini'
export const snapshot_mini = 'icon-sd-snapshot-mini'
export const social_facebook_ads_mini = 'icon-sd-social-facebook-ads-mini'
export const social_facebook_app_mini = 'icon-sd-social-facebook-app-mini'
export const social_facebook_mini = 'icon-sd-social-facebook-mini'
export const social_google_ad_mini = 'icon-sd-social-google-ad-mini'
export const social_google_plus_mini = 'icon-sd-social-google-plus-mini'
export const social_instagram_mini = 'icon-sd-social-instagram-mini'
export const social_line_mini = 'icon-sd-social-line-mini'
export const social_linkedin_ads_mini = 'icon-sd-social-linkedin-ads-mini'
export const social_linkedin_mini = 'icon-sd-social-linkedin-mini'
export const social_pinterest_mini = 'icon-sd-social-pinterest-mini'
export const social_post_mini = 'icon-sd-social-post-mini'
export const social_twitter_ads_mini = 'icon-sd-social-twitter-ads-mini'
export const social_twitter_mini = 'icon-sd-social-twitter-mini'
export const social_viber_mini = 'icon-sd-social-viber-mini'
export const social_vms_mini = 'icon-sd-social-vms-mini'
export const social_vuer_mini = 'icon-sd-social-vuer-mini'
export const social_whatsapp_mini = 'icon-sd-social-whatsapp-mini'
export const social_youtube_mini = 'icon-sd-social-youtube-mini'
export const solar_monitering_mini = 'icon-sd-solar-monitering-mini'
export const spam_assassin_mini = 'icon-sd-spam-assassin-mini'
export const spanner_mini = 'icon-sd-spanner-mini'
export const square_minus_fill_mini = 'icon-sd-square-minus-fill-mini'
export const square_plus_fill_mini = 'icon-sd-square-plus-fill-mini'
export const star_fill_mini = 'icon-sd-star-fill-mini'
export const star_mini = 'icon-sd-star-mini'
export const stop_fill_mini = 'icon-sd-stop-fill-mini'
export const stop_mini = 'icon-sd-stop-mini'
export const style_editor_mini = 'icon-sd-style-editor-mini'
export const tag_mini = 'icon-sd-tag-mini'
export const tag_offer_mini = 'icon-sd-tag-offer-mini'
export const template_builder_mini = 'icon-sd-template-builder-mini'
export const template_mini = 'icon-sd-template-mini'
export const text_document_mini = 'icon-sd-text-document-mini'
export const thumbs_down_fill_mini = 'icon-sd-thumbs-down-fill-mini'
export const thumbs_down_mini = 'icon-sd-thumbs-down-mini'
export const thumbs_left_fill_mini = 'icon-sd-thumbs-left-fill-mini'
export const thumbs_right_fill_mini = 'icon-sd-thumbs-right-fill-mini'
export const thumbs_straight_fill_mini = 'icon-sd-thumbs-straight-fill-mini'
export const thumbs_up_fill_mini = 'icon-sd-thumbs-up-fill-mini'
export const thumbs_up_mini = 'icon-sd-thumbs-up-mini'
export const tick_mini = 'icon-sd-tick-mini'
export const time_mini = 'icon-sd-time-mini'
export const undo_mini = 'icon-sd-undo-mini'
export const user_360_mini = 'icon-sd-user-360-mini'
export const user_admin_settings_mini = 'icon-sd-user-admin-settings-mini'
export const user_arrow_down_mini = 'icon-sd-user-arrow-down-mini'
export const user_arrow_mini  = 'icon-sd-user-arrow-mini '
export const user_call_center_mini = 'icon-sd-user-call-center-mini'
export const user_form_mini = 'icon-sd-user-form-mini'
export const user_laddering_mini = 'icon-sd-user-laddering-mini'
export const user_leadscore_mini = 'icon-sd-user-leadscore-mini'
export const user_list_mini = 'icon-sd-user-list-mini'
export const user_mini = 'icon-sd-user-mini'
export const user_network_mini = 'icon-sd-user-network-mini'
export const user_question_mark_mini = 'icon-sd-user-question-mark-mini'
export const user_settings_mini = 'icon-sd-user-settings-mini'
export const user_tick_mini = 'icon-sd-user-tick-mini'
export const users_persona_mini = 'icon-sd-users-persona-mini'
export const voice_assistant_mini = 'icon-sd-voice-assistant-mini'
export const web_analytics_mini = 'icon-sd-web-analytics-mini'
export const web_browser_mini = 'icon-sd-web-browser-mini'
export const web_meta_data_mini = 'icon-sd-web-meta-data-mini'
export const web_notification_mini = 'icon-sd-web-notification-mini'
export const web_request_mini = 'icon-sd-web-request-mini'
export const webinar_mini = 'icon-sd-webinar-mini'
export const zip_mini = 'icon-sd-zip-mini'
export const zoom_eye_mini = 'icon-sd-zoom-eye-mini'
export const zoom_mini = 'icon-sd-zoom-mini'
export const zoom_minus_mini = 'icon-sd-zoom-minus-mini'
export const zoom_plus_mini = 'icon-sd-zoom-plus-mini'
export const zoom_user_mini = 'icon-sd-zoom-user-mini'

export const filter_fill_mini = 'icon-sd-filter-fill-mini'
export const goals_benchmark_mini = 'icon-sd-goals-benchmark-mini'
export const printer_mini = 'icon-sd-printer-mini'
export const retarget_list_mini = 'icon-sd-retarget-list-mini'
export const zoom_link_verification_mini = 'icon-sd-zoom-link-verification-mini'
export const gif_mini = 'icon-sd-gif-mini'
export const trend_report_mini = 'icon-sd-trend-report-mini'
export const circle_minus_mini = 'icon-sd-circle-minus-mini'

// Icon-medium
export const add_events_medium = 'icon-sd-add-events-medium'
export const admin_feature_medium = 'icon-sd-admin-feature-medium'
export const age_medium = 'icon-sd-age-medium'
export const alert_medium = 'icon-sd-alert-medium'
export const alexa_medium = 'icon-sd-alexa-medium'
export const analytics_medium = 'icon-sd-analytics-medium'
export const analytics_settings_medium = 'icon-sd-analytics-settings-medium'
export const android_medium = 'icon-sd-android-medium'
export const api_settings_medium = 'icon-sd-api-settings-medium'
export const app_store_medium = 'icon-sd-app-store-medium'
export const arrow_down_medium = 'icon-sd-arrow-down-medium'
export const arrow_left_medium = 'icon-sd-arrow-left-medium'
export const arrow_left_right_medium = 'icon-sd-arrow-left-right-medium'
export const arrow_right_medium = 'icon-sd-arrow-right-medium'
export const arrow_up_bold_medium = 'icon-sd-arrow-up-bold-medium'
export const arrow_up_medium = 'icon-sd-arrow-up-medium'
export const asterisk_medium = 'icon-sd-asterisk-medium'
export const attach_medium = 'icon-sd-attach-medium'
export const audience_dynamic_list_medium = 'icon-sd-audience-dynamic-list-medium'
export const audience_list_medium = 'icon-sd-audience-list-medium'
export const audience_target_list_medium = 'icon-sd-audience-target-list-medium'
export const audio_medium = 'icon-sd-audio-medium'
export const bar_filter_medium = 'icon-sd-bar-filter-medium'
export const barcode_medium = 'icon-sd-barcode-medium'
export const beacon_medium = 'icon-sd-beacon-medium'
export const bell_medium = 'icon-sd-bell-medium'
export const benchmark_scale_medium = 'icon-sd-benchmark-scale-medium'
export const bookmark_medium = 'icon-sd-bookmark-medium'
export const brand_medium = 'icon-sd-brand-medium'
export const burger_menu_medium = 'icon-sd-burger-menu-medium'
export const calendar_medium = 'icon-sd-calendar-medium'
export const cart_drop_medium = 'icon-sd-cart-drop-medium'
export const cart_medium = 'icon-sd-cart-medium'
export const cart_not_purchase_medium = 'icon-sd-cart-not-purchase-medium'
export const cart_tick_medium = 'icon-sd-cart-tick-medium'
export const channel_action_medium = 'icon-sd-channel-action-medium'
export const chatbot_medium = 'icon-sd-chatbot-medium'
export const circle_arrow_down_medium = 'icon-sd-circle-arrow-down-medium'
export const circle_arrow_left_medium = 'icon-sd-circle-arrow-left-medium'
export const circle_arrow_medium = 'icon-sd-circle-arrow-medium'
export const circle_arrow_right_fill_medium = 'icon-sd-circle-arrow-right-fill-medium'
export const circle_arrow_right_medium = 'icon-sd-circle-arrow-right-medium'
export const circle_close_edge_medium = 'icon-sd-circle-close-edge-medium'
export const circle_close_fill_edge_medium = 'icon-sd-circle-close-fill-edge-medium'
export const circle_close_fill_medium = 'icon-sd-circle-close-fill-medium'
export const circle_close_medium = 'icon-sd-circle-close-medium'
export const circle_dropdown_medium = 'icon-sd-circle-dropdown-medium'
export const circle_exclamatory_medium = 'icon-sd-circle-exclamatory-medium'
export const circle_filter_medium = 'icon-sd-circle-filter-medium'
export const circle_grid_edge_medium = 'icon-sd-circle-grid-edge-medium'
export const circle_grid_fill_edge_medium = 'icon-sd-circle-grid-fill-edge-medium'
export const circle_grid_fill_medium = 'icon-sd-circle-grid-fill-medium'
export const circle_grid_medium = 'icon-sd-circle-grid-medium'
export const circle_hierarchy_fill_edge_medium = 'icon-sd-circle-hierarchy-fill-edge-medium'
export const circle_hierarchy_fill_medium = 'icon-sd-circle-hierarchy-fill-medium'
export const circle_history_fill_medium = 'icon-sd-circle-history-fill-medium'
export const circle_info_medium = 'icon-sd-circle-info-medium'
export const circle_key_fill_medium = 'icon-sd-circle-key-fill-medium'
export const circle_link_verification_medium = 'icon-sd-circle-link-verification-medium'
export const circle_list_medium = 'icon-sd-circle-list-medium'
export const circle_menu_dot_horizontal_medium = 'icon-sd-circle-menu-dot-horizontal-medium'
export const circle_menu_dot_medium = 'icon-sd-circle-menu-dot-medium'
export const circle_minus_fill_edge_medium = 'icon-sd-circle-minus-fill-edge-medium'
export const circle_minus_fill_medium = 'icon-sd-circle-minus-fill-medium'
export const circle_paid_media_medium = 'icon-sd-circle-paid-media-medium'
export const circle_pencil_medium = 'icon-sd-circle-pencil-medium'
export const circle_plus_edge_medium = 'icon-sd-circle-plus-edge-medium'
export const circle_plus_fill_edge_medium = 'icon-sd-circle-plus-fill-edge-medium'
export const circle_plus_fill_medium = 'icon-sd-circle-plus-fill-medium'
export const circle_plus_medium = 'icon-sd-circle-plus-medium'
export const circle_question_mark_fill_medium = 'icon-sd-circle-question-mark-fill-medium'
export const circle_question_mark_medium = 'icon-sd-circle-question-mark-medium'
export const circle_segmentation_status_medium = 'icon-sd-circle-segmentation-status-medium'
export const circle_smile_medium = 'icon-sd-circle-smile-medium'
export const circle_sub_segment_medium = 'icon-sd-circle-sub-segment-medium'
export const circle_thumbs_up_fill_medium = 'icon-sd-circle-thumbs-up-fill-medium'
export const circle_tick_medium = 'icon-sd-circle-tick-medium'
export const circle_time_medium = 'icon-sd-circle-time-medium'
export const circle_user_medium = 'icon-sd-circle-user-medium'
export const circle_zoom_fill_edge_medium = 'icon-sd-circle-zoom-fill-edge-medium'
export const circle_zoom_fill_medium = 'icon-sd-circle-zoom-fill-medium'
export const close_medium = 'icon-sd-close-medium'
export const code_editor_medium = 'icon-sd-code-editor-medium'
export const collapse_medium = 'icon-sd-collapse-medium'
export const colorpicker_bg_medium = 'icon-sd-colorpicker-bg-medium'
export const colorpicker_text_medium = 'icon-sd-colorpicker-text-medium'
export const communication_ai_medium = 'icon-sd-communication-ai-medium'
export const communication_analytics_medium = 'icon-sd-communication-analytics-medium'
export const communication_arrow_medium = 'icon-sd-communication-arrow-medium'
export const communication_calender_medium = 'icon-sd-communication-calender-medium'
export const communication_draft_medium = 'icon-sd-communication-draft-medium'
export const communication_edit_medium = 'icon-sd-communication-edit-medium'
export const communication_list_medium = 'icon-sd-communication-list-medium'
export const communication_mdc_medium = 'icon-sd-communication-mdc-medium'
export const communication_medium = 'icon-sd-communication-medium'
export const communication_pending_medium = 'icon-sd-communication-pending-medium'
export const communication_plus_medium = 'icon-sd-communication-plus-medium'
export const communication_response_sync_medium = 'icon-sd-communication-response-sync-medium'
export const communication_retarget_medium = 'icon-sd-communication-retarget-medium'
export const communication_sdc_medium = 'icon-sd-communication-sdc-medium'
export const communication_settings_medium = 'icon-sd-communication-settings-medium'
export const communication_stop_medium = 'icon-sd-communication-stop-medium'
export const communication_target_medium = 'icon-sd-communication-target-medium'
export const communication_tick_medium = 'icon-sd-communication-tick-medium'
export const communication_trigger_medium = 'icon-sd-communication-trigger-medium'
export const components_medium = 'icon-sd-components-medium'
export const content_management_medium = 'icon-sd-content-management-medium'
export const content_target_meadium = 'icon-sd-content-target-meadium'
export const conversion_medium = 'icon-sd-conversion-medium'
export const copy_medium = 'icon-sd-copy-medium'
export const coupon_medium = 'icon-sd-coupon-medium'
export const credit_card_medium = 'icon-sd-credit-card-medium'
export const crown_fill_medium = 'icon-sd-crown-fill-medium'
export const crown_medium = 'icon-sd-crown-medium'
export const css_medium = 'icon-sd-css-medium'
export const csv_medium = 'icon-sd-csv-medium'
export const data_download_medium = 'icon-sd-data-download-medium'
export const data_group_medium = 'icon-sd-data-group-medium'
export const data_history_medium = 'icon-sd-data-history-medium'
export const data_log_medium = 'icon-sd-data-log-medium'
export const data_medium = 'icon-sd-data-medium'
export const data_sync_comparison_medium = 'icon-sd-data-sync-comparison-medium'
export const date_time_medium = 'icon-sd-date-time-medium'
export const delete_medium = 'icon-sd-delete-medium'
export const desktop_medium = 'icon-sd-desktop-medium'
export const divider_medium = 'icon-sd-divider-medium'
export const dlr_ftp_logs_medium = 'icon-sd-dlr-ftp-logs-medium'
export const dmp_medium = 'icon-sd-dmp-medium'
export const dollar_bold_medium = 'icon-sd-dollar-bold-medium'
export const dollar_medium = 'icon-sd-dollar-medium'
export const download_medium = 'icon-sd-download-medium'
export const duplicate_medium = 'icon-sd-duplicate-medium'
export const email_arrow_left_maedium = 'icon-sd-email-arrow-left-maedium'
export const email_arrow_right_maedium = 'icon-sd-email-arrow-right-maedium'
export const email_click_maedium = 'icon-sd-email-click-maedium'
export const email_close_medium = 'icon-sd-email-close-medium'
export const email_footer_medium = 'icon-sd-email-footer-medium'
export const email_list_list_medium = 'icon-sd-email-list-list-medium'
export const email_medium = 'icon-sd-email-medium'
export const email_preview_medium = 'icon-sd-email-preview-medium'
export const email_settings_maedium = 'icon-sd-email-settings-maedium'
export const email_spam_medium = 'icon-sd-email-spam-medium'
export const email_tick_medium = 'icon-sd-email-tick-medium'
export const engagement_medium = 'icon-sd-engagement-medium'
export const equal_to_attribute_medium = 'icon-sd-equal-to-attribute-medium'
export const equal_to_medium = 'icon-sd-equal-to-medium'
export const event_tracking_medium = 'icon-sd-event-tracking-medium'
export const events_medium = 'icon-sd-events-medium'
export const expand_medium = 'icon-sd-expand-medium'
export const eye_hide_medium = 'icon-sd-eye-hide-medium'
export const eye_medium = 'icon-sd-eye-medium'
export const field_selector_medium = 'icon-sd-field-selector-medium'
export const file_lock_medium = 'icon-sd-file-lock-medium'
export const file_tick_medium = 'icon-sd-file-tick-medium'
export const file_user_medium = 'icon-sd-file-user-medium'
export const filter_medium = 'icon-sd-filter-medium'
export const fire_small = 'icon-sd-fire-small'
export const form_edit_medium = 'icon-sd-form-edit-medium'
export const fullscreen_collapse_medium = 'icon-sd-fullscreen-collapse-medium'
export const globe_medium = 'icon-sd-globe-medium'
export const goal_achieved_medium = 'icon-sd-goal-achieved-medium'
export const goal_summary_data_view_medium = 'icon-sd-goal-summary-data-view-medium'
export const google_home_medium = 'icon-sd-google-home-medium'
export const greater_than_attribute_medium = 'icon-sd-greater-than-attribute-medium'
export const grid_medium = 'icon-sd-grid-medium'
export const heart_fill_medium = 'icon-sd-heart-fill-medium'
export const html_editor_medium = 'icon-sd-html-editor-medium'
export const image_medium = 'icon-sd-image-medium'
export const import_audio_medium = 'icon-sd-import-audio-medium'
export const import_file_medium = 'icon-sd-import-file-medium'
export const import_link_medium = 'icon-sd-import-link-medium'
export const in_between_medium = 'icon-sd-in-between-medium'
export const in_progress_medium = 'icon-sd-in-progress-medium'
export const ios_medium = 'icon-sd-ios-medium'
export const ipad_medium = 'icon-sd-ipad-medium'
export const ipsettings_medium = 'icon-sd-ipsettings-medium'
export const ivr_medium = 'icon-sd-ivr-medium'
export const justify_dropdown_medium = 'icon-sd-justify-dropdown-medium'
export const key_fill_medium = 'icon-sd-key-fill-medium'
export const key_medium = 'icon-sd-key-medium'
export const landing_page_builder_medium = 'icon-sd-landing-page-builder-medium'
export const lesser_than_attribute_medium = 'icon-sd-lesser-than-attribute-medium'
export const like_1_fill_medium = 'icon-sd-like-1-fill-medium'
export const like_2_fill_medium = 'icon-sd-like-2-fill-medium'
export const like_3_fill_medium = 'icon-sd-like-3-fill-medium'
export const like_4_fill_medium = 'icon-sd-like-4-fill-medium'
export const like_5_fill_medium = 'icon-sd-like-5-fill-medium'
export const link_medium = 'icon-sd-link-medium'
export const list_attribute_medium = 'icon-sd-list-attribute-medium'
export const list_bar_medium = 'icon-sd-list-bar-medium'
export const lock_medium = 'icon-sd-lock-medium'
export const log_report_medium = 'icon-sd-log-report-medium'
export const logout_medium = 'icon-sd-logout-medium'
export const map_medium = 'icon-sd-map-medium'
export const menu_dot_medium = 'icon-sd-menu-dot-medium'
export const messaging_medium = 'icon-sd-messaging-medium'
export const minus_medium = 'icon-sd-minus-medium'
export const mms_medium = 'icon-sd-mms-medium'
export const mobile_analytics_medium = 'icon-sd-mobile-analytics-medium'
export const mobile_medium = 'icon-sd-mobile-medium'
export const mobile_notification_medium = 'icon-sd-mobile-notification-medium'
export const mobile_sms_medium = 'icon-sd-mobile-sms-medium'
export const mobile_sms_response_medium = 'icon-sd-mobile-sms-response-medium'
export const move_medium = 'icon-sd-move-medium'
export const multi_status_medium = 'icon-sd-multi-status-medium'
export const my_profile_medium = 'icon-sd-my-profile-medium'
export const not_equal_to_attribute_medium = 'icon-sd-not-equal-to-attribute-medium'
export const not_equal_to_medium = 'icon-sd-not-equal-to-medium'
export const notification_medium = 'icon-sd-notification-medium'
export const offline_conversion_medium = 'icon-sd-offline-conversion-medium'
export const ott_push_notifications_medium = 'icon-sd-ott-push-notifications-medium'
export const pause_medium = 'icon-sd-pause-medium'
export const paypal_medium = 'icon-sd-paypal-medium'
export const pdf_medium = 'icon-sd-pdf-medium'
export const pencil_edit_medium = 'icon-sd-pencil-edit-medium'
export const play_fill_medium = 'icon-sd-play-fill-medium'
export const play_medium = 'icon-sd-play-medium'
export const plus_medium = 'icon-sd-plus-medium'
export const poweredby_medium = 'icon-sd-poweredby-medium'
export const ppt_download_medium = 'icon-sd-ppt-download-medium'
export const preview_medium = 'icon-sd-preview-medium'
export const publishesd_network_medium = 'icon-sd-publishesd-network-medium'
export const purchase_pattern_medium = 'icon-sd-purchase-pattern-medium'
export const qrcode_medium = 'icon-sd-qrcode-medium'
export const question_mark_medium = 'icon-sd-question-mark-medium'
export const recycle_medium = 'icon-sd-recycle-medium'
export const redo_medium = 'icon-sd-redo-medium'
export const refresh_medium = 'icon-sd-refresh-medium'
export const report_medium = 'icon-sd-report-medium'
export const responsive_medium = 'icon-sd-responsive-medium'
export const restart_medium = 'icon-sd-restart-medium'
export const roi_medium = 'icon-sd-roi-medium'
export const save_medium = 'icon-sd-save-medium'
export const sentiment_medium = 'icon-sd-sentiment-medium'
export const settings_help_medium = 'icon-sd-settings-help-medium'
export const settings_medium = 'icon-sd-settings-medium'
export const share_medium = 'icon-sd-share-medium'
export const share_publish_medium = 'icon-sd-share-publish-medium'
export const share_tick_medium = 'icon-sd-share-tick-medium'
export const shield_medium = 'icon-sd-shield-medium'
export const shield_tick_medium = 'icon-sd-shield-tick-medium'
export const shrink_medium = 'icon-sd-shrink-medium'
export const smart_link_medium = 'icon-sd-smart-link-medium'
export const smiliey_1_medium = 'icon-sd-smiliey-1-medium'
export const smiliey_2_medium = 'icon-sd-smiliey-2-medium'
export const smiliey_3_medium = 'icon-sd-smiliey-3-medium'
export const smiliey_4_medium = 'icon-sd-smiliey-4-medium'
export const smiliey_5_medium = 'icon-sd-smiliey-5-medium'
export const snapshot_medium = 'icon-sd-snapshot-medium'
export const social_facebook_ads_medium = 'icon-sd-social-facebook-ads-medium'
export const social_facebook_app_medium = 'icon-sd-social-facebook-app-medium'
export const social_facebook_medium = 'icon-sd-social-facebook-medium'
export const social_google_ad_medium = 'icon-sd-social-google-ad-medium'
export const social_google_plus_medium = 'icon-sd-social-google-plus-medium'
export const social_instagram_medium = 'icon-sd-social-instagram-medium'
export const social_line_medium = 'icon-sd-social-line-medium'
export const social_linkedin_ads_medium = 'icon-sd-social-linkedin-ads-medium'
export const social_linkedin_medium = 'icon-sd-social-linkedin-medium'
export const social_media_medium = 'icon-sd-social-media-medium'
export const social_pinterest_medium = 'icon-sd-social-pinterest-medium'
export const social_post_medium = 'icon-sd-social-post-medium'
export const social_twitter_ads_medium = 'icon-sd-social-twitter-ads-medium'
export const social_twitter_medium = 'icon-sd-social-twitter-medium'
export const social_viber_medium = 'icon-sd-social-viber-medium'
export const social_vms_medium = 'icon-sd-social-vms-medium'
export const social_vuer_medium = 'icon-sd-social-vuer-medium'
export const social_whatsapp_medium = 'icon-sd-social-whatsapp-medium'
export const social_youtube_medium = 'icon-sd-social-youtube-medium'
export const solar_monitering_medium = 'icon-sd-solar-monitering-medium'
export const spam_assassin_medium = 'icon-sd-spam-assassin-medium'
export const spanner_medium = 'icon-sd-spanner-medium'
export const square_minus_fill_medium = 'icon-sd-square-minus-fill-medium'
export const square_plus_fill_medium = 'icon-sd-square-plus-fill-medium'
export const star_fill_medium = 'icon-sd-star-fill-medium'
export const star_medium = 'icon-sd-star-medium'
export const stop_fill_medium = 'icon-sd-stop-fill-medium'
export const stop_medium = 'icon-sd-stop-medium'
export const style_editor_medium = 'icon-sd-style-editor-medium'
export const tag_medium = 'icon-sd-tag-medium'
export const tag_offer_medium = 'icon-sd-tag-offer-medium'
export const template_builder_medium = 'icon-sd-template-builder-medium'
export const template_medium = 'icon-sd-template-medium'
export const text_document_medium = 'icon-sd-text-document-medium'
export const thumbs_down_fill_medium = 'icon-sd-thumbs-down-fill-medium'
export const thumbs_down_medium = 'icon-sd-thumbs-down-medium'
export const thumbs_left_fill_medium = 'icon-sd-thumbs-left-fill-medium'
export const thumbs_right_fill_medium = 'icon-sd-thumbs-right-fill-medium'
export const thumbs_straight_fill_medium = 'icon-sd-thumbs-straight-fill-medium'
export const thumbs_up_fill_medium = 'icon-sd-thumbs-up-fill-medium'
export const thumbs_up_medium = 'icon-sd-thumbs-up-medium'
export const tick_medium = 'icon-sd-tick-medium'
export const time_medium = 'icon-sd-time-medium'
export const undo_medium = 'icon-sd-undo-medium'
export const user_360_medium = 'icon-sd-user-360-medium'
export const user_admin_settings_medium = 'icon-sd-user-admin-settings-medium'
export const user_analytics_medium = 'icon-sd-user-analytics-medium'
export const user_arrow_down_medium = 'icon-sd-user-arrow-down-medium'
export const user_arrow_medium = 'icon-sd-user-arrow-medium'
export const user_call_center_medium = 'icon-sd-user-call-center-medium'
export const user_card_medium = 'icon-sd-user-card-medium'
export const user_form_medium = 'icon-sd-user-form-medium'
export const user_gdpr_medium = 'icon-sd-user-gdpr-medium'
export const user_laddering_medium = 'icon-sd-user-laddering-medium'
export const user_leadscore_medium = 'icon-sd-user-leadscore-medium'
export const user_list_medium = 'icon-sd-user-list-medium'
export const user_medium = 'icon-sd-user-medium'
export const user_network_medium = 'icon-sd-user-network-medium'
export const user_overview_medium = 'icon-sd-user-overview-medium'
export const user_question_mark_edge_medium = 'icon-sd-user-question-mark-edge-medium'
export const user_question_mark_medium = 'icon-sd-user-question-mark-medium'
export const user_settings_medium = 'icon-sd-user-settings-medium'
export const user_star_medium = 'icon-sd-user-star-medium'
export const user_tick_medium = 'icon-sd-user-tick-medium'
export const users_persona_medium = 'icon-sd-users-persona-medium'
export const voice_assistant_medium = 'icon-sd-voice-assistant-medium'
export const web_analytics_medium = 'icon-sd-web-analytics-medium'
export const web_browser_medium = 'icon-sd-web-browser-medium'
export const web_meta_data_medium = 'icon-sd-web-meta-data-medium'
export const web_notification_medium = 'icon-sd-web-notification-medium'
export const web_request_medium = 'icon-sd-web-request-medium'
export const web_settings_medium = 'icon-sd-web-settings-medium'
export const webinar_medium = 'icon-sd-webinar-medium'
export const zip_medium = 'icon-sd-zip-medium'
export const zoom_analytics_medium = 'icon-sd-zoom-analytics-medium'
export const zoom_eye_medium = 'icon-sd-zoom-eye-medium'
export const zoom_medium = 'icon-sd-zoom-medium'
export const zoom_minus_medium = 'icon-sd-zoom-minus-medium'
export const zoom_plus_medium = 'icon-sd-zoom-plus-medium'
export const zoom_user_medium = 'icon-sd-zoom-user-medium'

export const zoom_link_verification_medium = 'icon-sd-zoom-link-verification-medium'
export const gif_medium = 'icon-sd-gif-medium'
export const trend_report_medium = 'icon-sd-trend-report-medium'
export const circle_minus_medium = 'icon-sd-circle-minus-medium'
export const filter_fill_medium = 'icon-sd-filter-fill-medium'
export const goals_benchmark_medium = 'icon-sd-goals-benchmark-medium'
export const printer_medium = 'icon-sd-printer-medium'
export const retarget_list_medium = 'icon-sd-retarget-list-medium'
export const user_goals_benchmark_medium = 'icon-sd-user-goals-benchmark-medium'

// Icon-large
export const add_events_large = 'icon-sd-add-events-large'
export const admin_feature_large = 'icon-sd-admin-feature-large'
export const age_large = 'icon-sd-age-large'
export const alert_large = 'icon-sd-alert-large'
export const alexa_large = 'icon-sd-alexa-large'
export const analytics_large = 'icon-sd-analytics-large'
export const analytics_settings_large = 'icon-sd-analytics-settings-large'
export const android_large = 'icon-sd-android-large'
export const api_settings_large = 'icon-sd-api-settings-large'
export const app_store_large = 'icon-sd-app-store-large'
export const arrow_down_large = 'icon-sd-arrow-down-large'
export const arrow_left_large = 'icon-sd-arrow-left-large'
export const arrow_left_right_large = 'icon-sd-arrow-left-right-large'
export const arrow_right_large = 'icon-sd-arrow-right-large'
export const arrow_up_large = 'icon-sd-arrow-up-large'
export const asterisk_large = 'icon-sd-asterisk-large'
export const attach_large = 'icon-sd-attach-large'
export const audience_dynamic_list_large = 'icon-sd-audience-dynamic-list-large'
export const audience_list_large = 'icon-sd-audience-list-large'
export const audience_target_list_large = 'icon-sd-audience-target-list-large'
export const audio_large = 'icon-sd-audio-large'
export const bar_filter_large = 'icon-sd-bar-filter-large'
export const barcode_large = 'icon-sd-barcode-large'
export const beacon_large = 'icon-sd-beacon-large'
export const bell_large = 'icon-sd-bell-large'
export const benchmark_scale_large = 'icon-sd-benchmark-scale-large'
export const block_large = 'icon-sd-block-large'
export const bookmark_large = 'icon-sd-bookmark-large'
export const brand_domain_large = 'icon-sd-brand-domain-large'
export const brand_large = 'icon-sd-brand-large'
export const builder_attachment_large = 'icon-sd-builder-attachment-large'
export const builder_button_large = 'icon-sd-builder-button-large'
export const builder_checkbox_large = 'icon-sd-builder-checkbox-large'
export const builder_combobox_large = 'icon-sd-builder-combobox-large'
export const builder_coupon_large = 'icon-sd-builder-coupon-large'
export const builder_dam_large = 'icon-sd-builder-dam-large'
export const builder_date_large = 'icon-sd-builder-date-large'
export const builder_date_time_large = 'icon-sd-builder-date-time-large'
export const builder_divider_large = 'icon-sd-builder-divider-large'
export const builder_edit_large = 'icon-sd-builder-edit-large'
export const builder_hidden_filed_large = 'icon-sd-builder-hidden-filed-large'
export const builder_image_large = 'icon-sd-builder-image-large'
export const builder_left_caption_large = 'icon-sd-builder-left-caption-large'
export const builder_multiple_choice_large = 'icon-sd-builder-multiple-choice-large'
export const builder_pim_large = 'icon-sd-builder-pim-large'
export const builder_qrcode_large = 'icon-sd-builder-qrcode-large'
export const builder_radio_large = 'icon-sd-builder-radio-large'
export const builder_rating_large = 'icon-sd-builder-rating-large'
export const builder_right_caption_large = 'icon-sd-builder-right-caption-large'
export const builder_rss_large = 'icon-sd-builder-rss-large'
export const builder_social_share_large = 'icon-sd-builder-social-share-large'
export const builder_table_create_large = 'icon-sd-builder-table-create-large'
export const builder_text_large = 'icon-sd-builder-text-large'
export const builder_textfield_large = 'icon-sd-builder-textfield-large'
export const builder_upload_large = 'icon-sd-builder-upload-large'
export const builder_video_large = 'icon-sd-builder-video-large'
export const builder_webinar_large = 'icon-sd-builder-webinar-large'
export const burger_menu_large = 'icon-sd-burger-menu-large'
export const calendar_dollar_large = 'icon-sd-calendar-dollar-large'
export const calendar_large = 'icon-sd-calendar-large'
export const carousel_large = 'icon-sd-carousel-large'
export const cart_drop_large = 'icon-sd-cart-drop-large'
export const cart_large = 'icon-sd-cart-large'
export const cart_not_purchase_large = 'icon-sd-cart-not-purchase-large'
export const cart_tick_large = 'icon-sd-cart-tick-large'
export const channel_action_large = 'icon-sd-channel-action-large'
export const chatbot_large = 'icon-sd-chatbot-large'
export const circle_arrow_down_large = 'icon-sd-circle-arrow-down-large'
export const circle_arrow_large = 'icon-sd-circle-arrow-large'
export const circle_arrow_left_large = 'icon-sd-circle-arrow-left-large'
export const circle_arrow_right_fill_large = 'icon-sd-circle-arrow-right-fill-large'
export const circle_arrow_right_large = 'icon-sd-circle-arrow-right-large'
export const circle_close_edge_large = 'icon-sd-circle-close-edge-large'
export const circle_close_fill_edge_large = 'icon-sd-circle-close-fill-edge-large'
export const circle_close_fill_large = 'icon-sd-circle-close-fill-large'
export const circle_close_large = 'icon-sd-circle-close-large'
export const circle_dropdown_large = 'icon-sd-circle-dropdown-large'
export const circle_exclamatory_large = 'icon-sd-circle-exclamatory-large'
export const circle_filter_large = 'icon-sd-circle-filter-large'
export const circle_grid_edge_large = 'icon-sd-circle-grid-edge-large'
export const circle_grid_fill_edge_large = 'icon-sd-circle-grid-fill-edge-large'
export const circle_grid_fill_large = 'icon-sd-circle-grid-fill-large'
export const circle_grid_large = 'icon-sd-circle-grid-large'
export const circle_hierarchy_fill_edge_large = 'icon-sd-circle-hierarchy-fill-edge-large'
export const circle_hierarchy_fill_large = 'icon-sd-circle-hierarchy-fill-large'
export const circle_history_fill_edge_large = 'icon-sd-circle-history-fill-edge-large'
export const circle_history_fill_large = 'icon-sd-circle-history-fill-large'
export const circle_info_large = 'icon-sd-circle-info-large'
export const circle_key_fill_large = 'icon-sd-circle-key-fill-large'
export const circle_link_verification_large = 'icon-sd-circle-link-verification-large'
export const circle_list_edge_large = 'icon-sd-circle-list-edge-large'
export const circle_list_large = 'icon-sd-circle-list-large'
export const circle_menu_dot_horizontal_large = 'icon-sd-circle-menu-dot-horizontal-large'
export const circle_menu_dot_large = 'icon-sd-circle-menu-dot-large'
export const circle_minus_fill_edge_large = 'icon-sd-circle-minus-fill-edge-large'
export const circle_minus_fill_large = 'icon-sd-circle-minus-fill-large'
export const circle_paid_media_large = 'icon-sd-circle-paid-media-large'
export const circle_pencil_large = 'icon-sd-circle-pencil-large'
export const circle_plus_edge_large = 'icon-sd-circle-plus-edge-large'
export const circle_plus_fill_edge_large = 'icon-sd-circle-plus-fill-edge-large'
export const circle_plus_fill_large = 'icon-sd-circle-plus-fill-large'
export const circle_plus_large = 'icon-sd-circle-plus-large'
export const circle_question_mark_fill_large = 'icon-sd-circle-question-mark-fill-large'
export const circle_question_mark_large = 'icon-sd-circle-question-mark-large'
export const circle_segmentation_status_large = 'icon-sd-circle-segmentation-status-large'
export const circle_smile_large = 'icon-sd-circle-smile-large'
export const circle_sub_segment_large = 'icon-sd-circle-sub-segment-large'
export const circle_thumbs_up_fill_large = 'icon-sd-circle-thumbs-up-fill-large'
export const circle_tick_large = 'icon-sd-circle-tick-large'
export const circle_time_large = 'icon-sd-circle-time-large'
export const circle_user_large = 'icon-sd-circle-user-large'
export const circle_zoom_fill_edge_large = 'icon-sd-circle-zoom-fill-edge-large'
export const circle_zoom_fill_large = 'icon-sd-circle-zoom-fill-large'
export const close_large = 'icon-sd-close-large'
export const code_editor_large = 'icon-sd-code-editor-large'
export const code_large = 'icon-sd-code-large'
export const collapse_large = 'icon-sd-collapse-large'
export const colorpicker_bg_large = 'icon-sd-colorpicker-bg-large'
export const colorpicker_text_large = 'icon-sd-colorpicker-text-large'
export const communication_ai_large = 'icon-sd-communication-ai-large'
export const communication_analytics_large = 'icon-sd-communication-analytics-large'
export const communication_arrow_large = 'icon-sd-communication-arrow-large'
export const communication_calender_large = 'icon-sd-communication-calender-large'
export const communication_draft_large = 'icon-sd-communication-draft-large'
export const communication_edit_large = 'icon-sd-communication-edit-large'
export const communication_large = 'icon-sd-communication-large'
export const communication_list_large = 'icon-sd-communication-list-large'
export const communication_mdc_large = 'icon-sd-communication-mdc-large'
export const communication_pending_large = 'icon-sd-communication-pending-large'
export const communication_plus_large = 'icon-sd-communication-plus-large'
export const communication_response_sync_large = 'icon-sd-communication-response-sync-large'
export const communication_retarget_large = 'icon-sd-communication-retarget-large'
export const communication_sdc_large = 'icon-sd-communication-sdc-large'
export const communication_settings_large = 'icon-sd-communication-settings-large'
export const communication_stop_large = 'icon-sd-communication-stop-large'
export const communication_target_large = 'icon-sd-communication-target-large'
export const communication_tick_large = 'icon-sd-communication-tick-large'
export const communication_trigger_large = 'icon-sd-communication-trigger-large'
export const components_large = 'icon-sd-components-large'
export const consumptions_large = 'icon-sd-consumptions-large'
export const content_management_large = 'icon-sd-content-management-large'
export const content_target_large = 'icon-sd-content-target-large'
export const conversion_large = 'icon-sd-conversion-large'
export const copy_large = 'icon-sd-copy-large'
export const countdown_large = 'icon-sd-countdown-large'
export const coupon_large = 'icon-sd-coupon-large'
export const credit_card_large = 'icon-sd-credit-card-large'
export const crown_fill_large = 'icon-sd-crown-fill-large'
export const crown_large = 'icon-sd-crown-large'
export const css_large = 'icon-sd-css-large'
export const csv_large = 'icon-sd-csv-large'
export const custom_attributes_large = 'icon-sd-custom-attributes-large'
export const data_attributes_schema_large = 'icon-sd-data-attributes-schema-large'
export const data_catalogue_large = 'icon-sd-data-catalogue-large'
export const data_download_large = 'icon-sd-data-download-large'
export const data_exchange_large = 'icon-sd-data-exchange-large'
export const data_group_large = 'icon-sd-data-group-large'
export const data_history_large = 'icon-sd-data-history-large'
export const data_large = 'icon-sd-data-large'
export const data_log_large = 'icon-sd-data-log-large'
export const data_sync_large = 'icon-sd-data-sync-large'
export const date_time_large = 'icon-sd-date-time-large'
export const delete_large = 'icon-sd-delete-large'
export const desktop_large = 'icon-sd-desktop-large'
export const divider_large = 'icon-sd-divider-large'
export const dlr_ftp_logs_large = 'icon-sd-dlr-ftp-logs-large'
export const dmp_large = 'icon-sd-dmp-large'
export const dollar_large = 'icon-sd-dollar-large'
export const download_large = 'icon-sd-download-large'
export const duplicate_large = 'icon-sd-duplicate-large'
export const elements_large = 'icon-sd-elements-large'
export const email_arrow_left_large = 'icon-sd-email-arrow-left-large'
export const email_arrow_right_large = 'icon-sd-email-arrow-right-large'
export const email_bounced_large = 'icon-sd-email-bounced-large'
export const email_click_large = 'icon-sd-email-click-large'
export const email_close_large = 'icon-sd-email-close-large'
export const email_footer_large = 'icon-sd-email-footer-large'
export const email_large = 'icon-sd-email-large'
export const email_list_large = 'icon-sd-email-list-large'
export const email_preview_large = 'icon-sd-email-preview-large'
export const email_setting_larget = 'icon-sd-email-setting-larget'
export const email_sms_large = 'icon-sd-email-sms-large'
export const email_smtp_large = 'icon-sd-email-smtp-large'
export const email_spam_large = 'icon-sd-email-spam-large'
export const email_sub_unsub_large = 'icon-sd-email-sub-unsub-large'
export const email_tick_large = 'icon-sd-email-tick-large'
export const engagement_large = 'icon-sd-engagement-large'
export const equal_to_attribute_large = 'icon-sd-equal-to-attribute-large'
export const equal_to_large = 'icon-sd-equal-to-large'
export const event_tracking_large = 'icon-sd-event-tracking-large'
export const events_large = 'icon-sd-events-large'
export const expand_large = 'icon-sd-expand-large'
export const eye_hide_large = 'icon-sd-eye-hide-large'
export const eye_large = 'icon-sd-eye-large'
export const field_selector_large = 'icon-sd-field-selector-large'
export const file_lock_large = 'icon-sd-file-lock-large'
export const file_tick_large = 'icon-sd-file-tick-large'
export const file_user_large = 'icon-sd-file-user-large'
export const filter_large = 'icon-sd-filter-large'
export const form_edit_large = 'icon-sd-form-edit-large'
export const form_generator_large = 'icon-sd-form-generator-large'
export const forms_large = 'icon-sd-forms-large'
export const fullscreen_collapse_large = 'icon-sd-fullscreen-collapse-large'
export const globe_large = 'icon-sd-globe-large'
export const goal_achieved_large = 'icon-sd-goal-achieved-large'
export const goal_summary_data_view_large = 'icon-sd-goal-summary-data-view-large'
export const goals_benchmark_large = 'icon-sd-goals-benchmark-large'
export const google_home_large = 'icon-sd-google-home-large'
export const greater_than_attribute_large = 'icon-sd-greater-than-attribute-large'
export const grid_large = 'icon-sd-grid-large'
export const heading_large = 'icon-sd-heading-large'
export const heart_fill_large = 'icon-sd-heart-fill-large'
export const html_editor_large = 'icon-sd-html-editor-large'
export const image_large_1 = 'icon-sd-image-large_1'
export const image_large = 'icon-sd-image-large'
export const import_audio_large = 'icon-sd-import-audio-large'
export const import_file_edge_large = 'icon-sd-import-file-edge-large'
export const import_file_large = 'icon-sd-import-file-large'
export const import_link_large = 'icon-sd-import-link-large'
export const in_between_large = 'icon-sd-in-between-large'
export const in_progress_large = 'icon-sd-in-progress-large'
export const invoices_large = 'icon-sd-invoices-large'
export const ios_large = 'icon-sd-ios-large'
export const ipad_large = 'icon-sd-ipad-large'
export const ipsettings_large = 'icon-sd-ipsettings-large'
export const ivr_large = 'icon-sd-ivr-large'
export const justify_dropdown_large = 'icon-sd-justify-dropdown-large'
export const key_fill_large = 'icon-sd-key-fill-large'
export const key_large = 'icon-sd-key-large'
export const landing_page_builder_large = 'icon-sd-landing-page-builder-large'
export const lesser_than_attribute_large = 'icon-sd-lesser-than-attribute-large'
export const license_agreement_large = 'icon-sd-license-agreement-large'
export const license_info_large = 'icon-sd-license-info-large'
export const lifetime_cap_large = 'icon-sd-lifetime-cap-large'
export const like_1_fill_large = 'icon-sd-like-1-fill-large'
export const like_2_fill_large = 'icon-sd-like-2-fill-large'
export const like_3_fill_large = 'icon-sd-like-3-fill-large'
export const like_4_fill_large = 'icon-sd-like-4-fill-large'
export const like_5_fill_large = 'icon-sd-like-5-fill-large'
export const link_large = 'icon-sd-link-large'
export const link_verification_large = 'icon-sd-link-verification-large'
export const list_attribute_large = 'icon-sd-list-attribute-large'
export const live_audience_dashboard_large = 'icon-sd-live-audience-dashboard-large'
export const lock_large = 'icon-sd-lock-large'
export const log_report_large = 'icon-sd-log-report-large'
export const map_large = 'icon-sd-map-large'
export const menu_dot_large = 'icon-sd-menu-dot-large'
export const messaging_large = 'icon-sd-messaging-large'
export const meta_large = 'icon-sd-meta-large'
export const minus_large = 'icon-sd-minus-large'
export const mms_large = 'icon-sd-mms-large'
export const mobile_analytics_large = 'icon-sd-mobile-analytics-large'
export const mobile_large = 'icon-sd-mobile-large'
export const mobile_notification_large = 'icon-sd-mobile-notification-large'
export const mobile_sms_large = 'icon-sd-mobile-sms-large'
export const mobile_sms_response_large = 'icon-sd-mobile-sms-response-large'
export const move_large = 'icon-sd-move-large'
export const multi_status_large = 'icon-sd-multi-status-large'
export const my_profile_large = 'icon-sd-my-profile-large'
export const not_equal_to_attribute_large = 'icon-sd-not-equal-to-attribute-large'
export const not_equal_to_large = 'icon-sd-not-equal-to-large'
export const notification_large = 'icon-sd-notification-large'
export const offline_conversion_large = 'icon-sd-offline-conversion-large'
export const ott_push_notifications_large = 'icon-sd-ott-push-notifications-large'
export const pause_large = 'icon-sd-pause-large'
export const payment_settings_large = 'icon-sd-payment-settings-large'
export const payment_settings = 'icon-sd-payment-settings'
export const paypal_large = 'icon-sd-paypal-large'
export const pdf_large = 'icon-sd-pdf-large'
export const pencil_edit_large = 'icon-sd-pencil-edit-large'
export const play_fill_large = 'icon-sd-play-fill-large'
export const play_large = 'icon-sd-play-large'
export const plus_large = 'icon-sd-plus-large'
export const ppt_download_large = 'icon-sd-ppt-download-large'
export const preview_large = 'icon-sd-preview-large'
export const publishers_network_large = 'icon-sd-publishers-network-large'
export const purchase_pattern_large = 'icon-sd-purchase-pattern-large'
export const push_amplification_large = 'icon-sd-push-amplification-large'
export const qrcode_large = 'icon-sd-qrcode-large'
export const question_mark_large = 'icon-sd-question-mark-large'
export const recycle_large = 'icon-sd-recycle-large'
export const redo_large = 'icon-sd-redo-large'
export const refresh_large = 'icon-sd-refresh-large'
export const report_large = 'icon-sd-report-large'
export const responsive_large = 'icon-sd-responsive-large'
export const restart_large = 'icon-sd-restart-large'
export const roi_large = 'icon-sd-roi-large'
export const save_large = 'icon-sd-save-large'
export const section_large = 'icon-sd-section-large'
export const sentiment_large = 'icon-sd-sentiment-large'
export const service_catalogue_large = 'icon-sd-service-catalogue-large'
export const settings_edge_large = 'icon-sd-settings-edge-large'
export const settings_help_large = 'icon-sd-settings-help-large'
export const settings_large = 'icon-sd-settings-large'
export const share_large = 'icon-sd-share-large'
export const share_publish_large = 'icon-sd-share-publish-large'
export const share_tick_large = 'icon-sd-share-tick-large'
export const shield_large = 'icon-sd-shield-large'
export const shield_tick_large = 'icon-sd-shield-tick-large'
export const shrink_large = 'icon-sd-shrink-large'
export const smart_link_large = 'icon-sd-smart-link-large'
export const smiliey_1_large = 'icon-sd-smiliey-1-large'
export const smiliey_2_large = 'icon-sd-smiliey-2-large'
export const smiliey_3_large = 'icon-sd-smiliey-3-large'
export const smiliey_4_large = 'icon-sd-smiliey-4-large'
export const smiliey_5_large = 'icon-sd-smiliey-5-large'
export const smtp_large = 'icon-sd-smtp-large'
export const snapshot_large = 'icon-sd-snapshot-large'
export const snippet_large = 'icon-sd-snippet-large'
export const social_facebook_ads_large = 'icon-sd-social-facebook-ads-large'
export const social_facebook_app_large = 'icon-sd-social-facebook-app-large'
export const social_facebook_large = 'icon-sd-social-facebook-large'
export const social_follow_large = 'icon-sd-social-follow-large'
export const social_google_ad_large = 'icon-sd-social-google-ad-large'
export const social_google_plus_large = 'icon-sd-social-google-plus-large'
export const social_instagram_large = 'icon-sd-social-instagram-large'
export const social_line_large = 'icon-sd-social-line-large'
export const social_linkedin_ads_large = 'icon-sd-social-linkedin-ads-large'
export const social_linkedin_large = 'icon-sd-social-linkedin-large'
export const social_media_large = 'icon-sd-social-media-large'
export const social_media_pixel_target_large = 'icon-sd-social-media-pixel-target-large'
export const social_pinterest_large = 'icon-sd-social-pinterest-large'
export const social_post_large = 'icon-sd-social-post-large'
export const social_share_large = 'icon-sd-social-share-large'
export const social_twitter_ads_large = 'icon-sd-social-twitter-ads-large'
export const social_twitter_large = 'icon-sd-social-twitter-large'
export const social_viber_large = 'icon-sd-social-viber-large'
export const social_vms_large = 'icon-sd-social-vms-large'
export const social_vuer_large = 'icon-sd-social-vuer-large'
export const social_whatsapp_large = 'icon-sd-social-whatsapp-large'
export const social_youtube_large = 'icon-sd-social-youtube-large'
export const solar_monitering_large = 'icon-sd-solar-monitering-large'
export const spacer_large = 'icon-sd-spacer-large'
export const spam_assassin_large = 'icon-sd-spam-assassin-large'
export const spanner_large = 'icon-sd-spanner-large'
export const square_minus_fill_large = 'icon-sd-square-minus-fill-large'
export const square_plus_fill_large = 'icon-sd-square-plus-fill-large'
export const star_fill_large = 'icon-sd-star-fill-large'
export const star_large = 'icon-sd-star-large'
export const stop_fill_large = 'icon-sd-stop-fill-large'
export const stop_large = 'icon-sd-stop-large'
export const style_editor_large = 'icon-sd-style-editor-large'
export const subscription_kyc_large = 'icon-sd-subscription-kyc-large'
export const survey_large = 'icon-sd-survey-large'
export const tag_large = 'icon-sd-tag-large'
export const tag_offer_large = 'icon-sd-tag-offer-large'
export const tag_offer_management_large = 'icon-sd-tag-offer-management-large'
export const tell_a_friend_large = 'icon-sd-tell-a-friend-large'
export const template_builder_large = 'icon-sd-template-builder-large'
export const template_edge_large = 'icon-sd-template-edge-large'
export const template_generator_large = 'icon-sd-template-generator-large'
export const template_large = 'icon-sd-template-large'
export const text_document_edge_large = 'icon-sd-text-document-edge-large'
export const text_document_large = 'icon-sd-text-document-large'
export const thumbs_down_fill_large = 'icon-sd-thumbs-down-fill-large'
export const thumbs_down_large = 'icon-sd-thumbs-down-large'
export const thumbs_left_fill_large = 'icon-sd-thumbs-left-fill-large'
export const thumbs_right_fill_large = 'icon-sd-thumbs-right-fill-large'
export const thumbs_straight_fill_large = 'icon-sd-thumbs-straight-fill-large'
export const thumbs_up_fill_large = 'icon-sd-thumbs-up-fill-large'
export const thumbs_up_large = 'icon-sd-thumbs-up-large'
export const tick_large = 'icon-sd-tick-large'
export const time_large = 'icon-sd-time-large'
export const timer_large = 'icon-sd-timer-large'
export const undo_large = 'icon-sd-undo-large'
export const url_large = 'icon-sd-url-large'
export const user_360_large = 'icon-sd-user-360-large'
export const user_admin_settings_large = 'icon-sd-user-admin-settings-large'
export const user_anatytics_large = 'icon-sd-user-anatytics-large'
export const user_arrow_down_large = 'icon-sd-user-arrow-down-large'
export const user_arrow_large = 'icon-sd-user-arrow-large'
export const user_call_center_large = 'icon-sd-user-call-center-large'
export const user_card_large = 'icon-sd-user-card-large'
export const user_cgtg_large = 'icon-sd-user-cgtg-large'
export const user_form_large = 'icon-sd-user-form-large'
export const user_gdpr_large = 'icon-sd-user-gdpr-large'
export const user_laddering_large = 'icon-sd-user-laddering-large'
export const user_large = 'icon-sd-user-large'
export const user_leadscore_large = 'icon-sd-user-leadscore-large'
export const user_list_large = 'icon-sd-user-list-large'
export const user_lock_large = 'icon-sd-user-lock-large'
export const user_network_large = 'icon-sd-user-network-large'
export const user_overview_large = 'icon-sd-user-overview-large'
export const user_question_mark_edge_large = 'icon-sd-user-question-mark-edge-large'
export const user_question_mark_large = 'icon-sd-user-question-mark-large'
export const user_settings_large = 'icon-sd-user-settings-large'
export const user_star_large = 'icon-sd-user-star-large'
export const user_tick_large = 'icon-sd-user-tick-large'
export const users_large = 'icon-sd-users-large'
export const users_persona_large = 'icon-sd-users-persona-large'
export const video_large = 'icon-sd-video-large'
export const voice_assistant_large = 'icon-sd-voice-assistant-large'
export const web_analytics_large = 'icon-sd-web-analytics-large'
export const web_browser_large = 'icon-sd-web-browser-large'
export const web_meta_data_large = 'icon-sd-web-meta-data-large'
export const web_notification_large = 'icon-sd-web-notification-large'
export const web_request_large = 'icon-sd-web-request-large'
export const web_settings_large = 'icon-sd-web-settings-large'
export const webinar_large = 'icon-sd-webinar-large'
export const zip_large = 'icon-sd-zip-large'
export const zoom_analytics_large = 'icon-sd-zoom-analytics-large'
export const zoom_eye_large = 'icon-sd-zoom-eye-large'
export const zoom_large = 'icon-sd-zoom-large'
export const zoom_minus_large = 'icon-sd-zoom-minus-large'
export const zoom_plus_large = 'icon-sd-zoom-plus-large'
export const zoom_user_large = 'icon-sd-zoom-user-large'

export const zoom_link_verification_large = 'icon-sd-zoom-link-verification-large'
export const gif_large = 'icon-sd-gif-large'
export const industry_healthcare_large = 'icon-sd-industry-healthcare-large'
export const trend_report_large = 'icon-sd-trend-report-large'
export const circle_minus_large = 'icon-sd-circle-minus-large'
export const filter_fill_large = 'icon-sd-filter-fill-large'
export const industry_automobile_large = 'icon-sd-industry-automobile-large'
export const industry_b2b_large = 'icon-sd-industry-b2b-large'
export const industry_banking_large = 'icon-sd-industry-banking-large'
export const industry_consumer_electronics_large = 'icon-sd-industry-consumer-electronics-large'
export const industry_education_large = 'icon-sd-industry-education-large'
export const industry_entertainment_large = 'icon-sd-industry-entertainment-large'
export const industry_hospitality_large = 'icon-sd-industry-hospitality-large'
export const industry_insurance_large = 'icon-sd-industry-insurance-large'
export const industry_mutual_fund_large = 'icon-sd-industry-mutual-fund-large'
export const industry_real_estate_large = 'icon-sd-industry-real-estate-large'
export const industry_retail_large = 'icon-sd-industry-retail-large'
export const industry_telco_large = 'icon-sd-industry-telco-large'
export const industry_travel_large = 'icon-sd-industry-travel-large'
export const printer_large = 'icon-sd-printer-large'
export const retarget_list_large = 'icon-sd-retarget-list-large'
export const user_goals_benchmark_large = 'icon-sd-user-goals-benchmark-large'