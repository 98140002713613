import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSTextInputWithIcon } from "Components/RSInputs";
import React, { Component } from "react";
import {
  MAX_FIRST_NAME
} from 'Helper/Constants/inputLimit'

class CustomFilter extends React.Component {
  state = {
    operator: "eq",
    filterValue: filterOptions[0],
  };
  onChange = (event) => {
    const value = event;
    const { firstFilterProps } = this.props;
    this.setState({filterValue:event.target.value})
    firstFilterProps.onChange({
      value,
      operator: "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };
  render() {
    const { firstFilterProps } = this.props;
    const value = firstFilterProps.value;
    return (
      <div>
        <RSDropdownList
          data={filterOptions}
          value={this.state.filterValue}
          onChange={(value) => this.onChange(value)}
        />
        <RSTextInputWithIcon
          className="marginB10 mt10"
          name="firstname"
          max={MAX_FIRST_NAME}
          cb={(value) => this.onChange(value)}
        />
      </div>
    );
  }
}

export default CustomFilter;
const filterOptions = ["Starts with", "Is equal to", "Is not equal to"];
