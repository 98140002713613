import React, { Component } from "react";
import { Modal, Row, Col, CloseButton } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";

class AddAudienceModal extends Component {
  state = {
    isModalOpen: this.props.isOpen,
  };
  handleClose = () => {
    this.props.onChangeIsOpen(false);
    this.setState({ isModalOpen: false });
  };
  render() {
    return (
      <Modal
        className="dynamic-list-fill"
        show={this.state.isModalOpen}
        onHide={() => this.handleClose()}
        size="xs"
        centered
      >
        <Modal.Header>
          <h2>Add audience</h2>
          <CloseButton
            className="icon-sd-close-mini"
            onClick={() => {
              this.handleClose();
            }}
          />
        </Modal.Header>
        <Modal.Body className="p10">
          <Row className="text-center mb10 mt10">
            <span>
              <i className="icon-time-mini icons-xl"></i>
            </span>
          </Row>
          <Row className="text-center mb10">
            <h3
              style={{
                color: "#333333",
              }}
            >
              Vision Bank USA - Personal
            </h3>
          </Row>
          <Row className="text-center">
            <p className="font-sm p0">
              Import is in progress. Click "Return to the{" "}
              {this.props.mode === "csv" || this.props.mode === "manualupload"
                ? "master list"
                : "data exchange"}
              " to safely navigate to other pages.
            </p>
          </Row>
          <Row>
            <Col className="text-right">
              <RSSecondaryBtn
                className="fs18"
                onClick={(e) => {
                  this.props.history.push({
                    pathname: "/audience/add-audience",
                    state: { audienceType: "rds" },
                  });
                }}
              >
                Import again
              </RSSecondaryBtn>
              <RSPrimaryBtn
                className="fs18 px20 py9"
                onClick={(e) => {
                  if (
                    this.props.mode === "csv" ||
                    this.props.mode === "manualupload"
                  ) {
                    this.props.history.push({
                      pathname: "/audience",
                      state: { index: 0 },
                    });
                  } else {
                    this.props.history.push({
                      pathname: "/preferences/data-exchange",
                    });
                  }
                }}
              >
                Return to the{" "}
                {this.props.mode === "csv" || this.props.mode === "manualupload"
                  ? "master list"
                  : "data exchange"}
              </RSPrimaryBtn>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
export default AddAudienceModal;
