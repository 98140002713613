import React, { useEffect, useState } from 'react';
import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { HorizontalSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import { ch_orange } from 'Components/Charts/ChartColors';


function WhatsAppEngagementChart(props) {

    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])


    return (<div className={`portlet-container portlet-md portlet-footer-label`}>
        <div className="portlet-header">
            <h4>Engagement</h4>
            <div className="p-nav-right">
                <RTabbar
                    defaultSelectedItem={tabIndex}
                    defaultClass="tabDefault"
                    dynamicTab="mini marginB0"
                    activeClass="tabDefault active"
                    tabData={tabDataEngagement}
                    callBack={(item, index) => setTabIndex(index)}
                />
            </div>
        </div>
        <div className="portlet-body">
            {
                tabIndex === 0 ?
                    <div className="portlet-chart">
                        {data?.engagementPerformanceDTOs?.categories !== null && data?.engagementPerformanceDTOs?.series !== null ? <NewCharts options={engagementOverallChart(data)} />
                            : <><NoData /><HorizontalSkeleton /></>
                        }
                    </div> :
                    <div className="portlet-chart">
                        {data?.engagementPerformanceHrsDTOs?.categories !== null && data?.engagementPerformanceHrsDTOs?.series !== null ? <NewCharts options={engagementFirstHrChart(data)} />
                            : <><NoData /><HorizontalSkeleton /></>
                        }
                    </div>
            }
        </div>
        {tabIndex === 0 ?
            <div className={`${data?.engagementPerformanceDTOs?.categories !== null && data?.engagementPerformanceDTOs?.series !== null ? 'portlet-two-label' : ''}`}>
                {data?.engagementPerformanceDTOs?.categories !== null && data?.engagementPerformanceDTOs?.series !== null ?
                    <ul>
                        {/* <li><span>{data?.reach?.delivered || 0}</span><small>%</small></li>
                        <li>total reach with {data?.engagement?.clicks || 0}% engagement happened during this period</li> */}
                        <li><span>{data?.engagement?.clicks || 0}</span><small>%</small></li>
                        <li>Engagement happened during this period</li>
                    </ul> : ''
                }
            </div>
            :
            <div className={`${data?.engagementPerformanceHrsDTOs?.categories !== null && data?.engagementPerformanceHrsDTOs?.series !== null ? 'portlet-two-label' : ''}`}>
                {data?.engagementPerformanceHrsDTOs?.categories !== null && data?.engagementPerformanceHrsDTOs?.series !== null ?
                    <ul>
                        {/* <li><span>{data?.reach?.delivered || 0}</span><small>%</small></li>
                        <li>total reach with {data?.engagement?.clicks || 0}% engagement happened during this period</li> */}
                        <li><span>{data?.engagement?.clicks || 0}</span><small>%</small></li>
                        <li>Engagement happened during this period</li>
                    </ul> : ''
                }
            </div>
        }
    </div>);
}

export default WhatsAppEngagementChart;

const tabDataEngagement = [
    { "id": 1001, "text": "Overall" },
    { "id": 1002, "text": "First 24 hrs" }
]

// Engagement Overall -- area
const engagementOverallChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Date'
            },
            // categories: data?.engagementPerformanceDTOs?.map(item => {
            //     let newDate = new Date(item?.date).toDateString()
            //     let splitDate = newDate.split(" ")
            //     return splitDate[2] + " " + splitDate[1]
            // }),
            categories: data?.engagementPerformanceDTOs?.categories?.map(item => {
                let newDate = new Date(item).toDateString()
                let splitDate = newDate.split(" ")
                return splitDate[2] + " " + splitDate[1]
            }),
            labels: {
                rotation: 0
            },
            // tickInterval: 1
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 20
        },
        symbolRadius: 0,
        plotOptions: {
            series: {
                marker: {
                    fillColor: null,
                    lineColor: null,
                    lineWidth: 1,
                    radius: 3,
                    symbol: 'circle',
                }
            },
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: '#fff', radius: 3, lineWidth: 2 },
                name: data?.engagementPerformanceDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                data: data?.engagementPerformanceDTOs?.series[0]?.datas?.map(item => item || 0),
                zoneAxis: 'x',
                zones: [{ value: 6 }, { dashStyle: 'dash' }],
            }
        ],
    }

}

// Engagement First 24 hr -- area
const engagementFirstHrChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Hours'
            },
            // categories: data?.engagementPerformanceHrsDTOs?.map(item => `${item?.time} hr` || 0),
            categories: data?.engagementPerformanceHrsDTOs?.categories?.map(item => `${item} hr` || 0),
            labels: {
                rotation: 0
            },
            // tickInterval: 1
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 20
        },
        symbolRadius: 0,
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { lineColor: ch_orange, fillColor: 'white', },
                name: data?.engagementPerformanceHrsDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                data: data?.engagementPerformanceHrsDTOs?.series[0]?.datas?.map(item => item || 0),
                zoneAxis: 'x',
                zones: [{ value: 6 }, { dashStyle: 'dash' }]
            }
        ],
    }

}