import React from "react";
import { Modal, Row } from "react-bootstrap";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { RSPrimaryBtn } from "Components/RSButtons";
const Tags = [
  "Flipkart Best Buying Days",
  "Amazon great shopping festival",
  "Gold bonds",
];

class TagsComponent extends React.Component {
  state = {
    isModalOpen: this.props.isOpen,
    tagsValue: Tags,
    keyword: "",
  };
  render() {
    return (
      <Modal backdrop="static"
        keyboard={false} show={this.state.isModalOpen} onHide={() => {
          this.setState({ isModalOpen: !this.state.isModalOpen });
          this.props.onChangeIsOpen(false);
        }}>
        <Modal.Header closeButton>
          <h2>Tags</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="chips-container com-set">
            <div className="chips-box">
              <MultiSelect
                data={this.state.tagsValue}
                onChange={(event) => {
                  this.setState({ tagsValue: event.target.value });
                }}
                value={this.state.tagsValue}
                allowCustom={true}
              />
              <input
                type="textarea"
                name="textValue"
                placeholder="Enter keywords"
                onChange={(event) => {
                  this.setState({ keyword: event.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({
                      tagsValue: [...this.state.tagsValue, this.state.keyword],
                    });
                  }
                }}
              />
            </div>
          </div>
          <Row>
            <small>Enter keywords with comma separator</small>
          </Row>
          <Row className="float-end">
            <small>Up to 5 keywords, 75 characters limit</small>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <RSPrimaryBtn
            onClick={() => {
              this.setState({ isModalOpen: !this.state.isModalOpen });
              this.props.onChangeIsOpen(false);
            }}
          >
            OK
          </RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default TagsComponent;
