import React from 'react';
import { Container } from 'react-bootstrap';
import ProgressSteps from 'Components/ProgressSteps/ProgressSteps';
import { RSPTooltip } from "Components/Tooltip";
import { dateFormat } from 'Helper/Utils/Utils';

const CampaignHeader = (props) => {
    return (
        <>
            {props.progressSteps !== null && <ProgressSteps progressSteps={props.progressSteps} />}

            <div className="progress-steps-box theme-space-mt">
                <RSPTooltip text={props.header.name} position="top">
                    <div className="steps-label">
                        <small className="mr5">Name:</small>
                        <label className="label-row label-dark">{props.header.name || ""}</label>
                    </div>
                </RSPTooltip>
                <RSPTooltip text={props.header.startDate
                    ? `${dateFormat(props.header.startDate)} - ${dateFormat(props.header.endDate)}`
                    : ""} position="top">
                    <div className="steps-label">
                        <small className="mr5">Period:</small>
                        <label className="label-row label-dark">{props.header.startDate
                            ? `${dateFormat(props.header.startDate)} - ${dateFormat(props.header.endDate)}`
                            : ""}</label>
                    </div>
                </RSPTooltip>
                <RSPTooltip text={props.header.type || ""} position="top">
                    <div className="steps-label">
                        <small className="mr5">Type:</small>
                        <label className="label-row label-dark">{props.header.type || ""}</label>
                    </div>
                </RSPTooltip>
                {props.header.goal !== "" &&
                    <RSPTooltip text={props.header.goal || ""} position="top">
                        <div className="steps-label">
                            <small className="mr5">Primary communication goal:</small>
                            <label className="label-row label-dark">{props.header.goal || ""}</label>
                        </div>
                    </RSPTooltip>
                }
                <div className="steps-label-row-icon d-flex align-items-center justify-content-end">
                    {props.type === "editplan" ?
                        <RSPTooltip text={props.editTooltipText} position="top">
                            <i className={`icon-sd-form-edit-large icons-lg white large-icon cp ${props.type === "editplan" ? "bgBlue" : "bgGreen"}`} onClick={() => {
                                props.buttonClicked(1)
                            }} />
                        </RSPTooltip>
                        : null}

                    {props.editsmart === true ?
                        <RSPTooltip text="Smartlink" position="top">
                            <i className={`icon-smart-link-medium icons-lg white large-icon bgGreen cp ${props.isSmartlinkCreate === true ? "bgGreen" : "bgGrey"}`} onClick={() => {
                                props.buttonClicked(2)
                            }} />
                        </RSPTooltip>
                        : null}
                </div>
            </div>
        </>
    )
}
export default CampaignHeader;