import React, { useEffect, useState } from 'react';
import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { HorizontalSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import { ch_orange } from 'Components/Charts/ChartColors';

function WhatsAppReachChart(props) {

    const [expandEnable, setExpandEnable] = useState(false);
    const [tabReachIndex, setTabReachIndex] = useState(0);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const isEnableExpandIcon = () => {
        let isEnable = true;
        if (tabReachIndex === 0) {
            if (data?.reachPerformanceDTOs !== null && data?.reachPerformanceDTOs.categories !== null && data?.reachPerformanceDTOs.series !== null) {
                isEnable = true;
            } else {
                isEnable = false;
            }
        } else {
            if (data?.reachPerformanceHrsDTOs !== null && data?.reachPerformanceHrsDTOs.categories !== null && data?.reachPerformanceHrsDTOs.series !== null) {
                isEnable = true;
            } else {
                isEnable = false;
            }
        }
        return isEnable;
    }
    return (
        <div className={`portlet-container portlet-md ${expandEnable ? "expand-full-view" : ""}`}>
            <div className="portlet-header">
                <h4>Reach</h4>
                <div className="p-nav-right">
                    <RTabbar
                        defaultClass="tabDefault"
                        dynamicTab="mini marginB0"
                        activeClass="tabDefault active"
                        defaultSelectedItem={tabReachIndex}
                        tabData={tabDataReach}
                        callBack={(item, index) => setTabReachIndex(index)}
                    />
                </div>
            </div>
            <div className="portlet-body">
                {
                    tabReachIndex === 0 ?
                        <div className="portlet-chart">
                            {
                                data?.reachPerformanceDTOs
                                    !== null
                                    && data?.reachPerformanceDTOs?.categories
                                    !== null
                                    && data?.reachPerformanceDTOs?.series
                                    !== null
                                    ? <>
                                        <NewCharts options={reachOverallChart(data)} />
                                        <div className="expand-icon-wrap" onClick={() => {
                                            setExpandEnable(!expandEnable)
                                        }}>
                                            <i className={`${expandEnable ? "icon-sd-arrow-left-mini" : "icon-forward-small"} white`}></i>
                                        </div>
                                    </>
                                    : <>
                                        <NoData />
                                        <HorizontalSkeleton />
                                    </>
                            }
                        </div> :
                        <div className="portlet-chart">
                            {
                                data?.reachPerformanceHrsDTOs
                                    !== null
                                    && data?.reachPerformanceHrsDTOs?.categories
                                    !== null
                                    && data?.reachPerformanceHrsDTOs?.series
                                    !== null
                                    ? <>
                                        <NewCharts options={reachFirstHrChart(data)} />
                                        <div className="expand-icon-wrap" onClick={() => {
                                            setExpandEnable(!expandEnable);
                                        }}>
                                            <i className={`${expandEnable ? "icon-sd-arrow-left-mini" : "icon-forward-small"} white`}></i>
                                        </div>
                                    </>
                                    : <>
                                        <NoData />
                                        <HorizontalSkeleton />
                                    </>
                            }
                        </div>
                }
            </div>
            {isEnableExpandIcon() &&
                <div className="expand-icon-wrap" onClick={() => {
                    setExpandEnable(!expandEnable)
                }}>
                    <i className={`${expandEnable ? "icon-sd-arrow-left-mini" : "icon-sd-arrow-right-mini"} white`}></i>
                </div>}
        </div>
    );
}

export default WhatsAppReachChart;

const tabDataReach = [
    { "id": 1001, "text": "Overall" },
    { "id": 1002, "text": "First 24 hrs" }
]

// Reach Overall -- area
const reachOverallChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Date'
            },
            // categories: data?.reachPerformanceDTOs?.map(item => {
            //     let newDate = new Date(item?.date).toDateString()
            //     let splitDate = newDate.split(" ")
            //     return splitDate[2] + " " + splitDate[1]
            // }),
            categories: data?.reachPerformanceDTOs?.categories?.map(item => {
                let newDate = new Date(item).toDateString()
                let splitDate = newDate.split(" ")
                return splitDate[2] + " " + splitDate[1]
            }),
            labels: {
                rotation: 0
            },
            // tickInterval: 3
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 8
        },
        symbolRadius: 0,
        plotOptions: {
            series: {
                marker: {
                    fillColor: null,
                    lineColor: null,
                    lineWidth: 1,
                    radius: 3,
                    symbol: 'circle',
                }
            },
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: '#fff', radius: 3, lineColor: ch_orange, lineWidth: 2 },
                name: data?.reachPerformanceDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                // data: data?.reachPerformanceDTOs?.map(item => {
                //     return item?.count || 0
                // })
                data: data?.reachPerformanceDTOs?.series[0]?.datas?.map(item => item || 0),
                legendIndex: 0
            }
        ],
    }

}

// Reach First 24 hr -- area
const reachFirstHrChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Hours'
            },
            // categories: data?.reachPerformanceHrsDTOs?.map(item => `${item?.time} hr` || 0),
            categories: data?.reachPerformanceHrsDTOs?.categories?.map(item => `${item} hr` || 0),
            labels: {
                rotation: 0
            },
            // tickInterval: 3
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 25
        },
        symbolRadius: 0,
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { lineColor: ch_orange, fillColor: 'white', },
                name: data?.reachPerformanceHrsDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                // data: data?.reachPerformanceHrsDTOs?.map(item => item.count || 0)
                data: data?.reachPerformanceHrsDTOs?.series[0]?.datas?.map(item => item || 0)
            }
        ],
    }

}
