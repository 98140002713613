import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RTabbar } from 'Components/RChart/RTabbar';
import MobileSMSCreation from './MobileSMSCreation';
import MobileWhatsappCreation from './MobileWhatsappCreation';

class MobileCreation extends Component {

    state = {
        index: 0,
        tabData: [],
        channelId: null
    }

    componentDidMount = () => {
        this.updateChannels();
    }

    componentDidUpdate = (prevprops) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevprops)) {
            this.updateChannels();
        }
    }

    updateChannels = () => {
        let channelTypes = []
        this.props.channelTypes.map((item) => {
            if (item.toLowerCase() === 'sms') {
                channelTypes.push({ text: 'SMS', iconLeft: 'icon-sd-mobile-sms-large icons-lg', channelId: 2 })
            } else if (item.toLowerCase() === 'whatsapp') {
                channelTypes.push({ text: 'WhatsApp', iconLeft: 'icon-sd-social-whatsapp-large icons-lg', channelId: 21 })
            }
        })
        if (channelTypes.length > 1) {
            channelTypes.sort((a, b) => {
                let firstOne = a.text.toLowerCase(),
                    secondOne = b.text.toLowerCase();
                if (firstOne < secondOne) {
                    return -1;
                }
                if (firstOne > secondOne) {
                    return 1;
                }
                return 0;
            });
        }
        let index = this.props?.channelId === 21 ? channelTypes.length - 1 : 0
        let tempChannelId = 0;
        if (this.props.channelId === 2 || this.props?.channelId === 21) {
            tempChannelId = this.props?.channelId
        } else {
            tempChannelId = channelTypes[index].channelId
        }
        this.setState({ tabData: channelTypes, channelId: tempChannelId || null, index: index });
    }

    RenderComponents = channelId => {
        const some = {
            2: <MobileSMSCreation
                mobileSMSData={this.props.mobileSMSData}
                campaignType={this.props.campaignType}
                campaignId={this.props.campaignId}
                isUpdate={this.props.isUpdate}
                mode={this.props.mode}
                status={this.props.status}
                campaignHeader={this.props?.campaignHeader}
                moveNext={(value) => {
                    if (this.state.tabData.length > 1 && this.state.index !== value) {
                        this.setState({ index: value, channelId: null })
                    } else if (this.props.campaignType === "EventTrigger") {
                        this.props.history.push("/communication/completion");
                    } else {
                        this.props.moveNext(value);
                    }
                }}
            />,
            21: <MobileWhatsappCreation
                campaignType={this.props.campaignType}
                campaignId={this.props.campaignId}
                data={this.props.mobileWhatsAppData}
                mode={this.props.mode}
                status={this.props.status}
                isUpdate={this.props.isUpdate}
                campaignHeader={this.props?.campaignHeader}
                moveNext={(value) => {
                    this.props.moveNext(value);
                }} />
        }
        return some[channelId]
    }

    render() {
        return (
            <>
                <div className="horizontal-tab-wrapper">
                    <RTabbar
                        defaultSelectedItem={this.state.index}
                        defaultClass="tabDefault"
                        dynamicTab="rs-tab-icon rs-tab-horizontal"
                        activeClass="tabDefault active"
                        tabData={this.state.tabData}
                        callBack={(item, index) => {
                            this.setState({ index: index, channelId: null })
                        }}
                    />
                </div>
                <div className="clearfix"></div>
                {this.state?.channelId
                    ? this.RenderComponents(this.state?.channelId)
                    : this.state?.tabData[this.state.index]?.channelId
                        ? this.RenderComponents(this.state?.tabData[this.state.index]?.channelId)
                        : null}
            </>
        )
    }

}
export default withRouter(MobileCreation);