import { useState, useEffect, useContext } from "react";

import {
  COPY_FORM,
  DELETE_FORM,
  FORM_DOWNLOAD_DATA,
  FORM_PUBLISH,
  GET_FORMBY_ID,
  INSERT_PUBLISH,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import CopyForm from "./CopyForm";
import { getServerConfig } from "Helper/Utils/Utils";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import PublishModal from "./PublishModal";
import { RSPTooltip } from "Components/Tooltip";
import FormCSVDownloadModal from "./FormCSVDownloadModal";

const CustomCell = (props) => {
  const context = useContext(authContext);
  const [copyFormFlag, setCopyFormFlag] = useState(false);
  const [formName, setFormName] = useState("");
  const [formId, setFormId] = useState(null);
  const [formNameErr, setFormNameErr] = useState(null);
  const [deleteForm, setDeleteForm] = useState(false);
  const [deleteFormData, setDeleteFormData] = useState("");
  const [publishFlag, setPublishFlag] = useState(false);
  const [publishData, setIsPublishData] = useState("");
  const [formFields, setFormFields] = useState(null);
  const [isOpenCSVModal, setISOpenCSVModal] = useState(false);

  const handleCancel = () => {
    setCopyFormFlag(false);
    setFormName("");
    setFormNameErr(null);
    setDeleteForm(false);
    setFormId(null);
  };

  const handlePublish = (ele) => {
    let params = {
      formId: ele,
    };

    connectServer({
      path: FORM_PUBLISH,
      params,
      // loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          setIsPublishData(res.data);
          setPublishFlag(true);
        }
      },
      fail: (err) => { },
    });
  };

  const handleSaveCopy = () => {
    if (formNameErr === null) {
      let params = {
        formId: formId,
        formName: formName,
      };
      connectServer({
        path: COPY_FORM,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setCopyFormFlag(false);
            setFormName("");
            setFormId(null);
            props.getFormListAllData();
          } else {
            setFormNameErr("Form name already exists");
          }
        },
        fail: (err) => { },
      });
    }
  };

  const handleEdit = (args) => {
    let baseUrl = getServerConfig();
    const sessionObject = JSON.parse(localStorage.getItem("session"));
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    let fromEnvi = window.location.origin.includes("app")
      ? "app"
      : window.location.origin.includes("sit")
        ? "sit"
        : "run";
    window.location.href = `${baseUrl}${GET_FORMBY_ID}?formId=${encodeURIComponent(
      args.dataItem.formId
    )}&accessToken=${encodeURIComponent(accessToken)}&from=${fromEnvi}`;
  };

  return (
    <>
      <td>
        <div className="camp-icon">
          <ul className="camp-icon-pannel">
            <li>
              <RSPTooltip text="Edit" className={"float-end"} position="top">
                <i className={`icon-sd-pencil-edit-medium icons-md blue`}
                  onClick={() => {
                    handleEdit(props);
                  }} />
              </RSPTooltip>
            </li>

            <li>
              <RSPTooltip text="CSV" className={"float-end"} position="top">
                <i className={`icon-sd-csv-medium icons-md blue`}
                  onClick={() => {
                    setISOpenCSVModal(true);
                  }} />
              </RSPTooltip>
            </li>

            <li>
              <RSPTooltip text="Duplicate" className={"float-end"} position="top">
                <i className={`icon-sd-duplicate-medium icons-md blue`} onClick={() => {
                  setCopyFormFlag(true);
                  setFormName(props.dataItem.formName);
                  setFormId(props.dataItem.formId);
                }} />
              </RSPTooltip>
            </li>
            <li>
              <RSPTooltip text="Publish" className={"float-end"} position="top">
                <i className={`icon-sd-share-publish-mini icons-md blue`} onClick={() => {
                  handlePublish(props.dataItem.formId);
                }} />
              </RSPTooltip>
            </li>
            <li>
              <RSPTooltip text="Delete" className={"float-end"} position="top">
                <i className={`icon-sd-delete-medium icons-md blue`} onClick={() => {
                  setDeleteForm(true);
                  setDeleteFormData(props.dataItem.formId);
                }} />
              </RSPTooltip>
            </li>
            {/* <li>
              <i className="icon-csv-download-medium icons-md blue"></i>
            </li> */}
          </ul>
        </div>
      </td >
      {copyFormFlag && (
        <CopyForm
          data={{
            copyFormFlag,
            setCopyFormFlag,
            formName,
            setFormNameErr,
            formNameErr,
            handleCancel,
            handleSaveCopy,
            setFormName,
          }}
        />
      )
      }
      {
        deleteForm && (
          <RSConfirmAlert
            title="Are you sure you want to delete?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                let params = {
                  formId: deleteFormData,
                };
                connectServer({
                  path: DELETE_FORM,
                  params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: (res) => {
                    if (res.status) {
                      setDeleteForm(false);
                      props.getFormListAllData();
                    } else {
                      alert("something went wrong");
                    }
                  },
                  fail: (err) => { },
                });
              } else {
                setDeleteForm(false);
              }
            }}
          />
        )
      }
      <PublishModal
        isShow={publishFlag}
        setIsShow={setPublishFlag}
        data={publishData}
      />
      {isOpenCSVModal &&
        (<FormCSVDownloadModal
          isOpen={isOpenCSVModal}
          onChangeModalOpen={(value) => setISOpenCSVModal(value)}
          formId={props.dataItem.formId}
          createdDate={props.dataItem.createdDate}
        />)
      }
    </>
  );
};

export default CustomCell;
