import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSCheckbox } from "Components/RSInputs";
import {
  REASON_MESSAGE_UNIQUE,
  ENTER_REASON_TYPE,
} from "Helper/Constants/validationMessage";

class ReasonDynamic extends Component {

  state = {
    isModalOpen: this.props.isOpen,
    reasonTypeArray: this.props.reasonCategory,
    isEnable: this.props.isEnabled,
    messageUnique: null,
  };

  handleClose = () => {
    this.props.onChangeIsOpen(false);
    this.setState({ isModalOpen: false });
  };

  onTrigger = () => {

    // if (!this.state.reasonTypeArray.length) {
    //   this.setState({ messageUnique: ENTER_REASON_TYPE });
    //   return false;
    // } else {
    //   const hasDuplicates = (arr) => {
    //     return new Set(arr).size !== arr.length;
    //   };
    //   if (hasDuplicates(this.state.reasonTypeArray)) {
    //     this.setState({ messageUnique: REASON_MESSAGE_UNIQUE });
    //     return false;
    //   } else {
    //     this.setState({ messageUnique: null });
    //     this.props.onChangeIsOpen(false);
    //     this.props.reasonType(this.state.reasonTypeArray);
    //     this.props.enableOthers(this.state.isEnable);
    //   }
    // }
    this.props.onChangeIsOpen(false);
    this.props.reasonType(this.state.reasonTypeArray);
    this.props.enableOthers(this.state.isEnable);
  };

  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        show={this.state.isModalOpen}
        onHide={() => this.handleClose()}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h3>Reason type</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div
                className="chips-box css-scrollbar padding10"
                style={{ width: "760px", height: "115px" }}
              >
                {this.state.messageUnique !== null && (
                  <div className="validation-message">
                    {this.state.messageUnique}
                  </div>
                )}
                <MultiSelect
                  placeholder="Enter reason type"
                  data={this.state.reasonTypeArray}
                  onChange={(event) => {
                    let eachWordLength = event.target.value.map((word) =>
                      word.length > 75 ? "false" : "true"
                    );
                    if (!eachWordLength.includes("false")) {
                      if (this.state.reasonTypeArray.length <= 4) {
                        this.setState({ reasonTypeArray: event.target.value });
                      } else if (event.target.value.length <= 4) {
                        this.setState({ reasonTypeArray: event.target.value });
                      }
                    }

                    const hasDuplicates = (arr) => {
                      return new Set(arr).size !== arr.length;
                    };
                    if (!hasDuplicates(this.state.reasonTypeArray)) {
                      this.setState({ messageUnique: null });
                    }
                  }}
                  value={this.state.reasonTypeArray}
                  allowCustom={true}
                />
              </div>
              <small style={{ "font-style": "italic" }}>
                Enter values with comma separator
              </small>
              <small style={{ float: "right" }}>
                Up to 5 reason types, 75 characters per reason type
              </small>
            </Col>
          </Row>
          <Row className="mt30">
            <div style={{ bottom: "-120px" }}>
              <RSCheckbox
                className="mb0 fs14"
                lbl={"Enable others"}
                checked={this.state.isEnable}
                cb={(status) => {
                  this.setState({ isEnable: status });
                }}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <RSSecondaryBtn
            onClick={() => {
              this.handleClose();
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn onClick={this.onTrigger}>OK</RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReasonDynamic;
