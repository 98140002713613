import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import {
    useHistory
} from "react-router-dom";
import { RTabbar } from 'Components/RChart/RTabbar';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import CampaignListing from './Tabs/CampaignListing/CampaignListing';
import Gallery from './Tabs/Gallery/Gallery';
import authContext from 'Helper/StateHandler/auth-context';

const CampaignListGallery = () => {

    const history = useHistory()

    const [index, setIndex] = useState(0)
    const context = useContext(authContext)

    function renderComponent(index) {
        var renderComponent;
        if (index === 0) { renderComponent = <CampaignListing history={history} context={context} /> }
        else if (index === 1) { renderComponent = <Gallery updateIndex={index => setIndex(index)} context={context} /> }
        return renderComponent
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="d-flex justify-content-between mt15">
                    <h1 className="header-title-margin">Communication</h1>
                </div>

                <div className="tabs-filled-component-del">
                    <RTabbar
                        defaultSelectedItem={index}
                        defaultClass="tabDefault"
                        dynamicTab="tabs-filled-component marginB0"
                        activeClass="tabDefault active"
                        tabData={tabData}
                        callBack={(item, index) => { setIndex(index) }}
                    />
                </div>
                {renderComponent(index)}

            </Container>
        </LayoutPageWrapper>
    )
}
export default CampaignListGallery;

const tabData = [{ "id": 1001, "text": "List" }, { "id": 1002, "text": "Gallery" }]