import React from 'react';
import { CustomCode } from '../Components/Common'

export const BootstrapDropdown = () => {
    return(
        <>
            <CustomCode title="Page header" c1='Login page wrapper' c2="login" value={codeLoginPageWrapper}></CustomCode>
            <CustomCode title="Login page" c1="login" c2="use method" value={codeLoginPage}></CustomCode>
            <CustomCode title="Page wrapper" value={codePageWrapper}></CustomCode>
            <CustomCode title="Page top spacing" c1='default' c2={`className="page-header"`} value={codePageHeaderWrapper}></CustomCode>
        </>
    )
};



let codeLoginPageWrapper = `import { LoginWrapper } from "./Components/Login.styled";

<LoginWrapper>
    //{...}
</LoginWrapper>`


let codeLoginPage = `import { LoginWrapper } from "./Components/Login.styled";
import { LoginWrapper } from "Components/LayoutWrapper/LoginWrapper";

<LayoutPageWrapper>
    <LoginWrapper>
        //{...}
    </LoginWrapper>
</LayoutPageWrapper>`


let codePageWrapper = `import { LoginWrapper } from "Components/LayoutWrapper/LoginWrapper";

<LayoutPageWrapper>
    //{...}
</LayoutPageWrapper>`


let codePageHeaderWrapper = `import { Container } from 'react-bootstrap';
import { LoginWrapper } from "Components/LayoutWrapper/LoginWrapper";

<LayoutPageWrapper>
    <Container className="page-header">
        //{...}
    </Container>
</LayoutPageWrapper>`