import React, { Component } from "react";
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { Container, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";

class AudianceList extends Component {
    state = {
        isSaveBtnClicked: false,
        grid1_data: [
            { "Name": "Peter williams", "Age": "34", "Email": "wp****@hotmail.com", "Untitle": "New York" },
            { "Name": "George", "Age": "20", "Email": "george****@hotmail.com", "Untitle": "Los Angles" },
            { "Name": "Thomas", "Age": "25", "Email": "thomas.****@yahoo.com", "Untitle": "New York" },
            { "Name": "Kamryn", "Age": "26", "Email": "Kamryn.****@gmail.com", "Untitle": "New York" },
            { "Name": "John merlin", "Age": "28", "Email": "merlin_****@yahoo.co.in", "Untitle": "Los Angles" }
        ],
        grid2_data: [
            { "Name": "Peter williams", "Age": "34", "Email": "wp****@hotmail.com", "Untitle": "New York" },
            { "Name": "George", "Age": "20", "Email": "george****@hotmail.com", "Untitle": "Los Angles" },
            { "Name": "Thomas", "Age": "25", "Email": "thomas.****@yahoo.com", "Untitle": "New York" },
            { "Name": "Kamryn", "Age": "26", "Email": "Kamryn.****@gmail.com", "Untitle": "New York" },
            { "Name": "John merlin", "Age": "28", "Email": "merlin_****@yahoo.co.in", "Untitle": "Los Angles" }
        ]
    };
    render() {
        return (
            <>
                <LayoutPageWrapper>
                    <Container className="page-header box-bottom-space">
                        <div className="header-title d-flex justify-content-between">
                            <h1>Audience list</h1>
                            {/* <div className="back-text" onClick={() => this.props.history.goBack()}>
                                <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                                Back
                            </div> */}
                        </div>
                        <h3>Customers data</h3>
                        <Grid
                            data={this.state.grid1_data}
                            reorderable={true}
                        >
                            {/* <GridColumn field="Name" cell={() => {
                                return (
                                    <span class="recipients-column">
                                        <a href="javascript:;" class="key-column">
                                            <i class="icon-key-mini icons-sm white key-active"></i>
                                        </a>
                                        <a href="#confirm-delete" data-toggle="modal" class="delete-column deleteConfirm">
                                            <i class="glyp-icon-minus-sm"></i>
                                        </a>
                                    </span>
                                )
                            }} /> */}
                            <GridColumn field="Name" />
                            <GridColumn field="Age" />
                            <GridColumn field="Email" />
                            <GridColumn field="Untitle" />
                        </Grid>
                        <small>Select all as double opt-in audience.</small>
                        <br></br>
                        <br></br>
                        <h3>Purchase data</h3>
                        <Grid
                            data={this.state.grid2_data}
                            reorderable={true}
                        >
                            <GridColumn field="Name" />
                            <GridColumn field="Age" />
                            <GridColumn field="Email" />
                            <GridColumn field="Untitle" />
                        </Grid>
                        <div className="btn-container d-flex justify-content-end">
                            <RSSecondaryBtn onClick={() => {
                                this.props.history.push({ pathname: `/audience/add-audience`, state: { mode: 'add' } })
                            }}>Cancel
                            </RSSecondaryBtn>
                            <RSPrimaryBtn onClick={() => {
                                this.setState({ isSaveBtnClicked: true });
                            }}>
                                Save
                            </RSPrimaryBtn>
                        </div>
                        <Modal backdrop="static"
                            keyboard={false} show={this.state.isSaveBtnClicked} onHide={() => {
                                this.setState({ isSaveBtnClicked: false });
                            }}>
                            <Modal.Header closeButton>
                                <h2>Add audience</h2>
                            </Modal.Header>
                            <Modal.Body>
                                <label style={{ textAlign: "center" }}>Retirees</label>
                                <label>Import is in progress. Click "Return to the master list" to safely navigate to other pages.</label>
                            </Modal.Body>
                            <Modal.Footer>
                                <RSPrimaryBtn onClick={() => {
                                    this.props.history.push({ pathname: "/audience", state: { index: 0 } });
                                }}>Return to the master list</RSPrimaryBtn>
                            </Modal.Footer>
                        </Modal>
                    </Container>
                </LayoutPageWrapper>
            </>
        )
    }
}

export default withRouter(AudianceList);