import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { GET_CHECKSPAM_SCORE } from "Helper/Constants/endPoint";
import SpamScoreRangeSlider from "./SpamScoreRangeSlider";

const SubjectModal = (props) => {
  const context = useContext(authContext)
  const [isShow, setIsShow] = useState(false);
  const [checkSpamData, setCheckSpamData] = useState([])
  const [totalSpamScore, setTotalSpamScore] = useState(0)
  const { subjectLine } = props.checkSpamParams

  const getcheckspamScore = () => {
    const params = props.checkSpamParams
    connectServer({
      path: GET_CHECKSPAM_SCORE,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setCheckSpamData(res.data)
          setTotalSpamScore(parseFloat(res.message))
          setIsShow(true)
        }
      },
      fail: err => {
      }
    })
  }
  useEffect(() => {
    getcheckspamScore()
  }, [])

  return (
    <>
      <Modal
        show={isShow}
        backdrop="static"
        className="modal-top-fixed"
        keyboard={false}
        centered
        size="lg"
        onHide={() => {
          setIsShow(!isShow);
          props.onChangeIsOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <h2>Spam score</h2>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb20">{subjectLine}</h4>
          <div className="mb20 ">
            <h3 className="mb30 text-center">The average inbox score is <span className="blue xmedium">{totalSpamScore}%</span></h3>
            <SpamScoreRangeSlider spamScore={totalSpamScore} />
          </div>
          {/* <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <div>
                    <span>Projectd CTR</span>
                    <div className="line-analysis">
                      <CircularProgressbarWithChildren
                        value={15}
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",
                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Colors
                          pathColor: "#9ccc06",
                          trailColor: "#eeeeee",
                          backgroundColor: "#0b7cc1",
                        })}
                      >
                         Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. 
                        <div className="circular-text">
                          <b>
                            15<small>%</small>
                          </b>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span>Subject line length</span>
                    <div className="line-analysis">
                      <CircularProgressbarWithChildren
                        value={85}
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "16px",
                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Colors
                          pathColor: "#9ccc06",
                          trailColor: "#eeeeee",
                          backgroundColor: "#0b7cc1",
                        })}
                      >
                         Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. 
                        <div className="circular-text">
                          <b>
                            85<small>%</small>
                          </b>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div>
                <span>Inbox score</span>
              </div>
            </Col>
          </Row> */}
          <Row>
            {/* <Col md={6} className='bgGrey-lighter'>
                <div className="row">
                    <div className="col-md-9"><h3>Top 3 subject lines</h3></div>
                    <div className="col-md-3 text-end"><h3>CTR%</h3></div>
                </div>
                {subjectLineData && 
                    subjectLineData.map((item, index) => {
                        const { leftText, rightText} = item;
                        return(
                            <ul className="analysis-list">
                                <li key={index} className="row">
                                    <div className="col-md-9">{leftText}</div>
                                    <div className="col-md-3 text-end border-left-1 d-flex align-items-center justify-content-center">
                                        <h2 className="blue-medium mb0">{rightText}</h2>
                                    </div>
                                </li>
                             </ul>
                        ) 
                    })
                }   
            </Col> */}
            <Col md={12} className='bgGrey-lighter2 '>
              <div className="row">
                <div className="col-md-9"><h3>Inbox score breakdown</h3></div>
                {/* <div className="col-md-3 text-end"><h3>CTR%</h3></div> */}
              </div>
              {checkSpamData &&
                checkSpamData.map((item, index) => {
                  const { description, pts } = item;
                  let eachPts = pts
                  eachPts.replace(/[$@%]/g, '')
                  return (
                    <ul className="mb15">
                      {parseFloat(eachPts) > 0.0 &&
                        <li key={index} className="row">
                          <div className="col-md-9">{description}</div>
                          <div className="col-md-3 text-end border-left-1 d-flex align-items-center justify-content-center">
                            <span className="mb0">{parseFloat(eachPts)}</span>
                          </div>
                        </li>}
                    </ul>
                  )
                })
              }
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubjectModal;

const subjectLineData = [
  {
    "id": 1,
    "leftText": `Exclusive dining privileges on using Vision Credit Cards`,
    "rightText": '22%'
  },
  {
    "id": 2,
    "leftText": 'Special discounts at gourmet restaurants near you',
    "rightText": '18%'
  },
  {
    "id": 3,
    "leftText": '"Get personal loans at 10% off approved in 2 mins"',
    "rightText": '17%'
  }
]

const inboxScoreData = [
  {
    "id": 1,
    "leftText": `HTML has a low ratio of text to image area`,
    "rightText": '16%'
  },
  {
    "id": 2,
    "leftText": 'Message only has text/HTML MIME parts',
    "rightText": '7%'
  },
  {
    "id": 3,
    "leftText": 'HTML has a low ratio of text to image area',
    "rightText": '24%'
  }
]
