import React, { Component } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";

export default class ErrorModal extends Component {
  state = {
    isModalOpen: this.props.isOpen,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate = (PrevProps) => {
    if (this.props !== PrevProps) {
      this.setState({
        isModalOpen: this.props.isOpen,
      });
    }
  };

  handleClose() {
    this.props.onChangeIsOpen(!this.state.isModalOpen);
  }

  render() {
    const { isFooter = true, size = "lg" } = this.props;
    return (
      <Modal
        className="new-data-catalogue-modal"
        show={this.state.isModalOpen}
        onHide={() => this.handleClose()}
        size={size}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <h2>{this.props.title}</h2>
          <CloseButton
            className="icon-sd-close-mini"
            onClick={() => {
              this.setState({
                isModalOpen: !this.state.isModalOpen,
              });
              this.handleClose();
              // this.props.Confirm(true);
            }}
          />
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        {isFooter && (
          <Modal.Footer>
            <RSSecondaryBtn
              onClick={() => {
                this.setState({
                  isModalOpen: !this.state.isModalOpen,
                });
                this.props.onChangeIsOpen(!this.state.isModalOpen);
              }}
            >
              {this.props?.secondaryButton
                ? this.props?.secondaryButton
                : "Close"}
            </RSSecondaryBtn>
            {this.props?.primary && (
              <RSPrimaryBtn
                onClick={() => {
                  this.handleClose();
                  this.props.Confirm(true);
                }}
              >
                {this.props?.primary || "Ok"}
              </RSPrimaryBtn>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}
