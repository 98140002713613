import { withRouter } from "react-router-dom";
import React from "react";
import { GridDetailRow } from "@progress/kendo-react-grid";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { connectServer } from "Helper/Network/networkHandler";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { DELETE_CHANNEL, DUPlICATE_CHANNEL, MDC_CANVAS_URL, NO_MEDIA_IMAGE } from "Helper/Constants/endPoint";
import { DateFormatWithMomentForWhatsApp, dateTimeFormatWithMoment, getAuthUserId, getServerConfig, getUserData, TimeFormatWithMomentForWhatsApp } from "Helper/Utils/Utils";
import camera from 'Assets/Images/camera.png'
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import { WhatsAppMessage } from "Components/WhatsAppMessage";
import pdfImage from 'Assets/Images/wapdf.png';
import videoImage from 'Assets/Images/wavideo.png';
import MakeChangesModal from "./MakeChangesModal";

class CampaignListDetailComponent extends GridDetailRow {

  state = {
    editClickOff: false,
    isOpenDeleteModal: false,
    imagePath: getServerConfig().slice(0, -1),
    selectedChannelId: "",
    isOpenRFAModal: false
  }

  componentDidMount() {
    if (this.props.dataItem !== undefined && this.props.dataItem.info !== undefined) {
      let tempChannel = this.props.dataItem.info.sort((a, b) => Number(a.channelId) - Number(b.channelId))
      this.setState({ channelList: tempChannel })
    }
  }

  dublicateChannel = (campaignId, channelId) => {
    let params = {
      channelId: channelId,
      userId: getAuthUserId(),
      campaignId: campaignId,
      databaseName: 'camp_e25a3241_2b2e_444f_a491_247fb7cbf4fa'
    }
    connectServer({
      path: DUPlICATE_CHANNEL,
      params: params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status) {
          this.props.onRefresh();
        }
      },
      fail: err => { }
    })
  }

  deleteChannel = (campaignId, channelId) => {
    let params = {
      channelId: channelId,
      campaignId: campaignId,
    }
    connectServer({
      path: DELETE_CHANNEL,
      params: params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status) {
          this.props.onRefresh();
          this.props.deleteChannel(campaignId, channelId)
        }
      },
      fail: err => { }
    })
  }

  backToCanvas = (campid) => {
    const sessionStr = localStorage.getItem('session')
    const sessionObject = JSON.parse(sessionStr)
    const accessToken = sessionObject ? sessionObject.accessToken : null
    const campId = window.btoa(campid)
    let fromEnvi = window.location.href.includes("app") ? "app" : window.location.href.includes("sit") ? "sit" : "run";
    window.location.href = getServerConfig() + MDC_CANVAS_URL + "?CampId=" + campId + '&accessToken=' + encodeURIComponent(accessToken) + '&from=' + fromEnvi
  }

  getImage = (imageName) => {
    switch (imageName) {
      case 'email':
        return "icon-sd-email-medium icons-lg orange-medium"
      case 'sms':
        return "icon-sd-mobile-sms-medium icons-lg yellow-medium"
      case 'line':
        return " icon-line-xmedium icons-lg blue"
      case 'qrcode':
        return "icon-sd-qrcode-medium icons-md grey-dark"
      case 'web':
        return "icon-social-web-notification-xmedium icons-lg blue"
      case 'web notification':
        return "icon-social-web-notification-xmedium icons-lg blue"
      case 'mobile':
        return "icon-mobile-sms-medium icons-lg yellow-medium"
      case 'push notification':
        return "icon-mobile-push-notification-xmedium icons-lg blue"
      case 'whatsapp':
        return "icon-sd-social-whatsapp-medium icons-lg blue"
      case 'facebook':
        return "icon-facebook-medium icons-md blue"
      case 'twitter':
        return "icon-twitter-medium icons-md blue"
      case 'linkedin':
        return "icon-linkedin-medium icons-md blue"
      case 'pinterest':
        return "icon-pinterest-medium icons-md blue"
      case 'instagram':
        return "icon-instagram-medium icons-md blue"
      case 'paidads':
        return "icon-google-ad-medium icons-md blue"
      case 'paid media':
        return "icon-google-ad-medium icons-md blue"
      case 'socialmedia':
        return 'icon-facebook-medium icons-md blue'
      default:
        return ""
    }
  }

  getTitle = id => {

    var title = ''
    switch (id) {
      case "1":
        title = 'Email'
        break;
      case "2":
        title = 'SMS'
        break;
      case "21":
        title = 'WhatsApp'
        break;
      case "3":
        title = 'QR'
        break;
      default:
        title = ''
    }
    return title

  }

  render() {
    return (
      <div className="panel-detail-view">
        <table className="accordian-body">
          <thead>
            <tr>
              <th width={160}>Channel</th>
              <th width="18%">Friendly name</th>
              <th width="45%">Status</th>
              <th width="45%">Payment</th>
              <th width="20.4%" className="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.props.dataItem !== undefined && this.props.dataItem.info !== undefined &&
              this.props.dataItem.info.map((item, index) => {
                const campaignHeader = {
                  name: this.props.dataItem.campaignName,
                  startDate: this.props.dataItem.startDate,
                  endDate: this.props.dataItem.endDate,
                  goal: "Conversion",
                  type: this.props.dataItem.campaignType
                }
                return (
                  <tr key={index} className={index % 2 === 0 ? 'odd' : ''}>
                    <td>
                      <div className="d-flex">
                        <RSPTooltip text={this.getTitle(item.channelId)} position="top">
                          <i className={`blue icons-lg ${this.getImage(item.channelName?.toLowerCase().replaceAll(" ", ""))}`}
                          ></i></RSPTooltip>
                      </div>
                    </td>
                    <td>
                      <ul className='d-flex align-items-center'>
                        <li>
                          <RSPTooltip text={item.campaignType === "M" ? !!item.channelFriendlyName ? item.channelFriendlyName : item.channelName : item.channelName} position="top">
                            <p >{item.campaignType === "M" ? !!item.channelFriendlyName ? item.channelFriendlyName : item.channelName : item.channelName}</p>
                          </RSPTooltip>
                        </li></ul>
                    </td>
                    <td>
                      <ul className='d-flex align-items-center'>
                        <li>
                          <RSPTooltip text={campaignStatus(item.channelStatusId).title} position="top">
                            <i className={`${campaignStatus(item.channelStatusId).icon} ${campaignStatus(item.channelStatusId).className} marginR8 icons-md`} />
                          </RSPTooltip>
                        </li>
                        <li>
                          {(item.channelStatusId === 7) &&
                            <span>{item.scheduleDate !== null ? dateTimeFormatWithMoment(item.scheduleDate) : ""}</span>}
                          {<span>{campaignStatus(item.channelStatusId).message}</span>}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className='d-flex align-items-center'>
                        {((item.channelStatusId === 7 || item.channelStatusId === 9 || item.channelStatusId === 5 || item.channelStatusId === 12)) &&
                          <li>
                            <RSPTooltip text={item.paymentchannelStatusId === 1 ? "Success" : "Pending"} position='top'>
                              <div className="multi-icon-wrap">
                                <i className={`multi-icon-main icon-sd-credit-card-medium icons-md blue`} />
                                <i className={`multi-icon-small ${item.paymentchannelStatusId === 1 ? 'icon-sd-circle-tick-medium' : 'icon-sd-circle-close-medium'} icons-sm blue`} />
                              </div>
                            </RSPTooltip>
                          </li>}
                      </ul>
                    </td>
                    <td>
                      {/* {item.isSmartlinkCampaign !== undefined && item.isSmartlinkCampaign === false} */}
                      {this.props.dataItem.isSmartLinkCampaign === 'N' ? (
                        <div className='camp-icon float-end'>
                          <ul className='camp-icon-pannel'>
                            {/* {item.info !== '' ? (
                              <li>
                                <RSPPophover position='top' text={item.info}>
                                  <i className='icon-sd-circle-info-medium icons-md blue'></i>
                                </RSPPophover>
                              </li>
                            ) : null} */}
                            {(item.channelStatusId === 54 || item.channelStatusId === 51) && < li >
                              <RSPTooltip text="Comments" position="top">
                                <i
                                  className='icon-sd-messaging-medium icons-md blue'
                                  onClick={() => {
                                    this.setState({ isOpenRFAModal: true, selectedChannelId: item.channelId });
                                  }}
                                ></i>
                              </RSPTooltip>
                            </li>}
                            <RSPTooltip text='Preview' position="top" rootClose={true}>
                              <li>
                                {
                                  item.channelId === "1" ? <Tooltip anchorElement="target" position="top">
                                    <RSPPophover
                                      position='left'
                                      imgClass='campaign-pop-eye-img'
                                      image={(!!item.previewImage && item.previewImage !== "") ? `data:image/jpeg;base64,${item.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                                    >
                                      <i className='icon-sd-eye-medium icons-md blue'></i>
                                    </RSPPophover>
                                  </Tooltip>
                                    : item.channelId === "2" ? <RSPPophover
                                      position='left'
                                      imgClass='campaign-pop-eye-img'
                                      className="pophover-sms-con"
                                      pophover={
                                        <div className='campaign-sms-wrapper'>
                                          <div className='campaign-sms-header'>
                                            <div className="camp-sms-left"><span class="arrow-left">&lt;</span>Back</div>
                                            <div className='camp-sms-center blue'>{getUserData()?.clientDetailsLocalization?.clientName}</div>
                                            <div className="camp-sms-right">Contact</div>
                                            {/* <div className='blue w100p text-center'>Vision Bank</div> */}
                                          </div>
                                          <div className='campaign-sms-body'>
                                            <div className='chat-bubble-left'>
                                              <div className='chat-bubble'>
                                                {/* <div className='chat-content'> */}
                                                <span className='campaign-message'>
                                                  {item.previewImage ? item.previewImage : 'No data'}
                                                </span>
                                                {/* </div> */}
                                              </div>
                                              <small className='message-date mt10'>{item.scheduleDate ? dateTimeFormatWithMoment(item.scheduleDate) : dateTimeFormatWithMoment(new Date())}</small>
                                            </div>
                                          </div>
                                          <div className='campaign-sms-footer'>
                                            <img className="in-img" src={camera} alt='camera' />
                                            <div className="in-msg">Message</div>
                                            <div className="in-send">Send</div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <i title="Preview" className='icon-sd-eye-medium icons-md blue'></i>
                                    </RSPPophover>
                                      : item.channelId === "3" ? <Tooltip anchorElement="target" position="top">
                                        <RSPPophover
                                          position='left'
                                          imgClass='campaign-pop-eye-img'
                                          image={item.previewImage !== "" ? `${item.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                                        >
                                          <i className='icon-sd-eye-medium icons-md blue'></i>
                                        </RSPPophover>
                                      </Tooltip> : <RSPPophover
                                        position='left'
                                        imgClass='campaign-pop-eye-img'
                                        className="pophover-sms-con"
                                        pophover={
                                          <>
                                            <WhatsAppMessage
                                              popup
                                              profile={`${this.state.imagePath}${getUserData()?.clientDetailsLocalization?.logoPath}`}
                                              username={getUserData()?.clientDetailsLocalization?.clientName}
                                              date={item.scheduleDate ? DateFormatWithMomentForWhatsApp(item.scheduleDate) : DateFormatWithMomentForWhatsApp(new Date())}
                                              time={item.scheduleDate ? TimeFormatWithMomentForWhatsApp(item.scheduleDate) : TimeFormatWithMomentForWhatsApp(new Date())}
                                              image={item.templateType === 2 ? item.waImagePath !== "" ? item.waImagePath : item.waMediaURL : item.templateType === 3 ? pdfImage : item.templateType === 4 ? videoImage : ""}
                                              message={item.previewImage ? item.previewImage : 'No data'}
                                            />

                                            {/* <div className="preview-mobile-popup whatsapp-portlet">
                                            <div className="mb-content-box sms-popup w-inner-view">
                                              <div className='w-head'>
                                                <img className='w-profile' src={`${this.state.imagePath}${getUserData()?.clientDetailsLocalization?.logoPath}`} />
                                                <div className='w-user-details'>
                                                  <span className='w-user'>{getUserData()?.clientDetailsLocalization?.clientName}</span>
                                                  <span className='w-status'>online</span>
                                                </div>
                                              </div>
                                              <div className="mb-box-body w-body css-scrollbar">
                                                <ul className="message-box w-lists">
                                                  <li className='date'>
                                                    <small>{item.scheduleDate ? DateFormatWithMomentForWhatsApp(item.scheduleDate) : DateFormatWithMomentForWhatsApp(new Date())}</small>
                                                  </li>
                                                  <li className='message sent'>
                                                    {this.props.imagePath ? <img className="WhatsAppImage" src={this.props.imagePath} alt="Post Image" /> : null}
                                                    <span>{item.previewImage ? item.previewImage : 'No data'}</span>
                                                    <span class="metadata">
                                                      <span class="time">{item.scheduleDate ? TimeFormatWithMomentForWhatsApp(item.scheduleDate) : TimeFormatWithMomentForWhatsApp(new Date())}</span>
                                                      <span class="tick">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                                                          <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"></path></svg>
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div> */}
                                          </>
                                        }
                                      >
                                        <i title="Preview" className='icon-sd-eye-medium icons-md blue'></i>
                                      </RSPPophover>}
                              </li>
                            </RSPTooltip>

                            <li>
                              <RSPTooltip text="Edit" position="top">
                                <i
                                  className='icon-sd-pencil-edit-medium icons-md blue'
                                  onClick={() => {
                                    if (item.campaignType === 'M') {
                                      this.backToCanvas(this.props.dataItem.campaignId)
                                    }
                                    else {
                                      let channelTypes = [];
                                      this.props.dataItem.info.map((item, index) => (
                                        channelTypes.push(item.channelName)
                                      ))
                                      this.props.history.push({
                                        pathname: `communication/create-communication`,
                                        state: {
                                          mode: 'edit',
                                          campaignName: this.props.dataItem.campaignName,
                                          editClickOff: this.state.editClickOff,
                                          status: item.channelStatusId,
                                          Channeltype: channelTypes,
                                          AnalyticsType: ["Web", "App"],
                                          mobileApp: {},
                                          campaignId: this.props.dataItem.campaignId,
                                          channelId: parseInt(item.channelId, 10),
                                          campaignHeader,
                                          CampaignType: this.props.dataItem.campaignType
                                        }
                                      })
                                      this.props.onButtonClick(true)
                                    }
                                  }}
                                ></i>
                              </RSPTooltip>
                            </li>
                            {
                              (item.channelStatusId !== 3
                                && item.channelStatusId !== 4)
                              && <li>
                                <RSPTooltip text="Analytics" position="top">
                                  <i
                                    className={`icon-sd-analytics-medium icons-md blue ${(item.channelStatusId === 9 || item.channelStatusId === 5) ? "" : "click-off"}`}
                                    onClick={
                                      () => {
                                        this.props.history.push({
                                          pathname: `analytics/detail-analytics`,
                                          state: {
                                            campaignId: this.props.dataItem.campaignId,
                                            channelId: parseInt(item.channelId, 10),
                                            campaignName: this.props.dataItem.campaignName,
                                            campaignGuid: this.props.dataItem.guid,
                                            startDate: this.props.dataItem.createdDate,
                                            endDate: this.props.dataItem.endDate,
                                            selectedIndex: index,
                                            isEmailSplitAb: this.props.dataItem.isEmailSplitAB,
                                            isSmsSplitAb: this.props.dataItem.isSMSSplitAB
                                          }
                                        })
                                      }
                                    }
                                  ></i>
                                </RSPTooltip>
                              </li>
                            }
                            {item.campaignType !== "M" &&
                              <li>
                                <RSPTooltip text="Duplicate" position="top">
                                  <i
                                    className='icon-sd-duplicate-medium icons-md blue'
                                    onClick={() =>
                                      this.dublicateChannel(
                                        this.props.dataItem.campaignId,
                                        item.channelId
                                      )
                                    }
                                  ></i>
                                </RSPTooltip>
                              </li>}
                            {(item.campaignType !== "M" && (item.channelStatusId !== 9 && item.channelStatusId !== 5 && item.channelStatusId !== 52 && item.channelStatusId !== 54 && item.channelStatusId !== 12)) &&
                              <li>
                                <RSPTooltip text='Delete' position='top'>
                                  <i
                                    className='icon-archive-small icons-md blue'
                                    onClick={() => {
                                      this.setState({ isOpenDeleteModal: true, selectedChannelId: item.channelId });
                                    }}
                                  ></i>
                                </RSPTooltip>
                              </li>}
                          </ul>
                        </div>
                      ) : null}
                      {this.state.isOpenDeleteModal &&
                        <RSConfirmAlert
                          title="Are you sure you want to delete it?"
                          secondaryButtonText="Cancel"
                          primaryButtonText="OK"
                          buttonClicked={(value) => {
                            if (value) {
                              this.deleteChannel(
                                this.props.dataItem.campaignId,
                                this.state.selectedChannelId
                              )
                            }
                            this.setState({ isOpenDeleteModal: false })
                          }}
                        />
                      }
                      {this.state.isOpenRFAModal &&
                        <MakeChangesModal
                          isOpen={this.state.isOpenRFAModal}
                          channelDetails={{ "campaignId": this.props.dataItem.campaignId, "channelId": this.state.selectedChannelId }}
                          onChangeIsOpen={(value) => {
                            this.setState({ isOpenRFAModal: false })
                          }}
                        />
                      }
                    </td>
                  </tr>
                )
              })}

          </tbody>
        </table>
      </div >
    );
  }
}
export default withRouter(CampaignListDetailComponent);

const campaignStatus = campaignStatusId => {
  return campaignProgressStatus[campaignStatusId]
}

const campaignProgressStatus = {
  0: {
    title: 'Draft',
    className: 'ico-status-draft',
    icon: 'icon-sd-form-edit-medium',
    message: ""
  },
  5: {
    title: 'In progress',
    className: 'ico-status-progress',
    icon: 'icon-sd-in-progress-medium',
    message: ""
  },
  51: {
    title: 'Rejected',
    className: 'ico-status-rejected',
    icon: 'icon-sd-circle-close-medium',
    message: ""
  },
  52: {
    title: 'Alert',
    className: 'ico-status-alert',
    icon: 'icon-sd-alert-medium',
    message: "Final audience count is zero"
  },
  54: {
    title: 'On hold',
    className: 'ico-status-alert',
    icon: 'icon-sd-alert-medium',
    message: "On hold"
  },
  12: {
    title: 'Pending for approval',
    className: 'ico-status-alert',
    icon: 'icon-sd-alert-medium',
    message: "Pending for approval"
  },
  9: {
    title: 'Completed',
    className: 'ico-status-completed',
    icon: 'icon-sd-circle-tick-medium',
    message: ""
  },
  7: {
    title: 'Scheduled',
    className: 'ico-status-scheduled',
    icon: 'icon-sd-time-medium',
    message: ""
  },
  6: {
    title: 'Draft',
    className: 'ico-status-draft',
    icon: 'icon-sd-form-edit-medium',
    message: ""
  },
  20: {
    title: 'Multi status',
    className: 'ico-status-multistatus',
    icon: 'icon-multi-status-large',
    message: ""
  }
}