 
export const TEMPLATEDATA = [
  {
    id: 1,
    text: "My Template",
    // iconLeft: "icon-text-document-large icons-lg marginB10",
    disable: false,
    contentType: "T",
  },
  {
    id: 2,
    text: "Create template",
    // iconLeft: "icon-import-file-large icons-lg marginB10",
    disable: false,
    contentType: "M",
  },
];
 