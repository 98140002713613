import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { PDF_URL } from "Helper/Constants/validationMessage";
import { isValidWebsite } from "Helper/Utils/Utils";
import { RSDropdownList } from "Components/RSDropdowns";
import { UPLOAD_MEDIA_URL } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";

class PDFPicker extends React.Component {
    static contextType = authContext;
    state = {
        isModalOpen: this.props.isOpen,
        imageURL: "",
        imageType: null,
        errorImageURL: null,
        errorImageType: null,
        isEnableSaveButton: false

    };
    uploadImageToServer = (mediaUrl) => {
        let params = {
            "imageUrl": mediaUrl,
        }
        connectServer({
            path: UPLOAD_MEDIA_URL,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    if (parseInt(res.data.value, 10) <= -1) {
                        this.setState({ errorImageURL: "Link does not have document", isEnableSaveButton: false })
                    } else if (parseInt(res.data.value, 10) > 5) {
                        this.setState({ errorImageURL: "Document size maximum 5 MB", isEnableSaveButton: false })
                    } else {
                        this.setState({ errorImageURL: null, isEnableSaveButton: true })
                    }
                } else {
                    this.setState({ isEnableSaveButton: false });
                }
            },
            fail: err => {
            }
        })
    }
    render() {
        return (
            <Modal backdrop="static"
                keyboard={false} show={this.state.isModalOpen} centered size="lg" onHide={() => {
                    this.setState({ isModalOpen: !this.state.isModalOpen });
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header closeButton>
                    <h2>Enter document URL</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col md={2} >
                                <label className="label-row">URL</label>
                            </Col>
                            <Col md={9} >
                                <RSInput2
                                    ph="Enter URL"
                                    required={true}
                                    cb={(text) => {
                                        let errMsg = text.length > 0 ? null : PDF_URL;
                                        this.setState({ errorImageURL: errMsg, imageURL: text, isEnableSaveButton: false });
                                    }}
                                    ob={(text) => {
                                        if (text.length > 0) {
                                            let extension = text.split(".").at(-1)
                                            let errMsg = extension === "pdf" ? isValidWebsite(text) ? null : "Enter a valid URL" : "URL does not match with type";
                                            if (errMsg === null) {
                                                this.uploadImageToServer(text);
                                            } else {
                                                this.setState({ errorImageURL: errMsg });
                                            }
                                        } else {
                                            this.setState({ errorImageURL: PDF_URL });
                                        }
                                    }}
                                    errorMessage={this.state.errorImageURL}
                                />
                                <div className="input-outer-set">
                                    <RSPPophover text="Accepts URL formats with .pdf extention" position="top">
                                        <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                    </RSPPophover>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} >
                                <label className="label-row">Type</label>
                            </Col>
                            <Col md={9} >
                                <div className="position-relative">
                                    {this.state.errorImageType && (
                                        <div className="validation-message">
                                            {this.state.errorImageType}
                                        </div>)}
                                    <RSDropdownList
                                        className="marginB0 required"
                                        data={PDFData || []}
                                        value={this.state.imageType}
                                        defaultItem={{
                                            documentId: 0,
                                            documentType: "-- Select document type --",
                                        }}
                                        textField='documentType'
                                        dataItemKey='documentId'
                                        onChange={(e) => {
                                            let errMsg = e.target.value.imageId === 0 ? "Select document type" : null;
                                            this.setState({ imageType: e.target.value, errorImageType: errMsg });
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-container marginT20">
                        <RSSecondaryBtn
                            onClick={() => {
                                this.setState({ isModalOpen: !this.state.isModalOpen });
                                this.props.onChangeIsOpen(false);
                            }}
                        >
                            Cancel
                        </RSSecondaryBtn>
                        <RSPrimaryBtn
                            className={this.state.isEnableSaveButton ? "" : "click-off"}
                            onClick={() => {
                                if (this.state.imageType.documentId !== 0) {
                                    this.props.onCallback(this.state.imageType.documentType, this.state.imageURL);
                                    this.props.onChangeIsOpen(false);
                                } else {
                                    this.setState({ errorImageType: "Select document type" })
                                }
                            }}
                        >
                            Save
                        </RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PDFPicker;

const PDFData = [
    {
        documentId: 1,
        documentType: "PDF"
    }
]