import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { RSInput2 } from "Components/RSInputs";
import { isEmpty } from "Helper/Utils/Utils";
import {
  INBOUND_NUMBER,
  CONDITIONS,
  KEY_WORDS,
} from "Helper/Constants/validationMessage";
import { RSPTooltip } from "Components/Tooltip";

const SMSTwoWay = (props) => {

  const [twoWayArray, setTwoWayArray] = useState([]);
  const [errMessTwoWay, setErrMsgTwoWay] = useState([]);

  useEffect(() => {
    setTwoWayArray(props.array);
    setErrMsgTwoWay(props.error);
  }, [props]);

  const twoWayValidate = () => {
    for (let i = 0; i < twoWayArray.length; i++) {
      if (isEmpty(twoWayArray[i].inboundNumber)) {
        let errMsg = INBOUND_NUMBER;
        let temp = [...errMessTwoWay];
        temp[i].errMsgInboundNumber = errMsg;
        setErrMsgTwoWay(temp);
        return false;
      }
      if (isEmpty(twoWayArray[i].keywords)) {
        let errMsg = KEY_WORDS;
        let temp = [...errMessTwoWay];
        temp[i].errMsgKeyWord = errMsg;
        setErrMsgTwoWay(temp);
        return false;
      }
      if (isEmpty(twoWayArray[i].condition)) {
        let errMsg = CONDITIONS;
        let temp = [...errMessTwoWay];
        temp[i].errMsgConditions = errMsg;
        setErrMsgTwoWay(temp);
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Row>
        <Col>
          <h4 className="marginT30">Inbound number</h4>
        </Col>
        <Col md={{ span: 2 }}>
          <RSPTooltip text="Add inbound number" position="top">
            <i
              className={`icon-sd-circle-plus-fill-edge-medium icons-md blue float-right cursor-pointer ml10 ${twoWayArray.length === 5 ? "click-off" : ""
                }`}
              onClick={() => {
                if (twoWayArray?.length < 5) {
                  let isValidate = twoWayValidate();
                  if (isValidate) {
                    let temp = [...twoWayArray];
                    temp.push({
                      inboundNumberId: 0,
                      inboundNumber: "",
                      keywords: "",
                      condition: "",
                    });
                    setTwoWayArray(temp);
                    props.getValue(temp);
                    let temperror = [...errMessTwoWay];
                    temperror.push({
                      errMsgInboundNumber: null,
                      errMsgKeyWord: null,
                      errMsgConditions: null,
                    });
                    setErrMsgTwoWay(temperror);
                    props.getError(temperror);
                  }
                }
              }}
            ></i></RSPTooltip>
        </Col>
      </Row>
      {twoWayArray?.map((item, index) => {
        return (
          <Row>
            <Col md={4}>
              <RSInput2
                ph="Inbound number"
                val={item.inboundNumber}
                type="number"
                required={true}
                max={100}
                cb={(value) => {
                  let temp = [...twoWayArray];
                  temp[index].inboundNumber = value;
                  setTwoWayArray(temp);
                  item.inboundNumber = value;
                  let errMsg = item.inboundNumber ? null : INBOUND_NUMBER;
                  let tempError = [...errMessTwoWay];
                  tempError[index].errMsgInboundNumber = errMsg;
                  setErrMsgTwoWay(tempError);
                }}
                errorMessage={errMessTwoWay[index].errMsgInboundNumber}
              />
            </Col>
            <Col md={4}>
              <RSInput2
                ph="Keyword"
                val={item.keywords}
                required={true}
                max={100}
                cb={(value) => {
                  let temp = [...twoWayArray];
                  temp[index].keywords = value;
                  setTwoWayArray(temp);
                  item.keywords = value;
                  let errMsg = item.keywords ? null : KEY_WORDS;
                  let tempError = [...errMessTwoWay];
                  tempError[index].errMsgKeyWord = errMsg;
                  setErrMsgTwoWay(tempError);
                }}
                errorMessage={errMessTwoWay[index].errMsgKeyWord}
              />
            </Col>
            <Col md={4}>
              <RSInput2
                ph="Conditions"
                val={item.condition}
                required={true}
                max={100}
                cb={(value) => {
                  let temp = [...twoWayArray];
                  temp[index].condition = value;
                  setTwoWayArray(temp);
                  item.condition = value;
                  let errMsg = item.condition ? null : CONDITIONS;
                  let tempError = [...errMessTwoWay];
                  tempError[index].errMsgConditions = errMsg;
                  setErrMsgTwoWay(tempError);
                }}
                errorMessage={errMessTwoWay[index].errMsgConditions}
              />
              {index !== 0 && (
                <div className="input-outer-set">
                  <RSPTooltip text="Remove inbound number" position="top">
                    <i
                      className="icons-md red cursor-pointer float-left d-block icon-minus-fill-medium ml10"
                      onClick={() => {
                        if (props.mode === "edit") {
                          if (twoWayArray[index].inboundNumber !== "") {
                            let inBoundId = twoWayArray[index].inboundNumberId;
                            props.onPassInboundId(inBoundId);
                            let temp = [...twoWayArray];
                            temp.splice(index, 1);
                            setTwoWayArray(temp);
                            props.getValue(temp);
                          }
                        }
                        if (index !== 0) {
                          let temp = [...twoWayArray];
                          temp.splice(index, 1);
                          setTwoWayArray(temp);
                          props.getValue(temp);
                        }
                      }}
                    />
                  </RSPTooltip>
                </div>
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};
export default SMSTwoWay;
