 

const AddressCircleColor = "address-circle-colored.png";
const AppleStore = "apple-store-circle-colored.png";
const AskFM = "askfm-circle-colored.png";
const Behance = "behance-circle-colored.png";
const Blogger = "blogger-circle-colored.png";
const Dribble = "dribbble-circle-colored.png";
const DropBox = "dropbox-circle-colored.png";
const FaceBook = "facebook-circle-colored.png";
const Flickr = "flickr-circle-colored.png";
const FourSquare = "foursquare-circle-colored.png";
const Globe = "globe-circle-colored.png";
const GoogleDrive = "googledrive-circle-colored.png";
const HangOuts = "hangouts-circle-colored.png";
const ICQ = "icq-circle-colored.png";
const Insta = "instagram-circle-colored.png";
const SOCIAL_ICONS = [
  {
    id: 1,
    name: "Insta",
    icon: Insta,
    title: "Instagram",
    link: "",
    key: "instagram0001",
  },
  {
    id: 2,
    name: "AppleStore",
    icon: AppleStore,
    title: "AppleStore",
    link: "",
    key: "AppleStore0002",
  },
  {
    id: 3,
    name: "FaceBook",
    icon: FaceBook,
    title: "Facebook",
    link: "",
    key: "FaceBook00009",
  },
  {
    id: 4,
    name: "AskFM",
    icon: AskFM,
    title: "Ask FM",
    link: "",
    key: "AskFM0004",
  },
  {
    id: 5,
    name: "Behance",
    icon: Behance,
    title: "Behance",
    link: "",
    key: "Behance00005",
  },
  {
    id: 6,
    name: "Blogger",
    icon: Blogger,
    title: "Blogger",
    link: "",
    key: "Blogger00006",
  },
  {
    id: 7,
    name: "Dribble",
    icon: Dribble,
    title: "Dribble",
    link: "",
    key: "Dribble00007",
  },
  {
    id: 8,
    name: "DropBox",
    icon: DropBox,
    title: "DropBox",
    link: "",
    key: "DropBox00008",
  },
  {
    id: 9,
    name: "AddressCircleColor",
    icon: AddressCircleColor,
    title: "Address",
    link: "",
    key: "AddressCircleColor00003",
  },
];
export default SOCIAL_ICONS;
