import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPTooltip } from "Components/Tooltip";
import EmailFooterCreation from "./EmailFooterCreation";
import {
  GET_EMAIL_FOOTER_LIST,
  DELETE_EMAIL_FOOTER,
  GET_EMAIL_FOOTER,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { getAuthUserId, getUserData } from "Helper/Utils/Utils";
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';

const initialDataState = {
  skip: 0,
  take: 5,
};
const EmailFooter = () => {
  const context = useContext(authContext);

  const [page, setPage] = useState(initialDataState);
  const [emailFooterId, setEmailFooterId] = useState(null);
  const [index, setIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [listError, setListError] = useState(false);
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    getDatFromServer();
  }, [context.globalStateData.getIsReloadData]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const getDatFromServer = () => {
    setListError(false);
    const params = {
      userId: getAuthUserId(),
    };
    connectServer({
      path: GET_EMAIL_FOOTER_LIST,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            let resp = res.data.reverse()
            setListData(resp || []);
            setEdit(null)
          } else {
            setListError(true);
          }
        } else {
          setListError(true);
        }
      },
      fail: (err) => {
        setListError(true);
      },
    });
  };
  const getEditDate = (id) => {
    const params = {
      emailFooterId: id,
    };
    connectServer({
      path: GET_EMAIL_FOOTER,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          setEdit(res?.data);
          setIndex(1);
        }
      },
      fail: (err) => {
        setListError(true);
      },
    });
  };
  const CONFILTER = [
    {
      title: 'Name',
      field: 'footerName',
    },
    {
      title: 'Action',
      field: 'Action',
      width: 180,
      cell: ({ dataItem }) => (
        <td className="camp-icon">
          <div className="table-action-btn">
            <RSPTooltip text="Edit" position="top">
              <i
                className={`icon-sd-pencil-edit-medium icons-md blue cursor-pointer ${(getUserData().clientId === 16 || getUserData().clientId === 1928) ? dataItem?.emailFooterId < 7 ? "click-off" : "" : "click-off"}`}
                onClick={() => {
                  getEditDate(dataItem?.emailFooterId);
                }}
              />
            </RSPTooltip>
            <RSPTooltip text="Delete" position="top">
              <i
                className={`icon-sd-delete-medium icons-md blue cursor-pointer ${(getUserData().clientId === 16 || getUserData().clientId === 1928) ? dataItem?.emailFooterId < 7 ? "click-off" : dataItem.isDelete ? "" : "click-off" : "click-off"}`}
                onClick={() => {
                  setEmailFooterId(dataItem.emailFooterId);
                  setIsDeleteModalOpen(!isDeleteModalOpen);
                }}
              />
            </RSPTooltip>
          </div>
        </td>
      )
    }
  ]

  const EmailFooterList = () => {
    return (
      <Container className="p0 mt15">
        <div className="d-flex justify-content-between marginB20 align-items-center">
          <div className="d-flex align-items-center position-relative">
            <h3 className="marginB0">Email footer</h3>
            <RSPTooltip text="A list of pre-defined footer templates that can be used in emails." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
            </RSPTooltip>
          </div>
          <RSPTooltip text="Add" position="top">
            <i
              className={`icon-sd-circle-plus-fill-edge-medium icons-lg blue float-right cursor-pointer icon-shadow   ${(getUserData().clientId === 16 || getUserData().clientId === 1928) ? "" : "click-off"}`}
              onClick={() => {
                setIndex(1);
              }}
            ></i>
          </RSPTooltip>
        </div>
        {listData.length ? (
          <>
            <KendoGrid
              data={listData}
              scrollable={'scrollable'}
              column={CONFILTER}
              pageable
              settings={{
                total: listData?.length
              }}
            />
            {/* <Grid
              data={listData?.slice(page.skip, page.take + page.skip)}
              total={listData?.length}
              scrollable={"none"}
              skip={page.skip}
              take={page.take}
              sortable={false}
              className="grid-pagenation"
              messages={{
                itemsPerPage: "data items per page",
              }}
              onPageChange={pageChange}
              pageable={{
                type: "input",
                pageSizes: [5, 10, 20],
                previousNext: true,
              }}
            >
              <GridColumn title="Name" field="footerName" />
              <GridColumn
                width={110}
                title="Action"
                cell={(props) => {
                  return (
                    <td className="camp-icon">
                      <div className="table-action-btn">
                        <RSPTooltip text="Edit" position="top">
                          <i
                            className={`icon-sd-pencil-edit-medium icons-md blue cursor-pointer ${(getUserData().clientId === 16 || getUserData().clientId === 1928) ? props?.dataItem?.emailFooterId < 7 ? "click-off" : "" : "click-off"}`}
                            onClick={() => {
                              getEditDate(props?.dataItem?.emailFooterId);
                            }}
                          />
                        </RSPTooltip>
                        <RSPTooltip text="Delete" position="top">
                          <i
                            className={`icon-sd-delete-medium icons-md blue cursor-pointer ${(getUserData().clientId === 16 || getUserData().clientId === 1928) ? props?.dataItem?.emailFooterId < 7 ? "click-off" : "" : "click-off"}`}
                            onClick={() => {
                              setEmailFooterId(props.dataItem.emailFooterId);
                              setIsDeleteModalOpen(!isDeleteModalOpen);
                            }}
                          />
                        </RSPTooltip>
                      </div>
                    </td>
                  );
                }}
              /> */}
          </>
        ) : (
          <SkeletonTable
            text={"to create email footer"}
            count={5}
            isError={listError}
          />
        )}

        {isDeleteModalOpen && (
          <RSConfirmAlert
            title="Are you sure you want to delete it?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                let params = { emailFooterId: emailFooterId };
                connectServer({
                  path: DELETE_EMAIL_FOOTER,
                  params: params,
                  sessionOut: context.globalStateData.setSessionOutData,
                  loading: context.globalStateData.setIsLoadingData,
                  context: context,
                  retryTime: 5000,
                  ok: (res) => {
                    if (res.status) {
                      setEmailFooterId(null);
                      getDatFromServer()
                    }
                  },
                  fail: (err) => { },
                });
              }
              setIsDeleteModalOpen(!isDeleteModalOpen);
            }}
          />
        )}
      </Container>
    );
  };

  const RenderComponent = [
    <EmailFooterList onChangeIndex={(index) => setIndex(index)} />,
    <EmailFooterCreation
      onChangeIndex={(index) => setIndex(index)}
      edit={edit ? edit : null}
      getDatFromServer={getDatFromServer}
    />,
  ];

  return <>{RenderComponent[index]}</>;
};



export default EmailFooter;
