import React, { useState, useEffect } from "react";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { RSInput2 } from "Components/RSInputs";

export const Daily = (props) => {
  let min = new Date(2000, 2, 10, 12, 30);
  let max = new Date(2010, 2, 10, 24, 15);
  let defaultValue = new Date(2010, 2, 10, 10, 0);
  const steps = {
    hour: 1,
    minute: 30,
    second: 5,
  };

  return (
    <ul className="frequency-list Dailylist">
      <li>
        {" "}
        <label>Every</label>{" "}
      </li>

      <li className="">
        <RSInput2
          ph="Day(s)"
          val={props.campaignJson.frequency[0]["dayInterval"]}
          required="true"
          cb={(text) => {
            text = text.replace(/^0+(?=\d)/, '');
          
            if (text === "0" ) {
              props.onChange("0", "dayInterval");
            } else if (text === "0" && props.campaignJson.frequency[0].dayInterval !== "0") {
              return;
            } else if (text.length >= 3) {
              props.onChange("100", "dayInterval");
            } else if (text <= 101) {
              props.onChange(text, "dayInterval");
            }}}
          // cb={(text) => {
          //   if (101 < text) props.onChange(100, "dayInterval");
          //   else props.onChange(text, "dayInterval");
          // }}
          errorMessage={props.dailyError.dayInterval}
          // errorMessage={'Enter day interval'}
          validateCls="w-max-conent"
          keyboardType="number"
        />
      </li>
      <li>
        {" "}
        <label>day(s) @</label>{" "}
      </li>
      <li>
        <div
          className={
            props.editClickOff
              ? "position-relative click-off"
              : "position-relative"
          }
        >
          {props.dailyError.hourInterval && (
            <div className="validation-message">
              {props.dailyError.hourInterval}
            </div>
          )}
          <div className="rsptimer-picker">
            <TimePicker
              steps={steps}
              className="required"
              required="true"
              defaultValue={props.campaignJson.frequency[0]["hourInterval"]}
              onChange={(event) => {
                props.onChange(event.target.value, "hourInterval");
              }}
            />
          </div>
        </div>
      </li>
      <li>
        {" "}
        <label>hours</label>{" "}
      </li>
    </ul>
  );
};

let dailyArray = [
  {
    days: "",
    hours: "",
  },
];
