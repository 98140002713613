import React, { Component } from "react";
import * as constants from "./constants";
import ContentLoader from 'react-content-loader'

const GridHead = () => (
  <ContentLoader
    style={{ width: '100%' }}
    viewBox={`0 0 100% ${constants.sheightMd}`}
    height={50}
    backgroundColor={constants.scolor1}
    foregroundColor={constants.scolor2}
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="33" />
  </ContentLoader>
)

class SkeletonTable extends Component {

  state = {
    rowCount: 8
  }

  componentDidMount = () => {
    this.setState({
      rowCount: this.props?.count || 8
    })
  }

  render() {
    return (
      <div className="no-data-container">
        {
          this.props.isError
            ? <>
              {this.props.message ? <div className="nodata-bar">
                <p className="d-flex align-items-center">{this.props.message}</p>
              </div> :
                this.props.text ?
                  <div className="nodata-bar">
                    <p className="d-flex align-items-center">
                      <span>Click</span>
                      <span className="nodata-icon"><i className="icon-sd-circle-plus-fill-edge-medium icon-md blue"></i></span>
                      <span>{this.props.text}</span>
                    </p>
                  </div> :
                  <div className="nodata-bar"><i className="icon-sd-alert-medium icons-md orange-medium" />
                    <p>{"No data available"}</p>
                  </div>
              }
            </>
            : null
        }
        {
          Array(this.state.rowCount).fill(0).map((index) => {
            return <><GridHead key={index} /> <br /></>
          })
        }
        {/* <GridHead /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> <br />
        <GridView /> */}
        {/* <br />
        <GridViewPage /> */}
      </div >
    )
  }
}

export default SkeletonTable;