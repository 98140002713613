import { DropDownButton } from '@progress/kendo-react-buttons';
import * as React from 'react';
export const VSKendoIconDropdown = ({
    errorMessage,
    className,
    data,
    icon,
    popupClass,
    dir,
    onItemClick,
    itemRender,
    popupSettings,
    textField,
    defaultValue,
    containerClass = '',
    ...props
}) => {
    return (
        <div className={`rs-icon-dropdown ${containerClass}`}>
            {errorMessage && <div className="validation-message">{errorMessage}</div>}
            {defaultValue}
            <DropDownButton
                className={className}
                items={data}
                textField={textField}
                icon={icon}
                popupSettings={{
                    animate: true,
                    popupClass: `ic-dropdown subscribe-list ${popupClass}`,
                    ...popupSettings,
                }}
                itemRender={(props) => itemRender && itemRender(props)}
                dir={dir ? dir : 'ltr'}
                onItemClick={(e) => onItemClick && onItemClick(e)}
                {...props}
            />
        </div>
    );
};