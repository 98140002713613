import React, { useState } from 'react';
import { Range, getTrackBackground } from "react-range";
import "./RSSplitAB.css";
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';

export const RSSplitAB = (props) => {

    const STEP = 4;
    const MIN = 0;
    const MAX = 100;

    const [value, setValue] = useState([50])
    // console.log("Range:", value[0]);

    let rangePercentage = Math.round(value[0] / 2);
    let rangeCount = Math.round(value[0]);
    let total = 100;
    let splitBalance = total - rangeCount;

    const handleCancel = () => {
        props.onCancel()
    }

    return (
        <div className="sp-split-progress-main-wrapper">
            <div className="sp-split-progress-main">
                <div
                    className="sp-split-progress-container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap"
                    }}
                >
                    <Range
                        values={value}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={(values) => setValue(values)}
                        renderTrack={({ props, children }) => (
                            <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%"
                                }}
                            >
                            <div
                                ref={props.ref}
                                style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                        values: value,
                                        colors: ["#548BF4", "#ccc"],
                                        min: MIN,
                                        max: MAX
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "42px",
                                width: "42px",
                                borderRadius: "4px",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA"
                            }}
                        >
                        <div
                            style={{
                                height: "16px",
                                width: "5px",
                                backgroundColor: isDragged ? "#548BF4" : "#CCC"
                            }}
                        />
                        </div>
                    )}
                    />
                </div>
                <div className="sp-split-progress-wrapper">
                    <div
                        className="sp-split-progress-ab"
                        style={{ color: "red", width: `${value[0]}%` }}
                    >
                        <div className="sp-split-progress sp-split-progress-a">
                            <div className="sp-split-progress-wrap">
                                <span className="sp-circle">A</span>
                                <span className="sp-percentage">{rangePercentage}%</span>
                            </div>
                        </div>
                        <div className="sp-split-progress sp-split-progress-b">
                            <div className="sp-split-progress-wrap">
                                <span className="sp-circle">B</span>
                                <span className="sp-percentage">{rangePercentage}%</span>
                            </div>
                        </div>
                    </div>
                    <div className="sp-split-progress-text">
                        <p className="mb0">% Viewing your communication</p>
                        <span className="font-sm font-bold">{splitBalance}%</span>
                    </div>
                </div>
            </div>
            <div className="btn-container d-flex justify-content-end mb20">
                <RSSecondaryBtn onClick={() => { handleCancel() }} >Cancel</RSSecondaryBtn>
                <RSPrimaryBtn onClick={() => { console.log('Save') }} >Save</RSPrimaryBtn>
            </div>
        </div>
    );
}