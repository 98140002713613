import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";

const PublishModal = ({ isShow, setIsShow, data }) => {
  return (
    <>
      <Modal
        show={isShow}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
        onHide={() => {
          setIsShow(false);
        }}
      >
        <Modal.Header closeButton>
          <h2>Publish landing page</h2>
        </Modal.Header>
        <Modal.Body>
          <Row className="split-ab-modal">
            <Col>
              <RSInput2
                required={true}
                disabled
                val={data}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container d-flex justify-content-end">
            <RSSecondaryBtn
              padding="0px"
              onClick={() => {
                setIsShow(false);
              }}
            >
              Cancel
            </RSSecondaryBtn>

            <RSPrimaryBtn
              className={""}
              onClick={() => {
                navigator.clipboard.writeText(data);
                setIsShow(false);
              }}
            >
              Copy
            </RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublishModal;
