import React from "react";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSInput2, RSRadio } from "Components/RSInputs";
import { Col, Modal, Row } from "react-bootstrap";

class SupperssionListModal extends React.Component {
  state = {
    audienceLimit: "",
    isModalOpen: true,
    audienceLimitCount: "",
    equalsValue: "",
    equalsDropDownValue: "Incremental",
    baseVolume: "",
    incrementalValue: "",
  };
  render() {
    return (
      <Modal backdrop="static"
        keyboard={false} show={true} centered>
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <h2>List control</h2>
              <p className="ml10 mb0">Potential audience : <span>679,500</span></p>
            </div>
            <i className="icon-sd-close-mini blue cp p-close" onClick={() => {
              this.setState({ isModalOpen: !this.state.isModalOpen });
              this.props.onChangeIsOpen(false);
            }}></i>
          </div>
        </Modal.Header>
        <Modal.Body>

          <Row className="mt10 mb20">
            <Col md={4}>
              <label className="mt3">Limit audience</label>
            </Col>
            <Col md={3}>
              <RSRadio
                lbl="One time"
                name="limitAudience"
                labelId="onetime"
                cb={(event) => {
                  // this.state.audienceLimit = event.target.value;
                  this.setState({ audienceLimit: event.target.value });
                  this.forceUpdate();
                }}
              />
            </Col>
            <Col md={3}>
              <RSRadio
                lbl="By day"
                name="limitAudience"
                labelId="byday"
                cb={(event) => {
                  // this.state.audienceLimit = event.target.value;
                  this.setState({ audienceLimit: event.target.value });
                  this.forceUpdate();
                }}
              />
            </Col>
          </Row>


          {this.state.audienceLimit.toLowerCase() === "one time"
            ? (<Row>
              <Col>
                <RSInput2
                  ph="Enter the limit audience count"
                  cls="marginB0"
                  val={this.state.audienceLimitCount}
                  cb={(value) => {
                    this.state.audienceLimitCount = value;
                    this.forceUpdate();
                  }}
                />
              </Col>
            </Row>
            ) : null}

          {this.state.audienceLimit.toLowerCase() === "by day"
            && this.state.equalsDropDownValue === "Equals"
            ? (
              <Row>
                <Col md={6}>
                  <RSDropdownList
                    data={["Incremental", "Equals"]}
                    defaultValue={this.state.equalsDropDownValue}
                    // value={this.state.equalsDropDownValue}
                    onChange={(event) => {
                      this.state.equalsDropDownValue = event.target.value;
                      this.forceUpdate();
                    }}
                  ></RSDropdownList>
                </Col>
                <Col md={5}>
                  <RSInput2
                    ph="Enter volume per day"
                    cls="marginB0"
                    val={this.state.equalsValue}
                    cb={(value) => {
                      this.state.equalsValue = value;
                      this.forceUpdate();
                    }}
                  />
                  <div className="input-outer-set">
                    <i className="grey icon-arrow-right-fill-mini icons-sm"></i>
                  </div>
                </Col>
              </Row>
            ) : null}

          {this.state.audienceLimit.toLowerCase() === "by day"
            && this.state.equalsDropDownValue === "Incremental"
            ? (
              <Row>
                <Col md={6}>
                  <RSDropdownList
                    data={operatorData}
                    defaultValue={this.state.equalsDropDownValue}
                    // value={this.state.equalsDropDownValue}
                    onChange={(event) => {
                      this.state.equalsDropDownValue = event.target.value;
                    }}
                  ></RSDropdownList>
                </Col>
                <Col md={6}>
                  <RSInput2
                    ph="Base volume"
                    cls="marginB0"
                    val={this.state.baseVolume}
                    cb={(value) => {
                      this.state.baseVolume = value;
                      this.forceUpdate();
                    }}
                  />
                </Col>
                <Col md={6}>
                  <RSInput2
                    ph="Incremental"
                    cls="marginB0"
                    val={this.state.incrementalValue}
                    cb={(value) => {
                      this.state.incrementalValue = value;
                      this.forceUpdate();
                    }}
                  />
                  <div className="input-outer-set">
                    <i className="grey icon-arrow-right-fill-mini icons-sm"></i>
                  </div>
                </Col>

              </Row>
            ) : null}

        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container d-flex justify-content-end mt30">
            <RSSecondaryBtn
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
              }}
            >
              Cancel
            </RSSecondaryBtn>
            <RSPrimaryBtn
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
              }}
            >
              Save
            </RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SupperssionListModal;
const operatorData = ["Equals", "Incremental"];
