import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { RTabbar } from 'Components/RChart/RTabbar';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import SubscriptionInvoice from './Components/Tabs/SegmentationLists/SubscriptionInvoice';
import DynamicList from './Components/Tabs/DynamicList/DynamicList';

const AudienceHome = props => {

    const history = useHistory()

    const [index, setIndex] = useState(history?.location?.state !== null && history?.location?.state !== undefined ? history?.location?.state?.index : 0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function renderComponent(index) {
        var renderComponent;
        if (index === 0) { renderComponent = <SubscriptionInvoice history={history} /> }
        else if (index === 1) { renderComponent = <DynamicList /> }
        return renderComponent
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                {/* <div className="d-flex justify-content-between mt15">
                    <h1 className="header-title-margin">Audience</h1>
                </div> */}

                {/* <div className="tabs-filled-component-del">
                    <RTabbar
                        defaultSelectedItem={index}
                        defaultClass="tabDefault"
                        dynamicTab="tabs-filled-component marginB0"
                        activeClass="tabDefault active"
                        tabData={tabData}
                        callBack={(item, index) => { setIndex(index) }}
                    />
                </div> */}
                {/* {renderComponent(index)} */}

                <SubscriptionInvoice history={history} />

            </Container>
        </LayoutPageWrapper>
    )
}
export default AudienceHome;

const tabData = [
    { "id": 1001, "text": "Segmentation lists", "disable": false },
    { "id": 1002, "text": "Dynamic lists", "disable": true }
]