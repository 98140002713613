import React from "react";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { Container } from "react-bootstrap";
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { withRouter, useHistory } from "react-router-dom";
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { AUDIT_BIND_LIST } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { useContext } from "react";
import { useState } from "react";
import { connectServer } from "Helper/Network/networkHandler";
import { useEffect } from "react";
import { dateFormatWithHyphen, dateTimeFormatWithMoment, dateTimeFormatWithMomentForReports, getAuthUserId } from "Helper/Utils/Utils";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { process } from "@progress/kendo-data-query";
const defaultDataState = {
  skip: 0,
  take: 5,
  sort: [
    {
      field: "orderDate",
      dir: "desc",
    },
  ],
};

const AuditLog = (props) => {
  const context = useContext(authContext);
  const history = useHistory();

  const [auditList, setAuditList] = useState([]);
  const [listError, setListError] = useState(false);
  const [dataState, setDataState] = useState(defaultDataState);
  const [dataResult, setDataResult] = useState(null);
  const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);
  const [isShowDateRange, setIsShowDateRange] = useState(true);
  const [fetchDataParams, setFetchDataParams] = useState({
    endDate: "",
    startDate: "",
    userId: getAuthUserId(),
    pageNumber: 1,
    itemsPerPage: 5,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getFormListAllData(fetchDataParams);
  }, []);

  const getFormListAllData = (params) => {
    setDataResult(null);
    setListError(false);
    connectServer({
      path: AUDIT_BIND_LIST,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      params,
      ok: (res) => {
        if (res.status && !!res.data?.userLog) {
          let newTemp = [...res?.data?.userLog];
          const tempDataState = {
            skip: 0,
            take: params?.itemsPerPage,
          };
          let some = process(newTemp, tempDataState);
          setAuditList(res?.data?.userLog);
          setTotalCount(res?.data?.totalRows);
          setDataResult(Array.prototype.reverse.call(some));
          setListError(false);
        } else {
          setDataResult(null);
          setListError(true);
        }
      },
      fail: (err) => {
        setDataResult(null);
        setListError(true);
      },
    });
  };

  const dataStateChange = (event) => {
    const { skip, take } = event.dataState;
    const currentPage = Math.floor(skip / take + 1);
    setDataState(event.dataState);
    setDataResult(process(auditList, event.dataState));
    let tempFetchDataParams = { ...fetchDataParams };
    tempFetchDataParams.itemsPerPage = take;
    tempFetchDataParams.pageNumber = currentPage;
    setFetchDataParams(tempFetchDataParams);
    getFormListAllData(tempFetchDataParams);
  };

  // search and date
  const closeDateRangePicker = (status, dates) => {
    if (!status && !!dates) {
      let tempFetchDataParams = { ...fetchDataParams };
      tempFetchDataParams.startDate = dateFormatWithHyphen(new Date(dates?.start));
      tempFetchDataParams.endDate = dateFormatWithHyphen(new Date(dates?.end));
      tempFetchDataParams.pageNumber = 1;
      tempFetchDataParams.itemsPerPage = 5;
      getFormListAllData(tempFetchDataParams);
      setFetchDataParams(tempFetchDataParams);
      setDataState(dataState);
    }
    setIsShowDateRangePicker(status);
  };

  return (
    <>
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <div className="header-title d-flex justify-content-between">
            <h1>Audit log report</h1>
            <div
              className="back-text"
              onClick={() => history.push(`/analytics`)}
            >
              <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
              Back
            </div>
          </div>

          <hr className="blue" />
          <div className="d-flex justify-content-between mt15 mb15">
            <div></div>
            <div className="d-flex align-items-center">
              <RSPDateRangePicker
                class="marginR15"
                enable={true}
                show={isShowDateRange}
                isShowDateRangePicker={isShowDateRangePicker}
                closeDateRangePicker={(status, date) =>
                  closeDateRangePicker(status, date)
                }
                cancelBtnClicked={() => {
                  setIsShowDateRangePicker(false);
                }}
              />
            </div>
          </div>
          {dataResult ? (
            <Grid
              data={dataResult}
              {...dataState}
              onDataStateChange={dataStateChange}
              scrollable={"none"}
              className="grid-pagenation-del grid-replaceable"
              sortable={true}
              total={totalCount}
              pageable={{
                //type: 'input',
                pageSizes: true,
                previousNext: true,
              }}
            >
              <GridColumn
                cell={DateCell}
                columnMenu={ColumnMenu}
                field="createdDate"
                title="Date & time"
                width="200px"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="userName"
                title="Username"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="moduleName"
                title="Module"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="description"
                title="Description"
              />
            </Grid>
          ) : (
            <SkeletonTable
              count={5}
              isError={listError}
            />
          )}
        </Container>
      </LayoutPageWrapper>
    </>
  );
};

export default withRouter(AuditLog);

const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};

const DateCell = (props) => {
  return (
    <td>
      {dateTimeFormatWithMomentForReports(props.dataItem[props.field])}
    </td>
  )
}
