import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { ListBox, ListBoxToolbar, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { RSPPophover } from 'Components/Tooltip';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2, RSRadio } from 'Components/RSInputs';

const SELECTED_FIELD = "selected";
const toolbarTools = ["transferTo", "transferFrom"];

class ShareModalComponent extends React.Component {

  state = {
    isShow: this.props.isOpen,
    wishType: "Share",
    leftColumnValues: availableAttributes,
    rightColumnValues: selectedAttributues,
    draggedItem: {},
    shareRecordCount: "25,000"
  }

  handleItemClick = (event, data, connectedData) => {
    this.setState({
      [data]: this.state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: this.state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  handleToolBarClick = (e, data, connectedData) => {
    let result = processListBoxData(
      this.state[data],
      this.state[connectedData],
      e.toolName,
      SELECTED_FIELD
    );
    this.setState({
      [data]: result.listBoxOneData,
      [connectedData]: result.listBoxTwoData,
    });
  };

  handleDragStart = (e) => {
    e.dataItem.dataCollection = e.target.props.name;
    this.setState({
      draggedItem: e.dataItem,
    });
  };

  handleDrop = (e) => {
    let dragItemData = this.state.draggedItem.dataCollection;
    let dropItemData = e.target.props.name;
    let result = processListBoxDragAndDrop(
      this.state[dragItemData],
      this.state[dropItemData],
      this.state.draggedItem,
      e.dataItem,
      "name"
    );
    this.setState({
      [dragItemData]: result.listBoxOneData,
      [dropItemData]: result.listBoxTwoData,
    });
  };

  render() {
    return (
      <Modal backdrop="static"
        keyboard={false} show={this.state.isShow} centered size='lg' onHide={() => {
          this.setState({ isShow: !this.state.isShow });
          this.props.onChangeIsOpen(false);
        }}>
        <Modal.Header closeButton>
          <h2>Request to share/download</h2>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <label className="mt4">Would you like to</label>
            </Col>
            <Col md={3}>
              <div className="d-flex align-items-center marginB30">
                <RSRadio
                  lbl="Share"
                  name={"source"}
                  labelId={"sourceId"}
                  className=""
                  checked={this.state.wishType === "Share" ? true : false}
                  cb={(event) => {
                    this.setState({ wishType: event.target.value });
                  }} />
                <RSPPophover text="The format of the records will be hash " position="top">
                  <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp"></i>
                </RSPPophover>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex align-items-center marginB30">
                <RSRadio
                  lbl="Download"
                  name={"source1"}
                  labelId={"sourceId1"}
                  className=""
                  checked={this.state.wishType === "Download" ? true : false}
                  cb={(event) => {
                    this.setState({ wishType: event.target.value });
                  }} />
                <RSPPophover text="The download of the record cost may varies" position="top">
                  <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp"></i>
                </RSPPophover>
              </div>
            </Col>
          </Row>
          <Row className="row justify-content-between">
            <Col>
              <h6>Available attributes</h6>
              <ListBox
                style={{ height: 150, width: "100%" }}
                data={this.state.leftColumnValues}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) =>
                  this.handleItemClick(
                    e,
                    "leftColumnValues",
                    "rightColumnValues"
                  )
                }
                onDragStart={this.handleDragStart}
                onDrop={this.handleDrop}
                name="leftColumnValues"
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={toolbarTools}
                      data={this.state.leftColumnValues}
                      dataConnected={this.state.rightColumnValues}
                      onToolClick={(e) =>
                        this.handleToolBarClick(
                          e,
                          "leftColumnValues",
                          "rightColumnValues"
                        )
                      }
                    />
                  );
                }}
              />
            </Col>
            <Col>
              <h6>Selected attributes</h6>
              <ListBox
                style={{ height: 150, width: "100%" }}
                data={this.state.rightColumnValues}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) =>
                  this.handleItemClick(
                    e,
                    "rightColumnValues",
                    "leftColumnValues"
                  )
                }
                onDragStart={this.handleDragStart}
                onDrop={this.handleDrop}
                name="rightColumnValues"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label>No. of records to {this.state.wishType.toLowerCase()}</label>
            </Col>
            <Col md={4}>
              <RSInput2
                ph="Enter records count"
                val={this.state.shareRecordCount}
                cb={(text) => {
                  this.setState({ shareRecordCount: text });
                }} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <RSSecondaryBtn
            onClick={() => {
              this.setState({ isShow: !this.state.isShow });
              this.props.onChangeIsOpen(false);
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              this.setState({ isShow: !this.state.isShow });
              this.props.onChangeIsOpen(false);
            }}
          >
            {this.state.wishType}
          </RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }

}
export default ShareModalComponent;

const availableAttributes = [
  { name: "EmailID", selected: false },
  { name: "Mobile number", selected: false },
  { name: "Country", selected: false },
  { name: "City", selected: false },
  { name: "Region", selected: false },
  { name: "Company", selected: false },
  { name: "Industry", selected: false },
  { name: "Notes", selected: false },
  { name: "Engaged topics", selected: false },
  { name: "Mentions", selected: false },
  { name: "Object", selected: false },
  { name: "Scene", selected: false },
  { name: "Location", selected: false },
  { name: "People", selected: false },
  { name: "Building", selected: false },
  { name: "Abstract", selected: false },
  { name: "Emotions", selected: false },
  { name: "Sentiment", selected: false },
  { name: "Credit card limit", selected: false },
  { name: "Spend worth", selected: false },
  { name: "Loan period", selected: false },
  { name: "Loan amount", selected: false },
  { name: "Credit card opted", selected: false },
  { name: "Lifetime value", selected: false },
  { name: "lead score", selected: false },
  { name: "Loyalty/Usage", selected: false },
  { name: "Income", selected: false },
  { name: "Response", selected: false },
  { name: "Job function", selected: false }
];
const selectedAttributues = [
  { name: "EmailID", selected: false },
  { name: "Mobile number", selected: false },
  { name: "Company", selected: false },
  { name: "Industry", selected: false },
  { name: "Device", selected: false },
]