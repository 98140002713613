import { Grid, GridColumn } from '@progress/kendo-react-grid';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { RSPTooltip } from 'Components/Tooltip';
import { GET_EDM_GALLERY, GET_TOPLINK_CLICKS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import React, { Component } from 'react';

class QRTopLinks extends Component {
    static contextType = authContext
    state = {
        listClicksError: false,
        linkClickData: null,
        campaignGuid: this.props.campaignGuid,
        campaignId: this.props.campaignId,
        blastGuid: this.props.blastGuid,
        rowKey: this.props.rowKey,
        skip: 0,
        take: 10,
        linkPreviewData: null
    }
    componentDidMount = () => {
        if (this.state.rowKey !== "" && !!this.props.campaignGuid)
            this.getTopLinkClicks(this.state.rowKey)
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.setState({
                campaignGuid: this.props.campaignGuid,
                blastGuid: this.props.blastGuid,
                rowKey: this.props.rowKey,
                campaignId: this.props.campaignId,
            })
            if (this.props.rowKey !== prevProps.rowKey) {
                this.getTopLinkClicks(this.props.rowKey)
                this.getLinkPreview(this.props.campaignId, this.props.blastGuid)
            }
        }
    }
    getTopLinkClicks = rowKey => {

        const params = {
            "campaignGuid": this.state.campaignGuid,
            "channelId": 3,
            "rowkey": rowKey
        }

        connectServer({
            path: GET_TOPLINK_CLICKS,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res?.data?.length) {
                    this.setState({ linkClickData: res?.data })
                } else {
                    this.setState({ listClicksError: true })
                }
            },
            fail: err => this.setState({ listClicksError: true })
        })

    }

    getLinkPreview = (campaignId, blastGuid) => {

        const params = {
            "campaignId": campaignId,
            "blastguid": blastGuid
        }

        connectServer({
            path: GET_EDM_GALLERY,
            params,
            // loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ linkPreviewData: res.data.edmContent })
                }
            },
            fail: err => { }
        })

    }

    render() {
        return (<>
            <div className='d-flex align-items-center'>
                <h3 className="marginB15 mt15">Total link click activity</h3>
            </div>
            {this.state.linkClickData
                ? <Grid
                    data={this.state.linkClickData?.slice(this.state.skip, this.state.take + this.state.skip)}
                    total={this.state.linkClickData?.length}
                    scrollable={"none"}
                    className={`no-grid-pagenation-del grid-replaceable total-link-wrap-col ${this.state.linkClickData?.length > 5 ? '' : 'mb0'}`}
                >
                    <GridColumn field="sNo" title="S.No" />
                    <GridColumn field="url" title="Link (URL)" />
                    <GridColumn field="totalClicks" title="Total clicks" />
                    <GridColumn field="uniqueClicks" title="Unique clicks" />
                </Grid>
                : <SkeletonTable count={4} isError={this.state.listClicksError} />
            }</>);
    }
}

export default QRTopLinks;