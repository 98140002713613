import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { RSPrimaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";
import { connectServer } from "Helper/Network/networkHandler";
import { EDM_UPLOAD } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { isEmpty, isValidWebsite } from "Helper/Utils/Utils";
import {
  FILE_TYPE_MESSAGE,
  URL,
  ENTER_VALID_WEBSITE,
} from "Helper/Constants/validationMessage";

class ImportTabContent extends Component {

  static contextType = authContext;

  state = {
    campaignURL: "",
    iFrameValue: this.props.emailData,
    importZIPFile: {
      data: "",
      fileName: "",
    },
    importFileNameFromAPI: "",
    importFile: this.props.emailData ? true : false,
    push: {
      webUrl: "",
    },
    builderPreview: true,
    isAddFooter: false,
  };

  checkUploadFileType = (file) => {
    const validExtensions = [
      "zip",
      "x-zip-compressed",
      "rar",
      "gz",
      "7z",
      "sitx",
    ];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  importZip = (event) => {
    if (event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    if (!this.checkUploadFileType(file)) {
      this.setState({ errImportFileMsg: FILE_TYPE_MESSAGE });
    } else {
      this.setState({ errImportFileMsg: "" });
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      let fileName = event.target.files[0].name;
      let fileBytes = "";
      reader.onload = (e) => {
        fileBytes = reader.result.split(",").pop();
        this.setState({
          importZIPFile: { data: fileBytes, fileName: fileName },
        });
        let params = {
          importDescription: "sample adhoc 07Feb2022",
          fileName: this.state.importZIPFile.fileName,
          fileByte: this.state.importZIPFile.data,
          headers: "",
        };
        connectServer({
          path: EDM_UPLOAD,
          params,
          context: this.context,
          ok: (res) => {
            if (res.status) {
              this.setState({
                iFrameValue: res.data.html,
                importFileNameFromAPI: res.data.filePath,
                builderPreview: true,
              });
              this.props.getBodyContent(res.data.html);
            }
          },
          fail: (err) => { },
        });
      };
    }
  };

  onValidateUrl = () => {
    if (isEmpty(this.state.push.webUrl)) {
      this.setState({ errEnterURL: URL });
      return false;
    }
    if (!isValidWebsite(this.state.push.webUrl)) {
      this.setState({ errEnterURL: ENTER_VALID_WEBSITE });
      return;
    }
  };

  render() {
    return (
      <>
        <Row className={this.state.iFrameValue ? "click-off" : ""}>
          <Col>
            <Row
              className="custom-column"
              style={{ border: "1px solid #b8b8b8" }}
            >
              <Col className={`py15 borderR1 rs-radius-left ${this.state.importFile ? "col-md-3" : "col-md-9 bgGrey-L100"}`}
                onClick={() => this.setState({ importFile: false })}
              >
                <Row style={{ alignItems: "center" }}>
                  <Col className={`d-flex align-items-center ${this.state.importFile ? "col-md-12" : "col-md-3"}`}>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <i className="icon-sd-import-link-large icons-lg blue marginB7" />
                      <span>Import URL</span>
                    </div>
                  </Col>
                  {this.state.importFile ? (
                    ""
                  ) : (
                    <Col md={8} className="pl3">
                      <div className="d-flex align-items-center justify-content-center">
                        <RSInput2
                          ph="Enter URL"
                          val={this.state.push.webUrl}
                          cls="mb0"
                          cls2="mr25"
                          cb={(text) => {
                            let errMsg = text === "" ? URL : null;
                            this.setState({
                              errEnterURL: errMsg,
                              push: {
                                webUrl: text,
                              },
                            });
                          }}
                          errorMessage={this.state.errEnterURL}
                        />
                        <RSPrimaryBtn onClick={this.onValidateUrl}>
                          Go
                        </RSPrimaryBtn>
                      </div>
                    </Col>
                  )}
                </Row>
                <span className="or-divider import-or"></span>
              </Col>

              <Col className={`${this.state.importFile ? "col-md-9 justify-content-start bgGrey-L100" : "col-md-3 justify-content-center"} d-flex align-items-center`}>
                <div className="d-flex text-center flex-column"
                  onClick={() => {
                    this.setState({ importFile: true });
                  }}
                >
                  <i className="icon-sd-zip-large icons-lg blue cp marginB7" />
                  {this.state.errImportFileMsg && this.state.importFile && (
                    <div className="validation-message">
                      {this.state.errImportFileMsg}
                    </div>
                  )}
                  <span>Import ZIP file</span>
                  <span className="upload-lable-text">
                    {this.state.importZIPFile.fileName !== "" &&
                      this.state.importZIPFile.fileName}
                  </span>
                  <input
                    type="file"
                    className="browse-hidden"
                    accept=".zip, .rar"
                    onChange={this.importZip}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.iFrameValue && (
          <div className="import-iframe-wrapper">
            <div className="import-iframe">
              <iframe
                srcDoc={this.state.iFrameValue}
                style={{ width: "100%", minHeight: "100vh", pointerEvents: "none" }}
              ></iframe>
            </div>
          </div>
        )
        }
      </>
    );
  }
}

export default withRouter(ImportTabContent);
