import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { RSCheckbox } from 'Components/RSInputs';
import { RSSecondaryBtn, RSPrimaryBtn } from 'Components/RSButtons';

class ImportDataModal extends Component {
    state = {
        isShow: this.props.isOpen,
        isCustomer: false,
        isOrder: false,
        isCardApondment: false,
        selectedList: [],
        id: 0,
    }
    render() {
        return (
            <Modal backdrop="static"
                keyboard={false} size="md" show={this.state.isShow} centered onHide={() => {
                    this.setState({ isShow: false });
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header closeButton>
                    <h2>Import data</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="portlet-box-theme border1 p0 mb15 css-scrollbar checkbox-list-box">
                        <ul className="checkbox-list-modal">
                            <li className='even'>
                                <RSCheckbox
                                    className="mb0"
                                    lbl={'Customers'}
                                    checked={this.state.isCustomer}
                                    cb={status => {
                                        let temp = [...this.state.selectedList];

                                        temp.push({ id: this.state.id + 1, text: "Customers" });
                                        this.setState({ isCustomer: status, selectedList: temp, id: this.state.id + 1 });
                                    }} />
                            </li>
                            <li>
                                <RSCheckbox
                                    className="mb0"
                                    lbl={'Orders'}
                                    checked={this.state.isOrder}
                                    cb={status => {
                                        let temp = [...this.state.selectedList];
                                        temp.push({ id: this.state.id + 1, text: "Orders" });
                                        this.setState({ isOrder: status, selectedList: temp, id: this.state.id + 1 });
                                    }} />
                            </li>
                            <li className='even'>
                                <RSCheckbox
                                    className="mb0"
                                    lbl={'Card Abondment'}
                                    checked={this.state.isCardApondment}
                                    cb={status => {
                                        let temp = [...this.state.selectedList];
                                        temp.push({ id: this.state.id + 1, text: "Card Abondment" });
                                        this.setState({ isCardApondment: status, selectedList: temp, id: this.state.id + 1 });
                                    }} />
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <RSSecondaryBtn
                        onClick={() => {
                            this.setState({ isShow: false });
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn
                        className="m0"
                        onClick={() => {
                            this.setState({ isShow: false });
                            this.props.onSelectedDetails(this.state.selectedList);
                            this.props.onChangeIsOpen(false);
                        }}>Add</RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ImportDataModal;