import { getUserData } from 'Helper/Utils/Utils';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Buffer } from 'buffer'

const ResulPayModal = (props) => {
    const ref = React.useRef(null);
    // const navigate = useNavigate();

    const productName = 'M';
    const tenantShortCode = props.tenantShortCode || "";
    const clientName = Buffer.from(props.clientName).toString('base64') || "";
    const uniqueToken = 'yd109Iseo+WVgr58Tj6DazuRs0XUhCRzY3Mvir6ceMY=';
    const [isShow, setIsShow] = useState(props.isOpen)
    const tempURL = window.location.origin
    const orderId = props?.orderId;
    let finalAmount = props?.amount;
    const desc = 'campaignPayment';
    let redirectionURL =
        tempURL +
        '/communication?customparams=platform-marketing;name-vignesh&orderId=' +
        orderId +
        '&description=' +
        desc;
    let tempNavigation =
        'https://pay.resul.io/payment?amount=' +
        finalAmount +
        '&token=' +
        productName + "$" +
        tenantShortCode + "$" +
        clientName + "$" +
        uniqueToken +
        '&redirecturl=' +
        redirectionURL;

    return (
        <>
            <Modal
                show={isShow}
                // centered
                backdrop="static"
                onHide={() => {
                    setIsShow(!isShow);
                    props.onChangeIsOpen(false);
                }}
                keyboard={false}
                fullscreen={true}
                // size="lg"
                className="modal-top-fixed rupay-modal"
            >
                <Modal.Body >
                    <iframe
                        id="my-iframe"
                        name="myFrame"
                        src={tempNavigation}
                        width="100%"
                        height="100%"
                    />
                </Modal.Body>
            </Modal></>
    );

}

export default ResulPayModal;