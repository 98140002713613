import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { SearchViewAdvanced1 } from 'Components/SearchView/SearchView';
import { LIVE_SMS_PREVIEW, SAVE_SMS_CAMPAIGN, UPDATE_SMS_CAMPAIGN } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import mobileViewImg from "Assets/Images/preview-mobile-1.png";
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { dateFormat, dateTimeFormatWithMoment, getUserData } from 'Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { createMobileSMSCampaign } from '../../CamapignWrapper';
import { SuccessModal } from '../../EmailComponents/Components/SuccessModal';
import camera from 'Assets/Images/camera.png'
import { createMobileSMSCampaignMDC } from '../../../../MDCComponents/CamapignWrapper';
const defaultValue = new Date();
class SMSPreviewModal extends Component {

    static contextType = authContext;

    state = {
        isShow: this.props.isOpen,
        isEnable: false,
        smsData: this.props.mobileSMSData,
        searchData: null,
        livePreviewData: null,
        isSuccess: true
    }

    SMSConversation = () => {
        return (
            <>
                {/* <img src={mobileViewImg} alt="mobile popup" /> */}
                <div className="mobile-screen-bg">
                    <div className='campaign-sms-wrapper'>
                        <div className='campaign-sms-header'>
                            <div className="camp-sms-left"><span class="arrow-left">&lt;</span>Back</div>
                            <div className='camp-sms-center blue'>{getUserData()?.clientDetailsLocalization?.clientName}</div>
                            <div className="camp-sms-right">Contact</div>
                            {/* <div className='blue w100p text-center'>Vision Bank</div> */}
                        </div>
                        <div className='campaign-sms-body'>
                            <div className='chat-bubble-left'>
                                <div className='chat-bubble'>
                                    {/* <div className='chat-content'> */}
                                    <span className='campaign-message'>
                                        {this.state.livePreviewData ? this.state.livePreviewData.livePreviewBody : this.props.previewContent}
                                    </span>
                                    {/* </div> */}
                                </div>
                                <small className='message-date mt10'>{defaultValue ? dateTimeFormatWithMoment(defaultValue) : dateTimeFormatWithMoment(new Date())}</small>
                            </div>
                        </div>
                        <div className='campaign-sms-footer'>
                            <img className="in-img" src={camera} alt='camera' />
                            <div className="in-msg">Message</div>
                            <div className="in-send">Send</div>
                        </div>
                    </div>
                </div>

            </>
        );
    };

    liveEmailPreview = (searchDataFromChild) => {
        let tempAudienceList = [];
        let tempAudienceCount = 0;
        if (this.props.mobileSMSData.campaignType === "S") {
            this.props.mobileSMSData.targetList.map((item, index) => {
                tempAudienceList.push(
                    item.segmentationListID
                );
                tempAudienceCount = tempAudienceCount + item.recipientCount;
            });
        } else {
            this.props.channelDetails.segmentationListID.map((item, index) => {
                tempAudienceList.push(
                    item
                );
                tempAudienceCount = tempAudienceCount + item.recipientCount;
            });
        }
        let params = {
            campaignType: this.props.mobileSMSData.campaignType,
            campaignId: this.props.campaignId,
            levelNumber: this.props.mobileSMSData.campaignType === "M" ? this.props.channelDetails.LevelNumber : 1,
            senderId: this.props.mobileSMSData.senderId,
            testSmsMobileNo: this.props.mobileNumber,
            isFlashMessageEnabled: this.props.mobileSMSData.isFlashMessageEnabled,
            targetList: tempAudienceList,
            livePreviewType: searchDataFromChild?.id,
            livePreviewInput: searchDataFromChild?.value,
            content: this.props.mobileSMSData.content,
            smsType: "SMS",
            waTemplateName: null,
            waTemplateLang: null,
            waSenderId: null,
        }
        connectServer({
            path: LIVE_SMS_PREVIEW,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ livePreviewData: res.data, isEnable: true, isSuccess: true })
                } else {
                    this.setState({ isEnable: false, isSuccess: false })
                }
            },
            fail: err => {
                // 
            }
        })
    }

    render() {
        return (
            <>
                <Modal
                    show={this.state.isShow}
                    className='modal-top-fixed'
                    onHide={() => {
                        this.setState({ isShow: !this.state.isShow });
                        this.props.onChangeIsOpen(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <h2>Live preview</h2>
                        <div className="d-flex justify-content-end live-prev-search">
                            <SearchViewAdvanced1
                                cls='mr15'
                                modalTooltipCls='modal-tooltip'
                                dropdownData={['Email ID', 'Mobile no']}
                                onValidateSearch={(status, searchData) => {
                                    this.props.onValidateSearch(status)
                                    if (status) {
                                        this.liveEmailPreview(searchData)
                                    }
                                }}
                                isSearchValidate={this.props.isSearchValidate}
                                getSearchData={(item) => {
                                    this.setState({ searchData: item })
                                }}
                            />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="preview-mobile-popup">
                            <this.SMSConversation />
                            {/* <this.WhatsappConversation /> */}
                        </div>
                        {!this.state.isSuccess &&
                            <SuccessModal isOkButton={false} isOpen={!this.state.isSuccess} title={"Failure"} subTitle={"Invalid audience."} buttonClicked={(value) => {
                                this.setState({ isSuccess: true });
                            }} />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={!this.state.isEnable ? 'click-off' : ''}>
                            <RSSecondaryBtn onClick={() => {
                                this.setState({ isShow: !this.state.isShow });
                                this.props.onChangeIsOpen(false);
                            }}>Cancel</RSSecondaryBtn>
                            <RSPrimaryBtn onClick={() => {
                                let lpSMSCreatedBY = getUserData()?.emailId;
                                let lpSMSPassportID = this.state.livePreviewData.passportID;
                                let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
                                if (this.props.mobileSMSData.campaignType === "S") {
                                    let params = createMobileSMSCampaign("S", this.props.campaignId, this.props.mobileSMSData, false, lpSMSCreatedBY, lpSMSPassportID)
                                    connectServer({
                                        path: this.props.smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                                        params,
                                        loading: this.context.globalStateData.setIsLoadingData,
                                        sessionOut: this.context.globalStateData.setSessionOutData,
                                        context: this.context,
                                        ok: (res) => {
                                            if (res.status) {
                                                this.props.onChangeSmsChannelId(res.data.smsChannelDetailId)
                                                this.setState({ isShow: !this.state.isShow });
                                                this.props.onChangeIsOpen(true);
                                            } else {
                                                this.props.onChangeIsOpen(false);
                                            }
                                        },
                                        fail: (err) => {
                                        },
                                    });
                                } else {
                                    this.props.mobileSMSData.campaignId = this.props.campaignId;
                                    this.props.mobileSMSData.levelNumber = this.props.channelDetails.LevelNumber;
                                    this.props.channelDetails.totalaudience = this.props.totalaudience;
                                    let params = createMobileSMSCampaignMDC(
                                        "M",
                                        this.props.campaignId,
                                        this.props.mobileSMSData,
                                        this.props.senderId,
                                        this.props.language.languageId,
                                        this.props.schedule,
                                        this.props.mobileNumber,
                                        this.props.countryDialCodeMobile,
                                        this.props.channelDetails,
                                        false,
                                        lpSMSCreatedBY,
                                        lpSMSPassportID
                                    );
                                    connectServer({
                                        path: this.props.smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                                        params,
                                        loading: this.context.globalStateData.setIsLoadingData,
                                        sessionOut: this.context.globalStateData.setSessionOutData,
                                        context: this.context,
                                        ok: (res) => {
                                            if (res.status) {
                                                this.props.onChangeSmsChannelId(res.data.smsChannelDetailId)
                                                this.setState({ isShow: !this.state.isShow });
                                                this.props.onChangeIsOpen(true);
                                            } else {
                                                this.props.onChangeIsOpen(false);
                                            }
                                        },
                                        fail: (err) => { },
                                    });
                                }
                            }}>Send to me</RSPrimaryBtn>
                        </div>
                    </Modal.Footer>
                </Modal></>
        );
    }
}

export default SMSPreviewModal;