import RESTextEditor from 'Components/Editor/RESTextEditor';
import { RTabbar } from 'Components/RChart/RTabbar';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RSPTooltip } from 'Components/Tooltip';
import { DropDownButton } from '@progress/kendo-react-buttons';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import SubjectLineModal from './SubjectLineModal';
import RSEmojiPicker from 'Components/Emoji/EmojiPicker';
import { BODY, PRE_HEADER_MESSAGE, SUBJECT_LINE, SCHEDULE_DATE, UN_SUBSCRIPTION } from 'Helper/Constants/validationMessage';
import ImportTabContent from './ImportTabContent';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { CustomPopup } from "Components/DateRangePicker/ScheduleCustomPopup";
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { GET_FOOTER_LIST, GET_UNSUBSCRIPTION_LIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import { Switch } from "@progress/kendo-react-inputs";
import { BootstrapDropdownIcon } from 'Components/BootstrapDropdown/BootstrapDropdown';
import authContext from 'Helper/StateHandler/auth-context';
import EmailPreviewModal from './EmailPreviewModal';
import { addMinutes, getContent, getLsMasterDataDropdown, getUserData } from 'Helper/Utils/Utils';

const subjectLinePersonalizeTabData = [{ text: "[[firstname]]" }, { text: "[[lastname]]" }, { text: "[[age]]" }];
class SplitContent extends Component {
    static contextType = authContext
    tabs = [
        {
            text: "Text",
            iconLeft: "icon-text-document-large icons-lg marginB10",
            disable: false,
            contentType: "R",
        },
        {
            text: "Import",
            iconLeft: "icon-import-file-large icons-lg marginB10",
            disable: false,
            contentType: "Z",
        },
        {
            text: "Template",
            iconLeft: "icon-template-large icons-lg marginB10",
            disable: true,
            contentType: "T",
        },
    ];
    state = {
        isRefreshModal: false,
        headerMsg: this.props.splitEmailData.preHeaderMessage,
        isAddViewBrowser: this.props.splitEmailData.isViewinBrowserChecked,
        sendTestMailArray: [],
        isModalOpen: false,
        activeTab: this.props.splitEmailData.contentType === "R"
            ? 0
            : this.props.splitEmailData.contentType === "Z"
                ? 1
                : this.props.splitEmailData.contentType === "T"
                    ? 2
                    : -1,
        isOpenSubjectModal: false,
        clicked: "",
        isShow: false,
        subjectLine: this.props.splitEmailData.subjectLine,
        errBody: this.props.splitError.errBodyMsg,
        errSendTestMailMsg: this.props.splitError.errSendTestMailMsg,
        errSubjectLineMsg: this.props.splitError.errSubjectLineMsg,
        errScheduleDateMsg: this.props.splitError.errScheduleDateMsg,
        errEnableUnSubMsg: this.props.splitError.errEnableUnSubMsg,
        errUnSubMsg: this.props.splitError.errUnSubMsg,
        isShowFooterComponent: this.props.splitEmailData.body !== "" ? true : false,
        isEditTimeZone: false,
        timeZone: null,
        timeZoneData: [],
        isDayLightSavings: false,
        isSendRecommendation: false,
        schedule: this.props.splitEmailData.localBlastDateTime,
        startDate: new Date(this.props.startDate),
        endDate: new Date(this.props.endDate),
        checked: this.props.splitEmailData.unsubscribeSettingId !== 0 ? true : false,
        unsubscriptionList: [],
        unsubscription: {
            unsubscribeSettingId: 0,
            unsubscribeName: '-- Select --'
        },
        isAddFooter: this.props.splitEmailData.isFooterEnabled,
        emailFooterList: [],
        emailFooter: null,
        emailFooterHtml: "",
        isEmailPreviewModal: false,
    }
    constructor(props) {
        super(props);
        this.emojianochor = React.createRef();
        this.updateTabStatus();
    }
    componentDidMount = () => {
        this.getTimeZoneList();
        this.getUnsubscriptionList()
        this.getEmailFooterList()
        if (this.props.campaignHeader) {
            this.setState({
                startDate: new Date(this.props.campaignHeader.startDate),
                endDate: new Date(this.props.campaignHeader.endDate),
            });
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({
                errBody: this.props.splitError.errBodyMsg,
                errSendTestMailMsg: this.props.splitError.errSendTestMailMsg,
                errSubjectLineMsg: this.props.splitError.errSubjectLineMsg,
                errScheduleDateMsg: this.props.splitError.errScheduleDateMsg,
                errEnableUnSubMsg: this.props.splitError.errEnableUnSubMsg,
                errUnSubMsg: this.props.splitError.errUnSubMsg,
                subjectLine: this.props.splitEmailData.subjectLine,
                headerMsg: this.props.splitEmailData.preHeaderMessage,
                isAddFooter: this.props.splitEmailData.isFooterEnabled,
                checked: this.props.splitEmailData.unsubscribeSettingId !== 0 ? true : false,
                unsubscription: this.state.unsubscriptionList.filter(item => item.unsubscribeSettingId === this.props.splitEmailData.unsubscribeSettingId)[0],
                activeTab:
                    this.props.splitEmailData.contentType === "R"
                        ? 0
                        : this.props.splitEmailData.contentType === "Z"
                            ? 1
                            : this.props.splitEmailData.contentType === "T"
                                ? 2
                                : -1,
            })
            this.updateTabStatus();
        }

    }
    getUnsubscriptionList = () => {
        const params = {}
        connectServer({
            path: GET_UNSUBSCRIPTION_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let unsubscribe = res.data.filter(item => item.unsubscribeSettingId === this.props.splitEmailData.unsubscribeSettingId)[0];
                    this.setState({ unsubscriptionList: res.data || [], unsubscription: unsubscribe })
                }
            },
            fail: err => {
            }
        })
    }

    getEmailFooterList = () => {
        const params = {}
        connectServer({
            path: GET_FOOTER_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let footerRawHtml = res.data.emailfooterList.filter(item => item.emailfooterID === this.props.splitEmailData.footerId)[0]?.emailFooterRawcode;
                    this.setState({ emailFooterList: res.data.emailfooterList || [], emailFooterHtml: footerRawHtml })
                }
            },
            fail: err => {
            }
        })
    }
    getTimeZoneList = () => {
        const data = getLsMasterDataDropdown();
        const loggedDetails = getUserData();
        let filterTimezone = null;
        if (this.props.mode === "add") {
            filterTimezone = data.timeZone.filter(
                (item) =>
                    item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId
            )[0];
        } else {
            filterTimezone = data.timeZone.filter(
                (item) => item.timezoneId === this.props.splitEmailData.timezoneId
            )[0];
        }
        this.setState({ timeZoneData: data.timeZone, timeZone: filterTimezone });
    };
    updateTabStatus = () => {
        if (this.props.splitEmailData.contentType === "R") {
            this.setState({ activeTab: 0 });
            const newData = this.tabs;
            newData[0].disable = false;
            newData[1].disable = true;
            newData[2].disable = true;
        } else if (this.props.splitEmailData.contentType === "Z") {
            this.setState({ activeTab: 1 });
            const newData = this.tabs;
            newData[0].disable = true;
            newData[1].disable = false;
            newData[2].disable = true;
        } else if (this.props.splitEmailData.contentType === "T") {
            this.setState({ activeTab: 2 });
            const newData = this.tabs;
            newData[0].disable = true;
            newData[1].disable = true;
            newData[2].disable = false;
        } else {
            this.setState({ activeTab: -1 });
            const newData = this.tabs;
            newData[0].disable = false;
            newData[1].disable = false;
            newData[2].disable = true;
        }
    };
    buttonClick = (clicked) => {
        this.setState({ clicked: clicked, isShow: true });
    };
    header = (props) => {
        return (
            <div className="even marginT40 rich-push-tab">
                <div className="campaign-tab-bar marginY0 position-relative">
                    <Row className="d-flex align-items-center custom-column column-tab">
                        <Col md={{ span: 2, offset: 0 }} className="marginL25">
                            <label className="">
                                Email content
                            </label>
                        </Col>
                        <Col md={6} className="pl20">
                            <RTabbar
                                defaultSelectedItem={this.state.activeTab}
                                defaultClass="tabDefault"
                                dynamicTab="marginB0 rs-tab-icon or-tab flex-tab"
                                activeClass="tabDefault active"
                                tabData={this.tabs}
                                or={true}
                                callBack={(item, index) => {
                                    this.props.splitEmailData.contentType =
                                        item.contentType;
                                    this.updateTabStatus();
                                }}
                            />
                        </Col>
                    </Row>
                    {(this.state.activeTab !== -1) &&
                        <div className="refresh-icon">
                            <RSPTooltip text="Refresh" position="top">
                                <i className="icon-sd-refresh-medium icons-md blue"
                                    onClick={() => {
                                        this.setState({ isRefreshModal: !this.state.isRefreshModal });
                                    }} />
                            </RSPTooltip>
                        </div>}
                </div>
            </div>
        );
    };
    render() {
        return (
            <>
                <Row className="custom-column">
                    <Col md={3}>
                        <label className="label-row">Subject line</label>
                    </Col>
                    <Col md={6}>
                        {/* <RSInput2
                            ph="Enter subject line"
                            val={this.state.subjectLine}
                            cb={(text) => {
                                let errMsg = text === "" ? SUBJECT_LINE : null;
                                this.props.splitEmailData.subjectLine = text;
                                this.props.splitError.errSubjectLineMsg = errMsg;
                                this.setState({ subjectLine: text, errSubjectLineMsg: errMsg })
                            }}
                            errorMessage={this.state.errSubjectLineMsg} /> */}
                        <div className="position-relative">
                            {this.state.errSubjectLineMsg && (
                                <div className="validation-message">
                                    {this.state.errSubjectLineMsg}
                                </div>
                            )}
                            <input
                                type="textarea"
                                className="marginB5 required emoji-font"
                                placeholder="Enter subject line"
                                value={this.state.subjectLine}
                                onBlur={(event) => { this.props.splitEmailData.subTitlePosition = event.target.selectionStart; }}
                                onChange={(event) => {
                                    if (event.target.value.length >= 60)
                                        return
                                    let errMsg = event.target.value.length === "" ? SUBJECT_LINE : null;
                                    this.setState({ subjectLine: event.target.value, errSubjectLineMsg: errMsg });
                                    this.props.splitEmailData.subjectLine = event.target.value;
                                    this.props.splitError.errSubjectLineMsg = errMsg;
                                }}
                            />
                        </div>
                        {/* <div className="input-outer-set">
                            <i className="icon-spam-assassin-small icons-md blue" onClick={() => {
                                this.setState({ isOpenSubjectModal: !this.state.isOpenSubjectModal });
                            }} />
                        </div> */}
                        <div className="input-below-icon-set">
                            {/* <RSPTooltip text="Personalize" position="top"> */}
                            <div className="title-tip title-tip-up" title='Personalize'>
                                <DropDownButton
                                    className=""
                                    items={subjectLinePersonalizeTabData}
                                    icon=" icon-user-help-small blue icons-md"
                                    onItemClick={(e) => {
                                        let errMsg = e.item.text === "" ? SUBJECT_LINE : null;
                                        this.props.splitError.errSubjectLineMsg = errMsg;
                                        let obj = this.props.splitEmailData;
                                        let val = getContent(obj.subjectLine, " " + e.item + " ", obj.subTitlePosition);
                                        this.props.splitEmailData.subjectLine = val;
                                        this.setState({ subjectLine: val, errSubjectLineMsg: errMsg });
                                    }} />
                            </div>
                            {/* </RSPTooltip> */}
                            <i className="emoji-picker-icon emoji-picker icon-smiley2-small blue icons-md cp ml10"
                                ref={this.emojianochor}
                                onClick={() => {
                                    this.buttonClick(1);
                                }}></i>
                        </div>
                    </Col>
                </Row>
                <this.header />
                {this.props.splitEmailData.contentType !== "" &&
                    <div className="marginT25">
                        <Row className="custom-column marginT10 px20">
                            <Col md={12}>
                                {this.props.splitEmailData.contentType === "R" ?
                                    <div className="position-relative">
                                        {this.state.errBody && (
                                            <div className="validation-message">
                                                {this.state.errBody}
                                            </div>
                                        )}
                                        <RESTextEditor
                                            personalizeTabData={this.props.personalizeTabData}
                                            content={this.props.splitEmailData.textContent}
                                            callBack={(text, richText) => {
                                                let errMsg = text === "" ? BODY : null;
                                                this.props.splitEmailData.body = text;
                                                this.props.splitEmailData.textContent = richText;
                                                this.props.splitError.errBody = errMsg;
                                            }} />
                                    </div> : this.props.splitEmailData.contentType === "Z" ?
                                        <ImportTabContent
                                            emailData={this.props.emailData}
                                            contentData={this.props.splitEmailData}
                                            defaultError={this.props.splitError}
                                            getBodyContent={(bodyContent) => {
                                                this.props.splitEmailData.body = bodyContent;
                                            }}
                                            onValidateSearch={(status) => this.props.onValidateSearch(status)}
                                            isSearchValidate={this.props.isSearchValidate}
                                            onShowFooter={(value) => { this.setState({ isShowFooterComponent: value }) }}
                                            onValidateSpamCheck={(status) => this.props.onValidateSpamCheck(status)}
                                            isSpamCheckValidate={this.props.isSpamCheckValidate}
                                            emailfooterContent={this.state.emailFooterHtml}
                                        /> : null}
                            </Col>
                        </Row>

                        {this.props.splitEmailData.contentType === "R" &&
                            <Row className="custom-column mt30">
                                <Col md={3}>
                                    <label className="label-row">Preheader message</label>
                                </Col>
                                <Col md={6}>
                                    <RSInput2
                                        ph="Enter inbox first line message"
                                        val={this.state.headerMsg}
                                        cb={(text) => {
                                            this.props.splitEmailData.preHeaderMessage = text;
                                            this.setState({ headerMsg: text });
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <RSCheckbox
                                        className="mb0 mt10"
                                        lbl="Add view in browser"
                                        checked={this.state.isAddViewBrowser}
                                        cb={(status) => {
                                            this.setState({ isAddViewBrowser: status });
                                        }} />
                                </Col>
                            </Row>}
                        {this.state.isAddFooter &&
                            <div className="mt20 mb30">
                                <iframe
                                    title='footerContent'
                                    srcDoc={this.state.emailFooterHtml}
                                    style={{
                                        width: "100%",
                                        // pointerEvents: "none"
                                    }}
                                ></iframe>
                            </div>
                        }

                        {(this.props.splitEmailData.contentType === "R" || this.state.isShowFooterComponent)
                            ?
                            <Row className="custom-column mt30 mb30 d-flex align-items-center">
                                <Col md={3}>
                                    <label className="label-row truncate-line-1">{UN_SUBSCRIPTION}</label>
                                </Col>
                                <Col md={1}>
                                    <div className="position-relative">
                                        {this.state.errEnableUnSubMsg && (
                                            <div className="validation-message switch-valid">
                                                {this.state.errEnableUnSubMsg}
                                            </div>
                                        )}
                                        <Switch
                                            className="mt0 mr15"
                                            checked={this.state.checked}
                                            onChange={(e) => {
                                                if (e.value) {
                                                    this.setState({ checked: !this.state.checked, errEnableUnSubMsg: null })
                                                } else if (this.state.isAddFooter) {
                                                    this.setState({ checked: !this.state.checked, errEnableUnSubMsg: "Enable unsubscribe" })
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    {this.state.checked && this.state.unsubscriptionList && (
                                        <div className="position-relative">
                                            {this.state.errUnSubMsg && (
                                                <div className="validation-message">
                                                    {this.state.errUnSubMsg}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                className="mb0"
                                                data={this.state.unsubscriptionList || []}
                                                defaultItem={{
                                                    unsubscribeSettingId: 0,
                                                    unsubscribeName: '-- Select --'
                                                }}
                                                textField='unsubscribeName'
                                                dataItemKey='unsubscribeSettingId'
                                                value={this.state.unsubscription}
                                                onChange={(event) => {
                                                    let errMsg = event.target.value.unsubscribeSettingId !== 0 ? null : "Select unsubscribe";
                                                    this.props.splitEmailData.unsubscribeSettingId = event.target.value.unsubscribeSettingId;
                                                    this.props.splitError.errUnSubMsg = errMsg;
                                                    this.props.splitError.errEnableUnSubMsg = errMsg === null ? null : "Enable unsubscribe";
                                                    this.setState({ unsubscription: event.target.value, errUnSubMsg: errMsg })
                                                }}
                                            /></div>)
                                    }
                                </Col>
                                <Col className="padding0">
                                    <div className="d-flex justify-content-end">
                                        <ul className="d-flex  align-items-center justify-content-center">
                                            <li>
                                                <RSCheckbox
                                                    className="mt0 mb0"
                                                    lbl="Add email footer"
                                                    checked={this.state.isAddFooter}
                                                    cb={(status) => {
                                                        if (status) {
                                                            this.setState({ errEnableUnSubMsg: "Enable unsubscribe" })
                                                        }
                                                        this.props.splitEmailData.isFooterEnabled = status;
                                                        this.props.splitEmailData.footerId = this.state.emailFooterList.length > 0 ? this.state.emailFooterList[0].emailfooterID : 0;
                                                        this.props.getEmailFooterContent(this.state.emailFooterList.length > 0 ? this.state.emailFooterList[0].emailFooterRawcode : "");
                                                        this.setState({ isAddFooter: status, emailFooterHtml: this.state.emailFooterList.length > 0 ? this.state.emailFooterList[0].emailFooterRawcode : "" });
                                                    }}
                                                />
                                            </li>
                                            <li className="d-flex justify-content-center cp" onClick={() => {
                                                this.props.onChangeIsSearchValidate(false);
                                                this.setState({ isEmailPreviewModal: !this.state.isEmailPreviewModal })
                                            }}>
                                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;Email preview</span>
                                                {/* <RSPTooltip text="Email preview" position="top"> */}
                                                <i className="icon-email-preview-large icons-md blue ml5 mr15 cp" />
                                                {/* </RSPTooltip> */}
                                            </li>
                                            {this.state.isAddFooter &&
                                                <>
                                                    <li>
                                                        <RSPTooltip text="Email footer template" position="top">
                                                            <div>
                                                                {this.state.emailFooterList &&
                                                                    <BootstrapDropdownIcon
                                                                        data={this.state.emailFooterList}
                                                                        defaultItem={this.state.emailFooter}
                                                                        customAlignRight={true}
                                                                        className="fs18-btn mr15"
                                                                        alignRight
                                                                        fieldKey={'footername'}
                                                                        fixedIcon={<i className="icon-template-small icons-md blue posr t2" />}
                                                                        onSelect={(item, index) => {
                                                                            this.props.splitEmailData.footerId = item.emailfooterID;
                                                                            this.props.getEmailFooterContent(item.emailFooterRawcode);
                                                                            this.setState({ emailFooterHtml: item.emailFooterRawcode })
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </RSPTooltip>
                                                    </li>
                                                    <li>
                                                        <i className="icon-sd-circle-plus-fill-edge-medium icons-md blue posr t2 cp"
                                                            onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: '/preferences/communication-settings',
                                                                    state: { index: 2 }
                                                                })
                                                            }}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row> : null
                        }
                        {
                            this.state.isEmailPreviewModal &&
                            <EmailPreviewModal
                                isOpen={this.state.isEmailPreviewModal}
                                previewContent={this.props.splitEmailData.body}
                                onChangeIsOpen={(value) => {
                                    this.setState({ isEmailPreviewModal: value });
                                }}
                                onValidateSearch={(status) => this.props.onValidateSearch(status)}
                                isSearchValidate={this.props.isSearchValidate}
                            />
                        }
                        <Row className="custom-column schedule-even marginT30 paddingB10">
                            <Col md={3}>
                                <label className="label-row">Schedule</label>
                            </Col>
                            <Col md={6}>
                                <div className="position-relative">
                                    {this.state.errScheduleDateMsg && (
                                        <div className="validation-message">
                                            {this.state.errScheduleDateMsg}
                                        </div>
                                    )}
                                    <div className="rsptimer-picker">
                                        <DateTimePicker
                                            className="required"
                                            placeholder="Select schedule date and time"
                                            value={
                                                this.state.schedule !== ""
                                                    ? new Date(this.state.schedule)
                                                    : ""
                                            }
                                            // show={true}
                                            // min={this.state.startDate}
                                            min={addMinutes(new Date(this.state.startDate), 15)}
                                            max={this.state.endDate}
                                            format={"dd MMM, yyyy HH:mm"}
                                            popup={CustomPopup}
                                            onChange={(event) => {
                                                this.props.splitEmailData.localBlastDateTime =
                                                    event.target.value;
                                                let errMsg = this.props.splitEmailData.localBlastDateTime !== "" ? null : SCHEDULE_DATE
                                                this.props.splitError.errScheduleDateMsg = errMsg;
                                                this.setState({ schedule: event.target.value, errScheduleDateMsg: errMsg });
                                            }}
                                        />
                                    </div>
                                </div>
                                <Row className="marginT5">
                                    {/* <Col md="8">
                                        <div className="d-flex align-items-center marginB30">
                                            <RSCheckbox
                                                className="marginB0"
                                                lbl="Use send time recommendation"
                                                checked={this.state.isSendRecommendation}
                                                cb={(value) => {
                                                    this.setState({
                                                        isSendRecommendation:
                                                            !this.state.isSendRecommendation,
                                                    });
                                                }}
                                            />
                                            <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                        </div>
                                    </Col> */}
                                    {this.state.isEditTimeZone === false ? (
                                        <Col>
                                            <div className="float-end">
                                                <label>
                                                    {!!this.state.timeZone &&
                                                        this.state.timeZone.gmtOffset}
                                                </label>
                                                <RSPTooltip text="Edit" position="top">
                                                    <i
                                                        className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer"
                                                        onClick={() => {
                                                            this.setState({
                                                                isEditTimeZone: !this.state.isEditTimeZone,
                                                            });
                                                        }}
                                                    />
                                                </RSPTooltip>
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                        {this.state.isEditTimeZone ? (
                            <Row className="custom-column schedule-even pt0">
                                <Col md={3}>
                                    <label className="label-row">Select time zone</label>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        <RSDropdownList
                                            className="marginB0"
                                            data={this.state.timeZoneData || []}
                                            value={this.state.timeZone}
                                            defaultItem={{
                                                timezoneId: 0,
                                                timezoneName: "-- Select time zone --",
                                            }}
                                            textField="timezoneName"
                                            dataItemKey="timezoneId"
                                            onChange={(e) => {
                                                this.setState({ timeZone: e.target.value });
                                                this.props.splitEmailData.timezoneId =
                                                    e.target.value.timezoneId;
                                            }}
                                        />
                                    </div>
                                    <Row className="marginT5">
                                        <Col md="8">
                                            <div className="d-flex align-items-center">
                                                <RSCheckbox
                                                    className="marginT0 marginB15 click-off"
                                                    lbl="Daylight saving"
                                                    checked={this.state.isDayLightSavings}
                                                    cb={(value) => {
                                                        this.setState({
                                                            isDayLightSavings: !this.state.isDayLightSavings,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : null}
                    </div>}
                {this.state.isRefreshModal &&
                    <RSConfirmAlert
                        title="Are you sure you want to refresh?"
                        secondaryButtonText="Cancel"
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            if (value) {
                                this.props.splitEmailData.contentType = "";
                                this.props.splitEmailData.body = "";
                                this.props.splitEmailData.textContent = "";
                                this.props.splitEmailData.isFooterEnabled = false;
                                this.updateTabStatus();
                            }
                            this.setState({ isRefreshModal: false })
                        }}
                    />
                }
                {this.state.isOpenSubjectModal &&
                    <SubjectLineModal isOpen={this.state.isOpenSubjectModal} onChangeIsOpen={(value) => {
                        this.setState({ isOpenSubjectModal: value });
                    }} />
                }
                {this.state.clicked === 1 &&
                    <RSEmojiPicker
                        anchor={this.emojianochor}
                        show={this.state.isShow}
                        onEmojiClick={(value) => {
                            let obj = this.props.splitEmailData;
                            let val = getContent(obj.subjectLine, value, obj.subTitlePosition);
                            this.props.splitEmailData.subjectLine = val;
                            this.props.splitEmailData.subTitlePosition = obj.subTitlePosition + value.length;
                            this.setState({ subjectLine: val });
                        }} />
                }
            </>
        );
    }
}

export default SplitContent;