import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { isEmpty, getAuthClientId } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import { connectServer } from "Helper/Network/networkHandler";
import {
  GET_WHATSAPP_TYPE,
  GET_WHATSUP,
  SAVE_WHATSUP,
} from "Helper/Constants/endPoint";
import {
  SERV_PROVIDER,
  ACCESS_POINT,
  AUTHOR_KEY,
  WHATSAPP_SENDER_ID,
  SENDER_NAME,
  ENTER_TEMPLATE_NAME,
  WHATSAPP_TEMPLATE_NAME,
  KEY_WORDS,
} from "Helper/Constants/validationMessage";

const WhatsappCreation = (props) => {

  const context = useContext(authContext);

  //Drop Down Load Data from API
  const [serviceData, setServiceData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  //Drop Down KeyId and KeyName for service provider
  const [serviceProvider, setServiceProvider] = useState({
    keyId: 0,
    keyName: "-- Select provider --",
  });
  const [accessPoint, setAccessPoint] = useState("");
  const [authorizationKey, setAuthorizationKey] = useState("");
  const [errMsgServiceProvider, setErrServiceProvider] = useState(null);
  const [errMsgAccessPoint, setErrAccessPoint] = useState(null);
  const [errMsgErrAuthKey, setErrAuthKey] = useState(null);
  const [clientWaSettingId, setClientWaSettingId] = useState(0);
  const [mode, setMode] = useState(props.mode);
  const [deleteWaSender, setDeleteWaSender] = useState([]);
  const [deleteWaResponse, setDeleteWaResponse] = useState([]);
  const [senderDetailsList, setSenderDetailsList] = useState([
    { clientWASenderId: 0, senderId: "", senderName: "", templateName: "" },
  ]);
  const [errMsgSenderDetailsList, setErrMsgSenderDetailsList] = useState([
    {
      errMsgWhatsappSenderId: null,
      errMsgSenderName: null,
      errMsgTemplateName: null,
    },
  ]);
  const [whatsappResponseList, setWhatsappResponseList] = useState([
    {
      whatsappTemplate: {
        keyId: 0,
        keyName: "-- Select whatsApp template --",
      },
      responseId: 0,
      keywords: "",
    },
  ]);
  const [errMsgWhatsappResponseList, setErrMsgWhatsappResponseList] = useState([
    { errMsgwhatsappTemplate: null, errMsgKeyWords: null },
  ]);

  useEffect(() => {
    getWhatsAppType();
    if (props.mode === "edit") {
      getWhatsAppDataFromServer(clientWaSettingId);
    }
  }, []);

  const getWhatsAppDataFromServer = (clientWaSettingId) => {
    const params = {
      clientWASettingId: clientWaSettingId,
    };
    connectServer({
      path: GET_WHATSUP,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res?.data) {
            setAccessPoint(res.data.accessPoint);
            setAuthorizationKey(res.data.authorizationKey);
            let keyparams = {
              keyId: res.data.serviceProviderId,
              keyName: res.data.serviceProviderName,
            };
            setClientWaSettingId(res.data.clientWASettingId);
            setServiceProvider(keyparams);
            var senderLoadData = [];
            var errArrayLoad = [];
            res.data.whatsappSenderList?.map((item, index) => {
              var params = {
                clientWASenderId: item.clientWASenderId,
                senderId: item.senderId,
                senderName: item.senderName,
                templateName: item.templateName,
              };
              var errorParams = {
                errMsgWhatsappSenderId: null,
                errMsgSenderName: null,
                errMsgwhatsappTemplateName: null,
              };
              errArrayLoad.push(errorParams);
              senderLoadData.push(params);
            });
            setErrMsgSenderDetailsList(errArrayLoad);
            setSenderDetailsList(senderLoadData);
            var senderResponseData = [];
            var errRespLoad = [];
            res.data.whatsappResponseList?.map((item, index) => {
              var params = {
                whatsappTemplate: {
                  keyId: item.templateId,
                  keyName: item.templateName,
                },
                responseId: item.responseId,
                keywords: item.keywords,
              };
              var errorParams = {
                errMsgwhatsappTemplate: null,
                errMsgKeyWords: null,
              };
              senderResponseData.push(params);
              errRespLoad.push(errorParams);
            });
            setErrMsgWhatsappResponseList(errRespLoad);
            setWhatsappResponseList(senderResponseData);
          }
        }
      },
    });
    setMode(props.mode);
  };

  const getWhatsAppType = () => {
    connectServer({
      path: GET_WHATSAPP_TYPE,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            setServiceData(res.data.serviceProviderList || []);
            setTemplateData(res.data.templateList || []);
          }
        }
      },
      fail: (err) => { },
    });
  };

  const senderDetailsValidate = () => {
    for (let i = 0; i < senderDetailsList?.length; i++) {
      if (isEmpty(senderDetailsList[i].senderId)) {
        let errMsg = WHATSAPP_SENDER_ID;
        let temp = [...errMsgSenderDetailsList];
        temp[i].errMsgWhatsappSenderId = errMsg;
        setErrMsgSenderDetailsList(temp);
        return false;
      }
      if (isEmpty(senderDetailsList[i].senderName)) {
        let errMsg = SENDER_NAME;
        let temp = [...errMsgSenderDetailsList];
        temp[i].errMsgSenderName = errMsg;
        setErrMsgSenderDetailsList(temp);
        return false;
      }
      if (isEmpty(senderDetailsList[i].templateName)) {
        let errMsg = ENTER_TEMPLATE_NAME;
        let temp = [...errMsgSenderDetailsList];
        temp[i].errMsgTemplateName = errMsg;
        setErrMsgSenderDetailsList(temp);
        return false;
      }
    }
    return true;
  };

  const whatsappResponsesValidate = () => {
    for (let i = 0; i < whatsappResponseList?.length; i++) {
      if (whatsappResponseList[i].whatsappTemplate.keyId === 0) {
        let errMsg = WHATSAPP_TEMPLATE_NAME;
        let temp = [...errMsgWhatsappResponseList];
        temp[i].errMsgwhatsappTemplate = errMsg;
        setErrMsgWhatsappResponseList(temp);
        return false;
      }
      if (isEmpty(whatsappResponseList[i].keywords)) {
        let errMsg = KEY_WORDS;
        let temp = [...errMsgWhatsappResponseList];
        temp[i].errMsgKeyWords = errMsg;
        setErrMsgWhatsappResponseList(temp);
        return false;
      }
    }
    return true;
  };

  const validateForm = () => {
    if (serviceProvider.keyId === 0) {
      setErrServiceProvider(SERV_PROVIDER);
      return false;
    }
    if (isEmpty(accessPoint)) {
      setErrAccessPoint(ACCESS_POINT);
      return false;
    }
    if (isEmpty(authorizationKey)) {
      setErrAuthKey(AUTHOR_KEY);
      return false;
    }
    if (senderDetailsValidate() && whatsappResponsesValidate()) {
      return true;
    }
  };

  return (
    <div>
      <div className="onboard-header-label marginT18">
        <h3>WhatsApp settings</h3>
        <div className="marginT20 ml15">
          <Row>
            <Col md={5}>
              {errMsgServiceProvider && (
                <div className="validation-message">
                  {errMsgServiceProvider}
                </div>
              )}
              <RSDropdownList
                textField="keyName"
                dataItemKey="keyId"
                data={serviceData}
                value={serviceProvider}
                defaultItem={{
                  keyId: 0,
                  keyName: "-- Select Provider --",
                }}
                onChange={(event) => {
                  setServiceProvider(event.target.value);
                  let errMsg = event.target.value ? null : SERV_PROVIDER;
                  setErrServiceProvider(errMsg);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <RSInput2
                name="accessPoint"
                ph={"Access point"}
                val={accessPoint}
                cb={(value) => {
                  setAccessPoint(value);
                  let errMsg = value ? null : ACCESS_POINT;
                  setErrAccessPoint(errMsg);
                }}
                ob={(value) => {
                  setAccessPoint(value);
                  let errMsg = value ? null : ACCESS_POINT;
                  setErrAccessPoint(errMsg);
                }}
                errorMessage={errMsgAccessPoint}
              />
            </Col>
            <Col md={5}>
              <RSInput2
                name="authorizationKey"
                ph={"Authorization key"}
                val={authorizationKey}
                cb={(value) => {
                  setAuthorizationKey(value);
                  let errMsg = value ? null : AUTHOR_KEY;
                  setErrAuthKey(errMsg);
                }}
                ob={(value) => {
                  setAuthorizationKey(value);
                  let errMsg = value ? null : AUTHOR_KEY;
                  setErrAuthKey(errMsg);
                }}
                errorMessage={errMsgErrAuthKey}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="marginT30">Sender details</h4>
            </Col>
            <Col md={{ span: 8 }}>
              <i
                className={`icon-sd-circle-plus-fill-edge-medium icons-md blue float-right cursor-pointer ml10 ${senderDetailsList?.length === 5 ? "click-off" : ""
                  }`}
                onClick={() => {
                  if (senderDetailsList?.length < 5) {
                    if (senderDetailsValidate()) {
                      let temp = [...senderDetailsList];
                      temp.push({
                        clientWASenderId: 0,
                        senderId: "",
                        senderName: "",
                        templateName: "",
                      });
                      setSenderDetailsList(temp);
                      let tempError = [...errMsgSenderDetailsList];
                      tempError.push({
                        errMsgWhatsappSenderId: null,
                        errMsgSenderName: null,
                        errMsgTemplateName: null,
                      });
                      setErrMsgSenderDetailsList(tempError);
                    }
                  }
                }}
              ></i>
            </Col>
          </Row>
          {senderDetailsList?.map((item, index) => {
            return (
              <>
                <Row>
                  <Col md={5}>
                    <RSInput2
                      name="senderId"
                      ph={"WhatsApp sender ID"}
                      val={item.senderId}
                      cb={(value) => {
                        let temp = [...senderDetailsList];
                        temp[index].senderId = value;
                        setSenderDetailsList(temp);
                        item.senderId = value;
                        let errMsg = item.senderId ? null : WHATSAPP_SENDER_ID;
                        let tempError = [...errMsgSenderDetailsList];
                        tempError[index].errMsgWhatsappSenderId = errMsg;
                        setErrMsgSenderDetailsList(tempError);
                      }}
                      errorMessage={
                        errMsgSenderDetailsList[index].errMsgWhatsappSenderId
                      }
                    />
                  </Col>
                  <Col md={5}>
                    <div className="position-relative">
                      <RSInput2
                        name="senderName"
                        ph={"Sender name"}
                        val={item.senderName}
                        cb={(value) => {
                          let temp = [...senderDetailsList];
                          temp[index].senderName = value;
                          setSenderDetailsList(temp);
                          item.senderName = value;
                          let errMsg = item.senderName ? null : SENDER_NAME;
                          let tempError = [...errMsgSenderDetailsList];
                          tempError[index].errMsgSenderName = errMsg;
                          setErrMsgSenderDetailsList(tempError);
                        }}
                        errorMessage={
                          errMsgSenderDetailsList[index].errMsgSenderName
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <RSInput2
                      name="templateName"
                      ph={"Template name"}
                      val={item.templateName}
                      cb={(value) => {
                        let temp = [...senderDetailsList];
                        temp[index].templateName = value;
                        setSenderDetailsList(temp);
                        item.templateName = value;
                        let errMsg = item.templateName
                          ? null
                          : ENTER_TEMPLATE_NAME;
                        let tempError = [...errMsgSenderDetailsList];
                        tempError[index].errMsgTemplateName = errMsg;
                        setErrMsgSenderDetailsList(tempError);
                      }}
                      errorMessage={
                        errMsgSenderDetailsList[index].errMsgTemplateName
                      }
                    />
                    {index !== 0 && (
                      <div className="input-outer-set">
                        <i
                          className="icons-md red cursor-pointer float-left d-block icon-minus-fill-medium ml10"
                          onClick={() => {
                            if (mode === "edit") {
                              let wasSenderDel = [];
                              if (
                                senderDetailsList[index].clientWASenderId !== 0
                              ) {
                                wasSenderDel.push(
                                  senderDetailsList[index].clientWASenderId
                                );
                                setDeleteWaSender(wasSenderDel);
                              }
                            }
                            if (index !== 0) {
                              let temp = [...senderDetailsList];
                              temp.splice(index, 1);
                              setSenderDetailsList(temp);
                            }
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            );
          })}

          <Row>
            <Col>
              <h4 className="marginT30">WhatsApp responses</h4>
            </Col>
            <Col md={{ span: 8 }}>
              <i
                className={`icon-sd-circle-plus-fill-edge-medium icons-md blue float-right cursor-pointer ml10 ${whatsappResponseList?.length === 5 ? "click-off" : ""
                  }`}
                onClick={() => {
                  if (whatsappResponseList?.length < 5) {
                    let getTruth = whatsappResponsesValidate();
                    if (getTruth) {
                      let temp = [...whatsappResponseList];
                      temp.push({
                        whatsappTemplate: {
                          keyId: 0,
                          keyName: "-- Select whatsApp template --",
                        },
                        responseId: 0,
                        keywords: "",
                      });
                      setWhatsappResponseList(temp);
                      let tempError = [...errMsgWhatsappResponseList];
                      tempError.push({
                        errMsgwhatsappTemplate: null,
                        errMsgKeyWords: null,
                      });
                      setErrMsgWhatsappResponseList(tempError);
                    }
                  }
                }}
              ></i>
            </Col>
          </Row>
          {whatsappResponseList?.map((item, index) => {
            return (
              <>
                <Row>
                  <Col md={5}>
                    {errMsgWhatsappResponseList[index]
                      .errMsgwhatsappTemplate && (
                        <div className="validation-message">
                          {
                            errMsgWhatsappResponseList[index]
                              .errMsgwhatsappTemplate
                          }
                        </div>
                      )}
                    <RSDropdownList
                      textField="keyName"
                      dataItemKey="keyId"
                      data={templateData}
                      value={item.whatsappTemplate}
                      defaultItem={{
                        keyId: 0,
                        keyName: "-- Select whatsApp template --",
                      }}
                      onChange={(event) => {
                        let temp = [...whatsappResponseList];
                        temp.whatsappTemplate = event.target.value;
                        setWhatsappResponseList(temp);
                        item.whatsappTemplate = event.target.value;
                        let errMsg = item.whatsappTemplate.keyId
                          ? null
                          : WHATSAPP_TEMPLATE_NAME;
                        let tempError = [...errMsgWhatsappResponseList];
                        tempError[index].errMsgwhatsappTemplate = errMsg;
                        setErrMsgWhatsappResponseList(tempError);
                      }}
                    />
                  </Col>
                  <Col md={5}>
                    <div className="position-relative">
                      <RSInput2
                        name="keywords"
                        ph={"Enter keywords"}
                        val={item.keywords}
                        cb={(value) => {
                          let temp = [...whatsappResponseList];
                          temp[index].keywords = value;
                          setWhatsappResponseList(temp);
                          item.keywords = value;
                          let errMsg = item.keywords ? null : KEY_WORDS;
                          let tempError = [...errMsgWhatsappResponseList];
                          tempError[index].errMsgKeyWords = errMsg;
                          setErrMsgWhatsappResponseList(tempError);
                        }}
                        errorMessage={
                          errMsgWhatsappResponseList[index].errMsgKeyWords
                        }
                      />
                    </div>
                    {index !== 0 && (
                      <div className="input-outer-set">
                        <i
                          className="icons-md red cursor-pointer float-left d-block icon-minus-fill-medium ml10"
                          onClick={() => {
                            if (mode === "edit") {
                              if (whatsappResponseList[index].responseId !== 0) {
                                let delWaResponse = [];
                                delWaResponse.push(
                                  whatsappResponseList[index].responseId
                                );
                                setDeleteWaResponse(delWaResponse);
                              }
                            }
                            if (index !== 0) {
                              let temp = [...whatsappResponseList];
                              temp.splice(index, 1);
                              setWhatsappResponseList(temp);
                            }
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            );
          })}
        </div>
        <div className="btn-container d-flex justify-content-end marginT25">
          <RSSecondaryBtn onClick={() => props.onChangeIndex(0)}>
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              if (validateForm()) {
                var framedWhatsAppResponseList = [];
                whatsappResponseList?.forEach((item, index) => {
                  framedWhatsAppResponseList.push({
                    responseId: item.responseId,
                    templateId: item.whatsappTemplate.keyId,
                    keywords: item.keywords,
                  });
                });
                var params = {
                  clientWASettingId: clientWaSettingId,
                  clientId: getAuthClientId(),
                  serviceProviderId: serviceProvider.keyId,
                  accessPoint: accessPoint,
                  authorizationKey: authorizationKey,
                  whatsappSenderList: senderDetailsList,
                  whatsappResponseList: framedWhatsAppResponseList,
                  deleteWASender: deleteWaSender,
                  deleteWAResponse: deleteWaResponse,
                };
                connectServer({
                  path: SAVE_WHATSUP,
                  params: params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: (res) => {
                    if (res.status) {
                      props.onChangeIndex(0);
                    }
                  },
                  fail: (err) => { },
                });
              }
            }}
          >
            Save
          </RSPrimaryBtn>
        </div>
      </div>
    </div>
  );
};
export default WhatsappCreation;
