import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { RTabbar } from 'Components/RChart/RTabbar';
import CompanyDetails from './Tabs/CompanyDetails';
import Settings from './Tabs/Settings';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import AccountUsers from './Tabs/AccountUser/AccountUsers';
import { useHistory } from 'react-router-dom';

const AccountSettings = () => {

    const history = useHistory()
    const [index, setIndex] = useState(history.location.state !== null ? history.location.state.index : 0)

    useEffect(() => {
        setIndex(history.location.state !== null ? history.location.state.index : 0)
    }, [])

    function renderComponent(index) {
        var renderComponent;
        if (index === 0) {
            renderComponent = <CompanyDetails setIndex={setIndex} index={index} />
        } else if (index === 1) {
            renderComponent = <AccountUsers setIndex={setIndex} index={index} />
        }
        else if (index === 2) {
            renderComponent = <Settings setIndex={setIndex} index={index} />
        }
        return renderComponent
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <h1>Account settings</h1>
                <div className="portlet-box-theme bgWhite border-top-default p0 account-settings">
                    <div className="tabs-filled-component-del">
                        <RTabbar
                            defaultSelectedItem={index}
                            defaultClass="tabDefault"
                            dynamicTab="tabs-filled-component"
                            activeClass="tabDefault active"
                            tabData={tabData}
                            callBack={(item, index) => { setIndex(index) }}
                        />
                        {renderComponent(index)}
                    </div>
                </div>

            </Container>
        </LayoutPageWrapper>
    )
}
export default AccountSettings;

const tabData = [
    { "id": 1001, "text": "Brand details" },
    { "id": 1002, "text": "Users" },
    { "id": 1003, "text": "Settings" }
]
