import React, { Component } from "react";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { RTabbar, RTabbar1 } from "Components/RChart/RTabbar";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import { RSDropdownList } from "Components/RSDropdowns";
import RSEmojiPicker from "Components/Emoji/EmojiPicker";
import DefaultContent from "./Components/DefaultContent";
import SplitContent from "./Components/SplitContent";
import SplitSettingsModal from "./Components/SplitSettingsModal";
import SubjectLineModal from "./Components/SubjectLineModal";
import SplitSizeModal from "./Components/SplitSizeModal";
import ApprovalSettings from "./Components/ApprovalSettings";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { CustomPopup } from "Components/DateRangePicker/ScheduleCustomPopup";
import { SuccessModal } from "./Components/SuccessModal";
import { createEmailCampaign } from "../CamapignWrapper";
import {
    APPROVAL_MAIL, AUDIENCE, BODY, SENDER_EMAIL_ADDRESS, SENDER_NAME, SUBJECT_LINE, SCHEDULE_DATE, EMAIL_CONTENT, SELECT_TIMEZONE, ENTER_VALID_EMAIL, SELECT_DOMAIN
} from "Helper/Constants/validationMessage";
import {
    getLsMasterDataDropdown, isEmpty, getUserData, getAuthClientId, convertObjectToBase64, getContent, addMinutes, validateEmail, getCurrentTimeWithTimeZone,
} from "Helper/Utils/Utils";
import {
    CREATE_EMAIL_CAMPAIGN, EMAIL_TEST_PREVIEW, GET_ACTIVE_DOMAINS, GET_ADHOC_ATTRIBUTES, GET_APPROVER_LIST, GET_AUDIENCE_LIST, SENDER_DOMAIN_VALIDATION, UPDATE_EMAIL_CAMPAIGN
} from "Helper/Constants/endPoint";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { filterBy } from "@progress/kendo-data-query";
import { RSSplitAB } from "Components/SplitAB/RSSplitAB";

class EmailCreation extends Component {

    static contextType = authContext;

    state = {
        edmChannelId: 0,
        editClickOff: false,
        emailfooterContent: "",
        isRefreshModal: false,
        isOpenSendSuccessModal: false,
        isSpamCheckValidate: false,
        personalizeTabData: [],
        emailPersonlizeData: [],
        subjectLinePersonalizeData: [],
        isSearchValidate: false,
        approverListFromServer: [],
        audienceListFromServer: [],
        audienceListForDropDown: [],
        isOpenSplitResetModal: false,
        senderName: this.props.emailData?.senderName,
        senderEmailAddress: this.props.emailData.senderEmail,
        alternateEmailAddress: this.props.emailData.replyEmail,
        isReplyMail: this.props.emailData.isReplyMailEnabled,
        audienceList: this.props.emailData.targetListTargetAudience,
        isSplit: this.props.emailData.isSplitAB,
        subjectLine: this.props.emailData.content[0].subjectLine,
        activeTab: this.props.emailData.content[0].contentType === "R"
            ? 0
            : this.props.emailData.content[0].contentType === "Z"
                ? 1
                : this.props.emailData.content[0].contentType === "T"
                    ? 2
                    : -1,
        splitTabIndex: 0,
        isOpenSubjectModal: false,
        isSplitSettings: false,
        isShowSplitSizeModal: false,
        clicked: "",
        isShow: false,
        isRequestForApproval: false,
        isEditRequestForApproval: false,
        isModalOpen: false,
        isSendMsgTo: false,
        sendMailToAddress: "",
        isEditTimeZone: false,
        timeZone: null,
        timeZoneData: [],
        isDayLightSavings: false,
        isSendRecommendation: false,
        schedule: this.props.emailData.isSendTestMailContent !== 2 ? this.props.emailData.content[0].localBlastDateTime : "",
        approvalList: [
            {
                approverEmail: {
                    "email": "-- Select --",
                    "userid": 0
                },
                ismandatory: false,
                edmworlflowdetails: 0,
                priority: 1,
                ApprovalSentcount: 1,
                name: "approval",
                input: false,
                appErr: null
            }
        ],
        requestForErrorMsg: [
            {
                approverEmailMsg: null,
            },
        ],
        approvalSettings: {
            approvalMatchType: "All",
            approvalMatchCount: 0,
            isFollowHierarchyEnabled: false,
        },
        errSenderNameMsg: null,
        errSenderEmailMsg: null,
        errSenderDomainMsg: null,
        errReplyEmailMsg: null,
        errReplyDomainMsg: null,
        errAudienceMsg: null,
        errSubjectLineMsg: null,
        errScheduleDateMsg: null,
        errTimeZoneMsg: null,
        errSendMailMsg: null,
        defaultContentError: {
            errBodyMsg: null,
            errSendTestMailMsg: null,
            errEnableUnSubMsg: null,
            errUnSubMsg: null,
            errImportContent: null,
            errTemplateContent: null,
        },
        errEmailContentMsg: null,
        splitContentError: [
            {
                errBodyMsg: null,
                errSendTestMailMsg: null,
                errSubjectLineMsg: null,
                errScheduleDateMsg: null,
                errTimeZoneMsg: null,
                errEnableUnSubMsg: null,
                errUnSubMsg: null,
                errImportContent: null,
                errEmailContentMsg: null,
            },
            {
                errBodyMsg: null,
                errSendTestMailMsg: null,
                errSubjectLineMsg: null,
                errScheduleDateMsg: null,
                errTimeZoneMsg: null,
                errEnableUnSubMsg: null,
                errUnSubMsg: null,
                errImportContent: null,
                errEmailContentMsg: null,
            },
        ],
        startDate: new Date(),
        endDate: new Date(),
        splitTabData: [
            { id: 1001, text: "Split A" },
            { id: 1002, text: "Split B" },
        ],
        createTemplate: [],
        activeDomainList: [],
        activeDomain: { "smtpDomainSettingId": -1, "domainName": "-- Select domain --" },
        activeReplyDomain: { "smtpDomainSettingId": -1, "domainName": "-- Select domain --" },
        paymentStatusForList: null
    };

    tabs = [
        {
            text: "Text",
            iconLeft: "icon-sd-text-document-edge-large icons-lg marginB10",
            disable: false,
            contentType: "R",
        },
        {
            text: "Import",
            iconLeft: "icon-sd-import-file-edge-large icons-lg marginB10",
            disable: false,
            contentType: "Z",
        },
        {
            text: "Template",
            iconLeft: "icon-sd-template-generator-large icons-lg marginB10",
            disable: false,
            contentType: "T",
        },
    ];
    template = [
        {
            id: 1,
            text: "My Template",
            // iconLeft: "icon-text-document-large icons-lg marginB10",
            disable: false,
            contentType: "T",
        },
        {
            id: 2,
            text: "Create template",
            // iconLeft: "icon-import-file-large icons-lg marginB10",
            disable: false,
            contentType: "M",
        },

    ];

    constructor(props) {
        super(props);
        this.anchor = React.createRef();
        this.emojianochor = React.createRef();
        this.contentRef = React.createRef();
        this.blurTimeoutRef = React.createRef();
        this.updateTabStatus();
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const clickOffStatus = this.props.mode === "add"
            ? false
            : (this.props.emailData.channelstatusId !== 12 && this.props.emailData.channelstatusId !== 51 && this.props.emailData.channelstatusId !== 9 && this.props.emailData.channelstatusId !== 5 && this.props.emailData.channelstatusId !== 20 && this.props.emailData.channelstatusId !== 52)
                ? false
                : true;
        this.setState({ editClickOff: clickOffStatus })
        this.getActiveDomains();
        this.getTimeZoneList();
        this.getAudienceList();
        this.getApproverList();
        if (this.props.emailData.senderName === "") {
            let senderName = getUserData()?.clientDetailsLocalization?.clientName;
            this.props.emailData.senderName = senderName;
            this.setState({ senderName: senderName })
        }
        if (this.state.senderEmailAddress === "" || this.state.domainName) {
            if (!!this.props.emailData.senderEmail) {
                let senderEmail = this.props.emailData.senderEmail.split("@")
                let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                this.setState({
                    senderEmailAddress: senderEmail[0],
                    activeDomain: domainName,
                })
            }
        }
        if (this.state.alternateEmailAddress === "" || this.state.domainName) {
            if (this.props.emailData.replyEmail) {
                let senderEmail = this.props.emailData.replyEmail.split("@")
                let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                this.setState({
                    alternateEmailAddress: senderEmail[0],
                    activeReplyDomain: domainName,
                })
            }
        }
        if (this.props.emailData.isRequestForApprovalEnabled && this.props.emailData.channelstatusId !== 54) {
            let requestForApproval = { ...this.props.emailData.requestForApproval };
            let approveList = [...requestForApproval.approvarList];
            let tempApprovalList = [];
            let tempApprovalSettings = {
                approvalMatchType: requestForApproval.matchType,
                approvalMatchCount: requestForApproval.matchCount,
                isFollowHierarchyEnabled: requestForApproval.IsFollowHierarchy,
            }
            approveList.map((item, index) => {
                let tempItem = {
                    approverEmail: {
                        "email": item.approvarName,
                        "userid": parseInt(item.approvarId, 10)
                    },
                    ismandatory: item.ismandatory,
                    edmworlflowdetails: 0,
                    priority: item.priority,
                    ApprovalSentcount: item.ApprovalSentcount || 1,
                    name: "approval",
                    input: item.approvarId === "" ? true : false,
                    appErr: null
                }
                tempApprovalList.push(tempItem)
            })
            this.setState({ approvalList: tempApprovalList, approvalSettings: tempApprovalSettings, isRequestForApproval: this.props.emailData.isRequestForApprovalEnabled, isEditRequestForApproval: this.props.emailData.isRequestForApprovalEnabled })
        }
        const campaignHeader = this.props.campaignHeader;
        this.setState({
            startDate: new Date(this.getStartDate(campaignHeader.startDate)),
            // endDate: new Date(campaignHeader.endDate)
            endDate: new Date(new Date(new Date(campaignHeader.endDate).getFullYear(), new Date(campaignHeader.endDate).getMonth(), new Date(campaignHeader.endDate).getDate(), 23, 59, 59))
        });
        document.getElementById("root").addEventListener("click", this.outSideClick, false);
        // document.getElementsByClassName("emoji-picker-icon").addEventListener("click", this.outSideClick, false); 
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            const clickOffStatus = this.props.mode === "add"
                ? false
                : (this.props.emailData.channelstatusId !== 12 && this.props.emailData.channelstatusId !== 51 && this.props.emailData.channelstatusId !== 9 && this.props.emailData.channelstatusId !== 5 && this.props.emailData.channelstatusId !== 20 && this.props.emailData.channelstatusId !== 52)
                    ? false
                    : true;
            // let paymentStatus = this.props.emailData.targetListTargetAudience[0]?.isPaid ? null : "Payment for the list is pending";
            let paymentStatus = this.props.emailData.targetListTargetAudience.length === 0 ? null : this.props.emailData.targetListTargetAudience[0]?.isPaid ? null : "Payment for the list is pending";
            if (this.state.senderEmailAddress === "" || this.state.domainName) {
                if (!!this.props.emailData.senderEmail) {
                    let senderEmail = this.props.emailData.senderEmail.split("@")
                    let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                    this.setState({
                        senderEmailAddress: senderEmail[0],
                        activeDomain: domainName,
                    })
                }
            }
            if (this.state.alternateEmailAddress === "" || this.state.domainName) {
                if (!!this.props.emailData.replyEmail) {
                    let senderEmail = this.props.emailData.replyEmail.split("@")
                    let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                    this.setState({
                        alternateEmailAddress: senderEmail[0],
                        activeReplyDomain: domainName,
                    })
                }
            }
            this.setState({
                editClickOff: clickOffStatus,
                senderName: this.props.emailData.senderName || getUserData()?.clientDetailsLocalization?.clientName,
                // alternateEmailAddress: this.props.emailData.replyEmail || this.state.alternateEmailAddress,
                isReplyMail: this.props.emailData.isReplyMailEnabled,
                isRequestForApproval: this.props.emailData.channelstatusId !== 54 ? this.props.emailData.isRequestForApprovalEnabled : false,
                isEditRequestForApproval: this.props.emailData.channelstatusId !== 54 ? this.props.emailData.isRequestForApprovalEnabled : false,
                audienceList: this.props.emailData.targetListTargetAudience,
                paymentStatusForList: paymentStatus,
                isSplit: this.props.emailData.isSplitAB,
                subjectLine: this.props.emailData.content[0].subjectLine,
                edmChannelId: this.props.emailData.content[0].edmChannelId,
                activeTab:
                    this.props.emailData.content[0].contentType === "R"
                        ? 0
                        : this.props.emailData.content[0].contentType === "Z"
                            ? 1
                            : this.props.emailData.content[0].contentType === "T"
                                ? 2
                                : -1,
                schedule: (this.props.emailData.channelstatusId !== 54 && this.props.emailData.isSendTestMailContent !== 2) ? !!this.props.emailData.content[0].localBlastDateTime ? this.props.emailData.content[0].localBlastDateTime : "" : "",
                timeZone: this.props.emailData.content[0].timezoneId !== 0 ? this.state.timeZoneData.filter(
                    (item) =>
                        item.timezoneId === this.props.emailData.content[0].timezoneId
                )[0] : this.state.timeZoneData.filter(
                    (item) =>
                        item.timezoneId === getUserData().clientDetailsLocalization?.timeZoneId
                )[0],
            });
            if (!!!this.props.emailData.senderName || this.props.emailData.senderName === "") {
                this.props.emailData.senderName = getUserData()?.clientDetailsLocalization?.clientName;;
            }
            if (!!!this.props.emailData.senderEmail || this.props.emailData.senderEmail === "") {
                this.props.emailData.senderEmail = this.state.senderEmailAddress;
            }
            if (!!!this.props.emailData.replyEmail || this.props.emailData.replyEmail === "") {
                this.props.emailData.replyEmail = this.state.alternateEmailAddress;
            }
            const campaignHeader = this.props.campaignHeader;
            this.setState({
                startDate: new Date(this.getStartDate(campaignHeader.startDate)),
                // endDate: new Date(campaignHeader.endDate),
                endDate: new Date(new Date(new Date(campaignHeader.endDate).getFullYear(), new Date(campaignHeader.endDate).getMonth(), new Date(campaignHeader.endDate).getDate(), 23, 59, 59))
            });
            if (this.props.emailData.isRequestForApprovalEnabled && this.props.emailData.channelstatusId !== 54) {
                let requestForApproval = { ...this.props.emailData.requestForApproval };
                let approveList = [...requestForApproval.approvarList];
                let tempApprovalList = [];
                let tempApprovalSettings = {
                    approvalMatchType: requestForApproval.matchType,
                    approvalMatchCount: requestForApproval.matchCount,
                    isFollowHierarchyEnabled: requestForApproval.IsFollowHierarchy,
                }
                approveList.map((item, index) => {
                    let tempItem = {
                        approverEmail: {
                            "email": item.approvarName,
                            "userid": parseInt(item.approvarId, 10)
                        },
                        ismandatory: item.ismandatory,
                        edmworlflowdetails: 0,
                        priority: item.priority,
                        ApprovalSentcount: item.ApprovalSentcount || 1,
                        name: "approval",
                        input: item.approvarId === "" ? true : false,
                        appErr: null
                    }
                    tempApprovalList.push(tempItem)
                })
                this.setState({ approvalList: tempApprovalList, approvalSettings: tempApprovalSettings })
            }
            this.updateTabStatus();
            // this.getStartDateForSchedule()
            this.getAdhocAttributeHeaders(this.props.emailData.targetListTargetAudience[0]?.segmentationListID);
        }

    };

    outSideClick = () => {
        // alert("click")
        // this.setState({
        //     clicked: 0,
        //     isShow: false
        // })
    }

    onOpen = e => {
        this?.contentRef?.current?.focus()
    };

    onFocus = () => {
        // the user is still inside the content
        clearTimeout(this?.blurTimeoutRef?.current);
    };
    onBlurTimeout = () => {
        // the user is now outside the popup
        this.setState({ clicked: 0, isShow: false });
    };

    onBlur = () => {
        clearTimeout(this?.blurTimeoutRef?.current);
        this.blurTimeoutRef.current = setTimeout(this.onBlurTimeout, 200);
    };

    getStartDate = (startDate) => {
        let campaignStartDate = new Date(startDate);
        let today = new Date();
        if (campaignStartDate > today) {
            campaignStartDate = new Date(startDate);
        } else {
            campaignStartDate = new Date();
        }
        return campaignStartDate
    }

    getStartDateForSchedule = () => {
        const data = getLsMasterDataDropdown();
        const loggedDetails = getUserData();
        let filterTimezone = null;
        if (this.props.emailData.content[0].timezoneId === 0) {
            filterTimezone = data.timeZone.filter(
                (item) =>
                    item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
            )[0];
        } else {
            filterTimezone = data.timeZone.filter(
                (item) => item.timezoneId === this.props.emailData.content[0].timezoneId
            )[0];
        }
        const campaignHeader = this.props?.campaignHeader
        let startDate = new Date(this.getStartDate(campaignHeader.startDate));
        let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
        // this.setState({ startDate: startDateWithTime })
        // console.log("Start Date", this.state.startDate);
        return startDateWithTime;
    }

    updateTabStatus = () => {
        if (this.props.emailData.content[0].contentType === "R") {
            this.setState({ activeTab: 0 });
            const newData = this.tabs;
            newData[0].disable = false;
            newData[1].disable = true;
            newData[2].disable = true;
        } else if (this.props.emailData.content[0].contentType === "Z") {
            this.setState({ activeTab: 1 });
            const newData = this.tabs;
            newData[0].disable = true;
            newData[1].disable = false;
            newData[2].disable = true;
        } else if (this.props.emailData.content[0].contentType === "T") {
            this.setState({ activeTab: 2 });
            const newData = this.tabs;
            newData[0].disable = true;
            newData[1].disable = true;
            newData[2].disable = false;
        } else {
            this.setState({ activeTab: -1 });
            const newData = this.tabs;
            newData[0].disable = false;
            newData[1].disable = false;
            newData[2].disable = false;
        }
    };

    getAudienceList = () => {
        let params = {
            searchText: "",
            campaignID: 0,
            channel: "E"
        }
        connectServer({
            path: GET_AUDIENCE_LIST,
            params,
            context: this.context,
            ok: res => {
                if (res.status && res.message !== "No data") {
                    localStorage.setItem('lsAudienceList', convertObjectToBase64(res.data))
                    this.setState({ audienceListFromServer: res.data, audienceListForDropDown: res.data });
                }
            },
            fail: err => {
            }
        })
    }

    getApproverList = param => {
        let params = {
            "clinetId": getAuthClientId()
        }
        connectServer({
            path: GET_APPROVER_LIST,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({
                        approverListFromServer: res.data,
                    });
                }
            },
            fail: err => {
            }
        })
    }

    getTimeZoneList = () => {
        const data = getLsMasterDataDropdown();
        const loggedDetails = getUserData();
        let filterTimezone = null;
        if (this.props.mode === "add" || this.props.emailData.content[0].timezoneId === 0) {
            filterTimezone = data.timeZone.filter(
                (item) =>
                    item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
            )[0];
        } else {
            filterTimezone = data.timeZone.filter(
                (item) => item.timezoneId === this.props.emailData.content[0].timezoneId
            )[0];
        }
        this.setState({ timeZoneData: data.timeZone, timeZone: filterTimezone });
        this.props.emailData.content[0].timezoneId = filterTimezone.timezoneId;
    };

    getAdhocAttributeHeaders = (segmentId) => {
        if (segmentId !== undefined) {
            let params = {
                segmentationListID: segmentId,
            };
            connectServer({
                path: GET_ADHOC_ATTRIBUTES,
                params,
                sessionOut: this.context.globalStateData.setSessionOutData,
                context: this.context,
                ok: (res) => {
                    if (res?.status) {
                        let tempNamePersonal = [];
                        res.data?.names?.forEach((item, index) => {
                            tempNamePersonal.push("[[" + item + "]]");
                        });
                        let tempEmailPersonal = [];
                        res.data?.emails?.forEach((item, index) => {
                            tempEmailPersonal.push("[[" + item + "]]");
                        });
                        let tempFullPersonal = [];
                        res.data?.full?.forEach((item, index) => {
                            tempFullPersonal.push("[[" + item + "]]");
                        });
                        this.setState({
                            personalizeTabData: tempNamePersonal,
                            emailPersonlizeData: tempEmailPersonal,
                            subjectLinePersonalizeData: tempFullPersonal,
                        });
                    }
                },
                fail: (err) => {
                },
            });
        }
    };

    getRemainingAudienceList() {
        var temp = [...this.state.audienceListFromServer]
        var listTemp = []
        temp.forEach(obj => {
            let temp2 = this.state.audienceList.filter(
                audience => audience.segmentationListID === obj.segmentationListID
            )
            if (temp2.length === 0) {
                listTemp.push(obj)
            }
        })
        return listTemp
    }

    getActiveDomains = () => {
        let params = {}
        connectServer({
            path: GET_ACTIVE_DOMAINS,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let senderEmail = this.props.emailData.senderEmail?.split("@")
                    let replyEmail = this.props.emailData.replyEmail?.split("@");
                    let replyDomainName = res.data.filter(item => item.domainName === replyEmail[1])[0];
                    let domainName = res.data.filter(item => item.domainName === senderEmail[1])[0];
                    this.setState({
                        activeDomainList: res.data,
                        senderEmailAddress: senderEmail[0],
                        activeDomain: domainName,
                        alternateEmailAddress: replyEmail[0],
                        activeReplyDomain: replyDomainName
                    })
                }
            },
            fail: err => {
            }
        })
    }

    validateSenderDomain = (Domain) => {
        let params = {
            "domainname": Domain
        }

        connectServer({
            path: SENDER_DOMAIN_VALIDATION,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (!res?.status) {
                    this.setState({ errSenderEmailMsg: res.message })
                }
            },
            fail: (err) => {
            },
        });

    }
    createSplitObject = (sectionName) => {
        let split = {
            tempGuid: "",
            edmChannelId: 0,
            subjectLine: "",
            contentType: "",
            splitType: sectionName,
            statusId: 0,
            body: "",
            textContent: "",
            preHeaderMessage: "",
            spamScore: 3.0,
            isFooterEnabled: false,
            footerId: 0,
            isUnsubscribeEnabled: false,
            unsubscribeSettingId: 0,
            contentUrl: "",
            htmlFilePath: "",
            previewId: 0,
            zipFilePath: "",
            isViewinBrowserChecked: true,
            templateId: 0,
            dynamiclistId: 0,
            timezoneId: this.state.timeZone.timezoneId,
            localBlastDateTime: "",
            linkVerificationLogDetail: [],
            edmFileWeight: 0,
            edmDimension: 0
        };
        return split;
    };

    createSplitError = () => {
        let splitError = {
            errBodyMsg: null,
            errSendTestMailMsg: null,
            errSubjectLineMsg: null,
            errScheduleDateMsg: null,
            errEnableUnSubMsg: null,
            errUnSubMsg: null,
            errImportContent: null,
            errEmailContentMsg: null,
        }
        return splitError;
    }

    buttonClick = (clicked) => {
        this.setState({ clicked: clicked, isShow: true });
    };

    isEnableSaveButton = () => {
        let isEnable = true;
        if (this.state.defaultContentError.errBodyMsg === null) {
            isEnable = true;
        } else {
            isEnable = false;
        }
        return isEnable;
    }

    header = (props) => {
        return (
            <div className={`even mt10 rich-push-tab d ${props.editClickOff ? "click-off" : ""}`}>
                <div className="campaign-tab-bar my0 position-relative">
                    {this.state.errEmailContentMsg &&
                        <div className="validation-message top-30">
                            {this.state.errEmailContentMsg}
                        </div>}
                    <Row className="d-flex align-items-center custom-column column-tab">
                        <Col md={{ span: 3, offset: 0 }}>
                            <label>Email content</label>
                        </Col>
                        <Col md={6} className="pl0">
                            <RTabbar
                                defaultSelectedItem={this.state.activeTab}
                                defaultClass="tabDefault"
                                dynamicTab="mb0 rs-tab-icon or-tab flex-tab"
                                activeClass="tabDefault active"
                                tabData={this.tabs}
                                or={true}
                                callBack={(item, index) => {
                                    this.props.emailData.content[0].contentType =
                                        item.contentType;
                                    // this.props.emailData.content[0].body = index === 1 ? "" : this.props.emailData.content[0].body;
                                    this.updateTabStatus();
                                    let errMsg = index !== -1 ? null : EMAIL_CONTENT
                                    this.setState({ errEmailContentMsg: errMsg })
                                }}
                            />
                        </Col>
                    </Row>
                    {(this.state.activeTab !== -1) &&
                        <div className="refresh-icon">
                            <RSPTooltip text="Refresh" position="top">
                                <i
                                    className="icon-sd-refresh-medium icons-md blue"
                                    onClick={() => {
                                        this.setState({ isRefreshModal: !this.state.isRefreshModal });
                                    }}
                                />
                            </RSPTooltip>
                        </div>}
                </div>
            </div>
        );
    };

    splitValidation = (index) => {
        let isValidate = true;
        let splitContent = this.props.emailData.content[index];
        if (isEmpty(splitContent.subjectLine)) {
            let temp = [...this.state.splitContentError];
            temp[index].errSubjectLineMsg = SUBJECT_LINE;
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        if (isEmpty(splitContent.body)) {
            let temp = [...this.state.splitContentError];
            temp[index].errBodyMsg = BODY;
            temp[index].errEmailContentMsg = EMAIL_CONTENT;
            if (splitContent.contentType === "Z") {
                temp[index].errImportContent = "Please upload EDM";
            }
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        if (splitContent.isFooterEnabled && !splitContent.isUnsubscribeEnabled) {
            let temp = [...this.state.splitContentError];
            temp[index].errEnableUnSubMsg = "Enable unsubscribe";
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        if (splitContent.isUnsubscribeEnabled && splitContent.unsubscribeSettingId === 0) {
            let temp = [...this.state.splitContentError];
            temp[index].errUnSubMsg = "Select unsubscribe";
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        if (isEmpty(splitContent.localBlastDateTime)) {
            let temp = [...this.state.splitContentError];
            temp[index].errScheduleDateMsg = SCHEDULE_DATE;
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        if (splitContent.timezoneId === 0) {
            let temp = [...this.state.splitContentError];
            temp[index].errTimeZoneMsg = SELECT_TIMEZONE;
            this.setState({ splitContentError: temp });
            isValidate = false;
        }
        return isValidate;
    };

    templateValidation = () => {
        let validate = true;
        if (isEmpty(this.state.senderName)) {
            this.setState({ errSenderNameMsg: SENDER_NAME });
            validate = false;
        }
        if (this.state.errSenderEmailMsg === null) {
            if (isEmpty(this.state.senderEmailAddress)) {
                this.setState({ errSenderEmailMsg: SENDER_EMAIL_ADDRESS });
                validate = false;
            }
        } else {
            validate = false;
        }
        if (this.state.errSenderDomainMsg === null) {
            if (!!!this.state.activeDomain || this.state.activeDomain?.smtpDomainSettingId === -1) {
                this.setState({ errSenderDomainMsg: SELECT_DOMAIN });
                validate = false;
            }
        } else {
            validate = false;
        }
        if (this.state.isReplyMail) {
            if (this.state.errReplyEmailMsg === null) {
                if (isEmpty(this.state.alternateEmailAddress)) {
                    this.setState({ errReplyEmailMsg: SENDER_EMAIL_ADDRESS });
                    validate = false;
                }
            } else {
                validate = false;
            }
            if (this.state.errReplyDomainMsg === null) {
                if (!!!this.state.activeReplyDomain || this.state.activeReplyDomain?.smtpDomainSettingId === -1) {
                    this.setState({ errReplyDomainMsg: SELECT_DOMAIN });
                    validate = false;
                }
            } else {
                validate = false;
            }
        }
        if (this.state.audienceList.length === 0) {
            this.setState({ errAudienceMsg: AUDIENCE });
            validate = false;
        }
        if (isEmpty(this.state.subjectLine)) {
            this.setState({ errSubjectLineMsg: SUBJECT_LINE });
            validate = false;
        }
        return validate
    }
    validation = (type) => {
        let isValidate = true;
        if (isEmpty(this.state.senderName)) {
            this.setState({ errSenderNameMsg: SENDER_NAME });
            isValidate = false;
        }
        if (this.state.errSenderEmailMsg === null) {
            if (isEmpty(this.state.senderEmailAddress)) {
                this.setState({ errSenderEmailMsg: SENDER_EMAIL_ADDRESS });
                isValidate = false;
            }
        } else {
            isValidate = false;
        }
        if (this.state.errSenderDomainMsg === null) {
            if (!!!this.state.activeDomain || this.state.activeDomain?.smtpDomainSettingId === -1) {
                this.setState({ errSenderDomainMsg: SELECT_DOMAIN });
                isValidate = false;
            }
        } else {
            isValidate = false;
        }
        if (this.state.isReplyMail) {
            if (this.state.errReplyEmailMsg === null) {
                if (isEmpty(this.state.alternateEmailAddress)) {
                    this.setState({ errReplyEmailMsg: SENDER_EMAIL_ADDRESS });
                    isValidate = false;
                }
            } else {
                isValidate = false;
            }
            if (this.state.errReplyDomainMsg === null) {
                if (!!!this.state.activeReplyDomain || this.state.activeReplyDomain?.smtpDomainSettingId === -1) {
                    this.setState({ errReplyDomainMsg: SELECT_DOMAIN });
                    isValidate = false;
                }
            } else {
                isValidate = false;
            }
        }
        if (this.state.audienceList.length === 0) {
            this.setState({ errAudienceMsg: AUDIENCE });
            isValidate = false;
        }
        // let isSplitComplete = true;
        if (!this.state.isSplit) {
            if (isEmpty(this.state.subjectLine)) {
                this.setState({ errSubjectLineMsg: SUBJECT_LINE });
                isValidate = false;
            }
            if (this.state.activeTab === -1) {
                this.setState({ errEmailContentMsg: EMAIL_CONTENT });
                isValidate = false;
            }
            if (isEmpty(this.props.emailData.content[0].body)) {
                let temp = { ...this.state.defaultContentError };
                if (this.state.activeTab === 1) {
                    temp.errImportContent = "Please upload EDM";
                } else if (this.state.activeTab === 2) {
                    temp.errTemplateContent = "Please select or create template";
                }
                temp.errBodyMsg = BODY;
                this.setState({ defaultContentError: temp });
                isValidate = false;
            }
            if (this.props.emailData.content[0].body === null) {
                isValidate = false
            }
            if (this.props.emailData.content[0].isFooterEnabled && !this.props.emailData.content[0].isUnsubscribeEnabled) {
                let temp = { ...this.state.defaultContentError };
                temp.errEnableUnSubMsg = "Enable unsubscribe";
                this.setState({ defaultContentError: temp });
                isValidate = false;
            }
            if (this.props.emailData.content[0].isUnsubscribeEnabled && this.props.emailData.content[0].unsubscribeSettingId === 0) {
                let temp = { ...this.state.defaultContentError };
                temp.errUnSubMsg = "Select unsubscribe";
                this.setState({ defaultContentError: temp });
                isValidate = false;
            }
            if (type !== 'draft') {

                if (isEmpty(this.state.schedule)) {
                    this.setState({ errScheduleDateMsg: SCHEDULE_DATE });
                    isValidate = false;
                } else {
                    // let currentTime = addMinutes(new Date(), 15);
                    let currentTime = getCurrentTimeWithTimeZone(new Date(), this.state.timeZone.timezoneId, 15)
                    // let schduleTime = new Date(this.state.schedule);
                    // let schduleTime = getCurrentTimeWithTimeZone(new Date(this.state.schedule), this.state.timeZone.timezoneId, 0)
                    if (new Date(this.state.schedule) < new Date(currentTime)) {
                        this.setState({ errScheduleDateMsg: "Select a time greater from (" + currentTime.toLocaleString().trim() + ")" });
                        isValidate = false;
                    }
                }
                if (this.state.timeZone.timezoneId === 0) {
                    this.setState({ errTimeZoneMsg: SELECT_TIMEZONE });
                    isValidate = false;
                }
            }
        } else {
            let i = 0;
            this.props.emailData.content.forEach((element) => {
                isValidate = this.splitValidation(i);
                if (!isValidate) {
                    this.setState({ splitTabIndex: i });
                    return;
                }
                i++;
            });
        }

        let enable = true;
        if (this.state.isRequestForApproval && this.state.approvalList.filter((item) => item.appErr !== null).length === 0) {
            this.state.approvalList.map((item, index) => {
                if (item.approverEmail.email === "" || item.approverEmail.email === "-- Select --") {
                    enable = false;
                    let temp = [...this.state.approvalList];
                    temp[index].appErr = APPROVAL_MAIL;
                    this.setState({ approvalList: temp });
                    return;
                } else {
                    let temp = [...this.state.approvalList];
                    temp[index].appErr = null;
                    this.setState({ approvalList: temp });
                    enable = true;
                }
            });
        } else {
            enable = this.state.isRequestForApproval ? false : true
        }
        // if (!isSplitComplete) {
        //     isValidate = false;
        // } else {
        //     isValidate = true;
        // }
        return enable ? isValidate : false;
    };

    SplitComponents = (props) => {
        let components = [...this.props.emailData.content];
        let errorIndex = 0;
        let tempTabData = [...this.state.splitTabData];
        this.props.emailData.content.forEach(element => {
            if (this.state.splitContentError[errorIndex] === undefined) {
                this.state.splitContentError.push(this.createSplitError());
            }
            if (tempTabData[errorIndex] === undefined) {
                var add = tempTabData.length;
                var charCode = "A".charCodeAt(0);
                add = charCode + add;
                var obj = {
                    id: add,
                    text: "Split " + String.fromCharCode(add),
                };
                tempTabData.push(obj);
                this.setState({ splitTabData: tempTabData });
            }
            errorIndex++;
        });
        return (
            <>
                <Tabs
                    activeKey={"tab" + this.state.splitTabIndex}
                    id="uncontrolled-tab-example"
                    className="mb30 borderB0"
                >
                    {this.props.emailData.content.map((item, index1) => {
                        return (
                            <Tab eventKey={"tab" + index1}>
                                <SplitContent
                                    personalizeTabData={this.state.subjectLinePersonalizeData}
                                    emailData={this.props.emailData}
                                    campaignId={this.props.campaignId}
                                    splitEmailData={components[index1]}
                                    splitError={this.state.splitContentError[index1]}
                                    campaignHeader={this.props.campaignHeader}
                                    onSplitContentErrorChange={(key, value) => {
                                        let tempSplitError = [...this.state.splitContentError];
                                        switch (key) {
                                            case "body":
                                                tempSplitError[index1].errBodyMsg = value;
                                                break;
                                            case "subjectLine":
                                                tempSplitError[index1].errSubjectLineMsg = value;
                                                break;
                                            case "enableUnSub":
                                                tempSplitError[index1].errEnableUnSubMsg = value;
                                                break;
                                            case "unSub":
                                                tempSplitError[index1].errUnSubMsg = value;
                                                break;
                                            case "import":
                                                tempSplitError[index1].errImportContent = value;
                                                break;
                                            case "schedule":
                                                tempSplitError[index1].errScheduleDateMsg = value;
                                                break;
                                            case "timeZone":
                                                tempSplitError[index1].errTimeZoneMsg = value;
                                                break;
                                            case "emailContent":
                                                tempSplitError[index1].errEmailContentMsg = value;
                                                break;
                                        }
                                        this.setState({ splitContentError: tempSplitError });
                                    }}
                                    getEdmChannelId={(value) => {
                                        this.setState({ edmChannelId: value });
                                    }}
                                    getEmailFooterContent={(value) => {
                                        this.setState({ emailfooterContent: value });
                                    }}
                                    onValidateSearch={(status) => {
                                        let isValidate = this.validation("draft");
                                        this.setState({ isSearchValidate: isValidate });
                                    }}
                                    isSearchValidate={this.state.isSearchValidate}
                                    onChangeIsSearchValidate={() => {
                                        this.setState({ isSearchValidate: false });
                                    }}
                                    onValidateSpamCheck={(status) => {
                                        let isValidateSpam = this.validation('draft');
                                        this.setState({ isSpamCheckValidate: isValidateSpam });
                                    }}
                                    isSpamCheckValidate={this.state.isSpamCheckValidate}
                                />
                            </Tab>
                        );
                    })}
                </Tabs>
            </>
        );
    };
    filterChange = (event) => {
        let temp = this.getRemainingAudienceList();
        this.setState({
            audienceListForDropDown: filterBy(temp.slice(), event.filter),
        });
    };


    // method for clear input in approval request
    handleCloseInput = (index) => {
        let temp = [...this.state.approvalList];
        temp[index].input = false
        temp[index].appErr = null
        temp[index].approverEmail = {
            email: "-- Select --",
            userid: 0,
        };
        // let tempErrorMsg = [...this.state.requestForErrorMsg];
        // tempErrorMsg[index].approverEmailMsg = null;
        this.setState({
            approvalList: temp,
            //   requestForErrorMsg: tempErrorMsg,
            isSendMsgTo: false,
            sendMailToAddress: "",
        });
    };



    // method for onblur in request approval inputs fields

    hanldeBlurInputs = (text, index) => {
        let emails = text.split(",");
        emails.map((item, eIndex) => {
            if (!validateEmail(item)) {
                let tempApprovalList = [...this.state.approvalList];
                tempApprovalList[index].appErr = ENTER_VALID_EMAIL
                this.setState({
                    approvalList: tempApprovalList,
                });
                return;
            } else if (this.state.approvalList.filter((approval) => approval.approverEmail.email === item).length > 1) {
                let tempApprovalList = [...this.state.approvalList];
                tempApprovalList[index].appErr = "Approver's mail shouldn't be the same"
                this.setState({
                    approvalList: tempApprovalList,
                });
                return;
            }
        });
    };

    // method for onchange in request approval inputs fields
    handleChnageInputs = (text, index) => {
        let getMailvalid = this.getValidMail(text)

        if (!getMailvalid) {
            let tempValid = [...this.state.approvalList]
            tempValid[index].appErr = "Approver's mail shouldn't be the same"
            this.setState({
                errSendMailMsg: tempValid,
            });
        }
        if (text.split(",").length <= 5) {
            this.props.emailData.testCampaignEmailAddress = text;
            this.setState({
                sendMailToAddress: text,
                errSendMailMsg: null,
            });
            let temp = [...this.state.approvalList];
            temp[index].approverEmail = {
                "email": text,
                "userid": 0
            }
            temp[index].appErr = null;
            let tempErrorMsg = [...this.state.requestForErrorMsg];
            //   tempErrorMsg[index].approverEmailMsg = null;
            this.setState({
                approvalList: temp,
                requestForErrorMsg: tempErrorMsg,
                isSendMsgTo: false,
                sendMailToAddress: text,
            });
        } else {
            let temp = [...this.state.approvalList];
            temp[index].appErr = "Send email ids should not be more than 5";
            this.setState({
                approvalList: temp,
                errSendMailMsg: "Send email ids should not be more than 5",
            });
        }
    };
    getValidMail = (mail) => {
        let temp = [...this.state.approvalList];
        let valid = true
        temp.forEach((e) => {
            if (mail === e.approverEmail.email) {
                valid = false
            }
        })
        return valid
    }

    // method for onchange on request approval dropdown
    handleChangeRequest = (event, index) => {
        let getMailvalid = this.getValidMail(event.target.value.email)
        let tempValid = [...this.state.approvalList]

        if (!getMailvalid) {
            tempValid[index].appErr = "Approver's mail shouldn't be the same"
        } else {
            tempValid[index].appErr = null
        }
        this.setState({
            errSendMailMsg: tempValid,
        });
        if (event.target.value.userid !== -1) {
            let temp = [...this.state.approvalList];
            temp[index].approverEmail = event.target.value;
            let tempErrorMsg = [...this.state.requestForErrorMsg];
            //   tempErrorMsg[index].approverEmailMsg = null;
            this.props.emailData.testCampaignEmailAddress = event.target.value.email;
            this.setState({
                approvalList: temp,
                requestForErrorMsg: tempErrorMsg,
                isSendMsgTo: false,
                sendMailToAddress: event.target.value.email,
            });
        } else {
            let temp = [...this.state.approvalList];
            temp[index].input = true;
            temp[index].approverEmail = {
                "email": "",
                "userId": -1
            }
            let tempErrorMsg = [...this.state.requestForErrorMsg];
            this.setState({
                approvalList: temp,
                requestForErrorMsg: tempErrorMsg,
                isSendMsgTo: false,
                sendMailToAddress: event.target.value.email,
            });
        }
    };

    // method for create or add a new inputs in approval for request
    handleAddRequest = (index) => {
        let app = [...this.state.approvalList];

        let dummyObj_ = {
            approverEmail: {
                "email": "-- Select --",
                "userid": 0
            },
            ismandatory: false,
            edmworlflowdetails: 0,
            priority: 1,
            ApprovalSentcount: 1,
            name: "approval",
            input: false,
            appErr: null
        }
        app.push(dummyObj_);
        this.setState({ approvalList: [...app] });
    };

    getVerificationLinks = (edmHtml) => {

        const hrefValues = [];
        const htmlDoc = new DOMParser().parseFromString(edmHtml, 'text/html');
        const anchorElements = htmlDoc.querySelectorAll('a');

        anchorElements.forEach(anchorElement => {
            const href = anchorElement.getAttribute('href');
            if (href) {
                hrefValues.push(href);
            }
        });

        let tempEdmLinks = [];
        hrefValues.forEach((item) => {
            let isValidLink = item.includes("http") ? item : '';
            if (isValidLink !== '') {
                let tempObject = { "LinkURL": isValidLink, "SDKStatus": false, "LinkStatus": false };
                tempEdmLinks.push(tempObject);
            }
        });
        this.props.emailData.content[0].linkVerificationLogDetail = tempEdmLinks;
    }
    // method for remove inputs in request approval 
    handleRemoveRequest = (index) => {
        let app = [...this.state.approvalList];
        app.splice(index, 1);
        let tempApprovalSettings = { ...this.state.approvalSettings };
        tempApprovalSettings.approvalMatchCount = app.length > 2 ? 2 : 1;
        this.setState({
            approvalList: app,
            approvalSettings: tempApprovalSettings
        });
    };


    render() {
        return (
            <div className="mt20 ml20-del posr">
                {/* split ab */}
                {this.state.isShowSplitSizeModal && (
                    <div class="darkfade"></div>
                )}
                <Row className={`custom-column ${this.state.editClickOff ? "click-off" : ""}`}>
                    <Col md={3}>
                        <label className="label-row">Sender name</label>
                    </Col>
                    <Col md={6}>
                        <RSInput2
                            ph="Enter sender name"
                            val={this.state.senderName}
                            required={true}
                            max={100}
                            cb={(text) => {
                                let errMsg = text === "" ? SENDER_NAME : null;
                                this.props.emailData.senderName = text;
                                this.setState({ senderName: text, errSenderNameMsg: errMsg });
                            }}
                            errorMessage={this.state.errSenderNameMsg}
                        />
                        <RSPTooltip text="Personalize" position="top">
                            <div className={`input-outer-set ${this.state.audienceList.length > 0 ? "" : "click-off"}`}>
                                <Tooltip anchorElement="target" position="top">
                                    {/* <div className="title-tip title-tip-up" title='Personalize'> */}
                                    <DropDownButton
                                        className=""
                                        items={this.state.personalizeTabData}
                                        icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
                                        onItemClick={(e) => {
                                            this.props.emailData.senderName = e.item;
                                            this.setState({ senderName: e.item });
                                        }}
                                    />
                                    {/* </div> */}
                                </Tooltip>
                            </div>
                        </RSPTooltip>
                    </Col>
                </Row>
                <Row className={`custom-column ${this.state.editClickOff ? "click-off" : ""}`}>
                    <Col md={3}>
                        <label className="label-row">Sender's email address</label>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={5}>
                                <RSInput2
                                    ph="Username"
                                    val={this.state.senderEmailAddress}
                                    required={true}
                                    max={64}
                                    cb={(text) => {
                                        let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                        if (errMsg === null) {
                                            let regex = /^[A-Za-z0-9._-]*$/
                                            if (regex.test(text)) {
                                                this.setState({
                                                    senderEmailAddress: text,
                                                    errSenderEmailMsg: errMsg,
                                                });
                                            } else {
                                                this.setState({
                                                    errSenderEmailMsg: "Special characters are not allowed (except - _ .)",
                                                });
                                            }
                                        } else {
                                            this.setState({
                                                senderEmailAddress: text,
                                                errSenderEmailMsg: errMsg,
                                            });
                                        }
                                    }}
                                    ob={(text) => {
                                        // let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                        // if (errMsg === null) {
                                        //     errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                                        //     if (errMsg === null) {
                                        //         this.validateSenderDomain(text.split("@")[1])
                                        //     }
                                        // }
                                        // this.setState({ errSenderEmailMsg: errMsg });
                                    }}
                                    errorMessage={this.state.errSenderEmailMsg}
                                />
                            </Col>
                            <Col md={1}>
                                <div className="mt6">@</div>
                            </Col>
                            <Col md={6}>
                                <div className="position-relative">
                                    {this.state.errSenderDomainMsg && (
                                        <div className="validation-message">
                                            {this.state.errSenderDomainMsg}
                                        </div>)}
                                    <RSDropdownList
                                        data={this.state.activeDomainList}
                                        className="mb10 required"
                                        value={this.state.activeDomain}
                                        defaultItem={{
                                            "smtpDomainSettingId": -1,
                                            "domainName": "-- Select domain --"
                                        }}
                                        textField='domainName'
                                        dataItemKey='smtpDomainSettingId'
                                        onChange={(event) => {
                                            let errMsg = event.target.value.smtpDomainSettingId !== -1 ? null : SELECT_DOMAIN;
                                            this.setState({
                                                activeDomain: event.target.value,
                                                errSenderDomainMsg: errMsg,
                                            })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* <RSInput2
                            ph="{info@domain.com}"
                            val={this.state.senderEmailAddress}
                            required={true}
                            max={254}
                            cb={(text) => {
                                let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                if (errMsg === null) {
                                    errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                                }
                                this.props.emailData.senderEmail = text;
                                this.setState({
                                    senderEmailAddress: text,
                                    errSenderEmailMsg: errMsg,
                                });
                            }}
                            ob={(text) => {
                                let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                if (errMsg === null) {
                                    errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                                    if (errMsg === null) {
                                        this.validateSenderDomain(text.split("@")[1])
                                    }
                                }
                                this.setState({ errSenderEmailMsg: errMsg });
                            }}
                            errorMessage={this.state.errSenderEmailMsg}
                        /> */}
                        {/* <div className="input-outer-set">
                            <RSPTooltip text="Personalize" position="top">
                                <div>
                                    <DropDownButton
                                        className=""
                                        items={this.state.emailPersonlizeData}
                                        icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
                                        onItemClick={(e) => {
                                            this.setState({ senderEmailAddress: e.item });
                                        }}
                                    />
                                </div>
                            </RSPTooltip>
                        </div> */}
                        <div className="d-flex align-items-center mt-20 mb20 position-relative">
                            <RSCheckbox
                                className="marginB0"
                                lbl="Reply email"
                                checked={this.state.isReplyMail}
                                cb={(status) => {
                                    this.props.emailData.isReplyMailEnabled = status;
                                    let tempAlternateEmailAddress = this.state.alternateEmailAddress === "" ? this.state.senderEmailAddress : this.state.alternateEmailAddress;
                                    let tempReplyDomain = (this.state.activeReplyDomain === undefined || this.state.activeReplyDomain.smtpDomainSettingId === -1) ? this.state.activeDomain : this.state.activeReplyDomain;
                                    this.setState({ isReplyMail: status, errReplyEmailMsg: null, alternateEmailAddress: tempAlternateEmailAddress, activeReplyDomain: tempReplyDomain, errReplyDomainMsg: null });
                                }}
                            />
                            <div>
                                <RSPTooltip text="Please note that the reply email will be sent to the set Sender’s email address" position="top">
                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
                                </RSPTooltip>
                            </div>

                            {/* <RSPPophover
                                text="Please note that the reply email will be sent to the set Sender’s email address"
                                position="top"
                            >
                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                            </RSPPophover> */}
                        </div>
                        {this.state.isReplyMail && (
                            <Row>
                                <Col md={5}>
                                    <RSInput2
                                        ph="Username"
                                        val={this.state.alternateEmailAddress}
                                        required={true}
                                        max={64}
                                        cb={(text) => {
                                            let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                            if (errMsg === null) {
                                                let regex = /^[A-Za-z0-9._-]*$/
                                                if (regex.test(text)) {
                                                    this.setState({
                                                        alternateEmailAddress: text,
                                                        errReplyEmailMsg: errMsg,
                                                    });
                                                } else {
                                                    this.setState({
                                                        errReplyEmailMsg: "Special characters are not allowed (except - _ .)",
                                                    });
                                                }
                                            } else {
                                                this.setState({
                                                    alternateEmailAddress: text,
                                                    errReplyEmailMsg: errMsg,
                                                });
                                            }
                                        }}
                                        ob={(text) => {
                                            // let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                            // if (errMsg === null) {
                                            //     errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                                            //     if (errMsg === null) {
                                            //         this.validateSenderDomain(text.split("@")[1])
                                            //     }
                                            // }
                                            // this.setState({ errSenderEmailMsg: errMsg });
                                        }}
                                        errorMessage={this.state.errReplyEmailMsg}
                                    />
                                </Col>
                                <Col md={1}>
                                    <div className="mt6">@</div>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {this.state.errReplyDomainMsg && (
                                            <div className="validation-message">
                                                {this.state.errReplyDomainMsg}
                                            </div>)}
                                        <RSDropdownList
                                            data={this.state.activeDomainList}
                                            className="mb10 required"
                                            value={this.state.activeReplyDomain}
                                            defaultItem={{
                                                "smtpDomainSettingId": -1,
                                                "domainName": "-- Select domain --"
                                            }}
                                            textField='domainName'
                                            dataItemKey='smtpDomainSettingId'
                                            onChange={(event) => {
                                                let errMsg = event.target.value.smtpDomainSettingId !== -1 ? null : SELECT_DOMAIN;
                                                this.setState({ activeReplyDomain: event.target.value, errReplyDomainMsg: errMsg })
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            // <RSInput2
                            //     ph="Alternate email address"
                            //     className=""
                            //     val={this.state.alternateEmailAddress}
                            //     required={true}
                            //     max={255}
                            //     cb={(text) => {
                            //         let errMsg = text === "" ? REPLY_EMAIL : null;
                            //         if (errMsg === null) {
                            //             errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                            //         }
                            //         this.props.emailData.replyEmail = text;
                            //         this.setState({
                            //             alternateEmailAddress: text,
                            //             errReplyEmailMsg: errMsg,
                            //         });
                            //     }}
                            //     errorMessage={this.state.errReplyEmailMsg}
                            // />
                        )}
                    </Col>
                </Row>
                <Row className={`custom-column mb30 ${this.state.editClickOff ? "click-off" : ""}`}>
                    <Col md={3}>
                        <label className="label-row">Audience</label>
                    </Col>
                    <Col md={6}>
                        <div className="chips-input-container">
                            <div className="chips-input-box required">
                                <div className="position-relative">
                                    {this.state.errAudienceMsg && (
                                        <div className="validation-message">
                                            {this.state.errAudienceMsg}
                                        </div>
                                    )}
                                    <MultiSelect
                                        placeholder="-- Select the list --"
                                        // data={this.getRemainingAudienceList()}
                                        data={this.state.audienceListForDropDown}
                                        textField="recipientEmailDisplayName"
                                        dataItemKey="segmentationListID"
                                        filterable={true}
                                        onFilterChange={this.filterChange}
                                        onChange={(event) => {
                                            let errMsg = event.target.value.length === 0 ? AUDIENCE : null;
                                            errMsg = event.target.value.length > 1 ? "More than one list is not allowed" : null;
                                            if (event.target.value.length <= 1) {
                                                let paymentStatus = event.target.value.length === 0 ? null : event.target.value[0]?.isPaid ? null : "Payment for the list is pending";
                                                this.props.emailData.targetListTargetAudience = event.target.value;
                                                this.setState({ audienceList: event.target.value, errAudienceMsg: errMsg, paymentStatusForList: paymentStatus });
                                                if (event.target.value.length > 0) {
                                                    this.getAdhocAttributeHeaders(event.target.value[0].segmentationListID);
                                                } else {
                                                    this.setState({ subjectLinePersonalizeData: [] })
                                                }
                                            } else {
                                                this.setState({ errAudienceMsg: errMsg });
                                            }
                                        }}
                                        value={this.state.audienceList}
                                    />
                                </div>
                            </div>
                            <small>{this.state.paymentStatusForList ? this.state.paymentStatusForList : ""}</small>
                        </div>
                    </Col>
                </Row>
                <Row className={`custom-column mt20 mb20 split-ab-row d-none ${this.state.editClickOff ? "click-off" : "click-off"}`}>
                    <Col md={3}>
                        <label>Split A/B test</label>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex align-items-center mb20">
                            <Switch
                                className={`mt0 ${this.state.audienceList[0]?.recipientCount > 100 ? "" : "click-off"}`}
                                onLabel={"ON"}
                                offLabel={"OFF"}
                                onChange={(event) => {
                                    this.props.emailData.isSplitAB = event.target.value;
                                    if (event.target.value) {
                                        let temp = [...this.props.emailData.content];
                                        temp[0].edmChannelId = 0;
                                        temp[0].subjectLine = "";
                                        temp[0].contentType = "";
                                        temp[0].splitType = "A";
                                        temp[0].statusId = 0;
                                        temp[0].body = "";
                                        temp[0].textContent = "";
                                        temp[0].preHeaderMessage = "";
                                        temp[0].spamScore = 3.0;
                                        temp[0].isFooterEnabled = false;
                                        temp[0].footerId = 0;
                                        temp[0].unsubscribeSettingId = 0;
                                        temp[0].isUnsubscribeEnabled = false;
                                        temp[0].contentUrl = "";
                                        temp[0].htmlFilePath = "";
                                        temp[0].previewId = 0;
                                        temp[0].zipFilePath = "";
                                        temp[0].isViewinBrowserChecked = true;
                                        temp[0].templateId = 0;
                                        temp[0].dynamiclistId = 0;
                                        temp[0].timezoneId = this.state.timeZone.timezoneId;
                                        temp[0].localBlastDateTime = "";

                                        var add = this.state.splitTabData.length;
                                        var charCode = "A".charCodeAt(0);
                                        add = charCode + (add - 1);
                                        temp.push(this.createSplitObject(String.fromCharCode(add)));
                                        this.props.emailData.content = temp;
                                        this.setState({ isSplit: !this.state.isSplit });
                                    } else {
                                        this.setState({
                                            isOpenSplitResetModal: !this.state.isOpenSplitResetModal,
                                        });
                                    }
                                }}
                                checked={this.state.isSplit}
                            />
                            {this.state.isSplit && (
                                <div className="d-flex align-items-center">
                                    <i
                                        className="icon-dropdown-small cp ml15"
                                        onClick={() => {
                                            this.setState({
                                                isShowSplitSizeModal: !this.state.isShowSplitSizeModal,
                                            });
                                        }}
                                    ></i>
                                    <RSPTooltip text={"Schedule"} position="top">
                                        <i
                                            className="icon-sd-settings-mini icons-md blue cp ml10"
                                            onClick={() => {
                                                this.setState({
                                                    isSplitSettings: !this.state.isSplitSettings,
                                                });
                                            }}
                                        />
                                    </RSPTooltip>
                                </div>
                            )}
                            <RSPPophover
                                text="A minimum of 100 audience are required for split A/B testing. Check the communication listing page for results."
                                position="top"
                            >
                                <i className="icon-sd-question-mark-mini icons-sm blue cp ml10"></i>
                            </RSPPophover>
                        </div>
                    </Col>
                    {/* split ab */}
                    {this.state.isShowSplitSizeModal && (
                        <>
                            <RSSplitAB onCancel={() => this.setState({ isShowSplitSizeModal: !this.state.isShowSplitSizeModal })} />
                            {/* <SplitSizeModal
                                isOpen={this.state.isShowSplitSizeModal}
                                splitSizeDetails={this.props.emailData.edmSplit}
                                onChangeIsOpen={(value) => {
                                    this.setState({ isShowSplitSizeModal: value });
                                }}
                            /> */}
                        </>
                    )}
                </Row>
                {!this.state.isSplit && (
                    <Row className={`custom-column mt20 ${this.state.editClickOff ? "click-off" : ""}`}>
                        <Col md={3}>
                            <label className="label-row">Subject line</label>
                        </Col>
                        <Col md={6}>
                            <div className="position-relative">
                                {this.state.errSubjectLineMsg && (
                                    <div className="validation-message">
                                        {this.state.errSubjectLineMsg}
                                    </div>
                                )}
                                <input
                                    type="textarea"
                                    className="marginB5 required emoji-font"
                                    placeholder="Enter subject line"
                                    value={this.state.subjectLine}
                                    onBlur={(event) => { this.props.emailData.content[0].subTitlePosition = event.target.selectionStart; }}
                                    onChange={(event) => {
                                        if (event.target.value.length > 500) {
                                            this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                                        } else {
                                            let errMsg = event.target.value.length === 0 ? SUBJECT_LINE : null;
                                            this.setState({ subjectLine: event.target.value, errSubjectLineMsg: errMsg });
                                            this.props.emailData.content[0].subjectLine = event.target.value;
                                        }
                                    }}
                                />
                            </div>
                            <div className="input-below-icon-set">
                                <div className={`${this.state.audienceList.length > 0 ? "" : "click-off"}`}>
                                    <DropDownButton
                                        className=""
                                        items={this.state.subjectLinePersonalizeData}
                                        // icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
                                        popupSettings={{
                                            animate: true,
                                            popupClass: 'personalize-dropdown'
                                        }}
                                        text={
                                            <RSPTooltip text="Personalize" position="top">
                                                <i className=" icon-sd-user-question-mark-edge-medium blue icons-md"></i>
                                            </RSPTooltip>
                                        }
                                        onItemClick={(e) => {
                                            let obj = this.props.emailData.content[0];
                                            let value = " " + e.item + " ";
                                            let val = getContent(obj.subjectLine, value, obj.subTitlePosition);
                                            if (val.length > 500) {
                                                this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                                            } else {
                                                this.props.emailData.content[0].subjectLine = val;
                                                this.props.emailData.content[0].subTitlePosition = obj.subTitlePosition + value.length;
                                                this.setState({ subjectLine: val, errSubjectLineMsg: null });
                                            }
                                        }}
                                    />
                                    {/* </div> */}
                                </div>

                                <RSPTooltip text="Emoji" position="top">
                                    <div className="per-ico-ht marginT3 marginL10">
                                        <i
                                            className="icon-sd-circle-smile-medium blue icons-md cp"
                                            ref={this.emojianochor}
                                            onClick={() => {
                                                this.buttonClick(1)
                                            }}></i>
                                    </div>
                                </RSPTooltip>
                            </div>

                        </Col>
                    </Row>
                )}

                {this.state.isSplit ? (
                    <div className={`split-container ${this.state.editClickOff ? "click-off" : ""}`}>
                        <div className="tabs-filled-component-del d-flex align-items-center">
                            <RTabbar1
                                defaultSelectedItem={this.state.splitTabIndex}
                                defaultClass="tabDefault"
                                dynamicTab="addtab"
                                activeClass="tabDefault active"
                                tabData={this.state.splitTabData}
                                delete={true}
                                type="split"
                                mainClass="even-space-4"
                                deleteTabData={(removeIndex) => {
                                    let tempData = [...this.state.splitTabData];
                                    tempData.splice(removeIndex, 1);
                                    let temp = [...this.props.emailData.content];
                                    temp.splice(removeIndex, 1);
                                    this.props.emailData.content = temp;
                                    this.setState({ splitTabData: tempData });
                                }}
                                callBack={(item, index) => {
                                    this.setState({ splitTabIndex: index });
                                }}
                            />
                            {/* {this.state.splitTabData.length < 4
                                &&
                                <i
                                    className={`d-inline-block icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer ml15 ${this.props.editClickOff ? "click-off" : ""}`}
                                    onClick={() => {
                                        let enable = true;
                                        for (var i = 0; i < this.props.emailData.content.length; i++) {
                                            enable = this.splitValidation(i);
                                            if (!enable) {
                                                this.setState({ splitTabIndex: i });
                                                break;
                                            }
                                        }
                                        // let enable = this.splitValidation();
                                        if (enable) {
                                            if (this.state.splitTabData.length < 4) {
                                                var add = this.state.splitTabData.length;
                                                var charCode = "A".charCodeAt(0);
                                                add = charCode + add;

                                                var obj = {
                                                    id: add,
                                                    text: "Split " + String.fromCharCode(add),
                                                };
                                                var list = [...this.state.splitTabData];
                                                list.push(obj);
                                                this.setState({ splitTabData: list });

                                                let temp = [...this.props.emailData.content];
                                                temp.push(
                                                    this.createSplitObject(
                                                        String.fromCharCode(add)
                                                    )
                                                );
                                                this.props.emailData.content = temp;
                                                let errTemp = [...this.state.splitContentError];
                                                errTemp.push(this.createSplitError());
                                                this.setState({ splitContentError: errTemp, splitTabIndex: this.state.splitTabData.length });
                                            }
                                        }
                                    }}
                                />} */}
                        </div>
                        {<this.SplitComponents />}
                    </div>
                ) : (
                    <div className={`ml-20-del `}>
                        <this.header editClickOff={this.state.editClickOff} />
                        <DefaultContent
                            personalizeTabData={this.state.subjectLinePersonalizeData}
                            emailData={this.props.emailData}
                            edmChannelId={this.state.edmChannelId !== 0 ? this.state.edmChannelId : this.props.emailData.content[0].edmChannelId}
                            isUpdate={this.props.isUpdate}
                            campaignId={this.props.campaignId}
                            contentData={this.props.emailData.content[0]}
                            defaultError={this.state.defaultContentError}
                            activeDomain={this.state.activeDomain}
                            senderEmailAddress={this.state.senderEmailAddress}
                            activeReplyDomain={this.state.activeReplyDomain}
                            alternateEmailAddress={this.state.alternateEmailAddress}
                            isReplyMail={this.state.isReplyMail}
                            editClickOff={this.state.editClickOff}
                            getDefaultContentError={(key, value) => {
                                let tempDefaultContentErrorMsg = { ...this.state.defaultContentError }
                                if (key === "errBodyMsg") {
                                    tempDefaultContentErrorMsg.errBodyMsg = value;
                                } else if (key === "errSendTestMailMsg") {
                                    tempDefaultContentErrorMsg.errSendTestMailMsg = value;
                                } else if (key === "errEnableUnSubMsg") {
                                    tempDefaultContentErrorMsg.errEnableUnSubMsg = value;
                                } else if (key === "errUnSubMsg") {
                                    tempDefaultContentErrorMsg.errUnSubMsg = value;
                                } else if (key === "errImportContent") {
                                    tempDefaultContentErrorMsg.errImportContent = value;
                                }
                                this.setState({ defaultContentError: tempDefaultContentErrorMsg })
                            }}
                            getEdmChannelId={(value) => {
                                this.props.emailData.content[0].edmChannelId = value;
                                this.setState({ edmChannelId: value });
                            }}
                            getEmailFooterContent={(value) => {
                                this.setState({ emailfooterContent: value });
                            }}
                            onValidateSearch={(status) => {
                                let isValidate = this.validation("draft");
                                this.setState({ isSearchValidate: isValidate });
                            }}
                            isSearchValidate={this.state.isSearchValidate}
                            onChangeIsSearchValidate={() => {
                                this.setState({ isSearchValidate: false });
                            }}
                            onValidateSpamCheck={(status) => {
                                let isValidateSpam = this.validation('draft');
                                this.setState({ isSpamCheckValidate: isValidateSpam });
                            }}
                            isSpamCheckValidate={this.state.isSpamCheckValidate}
                            segList={this.state.audienceList}
                            validation={this.templateValidation}
                        />
                        <div className="schedule-even paddingT10 mt13 mb30">
                            <Row className={`custom-column paddingB10 ${this.state.editClickOff ? "click-off" : ""}`}>

                                <Col md={{ span: 3, offset: 0 }} className="">
                                    <label className="label-row">Schedule</label>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {this.state.errScheduleDateMsg && (
                                            <div className="validation-message">
                                                {this.state.errScheduleDateMsg}
                                            </div>
                                        )}
                                        <div className="rsptimer-picker">
                                            <DateTimePicker
                                                className="required"
                                                placeholder="Select schedule date and time"
                                                value={
                                                    this.state.schedule !== ""
                                                        ? new Date(this.state.schedule)
                                                        : ""
                                                }
                                                // show={true}
                                                // min={this.state.startDate}
                                                min={this.getStartDateForSchedule()}
                                                max={this.state.endDate}
                                                format={getUserData()?.timeFormat === "24 hours" ? "dd MMM, yyyy HH:mm " : "dd MMM, yyyy hh:mm a"}
                                                popup={CustomPopup}
                                                onChange={(event) => {
                                                    if (event.target.value !== null) {
                                                        let localBlastTime = moment(event.target.value).format(getUserData()?.timeFormat === "24 hours" ? "DD MMM, yyyy HH:mm " : "DD MMM, yyyy hh:mm a")
                                                        this.props.emailData.content[0].localBlastDateTime = localBlastTime
                                                        let errMsg = this.props.emailData.content[0].localBlastDateTime !== "" ? null : SCHEDULE_DATE
                                                        this.setState({ schedule: event.target.value, errScheduleDateMsg: errMsg });
                                                    } else {
                                                        this.props.emailData.content[0].localBlastDateTime = ""
                                                        this.setState({ schedule: "", errScheduleDateMsg: SCHEDULE_DATE });
                                                    }
                                                }}
                                            />
                                            <div className={`input-outer-set ${(this.state.schedule !== "") ? "" : "click-off"} `}>
                                                <RSPTooltip text="Reset schedule" position="top">
                                                    <i
                                                        className="icon-sd-refresh-medium icons-md blue ml10"
                                                        onClick={() => {
                                                            this.setState({ schedule: "" });
                                                            this.props.emailData.content[0].localBlastDateTime = "";
                                                        }}
                                                    />
                                                </RSPTooltip>
                                            </div>
                                        </div>

                                    </div>
                                    <Row className="marginT5">
                                        {/* <Col md="8">
                                            <div className="d-flex align-items-center marginB30">
                                                <RSCheckbox
                                                    className="marginB0"
                                                    lbl="Use send time recommendation"
                                                    checked={this.state.isSendRecommendation}
                                                    cb={(value) => {
                                                        this.setState({
                                                            isSendRecommendation:
                                                                !this.state.isSendRecommendation,
                                                        });
                                                    }}
                                                />
                                                <RSPPophover text="Send time will be based on Resulticks recommendation model, arrived at using individual audience's open time propensity" position="top">
                                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                                </RSPPophover>
                                            </div>
                                        </Col> */}
                                        {this.state.isEditTimeZone === false ? (
                                            <Col>
                                                <div className="float-end">
                                                    <label>
                                                        {!!this.state.timeZone &&
                                                            this.state.timeZone.gmtOffset}
                                                    </label>
                                                    <RSPTooltip text="Edit" position="top" >
                                                        <i
                                                            className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isEditTimeZone: !this.state.isEditTimeZone,
                                                                });
                                                            }}
                                                        />
                                                    </RSPTooltip>
                                                </div>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.isEditTimeZone ? (
                                <Row className={`custom-column paddingT10 ${this.state.editClickOff ? "click-off" : ""}`}>
                                    <Col md={3}>
                                        <label className="label-row">Select time zone</label>
                                    </Col>
                                    <Col md={6}>
                                        <div className="position-relative">
                                            {this.state.errTimeZoneMsg && (
                                                <div className="validation-message">
                                                    {this.state.errTimeZoneMsg}
                                                </div>
                                            )}
                                            <RSDropdownList
                                                className="marginB0 required"
                                                data={this.state.timeZoneData || []}
                                                value={this.state.timeZone}
                                                defaultItem={{
                                                    timezoneId: 0,
                                                    timezoneName: "-- Select time zone --",
                                                }}
                                                textField="timezoneName"
                                                dataItemKey="timezoneId"
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                                                    this.setState({ timeZone: e.target.value, errTimeZoneMsg: errMsg });
                                                    this.props.emailData.content[0].timezoneId = e.target.value.timezoneId;
                                                    this.getStartDateForSchedule();
                                                }}
                                            />
                                        </div>
                                        <Row className="marginT5">
                                            <Col md="8">
                                                <div className="d-flex align-items-center">
                                                    <RSCheckbox
                                                        className="marginT0 marginB15 click-off"
                                                        lbl="Daylight saving"
                                                        checked={this.state.isDayLightSavings}
                                                        cb={(value) => {
                                                            this.setState({
                                                                isDayLightSavings: !this.state.isDayLightSavings,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : null}
                        </div>
                    </div>
                )}
                <>
                    {(this.state.activeTab !== -1 || this.state.isSplit) && (
                        <div className={`${(this.props.emailData.channelstatusId === 7 && this.state.isEditRequestForApproval) ? "click-off" : ""}`}>
                            {this.state.approvalList.map((item, index) => {
                                return (
                                    <Row className={`custom-column marginT0 ${this.state.editClickOff ? "click-off" : ""}`}>
                                        <Col md={3}>
                                            {index === 0 &&
                                                <label className="label-row">
                                                    {this.state.isRequestForApproval
                                                        ? "Send approval request to"
                                                        : "Send test mail to"}
                                                </label>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <div className="position-relative mr15">
                                                {item.appErr && (
                                                    <div className="validation-message">
                                                        {item.appErr}
                                                    </div>
                                                )}
                                                {!item.input ? (
                                                    <RSDropdownList
                                                        className={`marginB0 ${this.state.isRequestForApproval ? "required" : ""}`}
                                                        data={this.state.approverListFromServer || []}
                                                        textField="email"
                                                        dataItemKey="userid"
                                                        value={item.approverEmail}
                                                        defaultItem={{
                                                            email: "-- Select --",
                                                            userid: 0,
                                                        }}
                                                        onChange={(event) => { this.handleChangeRequest(event, index) }}

                                                    />
                                                ) : (
                                                    <div>
                                                        <RSInput2
                                                            required={this.state.isRequestForApproval}
                                                            ph={this.state.isRequestForApproval ? "Enter the approver's email address" : "Enter email ID"}
                                                            val={item.approverEmail.email}
                                                            cls="mb10 paddingR35"
                                                            cb={(text) => { this.handleChnageInputs(text, index) }}
                                                            ob={(text) => { this.hanldeBlurInputs(text, index) }}
                                                            errorMessage={item.appErr}
                                                        />
                                                        <div className="input-inner-set">
                                                            <i className="icon-sd-close-mini icons-sm blue mr-10"
                                                                onClick={() => { this.handleCloseInput(index) }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="input-outer-set">

                                                    <>
                                                        {index === 0 ?
                                                            <RSPTooltip text="Add" position="top" >
                                                                <i className={`icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer d-block ml15 ${((this.state.approvalList.length > 2 || !this.state.isRequestForApproval)) && "click-off"}`}
                                                                    onClick={() => {
                                                                        this.handleAddRequest(index)
                                                                    }}
                                                                />
                                                            </RSPTooltip>
                                                            :
                                                            <RSPTooltip text="Remove" position="top" >
                                                                <i className="icon-sd-circle-minus-fill-edge-medium icons-md red cursor-pointer ml15"
                                                                    onClick={() => {
                                                                        this.handleRemoveRequest(index)
                                                                    }}
                                                                />
                                                            </RSPTooltip>}
                                                    </>
                                                    {this.state.approvalSettings.approvalMatchType.toLowerCase() === 'any' &&
                                                        <RSPTooltip text="Set mandatory" position="top" >
                                                            <i className={item.ismandatory ? "icon-sd-asterisk-mini red icons-sm mand-field cursor-pointer" : "icon-sd-asterisk-mini icons-sm mand-field cursor-pointer position-relative z-1"}
                                                                onClick={() => {
                                                                    if (item.ismandatory) {
                                                                        item.ismandatory = !item.ismandatory;
                                                                        let tempApprovalList = [...this.state.approvalList]
                                                                        tempApprovalList[index].ismandatory = item.ismandatory;
                                                                        this.setState({ approvalList: tempApprovalList })
                                                                    }
                                                                    else if (this.state.approvalList.length > this.state.approvalSettings.approvalMatchCount) {
                                                                        if (this.state.approvalList.filter(obj => obj.ismandatory === true).length !== this.state.approvalSettings.approvalMatchCount) {
                                                                            item.ismandatory = !item.ismandatory;
                                                                            let tempApprovalList = [...this.state.approvalList]
                                                                            tempApprovalList[index].ismandatory = item.ismandatory;
                                                                            this.setState({ approvalList: tempApprovalList })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </RSPTooltip>
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3} className="ml15">
                                            {index === 0 && (
                                                <RSPrimaryBtn
                                                    className={
                                                        !this.isEnableSaveButton()
                                                            ? "click-off"
                                                            : (this.state.sendMailToAddress !== "" && this.state.sendMailToAddress.includes("@") && this.state.errSenderEmailMsg === null)
                                                                ? ""
                                                                : this.state.isRequestForApproval
                                                                    ? ""
                                                                    : "click-off"}
                                                    onClick={() => {
                                                        if (this.state.isRequestForApproval) {
                                                            let approvarList = [...this.state.approvalList].map((item, index) => {
                                                                return {
                                                                    approvarId: item.approverEmail?.userid || "",
                                                                    approvarName: item.approverEmail.email,
                                                                    flag: false,
                                                                    ismandatory: item.ismandatory,
                                                                    priority: item.priority,
                                                                    ApprovalSentcount: item.ApprovalSentcount,
                                                                };
                                                            });
                                                            let approval_object = {
                                                                IsFollowHierarchy: this.state.approvalSettings.isFollowHierarchyEnabled,
                                                                matchType: this.state.approvalSettings.approvalMatchType,
                                                                matchCount: this.state.approvalSettings.approvalMatchCount,
                                                                approvarList: approvarList,
                                                            };
                                                            this.props.emailData.requestForApproval = approval_object;

                                                        }
                                                        let isValidate = true;
                                                        if (this.state.isRequestForApproval) {
                                                            isValidate = this.validation();
                                                        } else {
                                                            isValidate = this.validation("draft");
                                                            let emails = this.state.sendMailToAddress.split(",");
                                                            emails.forEach((item, index) => {
                                                                if (!validateEmail(item)) {
                                                                    this.setState({ errSendMailMsg: ENTER_VALID_EMAIL });
                                                                    isValidate = false;
                                                                    return;
                                                                }
                                                            })
                                                        }
                                                        if (isValidate === undefined || isValidate) {
                                                            if (this.props.emailData.content[0].contentType === "Z" && this.props.emailData.content[0].edmDimension === 0) {
                                                                let element = document.getElementById("edmFrame")
                                                                let edmHeight = element.contentWindow.document.body.scrollHeight
                                                                let edmWidth = element.contentWindow.document.body.scrollWidth
                                                                let edmDimension = edmHeight * edmWidth
                                                                this.props.emailData.content[0].edmDimension = edmDimension
                                                            }
                                                            let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
                                                            this.props.emailData.senderEmail = this.state.senderEmailAddress + "@" + this.state.activeDomain.domainName;
                                                            if (this.state.isReplyMail) {
                                                                this.props.emailData.replyEmail = this.state.alternateEmailAddress + "@" + this.state.activeReplyDomain.domainName;
                                                            } else {
                                                                this.props.emailData.replyEmail = ""
                                                            }
                                                            let params = createEmailCampaign(
                                                                "S",
                                                                this.props.campaignId,
                                                                this.props.emailData,
                                                                databaseName,
                                                                "",
                                                                this.state.isRequestForApproval ? false : true
                                                            );
                                                            connectServer({
                                                                path: this.state.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                                                                params,
                                                                loading: this.context.globalStateData.setIsLoadingData,
                                                                sessionOut: this.context.globalStateData.setSessionOutData,
                                                                context: this.context,
                                                                ok: (res) => {
                                                                    if (res.status) {
                                                                        if (this.state.edmChannelId === 0) {
                                                                            this.props.emailData.content[0].edmChannelId = res.edmChannelId;
                                                                            this.setState({ edmChannelId: res.edmChannelId })
                                                                        }
                                                                        this.setState({
                                                                            isOpenSendSuccessModal: true,
                                                                            isRequestForApproval: this.props.emailData.isRequestForApprovalEnabled,
                                                                            schedule: this.props.emailData.content[0].localBlastDateTime
                                                                        });
                                                                    }
                                                                },
                                                                fail: (err) => {
                                                                },
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </RSPrimaryBtn>
                                            )}
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>)}
                    {(this.state.activeTab !== -1 || this.state.isSplit) && (
                        <Row className={`custom-column marginT0 ${(this.state.editClickOff || (this.props.emailData.channelstatusId === 7 && this.state.isEditRequestForApproval)) ? "click-off" : ""}`}>
                            <Col md={3}>
                            </Col>
                            <Col md={6} className="pr0">
                                <RSCheckbox
                                    className="marginB0"
                                    lbl="Request for approval"
                                    checked={this.state.isRequestForApproval}
                                    cb={(status) => {
                                        let errMsg = this.state.approvalList[0].approverEmail.email !== "-- Select --" ? null : "Select email id";
                                        let tempApprovalList = [
                                            {
                                                approverEmail: {
                                                    "email": "-- Select --",
                                                    "userid": 0
                                                },
                                                ismandatory: false,
                                                edmworlflowdetails: 0,
                                                priority: 1,
                                                ApprovalSentcount: 1,
                                                name: "approval",
                                                input: false,
                                                appErr: null
                                            }
                                        ]
                                        let tempApprovalSettings = {
                                            approvalMatchType: "All",
                                            approvalMatchCount: 0,
                                            isFollowHierarchyEnabled: false,
                                        }
                                        if (!status) {
                                            this.setState({ sendMailToAddress: "", approvalList: tempApprovalList, approvalSettings: tempApprovalSettings, isRequestForApproval: status })
                                            this.props.emailData.isRequestForApprovalEnabled = status;
                                            this.props.emailData.requestForApproval = {
                                                "IsFollowHierarchy": false,
                                                "matchType": "All",
                                                "matchCount": "0",
                                                "approvarList": [
                                                    {
                                                        "approvarId": "1",
                                                        "approvarName": "murthy.ch14@gmail.com",
                                                        "flag": false,
                                                        "ismandatory": false,
                                                        "priority": 1,
                                                        "ApprovalSentcount": 1
                                                    }
                                                ]
                                            }
                                        } else {
                                            let tempApproveList = [...this.state.approvalList];
                                            tempApproveList[0].appErr = errMsg;
                                            this.setState({ isRequestForApproval: errMsg === null ? status : false, approvalList: tempApproveList });
                                            this.props.emailData.isRequestForApprovalEnabled = errMsg === null ? status : false;
                                        }
                                    }}
                                />
                                <RSPPophover
                                    text="Get the communication content verified and approved by one or more authorized approvers."
                                    position="top"
                                >
                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp"></i>
                                </RSPPophover>

                            </Col>
                            <Col md={3} className="pl0">
                                {this.state.approvalList.length > 1 &&
                                    <div className="left-11 position-relative">
                                        <RSPTooltip position="top" text="Approval settings">
                                            <i className={`blue cursor-pointer icon-sd-settings-medium icons-md ${this.state.isRequestForApproval ? "" : "click-off"}`}
                                                onClick={() => {
                                                    this.setState({ isModalOpen: true });
                                                }}
                                            ></i>
                                        </RSPTooltip>
                                    </div>
                                }
                            </Col>
                        </Row>
                    )}
                </>
                {this.state.isModalOpen && (
                    <ApprovalSettings
                        isOpen={this.state.isModalOpen}
                        approvalSettings={this.state.approvalSettings}
                        approvalListCount={this.state.approvalList.length}
                        onOpenChange={(value) => {
                            if (value) {
                                let tempApprovalList = [...this.state.approvalList]
                                tempApprovalList.map((item, index) => {
                                    item.ismandatory = false
                                })
                                this.setState({ approvalList: tempApprovalList, isModalOpen: false });
                            } else {
                                this.setState({ isModalOpen: value });
                            }
                        }}
                    ></ApprovalSettings>
                )}
                {this.state.isOpenSubjectModal && (
                    <SubjectLineModal
                        isOpen={this.state.isOpenSubjectModal}
                        onChangeIsOpen={(value) => {
                            this.setState({ isOpenSubjectModal: value });
                        }}
                    />
                )}
                {this.state.isSplitSettings && (
                    <SplitSettingsModal
                        isOpen={this.state.isSplitSettings}
                        communicationId={this.props.campaignId}
                        communicationData={this.props.emailData}
                        edmAutoSchedule={this.props.emailData.edmAutoSchedule}
                        onChangeIsOpen={(value) => {
                            this.setState({ isSplitSettings: value });
                        }}
                    />
                )}
                {this.state.isOpenSendSuccessModal &&
                    <SuccessModal
                        isOkButton={false}
                        isOpen={this.state.isOpenSendSuccessModal}
                        title={"Success"}
                        subTitle={this.state.isRequestForApproval ? "Communication has been sent successfully for approval." : "Test communication will be sent shortly."}
                        buttonClicked={(value) => {
                            if (this.state.isRequestForApproval && this.state.isOpenSendSuccessModal) {
                                this.setState({ isOpenSendSuccessModal: false })
                                this.props.moveNext(1);
                            } else {
                                this.setState({ isOpenSendSuccessModal: false })
                            }
                        }} />
                }
                {this.state.isRefreshModal &&
                    <RSConfirmAlert
                        subTitle="Selecting a new source will clear the existing content. Do you want to continue?"
                        secondaryButtonText="Cancel"
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            if (value) {
                                this.props.emailData.content[0].contentType = "";
                                this.props.emailData.content[0].body = "";
                                this.props.emailData.content[0].textContent = "";
                                this.props.emailData.content[0].isFooterEnabled = false;
                                this.props.emailData.content[0].preHeaderMessage = "";
                                this.props.emailData.content[0].footerId = 0;
                                this.props.emailData.content[0].unsubscribeSettingId = 0;
                                this.props.emailData.content[0].isUnsubscribeEnabled = false;
                                this.props.emailData.content[0].isViewinBrowserChecked = true;
                                this.props.emailData.content[0].edmFileWeight = 0;
                                this.props.emailData.content[0].edmDimension = 0;
                                this.props.emailData.content[0].edmImageDimension = 0;
                                this.props.emailData.content[0].linkVerificationLogDetail = [];
                                let tempDefaultError = { ...this.state.defaultContentError };
                                tempDefaultError.errBodyMsg = null;
                                tempDefaultError.errSendTestMailMsg = null;
                                tempDefaultError.errEnableUnSubMsg = null;
                                tempDefaultError.errUnSubMsg = null;
                                tempDefaultError.errImportContent = null;
                                tempDefaultError.errTemplateContent = null;
                                this.setState({
                                    errScheduleDateMsg: null,
                                    errSendMailMsg: null,
                                    defaultContentError: tempDefaultError,
                                    errEmailContentMsg: null,
                                })
                                this.updateTabStatus();
                            }
                            this.setState({ isRefreshModal: false })
                        }}
                    />
                }
                <div className="btn-container d-flex justify-content-end mt40">
                    <RSSecondaryBtn
                        paddingR="0px"
                        className="mr0"
                        onClick={() => {
                            this.props.history.push(`/communication`);
                        }}
                    >
                        Cancel
                    </RSSecondaryBtn>
                    <RSSecondaryBtn className={`mx7 blue ${(this.state.editClickOff || (this.props.mode === "edit" && this.props.emailData.channelstatusId === 7) || this.state.isRequestForApproval) ? "click-off" : !this.isEnableSaveButton() ? "click-off" : ""}`} onClick={() => {
                        if (this.state.editClickOff) {
                            // this.props.moveNext(1);
                            this.props.history.push({ pathname: `/communication` })
                        } else {
                            let tempDefaultError = {
                                errBodyMsg: null,
                                errSendTestMailMsg: null,
                                errEnableUnSubMsg: null,
                                errUnSubMsg: null,
                                errImportContent: null,
                                errTemplateContent: null
                            }
                            let tempSplitError = [
                                {
                                    errBodyMsg: null,
                                    errSendTestMailMsg: null,
                                    errSubjectLineMsg: null,
                                    errScheduleDateMsg: null,
                                    errEnableUnSubMsg: null,
                                    errUnSubMsg: null,
                                    errImportContent: null,
                                    errEmailContentMsg: null,
                                },
                                {
                                    errBodyMsg: null,
                                    errSendTestMailMsg: null,
                                    errSubjectLineMsg: null,
                                    errScheduleDateMsg: null,
                                    errEnableUnSubMsg: null,
                                    errUnSubMsg: null,
                                    errImportContent: null,
                                    errEmailContentMsg: null,
                                },
                            ]
                            this.setState({ defaultContentError: tempDefaultError, splitContentError: tempSplitError, errScheduleDateMsg: null })
                            let enable = this.validation('draft');
                            if (enable === undefined || enable) {
                                if (this.props.emailData.content[0].contentType === "Z" && this.props.emailData.content[0].edmDimension === 0) {
                                    let element = document.getElementById("edmFrame")
                                    let edmHeight = element.contentWindow.document.body.scrollHeight
                                    let edmWidth = element.contentWindow.document.body.scrollWidth
                                    let edmDimension = edmHeight * edmWidth
                                    this.props.emailData.content[0].edmDimension = edmDimension
                                }
                                let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
                                this.props.emailData.senderEmail = this.state.senderEmailAddress + "@" + this.state.activeDomain.domainName;
                                if (this.state.isReplyMail) {
                                    this.props.emailData.replyEmail = this.state.alternateEmailAddress + "@" + this.state.activeReplyDomain.domainName;
                                } else {
                                    this.props.emailData.replyEmail = ""
                                }
                                let params = createEmailCampaign(
                                    "S",
                                    this.props.campaignId,
                                    this.props.emailData,
                                    databaseName,
                                    "",
                                    false
                                );
                                let temp = [...params.content];
                                temp.forEach((item, index) => {
                                    item.localBlastDateTime = "";
                                })
                                params.content = temp;
                                connectServer({
                                    path: this.state.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                                    params,
                                    loading: this.context.globalStateData.setIsLoadingData,
                                    sessionOut: this.context.globalStateData.setSessionOutData,
                                    context: this.context,
                                    ok: (res) => {
                                        if (res.status) {
                                            this.props.history.push({ pathname: `/communication` })
                                        }
                                    },
                                    fail: (err) => {
                                    },
                                });
                            }
                        }
                    }}>Save as draft</RSSecondaryBtn>
                    <RSPrimaryBtn className={this.isEnableSaveButton() ? "" : "click-off"}
                        onClick={() => {
                            if (this.props.emailData.content[0].contentType === "R") {
                                const edmHtml = this.props.emailData.content[0].body;
                                this.getVerificationLinks(edmHtml);
                            }

                            if (this.state.editClickOff) {
                                this.props.moveNext(1);
                            } else {
                                if (this.state.isRequestForApproval) {
                                    let approvarList = [...this.state.approvalList].map((item, index) => {
                                        return {
                                            approvarId: item.approverEmail?.userid || "",
                                            approvarName: item.approverEmail.email,
                                            flag: false,
                                            ismandatory: item.ismandatory,
                                            priority: item.priority,
                                            ApprovalSentcount: item.ApprovalSentcount,
                                        };
                                    });
                                    let approval_object = {
                                        IsFollowHierarchy: this.state.approvalSettings.isFollowHierarchyEnabled,
                                        matchType: this.state.approvalSettings.approvalMatchType,
                                        matchCount: this.state.approvalSettings.approvalMatchCount,
                                        approvarList: approvarList,
                                    };
                                    this.props.emailData.requestForApproval = approval_object;
                                    this.props.emailData.isRequestForApprovalScheduled = (this.props.emailData.channelstatusId === 7 && this.state.isEditRequestForApproval) ? true : false;
                                }
                                let enable = false;
                                enable = this.validation();
                                if (enable) {
                                    if (this.props.emailData.content[0].contentType === "Z" && this.props.emailData.content[0].edmDimension === 0) {
                                        let element = document.getElementById("edmFrame")
                                        let edmHeight = element.contentWindow.document.body.scrollHeight
                                        let edmWidth = element.contentWindow.document.body.scrollWidth
                                        let edmDimension = edmHeight * edmWidth
                                        this.props.emailData.content[0].edmDimension = edmDimension
                                    }
                                    let databaseName = getUserData()?.clientDetailsLocalization?.instanceName
                                    this.props.emailData.senderEmail = this.state.senderEmailAddress + "@" + this.state.activeDomain.domainName;
                                    if (this.state.isReplyMail) {
                                        this.props.emailData.replyEmail = this.state.alternateEmailAddress + "@" + this.state.activeReplyDomain.domainName;
                                    } else {
                                        this.props.emailData.replyEmail = ""
                                    }
                                    let params = createEmailCampaign(
                                        "S",
                                        this.props.campaignId,
                                        this.props.emailData,
                                        databaseName,
                                        "",
                                        false
                                    );
                                    connectServer({
                                        path: this.state.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                                        params,
                                        loading: this.context.globalStateData.setIsLoadingData,
                                        sessionOut: this.context.globalStateData.setSessionOutData,
                                        context: this.context,
                                        ok: (res) => {
                                            if (res.status) {
                                                this.props.moveNext(1);
                                            }
                                        },
                                        fail: (err) => {
                                        },
                                    });
                                }
                            }
                        }}
                    >
                        Next
                    </RSPrimaryBtn>
                </div>
                {this.state.isOpenSplitResetModal ? (
                    <RSConfirmAlert
                        title="Are you sure you want to reset it?"
                        secondaryButtonText="Cancel"
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            if (value) {
                                let temp = [...this.props.emailData.content];
                                temp[0].edmChannelId = 0;
                                temp[0].subjectLine = "";
                                temp[0].contentType = "";
                                temp[0].splitType = "";
                                temp[0].statusId = 0;
                                temp[0].body = "";
                                temp[0].textContent = "";
                                temp[0].preHeaderMessage = "";
                                temp[0].spamScore = 3.0;
                                temp[0].isFooterEnabled = false;
                                temp[0].footerId = 0;
                                temp[0].unsubscribeSettingId = 0;
                                temp[0].isUnsubscribeEnabled = false;
                                temp[0].contentUrl = null;
                                temp[0].htmlFilePath = null;
                                temp[0].previewId = 0;
                                temp[0].zipFilePath = null;
                                temp[0].isViewinBrowserChecked = true;
                                temp[0].templateId = 0;
                                temp[0].dynamiclistId = 0;
                                temp[0].timezoneId = this.state.timeZone.timezoneId;
                                temp[0].edmFileWeight = 0;
                                temp[0].edmDimension = 0;
                                this.props.emailData.content = [temp[0]];
                                this.props.emailData.isSplitAB = false;
                                let tempDefaultError = { ...this.state.defaultContentError };
                                tempDefaultError.errBodyMsg = null;
                                tempDefaultError.errSendTestMailMsg = null;
                                tempDefaultError.errEnableUnSubMsg = null;
                                tempDefaultError.errUnSubMsg = null;
                                tempDefaultError.errImportContent = null;
                                tempDefaultError.errTemplateContent = null;
                                let tempSplitError = [];
                                tempSplitError.push(this.createSplitError);
                                tempSplitError.push(this.createSplitError);
                                this.setState({
                                    errScheduleDateMsg: null,
                                    errSendMailMsg: null,
                                    errSubjectLineMsg: null,
                                    defaultContentError: tempDefaultError,
                                    splitContentError: tempSplitError,
                                    errEmailContentMsg: null,
                                    isSplit: !this.state.isSplit
                                })
                            }
                            this.setState({
                                isOpenSplitResetModal: !this.state.isOpenSplitResetModal,
                            });
                        }}
                    />
                ) : null}
                {this.state.clicked === 1 && (
                    <RSEmojiPicker
                        anchor={this.emojianochor}
                        show={this.state.isShow}
                        onOpen={this.onOpen}
                        onFocus={this.onFocus}
                        ref={this.contentRef}
                        onBlur={this.onBlur}
                        emojiClose={() => {
                            this.setState({
                                clicked: 0,
                                isShow: false
                            })
                        }}
                        tabindex={0}
                        onEmojiClick={(value) => {
                            let obj = this.props.emailData.content[0];
                            let val = getContent(obj.subjectLine, value, obj.subTitlePosition);
                            if (val.length > 500) {
                                this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                            } else {
                                this.props.emailData.content[0].subjectLine = val;
                                this.props.emailData.content[0].subTitlePosition = obj.subTitlePosition + value.length;
                                this.setState({ subjectLine: val, errSubjectLineMsg: null });
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}
export default withRouter(EmailCreation);
