import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap"
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/custom/world.geo.json";
import NewCharts from 'Components/Charts/NewCharts';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import OverViewMap from '../Components/OverViewMap';
import authContext from 'Helper/StateHandler/auth-context';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { ColumnChartSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { GET_CAMPAIGN_CHANNEL_DETAILS, NO_MEDIA_IMAGE } from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler';
import markerImg from 'Assets/Images/maker.png'
import { dateTimeFormatWithMomentForReports, getServerConfig, numberWithCommas } from 'Helper/Utils/Utils';
import EmailReachChart from './components/EmailReachChart';
import EmailEngagementChart from './components/EmailEngagementChart';
import { ch_green } from 'Components/Charts/ChartColors';
import EmailPieChart from './components/EmailPieChart';
import EmailDevicePieChart from './components/EmailDevicePieChart';
import EmailCommunicationStatus from './components/EmailCommunicationStatus';
import EmailTopLinks from './components/EmailTopClicks';
import ReportsAlertPopup from '../Components/ReportsAlertPopup';
import { withRouter } from 'react-router-dom';
import reachImg from 'Assets/Images/detail-analytics-reach.svg'
import engagementImg from 'Assets/Images/detail-analytics-engagement.svg'
import conversionImg from 'Assets/Images/detail-analytics-conversion.svg'
import EmailConversionChart from './components/EmailConversionChart';

let markerIcon = markerImg;

require("highcharts/modules/map")(Highcharts);
highchartsMap(Highcharts); // Initialize the map module

const initialDataState = {
    sort: [
        {
            field: "name",
            dir: "asc"
        }
    ],
    take: 5,
    skip: 0
}

class Email extends Component {

    static contextType = authContext

    constructor(props) {
        super(props)
        // this.componentRef = React.createRef()
        this.state = {
            dataState: initialDataState,
            isShowAlert: false,
            rowKey: "",
            data: null,
            skip: 0, take: 10,
            selectedDropdownValue: "Split A",
            dropdownList: [
                "Split A",
                "Split B",
                "Actual communication"
            ],
            isEmailSplitAb: this.props.state.isEmailSplitAb,
            expandEnable: false,
            imagePath: getServerConfig().slice(0, -1),
            // communicationWidth: 760
        }
    }



    componentDidMount = () => {
        this.getEmailDatafromServer()
    }

    getEmailDatafromServer = () => {

        const { campaignId } = this.props.state
        const params = {
            "campaignId": campaignId,
            "channelId": 1,
            "channelFriendlyName": this.props.channelFriendlyName
        }

        connectServer({
            path: GET_CAMPAIGN_CHANNEL_DETAILS,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let isShow = res?.data.emailIDetail?.keyMetrics?.totalSent > 0 ? false : true;
                    if (isShow) {
                        this.setState({ isShowAlert: isShow })
                    } else {
                        this.setState({ data: res?.data?.emailIDetail, rowKey: res.data?.rowKey, isShowAlert: isShow })
                    }
                }
            },
            fail: err => { }
        })

    }

    render() {
        return <>
            {this.state.isShowAlert &&
                <ReportsAlertPopup
                    isOpen={this.state.isShowAlert}
                    onChangeIsOpen={(value) => {
                        if (value) {
                            this.props.history.push("/analytics");
                        }
                        this.setState({ isShowAlert: false })
                    }}></ReportsAlertPopup>}
            {this.state.isEmailSplitAb &&
                <Row>
                    <Col>
                        <div className="tabs-sublevel bgBlue">
                            <div className="tabs-sub-left">
                                <BootstrapDropdown
                                    className="marginR10 fs18-btn"
                                    data={this.state.dropdownList}
                                    defaultItem={this.state.selectedDropdownValue}
                                    customAlignRight={true}
                                    onSelect={item => this.setState({ selectedDropdownValue: item })}
                                />
                            </div>
                            <div className="tabs-sub-right">
                                <RSPDateRangePicker class="" />
                            </div>
                        </div>
                    </Col>
                </Row>}

            {/* Email Dashboard */}
            <div className='d-flex align-items-center'>
                <h3 className="marginB15 mt15">Overview</h3>
                <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(this.state.data?.latestDate)}</span>)</span>
                <div className='d-flex align-items-center'>
                    <RSPPophover
                        position='right'
                        imgClass='campaign-pop-eye-img'
                        image={this.state?.data?.previewImage !== "" ? `data:image/jpeg;base64,${this.state?.data?.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                    >
                        <div>
                            <RSPTooltip position="top" text="Preview">
                                <i className='icon-sd-eye-medium icons-md blue ml5 cp posr top3'></i>
                            </RSPTooltip>
                        </div>
                    </RSPPophover>
                </div>
            </div>

            <Row>
                {this.state.data &&
                    getOverviewCardData(this.state.data)?.map((item, index) => {
                        return (
                            <OverViewMap
                                key={index}
                                campaignType={item?.campaignType}
                                campaignCount={item?.campaignCount}
                                data={item?.data}
                                icon={item?.icon}
                                bgColor={item?.bgColor}
                                percentage={item?.percentage}
                                infoMsg={item?.data?.info}
                                displayLabel={item?.displayLabel}
                                isLowPerformance={item?.isLowPerformance}
                            />
                        )
                    })
                }
            </Row>

            <h3 className="marginB15">Communication performance</h3>
            <Row
            // ref={this.componentRef}
            >
                {/* Reach */}
                <Col md={8}>
                    <EmailReachChart
                        data={this.state.data}
                    // expandWidth={this.state.communicationWidth} 
                    />
                </Col>

                {/* Key metrics */}
                <Col md={4}>
                    <div className="portlet-container keymetrics-portlet keymetrics-md">
                        <h4>Key metrics</h4>
                        <ul className="keymetrics-list key-border">
                            <li>
                                <h3>{numberWithCommas(this.state.data?.keyMetrics?.totalSent) || 0}</h3>
                                <small>Total sent</small>
                            </li>
                            <li>
                                <h3>{numberWithCommas(this.state.data?.keyMetrics?.delivered) || 0}</h3>
                                <small>Delivered</small>
                            </li>
                        </ul>
                        <p className="mt10 mb5 text-center">User engagement</p>
                        <ul className="keymetrics-list keymetrics-theme">
                            <li className="bgBlue-lighter">
                                <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.forwarded) || 0}<small></small></h3>
                                <small>Forwarded</small>
                            </li>
                            <li className="bgBlue-light">
                                <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.totalOpened) || 0}<small></small></h3>
                                <small>Total opened</small>
                            </li>
                            <li className="bgBlue-medium">
                                <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.totalClicked) || 0}<small></small></h3>
                                <small>Total clicked</small>
                            </li>
                        </ul>
                        <ul className="keymetrics-list key-border pt10">
                            <li>
                                <small>Conversion rate</small>
                                <h3 className="pb0 mt10">{this.state.data?.keyMetrics?.goalConversionRate || 0}<small>%</small></h3>
                            </li>
                            <li>
                                <small>Channel cost</small>
                                <h3 className="pb0 mt10">${this.state.data?.keyMetrics?.channelCost || 0}</h3>
                            </li>
                        </ul>
                        <p className="mt10 mb5 text-center">Undelivered email status</p>
                        {this.state.data && this.state.data?.keyMetrics?.negativeFeedbacks?.map((item, index) => {
                            return (
                                <ul key={index} className="keymetrics-list keymetrics-theme">
                                    <li className="bgMaroon-light">
                                        <h3 className="white p0">{item?.bounced}<small></small></h3>
                                        <small>Bounced</small>
                                    </li>
                                    <li className="bgMaroon-medium">
                                        <h3 className="white p0">{item?.spammed}<small></small></h3>
                                        <small>Spammed</small>
                                    </li>
                                    <li className="bgMaroon">
                                        <h3 className="white p0">{item?.unsubscribed}<small></small></h3>
                                        <small>Unsubscribed</small>
                                    </li>
                                </ul>
                            )
                        })}
                    </div>
                </Col>
            </Row>

            <Row>
                {/* Browser */}
                <Col md={6}>
                    <EmailEngagementChart data={this.state.data} />
                </Col>

                {/* Conversion */}
                <Col md={6}>
                    <EmailConversionChart data={this.state.data} />
                </Col>
            </Row>

            <h3 className="marginB15">User engagement</h3>
            <Row>
                {/* Device */}
                {/* <Col md={6}>
                        <div className="portlet-container portlet-md portlet-footer-label">
                            <div className="portlet-header">
                                <h4>Geography</h4>
                            </div>
                            <div className="portlet-body">
                                <div className="portlet-map">
                                    <img src={location} alt={location} />
                                    <HighchartsReact
                                        constructorType={"mapChart"}
                                        highcharts={Highcharts}
                                        options={worldMapChartOption}
                                    />
                                </div>
                            </div>
                            <div className="portlet-two-label">
                                <ul>
                                    <li><span>32</span><small>%</small></li>
                                    <li>of engagement is from users in North America</li>
                                </ul>
                            </div>
                        </div>
                    </Col> */}

                {/* Email opens by | Client / Device */}
                <Col md={6}>
                    <EmailDevicePieChart data={this.state.data} />
                </Col>

                <Col md={6}>
                    <EmailPieChart data={this.state.data} />
                </Col>

            </Row>

            <Row className='mb20'>
                <Col>
                    <EmailTopLinks campaignId={this.props.state.campaignId} campaignGuid={this.props.state.campaignGuid} blastGuid={this.state?.data?.blastguid} rowKey={this.state.rowKey} />
                </Col>
            </Row>

            <Row className="marginT30">
                <Col>
                    <EmailCommunicationStatus campaignId={this.props.state.campaignId} latestDate={this.state.data?.latestDate} rowKey={this.state.rowKey} />
                </Col>
            </Row>
        </>
    }
}

export default withRouter(Email);

// Overall datas
const getOverviewCardData = data => {

    const overallDatas = [
        {
            "campaignType": "Reach",
            "campaignCount": data?.reach?.count || 0,
            "data": [
                { "text": "Unique opens", "percent": data?.reach?.uniqueOpens || 0, isCount: false },
                { "text": "Forwards", "percent": data?.reach?.forwards || 0, isCount: true }
            ],
            // "icon": "icon-campaign-target-large icons-lg",
            "icon": reachImg,
            "bgColor": "bgBlue-light",
            "percentage": data?.reach?.previousWeekComparison || "0",
            "displayLabel": (!!data?.reach?.prevDisplayLabel && data?.reach?.prevDisplayLabel !== "") ? data?.reach?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.reach?.isLowReachPerformance
        },
        {
            "campaignType": "Engagement",
            "campaignCount": data?.engagement?.count || 0,
            "data": [
                { "text": "Unique clicks", "percent": data?.engagement?.uniqueClicks || 0, isCount: false },
                { "text": "Fwd mail clicks", "percent": data?.engagement?.fwdMailClicks || 0, isCount: true }
            ],
            "icon": engagementImg,
            // "icon": "icon-engagement-large icons-xl",
            "bgColor": "bgOrange-medium",
            "percentage": data?.engagement?.previousWeekComparison || "0",
            "displayLabel": (!!data?.engagement?.prevDisplayLabel && data?.engagement?.prevDisplayLabel !== "") ? data?.engagement?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.engagement?.isLowEngPerformance
        },
        {
            "campaignType": "Conversion",
            "campaignCount": data?.conversion?.count || 0,
            "data": [{
                "text": "Registration", "percent": data?.conversion?.registration || 0, isCount: false
            }],
            "icon": conversionImg,
            // "icon": "icon-conversion-large icons-xl",
            "bgColor": "bgGreen-medium",
            "percentage": data?.conversion?.previousWeekComparison || "0",
            "displayLabel": (!!data?.conversion?.prevDisplayLabel && data?.conversion?.prevDisplayLabel !== "") ? data?.conversion?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.conversion?.isLowConvPerformance
        }
    ]
    return overallDatas

}

// World map
const worldMapChartOption = {

    chart: {
        // type: 'map',
        map: "custom/world",
        proj4,
        // map: "countries/in/in-all"
        height: 290
    },
    title: {
        text: " "
    },
    credits: {
        enabled: false
    },
    mapNavigation: {
        enabled: true
    },
    tooltip: {
        headerFormat: "",
        pointFormat: "<span>{point.name}</span><br>Lat: {point.lat}, Lon: {point.lon}",
        useHTML: true,
        shared: true,
        backgroundColor: '#5f5f5f',
        borderWidth: 0,
        shadow: false,
        style: {
            color: '#fefefe',
            cursor: 'default',
            fontSize: '13px',
            whiteSpace: 'nowrap'
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {

        mappoint: {
            marker: {
                symbol: `url(${markerIcon})`
            },
            dataLabels: {
                align: 'left',
                x: 5,
                verticalAlign: 'middle'
            }
        }
    },
    series: [
        {
            name: "Basemap",
            mapData: mapDataIE,
            borderColor: "#ffffff",
            nullColor: "#749bf0",
            showInLegend: false
        },
        {
            name: "Separators",
            type: "mapline",
            nullColor: "#707070",
            showInLegend: false,
            enableMouseTracking: false
        },
        {
            type: 'mappoint',
            name: 'Cities',

            data: [
                {
                    name: 'London',
                    lat: 51.507222,
                    lon: -0.1275
                }, {
                    name: 'Birmingham',
                    lat: 52.483056,
                    lon: -1.893611
                }, {
                    name: 'Leeds',
                    lat: 53.799722,
                    lon: -1.549167
                }, {
                    name: 'Glasgow',
                    lat: 55.858,
                    lon: -4.259
                }, {
                    name: 'Sheffield',
                    lat: 53.383611,
                    lon: -1.466944
                }, {
                    name: 'Liverpool',
                    lat: 53.4,
                    lon: -3
                }, {
                    name: 'Bristol',
                    lat: 51.45,
                    lon: -2.583333
                }, {
                    name: 'Belfast',
                    lat: 54.597,
                    lon: -5.93
                }, {
                    name: 'Chennai',
                    lat: 13.0827,
                    lon: 80.2707
                }, {
                    name: 'Oslo',
                    lat: 59.9139,
                    lon: 10.7522
                }, {
                    name: 'Stockholm',
                    lat: 59.3293,
                    lon: 18.0686
                },
                // {
                //     name: 'Reykjavík',
                //     lat: 64.1466,
                //     lon: 21.9426,
                //     dataLabels: {
                //         align: 'left',
                //         x: 5,
                //         verticalAlign: 'middle'
                //     }
                // }
            ]
        }
    ]

}