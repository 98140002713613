import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RTabbar } from 'Components/RChart/RTabbar';
import CampaignHeader from '../../../Components/CampaignHeader';
import QRCreation from './MSComponents/QRComponents/QRCreation';
import MobileCreation from './MSComponents/MobileComponents/MobileCreation';
import EmailCreation from './MSComponents/EmailComponents/EmailCreation';
import emailNew from './EmailNew.json';
import mobileSMSNew from './SMSNew.json';
import whatsAppNew from './WhatsappNew.json';
import { prepareEmailData, prepareMobileSMSData, prepareWhatsAppData } from './MSComponents/CamapignWrapper';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_AUDIENCE_LIST, GET_CAMPAIGN_HEADER, GET_NOTIFICATION_DATA } from 'Helper/Constants/endPoint';
import { convertObjectToBase64, getAuthUserId, makeImmutableObject } from "Helper/Utils/Utils";
import authContext from 'Helper/StateHandler/auth-context';

export class Notification extends Component {

  static contextType = authContext

  render() {
    return <NotificationHome history={this.props?.history} context={this.context} />
  }
}
const queryString = window.location.search.split("?");
const urlParams = new URLSearchParams(queryString[1]).get("ChannelDetails");
const resSave = new URLSearchParams(queryString[1]).get("resSave");
const environmentFrom = JSON.parse(urlParams)
class NotificationHome extends Component {

  state = {
    isGetEmailData: false,
    isGetMobileSMSData: false,
    isGetWhatsAppData: false,
    isEmailUpdate: false,
    isMobileSMSUpdate: false,
    isWhatsAppUpdate: false,
    index: 0,
    innerTabIndex: 0,
    mobileSMSData: {
      smsData: makeImmutableObject({ ...mobileSMSNew })
    },
    emailData: {
      data: makeImmutableObject({ ...emailNew })
    },
    whatsAppData: {
      data: makeImmutableObject({ ...whatsAppNew })
    },
    header: {},
    confirmAlert: this.props.history.location.state?.mode === "add" ? true : false,
    mobileApp: "",
    tabData: [],
    channelId: null,
    channelTypes: [],
    campaignId: 0
  };

  componentDidMount = (props) => {
    window.scrollTo(0, 0);
    let channelId = !!this.props.history?.location?.state?.channelId ? this.props.history?.location?.state?.channelId !== 21 ? this.props.history?.location?.state?.channelId : 2 : null;
    this.setState({
      channelId: channelId
      , index: this.props.history?.location?.state?.index || 0
    })
    this.getDataFromServer(channelId)

  }

  getDataFromServer = (channelId) => {
    let queryString = window.location.search.split("?");
    let urlParams = new URLSearchParams(queryString[1]).get("ChannelDetails");
    let environmentFrom = JSON.parse(urlParams)
    let _campaignHeader = {}
    const params = {
      userId: getAuthUserId(),
      campaignId: this.props.history.location.state?.campaignId ? this.props.history.location.state?.campaignId : environmentFrom?.campaignId,
    };
    this.setState({ campaignId: this.props.history.location.state?.campaignId ? this.props.history.location.state?.campaignId : environmentFrom?.campaignId });
    connectServer({
      path: GET_CAMPAIGN_HEADER,
      params: params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (res?.data) {
            _campaignHeader = {
              name: window.atob(res?.data?.campaignName),
              startDate: res?.data?.startDate,
              endDate: res?.data?.endDate,
              goal: res?.data?.primaryTargetCode === "E"
                ? "Engagement"
                : res?.data?.primaryTargetCode === "R"
                  ? "Reach"
                  : "Conversion",
              type: res?.data?.attributeName
            };
            let channelTypes = []
            let channelType = JSON.parse(res.data.channelType);
            channelType.map((item) => {
              if (item.toLowerCase() === 'email') {
                channelTypes.push({ text: 'Email', iconLeft: 'icon-sd-email-large icons-lg', channelId: 1 })
              } else if (item.toLowerCase() === 'sms' || item.toLowerCase() === 'whatsapp') {
                if (channelTypes.filter(item => item.text === "Messaging").length === 0) {
                  channelTypes.push({ text: 'Messaging', iconLeft: 'icon-sd-messaging-large icons-lg', channelId: 2 })
                }
              } else {
                channelTypes.push({ text: 'QR', iconLeft: 'icon-sd-qrcode-large icons-lg', channelId: 3 })
              }
            })
            if (channelTypes.length > 1) {
              channelTypes.sort((a, b) => {
                let firstOne = a.text.toLowerCase(),
                  secondOne = b.text.toLowerCase();
                if (firstOne < secondOne) {
                  return -1;
                }
                if (firstOne > secondOne) {
                  return 1;
                }
                return 0;
              });
            }
            this.setState({
              header: _campaignHeader,
              tabData: channelTypes,
              channelId: channelId,
              channelTypes: channelType,
              campaignId: res?.data?.campaignID
            })
            if (!this.state.isGetEmailData) {
              this.getEmailData();
            }
            if (!this.state.isGetMobileSMSData) {
              this.getMobileSMSData();
            }
            if (!this.state.isGetWhatsAppData) {
              this.getMobileWhatsAppData();
            }
          }
        }
      },
      fail: (err) => { },
    });
  };

  getEmailAudienceList = () => {
    let params = {
      searchText: "",
      campaignID: 0,
      channel: "E"
    }
    connectServer({
      path: GET_AUDIENCE_LIST,
      params,
      context: this.props.context,
      ok: res => {
        if (res.status && res.message !== "No data") {
          localStorage.setItem('lsAudienceList', convertObjectToBase64(res.data))
        }
      },
      fail: err => {
      }
    })
  }

  getMobileAudienceList = () => {
    let params = {
      searchText: "",
      campaignID: 0,
      channel: "S"
    }
    connectServer({
      path: GET_AUDIENCE_LIST,
      params,
      context: this.props.context,
      ok: res => {
        if (res.status && res.message !== "No data") {
          localStorage.setItem('lsAudienceList', convertObjectToBase64(res.data))
        }
      },
      fail: err => {
      }
    })
  }

  getEmailData = () => {
    let params = {
      // "campaignId": this.props.history.location.state?.campaignId ? this.props.history.location.state?.
      "campaignId": this.props.history.location.state?.campaignId ? this.props.history.location.state?.campaignId : environmentFrom?.campaignId,
      "channelId": 1,
      "templateId": this.props.history.location.state?.templateId ? this.props.history.location.state?.templateId : environmentFrom?.templateId,
    }
    connectServer({
      path: GET_NOTIFICATION_DATA,
      params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {

        if (res.status && !!res.data) {
          let tempObject = !!res.data.channelJson && JSON.parse(res.data.channelJson)
          if (!!tempObject && tempObject !== false) {
            let emailData = prepareEmailData(tempObject);
            let tempEmailData = {
              data: makeImmutableObject({ ...emailData })
            }
            this.setState({ emailData: tempEmailData, isEmailUpdate: true, isGetEmailData: true })
          }
        }
      },
      fail: err => {
      }
    })
  }

  RenderComponent = channelId => {

    const some = {
      1: <EmailCreation
        emailData={this.state.emailData.data}
        campaignHeader={this.state.header}
        campaignType={this.props.history.location?.state?.CampaignType || "S"}  // need to chage dynamically
        campaignId={this.state?.campaignId}
        isUpdate={this.state.isEmailUpdate}
        mode={this.props.history.location.state?.mode}
        status={this.props.history.location?.state?.status || true}   // need to change dynamically
        moveNext={(value) => {
          if (this.state.tabData.length > 1 && this.state.index !== value) {
            this.setState({ index: value, channelId: null });
          } else if (this.state?.tabData?.filter((item) => item.text === "QR").length > 0) {
            this.setState({ index: value + 1, channelId: null });
          } else {
            this.props.history.push({
              pathname: "/communication/completion",
              state: {
                mode: this.props.history.location.state?.mode,
                campaignId: this.state?.campaignId,
                campaignHeader: this.state.header,
                channelType: this.state.channelTypes,
                status: this.props.history.location.state?.status
              }
            });
            this.setState({ index: this.state.tabData.length - 1 });
          }
        }} />,
      2:
        <MobileCreation
          mobileSMSData={this.state.mobileSMSData.smsData}
          mobileWhatsAppData={this.state.whatsAppData.data}
          campaignType={this.props.history.location.state?.CampaignType || "S"}
          campaignId={this.state?.campaignId}
          channelId={this.props.history?.location?.state?.channelId}
          isUpdate={this.state.isMobileSMSUpdate}
          mode={this.props.history.location.state?.mode}
          status={this.props.history.location.state?.status}
          channelTypes={this.state.channelTypes}
          campaignHeader={this.state?.header}
          moveNext={(value) => {
            if (this.state?.tabData?.length > 1 && this.state?.index !== value) {
              this.setState({ index: value, channelId: null });
            } else if (this.state?.tabData?.filter((item) => item.text === "QR").length > 0) {
              this.setState({ index: value + 1, channelId: null });
            } else {
              this.props.history.push({
                pathname: "/communication/completion",
                state: {
                  mode: this.props.history.location.state?.mode,
                  campaignId: this.state?.campaignId,
                  campaignHeader: this.state?.header,
                  channelType: this.state.channelTypes,
                  status: this.props.history.location.state?.status
                }
              });
              this.setState({ index: this.state.tabData?.length - 1 });
            }
          }}
        />,
      3:
        <QRCreation
          campaignType={this.props.history.location.state?.CampaignType}
          campaignId={this.state?.campaignId}
          mode={this.props.history.location.state?.mode}
          status={this.props.history.location.state?.status}
          campaignHeader={this.state?.header}
          moveNext={(value) => {
            // if (this.state.tabData.filter((item) => item.text === "Email" || item.text === "Mobile").length === 0) {
            //   this.props.history.push("/communication");
            // } else {
            this.props.history.push({
              pathname: "/communication/completion",
              state: {
                mode: this.props.history.location.state?.mode,
                campaignId: this.state?.campaignId,
                campaignHeader: this.state?.header,
                channelType: this.state.channelTypes,
                status: this.props.history.location.state?.status
              }
            });
            // }
          }} />
    }
    return some[channelId]
  }

  progressSteps = [
    {
      status: 'complete',
      steps: '1',
      steps_title: 'Plan'
    },
    {
      status: 'inProcess',
      steps: '2',
      steps_title: 'Create'
    },
    {
      status: '',
      steps: '3',
      steps_title: 'Analyze & execute'
    }
  ]

  tabs = [
    { text: 'Email', iconLeft: 'icon-sd-email-large icons-lg' },
    { text: 'Messaging', iconLeft: 'icon-sd-messaging-large icons-lg' },
    { text: 'QR', iconLeft: 'icon-sd-qrcode-xmedium icons-lg' }
  ];

  getMobileSMSData = () => {
    let params = {
      "campaignId": this.props.history.location.state?.campaignId,
      "channelId": 2
    }
    connectServer({
      path: GET_NOTIFICATION_DATA,
      params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status && !!res.data) {
          let tempObject = !!res.data.channelJson && JSON.parse(res.data?.channelJson)
          if (!!tempObject && tempObject !== false) {
            let smsData = prepareMobileSMSData(tempObject);
            let tempMobileSMSData = {
              smsData: makeImmutableObject({ ...smsData })
            }
            this.setState({ mobileSMSData: tempMobileSMSData, isMobileSMSUpdate: true, isGetMobileSMSData: true })
          }
        }
      },
      fail: err => {

      }
    })
  }

  getMobileWhatsAppData = () => {
    let params = {
      "campaignId": this.props.history.location.state?.campaignId,
      "channelId": 21
    }
    connectServer({
      path: GET_NOTIFICATION_DATA,
      params,
      loading: this.props.context.globalStateData.setIsLoadingData,
      sessionOut: this.props.context.globalStateData.setSessionOutData,
      context: this.props.context,
      ok: res => {
        if (res.status && !!res.data) {
          let tempObject = !!res.data.channelJson && JSON.parse(res.data?.channelJson)
          if (!!tempObject && tempObject !== false) {
            let whatsAppData = prepareWhatsAppData(tempObject);
            let tempWhatsAppData = {
              data: makeImmutableObject({ ...whatsAppData })
            }
            this.setState({ whatsAppData: tempWhatsAppData, isWhatsAppUpdate: true, isGetWhatsAppData: true })
          }
        }
      },
      fail: err => {

      }
    })
  }

  render() {
    return (
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <h1>Communication creation</h1>
          <div className="portlet-box-theme bgWhite padding0">
            <div className='theme-space-mx'>
              <CampaignHeader progressSteps={this.progressSteps} header={this.state.header} type="editplan" editTooltipText="Edit plan" buttonClicked={(value) => {
                if (value === 2) {
                } else if (value === 1) {
                  this.props.history.push({
                    pathname: `/communication/planning`,
                    state: {
                      mode: this.props.history.location.state?.mode,
                      deliveryType: "S",
                      campaignName: this.state.header?.name,
                      status: this.props.history.location.state?.status,
                      campaignId: this.props.history.location.state?.campaignId ? this.props.history.location.state?.campaignId : environmentFrom?.campaignId
                    }
                  });
                }
              }} />
            </div>

            <div className="d-flex marginT20">
              <div className="tabs-vertical">
                <RTabbar
                  defaultSelectedItem={this.state.index}
                  defaultClass="tabDefault"
                  dynamicTab="vertical-tabs"
                  activeClass="tabDefault active"
                  defaultSelectedObjectKey="channelId"
                  defaultSelectedObjectValue={this.state?.channelId}
                  tabData={this.state.tabData}
                  callBack={(item, index) => { this.setState({ index: index, channelId: null }) }}
                />
              </div>
              <div className="tab-vertical-content paddingT0">
                {this.state?.channelId
                  ? this.RenderComponent(this.state?.channelId)
                  : this.state?.tabData[this.state.index]?.channelId
                    ? this.RenderComponent(this.state?.tabData[this.state.index]?.channelId)
                    : null}
              </div>
            </div>
          </div>
        </Container>
      </LayoutPageWrapper>

    )
  }

}

// export default withRouter(Notification);
