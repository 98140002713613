import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { GET_MAKE_CHANGES } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { dateFormat, getAuthUserId } from 'Helper/Utils/Utils';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
const dataState = {
    skip: 0,
    take: 5,
    sort: [
        {
            field: 'orderDate',
            filter: false,
            dir: 'desc',
        },
    ],
};
class MakeChangesModal extends Component {
    static contextType = authContext;
    state = {
        isShow: this.props.isOpen,
        totalListCount: 0,
        result: null,
        dataState: dataState,
        fetchDataParams: {},
        listError: false,
        changesList: []
    }
    componentDidMount = () => {
        let params = this.getParams(this.props.channelDetails);
        this.setState({ fetchDataParams: params });
        this.getMakeChanges(params);
        
        // document.querySelectorAll('[role="dialog"]')[0].removeAttribute("tabIndex")
        if(this.state.isShow === true) {
            document.querySelectorAll('[role="dialog"]')[0].removeAttribute("tabIndex")
        }
    }
    getParams = (channelDetails) => {
        let params = {
            "pagination": {
                "PageSize": 5,
                "TotalCount": 0,
                "PageCount": 1
            },
            "createdBy": getAuthUserId(),
            "campaignId": channelDetails.campaignId,
            "channelId": channelDetails.channelId
        }
        return params;
    }
    getMakeChanges = (params) => {
        this.setState({ listError: false, result: null })
        connectServer({
            path: GET_MAKE_CHANGES,
            params: params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && (res?.data?.makeChangeList && res?.data?.makeChangeList?.length > 0)) {
                    let newTemp = [...res?.data?.makeChangeList]
                    const tempDataState = {
                        skip: 0,
                        take: this.state.dataState?.take
                    };
                    let some = process(newTemp, tempDataState)
                    this.setState({
                        totalListCount: parseInt(res?.totalRows, 10),
                        result: Array.prototype.reverse.call(some),
                        changesList: res?.data?.makeChangeList
                    })
                } else {
                    this.setState({
                        listError: true,
                        result: null,
                        message: "No data"
                    })
                }
            },
            fail: err => { this.setState({ listError: true }) }
        })
    }
    dataStateChange = (event) => {
        const { skip, take } = event.dataState;
        const currentPage = Math.floor(skip / take + 1)
        this.setState({
            result: process(this.state.changesList, event.dataState),
            dataState: event.dataState
        });
        let tempData = { ...this.state.fetchDataParams };
        tempData.pagination.PageSize = take;
        tempData.pagination.PageCount = currentPage
        this.setState({ fetchDataParams: tempData });
        this.getMakeChanges(tempData)
    };

    render() {
        return (
            <Modal
                show={this.state.isShow}
                backdrop="static"
                className='modal-top-fixed'
                keyboard={false}
                centered
                tabindex={0}
                autoFocus={false}
                size="xl"
                onHide={() => {
                    this.setState({ isShow: false })
                    this.props.onChangeIsOpen(false);
                }}
            >
                <Modal.Header closeButton>
                    <h2>Comments on request for approval/gate approval</h2>
                </Modal.Header>
                <Modal.Body>
                    {this.state.result
                        ? (<Grid
                            data={this.state.result}
                            {...this.state.dataState}
                            onDataStateChange={this.dataStateChange}
                            sortable={true}
                            className="grid-pagenation-del grid-replaceable mb30"
                            scrollable={"none"}
                            total={this.state.totalListCount}
                            pageable={
                                this.state.result?.data.length > 5 ? {
                                    //type: 'input',
                                    pageSizes: true,
                                    previousNext: true
                                } : false
                            }
                        >
                            <GridColumn
                                field='approvalEmailSentTo'
                                title="Approval sent to"
                                columnMenu={ColumnMenu}
                                filterable={false}
                            />
                            <GridColumn
                                field='createdDate'
                                title="Date"
                                cell={DateCell}
                                columnMenu={ColumnMenu}
                                filterable={false}
                            />
                            <GridColumn
                                field='status'
                                title="Status"
                                columnMenu={ColumnMenu}
                                filterable={false}
                            />
                            <GridColumn
                                field='makeChangesComments'
                                title="Comments"
                                columnMenu={ColumnMenu}
                                filterable={false}
                            />
                        </Grid>)
                        : (<SkeletonTable count={5} isError={this.state.listError} />)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container d-flex justify-content-end'>
                        <RSSecondaryBtn onClick={() => {
                            this.setState({ isShow: false })
                            this.props.onChangeIsOpen(false)
                        }}>
                            Cancel
                        </RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            this.setState({ isShow: false })
                            this.props.onChangeIsOpen(false)
                        }}>Ok</RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MakeChangesModal;
const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter filterable={false} {...props} expanded={true} />
    </div>;
};
const DateCell = (props) => {
    return (
        <td>
            {dateFormat(props.dataItem[props.field])}
        </td>
    )
}