import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import RESEditor from 'Components/Editor/RESEditor';
import { RSPrimaryBtn } from 'Components/RSButtons';
import { RSDropdownList, RSPhoneInput } from 'Components/RSDropdowns';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { CAMPAIGN_TEXT, SCHEDULE_DATE } from 'Helper/Constants/validationMessage';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { CustomPopup } from 'Components/DateRangePicker/ScheduleCustomPopup';
import { withRouter } from 'react-router-dom';
import { addMinutes, getContent, getLsMasterDataDropdown, getUserData, isEmpty } from 'Helper/Utils/Utils';
import { GET_SHORTEN_URL } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';
import { RSPTooltip } from 'Components/Tooltip';
const approverList = [
    "William(swil****@vi**onb**k.com",
    "Peter(rpe***r@v**ionb***.c**m)",
    "Catherine(cath***@v**onb****.com"];
const MobileSMSSplitContent = props => {
    const context = useContext(authContext)
    const [campaignText, setCampaignText] = useState(props.splitSMSData.body);
    const [errCampaignText, setErrCampaignTextMsg] = useState(props.splitContentError.errBodyMsg);
    const [isEditTimeZone, setIsEditTimeZone] = useState(false);
    const [timeZone, setTimeZone] = useState("");
    const [timeZoneData, setTimeZoneData] = useState([]);
    const [isDayLightSavings, setIsDayLightSavings] = useState(false);
    const [isSendRecommendation, setIsSendRecommendation] = useState(false);
    const [schedule, setSchedule] = useState(props.splitSMSData.localBlastDateTime);
    const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(props.splitContentError.errScheduleDateMsg);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [smartURL, setSmartURL] = useState(props.splitSMSData.smartURL);
    const [errSmartURL, setErrSmartURL] = useState(null);

    useEffect(() => {
        getTimeZoneList();
        setErrCampaignTextMsg(props.splitContentError.errBodyMsg);
        setErrScheduleDateMsg(props.splitContentError.errScheduleDateMsg);
        if (props.history.location && props.history.location.state) {
            const campaignHeader = props.history.location.state.campaignHeader
            setStartDate(new Date(campaignHeader.startDate))
            setEndDate(new Date(campaignHeader.endDate))
        }
    }, [])
    const getTimeZoneList = () => {
        const data = getLsMasterDataDropdown();
        const loggedDetails = getUserData();
        let filterTimezone = null;
        if (props.mode === "add") {
            filterTimezone = data.timeZone.filter((item) => item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId)[0];
        } else {
            filterTimezone = data.timeZone.filter((item) => item.timezoneId === props.splitSMSData.timezoneId)[0];
        }
        setTimeZoneData(data.timeZone);
        setTimeZone(filterTimezone)
    }

    return (
        <div>
            <Row className="custom-column">
                <Col md={3}>
                    <label className="label-row">Communication text</label>
                </Col>
                <Col md={6}>
                    <div className="position-relative">
                        {errCampaignText && (
                            <div className="validation-message yaxis-7">
                                {errCampaignText}
                            </div>
                        )}
                        <RESEditor
                            personalizeTapData={props.personalizeTapData}
                            mobilepush={false}
                            isCampaignText={true}
                            isWhatsappCampaignText={false}
                            bodyContent={props.splitSMSData.body}
                            callBack={(key, item) => {
                                if (key === "bodytext") {
                                    let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                                    // props.splitSMSData.body = item.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                                    props.splitSMSData.body = item;
                                    props.splitContentError.errBodyMsg = errMsg;
                                    setErrCampaignTextMsg(errMsg);
                                    setCampaignText(item);
                                } else if (key === "onBlur") {
                                    props.splitSMSData.bodyPosition = item.target.selectionStart;
                                }
                            }} />
                    </div>
                </Col>
            </Row>
            <Row className="custom-column marginB30">
                <Col md={3}>
                    <label className="label-row">Smartlink</label>
                </Col>
                <Col md={6}>
                    <RSInput2
                        ph="Enter URL"
                        className=""
                        val={smartURL}
                        required={true}
                        cb={(text) => {
                            let errMsg = text !== "" ? null : "Enter URL";
                            props.splitSMSData.smartURL = text;
                            setSmartURL(text);
                            setErrSmartURL(errMsg);
                        }}
                        errorMessage={errSmartURL}
                    />
                </Col>
                <Col md={3}>
                    <RSPrimaryBtn onClick={() => {
                        if (isEmpty(smartURL)) {
                            setErrSmartURL("Enter URL");
                            return
                        }
                        let params = {
                            "campaignId": props.history.location.state.campaignId,
                            "goalNo": 1,
                            "smartCode": "",
                            "domainUrl": "https://lcoa.mkstr.io/",
                            "smartAdaptive": "",
                            "smartUrl": smartURL
                        }
                        connectServer({
                            path: GET_SHORTEN_URL,
                            params,
                            loading: context.globalStateData.setIsLoadingData,
                            sessionOut: context.globalStateData.setSessionOutData,
                            context: context,
                            ok: res => {
                                if (res.status) {
                                    let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.splitSMSData.bodyPosition);
                                    props.splitSMSData.body = val;
                                    setCampaignText(val);
                                }
                            },
                            fail: err => {
                            }
                        })
                    }}>Shorten</RSPrimaryBtn>
                </Col>
            </Row>
            <Row className="custom-column schedule-even marginT30 paddingB10">
                <Col md={3}>
                    <label className="label-row">Schedule</label>
                </Col>
                <Col md={6}>
                    <div className="position-relative">
                        {errScheduleDateMsg && (
                            <div className='validation-message'>
                                {errScheduleDateMsg}
                            </div>
                        )}
                        <div className="rsptimer-picker">
                            <DateTimePicker
                                className="required"
                                placeholder='Select schedule date and time'
                                value={schedule !== "" ? new Date(schedule) : ""}
                                // show={true}
                                // min={startDate}
                                min={addMinutes(new Date(), 15)}
                                max={endDate}
                                format={"dd MMM, yyyy HH:mm"}
                                popup={CustomPopup}
                                onChange={(event) => {
                                    setSchedule(event.target.value);
                                    props.splitSMSData.localBlastDateTime = event.target.value;
                                    let errMsg = props.splitSMSData.localBlastDateTime !== "" ? null : SCHEDULE_DATE
                                    props.splitContentError.errScheduleDateMsg = errMsg;
                                    setErrScheduleDateMsg(errMsg)
                                }}
                            />
                        </div>
                    </div>
                    <Row className="marginT5">
                        {/* <Col md="8">
                            <div className="d-flex align-items-center marginB30">
                                <RSCheckbox
                                    className="marginB0"
                                    lbl="Use send time recommendation"
                                    checked={isSendRecommendation}
                                    cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                                />
                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                            </div>
                        </Col> */}
                        {isEditTimeZone === false ?
                            <Col>
                                <div className="float-end">
                                    <label>  {!!timeZone &&
                                        timeZone.gmtOffset}
                                    </label>
                                    <RSPTooltip text="Edit" position="top">
                                        <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                                            setIsEditTimeZone(!isEditTimeZone);
                                        }} />
                                    </RSPTooltip>
                                </div>
                            </Col> : null}
                    </Row>
                </Col>
            </Row>
            {
                isEditTimeZone
                    ? <Row className="custom-column schedule-even pt0">
                        <Col md={3}>
                            <label className="label-row">Select time zone</label>
                        </Col>
                        <Col md={6}>
                            <div className="position-relative">
                                <RSDropdownList
                                    className="marginB0"
                                    data={timeZoneData}
                                    value={timeZone}
                                    defaultItem={{
                                        "timezoneId": 0,
                                        "timezoneName": "-- Select time zone --",
                                    }}
                                    textField="timezoneName"
                                    dataItemKey="timezoneId"
                                    onChange={(e) => {
                                        setTimeZone(e.target.value);
                                        props.splitSMSData.timezoneId = e.target.value.timezoneId;
                                    }} />
                            </div>
                            <Row className="marginT5">
                                <Col md="8">
                                    <div className="d-flex align-items-center">
                                        <RSCheckbox
                                            className="marginT0 click-off"
                                            lbl="Daylight saving"
                                            checked={isDayLightSavings}
                                            cb={(value) => { setIsDayLightSavings(!isDayLightSavings) }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : null
            }
        </div>
    );
}
export default withRouter(MobileSMSSplitContent);