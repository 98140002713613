import * as constants from "Components/Skeleton/constants";
import React, { Component } from "react";
import ContentLoader from "react-content-loader";
// import TemplateCreateModal from "./TemplateCreate";
const th = 33;
const td = 25;

const GridHead = () => (
  <ContentLoader 
    style={{ width: "100%" }} 
    viewBox="0 0 100% 70" 
    height={50} 
    backgroundColor={constants.scolor1}
    foregroundColor={constants.scolor2}
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="33" />
  </ContentLoader>
);



class SkeletonTable extends Component {
  state = {
    rowCount: 8,
  };

  componentDidMount = () => {
    this.setState({
      rowCount: this.props?.count || 8,
    });
  };

  render() {
    return (
      <div className="no-data-container">
        {this.props.isError ? (
          <div
            className="nodata-bar add-func"
            onClick={() => {
              this.props.setTemplateCreateFlage(true);
            }}
          >
            <p>No template to display. Click the</p>
            <i className="icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer px5"></i>
            <p>to create a template</p>
          </div>
        ) : (
         null
        )}

        {Array(this.state.rowCount)
          .fill(0)
          .map((index) => {
            return (
              <>
                <GridHead key={index} /> <br />
              </>
            );
          })}
      </div>
    );
  }
}

export default SkeletonTable;
