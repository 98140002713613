import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { RSPrimaryBtn, } from 'Components/RSButtons'

import { FeaturesWrapper } from './Components/Features.styled'
import { Container, Row, Col } from 'react-bootstrap'
import { RSCheckbox, RSTextInputWithIcon } from 'Components/RSInputs'
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'
import Icon from 'Components/Icon/Icon';
import * as icons from "Constants/GlobalConstant/Glyphicons/Glyphicons-v5.0";
import authContext from 'Helper/StateHandler/auth-context'

import { convertBase64ToObject, getAuthClientId, getAuthUserId, getUserData, numberWithCommas } from 'Helper/Utils/Utils'
import jsonData from './RSPPricing.json'
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo'
import { GET_FEATURES_MASTER, GET_OFFER_COUPON, SAVE_PAYMENT_DETAILS, SAVE_SUBSCRIBE_PLAN } from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler'
import DataProviderModal from './Components/DataProviderModal'
import ResulPayModal from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/ConfirmPayment/ResulPayModal'
import DataProviderTermsModal from './Components/DataProviderTermsModal'
// import queryString from 'query-string';

const FeaturePlan = props => {

    const context = useContext(authContext)
    const history = useHistory()
    const [selectedItem, setSelectedItem] = useState(-1)
    const [message, setMessage] = useState('No data received from IFrame, yet.');
    const [transactionId, settransactionId] = useState(0);
    const [planInvoiceDetails, setPlanInvoiceDetails] = useState(null);
    const [isOpenResulPay, setIsOpenResulPay] = useState(false);
    const [overallJson, setOverallJson] = useState(jsonData);
    const [selectedPeriod, setSelectedPeriod] = useState(null)//useState(jsonData.data.Frequency[3])
    const [selectPackage, setSelectPackage] = useState(-1)
    const [selectAddOnsCheckbox, setSelectAddOnsCheckbox] = useState(null)
    const [selectFreeAddOnsCheckbox, setSelectFreeAddOnsCheckbox] = useState(null)
    const [selectedAdditionalUsers, setSelectedAAdditionalUsers] = useState({ Free: "Select", Essentials: 1, Premier: 1, Ultimate: 1 })
    const [dataError, setDataError] = useState(false)
    const [promoCodeApplied, setPromoCodeApplied] = useState(null)
    const [promoCode, setPromoCode] = useState("")
    const [promoCodeErr, setPromoCodeErr] = useState(null)
    const [promoSuccess, setPromoSuccess] = useState(false)
    const [discountAmount, setDiscountAmount] = useState(0)
    const [stepperValue, setStepperValue] = useState(1)

    const [scroll, setScroll] = useState(false)
    const [scrollEnd, setScrollEnd] = useState(false)
    const [scrollTab, setScrollTab] = useState(false)
    const [scrollTabOpacity, setScrollTabOpacity] = useState(false)
    const [dataProviders, setDataProviders] = useState([]);
    const [selectedDataProviders, setSelectedDataProviders] = useState([]);
    const [isOpenDataProviderModal, setIsOpenDataProviderModal] = useState(false);
    const [isOpenDataProviderTermsModal, setIsOpenDataProviderTermsModal] = useState(false);
    const [isVersiumOpenDataProviderTermsModal, setIsVersiumOpenDataProviderTermsModal] = useState(false);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        // let userData = getUserData()
        // if (userData?.payStatus !== null && userData?.payStatus !== undefined && userData?.payStatus === 'C') {
        //   history.push('/')
        //   return
        // }
        let parsed = new URLSearchParams(window.location.search);
        let parsedUserDetails = parsed.get("userDetails");
        let userDetails = parsedUserDetails ? convertBase64ToObject(parsedUserDetails) : null
        if (userDetails === null) {
            userDetails = { "promocode": "", "isVersium": false, "pId": 0, "cId": history.location.state.clientId, "uId": history.location.state.userId }
        }
        setUserDetails(userDetails);
        if (!!userDetails) {
            getFeatureCost(userDetails)
        }

    }, [])
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 100)
        })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollEnd(window.scrollY > 1200)
        })
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollTabOpacity(window.scrollY > 300)
        })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollTab(window.scrollY > 450)
        })
    }, [])

    React.useEffect(() => {
        if (message.toLowerCase().startsWith('c')) {
            setIsOpenResulPay(false);
        } else if (message.toLowerCase().startsWith('s')) {
            // const payloads = {
            //   clientId: getAuthClientId(),
            //   orderId: orderId,
            //   userId: getAuthUserId(),
            //   clientName: getUserData().clientDetailsLocalization.clientName,
            //   authorizationCode: transactionId,
            //   paymentMode: 1,
            // };
            // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
            // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
        } else if (message.toLowerCase().startsWith('f')) {
            // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
        } else {
            setIsOpenResulPay(false);
        }
    }, [message]);
    React.useEffect(function () {
        var handler = function (ev) {
            if (typeof ev.data !== 'object') return;
            if (!ev.data.type) return;
            if (ev.data.type !== 'button-click') return;
            if (!ev.data.message) return;
            setMessage(ev.data.message);
            if (ev.data.message.toLowerCase().startsWith('c')) {
                setIsOpenResulPay(false);
            } else if (ev.data.message.toLowerCase().startsWith('s')) {
                settransactionId(ev.data.transactionId);
                setIsOpenResulPay(false);
                // const payloads = {
                //   clientId: getAuthClientId(),
                //   orderId: orderId,
                //   userId: getAuthUserId(),
                //   clientName: getUserData().clientDetailsLocalization.clientName,
                //   authorizationCode: transactionId,
                //   paymentMode: 1,
                // };
                const paymentParams = {
                    "status": true, // payment status (response from resul pay)
                    "isTenantSubscription": false, // true if Tenant Subscription payment
                    "isClientSubscription": true,// true if client Subscription payment(first time subscription)
                    "isConsumable": false,// true if consumable payment
                    "isRecipient": false,// true if recipient payment
                    "transactionNo": ev.data.transactionId, // response from resul pay
                    "orderId": ev.data.orderId,//response from resul pay as string
                }
                savePaymentDetails(paymentParams)
                // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
                // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
            } else if (ev.data.message.toLowerCase().startsWith('f')) {
                // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
            } else {
                setIsOpenResulPay(false);
            }
        };
        window.addEventListener('message', handler);
        // Clean up
        return function () {
            return window.removeEventListener('message', handler);
        }; // The "message" param should match with the iframe.js file
    }, []);

    const savePaymentDetails = (params) => {
        connectServer({
            path: SAVE_PAYMENT_DETAILS,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status) {
                    setIsOpenResulPay(false);
                    history.push({ pathname: "thanks", state: { payment: false } });
                }
            },
            fail: err => {
            }
        })
    }
    const getFeatureCost = (userDetail) => {
        let params = {
            "invoiceId": getUserData() ? getUserData()?.invoiceId : 0,
            "databaseName": getUserData() ? getUserData()?.clientDetailsLocalization.instanceName : "",
            "pricingDetailsId": getUserData() ? getUserData()?.pricingDetailsId : 0
        }
        connectServer({
            path: GET_FEATURES_MASTER,
            params: params,
            // sessionOut: context.globalStateData.setSessionOutData,
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res?.status) {
                    setOverallJson(res || jsonData)
                    // setOverallJson(jsonData)
                    // let tempData = { ...context.globalStateData.getKeyContactInfoData }
                    setSelectPackage(userDetail.pId);
                    setSelectedItem(userDetail.pId);
                    // selectDefaultPackage()
                    setSelectedPeriod(res?.data?.frequency[0])
                    setSelectAddOnsCheckbox(res?.data?.free?.addOns)
                    setSelectFreeAddOnsCheckbox(res?.data?.free?.freeAddOns)
                    setDataProviders(res?.data?.dataProviders);
                    setSelectedDataProviders([]);
                    setPromoCode(userDetail?.promocode)
                    if (userDetail?.isVersium) {
                        let tempFreeAddons = [...res?.data[res?.data?.licenseType[userDetail.pId].licensingType.toLowerCase()].freeAddOns]
                        let _newArray = []
                        tempFreeAddons?.map((_item, _index) => {
                            _item.checked = true
                        })
                        setSelectFreeAddOnsCheckbox(tempFreeAddons)
                    }
                } else {
                    setDataError(true)
                }
            },
            fail: error => {
                // setDataError(true)
            }
        })
    }
    const getOfferCost = (licenseTypeId, frequencyId) => {
        const params = {
            couponCode: promoCode,
            licensingTypeId: licenseTypeId,
            frequencyId: frequencyId,
            offerTypeId: 1
        }
        connectServer({
            path: GET_OFFER_COUPON,
            params,
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res.status) {
                    if (!!res.data) {
                        let subTodal = overallJson?.data?.licenseType[selectedItem]?.price + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers);
                        let discountAmount = subTodal * res.data.couponPercentage / 100;
                        setDiscountAmount(res.data.discountAmount);
                        setPromoCodeApplied(true)
                        setPromoCodeErr("Coupon code applied successfully")
                        setPromoSuccess(true)
                        setTimeout(() => {
                            setPromoCodeErr(null)
                            setPromoSuccess(false)
                        }, 2000);
                    }
                } else {
                    setDiscountAmount(0);
                    setPromoCodeApplied(false)
                    setPromoCodeErr("Invalid coupon code")
                    setPromoSuccess(false)
                }
            },
            fail: error => {
                setDiscountAmount(0);
                setPromoCodeErr("Invalid coupon code")
                setPromoSuccess(false)
                setPromoCodeApplied(false)
                setTimeout(() => {
                    setPromoCodeErr(null)
                }, 2000);
            }
        })
    }
    // const selectDefaultPackage = () => {
    //     const parsed = queryString.parse(history?.location?.search);
    //     let _pId = parsed?.pId || 0
    //     if (_pId !== 0) {
    //         let selectPlan = parseInt(_pId) - 1
    //         setSelectPackage(selectPlan)
    //     } else {
    //         setSelectPackage(0)
    //     }
    // }
    const getSubTotal = (arry, selectedAdditionalUsers) => {

        let price = 0


        arry?.map(item => {
            if (item.checked) {
                if (item.licenseFeatureId === 19) {
                    price = price + item.licensePrice * stepperValue || 0
                } else {
                    price = price + item.licensePrice
                }
            }
        })
        return price
    }
    const postPricingDetails = () => {

        if (selectFreeAddOnsCheckbox[0].checked && selectedDataProviders.length === 0) {
            setSelectedDataProviders(dataProviders)
            setIsOpenDataProviderModal(true);
            setIsVersiumOpenDataProviderTermsModal(true);
            return
        }
        let addOns = selectAddOnsCheckbox
            .filter(item => item.checked)
            .map((item, index) => {
                return {
                    addonid: item.addOnId,
                    licenseFeatureID: item.licenseFeatureId,
                    addonValue: item.licenseFeatureId === 19 ? stepperValue : 0,
                    licenseValue: item.licenseFeatureId
                }
            })
        let freeAddOns = selectFreeAddOnsCheckbox
            .filter(item => item.checked)
            .map((item, index) => {
                return {
                    addonid: item.addOnId,
                    licenseFeatureID: item.licenseFeatureId,
                    addonValue: 0,
                    licenseValue: item.licenseFeatureId,
                    dataProviders: selectedDataProviders,
                    licenseGroupId: item.licenseGroupId,
                    licensingTypeId: item.licensingTypeId,
                }
            })

        let params = {}
        const state = history.location.state
        params.isPlanUpgrade = false// false for first time and subscription has ended, true for upgrading te existing plan
        params.isAddOnUpgrade = false
        params.isPlanActive = false
        params.userId = (userDetails && userDetails.uId) || state.userId || getAuthUserId() || 0
        params.clientId = (userDetails && userDetails.cId) || state.clientId || getAuthClientId() || 0
        params.licensingTypeID = overallJson?.data?.licenseType[selectedItem]?.licensingTypeID
        params.frequencyId = selectedPeriod.frequencyId
        params.promoCode = promoCode
        params.AddOns = addOns
        params.freeAddOns = freeAddOns
        params.invoiceId = 0// 0 for first time, else invoice id from login data
        params.isNewAccount = true // true for first time, false if account has been activated


        connectServer({
            path: SAVE_SUBSCRIBE_PLAN,
            params: params,
            // sessionOut: context.globalStateData.setSessionOutData,
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res.status) {
                    if (res?.data.isPaymentCompleted) {
                        history.push({ pathname: "thanks", state: { payment: true } });
                    } else {
                        setPlanInvoiceDetails(res?.data)
                        setIsOpenResulPay(true);
                    }
                    // let totalAmount = (overallJson?.data?.licenseType[selectedItem]?.price + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers) - discountAmount) * selectedPeriod.value
                    // history.push({
                    //     pathname: `payment`,
                    //     state: {
                    //         amount: res?.data?.amountPayable,
                    //         clientId: (userDetails && userDetails.cId) || state.clientId || getAuthClientId() || 0,
                    //         userId: (userDetails && userDetails.uId) || state.userId || getAuthUserId() || 0,
                    //         invoiceId: res?.data?.clientInvoiceId,
                    //         pricingDetailsId: res?.data?.pricingDetailsId,
                    //         licenceTypeId: overallJson?.data?.licenseType[selectedItem]?.licensingTypeID || 1,
                    //         screenName: 'feature'
                    //     }
                    // })
                } else {
                    alert(res?.message || "Something went wrong")
                    // let totalAmount = (featureTitleObj[selectPackage].cost + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers) - discountAmount) * selectedPeriod.value
                    // history.push({
                    //   pathname: `payment`,
                    //   state: {
                    //     amount: totalAmount,
                    //     clientId: history?.location?.state?.clientId || getAuthClientId() || 0,
                    //     userId: history?.location?.state?.userId || getAuthUserId() || 0
                    //   }
                    // })
                }
            },
            fail: error => {
                alert(error?.message || "Something went wrong")
                // let totalAmount = (featureTitleObj[selectPackage].cost + getSubTotal(, selectedAdditionalUsers) - discountAmount) * selectedPeriod.value
                // history.push({
                //   pathname: `payment`,
                //   state: {
                //     amount: totalAmount,
                //     clientId: history?.location?.state?.clientId || getAuthClientId() || 0,
                //     userId: history?.location?.state?.userId || getAuthUserId() || 0
                //   }
                // })
            }
        })
    }

    const CSRowComponent = props => {


        let price = props.item.licensePrice
        const name = props.item.addOnName

        if (props.item.licenseFeatureId === 19) {
            price = props.item.licensePrice * stepperValue || 0
        }

        return <>

            <div className={`cost-row-component `}>
                <div>
                    <div className={`comma-seperator`}>
                        <small>{name}</small>
                    </div>
                </div>
                <div className='price-calc'>
                    {
                        props.item.licenseFeatureId === 19 ? <div className='rs-add-remove-btn'>
                            <div className={`rs-ar-btn ar-remove ${stepperValue === 1 && 'click-off'}`}>
                                <Icon
                                    icon={icons.circle_minus_fill_edge_medium}
                                    size='md'
                                    color='color-medium-red'
                                    onClick={() => {
                                        if (stepperValue > 1) {
                                            setStepperValue(stepperValue - 1)
                                        }
                                    }}
                                ></Icon>
                            </div>
                            <div className='rs-ar-sel'>{numberWithCommas(stepperValue)}</div>
                            <div className={`rs-ar-btn ar-add ${stepperValue === 5 && 'click-off'}`}>
                                <Icon
                                    icon={icons.circle_plus_fill_edge_medium}
                                    size='md'
                                    color='color-dark-green'
                                    onClick={() => {

                                        if (stepperValue < 5) {
                                            setStepperValue(stepperValue + 1)
                                        }
                                    }}
                                ></Icon>
                            </div>
                        </div>
                            : null
                    }

                    <div className='price-amount'>${numberWithCommas(price)}</div>
                </div>
            </div>
        </>
    }

    return (
        <>
            {!getUserData() && <div className='header-component'>
                <div className='header-wrapper'>
                    <RSBrandLogo inner />
                    <ul className='header-nav-right'>
                        <li
                            onClick={() => {
                                localStorage.removeItem('isAuth')
                                context.toggleAuth(false)
                                history.push('/')
                            }}
                        >
                            {/* <i className='icon-sd-logout-medium icons-md white cursor-pointer'></i> */}
                        </li>
                    </ul>
                </div>
            </div>}
            <Container fluid className='features-container new-features'>
                <FeaturesWrapper className={`features-subs-wrapper`}>
                    <Row>
                        <Col md={8} className='features-content-wrapper'>

                            {/* PRICING */}
                            <Row className={`advanced-pricing-button start-panel freeze-panel lanchpad-price ${scrollTabOpacity ? 'freeze-panel-init-ar' : 'freeze-panel-rest-ar'} ${scrollTab ? 'freeze-panel-init' : 'freeze-panel-rest'}`}>
                                <Col md={3} className='rsp-btn-select plain-bg'>
                                    <div className='rsp-btn-label text-start'>
                                        <h3 className=''>Subscription plan</h3>
                                    </div>
                                </Col>
                                {
                                    overallJson?.data?.licenseType.map((item, index) => {
                                        return (
                                            <Col className={`rsp-btn-select cp ${selectedItem === index ? 'price-selected' : 'price-disable'}`} onClick={() => {
                                                setSelectPackage(index)
                                                setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
                                                setSelectAddOnsCheckbox(index === 0 ? overallJson?.data?.free?.addOns : index === 1 ? overallJson?.data?.essentials?.addOns : index === 2 ? overallJson?.data?.premier?.addOns : overallJson?.data?.ultimate?.addOns)
                                                let freeAddons = index === 0 ? overallJson?.data?.free?.freeAddOns : index === 1 ? overallJson?.data?.essentials?.freeAddOns : index === 2 ? overallJson?.data?.premier?.freeAddOns : overallJson?.data?.ultimate?.freeAddOns;

                                                setSelectedPeriod(overallJson?.data?.frequency[0])
                                                if (promoCode !== "") {
                                                    getOfferCost(index + 1, overallJson?.data?.frequency[0].frequencyId);
                                                } else {
                                                    setDiscountAmount(0)
                                                    setPromoCodeErr(null)
                                                    setPromoCode('')
                                                    setPromoSuccess(false)
                                                    setPromoCodeApplied(null)
                                                }
                                                if (userDetails?.isVersium) {
                                                    let tempFreeAddons = [...freeAddons]
                                                    let _newArray = []
                                                    tempFreeAddons?.map((_item, _index) => {
                                                        _item.checked = true
                                                    })
                                                    setSelectFreeAddOnsCheckbox(tempFreeAddons)
                                                } else {
                                                    setSelectFreeAddOnsCheckbox(freeAddons)
                                                }
                                                setStepperValue(1)
                                                setSelectedItem(index)
                                                setSelectedDataProviders([]);
                                            }}>
                                                <div className='rsp-btn-label'>
                                                    {/* <h4 className='mb5'>{item?.licensingType}</h4> */}
                                                    <h4 className='mb5'>{item?.licensingType === "Free trial" ? "Trial (30) days" : item?.licensingType}</h4>
                                                    <h3 className='font-medium fs26'>${item?.price}</h3>
                                                    <div className='selected-price-list'>{selectedItem === index
                                                        ? <Icon icon={icons.circle_tick_medium} color={`white`} size={'md'} />
                                                        : null}
                                                    </div>

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            {/* PLATFORM USERS */}
                            <Row className='advanced-pricing advanced-price-middle advanced-price-features'>
                                <Col md={3} className={`pricing-column pricing-text first-pricing`}>
                                    {overallJson?.data?.free?.commonFeatures?.map((item, index) => {
                                        return <div className='price-label'><p>{item.licenseFeatureName}</p></div>
                                    })}
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 0 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.free?.commonFeatures?.map((item, index) => {
                                        return (
                                            <div className='price-label'>
                                                {/* {
                          item.licenseValue !== null
                            ? `${item.licenseValue}`
                            : item.licenseFeature
                              ? <Icon mainClass='bg-dark-green' icon={icons.tick_medium} color='white' size={'md'} />
                              : <Icon mainClass='bg-medium-red' icon={icons.close_mini} color='white' size={'md'} />
                        } */}
                                                {
                                                    item.licenseValue
                                                        ? item.licenseValue
                                                        : item.licenseFeature === 'Yes'
                                                            ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                                                            : item.licenseFeature === 'No'
                                                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                                : item.licenseFeature
                                                }
                                            </div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 1 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.essentials?.commonFeatures?.map((item, index) => {
                                        return (
                                            <div className='price-label'>
                                                {/* {
                          item.licenseValue !== null
                            ? `${item.licenseValue}`
                            : item.licenseFeature
                              ? <Icon mainClass='bg-dark-green' icon={icons.tick_medium} color='white' size={'md'} />
                              : <Icon mainClass='bg-medium-red' icon={icons.close_mini} color='white' size={'md'} />
                        } */}
                                                {
                                                    item.licenseValue
                                                        ? item.licenseValue
                                                        : item.licenseFeature === 'Yes'
                                                            ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                                                            : item.licenseFeature === 'No'
                                                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                                : item.licenseFeature
                                                }
                                            </div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 2 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.premier?.commonFeatures?.map((item, index) => {
                                        return (
                                            <div className='price-label'>
                                                {/* {
                          item.licenseValue !== null
                            ? `${item.licenseValue}`
                            : item.licenseFeature
                              ? <Icon mainClass='bg-dark-green' icon={icons.tick_medium} color='white' size={'md'} />
                              : <Icon mainClass='bg-medium-red' icon={icons.close_mini} color='white' size={'md'} />
                        } */}
                                                {
                                                    item.licenseValue
                                                        ? item.licenseValue
                                                        : item.licenseFeature === 'Yes'
                                                            ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                                                            : item.licenseFeature === 'No'
                                                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                                : item.licenseFeature
                                                }
                                            </div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 3 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.ultimate?.commonFeatures?.map((item, index) => {
                                        return (
                                            <div className='price-label'>
                                                <div className='price-label'>
                                                    {/* {
                            item.licenseValue !== null
                              ? `${item.licenseValue}`
                              : item.licenseFeature
                                ? <Icon mainClass='bg-dark-green' icon={icons.tick_medium} color='white' size={'md'} />
                                : <Icon mainClass='bg-medium-red' icon={icons.close_mini} color='white' size={'md'} />
                          } */}
                                                    {
                                                        item.licenseValue
                                                            ? item.licenseValue
                                                            : item.licenseFeature === 'Yes'
                                                                ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                                                                : item.licenseFeature === 'No'
                                                                    ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                                    : item.licenseFeature
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>

                            {/* ADDITIONAL USERS */}
                            <Row className='advanced-pricing-button ad-head-mid my15'>
                                <Col md={12} className='rsp-btn-select'><h3 className='my23'>Add-ons <small>({overallJson?.data?.licenseType[selectedItem]?.licensingType})</small></h3></Col>
                            </Row>
                            <Row className='advanced-pricing advanced-price-middle'>

                                <Col md={3} className={`pricing-column pricing-text first-pricing`}>
                                    {overallJson?.data?.free?.addOns?.map((item, index) => {
                                        return <div className='price-label'><p>{item.addOnName}</p></div>
                                    })}
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 0 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.free?.addOns[index].isActive
                                                        ? <RSCheckbox
                                                            className="marginB0 marginT-23"
                                                            // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                            lbl={`$${item.licensePrice} `}
                                                            checked={selectAddOnsCheckbox[index]?.checked || false}
                                                            cb={val => {

                                                                // console.log("_newArray: green day: ", some);
                                                                let _newArray = []
                                                                selectAddOnsCheckbox?.map((_item, _index) => {

                                                                    let some = { ...item }
                                                                    if (some?.checked === undefined) {
                                                                        if (item.licenseFeatureId === 19) {
                                                                            setStepperValue(1)
                                                                        }
                                                                        some.checked = true
                                                                    } else {
                                                                        if (item.licenseFeatureId === 19) {
                                                                            setStepperValue(1)
                                                                        }
                                                                        some.checked = !some?.checked
                                                                    }

                                                                    if (index === _index) {
                                                                        _newArray.push(some)
                                                                    } else {
                                                                        _newArray.push(_item)
                                                                    }
                                                                })
                                                                setSelectAddOnsCheckbox(_newArray)
                                                            }}
                                                        /> : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 1 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.essentials?.addOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 1
                                                                    ? <RSCheckbox
                                                                        className="marginB0"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={selectAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectAddOnsCheckbox(_newArray)
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 2 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.premier?.addOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 2
                                                                    ? <RSCheckbox
                                                                        className="marginB0"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={selectAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectAddOnsCheckbox(_newArray)
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 3 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.ultimate?.addOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 3
                                                                    ? <RSCheckbox
                                                                        className="marginB0"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={selectAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectAddOnsCheckbox(_newArray)
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl={`$${item.licensePrice} `}
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                            </Row>
                            <Row className='advanced-pricing advanced-price-middle'>
                                <Col md={3} className={`pricing-column pricing-text first-pricing`}>
                                    {overallJson?.data?.free?.freeAddOns?.map((item, index) => {
                                        return <div className='price-label'><p>{item.addOnName}</p></div>
                                    })}
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 0 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectFreeAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.free?.freeAddOns[index].isActive
                                                        ? <RSCheckbox
                                                            className="marginB0"
                                                            // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                            // lbl={`$${item.licensePrice} ${item.licenseFeatureId === 1 ? '/ User' : ''}`}
                                                            checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                                            cb={val => {

                                                                // console.log("_newArray: green day: ", some);
                                                                let _newArray = []
                                                                selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                                                    let some = { ...item }
                                                                    if (some?.checked === undefined) {
                                                                        if (item.licenseFeatureId === 19) {
                                                                            setStepperValue(1)
                                                                        }
                                                                        some.checked = true
                                                                    } else {
                                                                        if (item.licenseFeatureId === 19) {
                                                                            setStepperValue(1)
                                                                        }
                                                                        some.checked = !some?.checked
                                                                    }

                                                                    if (index === _index) {
                                                                        _newArray.push(some)
                                                                    } else {
                                                                        _newArray.push(_item)
                                                                    }
                                                                })
                                                                setSelectFreeAddOnsCheckbox(_newArray)
                                                            }}
                                                        /> : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 1 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectFreeAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.essentials?.freeAddOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 1
                                                                    ? <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        // lbl={`$${item.licensePrice} `}
                                                                        checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectFreeAddOnsCheckbox(_newArray)
                                                                            setIsOpenDataProviderModal(val)
                                                                            setSelectedDataProviders([])
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 2 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectFreeAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.premier?.freeAddOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 2
                                                                    ? <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        // lbl={`$${item.licensePrice} `}
                                                                        checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectFreeAddOnsCheckbox(_newArray)
                                                                            setIsOpenDataProviderModal(val)
                                                                            setSelectedDataProviders([])
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 3 ? 'price-selected' : 'price-disable'}`}>
                                    {
                                        selectFreeAddOnsCheckbox?.map((item, index) => {

                                            return <div className='price-label'>
                                                {
                                                    overallJson?.data?.ultimate?.freeAddOns[index].isActive
                                                        ? <>
                                                            {
                                                                selectedItem === 3
                                                                    ? <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                                                        // lbl={`$${item.licensePrice} }`}
                                                                        checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                                                        cb={val => {

                                                                            // console.log("_newArray: green day: ", some);
                                                                            let _newArray = []
                                                                            selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                                                                let some = { ...item }
                                                                                if (some?.checked === undefined) {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = true
                                                                                } else {
                                                                                    if (item.licenseFeatureId === 19) {
                                                                                        setStepperValue(1)
                                                                                    }
                                                                                    some.checked = !some?.checked
                                                                                }

                                                                                if (index === _index) {
                                                                                    _newArray.push(some)
                                                                                } else {
                                                                                    _newArray.push(_item)
                                                                                }
                                                                            })
                                                                            setSelectFreeAddOnsCheckbox(_newArray)
                                                                            setIsOpenDataProviderModal(val)
                                                                            setSelectedDataProviders([])
                                                                        }}
                                                                    />
                                                                    : <RSCheckbox
                                                                        className="marginB0 marginT-23"
                                                                        checked={false}
                                                                    />
                                                            }
                                                        </>
                                                        : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                                }

                                            </div>
                                        })
                                    }
                                </Col>
                            </Row>

                            {/* PACKAGES INCLUDES - Fixed volume per month */}
                            <Row className='advanced-pricing-button ad-head-mid my15'>
                                <Col md={12} className='rsp-btn-select'><h3 className='my23 text-left'>Package includes <small>({overallJson?.data?.licenseType[selectedItem]?.licensingType})</small></h3></Col>
                            </Row>

                            <Row className='advanced-pricing advanced-price-middle'>
                                <Col md={3} className={`pricing-column pricing-text first-pricing`}>
                                    {overallJson?.data?.free?.packages?.map((item, index) => {
                                        return <div className='price-label'><p>{item.packageName}</p></div>
                                    })}
                                </Col>

                                <Col className={`pricing-column pricing-field ${selectedItem === 0 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.free?.packages?.map((item, index) => {
                                        return (
                                            <div className='price-label'>{item.packageVolume}</div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 1 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.essentials?.packages?.map((item, index) => {
                                        return (
                                            <div className='price-label'>{item.packageVolume}</div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 2 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.premier?.packages?.map((item, index) => {
                                        return (
                                            <div className='price-label'>{item.packageVolume}</div>
                                        )
                                    })}
                                </Col>
                                <Col className={`pricing-column pricing-field ${selectedItem === 3 ? 'price-selected' : 'price-disable'}`}>
                                    {overallJson?.data?.ultimate?.packages?.map((item, index) => {
                                        return (
                                            <div className='price-label'>{item.packageVolume}</div>
                                        )
                                    })}
                                </Col>

                            </Row>

                            {/* FOOTER TICK */}
                            <Row className='advanced-pricing-button ad-price-footer'>
                                <Col md={3}></Col>
                                <Col className={`rsp-btn-select ${selectedItem === 0 ? 'price-selected' : 'price-disable'}`}>
                                    <div className='rsp-btn-label'>
                                        <div className={`selected-price-list ${selectedItem === 0 ? '' : 'd-none'}`}><Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /></div>
                                    </div>
                                </Col>
                                <Col className={`rsp-btn-select ${selectedItem === 1 ? 'price-selected' : 'price-disable'}`}>
                                    <div className='rsp-btn-label'>
                                        <div className={`selected-price-list ${selectedItem === 1 ? '' : 'd-none'}`}><Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /></div>
                                    </div>
                                </Col>
                                <Col className={`rsp-btn-select ${selectedItem === 2 ? 'price-selected' : 'price-disable'}`}>
                                    <div className='rsp-btn-label'>
                                        <div className={`selected-price-list ${selectedItem === 2 ? '' : 'd-none'}`}><Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /></div>
                                    </div>
                                </Col>
                                <Col className={`rsp-btn-select ${selectedItem === 3 ? 'price-selected' : 'price-disable'}`}>
                                    <div className='rsp-btn-label'>
                                        <div className={`selected-price-list ${selectedItem === 3 ? '' : 'd-none'}`}><Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /></div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>

                        <div className={`features-cost-wrapper fixed-top rs-scroll ${scroll ? 'rs-scroll-init' : 'rs-scroll-rest'} ${scrollEnd ? 'rs-scroll-init-end' : 'rs-scroll-rest-end'}`}>
                            <div className='summary-cost-header'>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <h3 className='paddingB10'>Cost summary</h3>
                                    <h4>Amount (USD)</h4>
                                </div>
                                <hr className='dark' />
                            </div>
                            <div className='summary-cost-body-wrapper'>
                                <div className="summary-cost-body">
                                    <div className="summary-cost-list">
                                        {/* SUBSCRIPTION COST */}
                                        <div className='cost-row-component'>
                                            <div>
                                                <div>Subscription cost</div>
                                            </div>
                                            <div>${overallJson?.data?.licenseType[selectedItem]?.price}</div>
                                        </div>
                                        {
                                            selectAddOnsCheckbox?.map(item => {


                                                return item.checked
                                                    ?
                                                    <>
                                                        <CSRowComponent
                                                            title="Audience list volume"
                                                            item={item}
                                                        />
                                                    </>
                                                    : null
                                            })
                                        }
                                        {/* {
                                            selectFreeAddOnsCheckbox?.map(item => {

                                                console.log("selectAddOnsCheckbox: ", selectFreeAddOnsCheckbox);

                                                return item.checked
                                                    ?
                                                    <>
                                                        <CSRowComponent
                                                            title="Audience list volume"
                                                            item={item}
                                                        />
                                                    </>
                                                    : null
                                            })
                                        } */}
                                    </div>
                                    <div className="summary-cost-footer"> {/* SUB TOTAL */}
                                        <div className='cost-row-component mt15'>
                                            <div>
                                                <div>Sub total</div>
                                            </div>
                                            <div className=''>${numberWithCommas(overallJson?.data?.licenseType[selectedItem]?.price + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers))}</div>
                                        </div>

                                        {/* PROMO CODE */}
                                        {selectPackage === 0 ? null : <div className='cost-row-component'>
                                            <div className='wsn promo-code-text'>Coupon code</div>
                                            <div className="position-relative">
                                                <div className={`d-flex align-items-end justify-content-end promo position-relative`}>
                                                    {promoCodeErr && <div className={`validation-message ${promoSuccess ? 'success_msg' : ''}`}>{promoCodeErr}</div>}
                                                    <div className='coupon-input'>
                                                        <RSTextInputWithIcon
                                                            ph='Enter coupon code'
                                                            disabled={promoCodeApplied}
                                                            val={promoCode}
                                                            icon={`${promoCodeApplied === null ? '' : promoCodeApplied ? 'icon-sd-circle-tick-medium green mr10' : 'icon-sd-circle-close-fill-medium red'} icons-md mr10`}
                                                            max={15}
                                                            onClickIcon={() => {
                                                                setPromoCode("")
                                                                setPromoCodeErr(null)
                                                                setPromoCodeApplied(null)
                                                                setDiscountAmount(0)
                                                                setPromoSuccess(false)
                                                            }}
                                                            cb={value => {
                                                                setPromoCode(value)
                                                                setPromoCodeApplied(null)

                                                                // if (value.length) {
                                                                setPromoCodeErr(null)
                                                                // } else {
                                                                //   setPromoCodeErr("Enter promo code")
                                                                // }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="position-relative promo-code-btn">
                                                        <span className="blue cp" onClick={() => {
                                                            if (promoCodeApplied) {
                                                                setDiscountAmount(0)
                                                                setPromoCodeErr(null)
                                                                setPromoCode('')
                                                                setPromoSuccess(false)
                                                                setPromoCodeApplied(null)
                                                            }
                                                            else {
                                                                if (promoCode.length) {
                                                                    getOfferCost(overallJson?.data?.licenseType[selectedItem]?.licensingTypeID, selectedPeriod.frequencyId)
                                                                } else {
                                                                    setPromoSuccess(false)
                                                                    setPromoCodeErr("Enter coupon code")
                                                                }
                                                            }
                                                        }}>
                                                            {promoCodeApplied === null ? "Apply" : promoCodeApplied ? 'Remove' : 'Apply'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        }
                                        {selectPackage === 0 || discountAmount === 0 ? null :
                                            <div className='cost-row-component mt23'>
                                                <div>
                                                    <div>Discount amount</div>
                                                </div>
                                                <div className={`${discountAmount ? 'color-submit' : ''}`}>- ${numberWithCommas(discountAmount)}</div>
                                            </div>
                                        }

                                        {/* <hr /> */}
                                        {/* <div className='cost-row-component mt15'>
                      <div>
                        <div>Total amount {`(9897)`}</div>
                      </div>
                      <div className={`font-bold`}> {`$8878`}</div>
                    </div>
                    <div className='cost-row-component mt15'>
                      <div>
                        <div>Available amount</div>
                      </div>
                      <div className={`color-submit font-bold`}> {`$8989`}</div>
                    </div>
                    <div className='cost-row-component mt15'>
                      <div>
                        <div>Tax amount</div>
                      </div>
                      <div className={`font-bold`}> {`$${numberWithCommas(8787889)}`}</div>
                    </div> */}
                                    </div>
                                </div>

                                {/* FOOTER FIXED */}
                                <div className="cost-footer-fixed">
                                    <div className="cost-row-component my15">
                                        <div>
                                            <h4 className='font-bold fs18'>Amount (USD)</h4>
                                            <div >
                                                <BootstrapDropdown
                                                    data={overallJson?.data?.frequency || []}
                                                    fieldKey="frequency"
                                                    defaultItem={selectedPeriod?.frequency}
                                                    // customAlignRight={true}
                                                    // alignRight
                                                    className={`fs16-btn dropdown-btn-primary ${selectedItem === 0 ? "click-off" : ""}`}
                                                    onSelect={(item, index) => {
                                                        if (promoCode !== "") {
                                                            getOfferCost(overallJson?.data?.licenseType[selectedItem]?.licensingTypeID, item.frequencyId)
                                                        }
                                                        setSelectedPeriod(item)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-end'>
                                            <div className='font-bold fs18'>
                                                ${numberWithCommas((overallJson?.data?.licenseType[selectedItem]?.price + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers)) * selectedPeriod?.value - discountAmount)}
                                            </div>
                                            <small>Payable amount</small>
                                        </div>
                                    </div>
                                    {/* <hr /> */}

                                    <div className='btn-container d-flex justify-content-end mt0 mb23'>
                                        {/* {getUserData() &&
                                            <RSCancelBtn
                                                onClick={() => {
                                                    // context.globalStateData.setBodyNameData('')
                                                    history.push("/preferences")
                                                }}
                                            >
                                                Cancel
                                            </RSCancelBtn>
                                        } */}
                                        <RSPrimaryBtn
                                            className={'click-on'}
                                            onClick={() => {
                                                postPricingDetails()
                                            }}>
                                            Proceed</RSPrimaryBtn>
                                    </div>

                                    {/* Line */}
                                    <hr />

                                    <small className='fs13'>* This cost is only for subscription of this product</small>
                                </div>
                            </div>
                        </div>

                    </Row>
                </FeaturesWrapper>
                {isOpenDataProviderModal &&
                    <DataProviderModal
                        isOpen={isOpenDataProviderModal}
                        dataProviders={dataProviders}
                        selectedDataProviders={selectedDataProviders}
                        onChangeOpen={(value) => {
                            if (!value) {
                                let _newArray = []
                                selectFreeAddOnsCheckbox?.map((_item, _index) => {
                                    let some = { ..._item }
                                    some.checked = false
                                    _newArray.push(some)
                                })
                                setSelectFreeAddOnsCheckbox(_newArray)
                            }
                            setIsOpenDataProviderTermsModal(value)
                            setIsOpenDataProviderModal(false)
                        }}
                        getSelectedDataProviders={(value) => { setSelectedDataProviders(value) }}
                    />}
                {isOpenDataProviderModal &&
                    <DataProviderModal
                        isOpen={isOpenDataProviderModal}
                        dataProviders={dataProviders}
                        selectedDataProviders={selectedDataProviders}
                        dataProviderContent={isVersiumOpenDataProviderTermsModal ? "Your Versium REACH account is enabled by default. Do you wish to proceed?" : ""}
                        onChangeOpen={(value) => {
                            if (!value) {
                                let _newArray = []
                                selectFreeAddOnsCheckbox?.map((_item, _index) => {
                                    let some = { ..._item }
                                    some.checked = false
                                    _newArray.push(some)
                                })
                                setSelectFreeAddOnsCheckbox(_newArray)
                            }
                            setIsOpenDataProviderTermsModal(value)
                            setIsOpenDataProviderModal(false)
                        }}
                        getSelectedDataProviders={(value) => { setSelectedDataProviders(value) }}
                    />}
                {isOpenDataProviderTermsModal &&
                    <DataProviderTermsModal
                        isOpen={isOpenDataProviderTermsModal}
                        dataProviders={dataProviders}
                        selectedDataProviders={selectedDataProviders}
                        onChangeOpen={(value) => {
                            if (!value) {
                                let _newArray = []
                                selectFreeAddOnsCheckbox?.map((_item, _index) => {
                                    let some = { ..._item }
                                    some.checked = false
                                    _newArray.push(some)
                                })
                                setSelectFreeAddOnsCheckbox(_newArray)
                            }
                            setIsOpenDataProviderTermsModal(false)
                        }}
                        getSelectedDataProviders={(value) => { setSelectedDataProviders(value) }}
                    />}
                {isOpenResulPay &&
                    <ResulPayModal
                        isOpen={isOpenResulPay}
                        orderId={planInvoiceDetails?.clientInvoiceId}
                        isConsumable={false}
                        amount={planInvoiceDetails?.encryptedTotalAmount}
                        clientName={planInvoiceDetails?.clientname}
                        tenantShortCode={planInvoiceDetails?.tenantShortCode}
                        onChangeIsOpen={(value) => {
                            setIsOpenResulPay(value)
                        }}
                    />
                }
            </Container>
        </>
    )
}
export default FeaturePlan




const featureTitleObj = {
    0: { title: "Free", cost: 0 },
    1: { title: "Essentials", cost: 100 },
    2: { title: "Premier", cost: 300 },
    3: { title: "Ultimate", cost: 500 }
}

const additionalUsersArry = [1, 2, 3, 4, 5]