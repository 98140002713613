import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { GET_USER_LIST, GET_USER_ROLE, USER_ASSIGN_ROLE } from 'Helper/Constants/endPoint';
import { SELECT_USERROLE } from 'Helper/Constants/validationMessage';
import { getAuthClientId } from 'Helper/Utils/Utils';
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { RSPTooltip } from 'Components/Tooltip';
let myArr = [], cloneData = [];

class AddUserRole extends React.Component {

    static contextType = authContext

    state = {
        assignList: null,
        usersRole: [],
        formFields: '',
        newData: [],
        selectList: [],
        errMsgUserRole: [],
        isClicked: false,
        selectListCount: 0,
        isOpenErrorModal: false,
        modalErrorMessage: null
    }

    componentDidMount = () => {
        this.getUsersList();
        this.getUsersRole();
    }

    getUsersList = () => {
        const params = { clientId: getAuthClientId() }
        connectServer({
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            path: GET_USER_LIST,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let userListWithoutRole = res?.data?.filter(item => item.role === null)
                    let usersDataList = userListWithoutRole.map(item => {
                        item.className = '';
                        return item;
                    })
                    let assignedRoleList = res?.data?.filter(item => item.role !== null)
                    let assignErrMsgList = []
                    let assignedUsersList = assignedRoleList.map((item, index) => {
                        assignErrMsgList.push({
                            errMsg: null
                        })
                        return item
                    })
                    this.setState({
                        assignList: usersDataList,
                        newData: assignedUsersList,
                        errMsgUserRole: assignErrMsgList,
                        selectList: assignedUsersList
                    })
                }
            },
            fail: err => {
            }
        })
    }

    getUsersRole = () => {
        const params = { clientId: getAuthClientId() }
        connectServer({
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            path: GET_USER_ROLE,
            params,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ usersRole: res.data })
                }
            },
            fail: err => {
            }
        })
    }


    assignList = () => {
        return <ul className="grid-style">
            {
                this.state.assignList.map((item, index) => (item.className === '')
                    ? <li key={index} list={index} onClick={(e) => this.addRow(e, index, item)} >
                        <span>{item.firstName} {item.lastName}</span>
                    </li>
                    : ''
                )
            }
        </ul>
    }

    addRow = (...data) => {
        cloneData = [...this.state.assignList]
        this.setState({ isClicked: true })
        if (data[0].currentTarget.classList.contains('active')) {
            data[0].currentTarget.classList.remove('active');
            this.setState({ isClicked: this.state.selectListCount > 0 ? true : false })
            let temp = [...this.state.selectList]
            temp = temp.filter(items => items !== data[2])
            this.setState({ selectList: temp })
            cloneData[data[1]].className = '';
        } else {
            data[0].currentTarget.classList.add('active');
            let temp = [...this.state.selectList]
            temp.push(data[2])
            let tempError = [...this.state.errMsgUserRole]
            tempError.push({
                errMsg: null
            })
            this.setState({ selectList: temp, errMsgUserRole: tempError, selectListCount: this.state.selectListCount + 1 })
        }
    }

    addInputs = () => {
        cloneData.forEach(item => {
            this.state.selectList.forEach((selectItem, index) => {
                if (selectItem.userId === item.userId) {
                    cloneData = cloneData.filter(items => selectItem.userId !== items.userId)
                }
            })
        })
        this.setState({
            formFields: myArr,
            assignList: cloneData,
            newData: this.state.selectList,
        })
    }

    removeRole = (item) => {
        let assList = [...this.state.assignList];
        const items = this.state.newData.filter(listItem => listItem !== item);
        this.setState({ newData: items, selectList: items });
        assList.push(item)
        assList.forEach(
            (items, index) => (items === item) ? items.className = '' : ''
        )
        this.setState({
            assignList: assList
        })
    }

    handleKeyPerson = (data, index) => {
        this.setState(prevState => ({
            newData: prevState.newData.map((item, itemIndex) => {
                return itemIndex === index ? { ...item, isKeyperson: !data.isKeyperson } : { ...item, isKeyperson: false };
            })
        }, () => {
        }))
        this.state.selectList.forEach((selectItem, selectIndex) => {
            if (selectIndex === index) {
                selectItem['isKeyperson'] = !data.isKeyperson;
            } else {
                selectItem['isKeyperson'] = false;
            }
        })
    }



    submitUsersRole = () => {

        let temp = [...this.state.newData]
        let tempErrMsg = [...this.state.errMsgUserRole];
        let enable = true
        temp.forEach((item, index) => {
            if (item.role === null || item.role === '-- Select role --') {
                let errMsg = item.role === null || item.role === '-- Select role --' ? SELECT_USERROLE : null
                tempErrMsg[index].errMsg = errMsg;
                this.setState({ errMsgUserRole: tempErrMsg })
                enable = false;
                return
            }
        })
        if (enable) {
            const userRoleList = this.state.newData.map((item, index) => {
                const { userId, roleId, statusId, isKeyperson } = item;
                return { userId, roleId, statusId, isKeyperson };
            })
            const userUnassign = this.state.assignList.map((item, index) => {
                const { userId, roleId } = item;
                return { userId, roleId };
            })
            const params = { userRoleList, userUnassign };

            connectServer({
                loading: this.context.globalStateData.setIsLoadingData,
                sessionOut: this.context.globalStateData.setSessionOutData,
                path: USER_ASSIGN_ROLE,
                params,
                context: this.context,
                ok: res => {
                    if (res.status) {
                        this.props.cb(2)
                    } else {
                        this.setState({ isOpenErrorModal: true, modalErrorMessage: res.message })
                    }
                },
                fail: err => {
                }
            })
        }

    }

    render() {

        return (
            <div className="page-header box-bottom-space settings-container">
                <div className="well-cover btop">
                    <Row className="marginB10 d-none">
                        <Col xs={7} style={{ textAlign: 'right', width: '100%' }}>
                            <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer" onClick={() => { this.props.history.push("users") }}></i>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            {this.state.assignList && <this.assignList />}
                        </Col>
                        <Col md={1} style={{ height: '100%', textAlign: 'center' }}>
                            <div className={`${this.state.isClicked ? 'grid-style-arrow text-center' : 'grid-style-arrow click-off text-center'}`}
                                onClick={() => {
                                    this.addInputs()
                                    this.setState({ isClicked: false })
                                }}>
                            </div>
                        </Col>
                        <Col md={8}>

                            <div className="sec-list">
                                <ul className="sec-listing css-scrollbar paddingT15">
                                    {this.state.newData.map((data, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="label">
                                                    {data.firstName} {data.lastName}
                                                </div>

                                                <div className="input-field-list">
                                                    <div className='position-relative'>
                                                        {this.state.errMsgUserRole[index].errMsg && <div className='validation-message'>{this.state.errMsgUserRole[index].errMsg}</div>}
                                                        <RSDropdownList
                                                            className="required"
                                                            data={this.state.usersRole}
                                                            value={{
                                                                securityGroupId: data.roleId,
                                                                securityGroupName: data.role !== null ? data.role : '-- Select role --'
                                                            }}
                                                            defaultItem={{
                                                                securityGroupId: 0,
                                                                securityGroupName: "-- Select role --"
                                                            }}
                                                            textField='securityGroupName'
                                                            dataItemKey='securityGroupId'
                                                            onChange={(event) => {
                                                                let tempData = [...this.state.newData]
                                                                let tempError = [...this.state.errMsgUserRole];
                                                                tempData[index].roleId = event.target.value.securityGroupId
                                                                tempData[index].role = event.target.value.securityGroupName
                                                                let errMsg = tempData[index].role === null || tempData[index].role === '-- Select role --' ? SELECT_USERROLE : null
                                                                tempError[index].errMsg = errMsg;
                                                                this.setState({
                                                                    newData: tempData,
                                                                    errMsgUserRole: tempError
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {(data.role === "Superuser" || data.role === "Admin")
                                                    && <div className="sec-icon-list">
                                                        <i className={`icon-sd-crown-fill-medium icons-md cursor-pointer ${data.isKeyperson ? "orange" : "grey"}`}
                                                            onClick={() => this.handleKeyPerson(data, index)}>
                                                        </i>
                                                    </div>
                                                }

                                                <div className="sec-icon-list-end">
                                                    <RSPTooltip text={"Remove"} position="top">
                                                        <i className="icon-sd-circle-minus-fill-edge-medium icons-md red cursor-pointer" onClick={() => { this.removeRole(data) }}></i>
                                                    </RSPTooltip>
                                                </div>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="clearfix small invoice-note">
                    <p>Note: The crowned user will become the key (primary) contact for the account and receive all account-related notifications & OTPs. Only one selection is allowed.</p>
                </div>
                <div className="btn-container d-flex justify-content-end">
                    <RSSecondaryBtn onClick={() => { this.props.history.push(`/preferences`) }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn onClick={() => this.submitUsersRole()}>Save</RSPrimaryBtn>
                </div>
                {this.state.isOpenErrorModal &&
                    <RSConfirmAlert
                        title={this.state.modalErrorMessage}
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            this.setState({ isOpenErrorModal: false, modalErrorMessage: null })
                        }} />}
            </div>
        )
    }
}

export default withRouter(AddUserRole);