import React, { Component } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap"
import { withRouter } from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Switch } from '@progress/kendo-react-inputs'
import { DropDownButton } from "@progress/kendo-react-buttons";

import { RSCheckbox, RSInput2, RSRadio } from "Components/RSInputs";
import { RSDropdownList } from 'Components/RSDropdowns';
import { IconDropdown } from "Components/BootstrapDropdown/BootstrapDropdown";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import RequestApproval from "./RequestApproval/RequestApproval";

import { convertStringToArray, filterTwoStringArray, isEmpty, makeImmutableObject } from "Helper/Utils/Utils";
import { ruleValidation, validateDynamicList } from "./DLValidation";

import editJson from './DLCreationEdit.json'
import editJson1 from './DLCreationEdit1.json'
import editJson2 from './DLCreationEdit2.json'
import editJson3 from './DLCreationEdit3.json'
import editJson4 from './DLCreationEdit4.json'
import editJson5 from './DLCreationEdit5.json'

import loadJson from './DLCreationJson'
import DLSaveModal from "./DLSaveModal";

class DLCreation extends Component {

    currentIndex = 1;
    auidenceCount = 889774;
    createObject = (name) => {
        var obj = {
            ruleGroupName: name.toString(),
            disabled: true,
            matchtype: 'All',
            trigger: '',
            source: '',
            condtion: true,
            matchCount: '3',
            values: [

            ],
            rule: [

            ]
        };
        return obj;
    }

    state = {
        dynamicListName: '',
        list: [],//[this.createObject(this.currentIndex, 889774)],
        requestForApproval: false,
        triggerSelected: false,
        errorMessage: null,
        showPopUp: true,
        showSavePopup: false,
        isListNameSaved: false,
        approvalList: [
            {
                approverName: "",
                ismandatory: false
            }
        ],
        approvalSettings: {
            approvalMatchType: "All",
            approvalMatchCount: 0,
            isFollowHierarchyEnabled: false,
        },
        ruleCondition: "And"
    }

    componentDidMount() {
        if (this.props.history.location.state.mode === 'edit') {

            let tempRule = [...editJson.ruleGroup]
            let listName = editJson.listName
            let json = editJson
            if (this.props.history.location.state.listId === 1000) {
                let object = makeImmutableObject(editJson)
                tempRule = [...object.ruleGroup]
                listName = object.listName
                json = object;
            }
            else if (this.props.history.location.state.listId === 1001) {
                let object = makeImmutableObject(editJson1)
                tempRule = [...object.ruleGroup]
                listName = object.listName
                json = object;
            }
            else if (this.props.history.location.state.listId === 1002) {
                let object = makeImmutableObject(editJson2)
                tempRule = [...object.ruleGroup]
                listName = object.listName
                json = object;
            }
            else if (this.props.history.location.state.listId === 1003) {
                let object = makeImmutableObject(editJson3)
                tempRule = [...object.ruleGroup]
                listName = object.listName
                json = object;
            }
            else if (this.props.history.location.state.listId === 1004) {
                let object = makeImmutableObject(editJson4)
                tempRule = [...object.ruleGroup]
                listName = object.listName
                json = object;
            }
            else if (this.props.history.location.state.listId === 1005) {
                let object = makeImmutableObject({ ...editJson5 })
                tempRule = object.ruleGroup
                listName = object.listName
                json = object;
            }
            else {
                let ruleJson = JSON.parse(localStorage.getItem(`ruleJson${this.props.history.location.state.listId}`))
                tempRule = [...ruleJson.ruleGroup]
                listName = ruleJson.listName
                json = ruleJson;
            }


            for (let i = 0; i < tempRule.length; i++) {
                var tempItem = tempRule[i]
                var tempRuleArray = tempItem.rule
                for (let j = 0; j < tempRuleArray.length; j++) {
                    var tempRuleItem = tempRuleArray[j]
                    tempRuleItem.inputArray = []
                    var tempInputData = loadJson[tempItem.trigger.toLowerCase()].data.filter(dataItem => dataItem.id === tempRuleItem.id)
                    if (tempInputData.length > 0) {
                        if (tempInputData[0].value === '') {
                            // eslint-disable-next-line no-loop-func
                            tempRuleItem = [1, 2, 3, 4, 5].map(item => {
                                let text = tempRuleItem.rulename + item.toString()
                                return tempRuleItem.inputArray.push(text)
                            })

                        } else {
                            tempRuleItem.inputArray = tempInputData[0].value
                        }
                    }

                }


            }
            this.setState({ ruleCondition: json.ruleCondition })
            this.setState({ dynamicListName: listName, list: tempRule })
            this.setState({
                triggerSelected: true, requestForApproval: json.isRequestForApprovalEnabled,
                approvalList: json.requestForApproval
            })
            let tempApproverSettings = {
                approvalMatchType: json.approvalMatchType,
                approvalMatchCount: json.approvalMatchCount,
                isFollowHierarchyEnabled: json.isFollowHierarchyEnabled,
            }
            this.setState({ approvalSettings: tempApproverSettings })
        } else {
            this.setState({ list: [this.createObject(this.currentIndex)] })
        }
    }

    addBtnClicked(listname, list) {
        return validateDynamicList(listname, list)

    }

    handleClose = () => {
        this.setState({ errorMessage: null, showPopUp: false });
    }

    // buildParams = () => {
    //     let ruleList = [];
    //     let listId = Math.floor(1000 + ((crypto.getRandomValues(new Uint32Array(1))[0]) / 0x100000000) * 9000);
    //     if (this.props.history.location.state.mode === 'edit') {
    //         listId = this.props.history.location.state.listId
    //     }
    //     this.state.list.forEach(element => {

    //         let rule = {
    //             ruleGroupName: element.name,
    //             matchtype: element.matchtype,
    //             matchCount: element.matchCount,
    //             trigger: element.trigger,
    //             source: element.source,
    //             rule: element.rule.map(({ rulename, ismandatory, type, value, id, city, area, formname, formvalue, attributesname, attributesvalue, latitude, longitude, radius, beaconname, beaconvalue }) => ({ rulename, ismandatory, type, value, id, city, area, formname, formvalue, attributesname, attributesvalue, latitude, longitude, radius, beaconname, beaconvalue })),
    //         };
    //         ruleList.push(rule);
    //     });
    //     let object = {
    //         listName: this.state.dynamicListName,
    //         listId: listId,
    //         ruleCondition: this.state.ruleCondition,
    //         ruleGroup: ruleList,
    //         isRequestForApprovalEnabled: this.state.requestForApproval,
    //         requestForApproval: this.state.approvalList,
    //         approvalMatchType: this.state.approvalSettings.approvalMatchType,
    //         approvalMatchCount: this.state.approvalSettings.approvalMatchCount,
    //         isFollowHierarchyEnabled: this.state.approvalSettings.isFollowHierarchyEnabled
    //     }
    //     let listObject = {
    //         "name": object.listName,
    //         "createdOn": "Sun, Aug 22, 2021",
    //         "totalAudience": 215300,
    //         "blastedItems": 5,
    //         "listId": object.listId,
    //         "avgOpenRate": 0.2
    //     }

    //     let dlist = localStorage.getItem('dlist')
    //     if (listId === 1000 || listId === 1001 || listId === 1002 || listId === 1003 || listId === 1004 || listId === 1005) {

    //     } else {
    //         localStorage.setItem(`dlist${listId}`, JSON.stringify(listObject))
    //         localStorage.setItem(`ruleJson${listId}`, JSON.stringify(object))
    //     }

    // }

    render() {

        return (
            <>
                {this.state.errorMessage &&
                    <Modal show={this.state.showPopUp} backdrop="static"
                        keyboard={false} centered onHide={() => this.handleClose()}>
                        <div className="d-flex align-items-center justify-content-between">
                            <label>{this.state.errorMessage}</label>
                            <i className="icon-close-small blue cp p-close" onClick={() => this.handleClose()}></i>
                        </div>
                    </Modal>
                }
                <div className="header-title d-flex justify-content-between">
                    <h1>New dynamic list</h1>
                    <div className="back-text" onClick={() => { this.props.history.push({ pathname: "/audience", state: { index: 1 } }); }}>
                        <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer" ></i>
                        Back
                    </div>
                </div>
                <hr className="blue marginB20" />
                <Row className="custom-list">
                    <Col md={3}>
                        <label className="label-row">List name</label>
                    </Col>
                    <Col md={8}>
                        <RSInput2 cls="marginB15" ph="Enter the dynamic list name" val={this.state.dynamicListName} cb={(value) => { this.setState({ dynamicListName: value }) }} />
                    </Col>
                </Row>

                {this.state.list.map((item, index) => {
                    return (
                        <div key={index}>
                            <DLC1
                                data={item}
                                array={this.state.list}
                                index={index}
                                disabled={isEmpty(this.state.dynamicListName)}
                                errorMessage={this.state.errorMessage}
                                rulesAdded={() => {
                                    this.forceUpdate()
                                }}
                                closeBtnClicked={() => {
                                    var list = [...this.state.list]
                                    var index1 = list.indexOf(item)
                                    list.splice(index1, 1)
                                    this.setState({ list: list })
                                }}
                                triggerChanged={(value) => {
                                    this.setState({ triggerSelected: value });
                                }}
                            />
                            {this.state.list.length > 1 && index === 0 ?
                                <Row>
                                    <Col>
                                        <div className="w-100 d-flex justify-content-center marginB25 switch-divider">
                                            <Switch
                                                onLabel={"And"} offLabel={"Or"}
                                                onChange={(event) => {
                                                    item.condtion = event.target.value;
                                                    if (item.condtion) {
                                                        this.setState({ ruleCondition: "And" })
                                                    } else {
                                                        this.setState({ ruleCondition: "Or" })
                                                    }
                                                    this.forceUpdate()

                                                    // this.forceUpdate()
                                                }} checked={item.condtion} />
                                        </div>
                                    </Col>
                                </Row>
                                : null}
                        </div>
                    )
                })}

                {this.state.list.length <= 1 ?
                    <div className="dynamicListAddIcon">
                        <i className={`icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer ${isEmpty(this.state.dynamicListName) && 'click-off'}`} onClick={() => {
                            var status = this.addBtnClicked(this.state.dynamicListName, this.state.list)
                            if (status[0] === true) {
                                this.currentIndex = this.currentIndex + 1
                                var list = [...this.state.list]
                                list.push(this.createObject(this.currentIndex))
                                this.setState({ list: list })
                            } else {
                                this.setState({ errorMessage: status[1], showPopUp: true })
                            }

                        }}>
                        </i>
                    </div>
                    : null}

                <p className="marginT-15">Note: Probabilistic audience size prediction is derived</p>

                {this.state.triggerSelected && <div className="d-flex align-items-center marginB30">
                    <RSCheckbox
                        className="marginB0"
                        lbl="Request for approval"
                        checked={this.state.requestForApproval}
                        cb={(status) => {
                            this.setState({ requestForApproval: status });
                        }}
                    />
                    <RSPPophover text="Get the list approved by the concerned person." position="top">
                        <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                    </RSPPophover>
                </div>}



                {this.state.requestForApproval && <RequestApproval approvalList={this.state.approvalList} approvalSettings={this.state.approvalSettings} clickModelShow={(value) => {
                    this.setState({ requestForApproval: value });
                }} />}



                <div className="btn-container d-flex justify-content-end w-100">
                    <RSSecondaryBtn onClick={() => { this.props.history.push({ pathname: "/audience", state: { index: 1 } }); }}>Cancel</RSSecondaryBtn>
                    {/* <RSSecondaryBtn className="blue" onClick={() => { this.props.updateIndex(0) }}>Save</RSSecondaryBtn> */}
                    <RSPrimaryBtn
                        className={`${isEmpty(this.state.dynamicListName) && 'click-off'}`}
                        onClick={() => {


                            var status = this.addBtnClicked(this.state.dynamicListName, this.state.list)
                            if (status[0]) {
                                if (!this.state.requestForApproval) {
                                    status = [false, "Please check request for approval"];
                                } else {
                                    this.state.approvalList.forEach(element => {
                                        if (isEmpty(element.approverName) || element.approverName.toLowerCase().includes('select')) {
                                            status = [false, "Please select approver"];
                                            return;
                                        }
                                    });
                                }
                                if (status[0] && !isEmpty(this.state.approvalSettings.approvalMatchCount)) {
                                    if (this.state.approvalSettings.approvalMatchCount < this.state.approvalList.length) {
                                        status = [true, ""];
                                    } else {
                                        status = [false, "Approver list & Approver Any Count Value is mismatch"];
                                    }
                                }
                            }
                            if (status[0] === true) {
                                this.setState({ showSavePopup: true });
                                // if(this.state.isListNameSaved)
                                // {
                                //     this.buildParams();
                                //     this.props.history.goBack();
                                // }
                            } else {
                                this.setState({ errorMessage: status[1], showPopUp: true })
                            }

                        }}>{this.props.history.location.state.mode === 'edit' ? 'Update' : 'Create'} rule</RSPrimaryBtn>
                </div>
                {this.state.showSavePopup &&
                    <DLSaveModal isOpen={this.state.showSavePopup} listName={this.state.dynamicListName} onChangeIsOpen={(value) => {
                        this.setState({ showSavePopup: false, isListNameSaved: value.isShow, dynamicListName: value.listName });
                        if (value.isShow) {
                            // this.buildParams();
                            this.props.history.push({ pathname: "/audience", state: { index: 1 } });
                        }
                    }} />
                }

                {/* <Modal show={this.state.requestForApproval}>
                        <ApprovalPopup />
                </Modal> */}

            </>
        )
    }
}

export default withRouter(DLCreation)

class RenderComponent extends Component {

    latitude = this.props.selectValues.latitude
    longitude = this.props.selectValues.longitude
    showPopOver = false
    latLongComponent = (data) => {
        return (
            <Row>
                <Col md={4}>
                    <RSInput2 ph="Latitude" cls2="latitude-to" keyboardType='number' val={this.latitude} cb={(text) => {
                        //this.props.value = text
                        this.latitude = text
                        this.forceUpdate();
                        this.props.selectValues.latitude = text
                        this.props.onChanged(text)
                    }} />
                </Col>
                <Col md={4}>
                    <RSInput2 ph="Longitude" keyboardType='number' val={this.longitude} cb={(text) => {
                        //this.props.value = text
                        this.longitude = text
                        this.props.selectValues.longitude = text
                        this.props.onChanged(text)
                    }} />
                </Col>
                <Col md={4}>
                    <RSDropdownList data={["2 KM", "3 KM", "4 KM"]} defaultItem={this.props.selectValues.radius !== undefined && this.props.selectValues.radius !== "" ? this.props.selectValues.radius : "-- Radius --"} onChange={(event) => {
                        this.props.onChanged(event.target.value)
                        this.props.selectValues.radius = event.target.value
                    }} />
                </Col>
            </Row>
        )
    }
    city = ""
    title = ""
    loadbeaconComponent = () => {
        return (
            <Row>
                <Col>
                    <RSDropdownList data={this.props.data} value={this.props.selectValues.beaconname !== undefined && this.props.selectValues.beaconname !== "" ? this.props.selectValues.beaconname : "-- Select type --"} onChange={(event) => {
                        this.props.onChanged(event.target.value)
                        this.props.selectValues.beaconname = event.target.value
                    }} />
                </Col>
                <Col>
                    <RSDropdownList data={["In", "Out"]} defaultItem={this.props.selectValues.beaconvalue !== undefined && this.props.selectValues.beaconvalue !== "" ? this.props.selectValues.beaconvalue : "-- Select type --"} onChange={(event) => {
                        this.props.onChanged(event.target.value)
                        this.props.selectValues.beaconvalue = event.target.value
                    }} />
                </Col>
            </Row>
        )
    }

    loadCityComponent = (type) => {
        var tempValue = type === "forms" || type === "attributes" ? this.props.selectValues.formname : this.props.selectValues.city
        var tempValue1 = type === "forms" || type === "attributes" ? this.props.selectValues.formvalue : this.props.selectValues.area
        return (
            <Row>
                <Col>
                    <RSDropdownList data={this.props.data.city} defaultItem={tempValue !== undefined && tempValue !== "" ? tempValue : "-- Select type --"} onChange={(event) => {
                        this.city = ""
                        this.city = event.target.value;
                        this.title = ""
                        this.props.selectValues.city = event.target.value
                        this.props.selectValues.area = ''
                        if (type === "forms" || type === "attributes") {
                            this.props.selectValues.formname = event.target.value
                        }

                        this.props.onChanged(event.target.value)
                        this.forceUpdate()
                    }} />
                </Col>
                <Col>
                    <RSDropdownList data={this.props.data[tempValue]} value={tempValue1 !== undefined && tempValue1 !== "" ? tempValue1 : "-- Select --"} onChange={(event) => {
                        this.title = event.target.value
                        this.props.onChanged(event.target.value)
                        this.props.selectValues.area = event.target.value
                        if (type === "forms" || type === "attributes") {
                            this.props.selectValues.formvalue = event.target.value
                        }
                        this.forceUpdate()
                    }} />
                </Col>
            </Row>
        )
    }

    loadFormComponent = (type) => {
        var tempValue = type === "forms" || type === "attributes" ? this.props.selectValues.formname : this.props.selectValues.city
        var tempValue1 = type === "forms" || type === "attributes" ? this.props.selectValues.formvalue : this.props.selectValues.area
        return (
            <Row>
                <Col>
                    <RSDropdownList data={this.props.data.forms} defaultItem={tempValue !== undefined && tempValue !== "" ? tempValue : "-- Select type --"} onChange={(event) => {
                        this.city = ""
                        this.city = event.target.value;
                        this.title = ""
                        this.props.selectValues.city = event.target.value
                        if (type === "forms" || type === "attributes") {
                            this.props.selectValues.formname = event.target.value
                        }

                        this.props.onChanged(event.target.value)
                        this.forceUpdate()
                    }} />
                </Col>
                <Col>
                    <RSDropdownList data={this.props.data[tempValue]} value={tempValue1 !== undefined && tempValue1 !== "" ? tempValue1 : "-- Select --"} onChange={(event) => {
                        this.title = event.target.value
                        this.props.onChanged(event.target.value)
                        this.props.selectValues.area = event.target.value
                        if (type === "forms" || type === "attributes") {
                            this.props.selectValues.formvalue = event.target.value
                        }
                        this.forceUpdate()
                    }} />
                </Col>
            </Row>
        )
    }

    loadAttributesComponent = (type) => {
        var tempValue = this.props.selectValues.attributesname
        var tempValue1 = this.props.selectValues.attributesvalue
        return (
            <Row>
                <Col>
                    <RSDropdownList data={this.props.data.attributes} value={tempValue !== undefined && tempValue !== "" ? tempValue : "-- Select type --"} onChange={(event) => {
                        this.props.selectValues.attributesname = event.target.value
                        this.props.selectValues.attributesvalue = ""
                        this.props.onChanged(event.target.value)
                        this.forceUpdate()
                    }} />
                </Col>
                <Col>
                    <RSDropdownList data={this.props.data[tempValue]} value={tempValue1 !== undefined && tempValue1 !== "" ? tempValue1 : "-- Select --"} onChange={(event) => {
                        this.props.selectValues.attributesvalue = event.target.value
                        this.forceUpdate()
                    }} />
                </Col>
            </Row>
        )
    }

    loadCompareComponent = props => {
        return (
            <Row>
                <Col md={12} className="d-flex">
                    <IconDropdown
                        data={props.type === "ad" ? [
                            "icon-plus-small icons-sm",
                            "icon-minus-small icons-sm",
                            "icon-lesser-than-small icons-sm",
                            "icon-greater-than-small icons-sm"
                        ] : [
                            "icon-lesser-than-small icons-sm",
                            "icon-in-between-small icons-sm",
                            "icon-greater-than-small icons-sm",
                            "icon-equal-to-small icons-sm",
                            "icon-not-equal-to-small icons-sm"]}
                        defaultItem={props.type === "ad" ? "icon-plus-small icons-sm" : "icon-lesser-than-small icons-sm"}
                        customAlignRight={true}
                        className="symbol-dropdown"
                        onSelect={(e) => {
                            this.props.selectValues.compareValue = e
                            this.forceUpdate()
                        }}
                    />
                    <RSInput2
                        ph="-- Enter the no of days --"
                        val={this.props.selectValues.value}
                        keyboardType='number'
                        max={2}
                        min={2}
                        cb={(text) => {
                            //this.props.value = text
                            this.props.selectValues.value = text
                            this.props.onChanged(text)
                        }}
                    />
                </Col>
            </Row>
        )
    }

    render() {
        var renderComponent
        switch (this.props.type.toLowerCase()) {
            case "dropdown":
                renderComponent = <RSDropdownList data={this.props.data} value={this.props.selectValues.value !== "" ? this.props.selectValues.value : '-- Select type --'} onChange={(event) => {
                    this.props.selectValues.value = event.target.value

                    this.props.onChanged(event.target.value)
                }
                } />
                break;
            case "ad":
                renderComponent = <this.loadCompareComponent type={"ad"} />
                break;
            case "compare":
                renderComponent = <this.loadCompareComponent type={"compare"} />
                break;
            case "multiselect":
                renderComponent = <div className="chips-input-container"><div className="chips-input-box"><MultiSelect placeholder="-- Select --" data={filterTwoStringArray(this.props.data, convertStringToArray(this.props.selectValues.value))} value={convertStringToArray(this.props.selectValues.value)} onChange={(e) => {
                    this.props.selectValues.value = e.target.value.toString()
                    this.props.onChanged(e.target.value)
                }}
                    allowCustom={false}
                /></div></div>
                break
            case "latlong":
                renderComponent = this.latLongComponent()
                break;
            case "beacon":
                renderComponent = this.loadbeaconComponent()
                break;
            case "cityarea":
                renderComponent = this.loadCityComponent(this.props.type)
                break;
            case "forms":
                renderComponent = this.loadFormComponent(this.props.type)
                break
            case "attributes":
                renderComponent = this.loadAttributesComponent(this.props.type)
                break

            default:
                renderComponent = <RSInput2 ph="Enter type" val={this.props.selectValues.value} cb={(text) => {
                    //this.props.value = text
                    this.props.selectValues.value = text
                    this.props.onChanged(text)
                }} />
                break;
        }
        return renderComponent
    }
}


class DLC1 extends Component {
    state = {
        matchtype: '',
        anyvalue: '',
        disabled: this.props.disabled,
        errorMessage: null,
        showPopUp: true,
        treeUpdate: 1
    }
    componentDidMount() {
        this.setState({ rule: this.props.data.rule })
    }
    componentDidUpdate(oldProps) {
        if (this.props !== oldProps) {
            this.setState({ disabled: this.props.disabled })
        }
    }

    handleClose = () => {
        this.setState({ errorMessage: null, showPopUp: false });
    }

    getdata = () => {
        let array = loadJson[this.props.data.trigger.toLowerCase()].data
        let loadArr = []
        array.map(litem => {
            let data = this.props.data.rule.filter(ritem => litem.rulename === ritem.rulename)
            if (data.length === 0) {
                loadArr.push(litem)
            }
        })
        // let array1 = array.map(ritem => this.props.data.rule.filter(item => item.rulename.toLowerCase() !== ritem.rulename.toLowerCase()))
        return loadArr
    }
    getTriggerdata = () => {
        let array = this.props.array.map(triggerItem => loadJson.trigger.filter(lItem => lItem !== triggerItem.trigger))
        // let array1 = loadJson.trigger.map(triggerItem => this.props.array.filter(lItem => lItem.trigger !==  triggerItem))
        return array[0]
    }

    createRule = () => {
        this.props.data.values = [{
            placeholder: {
                text: "-- Type --",
                id: 0
            },
            ismandatory: false
        }]
        this.props.data.rule = []
        var selectItems = {
            rulename: "-- Select rule --",
            id: 0,
            ismandatory: false,
            type: "",
            value: ''
        }

        this.props.data.source = ''

        this.props.data.rule.push(selectItems)
        let temp = [...this.state.rule]
        temp.push(selectItems)
        this.setState({ rule: temp })
        this.props.triggerChanged(true);
        this.forceUpdate()
    }

    reset = () => {
        this.props.data.source = ''
        this.props.data.trigger = ''
        this.props.data.rule = []
        this.forceUpdate()
    }

    render() {
        const className = `portlet-box-theme  border-top-default border-dashed padding15 position-relative marginB25 ${this.state.disabled ? 'bgWhite click-off' : 'bgWhite'}`
        return (
            <Container className={className}>
                {this.props.index !== 0 ? <div className="rule-close-icon"><i className="icon-close-small icons-md blue cursor-pointer" onClick={() => this.props.closeBtnClicked()} /></div> : null}

                {this.state.errorMessage &&
                    <Modal show={this.state.showPopUp} centered onHide={() => this.handleClose()}>
                        <div className="d-flex align-items-center justify-content-between">
                            <label>{this.state.errorMessage}</label>
                            <i className="icon-close-small blue cp p-close" onClick={() => this.handleClose()}></i>
                        </div>
                    </Modal>
                }

                <Row>
                    <Col>
                        <h4>Rule group {this.props.data.ruleGroupName}</h4>
                    </Col>
                    <Col className="text-end">
                        <h4>{this.props.data.trigger !== "" ? this.props.data.auidence : ""}</h4>
                    </Col>
                </Row>

                {/* Second Row */}
                <Row className="marginY30 paddingL15">
                    <Col md={2}><label className="label-row">Match type</label></Col>
                    <Col md={2}>
                        <RSRadio lbl="All" name={"matchtype" + this.props.index} labelId={"all" + this.props.index} checked={this.props.data.matchtype.toLowerCase() === 'all' ? true : false}
                            cb={(event) => {
                                this.props.data.matchtype = event.target.value
                                this.props.data.matchCount = ""
                                this.props.data.rule.map(item => {
                                    item.ismandatory = false;
                                })
                                this.forceUpdate()
                            }}
                        />
                        <RSRadio lbl="Any" name={"matchtype" + this.props.index} labelId={"any" + this.props.index} className="ml50" checked={this.props.data.matchtype.toLowerCase() === 'any' ? true : false}
                            cb={(event) => {
                                this.props.data.matchtype = event.target.value
                                this.props.data.matchCount = "1"
                                this.props.data.rule.map(item => {
                                    item.ismandatory = false;
                                })

                                this.forceUpdate()
                            }}
                        />
                    </Col>
                    {
                        this.props.data.matchtype.toLowerCase() === "any" ?
                            <Col md={2}>
                                <RSDropdownList
                                    data={[1, 2, 3, 4]}
                                    value={this.props.data.matchCount}
                                    customAlignRight={true}
                                    className="mb0 match-type-input"
                                    onChange={(e) => {
                                        this.props.data.matchCount = e.target.value
                                        this.setState({ treeUpdate: this.state.treeUpdate + 1 })
                                    }}
                                />
                            </Col>
                            : null
                    }
                </Row>

                {/* Third Row */}
                <Row className="paddingL15">
                    <Col md={2}><label className="label-row">Select trigger source</label></Col>

                    <Col md={3} className={this.props.data.trigger.length > 0 && 'click-off'}>
                        <RSDropdownList data={this.getTriggerdata()} value={this.props.data.trigger !== "" ? this.props.data.trigger : "-- Source -- "} onChange={(event) => {
                            this.props.data.trigger = event.target.value;
                            if (this.props.data.trigger.toLowerCase().includes('web push') === true || this.props.data.trigger === "Mobile") {
                                this.forceUpdate()
                            } else {
                                this.createRule()
                            }


                        }} />
                    </Col>

                    {this.props.data.trigger.toLowerCase().includes('web push') || this.props.data.trigger === "Mobile"
                        ? <Col md={5} className={this.props.data.source.length > 0 && 'click-off'}>
                            <RSDropdownList data={loadJson[this.props.data.trigger.toLowerCase()].values} value={this.props.data.source !== "" ? this.props.data.source : "-- Select " + this.props.data.trigger.toLowerCase() + " -- "} onChange={(event) => {
                                this.createRule()
                                this.props.data.source = event.target.value
                                this.forceUpdate()
                            }} />
                        </Col>
                        : null}

                    {this.props.data.trigger !== "" ?
                        <Col md={1} className="p0">
                            <i className="icon-sd-refresh-medium icons-md blue cp posr t7" onClick={() => {
                                this.reset()
                            }} />
                        </Col>
                        : null}
                </Row>

                {/* FourthRow */}
                {this.props.data.trigger !== "" ?
                    <>
                        {this.props.data.rule.map((item, index) => {

                            return (
                                <Row className="paddingL15" key={index + ''}>
                                    <Col md={{ span: 3, offset: 2 }}>
                                        <RSDropdownList
                                            data={this.getdata()}
                                            dataItemKey="id"
                                            value={item}
                                            textField="rulename"
                                            onChange={(event) => {

                                                var dropdownValue = event.target.value
                                                item.type = dropdownValue.type
                                                item.rulename = dropdownValue.rulename
                                                let tempValueArray = []
                                                if (isEmpty(dropdownValue.value)) {
                                                    tempValueArray = [1, 2, 3, 4, 5].map(item => {
                                                        let text = dropdownValue.rulename + item.toString()
                                                        return tempValueArray.push(text)
                                                    })
                                                    item.inputstringvalue = tempValueArray
                                                    item.inputArray = tempValueArray
                                                } else {
                                                    item.inputstringvalue = dropdownValue.value
                                                    item.inputArray = dropdownValue.value
                                                }

                                                item.text = dropdownValue.text
                                                item.id = dropdownValue.id
                                                item.value = ''
                                                item.compareValue = dropdownValue.type === 'ad' ? "+" : ""
                                                this.props.data.rule[index] = item

                                                this.forceUpdate()
                                            }} />
                                    </Col>
                                    <Col md={5}>
                                        <RenderComponent type={item.type} value={item.inputstringvalue} data={item.inputArray} selectValues={this.props.data.rule[index]} onChanged={(changedValue) => {
                                            item.inputstringvalue = changedValue
                                            if (item.type === "cityarea") {
                                            }

                                            this.forceUpdate()
                                        }} />
                                        <div className="input-outer-set">
                                            <i className={`icons-md blue cursor-pointer d-block ${index === 0 ? `${this.props.data.rule.length < 5 ? "icon-sd-circle-plus-fill-edge-medium " : "icon-sd-circle-plus-fill-edge-medium click-off "}` : "icon-sd-circle-minus-fill-edge-medium red"}`}
                                                onClick={() => {
                                                    if (index === 0 && this.props.data.rule.length < 5) {
                                                        var status = false;
                                                        var status = ruleValidation(this.props.data.trigger, this.props.data.rule)
                                                        if (status) {
                                                            this.props.rulesAdded(1)
                                                            var selectItems = {
                                                                rulename: "-- Select rule --",
                                                                ismandatory: false,
                                                                type: "",
                                                                value: '',

                                                            }
                                                            this.props.data.rule.push(selectItems)
                                                            this.forceUpdate()
                                                        } else {
                                                            this.setState({ errorMessage: "Please select valid rules & value", showPopUp: true })
                                                        }

                                                    } else {
                                                        var index1 = this.props.data.rule.indexOf(item)
                                                        var tempSelectedList = [...this.props.data.rule]
                                                        tempSelectedList.splice(index1, 1)
                                                        this.props.data.rule = tempSelectedList
                                                        this.props.rulesAdded(0)
                                                        this.forceUpdate()
                                                    }
                                                }}>
                                            </i>
                                            {this.props.data.matchtype.toLowerCase() !== 'all' &&
                                                <RSPTooltip text="Set mandatory" position="top">
                                                    <i className={item.ismandatory ? "icon-asterisk-mini red icon-sm mand-field cursor-pointer" : "icon-asterisk-mini icon-sm mand-field cursor-pointer"} onClick={() => {
                                                        if (this.props.data.matchCount !== this.props.data.rule.filter(obj => obj.ismandatory === true).length) {
                                                            item.ismandatory = !item.ismandatory
                                                            this.props.data.rule[index].ismandatory = item.ismandatory
                                                            this.forceUpdate()
                                                        } else if (item.ismandatory) {
                                                            item.ismandatory = !item.ismandatory
                                                            this.props.data.rule[index].ismandatory = item.ismandatory
                                                            this.forceUpdate()
                                                        }

                                                    }}></i>
                                                </RSPTooltip>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })}
                    </>
                    : null}

            </Container>
        )
    }
}
