import React, { Component } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSRadio } from "Components/RSInputs";
import { Col, Modal, Row } from "react-bootstrap";
// import ApprovalPopup from "./ApprovalPopup";
import { RSPTooltip } from "Components/Tooltip";
import { isEmpty } from "Helper/Utils/Utils";
import { APPROVAL_MAIL } from "Helper/Constants/validationMessage";

class RequestApproval extends Component {
  state = {
    // values: [
    //   {
    //     placeholder: {
    //       text: "-- Select --",
    //       id: 0,
    //     },
    //     ismandatory: false,
    //     selectedValue: "",
    //     errorMessage: null
    //   },
    // ],
    values: this.props.approvalList,
    approverList: [
      "William(swil****@vi**onb**k.com",
      "Peter(rpe***r@v**ionb***.c**m)",
      "Catherine(cath***@v**onb****.com",
    ],
    isModalOpen: false,
    matchType: this.props.approvalSettings.approvalMatchType,
    anyCount: this.props.approvalSettings.approvalMatchCount,
    followHierarchyChecked: this.props.approvalSettings.isFollowHierarchyEnabled,
  };
  getData = () => {
    var list = []
    list = this.state.approverList.map(litem => {
      let data = this.state.values.filter(ritem => ritem.approverName === litem)
      if (data.length === 0) {
        return list.push(litem)
      }
    })

    // let array1 = this.state.approverList.map(litem => this.state.values.filter(ritem => litem !== ritem.approverName ))
    return list
  }
  render() {
    return (
      <div>
        <Row className="custom-approval">
          <Col md={3}>
            <label className="label-row">Send approval request to</label>
          </Col>
          <Col md={8}>
            {this.props.approvalList.map((item, index) => {
              return (
                <Row className="marginB15" key={index}>
                  <Col md={12}>
                    <div className="position-relative">
                      {item.errorMessage && (
                        <div className="validation-message">
                          {item.errorMessage}
                        </div>
                      )}
                      <RSDropdownList
                        className="marginB10"
                        data={this.getData()}
                        value={
                          item.approverName !== ""
                            ? item.approverName
                            : "-- Select --"
                        }
                        onChange={(event) => {
                          let errMessage = event.target.value.length > 0 ? null : APPROVAL_MAIL;
                          item.errorMessage = errMessage;
                          item.approverName = event.target.value;
                          this.props.approvalList[index].approverName = event.target.value;
                          this.props.approvalList[index].ismandatory = false;
                          this.forceUpdate();
                        }}
                      />
                    </div>

                    <div className="input-outer-set">
                      <i
                        className={`icons-md blue cursor-pointer d-block ${index === 0
                          ? `${this.state.values.length < 3
                            ? "icon-sd-circle-plus-fill-edge-medium "
                            : "icon-sd-circle-plus-fill-edge-medium click-off "
                          }`
                          : "icon-sd-circle-minus-fill-edge-medium red"
                          }`}
                        onClick={() => {
                          if (index === 0 && this.state.values.length < 3) {
                            let enable = true;
                            this.state.values.forEach(value => {
                              if (isEmpty(value.approverName)) {
                                enable = false;
                                value.errorMessage = APPROVAL_MAIL;
                                this.forceUpdate();
                                return;
                              } else {
                                value.errorMessage = "";
                                enable = true;
                              }
                            });
                            if (enable) {
                              //if (index === 0 && this.state.values.length < 3) {
                              // this.state.values.push({
                              //   placeholder: {
                              //     text: "-- Select --",
                              //     id: 0,
                              //   },
                              //   selectedValue: "",
                              // });


                              this.props.approvalList.push({
                                approverName: "",
                                ismandatory: false
                              })
                              // let temp = [...this.state.values]
                              // temp.push({
                              //   approverName:"",
                              //   ismandatory:false
                              // })
                              // this.setState({values:temp})
                              this.forceUpdate();
                              //} 

                            }
                          }
                          else {
                            var index1 = this.state.values.indexOf(item);
                            var tempList = [...this.state.values];
                            tempList.splice(index1, 1);
                            this.setState({ values: tempList });
                            this.props.approvalList.splice(index1, 1);
                            this.forceUpdate();
                          }

                        }}
                      ></i>
                      {this.state.matchType !== "" && this.state.matchType.toLowerCase() !== 'all' &&
                        <RSPTooltip text="Set mandatory" position="top">
                          <i className={item.ismandatory ? "icon-asterisk-mini red icon-sm mand-field cursor-pointer" : "icon-asterisk-mini icon-sm mand-field cursor-pointer"}
                            onClick={() => {
                              if (this.state.anyCount !== this.state.values.filter(obj => obj.ismandatory === true).length) {
                                item.ismandatory = !item.ismandatory;
                                this.state.values[index].ismandatory = item.ismandatory;
                                this.props.approvalList[index].ismandatory = item.ismandatory;
                                this.forceUpdate()
                              } else if (item.ismandatory) {
                                item.ismandatory = !item.ismandatory;
                                this.state.values[index].ismandatory = item.ismandatory;
                                this.props.approvalList[index].ismandatory = item.ismandatory;
                                this.forceUpdate()
                              }
                            }}></i>
                        </RSPTooltip>}
                    </div>
                  </Col>
                </Row>
              );
            })}

            <div className="input-below-icon-set justify-content-end mt-20">
              {<RSPTooltip position="top" text="Approval settings"><i className={"icon-settings-small icons-md blue cursor-pointer mr0"} onClick={() => {
                this.setState({
                  isModalOpen: !this.state.isModalOpen,
                });
                //this.props.clickModelShow(true)
              }} ></i></RSPTooltip>}
            </div>
          </Col>
        </Row>
        <Modal
          backdrop="static"
          keyboard={false}
          onHide={() => { this.setState({ isModalOpen: !this.state.isModalOpen }) }}
          show={this.state.isModalOpen} centered size="lg">
          <Modal.Header closeButton>
            <h2>Approval settings</h2>
          </Modal.Header>
          <Modal.Body>

            {/* <ApprovalPopup /> */}
            <Row className="marginY30 paddingL15">
              <Col md={3}>
                <label className="label-row">Get approval from</label>
              </Col>
              <Col md={9}>
                <div className="match-type-input-wrapper">
                  <div className="match-type-input">
                    <RSRadio
                      lbl="All"
                      name="rmatchtype"
                      labelId="rall"
                      checked={this.state.matchType.toLowerCase() === "all"}
                      cb={(event) => {
                        this.setState({ matchType: event.target.value });
                        this.setState({ anyCount: 0 });

                        this.props.approvalSettings.approvalMatchType = "All";
                        this.props.approvalSettings.approvalMatchCount = 0
                      }}
                    />
                  </div>
                  <div className="match-type-input">
                    <RSRadio
                      lbl="Any"
                      name="rmatchtype"
                      labelId="rany"
                      checked={this.state.matchType.toLowerCase() === "any"}
                      cb={(event) => {
                        this.setState({ matchType: event.target.value });
                        this.setState({ anyCount: 1 });
                        this.props.approvalSettings.approvalMatchType = "Any";
                        this.props.approvalSettings.approvalMatchCount = 1;
                      }}
                    />
                  </div>
                  {this.state.matchType.toLowerCase() === "any" ? (
                    <div className="match-type-input">
                      {/* <RSInput2
                    cls="marginB0"
                    val={this.state.anyCount}
                    cb={(value) => {
                      this.setState({ anyCount: value });
                    }}
                  /> */}
                      <RSDropdownList
                        data={[1, 2]}
                        value={this.state.anyCount}
                        customAlignRight={true}
                        className="mb0"
                        onChange={(e) => {
                          this.setState({ anyCount: e.target.value });
                          this.props.approvalSettings.approvalMatchCount = e.target.value;
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </Col>

            </Row>
            <Row className="marginY30 paddingL15">
              <Col md={3}>
                <label className="label-row">Follow hierarchy</label>
              </Col>
              <Col md={2}>
                <Switch
                  onChange={() => {
                    this.setState({
                      followHierarchyChecked:
                        !this.state.followHierarchyChecked,
                    });
                    this.props.approvalSettings.isFollowHierarchyEnabled = !this.props.approvalSettings.isFollowHierarchyEnabled;
                  }}
                  checked={this.state.followHierarchyChecked}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <RSSecondaryBtn
              onClick={() => {
                this.setState({
                  isModalOpen: !this.state.isModalOpen
                });
              }}
            >
              Cancel
            </RSSecondaryBtn>
            <RSPrimaryBtn
              onClick={() => {
                this.setState({
                  isModalOpen: !this.state.isModalOpen
                });
              }}
            >
              Save
            </RSPrimaryBtn>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default RequestApproval;
