import React, { Component } from 'react';

import { Row, Container, Col } from "react-bootstrap";
import errorImg from 'Assets/Images/internet-cut_icon.svg'
import brandLogo from 'Assets/Images/marketing-star-logo.svg'
import poweredBy from 'Assets/Images/powered-by-new.svg'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    const today = new Date();
    const year = today.getFullYear();
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className='error-boundry-wrapper'>
            <div className='err-header'>
              <div className='err-logo'>
                <img src={brandLogo} alt="Logo" onClick={() => this.history.push('/')} />
              </div>
            </div>
            <Container>
              <Row className='error-boundry'>
                <Col md={6} className='err-left'>
                  <img
                    src={errorImg}
                    alt="No internet connection"
                    onClick={() => {
                      this.history.push(`/invoice`)
                    }}
                  />
                </Col>
                <Col md={6} className='err-right'>
                  <div className='err-seperator'></div>
                  <h1>SORRY <small>...</small></h1>
                  <h5>It's not you. It's us.</h5>
                  <p>We're experiencing an internal server error problem, <br /> Please try again later...</p>
                </Col>
              </Row>
            </Container>
            <div className='err-footer'>
              <div className='err-cloud'>
                <Container className='position-relative'>
                  <ul className="copyright">
                    <li>Copyright &copy; <span className="init-copyright">{year}</span></li>
                    <li onClick={() => window.open("https://marketingstar.io/privacy-policy.html", "_blank")}>Privacy policy</li>
                    <li onClick={() => window.open("https://marketingstar.io/terms-and-conditions.html", "_blank")}>Terms & Conditions</li>
                    <li onClick={() => window.open("https://marketingstar.io/help.html", "_blank")}>Help</li>
                  </ul>
                  <div className="powered-by">
                    <i className="icon-sd-poweredby-medium icons-md"></i>
                    <img src={poweredBy} alt={poweredBy} />
                  </div>
                </Container>
              </div>
              <ul className="footer-borders-container"> <li></li> <li></li> <li></li> <li></li> <li></li> </ul>
            </div>
          </div>
        </>
      )
    }

    return this.props.children;
  }
}
export default ErrorBoundary