import React, { useState } from "react";
import SMSCreation from "./SMSCreation";
import SMSList from "./SMSList";

const SMS = () => {

  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("add");
  const [array, setSender] = useState([]);
  const [clientSmsSettingId, setClientSmsSettingId] = useState("");
  const [clientSmsSenderId, setClientSmsSenderId] = useState("");
  const [inBoundId, setInBoundId] = useState("");

  const renderComponent = [
    <SMSList
      onChangeIndex={(index) => setIndex(index)}
      onChangeMode={(mode) => setMode(mode)}
      onCheck={(array) => setSender(array)}
      onPassClientId={(clientSmsSenderId) =>
        setClientSmsSenderId(clientSmsSenderId)
      }
      onPassInboundId={(inboundId) => setInBoundId(inboundId)}
      getClientSmsSettingId={(clientSmsSettingId) =>
        setClientSmsSettingId(clientSmsSettingId)
      }
    />,
    <SMSCreation
      onChangeIndex={(index) => setIndex(index)}
      mode={mode}
      array={array}
      clientSmsSettingId={clientSmsSettingId}
      clientSMSSenderId={clientSmsSenderId}
      inBoundId={inBoundId}
    />,
  ];
  
  return <>{renderComponent[index]}</>;
};
export default SMS;
