import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSCheckbox } from "Components/RSInputs";
import { RSPTooltip } from "Components/Tooltip";
import {
  PORT_NUMBER,
  SERVER_NAME,
  ENTER_VALID_PASSWORD,
  ENTER_VALID_EMAIL,
} from "Helper/Constants/validationMessage";
import { isEmpty, validateEmail, validatePassword } from "Helper/Utils/Utils";

const ServerSettings = (props) => {

  const [isShow, setIsShow] = useState(props.isOpen);
  const [credentialsArray, setCredentialsArray] = useState([
    { email: "", password: "" },
  ]);
  const [isSmtpConfiguration, setIsSmtpConfiguration] = useState(true);
  const [serverName, setServerName] = useState("");
  const [portNumber, setPortNumber] = useState("");
  const [errMsgServerName, setErrMsgServerName] = useState("");
  const [errMsgPortNumber, setErrMsgPortNumber] = useState("");
  const [errMessRow, setMessRow] = useState([
    {
      errMsgEmail: null,
      errMsgPassword: null,
    },
  ]);

  const validation = () => {
    if (!isSmtpConfiguration) {
      if (isEmpty(serverName)) {
        setErrMsgServerName(SERVER_NAME);
        return false;
      }
      if (isEmpty(portNumber)) {
        setErrMsgPortNumber(PORT_NUMBER);
        return false;
      }
    }
    return validateCredentials();
  };

  const validateCredentials = () => {
    for (let i = 0; i < credentialsArray.length; i++) {
      if (!validateEmail(credentialsArray[i].email)) {
        let errMsg = ENTER_VALID_EMAIL;
        let temp = [...errMessRow];
        temp[i].errMsgEmail = errMsg;
        setMessRow(temp);
        return false;
      }
      if (!validatePassword(credentialsArray[i].password)) {
        let errMsg = ENTER_VALID_PASSWORD;
        let temp = [...errMessRow];
        temp[i].errMsgPassword = errMsg;
        setMessRow(temp);
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={isShow}
      size="md"
      centered
      onHide={() => {
        setIsShow(!isShow);
        props.onChange(false);
      }}
    >
      <Modal.Header closeButton>
        <h2>SMTP server settings</h2>
      </Modal.Header>
      <Modal.Body>
        <RSCheckbox
          className="marginB20"
          lbl="Check to use the same SMTP configuration"
          checked={isSmtpConfiguration}
          cb={(status) => {
            setIsSmtpConfiguration(status);
          }}
        />
        {!isSmtpConfiguration && (
          <div>
            <Col>
              <RSInput2
                name="serverName"
                ph={"Server name/IP"}
                required={true}
                val={serverName}
                cb={(value) => {
                  setServerName(value);
                  let errMsg = value.length ? null : SERVER_NAME;
                  setErrMsgServerName(errMsg);
                }}
                ob={(value) => {
                  setServerName(value);
                  let errMsg = value.length ? null : SERVER_NAME;
                  setErrMsgServerName(errMsg);
                }}
                errorMessage={errMsgServerName}
              />
            </Col>
            <Col>
              <RSInput2
                name="portNumber"
                ph={"Port number"}
                val={portNumber}
                required={true}
                cb={(value) => {
                  setPortNumber(value);
                  let errMsg = value.length ? null : PORT_NUMBER;
                  setErrMsgPortNumber(errMsg);
                }}
                ob={(value) => {
                  setPortNumber(value);
                  let errMsg = value.length ? null : PORT_NUMBER;
                  setErrMsgPortNumber(errMsg);
                }}
                errorMessage={errMsgPortNumber}
              />
            </Col>
          </div>
        )}
        <Row>
          <div className="d-flex justify-content-between marginB20 align-items-center">
            <h3 className="marginB0">Credentials</h3>
            <RSPTooltip text="Add" position="top">
              <i
                className="icon-sd-circle-plus-fill-edge-medium icons-md blue float-end cp"
                onClick={() => {
                  if (validateCredentials()) {
                    if (credentialsArray.length < 5) {
                      let temp = [...credentialsArray];
                      temp.push({ email: "", password: "" });
                      setCredentialsArray(temp);
                      let tempError = [...errMessRow];
                      tempError.push({
                        errMsgEmail: null,
                        errMsgPassword: null,
                      });
                      setMessRow(tempError);
                    }
                  }
                }}
              ></i>
            </RSPTooltip>
          </div>
        </Row>
        {credentialsArray?.map((item, index) => {
          return (
            <div className={index ? "bgGrey-lighter pl20 pr35 posr" : ""}>
              {index > 0 && (
                <RSPTooltip text="Remove" position="top">
                  <i
                    className="icon-sd-close-mini icons-sm blue cp input-r-close"
                    onClick={() => {
                      let temp = [...credentialsArray];
                      temp.splice(index, 1);
                      setCredentialsArray(temp);
                    }}
                  />
                </RSPTooltip>
              )}
              <Row>
                <Col md={6}>
                  <RSInput2
                    cls="mt10 mb20"
                    name="email"
                    ph={"Enter email ID"}
                    val={item.email}
                    required={true}
                    cb={(value) => {
                      let temp = [...credentialsArray];
                      temp[index].email = value;
                      setCredentialsArray(temp);
                      item.email = value;
                      let errMsg = validateEmail(item.email)
                        ? null
                        : ENTER_VALID_EMAIL;
                      let tempError = [...errMessRow];
                      tempError[index].errMsgEmail = errMsg;
                      setMessRow(tempError);
                    }}
                    errorMessage={errMessRow[index].errMsgEmail}
                  />
                </Col>
                <Col md={6}>
                  <RSInput2
                    cls="mt10 mb20"
                    name="password"
                    ph={"Enter password"}
                    val={item.password}
                    required={true}
                    type="password"
                    cb={(value) => {
                      let temp = [...credentialsArray];
                      temp[index].password = value;
                      setCredentialsArray(temp);
                      item.password = value;
                      let errMsg = validatePassword(item.password)
                        ? null
                        : ENTER_VALID_PASSWORD;
                      let tempError = [...errMessRow];
                      tempError[index].errMsgPassword = errMsg;
                      setMessRow(tempError);
                    }}
                    errorMessage={errMessRow[index].errMsgPassword}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="mt20">
        <RSSecondaryBtn
          onClick={() => {
            props.onChange(false);
          }}
        >
          Cancel
        </RSSecondaryBtn>
        <RSPrimaryBtn
          onClick={() => {
            if (validation()) {
              props.onChange(false);
            }
          }}
        >
          Save
        </RSPrimaryBtn>
      </Modal.Footer>
    </Modal>
  );
};
export default ServerSettings;
