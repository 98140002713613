import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Container } from "react-bootstrap"
import authContext from 'Helper/StateHandler/auth-context';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import { keyContactInfoInitialState } from 'AppJsImport';

const ThanksSignup = props => {
    const history = useHistory()
    const context = useContext(authContext)
    useEffect(() => {

        setTimeout(() => {
            context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState)
            history.push('/login')
        }, 15000);

        // setTimeout(() => {
        //     history.push('/login')
        // }, 15000);
    })
    return (
        <LayoutPageWrapper>
            <Container>
                <div onClick={() => {
                    context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState);
                    history.push('/login');
                }}>
                    <RSBrandLogo />
                </div>
                <div className="portlet-box-theme thanks-theme-box-temp">
                    <div className="vertical-space text-center thanks-temp">
                        <p><i className="icon-sd-circle-thumbs-up-fill-large icons-xxl space-top green-medium"></i></p>
                        <h2 className="blue-text font-l"> Thanks for signing up! </h2>
                        <h5 className="line-height-md mt20 mb0 small-text fontR">
                            {"Your account has been registered and an activation mail has been sent to your registered email address."}<br />
                            {"Kindly click on the initiate button to activate your account."}<br />
                            If you do not receive the email, please email us at <span className="rs-link orange-medium"
                                onClick={() => {
                                    window.location.href = "mailto:support@marketingstar.io.";
                                    context.globalStateData.setSelectedDataStatus('default')
                                }
                                }>support@marketingstar.io</span><br />
                        </h5>
                    </div>
                </div>
            </Container>
        </LayoutPageWrapper>
    );
};


export default ThanksSignup;