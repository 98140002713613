import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { connectServer } from "Helper/Network/networkHandler";
import { DOWNLOAD_SUBSCRIPTION, GET_SUBSCRIBE_INVOICE_LIST } from "Helper/Constants/endPoint";
import { getAuthClientId } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import { dateFormat } from "Helper/Utils/Utils";
import SkeletonTable from "Components/Skeleton/SkeletonTable";
import { RSPTooltip } from "Components/Tooltip";
import { SuccessModal } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal";
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";

const initialSort = [
  {
    field: "invoiceNumber",
    dir: "asc",
  },
];

const settings = [
  {
    text: "",
    iconClass: "icon-print-medium icons-md blue cursor-pointer",
  },
  {
    text: "",
    iconClass: "icon-print-medium icons-md blue cursor-pointer",
  },
  {
    text: "",
    iconClass: "icon-print-medium icons-md blue cursor-pointer",
  },
];
class ActionCell extends React.Component {
  static contextType = authContext;
  state = {
    sort: initialSort,
    isShowSuccessModal: false,
    isShow: false,
  };
  downloadPdf = (arg) => {
    let params = {
      invoiceId: this.props.dataItem.invoiceId,
      // invoiceNo: this.props.dataItem.invoiceNo,
      docType: arg,
    };

    connectServer({
      path: DOWNLOAD_SUBSCRIPTION,
      params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (arg === 2) {
            const byteArray = "data:application/pdf;base64," + res.data;
            const a = document.createElement("a");
            a.href = byteArray;
            a.download = res?.fileName || `Subscription${new Date().getDate()}-${new Date().getMonth()}`;
            a.click();
          } else if (arg === 1) {
            let print = window.open("", "", "height=500, width=500");
            // let decode = atob(decode)
            print.document.write(res.htmlData); // here replate it data.encodedData print.document.write(decode);
            print.document.close();
            print.print();
          } else if (arg === 3) {
            this.setState({ isShowSuccessModal: true });
          }
        }
      },
      fail: (fail) => { },
    });
  };

  itemRender = (props) => {
  };

  render() {
    return (
      <>
        <td>
          <div className="table-action-btn">
            <RSPTooltip text="View" position="top">
              <i
                className="icon-sd-eye-medium icons-md blue cursor-pointer"
                onClick={() => {
                  this.props.history.push({
                    pathname: `${this.props.url}/invoice`,
                    state: {
                      invoiceId: this.props.dataItem.invoiceId,
                      type: "SubscriptionInvoice",
                    },
                  });
                }}
              ></i>
            </RSPTooltip>
            {/* <DropDownButton
                className="buttons-container-button k-ml-4"
                items={settings}
                iconClass={"icon-download-small icons-md blue cursor-pointer"}
                onItemClick={(prop) => {
                this.downloadPdf(prop.itemIndex + 1);
              }}
              /> */}
            <RSPTooltip text="Download" position="top" show={this.state.isShow}>
              <div
                onMouseEnter={() => { this.setState({ isShow: true }) }}
                onMouseLeave={() => { this.setState({ isShow: false }) }}
                onClick={(prop) => {
                  this.setState({ isShow: false })
                }}
              >
                <DropDownButton
                  className=""
                  iconClass="icon-sd-download-medium icons-md blue cursor-pointer"
                  onItemClick={(prop) => {
                    this.downloadPdf(prop.itemIndex + 1);
                    this.setState({ isShow: false })
                  }}
                >
                  <DropDownButtonItem
                    iconClass="icon-sd-printer-medium icons-md blue cursor-pointer"
                    text=""
                  />
                  <DropDownButtonItem
                    iconClass="icon-sd-pdf-medium icons-md blue cursor-pointer"
                    text=""
                    title="aaa"
                  />
                  <DropDownButtonItem
                    iconClass="icon-sd-email-medium icons-md blue cursor-pointer"
                    text=""
                  />
                </DropDownButton>
              </div>
            </RSPTooltip>
          </div>
        </td>
        {this.state.isShowSuccessModal && (
          <SuccessModal
            isOkButton={false}
            isOpen={this.state.isShowSuccessModal}
            title={"Success"}
            subTitle={"Invoice mailed successfully."}
            buttonClicked={(value) => {
              this.setState({ isShowSuccessModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const createDataState = (dataState, list) => {
  return {
    result: process(list.slice(0), dataState),
    dataState: dataState,
  };
};

class SubscriptionInvoice extends Component {
  initialDataState = {
    skip: 0,
    take: 5,
  };
  state = {
    result: null,
    dataState: createDataState(this.initialDataState, []).dataState,
    invoiceList: [],
    listError: false,
    totalCount: 0,
  };

  static contextType = authContext;

  dataStateChange = (event) => {
    const { skip, take } = event.dataState;
    const currentPage = Math.floor(skip / take + 1);
    this.setState({
      result: process(this.state.invoiceList, event.dataState),
      dataState: event.dataState,
    });
    let params = {
      clientId: getAuthClientId(),
      pageNumber: currentPage,
      itemsPerPage: take,
    };
    this.getDataFromServer(params);
  };

  CustomActionCell = (props) => {
    const { match, history } = this.props;
    return <ActionCell {...props} history={history} url={match.url} />;
  };
  componentDidMount = (props) => {
    let params = {
      pageNumber: 1,
      itemsPerPage: 5,
    };
    this.getDataFromServer(params);
  };

  getDataFromServer = (params) => {
    this.setState({ result: null, listError: false });
    connectServer({
      path: GET_SUBSCRIBE_INVOICE_LIST,
      params: params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status && res.data?.invoiceDetails?.length > 0) {
          let currentState = createDataState(
            {
              take: this.state.dataState?.take,
              skip: 0,
            },
            res.data.invoiceDetails
          );
          this.setState({
            result: currentState.result,
            // dataState: currentState.dataState,
            invoiceList: res.data.invoiceDetails,
            totalCount: res.data.totalRowCount,
          });
        } else {
          this.setState({ listError: true });
        }
      },
      fail: (err) => {
        this.setState({ listError: true });
      },
    });
  };

  render() {
    return (
      <>
        <div className="grid-truncate">
          {this.props.isAllow && this.state.result !== null ? (
            <Grid
              data={this.state.result}
              {...this.state.dataState}
              total={this.state.totalCount}
              onDataStateChange={this.dataStateChange}
              className="grid-pagenation-del grid-replaceable"
              scrollable={"none"}
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort,
                  result: orderBy(this.state.invoiceList, e.sort),
                });
              }}
              pageable={{
                //type: 'input',
                pageSizes: true,
                previousNext: true,
              }}
            >
              <GridColumn
                columnMenu={ColumnMenu}
                field="invoiceNo"
                title="Invoice number"
                filter="text"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="invoiceDateTime"
                cell={DateCell}
                title="Invoice date"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="salePrice"
                cell={TotalAmountCell}
                title="Amount (USD)"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="paymentMode"
                title="Payment mode"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="status"
                title="Status"
              />
              <GridColumn
                columnMenu={ColumnMenu}
                field="dueDate"
                cell={DateCell}
                title="Due date"
              />
              <GridColumn
                field="action"
                title="Action"
                cell={this.CustomActionCell}
              />
            </Grid>
          ) : (
            <SkeletonTable
              count={5}
              isError={!this.props.isAllow ? true : this.state.listError}
            />
          )}
        </div>
      </>
    );
  }
}
export default withRouter(SubscriptionInvoice);
const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};
const DateCell = (props) => {
  return <td>{dateFormat(props.dataItem[props.field])}</td>;
};

const TotalAmountCell = (props) => {
  return <td className="text-end">${props.dataItem[props.field]}</td>;
};
