import React, { Component } from "react"
import '@progress/kendo-react-intl'
import AssignUserRole from "./Components/AssignUserRole"
import UserDetails from "./Components/UserDetails"
import AddNewUser from "./Components/AddNewUser"
import { getAuthClientId } from "Helper/Utils/Utils"
import authContext from "Helper/StateHandler/auth-context"
import { GET_USER_LIST } from "Helper/Constants/endPoint"
import { connectServer } from "Helper/Network/networkHandler"

class AccountUsers extends Component {

    static contextType = authContext

    state = {
        index: -1,
        userData: null,
        usersList: []
    }

    componentDidMount = () => {
        this.getUserList()
    }

    getUserList = () => {
        const params = { "clientId": getAuthClientId() };
        connectServer({
            loading: this.context.globalStateData.setIsLoadingData,
            path: GET_USER_LIST,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res?.data?.length) {
                    this.setState({
                        usersList: res.data,
                        index: 1
                    })
                } else {
                    this.setState({ index: 0 })
                }
            },
            fail: err => { }
        })
    }

    updateIndex = (index, data) => {
        this.setState({ index: index })
    }

    AccountUsersComponents = [
        <UserDetails cb={index => this.updateIndex(index)} />,
        <AssignUserRole cb={index => this.props.setIndex(index)} />,
        <AddNewUser cb={(index, data) => this.updateIndex(index, data)} />,
    ]

    render() {
        return <>{this.AccountUsersComponents[this.state.index]} </>
    }
}
export default AccountUsers;
