import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts'
import { ch_chartColor, ch_legendColor, ch_chartTitle, ch_labelColor, ch_tooltipColor, ch_tooltipBgColor } from './ChartColors';
// import printAny from '../HighChartDashboard/PrintAny'
require('highcharts/highcharts-more.js')(Highcharts);
require("highcharts/modules/variable-pie.js")(Highcharts);
require('highcharts/modules/funnel.js')(Highcharts);
require('highcharts/modules/sankey.js')(Highcharts);
require("highcharts/modules/map")(Highcharts);

const NewCharts = props => {


  const [container, setContainer] = useState(React.createRef())

  useEffect(() => {
    // printAny("chart data", JSON.stringify(options))
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
    let newChart = Highcharts["chart"](
      container.current,
      Highcharts.merge(props.id === 10011 ? commonOptions1 : commonOptions, props.options),
    );

    if (props.image) {
      newChart.renderer.image(props.image, props.style.x, props.style.y, props.style.width, props.style.height).add();
    }

    return (() => {
      newChart.destroy()
      newChart = null
    })
  }, [props.options, container, props.width])

  const chartStyle = { width: props.width, height: props.height ?? '100%' }

  return (
    <>
      <div className={props.className ? props.className : null} style={chartStyle} ref={container}></div>
    </>
  )
}
export default NewCharts

// Font size
const chartSize = '17px';
const titleSize = '17px';
const labelSize = '15px';
const legendSize = '15px';
const tooltipSize = '15px';
const pieLabelSize = '20px';

// Font
const pieLabelFont = 'MuktaRegular';

const commonOptions = {
  colors: ['#26ADE0', '#90C830', '#F58332', '#DDB219', '#F05455', '#35A8AD', '#D1569E', '#9B5FAA', '#B8B6B7'],
  chart: {
    //marginRight: 5,
    //marginTop: 0,
    reflow: true,
    // backgroundColor: 'rgba(255, 255, 255, 0.1)',
    style: { fontFamily: 'MuktaRegular', fontWeight: 'normal', fontSize: chartSize, color: ch_chartColor }
  },
  title: { text: null },
  subtitle: { text: null },
  credits: { enabled: false },
  exporting: { enabled: false },
  xAxis: {
    useHTML: true,
    align: 'center',
    title: {
      style: { color: ch_chartTitle, fontSize: titleSize }
    },
    startOnTick: false,
    tickmarkplacement: "on",
    //min: 0.5,
    minPadding: 0,
    maxPadding: 0,
    labels: {
      rotation: 0,
      style: { color: ch_labelColor, fontSize: labelSize },
    },
    lineWidth: 1,
    tickLength: 0
  },
  yAxis: {
    title: {
      style: { color: ch_chartTitle, fontSize: titleSize }
    },
    labels: {
      style: { color: ch_labelColor, fontSize: labelSize }
    },
    // tickInterval: 100,
    lineWidth: 1,
    gridLineDashStyle: 'dash'
  },
  series: [{
    //backgroundColor: "rgba(0 ,0, 0, 1)",
  }],

  // Plot options
  plotOptions: {

    series: {
      fillOpacity: 0.1,
      states: { hover: { enabled: true } }
    },

    // packedbubble chart options
    // packedbubble: {
    //   chart: {
    //     height: 350
    //   },
    // },
    // Pie chart options
    pie: {
      allowPointSelect: false,
      cursor: 'default',
      dataLabels: {
        enabled: true,
        //useHTML: true,
        connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
        crookDistance: '100%',
        //alignTo: '', // connectors, plotEdges
        formatter: function () {
          return (this.y + "<span class='pieDataLabelPercentage'>%</span>");
        },
        style: {
          color: ch_chartColor,
          fontSize: pieLabelSize,
          fontFamily: pieLabelFont,
          fontWeight: 'normal',
          textShadow: 'none',
          textOutline: false
        },
        softConnector: false,
      },
      states: {
        inactive: { opacity: 1 },
        hover: { enabled: false }
      },
      showInLegend: false,
    },
    // Area chart options
    area: {
      stacking: 'normal',
      lineWidth: 1,
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: 'white',
        radius: 3,
        lineColor: "#f68936",
        color: '#f68936'
      },
      legend: {
        useHTML: true,
        radius: 2,
      }
    },
    // Bar (Column) chart options
    column: {
      stacking: 'normal',
      pointWidth: 15,
      pointPadding: 0,
      //groupPadding: 0.8,
      borderWidth: 0,
      dataLabels: {
        enabled: false,
        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
        style: {
          textShadow: 'none',
          textOutline: false
        }
      }
    },
    // Line chart options
    line: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
        style: {
          textShadow: 'none',
          textOutline: false
        }
      }
    },
    // pyramid
    pyramid: {
      states: {
        inactive: { opacity: 1 },
        hover: { enabled: false }
      },
    }

  },
  tooltip: {
    useHTML: true,
    shared: true,
    backgroundColor: ch_tooltipBgColor,
    borderWidth: 0,
    shadow: false,
    style: {
      color: ch_tooltipColor,
      cursor: 'default',
      fontSize: tooltipSize,
      whiteSpace: 'nowrap'
    }
  },
  legend: {
    y: 4, //10
    itemMarginTop: 0, // 15
    itemStyle: { fontWeight: 'normal', fontSize: legendSize, color: ch_legendColor, /*width: '60px', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"*/ },
    marker: { symbol: 'square', verticalAlign: 'middle', radius: '4', },
    symbolHeight: 9,
    symbolWidth: 9,
    symbolRadius: 0
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        chart: {
          height: 243
        },
        subtitle: {
          text: null
        },
        navigator: {
          enabled: false
        }
      }
    }]
  }
}

const commonOptions1 = {
  colors: ['#f68936', '#70ba47', '#33b5e6', '#fd8f40', '#e7ca60', '#40abaf', '#f6f7f8', '#e9e9eb'],
  chart: {
    //marginRight: 5,
    //marginTop: 0,
    reflow: true,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    style: { fontFamily: 'MuktaRegular', fontWeight: 'normal', fontSize: chartSize, color: ch_chartColor, }
  },
  title: { text: null },
  subtitle: { text: null },
  credits: { enabled: false },
  exporting: { enabled: false },
  xAxis: {
    useHTML: true,
    align: 'center',
    title: {
      style: { color: ch_chartTitle }
    },
    startOnTick: false,
    tickmarkplacement: "on",
    //min: 0.5,
    minPadding: 0,
    maxPadding: 0,
    labels: {
      rotation: 0,
      style: { color: ch_labelColor },
    },
    lineWidth: 1,
    tickLength: 0,
  },
  yAxis: {
    title: {
      style: { color: ch_chartTitle }
    },
    labels: {
      style: { color: ch_labelColor },
    },
    // tickInterval: 100,
    lineWidth: 1,
    gridLineDashStyle: 'dash',
  },
  series: [{
    //backgroundColor: "rgba(0 ,0, 0, 1)",
  }],

  // Plot options
  plotOptions: {

    series: {
      fillOpacity: 1,
      states: {
        inactive: { opacity: 1 },
        hover: { enabled: false }
      },
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        useHTML: true,
        distance: -30,
        formatter: function () {
          if (this.point.name === 'Email') {
            return '<div class="pitChartIcon pieIcon-email"><i class="icon-mail-xmedium icons-sm white"></i></div>';
          }
          if (this.point.name === 'SMS') {
            return '<div class="pitChartIcon pieIcon-mobile"><i class="icon-sd-email-large icons-sm white"></i></div>';
          }
          if (this.point.name === 'Mobile push') {
            return '<div class="pitChartIcon pieIcon-facebook"><i class="icon-mobile-push-notification-xmedium icons-sm white"></i></div>';
          }
          if (this.point.name === 'Web push') {
            return '<div class="pitChartIcon pieIcon-googleplus"><i class="icon-social-web-notification-xmedium icons-sm white"></i></div>';
          }
        },
        style: {
          color: '#424242',
          fontSize: '16px',
          fontFamily: 'MuktaRegular',
          fontWeight: 'normal',
          textShadow: 'none',
          textOutline: false
        },
        softConnector: false,
      },
      showInLegend: false
    },

  },
  tooltip: {
    useHTML: true,
    shared: true,
    backgroundColor: ch_tooltipBgColor,
    borderWidth: 0,
    shadow: false,
    style: { color: ch_tooltipColor }
  },
  legend: {
    y: 10,
    itemMarginTop: 15,
    itemStyle: { fontWeight: 'normal', fontSize: legendSize, color: ch_legendColor, /*width: '60px', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"*/ },
    marker: { symbol: 'square', verticalAlign: 'middle', radius: '4', },
    symbolHeight: 9,
    symbolWidth: 9,
    symbolRadius: 0
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        chart: {
          height: 243
        },
        subtitle: {
          text: null
        },
        navigator: {
          enabled: false
        }
      }
    }]
  }
}
