// REGISTRATION
export const MAX_LOGIN_OTP = 6
export const MAX_FIRST_NAME = 50
export const MAX_LAST_NAME = 50
export const MAX_EMAIL = 101
export const MAX_PHONE = 18
export const MAX_PASSWORD = 15
export const MAX_TITLE = 30
export const MAX_ANSWER = 2
export const MAX_COMPANY_NAME = 50
export const MAX_WEBSITE = 250
export const MAX_ADDRESS1 = 60
export const MAX_ADDRESS2 = 60
export const MAX_CITY = 40
export const MAX_ZIP = 18
export const MAX_COUNTRY = 50
export const MAX_INDUSTRY = 50

// PAYMENT
export const MAX_NUMBER_ON_CARD = 19
export const MAX_NAME_ON_CARD = 50

// SEARCH
export const MAX_CAMPAIGN_NAME = 50
export const MAX_CREATED_BY = 50

// AUDIENCE
export const MAX_LIST_NAME = 50
export const MAX_LAT = 50
export const MAX_LON = 50
export const MAX_DAY = 2
export const MAX_TYPE = 80
export const MAX_DYNAMIC_LIST_NAME = 80

// CAMPAIGN
export const MAX_AUDIENCE_COUNT = 150
export const MAX_VOLUME_PER_DAY = 150
export const MAX_BASE_VOLUME = 150
export const MAX_INCREMENTAL = 150

export const MAX_BUTTON_TEXT = 50
export const MAX_NEW_LINK = 150
export const MAX_EXPIRY = 150
export const MAX_FREQUENCY = 150
export const MAX_COUPON_CODE = 20


// //Campaign
// export const CAMPAIGN_NAME              = "Enter campaign name"
// export const CAMPAIGN_TYPE              = "Select campaign type"
// export const PRODUCT_TYPE               = "Select product type"
// export const PRIMARY_GOAL               = "Select primary goal"
// export const GOAL_PERCENTAGE            = "Enter percentage"
// export const GOAL_TYPE                  = "Select type"
// export const NATIVE_CHANNEL             = "Select native channel"
// export const ANALYTICS_TYPE             = "Select analytics type"
// export const SELECT_SOCIAL_MEDIA        = "Select social media"
// export const SELECT_PAIDADDS            = "Select paid ads"


// // SmartLinkCreation

// export const ENTER_WEBURL               = "Enter web URL"
// export const ENTER_MOBILE_ADAPTIVE_URL  = "Enter mobile adaptive URL"
// export const SELECT_KEYVALUE            = "Select key value"
// export const ENTER_VALUE                = "Enter value"
// export const SELECT_DEVICE_TYPE         = "Select device type"
// export const SELECT_APP_SCREEN          = "Select app screen"
// export const SELECT_APP_SUB_SCREEN      = "Select app sub screen"
// //OnAppBoarding

// export const APP_NAME = "Enter app name";
// export const MOBILE_PLATFORM = "Select mobile platform";
// export const STORE_URL_ANDROID = "Enter play store URL";
// export const STORE_URL_IOS = "Enter app store URL";
// export const NOTIFICATION_PROVIDER = "Choose notification provider";
// export const FCM_SENDER_ID = "Enter FCM sender ID";
// export const FCM_SERVER_KEY = "Enter FCM server key";
// export const LANGUAGE = "Chosse language";
// export const HYBRID_LANGUAGE = "Chosse hybrid language";
// export const CERTIFICATE = "Upload APNS certificate file";
// export const CERT_PASSWORD = "Enter CERT password";

// //AppAnalytics
// export const ANALYTICS_PLATFORM = "Select analytic platform";
// export const ACCOUNT_MAIL = "Enter mail account";
// export const APP_KEY = "Enter app key";
// export const APP_SECRET_ID = "Enter app secret id";

// //Mobile Notification
// export const MOBILE_APP = "Select mobile app";
// export const DELIVERY_TYPE = "Select delivery type";
// export const CLASSIFICATION = "Select inbox classification";
// export const LAYOUT = "Select layout";
// export const POSITION = "Select position";
// export const TITLE="Enter title";
// export const SUBTITLE="Enter subtitle";
// export const BODY="Enter message content";
// export const INTER_ACTIVITY_BUTTON1="Select first interactivity button";
// export const INTER_ACTIVITY_BUTTON2="Select second interactivity button";
// export const ALERT_SOUND="Select alert sound";
// export const EXPIRY_TYPE="Select expiry type";
// export const EXPIRY_VALUE="Enter time to expier";
// export const HASHTAG="Enter hashtag";
// export const SCHEDULE="Set schedule time";
// export const FREQUENCY_TYPE="Select frequency type";
// export const FREQUENCY_VALUE="Enter frequency value";

// //Web Notification
// export const SEND_PUSH_TO="Select send push to option";
// export const DOMAIN_SITE="Select domain";
// export const URL="Enter URL";
// export const APPROVAL_MAIL="Select approval mail id";

// //Custom Button
// export const BUTTON_NAME="Enter button name";
// export const BEHAVIOUR="Select click on behaviour";
// export const BEHAVIOUR_TYPE="Select type";
// export const PHONE_NUMBER="Enter phone number";
// export const SMARTLINK="Enter new link";

// //Image Picker
// export const IMAGE_URL = "Enter image URL";

// //Web Analytics
// export const WEB_ANALYTICS_PLATFORM = "Select analytics platform";
// export const DOMAIN_NAME = "Select domain";
// export const CONVERSION_ACTION = "Select conversion action";
// export const CONVERSION_URL = "Enter conversion URL";

// //Mobile Analytics
// export const CONVERSION_FORM = "Select conversion form";

