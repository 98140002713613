import React, { useState } from 'react';
import { DropDownButton } from '@progress/kendo-react-buttons';

export const KendoIconDropdown = props => {

  return (
    <DropDownButton
      className={props.className}
      items={props.data}
      icon={props.icon}
      popupSettings={{
        animate: true,
        popupClass: `ic-dropdown ${props.popupClass}`
      }}
      dir={props.dir ? props.dir : "ltr"}
      onItemClick={(e) => props.onItemClick && props.onItemClick(e)}
    />
  )
}