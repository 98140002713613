import {
  AddressCircleColor,
  AppleStore,
  AskFM,
  Behance,
  Blogger,
  Dribble,
  DropBox,
  FaceBook,
  Flickr,
  FourSquare,
  Globe,
  GoogleDrive,
  HangOuts,
  ICQ,
  Insta,
} from "Assets/Images/SocilaIcons";
import * as images from "Assets/Images";
import { UPLOAD_EMAIL_FOOTER } from "Helper/Constants/endPoint";
import context from "react-bootstrap/esm/AccordionContext";
import { connectServer } from "Helper/Network/networkHandler";

const showDropdownData = (totalArray, uniqueArray) => {
  let getArray = [...totalArray].filter((e, i) => {
    if (!uniqueArray.some((ee) => ee.id == e.id)) {
      return e;
    }
  });
  return getArray;
};

// function handleFilePicker(callback, value, meta) {
//   // Open your file picker dialog here
//   const input = document.createElement("input");
//   input.type = "file";
//   input.accept = "image/*";
//   input.onchange = () => {
//     const file = input.files[0];
//     // Use FileReader to read the file and get its data URL
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       // const base64Image = reader.result.split(',')[1];
//       console.log(reader.result,"reader.result");
//       // Pass the data URL back to TinyMCE using the callback function
//       callback(reader.result, {
//         alt: file.name,
//         title: file.name,
//       });
//     };
//   };
//   input.click();
//   let params = {
//     fileName: file.name,
//     fileByte:
//   };
//   connectServer({
//     path: UPLOAD_EMAIL_FOOTER,
//     params,
//     loading: context.globalStateData.setIsLoadingData,
//     sessionOut: context.globalStateData.setSessionOutData,
//     context: context,
//     ok: (res) => {
//       handleCancel();
//       onChangeIndex(0);
//       // getDatFromServer();
//     },
//     fail: (err) => {},
//   });

// }

function handleFilePicker(callback, value, meta) {
  // Open your file picker dialog here
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";
  input.onchange = () => {
    const file = input.files[0];
    // Use FileReader to read the file and get its data URL
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Pass the data URL back to TinyMCE using the callback function

      // Convert the file to bytes
      uploadImage(file, (response) => {
        callback(response, {
          alt: file.name,
          title: file.name,
        });
      });
    };
  };
  input.click();
}

function uploadImage(file, callback) {
  // Convert the file to bytes
  const readerBytes = new FileReader();
  readerBytes.onloadend = () => {
    const byteArray = new Uint8Array(readerBytes.result);
    // Convert the byte array to base64
    const base64String = btoa(String.fromCharCode.apply(null, byteArray));

    // Make your API call to upload the image
    let params = {
      fileName: file.name,
      fileByte: base64String,
    };

    connectServer({
      path: UPLOAD_EMAIL_FOOTER,
      params,
      context: context, // Assuming you have access to the context here
      ok: (res) => {
        // If the API call is successful, pass the image URL back to TinyMCE
        callback(res.data.filePath);
      },
      fail: (err) => {
        // Handle API call failure if needed
      },
    });
  };
  readerBytes.readAsArrayBuffer(file);
}

const clickableClass = "my-clickable-class";

const BODYCONFIG = {
  selector: ".textArea",
  menubar: false,
  inline: true,
  resize: false,
  plugins: ["link", "lists", "autolink", "image", "textcolor", "colorpicker"],
  toolbar: [
    " fontfamily fontsize | bold italic underline  | image link",
    "forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent",
  ],
  noneditable_noneditable_class: "mceNonEditable",
  noneditable_editable_class: "mceEditable",
  noneditable_regexp: /^[\s\t\r\n]*$/,
  noneditable_clickable_class: clickableClass,
  paste_data_images: false,
  content_style: `
              .${clickableClass} {
                cursor: pointer;
                color: #333;
                background-color: #f5f5f5;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
              }
              table {
      max-width: 100% !important;
      width: 100% !important; /* Ensure table takes full width */
    }
            `,
  setup: function (editor) {
    editor.on("click", function (e) {
      if (e.target.classList.contains("my-clickable-class")) {
        editor.setMode("design");
      }
    });
  },
  file_picker_types: "image",
  file_picker_callback: handleFilePicker,
  powerpaste_word_import: "clean",
  powerpaste_html_import: "clean",
};

const BODYCONFIGIMAGE = {
  menubar: false,
  inline: true,
  plugins: "",
  toolbar: "",
  //content_css: ' # mce-content-body { display: flex }',
  content_css: "",
  // file_picker_types: 'image',
  // content_style: '.my-editor .mce-content-body { display: flex !important; }',
  // file_picker_callback: handleFilePicker,
};

// const CONTENTTEXT = `
//    <div>
//    <span><a href="Forward%20to%20friends">Forward to friends </a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <a href="Update%20your%20profile">&nbsp;Update your profile</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<a href="Unsubscribe">Unsubscribe</a></span>
//    </div>`;

const CONTENTNAVIGATION = `
<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%">
    <tbody>
        <tr>
            <td width="300" align="center"><a href="[FORWARDFRIEND_URL]">Forward to friends</a></td>
            <td width="300" align="center"><a href="[UNSUBCRIPTION]">Unsubscribe</a></td>
        </tr>
    </tbody>
</table>
`;

const CONTENTTEXT = `
<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" >
    <tbody>
        <tr>
            <td align="left">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</td>
        </tr>
    </tbody>
</table>
`;

const CONTENTCOMMON = `
<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" >
    <tbody>
        <tr>
            <td width="300" align="right" valign="middle" style="padding:10px"><img src={${AppleStore}} border="0"></td>
            <td width="300" align="left" valign="middle" style="padding-left:10px;">
                <p>Vision bank</p>
                <p>350, 5th Avenue, 34th Floor, New York, USA</p>
            </td>
        </tr>
    </tbody>
</table>
`;

// const CONTENTCOMMON = `
// "<p style="text-align: center;"><img src="https://svn1.Icon_move.com/svn/ui-Icon_moveapp/rs50/banking/Images/logo-company.png" border="0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Company name</p>
// <p style="text-align: center;">350, 5th Avenue, 34th Floor, New York, USA</p>
// <p style="text-align: center;">Was this email forwarded to you?&nbsp;Sign up to receive your own copy.</p>
// <p style="text-align: center;">Copyright &copy;&nbsp;2023&nbsp;Company Name. All rights reserved.&nbsp;Privacy policy.</p>"`;

// const CONTENTRIGHT = `<p>this is the content for left <img src={${Icon_move}} /></p>`;
// const CONTENTLEFT = `<p> <img src={${Icon_move}} /> this is the content for left</p>`;

const CONTENTRIGHT = `
<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" >
    <tbody>
        <tr>
            <td width="300" align="left" valign="middle" style="padding-left:10px;">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>
            </td>
            <td width="300" align="left" valign="middle" style="padding:10px"><img src=${images.chooseImage} border="0" /></td>
        </tr>
    </tbody>
</table>
`;

const CONTENTLEFT = `
<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%">
    <tbody>
        <tr>
            <td width="300" align="left" valign="middle" style="padding:10px"><img src=${images.chooseImage} border="0" /></td>
            <td width="300" align="left" valign="middle" style="padding-left:10px;">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod</p>
            </td>
        </tr>
    </tbody>
</table>
`;

const ICONLIST = [
  {
    id: 4,
    content: "Text",
    //icon: icons.builder_text_large,
    elementImage: images.IconText,
  },

  {
    id: 2,
    content: "Left caption",
    //icon: icons.builder_left_caption_large,
    elementImage: images.IconLeft,
  },
  {
    id: 3,
    content: "Right caption",
    //icon: icons.builder_right_caption_large,
    elementImage: images.IconRight,
  },
  // {
  //   id: 5,
  //   content: "Social follow",
  //   //icon: icons.builder_social_share_large,
  //   elementImage: images.IconSocial,
  // },
];

export {
  showDropdownData,
  BODYCONFIG,
  CONTENTTEXT,
  CONTENTNAVIGATION,
  CONTENTRIGHT,
  CONTENTCOMMON,
  CONTENTLEFT,
  BODYCONFIGIMAGE,
  ICONLIST,
};
