// import { render } from 'node-sass';
import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import NewCharts from 'Components/Charts/NewCharts';
import { BootstrapDropdown } from "Components/BootstrapDropdown";
import Info from './Info';
import sdfEvents from 'Assets/Images/events-large.svg'
import ConsumptionChannel from './ConsumptionChannel';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_CONSUMPTION_STATUS } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { dateFormat, displayDateAndTime, getUserData, numberFormatter } from 'Helper/Utils/Utils';
import moment from 'moment';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import * as images from 'Assets/Images';
const Consumption = (props) => {

    let match = useRouteMatch();

    return <Switch>
        <Route exact path={`${match.url}/`}>
            <ConsumptionHome />
        </Route>
        <Route path={`${match.url}/info`}>
            <Info />
        </Route>
        <Route path={`${match.url}/consumption-channel`}>
            <ConsumptionChannel />
        </Route>
    </Switch >
}
export default Consumption;

const ConsumptionHome = props => {

    const context = useContext(authContext)
    const history = useHistory()
    const match = useRouteMatch()

    const [dropdownYear, setDropdownYear] = useState([...Array(3).keys()].map(numb => new Date().getFullYear() + 1 - numb))
    const [dropdownMonth, setDropdownMonth] = useState([...Array(3).keys()].map(numb => moment().subtract(numb, "month").format("MMMM")))
    const [selectedMonthItem, setSelectedMonthItem] = useState(dropdownMonth[0])
    const [selectedMonth, setSelectedMonth] = useState(1)
    const [selectedYearItem, setSelectedYearItem] = useState(new Date().getFullYear())
    const [consumptionList, setConsumptionList] = useState([]);
    const [consumptionListError, setConsumptionListError] = useState(false)
    const diff_months = (dt1, dt2, year) => {
        let rest = []
        if ((year - dt1.getFullYear()) === 0) {
            if (dt1.getMonth() > dt2.getMonth()) {
                rest = months.slice(dt1.getMonth(), 12)
            } else {
                rest = months.slice(dt1.getMonth(), dt2.getMonth() + 1)
            }
        } else {
            rest = months.slice(0, dt2.getMonth() + 1)
        }
        return rest;
    }
    const yearDiff = (createDate) => {
        return Math.abs(new Date().getFullYear() - createDate.getFullYear()) + 1;
    }
    useEffect(() => {
        let userData = getUserData()
        let diffYear = yearDiff(new Date(userData?.activationDate))
        const dropdownYear1 = [...Array(diffYear).keys()].map(numb => new Date().getFullYear() - numb)
        setDropdownYear(dropdownYear1)
        setSelectedYearItem(dropdownYear1[0])
        const rest = diff_months(new Date(userData?.activationDate), new Date(), selectedYearItem)
        setDropdownMonth(rest)
        setSelectedMonthItem(rest[rest.length - 1])
        setSelectedMonth(months.findIndex(mItem => mItem === rest[rest.length - 1]) + 1)
        getConsumptionList(selectedYearItem, months.findIndex(mItem => mItem === rest[rest.length - 1]) + 1)
    }, [])

    const getConsumptionList = (year, month) => {
        setConsumptionListError(false)
        setConsumptionList([])
        let params = {
            "year": year,
            "month": month
        }
        connectServer({
            path: GET_CONSUMPTION_STATUS,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status && res?.data?.channelConsumptionList?.length) {
                    let temp = [...res.data.channelConsumptionList]
                    temp.push({
                        "channelName": "Data provider",
                        "channelID": 5,
                        "count": res.data.dataProvider,
                        "lastUpdateDatetime": new Date().toString()
                    })
                    setConsumptionList(temp)
                } else {
                    setConsumptionListError(true)
                    setConsumptionList([])
                }
            },
            fail: err => {
                setConsumptionListError(true)
            }
        })
    }

    return (

        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="header-title d-flex justify-content-between">
                    <h1>Consumptions</h1>
                    <div className="d-flex align-center">
                        <div className={`mr20 ${dropdownMonth.length > 0 ? "" : "click-off"}`}>
                            <BootstrapDropdown
                                data={dropdownMonth}
                                defaultItem={selectedMonthItem}
                                alignRight
                                customAlignRight={true}
                                className="fs18-btn"
                                onSelect={item => {
                                    setSelectedMonthItem(item)
                                    setSelectedMonth(months.findIndex(mItem => mItem === item) + 1)
                                    getConsumptionList(selectedYearItem, months.findIndex(mItem => mItem === item) + 1)
                                }}
                            />
                        </div>
                        <div>
                            <BootstrapDropdown
                                data={dropdownYear}
                                defaultItem={selectedYearItem}
                                alignRight
                                customAlignRight={true}
                                className="fs18-btn"
                                onSelect={item => {
                                    const rest = diff_months(new Date(getUserData()?.activationDate), new Date(), item)
                                    setDropdownMonth(rest)
                                    setSelectedMonthItem(rest[0])
                                    setSelectedMonth(months.findIndex(mItem => mItem === rest[0]) + 1)
                                    setSelectedYearItem(item)
                                    getConsumptionList(item, months.findIndex(mItem => mItem === rest[0]) + 1)
                                }}
                            />
                        </div>
                        <div className="back-text marginL15" onClick={() => { history.push(`/preferences`) }}>
                            <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
                            Back
                        </div>
                    </div>
                </div>

                <hr className="blue mb30" />

                <Row className="d-none">
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Data transfer</h4>
                            </div>
                            <div className="portlet-body">
                                <div className="portlet-chart gauge-chart"><NewCharts options={recentCampaignsChart1} /></div>
                            </div>
                            <div className="portlet-info-text">
                                <span>For the month of January 2022</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="portlet-container portlet-md">
                            <div className="portlet-header">
                                <h4>Data storage</h4>
                            </div>
                            <div className="portlet-body">
                                <div className="portlet-chart gauge-chart-del"><div className="mx-auto" id="chartdiv" style={{ width: "273px", height: "300px" }}></div></div>
                            </div>
                            <div className="portlet-info-text">
                                <span>For the month of January 2022</span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <h3 className="marginB15">Channel consumption status</h3>

                <Row>
                    {consumptionList?.length ? consumptionList?.map((status, index) => {
                        return (
                            <Col md={4} key={index + ""}>
                                <div className={`well consume-portlet-box ${status.disable ? "click-off" : ""}`}>
                                    <div className="consume-portlet-body">
                                        {status.channelID !== 5 ?
                                            <i className={`${channelStatusImg[status.channelID]} icons-lg blue`}></i>
                                            :
                                            <img src={channelStatusImg[status.channelID]} alt={"Data provider"} />
                                        }
                                        <span>{status.channelName}</span>
                                    </div>
                                    <h2 className={status.count === 0 ? "click-off" : ""}>{numberFormatter(status.count)}</h2>
                                    <div className="consume-portlet-footer">
                                        <small>({"For the month of " + selectedMonthItem + " " + selectedYearItem})</small>
                                        {status.channelID !== 5 &&
                                            <i className={`icon-sd-circle-info-medium icons-md ${status.count === 0 ? "click-off" : ""}`} onClick={() => history.push({
                                                pathname: `${match.url}/consumption-channel`,
                                                state: {
                                                    channelId: status.channelID,
                                                    channelName: status.channelName,
                                                    month: selectedMonth,
                                                    year: selectedYearItem
                                                }
                                            })}></i>}
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : <SkeletonTable count={5} isError={consumptionListError} />}
                </Row>
            </Container>
        </LayoutPageWrapper>
    )
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const recentCampaignsChart1 = {
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: null,
        height: 350
    },

    title: {
        text: null
    },
    background: null,
    credits: {
        enabled: false
    },
    plotOptions: {
        gauge: {
            dataLabels: {
                enabled: true,
                useHTML: true,
                formatter: function () {
                    return '<span class="rpGaugeLabel font-xs" style="text-align:center;display:block;"><span style="font-family: Muktamedium; text-align:center;display:block;">89 GB</span></span>';
                },
                style: {
                    fontFamily: 'Muktaregular',
                    fontWeight: '300',
                    fontSize: '22px',
                    textShadow: '0',
                    color: '#333'
                },
                borderColor: 'transparent',
            },
            dial: {
                radius: '75%',
                backgroundColor: '#fd2d32',
                borderWidth: 0,
                baseWidth: 5,
                topWidth: 1,
                baseLength: '40%', // of radius
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: '#b8b8b8',
                radius: 7
            }
        }
    },
    pane: [
        { startAngle: -120, endAngle: 120, center: ['50%', '50%'], background: null, },
        { startAngle: -100, endAngle: 100, center: ['50%', '65%'], background: null }
    ],

    // the value axis
    yAxis:
    {
        min: 0,
        max: 100,
        lineColor: null,
        minorTickInterval: 'auto',
        minorTickWidth: 0,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        tickPixelInterval: '30',
        tickWidth: 0,
        tickLength: 10,
        tickPosition: 'inside',
        tickColor: '#fff',
        labels: {
            step: 4,
        },
        title: {
            text: null
        },
        plotBands: [
            { from: 0, to: 20, color: '#99cc01', innerRadius: '88%', outerRadius: '105%', },
            { from: 20, to: 40, color: '#b2d942', innerRadius: '88%', outerRadius: '105%' },
            { from: 40, to: 60, color: '#e7cb60', innerRadius: '88%', outerRadius: '105%' },
            { from: 60, to: 80, color: '#fe9041', innerRadius: '88%', outerRadius: '105%' },
            { from: 80, to: 100, color: '#ff5757', innerRadius: '88%', outerRadius: '105%' },
            { from: 0, to: 100, color: '#e7f2f8', innerRadius: '105%', outerRadius: '113%' }
        ]
    },

    series: [{
        name: 'Warming up',
        data: [89],
        tooltip: {
            valueSuffix: '' //  km/h
        }
    }]
}

const dropdownMonth = [0, 1, 2, 3, 4, 5].map(num => moment().subtract(num, "month").format("MMMM"))
const dropdownYear = [1, 2, 3, 4, 5].map(num => new Date().getFullYear() + 1 - num)
const channelStatusImg = {
    1: 'icon-sd-email-large icons-lg blue',
    2: 'icon-sd-mobile-sms-large icons-lg blue',
    3: 'icon-sd-qrcode-large icons-lg blue',
    4: 'blue icons-lg icon-sd-social-whatsapp-large',
    5: images.data_provider,
    21: 'blue icons-lg icon-sd-social-whatsapp-large',
}
