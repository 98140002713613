import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { HorizontalSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import React, { Component } from 'react';
import { ch_orange } from 'Components/Charts/ChartColors';

class QREngagementChart extends Component {
    state = {
        tabEngagementIndex: 0,
        data: this.props.data,
        expandEnable: false
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ data: this.props.data })
        }
    }
    render() {
        return (<div className="portlet-container portlet-md portlet-footer-label">
            <div className="portlet-header">
                <h4>Engagement</h4>
                <div className="p-nav-right">
                    <RTabbar
                        defaultSelectedItem={this.state.tabEngagementIndex}
                        defaultClass="tabDefault"
                        dynamicTab="mini marginB0"
                        activeClass="tabDefault active"
                        tabData={tabDataEngagement}
                        callBack={(item, index) => this.setState({ tabEngagementIndex: index })}
                    />
                </div>
            </div>
            <div className="portlet-body">
                {
                    this.state.tabEngagementIndex === 0
                        ? <div className="portlet-chart">
                            {this.state.data?.engagementPerformanceDTOs !== null && this.state.data?.engagementPerformanceDTOs?.categories !== null && this.state.data?.engagementPerformanceDTOs?.series !== null ? <NewCharts options={engagementOverallChart(this.state.data)} />
                                : <><NoData /><HorizontalSkeleton /></>
                            }
                        </div>
                        : <div className="portlet-chart">
                            {this.state.data?.engagementPerformanceHrsDTOs !== null && this.state.data?.engagementPerformanceHrsDTOs?.categories !== null && this.state.data?.engagementPerformanceHrsDTOs?.series !== null ? <NewCharts options={engagementFirstHrChart(this.state.data)} />
                                : <><NoData /><HorizontalSkeleton /></>
                            }
                        </div>
                }
            </div>
            {this.state.tabEngagementIndex === 0 ?
                <div className={`${this.state.data?.engagementPerformanceDTOs !== null && this.state.data?.engagementPerformanceDTOs?.categories !== null && this.state.data?.engagementPerformanceDTOs?.series !== null ? 'portlet-two-label' : ''}`}>
                    {this.state.data?.engagementPerformanceDTOs !== null && this.state.data?.engagementPerformanceDTOs?.categories !== null && this.state.data?.engagementPerformanceDTOs?.series !== null ?
                        <ul>
                            {/* <li><span>{this.state?.data?.reach?.count || 0}</span><small>%</small></li>
                            <li>total reach with {this.state?.data?.engagement?.clicks || 0}% engagement happened during this period.</li> */}
                            <li><span>{this.state?.data?.engagement?.clicks || 0}</span><small>%</small></li>
                            <li>Engagement happened during this period</li>
                        </ul> : ''
                    }
                </div>
                :
                <div className={`${this.state.data?.engagementPerformanceHrsDTOs !== null && this.state.data?.engagementPerformanceHrsDTOs?.categories !== null && this.state.data?.engagementPerformanceHrsDTOs?.series !== null ? 'portlet-two-label' : ''}`}>
                    {this.state.data?.engagementPerformanceHrsDTOs !== null && this.state.data?.engagementPerformanceHrsDTOs?.categories !== null && this.state.data?.engagementPerformanceHrsDTOs?.series !== null ?
                        <ul>
                            {/* <li><span>{this.state?.data?.reach?.count || 0}</span><small>%</small></li>
                            <li>total reach with {this.state?.data?.engagement?.clicks || 0}% engagement happened during this period.</li> */}
                            <li><span>{this.state?.data?.engagement?.clicks || 0}</span><small>%</small></li>
                            <li>Engagement happened during this period</li>
                        </ul> : ''
                    }
                </div>
            }
        </div>);
    }
}

export default QREngagementChart;

const tabDataEngagement = [
    { "id": 1001, "text": "Overall" },
    { "id": 1002, "text": "First 24 hrs" }
]

// Engagement Overall -- line
const engagementOverallChart = data => {

    return {

        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Date'
            },
            // categories: data?.engagementPerformanceDTOs?.map(item => {
            //     let newDate = new Date(item?.date).toDateString()
            //     let spiltDate = newDate.split(" ")
            //     return spiltDate[2] + " " + spiltDate[1]
            // }),
            categories: data?.engagementPerformanceDTOs?.categories.map(item => {
                let newDate = new Date(item).toDateString()
                let spiltDate = newDate.split(" ")
                return spiltDate[2] + " " + spiltDate[1]
            }),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Count'
            }
        },
        symbolRadius: 0,
        plotOptions: {
            series: {
                marker: {
                    fillColor: null,
                    lineColor: null,
                    lineWidth: 1,
                    radius: 3,
                    symbol: 'circle',
                }
            },
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: '#fff', radius: 3, lineColor: ch_orange, lineWidth: 2 },
                name: data?.engagementPerformanceDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                // data: data?.engagementPerformanceDTOs?.map(item => item?.count || 0),
                // data: [6, 0, 0].map(item => item || 0),
                data: data?.engagementPerformanceDTOs?.series[0]?.datas.map(item => item || 0),
                legendIndex: 0

            }
        ],
    }

}
// Engagement First 24 hr -- line
const engagementFirstHrChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Hours'
            },
            // categories: data?.engagementPerformanceHrsDTOs?.map(item => `${item?.time} hr` || 0),
            categories: data?.engagementPerformanceHrsDTOs?.categories?.map(item => `${item} hr` || 0),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Count'
            }
        },
        symbolRadius: 0,
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: 'white', radius: 3, lineWidth: 2, lineColor: ch_orange },
                name: data?.engagementPerformanceHrsDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_orange,
                // data: data?.engagementPerformanceHrsDTOs?.map(item => item?.count)
                data: data?.engagementPerformanceHrsDTOs?.series[0]?.datas.map(item => item || 0)
            }
        ],
    }

}