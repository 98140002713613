import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap"
import NewCharts from 'Components/Charts/NewCharts';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import OverViewMap from '../Components/OverViewMap';
import authContext from 'Helper/StateHandler/auth-context';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { NoData, PieChartSkeleton } from 'Components/Skeleton/Skeleton';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { GET_CAMPAIGN_CHANNEL_DETAILS } from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler';
import camera from 'Assets/Images/camera.png'
import { dateTimeFormatWithMoment, dateTimeFormatWithMomentForReports, getUserData, numberWithCommas } from 'Helper/Utils/Utils';
import ReachChart from './Components/ReachChart';
import EngagementChart from './Components/EngagementChart';
import { ch_greenLightColor } from 'Components/Charts/ChartColors';
import SMSCommunicationStatus from './Components/SMSCommunicationStatus';
import SMSTotalClicks from './Components/SMSTotalClicks';
import ReportsAlertPopup from '../Components/ReportsAlertPopup';
import { withRouter } from 'react-router-dom';
import reachImg from 'Assets/Images/detail-analytics-reach.svg'
import engagementImg from 'Assets/Images/detail-analytics-engagement.svg'
import conversionImg from 'Assets/Images/detail-analytics-conversion.svg'
import SMSConversionChart from './Components/SMSConversionChart';

const initialDataState = {
    sort: [
        {
            field: "name",
            dir: "asc"
        },
    ],
    take: 5,
    skip: 0
}

class SMS extends Component {

    static contextType = authContext

    state = {
        dataState: initialDataState,
        isShowAlert: false,
        data: null,
        rowKey: "",
        sankeyPortletFullView: false,
        tabReachIndex: 0,
        tabEngagementIndex: 0,
        tabDaysIndex: 0,
        tabDeviceIndex: 0,
        skip: 0, take: 10,
        selectedDropdownValue: "Split A",
        dropdownList: [
            "Split A",
            "Split B",
            "Actual communication"
        ],
        isSmsSplitAb: this.props.state.isSmsSplitAb,
        expandEnable: false
    }

    componentDidMount = () => {
        this.getMobileDataFromServer()
    }

    getMobileDataFromServer = () => {

        const { campaignId } = this.props.state
        const params = {
            "campaignId": campaignId,
            "channelId": 2,
            "channelFriendlyName": this.props.channelFriendlyName
        }

        connectServer({
            path: GET_CAMPAIGN_CHANNEL_DETAILS,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let isShow = res?.data.mobileDetail?.keyMetrics?.totalSent > 0 ? false : true;
                    if (isShow) {
                        this.setState({ isShowAlert: isShow })
                    } else {
                        this.setState({ data: res.data?.mobileDetail, rowKey: res?.data?.rowKey, isShowAlert: isShow })
                    }
                }
            },
            fail: err => { }
        })

    }

    render() {
        return (
            <>
                {this.state.isShowAlert &&
                    <ReportsAlertPopup
                        isOpen={this.state.isShowAlert}
                        onChangeIsOpen={(value) => {
                            if (value) {
                                this.props.history.push("/analytics");
                            }
                            this.setState({ isShowAlert: false })
                        }}></ReportsAlertPopup>}
                {this.state.isSmsSplitAb &&
                    <Row>
                        <Col>
                            <div className="tabs-sublevel bgBlue">
                                <div className="tabs-sub-left">
                                    <BootstrapDropdown
                                        className="marginR10 fs18-btn"
                                        data={this.state.dropdownList}
                                        defaultItem={this.state.selectedDropdownValue}
                                        customAlignRight={true}
                                        onSelect={item => this.setState({ selectedDropdownValue: item })}
                                    />
                                </div>
                                <div className="tabs-sub-right">
                                    <RSPDateRangePicker class="" />
                                </div>
                            </div>
                        </Col>
                    </Row>}

                {/* SMS Dashboard */}
                <div className='d-flex align-items-center'>
                    <h3 className="marginB15 mt15">Overview</h3>
                    <span className='ml5 mt2'>(As on: <span className='d-inline-block'>{dateTimeFormatWithMomentForReports(this.state.data?.latestDate)}</span>)</span>
                    <div className='d-flex align-items-center'>
                        <RSPPophover
                            position='right'
                            imgClass='campaign-pop-eye-img'
                            className="pophover-sms-con"
                            pophover={
                                <div className='campaign-sms-wrapper'>
                                    <div className='campaign-sms-header'>
                                        <div className="camp-sms-left"><span class="arrow-left">&lt;</span>Back</div>
                                        <div className='camp-sms-center blue'>{getUserData()?.clientDetailsLocalization?.clientName}</div>
                                        <div className="camp-sms-right">Contact</div>
                                    </div>
                                    <div className='campaign-sms-body'>
                                        <div className='chat-bubble-left'>
                                            <div className='chat-bubble'>
                                                <span className='campaign-message'>
                                                    {this.state?.data?.previewImage ? this.state?.data?.previewImage : 'No data'}
                                                </span>
                                                <small className='message-date'>{this.state?.data?.scheduleDate ? dateTimeFormatWithMoment(this.state?.data?.scheduleDate) : ''}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='campaign-sms-footer'>
                                        <img className="in-img" src={camera} alt='camera' />
                                        <div className="in-msg">Message</div>
                                        <div className="in-send">Send</div>
                                    </div>
                                </div>
                            }
                        >
                            <div>
                                <RSPTooltip position="top" text="Preview">
                                    <i className='icon-sd-eye-medium icons-md blue ml5 cp posr top3'></i>
                                </RSPTooltip>
                            </div>
                        </RSPPophover>
                    </div>
                </div>
                <Row>
                    {
                        this.state.data &&
                        getOverviewCardData(this.state.data)?.map((item, index) => {
                            return (
                                <OverViewMap
                                    key={index}
                                    campaignType={item?.campaignType}
                                    campaignCount={item?.campaignCount}
                                    data={item?.data}
                                    icon={item?.icon}
                                    bgColor={item?.bgColor}
                                    percentage={item?.percentage}
                                    infoMsg={item?.data?.info}
                                    displayLabel={item?.displayLabel}
                                    isLowPerformance={item?.isLowPerformance}
                                />
                            )
                        })
                    }

                </Row>

                <h3 className="marginB15">Communication performance</h3>
                <Row>
                    {/* Reach */}
                    <Col md={8}>
                        <ReachChart data={this.state.data} />
                    </Col>

                    {/* Key metrics */}
                    <Col md={4}>
                        <div className="portlet-container keymetrics-portlet keymetrics-md">
                            <h4>Key metrics</h4>
                            <ul className="keymetrics-list key-border">
                                <li>
                                    <h3>{numberWithCommas(this.state.data?.keyMetrics?.totalSent) || 0}</h3>
                                    <small>Total sent</small>
                                </li>
                                <li>
                                    <h3>{numberWithCommas(this.state.data?.keyMetrics?.delivered) || 0}</h3>
                                    <small>Delivered</small>
                                </li>
                            </ul>
                            <p className="mt10 mb5 text-center">Inprogress reach status</p>
                            <ul className="keymetrics-list keymetrics-theme">
                                <li className="bgBlue-lighter">
                                    <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.messageInQueue) || 0}<small></small></h3>
                                    <small>Message in queue</small>
                                </li>
                                <li className="bgBlue-light">
                                    <h3 className="white p0">{numberWithCommas(this.state.data?.keyMetrics?.submittedToCarrier) || 0}<small></small></h3>
                                    <small>Submitted to carrier</small>
                                </li>
                            </ul>
                            <ul className="keymetrics-list key-border pt10">
                                <li>
                                    <small>Conversion rate</small>
                                    <h3 className="pb0 mt10">{this.state.data?.keyMetrics?.goalConversionRate || 0}<small>%</small></h3>
                                </li>
                                <li>
                                    <small>Channel cost</small>
                                    <h3 className="pb0 mt10">${this.state.data?.keyMetrics?.channelCost || 0}</h3>
                                    {/* <i class="icon-sd-circle-info-medium icons-sm blue cp posr t2"></i> */}
                                </li>
                            </ul>
                            <p className="mt10 mb5 text-center">Undelivered SMS status</p>
                            {this.state.data && this.state.data?.keyMetrics?.undeliveredMessageStatus?.map((item, index) => {
                                return (
                                    <ul key={index} className="keymetrics-list keymetrics-theme">
                                        <li className="bgMaroon-light">
                                            <h3 className="white p0">{item?.rejected}<small></small></h3>
                                            <small>Rejected</small>
                                        </li>
                                        <li className="bgMaroon-medium">
                                            <h3 className="white p0">{item?.dnd}<small></small></h3>
                                            <small>DND</small>
                                        </li>
                                        <li className="bgMaroon">
                                            <h3 className="white p0">{item?.expired}<small></small></h3>
                                            <small>Expired</small>
                                        </li>
                                        <li className="bgMaroon-medium">
                                            <h3 className="white p0">{item?.undelivered}<small></small></h3>
                                            <small>Undelivered</small>
                                        </li>
                                    </ul>
                                )
                            })}
                        </div>
                    </Col>
                </Row>

                <Row>
                    {/* Engagement */}
                    <Col md={6}>
                        <EngagementChart data={this.state.data} />
                    </Col>

                    {/* Conversion */}
                    <Col md={6}>
                        <SMSConversionChart data={this.state.data} />
                    </Col>
                </Row>

                <Row>
                    {/* Campaign status */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md portlet-footer-label">
                            <div className="portlet-header">
                                <h4>Delivery status</h4>
                            </div>
                            {this.state.data?.statusInfoDTOs !== null && this.state.data?.statusInfoDTOs?.length ?
                                <><div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={campaignStatusChart(this.state.data)} />
                                    </div>
                                </div>
                                    <div className="portlet-two-label">
                                        <ul>
                                            <li><span>{this.state?.data?.reach?.delivered || 0}</span><small>%</small></li>
                                            <li>SMS have been successfully delivered to audience</li>
                                        </ul>
                                    </div></> : <><NoData /><PieChartSkeleton /></>
                            }
                        </div>
                    </Col>

                    {/* Mobile OS info */}
                    <Col md={6}>
                        <div className="portlet-container portlet-md portlet-footer-label">
                            <div className="portlet-header">
                                <h4>Mobile OS info</h4>
                            </div>
                            {this.state.data?.osInfoDTOs !== null && this.state.data?.osInfoDTOs?.length ?
                                <><div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={mobileOSinfoChart(this.state.data)} />
                                    </div>
                                </div>
                                    <div className="portlet-two-label">
                                        <ul>
                                            <li><span>{this.state?.data?.topOsValue || 0}</span><small>%</small></li>
                                            <li>of SMS have been opened from {this.state?.data?.topOs}</li>
                                        </ul>
                                    </div></> : <><NoData /><PieChartSkeleton /></>
                            }
                        </div>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <SMSTotalClicks campaignGuid={this.props.state.campaignGuid} rowKey={this.state.rowKey} />
                    </Col>
                </Row>

                <Row className="marginT30">
                    <Col>
                        <SMSCommunicationStatus campaignId={this.props.state.campaignId} latestDate={this.state.data?.latestDate} rowKey={this.state.rowKey} />
                    </Col>
                </Row>

            </>
        )
    }
}

export default withRouter(SMS);

// Overall datas
const getOverviewCardData = data => {

    const overallDatas = [
        {
            "campaignType": "Reach",
            "campaignCount": data?.reach?.count || 0,
            "data": [{
                "text": "Delivered", "percent": data?.reach?.delivered || 0,
                "info": [{ "known": data?.reach?.uniqueOpens || 0, "unKnown": data?.reach?.forwards || 0 }]
            }],
            // "icon": "icon-campaign-target-large icons-xl",
            "icon": reachImg,
            "bgColor": "bgBlue-light",
            "percentage": data?.reach?.previousWeekComparison || "0",
            "displayLabel": (!!data?.reach?.prevDisplayLabel && data?.reach?.prevDisplayLabel !== "") ? data?.reach?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.reach?.isLowReachPerformance
        },
        {
            "campaignType": "Engagement",
            "campaignCount": data?.engagement?.count || 0,
            "data": [{
                "text": "Link clicks", "percent": data?.engagement?.clicks || 0,
                "info": [{ "known": data?.engagement?.uniqueClicks || 0, "unKnown": data?.engagement?.fwdMailClicks || 0 }]
            }],
            "icon": engagementImg,
            // "icon": "icon-engagement-large icons-xl",
            "bgColor": "bgOrange-medium",
            "percentage": data?.engagement?.previousWeekComparison || "0",
            "displayLabel": (!!data?.engagement?.prevDisplayLabel && data?.engagement?.prevDisplayLabel !== "") ? data?.engagement?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.engagement?.isLowEngPerformance
        },
        {
            "campaignType": "Conversion",
            "campaignCount": data?.conversion?.count || 0,
            "data": [{
                "text": "Registration", "percent": data?.conversion?.registration || 0,
                "info": [{ "known": "389", "unKnown": "1,577" }]
            }],
            "icon": conversionImg,
            // "icon": "icon-conversion-large icons-xl",
            "bgColor": "bgGreen-medium",
            "percentage": data?.conversion?.previousWeekComparison || "0",
            "displayLabel": (!!data?.conversion?.prevDisplayLabel && data?.conversion?.prevDisplayLabel !== "") ? data?.conversion?.prevDisplayLabel : data?.prevDisplayLabel,
            "isLowPerformance": data?.conversion?.isLowConvPerformance
        }
    ]
    return overallDatas

}

// Campaign Status -- pie
const campaignStatusChart = data => {

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 5,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "85%",
                innerSize: "50%",
                size: "85%",
                // data: [
                //     { name: "Delivered", color: ch_green, y: 16.1, legendIndex: 0, },
                //     { name: "Undelivered", color: ch_yellowColor, y: 9.9, legendIndex: 1, },
                //     { name: "Submitted to carrier", color: ch_orange, y: 15.4, legendIndex: 2, },
                //     { name: "Message in queue", color: ch_blueLightColor, legendIndex: 3, y: 33 },
                //     { name: "Expired", color: ch_others, legendIndex: 4, y: 1.7 },
                //     { name: "Rejected", color: ch_blueColor, legendIndex: 5, y: 17.3 },
                //     { name: "Opted out", color: ch_greyDarkColor, legendIndex: 6, y: 9.6 }
                // ],
                data: data?.statusInfoDTOs?.map((item, index) => {
                    let statusData = { name: item?.name, legendIndex: index, y: parseFloat(item?.value) || 0 }
                    return statusData
                }),
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "45%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}

// Mobile OS info -- pie
const mobileOSinfoChart = data => {

    return {
        chart: {
            type: "pie"
        },
        legend: {
            itemMarginTop: 15,
            itemMarginBottom: 0
        },
        series: [
            {
                name: "Total",
                showInLegend: true,
                borderWidth: 2,
                height: "100%",
                innerSize: "50%",
                size: "100%",
                // data: [
                //     { name: "Android", color: ch_cyanColor, y: 8, legendIndex: 0 },
                //     { name: "Windows", color: ch_blueLightColor, y: 49, legendIndex: 1 },
                //     { name: "iPhone", color: ch_greenLightColor, y: 25, legendIndex: 2 },
                //     { name: "Others", color: ch_others, y: 17, legendIndex: 3 }
                // ],
                data: data?.osInfoDTOs?.map((item, index) => {
                    let statusData = { name: item?.name, legendIndex: index, y: parseFloat(item?.value) || 0 }
                    return statusData
                }),
                shadow: false,
                dataLabels: {
                    enabled: true,
                    connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                    crookDistance: '100%',
                    softConnector: false
                }
            },
            {
                borderWidth: 0,
                innerSize: "89%",
                size: "53%",
                data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
                shadow: false,
                showInLegend: false,
                enableMouseTracking: false,
                dataLabels: { enabled: false }
            }
        ]
    }

}
