import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RTabbar } from 'Components/RChart/RTabbar';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import CreditCard from 'Container/RegistrationModule/Payment/Components/CreditCard';
import Paypal from 'Container/RegistrationModule/Payment/Components/Paypal';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import paypalImg from 'Assets/Images/paypal.png';
import { dateFormat, getAuthClientId } from 'Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { CHECK_CARD_TYPE, SAVE_CONSUMABLE_INVOICE_PAYMENT } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { ENTER_NUMBER_ON_CARD } from 'Helper/Constants/validationMessage';
import { RSPTooltip } from 'Components/Tooltip';

const CampaignPayment = props => {
    let history = useHistory();
    const context = useContext(authContext)
    const [index, setIndex] = useState(0)
    const [campaignHeader, setCampaignHeader] = useState({
        name: "",
        endDate: "",
        type: "",
        goal: "",
        startDate: ""
    })
    const [cardNumber, setCardNumber] = useState('')
    const [cardName, setCardName] = useState('')
    const [cardMonth, setCardMonth] = useState('')
    const [cardYear, setCardYear] = useState('')
    const [cardCvv, setCardCvv] = useState('')
    const [cardType, setCardType] = useState("");
    const [errorMessage, setErrorMessage] = useState({
        cardNumber: null,
        cardName: null,
        cardMonth: null,
        cardYear: null,
        cardCvv: null
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.history.location && props.history.location.state) {
            const state = props.history.location.state
            const { campaignHeader } = state
            setCampaignHeader(campaignHeader)
        }
    }, [])

    const saveConsumableInvoicePayment = () => {
        const { campaignId, totalAmount } = props.history.location.state
        let params = {
            "campaignid": campaignId,
            "cardHolderName": cardName,
            "cardNumber": cardNumber,
            "month": cardMonth,
            "year": cardYear,
            "expirationDate": `${cardMonth}/${cardYear}`,
            "cvv": cardCvv,
            "clientId": getAuthClientId(),
            "Amount": totalAmount,
            "source": 5
        }
        connectServer({
            path: SAVE_CONSUMABLE_INVOICE_PAYMENT,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    history.push("/communication");
                }
            },
            fail: err => {
            }
        })
    }

    const cardExpiryValidation = t => {

        if (!cardYear.length && !cardMonth.length) { return false }

        let currentYear = new Date().getFullYear()
        if (cardYear < currentYear) {
            setErrorMessage({
                ...errorMessage,
                ...{ cardYear: "Your card expired" }
            })
            return false
        } else if (cardYear > currentYear) {
            setErrorMessage({
                ...errorMessage,
                ...{ cardYear: null }
            })
            return true
        } else {
            let currentMonth = new Date().getMonth() + 1
            if (cardMonth < currentMonth) {
                setErrorMessage({
                    ...errorMessage,
                    ...{ cardYear: "Your card expired" }
                })
                return false
            } else {
                setErrorMessage({
                    ...errorMessage,
                    ...{ cardYear: null }
                })
                return true
            }
        }
    }

    const checkCardType = (cardNumber, result) => {
        let params = {
            "cardNumber": cardNumber
        }
        connectServer({
            path: CHECK_CARD_TYPE,
            params,
            context: context,
            ok: res => {
                result(res)
            },
            fail: err => {
            }
        })
    }

    const validation = () => {

        let isValidate = true;
        let tempErrorMsg = { ...errorMessage };
        let digits = cardType === "AmEx" ? 4 : 3

        if (cardNumber.length > 0) {
            checkCardType(cardNumber, (result => {
                if (result.status) {
                    tempErrorMsg.cardNumber = null;
                    setCardType(result.data.cardName)
                } else {
                    tempErrorMsg.cardNumber = result.message;
                    isValidate = false;
                }
            }))
        } else {
            tempErrorMsg.cardNumber = ENTER_NUMBER_ON_CARD;
            isValidate = false;
        }
        if (cardName.length > 0) {
            tempErrorMsg.cardName = null;
        } else {
            tempErrorMsg.cardName = 'Enter name on card';
            isValidate = false;
        }

        if (cardMonth.toString().length > 0) {
            if (cardMonth.toString() === "00") {
                tempErrorMsg.cardMonth = 'Invalid month';
                isValidate = false;
            } else {
                tempErrorMsg.cardMonth = null;
            }
        } else {
            tempErrorMsg.cardMonth = 'Enter month';
            isValidate = false;
        }

        if (cardYear.toString().length === 4) {
            tempErrorMsg.cardYear = null;
        } else {
            tempErrorMsg.cardYear = 'Enter year';
            isValidate = false;
        }

        if (cardCvv.toString().length === 0) {
            tempErrorMsg.cardCvv = 'Enter cvv';
            isValidate = false;
        } else if (cardCvv.toString().length === digits) {
            tempErrorMsg.cardCvv = null;
        } else {
            tempErrorMsg.cardCvv = "Need " + digits + " digits";
            isValidate = false;
        }
        if (isValidate) {
            let currentYear = new Date().getFullYear()
            if (cardYear < currentYear) {
                tempErrorMsg.cardYear = "Your card expired";
                isValidate = false;
            } else if (cardYear > currentYear) {
                if (cardYear > (currentYear + 50)) {
                    tempErrorMsg.cardYear = "Enter year below " + (currentYear + 51).toString();
                    isValidate = false;
                } else {
                    tempErrorMsg.cardYear = null;
                    isValidate = true;
                }
            } else {
                let currentMonth = new Date().getMonth() + 1
                if (cardMonth < currentMonth) {
                    tempErrorMsg.cardMonth = "Your card expired";
                    isValidate = false;
                } else {
                    tempErrorMsg.cardMonth = null;
                    isValidate = true;
                }
            }
        }
        setErrorMessage(tempErrorMsg);
        // isValidate = cardExpiryValidation();
        if (!isValidate) {
            return
        }
        saveConsumableInvoicePayment()
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <h1>Communication payment</h1>
                <hr className="blue marginB20" />
                <div className="progress-steps-box" style={{ backgroundColor: "#fae9db" }}>
                    <RSPTooltip text={campaignHeader?.name || ''} position="top">
                        <div className="steps-label">
                            <small>Name:</small>
                            <label className="label-row label-dark">{campaignHeader?.name || ''}</label>
                        </div>
                    </RSPTooltip>
                    <RSPTooltip text={campaignHeader?.startDate
                        ? `${dateFormat(campaignHeader?.startDate)}-${dateFormat(campaignHeader?.endDate)}`
                        : ""} position="top">
                        <div className="steps-label">
                            <small>Period:</small>
                            <label className="label-row label-dark">{campaignHeader?.startDate
                                ? `${dateFormat(campaignHeader?.startDate)}-${dateFormat(campaignHeader?.endDate)}`
                                : ""}</label>
                        </div>
                    </RSPTooltip>
                    <RSPTooltip text={campaignHeader?.type || ''} position="top">
                        <div className="steps-label">
                            <small>Type:</small>
                            <label className="label-row label-dark">{campaignHeader?.type || ''}</label>
                        </div>
                    </RSPTooltip>
                    <RSPTooltip text={props.history.location.state?.totalAmount} position="top">
                        <div className="steps-label">
                            <small>Amount (USD):</small>
                            <label className="label-row label-dark">$ {props.history.location.state?.totalAmount}</label>
                        </div>
                    </RSPTooltip>
                </div>
                <Row>
                    <h4 className='theme-space-mt'>Choose a payment method to complete setting up your communication.</h4>
                    <Row className='justify-content-center row'>
                        <Col md={{ span: 5 }}>
                            <div className="payment-tab-container">
                                <RTabbar
                                    defaultSelectedItem={index}
                                    defaultClass="tabDefault"
                                    dynamicTab="mini"
                                    activeClass="tabDefault active"
                                    tabData={tabData}
                                    callBack={(item, index) => { setIndex(index) }}
                                />
                                <div className="payment-body-form">
                                    {index === 0 ? <CreditCard errorMessage={errorMessage}
                                        getValue={(key, value) => {
                                            if (key === 'cardNumber') {
                                                setCardNumber(value)
                                            } else if (key === "cardName") {
                                                setCardName(value)
                                            } else if (key === 'cardMonth') {
                                                setCardMonth(value)
                                            } else if (key === 'cardYear') {
                                                setCardYear(value)
                                            } else if (key === 'cardCvv') {
                                                setCardCvv(value)
                                            } else if (key === 'errMsg') {
                                                setErrorMessage(value)
                                            } else if (key === "cardType") {
                                                setCardType(value);
                                            }
                                        }
                                        } /> : <Paypal />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="btn-container d-flex justify-content-end mt10" style={{ textAlign: "right", marginBottom: "20px" }}>
                        <RSSecondaryBtn onClick={() => {
                            history.push("/communication");
                        }}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            validation()
                        }}>Submit
                        </RSPrimaryBtn>
                    </div>
                </Row>
            </Container>
        </LayoutPageWrapper>
    )

}
export default withRouter(CampaignPayment);
const tabData = [
    { "id": 1001, "text": "Credit card", "iconLeft": "icon-sd-credit-card-large icons-lg marginR10", "disable": false },
    { "id": 1002, "image": paypalImg, "disable": true }
]