import React, { useState } from "react";

import TemplateCard from "./TemplateCard";

const CreateNewTemplate = ({ page, mainClass }) => {
  return (
    <div>
      <TemplateCard page={"email"} mainClass={mainClass} name={"CreateNewTemplate"} id={3} />
    </div>
  );
};

export default CreateNewTemplate;
