import { Buffer } from 'buffer'
import moment from 'moment-timezone'
import { GET_AUDIENCE_LIST, GET_CAMPAIGN_ATTRIBUTES_LIST, GET_LANGUAGE_DATA } from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler'

export const isEmpty = str => {
    return str === ""
}

export const isNchars = (str, length) => {
    return str.length > length - 1
}

export const validateEmail = str => {
    if (isEmpty(str)) { return false }
    // var re = /\S+@\S+\.\S+/;
    // var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(str);
}

export const validatePassword = str => {
    if (isEmpty(str)) { return false }
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    if (re.test(str)) {
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
        return !emojiRegex.test(str);
    } else {
        return false
    }
}

export const passwordStrengthMeter = str => {
    var veryStrongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    var MediumStrongPassword = new RegExp('(?=.*[a-z])(?=.*[^A-Za-z0-9])(?=.{5,})')
    const StrongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.{5,})')
    const MediumPassword = new RegExp('(?=.*[a-z])(?=.{4,})')
    if (str.length === 1 || str.length === 0) {
        return 0
    } else {
        if (veryStrongPassword.test(str)) {
            return 100
        } else if (MediumStrongPassword.test(str)) {
            return 80
        } else if (StrongPassword.test(str)) {
            return 60
        } else {
            if (MediumPassword.test(str)) {
                return 40
            } else {
                return 10
            }
        }
    }
}

export const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export const capitalizeEveryWord = str => {
    return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
}
const colorCodeToHex = (colorCode) => {
    var hex = Number(colorCode).toString(16);
    if (hex.length === 1) {
        hex = "0" + hex;
    }
    return hex;
}
export const rgbToHex = (r, g, b) => {
    var red = colorCodeToHex(r);
    var green = colorCodeToHex(g);
    var blue = colorCodeToHex(b);
    return "#" + red + green + blue;
}

export const isValidDomain = domain => {
    var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    return domain.match(re);
}
export const isValidWebsite = website => {
    //var pattern = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i; // fragment locator
    let pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    let isPatternValid = pattern.test(website) ? true : false;
    let isHttpsValid = validateSmartURLHttp(website)
    return (isHttpsValid && isPatternValid) ? true : false;
    // var res = website.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    // return (res !== null)
}
export const validateSmartURLHttp = (smarturl) => {
    try {
        smarturl = smarturl.split("?")[0].split('[[')[0];
        let url = new URL(smarturl);
        if (url.protocol === "http:") {
            return false;
        } else {
            return true;
        }
    } catch (_) {
        return false;
    }
}
export const setContextValue = (key, value) => {
    // let context = useContext(authContext)
    // context[key] = value
}
export const convertStringToArray = (string) => {
    if (isEmpty(string)) {
        return []
    }
    return string.split(",")
}
export const filterTwoStringArray = (arr1, arr2) => {
    if (arr2.length === 0) {
        return arr1
    }
    return arr1.filter(function (val) {
        return arr2.indexOf(val) === -1;
    });
}
export const makeImmutableObject = (object) => {
    let temp1 = JSON.stringify(object)
    return JSON.parse(temp1)
}

export const getYearMonthDate = (value) => {
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleDateString("en-US", options);
    return dateValue;
}

export const dateFormat = (value) => {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleDateString("en-US", options);
    return dateValue;
}

export const dateFormatWithMomentForConsumptions = (value) => {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleDateString("en-US", options);
    dateValue = moment(dateValue,).format('ddd, MMM DD, yyyy');
    return dateValue;
}
export const dateFormatWithMomentForInvoice = (value) => {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleDateString("en-US", options);
    dateValue = moment(dateValue,).format('MMM DD, yyyy');
    return dateValue;
}
export const rspInternationalFormat = number => parseInt(number, 10) ? parseInt(number, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
export const dateTimeFormat = (value) => {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleTimeString("en-US", options);
    return dateValue;
}
export const dateTimeFormatWithMoment = (value) => {
    let dateValue = new Date(value);
    dateValue = moment(dateValue).format(getUserData().timeFormat === "24 hours" ? "DD MMM, yyyy HH:mm " : "DD MMM, yyyy hh:mm a")
    return dateValue;
}
export const dateTimeFormatWithMomentForInvoice = (value) => {
    let dateValue = new Date(value);
    dateValue = moment(dateValue).format(getUserData().timeFormat === "24 hours" ? "MMM DD, yyyy HH:mm " : "MMM DD, yyyy hh:mm a")
    return dateValue;
}
export const TimeFormatWithMomentForWhatsApp = (value) => {
    let timeValue = new Date(value).toLocaleTimeString("en-US");
    timeValue = getUserData().timeFormat === "24 hours" ? moment(timeValue, 'hh:mm A').format('HH:mm') : timeValue;
    return timeValue;
}
export const DateFormatWithMomentForWhatsApp = (value) => {
    let dateValue = new Date(value).toLocaleDateString("en-US");
    dateValue = moment(dateValue,).format('DD MMM, yyyy');
    return dateValue;
}
export const dateTimeFormatWithMomentForReports = (value) => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = data.timeZone.filter((item) =>
        item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId)[0];
    let offset = filterTimezone.gmtOffset.replace("(GMT", "").replace(")", "");
    let dateValue = moment.utc(value).utcOffset(offset);
    dateValue = dateValue.format(loggedDetails?.timeFormat === "24 hours" ? "ddd, DD MMM, yyyy HH:mm " : "ddd, DD MMM, yyyy hh:mm a")
    return dateValue;
}
export const getCurrentTimeWithTimeZone = (value, timeZoneId, minutes) => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = data.timeZone.filter((item) =>
        item.timezoneId === timeZoneId)[0];
    let offset = filterTimezone.gmtOffset.replace("(GMT", "").replace(")", "");
    let dateValue = moment.utc((value.getTime() + minutes * 60000)).utcOffset(offset);
    dateValue = dateValue.format(loggedDetails?.timeFormat === "24 hours" ? "ddd, DD MMM, yyyy HH:mm " : "ddd, DD MMM, yyyy hh:mm a")
    return dateValue;
}
export const dateTimeFormatMdc = (value) => {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let dateValue = new Date(value);
    dateValue = dateValue.toLocaleTimeString("en-US", options);
    return dateValue;
}
export const dateFormatWithHyphen = value => {
    let dateValue = new Date(value);
    dateValue = dateValue.toISOString().slice(0, 10);
    return dateValue;
}

export const getFileToBase64 = (file, success, failure) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        if (reader.result) {
            success(reader.result.split(',')[1])
        } else {
            failure("Problem with converting file to base64")
        }
    };
    reader.onerror = (error) => {
        failure(error)
    };
}

export const convertDynamicListResponseToRsResponse = (res) => {
    let rsObject = {}
    rsObject.listId = res.dynamicListID
    rsObject.listName = res.dynamicListName
    rsObject.departmentId = res.departmentID
    const ruleJson = JSON.parse(res.ruleJSON)
    rsObject.ruleCondition = ruleJson.RuleCondition

    let ruleGroups = []

    if (ruleJson.RuleGroup1 && typeof (ruleJson.RuleGroup1) === 'object') {
        let _ruleGroup1 = checkRuleGroup(ruleJson.RuleGroup1)
        ruleGroups.push(_ruleGroup1)
    }
    if (ruleJson.RuleGroup2 && typeof (ruleJson.RuleGroup2) === 'object') {
        let _ruleGroup2 = checkRuleGroup(ruleJson.RuleGroup2)
        ruleGroups.push(_ruleGroup2)
    }

    rsObject.ruleGroup = ruleGroups

    return rsObject
}

const checkRuleGroup = (ruleJson) => {


    let obj = {}
    obj.ruleGroupName = ruleJson.RuleGroupName
    obj.matchType = ruleJson.MatchType
    obj.matchCount = ruleJson.MatchCount
    obj.trigger = "Mobile"//ruleJson.TriggerName
    obj.ruleType = ruleJson.RuleType
    obj.source = "Vision Bank"//ruleJson.Source

    const rules = ruleJson.RuleAttributes.map((item, i) => {
        let _ruleObj = {}
        _ruleObj.rulename = item.Name
        _ruleObj.isMandatory = item.Mandatory
        _ruleObj.type = item.FieldType
        _ruleObj.value = item.Value
        _ruleObj.id = item.Id
        _ruleObj.rudimentaryTableName = item.RudimentaryTableName
        return _ruleObj
    })
    obj.rule = rules
    return obj
}


export const convertObjectToBase64 = obj => {
    let objJsonStr = JSON.stringify(obj)
    let objJsonB64 = Buffer.from(objJsonStr).toString('base64')
    return objJsonB64
}

export const convertBase64ToObject = b64 => {
    let base64ToString = Buffer.from(b64, 'base64').toString()
    let obj = JSON.parse(base64ToString)
    return obj
}

export const getUserData = () => {
    const _userIdB64 = localStorage.getItem('lsUserData')
    return _userIdB64 ? convertBase64ToObject(_userIdB64) : null
}

export const removeAllLocalStorage = () => {
    localStorage.removeItem("lsUserData")
    localStorage.removeItem("lsMasterDropdownData")
    localStorage.removeItem("lsCampaignMaster")
    localStorage.removeItem("lsTimzoneData")
    localStorage.removeItem("")
}

export const getAuthUserId = () => {
    const userObject = getUserData()
    return userObject ? userObject?.userId || 0 : 0
}
export const getAuthClientId = () => {
    const userObject = getUserData()
    return userObject ? userObject?.clientId || 0 : 0
}
export const isAllowToAddUser = () => {
    let userDetails = getUserData();
    let isAllow = false;
    if (userDetails?.userTypeId === 4) {
        isAllow = true
    } else if (userDetails?.roleId === 4) {
        isAllow = true;
    }
    return isAllow;
}
export const getLsMasterDataDropdown = () => {
    const _userIdB64 = localStorage.getItem('lsMasterDropdownData')
    return _userIdB64 ? convertBase64ToObject(_userIdB64) : null
}
export const setCampaignMaster = (data) => {
    localStorage.setItem('lsCampaignMaster', convertObjectToBase64(data))
}
export const getCampaignMaster = () => {
    const _userIdB64 = localStorage.getItem('lsCampaignMaster')
    return _userIdB64 ? convertBase64ToObject(_userIdB64) : null
}
export const validateIPAddress = (ipaddress) => {
    if (isEmpty(ipaddress)) { return false }
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
    }
    else {
        return (false)
    }
}
export const validatePortNumber = (portnumber) => {
    if (isEmpty(portnumber)) { return false }
    if (/^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi.test(portnumber)) {
        return (true)
    }
    else {
        return (false)
    }
}
export const numberFormatter = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
}
export const decimalNumberFormatter = (number) => {
    return number.toFixed(2);
}
export const getLowerCasedNoSpaceString = str => {
    return str.toLowerCase().replace(/ /g, '')
}
export const removeSpace = str => {
    return str.replace(/ /g, '')
}
export const formatCreditCardNumber = value => {

    const re = /^[0-9-]{1,20}$/
    if (value === '' || re.test(value)) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []
        let i = 0
        let len = 0
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            return parts.join('-')
        } else {
            return value
        }
    } else {
        return value.length === 0 ? "" : value.substring(0, value.length - 1)
    }
}
export const localStorageStrToObject = str => {
    const _config = localStorage.getItem(str)
    return JSON.parse(_config)
}
export const getContent = (oldText, newText, position) => {

    // this.setState({ clicked: 0 });
    oldText = oldText.split('');
    // Insert the string at the index position
    oldText.splice(position, 0, newText);
    // Join back the individual characters
    // to form a new string
    oldText = oldText.join('');
    return oldText;
}
export const excludeSuccess = (status, url) => {

    const paths = ["GetMasterData"]
    const path = url.split('/').pop()

    if (status === 200 || status === 201) {
        if (paths.includes(path)) {
            return true
        }
    }
    return false
}
export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000)
}
export const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getTimezoneData = () => {
    const _userIdB64 = localStorage.getItem('lsTimzoneData')
    return _userIdB64 ? convertBase64ToObject(_userIdB64) : null
}
export const displayDateAndTime = (date, isTime) => {

    const timezoneData = getTimezoneData()
    const timezoneValue = timezoneData?.timezoneValue
    var dateFormat = timezoneData?.dateFormat
    var timeFormat = timezoneData?.timeFormat?.includes(12) ? "hh:mm:ss A" : "HH:mm:ss"

    if (dateFormat === "MM-DD-YYYY" || dateFormat === "MMM-DD-YYYY") {
        dateFormat = "ddd, MMM DD, yyyy"
        timeFormat = "hh:mm A"
    } else {
        dateFormat = "ddd, DD MMM, yyyy"
        timeFormat = "hh:mm:ss"
    }

    const timeWithTimezone = moment.tz(new Date(date), timezoneValue)
    const formattedTime = timeWithTimezone?.format(`${dateFormat} ${isTime ? timeFormat : ""}`)
    // const formattedTime = timeWithTimezone?.format(`${dateFormat}`)

    return formattedTime
}

export const getLocalScheduleTime = (date) => {
    const timezoneData = getTimezoneData()
    const timezoneValue = timezoneData?.timezoneValue
    const timeWithTimezone = moment.tz(new Date(date), timezoneValue)
    return timeWithTimezone
}

// Email mask start
export const maskEmail = (emailID) => {
    let split = emailID.split('@')
    return emailID.substr(0, 1) + new Array(split[0].length - 1).fill('x').join('') + '@' + split[1]
}

export const getServerConfig = () => {
    const GBASE_URL = "https://paasgstg.marketingstar.us/"
    //const ABASE_URL = "https://paasa.marketingstar.us/"
    const ABASE_URL = "https://paasgstg.marketingstar.us/"
    if (window.location.href.includes('app.marketingstar.us')) {
        return GBASE_URL
    } else if (window.location.href.includes('sit.marketingstar.us')) {
        return ABASE_URL
    }
    else if (window.location.href.includes('run.marketingstar.us')) {
        let nbase = localStorage.getItem('nbase')
        if (nbase === 'G') {
            return GBASE_URL
        } else {
            return ABASE_URL
        }

    } else if (window.location.href.includes('localhost')) {
        let nbase = localStorage.getItem('nbase')
        if (nbase === 'A' || nbase === undefined || nbase === null) {
            return ABASE_URL
        } else if (nbase === "G") {
            return GBASE_URL
        } else {
            return ABASE_URL
        }
        // return ABASE_URL
    }
    return ABASE_URL
}
export const getAccessToken = () => {
    const sessionStr = localStorage.getItem('session')
    const sessionObject = JSON.parse(sessionStr)
    const accessToken = sessionObject ? sessionObject.accessToken : ""
    return window.btoa(accessToken);
}
export const getSourceType = () => {
    let nbase = localStorage.getItem('nbase')
    if (nbase === 'A' || nbase === undefined || nbase === null) {
        return 'A'
    } else if (nbase === "G") {
        return 'G'
    }
    return 'A'
}
export const getSourceTypeForDocs = () => {
    return "G"
}
export const getBase = () => {
    let base = getSourceTypeForDocs();
    if (base === "A") {
        return "paasa"
    } else if (base === "G") {
        return "paasgstg"
    }
}

export const getCampaignMasterData = (context) => {

    let params = {
        "campaignId": 0,
    }
    connectServer({
        path: GET_CAMPAIGN_ATTRIBUTES_LIST,
        params: params,
        context: context,
        ok: res => {
            context.globalStateData.setCampaignMasterDropdownData(res.data)
            // this.setState({
            //     campaignDropdown: campaignDropdownData,
            //     campaignDropdownSelectedItem: campaignDropdownData[0]
            // })
            setCampaignMaster(res.data)
        },
        fail: err => {
        }
    })
}
export const getLanguageData = (context) => {
    let params = {
        "countryId": "1"
    }
    connectServer({
        path: GET_LANGUAGE_DATA,
        params,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: res => {
            if (res.status) {
                localStorage.setItem(
                    'lsLanguageData',
                    convertObjectToBase64(res.data !== null ? res.data : [])
                )
            }
        },
        fail: err => {
        }
    })
}
export const getEmailAudienceList = (context) => {
    let params = {
        searchText: "",
        campaignID: 0,
        channel: "E"
    }
    connectServer({
        path: GET_AUDIENCE_LIST,
        params,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: res => {
            if (res.status && res.message !== "No data") {
                localStorage.setItem('lsAudienceList', convertObjectToBase64(res.data))
            }
        },
        fail: err => {
        }
    })
}

export const getMobileAudienceList = (context) => {
    let params = {
        searchText: "",
        campaignID: 0,
        channel: "S"
    }
    connectServer({
        path: GET_AUDIENCE_LIST,
        params,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: res => {
            if (res.status && res.message !== "No data") {
                localStorage.setItem('lsAudienceList', convertObjectToBase64(res.data))
            }
        },
        fail: err => {
        }
    })
}
// get ipaddress 
export const getuserIp = async () => {
    let address = {};
    await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {

            address = data.ip;

        })
        .catch(error => {
            console.error('Error fetching IP address:', error);
        });
    return address
};

// get browser

export const getUserBrowser = () => {
    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.includes("Firefox")) {
        browserName = "Mozilla Firefox";
    } else if (userAgent.includes("Chrome")) {
        browserName = "Google Chrome";
    } else if (userAgent.includes("Safari")) {
        browserName = "Apple Safari";
    } else if (userAgent.includes("Edge")) {
        browserName = "Microsoft Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
        browserName = "Internet Explorer";
    } else {
        browserName = "Unknown";
    }

    return browserName;
};