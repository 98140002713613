import React, { useCallback, useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CSVImport from "./Components/CSVImport";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSDropdownList } from "Components/RSDropdowns";
import RemoteDataSource from "./Components/RemoteDataSource";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { SAVE_AUDIENCE_BY_CSV } from "Helper/Constants/endPoint"
import { connectServer } from "Helper/Network/networkHandler"
import { isEmpty } from "Helper/Utils/Utils";
import {
    IMPORT_DESCRIPTION, AUDIENCE_FILE_UPLOAD
} from "Helper/Constants/validationMessage"
import authContext from 'Helper/StateHandler/auth-context';
import { getFocusableElements } from "@progress/kendo-react-data-tools/dist/npm/navigation/utils";
import CSVTermsModal from "./Components/CSVTermsModal";

const AddAudience = (props) => {

    const history = useHistory();
    const context = useContext(authContext)

    const [audienceBy, setAudienceBy] = useState(history.location.state.audienceType === "rds" ? "Remote data source" : "-- Source --");
    const [isOpenCSVModal, setIsOpenCSVModal] = useState(false);
    const [isAddBtnClicked, setIsAddBtnClicked] = useState(history.location.state.audienceType === "rds" ? true : false);
    const [desc, setDesc] = useState('');
    const [filesPath, setFilesPath] = useState([]);
    const [rowstatus, setRowStatus] = useState('');
    const [errMsgAudienceBy, setErrMsgAudienceBy] = useState("")
    const [errMsgImportDesc, setErrMsgImportDesc] = useState("")
    const [errMsgFileUpload, setErrMsgFileUpload] = useState("")
    const [enableApplyButton, setEnableApplyButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //Call Backs
    const descback = useCallback((desc) => {
        setDesc(desc);
        if (desc !== '') {
            setErrMsgImportDesc('')
        }
    }, []);

    const fileback = useCallback((filepath) => {
        setFilesPath(filepath);
        if (filepath !== '') {
            setErrMsgFileUpload('')
        }
    }, []);

    const statusback = useCallback((rowstatus) => {
        setRowStatus(rowstatus);
    }, []);

    let getRowStatus
    const RenderComponents = (name) => {
        switch (name.toLowerCase()) {
            case 'csv':
                return <CSVImport
                    getFilePath={(value) => { setFilesPath(value) }}
                    parentImportDescription={descback}
                    errImportDesc={errMsgImportDesc}
                    parentFileImport={fileback}
                    errFileUpload={errMsgFileUpload}
                    getEnableApplyButton={(value) => { setEnableApplyButton(value) }}
                    parentFirstRow={statusback} />
            case 'remote data source':
                return <RemoteDataSource rsId={history.location.state.rdsId} mode={history.location.state.mode} onCancelClick={(value) => { setAudienceBy("-- Source --") }} isAddBtnClicked={history.location.state.audienceType === "rds"} onClickAddBtn={(value) => {
                    setIsAddBtnClicked(value);
                }} />
            default:
                break;
        }
    }
    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="">
                    <div className="header-title d-flex justify-content-between">
                        <h1>Add audience</h1>
                    </div>
                    <div className="portlet-box-theme p0">
                        <Row className="even py15 mx0 rs-radius-2">
                            <Col md={{ span: 2, offset: 3 }} className="text-end">
                                <label className="label-row">Add audience by</label>
                            </Col>
                            <Col md={3}>
                                <RSDropdownList
                                    data={["CSV", "Remote data source"]}
                                    className="mb0 required"
                                    value={audienceBy}
                                    defaultItem={"-- Source --"}
                                    onChange={(e) => {
                                        if (e.target.value.startsWith("C")) {
                                            setIsOpenCSVModal(true);
                                            setAudienceBy("")
                                        } else {
                                            setAudienceBy(e.target.value)
                                            setIsOpenCSVModal(false);
                                        }
                                        // if (e.target.value.startsWith("C")) {
                                        //     setIsAddBtnClicked(true);
                                        // } else {
                                        //     setIsAddBtnClicked(false);
                                        // }
                                    }}
                                />
                            </Col>
                        </Row>
                        {audienceBy.toLowerCase().includes('-- source --') !== true &&
                            RenderComponents(audienceBy)
                        }
                    </div>
                    {isOpenCSVModal &&
                        <CSVTermsModal isOpen={isOpenCSVModal} onChangeOpen={(value) => {
                            if (value) {
                                setAudienceBy("CSV")
                                setIsAddBtnClicked(true);
                            } else {
                                setAudienceBy("-- Source --")
                                setIsAddBtnClicked(false);
                            }
                            setIsOpenCSVModal(false);
                        }} />
                    }
                </div>
                {(!audienceBy.startsWith("-") && isAddBtnClicked) &&
                    <div className="btn-container d-flex justify-content-end">
                        {(audienceBy === 'CSV') &&
                            <RSSecondaryBtn onClick={() => {
                                setAudienceBy("-- Source --");
                            }}>Cancel</RSSecondaryBtn>
                        }
                        {(audienceBy === 'CSV') &&
                            <RSPrimaryBtn className={!enableApplyButton || desc === '' ? "click-off" : ""}
                                onClick={() => {
                                    //Validation
                                    if (audienceBy.toLowerCase().includes('-- source --') === false) {
                                        if (isEmpty(desc)) {
                                            let errMsgDesc = IMPORT_DESCRIPTION
                                            setErrMsgImportDesc(errMsgDesc)
                                            return
                                        }
                                        if (filesPath.length === 0) {
                                            let errMsgFilePath = AUDIENCE_FILE_UPLOAD
                                            setErrMsgFileUpload(errMsgFilePath)
                                            return
                                        }
                                        if (rowstatus) {
                                            getRowStatus = true
                                        }
                                        else {
                                            getRowStatus = false
                                        }
                                    }
                                    //Params  
                                    if (audienceBy === 'CSV') {
                                        var params = {
                                            importSource: 1,
                                            importDescription: desc,
                                            filePath: filesPath.toString(),
                                            listType: 1,
                                        }
                                    }
                                    connectServer({
                                        path: SAVE_AUDIENCE_BY_CSV,
                                        params: params,
                                        loading: context.globalStateData.setIsLoadingData,
                                        sessionOut: context.globalStateData.setSessionOutData,
                                        context: context,
                                        ok: res => {
                                            if (res.status) {
                                                history.push({ pathname: "/audience", state: { index: 0 } });
                                            }
                                        },
                                        fail: err => {
                                        }
                                    })
                                }}>{audienceBy === "CSV" ? "Upload" : "Connect"}</RSPrimaryBtn>
                        }
                    </div>}
            </Container>
        </LayoutPageWrapper>
    )
}
export default AddAudience