const ArchiveMedium = (props) => {
    const {color, onClick} = props
    return (
        <div className="ArchiveMedium cp" onClick={() => onClick && onClick()}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    class={`${color ?? 'th-primary-fill-color'}`}
                    strokeWidth="0"
                    d="M21.21 12.1h-1.03V2.6c0-.34-.28-.62-.62-.62H4.85c-.34 0-.62.28-.62.62v6.62H2.61c-.34 0-.62.28-.62.62v11.42c0 .34.28.62.62.62l18.6.08c.17 0 .32-.07.44-.18s.18-.28.18-.44v-8.63c0-.34-.28-.62-.62-.62zM5.47 3.22h13.45v8.85l-7.02-.06-1.66-2.51a.616.616 0 00-.52-.28H5.47v-6zm15.11 17.5l-17.35-.07V10.48h6.16l1.66 2.51c.11.17.31.28.52.28l9.02.07v7.38z"
                ></path>
                <path
                    class={`${color ?? 'th-primary-fill-color'}`}
                    strokeWidth="0"
                    d="M6.5 14.88c-.34 0-.62.28-.62.62s.28.62.62.62h5c.34 0 .62-.28.62-.62s-.28-.62-.62-.62h-5zM11.5 17.88h-5c-.34 0-.62.28-.62.62s.28.62.62.62h5c.34 0 .62-.28.62-.62s-.28-.62-.62-.62z"
                ></path>
            </svg>
        </div>
    )
}
export default ArchiveMedium;