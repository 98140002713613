import React from "react"
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom"

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) => {
    const history = useHistory()
    const handlePopState = (e) => {
        history.go(1)
    }
    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        }
    }, [])
    const navigate = props => {
        if (isAuth) {
            return <Component />
        } else {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
    }
    return <Route {...rest} render={navigate} />
}

export default ProtectedRoute;

export const NonProtectedRoute = ({ isAuth, component: Component, ...rest }) => {


    const history = useHistory()

    const handlePopState = (e) => {
        history.go(1)
    }
    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        }
    }, [])

    const navigate = props => {
        return !isAuth ? <Component /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />

    }
    return <Route {...rest} render={navigate} />
}