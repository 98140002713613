import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { RSDropdownList } from "Components/RSDropdowns";
import RSColorGradient from "Components/ColorGradient/RSColorGradient";
import RSEmojiPicker from "Components/Emoji/EmojiPicker";
import ImagePicker from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/ImagePicker";
import { getContent, rgbToHex, getFileToBase64, getUserData } from "Helper/Utils/Utils";
import InsertImageModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/MobileComponents/Components/InsertImageModal";
import InsertHyperlinkModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/MobileComponents/Components/InsertHyperlinkModal";
import { RSPTooltip } from "Components/Tooltip";
import SMSPreviewModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/MobileComponents/Components/SMSPreviewModal";
import { SuccessModal } from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal";
import { Tooltip } from "@progress/kendo-react-tooltip";
import SelectImageModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/SelectImageModal";
import SelectPDFModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/SelectPDFModal";
import SelectVideoModal from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/SelectVideoModal";
import PDFPicker from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/PDFPicker";
import VideoPicker from "Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/Components/VideoPicker";

export default class RESEditor extends Component {

  constructor(props) {
    super(props);
    this.anchor = React.createRef();

    this.contentRef = React.createRef();
    this.blurTimeoutRef = React.createRef();

    this.fontcoloranochor = React.createRef();
    this.backgroundcoloranochor = React.createRef();
    this.emojianochor = React.createRef();

  }

  state = {
    isShow: false,
    clicked: 0,
    isImagePickerModal: false,
    isVideoPickerModal: false,
    isPDFPickerModal: false,
    isSelectImageModal: false,
    isSelectPDFModal: false,
    isSelectVideoModal: false,
    isPreviewWeb: false,
    bodyContent: this.props.bodyContent,
    bodyContentPosition: this.props.bodyContentPosition,
    personalizeTabData: this.props.personalizeTapData,
    isBrowser: false,
    isInsertImageModal: false,
    isInsertHyperlinkModal: false,
    isOpenLiveSuccessModal: false
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({ bodyContent: this.props.bodyContent, personalizeTabData: this.props.personalizeTapData })
    }
    //alert(nextProps.bodyContent)  
    // if (nextProps.bodyContent !== this.state.bodyContent) {
    //   this.setState({ bodyContent: this.props.bodyContent })
    //   console.log('ture')
    // }
  }
  buttonClick = (clicked) => {
    this.setState({ clicked: clicked, isShow: true });
  };

  onOpen = e => {
    this.contentRef.current.focus()
  };

  onFocus = () => {
    // the user is still inside the content
    clearTimeout(this.blurTimeoutRef.current);
  };

  onBlurTimeout = () => {
    // the user is now outside the popup
    this.setState({ clicked: 0, isShow: false });
  };

  onBlur = () => {
    clearTimeout(this.blurTimeoutRef.current);

    this.blurTimeoutRef.current = setTimeout(this.onBlurTimeout, 200);
  };

  render() {
    return (
      <div className="editor-view-container">
        <div>
          <ListGroup horizontal>
            <ListGroup.Item
              className={this.state?.personalizeTabData?.length > 0 ? "" : "click-off"}
              style={{ backgroundColor: "transparent" }}
              ref={this.anchor}
            >
              {/* <RSPTooltip text="Personalize" position="top">
                <div className={``}>
                  <Tooltip anchorElement="target" position="top"> */}
              {/* <div className="title-tip title-tip-up" title='Personalize'> */}
              <div className="editor-ico">
                <DropDownButton
                  className=""
                  items={!!this.state.personalizeTabData && this.state.personalizeTabData}
                  // icon=" icon-user-help-small blue icons-md"
                  text={
                    <RSPTooltip
                      text="Personalize"
                      position="top"
                      show={false}>
                      <i className="icon-sd-user-question-mark-edge-medium blue icons-md"></i>
                    </RSPTooltip>
                  }
                  onItemClick={(e) => {
                    let itemText = this.props.isWhatsappCampaignText ? e.item : " " + e.item + " ";
                    let val = getContent(this.state.bodyContent, itemText, this.state.bodyContentPosition);
                    this.setState({ bodyContent: val });
                    this.props.callBack("bodytext", val);
                  }}
                />
              </div>
              {/* </div> */}
              {/* </Tooltip>
                </div>
              </RSPTooltip> */}

            </ListGroup.Item>

            {
              this.props.isWhatsappCampaignText &&
              <ListGroup.Item
                className={this.props.waTemplateType === 2 ? "" : "click-off"}
                style={{ backgroundColor: "transparent" }}>
                {/* <RSPTooltip text="Image upload" position="top"> */}
                <div className="editor-ico">
                  <DropDownButton
                    text={
                      <RSPTooltip
                        text="Image upload"
                        position="top"
                        show={false}>
                        <i className={`icon-sd-image-medium blue icons-md ${this.props.waTemplateType === 2 ? "" : "click-off"}`}></i>
                      </RSPTooltip>
                    }
                    // text={<i className={`icon-campaign-gallery-medium blue icons-md ${this.props.waTemplateType === 2 ? "" : "click-off"}`}></i>}
                    onItemClick={(e) => {
                      if (e.item.text === "Image URL") {
                        this.setState({ isImagePickerModal: !this.state.isImagePickerModal });
                      } else {
                        this.setState({ isSelectImageModal: true });
                      }
                    }}>
                    <DropDownButtonItem text="Browse" />
                    <DropDownButtonItem text="Image URL" />
                  </DropDownButton>
                  {/* <i className="icon-campaign-gallery-medium blue icons-md" onClick={() => {
                      this.setState({ isInsertImageModal: !this.state.isInsertImageModal })
                    }} /> */}
                </div>
                {/* </RSPTooltip> */}
              </ListGroup.Item>
            }
            {
              this.props.isWhatsappCampaignText &&
              <ListGroup.Item
                className={this.props.waTemplateType === 3 ? "" : "click-off"}
                style={{ backgroundColor: "transparent" }}>
                {/* <RSPTooltip text="Document upload" position="top"> */}
                <div className="editor-ico">
                  <DropDownButton
                    text={
                      <RSPTooltip
                        text="Document upload"
                        position="top"
                        show={false}>
                        <i className={`icon-sd-pdf-medium blue icon-lg ${this.props.waTemplateType === 3 ? "" : "click-off"}`}></i>
                      </RSPTooltip>
                    }
                    // text={<i className={`icon-pdf-medium blue icon-lg ${this.props.waTemplateType === 3 ? "" : "click-off"}`}></i>}
                    onItemClick={(e) => {
                      if (e.item.text === "Document URL") {
                        this.setState({ isPDFPickerModal: !this.state.isPDFPickerModal });
                      } else {
                        this.setState({ isSelectPDFModal: true });
                      }
                    }}>
                    <DropDownButtonItem text="Browse" />
                    <DropDownButtonItem text="Document URL" />
                  </DropDownButton>
                  {/* <i className="icon-campaign-gallery-medium blue icons-md" onClick={() => {
                      this.setState({ isInsertImageModal: !this.state.isInsertImageModal })
                    }} /> */}
                </div>
                {/* </RSPTooltip> */}
              </ListGroup.Item>
            }
            {
              this.props.isWhatsappCampaignText &&
              <ListGroup.Item
                className={this.props.waTemplateType === 4 ? "" : "click-off"}
                style={{ backgroundColor: "transparent" }}>
                {/* <RSPTooltip text="Video upload" position="top"> */}
                <div className="editor-ico">
                  <DropDownButton
                    text={
                      <RSPTooltip
                        text="Video upload"
                        position="top"
                        show={false}>
                        <i className={`icon-sd-builder-video-large blue icon-lg ${this.props.waTemplateType === 4 ? "" : "click-off"}`}></i>
                      </RSPTooltip>
                    }
                    // text={<i className={`icon-video-large blue icon-lg ${this.props.waTemplateType === 4 ? "" : "click-off"}`}></i>}
                    onItemClick={(e) => {
                      if (e.item.text === "Video URL") {
                        this.setState({ isVideoPickerModal: !this.state.isVideoPickerModal });
                      } else {
                        this.setState({ isSelectVideoModal: true });
                      }
                    }}>
                    {/* <DropDownButtonItem text="Browse" /> */}
                    <DropDownButtonItem text="Video URL" />
                  </DropDownButton>
                  {/* <i className="icon-campaign-gallery-medium blue icons-md" onClick={() => {
                      this.setState({ isInsertImageModal: !this.state.isInsertImageModal })
                    }} /> */}
                </div>
                {/* </RSPTooltip> */}
              </ListGroup.Item>
            }

            {
              (!this.props.isCampaignText && !this.props.isWhatsappCampaignText) &&
              <ListGroup.Item className="cursor-pointer" style={{ backgroundColor: "transparent" }}>
                <RSPTooltip text="Image" position="top">
                  <div className="browser-button">
                    <DropDownButton
                      text={<i className="icon-campaign-gallery-medium blue icons-md"></i>}
                      onItemClick={(e) => {
                        if (e.item.text === "Image URL") {
                          this.setState({ isImagePickerModal: !this.state.isImagePickerModal });
                        } else { }
                      }}>
                      <DropDownButtonItem text="Image URL" />
                      <DropDownButtonItem
                        text={
                          <div className="btn-file">
                            <div>Browse Image</div>
                            <input type="file" />
                          </div>
                        }
                      />
                    </DropDownButton>
                  </div>
                </RSPTooltip>
                {/* <DropDownButton
                className=""
                // items={galleryDropdownData}
                icon=" icon-campaign-gallery-medium blue icons-md"
                onItemClick={(e) => {
                  if (e.item.text == "Image URL") {
                    this.setState({ isImagePickerModal: !this.state.isImagePickerModal });
                  } else {
                    console.log(e);
                  }
                }}
              /> */}
                {/* <i className="icon-coupon-small icons-md blue" onClick={() => {
                this.setState({ isBrowser: !this.state.isBrowser})
              }}></i>
              <div>
                {
                  this.state.isBrowser && [1, 2].map((item, index) => {
                    return index === 0 ? <h1>Image URL</h1> : <input type='file' />;
                  })
                }
              </div> */}
              </ListGroup.Item>
            }

            {/* {
              this.props.isWhatsappCampaignText &&
              <ListGroup.Item style={{ backgroundColor: "transparent" }}>
                <RSPTooltip text="Hyper link" position="top">
                  <div className="editor-ico">
                    <i className="icon-attachment-medium -small blue icons-md" onClick={() => {
                      this.setState({ isInsertHyperlinkModal: !this.state.isInsertHyperlinkModal })
                    }} />
                  </div>
                </RSPTooltip>
              </ListGroup.Item>
            } */}
            {
              !this.props.isWhatsappCampaignText &&
              <ListGroup.Item
                style={{ backgroundColor: "transparent" }}
                ref={this.emojianochor}
                onClick={() => this.buttonClick(3)}
              >
                <RSPTooltip text="Emoji" position="top">
                  <div className="editor-ico">
                    <i className="icon-sd-circle-smile-medium blue icons-md"></i>
                  </div>
                </RSPTooltip>
              </ListGroup.Item>
            }

            {/* <ListGroup.Item
              style={{ backgroundColor: "transparent" }}
            >
              <DropDownButton
                className=""
                // items={smartlinkDropdownData}
                icon=" icon-link-down-small blue icons-md"
                onItemClick={(e) => {
                  this.setState({ bodyContent: this.state.bodyContent + e.item.text });
                  this.props.callBack("bodytext", this.state.bodyContent + e.item.text);
                }}
              />
            </ListGroup.Item> */}

            {
              (!this.props.isCampaignText && !this.props.isWhatsappCampaignText) &&
              <ListGroup.Item
                style={{ backgroundColor: "transparent" }}
                onClick={() => this.buttonClick(5)}
              >
                <RSPTooltip text="Coupon code" position="top">
                  <div className="editor-ico">
                    <i className="icon-coupon-small icons-md blue"></i>
                  </div>
                </RSPTooltip>
              </ListGroup.Item>
            }

            {
              this.props.mobilepush === true
                ? (
                  <>
                    <ListGroup.Item
                      style={{ backgroundColor: "transparent" }}
                      ref={this.fontcoloranochor}
                      onClick={() => this.buttonClick(6)}
                    >
                      <div className="picker-in picker-text"> <span></span> </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{ backgroundColor: "transparent" }}
                      ref={this.backgroundcoloranochor}
                      onClick={() => this.buttonClick(7)}
                    >
                      <div className="picker-in picker-icon"> <span></span> </div>
                    </ListGroup.Item>
                  </>
                )
                : null
            }

            <ListGroup.Item
              className={`cursor-pointer ${(this.props.isWhatsappCampaignText || this.props.isCampaignText) ? "" : "click-off"}`}
              style={{ backgroundColor: "transparent" }}
              onClick={() => {
                if (this.props.isWhatsappCampaignText) {
                  this.props.onChangePreviewOpen(true);
                }
                if (!this.props.isWhatsappCampaignText && this.state.bodyContent !== "") {
                  this.setState({
                    isPreviewWeb: !this.state.isPreviewWeb,
                  });
                }
              }}
            >
              <RSPTooltip text="Preview" position="top">
                <div className="editor-ico">
                  <i className={`icon-sd-eye-medium icons-md blue ${this.state.bodyContent !== "" ? "" : "click-off"}`}></i>
                </div>
              </RSPTooltip>
            </ListGroup.Item>
          </ListGroup>
        </div>

        <div>
          <textarea
            style={{ height: 170 }}
            className="bgWhite emoji-font"
            placeholder="Enter message"
            value={this.state.bodyContent}
            maxLength={this.props.isWhatsappCampaignText ? 1001 : this.props.isCampaignText && 501}
            onBlur={(event) => {
              this.props.callBack("onBlur", event);
              this.setState({ bodyContentPosition: event.target.selectionStart });

            }}
            onChange={(event) => {
              this.setState({ bodyContent: event.target.value });
              this.props.callBack("bodytext", event.target.value);
            }}
          />
        </div>

        {
          this.state.clicked === 1
            ? (
              <Popup
                anchor={this.anchor.current}
                show={this.state.isShow}
                popupClass={"popup-content"}
                style={{ width: 190, marginTop: 1 }}
              >
                <RSDropdownList
                  data={["Value 1", "Value 2", "Value 3"]}
                  value={"-- select personalize --"}
                  onChange={(event) => {
                    this.setState({ clicked: 0 });
                    this.setState({
                      bodyContent: this.state.bodyContent + event.target.value,
                    });
                    this.props.callBack("bodytext", this.state.bodyContent + event.target.value);
                  }}
                />
              </Popup>
            )
            : null
        }

        {
          this.state.clicked === 4
            ? (
              <Popup
                anchor={this.anchor.current}
                show={this.state.isShow}
                popupClass={"popup-content"}
                style={{ width: 190, marginTop: 1 }}
              >
                <RSDropdownList
                  data={["Smartlink1", "Smartlink2", "Smartlink3"]}
                  value={"-- select smartlink --"}
                  onChange={(event) => {
                    this.setState({ clicked: 0 });
                    this.setState({
                      bodyContent: this.state.bodyContent + event.target.value,
                    });
                    this.props.callBack("bodytext", this.state.bodyContent + event.target.value);
                  }}
                />
              </Popup>
            )
            : null
        }

        {this.state.clicked === 3 ? (
          <RSEmojiPicker
            popupClass="emoji-popup-wrap-modal"
            anchor={this.emojianochor}
            show={this.state.isShow}
            onOpen={this.onOpen}
            onFocus={this.onFocus}
            ref={this.contentRef}
            onBlur={this.onBlur}
            tabindex={0}
            emojiClose={() => {
              this.setState({
                clicked: 0, isShow: false
              })
            }}
            onEmojiClick={(value) => {
              // this.setState({ clicked: 0 });
              let val = getContent(this.state.bodyContent, value, this.state.bodyContentPosition);
              this.setState({ bodyContent: val, bodyContentPosition: this.state.bodyContentPosition + value.length });
              this.props.callBack("bodytext", val);
            }}
          />
        ) : null}

        {
          this.state.clicked === 6
            ? (
              <RSColorGradient
                anchor={this.fontcoloranochor}
                show={this.state.isShow}
                cancelBtnClicked={() => {
                  this.setState({ clicked: 0 });
                }}
                applyBtnClicked={(value) => {
                  var rgbaValue = value.split("(");
                  var redValue = parseInt(rgbaValue[1].split(",")[0], 16);
                  var greenValue = parseInt(rgbaValue[1].split(",")[1], 16);
                  var blueValue = parseInt(rgbaValue[1].split(",")[2], 16);
                  var hexValue = rgbToHex(redValue, greenValue, blueValue);
                  this.props.callBack("bodyTextColor", hexValue);

                  this.setState({ clicked: 0 });
                }}
              />
            )
            : null
        }

        {
          this.state.clicked === 7
            ? (
              <RSColorGradient
                anchor={this.backgroundcoloranochor}
                show={this.state.isShow}
                cancelBtnClicked={() => {
                  this.setState({ clicked: 0 });
                }}
                applyBtnClicked={(value) => {
                  var rgbaValue = value.split("(");
                  var redValue = parseInt(rgbaValue[1].split(",")[0], 16);
                  var greenValue = parseInt(rgbaValue[1].split(",")[1], 16);
                  var blueValue = parseInt(rgbaValue[1].split(",")[2], 16);
                  var hexValue = rgbToHex(redValue, greenValue, blueValue);
                  this.props.callBack("bodyBgColor", hexValue);

                  this.setState({ clicked: 0 });
                }}
              />
            )
            : null
        }

        {
          this.state.isImagePickerModal
            ? (
              <ImagePicker
                isOpen={this.state.isImagePickerModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isImagePickerModal: value });
                }}
                onCallback={(type, value) => {
                  this.props.onMediaCallback(type, value)
                }}
              ></ImagePicker>
            )
            : null
        }
        {
          this.state.isPDFPickerModal
            ? (
              <PDFPicker
                isOpen={this.state.isPDFPickerModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isPDFPickerModal: value });
                }}
                onCallback={(type, value) => {
                  this.props.onMediaCallback(type, value)
                }}
              ></PDFPicker>
            )
            : null
        }
        {
          this.state.isVideoPickerModal
            ? (
              <VideoPicker
                isOpen={this.state.isVideoPickerModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isVideoPickerModal: value });
                }}
                onCallback={(type, value) => {
                  this.props.onMediaCallback(type, value)
                }}
              ></VideoPicker>
            )
            : null
        }
        {
          this.state.isSelectImageModal
            ? (
              <SelectImageModal
                isOpen={this.state.isSelectImageModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isSelectImageModal: value });
                }}
                onCallBack={(value) => {
                  this.props.callBack("imagePath", value)
                }}
              ></SelectImageModal>
            )
            : null
        }
        {
          this.state.isSelectPDFModal
            ? (
              <SelectPDFModal
                isOpen={this.state.isSelectPDFModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isSelectPDFModal: value });
                }}
                onCallBack={(value) => {
                  this.props.callBack("PDFPath", value)
                }}
              ></SelectPDFModal>
            )
            : null
        }
        {
          this.state.isSelectVideoModal
            ? (
              <SelectVideoModal
                isOpen={this.state.isSelectVideoModal}
                onChangeIsOpen={(value) => {
                  this.setState({ isSelectVideoModal: value });
                }}
                onCallBack={(value) => {
                  this.props.callBack("VideoPath", value)
                }}
              ></SelectVideoModal>
            )
            : null
        }

        {this.state.isPreviewWeb ? (
          <SMSPreviewModal
            smsChannelId={this.props.smsChannelId}
            campaignId={this.props.campaignId}
            mobileSMSData={this.props.mobileSMSData}
            isOpen={this.state.isPreviewWeb}
            isWeb={this.props.mobilepush ? false : true}
            mobileNumber={this.props.mobileNumber}
            schedule={this.props.schedule}
            countryCodeMobile={this.props.countryDialCodeMobile}
            previewContent={this.state.bodyContent}
            channelDetails={this.props.channelDetails || {}}
            totalaudience={this.props.totalaudience}
            senderId={this.props.senderId}
            language={this.props.language}
            onChangeIsOpen={(value) => {
              if (value) {
                this.setState({ isOpenLiveSuccessModal: true, isPreviewWeb: false });
              } else {
                this.setState({ isPreviewWeb: false });
              }
            }}
            onChangeSmsChannelId={(value) => {
              this.props.onChangeSmsChannelId(value)
            }}
            onValidateSearch={(status) => this.props.onValidateSearch(status)}
            isSearchValidate={this.props.isSearchValidate}
          ></SMSPreviewModal>
        ) : null}
        {this.state.isInsertImageModal &&
          <InsertImageModal isOpen={this.state.isInsertImageModal} onChangeIsOpen={(value) => {
            this.setState({ isInsertImageModal: value });
          }}></InsertImageModal>
        }
        {this.state.isInsertHyperlinkModal && <InsertHyperlinkModal isOpen={this.state.isInsertHyperlinkModal} onChangeIsOpen={(value) => {
          this.setState({ isInsertHyperlinkModal: value });
        }}></InsertHyperlinkModal>}
        {
          this.state.isOpenLiveSuccessModal &&
          <SuccessModal isOkButton={false} isOpen={this.state.isOpenLiveSuccessModal} title={"Success"} subTitle={"Live communication will be sent shortly."} buttonClicked={(value) => {
            this.setState({ isOpenLiveSuccessModal: false })
          }} />
        }
      </div>
    );
  }
}