import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import SubscriptionInvoice from './Tabs/SubscriptionInvoice';
import ConsumableInvoice from './Tabs/ConsumableInvoice';

import { RTabbar } from "Components/RChart/RTabbar";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import Invoice from "./Invoice";
import { isAllowToAddUser } from "Helper/Utils/Utils";
import { useEffect } from "react";

const InVoices = () => {

  let match = useRouteMatch();

  return <Switch>
    <Route exact path={`${match.url}/`}>
      <InvoicesHome />
    </Route>
    <Route path={`${match.url}/invoice`}>
      <Invoice />
    </Route>
  </Switch>

}

export default InVoices;

const InvoicesHome = props => {

  const [index, setIndex] = useState(0)
  const [tabData, setTabData] = useState([
    { "id": 1001, "text": "Subscription invoice", "disable": false },
    { "id": 1002, "text": "Consumables invoice", "disable": false }
  ])
  const [isAllowtoShow, setIsAllowtoShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
    let isAllow = isAllowToAddUser();
    if (!isAllow) {
      let tempTabData = [...tabData];
      tempTabData[0].disable = true;
      setTabData(tempTabData);
      setIndex(1);
    }
    setIsAllowtoShow(isAllow);
  }, [])

  const history = useHistory()

  const renderComponent = index => {
    var renderComponent;
    if (index === 0) {
      renderComponent = <SubscriptionInvoice isAllow={isAllowtoShow}></SubscriptionInvoice>
    } else if (index === 1) {
      renderComponent = <ConsumableInvoice></ConsumableInvoice>
    }
    return renderComponent
  }

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">

        <div className="header-title d-flex justify-content-between">
          <h1>Invoice list</h1>
          <div className="back-text" onClick={() => history.push(`/preferences`)}>
            <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
            Back
          </div>
        </div>

        {/* <hr className="blue" /> */}

        <div className="tabs-filled-component-del">
          <RTabbar
            defaultSelectedItem={index}
            defaultClass="tabDefault"
            dynamicTab="tabs-filled-component"
            activeClass="tabDefault active"
            tabData={tabData}
            callBack={(item, index) => { setIndex(index) }}
          />
          {renderComponent(index)}
        </div>
      </Container>
    </LayoutPageWrapper>
  )
}
