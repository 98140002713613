import React from "react";
import { Col, Row } from "react-bootstrap";
import { ICONLIST } from "./constants";
import * as images from "Assets/Images";

const ContentIcons = ({ containers, onDragEnd }) => {
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDragStart = (id) => {
    return (event) => event.dataTransfer.setData("task", id);
  };

  return (
    <div className="rs-builder-elements-holder border-top-0 bgGrey-lighter" onDragOver={handleDragOver}>
      <Row>
        <ul style={{ display: "flex", justifyContent: "space-evenly" }}>
          {ICONLIST.map((ele, ind) => {
            return (
              <Col sm={3} key={ele?.id + ind}>
                <li
                  key={ele.content}
                  className={
                    ele.id == 5 && containers.some((e) => e.id == 5)
                      ? "click-off"
                      : ""
                  }
                  onDragStart={handleDragStart(ele.id)}
                  draggable
                  onMouseLeave={onDragEnd}
                >
                  <div className="rs-element-icon-holder">
                    <img src={`${ele.elementImage}`} alt={ele.content} />
                    <h6 className="mt10 mb-0">{ele.content}</h6>
                  </div>
                </li>
              </Col>
            );
          })}
        </ul>
      </Row>
    </div>
  );
};

export default React.memo(ContentIcons);
