import { RSPTooltip } from "Components/Tooltip"
import { RSPPophover } from 'Components/Tooltip';

const Icon = (props) => {
    const {icon, size, color, bg, tooltip, pophover, position, iconClass, circle, nocp, mainClass, inline, onClick} = props?.config || props
    return (
        <div className={`rsp-icon rsp-icon-${size ? size : 'default'} ${circle ? 'rsp-circle' : ''} ${mainClass ? mainClass : ''} ${inline ? 'inline' : ''}`}>
            {
                tooltip
                    ? <RSPTooltip position={position ? position : 'top'} text={tooltip}>
                        <i className={`${icon} icons-${size ? size : 'default'} ${circle ? '' : color ? color : 'blue'} ${bg ? bg : ''} ${iconClass ?? ''} ${nocp ? '' : 'cursor-pointer'}`} onClick={()=> onClick && onClick()} {...props} />
                    </RSPTooltip>
                    : pophover
                        ? <RSPPophover position={position ? position : 'top'} text={pophover}>
                            <i className={`${icon} icons-${size ? size : 'default'} ${circle ? '' : color ? color : 'blue'} ${bg ? bg : ''} ${iconClass ?? ''} ${nocp ? '' : 'cursor-pointer'}`} onClick={()=> onClick && onClick()} {...props} />
                        </RSPPophover>
                        : <i className={`${icon} icons-${size ? size : 'default'} ${circle ? '' : color ? color : 'blue'} ${bg ? bg : ''} ${iconClass ?? ''} ${nocp ? '' : 'cursor-pointer'}`} onClick={()=> onClick && onClick()} {...props} />
            }
        </div>
    )
}
export default Icon

export const Icons = (props) => {
    const {children, className} = props
    return (
        <div className={`rsp-icon-group ${className ?? ''}`}>
            {children}
        </div>
    )
}