import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { Switch } from '@progress/kendo-react-inputs';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { CAMP_PERFORMANCE, PERIOD_TYPE, PERIOD_VALUE } from 'Helper/Constants/validationMessage';
import { isEmpty } from 'Helper/Utils/Utils';
const periodDropData = ["Hour(s)", "Day(s)", "Week(s)", "Month(s)"];

class SplitSettingsModal extends Component {
    state = {
        isShow: this.props.isOpen,
        isAutoSchedule: this.props.splitDetails.isAutoSchedule,
        campPerformanceBy: this.props.splitDetails.campaignPerFormanceBy,
        periodValue: this.props.splitDetails.duration,
        periodType: this.props.splitDetails.period,
        errCampPerformanceByMsg: null,
        errPeriodValueMsg: null,
        errPeriodTypeMsg: null
    }
    render() {
        return (
            <Modal backdrop="static"
                keyboard={false} show={this.state.isShow} centered size='lg' onHide={() => {
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header closeButton>
                    <h2>Schedule</h2>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: "30px", position: "relative" }}>
                        <Row>
                            <Col md={4}>
                                <label className="label-row">Auto schedule</label>
                            </Col>
                            <Col md={7}>
                                <Switch
                                    onLabel={"ON"}
                                    offLabel={"OFF"}
                                    onChange={(event) => {
                                        this.props.splitDetails.isAutoSchedule = event.target.value;
                                        this.setState({ isAutoSchedule: event.target.value });
                                    }}
                                    checked={this.state.isAutoSchedule}
                                />
                            </Col>
                        </Row>
                        {this.state.isAutoSchedule &&
                            <>
                                <Row className='mt30'>
                                    <Col md={4}>
                                        <label>Communication performance by</label>
                                    </Col>
                                    <Col md={7}>
                                        <div className="position-relative">
                                            {this.state.errCampPerformanceByMsg && (
                                                <div className="validation-message">
                                                    {this.state.errCampPerformanceByMsg}
                                                </div>
                                            )}
                                            <Row>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Subject line"
                                                        name={"campPerformance"}
                                                        labelId={"campPerformanceId"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === "Subject line" ? true : false}
                                                        cb={(event) => {
                                                            this.props.splitDetails.campaignPerFormanceBy = event.target.value;
                                                            this.setState({ campPerformanceBy: event.target.value, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Content"
                                                        name={"campPerformance1"}
                                                        labelId={"campPerformanceId1"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === "Content" ? true : false}
                                                        cb={(event) => {
                                                            this.props.splitDetails.campaignPerFormanceBy = event.target.value;
                                                            this.setState({ campPerformanceBy: event.target.value, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Schedule"
                                                        name={"campPerformance2"}
                                                        labelId={"campPerformanceId2"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === "Schedule" ? true : false}
                                                        cb={(event) => {
                                                            this.props.splitDetails.campaignPerFormanceBy = event.target.value;
                                                            this.setState({ campPerformanceBy: event.target.value, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        {/* <label>Auto schedule</label> */}
                                    </Col>
                                    <Col md={7}>
                                        <ul className="time-row">
                                            <li>
                                                <i className='icon-timer-large blue icons-md' />
                                            </li>
                                            <li>
                                                <label>Start in</label>
                                            </li>
                                            <li>
                                                <RSInput2
                                                    ph="Enter value"
                                                    cls='mb0'
                                                    val={this.state.periodValue}
                                                    cb={(text) => {
                                                        let errMsg = text <= 0 ? PERIOD_VALUE : null;
                                                        this.props.splitDetails.duration = text;
                                                        this.setState({ periodValue: text, errPeriodValueMsg: errMsg });
                                                    }}
                                                    errorMessage={this.state.errPeriodValueMsg} />
                                            </li>
                                            <li>
                                                <div className="position-relative">
                                                    {this.state.errPeriodTypeMsg && (
                                                        <div className="validation-message">
                                                            {this.state.errPeriodTypeMsg}
                                                        </div>
                                                    )}
                                                    <BootstrapDropdown
                                                        data={periodDropData}
                                                        defaultItem={"Select period"}
                                                        customAlignRight={true}
                                                        className="fs16-btn"
                                                        onSelect={(item, index) => {
                                                            let errMsg = item === "" ? PERIOD_TYPE : null;
                                                            this.props.splitDetails.period = item;
                                                            this.setState({ periodType: item, errPeriodTypeMsg: errMsg });
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <RSSecondaryBtn
                        onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn
                        onClick={() => {
                            if (this.state.isAutoSchedule) {
                                if (isEmpty(this.state.campPerformanceBy)) {
                                    this.setState({ errCampPerformanceByMsg: CAMP_PERFORMANCE });
                                    return false;
                                }
                                if (this.state.periodValue <= 0) {
                                    this.setState({ errPeriodValueMsg: PERIOD_VALUE });
                                    return false;
                                }
                                if (isEmpty(this.state.periodType)) {
                                    this.setState({ errPeriodTypeMsg: PERIOD_TYPE });
                                    return false;
                                }
                            }
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Save</RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SplitSettingsModal;