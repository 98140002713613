import React, { useEffect, useState } from "react";
import mobileViewImg from "Assets/Images/preview-mobile-1.png";
import { dateFormat } from 'Helper/Utils/Utils';
const defaultValue = new Date();
const Nperview = (props) => {
  const [bodyTextCount, setBodyTextCount] = useState("");
  const [smsCount, setSMSCount] = useState("");
  const [bodyContent, setBodyContent] = useState(props.body);
  const [contentlimit, setContentLimit] = useState(props.isCampaignText ? 500 : 1000)
  useEffect(() => {
    if (bodyContent !== props.body) {
      if (props.body.length > 0 && props.smartUrls.length > 0 && props.shortenLinks.length > 0) {
        let tempVal = props.body
        props.smartUrls.map((item, index) => {
          if (index < 2) {
            tempVal = tempVal.replaceAll(item, props.shortenLinks[index])
          }
        })
        if (tempVal.length <= contentlimit) {
          setBodyContent(tempVal);
          props.getErrMessage(null);
        } else {
          props.getErrMessage("Communication content size should not be more than " + contentlimit.toString())
        }
      } else {
        if (props.body.length <= contentlimit) {
          setBodyContent(props.body);
          props.getErrMessage(null);
        } else {
          props.getErrMessage("Communication content size should not be more than " + contentlimit.toString())
        }
      }
    }
    if (props.body.length <= contentlimit) {
      let textCount = props.body.length > 0 ? props.body.length.toString() + "/" : "";
      let smsCount = props.isCampaignText ? Math.floor(props.body.length / 140) + 1 : 1;
      setBodyTextCount(textCount);
      setSMSCount(smsCount.toString());
    }
  })

  return (
    <div className="preview-mobile-popup">
      <>
        {/* <img src={mobileViewImg} alt="mobile popup" /> */}
        <div className="mb-content-box sms-popup top-0" >
          <div className="mb-box-head visually-hidden">
            <div className="d-flex align-items-center">
              <i className="icon-sd-arrow-left-mini"></i> Back
            </div>
            <div className="blue">{props.mobileNumber}</div>
            <div>Contact</div>
          </div>
          <div className="mb-box-body">
            <ul className="message-box">
              <li>
                {/* <div>Renew your fixed deposit today to keep them happy. <span className="rs-link">https://mtkgstr.io/ap7Xgb</span></div> */}
                <div className='css-scrollbar max-height-sms-pop emoji-font'>
                  {bodyContent}
                </div>
              </li>
            </ul>
            <div className="d-flex justify-content-between align-items-center mt5">
              <small className="fs12">{dateFormat(defaultValue)}</small>
              <small className="fs12">{bodyTextCount + contentlimit.toString()} <span>{smsCount} {props.isCampaignText ? "SMS" : "WhatsApp"}/Audience</span></small>
            </div>
          </div>
        </div>
      </>
    </div >
  )
}

export default Nperview