import React, { useState, useEffect } from "react";

import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";

import HowerIcon from "./HowerIcons";
import ContentIcons from "./ContentIcons";
import SocialFollow from "./SocialFollows";
import SOCIAL_ICONS from "./SocialFollows/constant";
import {
  CONTENTLEFT,
  CONTENTRIGHT,
  CONTENTTEXT,
  CONTENTNAVIGATION,
  BODYCONFIG,
  BODYCONFIGIMAGE,
} from "./constants";
import RSTinyMceInlineEditor from "./TyniMceEditor";
import EmailFooterCreateModal from "./SaveTemplate";
import { getServerConfig } from "Helper/Utils/Utils";
import PreviewModal from "./PreviewModal";

const EmailFooterCreation = ({ edit, onChangeIndex, getDatFromServer }) => {
  let path = "\\Images\\EmailFooter\\SocilaIMedia\\";

  const [socialContentAll, setSocialContentAll] = useState(SOCIAL_ICONS);
  const [moveDragId, setMoveDragId] = useState(null);
  const [socialFlag, setSocialFlag] = useState(false);
  const [dropHereId, setDropHereId] = useState(null);
  const [templateCreateFlag, setTemplateCreateFlage] = useState(false);
  const [contentSocial, setContentSocial] = useState(
    [...socialContentAll]
      .map(
        (e) =>
          `<td> <img src=${getServerConfig()}${path}${e.icon} alt={${e.title
          }} /> </td>`
      )
      .join()
  );
  const [containers, setContainers] = useState([
    { id: 1, name: "contentNavigation", text: CONTENTNAVIGATION },
    { id: 2, name: "contentRight", text: CONTENTRIGHT },
    { id: 3, name: "contentLeft", text: CONTENTLEFT },
    { id: 4, name: "contentText", text: CONTENTTEXT },
    // { id: 5, name: "contentSocial", text: contentSocial },
  ]);
  const [preview, setPreview] = useState({
    show: false,
    data: {},
  });


  useEffect(() => {
    if (edit !== null) {
      setContainers(JSON.parse(edit?.emailFooterHtml));
    }
  }, []);
  const [isDropped, setIsDropped] = useState(false);
  const [tableBG, setTableBG] = useState("#ffffff");

  useEffect(() => {
    const social = [...socialContentAll];
    let convertTDTag = social.map(
      (e) =>
        `<img src=${getServerConfig()}${path}${e.icon} alt={${e.title
        } width="30" style="margin-right:10px" />`
    );
    setContentSocial(
      `<table width="100%" border="0" cellPadding="0" cellspacing="0" style="background-color:#f4f4f4;"><tr><td align="center" style="padding:10px;">${convertTDTag.join(
        ""
      )}</td></tr></table>`
    );
  }, [socialContentAll]);

  useEffect(() => {
    let social = [...socialContentAll].slice(0, 4);
    setSocialContentAll(social);
  }, []);

  // function onNodeChange(e) {
  //   const node = e.element;
  //   if (node.tagName === "IMG") {
  //     const src = node.getAttribute("src");
  //     const alt = node.getAttribute("alt");
  //     setSocialFlag(true);
  //   }
  // }

  const handleDragOver = (event, i) => {
    event.preventDefault();
    setIsDropped(true);
    setDropHereId(i);
  };

  const onDragEnd = () => {
    if (isDropped) setIsDropped(false);
  };

  const dragChange = (event, id) => {
    // event.preventDefault();
    setMoveDragId(id);
    event.dataTransfer.setData("move", "icon");
  };

  // var hovweIconsImages = document.querySelectorAll("img.hovweIcons");

  // Loop through each image and remove it
  // hovweIconsImages.forEach(function(image) {
  //     image.parentNode.removeChild(image);
  // });

  const handleDoDrop = (event, ind) => {
    event.preventDefault();
    setIsDropped(false);
    const taskId = Number(event.dataTransfer.getData("task"));
    const move = event.dataTransfer.getData("move");
    const newContent = { id: taskId, text: CONTENTNAVIGATION };
    const newContentRight = { id: taskId, text: CONTENTRIGHT };
    const newContentleft = { id: taskId, text: CONTENTLEFT };
    const newContentText = { id: taskId, text: CONTENTTEXT };
    const newContentSocial = { id: taskId, text: contentSocial };
    let cloneContainer = [...containers];
    if (ind == moveDragId) return;
    if (move === "icon") {
      let clone = [...cloneContainer];
      let removedEle = clone.splice(moveDragId, 1)[0];
      clone.splice(ind - 1, 0, removedEle);
      setContainers(clone);
      return;
    }
    if (taskId == 1) {
      cloneContainer.splice(ind, 0, newContent);
    } else if (taskId == 2) {
      cloneContainer.splice(ind, 0, newContentRight);
    } else if (taskId == 3) {
      cloneContainer.splice(ind, 0, newContentleft);
    } else if (taskId == 4) {
      cloneContainer.splice(ind, 0, newContentText);
    } else if (taskId == 5) {
      cloneContainer.splice(ind, 0, newContentSocial);
    }
    setContainers([...cloneContainer]);
    setDropHereId(null);
  };

  // sidebar for social content
  // const addSocialContent = (ele) => {
  //   let social = [...socialContentAll];
  //   if (!social.some((e) => e.id == ele.id) && social.length < 6) {
  //     social.push(ele);
  //     setSocialContentAll(social);
  //   }
  // };

  const removeSocialContent = (index) => {
    let social = [...socialContentAll];
    social.splice(index, 1);
    setSocialContentAll(social);
  };
  const removeContent = (ind) => {
    let cloneContainer = [...containers];
    if (cloneContainer.length == 1) return;
    cloneContainer.splice(ind, 1);
    setContainers(cloneContainer);
  };

  const handleSave = (e) => {
    e.preventDefault();
    var htmlContent = document.getElementById("cardId").innerHTML;
    const iconElements = document.querySelectorAll(".hovweIcons");
    // Iterate over each icon element and remove it
    iconElements.forEach((icon) => {
      icon.remove();
    });
    setTemplateCreateFlage(true);
  };

  const handleEditor = (eve, con, ele, ind) => {
    let cloneContainer = [...containers];
    cloneContainer.splice(ind, 1, { ...ele, text: con.getContent() });
    setContainers(cloneContainer);
  };

  const handlePreview = (e) => {
    e.preventDefault();
    var result = document.getElementById("cardId")?.outerHTML;
    var removeIcons = document.getElementById("cardId");
    removeIcons.classList.remove("hovweIcons");
    setPreview({
      show: true,
      data: result,
    });
  };

  return (
    <div className="rsv-tabs-content">
      <form>
        <div className="mt20">
          {/* Content starts */}
          <div className="rs-sub-heading rss-left">
            <h4>Email footer</h4>
          </div>
          <ContentIcons containers={containers} onDragEnd={onDragEnd} />
          <SocialFollow
            data={{
              socialContentAll,
              removeSocialContent,
              setSocialContentAll,
              setSocialFlag,
              socialFlag,
            }}
          />
          <div className="rs-builder-elements-dropped-wrapper rsbedw-email-footer-builder">
            {/* <div className="flex-right rs-builder-colorpicker-container">
                            <RSColorPicker icon={ ""} onSelect={(color) => setTableBG(color)} />
                        </div> */}
            <div
              id={"cardId"}
              className="rs-builder-elements-content-wrapper p0 "
            >
              <table
                width="600"
                style={{
                  backgroundColor: tableBG,
                }}
                cellspacing="0"
                cellPadding="0"
                border="0"
                align="center"
                className=""
              >
                <tbody>
                  <tr>
                    <td>
                      {containers.map((ele, ind) => {
                        return (
                          <div
                            key={ind}
                            onDragOver={(e) => {
                              handleDragOver(e, ind);
                            }}
                            onDrop={(e) => {
                              handleDoDrop(e, ind);
                            }}
                            className="rsbecw-row"
                          >
                            {isDropped && dropHereId === ind && (
                              <div className="rs-form-builder-drop-box">
                                <p className="text-center">Drop here...</p>
                              </div>
                            )}
                            {ele?.id == 1 && (
                              <div
                                id={`contentCard${ind}navigate`}
                                draggable={false}
                              // className={`rs-pop-view ${
                              //   isDropped ? "click-off" : ""
                              // }`}
                              >
                                <HowerIcon
                                  data={{
                                    removeContent,
                                    dragChange,
                                    ind,
                                  }}
                                />
                                <RSTinyMceInlineEditor
                                  id={`contentCard${ind}contentNavigation`}
                                  initialValue={ele?.text}
                                  init={BODYCONFIG}
                                  name={`${ele?.name}${ind}`}
                                  handleChange={(eve, con) =>
                                    handleEditor(eve, con, ele, ind)
                                  }
                                />
                              </div>
                            )}

                            {ele?.id == 2 && (
                              <div
                                id={`contentCard${ind}contentRight`}
                                draggable={false}
                                className={`rs-pop-view clearfix ${isDropped ? "click-off" : ""
                                  }`}
                              >
                                <HowerIcon
                                  data={{
                                    removeContent,
                                    dragChange,
                                    ind,
                                  }}
                                />
                                <RSTinyMceInlineEditor
                                  name={`${ele?.name}${ind}`}
                                  initialValue={ele?.text}
                                  init={BODYCONFIG}
                                  handleChange={(eve, con) =>
                                    handleEditor(eve, con, ele, ind)
                                  }
                                />
                              </div>
                            )}
                            {ele?.id == 3 && (
                              <div
                                id={`contentCard[${ind}]contentLeft`}
                                draggable={false}
                                className={`rs-pop-view clearfix ${isDropped ? "click-off" : ""
                                  }`}
                              >
                                <HowerIcon
                                  data={{
                                    removeContent,
                                    dragChange,
                                    ind,
                                  }}
                                />
                                <RSTinyMceInlineEditor
                                  initialValue={ele?.text}
                                  init={BODYCONFIG}
                                  name={`${ele?.name}${ind}`}
                                  handleChange={(eve, con) =>
                                    handleEditor(eve, con, ele, ind)
                                  }
                                />
                              </div>
                            )}
                            {ele?.id == 4 && (
                              <div
                                id={`contentCard${ind}contentText`}
                                draggable={false}
                                className={`rs-pop-view clearfix ${isDropped ? "click-off" : ""
                                  }`}
                              >
                                <HowerIcon
                                  data={{
                                    removeContent,
                                    dragChange,
                                    ind,
                                  }}
                                />
                                <RSTinyMceInlineEditor
                                  initialValue={ele?.text}
                                  init={BODYCONFIG}
                                  name={`${ele?.name}${ind}`}
                                  handleChange={(eve, con) =>
                                    handleEditor(eve, con, ele, ind)
                                  }
                                />
                              </div>
                            )}
                            {/* {ele?.id == 5 && (
                              <div
                                onDragOver={(e) => {
                                  handleDragOver(e, ind);
                                }}
                                draggable={false}
                                className={`rs-pop-view clearfix ${
                                  isDropped ? "click-off" : ""
                                }`}
                              >
                                <HowerIcon
                                  data={{
                                    removeContent,
                                    dragChange,
                                    ind,
                                  }}
                                />
                                <RSTinyMceInlineEditor
                                  initialValue={contentSocial}
                                  init={BODYCONFIGIMAGE}
                                  onNodeChange={onNodeChange}
                                  name={`${ele?.name}${ind}`}
                                  handleChange={(eve, con) =>
                                    handleEditor(eve, con, ele, ind)
                                  }
                                />
                              </div>
                            )} */}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="btn-container d-flex justify-content-end marginT15">
          <RSSecondaryBtn
            onClick={() => {
              onChangeIndex(0);
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn onClick={handlePreview} className="mr10">
            Preview
          </RSPrimaryBtn>
          <RSPrimaryBtn onClick={handleSave}>
            {edit === null ? "Save" : "Update"}
          </RSPrimaryBtn>
        </div>
      </form>
      <>
        <PreviewModal
          show={preview?.show}
          handleClose={() =>
            setPreview((prev) => ({
              ...prev,
              show: false,
            }))
          }
          data={preview?.data}
        />
      </>
      <EmailFooterCreateModal
        onChangeIndex={onChangeIndex}
        templateCreateFlag={templateCreateFlag}
        setTemplateCreateFlage={setTemplateCreateFlage}
        containers={containers}
        getDatFromServer={getDatFromServer}
        edit={edit}
        data={preview?.data}
      />
    </div>
  );
};

export default EmailFooterCreation;
