import React, { Component, useContext } from "react";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker";
import { SearchViewAdvanced } from "Components/SearchView/SearchView";
import { RSPTooltip } from "Components/Tooltip";
import SegmentDynamicInfo from "./SegmentDynamicInfo";
import SuppersionModal from "./SuppersionModal";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import ShareModalComponent from "./ShareModalComponent";
import { DELETE_SEGEMENTATION_LIST, DUPLICATE_SEGMENTATION_LIST, GET_SUBLIST } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import SkeletonTable from 'Components/Skeleton/SkeletonTable'
import authContext from 'Helper/StateHandler/auth-context'
import EditListNameModal from "./EditListNameModal";
import { KendoIconDropdown } from "Components/KendoDropdown/IconDropdown";
import { dateFormat, dateFormatWithHyphen, dateFormatWithMomentForInvoice, getUserData, numberFormatter } from "Helper/Utils/Utils";
import CustomFilter from 'Components/Grid/CustomFilter';
import CustomDateFilter from 'Components/Grid/CustomDateFilter';
import ListDownloadModal from "./ListDownloadModal";
import { RSPTimer } from "Components/RSPTimer";
import Timer, { RSPTimerCustom } from "Components/RSPTimerCustom";
import { DownloadAlertModal } from "../../SegmentationCreation/Components/DownloadAlertModal";
import { FirefoxLogoIcon, paymentPendingMedium } from "Assets/Images";
import PaymentPendingMedium from "Components/SVG/PaymentPendingMedium";
import KendoGrid from 'Components/RSKendoGrid/RSKendoGrid';

const dataState = {
  skip: 0,
  take: 5,
  sort: [
    {
      field: 'orderDate',
      dir: 'desc',
    },
  ],
};

const DynamicList = props => {
  let match = useRouteMatch()
  let history = useHistory()
  const context = useContext(authContext)
  return <SubscriptionInvoice history={history} match={match} context={context} />
}

class ActionCell extends React.Component {

  state = {
    isOpenInfoModal: false,
    isSuppersionModal: false,
    isDeleteModal: false,
    isShareModal: false,
    isOpenEditListNameModal: false,
    isOpenDownloadModal: false,
    isOpenPaymentPendingModal: false
  }

  render() {
    return (
      <td>
        <div className="table-action-btn">
          <RSPTooltip position="top" text="Edit">
            <i className={`icon-sd-pencil-edit-medium icons-md blue cp ${this.props.dataItem?.isEditable ? "" : "click-off"}`}
              onClick={() => {
                // this.props.history.push({ pathname: `audience/new-segmentation-creation`, state: { mode: 'edit' } })
                this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
                this.props.editBtnClicked(!this.state.isOpenEditListNameModal);
              }} />
          </RSPTooltip>
          <RSPTooltip position="top" text="Duplicate">
            <i className={`icon-sd-duplicate-medium icons-md blue cp ${this.props.dataItem.listType === 5 ? "" : "click-off"}`}
              onClick={() => {
                this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
                this.props.duplicateBtnClicked(this.props.dataItem?.segmentationListID);
              }} />
          </RSPTooltip>
          {
            (this.props.dataItem.listType === 5 && !this.props.dataItem.isDownloadable)
              ? <div className={`ml8 mr7 cp ${this.props.dataItem.islistCreated ? "" : "click-off"}`}>
                {/* <img src={paymentPendingMedium} alt='payment pending' className={`${this.props.dataItem.islistCreated ? "" : "click-off"}`} onClick={() => {
                  this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
                  this.props.downloadBtnClicked(this.props.dataItem.isDownloadable);
                }} /> */}
                <RSPTooltip position="top" text="Payment pending">
                  <div
                    className={`${this.props.dataItem.islistCreated ? "" : "click-off"}`}
                    onClick={() => {
                      this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
                      this.props.downloadBtnClicked(this.props.dataItem.isDownloadable);
                    }}>
                    <PaymentPendingMedium color={'th-primary-fill-color'} />
                  </div>
                </RSPTooltip>
              </div>
              : <RSPTooltip text="Download CSV" className={"float-end"} position="top">
                <i className={`icon-sd-csv-medium icons-md blue cp ${(this.props.dataItem.listType === 5 && this.props.dataItem.islistCreated) ? "" : "click-off"}`}
                  onClick={() => {
                    this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
                    this.props.downloadBtnClicked(this.props.dataItem.isDownloadable);
                  }} />
              </RSPTooltip>
          }


          <RSPTooltip position="top" text="More info">
            <i className={`icon-sd-circle-info-medium icons-md blue cp ${this.props.dataItem.islistCreated ? "" : "click-off"}`} onClick={() => {
              this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
              this.props.infobtnClicked(!this.state.isOpenInfoModal);
            }} />
          </RSPTooltip>
          <RSPTooltip position="top" text="Remove">
            <i className={`icon-sd-delete-medium icons-md blue cp ${!this.props.dataItem?.isDeletable ? '' : 'disabled'}`} onClick={() => {
              this.props.getSelectedListId(this.props.dataItem?.segmentationListID);
              this.props.deleteBtnClicked(!this.state.isDeleteModal);
            }} />
          </RSPTooltip>
        </div>
      </td>
    );
  }
}

class SubscriptionInvoice extends Component {
  static contextType = authContext
  initialDataState = {
    skip: 0,
    take: 5
  }

  state = {
    titleDropdown: [
      "Last 30 days",
      "Last 90 days",
      "Last 180 days"
    ]
  }

  state = {
    result: null,
    dataState: dataState,
    dynamicList: null,
    isShowDateRangePicker: false,
    showdateRange: true,
    listError: false,
    isAttribute: false,
    isOpenInfoModal: false,
    isSuppersionModal: false,
    isDeleteModal: false,
    isShareModal: false,
    segmentList: "My segment list",
    isOpenEditListNameModal: false,
    selectedListId: "",
    selectedListItem: null,
    totalListCount: 0,
    fetchDataParams: {},
    message: "",
  }

  componentDidMount = props => {
    let params = this.getParams(undefined);
    this.setState({ fetchDataParams: params });
    this.getDataFromServerNew(params)
  }

  componentDidUpdate = () => {
    if (this.props.context.globalStateData.getIsReloadData) {
      this.props.context.globalStateData.setIsReloadData(false)
      let params = this.getParams(undefined);
      this.setState({ fetchDataParams: params });
      this.getDataFromServerNew(params)
    }
  }

  params = {
    "pageNumber": 1,
    "sortBy": "",
    "sortDescending": false,
    "searchText": "",
    "searchTextField": "",
    "listType": this.state.segmentList,
    "startDate": "",
    "endDate": "",
    "itemsPerPage": 5
  }

  getParams = (searchData, listType) => {
    let params = {
      "pageNumber": searchData !== undefined && searchData.PageCount !== undefined ? searchData.PageCount : 1,
      "sortBy": searchData !== undefined && searchData.sortBy !== undefined ? searchData.sortBy : "",
      "sortDescending": searchData !== undefined && searchData.sortDescending !== undefined ? searchData.sortDescending : false,
      "searchText": searchData !== undefined && searchData.searchText !== undefined ? searchData.searchText.toString() : "",
      "searchTextField": searchData !== undefined && searchData.searchTextField !== undefined ? searchData.searchTextField : "",
      "listType": searchData !== undefined && searchData.listType !== undefined ? searchData.listType : listType !== undefined ? listType : this.state.segmentList,
      "startDate": searchData !== undefined && searchData.startDate !== undefined ? searchData.startDate : "",
      "endDate": searchData !== undefined && searchData.endDate !== undefined ? searchData.endDate : "",
      "itemsPerPage": searchData !== undefined && searchData.PageSize !== undefined ? searchData.PageSize : 5
    }
    return params
  }
  getDataFromServerNew = params => {
    this.setState({ listError: false, result: null })
    connectServer({
      path: GET_SUBLIST,
      params: params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status && (res.message !== "No data" && !res.message.includes("Data"))) {
          let newTemp = [...res?.data]
          const tempDataState = {
            skip: 0,
            take: this.state.dataState?.take
          };
          let some = process(newTemp, tempDataState)
          this.setState({
            totalListCount: res.totalListCount,
            result: Array.prototype.reverse.call(some),
            dynamicList: res.data
          })
        } else {
          this.setState({
            listError: true,
            result: null,
            message: res.message !== "No data" ? res.message : ""
          })
        }
      },
      fail: err => {
        this.setState({ listError: true })
      }
    })
  }
  deleteSegmentationList = () => {
    let params = {
      "segmentationListID": this.state.selectedListId
    }
    connectServer({
      path: DELETE_SEGEMENTATION_LIST,
      params: params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status) {
          this.getDataFromServerNew(this.getParams(undefined));
        }
      },
      fail: err => {
      }
    })
  }
  duplicateSegmentationList = (segmentationID) => {
    let params = {
      "segmentationListID": segmentationID
    }
    connectServer({
      path: DUPLICATE_SEGMENTATION_LIST,
      params: params,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status) {
          this.getDataFromServerNew(this.getParams(undefined));
        }
      },
      fail: err => {
      }
    })
  }
  dataStateChange = (event) => {
    const { skip, take } = event.dataState;
    const currentPage = Math.floor(skip / take + 1)
    this.setState({
      result: process(this.state.dynamicList, event.dataState),
      dataState: event.dataState
    });
    let tempData = { ...this.state.fetchDataParams };
    tempData.itemsPerPage = take;
    tempData.pageNumber = currentPage
    this.setState({ fetchDataParams: tempData });
    this.getDataFromServerNew(tempData)
  };

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      columnMenuDate: ColumnMenuDate,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? "active"
        : "",
    };
  }
  isColumnActive(field, dataState) {
    return (
      GridColumnMenuFilter.active(field, dataState.filter) ||
      GridColumnMenuSort.active(field, dataState.sort)
    );
  }
  CustomActionCell = (props) => {
    const { match, history } = this.props;
    return <ActionCell {...props}
      history={history} url={match.url}
      getSelectedListId={(listId) => { this.setState({ selectedListId: listId }) }}
      editBtnClicked={() => {
        this.setState({ isOpenEditListNameModal: props.dataItem.listType === 5 ? false : true, selectedListItem: props.dataItem });
        if (props.dataItem.listType === 5) {
          this.props.history.push({ pathname: `${this.props.match.url}/new-subscription-list`, state: { mode: 'edit', listId: props.dataItem.segmentationListID } })
        }
      }}
      duplicateBtnClicked={(segmentationID) => {
        this.duplicateSegmentationList(segmentationID)
      }}
      userBtnClicked={() => this.setState({ isAttribute: true })}
      infobtnClicked={() => { this.setState({ isOpenInfoModal: true, selectedListItem: props.dataItem }) }}
      suppersionBtnClicked={() => { this.setState({ isSuppersionModal: true }) }}
      deleteBtnClicked={() => { this.setState({ isDeleteModal: true }) }}
      downloadBtnClicked={(value) => {
        if (value) {
          this.setState({ isOpenDownloadModal: true })
        } else {
          this.setState({ isOpenPaymentPendingModal: true })
        }
      }}
      shareBtnClicked={() => { this.setState({ isShareModal: true }) }} />
  }
  closeDateRangePicker = (status, dates) => {
    if (!status && !!dates) {
      let tempData = { ...this.state.fetchDataParams };
      tempData.startDate = dateFormatWithHyphen(new Date(dates?.start));
      tempData.endDate = dateFormatWithHyphen(new Date(dates?.end));
      tempData.pageNumber = 1;
      tempData.itemsPerPage = 5;
      this.setState({ fetchDataParams: tempData, dataState: dataState });
      this.getDataFromServerNew(tempData)
    }
    this.setState({ isShowDateRangePicker: status });
  }
  HeaderView = props => {
    const [isShow, setIsShow] = React.useState(false);
    let dropData = [];
    if (getUserData().clientDetailsLocalization.isVersiumEnabled) {
      if (getUserData()?.clientId === 16) {
        dropData = [{ text: "Subscription list" }, { text: "Upload list" }, { text: "RSSubscription list" }];
      } else {
        dropData = [{ text: "Subscription list" }, { text: "Upload list" }];
      }

    } else {
      dropData = [{ text: "Upload list" }];
    }
    return <>
      <div className="header-title d-flex justify-content-between">
        <h1>Audience lists</h1>
      </div>

      <hr className="blue" />

      <div className="d-flex justify-content-between marginT15 marginB15 padding0">
        <div></div>
        <div className="d-flex align-items-center">
          <RSPDateRangePicker
            class="marginR15"
            enable={true}
            show={this.state.showdateRange}
            isShowDateRangePicker={this.state.isShowDateRangePicker}
            closeDateRangePicker={(status, date) => this.closeDateRangePicker(status, date)}
            cancelBtnClicked={() => {
              this.setState({ isShowDateRangePicker: false })
            }}
          />
          <BootstrapDropdown
            data={["My lists", "All lists"]}
            defaultItem={this.state.segmentList !== "" ? "My lists" : "All lists"}
            customAlignRight={true}
            className="fs18-btn mr17"
            onSelect={(item, index) => {
              let listType = item === "My lists" ? item : ""
              let tempData = { ...this.state.fetchDataParams };
              tempData.listType = listType;
              this.setState({ segmentList: listType, fetchDataParams: tempData });
              this.getDataFromServerNew(tempData)
            }} />
          <SearchViewAdvanced
            closeDateRangePicker={this.closeDateRangePicker}
            cls="marginR15"
            dropdownData={['List name', 'Created by', 'Modified by']}
            enable={true}
            isAudience={true}
            onDataUpdate={data => {
              let tempData = { ...this.state.fetchDataParams };
              tempData.searchText = data.searchText;
              tempData.searchTextField = data.searchTextField;
              tempData.pageNumber = 1;
              tempData.itemsPerPage = 5;
              this.setState({ fetchDataParams: tempData, dataState: dataState });
              this.getDataFromServerNew(tempData)
            }}
          />
          {/* <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer"
              onClick={() => this.props.history.push({ pathname: `${this.props.match.url}/add-audience`, state: { mode: 'add' } })}></i> */}
          <RSPTooltip text="Add audience" show={isShow} position="top">
            <div onClick={() => {
              setIsShow(false)
            }} onMouseEnter={() => { setIsShow(true) }}
              onMouseLeave={() => { setIsShow(false) }}>
              <KendoIconDropdown
                className=""
                popupClass="marginT5"
                dir="rtl"
                data={dropData}
                icon=" icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer icon-shadow"
                onItemClick={(e) => {
                  setIsShow(false)
                  if (e.item.text.toLowerCase() === 'upload list') {
                    this.props.history.push({ pathname: `${this.props.match.url}/add-audience`, state: { mode: 'add' } })
                  } else if (e.item.text.toLowerCase() === "subscription list") {
                    this.props.history.push({ pathname: `${this.props.match.url}/new-subscription-list`, state: { mode: 'add', listId: 0 } })
                  } else {
                    this.props.history.push({ pathname: `${this.props.match.url}/new-subscription-list-RS`, state: { mode: 'add', listId: 0 } })
                  }
                }}
              />
              {/* <DropDownButton
                className="gallery-dropdown-button"
                align="start"
                onItemClick={(e) => {
                  if (e.item.text.toLowerCase() === 'upload list') {
                    this.props.history.push({ pathname: `${this.props.match.url}/add-audience`, state: { mode: 'add' } })
                  } else {
                    this.props.history.push({ pathname: `${this.props.match.url}/new-segmentation-creation`, state: { mode: 'add' } })
                  }
                }}
                items={dropData}
                icon=" icon-sd-circle-plus-fill-edge-medium icons-lg blue cursor-pointer"
              /> */}
            </div>
          </RSPTooltip>
        </div>
      </div>
    </>
  }
  NameComponent = (props) => {
    // <NameCell {...props} />
    return (<td>
      <div className="d-flex justify-content-between">
        <RSPTooltip text={props.dataItem.recipientsBunchName} position="top">
          <span className="marginB0 text-wrap-1" >{`${props.dataItem.recipientsBunchName}`}</span>
        </RSPTooltip>
      </div>
    </td>)
  }
  CONFILTER = [
    {
      title: 'Name',
      field: 'recipientsBunchName',
      cell: this.NameComponent
    },
    {
      title: 'Created on',
      field: 'createdDate',
      cell: DateCell,
      columnMenu: ColumnMenuDate
    },
    {
      title: 'Created by',
      field: 'createdBy'
    },
    {
      title: 'Total audience',
      field: 'recipientCount',
      cell: TotalAudienceCell
    },
    {
      title: 'No. of linked comm.',
      field: 'blastedCount',
      className: "text-end"
    },
    {
      title: 'Action',
      field: 'Action',
      width: 220,
      cell: this.CustomActionCell
    }
  ]
  render() {
    console.log("DATA::", this.state.result);
    return (
      <>
        <this.HeaderView />
        {this.state.result
          ? (
            <>
              {/* <KendoGrid
                data={this.state.result?.data}
                scrollable={'scrollable'}
                column={this.CONFILTER}
                onDataStateChange={this.dataStateChange}
                pageable
                settings={{
                  total: this.state.totalListCount
                }}
              /> */}
              <Grid
                data={this.state.result}
                {...this.state.dataState}
                onDataStateChange={this.dataStateChange}
                className="grid-pagenation-del grid-replaceable grid-vertical-noscroll"
                scrollable={true}
                total={this.state.totalListCount}
                pageable={{
                  //type: 'input',
                  pageSizes: true,
                  previousNext: true
                }}
              >
                <GridColumn
                  {...this.columnProps("recipientsBunchName")}
                  title="Name"
                  columnMenu={ColumnMenu}
                  cell={this.NameComponent}
                // width="225px"
                />
                <GridColumn
                  {...this.columnProps("createdDate")}
                  title="Created on"
                  cell={DateCell}
                  columnMenu={ColumnMenuDate}
                // width="200px"
                />
                <GridColumn {...this.columnProps("createdBy")}
                  title="Created by"
                  columnMenu={ColumnMenu}
                  width={150}
                />
                <GridColumn {...this.columnProps("recipientCount")}
                  title="Total audience"
                  cell={TotalAudienceCell}
                  columnMenu={ColumnMenu}
                  width={200}
                />
                <GridColumn {...this.columnProps("blastedCount")}
                  title="No. of linked comm."
                  className="text-end"
                  columnMenu={ColumnMenu}
                  width={220}
                />
                <GridColumn
                  field="action"
                  title="Action"
                  cell={this.CustomActionCell}
                  width="220"
                />
              </Grid>
            </>
          )
          : (<SkeletonTable message={this.state.message} text={"to upload data"} count={5} isError={this.state.listError} />)
        }
        {this.state.isOpenInfoModal ? (
          <SegmentDynamicInfo
            selectedData={this.state?.selectedListItem}
            selectedListID={this.state?.selectedListId}
            isOpen={this.state.isOpenInfoModal}
            onChangeIsOpen={(value) => {
              this.setState({ isOpenInfoModal: !this.state.isOpenInfoModal });
            }}> </SegmentDynamicInfo>
        ) : null}
        {this.state.isOpenEditListNameModal ? (
          <EditListNameModal
            selectedListId={this.state.selectedListId}
            selectedListName={this.state?.selectedListItem?.recipientsBunchName?.substring(3)}
            isOpen={this.state.isOpenEditListNameModal}
            onChangeIsOpen={(value) => {
              if (value) {
                this.setState({
                  dataState: {
                    skip: 0,
                    take: 5
                  }
                })
                this.getDataFromServerNew(this.getParams(undefined))
              }
              this.setState({ isOpenEditListNameModal: !this.state.isOpenEditListNameModal });
            }} />
        ) : null}
        {this.state.isOpenDownloadModal &&
          <ListDownloadModal
            isOpen={this.state.isOpenDownloadModal}
            selectedListId={this.state.selectedListId}
            onChangeIsOpen={(value) => {
              this.setState({ isOpenDownloadModal: false })
            }} />
        }
        {this.state.isSuppersionModal &&
          <SuppersionModal isOpen={this.state.isSuppersionModal}
            onChangeIsOpen={(value) => {
              this.setState({ isSuppersionModal: !this.state.isSuppersionModal });
            }} />}
        {this.state.isAttribute === true &&
          <></>
        }
        {this.state.isDeleteModal &&
          <RSConfirmAlert
            title="Are you sure you want to delete?"
            subTitle="Deleted lists cannot be used for communication creation"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                this.deleteSegmentationList();
              }
              this.setState({ isDeleteModal: false });
            }} />
        }
        {this.state.isShareModal &&
          <ShareModalComponent isOpen={this.state.isShareModal}
            onChangeIsOpen={(value) => {
              this.setState({ isShareModal: !this.state.isShareModal });
            }} />
        }
        {this.state.isOpenPaymentPendingModal &&
          <DownloadAlertModal
            subTitle="Charges applicable for list download. Do you wish to proceed?"
            secondaryButtonText="Cancel"
            primaryButtonText="Confirm"
            buttonClicked={(value) => {
              if (value) {
                this.props.history.push({ pathname: `/audience/audience-payment-summary`, state: { segListId: { "segmentationSchedulerID": 0, "segmentationListID": this.state.selectedListId, "isDownload": true } } });
                // getAudiencePaymentSummary(props.history.location.state.segListId, value)
              } else {

                // setIsDownload(false);
              }
              this.setState({ isOpenPaymentPendingModal: false })
            }}
          />
        }
      </>
    )
  }
}
export default withRouter(DynamicList);

const NumberCell = (props) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {props.dataItem[props.field]}
    </td>
  )
}
const DateCell = (props) => {
  return (
    <td>
      {dateFormatWithMomentForInvoice(props.dataItem[props.field])}
    </td>
  )
}
const TotalAudienceCell = (props) => {
  let listProgress = props.dataItem["estimateSeconds"]
  console.log('listProgress: ', listProgress);
  return (
    <td className="text-end">
      <div style={{ fontSize: "17px" }} className="d-block">{(props.dataItem["listUploadStatus"]?.includes("progress") || props.dataItem["listUploadStatus"]?.includes("pending")) ? numberFormatter(props.dataItem["versiumAudience"]) : numberFormatter(props.dataItem[props.field])}</div>

      <div style={{ fontSize: "12px" }} className="font-italic d-block s">{(props.dataItem["listUploadStatus"]?.includes("progress") || props.dataItem["listUploadStatus"]?.includes("pending")) ? props.dataItem["listUploadStatus"] : null}</div>

      {
        listProgress > 0 ? <>
          <div className="font-italic d-block">
            {/* <small style={{ fontSize: "12px" }}>List generation in progress</small> */}
          </div>
          <div className="font-italic d-block" style={{ fontSize: "12px", marginTop: "-6px" }}><Timer initialTime={listProgress} /></div>
        </> : null
      }

    </td>
  )
}
const ColumnMenu = props => {
  return (
    <div>
      {true &&
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          active={true}
          // filterable={false}
          hideSecondFilter={true}
          filterUI={CustomFilter}
        />}
    </div>
  )
}
const ColumnMenuDate = props => {
  return (
    <div>
      {true &&
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          active={true}
          // filterable={false}
          hideSecondFilter={true}
          filterUI={CustomDateFilter}
        />}
    </div>
  )
}