import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import TagsComponent from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/TagsComponent';
import { CAMPAIGN_NAME, NATIVE_CHANNEL } from 'Helper/Constants/validationMessage';
import { isEmpty, makeImmutableObject } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context"
import * as ConstStr from "Helper/Constants/validationMessage";
import { camapignNameisPresent, createSmartlinkObject, getCampaignPlanById, savePlan } from '../SmartLink/SLObjectManager';
import GoalModal from './Components/GoalModal';
import { RSPTooltip } from 'Components/Tooltip';
import { GET_CAMPAIGN_STATUS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';

const Notification = (props) => {

    let history = useHistory();
    const context = useContext(authContext)
    const defaultValue = new Date();

    const [camapingStartDate, setCampaignStartDate] = useState(props.json.campaignDetails.startDate)
    const [camapingEndDate, setCampaignEndDate] = useState(props.json.campaignDetails.endDate)
    const [campaignDetails, setCampaignDetails] = useState(null)
    const [campaignJson, setCampaignJson] = useState(props.json)
    const [isOpenTagsModal, setOpenTagsModal] = useState(false);
    const [errCampaignName, setErrCampaignName] = useState(null)
    const [isShowTickSymbol, setIsShowTickSymbol] = useState(false);
    const [errCampaignType, setErrCampaignType] = useState(null)
    const [errPrimaryGoal, setErrPrimaryGoal] = useState(null)
    const [errPrimaryGoalType, setErrPrimaryGoalType] = useState(null);
    const [errSecondaryGoal, setErrSecondaryGoal] = useState(null)
    const [errSecondaryGoalType, setErrSecondaryGoalType] = useState(null);
    const [errStartDate, setErrStartDate] = useState(null);
    const [errEndDate, setErrEndDate] = useState(null);
    const [errGoalPercentage, setErrGoalPercentage] = useState(null)
    const [errSecondaryGoalPercentage, setErrSecondaryGoalPercentage] = useState(null)
    const [errProductType, setErrProductType] = useState(null)
    const [errNativeChannel, setErrNativeChannel] = useState(null)
    const [editClickOff, setEditClickOff] = useState(false);
    const [endDateMinValue, setEndDateMinValue] = useState('');
    const [endDateMaxvalue, setEndDateMaxValue] = useState("");
    const [isShowSecondaryGoal, setIsShowSecondaryGoal] = useState(false);
    const [isOpenGoalModal, setIsOpenGoalModal] = useState(false);
    const [channelTypes, setChannelTypes] = useState([])
    const [isEnableButton, setIsEnableButton] = useState(true)

    useEffect(() => {

        getChannelTypes();
        const temp = { ...campaignJson }
        // let cd = ["Reach"]
        // temp.primaryGoal.map(item => {
        //     cd.push(item)
        // })
        // RSLog("Goal Types",cd,false)
        // temp.primaryGoal = cd
        setCampaignJson(temp)
        if (props.campaignDetails.mode === "add" && props.campaignId === 0) {
            let object = createSmartlinkObject(false)
            object.campaignId = 0
            setCampaignDetails(object)
        } else {
            getCampaignStatus(props.campaignDetails.campaignId)
            getCampaign()
        }
    }, []);

    const getChannelTypes = () => {
        let channelTypesArray = [];
        let hasQRChannel = false;
        props?.attributes?.channelType?.map((item, index) => {
            switch (item.channelId) {
                case 1: channelTypesArray.push({
                    label: "Email",
                    value: false,
                }); break;
                case 2: channelTypesArray.push({
                    label: "SMS",
                    value: false,
                }); break;
                case 21: channelTypesArray.push({
                    label: "WhatsApp",
                    value: false,
                }); break;
                case 3: channelTypesArray.push({
                    label: "QR",
                    value: false,
                }); hasQRChannel = true;; break;
            }
        })
        if (hasQRChannel) {
            channelTypesArray = channelTypesArray.filter(item => item.label !== "QR");
            channelTypesArray.push({
                label: "QR",
                value: false,
            })
        }
        setChannelTypes(channelTypesArray);
    }

    const getCampaign = () => {

        getCampaignPlanById(props.campaignDetails.campaignId, context, props.attributes, (campaignDetails => {
            setCampaignDetails(campaignDetails)
            if (campaignDetails.secondaryGoal !== "") {
                setIsShowSecondaryGoal(true);
            }
            var someDate = new Date(campaignDetails.startDate);
            var numberOfDaysToAdd = 3;
            var maxNumOfDaysToAdd = 180;
            var result = someDate?.setDate(
                someDate.getDate() + numberOfDaysToAdd
            );
            var endResult = someDate?.setDate(
                someDate.getDate() + maxNumOfDaysToAdd
            );
            let date = new Date(campaignDetails.maxEndDate ? campaignDetails.maxEndDate : result);
            setEndDateMinValue(date);
            setEndDateMaxValue(new Date(endResult))
        }))

    }
    const getCampaignStatus = (campaignID) => {
        let params = {
            "campaignId": campaignID,
        }
        connectServer({
            path: GET_CAMPAIGN_STATUS,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    const clickOffStatus = props.campaignDetails.mode === "add"
                        ? false
                        : (res.data === 3 || res.data === 4 || res.data === 101)
                            ? true
                            : false;
                    setEditClickOff(clickOffStatus)
                }
            },
            fail: err => { }
        })
    }
    const submit = (save, campaignHeader) => {
        let error = false
        if (isEmpty(campaignDetails.campaignName)) {
            setErrCampaignName(CAMPAIGN_NAME)
            error = true
        }
        if (isEmpty(campaignDetails.campaignAttribute.attributeName) || campaignDetails.campaignAttribute.attributeName.toLowerCase().includes('select')) {
            setErrCampaignType(ConstStr.CAMPAIGN_TYPE)
            error = true
        }
        if (isEmpty(campaignDetails.productCategory.categoryName) || campaignDetails.productCategory.categoryName.toLowerCase().includes('select')) {
            setErrProductType(ConstStr.PRODUCT_TYPE)
            error = true
        }
        if (isEmpty(campaignDetails.goal) || campaignDetails.goal.toLowerCase().includes("select")) {
            setErrPrimaryGoal(ConstStr.PRIMARY_GOAL);
            error = true;
        }
        if (campaignDetails.goal !== "Reach") {
            if (isEmpty(campaignDetails.goalType) || campaignDetails.goalType.toLowerCase().includes("select")) {
                setErrPrimaryGoalType("Select " + campaignDetails.goal.toLowerCase() + " type")
                error = true;
            }
        }
        if (isShowSecondaryGoal) {
            if (isEmpty(campaignDetails.secondaryGoal) || campaignDetails.secondaryGoal.toLowerCase().includes("select")) {
                setErrSecondaryGoal(ConstStr.SECONDARY_GOAL);
                error = true;
            }
            if (campaignDetails.secondaryGoal !== "Reach") {
                if (isEmpty(campaignDetails.secondaryGoalType) || campaignDetails.secondaryGoalType.toLowerCase().includes("select")) {
                    setErrSecondaryGoalType("Select " + campaignDetails.secondaryGoal.toLowerCase() + " type")
                    error = true;
                }
            }
            if (campaignDetails.goal === campaignDetails.secondaryGoal) {
                setErrSecondaryGoal("Primary and secondary goal should not be same");
                setErrPrimaryGoal("Primary and secondary goal should not be same");
                error = true;
            }
        }
        if (isEmpty(campaignDetails.startDate.toString())) {
            setErrStartDate(ConstStr.START_DATE);
            error = true;
        }
        if (isEmpty(campaignDetails.endDate.toString())) {
            setErrEndDate(ConstStr.END_DATE);
            error = true;
        }
        if (campaignDetails.channelTypes.length <= 0) {
            setErrNativeChannel(NATIVE_CHANNEL)
            error = true;
        }
        if (isEmpty(campaignDetails.goalPercentage)) {
            setErrGoalPercentage(ConstStr.GOAL_PERCENTAGE);
            error = true;
        } else if (Math.abs(campaignDetails.goalPercentage) <= 0.1 && Math.abs(campaignDetails.goalPercentage) >= 100) {
            setErrGoalPercentage("Only values 0.1-100 are accepted");
            error = true;
        }
        if (isShowSecondaryGoal) {
            if (isEmpty(campaignDetails.secondaryGoalPercentage)) {
                setErrSecondaryGoalPercentage(ConstStr.SECONDARY_GOAL_PERCENTAGE);
                error = true;
            } else if (Math.abs(campaignDetails.secondaryGoalPercentage) <= 0.1 && Math.abs(campaignDetails.secondaryGoalPercentage) >= 100) {
                setErrSecondaryGoalPercentage("Only values 0.1-100 are accepted");
                error = true;
            }
        }
        /*  if (isEmpty(campaignDetails.timeZone) || campaignDetails.timeZone.toLowerCase().includes('select')) {
             error = true;
             setErrTimezone(SELECT_TIMEZONE)
         } */

        if (!error) {
            savePlan(campaignDetails, "S", context, response => {
                //alert('Planning' +status)
                if (response.status) {
                    context.channels = campaignDetails.nativeChannels.concat(campaignDetails.socialMedia, campaignDetails.paidAds)
                    campaignDetails.allChannels = [...campaignDetails.nativeChannels]
                    // let campaignId = prepareLocalJSON(campaignDetails, true, props.campaignDetails.mode)
                    let campaignId = response.data.campaignId
                    if (save === 0) {
                        history.push({ pathname: `/communication` })
                    } else {
                        history.push({
                            pathname: `/communication/create-communication`,
                            state:
                            {
                                mode: props.campaignDetails.mode,
                                campaignName: campaignDetails.campaignName,
                                editClickOff: editClickOff,
                                status: props.campaignDetails.status,
                                Channeltype: campaignDetails.channelTypes,
                                AnalyticsType: campaignDetails.analyticsType,
                                mobileApp: {},
                                campaignId: campaignId,
                                channelId: 0,
                                campaignHeader,
                                CampaignType: "SingleDimension",
                                templateId: props.campaignDetails.templateId
                            }
                        })
                    }
                }
            })
        }
    }

    const GoalPercentage = props => {

        const [value, setValue] = useState(props.campaignDetails.goalPercentage)
        const [errMsg, setErrMsg] = useState(props.errGoalPercentage);

        return <Col md={6} className={props.editClickOff ? "click-off" : ""}>
            <RSInput2
                ph="Primary goal percentage"
                val={value}
                errorMessage={errMsg}
                required={true}
                keyboardType="number"
                max={6}
                cb={text => {
                    const re = /^\d*\.?\d*$/;
                    if (text.length > 6) {
                        setErrMsg("Limit exists");
                        return
                    }
                    if (text === '' || re.test(text)) {
                        let errMsg = text.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
                        setValue(text);
                        setErrMsg(errMsg);
                    } else {
                        let errMsg = text.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
                        setErrMsg(errMsg);
                    }
                }}
                ob={text => {
                    let cd = { ...props.campaignDetails };
                    if (text !== "") {
                        let value = Math.abs(text);
                        let errMsg = (value >= 0.1 && value <= 100) ? null : "Only values 0.1-100 are accepted";
                        cd.goalPercentage = errMsg === null ? value : "";
                        setErrMsg(errMsg);
                        props.getValues(cd, errMsg);
                    } else {
                        cd.goalPercentage = text;
                        setErrMsg(ConstStr.GOAL_PERCENTAGE);
                        props.getValues(cd, ConstStr.GOAL_PERCENTAGE)
                    }
                }}
            />
            <div className="input-outer-set mt4">
                <span>%</span>
            </div>
        </Col>
    }
    const SecondaryGoalPercentage = props => {

        const [value, setValue] = useState(props.campaignDetails.secondaryGoalPercentage)
        const [errMsg, setErrMsg] = useState(props.errSecondaryGoalPercentage);

        return <Col md={6} className={props.editClickOff ? "click-off" : ""}>
            <RSInput2
                required
                ph="Secondary goal percentage"
                val={value}
                keyboardType="number"
                errorMessage={errMsg}
                cb={text => {
                    const re = /^\d*\.?\d*$/;
                    if (text.length > 6) {
                        setErrMsg("Limit exists");
                        return
                    }
                    if (text === '' || re.test(text)) {
                        let errMsg = text.length > 0 ? null : ConstStr.SECONDARY_GOAL_PERCENTAGE;
                        setValue(text);
                        setErrMsg(errMsg);
                    } else {
                        let errMsg = text.length > 0 ? null : ConstStr.SECONDARY_GOAL_PERCENTAGE;
                        setErrMsg(errMsg);
                    }
                }}
                ob={text => {
                    let cd = { ...props.campaignDetails };
                    if (text !== "") {
                        let value = Math.abs(text);
                        let errMsg = (value >= 0.1 && value <= 100) ? null : "Only values 0.1-100 are accepted";
                        cd.secondaryGoalPercentage = errMsg === null ? value : "";
                        setErrMsg(errMsg);
                        props.getValues(cd, errMsg);
                    } else {
                        cd.secondaryGoalPercentage = text;
                        setErrMsg(ConstStr.SECONDARY_GOAL_PERCENTAGE);
                        props.getValues(cd, ConstStr.SECONDARY_GOAL_PERCENTAGE)
                    }
                }}
            />
            <div className="input-outer-set mt4">
                <span>%</span>
            </div>
        </Col>
    }
    const SecondaryGoalRowComponent = () => {
        return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
            <Col md={{ span: 3, offset: 1 }}>
                <label className="label-row label-dark">Secondary goal</label>
            </Col>
            <Col md={7}>
                <Row>
                    <Col md={6}>
                        <div className="position-relative">
                            {errSecondaryGoal && <div className="validation-message">{errSecondaryGoal}</div>}
                            <RSDropdownList
                                className="required"
                                data={campaignJson.primaryGoal}
                                value={campaignDetails.secondaryGoal !== "" ? campaignDetails.secondaryGoal : "-- Goal --"}
                                defaultItem={"-- Goal --"}
                                onChange={(e) => {
                                    if (e.target.value !== "-- Goal --") {
                                        // var percentage = 0;
                                        let cd = { ...campaignDetails };
                                        cd.secondaryGoal = e.target.value;
                                        setErrSecondaryGoalType(null);
                                        // if (cd.secondaryGoal === campaignJson.primaryGoal[0]) {
                                        //     percentage = 20;
                                        // } else if (cd.secondaryGoal === campaignJson.primaryGoal[1]) {
                                        //     percentage = 40;
                                        // } else if (cd.secondaryGoal === campaignJson.primaryGoal[2]) {
                                        //     percentage = 75;
                                        // }

                                        // cd.secondaryGoalType = cd.secondaryGoal === campaignJson.primaryGoal[1]
                                        //     ? campaignJson.engamentTypes[0]
                                        //     : campaignJson.conversationTypes[0]

                                        cd.secondaryGoalType = "-- Select --"

                                        let errMsg = e.target.value.length > 0 ? null : ConstStr.SECONDARY_GOAL;
                                        setErrSecondaryGoal(errMsg);
                                        let errGoalMsg = e.target.value.length > 0 ? null : ConstStr.SECONDARY_GOAL;
                                        setErrSecondaryGoalPercentage(errGoalMsg);
                                        //cd.secondaryGoalPercentage = percentage;
                                        setCampaignDetails(cd);
                                        // props.onChange("goalPercentage", percentage);
                                        props.onChange("reachType", e.target.value);
                                        if (e.target.value !== "" && campaignDetails.goal !== "") {
                                            if (e.target.value !== campaignDetails.goal) {
                                                setErrPrimaryGoal(null);
                                            } else {
                                                setErrPrimaryGoal("Primary and secondary goal should not be same");
                                                setErrSecondaryGoal("Primary and secondary goal should not be same")
                                            }
                                        }
                                    } else {
                                        let cd = { ...campaignDetails };
                                        cd.secondaryGoal = "";
                                        cd.secondaryGoalType = "-- Select --"
                                        setCampaignDetails(cd);
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    <SecondaryGoalPercentage
                        editClickOff={editClickOff}
                        campaignDetails={campaignDetails}
                        errSecondaryGoalPercentage={errSecondaryGoalPercentage}
                        getValues={(goalValue, ErrMsg) => {
                            setCampaignDetails(goalValue)
                            setErrSecondaryGoalPercentage(ErrMsg)
                        }}
                    />
                </Row>
            </Col>
            <Col>
                <RSPTooltip text="Remove" position="top">
                    <i className='icon-sd-circle-minus-fill-edge-medium icons-md red posr top10 left5 cp' onClick={() => {
                        let cd = { ...campaignDetails };
                        cd.secondaryGoal = "";
                        cd.secondaryGoalPercentage = "";
                        cd.secondaryGoalType = ""
                        cd.errSecondaryGoalPercentage = "";
                        setCampaignDetails(cd);
                        setIsShowSecondaryGoal(!isShowSecondaryGoal)
                        setErrPrimaryGoal(null);
                        setErrSecondaryGoal(null);
                    }}></i>
                </RSPTooltip>
            </Col>
        </Row>
    }

    const PrimaryGoalRowComponent = () => {
        return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
            <Col md={{ span: 3, offset: 1 }}>
                <label className="label-row label-dark">Primary goal</label>
            </Col>
            <Col md={7}>
                <Row>
                    <Col md={6}>
                        <div className="position-relative">
                            {errPrimaryGoal && <div className="validation-message">{errPrimaryGoal}</div>}
                            <RSDropdownList
                                className="required"
                                data={campaignJson.primaryGoal}
                                defaultValue={campaignDetails.goal !== "" ? campaignDetails.goal : "-- Goal --"}
                                defaultItem={"-- Goal --"}
                                onChange={(e) => {
                                    if (e.target.value !== "-- Goal --") {
                                        // var percentage = 0;
                                        let cd = { ...campaignDetails };
                                        cd.goal = e.target.value;
                                        // if (cd.goal === campaignJson.primaryGoal[0]) {
                                        //     percentage = 20;
                                        // } else if (cd.goal === campaignJson.primaryGoal[1]) {
                                        //     percentage = 40;
                                        // } else if (cd.goal === campaignJson.primaryGoal[2]) {
                                        //     percentage = 75;
                                        // }

                                        // cd.goalType = cd.goal === campaignJson.primaryGoal[1]
                                        //     ? campaignJson.engamentTypes[0]
                                        //     : campaignJson.conversationTypes[0]

                                        cd.goalType = "-- Select --"
                                        setErrPrimaryGoalType(null);
                                        let errMsg = e.target.value.length > 0 ? null : ConstStr.PRIMARY_GOAL;
                                        setErrPrimaryGoal(errMsg);
                                        let errGoalMsg = e.target.value.length > 0 ? null : ConstStr.GOAL_PERCENTAGE;
                                        setErrGoalPercentage(errGoalMsg);
                                        if (e.target.value !== "" && campaignDetails.secondaryGoal !== "") {
                                            if (e.target.value !== campaignDetails.secondaryGoal) {
                                                setErrSecondaryGoal(null);
                                            } else {
                                                setErrPrimaryGoal("Primary and secondary goal should not be same");
                                                setErrSecondaryGoal("Primary and secondary goal should not be same");
                                            }
                                        }
                                        // cd.goalPercentage = percentage;
                                        setCampaignDetails(cd);
                                        // props.onChange("goalPercentage", percentage);
                                        props.onChange("reachType", e.target.value);
                                    } else {
                                        let cd = { ...campaignDetails };
                                        cd.goal = "";
                                        cd.goalType = "-- Select --"
                                        setCampaignDetails(cd);
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    <GoalPercentage
                        editClickOff={editClickOff}
                        campaignDetails={campaignDetails}
                        errGoalPercentage={errGoalPercentage}
                        getValues={(goalValue, ErrMsg) => {
                            setCampaignDetails(goalValue)
                            setErrGoalPercentage(ErrMsg)
                        }}
                    />
                </Row>
            </Col>
            <Col>
                <RSPTooltip text="Add" position="top">
                    <i className={`icon-sd-circle-plus-fill-edge-medium icons-md blue posr top10 left5 cp ${campaignDetails.goalPercentage !== "" ? isShowSecondaryGoal ? "click-off" : "" : "click-off"}`} onClick={() => {
                        setIsOpenGoalModal(!isOpenGoalModal);
                    }}></i>
                </RSPTooltip>
            </Col>
        </Row>
    }

    const CampaignGoalColComponent = () => {
        if (campaignDetails.goal !== campaignJson.primaryGoal[0] && campaignDetails.goal.length > 0) {
            return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                <Col md={{ span: 3, offset: 1 }}>
                    <label className="label-row label-dark">
                        {campaignDetails.goal + " type"}
                    </label>
                </Col>
                <Col md={7}>
                    <div className="position-relative">
                        {errPrimaryGoalType && <div className="validation-message">{errPrimaryGoalType}</div>}
                        <RSDropdownList
                            className="required"
                            data={
                                campaignDetails.goal === campaignJson.primaryGoal[1]
                                    ? campaignJson.engamentTypes
                                    : campaignJson.conversationTypes
                            }
                            value={
                                campaignDetails.goalType
                            }
                            defaultItem={"-- Select --"}
                            onChange={(e) => {
                                let cd = { ...campaignDetails };
                                let errMsg = e.target.value === "-- Select --" ? "Select " + campaignDetails.goal.toLowerCase() + " type" : null;
                                setErrPrimaryGoalType(errMsg);
                                cd.goalType = e.target.value;
                                setCampaignDetails(cd);
                            }}
                        />
                    </div>
                </Col>
            </Row>
        }
        return null
    }
    const SecondaryCampaignGoalColComponent = () => {
        if (campaignDetails.secondaryGoal !== campaignJson.primaryGoal[0] && campaignDetails.secondaryGoal.length > 0) {
            return <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                <Col md={{ span: 3, offset: 1 }}>
                    <label className="label-row label-dark">
                        {"Secondary " + campaignDetails.secondaryGoal.toLowerCase() + " type"}
                    </label>
                </Col>
                <Col md={7}>
                    <div className="position-relative">
                        {errSecondaryGoalType && <div className="validation-message">{errSecondaryGoalType}</div>}
                        <RSDropdownList
                            className="required"
                            data={
                                campaignDetails.secondaryGoal === campaignJson.primaryGoal[1]
                                    ? campaignJson.engamentTypes
                                    : campaignJson.conversationTypes
                            }
                            value={
                                campaignDetails.secondaryGoalType
                            }
                            defaultItem={"-- Select --"}
                            onChange={(e) => {
                                let cd = { ...campaignDetails };
                                let errMsg = e.target.value === "-- Select --" ? "Select " + campaignDetails.secondaryGoal.toLowerCase() + " type" : null;
                                setErrSecondaryGoalType(errMsg);
                                cd.secondaryGoalType = e.target.value;
                                setCampaignDetails(cd);
                            }}
                        />
                    </div>
                </Col>
            </Row>
        }
        return null
    }

    return (
        <>
            {/* First Row */}

            {campaignDetails &&
                <>
                    <Row className={`custom-column-del smartlink-col-del marginT30 ${editClickOff ? "click-off" : ""}`}>

                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Communication name</label>
                        </Col>
                        <Col md={7}>
                            <RSInput2
                                ph="Enter communication name"
                                val={campaignDetails.campaignName}
                                required={true}
                                cls=''
                                cb={text => {
                                    setIsShowTickSymbol(false)
                                    var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                                    if (text.length > 0 && text.length <= 100) {
                                        if (regex.test(text)) {
                                            let disablevalue = text.length > 0 ? false : true;
                                            if (!disablevalue) {
                                                props.onDisableChange({ "title": "Single Dimension", "value": !disablevalue });
                                            }
                                            let errMsg = text.length > 0 ? null : CAMPAIGN_NAME
                                            setErrCampaignName(errMsg)
                                            let cd = { ...campaignDetails }
                                            cd.campaignName = text
                                            setCampaignDetails(cd)
                                        } else {
                                            let errMsg = text.length > 0 ? "Special characters are not allowed except (_ and -)" : CAMPAIGN_NAME;
                                            setErrCampaignName(errMsg);
                                        }
                                    } else {
                                        let errMsg = text.length > 0 ? "Communication name exceeded the limit" : CAMPAIGN_NAME;
                                        if (text.length === 0) {
                                            let cd = { ...campaignDetails }
                                            cd.campaignName = ""
                                            setCampaignDetails(cd)
                                        }
                                        setErrCampaignName(errMsg);
                                    }
                                }}
                                ob={text => {
                                    props.onChange("ncampaingName", text)
                                    text = text.trimStart();
                                    text = text.trimEnd();
                                    let errMsg = text !== "" ? null : CAMPAIGN_NAME;
                                    setErrCampaignName(errMsg);
                                    camapignNameisPresent(campaignDetails.campaignId, text, context, errCampaignName => {
                                        let isEnable = errCampaignName !== "" ? false : true;
                                        setIsShowTickSymbol(isEnable);
                                        setIsEnableButton(isEnable)
                                        setErrCampaignName(errCampaignName);
                                    });
                                    let cd = { ...campaignDetails }
                                    cd.campaignName = text
                                    setCampaignDetails(cd)
                                }}
                                errorMessage={errCampaignName}
                            />
                            {isShowTickSymbol &&
                                <div className="input-outer-set">
                                    <i className="icon-sd-circle-tick-medium icons-md green d-block marginT0" />
                                </div>
                            }
                        </Col>
                    </Row>

                    {/* Second Row */}
                    <Row className={`custom-column-del smartlink-col-del ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-row label-dark">Communication / Product type</label>
                        </Col>
                        <Col md={7}>
                            <Row>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errCampaignType && <div className="validation-message">{errCampaignType}</div>}
                                        <RSDropdownList
                                            className="required"
                                            data={props.attributes.campaignAttributeList}
                                            value={
                                                campaignDetails.campaignAttribute !== undefined &&
                                                    campaignDetails.campaignAttribute.attributeName !== ''
                                                    ? campaignDetails.campaignAttribute
                                                    : '-- Select Target list  --'
                                            }
                                            defaultItem={{ campaignAttributeId: 0, attributeName: "-- Select communication type --" }}
                                            textField='attributeName'
                                            dataItemKey='campaignAttributeId'
                                            onChange={e => {
                                                props.onChange('camapaignTypes', e.target.value)
                                                let cd = { ...campaignDetails }
                                                cd.campaignAttribute = e.target.value
                                                setCampaignDetails(cd)
                                                let errMsg = e.target.value.attributeName.length > 0
                                                    ? null
                                                    : ConstStr.CAMPAIGN_TYPE
                                                setErrCampaignType(errMsg)
                                            }} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errProductType && <div className="validation-message">{errProductType}</div>}
                                        <RSDropdownList
                                            className="required"
                                            data={props.attributes.productCategorysList}
                                            value={
                                                campaignDetails.productCategory !== undefined &&
                                                    campaignDetails.productCategory.categoryName !== ''
                                                    ? campaignDetails.productCategory
                                                    : '-- Select  --'
                                            }
                                            defaultItem={{ categoryId: 0, categoryName: "-- Select product type --" }}
                                            textField='categoryName'
                                            dataItemKey='categoryId'
                                            onChange={(e) => {
                                                let cd = { ...campaignDetails }
                                                cd.productCategory = e.target.value
                                                setCampaignDetails(cd)
                                                props.onChange('productTypes', e.target.value)
                                                let errMsg = e.target.value.categoryName.length > 0
                                                    ? null
                                                    : ConstStr.CAMPAIGN_TYPE
                                                setErrProductType(errMsg)
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Col>
                    </Row>

                    {/* Third Row */}
                    <PrimaryGoalRowComponent />
                    <CampaignGoalColComponent />
                    {isShowSecondaryGoal &&
                        <>
                            <SecondaryGoalRowComponent />
                            <SecondaryCampaignGoalColComponent />
                        </>}


                    {/* Fourth Row */}
                    <Row className={`custom-column-del smartlink-col-del `}>
                        <Col md={{ span: 3, offset: 1 }} className={props.campaignDetails.status === 9 ? "click-off" : ""}>
                            <label className="label-row label-dark">Communication period</label>
                        </Col>
                        <Col md={7} >
                            <Row>
                                <Col md={6} className={editClickOff ? "click-off" : ""}>
                                    <div className="position-relative">
                                        {errStartDate && <div className="validation-message">{errStartDate}</div>}
                                        <div className="rspdate-picker">
                                            <Tooltip anchorElement="target" position="top">
                                                <DatePicker
                                                    title={false}
                                                    format={"MMM dd, yyyy"}
                                                    // show={true}
                                                    placeholder={"Start date"}
                                                    value={campaignDetails.startDate !== '' ? new Date(campaignDetails.startDate) : null}
                                                    min={defaultValue}
                                                    onChange={e => {
                                                        setCampaignStartDate(e.target.value);
                                                        var someDate = e.target.value;
                                                        var numberOfDaysToAdd = 3;
                                                        var maxNumOfDaysToAdd = 180;
                                                        var result = someDate?.setDate(
                                                            someDate.getDate() + numberOfDaysToAdd
                                                        );
                                                        var endResult = someDate?.setDate(
                                                            someDate.getDate() + maxNumOfDaysToAdd
                                                        );

                                                        let date = new Date(result);
                                                        setEndDateMinValue(date);
                                                        setEndDateMaxValue(new Date(endResult))
                                                        // let cd = { ...campaignDetails }
                                                        campaignDetails.startDate = e.target.value
                                                        // let cd = { ...campaignDetails }
                                                        campaignDetails.endDate = "";
                                                        let errMsg = e.target.value !== null ? null : ConstStr.START_DATE
                                                        setErrStartDate(errMsg)

                                                    }}
                                                    className="required"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className={props.campaignDetails.status === 9 ? "click-off" : ""}>
                                    <div className="position-relative">
                                        {errEndDate && <div className="validation-message">{errEndDate}</div>}
                                        <div className="rspdate-picker">
                                            <Tooltip anchorElement="target" position="top">
                                                <DatePicker
                                                    format={"MMM dd, yyyy"}
                                                    placeholder={"End date"}
                                                    disabled={campaignDetails.startDate !== "" ? false : true}
                                                    // show={true}
                                                    value={campaignDetails.endDate !== '' ? new Date(campaignDetails.endDate) : null}
                                                    min={endDateMinValue !== '' ? endDateMinValue : defaultValue}
                                                    max={endDateMaxvalue !== '' ? endDateMaxvalue : defaultValue}
                                                    onChange={e => {
                                                        setCampaignEndDate(e.target.value);
                                                        // let cd = { ...campaignDetails }
                                                        campaignDetails.endDate = e.target.value
                                                        let errMsg = e.target.value !== null ? null : ConstStr.END_DATE
                                                        setErrEndDate(errMsg)
                                                    }}
                                                    className="required"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Col>
                    </Row>
                    {/* Sixth Row */}
                    <Row className={`custom-column-del smartlink-col-del marginT40 ${editClickOff ? "click-off" : ""}`}>
                        <Col md={{ span: 3, offset: 1 }}>
                            <label className="label-dark">Channel type</label>
                        </Col>
                        <Col md={8}>
                            <ul className="checkbox-listing">
                                <div className="position-relative">
                                    {errNativeChannel && <div className="validation-message">{errNativeChannel}</div>}
                                    {channelTypes.map((item, index) => {
                                        item.value = false;
                                        campaignDetails.channelTypes.map((citem, cindex) => {
                                            if (item.label.toLocaleLowerCase() === citem.toLocaleLowerCase()) {
                                                item.value = true;
                                            }
                                        })
                                        return (
                                            <li key={index}>
                                                <RSCheckbox
                                                    className=""
                                                    lbl={item.label}
                                                    checked={item.value}
                                                    cb={status => {
                                                        let cd = { ...campaignDetails }
                                                        if (status) {
                                                            cd.channelTypes.push(item.label)
                                                        } else {
                                                            let myarr = cd.channelTypes.filter((obj) => obj.toLocaleLowerCase() !== item.label.toLocaleLowerCase())
                                                            cd.channelTypes = myarr
                                                        }
                                                        let err = cd.channelTypes.length > 0 ? null : NATIVE_CHANNEL
                                                        setErrNativeChannel(err)
                                                        setCampaignDetails(cd)
                                                    }} />
                                            </li>
                                        )
                                    })}
                                </div>
                            </ul>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <div className="btn-container d-flex justify-content-end pr20 mb20">
                                <RSSecondaryBtn className="paddingR0" onClick={() => {
                                    history.push({ pathname: `/communication` })
                                }}>Cancel</RSSecondaryBtn>
                                <RSSecondaryBtn
                                    className={`blue ${isEnableButton ? "" : "click-off"}`}
                                    onClick={() => {
                                        if (editClickOff && props.campaignDetails.status === 9) {
                                            history.push({ pathname: `/communication` })
                                        } else {
                                            submit(0);
                                        }
                                    }}>Save</RSSecondaryBtn>
                                <RSPrimaryBtn className={isEnableButton ? "" : "click-off"} onClick={() => {
                                    const _campaignHeader = {
                                        name: campaignDetails.campaignName,
                                        startDate: campaignDetails.startDate,
                                        endDate: campaignDetails.endDate,
                                        goal: campaignDetails.goal,
                                        type: campaignDetails.campaignAttribute.attributeName
                                    }
                                    if (editClickOff && props.campaignDetails.status === 9) {
                                        history.push({ pathname: `/communication/create-communication`, state: { mode: props.campaignDetails.mode, status: props.campaignDetails.status, campaignName: campaignDetails.campaignName, editClickOff: editClickOff, Channeltype: campaignDetails.channelTypes, AnalyticsType: campaignDetails.analyticsType, mobileApp: {}, campaignId: campaignDetails.campaignId, channelId: 0, campaignHeader: _campaignHeader, CampaignType: "SingleDimension" } })
                                    } else {
                                        submit(1, _campaignHeader);
                                    }
                                }}>Next</RSPrimaryBtn>
                            </div>
                        </Row>
                    </Container>
                    {isOpenGoalModal &&
                        <GoalModal
                            isOpen={isOpenGoalModal}
                            onChangeIsOpen={(value) => {
                                setIsOpenGoalModal(false);
                                if (value)
                                    setIsShowSecondaryGoal(!isShowSecondaryGoal);
                            }}></GoalModal>}
                    {isOpenTagsModal
                        ? <TagsComponent
                            isOpen={isOpenTagsModal}
                            onChangeIsOpen={(value) => {
                                setOpenTagsModal(value);
                            }}
                        />
                        : null}
                </>}
        </>
    )
}
export default Notification

const channelTypesArray = makeImmutableObject([
    {
        label: "Email",
        value: false,
    },
    {
        label: "SMS",
        value: false,
    },
    {
        label: "WhatsApp",
        value: false,
    },
    // { 
    //     label: "QR",
    //     value: false,
    // }
])
