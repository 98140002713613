import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { RTabbar } from "Components/RChart/RTabbar";
import SubscribeHome from "./Sub-Unsub/SubscribeHome";
import SMTP from "./SMTP/SMTP";
import EmailFooter from "./EmailFooter/EmailFooter";

const Email = (props) => {

  const [index, setIndex] = useState(props.index);

  useEffect(() => {
    setIndex(props.index);
  }, []);

  const sections = [<SMTP />, <SubscribeHome />, <EmailFooter />];

  return (
    <>
      <div className="horizontal-tab-wrapper">
        <RTabbar
          defaultSelectedItem={index}
          defaultClass="tabDefault"
          dynamicTab="rs-tab-icon rs-tab-horizontal"
          activeClass="tabDefault active"
          tabData={tabData}
          callBack={(item, index) => {
            setIndex(index);
          }}
        />
      </div>
      <div className="clearfix"></div>
      <div>{sections[index]}</div>
    </>
  );
};

export default withRouter(Email);

const tabData = [
  { id: 1001, text: "SMTP", iconLeft: "icon-sd-smtp-large icons-lg" },
  {
    id: 1002,
    text: "Sub / Unsub",
    iconLeft: "icon-sd-email-sub-unsub-large icons-lg",
  },
  {
    id: 1003,
    text: "Email footer",
    iconLeft: "icon-sd-email-footer-large icons-lg",
  },
];
