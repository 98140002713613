import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSInput2, RSRadio } from "Components/RSInputs";
import { RSPTooltip } from "Components/Tooltip";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { isEmpty, getAuthClientId } from "Helper/Utils/Utils";
import { GET_SMS_PROVIDER, GET_SMS, SAVE_SMS } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";
import { connectServer } from "Helper/Network/networkHandler";
import TwoWay from "./SMSTwoWay";
import {
  SERV_PROVIDER,
  SMS_TYPE,
  USER_NAME,
  ACCESS_POINT,
  OPERATOR_CODE,
  BATCH_AUDIENCE_LIMIT,
  ENTER_PASSWORD,
  SMS_SENDER_ID,
  INBOUND_NUMBER,
  BATCH_AUDIENCE_LENGTH,
  OPERATOR_CODE_LENGTH,
  KEY_WORDS,
  SMS_FRIENDLY_NAME,
  CONDITIONS,
} from "Helper/Constants/validationMessage";

const SMSCreation = (props) => {

  const context = useContext(authContext);

  const [serviceProvider, setServiceProvider] = useState({ keyId: 0, keyName: "-- Select provider --", });
  const [errMsgServiceProvider, setErrServiceProvider] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [smsType, setSMSType] = useState("Promotional");
  const [mode, setMode] = useState(props.mode);
  const [clientSmsSettingId, setClientSmsSettingId] = useState(0);
  const [errMsgSmsType, setErrSmsType] = useState(null);
  const [accessPoint, setAccessPoint] = useState("");
  const [userName, setUsername] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [batchAudienceLimit, setBatchAudienceLimit] = useState("");
  const [deleteSmsBound, setDeleteSmsBound] = useState([]);
  const [deleteSmsSender, setDeleteSmsSender] = useState([]);
  const [errMsgErrUserName, setErrUserName] = useState(null);
  const [userPassword, setPassword] = useState("");
  const [errMsgErrUserPassword, setErrUserPassword] = useState(null);
  const [errMsgAccessPoint, setErrAccessPoint] = useState(null);
  const [errMsgBatchAudienceLimit, setErrBatchAudienceLimit] = useState(null);
  const [errMsgOperatorCode, setErrOperatorCode] = useState(null);
  const [senderIdArray, setSenderIdArray] = useState([
    { clientSmsSenderId: 0, clientSmsSenderName: "", clientFriendlyName: "" },
  ]);
  const [errMsgSenderList, setErrMsgSenderList] = useState([
    { errMsgSenderId: null, errMsgSmsFriendlyName: null },
  ]);
  const [twoWayArray, setTwoWayArray] = useState([
    { inboundNumberId: 0, inboundNumber: "", keywords: "", condition: "" },
  ]);
  const [errMessTwoWay, setErrMsgTwoWay] = useState([
    { errMsgInboundNumber: null, errMsgKeyWord: null, errMsgConditions: null },
  ]);

  useEffect((props) => {
    window.scrollTo(0, 0);
    //For Edit Page Load Data from ServerApi
    getSMSProvider();
    getSMSCreationDataFromServer(mode);
  }, []);

  const getSMSCreationDataFromServer = (mode) => {
    if (mode === "edit") {
      const params = {
        clientSmsSettingId: props.clientSmsSettingId,
      };
      connectServer({
        path: GET_SMS,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            if (res?.data) {
              let keyparams = {
                keyId: res.data.serviceProviderId,
                keyName: res.data.serviceProviderName,
              };
              setMode(mode);
              setClientSmsSettingId(res.data.clientSmsSettingId);
              setServiceProvider(keyparams);
              setUsername(res.data.userName);
              setPassword(res.data.password);
              setAccessPoint(res.data.accessPoint);
              setBatchAudienceLimit(res.data.batchAudienceLimit);
              setOperatorCode(res.data.operatorCode);
              if (res.data.settingType === "P") {
                setSMSType("Promotional");
              } else if (res.data.settingType === "T") {
                setSMSType("Transactional");
              } else {
                setSMSType("Two way SMS");
              }
              var senderLoadData = [];
              var errArrayLoad = [];
              res.data.smsSenderList?.map((item, index) => {
                var params = {
                  clientSmsSenderId: item.clientSMSSenderId,
                  clientSmsSenderName: item.clientSmsSenderName,
                  clientFriendlyName: item.clientFriendlyName,
                };
                var errorParams = {
                  errMsgSenderId: null,
                  errMsgSmsFriendlyName: null,
                };
                errArrayLoad.push(errorParams);
                senderLoadData.push(params);
              });
              setErrMsgSenderList(errArrayLoad);
              setSenderIdArray(senderLoadData);
              if (res.data.settingType === "Two") {
                var inboundLoadData = [];
                var inboundErrData = [];
                res.data.smsBoundList?.map((item, index) => {
                  var params = {
                    inboundNumberId: parseInt(item.inboundNumberID, 10),
                    inboundNumber: parseInt(item.inboundNumber, 10),
                    keywords: item.keywords,
                    condition: item.condition,
                  };
                  var errorParams = {
                    errMsgInboundNumber: null,
                    errMsgKeyWord: null,
                    errMsgConditions: null,
                  };
                  inboundErrData.push(errorParams);
                  inboundLoadData.push(params);
                });
                setErrMsgTwoWay(inboundErrData);
                setTwoWayArray(inboundLoadData);
              }
            }
          }
        },
        fail: (err) => { },
      });
    }
  };

  const getSMSProvider = () => {
    connectServer({
      path: GET_SMS_PROVIDER,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) {
            setServiceData(res.data || []);
          }
        }
      },
      fail: (err) => { },
    });
  };

  const senderIdValidate = () => {
    let isValidate = true;
    for (let i = 0; i < senderIdArray.length; i++) {
      if (isEmpty(senderIdArray[i].clientSmsSenderName)) {
        let errMsg = SMS_SENDER_ID;
        let temp = [...errMsgSenderList];
        temp[i].errMsgSenderId = errMsg;
        setErrMsgSenderList(temp);
        isValidate = false;
      }
      if (isEmpty(senderIdArray[i].clientFriendlyName)) {
        let errMsg = SMS_FRIENDLY_NAME;
        let temp = [...errMsgSenderList];
        temp[i].errMsgSmsFriendlyName = errMsg;
        setErrMsgSenderList(temp);
        isValidate = false;
      }
    }
    return isValidate;
  };

  const twoWayValidate = () => {
    let isValidate = true;
    for (let i = 0; i < twoWayArray.length; i++) {
      if (isEmpty(twoWayArray[i].inboundNumber)) {
        let errMsg = INBOUND_NUMBER;
        let temp = [...errMessTwoWay];
        temp[i].errMsgInboundNumber = errMsg;
        setErrMsgTwoWay(temp);
        isValidate = false;
      }
      if (isEmpty(twoWayArray[i].keywords)) {
        let errMsg = KEY_WORDS;
        let temp = [...errMessTwoWay];
        temp[i].errMsgKeyWord = errMsg;
        setErrMsgTwoWay(temp);
        isValidate = false;
      }
      if (isEmpty(twoWayArray[i].condition)) {
        let errMsg = CONDITIONS;
        let temp = [...errMessTwoWay];
        temp[i].errMsgConditions = errMsg;
        setErrMsgTwoWay(temp);
        isValidate = false;
      }
    }
    return isValidate;
  };

  const validateFormFields = () => {
    let isValidate = true;
    if (serviceProvider.keyId === 0) {
      setErrServiceProvider(SERV_PROVIDER);
      isValidate = false;
    }
    if (isEmpty(smsType)) {
      setErrSmsType(SMS_TYPE);
      isValidate = false;
    }
    if (isEmpty(accessPoint)) {
      setErrAccessPoint(ACCESS_POINT);
      isValidate = false;
    }
    if (isEmpty(userName)) {
      setErrUserName(USER_NAME);
      isValidate = false;
    }
    if (isEmpty(userPassword)) {
      setErrUserPassword(ENTER_PASSWORD);
      isValidate = false;
    }
    if (isEmpty(operatorCode)) {
      setErrOperatorCode(OPERATOR_CODE);
      isValidate = false;
    }
    if (isEmpty(batchAudienceLimit)) {
      setErrBatchAudienceLimit(BATCH_AUDIENCE_LIMIT);
      isValidate = false;
    }
    let isValidateSenderID = true;
    let isValidateTwoWay = true;
    isValidateSenderID = senderIdValidate();
    if (smsType === "Two way SMS") {
      isValidateTwoWay = twoWayValidate();
    }
    return (isValidate && isValidateSenderID && isValidateTwoWay);
  };

  const saveSms = () => {
    var params = {
      clientSmsSettingID: clientSmsSettingId,
      serviceProviderID: serviceProvider.keyId,
      userName: userName,
      password: userPassword,
      accessPoint: accessPoint,
      operatorCode: operatorCode,
      batchAudienceLimit: batchAudienceLimit,
      clientID: getAuthClientId(),
      settingType:
        smsType === "Promotional"
          ? "P"
          : smsType === "Transactional"
            ? "T"
            : "Two",
      smsSenderlist: senderIdArray,
      smsBoundlist: smsType === "Two way SMS" ? twoWayArray : [],
      deleteSMSSender: deleteSmsSender,
      deleteSMSBound: deleteSmsBound,
    };

    connectServer({
      path: SAVE_SMS,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          props.onChangeIndex(0);
        }
      },
      fail: (err) => { },
    });
  };

  return (
    <div>
      <div className="onboard-header-label marginT18">
        <h3>SMS settings</h3>
        <Row>
          <Col md={12} className="marginT20 ml15">
            <Row>
              <Col md={6}>
                {errMsgServiceProvider && (
                  <div className="validation-message">
                    {errMsgServiceProvider}
                  </div>
                )}
                <RSDropdownList
                  textField="keyName"
                  dataItemKey="keyId"
                  className="required"
                  data={serviceData}
                  value={serviceProvider}
                  defaultItem={{
                    keyId: 0,
                    keyName: "-- Select Provider --",
                  }}
                  onChange={(event) => {
                    setServiceProvider(event.target.value);
                    let errMsg = event.target.value.keyId
                      ? null
                      : SERV_PROVIDER;
                    setErrServiceProvider(errMsg);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <RSRadio
                      lbl={"Promotional"}
                      name={"smsType"}
                      className="marginB20"
                      labelId={"smsType1"}
                      checked={smsType === "Promotional" ? true : false}
                      cb={(event) => {
                        setSMSType(event.target.value);
                        setTwoWayArray([
                          {
                            inboundNumberId: 0,
                            inboundNumber: "",
                            keywords: "",
                            condition: "",
                          },
                        ]);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <RSRadio
                      lbl={"Transactional"}
                      name={"smsType"}
                      className="marginB20"
                      labelId={"smsType2"}
                      checked={smsType === "Transactional" ? true : false}
                      cb={(event) => {
                        setSMSType(event.target.value);
                        setTwoWayArray([
                          {
                            inboundNumberId: 0,
                            inboundNumber: "",
                            keywords: "",
                            condition: "",
                          },
                        ]);
                      }}
                    />
                  </Col>
                  {/* <Col md={4}>
                    <RSRadio
                      lbl={"Two way SMS"}
                      name={"smsType"}
                      className="marginB20"
                      labelId={"smsType3"}
                      checked={smsType === "Two way SMS" ? true : false}
                      cb={(event) => {
                        setSMSType(event.target.value);
                      }}
                    />
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <RSInput2
                  name="accessPoint"
                  ph={"Enter access point"}
                  val={accessPoint}
                  required={true}
                  max={50}
                  cb={(value) => {
                    setAccessPoint(value);
                    let errMsg = value ? null : ACCESS_POINT;
                    setErrAccessPoint(errMsg);
                  }}
                  ob={(value) => {
                    setAccessPoint(value);
                    let errMsg = value ? null : ACCESS_POINT;
                    setErrAccessPoint(errMsg);
                  }}
                  errorMessage={errMsgAccessPoint}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <RSInput2
                  name="userName"
                  ph={"Enter user name"}
                  val={userName}
                  required={true}
                  max={50}
                  cb={(value) => {
                    setUsername(value);
                    let errMsg = value ? null : USER_NAME;
                    setErrUserName(errMsg);
                  }}
                  ob={(value) => {
                    setUsername(value);
                    let errMsg = value ? null : USER_NAME;
                    setErrUserName(errMsg);
                  }}
                  errorMessage={errMsgErrUserName}
                />
                <RSInput2
                  name="password"
                  ph={"Enter password"}
                  required={true}
                  type="password"
                  val={userPassword}
                  max={20}
                  cb={(value) => {
                    setPassword(value);
                    let errMsg = value ? null : ENTER_PASSWORD;
                    setErrUserPassword(errMsg);
                  }}
                  ob={(value) => {
                    setPassword(value);
                    let errMsg = value ? null : ENTER_PASSWORD;
                    setErrUserPassword(errMsg);
                  }}
                  errorMessage={errMsgErrUserPassword}
                />
                <RSInput2
                  name="operatorCode"
                  ph={"Enter operator code "}
                  required={true}
                  val={operatorCode}
                  max={5}
                  cb={(value) => {
                    setOperatorCode(value);
                    let errMsg = value ? null : OPERATOR_CODE;
                    setErrOperatorCode(errMsg);
                    let errLengthMsg =
                      value.length > 5 ? OPERATOR_CODE_LENGTH : null;
                    setErrOperatorCode(errLengthMsg);
                  }}
                  ob={(value) => {
                    setOperatorCode(value);
                    let errMsg = value ? null : OPERATOR_CODE;
                    setErrOperatorCode(errMsg);
                    let errLengthMsg =
                      value.length > 5 ? OPERATOR_CODE_LENGTH : null;
                    setErrOperatorCode(errLengthMsg);
                  }}
                  errorMessage={errMsgOperatorCode}
                />
                <RSInput2
                  name="batchAudience"
                  ph={"Batch audience limit "}
                  val={batchAudienceLimit}
                  required={true}
                  max={20}
                  cb={(value) => {
                    setBatchAudienceLimit(value);
                    let errMsg = value ? null : BATCH_AUDIENCE_LIMIT;
                    setErrBatchAudienceLimit(errMsg);
                    let errLengthMsg =
                      value.length > 20 ? BATCH_AUDIENCE_LENGTH : null;
                    setErrBatchAudienceLimit(errLengthMsg);
                  }}
                  ob={(value) => {
                    setBatchAudienceLimit(value);
                    let errMsg = value ? null : BATCH_AUDIENCE_LIMIT;
                    setErrBatchAudienceLimit(errMsg);
                    let errLengthMsg =
                      value.length > 20 ? BATCH_AUDIENCE_LENGTH : null;
                    setErrBatchAudienceLimit(errLengthMsg);
                  }}
                  errorMessage={errMsgBatchAudienceLimit}
                />
              </Col>
            </Row>
            <h4 className="marginT30 marginB20">Sender ID</h4>

            {senderIdArray?.map((item, index) => {
              return (
                <Row key={index}>
                  <Col md={4}>
                    <RSInput2
                      name="senderId"
                      ph={"SMS sender ID"}
                      required={true}
                      max={50}
                      val={item.clientSmsSenderName}
                      cb={(value) => {
                        let temp = [...senderIdArray];
                        temp[index].clientSmsSenderName = value;
                        setSenderIdArray(temp);
                        item.clientSmsSenderName = value;
                        let errMsg = item.clientSmsSenderName
                          ? null
                          : SMS_SENDER_ID;
                        let tempError = [...errMsgSenderList];
                        tempError[index].errMsgSenderId = errMsg;
                        setErrMsgSenderList(tempError);
                      }}
                      errorMessage={errMsgSenderList[index].errMsgSenderId}
                    />
                  </Col>
                  <Col md={4}>
                    <RSInput2
                      name="smsFriendlyName"
                      ph={"SMS friendly name"}
                      required={true}
                      max={12}
                      val={item.clientFriendlyName}
                      cb={(value) => {
                        let temp = [...senderIdArray];
                        temp[index].clientFriendlyName = value;
                        setSenderIdArray(temp);
                        item.clientFriendlyName = value;
                        let errMsg = item.clientFriendlyName
                          ? null
                          : SMS_FRIENDLY_NAME;
                        let tempError = [...errMsgSenderList];
                        tempError[index].errMsgSmsFriendlyName = errMsg;
                        setErrMsgSenderList(tempError);
                      }}
                      errorMessage={
                        errMsgSenderList[index].errMsgSmsFriendlyName
                      }
                    />
                    {
                      index >= 1 ? (
                        <div className="input-outer-set">
                          <RSPTooltip text="Remove sender ID" position="top">
                            <i
                              className="icons-md red cursor-pointer float-left d-block icon-minus-fill-medium ml10"
                              onClick={() => {
                                if (mode === "edit") {
                                  if (senderIdArray[index].clientSmsSenderId !== 0) {
                                    let deleteSmsSender = [];
                                    let clientSmsSenderId =
                                      senderIdArray[index].clientSmsSenderId;
                                    deleteSmsSender.push(clientSmsSenderId);
                                    setDeleteSmsSender(deleteSmsSender);
                                  }
                                }
                                if (index !== 0) {
                                  let temp = [...senderIdArray];
                                  temp.splice(index, 1);
                                  setSenderIdArray(temp);
                                }
                              }}
                            />
                          </RSPTooltip>
                        </div>
                      )
                        : <div className="input-outer-set">
                          <RSPTooltip text="Add sender ID" position="top">
                            <i
                              className={`icon-sd-circle-plus-fill-edge-medium icons-md blue float-right cursor-pointer ml10 ${senderIdArray.length === 5 ? "click-off" : ""
                                }`}
                              onClick={() => {
                                if (senderIdValidate()) {
                                  if (senderIdArray?.length < 5) {
                                    let tempArray = [...senderIdArray];
                                    tempArray.push({
                                      clientSmsSenderId: 0,
                                      clientSmsSenderName: "",
                                      clientFriendlyName: "",
                                    });
                                    setSenderIdArray(tempArray);
                                    let tempError = [...errMsgSenderList];
                                    tempError.push({
                                      errMsgSenderId: null,
                                      errMsgSmsFriendlyName: null,
                                    });
                                    setErrMsgSenderList(tempError);
                                  }
                                }
                              }}
                            ></i>
                          </RSPTooltip>
                        </div>
                    }
                  </Col>
                </Row>
              );
            })}
            {smsType === "Two way SMS" && (
              <TwoWay
                array={twoWayArray}
                mode={mode}
                error={errMessTwoWay}
                getValue={(twoWayData) => setTwoWayArray(twoWayData)}
                getError={(twoWayErrMsg) => setErrMsgTwoWay(twoWayErrMsg)}
                onCheck={() => {
                  let temp = [...twoWayArray];
                  temp.push({
                    inboundNumberId: 0,
                    inboundNumber: "",
                    keywords: "",
                    condition: "",
                  });
                  setTwoWayArray(temp);
                  let temperror = [...errMessTwoWay];
                  temperror.push({
                    errMsgInboundNumber: null,
                    errMsgKeyWord: null,
                    errMsgConditions: null,
                  });
                  setErrMsgTwoWay(temperror);
                }}
                onPassInboundId={(inboundId) => {
                  let deleteInboundData = [];
                  deleteInboundData.push(inboundId);
                  setDeleteSmsBound(deleteInboundData);
                  setTwoWayArray(
                    twoWayArray.filter(
                      (item) => item.inboundNumberId !== inboundId
                    )
                  );
                }}
              />
            )}
          </Col>
        </Row>
        <div className="btn-container d-flex justify-content-end marginT25">
          <RSSecondaryBtn onClick={() => props.onChangeIndex(0)}>
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              if (validateFormFields()) {
                saveSms();
              }
            }}
          >
            {mode !== "edit" ? "Save" : "Update"}
          </RSPrimaryBtn>
        </div>
      </div>
    </div>
  );
};
export default SMSCreation;
