import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import jsonData from "./CampaignList.json";
const CampaignListModal = props => {
    const [isOpen, setIsShow] = useState(props.isOpen);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    return (
        <Modal backdrop="static"
            keyboard={false} show={isOpen} centered size='xl' onHide={() => {
                setIsShow(!isOpen);
                props.onChangeIsOpen(false);
            }}>
            <Modal.Header closeButton>
                <div></div>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt5">
                    <Grid
                        data={jsonData.slice(skip, take + skip)}
                        total={jsonData.length}
                        scrollable={true}
                        className="grid-pagenation-del"
                        pageable={{
                            //type: 'input',
                            pageSizes: [5, 10, 15],
                            previousNext: true,
                        }}
                    >
                        <GridColumn field="campaignName" title="Campaign name" columnMenu={ColumnMenu} />
                        <GridColumn field="sentOn" title="Sent on" columnMenu={ColumnMenu} />
                        <GridColumn field="campaignType" title="Campaign type" columnMenu={ColumnMenu} />
                        <GridColumn field="deliveryMethod" title="Delivery method" columnMenu={ColumnMenu} />
                        <GridColumn field="sent" title="Sent" columnMenu={ColumnMenu} />
                        <GridColumn field="delivered" title="Delivered" columnMenu={ColumnMenu} />
                        <GridColumn field="opened" title="Opened" columnMenu={ColumnMenu} />
                        <GridColumn field="clicked" title="Clicked" columnMenu={ColumnMenu} />
                        <GridColumn field="bounced" title="Bounced" columnMenu={ColumnMenu} />
                    </Grid>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )

}
export default CampaignListModal;

const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;
};