import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper'
import { GET_CONSUMABLE_INVOICE_BY_ID, GET_LIST_INVOICE_BY_ID, GET_SUBSCRIBE_INVOCE_DETAILS } from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler'
import authContext from 'Helper/StateHandler/auth-context'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from "react-bootstrap"
import { useHistory } from 'react-router-dom'
import { capitalizeEveryWord, dateFormat, dateFormatWithMomentForInvoice, dateTimeFormatWithMomentForInvoice } from 'Helper/Utils/Utils';
import CommunicationInvoiceById from './Components/CommunicationInvoiceById'
import SubscriptionInvoiceById from './Components/SubscriptionInvoiceById'
import ListInvoiceById from './Components/ListInvoiceById'

const Invoice = props => {
    let history = useHistory()
    let context = useContext(authContext)
    const [invoiceDetails, setInvoiceDetails] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0);
        getDataFromServer()
    }, [])

    const getDataFromServer = () => {
        const { invoiceId, type } = history.location.state
        let params = type === 'SubscriptionInvoice' ? { invoiceId: invoiceId } : type === "List" ? { audienceInvoiceId: invoiceId } : { consumableId: invoiceId }
        connectServer({
            path: type === 'SubscriptionInvoice' ? GET_SUBSCRIBE_INVOCE_DETAILS : type === "List" ? GET_LIST_INVOICE_BY_ID : GET_CONSUMABLE_INVOICE_BY_ID,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setInvoiceDetails(res.data)
                }
            },
            fail: fail => {

            }
        })
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="header-title d-flex justify-content-between">
                    <h1>Invoice</h1>
                    <div>
                        <i className="icon-sd-download-medium icons-md blue cursor-pointer click-off"></i>
                    </div>
                </div>
                <div className="portlet-box-theme">
                    <div className="d-flex justify-content-between theme-space-mb">
                        <div className="invoice-address">
                            <h4>To,</h4>
                            <ul className="">
                                <li>{invoiceDetails?.customerName?.length > 0 ? capitalizeEveryWord(invoiceDetails?.customerName) + "," : ""}</li>
                                <li>{invoiceDetails?.clientName?.length > 0 ? capitalizeEveryWord(invoiceDetails?.clientName) + "," : ""}</li>
                                <li>{invoiceDetails?.address1.length > 0 ? capitalizeEveryWord(invoiceDetails?.address1) + "," : ""}</li>
                                <li>{invoiceDetails?.address2.length > 0 ? capitalizeEveryWord(invoiceDetails?.address2) + "," : ""}</li>
                                <li>{invoiceDetails?.city.length > 0 ? capitalizeEveryWord(invoiceDetails?.city) + "," : ""}</li>
                                <li>{invoiceDetails?.country}</li>
                            </ul>
                        </div>
                        <table className="rs-table-sm">
                            <tbody>
                                <tr>
                                    <td>Date/Time:</td>
                                    <td>{dateTimeFormatWithMomentForInvoice(invoiceDetails?.invoiceDateTime)}</td>
                                </tr>
                                <tr>
                                    <td>Invoice number:</td>
                                    <td>{invoiceDetails?.invoiceNo}</td>
                                </tr>
                                <tr>
                                    <td>Payable amount:</td>
                                    <td>${history.location.state.type === 'SubscriptionInvoice' ? invoiceDetails?.salePrice : invoiceDetails?.totalAmount}</td>
                                </tr>
                                <tr>
                                    <td>Due date:</td>
                                    <td>{dateFormatWithMomentForInvoice(invoiceDetails?.dueDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="horizontal-list-row theme-space-mb rs-radius even">
                        <div className="horizontal-list">
                            <small>Account/Client:</small>
                            <h4>{invoiceDetails?.clientName}</h4>
                        </div>
                        <div className="horizontal-list">
                            <small>Account.No:</small>
                            <h4>{invoiceDetails?.accountNo}</h4>
                        </div>
                        {/* <div className="horizontal-list">
                            <small>Type:</small>
                            <h4>{invoiceDetails?.description}</h4>
                        </div> */}
                    </div>
                    <div className="notification-box bgGreen-light d-none">
                        <p> You have acknowledged to make this payment using the authorization code
                            <span className="bgGreen paddingX5 marginX5">{invoiceDetails?.authorizationCode}</span>
                            entered by
                            <span> {invoiceDetails?.customerName} ({invoiceDetails?.maskedEmailId})</span>
                        </p>
                    </div>
                    {history.location.state.type === "SubscriptionInvoice" ?
                        <SubscriptionInvoiceById invoiceDetails={invoiceDetails} />
                        : history.location.state.type === "List" ?
                            <ListInvoiceById invoiceDetails={invoiceDetails} /> :
                            invoiceDetails?.consumableSummary &&
                            <CommunicationInvoiceById invoiceDetails={invoiceDetails} />}

                    <div className="clearfix small invoice-note">
                        <p>Note:</p>
                        {
                            history.location.state.type === "ConsumableInvoice" ?
                                <>
                                    <p>1) All pricing mentioned above are in USD.</p>
                                    <p>2) Payment details - Bank name : Standard chartered bank | Account number : 0104184965 | SWIFT code : SCBLSGSG.</p>
                                    <p>3) In case of any clarification on this invoice, please contact us within 10 days from the billing date.</p>
                                </> :
                                <>
                                    <p>1) Payment terms : 15 days (18% of interest will be applicable for delay payment beyond stipulated days).</p>
                                    <p>2) All pricing mentioned above are in USD.</p>
                                    <p>3) Payment details - Bank name : Standard chartered bank | Account number : 0104184965 | SWIFT code : SCBLSGSG.</p>
                                    <p>4) In case of any clarification on this invoice, please contact us within 10 days from the billing date.</p>
                                </>
                        }

                    </div>

                    <div className="clearfix small text-center marginT35">
                        <p className="marginB5">For any queries, please write to <span className="rs-link blue" onClick={() => {
                            window.location.href = "mailto:billing@marketingstar.us"
                        }}>billing@marketingstar.us</span></p>
                        <p className="marginB15">"Marketing Star Singapore Pte Ltd, Singapore Land Tower #37, 50 Raffles Place, Singapore - 048623, Tel: +65 6238 8995 / Fax: +65 6238 8943, Biz. <br/>Reg. no: 201429642C"</p>

                        <h4>Thank you for your business.</h4>
                    </div>
                </div>
            </Container>
        </LayoutPageWrapper >
    )
}

export default Invoice