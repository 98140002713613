import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import UploadComponent from '../../CommunicationSettings/UploadComponent';

class MobileDataExchange extends Component {
    state = {
        appName: "",
        deviceType: "",
        playStoreURL: "",
        notificationProvider: "FCM",
        projectID: "",
        notificationAppKey: "",
        certificateValue: "",
        password: "",
        update: 0,
        deviceSetups: [
            {
                deviceType: "",
                playStoreURL: "",
                notificationProvider: "FCM",
                projectID: "",
                notificationAppKey: "",
            }
        ]
    }
    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className=''>
                        <div className="header-title d-flex justify-content-between">
                            <h1>Data exchange</h1>
                        </div>
                    </div>
                    <div className="portlet-box-theme pb0">
                        <h4>In App Analytics</h4>
                        <Row className="mt20">
                            <Col md={2}>
                                <label className="label-row">App name</label>
                            </Col>
                            <Col md={6} >
                                <RSInput2
                                    ph="Enter app name"
                                    val={this.state.appName}
                                    cb={(text) => {
                                        this.setState({ appName: text });
                                    }} />
                            </Col>
                        </Row>

                        <h4>Device setup</h4>
                        <Row className="mb20">
                            <Col md={11}>
                                {this.state.deviceSetups.map((item, index) => {
                                    return (
                                        <div className='position-relative mb15'>
                                            <div className='bgGrey-lighter data-even-row'>
                                                <label className="label-row">App details</label>
                                                <Row className="mt5">
                                                    <Col md={6}>
                                                        <RSDropdownList
                                                            data={DeviceList}
                                                            value={item.deviceType !== "" ? item.deviceType : "-- Choose device --"}
                                                            ph={item.deviceType}
                                                            onChange={(e) => {
                                                                item.deviceType = e.target.value;
                                                                this.setState({ deviceType: e.target.value });
                                                            }}
                                                        />
                                                    </Col>
                                                    {item.deviceType !== "" &&
                                                        <Col md={6}>
                                                            <RSInput2
                                                                ph={item.deviceType.startsWith("i") ? "Appstore URL" : "Playstore URL"}
                                                                val={item.playStoreURL}
                                                                cb={(text) => {
                                                                    item.playStoreURL = text;
                                                                    this.setState({ playStoreURL: text });
                                                                }} />
                                                        </Col>}
                                                </Row>
                                            </div>
                                            {item.deviceType !== "" &&
                                                <div className='bgGrey-lighter data-even-row'>
                                                    <label className="label-row">Notification</label>
                                                    <Row className="mt5">
                                                        {item.deviceType.startsWith("i") ?
                                                            <>
                                                                <Col md={6}>
                                                                    <RSRadio
                                                                        lbl="APNS"
                                                                        name={"notificationProvider" + index.toString()}
                                                                        labelId={"notificationProviderId" + index.toString()}
                                                                        className=""
                                                                        checked={item.notificationProvider.toLowerCase() === "apns"}
                                                                        cb={(event) => {
                                                                            item.notificationProvider = event.target.value;
                                                                            this.setState({ notificationProvider: event.target.value });
                                                                        }} />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <RSRadio
                                                                        lbl="FCM"
                                                                        name={"notificationProvider1" + index.toString()}
                                                                        labelId={"notificationProviderId1" + index.toString()}
                                                                        className=""
                                                                        checked={item.notificationProvider.toLowerCase() === "fcm"}
                                                                        cb={(event) => {
                                                                            item.notificationProvider = event.target.value;
                                                                            this.setState({ notificationProvider: event.target.value });
                                                                        }} />
                                                                </Col>
                                                            </> :
                                                            <>
                                                                <Col md={6}>
                                                                    <RSRadio
                                                                        lbl="FCM"
                                                                        name={"notificationProvider" + index.toString()}
                                                                        labelId={"notificationProviderId" + index.toString()}
                                                                        className=""
                                                                        checked={item.notificationProvider.toLowerCase() === "fcm"}
                                                                        cb={(event) => {
                                                                            item.notificationProvider = event.target.value;
                                                                            this.setState({ notificationProvider: event.target.value });
                                                                        }} />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <RSRadio
                                                                        lbl="GCM"
                                                                        name={"notificationProvider1" + index.toString()}
                                                                        labelId={"notificationProviderId1" + index.toString()}
                                                                        className=""
                                                                        checked={item.notificationProvider.toLowerCase() === "gcm"}
                                                                        cb={(event) => {
                                                                            item.notificationProvider = event.target.value;
                                                                            this.setState({ notificationProvider: event.target.value });
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </>}
                                                    </Row>
                                                    <Row className="label-row">
                                                        {!item.deviceType.startsWith("i") ?
                                                            <>
                                                                <Col md={6}>
                                                                    <RSInput2
                                                                        ph="ProjectID"
                                                                        val={item.projectID}
                                                                        cb={(text) => {
                                                                            item.projectID = text;
                                                                            this.setState({ projectID: text });
                                                                        }} />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <RSInput2
                                                                        ph="Notification App key"
                                                                        val={item.notificationAppKey}
                                                                        cb={(text) => {
                                                                            item.notificationAppKey = text;
                                                                            this.setState({ notificationAppKey: text });
                                                                        }} />
                                                                </Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Col md={6}>
                                                                    <Row>
                                                                        <Col md={9}>
                                                                            <RSInput2
                                                                                ph="Certificate"
                                                                                val={item.projectID}
                                                                                cb={(text) => {
                                                                                    item.projectID = text;
                                                                                    this.setState({ certificateValue: text });
                                                                                }} />
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <UploadComponent onChange={(value) => {
                                                                                item.projectID = value;
                                                                                this.setState({ certificateValue: value });
                                                                            }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <RSInput2
                                                                        ph="Password"
                                                                        val={item.notificationAppKey}
                                                                        cb={(text) => {
                                                                            item.notificationAppKey = text;
                                                                            this.setState({ password: text });
                                                                        }} />
                                                                </Col>
                                                            </>}
                                                    </Row>
                                                </div>}
                                            {index === 0 && (
                                                <div className="pos-icon-1" onClick={() => {
                                                    let temp = [...this.state.deviceSetups];
                                                    temp.push({
                                                        deviceType: "",
                                                        playStoreURL: "",
                                                        notificationProvider: "FCM",
                                                        projectID: "",
                                                        notificationAppKey: "",
                                                    });
                                                    this.setState({ deviceSetups: temp });
                                                }}>
                                                    <i className={`icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer ${this.state.deviceSetups.length > 3 ? "click-off" : "clickon"}`}
                                                        title="Add"></i>
                                                </div>)}
                                            {index > 0 && (
                                                <div className="pos-icon-1" onClick={() => {
                                                    let temp = [...this.state.deviceSetups];
                                                    const index1 = temp.indexOf(item);
                                                    temp.splice(index1, 1);
                                                    this.setState({ deviceSetups: temp });
                                                }}>
                                                    <i className="icon-sd-circle-minus-fill-edge-medium icons-md red cursor-pointer"></i>
                                                </div>)}
                                        </div>)
                                })}
                            </Col>
                        </Row>
                    </div>
                    <div className="btn-container d-flex justify-content-end marginT15">
                        <RSSecondaryBtn className="mr0" onClick={() => { this.props.history.push({ pathname: "/preferences/data-exchange", state: { selectedData: this.props.history.location.state.selectedData } }); }}>
                            Cancel
                        </RSSecondaryBtn>
                        <RSSecondaryBtn className="mx7 blue" onClick={() => { this.props.history.push({ pathname: "/preferences/data-exchange", state: { selectedData: this.props.history.location.state.selectedData } }); }}>
                            Save
                        </RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            this.props.history.push({ pathname: "/preferences/data-exchange", state: { selectedData: this.props.history.location.state.selectedData } });
                        }}>
                            Proceed
                        </RSPrimaryBtn>
                    </div>
                </Container>
            </LayoutPageWrapper>
        );
    }
}

export default withRouter(MobileDataExchange);

const DeviceList = ["Android phone", "Android tab", "iPhone", "iPad"];