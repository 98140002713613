import { Grid, GridColumn } from '@progress/kendo-react-grid';
import SkeletonTable from 'Components/Skeleton/SkeletonTable';
import { GET_TOPLINK_CLICKS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import React, { Component } from 'react';
import authContext from 'Helper/StateHandler/auth-context';

class SMSTotalClicks extends Component {
    static contextType = authContext
    state = {
        listClicksError: false,
        linkClickData: null,
        skip: 0,
        take: 10,
        campaignGuid: this.props.campaignGuid,
        rowKey: this.props.rowKey
    }
    componentDidMount = () => {
        if (this.state.rowKey !== "" && !!this.props.campaignGuid) {
            this.getTopLinkClicks(this.state.rowKey);
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.setState({
                campaignGuid: this.props.campaignGuid,
                rowKey: this.props.rowKey
            })
            if (this.props.rowKey !== prevProps.rowKey) {
                this.getTopLinkClicks(this.props.rowKey);
            }
        }
    }
    getTopLinkClicks = rowKey => {

        const params = {
            "campaignGuid": this.state.campaignGuid,
            "channelId": 2,
            "rowkey": rowKey
        }

        connectServer({
            path: GET_TOPLINK_CLICKS,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res?.data?.length) {
                    this.setState({ linkClickData: res?.data })
                } else {
                    this.setState({ listClicksError: true })
                }
            },
            fail: err => {

            }
        })

    }
    render() {
        return (
            <>
                <h3 className="marginB15 mt15">Total link click activity</h3>
                {this.state.linkClickData
                    ? <Grid
                        data={this.state.linkClickData?.slice(this.state.skip, this.state.take + this.state.skip)}
                        total={this.state.linkClickData?.length}
                        scrollable={"none"}
                        sortable={true}
                        className={`no-grid-pagenation-del grid-replaceable total-link-wrap-col ${this.state.linkClickData?.length > 5 ? '' : 'mb0'}`}
                        pageable={
                            this.state.linkClickData?.length > 5 ? {
                            pageSizes: true,
                            previousNext: true
                        } : false}
                    >
                        <GridColumn field="sNo" title="S.No." />
                        <GridColumn field="url" title="Link (URL)" />
                        <GridColumn field="totalClicks" title="Total clicks" />
                        <GridColumn field="uniqueClicks" title="Unique clicks" />
                    </Grid>
                    : <SkeletonTable count={4} isError={this.state.listClicksError} />
                }
            </>
        );
    }
}

export default SMSTotalClicks;