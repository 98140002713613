import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class ShowLinksPopup extends Component {
    state = {
        isShow: this.props.isOpen
    }
    render() {
        return (
            <Modal show={this.state.isShow}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
                onHide={() => {
                    this.setState({ isShow: false })
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header className='border-0 mt5' closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Grid
                        className="grid-pagenation-del grid-show-link"
                        data={this.props.linkList}
                        scrollable={"none"}
                        sortable={true}
                    >
                        <Column field="linkUrl" filter="text" title="Link (URL)" />
                        <Column width={90} field="count" filter="text" title="Clicks" />
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ShowLinksPopup;