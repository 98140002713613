import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper'
import React, { useState } from 'react'
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { Container, Row, Col } from "react-bootstrap"
import jsonData from "./Info.json"
import { RTabbar } from 'Components/RChart/RTabbar';
import { useHistory } from 'react-router-dom';

import { SearchViewAdvanced } from 'Components/SearchView/SearchView';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';

const Info = props => {
    const history = useHistory()

    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(5)
    const [gridData, setGridData] = useState(jsonData["webPush"])
    const [column, setColumn] = useState(columnData["webPush"])
    const [index, setIndex] = useState(0)

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }
    return <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
            <div className="header-title d-flex justify-content-between">
                <h1>Consumption</h1>
                <div className="d-flex marginT5">
                    <RTabbar
                        defaultSelectedItem={index}
                        defaultClass="tabDefault"
                        dynamicTab="mini marginB0 marginR10"
                        activeClass="tabDefault active"
                        tabData={tabs}
                        callBack={(item, index) => {
                            setGridData(jsonData[item.id])
                            setColumn(columnData[item.id])
                            setSkip(0)
                            setTake(5)
                            setIndex(index)
                        }}
                    />
                    <div className="back-text marginL10 marginT-6" onClick={() => { history.push(`/preferences`) }}>
                        <i className="icon-sd-arrow-left-mini icon-mini blue" ></i>
                        Back
                    </div>
                </div>
            </div>

            <hr className="blue mb30" />

            <Row>
                <Col>
                    <div className="d-flex justify-content-between marginB20">
                        <div className="d-flex align-items-center">
                            <h5 className="marginB0">Smartlinks</h5>
                            <span className="date-text marginT2 marginR5 marginL5">(As on: Mon, Sep 27, 2021)</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <SearchViewAdvanced
                                cls="marginR15"
                                dropdownData={['All', 'Personal', 'Business', 'Privilege']}
                            />
                            <RSPDateRangePicker class="marginR15" />
                            <i className="icon-sd-csv-medium icons-lg blue cursor-pointer"></i>
                        </div>
                    </div>
                </Col>
                <Grid
                    data={gridData.slice(skip, take + skip)}
                    skip={skip}
                    take={take}
                    total={gridData.length}
                    scrollable={"none"}
                    className="grid-pagenation-del"
                    onPageChange={pageChange}
                    pageable={{
                        //type: 'input',
                        pageSizes: [5, 10, 15],
                        previousNext: true,
                    }}
                >

                    {
                        column.map(item => <GridColumn columnMenu={ColumnMenu} field={item.field} title={item.title} />)
                    }

                </Grid>
            </Row>
        </Container>
    </LayoutPageWrapper>
}

export default Info

const tabs = [
    { id: "webPush", text: "Web push" },
    { id: "mobilePush", text: "Mobile push" },
    { id: "links", text: "Links" },
    { id: "sdkProfile", text: "SDK profile" }
]

const columnData = {
    "webPush": [
        {
            "field": "campaignName",
            "title": "Campaign name"
        },
        {
            "field": "sentOn",
            "title": "Sent on"
        },
        {
            "field": "campaignType",
            "title": "Campaign type"
        },
        {
            "field": "deliveryMethod",
            "title": "Delivery method"
        },
        {
            "field": "clicked",
            "title": "Clicked"
        },
        {
            "field": "converted",
            "title": "Converted"
        },
        {
            "field": "productName",
            "title": "Product name"
        }
    ],
    "mobilePush": [
        {
            "field": "campaignName",
            "title": "Campaign name"
        },
        {
            "field": "sentOn",
            "title": "Sent on"
        },
        {
            "field": "campaignType",
            "title": "Campaign type"
        },
        {
            "field": "deliveryMethod",
            "title": "Delivery method"
        },
        {
            "field": "clicked",
            "title": "Clicked"
        },
        {
            "field": "converted",
            "title": "Converted"
        },
        {
            "field": "productName",
            "title": "Product name"
        }
    ],
    "links": [
        {
            "field": "campaignName",
            "title": "Campaign name"
        },
        {
            "field": "sentOn",
            "title": "Sent on"
        },
        {
            "field": "campaignType",
            "title": "Campaign type"
        },
        {
            "field": "deliveryMethod",
            "title": "Delivery method"
        },
        {
            "field": "clicked",
            "title": "Clicked"
        },
        {
            "field": "converted",
            "title": "Converted"
        },
        {
            "field": "productName",
            "title": "Product name"
        }
    ],
    "sdkProfile": [
        {
            "field": "domainAppName",
            "title": "Domain / App name"
        },
        {
            "field": "date",
            "title": "Date"
        },
        {
            "field": "profileCount",
            "title": "Profile count"
        }
    ]
}
const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;
};
