import React, { useState } from "react";
import WhatsappCreation from "./WhatsappCreation";
import WhatsappList from "./WhatsappList";

const Whatsapp = () => {
  
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("add");
  const [clientWaSettingId, setClientWaSettingId] = useState("");

  const renderComponent = [
    <WhatsappList
      onChangeIndex={(index) => setIndex(index)}
      onChangeMode={(mode) => setMode(mode)}
      getClientWaSettingId={(clientWaSettingId) =>
        setClientWaSettingId(clientWaSettingId)
      }
    />,
    <WhatsappCreation
      onChangeIndex={(index) => setIndex(index)}
      mode={mode}
      clientWaSettingId={clientWaSettingId}
    />,
  ];

  return <>{renderComponent[index]}</>;
};
export default Whatsapp;
