import { RSSecondaryBtn, RSPrimaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSTextInputWithIcon } from "Components/RSInputs";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import React, { Component } from "react";
import {
  MAX_FIRST_NAME
} from 'Helper/Constants/inputLimit'

class CustomDateFilter extends React.Component {
  state = {
    operator: "eq",
    filterValue: filterOptions[0],
  };
  onChange = (event) => {
    const value = event;
    const { firstFilterProps } = this.props;
    this.setState({ filterValue: event.target.value })
    firstFilterProps.onChange({
      value,
      operator: "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };
  render() {
    const { firstFilterProps } = this.props;
    const value = firstFilterProps.value;
    const defaultValue = new Date();
    return (
      <div>
        <RSDropdownList
          data={filterOptions}
          value={this.state.filterValue}
          onChange={(value) => this.onChange(value)}
        />
        <div className="position-relative mt10">
          <div className="rspdate-picker sp-small">
            <DatePicker
              className="required"
              format={"MMM dd, yyyy"}
              placeholder={""}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomDateFilter;
const filterOptions = ["Starts with", "Is equal to", "Is not equal to"];
