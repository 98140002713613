import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import jsonData from "./CompanyList.json"
import actionList from "./ActionList.json";
import { SearchViewAdvanced } from "Components/SearchView/SearchView";
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import CampaignListModal from './Components/CampaignListModal';
import CampaignSummaryPanel from './Components/CampaignSummaryPanel';
import { withRouter, useHistory } from "react-router-dom";


const CompanyList = (props) => {
    const history = useHistory();
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [modalState, setModalState] = useState(false);
    const [summaryState, setsummaryState] = useState(false);
    const handleChange = () => {
        setModalState(!modalState);
    };
    const handleSummaryStateChange = () => {
        setsummaryState(!summaryState);
    };
    const CustomCell = props => {
        return <td>
            <div className="camp-icon">
                <ul className="camp-icon-pannel">
                    {actionList.map((item, index) =>
                        <li key={index}><i className={item.icon} title={item.title}
                            onClick={() => {
                                item.link === "campaignList"
                                    ? handleChange()
                                    : item.link === "campaignSummary"
                                        ? handleSummaryStateChange()
                                        : history.push({ pathname: item.link, state: { index: item.index } })
                            }} /></li>
                    )
                    }
                </ul>
            </div>
        </td>;
    }
    const HandleCompanyListRender = () => {
        const dropDownData = ['All', 'Personal', 'Business', 'Privilege']
        return <>
            <div className="d-flex justify-content-between mt15">
                <div></div>
                <div className="d-flex align-items-center">
                    <RSPDateRangePicker class="marginR15" />
                    <SearchViewAdvanced cls="" dropdownData={dropDownData} />
                </div>
            </div>
            <Grid
                data={jsonData.slice(skip, take + skip)}
                total={jsonData.length}
                scrollable={"none"}
                className="grid-pagenation-del"
                pageable={{
                    //type: 'input',
                    pageSizes: [5, 10, 15],
                    previousNext: true,
                }}
            >
                <GridColumn field="company" title="Company" />
                <GridColumn field="created" title="Created" />
                <GridColumn width={200} title="Action" cell={(props) => <CustomCell {...props} />} />
            </Grid>
            <CampaignListModal isOpen={modalState} onChangeIsOpen={() => handleChange()} />
            <CampaignSummaryPanel isOpen={summaryState} onChangeIsOpen={() => handleSummaryStateChange()} />
        </>
    }
    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <div className="header-title d-flex justify-content-between">
                    <h1>Company list</h1>
                </div>
                <hr className="blue" />
                <HandleCompanyListRender />
            </Container>
        </LayoutPageWrapper>
    );
}
export default withRouter(CompanyList);





