import { Pager } from "@progress/kendo-react-data-tools";
import { ListView } from "@progress/kendo-react-listview";
import React from "react";
import { useState } from "react";
import { Row } from "react-bootstrap";

const Pagination = ({templateCard,cards}) => {
  const pageSizes = [4, 8, 20];
  const initialDataState = {
    skip: 0,
    take: 4,
    //type: 'input',
    buttonCount: 4,
    info: false,
    pageSizes: true,
    previousNext: true,
    responsive: true,
  };
  const [pageState, setPageState] = useState(initialDataState);

  const handlePageChange = (event) => {
    const { skip, take } = event;
    setPageState({ ...pageState, skip: skip, take: take });
  };
  return (
    <>
      <Row  className="float-start" >
        <>
          <ListView
            className="gallery-grid-view"
            data={
              templateCard &&
              templateCard?.slice(
                pageState.skip,
                pageState.skip + pageState.take
              )
            }
            item={cards}
          />
          <div className="grid-pagenation-del d-flex">
            <Pager
              skip={pageState.skip}
              take={pageState.take}
              onPageChange={handlePageChange}
              total={templateCard && templateCard.length}
              type={pageState.type}
              info={pageState.info}
              buttonCount={pageState.buttonCount}
              pageSizes={pageState.pageSizes ? pageSizes : undefined}
              previousNext={pageState.previousNext}
            />
          </div>
        </>
      </Row>
    </>
  );
};

export default Pagination;
