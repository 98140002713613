
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import styled from "styled-components"

// RADIO BUTTON
export const RSRadio = ({ lbl, children, className, name, labelId, checked, cb, tabIndex }) => {
    return <RSRadioLblContainer className={`radioContainer ${className ? className : ''}`}>
        <RSRadioInput
            value={lbl}
            id={labelId}
            name={name}
            className="rs-radio"
            type="radio"
            checked={checked}
            tabIndex={tabIndex ? tabIndex : null}
            onChange={e => {

                cb(e)
            }}
        />
        <RSRadioLabel htmlFor={labelId}>{lbl}</RSRadioLabel>
        {children}
    </RSRadioLblContainer>
}

// CHECKBOX
export const RSCheckbox = ({ lbl, labelClass, checked, children, className, icon, cb }) => {
    return <RSCheckboxLblContainer className={`checkboxContainer ${className ? className : ''}`}>
        <RSCheckboxInput id={lbl} className="rs-checkbox" type="checkbox" checked={checked} onChange={e => cb(!checked)} />
        <RSCheckboxLabel className="checkmark" htmlFor={lbl}></RSCheckboxLabel>
        <div className={`${labelClass ? labelClass : ''}`}>
            {lbl}
            {children}
        </div>
    </RSCheckboxLblContainer>
}
export const RSCheckboxIcon2 = (props) => {
    const [checkedItems, setCheckedItems] = useState({});

    const onChecked = (e, checkbox) => {
        let checked = e.target.checked;
        let item = { ...checkbox };
        item["checked"] = checked;
        let items = { ...checkedItems, [e.target.name]: item };

        let mappedArry = Object.keys(items).map(key => items[key].checked && items[key])
        let filteredArry = mappedArry.filter(value => value !== false)
        setCheckedItems(items);
        props.onChecked && props.onChecked(filteredArry);
    };

    return <>
        {
            props.arry.map((checkbox, index) => {
                return <Col
                    id={index + ""}
                    md={{ span: checkbox.span || 3, offset: checkbox.offset || 0 }}
                    className={`${checkbox.colClass ? checkbox.colClass : ''}`}
                >
                    <RSCheckboxLblContainer id={index + ""} className={`checkboxContainer ${props.className ? props.className : ''}`}>
                        <input
                            className="rs-checkbox"
                            id={checkbox.label}
                            name={checkbox.name}
                            type="checkbox"
                            onChange={(e) => {
                                onChecked(e, checkbox)
                            }}
                        />
                        <RSCheckboxLabel className="checkmark" htmlFor={checkbox.label}></RSCheckboxLabel>
                        <i className={checkbox.icon}></i>
                        {checkbox.label}
                    </RSCheckboxLblContainer>
                </Col>
            })
        }
    </>
}
export const RSCheckboxIcon = ({ lbl, checked, children, className, icon, cb }) => {
    return <RSCheckboxLblContainer className={`checkboxContainer ${className ? className : ''}`}>
        <RSCheckboxInput id={lbl} className="rs-checkbox" type="checkbox" checked={checked} onChange={e => cb(!checked)} />
        <RSCheckboxLabel className="checkmark" htmlFor={lbl}></RSCheckboxLabel>
        {children}
        {lbl}
    </RSCheckboxLblContainer>
}

// INPUT
export const RSInput2 = props => {
    const {
        name, ph,
        val, type,
        cls, cls2,
        cb, min, max,
        pattern, errorMessage,
        ob, of, disabled,
        keyboardType,
        tabIndex,
        required, dialCode, phoneLength,
        validateCls
    } = props
    return <div className={`position-relative w-100 ${cls2 ? cls2 : ''}`}>
        {errorMessage && <div className={`validation-message ${validateCls ? validateCls : ''}`}>{errorMessage}</div>}
        <RSTextInput
            name={name}
            className={`slashText ${cls ? cls : ''}`}
            placeholder={ph}
            required={required ? required : null}
            value={val}
            type={type ? type : "text"}
            tabIndex={tabIndex ? tabIndex : null}
            min={min && min}
            // max={max && max} 
            maxLength={max ? max : null}
            disabled={disabled}
            onChange={e => {

                if (cb && keyboardType === undefined) {
                    cb(e.target.value)
                }
                else if (cb && keyboardType !== undefined && keyboardType === 'number') {
                    const re = /^[0-9\b.]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        cb(e.target.value)
                    }
                }
                else if (cb && keyboardType !== undefined && keyboardType === 'telePhoneNumber') {
                    const re = /^[+]*[0-9\ /\,/]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        let currentValue = e.target.value;
                        let maxMobile = false;
                        currentValue = currentValue.replaceAll(/ /g, '');
                        let getCountryCode = currentValue.split(`+${dialCode}`);
                        let numberWihoutComma = getCountryCode[1]?.replaceAll(',', '');
                        // let phoneValues = numberWihoutComma?.match(new RegExp('.{1,' + phoneLength + '}', 'g'));
                        let phoneValues = getCountryCode[1]?.split(/\s*,\s*/);
                        if (phoneValues?.length > 5) {
                            maxMobile = true;
                            phoneValues.pop();
                        }
                        let phoneValueFromProps = (getCountryCode && getCountryCode[1]) ? getCountryCode[1] : '';
                        let op = (phoneValues?.length > 1) ? phoneValueFromProps : phoneValueFromProps;
                        cb(`${op}`, maxMobile)
                        //cb(e.target.value)
                    }
                }
            }}

            onKeyDown={e => {
                if (cb && keyboardType !== undefined && keyboardType === 'telePhoneNumber' && e.key === ',') {
                    // e.preventDefault();
                }

            }}
            onBlur={e => ob && ob(e.target.value, e)}
            onFocus={e => of && of(true)}
            pattern={pattern && pattern}
            errorMessage={errorMessage}
        />
    </div>
}


/*const phoneNoRegx = (phone, format, length) => {
    let number = phone.split(countryCode);
console.log(number)
    phoneValues = number[1].match(new RegExp('.{1,' + length + '}', 'g'));
    console.log(phoneValues)
   // setMobileNo(`${number[0]} ${number[1]}`);
    //phoneNoRegx()
  }*/
export const RSTextInputWithIcon = props => {
    const {
        name, ph,
        val,
        img, icon, iconClass,
        altName, className,
        required,
        ob, of, cb,
        disabled,
        min, max,
        errorMessage,
        errorMessage_v2,
        cls2, imgClass,
        tabIndex,
        eye,
        eyeClass,
        eyeShow,
        success_msg,
        validClass
    } = props

    const [eyeVshow, setEyeVshow] = useState(false)
    const [type, setType] = useState(props.type)

    return <div className={`position-relative w-100 ${cls2 ? cls2 : ''}`}>
        {errorMessage && <div className={`validation-message ${validClass ? validClass : ''} ${success_msg ? success_msg : ''}`}>{errorMessage}</div>}
        <div className={`validation-message-v2 ${errorMessage_v2 ? 'error-show' : ''}`}>{errorMessage_v2}</div>
        {props.children && props.children}
        <InputIconWrapper>
            <RSTextInput
                autoComplete="off"
                name={name}
                placeholder={ph}
                required={required ?? false}
                className={`${className ? className : ''} pr40`}
                value={val}
                disabled={disabled}
                type={type ? type : "text"}
                tabIndex={tabIndex ? tabIndex : null}
                onBlur={e => ob && ob(e.target.value)}
                onFocus={e => of && of(true)}
                onChange={e => cb && cb(e.target.value)}
                // min={min && min}
                // max={max && max}
                // minLength={min ? min : null}
                maxLength={max ? max : 150}
            />
            {img && <InputImgIcon className={`${imgClass ? imgClass : ''}`} src={img} alt={altName} />}
            {eye && <InputIcon className={`${eye} icons-md  cp ${eyeVshow ? 'icon-sd-eye-medium' : 'icon-sd-eye-hide-medium'}`}
                onClick={() => {
                    setType(eyeVshow === false ? 'text' : 'password')
                    setEyeVshow(!eyeVshow)
                }}></InputIcon>}
            {/* {eye && <InputIcon className={`${eye} icons-md password-visible-icon cp hover-icon icon-sd-eye-hide-medium ${eyeVshow ? 'opacity-100 icon-sd-eye-medium top6' : 'opacity-0 icon-sd-eye-hide-medium top2'}`}
                onClick={() => {
                    setEyeVshow(!eyeVshow)
                }}></InputIcon>} */}
            {icon && <InputIcon className={`${icon} input-icon ${iconClass}`}></InputIcon>}
        </InputIconWrapper>
    </div>
}

const RSCheckboxLblContainer = styled.label` `
const RSCheckboxInput = styled.input``
const RSCheckboxLabel = styled.span``

const RSRadioLblContainer = styled.div` `
const RSRadioInput = styled.input``
const RSRadioLabel = styled.label``

const RSTextInput = styled.input`
    margin-bottom: 30px;
    margin-top: 0px;
    padding-top: 0;
    padding-right: 8px;
`
const InputIconWrapper = styled.div`
    position: relative;
 `
const InputIcon = styled.i`
    position: absolute;
    right: 7px;
    top: 4px;
 `
const InputImgIcon = styled.img`
    position: absolute;
    right: 7px;
    top: 7px;
    width: 23px;
 `