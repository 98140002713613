
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { RTabbar } from "Components/RChart/RTabbar";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";

import AllTemplate from "../Components/AllTemplate";
import MyTemplate from "../Components/Mytemplate";
import { LANDING_TABS } from "../../constants";


const LandingPage = () => {
  const [tabInd, setTabInd] = useState(0);
  return (
    <>
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <div className="d-flex justify-content-between mt15">
            <h1 className="header-title-margin">Landing page template gallery</h1>
          </div>

          <div className="tabs-filled-component-del">
            <RTabbar
              defaultSelectedItem={tabInd}
              defaultClass="tabDefault"
              dynamicTab="tabs-filled-component marginB0"
              activeClass="tabDefault active"
              tabData={LANDING_TABS}
              callBack={(item, index) => {
                setTabInd(index);
              }}
            />
          </div>
          {tabInd == 0 && <AllTemplate page={"landing"} />}
          {tabInd == 1 && <MyTemplate page={"landing"} />}
        </Container>
      </LayoutPageWrapper>
    </>
  );
};

export default withRouter(LandingPage);
