import React from "react";
import { RTabbar } from "Components/RChart/RTabbar";
import ApprovalCapmaign from "./ApprovalCampaign";
import ApprovalList from "./ApprovalList";
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'

class ApprovalStaus extends React.Component {

  state = {
    tabIndex: 0,
    mdcLevels: [],
    approvalData: this.props.data
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      if (this.props.campaignType === "M" && (this.state.mdcLevels.length === 0 || prevProps.data.channelName !== this.props.data.channelName)) {
        let tempMDCLevels = [];
        this.props.data?.contentDetailEmail?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailSMS?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailWA?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailQR?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.setState({ mdcLevels: tempMDCLevels, tabIndex: 0 })
      }
      this.setState({ approvalData: this.props.data })
    }
  }
  getApprovalData = () => {
    let approvalData = [];
    if (this.state.approvalData?.channelName?.toLowerCase() === "email") {
      approvalData = this.state.approvalData?.contentDetailEmail?.length > 0 && this.state.approvalData?.contentDetailEmail[this.state.tabIndex]?.rfa
    } else if (this.state.approvalData?.channelName?.toLowerCase() === "sms") {
      approvalData = this.state.approvalData?.contentDetailSMS?.length > 0 && this.state.approvalData?.contentDetailSMS[this.state.tabIndex]?.rfa
    }
    return approvalData
  }
  render() {
    function renderComponent(index) {
      var renderComponent;
      if (index === 0) {
        renderComponent = <ApprovalCapmaign />;
      } else if (index === 1) {
        renderComponent = <ApprovalList />;
      }
      return renderComponent;
    }
    return (
      <>
        {
          this.getApprovalData().length > 0 &&
          <div className="portlet-container">
            <div className="portlet-header d-flex align-items-center justify-content-between">
              <h4>Approval status</h4>
              {/* <div className="portlet-header-right">
              <RTabbar
                  defaultSelectedItem={this.state.tabIndex}
                  defaultClass="tabDefault"
                  dynamicTab="nav-tabs p-tabs-1"
                  activeClass="tabDefault active"
                  tabData={tabData}
                  callBack={(item, index) => {
                    this.setState({ tabIndex: index });
                  }}
                />
          </div> */}
              {this.state.mdcLevels.length !== 0 &&
                // <RTabbar
                //   defaultSelectedItem={this.state.tabIndex}
                //   defaultClass="tabDefault"
                //   dynamicTab="mini"
                //   dynamicTab={`tabs-filled-component marginB15 ${this.state.mdcLevels.length === 1 ? 'one-level' : ''}`}
                //   activeClass="tabDefault active"
                //   tabData={this.state.mdcLevels}
                //   callBack={(item, index) => {
                //     this.setState({ tabIndex: index });
                //   }}
                // />
                <BootstrapDropdown
                  data={this.state.mdcLevels || []}
                  fieldKey="text"
                  defaultItem={this.state.mdcLevels[this.state.tabIndex].text}
                  customAlignRight={true}
                  alignRight
                  // className={`marginR10 fs19-btn float-end`}
                  onSelect={(item, index) => {
                    this.setState({ tabIndex: index });
                  }}
                />
              }
            </div>
            <div className="portlet-body paddingX15">
              <div className="tab-content p-tabs-content-1">
                <div className="tab-pane active">
                  {/* {renderComponent(0, this.state.approvalData)} */}
                  <ApprovalCapmaign data={this.getApprovalData()} />
                </div>
              </div>
            </div>

          </div>
        }
      </>
    );
  }
}

export default ApprovalStaus;
const tabData = [
  { id: 1001, text: "Campaign" },
  { id: 1002, text: "List" },
];
