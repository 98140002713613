import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSInput2 } from 'Components/RSInputs';
import { getFileToBase64, isEmpty } from "Helper/Utils/Utils";
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { IMAGE_URL } from 'Helper/Constants/validationMessage';
import React, { Component } from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import UploadComponent from 'Container/AuthenticatedModule/Preference/Components/CommunicationSettings/UploadComponent';
import { UPLOAD_WHATSAPP_IMAGE } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';

class SelectPDFModal extends Component {
    static contextType = authContext;
    state = {
        isModalOpen: this.props.isOpen,
        fileName: "",
        fileByte: "",
        imageType: "",
        errorImageURL: null,
        errorImageType: null,
    }
    uploadImageToServer = (imageBytes, fileName, ImageType) => {
        let params = {
            "fileName": fileName,
            "fileByte": imageBytes,
            "fileType": ImageType
        }
        connectServer({
            path: UPLOAD_WHATSAPP_IMAGE,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.props.onCallBack(res.data.filePath);
                }
            },
            fail: err => {
            }
        })
    }
    render() {
        return (<Modal backdrop="static"
            keyboard={false} show={this.state.isModalOpen} centered size="lg" onHide={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
            }}>
            <Modal.Header closeButton>
                <h2>Select PDF</h2>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col md={2} >
                            <label className="label-row">Upload file</label>
                        </Col>
                        <Col md={6} >
                            <RSInput2
                                ph="Upload PDF"
                                required={true}
                                val={this.state.fileName}
                                cls="click-off"
                                cb={(text) => {
                                    let errMsg = text.length > 0 ? null : IMAGE_URL;
                                    this.setState({ errorImageURL: errMsg, imageURL: text });
                                }}
                                errorMessage={this.state.errorImageURL}
                            />
                        </Col>
                        <Col md={3}>
                            <div className="btn-file cp">
                                <RSPrimaryBtn >{'Upload'}</RSPrimaryBtn>
                                <input type="file" accept=".pdf" title="" onChange={(e) => {
                                    this.setState({ errorImageURL: null, fileName: "" });
                                    const imgFile = e.target.files[0]
                                    const imagePath = imgFile.name.split(".").at(-1)
                                    if (imagePath.toLowerCase() === 'pdf') {
                                        const fileSize = Math.round((imgFile.size / 1024));
                                        if (fileSize <= 5120) {

                                            getFileToBase64(imgFile, data => {
                                                this.setState({ fileName: imgFile.name, fileByte: data, imageType: imagePath })
                                                //this.props.onCallBack && this.props.onCallBack(data)
                                            },
                                                err => {
                                                })
                                        } else {
                                            this.setState({ errorImageURL: "Document size maximum 5 MB" });
                                        }
                                    } else {
                                        this.setState({ errorImageURL: "Invalid file type" });
                                    }
                                    e.target.value = null
                                }} className="btn-browse-edit cp" name="uploaded_file"></input>
                            </div>
                            <div className="input-outer-set">
                                <RSPTooltip text="Choose PDF file" position="top">
                                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                </RSPTooltip>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-container marginT20">
                    <RSSecondaryBtn
                        onClick={() => {
                            this.setState({ isModalOpen: !this.state.isModalOpen });
                            this.props.onChangeIsOpen(false);
                        }}
                    >
                        Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn
                        className={this.state.fileName !== "" ? "" : "click-off"}
                        onClick={() => {
                            if (isEmpty(this.state.imageURL)) {
                                this.setState({ errorImageURL: IMAGE_URL });
                            } else {
                                this.uploadImageToServer(this.state.fileByte, this.state.fileName, this.state.imageType)
                                this.setState({ isModalOpen: !this.state.isModalOpen });
                                this.props.onChangeIsOpen(false);
                            }

                        }}
                    >
                        Save
                    </RSPrimaryBtn>
                </div>
            </Modal.Footer>
        </Modal>);
    }
}

export default SelectPDFModal;