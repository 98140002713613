import { Container } from "react-bootstrap"
import poweredBy from 'Assets/Images/powered-by-new.svg'
import { RSPTooltip } from "Components/Tooltip";
import { getAccessToken, getBase, getSourceType, getSourceTypeForDocs } from "Helper/Utils/Utils";


const RSFooter = props => {
    const today = new Date();
    const year = today.getFullYear();
    return <footer>
        {!props.hideFooter &&
            <Container className="position-relative">
                <ul className="copyright">
                    <li>Copyright &copy; <span className="init-copyright">{year}</span></li>
                    <li onClick={() => window.open("https://marketingstar.io/privacy-policy.html", "_blank")}>Privacy policy</li>
                    <li onClick={() => window.open("https://marketingstar.io/terms-and-conditions.html", "_blank")}>Terms & Conditions</li>
                    {props.isAuth &&
                        <li onClick={() => window.open("https://doc.mkstr.io/login/authenticate-callback/3?jwt=" + getAccessToken() + "&base=" + getBase() + "&source=" + getSourceTypeForDocs(), "_blank")}>Help</li>
                    }
                </ul>
                <RSPTooltip position="top" text="Powered by Resulticks">
                    <div className="powered-by">

                        <i className="icon-sd-poweredby-medium icons-md"></i>
                        <img src={poweredBy} alt={poweredBy} />

                    </div>
                </RSPTooltip>
            </Container>
        }
        {/* <ul className="footer-borders-container"> <li></li> <li></li> <li></li> <li></li> <li></li> </ul> */}
    </footer >
}
export default RSFooter;