import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Modal, Tab, Card } from "react-bootstrap";
import {
  COPY_TEMPLATE,
  CREATE_EMAIL_CAMPAIGN,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  TEMPLATE_BUILDER_URL,
  UPDATE_EMAIL_CAMPAIGN,
} from "Helper/Constants/endPoint";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { connectServer } from "Helper/Network/networkHandler";
import { CopyModal, TEMPLATEDATA } from "./constants";
import { RTabbar, RTabbar1 } from "Components/RChart/RTabbar";
import { getServerConfig, getUserData } from "Helper/Utils/Utils";
import "./template.scss";
import { createEmailCampaign } from "../../CamapignWrapper";
import authContext from "Helper/StateHandler/auth-context";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";
import { RSPTooltip } from "Components/Tooltip";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { RSConfirmAlert } from "Components/RSConfirmAlert";

const Template = ({
  segList,
  validation,
  emailData,
  campaignId,
  isUpdate,
  edmChannelId,
  sendMailToAddress,
  createEmail,
  levelNumber = "",
  senderEmailAddress,
  activeDomain,
  activeReplyDomain,
  alternateEmailAddress,
  isReplyMail,
  editClickOff
}) => {
  const context = useContext(authContext);
  const [templateCard, setTemplateCard] = useState([]);
  const [copyTemplateModal, setCopyTemplateModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [tempErr, setTemErr] = useState("");
  const [tabInd, setTabInd] = useState(1);
  const [edmTempId, setEdmTempId] = useState(null);
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
  const [edmLinks, setEDMLinks] = useState([]);

  useEffect(() => {
    getTemplateCatagory();
    if (emailData?.content[0]?.body !== "") {
      getVerificationLinks(emailData?.content[0]?.body);
    }
  }, [tabInd]);

  const getTemplateCatagory = () => {
    let params = {
      EDMTemplateCategoryID: tabInd == 1 ? 7 : 6,
      channelid: 1,
      SplitType: "",
    };

    connectServer({
      path: GET_TEMPLATE,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        setDeleteTemplateModal(false);
        setCopyTemplateModal(false);
        setTotalCount(JSON.parse(res.data).length);
        setTemplateCard(JSON.parse(res.data));
      },
      fail: (err) => { },
    });
  };

  const handleDirect = (ele, res) => {
    let segmentationListID = segList[0];
    let params = {
      campaignId: campaignId ? campaignId : emailData?.campaignId,
      channelid: 1,
      templateId: ele === "edit" ? emailData?.content[0].templateId : ele.EDMTemplateID,
      segList:
        window.location.pathname !== "/communication/mdc-email-creation"
          ? segmentationListID?.segmentationListID
          : segmentationListID,
      name: ele === "edit" ? "Edit Campain" : "NewCampaign",
      SplitType: "",
      channelDetailId: res,
    };
    //2823
    if (window.location.pathname === "/communication/mdc-email-creation") {
      params.LevelNumber = levelNumber || ""
    }

    let channelDetails = JSON.stringify(params);
    let baseURL = getServerConfig();

    const sessionObject = JSON.parse(localStorage.getItem("session"));
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    let fromEnvi = window.location.origin.includes("app")
      ? "app"
      : window.location.origin.includes("sit")
        ? "sit"
        : "run";
    window.location.href = `${baseURL}${TEMPLATE_BUILDER_URL}?accessToken=${encodeURIComponent(
      accessToken
    )}&ChannelDetails=${encodeURIComponent(channelDetails)}&from=${fromEnvi}`;
  };

  const getVerificationLinks = (edmHtml) => {
    let htmlString = edmHtml;
    const htmlDoc = new DOMParser().parseFromString(htmlString, 'text/html');
    const links = Array.from(
      htmlDoc.querySelectorAll('[href]'),
      element => element.getAttribute('href')
    );
    let tempEdmLinks = []
    links.forEach((item) => {
      let isValidLink = item.includes("http") ? item : '';
      if (isValidLink !== '') {
        let tempObject = { "LinkURL": isValidLink, "SDKStatus": false, "LinkStatus": false }
        tempEdmLinks.push(tempObject)
      } else { }
    })
    setEDMLinks(tempEdmLinks)
    emailData.content[0].linkVerificationLogDetail = tempEdmLinks;
  }

  const templateValidation = (ele) => {
    let valid = validation();
    if (valid) {
      let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
      emailData.senderEmail = senderEmailAddress + "@" + activeDomain.domainName;
      if (isReplyMail) {
        emailData.replyEmail = alternateEmailAddress + "@" + activeReplyDomain.domainName;
      } else {
        emailData.replyEmail = "";
      }
      const url = new URL(window.location.href);
      const paramExists = url.searchParams.has("resSave");
      const urlResult = new URLSearchParams(window.location.search);
      let channelDetails = urlResult.get("ChannelDetails");
      const obj = JSON.parse(channelDetails);
      let paramsS = createEmailCampaign(
        "S",
        campaignId,
        emailData,
        databaseName,
        "",
        false
      );
      let emailDatas = { ...emailData };
      emailDatas.data = emailDatas;
      let paramsM =
        !!createEmail &&
        createEmail(
          "M",
          campaignId,
          emailDatas,
          databaseName,
          sendMailToAddress,
          false,
          obj,
          ""
        );
      connectServer({
        path: isUpdate
          ? UPDATE_EMAIL_CAMPAIGN
          : edmChannelId !== 0
            ? UPDATE_EMAIL_CAMPAIGN
            : CREATE_EMAIL_CAMPAIGN,
        params:
          window.location.pathname === "/communication/mdc-email-creation"
            ? paramsM
            : paramsS,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res?.status) {
            handleDirect(ele, res?.edmChannelId);
          } else {
          }
        },
        fail: (err) => { },
      });
    } else {
      window.scroll(0, 200);
    }
  };

  const handleSaveDelete = () => {
    if (edmTempId !== null) {
      let params = {
        channelId: 1,
        templateId: edmTempId,
        templateName: templateName,
      };
      connectServer({
        path: DELETE_TEMPLATE,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            getTemplateCatagory();
          }
        },
        fail: (err) => { },
      });
    }
  };

  const handleDelete = (ele) => {
    if (ele.TemplateName) {
      setTemplateName(ele.TemplateName);
      setEdmTempId(ele.EDMTemplateID);
      setDeleteTemplateModal(true);
    }
  };

  const handleCopy = (ele) => {
    if (ele.TemplateName) {
      setTemplateName(ele.TemplateName);
      setEdmTempId(ele.EDMTemplateID);
      setCopyTemplateModal(true);
    }
  };

  const handleSaveCopy = () => {
    if (tempErr == "") {
      let params = {
        channelId: 1,
        templateId: edmTempId,
        templateName: templateName,
      };
      connectServer({
        path: COPY_TEMPLATE,
        params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            getTemplateCatagory();
          } else {
            setTemErr("Template name already exists");
          }
        },
        fail: (err) => { },
      });
    } else {
      setTemErr("Enter template name");
    }
  };

  const handleCancel = () => {
    setCopyTemplateModal(false);
    setDeleteTemplateModal(false);
    setTemErr("");
  };
  const pageSizes = [4, 8, 20];
  const initialDataState = {
    skip: 0,
    take: 4,
    //type: 'input',
    buttonCount: 4,
    info: false,
    pageSizes: true,
    previousNext: true,
    responsive: true,
  };

  const [pageState, setPageState] = useState(initialDataState);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (event) => {
    const { skip, take } = event;
    setPageState({ ...pageState, skip: skip, take: take });
  };

  const cards = (props) => {
    let data = props.dataItem;
    return (
      <div className="box">
        <Card className="overlay-button position-relative overlay-card">
          <div className="overlay-card-inner">
            <img src={`${getServerConfig()}${data.ThumbnailPath}`} />
            <ul>
              <li>{data.TemplateName}</li>
              {tabInd == 1 ? (
                <li>
                  <RSPrimaryBtn
                    onClick={() => {
                      templateValidation(data);
                    }}
                  >
                    Select
                  </RSPrimaryBtn>
                </li>
              ) : (
                <li>
                  <div className="btn-container d-flex justify-content-evenly mt40">
                    <RSPrimaryBtn
                      className="hover_button"
                      onClick={() => {
                        templateValidation(data);
                      }}
                    >
                      Select
                    </RSPrimaryBtn>
                    <RSPrimaryBtn
                      className="hover_button"
                      onClick={() => {
                        handleCopy(data);
                      }}
                    >
                      Copy
                    </RSPrimaryBtn>
                    <RSPrimaryBtn
                      className="hover_button"
                      onClick={() => {
                        handleDelete(data);
                      }}
                    >
                      Delete
                    </RSPrimaryBtn>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      {emailData?.content[0]?.body !== "" ? (
        <div className="import-iframe-wrapper">
          <RSPTooltip position="top" text={"Edit"}>
            <i
              className={`icon-sd-form-edit-medium blue icons-md cp float-end ${editClickOff ? "click-off" : ""}`}
              onClick={() => {
                templateValidation("edit");
              }}
            ></i>
          </RSPTooltip>
          <div className={`import-iframe`}>
            <iframe
              id="edmFrame"
              title="uploadFrame"
              srcDoc={
                emailData?.content[0]?.body +
                "<style>body{pointer-events: none;}</style>"
              }
              style={{ width: "100%", minHeight: "100vh" }}
              onLoad={() => {
                let element = document.getElementById("edmFrame")
                let edmHeight = element.contentWindow.document.body.scrollHeight
                let edmWidth = element.contentWindow.document.body.scrollWidth
                let edmDimension = edmHeight * edmWidth
                emailData.content[0].edmDimension = edmDimension
              }}
            ></iframe>
          </div>
        </div>
      ) : (
        <>
          <RTabbar
            defaultSelectedItem={tabInd}
            defaultClass="tabDefault"
            dynamicTab="tabs-filled-component marginB0"
            activeClass="tabDefault active"
            tabData={TEMPLATEDATA}
            callBack={(item, index) => {
              setPageState(initialDataState);
              setTabInd(index);
            }}
          />
          <Row>
            {tabInd !== 1 ? (
              <>
                <ListView
                  className="gallery-grid-view temp-card"
                  data={
                    templateCard &&
                    templateCard?.slice(
                      pageState.skip,
                      pageState.skip + pageState.take
                    )
                  }
                  item={cards}
                />
                <div className="grid-pagenation-del d-flex">
                  <Pager
                    skip={pageState.skip}
                    take={pageState.take}
                    onPageChange={handlePageChange}
                    total={templateCard && templateCard.length}
                    type={pageState.type}
                    info={pageState.info}
                    buttonCount={pageState.buttonCount}
                    pageSizes={pageState.pageSizes ? pageSizes : undefined}
                    previousNext={pageState.previousNext}
                  />
                </div>
              </>
            ) : (
              <>
                <ListView
                  className="gallery-grid-view temp-card"
                  data={
                    templateCard &&
                    templateCard?.slice(
                      pageState.skip,
                      pageState.skip + pageState.take
                    )
                  }
                  item={cards}
                />
              </>
            )}
          </Row>
        </>
      )}

      <div>
        <Modal
          show={copyTemplateModal}
          centered
          backdrop="static"
          keyboard={false}
          size="md"
          onHide={() => {
            setCopyTemplateModal(false);
            setTemErr("");
          }}
        >
          <Modal.Header closeButton>
            <h2>Template name</h2>
          </Modal.Header>
          <Modal.Body>
            <Row className="split-ab-modal">
              <Col>
                <RSInput2
                  ph="Enter sender name"
                  required={true}
                  val={templateName}
                  max={100}
                  cb={(text) => {
                    var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                    let val = text.trim(" ");
                    if (val == "") {
                      setTemErr("Enter valid name");
                      setTemplateName(text);
                    } else if (!regex.test(val)) {
                      setTemErr("Special character not allowed");
                      setTemplateName(text);
                    } else {
                      setTemplateName(text);
                      setTemErr("");
                    }
                  }}
                  errorMessage={tempErr}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-container d-flex justify-content-end">
              <RSSecondaryBtn
                padding="0px"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </RSSecondaryBtn>

              <RSPrimaryBtn
                className={tempErr !== "" ? "click-off" : ""}
                onClick={() => {
                  handleSaveCopy();
                }}
              >
                Save
              </RSPrimaryBtn>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        {deleteTemplateModal &&
          <RSConfirmAlert
            title="Are you sure you want to delete?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                handleSaveDelete();
              }
              setDeleteTemplateModal(false);
            }}
          />}
      </div>
    </>
  );
};

export default Template;
