import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import * as overallData from './FontData'

export const Font = () => {
    const [data, setData] = useState(overallData)
    return (
        <>
            <h3>Icons</h3>
            <table style={{ width: '100%' }}>
                <tbody>
                    {
                        data.iconData.map((item, index) => {
                            const { fdontSize, fontType, description } = item
                            return <IconsRow fSize={fdontSize} fText={fontType} source={description} />
                        })
                    }
                </tbody>
            </table>

            <h3>Fonts</h3>
            <table style={{ width: '100%' }}>
                <tbody>
                    {
                        data.fontData.map((item, index) => {
                            const { fdontSize, fontType, description } = item
                            return <FontRow fSize={fdontSize} fText={fontType} source={description} />
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

const FontRow = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div style={{ fontSize: `${props.fSize}px`, marginBottom: 20 }}>Sample Text</div>
                </td>
                <td>
                    <div style={{ marginBottom: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CopyToClipboard text={`font-${props.fText}`}><span className='cursor-pointer labelcss marginL0 marginR10'>scss</span></CopyToClipboard>
                            <div style={{ fontSize: 20 }}>icon-{props.fText}:</div>
                            <div>&nbsp;{props.fSize}px</div>
                        </div>
                        <small>{props.source}</small>
                    </div>
                </td>
            </tr>
        </>
    )
}
const IconsRow = (props) => {
    return (
        <>
            {/* <i className="icon-audiencebase-mini icons-lg"></i>
        <i className="icon-audiencebase-mini icons-md"></i>
        <i className="icon-audiencebase-mini icons-sm"></i> */}
            <tr>
                <td>
                    <div style={{ fontSize: `${props.fSize}px`, marginBottom: 20 }}><i className={`icon-audiencebase-mini icons-${props.fText}`} /></div>
                </td>
                <td>
                    <div style={{ marginBottom: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CopyToClipboard text={`icons-${props.fText}`}><span className='cursor-pointer labelcss marginL0 marginR10'>scss</span></CopyToClipboard>
                            <div style={{ fontSize: 20 }}>icons-{props.fText}:</div>
                            <div>&nbsp;{props.fSize}px</div>
                        </div>
                        <small>{props.source}</small>
                    </div>
                </td>
            </tr>
        </>
    )
}