import React, { Component } from 'react';
import { RSDropdownList } from 'Components/RSDropdowns';
import { Container, Row, Col } from "react-bootstrap"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/custom/world.geo.json";
import { RSCheckbox } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import authContext from 'Helper/StateHandler/auth-context';
import { CREATE_CLIENT_ACCOUNT, GET_COUNTRY_DETAILS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import {
    SELECT_CURRENCY,
    SELECT_DATE_FORMAT,
    SELECT_LANGUAGE,
    SELECT_TIMEZONE,
    SELECT_TIME_FORMAT
} from 'Helper/Constants/validationMessage';
import { isEmpty } from 'Helper/Utils/Utils';
import { withRouter } from 'react-router';
import markerImg from 'Assets/Images/maker.png'
import { RSConfirmAlert } from 'Components/RSConfirmAlert';
import { keyContactInfoInitialState } from 'AppJsImport';

let markerIcon = markerImg;
require("highcharts/modules/map")(Highcharts);
highchartsMap(Highcharts); // Initialize the map module

class LocationSettings extends Component {

    static contextType = authContext
    state = {
        ...this.context.globalStateData.getKeyContactInfoData.locationSettings,
        isShowAlert: false,
        alertMessage: ""
    }

    componentDidMount = props => {
        window.scrollTo(0, 0);
        this.getCountryDetails();
    }

    getCountryDetails = () => {
        let params =
        {
            "CountryID": this.context.globalStateData.getKeyContactInfoData.brandDetails.countryDropdownItem.countryId
        }
        connectServer({
            path: GET_COUNTRY_DETAILS,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    let currentDetails = this.state.currencyDropdownData.filter(item => item.currencyId === res.data.currencyID)[0];
                    let languageDetails = this.state.languageDropdownData.filter(item => item.languageId === res.data.defaultLanguageId)[0];
                    let dateFormat = this.state.dateFormatDropdownData.filter(item => item.dateFormatId === res.data.defaultDateformatID)[0];
                    let timeFormat = this.state.timeFormatDropdownData.filter(item => item.timeFormatId === res.data.defaultTimeFormatID)[0];
                    let timeZone = this.state.timeZoneDropdownData.filter(item => item.timezoneId === res.data.defaultTimeZoneID)[0];
                    let countryLocation = {
                        country: res.data.country,
                        latitude: Math.round(res.data.latitude),
                        longitude: Math.round(res.data.longitude)
                    };
                    this.setState({
                        currencyDropdownItem: currentDetails,
                        languageDropdownItem: languageDetails,
                        dateFormatDropdownItem: dateFormat,
                        timeFormatDropdownItem: timeFormat,
                        timeZoneDropdownItem: timeZone,
                        dayLightSaving: res.data.isDayLight,
                        countryLocation: countryLocation
                    });
                }
            },
            fail: err => {
            }
        })
    }
    postDataToServer = finalParams => {
        connectServer({
            path: CREATE_CLIENT_ACCOUNT,
            params: finalParams,
            loading: this.context.globalStateData.setIsLoadingData,
            context: this.context,
            retry: 2,
            ok: res => {
                if (res.status) {
                    if (res.data.isAwsMarketPlace) {
                        this.props.history.push({
                            pathname: 'thanks',
                            state: { payment: true }
                        })
                    } else {
                        const { userId, clientId } = res.data;
                        // this.props.history.push({ pathname: `features`, state: { clientId: clientId || 0, userId: userId || 0, isForPaylater: false } })
                        this.props.history.push({ pathname: `thanks-signing-up`, state: { clientId: clientId || 0, userId: userId || 0, isForPaylater: false } })
                    }
                } else {
                    this.setState({ isShowAlert: true, alertMessage: res.message })
                }
            },
            fail: err => {
            }
        })
    }

    validateState = () => {

        const { currencyDropdownItem, languageDropdownItem, dateFormatDropdownItem, timeFormatDropdownItem, timeZoneDropdownItem, dayLightSaving } = this.state

        if (currencyDropdownItem === null || isEmpty(currencyDropdownItem.currencyName) || currencyDropdownItem.currencyName.toLowerCase().includes("currency")) {
            this.setState({ errMsgCurrency: SELECT_CURRENCY }); return
        }
        if (languageDropdownItem === null || isEmpty(languageDropdownItem.languageName) || languageDropdownItem.languageName.toLowerCase().includes("language")) {
            this.setState({ errMsgLanguage: SELECT_LANGUAGE }); return
        }
        if (dateFormatDropdownItem === null || isEmpty(dateFormatDropdownItem.dateFormat) || dateFormatDropdownItem.dateFormat.toLowerCase().includes("date")) {
            this.setState({ errMsgDateFormat: SELECT_DATE_FORMAT }); return
        }
        if (timeFormatDropdownItem === null || isEmpty(timeFormatDropdownItem.timeFormat) || timeFormatDropdownItem.timeFormat.toLowerCase().includes("time")) {
            this.setState({ errMsgTimeFormat: SELECT_TIME_FORMAT }); return
        }
        if (timeZoneDropdownItem === null || isEmpty(timeZoneDropdownItem.timezoneName) || timeZoneDropdownItem.timezoneName.toLowerCase().includes("time zone")) {
            this.setState({ errMsgTimeZone: SELECT_TIMEZONE }); return
        }

        const params = {
            userTypeId: this.context.globalStateData.userTypeId,
            currencyId: this.state.currencyDropdownItem.currencyId,
            languageId: this.state.languageDropdownItem.languageId,
            dateFormatId: this.state.dateFormatDropdownItem.dateFormatId,
            timeFormatId: this.state.timeFormatDropdownItem.timeFormatId,
            timeZoneId: this.state.timeZoneDropdownItem.timezoneId,
            isDayLight: dayLightSaving
        }

        this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ locationSettings: this.state } })

        const clientDetail = this.props.params["clientDetail"]
        const mergedClientDetail = { ...clientDetail, ...params }
        const copiedData = { clientDetail: mergedClientDetail }
        let finalParams = { ...this.props.params, ...copiedData }
        finalParams.userTypeId = 4
        finalParams.source = this.context.globalStateData.getKeyContactInfoData.contactInfo.source
        finalParams.partner = this.context.globalStateData.getKeyContactInfoData.contactInfo.partner
        finalParams.isVersium = this.context.globalStateData.getKeyContactInfoData.contactInfo.isFromVersium
        finalParams.planId = this.context.globalStateData.getKeyContactInfoData.contactInfo.planId
        finalParams.promocode = this.context.globalStateData.getKeyContactInfoData.contactInfo.promoCode
        // finalParams.channelPartner = this.context.globalStateData.getKeyContactInfoData.contactInfo.channelPartner !== "" ? JSON.parse(this.context.globalStateData.getKeyContactInfoData.contactInfo.channelPartner).cpName : "";
        finalParams.cpPayload = this.context.globalStateData.getKeyContactInfoData.contactInfo.channelPartner

        this.postDataToServer(finalParams)
    }

    render() {
        const { errMsgCurrency, errMsgLanguage, errMsgDateFormat, errMsgTimeFormat, errMsgTimeZone } = this.state

        return (
            <Container className='box-bottom-space'>
                <RSBrandLogo />
                <h1>Localization settings</h1>
                <div className="portlet-box-theme">
                    <Row>
                        {/* map */}
                        <Col md={6}>
                            <div className="portlet-map">
                                {/* <MapChart /> */}
                                <HighchartsReact
                                    constructorType={"mapChart"}
                                    highcharts={Highcharts}
                                    options={worldMapChartOption(this.state.countryLocation)}
                                />
                            </div>
                            {/* <figure className="d-flex justify-content-center mb0">
                                <img src={mapLocalization} alt="this is map localization" />
                            </figure> */}
                        </Col>

                        <Col md={6}>
                            <Row>
                                {/* Currency */}
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errMsgCurrency && <div className="validation-message">{errMsgCurrency}</div>}
                                        <RSDropdownList
                                            textField="currencyName"
                                            dataItemKey="currencyId"
                                            className='required'
                                            value={this.state.currencyDropdownItem}
                                            data={this.state.currencyDropdownData}
                                            defaultItem={{
                                                "currencyId": -1,
                                                "currencyName": "-- Select currency --",
                                            }}

                                            onChange={(e) => {
                                                let errMsg = e.target.value.currencyId !== -1 ? null : SELECT_CURRENCY
                                                this.setState({ currencyDropdownItem: e.target.value, errMsgCurrency: errMsg })
                                            }} />
                                    </div>
                                </Col>
                                {/* Language */}
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errMsgLanguage && <div className="validation-message">{errMsgLanguage}</div>}
                                        <RSDropdownList
                                            textField="languageName"
                                            dataItemKey="languageId"
                                            className='required'
                                            data={this.state.languageDropdownData}
                                            value={this.state.languageDropdownItem}
                                            defaultItem={{
                                                "languageId": -1,
                                                "languageName": "-- Select language --",
                                            }}
                                            onChange={(e) => {
                                                let errMsg = e.target.value.languageId !== -1 ? null : SELECT_LANGUAGE
                                                this.setState({ languageDropdownItem: e.target.value, errMsgLanguage: errMsg })
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Date */}
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errMsgDateFormat && <div className="validation-message">{errMsgDateFormat}</div>}
                                        <RSDropdownList

                                            textField="dateFormat"
                                            dataItemKey="dateFormatId"
                                            className='required'
                                            data={this.state.dateFormatDropdownData}
                                            value={this.state.dateFormatDropdownItem}
                                            defaultItem={{
                                                "dateFormatId": -1,
                                                "dateFormat": `-- Select date format --`,
                                            }}
                                            onChange={(e) => {
                                                let errMsg = e.target.value.dateFormatId !== -1 ? null : SELECT_DATE_FORMAT
                                                this.setState({ dateFormatDropdownItem: e.target.value, errMsgDateFormat: errMsg })
                                            }} />
                                    </div>
                                </Col>
                                {/* Time */}
                                <Col md={6}>
                                    <div className="position-relative">
                                        {errMsgTimeFormat && <div className="validation-message">{errMsgTimeFormat}</div>}
                                        <RSDropdownList

                                            textField="timeFormat"
                                            dataItemKey="timeFormatId"
                                            className='required'
                                            data={this.state.timeFormatDropdownData}
                                            value={this.state.timeFormatDropdownItem}
                                            defaultItem={{
                                                "timeFormatId": -1,
                                                "timeFormat": "-- Select time format --",
                                            }}
                                            onChange={(e) => {
                                                let errMsg = e.target.value.timeFormatId !== -1 ? null : SELECT_TIME_FORMAT
                                                this.setState({ timeFormatDropdownItem: e.target.value, errMsgTimeFormat: errMsg })
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Timezone */}
                                <Col className="position-relative">
                                    <div className="position-relative">
                                        {errMsgTimeZone && <div className="validation-message">{errMsgTimeZone}</div>}
                                        <RSDropdownList
                                            textField="timezoneName"
                                            dataItemKey="timezoneId"
                                            className='required'
                                            data={this.state.timeZoneDropdownData}
                                            value={this.state.timeZoneDropdownItem}
                                            defaultItem={{
                                                "timezoneId": -1,
                                                "timezoneName": `-- Select time zone --`,
                                            }}
                                            onChange={(e) => {
                                                let errMsg = e.target.value.timezoneId !== -1 ? null : SELECT_TIMEZONE
                                                this.setState({ timeZoneDropdownItem: e.target.value, errMsgTimeZone: errMsg })
                                            }} />
                                    </div>
                                    <RSCheckbox cb={status => this.setState({ dayLightSaving: status })} checked={this.state.dayLightSaving} className="input-desc marginX11 click-off">Daylight saving</RSCheckbox>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="btn-container d-flex justify-content-end">
                    <RSSecondaryBtn onClick={() => {
                        this.context.globalStateData.setKeyContactInfoData({ ...this.context.globalStateData.getKeyContactInfoData, ...{ locationSettings: this.state } })
                        this.props.updateDataAndIndex(this.props.i - 1, null)
                    }}>Back</RSSecondaryBtn>
                    <RSPrimaryBtn onClick={this.validateState}>Submit</RSPrimaryBtn>
                </div>
                {this.state.isShowAlert &&
                    <RSConfirmAlert
                        title={this.state.alertMessage}
                        primaryButtonText="OK"
                        buttonClicked={(value) => {
                            this.props.history.push(this.context.globalStateData.getKeyContactInfoData.contactInfo.lastLoginPath)
                            this.context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState)
                            this.setState({ isShowAlert: false })
                        }} />
                }
            </Container>
        );
    }
}

export default withRouter(LocationSettings);

// World map
const worldMapChartOption = data => {
    return {
        chart: {
            // type: 'map',
            map: "custom/world",
            proj4,
            // map: "countries/in/in-all"
            height: 290
        },
        title: {
            text: " "
        },
        credits: {
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        mapNavigation: {
            enabled: false
        },
        tooltip: {
            enabled: false,
            headerFormat: "",
            pointFormat: "<span>{point.name}</span><br>Lat: {point.lat}, Lon: {point.lon}",
            useHTML: true,
            shared: true,
            backgroundColor: '#5f5f5f',
            borderWidth: 0,
            shadow: false,
            style: {
                color: '#fefefe',
                cursor: 'default',
                fontSize: '13px',
                whiteSpace: 'nowrap'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {

            mappoint: {
                marker: {
                    symbol: `url(${markerIcon})`
                },
                dataLabels: {
                    align: 'left',
                    x: 5,
                    verticalAlign: 'middle'
                }
            }
        },
        series: [
            {
                name: "Basemap",
                mapData: mapDataIE,
                borderColor: "#ffffff",
                nullColor: "#749bf0",
                showInLegend: false
            },
            {
                name: "Separators",
                type: "mapline",
                nullColor: "#707070",
                showInLegend: false,
                enableMouseTracking: false
            },
            {
                type: 'mappoint',
                name: 'Cities',

                data: [
                    {
                        name: data?.country,
                        lat: data?.latitude,
                        lon: data?.longitude
                    }
                ]
            }
        ]
    }
}