import React from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { RSPrimaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { Modal } from "react-bootstrap";

class FrequencyModal extends React.Component {
  state = {
    isModalOpen: this.props.isOpen,
    caplistChecked: false,
  };
  render() {
    return (
      <Modal backdrop="static"
        keyboard={false} show={this.state.isModalOpen} centered>
        <Modal.Header>
          <div>
            <label>Frequency cap list</label>
            <div>
              <Switch
                onChange={() =>
                  this.setState({ caplistChecked: !this.state.caplistChecked })
                }
                checked={this.state.caplistChecked}
              />
            </div>
            <div
              className="back-text"
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
              }}
            >
              <i className="icon-close-small icon-mini blue"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.caplistChecked === true ? (
            <RSDropdownList data={capList} defaultItem={"-- Select --"} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {this.state.caplistChecked === true ? (
            <RSPrimaryBtn
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
                this.props.onChangeIsOpen(false);
              }}
            >
              Ok
            </RSPrimaryBtn>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FrequencyModal;
const capList = ["Corporate customer", "Cash back", "NRI customer"];
