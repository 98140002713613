import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

const SubjectLineModal = props => {

    const [isShow, setIsShow] = useState(props.isOpen);

    return (
        <Modal backdrop="static"
            keyboard={false} show={isShow} centered size='lg' onHide={() => {
                setIsShow(!isShow);
                props.onChangeIsOpen(false);
            }}>
            <Modal.Header closeButton>
                <h2>Subject line analysis</h2>
            </Modal.Header>
            <Modal.Body>
                <h4>Earn high interest on fixed deposits</h4>
                <Col md={12}>
                    <Row>
                        <Col md={6} className="d-flex justify-content-between">
                            <div className="line-analysis text-center">
                                <span>Projectd CTR</span>
                                <div className="line-analysis">
                                    <CircularProgressbarWithChildren
                                        value={15}
                                        styles={buildStyles({

                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: 'butt',

                                            // Text size
                                            textSize: '30px',
                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Colors
                                            pathColor: '#9ccc06',
                                            trailColor: '#eeeeee',
                                            backgroundColor: '#0b7cc1',
                                        })}
                                    >
                                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                        <div className="circular-text">
                                            <b>15<small>%</small></b>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="text-center">
                            <span>Subject line length</span>
                            <div className="line-analysis">
                                <CircularProgressbarWithChildren
                                    value={85}
                                    styles={buildStyles({

                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',

                                        // Text size
                                        textSize: '16px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,

                                        // Colors
                                        pathColor: '#9ccc06',
                                        trailColor: '#eeeeee',
                                        backgroundColor: '#0b7cc1',
                                    })}
                                >
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                    <div className="circular-text">
                                        <b>85<small>%</small></b>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <h3>Top 3 subject lines</h3>
                        </Col>
                        <Col md={3}>
                            <h3>CTR %</h3>
                        </Col>
                        <Col md={9}>
                            "Exclusive
                            <b>dining privileges</b> on using Vision Credit Cards"
                        </Col>
                        <Col md={3}>
                            <h3>22%</h3>
                        </Col>
                        <Col md={9}>
                            "Special
                            <b>discounts</b> at gourmet restaurants near you"
                        </Col>
                        <Col md={3}>
                            <h3>18%</h3>
                        </Col>
                        <Col md={9}>
                            "Get personal loans at
                            <b>10% off</b> approved in 2 mins"
                        </Col>
                        <Col md={3}>
                            <h3>17%</h3>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
        </Modal>
    )

}
export default SubjectLineModal;