import React, { Component } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2 } from 'Components/RSInputs';

class SplitSizeModal extends Component {
    state = {
        isShow: this.props.isOpen,
        splitSize: "2000",
        value: '10'
    }
    onInput() {
        var input = document.getElementById("typeinp");
        var currentVal = input.value;
        if (currentVal <= 80) {
            this.setState({
                value: currentVal
            })
        }
    }
    render() {
        return (
            <div className='split-ab-progress-wrapper'>
                <div className='split-ab-progressbar'>
                    <ul>
                        <li>Split size</li>
                        <li>
                            <RSInput2
                                ph="Enter value"
                                cls2="mx15"
                                val={this.state.splitSize}
                                cb={(text) => {
                                    this.setState({ splitSize: text });
                                }} />
                        </li>
                        <li>/ 6553</li>
                    </ul>
                    <div className="split-box space-top">
                        <div className="dragSlider">
                            <div className="dragCont br1" aria-disabled="false">
                                <div className="inside">
                                    <div className="split-ab-wrapper-container">
                                        <div className="split-ab-wrapper" style={{ 'width': this.state.value + '%' }}>
                                            <div className="split-ab-list split-a">
                                                <div className='split-sep'>
                                                    <span className="listname">A</span>
                                                    <h5>{Math.floor(this.state.value / 2)}%</h5>
                                                </div>
                                            </div>
                                            <div className="split-ab-list split-b">
                                                <div className='split-sep'>
                                                    <span className="listname">B</span>
                                                    <h5>{Math.floor(this.state.value / 2)}%</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="split-ab-wrapper2 pt10" style={{ 'width': 100 - this.state.value + '%' }}>
                                            <span className="grey-dark split-text">% viewing your communication</span>
                                            {100 - this.state.value > 20 ? <h5><span className="groupOverAll grey">{100 - this.state.value}%</span></h5> : <h5><span className="groupOverAll grey">20%</span></h5>}
                                        </div>
                                    </div>
                                </div>
                                <input id="typeinp" type="range" min="0" max="100" step="2" defaultValue="15" onInput={this.onInput.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btn-container d-flex justify-content-end'>
                    <RSSecondaryBtn
                        onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn
                        onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Save</RSPrimaryBtn>
                </div>

            </div>
        );
    }
}

export default SplitSizeModal;