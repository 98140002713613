import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid'
import { process } from "@progress/kendo-data-query";
import authContext from 'Helper/StateHandler/auth-context'
import { RSPTooltip } from 'Components/Tooltip';
import { connectServer } from 'Helper/Network/networkHandler';
import { dateFormat, dateFormatWithMomentForConsumptions } from 'Helper/Utils/Utils'
import SkeletonTable from 'Components/Skeleton/SkeletonTable'
import { GET_CHANNEL_CONSUMPTION_DETAILS } from 'Helper/Constants/endPoint';

const initialDataState = {
    skip: 0,
    take: 5
}

class WhatsApp extends Component {

    static contextType = authContext

    state = {
        result: [],
        dataState: initialDataState,
        consumptionData: [],
        listError: false,
        totalCount: 0,
    }

    componentDidMount = props => {
        this.setState({ dataState: initialDataState })
        let params = {
            "ChannelID": 21,
            "year": this.props.selectedYear,
            "month": this.props.selectedMonth,
            "recordLimit": 5,
            "pageNumber": 1,
            "searchCampaignName": this.props.searchCampaignName
        }
        this.getDataFromServer(params);
    }

    componentDidUpdate = prevProps => {
        if (prevProps !== this.props) {
            this.setState({ dataState: initialDataState })
            let params = {
                "ChannelID": 21,
                "year": this.props.selectedYear,
                "month": this.props.selectedMonth,
                "recordLimit": 5,
                "pageNumber": 1,
                "searchCampaignName": this.props.searchCampaignName
            }
            this.getDataFromServer(params);
        }
    }
    getDataFromServer = params => {
        this.setState({ listError: false, result: null });
        connectServer({
            path: GET_CHANNEL_CONSUMPTION_DETAILS,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ totalCount: res.data.totalRows });
                    this.props.getLastUpdatedDate(!!res.data.latestDate ? dateFormat(res.data.latestDate) : "");
                    let newTemp = [...res?.data?.activityList]
                    const tempDataState = {
                        skip: 0,
                        take: this.state.dataState?.take
                    };
                    let some = process(newTemp, tempDataState)
                    if (res.data.activityList.length > 0) {
                        this.setState({ result: some, consumptionData: res.data.activityList });
                    } else {
                        this.setState({ listError: true });
                    }
                } else {
                    this.setState({ listError: true });
                    // this.props.getLastUpdatedDate(dateFormat(new Date()));
                }
            },
            fail: err => {
                this.setState({ listError: true });
            }
        })
    }
    dataStateChange = (event) => {
        const { skip, take } = event.dataState;
        const currentPage = Math.floor(skip / take + 1)
        this.setState({ consumptionData: process(this.state.consumptionData, event.dataState), dataState: event.dataState })
        let params = {
            "ChannelID": 21,
            "year": this.props.selectedYear,
            "month": this.props.selectedMonth,
            "recordLimit": take,
            "pageNumber": currentPage,
            "searchCampaignName": this.props.searchCampaignName
        }
        this.getDataFromServer(params);
    }

    NameComponent = (props) => {
        // <NameCell {...props} />
        return (<td>
            <div className="d-flex justify-content-between">
                <RSPTooltip text={props.dataItem.campaignName} position="top">
                    <span className="marginB0 cursor-pointer text-underline-h text-wrap-1" onClick={() => {
                        this.props.history.push({
                            pathname: `/analytics/detail-analytics`,
                            state: {
                                campaignId: props.dataItem.campaignID,
                                channelId: 21,
                                campaignName: props.dataItem.campaignName,
                                campaignGuid: props.dataItem.campaignGuid,
                                startDate: props.dataItem.startDate,
                                endDate: props.dataItem.endDate,
                                isEmailSplitAb: props.dataItem.isEmailSplitAB,
                                isSmsSplitAb: props.dataItem.isSMSSplitAB
                            }
                        })
                    }}>{`${props.dataItem.campaignName}`}</span>
                </RSPTooltip>
            </div>
        </td>)
    }

    render() {
        return <>
            {
                this.state.result !== null ?
                    <Grid
                        data={this.state.result}
                        {...this.state.dataState}
                        total={this.state.totalCount}
                        // scrollable={"none"}
                        className='grid-pagenation-del grid-replaceable grid-vertical-noscroll'
                        onDataStateChange={this.dataStateChange}
                        pageable={{
                            //type: 'input',
                            pageSizes: true,
                            previousNext: true
                        }}
                    >
                        <GridColumn columnMenu={ColumnMenu} field='campaignName' title='Communication name' width="250px" cell={this.NameComponent} />
                        <GridColumn columnMenu={ColumnMenu} field='scheduledate' cell={DateCell} title='Sent on' width="250px" />
                        <GridColumn columnMenu={ColumnMenu} field='campaignType' title='Communication type' width="250px" />
                        <GridColumn columnMenu={ColumnMenu} field='deliveryMethod' title='Delivery method' width="250px" />
                        <GridColumn columnMenu={ColumnMenu} field='productName' title='Product type' width="250px" />
                        <GridColumn columnMenu={ColumnMenu} field='levelNumber' title='MDC level' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='sent' cell={NumberCell} className="text-end" title='Sent' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='delivered' cell={NumberCell} className="text-end" title='Delivered' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='opened' cell={NumberCell} className="text-end" title='Read' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='clicked' cell={NumberCell} className="text-end" title='Clicked' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='converted' cell={NumberCell} className="text-end" title='Conversion' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='blocked' cell={NumberCell} className="text-end" title='Blocked' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='reported' cell={NumberCell} className="text-end" title='Reported' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='undelivered' cell={NumberCell} className="text-end" title='Undelivered' width="150px" />
                        <GridColumn columnMenu={ColumnMenu} field='costcode' cell={NumberCell} className="text-end" title='Cost $' width="150px" />
                    </Grid> :
                    <SkeletonTable count={5} isError={this.state.listError} />
            }
        </>
    }
}


export default withRouter(WhatsApp)

const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;
};
const DateCell = (props) => {
    return (
        <td>
            {dateFormatWithMomentForConsumptions(props.dataItem[props.field])}
        </td>
    )
}
const NumberCell = (props) => {
    return (
        <td>
            {props.dataItem[props.field]}
        </td>
    )
}