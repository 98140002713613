import React, { useEffect } from "react";
import * as d3 from "d3";
import ProductData from "./ProductData";
const WIDTH = 510;
const Height = 260;
const RADIUS = Math.min(WIDTH, Height) / 2;
const b = {
  w: 75,
  h: 30,
  s: 3,
  t: 10,
};

// Mapping of step names to colors.
const colors = {
  'Awareness': '#5f77ac',
  'Greetings': '#31ccfd',
  'New product launch': '#fa5c5c',
  'Promotion': '#4885ed',
  'Sale': '#bc2f20',
  'Events': '#99cc03',
  'Los Angeles': '#31ccfd',
  'Chicago': '#5f77ac',
  'iPad': '#31ccfd',
  'Known': '#fa5c5c',
  'Unknown': '#4885ed',
  'California': '#bc2f20',
  'Mobile': '#99cc03',
  'Tablet': '#31ccfd',
  'Illinois': '#5f77ac',
  'Browser': '#31ccfd',
};

// Mapping of step names to colors.
// custom edit
// var colors = {};

const colorsData = [
  { key: 'Awareness', value: '#5f77ac'},
  { key: 'Greetings', value: '#31ccfd'},
  { key: 'New product launch', value: '#fa5c5c'},
  { key: 'Promotion', value: '#4885ed'},
  { key: 'Sale', value: '#bc2f20'},
  { key: 'Events', value: '#99cc03'},
  { key: 'Los Angeles', value: '#31ccfd'},
  { key: 'Chicago', value: '#5f77ac'},
  { key: 'iPad', value: '#31ccfd'},
  { key: 'Known', value: '#fa5c5c'},
  { key: 'Unknown', value: '#4885ed'},
  { key: 'California', value: '#bc2f20'},
  { key: 'Mobile', value: '#99cc03'},
  { key: 'Tablet', value: '#31ccfd'},
  { key: 'Illinois', value: '#5f77ac'},
  { key: 'Browser', value: '#31ccfd'},
];

// Total size of all segments; we set this later, after loading the data.
var totalSize = 0;

function initializeBreadcrumbTrail() {
  // Add the svg area.
  var trail = d3
    .select("#sequence")
    .append("div:ul")
    .attr("width", WIDTH)
    .attr("height", 50)
    .attr("id", "trail");
  // Add the label at the end, for the percentage.
  trail.append("ul:div").attr("id", "endlabel").style("fill", "#000");
}

function drawLegend() {
  // Dimensions of legend item: width, height, spacing, radius of rounded rect.
  var li = {
    w: 75,
    h: 30,
    s: 3,
    r: 3,
  };

  var legend = d3
    .select("#legend")
    .append("svg:svg")
    .attr("width", li.w)
    // .attr("height", d3.keys(colors).length * (li.h + li.s));
    .attr("height", colorsData.length * (li.h + li.s));

  var g = legend
    .selectAll("g")
    // .data(d3.entries(colors))
    .data(colorsData)
    .enter()
    .append("svg:g")
    .attr("transform", function (d, i) {
      return "translate(0," + i * (li.h + li.s) + ")";
    });

  g.append("svg:rect")
    .attr("rx", li.r)
    .attr("ry", li.r)
    .attr("width", li.w)
    .attr("height", li.h)
    .style("fill", function (d) {
      return d.value;
    });

  g.append("svg:text")
    .attr("x", li.w / 2)
    .attr("y", li.h / 2)
    .attr("dy", "0.35em")
    .attr("text-anchor", "middle")
    .text(function (d) {
      return d.key;
    });
}

function toggleLegend() {
  var legend = d3.select("#legend");
  if (legend.style("visibility") === "hidden") {
    legend.style("visibility", null);
  } else {
    legend.style("visibility", "hidden");
  }
}

export const SequenceChart = () => {
  const svgRef = React.useRef(null);
  // const [viewBox, setViewBox] = React.useState("0,0,0,0");
  const partition = (data) =>
    d3.partition().size([2 * Math.PI, RADIUS * RADIUS])(
      d3
        .hierarchy(data)
        .sum((d) => d.size)
        .sort((a, b) => b.value - a.value)
    );
  const color = d3
    .scaleOrdinal()
    .domain([
      'Awareness',
      'Greetings',
      'New product launch',
      'Promotion',
      'Sale',
      'Events',
      'Los Angeles',
      'Chicago',
      'iPad',
      'Known',
      'Unknown',
      'California',
      'Mobile',
      'Tablet',
      'Illinois',
      'Browser',
    ])
    .range([
      '#5f77ac',
      '#31ccfd',
      '#fa5c5c',
      '#4885ed',
      '#bc2f20',
      '#99cc03',
      '#31ccfd',
      '#5f77ac',
      '#31ccfd',
      '#fa5c5c',
      '#4885ed',
      '#bc2f20',
      '#99cc03',
      '#31ccfd',
      '#5f77ac',
      '#31ccfd',
    ]);

  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle(1 / RADIUS)
    .padRadius(RADIUS)
    .innerRadius((d) => Math.sqrt(d.y0))
    .outerRadius((d) => Math.sqrt(d.y1) - 1);

  const mousearc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .innerRadius((d) => Math.sqrt(d.y0))
    .outerRadius(RADIUS);
  // Update the breadcrumb trail to show the current sequence and percentage.
  function updateBreadcrumbs(nodeArray, percentageString) {
    // Data join; key function combines name and depth (= position in sequence).
    var trail = d3
      .select("#trail")
      .selectAll("li")
      .data(nodeArray, function (d) {
        return d.data.name + d.depth;
      });

    // Remove exiting nodes.
    trail.exit().remove();

    // Add breadcrumb and label for entering nodes.
    var entering = trail.enter().append("ul:li").style("background-color", function (d) {
      return colors[d.data.name];
    });

    entering
    .append("ul:span")
    .attr("points", breadcrumbPoints)
    .style("fill", function (d) {
      return colors[d.data.name];
    });

    entering
      .append("ul:div")
      .attr("x", (b.w + b.t) / 2)
      .attr("y", b.h / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.data.name;
      });

    // Merge enter and update selections; set position for all nodes.
    entering.merge(trail).attr("transform", function (d, i) {
      return "translate(" + i * (b.w + b.s) + ", 0)";
    });

    // Now move and update the percentage at the end.
    d3.select("#trail")
      .select("#endlabel")
      .attr("x", (nodeArray.length + 0.5) * (b.w + b.s))
      .attr("y", b.h / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(percentageString);

    // Make the breadcrumb trail visible, if it's hidden.
    d3.select("#trail").style("visibility", "");
  }
  function breadcrumbPoints(d, i) {
    const tipWidth = 10;
    const points = [];
    points.push("0,0");
    points.push(`${b.w},0`);
    points.push(`${b.w + tipWidth},${b.h / 2}`);
    points.push(`${b.w},${b.h}`);
    points.push(`0,${b.h}`);
    if (i > 0) {
      // Leftmost breadcrumb; don't include 6th vertex.
      points.push(`${tipWidth},${b.h / 2}`);
    }
    return points.join(" ");
  }
  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }

    const { x, y, width, height } = svgRef.current.getBBox();

    return [x, y, width, height].toString();
  };
  useEffect(() => {
    const root = partition(ProductData);
    // Basic setup of page elements.
    initializeBreadcrumbTrail();
    drawLegend();
    d3.select("#togglelegend").on("click", toggleLegend);
    const svg = d3.select(svgRef.current);
    // Make this into a view, so that the currently hovered sequence is available to the breadcrumb
    const element = svg.node();
    element.value = { sequence: [], percentage: 0.0 };

    const label = svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("fill", "#888")
      // .style("visibility", "hidden");
      .style("display", "none");

    label
      .append("tspan")
      .attr("class", "percentage")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", "-0.1em")
      .attr("font-size", "3em")
      .text("");

    label
      .append("tspan")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", "1.5em")
      .text("of visits begin with this sequence");

    svg
      .attr("viewBox", `${-RADIUS} ${-RADIUS} ${WIDTH} ${WIDTH}`)
      .style("max-width", `${WIDTH}px`)
      .style("font", "12px sans-serif");
    // For efficiency, filter nodes to keep only those large enough to see.
    var nodes = root.descendants().filter(function (d) {
      return d.x1 - d.x0 > 0.005; // 0.005 radians = 0.29 degrees
    });
    const path = svg
      .append("g")
      .selectAll("path")
      .data(nodes)
      .join("path")
      .attr("fill", (d) => color(d.data.name))
      .attr("d", arc);

    svg
      .append("g")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseleave", () => {
        // path.attr("fill-opacity", 1);
        // label.style("visibility", "hidden");
        // // Update the value of this view
        // element.value = { sequence: [], percentage: 0.0 };
        // element.dispatchEvent(new CustomEvent("input"));

        // Hide the breadcrumb trail
        d3.select("#trail").style("visibility", "hidden");

        // Deactivate all segments during transition.
        d3.selectAll("path").on("mouseover", null);

        // Transition each segment to full opacity and then reactivate it.
        d3.selectAll("path")
          .transition()
          .duration(1000)
          .style("opacity", 1)
          .on("end", function () {
            // d3.select(this).on("mouseover", mouseover);
          });

        d3.select("#explanation").style("visibility", "hidden");
        d3.select("#percentage-list").style("visibility", "hidden");
      })
      .selectAll("path")
      .data(nodes)
      .join("path")
      .attr("d", mousearc)
      .on("mouseenter", (event, d) => {
        // Get the ancestors of the current segment, minus the root
        const sequence = d.ancestors().reverse().slice(1);
        // Highlight the ancestors
        path.attr("fill-opacity", (node) =>
          sequence.indexOf(node) >= 0 ? 1.0 : 1.0 // 0.3
        );
        const percentage = ((100 * d.value) / root.value).toPrecision(3);
        label
          .style("visibility", null)
          .select(".percentage")
          .text(percentage + "%");
        // Update the value of this view with the currently hovered sequence and percentage
        element.value = { sequence, percentage };
        element.dispatchEvent(new CustomEvent("input"));

        var forLegendPercentage = ((100 * d.value) / totalSize).toPrecision(3);
        var percentageString = forLegendPercentage + "%";
        if (forLegendPercentage < 0.1) {
          percentageString = "< 0.1%";
        }

        d3.select("#percentage").text(percentageString);
        d3.select("#percentage2").text(percentageString);
        d3.select("#percentage-list").text(percentageString);

        d3.select("#explanation").style("visibility", null);
        d3.select("#explanation2").style("visibility", null);
        d3.select("#percentage-list").style("visibility", null);

        var sequenceArray = d.ancestors().reverse();
        sequenceArray.shift(); // remove root node from the array
        updateBreadcrumbs(sequenceArray, percentageString);

        // Fade all the segments.
        d3.selectAll("#sunburst-chartview path").style("opacity", 0.3);

        // Then highlight only those that are an ancestor of the current segment.
        svg
          .selectAll("path")
          .filter(function (node) {
            return sequenceArray.indexOf(node) >= 0;
          })
          .style("opacity", 1);
      });
    // Get total size of the tree = value of root node from partition.
    totalSize = path.datum().value;
    svg.attr("viewBox", getAutoBox);
    // return element;
  }, []);

  
  return (
    <div>

      <div id="sunburstChartContainer">
        <div className="explanation-view" id="explanation" style={{ visibility: "hidden" }}>
          <span id="percentage"></span>
          <br />
          of visits begin with this sequence of pages
        </div>
        <div className="explanation-view" id="explanation2">
          <span id="percentage2">100%</span>
          <br />
          of visits begin with this sequence of pages
        </div>
      </div>

      <div id="sidebar" className="d-none">
        <input type="checkbox" id="togglelegend" />
        Legend
        <br />
        <div id="legend" style={{ visibility: "hidden" }}></div>
      </div>

      <div id="sunburst-chartview">
        <svg width={WIDTH} height={Height} ref={svgRef} />
      </div>

      <div id="main" className="sunburst-legend">
        <div id="sequence"></div>
        <span id="percentage-list" style={{ visibility: "hidden" }}>100%</span>
      </div>

    </div>
  );
};

export default SequenceChart;
