import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { RTabbar } from "Components/RChart/RTabbar";
import Subscribe from "./Subscribe";
import UnSubscribe from "./Unsubscribe";

const SubscribeHome = (props) => {

  const [index, setIndex] = useState(
    props.history.location.state === undefined ? 0
      : props.history.location.state.updateIndex
  );

  const renderComponent = (index) => {
    var subOrUnSub;
    if (index === 0) {
      subOrUnSub = <Subscribe />;
    } else {
      subOrUnSub = <UnSubscribe />;
    }
    return subOrUnSub;
  };

  return (
    <div className="page-header box-bottom-space">
      <RTabbar
        defaultSelectedItem={index}
        defaultClass="tabDefault"
        dynamicTab="mini marginB0 float-end"
        activeClass="tabDefault active"
        tabData={tabData}
        callBack={(item, index) => {
          setIndex(index);
        }}
      />
      {renderComponent(index)}
    </div>
  );
};
export default withRouter(SubscribeHome);

const tabData = [
  { id: 1001, text: "Sub" },
  { id: 1002, text: "Unsub" },
];
