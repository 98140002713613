import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Tab, Tabs, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Switch } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { RSDropdownList, RSPhoneInput } from "Components/RSDropdowns";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import RESEditor from "Components/Editor/RESEditor";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import { RTabbar } from "Components/RChart/RTabbar";
import { CustomPopup } from "Components/DateRangePicker/ScheduleCustomPopup";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import SplitSettingsModal from "./SplitSettingsModal";
import MobileSMSSplitContent from "./MobileSMSSplitContent";
import { createMobileSMSCampaignMDC } from "./CamapignWrapper";
import ApprovalSettings from "./ApprovalSettings";
import SplitSizeModal from "./SplitSizeModal";
import {
  APPROVAL_MAIL,
  CAMPAIGN_TEXT,
  LANGUAGE,
  SENDER_ID,
  SEND_TEST_MSG,
  SCHEDULE_DATE,
  SELECT_TIMEZONE,
  SMS_TEMPLATE_ID,
  SPECIAL_CHARACTERS,
  ENTER_VALID_EMAIL,
} from "Helper/Constants/validationMessage";
import {
  convertBase64ToObject,
  addMinutes,
  convertObjectToBase64,
  getLsMasterDataDropdown,
  getUserData,
  isEmpty,
  getAuthUserId,
  dateFormat,
  dateTimeFormatMdc,
  getServerConfig,
  getCurrentTimeWithTimeZone,
  validateEmail,
  getAuthClientId,
} from "Helper/Utils/Utils";
import {
  GET_ADHOC_ATTRIBUTES,
  GET_SMS_SETTINGS,
  GET_SHORTEN_URL,
  SAVE_SMS_CAMPAIGN,
  UPDATE_SMS_CAMPAIGN,
  GET_CAMPAIGN_HEADER,
  GET_POTENTIAL_AUD,
  GET_NOTIFICATION_DATA,
  MDC_CANVAS_URL,
  GET_APPROVER_LIST,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import mobileSMSNew from "./SMSNew.json";
import { SuccessModal } from "../Notification/MSComponents/EmailComponents/Components/SuccessModal";
import Nperview from "../Notification/Components/NPreview";

const MdcSmsCreation = (props) => {
  const context = useContext(authContext);
  const history = useHistory();


  const { search } = useLocation();
  let queryParam = React.useMemo(() => new URLSearchParams(search), [search]);
  let decodeCampaignId = convertBase64ToObject(queryParam.get("CampaignId"));
  let getTokenFromParam = decodeURIComponent(queryParam.get("accessToken"));
  let campaignMode = decodeURIComponent(queryParam.get("mode"));
  let getChannelDetailFromParam = JSON.parse(
    decodeURIComponent(queryParam.get("ChannelDetails"))
  );
  const languageList = localStorage.getItem("lsLanguageData");
  const [channelDetails, setChannelDetails] = useState(
    getChannelDetailFromParam
  );
  const [segmentationListId, setSegmentationListId] = useState(
    channelDetails["segmentationListID"]
  );
  const [smsChannelId, setSmsChannelId] = useState(0);
  const [potentialAudienceCount, setPotentialAudienceCount] = useState(0);
  const [campaignModeAddOrEdit, setCampaignModeAddOrEdit] = useState(campaignMode);
  const [campaignId, setCampaignId] = useState(decodeCampaignId);
  const [accessToken, setAccessToken] = useState(getTokenFromParam);
  const [senderIDList, setSenderIDList] = useState([]);
  const [senderID, setSenderID] = useState(null);
  const [smsTemplateId, setSmsTemplateId] = useState(null);
  const [isSplit, setIsSplit] = useState(false);
  const [campaignText, setCampaignText] = useState(mobileSMSNew.content[0].previewBody);
  const [previewCampaignText, setPreviewCampaignText] = useState(mobileSMSNew.content[0].body)
  const [shortenLinks, setShortenLinks] = useState("");
  const [smartUrls, setSmartUrls] = useState([]);
  const [mobileNo, setMobileNo] = useState(mobileSMSNew.testSmsMobileNo !== "" ? mobileSMSNew.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryDialCodeMobile, setCountryDialCodeMobile] = useState(mobileSMSNew.countryCodeMobile !== "" ? mobileSMSNew.countryCodeMobile : getUserData()?.countryCodeMobile);
  const [mobileNoWithoutDaialCode, setMobileNoWithoutDaialCode] = useState(mobileSMSNew.testSmsMobileNo !== "" ? mobileSMSNew.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryCode, setCountryCode] = useState("in");
  const [countryPhoneNumberLength, setCountryPhoneNumberLength] = useState(0)
  const [isRequestForApproval, setIsRequestApproval] = useState();
  const [splitTabIndex, setSplitTabIndex] = useState(0);
  const [isFlashMessage, setIsFlashMessage] = useState(false);
  const [isEditTimeZone, setIsEditTimeZone] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [isDayLightSavings, setIsDayLightSavings] = useState(false);
  const [isSendRecommendation, setIsSendRecommendation] = useState(false);
  const [schedule, setSchedule] = useState("");
  const [scheduleMinimumDateTime, setScheduleMinimumDateTime] = useState(new Date());
  const [approverName, setApproverName] = useState("");
  const [isSplitSettings, setIsSplitSettings] = useState(false);
  const [isShowSplitSizeModal, setIsShowSplitSizeModal] = useState(false);
  const [isOpenSplitResetModal, setIsOpenSplitResetModal] = useState(false);
  const [errSenderIdMsg, setErrSenderIdMsg] = useState(null);
  const [errSmsTemplateIdMsg, setErrSmsTemplateIdMsg] = useState(null);
  const [errLanguageMsg, setErrLanguageMsg] = useState(null);
  const [errCampaignText, setErrCampaignTextMsg] = useState(null);
  const [errSendTestMsg, setErrSendTestMsg] = useState(null);
  const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(null);
  const [errTimeZoneMsg, setErrTimeZoneMsg] = useState(null);
  const [errApproverMsg, setErrApproverMsg] = useState(null);
  const [errMbileMsg, setErrMbileMsg] = useState(null);
  const [languageData, setLanguageData] = useState(languageList ? convertBase64ToObject(languageList) : []);
  const [language, setLanguage] = useState(languageData[0]);
  const [audienceListFromServer, setAudienceListFromServer] = useState([]);
  const [bodyTextCount, setBodyTextCount] = useState("");
  const [smsCount, setSMSCount] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [startCampaignDate, setStartCampaignDate] = useState("");
  const [endCampaignDate, setEndCampaignDate] = useState("");
  const [disableEdit, setDisableEdit] = useState("");
  const [subjectLinePersonalizeData, setSubjectLinePersonalizeData] = useState(
    []
  );
  const [approverListFromServer, setApproverListFromServer] = useState([]);
  const [isOpenSendSuccessModal, setIsOpenSendSuccessModal] = useState(false);
  const [attributeName, setAttributeName] = useState("");
  const [primayGoal, setPrimaryGoal] = useState("");
  const [toggleScreenTitle, setToggleScreenTitle] = useState("Full screen view");
  const [fullScreen, setFullScreen] = useState(false)
  const [splitTabData, setSplitTabData] = useState([
    { id: 10011, text: "Split A" },
    { id: 10012, text: "Split B" },
  ]);
  const [approvalList, setApprovalList] = useState([
    {
      approverEmail: {
        "email": "-- Select approval mail --",
        "userid": 0
      },
      ismandatory: false,
      edmworlflowdetails: 0,
      priority: 1,
      ApprovalSentcount: 1,
      name: "approval",
      input: false,
      appErr: null
    },
  ]);
  const [requestForErrorMsg, setRequestForErrorMsg] = useState([
    {
      approverEmailMsg: null,
    },
  ]);
  const [approvalSettings, setApprovalSettings] = useState({
    approvalMatchType: "All",
    approvalMatchCount: 0,
    isFollowHierarchyEnabled: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [splitContentError, setSplitContentError] = useState([
    {
      errBodyMsg: null,
    },
    {
      errBodyMsg: null,
    },
  ]);
  const [editModeCampaignData, setEditModeCampaignData] = useState([]);
  const [isShortURLCreated, setIsShortURLCreated] = useState(false);
  const [isSearchValidate, setIsSearchValidate] = useState(false);

  useEffect(() => {
    //if (senderIDList.length === 0) {
    getSMSSettings();
    getDatFromServer();
    getAudienceList();
    getApproverList();
    getTimeZoneList();

    if (!!segmentationListId && segmentationListId.length > 0) {
      getPotentialAudienceCount();
      getAdhocAttributeHeaders();
    }
    //}
  }, [])

  useEffect(() => {
    if (campaignModeAddOrEdit === "edit") {
      getCampaignDetailsById();
    }
    // handleScheduleMinimumDate();
  }, [senderIDList]);

  const getSMSCount = (campaignText) => {
    let textCount =
      campaignText.length > 0 ? campaignText.length.toString() + "/" : "";
    let smsCount = Math.floor(campaignText.length / 140) + 1;
    setBodyTextCount(textCount);
    setSMSCount(smsCount.toString());
  };

  const getSMSSettings = () => {
    let params = {};
    connectServer({
      path: GET_SMS_SETTINGS,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          setSenderIDList(res?.data?.smsSettingList);
        }
      },
      fail: (err) => {
      },
    });
  };


  const getAudienceList = () => {
    const audienceList = localStorage.getItem("lsAudienceList");
    setAudienceListFromServer(
      audienceList ? convertBase64ToObject(audienceList) : []
    );
  };

  const getApproverList = () => {
    let params = {
      clinetId: getAuthClientId(),
    };
    connectServer({
      path: GET_APPROVER_LIST,
      params,
      context: context,
      sessionOut: context.globalStateData.setSessionOutData,
      ok: (res) => {
        if (res.status) {
          setApproverListFromServer(res.data)
        }
      },
      fail: (err) => { },
    });
  };

  const getAdhocAttributeHeaders = (segmentId) => {
    let params = {
      segmentationListID: parseInt(segmentationListId.toString(), 10),
    };
    connectServer({
      path: GET_ADHOC_ATTRIBUTES,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let tempNamePersonal = [];
          res.data.names.forEach((item) => {
            tempNamePersonal.push("[[" + item + "]]");
          });
          let tempEmailPersonal = [];
          res.data.emails.forEach((item) => {
            tempEmailPersonal.push("[[" + item + "]]");
          });
          let tempFullPersonal = [];
          res.data.full.forEach((item) => {
            tempFullPersonal.push("[[" + item + "]]");
          });
          setSubjectLinePersonalizeData(tempFullPersonal);
        }
      },
      fail: (err) => {
      },
    });
  };

  const SplitComponents = (props) => {
    let components = [...mobileSMSNew.content];
    let errorIndex = 0;
    let tempTabData = [...splitTabData];
    mobileSMSNew.content.forEach((element) => {
      if (splitContentError[errorIndex] === undefined) {
        splitContentError.push(createSplitError());
      }
      if (tempTabData[errorIndex] === undefined) {
        var add = tempTabData.length;
        var charCode = "A".charCodeAt(0);
        add = charCode + add;
        var obj = {
          id: add,
          text: "Split " + String.fromCharCode(add),
        };
        tempTabData.push(obj);
        setSplitTabData(tempTabData);
      }
      errorIndex++;
    });

    return (
      <>
        <Tabs
          activeKey={"tab" + splitTabIndex}
          id="uncontrolled-tab-example"
          className="mb30 borderB0"
        >
          {splitTabData?.map((item, index1) => {
            return (
              <Tab eventKey={"tab" + index1}>
                <MobileSMSSplitContent
                  campaignId={campaignId}
                  personalizeTapData={subjectLinePersonalizeData}
                  splitSMSData={components[index1]}
                  splitContentError={splitContentError[index1]}
                />
              </Tab>
            );
          })}
        </Tabs>
      </>
    );
  };

  const getDatFromServer = () => {
    const params = {
      userId: getAuthUserId(),
      campaignId: campaignId,
    };
    connectServer({
      path: GET_CAMPAIGN_HEADER,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      retryTime: 5000,
      context: context,
      ok: (res) => {
        if (res.status) {
          const {
            campaignName,
            startDate,
            endDate,
            attributeName,
            primaryTargetCode,
          } = res.data;
          setCampaignName(campaignName);
          setStartCampaignDate(getStartDate(startDate));
          //  handleScheduleMinimumDate();
          setEndCampaignDate(new Date(new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate(), 23, 59, 59)))
          // setEndCampaignDate(endDate);
          setAttributeName(attributeName);
          let goal = goalList.filter(
            (item) => item.shortCode === primaryTargetCode
          );
          setPrimaryGoal(goal[0]["name"]);
        }
      },
      fail: (err) => { },
    });
  };

  const createSplitObject = (sectionName) => {
    let split = {
      splitType: sectionName,
      body: "",
      previewBody: "",
      smsChannelDetailId: 0,
      timezoneId: timeZone.timezoneId,
      localBlastDateTime: "",
    };
    return split;
  };

  const createSplitError = () => {
    let splitError = {
      errBodyMsg: null,
      errScheduleDateMsg: null,
    };
    return splitError;
  };

  const splitValidation = (index) => {
    let splitContent = mobileSMSNew.content[index];
    if (isEmpty(splitContent.body)) {
      let temp = { ...splitContentError };
      temp[index].errBodyMsg = CAMPAIGN_TEXT;
      setSplitContentError(temp);
      return false;
    } else {
      if (splitContent.body.includes("https") && !splitContent.body.includes("mkstr.io")) {
        let temp = [...splitContentError];
        temp[index].errBodyMsg = "Shorten URL";
        setSplitContentError(temp);
        return false;
      }
      // if (splitContent.body.length > 500) {
      //   let temp = [...splitContentError];
      //   temp[index].errBodyMsg = "Communication content should not be more that 500";
      //   setSplitContentError(temp);
      //   isValidate = false;
      // }
    }
    if (isEmpty(splitContent.localBlastDateTime)) {
      let temp = [...splitContentError];
      temp[index].errScheduleDateMsg = SCHEDULE_DATE;
      setSplitContentError(temp);
      return false;
    }
    return true;
  };

  const getPotentialAudienceCount = () => {
    let params = {
      segmentationListids: segmentationListId,
      campaignID: campaignId,
    };
    connectServer({
      path: GET_POTENTIAL_AUD,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let resultData = res.data;
          let totalListCount = resultData.filter(
            (item) => item.channelType === "SMS"
          );
          totalListCount = parseInt(totalListCount[0]["channelDescription"], 10);
          setPotentialAudienceCount(totalListCount);
        }
      },
      fail: (err) => {
      },
    });
  };

  const getCampaignDetailsById = () => {
    let params = {
      campaignId: campaignId,
      channelId: parseInt(channelDetails.ChannelId.substr(4), 10),
      campaignType: "M",
      channelDetailId: channelDetails.ChannelDetailID,
    };
    connectServer({
      path: GET_NOTIFICATION_DATA,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          const { channelJson } = res?.data;
          let data = JSON.parse(channelJson);
          // if (data?.levelNumber === 1) {
          //   let getScheduledDate = data?.ScheduleDate
          //   let scheduledDate = new Date(data['content'][0]['blastDateTime'])
          //   let currentDate = new Date()
          //   let sdate = new Date(scheduledDate)
          //   if (currentDate >= sdate) {
          //     setDisableEdit('click-off')
          //   }
          // }
          let editClickOff = (data.channelstatusId !== 12 && data.channelstatusId !== 51 && data.channelstatusId !== 9 && data.channelstatusId !== 5 && data.channelstatusId !== 20 && data.channelstatusId !== 52) ? "" : "click-off"
          setDisableEdit(editClickOff)
          setIsRequestApproval(data.channelstatusId !== 54 ? data.isRequestForApprovalEnabled : false);
          let filterSenderId = senderIDList.filter(item => item.clientSmsSettingId === data.senderId)[0];
          setSenderID(filterSenderId);
          setSmsTemplateId(data.smsProviderTemplateID);

          let filterLanguageId = languageData.filter((item) => item.languageId === data.languageId)[0];
          setLanguage(filterLanguageId);
          setSmsTemplateId(data.smsProviderTemplateID);

          mobileSMSNew.content[0].timezoneId = data.content[0].timezoneId;
          mobileSMSNew.content[0].previewBody = data.content[0].previewBody;
          const masterData = getLsMasterDataDropdown();
          let filterTimezone = masterData.timeZone.filter(item => item.timezoneId === data.content[0].timezoneId)[0];
          setTimeZone(filterTimezone);
          setCampaignText(data.content[0].previewBody);
          setPreviewCampaignText(data.content[0].body);
          getSMSCount(data.content[0].body);
          setIsShortURLCreated(data.content[0].isShortenURLEnabled)
          setEditModeCampaignData(data);

          let date = dateTimeFormatMdc(data.content[0].localBlastDateTime);
          setSchedule(date);
          if (data.channelstatusId !== 54 && data.isRequestForApprovalEnabled) {
            let requestForApproval = { ...data.requestForApproval };
            let approveList = [...requestForApproval.approvarList];
            let tempApprovalList = [];
            let tempApprovalSettings = {
              approvalMatchType: requestForApproval.matchType,
              approvalMatchCount: requestForApproval.matchCount,
              isFollowHierarchyEnabled: requestForApproval.IsFollowHierarchy,
            }
            approveList.map((item, index) => {
              let tempItem = {
                approverEmail: {
                  "email": item.approvarName,
                  "userid": parseInt(item.approvarId, 10)
                },
                ismandatory: item.ismandatory,
                edmworlflowdetails: 0,
                priority: item.priority,
                ApprovalSentcount: item.ApprovalSentcount || 1,
                name: "approval",
                input: item.approvarId === "" ? true : false,
                appErr: null
              }
              tempApprovalList.push(tempItem)
            })
            setApprovalList(tempApprovalList);
            setApprovalSettings(tempApprovalSettings);
          }

          // if (data.content[0].statusId === 5 || data.content[0].statusId === 9) {
          //   setDisableEdit('click-off')
          // }
        }
      },
      fail: (err) => {
      },
    });
  };

  const getTimeZoneList = () => {
    const data = getLsMasterDataDropdown();
    setTimeZoneData(data.timeZone);
    if (campaignModeAddOrEdit === "add") {
      const loggedDetails = getUserData();
      let filterTimezone = data.timeZone.filter(function (item) {
        return (
          item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId
        );
      });
      setTimeZone(filterTimezone[0]);
      mobileSMSNew.content[0].timezoneId = filterTimezone[0].timezoneId;
      setMobileNo(mobileSMSNew.testSmsMobileNo !== "" ? mobileSMSNew.testSmsMobileNo : getUserData()?.mobileNo);
      setCountryDialCodeMobile(mobileSMSNew.countryCodeMobile !== "" ? mobileSMSNew.countryCodeMobile : getUserData()?.countryCodeMobile)
      setMobileNoWithoutDaialCode(mobileSMSNew.testSmsMobileNo !== "" ? mobileSMSNew.testSmsMobileNo : getUserData()?.mobileNo)
    } else {
      /* getSMSCount(campaignText);
       setSmartURL(mobileSMSNew.content[0].smartURL);
         */
      //setMobileNo(mobileSMSNew.testSmsMobileNo);
    }
  };

  const backToCanvas = () => {
    const sessionStr = localStorage.getItem("session");
    const sessionObject = JSON.parse(sessionStr);
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    const campId = window.btoa(campaignId);
    let baseURL = getServerConfig();
    let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
    window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${campId}&accessToken=${encodeURIComponent(accessToken)}&from=${fromEnvi}`;
  };
  const validation = (testSms = false) => {
    if (senderID === null || senderID === undefined || senderID.clientSmsSettingId === 0) {
      setErrSenderIdMsg(SENDER_ID);
      return false;
    }
    if (smsTemplateId === null || isEmpty(smsTemplateId)) {
      setErrSmsTemplateIdMsg(SMS_TEMPLATE_ID);
      return false;
    }
    if (language === undefined || language.languageId === 0) {
      setErrLanguageMsg(LANGUAGE);
      return false;
    }
    if (isEmpty(previewCampaignText)) {
      setErrCampaignTextMsg(CAMPAIGN_TEXT);
      return false;
    } else {
      if (previewCampaignText.includes("https") && !isShortURLCreated) {
        setErrCampaignTextMsg("Shorten URL");
        return false;
      }
      if (previewCampaignText.length > 500) {
        setErrCampaignTextMsg("Text limit exceeded");
        return false;
      }
    }
    if (channelDetails.LevelNumber === 1 && testSms === false) {
      if (isEmpty(schedule)) {
        setErrScheduleDateMsg(SCHEDULE_DATE);
        setSchedule("");
        return false;
      } else {
        let currentTime = getCurrentTimeWithTimeZone(new Date(), timeZone.timezoneId, 15)
        if (new Date(schedule) < new Date(currentTime)) {
          setErrScheduleDateMsg("Select a time greater from (" + currentTime.toLocaleString().trim() + ")");
          return false;
        }
      }
      // if (new Date() > new Date(schedule)) {
      //   setErrScheduleDateMsg("Choose at least 15 minutes greater than current time");
      //   handleScheduleMinimumDate(startCampaignDate)
      //   return false;
      // }
      // if (new Date() < new Date(schedule) && !handleTimeDiff()) {
      //   handleScheduleMinimumDate(startCampaignDate);
      //   setErrScheduleDateMsg("Choose at least 15 minutes greater than current time");
      //   return false;
      // }
      if (timeZone.timezoneId === 0) {
        setErrTimeZoneMsg(SELECT_TIMEZONE);
        return false;
      }
    }
    let isSplitComplete = true;
    if (!isSplit) {
      // if (isEmpty(previewCampaignText)) {
      //   setErrCampaignTextMsg(CAMPAIGN_TEXT);
      //   return false;
      // } else {
      //   if (previewCampaignText.includes("https") && !previewCampaignText.includes("mkstr.io")) {
      //     setErrCampaignTextMsg("Shorten URL");
      //     return false;
      //   }
      //   // if (previewCampaignText.length > 500) {
      //   //   setErrCampaignTextMsg("Communication content should not be more that 500");
      //   //   isValidate = false;
      //   // }
      // }
    } else {
      let i = 0;
      mobileSMSNew.content.forEach((element) => {
        isSplitComplete = splitValidation(i);
        if (!isSplitComplete) {
          setSplitTabIndex(i);
          return;
        }
        i++;
      });
    }
    if (testSms) {
      if (isEmpty(mobileNo)) {
        setErrMbileMsg('')
        setErrSendTestMsg(SEND_TEST_MSG);
        return false;
      }
      let rslt = handleMobileNumberValidation(mobileNoWithoutDaialCode, false);
      if (rslt.err) {
        setErrSendTestMsg('');
        setErrMbileMsg(rslt.msg)
        return false;
      } else {
        setErrSendTestMsg('');
        setErrMbileMsg('')
      }
    }
    let enable = true;
    if (isRequestForApproval && requestForErrorMsg.filter((item) => item.approverEmailMsg !== null).length === 0) {
      approvalList.forEach((item, index) => {
        if (item.approverEmail.email === "" || item.approverEmail.email === "-- Select approval mail --") {
          enable = false;
          let tempErrorMsg = [...requestForErrorMsg];
          tempErrorMsg[index].approverEmailMsg = APPROVAL_MAIL;
          setRequestForErrorMsg(tempErrorMsg);
          return;
        } else {
          let tempErrorMsg = [...requestForErrorMsg];
          tempErrorMsg[index].approverEmailMsg = null;
          setRequestForErrorMsg(tempErrorMsg);
          enable = true;
        }
      })
    } else {
      enable = isRequestForApproval ? false : true
    }
    if (!isSplitComplete || !enable) {
      return false;
    } else {
      return true;
    }
  };

  const handleTimeDiff = () => {
    let currentDate = new Date();
    let currentTime = currentDate.getTime();
    let scheduleDate = new Date(schedule);
    let scheduleTime = scheduleDate.getTime();
    let timeDiffInSecs = (scheduleTime - currentTime) / 1000;
    let timeDiffInMins = Math.round(timeDiffInSecs / 60);
    return timeDiffInMins < 15 ? false : true;
  }
  const handleCountryPhoneNumberLength = (country) => {
    let countryFormat = country['format'].split(/ (.*)/s);
    let [countryCodeFormat, countryNumberFormat, empty] = countryFormat;
    let totCount = (countryNumberFormat.match(/\./g) || []).length
    setCountryPhoneNumberLength(totCount);
  }

  const handleMobileNumberValidation = (mobileNumber, maxMobile) => {
    let numberWihoutComma = mobileNumber?.replaceAll(',', '');
    let valid = true;
    let errObj = { err: false, msg: null };
    // let phoneValues = numberWihoutComma?.match(new RegExp('.{1,' + countryPhoneNumberLength + '}', 'g'));
    let phoneValues = mobileNumber.split(/\s*,\s*/);
    if (phoneValues && phoneValues.length <= 5) {
      const numberPattern = /^[+]*[0-9]+$/;
      phoneValues.forEach(item => {
        if (item.length < 5 || item.length > countryPhoneNumberLength || !numberPattern.test(item)) {
          valid = false;
        }
      });
    }
    if (!phoneValues) {
      errObj.err = true;
      errObj.msg = 'Please enter mobile number';
    } else if (!valid) {
      errObj.err = true;
      errObj.msg = 'Enter valid mobile number';
    } else if (maxMobile) {
      errObj.err = true;
      errObj.msg = 'Maximum mobile number reached';
    } else if (phoneValues && phoneValues.length !== new Set(phoneValues).size) {
      errObj.err = true;
      errObj.msg = 'Please remove duplicate number';
    }

    return errObj;
  }
  const getStartDate = (startDate) => {
    let campaignStartDate = new Date(startDate);
    let today = new Date();
    if (campaignStartDate > today) {
      campaignStartDate = new Date(startDate);
    } else {
      campaignStartDate = new Date();
    }
    return campaignStartDate
  }
  const getStartDateForSchedule = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (mobileSMSNew.content[0].timezoneId === 0) {
      filterTimezone = data.timeZone.filter(
        (item) =>
          item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
      )[0];
    } else {
      filterTimezone = data.timeZone.filter(
        (item) => item.timezoneId === mobileSMSNew.content[0].timezoneId
      )[0];
    }
    let startDate = new Date(getStartDate(startCampaignDate));
    let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
    return startDateWithTime;
  }

  const toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      setToggleScreenTitle("Normal view")
      setFullScreen(true)
    } else {
      cancelFullScreen.call(doc);
      setToggleScreenTitle("Full screen view")
      setFullScreen(false)
    }
  }
  return (
    <>
      <LayoutPageWrapper>
        <Container>
          <Row>
            <Col md={12}>
              <div className="progress-steps-box mr--mdcsmscreate">
                <div className="steps-label">
                  <small>Communication name:</small>
                  <RSPTooltip text={window.atob(campaignName)} position="bottom">
                    <label className="label-row label-dark">
                      {window.atob(campaignName)}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Duration:</small>
                  <RSPTooltip
                    text={` ${dateFormat(startCampaignDate)} - ${dateFormat(
                      endCampaignDate
                    )}`}
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {dateFormat(startCampaignDate)} - {dateFormat(endCampaignDate)}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Type:</small>
                  <RSPTooltip text={attributeName} position="bottom">
                    <label className="label-row label-dark">{attributeName}</label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Primary communication goal:</small>
                  <RSPTooltip text={primayGoal} position="bottom">
                    <label className="label-row label-dark">{primayGoal}</label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Potential audience:</small>
                  <RSPTooltip text={potentialAudienceCount !== 0 ? potentialAudienceCount : "NA"} position="bottom">
                    <label className="label-row label-dark">
                      {potentialAudienceCount !== 0 ? potentialAudienceCount : "NA"}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="">
                  <RSPTooltip text={toggleScreenTitle} position="bottom">
                    <div className="steps-label-row-icon d-flex align-items-center justify-content-end">
                      <i
                        onClick={() => toggleFullScreen()}
                        className={`icon-sd-${fullScreen ? "collapse" : "expand"
                          }-large icons-md white large-icon cp bgBlue`}
                      ></i>
                    </div>
                  </RSPTooltip>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={12}>
              <div className="header-title d-flex justify-content-between">
                <h1>SMS content</h1>
                <div className="back-text">
                  <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                  <a onClick={backToCanvas}>Back to canvas</a>
                </div>
              </div>
              <div className={`portlet-box-theme bgWhite padding20 mb50`}>
                <div className="mt20 ml20-del posr">
                  <Row className={`custom-column marginB20 ${disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Sender ID</label>
                    </Col>
                    <Col md={6}>
                      <div className="position-relative">
                        {errSenderIdMsg && (
                          <div className="validation-message">{errSenderIdMsg}</div>
                        )}
                        <RSDropdownList
                          data={senderIDList}
                          className="mb10 required"
                          value={senderID}
                          defaultItem={{
                            clientSmsSettingId: 0,
                            clientId: 0,
                            senderId: "-- Select sender ID --",
                          }}
                          textField="senderId"
                          dataItemKey="clientSmsSettingId"
                          onChange={(event) => {
                            let errMsg =
                              event.target.value.clientSmsSettingId !== 0
                                ? null
                                : SENDER_ID;
                            mobileSMSNew.senderId =
                              event.target.value.clientSmsSettingId;
                            setErrSenderIdMsg(errMsg);
                            setSenderID(event.target.value);
                          }}
                        />
                      </div>
                      <div className="input-outer-set">
                        <RSPTooltip text="New sender ID" position="top">
                          <i className="icon-sd-settings-medium icons-md blue" onClick={() => {
                            history.push({
                              pathname: '/preferences/communication-settings',
                              state: { hIndex: 1, index: 0, from: "MDC" }
                            })
                          }} />
                        </RSPTooltip>
                      </div>
                      {/*  <div className="input-outer-set">
                    <RSPTooltip text="New sender ID" position="top">
                      <i
                        className="icon-settings-small icons-md blue"
                        onClick={() => {
                          history.push("/preferences/communication-settings");
                        }}
                      />
                    </RSPTooltip>
                  </div>*/}
                      {/* <RSCheckbox
                    className="marginB20"
                    lbl="Flash message"
                    checked={isFlashMessage}
                    cb={(status) => {
                      setIsFlashMessage(status);
                      mobileSMSNew.isFlashMessageEnabled = status;
                    }}
                  /> */}
                    </Col>
                  </Row>
                  <Row className={`custom-column marginB20 ${disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Template ID</label>
                    </Col>
                    <Col md={6}>
                      <RSInput2
                        required={true}
                        ph="Enter sender template ID"
                        val={smsTemplateId}
                        max={50}
                        cb={(value) => {
                          let errMsg = value.length > 0 ? null : SMS_TEMPLATE_ID;
                          var regex = /^[A-Za-z0-9 ]+$/;
                          if (errMsg === null) {
                            if (regex.test(value)) {
                              mobileSMSNew.smsProviderTemplateID = value;
                              setSmsTemplateId(value);
                              setErrSmsTemplateIdMsg(errMsg);
                            } else {
                              setSmsTemplateId(mobileSMSNew.smsProviderTemplateID);
                              setErrSmsTemplateIdMsg(SPECIAL_CHARACTERS);
                            }
                          } else {
                            mobileSMSNew.smsProviderTemplateID = value;
                            setSmsTemplateId(value);
                            setErrSmsTemplateIdMsg(errMsg);
                          }
                        }}
                        errorMessage={errSmsTemplateIdMsg}
                      />
                    </Col>
                  </Row>
                  <Row className={`custom-column marginB20 ${disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Language</label>
                    </Col>
                    <Col md={6}>
                      <div className="position-relative">
                        {errLanguageMsg && (
                          <div className="validation-message">{errLanguageMsg}</div>
                        )}
                        <RSDropdownList
                          data={languageData}
                          value={language}
                          defaultItem={{ languageId: 0, languageName: "-- Select language --" }}
                          textField="languageName"
                          dataItemKey="languageId"
                          onChange={(event) => {
                            let errMsg =
                              event.target.value.languageId !== 0 ? null : LANGUAGE;
                            mobileSMSNew.languageId = event.target.value.languageId;
                            setErrLanguageMsg(errMsg);
                            setLanguage(event.target.value);
                          }}
                          className={"required"}
                        />
                      </div>
                    </Col>
                  </Row>
                  {channelDetails.LevelNumber === 1 && (
                    <Row className="custom-column marginB30 mt10 click-off">
                      <Col md={3}>
                        <label>Split A/B test</label>
                      </Col>
                      <Col md={6}>
                        <div className={`d-flex align-items-center mb20`}>
                          <Switch
                            className={`my0 ${potentialAudienceCount < 100 ? "click-off" : ""
                              }`}
                            onLabel={"ON"}
                            offLabel={"OFF"}
                            onChange={(event) => {
                              if (event.target.value) {
                                let temp = [...mobileSMSNew.content];
                                temp[0].splitType = "A";
                                temp[0].body = "";
                                temp[0].smsChannelDetailId = 0;
                                temp[0].timezoneId = timeZone.timezoneId;
                                temp[0].localBlastDateTime = "";
                                var charCode = "A".charCodeAt(0);
                                let add = charCode + 1;
                                temp.push(
                                  createSplitObject(String.fromCharCode(add))
                                );
                                mobileSMSNew.content = temp;
                                setIsSplit(!isSplit);
                              } else {
                                setIsOpenSplitResetModal(!isOpenSplitResetModal);
                              }
                            }}
                            checked={isSplit}
                          />
                          {isSplit && (
                            <div className="d-flex align-items-center">
                              <i
                                className="icon-dropdown-small cp ml15"
                                onClick={() => {
                                  setIsShowSplitSizeModal(!isShowSplitSizeModal);
                                }}
                              ></i>
                              {/* <i className='icon-settings-small icons-md blue cursor-pointer' onClick={()=>{
                  setIsSplitSettings(!isSplitSettings);
                }}/> */}
                            </div>
                          )}
                          <RSPTooltip
                            text="A minimum of 100 audience are required for Split A/B testing. Check the Campaign Listing page for results."
                            position="top"
                          >
                            <i className="icon-sd-question-mark-mini icons-sm blue cp ml10"></i>
                          </RSPTooltip>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {isSplit ? (
                    <div className="split-container">
                      <div className="tabs-filled-component-del d-flex align-items-center">
                        <RTabbar
                          defaultSelectedItem={splitTabIndex}
                          defaultClass="tabDefault"
                          dynamicTab="addtab"
                          activeClass="tabDefault active"
                          tabData={splitTabData}
                          callBack={(item, index) => {
                            setSplitTabIndex(index);
                          }}
                        />
                        {splitTabData.length < 2 && (
                          <i
                            className={`d-inline-block icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer ml15 `}
                            onClick={() => {
                              let enable = true;
                              for (
                                var i = 0;
                                i < mobileSMSNew.content.length;
                                i++
                              ) {
                                enable = splitValidation(i);
                                if (!enable) {
                                  setSplitTabIndex(i);
                                  break;
                                }
                              }
                              if (enable) {
                                if (splitTabData.length < 2) {
                                  let obj = splitTabData[splitTabData.length - 1];
                                  let charCode = obj["text"]
                                    .split(" ")[1]
                                    .charCodeAt(0);
                                  let add = charCode + 1;
                                  let splitText = String.fromCharCode(add);
                                  obj.id = parseInt(obj.id, 10) + 1;
                                  obj.text = `Split ${splitText}`;
                                  let temp = [...mobileSMSNew.content];
                                  temp.push(createSplitObject(splitText));
                                  mobileSMSNew.content = temp;
                                  let errTemp = [...splitContentError];
                                  errTemp.push(createSplitError());
                                  setSplitContentError(errTemp);
                                  setSplitTabData([...splitTabData, obj]);
                                }
                              }
                            }}
                          />
                        )}
                      </div>
                      {<SplitComponents mobileSMSData={mobileSMSNew} />}
                    </div>
                  ) : (
                    <>
                      <Row className={`custom-column marginB20 ${disableEdit}`}>
                        <Col md={3}>
                          <label className="label-row">Communication text</label>
                        </Col>
                        <Col md={6}>
                          <div className="position-relative">
                            {errCampaignText && (
                              <div className="validation-message yaxis-7">
                                {errCampaignText}
                              </div>
                            )}
                            <RESEditor
                              personalizeTapData={subjectLinePersonalizeData}
                              mobilepush={false}
                              isCampaignText={true}
                              isWhatsappCampaignText={false}
                              bodyContent={mobileSMSNew.content[0].previewBody}
                              mobileNumber={mobileNo}
                              schedule={schedule}
                              countryCodeMobile={countryDialCodeMobile}
                              campaignId={campaignId}
                              smsChannelId={smsChannelId}
                              mobileSMSData={mobileSMSNew}
                              channelDetails={channelDetails}
                              totalaudience={potentialAudienceCount}
                              senderId={senderID}
                              language={language}
                              onValidateSearch={(status) => {
                                let isValidate = validation(true);
                                setIsSearchValidate(isValidate);
                              }}
                              isSearchValidate={isSearchValidate}
                              onChangeIsSearchValidate={() => {
                                setIsSearchValidate(false);
                              }}
                              onChangeSmsChannelId={(value) => {
                                mobileSMSNew.content[0].smsChannelDetailId = value;
                                channelDetails.ChannelDetailID = value;
                                setSmsChannelId(value)
                              }}
                              callBack={(key, item) => {
                                if (key === "bodytext") {
                                  let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                                  if (errMsg !== null) {
                                    setSmartUrls([]);
                                  }
                                  let textCount = item.length > 0 ? item.length.toString() + "/" : "";
                                  let smsCount = Math.floor(item.length / 140) + 1;
                                  setBodyTextCount(textCount);
                                  setSMSCount(smsCount.toString());
                                  mobileSMSNew.content[0].body = item;
                                  mobileSMSNew.content[0].previewBody = item;
                                  setErrCampaignTextMsg(errMsg);
                                  setCampaignText(item);
                                  setPreviewCampaignText(item);

                                  if (item.length > 0 && smartUrls.length > 0 && shortenLinks.length > 0) {
                                    let tempVal = item;
                                    smartUrls.forEach((URL, index) => {
                                      if (index < 2) {
                                        tempVal = item.replaceAll(URL, shortenLinks[index])
                                      }
                                    })
                                    setPreviewCampaignText(tempVal);
                                    mobileSMSNew.content[0].body = tempVal;
                                  }
                                } else if (key === "onBlur") {
                                  mobileSMSNew.content[0].bodyPosition = item.target.selectionStart;
                                }
                              }}
                            />
                          </div>
                          <RSPTooltip text="Shortern URL" position="top">
                            <i className={`icons-sm icon-sd-link-medium icons-md blue float-end cursor-pointer ${mobileSMSNew?.content[0]?.previewBody?.includes("https") ? "" : "click-off"}`} onClick={() => {
                              var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
                              let smartURLs = mobileSMSNew.content[0].previewBody.match(urlRegex);
                              let errMsg = !!smartURLs ? smartURLs.length > 2 ? "Accept only two URL in SMS content" : null : "Invalid URL"
                              if (errMsg === null) {
                                errMsg = smartURLs[0] !== smartURLs[1] ? null : "Give different URLs in SMS content";
                                setErrCampaignTextMsg(errMsg);
                                setSmartUrls(smartURLs);
                                if (errMsg === null) {
                                  let smartURLParams = [];
                                  smartURLs.forEach((item, index) => {
                                    let params = {
                                      "campaignId": campaignId,
                                      "goalNo": 1,
                                      "blastType": "",
                                      "smartCode": "",
                                      // "domainUrl": DOMAIN_URL,
                                      "smartAdaptive": "",
                                      "smartUrl": item,
                                      "channelId": 2,
                                      "blastNo": channelDetails["LevelNumber"],
                                      "blastType": "M",
                                      "actionId": channelDetails["ActionID"],
                                      parentChannelDetailId: channelDetails["ParentChannelDetailID"]
                                    }
                                    smartURLParams.push(params)
                                  })
                                  let params = {
                                    "smartUrlsList": smartURLParams
                                  }
                                  connectServer({
                                    path: GET_SHORTEN_URL,
                                    params,
                                    loading: context.globalStateData.setIsLoadingData,
                                    sessionOut: context.globalStateData.setSessionOutData,
                                    context: context,
                                    ok: res => {
                                      if (res.status) {
                                        let shortenLinks = [];
                                        let tempVal = mobileSMSNew.content[0].previewBody;
                                        res.data.forEach((item, index) => {
                                          if (index < 2) {
                                            tempVal = tempVal.replaceAll(smartURLs[index], item.smartCodePath)
                                            shortenLinks.push(item.smartCodePath)
                                          }
                                        })
                                        mobileSMSNew.content[0].body = tempVal;
                                        mobileSMSNew.content[0].isShortenURLEnabled = res.status;
                                        setPreviewCampaignText(tempVal)
                                        setShortenLinks(shortenLinks)
                                        setIsShortURLCreated(res.status)
                                      } else {
                                        mobileSMSNew.content[0].isShortenURLEnabled = res.status;
                                        setIsShortURLCreated(res.status)
                                        setErrCampaignTextMsg(res.message)
                                      }
                                    },
                                    fail: err => {
                                    }
                                  })
                                }
                              } else {
                                setErrCampaignTextMsg(errMsg);
                                setSmartUrls(smartURLs || []);
                              }
                            }}></i>
                          </RSPTooltip>
                          <RSPTooltip text="Shorten URL will reflect in SMS preview content." position="top">
                            <i className="icon-sd-question-mark-mini icons-sm float-end blue cp"></i>
                          </RSPTooltip>
                        </Col>
                        <Col md={3} className="last-column-view mr-sms-mdc">
                          {mobileSMSNew?.content[0]?.previewBody?.length > 0 ?
                            <div className="instant-preview">
                              <Nperview
                                body={previewCampaignText}
                                smartUrls={smartUrls}
                                shortenLinks={shortenLinks}
                                isCampaignText={true}
                                mobileNumber={"+" + countryDialCodeMobile + " " + mobileNo}
                                getErrMessage={(value) => { setErrCampaignTextMsg(value) }}
                              />
                            </div>
                            : null}
                        </Col>
                      </Row>
                      {/* <Row className="custom-column marginB30">
                    <Col md={3}>
                      <label className="label-row">Shortlink</label>
                    </Col>
                    <Col md={6}>

                      <RSInput2
                        ph="Enter URL"
                        className=""
                        val={smartURL}
                        cb={(text) => {
                          let errMsg = text !== "" ? isValidWebsite(text) ? null : ENTER_VALID_WEBSITE : "Enter valid URL"
                          mobileSMSNew.content[0].shortenURL = text;
                          setSmartURL(text);
                          setErrSmartURL(errMsg);
                        }}
                        errorMessage={errSmartURL}
                      />
                    </Col>
                    <Col md={3}>
                      <RSPrimaryBtn className={isEnableShortenButton(smartURL) ? "" : "click-off"} onClick={() => {
                        if (isEmpty(smartURL)) {
                          setErrSmartURL("Enter URL");
                          return
                        }
                        let params = {
                          "campaignId": campaignId,
                          "goalNo": 1,
                          "smartCode": "",
                          "domainUrl": "https://lcoa.mkstr.io/",
                          "smartAdaptive": "",
                          "smartUrl": smartURL,
                          "channelId": 2
                        }
                        connectServer({
                          path: GET_SHORTEN_URL,
                          params,
                          loading: context.globalStateData.setIsLoadingData,
                          sessionOut: context.globalStateData.setSessionOutData,
                          ok: res => {
                            if (res.status) {
                              let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", mobileSMSNew.content[0].bodyPosition);
                              mobileSMSNew.content[0].body = val;
                              setCampaignText(val);
                              getSMSCount(val);
                            }
                          },
                          fail: err => {
                          }
                        })
                      }}>Shorten URL</RSPrimaryBtn>
                    </Col>
                  </Row> */}
                      <div className={`schedule-even ${(channelDetails.LevelNumber === 1 || isEditTimeZone) ? 'paddingT10 mt13' : ''}`}>
                        {channelDetails.LevelNumber === 1 && (
                          <Row className={`custom-column paddingB10 ${disableEdit}`}>
                            <Col md={3}>
                              <label className="label-row">Schedule</label>
                            </Col>
                            <Col md={6}>
                              <div className="position-relative">
                                {errScheduleDateMsg && (
                                  <div className="validation-message top-18">
                                    {errScheduleDateMsg}
                                  </div>
                                )}
                                <div className="rsptimer-picker ">
                                  <DateTimePicker
                                    placeholder="Select schedule date and time "
                                    className="required"
                                    value={schedule !== "" ? new Date(schedule) : null}
                                    // //show={true}
                                    min={getStartDateForSchedule()}
                                    max={new Date(endCampaignDate)}
                                    format={"dd MMM, yyyy HH:mm"}
                                    popup={CustomPopup}
                                    onChange={(event) => {
                                      if (event.target.value !== null) {
                                        setSchedule(event.target.value);
                                        let date = new Date(event.target.value);
                                        let errMsg =
                                          event.target.value !== "" || date <= new Date()
                                            ? null
                                            : SCHEDULE_DATE;
                                        setErrScheduleDateMsg(errMsg);
                                      } else {
                                        setSchedule("");
                                        setErrScheduleDateMsg(SCHEDULE_DATE);
                                      }
                                    }}
                                  />
                                  <div className={`input-outer-set ${(schedule !== "") ? "" : "click-off"} `}>
                                    <RSPTooltip text="Reset schedule" position="top">
                                      <i
                                        className="icon-sd-refresh-medium icons-md blue ml10"
                                        onClick={() => {
                                          setSchedule("");
                                        }}
                                      />
                                    </RSPTooltip>
                                  </div>
                                </div>
                              </div>
                              <Row className="marginT5">
                                {/*<Col md="8">
                            <div className="d-flex align-items-center marginB30">
                              <RSCheckbox
                                className="marginB0"
                                lbl="Use send time recommendation"
                                checked={isSendRecommendation}
                                cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                              />
                              <RSPTooltip
                                text="Send time will be based on Resulticks recommendation model, arrived at using individual audience's open time propensit"
                                position="top"
                              >
                              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                              </RSPTooltip>
                            </div>
                          </Col>*/}
                                {isEditTimeZone === false ? (
                                  <Col>
                                    <div className="float-end">
                                      <label>
                                        {!!timeZone && timeZone.gmtOffset}
                                      </label>
                                      <RSPTooltip text="Edit" position="top">
                                        <i
                                          className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer"
                                          onClick={() => {
                                            setIsEditTimeZone(!isEditTimeZone);
                                          }}
                                        />
                                      </RSPTooltip>
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        )}
                        {isEditTimeZone ? (
                          <Row className={`custom-column pt0 ${disableEdit}`}>
                            <Col md={3}>
                              <label className="label-row">Select time zone</label>
                            </Col>
                            <Col md={6}>
                              <div className="position-relative">
                                {errTimeZoneMsg && (
                                  <div className='validation-message'>
                                    {errTimeZoneMsg}
                                  </div>
                                )}
                                <RSDropdownList
                                  className="marginB0 required"
                                  data={timeZoneData || []}
                                  value={timeZone}
                                  defaultItem={{
                                    timezoneId: 0,
                                    timezoneName: "-- Select time zone --",
                                  }}
                                  textField="timezoneName"
                                  dataItemKey="timezoneId"
                                  onChange={(e) => {
                                    let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                                    setErrTimeZoneMsg(errMsg);
                                    setTimeZone(e.target.value);
                                    mobileSMSNew.content[0].timezoneId = e.target.value.timezoneId
                                    channelDetails.timezoneId = e.target.value.timezoneId;
                                    getStartDateForSchedule()
                                  }}
                                />
                              </div>
                              <Row className="marginT5">
                                <Col md="8">
                                  <div className="d-flex align-items-center">
                                    <RSCheckbox
                                      className="marginT0 marginB15 click-off"
                                      lbl="Daylight saving"
                                      checked={isDayLightSavings}
                                      cb={(value) => {
                                        setIsDayLightSavings(!isDayLightSavings);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                      <Row className={`custom-column mt30 ${disableEdit}`}>
                        <Col md={3}>
                          <label className="label-row">Send test message to</label>
                        </Col>
                        <Col md={5} className="posr zIndex1">
                          <div className="position-relative d-flex">
                            {errSendTestMsg && (
                              <div className="validation-message">
                                {errSendTestMsg}
                              </div>
                            )}
                            <RSPhoneInput
                              className="phone-input-short not-required"
                              value={countryDialCodeMobile + mobileNo}
                              country={countryCode}
                              inputStyle={{ display: 'none' }}
                              onMount={(phone, country) => {
                                handleCountryPhoneNumberLength(country)
                              }}
                              onChange={(phone, country) => {
                                handleCountryPhoneNumberLength(country)
                                let errMsg = phone !== "" ? null : SEND_TEST_MSG;
                                setErrSendTestMsg(errMsg);
                                if (countryDialCodeMobile !== country.dialCode) {
                                  setMobileNo("");
                                }
                                setCountryCode(country.countryCode);
                                setErrMbileMsg(null)
                                setCountryDialCodeMobile(country.dialCode);
                              }}
                            />
                            <RSInput2
                              ph="+91 7845984022"
                              type="tel"
                              className=""
                              keyboardType="telePhoneNumber"
                              dialCode={countryDialCodeMobile}
                              phoneLength={countryPhoneNumberLength}
                              val={`+${countryDialCodeMobile} ${mobileNo}`}
                              cb={(phone, maxMobile) => {
                                setMobileNo(phone);
                                setMobileNoWithoutDaialCode(phone);
                                let isValidNumber = handleMobileNumberValidation(phone, maxMobile);
                                const { err, msg } = isValidNumber;
                                setErrMbileMsg(msg);

                              }}
                              errorMessage={errMbileMsg}
                            />
                          </div>
                          <div className={`d-flex align-items-center mt-20 mb20 ${(getUserData().clientId === 16 || getUserData().clientId === 1604) ? "" : "d-none"}`}>
                            <RSCheckbox
                              className="marginB0"
                              lbl="Request approval"
                              checked={isRequestForApproval}
                              cb={(status) => {
                                setIsRequestApproval(status);
                                mobileSMSNew.isRequestForApprovalEnabled = status;
                                let tempApprovalList = [
                                  {
                                    approverEmail: {
                                      "email": "-- Select approval mail --",
                                      "userid": 0
                                    },
                                    ismandatory: false,
                                    edmworlflowdetails: 0,
                                    priority: 1,
                                    ApprovalSentcount: 1,
                                    name: "approval",
                                    input: false,
                                    appErr: null
                                  }
                                ]
                                if (!status) {
                                  setApprovalList(tempApprovalList)
                                  mobileSMSNew.requestForApproval = {
                                    "IsFollowHierarchy": false,
                                    "matchType": "All",
                                    "matchCount": "0",
                                    "approvarList": [
                                      {
                                        "approvarId": "0",
                                        "approvarName": "-- Select approval mail --",
                                        "flag": false,
                                        "ismandatory": false,
                                        "priority": 1,
                                        "ApprovalSentcount": 1
                                      }
                                    ]
                                  }
                                } else {
                                  setApprovalList(tempApprovalList);
                                }
                              }}
                            />
                            <RSPPophover text="Get the communication content approved by the concerned person." position="top">
                              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer position-relative z-1"></i>
                            </RSPPophover>
                          </div>
                          {isRequestForApproval && approvalList.map((item, index) => {
                            return (
                              <Row key={index}>
                                <Col md={12}>
                                  <div className="position-relative">
                                    {requestForErrorMsg[index].approverEmailMsg && (
                                      <div className="validation-message">
                                        {requestForErrorMsg[index].approverEmailMsg}
                                      </div>)}
                                    {
                                      item.approverEmail.userid !== -1 ?
                                        <RSDropdownList
                                          className={`mb10 ${isRequestForApproval ? "required" : ""}`}
                                          data={approverListFromServer}
                                          textField="email"
                                          dataItemKey="userid"
                                          value={item.approverEmail}
                                          defaultItem={{
                                            "email": "-- Select approval mail --",
                                            "userid": 0
                                          }}
                                          onChange={(event) => {
                                            let temp = [...approvalList];
                                            temp[index].approverEmail = event.target.value.userid !== -1 ? event.target.value : {
                                              "email": "",
                                              "userid": -1
                                            };
                                            let tempErrorMsg = [...requestForErrorMsg];
                                            tempErrorMsg[index].approverEmailMsg = null;
                                            setApprovalList(temp);
                                            setRequestForErrorMsg(tempErrorMsg);
                                          }} /> :
                                        <div>
                                          <RSInput2
                                            required={isRequestForApproval}
                                            ph="Enter the approver's email address"
                                            val={item.approverEmail.email}
                                            cls="mb10 paddingR35"
                                            cb={(text) => {
                                              let tempErrorMsg = [...requestForErrorMsg];
                                              tempErrorMsg[index].approverEmailMsg = null;
                                              setRequestForErrorMsg(tempErrorMsg);
                                              let tempApprovalList = [...approvalList];
                                              tempApprovalList[index].approverEmail.email = text;
                                              setApprovalList(tempApprovalList);
                                            }}
                                            ob={(text) => {
                                              if (text.split(",").length === 1) {
                                                if (!validateEmail(text)) {
                                                  let tempErrorMsg = [...requestForErrorMsg];
                                                  tempErrorMsg[index].approverEmailMsg = ENTER_VALID_EMAIL;
                                                  setRequestForErrorMsg(tempErrorMsg);
                                                } else {
                                                  let tempErrorMsg = [...requestForErrorMsg];
                                                  tempErrorMsg[index].approverEmailMsg = null;
                                                  setRequestForErrorMsg(tempErrorMsg);
                                                }
                                              } else {
                                                let tempErrorMsg = [...requestForErrorMsg];
                                                tempErrorMsg[index].approverEmailMsg = "Accept only one approval mail";
                                                setRequestForErrorMsg(tempErrorMsg);
                                              }
                                            }}
                                            errorMessage={requestForErrorMsg[index].approverEmailMsg}
                                          />
                                          <div className="input-inner-set">
                                            <i className="icon-sd-close-mini icons-sm blue mr-10"
                                              onClick={() => {
                                                let tempApprovalList = [...approvalList];
                                                tempApprovalList[index].approverEmail = {
                                                  "email": "-- Select approval mail --",
                                                  "userid": 0
                                                };
                                                let tempErrorMsg = [...requestForErrorMsg];
                                                tempErrorMsg[index].approverEmailMsg = null;
                                                setRequestForErrorMsg(tempErrorMsg);
                                                setApprovalList(tempApprovalList);
                                              }}
                                            />
                                          </div>
                                        </div>
                                    }

                                  </div>
                                  <div className="input-outer-set d-none">
                                    <i className={`icons-md blue cp mt10 mr15 d-block ${isRequestForApproval ? "" : "click-off"} 
                                ${index === 0
                                        ? `${approvalList.length < 3
                                          ? "icon-sd-circle-plus-fill-edge-medium "
                                          : "icon-sd-circle-plus-fill-edge-medium click-off "}`
                                        : "icon-sd-circle-minus-fill-edge-medium red"}`} onClick={() => {
                                          if (index === 0 && approvalList.length < 3) {
                                            let enable = true;
                                            approvalList.forEach((item, index) => {
                                              if (item.approverEmail.userId === 0) {
                                                enable = false;
                                                let tempErrorMsg = [...requestForErrorMsg];
                                                tempErrorMsg[index].approverEmailMsg = APPROVAL_MAIL;
                                                setRequestForErrorMsg(tempErrorMsg)
                                                return;
                                              } else {
                                                let tempErrorMsg = [...requestForErrorMsg];
                                                tempErrorMsg[index].approverEmailMsg = null;
                                                setRequestForErrorMsg(tempErrorMsg)
                                                enable = true;
                                              }
                                            })
                                            if (enable) {
                                              let temp = [...approvalList];
                                              temp.push({
                                                approverEmail: {
                                                  "email": "-- Select approval mail --",
                                                  "userid": 0
                                                },
                                                ismandatory: false,
                                                edmworlflowdetails: 0,
                                                priority: 1,
                                                ApprovalSentcount: 1
                                              });
                                              let tempErrMsg = [...requestForErrorMsg];
                                              tempErrMsg.push(
                                                {
                                                  approverEmailMsg: null
                                                }
                                              )
                                              setApprovalList(temp);
                                              setRequestForErrorMsg(tempErrMsg);
                                            }
                                          } else {
                                            var index1 = approvalList.indexOf(item);
                                            var tempList = [...approvalList];
                                            tempList.splice(index1, 1);
                                            var tempErrMsg = [...requestForErrorMsg];
                                            tempErrMsg.splice(index1, 1);
                                            setApprovalList(tempList);
                                            setRequestForErrorMsg(tempErrMsg);
                                          }
                                        }} />
                                    {approvalSettings.approvalMatchType !== "" && approvalSettings.approvalMatchType.toLowerCase() !== 'all' &&
                                      <RSPTooltip text="Set mandatory" position="top">
                                        <i className={item.ismandatory ? "icon-asterisk-mini red icon-sm mand-field cursor-pointer" : "icon-asterisk-mini icon-sm mand-field cursor-pointer"}
                                          onClick={() => {
                                            if (approvalSettings.approvalMatchCount !== approvalList.filter(obj => obj.ismandatory === true).length) {
                                              item.ismandatory = !item.ismandatory;
                                              let temp = [...approvalList];
                                              temp[index].ismandatory = item.ismandatory;
                                              setApprovalList(temp);
                                            } else if (item.ismandatory) {
                                              item.ismandatory = !item.ismandatory;
                                              let temp = [...approvalList];
                                              temp[index].ismandatory = item.ismandatory;
                                              setApprovalList(temp);
                                            }
                                          }}></i>
                                      </RSPTooltip>}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                          {isRequestForApproval &&
                            <RSPTooltip position="top" text="Approval settings">
                              <i className={`icon-sd-settings-small icons-md blue cursor-pointer mr0 float-end d-none ${isRequestForApproval ? "" : "click-off"}`} onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }} ></i>
                            </RSPTooltip>}
                        </Col>
                        <Col md={3}>
                          <RSPrimaryBtn
                            onClick={() => {
                              let isEnable = false;
                              if (isRequestForApproval) {
                                isEnable = validation();
                              } else {
                                isEnable = validation(true);
                              }
                              if (isEnable === undefined || isEnable) {
                                if (isRequestForApproval) {
                                  let approvarList = [...approvalList].map((item, index) => {
                                    return {
                                      approvarId: item.approverEmail?.userid || "",
                                      approvarName: item.approverEmail.email,
                                      flag: false,
                                      ismandatory: false,
                                      priority: 1,
                                      ApprovalSentcount: 1,
                                    };
                                  });
                                  let approval_object = {
                                    IsFollowHierarchy: false,
                                    matchType: "All",
                                    matchCount: 0,
                                    approvarList: approvarList,
                                  };
                                  mobileSMSNew.requestForApproval = approval_object;
                                }
                                mobileSMSNew.campaignId = campaignId;
                                mobileSMSNew.levelNumber = channelDetails.LevelNumber;
                                mobileSMSNew.smsSchedule.localBlastDateTime = schedule;
                                mobileSMSNew.content[0].timezoneId = timeZone.timezoneId;
                                channelDetails.totalaudience = potentialAudienceCount;
                                let params = createMobileSMSCampaignMDC(
                                  "M",
                                  campaignId,
                                  mobileSMSNew,
                                  senderID,
                                  language.languageId,
                                  schedule,
                                  mobileNo,
                                  countryDialCodeMobile,
                                  channelDetails,
                                  isRequestForApproval ? false : true,
                                  "",
                                  ""
                                );
                                connectServer({
                                  path: smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                                  params,
                                  loading: context.globalStateData.setIsLoadingData,
                                  sessionOut: context.globalStateData.setSessionOutData,
                                  context: context,
                                  ok: (res) => {
                                    if (res.status) {
                                      mobileSMSNew.content[0].smsChannelDetailId = res.data.smsChannelDetailId;
                                      setSmsChannelId(res.data.smsChannelDetailId)
                                      channelDetails.ChannelDetailID = res.data.smsChannelDetailId;
                                      setIsOpenSendSuccessModal(
                                        !isOpenSendSuccessModal
                                      );
                                    }
                                  },
                                  fail: (err) => { },
                                });
                              }
                            }}
                          >
                            Send
                          </RSPrimaryBtn>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
                <div className="btn-container d-flex justify-content-end mt40">
                  <RSSecondaryBtn paddingR="0px" onClick={backToCanvas}>
                    Cancel
                  </RSSecondaryBtn>
                  <RSPrimaryBtn className={disableEdit}
                    onClick={() => {
                      let enable = validation();
                      if (enable === undefined || enable) {
                        if (isRequestForApproval) {
                          let approvarList = [...approvalList].map((item, index) => {
                            return {
                              approvarId: item.approverEmail?.userid || "",
                              approvarName: item.approverEmail.email,
                              flag: false,
                              ismandatory: false,
                              priority: 1,
                              ApprovalSentcount: 1,
                            };
                          });
                          let approval_object = {
                            IsFollowHierarchy: false,
                            matchType: "All",
                            matchCount: 0,
                            approvarList: approvarList,
                          };
                          mobileSMSNew.requestForApproval = approval_object;
                        }
                        mobileSMSNew.campaignId = campaignId;
                        mobileSMSNew.levelNumber = channelDetails.LevelNumber;
                        mobileSMSNew.smsSchedule.localBlastDateTime = schedule;
                        mobileSMSNew.content[0].timezoneId = timeZone.timezoneId;
                        channelDetails.totalaudience = potentialAudienceCount;
                        //channelDetails.targetList = segmentationListId;
                        let params = createMobileSMSCampaignMDC(
                          "M",
                          campaignId,
                          mobileSMSNew,
                          senderID,
                          language.languageId,
                          schedule,
                          mobileNo,
                          countryDialCodeMobile,
                          channelDetails,
                          false,
                          "",
                          ""
                        );
                        if (!props.isUpdate) {
                          connectServer({
                            path:
                              campaignModeAddOrEdit === "edit"
                                ? UPDATE_SMS_CAMPAIGN
                                : smsChannelId !== 0 ? UPDATE_SMS_CAMPAIGN : SAVE_SMS_CAMPAIGN,
                            params,
                            loading: context.globalStateData.setIsLoadingData,
                            sessionOut: context.globalStateData.setSessionOutData,
                            context: context,
                            ok: (res) => {
                              if (res.status) {
                                let baseURL = getServerConfig();
                                let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                                window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${convertObjectToBase64(
                                  campaignId
                                )}&accessToken=${encodeURIComponent(
                                  accessToken
                                )}&from=${fromEnvi}&channelResponseDetailId=${convertObjectToBase64(
                                  res.data.smsChannelDetailId
                                )}&channelType=S`;
                              }
                            },
                            fail: (err) => { },
                          });
                        }
                      }
                    }}
                  >
                    {" "}
                    {campaignModeAddOrEdit === "add"
                      ? "Create SMS content"
                      : "Update SMS content"}
                  </RSPrimaryBtn>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </LayoutPageWrapper>
      {
        isModalOpen && (
          <ApprovalSettings
            isOpen={isModalOpen}
            approvalSettings={approvalSettings}
            onOpenChange={(value) => {
              setIsModalOpen(value);
            }}
          ></ApprovalSettings>
        )
      }
      {
        isSplitSettings && (
          <SplitSettingsModal
            isOpen={isSplitSettings}
            onChangeIsOpen={(value) => {
              setIsSplitSettings(value);
            }}
          />
        )
      }
      {
        isShowSplitSizeModal && (
          <SplitSizeModal
            isOpen={isShowSplitSizeModal}
            onChangeIsOpen={(value) => {
              setIsShowSplitSizeModal(value);
            }}
          />
        )
      }
      {
        isOpenSendSuccessModal && (
          <SuccessModal
            isOkButton={false}
            isOpen={isOpenSendSuccessModal}
            title={"Success"}
            subTitle={isRequestForApproval ? "Communication has been sent successfully for approval." : "Test communication will be sent shortly."}
            buttonClicked={(value) => {
              if (isRequestForApproval) {
                let baseURL = getServerConfig();
                let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${convertObjectToBase64(
                  campaignId
                )}&accessToken=${encodeURIComponent(
                  accessToken
                )}&from=${fromEnvi}&channelResponseDetailId=${convertObjectToBase64(
                  smsChannelId
                )}&channelType=S`;
              } else {
                setIsOpenSendSuccessModal(false);
              }
              setIsOpenSendSuccessModal(false);

            }}
          />
        )
      }
      {
        isOpenSplitResetModal && (
          <RSConfirmAlert
            title="Are you sure, you want to reset it?"
            secondaryButtonText="Cancel"
            primaryButtonText="OK"
            buttonClicked={(value) => {
              if (value) {
                let temp = [...mobileSMSNew.content];
                temp[0].splitType = "";
                temp[0].body = "";
                temp[0].smsChannelDetailId = 0;
                temp[0].timezoneId = timeZone.timezoneId;
                temp[0].localBlastDateTime = "";
                mobileSMSNew.content = [temp[0]];
                mobileSMSNew.isSplitAB = false;
                setIsSplit(!isSplit);
              }
              setIsOpenSplitResetModal(!isOpenSplitResetModal);
            }}
          />
        )
      }
    </>
  );
};
export default withRouter(MdcSmsCreation);
const goalList = [
  { shortCode: "R", name: "Reach" },
  { shortCode: "C", name: "Conversion" },
  { shortCode: "E", name: "Engagement" },
];
