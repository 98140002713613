import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2 } from "Components/RSInputs";

class Threshold extends Component {
  state = {
    isModalOpen: this.props.isOpen,
  };
  handleClose = () => {
    this.props.onChangeIsOpen(false);
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        show={this.state.isModalOpen}
        onHide={() => this.handleClose()}
        size="lg"
        centered
      >
        <Modal.Header>
          <h2>Manage Your Threshold </h2>
          <div className="back-text">
            <i className="icon-sd-close-mini icon-sm blue" onClick={() => {
              this.handleClose();
            }}></i>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>
                Your billing threshold only changes how often you're billed, not
                how much you spend. If you want fewer bills, you can raise your
                threshold. If you want to be billed for smaller amounts, you can
                lower your threshold.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3}>Current threshold:</Col><Col md={3}> $12,000.00</Col>
          </Row>
          <Row style={{ padding: "13px 17px;" }}>
            <Col md={3}>Change threshold to:</Col>
            <Col md={3}>
              <RSInput2 name="volume" ph={"Enter volume"} className="margin-L15 margin-T-15" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <RSSecondaryBtn
            onClick={() => {
              this.handleClose();
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSPrimaryBtn className="click-off" onClick={this.onTrigger}>Change Threshold</RSPrimaryBtn>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Threshold;
