import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap"

import NewCharts from 'Components/Charts/NewCharts';
import activeImg from 'Assets/Images/active.svg'
import worldMap from 'Assets/Images/world-map.png'
import byHours from 'Assets/Images/aud-chart-4.jpg'
import { RTabbar } from 'Components/RChart/RTabbar';
import { ch_chartColor, ch_converted, ch_cyanColor, ch_english, ch_friday, ch_green, ch_identified, ch_known, ch_monday, ch_orange, ch_others, ch_saturday, ch_spanish, ch_sunday, ch_thursday, ch_tuesday, ch_wednesday } from 'Components/Charts/ChartColors';


class MobileLiveDashboard extends Component {

    state = {
        sankeyPortletFullView: false,
        tabBehaviourIndex: 0,
        tabGeographicIndex: 0,
        tabDeviceIndex: 0,
        tabAppOSIndex: 0,
    }
    render() {
        return (
            <>
                    <Row>
                        <Col md={4}>
                            <div className="portlet-container portlet-sm">
                                <div className="portlet-header">
                                    <h4>User status</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-sm-body-height">
                                        <div className="v-center-inner">
                                            <img src={activeImg} alt={activeImg} width="120" />
                                            <div className="two-label-container">
                                                <div className="two-label">
                                                    <p>Total installation</p>
                                                    <h2 className="user-count">22,237</h2>
                                                </div>
                                                <div className="two-label">
                                                    <p>Live users</p>
                                                    <h2 className="user-count">2,477</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Daily status</span>)</div>
                            </div>
                        </Col>

                        {/* Traffic breakdown by active users */}
                        <Col md={8}>
                            <div className="portlet-container portlet-sm">
                                <div className="portlet-header">
                                    <h4>Traffic breakdown by active visitors</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={trafficBreakdownChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 7 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Key metrics */}
                        <Col md={4}>
                            <div className="portlet-container keymetrics-portlet keymetrics-sm">
                                <h4>Key metrics</h4>
                                <ul className="keymetrics-list key-border">
                                    <li>
                                        <h3>152,648</h3>
                                        <small>Sessions</small>
                                    </li>
                                    <li>
                                        <h3>424,968</h3>
                                        <small>Screen views</small>
                                    </li>
                                </ul>
                                <ul className="keymetrics-list keymetrics-theme">
                                    <li className="bgBlue-lighter">
                                        <h3>2m 23s</h3>
                                        <small>Avg time spent</small>
                                    </li>
                                    <li className="bgBlue-light">
                                        <h3>3</h3>
                                        <small>Avg screen/session</small>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        {/* Active users */}
                        <Col md={8}>
                            <div className="portlet-container keymetrics-portlet keymetrics-sm">
                                <h4>Active users</h4>
                                <ul className="keymetrics-list key-border">
                                    <li> <h3>1,097</h3> <span>Avg. DAU</span> </li>
                                    <li> <h3>4,397</h3> <span>WAU</span> </li>
                                    <li> <h3>9,550</h3> <span>MAU</span> </li>
                                </ul>
                                <ul className="keymetrics-list keymetrics-theme">
                                    <li className="bgMaroon-light"> <h3>96</h3> <small>In-app errors</small> </li>
                                    <li className="bgMaroon-medium"> <h3>15</h3> <small>Crashes</small> </li>
                                    <li className="bgMaroon-dark"> <h3>12</h3> <small>Uninstalls</small> </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* User types */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>User types</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={audienceTypesChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* User types */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>User types</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={knownConversion} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Retention */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Retention</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <table className="table table-striped table-hover st-grid-knownUser">
                                            <thead>
                                                <tr>
                                                    <th data-field="tlca_header_sno">Date</th>
                                                    <th data-field="tlca_link">Known</th>
                                                    <th data-field="tlca_total_clicks">1 day</th>
                                                    <th data-field="tlca_total_clicks1">2 day</th>
                                                    <th data-field="tlca_total_clicks2">3 day</th>
                                                    <th data-field="tlca_total_clicks3">4 day</th>
                                                    <th data-field="tlca_total_clicks4">5 day</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID1days">{ }</span></td>
                                                    <td>20</td>
                                                    <td>10.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>35.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>30.00</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>15.00</td>
                                                    <td style={{ backgroundColor: "#fd8f40ad" }}>25.00</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID2days">{ }</span></td>
                                                    <td>24</td>
                                                    <td style={{ backgroundColor: "#fd8f40d1" }}>37.50</td>
                                                    <td style={{ backgroundColor: "#fd8f40" }}>50.00</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>20.83</td>
                                                    <td>9.17</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>16.67</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID3days">{ }</span></td>
                                                    <td>29</td>
                                                    <td style={{ backgroundColor: "#fd8f408f" }}>20.69</td>
                                                    <td>0.00</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>13.79</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>17.24</td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID4days">{ }</span></td>
                                                    <td>46</td>
                                                    <td style={{ backgroundColor: "#fd8f40b5" }}>32.61</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>19.57</td>
                                                    <td style={{ backgroundColor: "#fd8f404f" }}>13.04</td>
                                                    <td>4.35</td>
                                                    <td>2.17</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="mDatesubtractGRID5days">{ }</span></td>
                                                    <td>57</td>
                                                    <td style={{ backgroundColor: "#fd8f40ad" }}>24.56</td>
                                                    <td style={{ backgroundColor: "#fd8f4070" }}>19.30</td>
                                                    <td>1.75</td>
                                                    <td>1.75</td>
                                                    <td>1.75</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ul className="legendList">
                                            <li className="orange-light knownUserAnal">Known</li>
                                            <li className="green identifiedUserAnal">Identified</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Progressive profile */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Progressive profile</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <NewCharts options={progressiveProfileChart} />
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Top screen views */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Top screen views</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <ul className="portlet-list">
                                            <li>
                                                <div>My account</div>
                                                <div className="percentage"><span>89,942</span></div>
                                            </li>
                                            <li>
                                                <div>Bill pay & recharge</div>
                                                <div className="percentage"><span>62,706</span></div>
                                            </li>
                                            <li>
                                                <div>Apply now</div>
                                                <div className="percentage"><span>58,537</span></div>
                                            </li>
                                            <li>
                                                <div>Locate branches & ATM</div>
                                                <div className="percentage"><span>28,603</span></div>
                                            </li>
                                            <li>
                                                <div>Contact us</div>
                                                <div className="percentage"><span>21,942</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Top event summary */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md">
                                <div className="portlet-header">
                                    <h4>Top event summary</h4>
                                </div>
                                <div className="portlet-body">
                                    <div className="portlet-chart">
                                        <ul className="portlet-list">
                                            <li>
                                                <p>Apply credit card</p>
                                                <div className="percentage">
                                                    <span>23,236</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Extend limit</p>
                                                <div className="percentage">
                                                    <span>21,849</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Apply home loan</p>
                                                <div className="percentage">
                                                    <span>19,647</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Pay bill</p>
                                                <div className="percentage">
                                                    <span>16,838</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                            <li>
                                                <p>Apply car loan</p>
                                                <div className="percentage">
                                                    <span>13,871</span>
                                                    <i className="icon-arrow-up-small icons-md green-medium"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Operating sytem / Device */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-text">
                                <div className="portlet-header">
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabDeviceIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataDevice}
                                            callBack={(item, index) => { this.setState({ tabDeviceIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {
                                        this.state.tabDeviceIndex === 0 ? 
                                        <div className="portlet-chart"><NewCharts options={operatingSystemChart} /></div>:
                                        <div className="portlet-chart"><NewCharts options={devicesChart} /></div>
                                    }
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* App versions / OS versions */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-text">
                                <div className="portlet-header">
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabAppOSIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataAppOS}
                                            callBack={(item, index) => { this.setState({ tabAppOSIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {
                                        this.state.tabAppOSIndex === 0 ? 
                                        <div className="portlet-chart"><NewCharts options={appVersionsChart} /></div>:
                                        <div className="portlet-chart"><NewCharts options={osVersionsChart} /></div>
                                    }
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        {/* Geographic | Location / App language */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-label portlet-footer-text">
                                <div className="portlet-header">
                                    <h4>Geographic</h4>
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabGeographicIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataGeographic}
                                            callBack={(item, index) => { this.setState({ tabGeographicIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {
                                        this.state.tabGeographicIndex === 0 ? 
                                        <div className="portlet-image"><img src={worldMap} alt={worldMap} /></div> :
                                        <div className="portlet-chart"><NewCharts options={appLanguageChart} /></div>
                                    }
                                </div>
                                <div className="portlet-two-label">
                                    <ul>
                                        <li><span>{ this.state.tabGeographicIndex === 0 ? '52' : '88' }</span><small>%</small></li>
                                        <li>
                                            {
                                                this.state.tabGeographicIndex === 0 ? 
                                                'of users from North America have highest engagement rate':
                                                'of the users install the app in spanish language'
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>

                        {/* Usage behaviour | By days / By hours */}
                        <Col md={6}>
                            <div className="portlet-container portlet-md portlet-footer-label portlet-footer-text">
                                <div className="portlet-header">
                                    <h4>Usage behaviour</h4>
                                    <div className="p-nav-right">
                                        <RTabbar
                                            defaultSelectedItem={this.state.tabBehaviourIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="mini marginB0"
                                            activeClass="tabDefault active"
                                            tabData={tabDataBehaviour}
                                            callBack={(item, index) => { this.setState({ tabBehaviourIndex: index }) }}
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {
                                        this.state.tabBehaviourIndex === 0 ? 
                                        <div className="portlet-chart"><NewCharts options={byDaysChart} /></div> :
                                        <div className="portlet-image"><img src={byHours} alt={byHours} /></div>
                                    }
                                </div>
                                <div className="portlet-two-label">
                                    <ul>
                                        <li><span>{ this.state.tabBehaviourIndex === 0 ? '18' : '62' }</span><small>%</small></li>
                                        <li>
                                            {
                                                this.state.tabBehaviourIndex === 0 ? 
                                                'of the users engage with the website on Sunday':
                                                'of the visitors engage during 12 PM to 15 PM'
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="portlet-info-text">(As on: <span>Last 30 days</span>)</div>
                            </div>
                        </Col>
                    </Row>

                </>
        )
    }
}

export default MobileLiveDashboard;

const tabDataBehaviour= [
    { "id": 1001, "text": "By days" },
    { "id": 1002, "text": "By hours" }
]
const tabDataGeographic = [
    { "id": 1001, "text": "Location" },
    { "id": 1002, "text": "App language" }
]
const tabDataDevice = [
    { "id": 1001, "text": "Operating sytem" },
    { "id": 1002, "text": "Device" }
]
const tabDataAppOS = [
    { "id": 1001, "text": "App versions" },
    { "id": 1002, "text": "OS versions" }
]

// Audience types -- bar
const trafficBreakdownChart = {
    chart: {
        type: 'area'
    },
    title: {
        text: ''
    },
    xAxis: {
        title: {
            text: 'Date'
        },
        categories: [
            '6th Oct', // '2021-10-06',
            '7th Oct', // '2021-10-07',
            '8th Oct', // '2021-10-08',
            '9th Oct', // '2021-10-09',
            '10th Oct', // '2021-10-10',
            '11th Oct', // '2021-10-11'
            '12th Oct', // '2021-10-12'
        ]
    },
    yAxis: {
        title: {
            text: 'Count'
        }
    },
    legend: {
        enabled: false,
    },
    series: [
        {
            name: 'Date',
            data: [800, 350, 900, 1800, 900, 1470, 1270]
        }
    ],
}
// Traffic breakdown by active visitors -- area
const audienceTypesChart = {
    chart: { type: 'bar' },
    xAxis: {
        title: { text: '% Audience', },
        categories: ['Value'],
        labels: {
            enabled: false
        }
    },
    yAxis: {
        title: { text: '', },
        tickInterval: 20,
        labels: { format: '{value}%', }
    },
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}% </br> </b>'
        // shared: false
    },
    legend: {
        reversed: true
    },
    plotOptions: {
        series: {
            stacking: 'normal'
        }
    },
    series: [{
        name: 'Identified',
        data: [55],
        color: ch_identified
    }, {
        name: 'Known',
        data: [45],
        color: ch_known
    }]
}
// Known to conversion -- pyramid
const knownConversion = {
    chart: {
        type: 'pyramid',
        // style: { fontFamily: 'mukta-regular', fontWeight: 'normal', fontSize: '16px', color: '#585858' }
    },
    title: {
        text: '',
        x: -50
    },
    credits: {
        enabled: false
    },
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b>k+<br/>',
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                color: "#333",
                style: { color: ch_chartColor, fontSize: '14px', fontWeight: 'normal' }
            },
            center: ['40%', '50%'],
            width: '70%'
        }, pyramid: { reversed: false }
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Value', borderWidth: 5,
        data: [
            { name: 'Known users', y: 203, color: ch_known },
            { name: 'Identified users', y: 144, color: ch_identified },
            { name: 'Converted', y: 108, color: ch_converted },
        ]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        'stroke-width': 10,
                        dataLabels: {
                            inside: true
                        },
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}
// Progressive profile -- pyramid
const progressiveProfileChart = {
    chart: {
        type: 'pyramid',
        // style: { fontFamily: 'mukta-regular', fontWeight: 'normal', fontSize: '16px', color: '#585858' }
    },
    title: {
        text: '',
        x: -50
    },
    credits: {
        enabled: false
    },
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b>k<br/>',
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                color: "#333",
                style: { color: ch_chartColor, fontSize: '15px', fontWeight: 'normal' }
            },
            center: ['40%', '50%'],
            width: '70%'
        }
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Value',
        data: [
            { name: 'Demographic', y: 2, color: "#66c7ea" },
            { name: 'Engagement', y: 2, color: "#5ab5e6" },
            { name: 'Interest', y: 2, color: "#4e9ce0" },
            { name: 'Social share', y: 2, color: "#3568c6" },
            { name: 'Product propensity', y: 2, color: "#132385" }
        ]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            inside: true
                        },
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}
// Operating sytem -- pie
const operatingSystemChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "Andriod", color: "#4885ed", y: 78 },
                { name: "iOS", color: "#99cc03", y: 23 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            name: "Total",
            borderWidth: 0,
            size: "50%",
            shadow: false
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// App versions -- pie
const appVersionsChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "v1.0", color: ch_orange, y: 31 },
                { name: "v2.0", color: ch_cyanColor, y: 21 },
                { name: "v3.0", color: ch_green, y: 12 },
                { name: "Others", color: ch_others, y: 36 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            name: "Total",
            borderWidth: 0,
            size: "50%",
            shadow: false
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// Os versions -- pie
const osVersionsChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "Android 1.9.11", color: "#99cc03", y: 55 },
                { name: "Android 1.9.10", color: "#aadd14", y: 18 },
                { name: "iOS 1.8.2", color: "#585858", y: 14 },
                { name: "iOS 1.8.1", color: "#1AD6FD", y: 13 },
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            name: "Total",
            borderWidth: 0,
            size: "50%",
            shadow: false
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// Devices -- pie
const devicesChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "OnePlus", color: "#eb7f82", y: 20 },
                { name: "Samsung", color: "#6ec0c3", y: 15 },
                { name: "Redmi", color: "#b785ce", y: 20 },
                { name: "iPhone", color: "#f0aa6d", y: 30 },
                { name: "Others", color: "#ecd788", y: 15 },
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            name: "Total",
            borderWidth: 0,
            size: "50%",
            shadow: false
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// App language -- pie
const appLanguageChart = {
    chart: {
        type: "pie"
    },
    legend: {
        itemMarginTop: 15,
        itemMarginBottom: 0
    },
    series: [
        {
            name: "Total",
            showInLegend: true,
            borderWidth: 2,
            height: "100%",
            innerSize: "50%",
            size: "100%",
            data: [
                { name: "English", color: ch_english, y: 7 },
                { name: "Spanish", color: ch_spanish, y: 88 },
                { name: "Others", color: ch_others, y: 5 }
            ],
            shadow: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'crookedLine', // crookedLine, fixedOffset, straight
                crookDistance: '100%',
                softConnector: false
            }
        },
        {
            name: "Total",
            borderWidth: 0,
            size: "50%",
            shadow: false
        },
        {
            borderWidth: 0,
            innerSize: "89%",
            size: "53%",
            data: [{ color: "rgba(0, 0, 0, 0.15)", y: 100 }],
            shadow: false,
            showInLegend: false,
            enableMouseTracking: false,
            dataLabels: { enabled: false }
        }
    ]
}
// By days -- Column
const byDaysChart = {
    chart: {
        type: 'column',
    },
    xAxis: {
        title: { text: 'Days', },
        categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        tickInterval: 1,
    },
    plotOptions: {
        column: {
            stacking: false,
            colorByPoint: true
        }
    },
    colors: [ch_sunday, ch_monday, ch_tuesday, ch_wednesday, ch_thursday, ch_friday, ch_saturday],
    tooltip: {
        formatter: function () {
            return this.x + '<div style="text-align:center;font-weight:400;font-size: 19px;">' + this.y + '</div>';
        }
    },
    yAxis: {
        title: { text: 'Engagement ( % )', },
        labels: {
            formatter: function () {
                return this.axis.defaultLabelFormatter.call(this) + '%';
            }
        },
        tickInterval: 15,
    },
    series: [
        {
            showInLegend: false,
            name: 'Campaign period',
            pointWidth: 25,
            data: [25, 8, 13.5, 8, 13, 12.5, 20],
            legendIndex: 0, states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    enabled: false,
                }
            }
        }
    ]
}
// Operating sytem -- pie
// Product analytics -- sunburst
// By Interests -- pie 2
// Progressive profile -- pyramid
// By device -- pie
// By browser -- pie
// By days -- column
