import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

import Icon_move from "Assets/Images/icon-move.png";
import { RSPTooltip } from "Components/Tooltip";
import { RSPrimaryBtn } from "Components/RSButtons";
import * as images from "Assets/Images";

const HowerIcon = ({ data }) => {
  const { removeContent, dragChange, ind } = data;

  const [warningMsg, setWarningMsg] = React.useState(false);

  const handleDelete = () => {
    removeContent(ind);
    handleClose();
  };

  const handleClose = (status) => {
    if (!status) {
      setWarningMsg(status);
    }
  };

  const removeHoverIconsClass = () => {
    const hoverIcons = document.querySelectorAll('.hovweIcons');
    hoverIcons.forEach(icon => {
      // Check if the image source is empty
      if (!icon.src) {
        icon.remove();
      }
    });
  };
  

  React.useEffect(() => {
    removeHoverIconsClass();
  }, []);

  return (
    <React.Fragment>
      <RSPTooltip text="Move">
        <img
          className={` hovweIcons color-secondary-blue icon-md left-10 position-absolute top-10 test pop`}
          src={Icon_move}
          onDragStart={(event) => {
            dragChange(event, ind);
          }}
          draggable
        />
      </RSPTooltip>
      <RSPTooltip className={'hovweIcons'} text="Delete">
        <img
          className={` hovweIcons color-primary-red icon-md right-6 position-absolute top-10 pop`}
          src={images.IconTempDelete}
          onClick={() => {
            setWarningMsg(true);
          }}
          draggable
        />
      </RSPTooltip>

      <Modal
        backdrop="static"
        show={warningMsg}
        centered
        className="logged-in-otp"
        onHide={() => {
          handleClose(false);
        }}
      >
        <Modal.Header closeButton>
          <h2>Delete</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className=" ">
              <label>Are you sure want to delete</label>
            </Row>
            <div className="buttons-holder">
              <Row>
                <Col className="align-center col d-flex justify-content-end mt30">
                  <RSPrimaryBtn onClick={handleDelete}>Delete</RSPrimaryBtn>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(HowerIcon);
