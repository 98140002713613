import React, { useContext, useEffect, useState } from "react"
import { Grid, GridColumn, GridColumnMenuFilter } from "@progress/kendo-react-grid"
import { GET_USER_LIST, USER_STATUS_CHANGE } from "Helper/Constants/endPoint"
import { connectServer } from "Helper/Network/networkHandler"
import { Switch } from "@progress/kendo-react-inputs"
import authContext from "Helper/StateHandler/auth-context"
import { getAuthClientId } from 'Helper/Utils/Utils'
import SkeletonTable from "Components/Skeleton/SkeletonTable"
import { RSPTooltip } from "Components/Tooltip"

const initialDataState = {
    skip: 0,
    take: 5,
}

const UserDetails = props => {

    const context = useContext(authContext)
    const [page, setPage] = useState(initialDataState)
    const [usersList, setUsersList] = useState([])
    const [listError, setListError] = useState(false)

    const StatusCellComponent = (props) => {
        return (
            <StatusCell onStatusUpdate={(value) => { getUserList() }} {...props} />
        )
    }

    const NameComponent = (props) => <NameCell {...props} />

    useEffect(() => {

        if (context.globalStateData.getIsPrototype) {
            return
        }
        getUserList()
        /* const params = {
             userID: "3",
             clientID: "1925",
             tenantID: "",
             recordLimit: "5",
             cursorPosition: "1"
         }
         context.globalStateData.setIsLoadingData(true)
         postDataToServer(BASE_URL1, GET_USER_LIST, params, (data => {
             context.globalStateData.setIsLoadingData(false)
             if(data.objUsersListReturn) {
                 RSLog("ärray", data.objUsersListReturn, true)
                 setJsonData(data.objUsersListReturn)
             }
         }), (error => {
             context.globalStateData.setIsLoadingData(false)
         }))*/

    }, [context.globalStateData.getIsReloadData])

    const getUserList = () => {
        setListError(false)
        const params = { "clientId": getAuthClientId() };
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            path: GET_USER_LIST,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setUsersList(res.data)
                }
                else {
                    setListError(true)
                }
            },
            fail: err => {
                setListError(true)
            }
        })
    }

    const pageChange = (e) => {
        setPage(e.page);
    }

    return <>
        <div className="page-header box-bottom-space settings-container">
            <div className="d-flex justify-content-between marginT15 marginB15">
                <h4 className="mb0 add-title">User details</h4>
                <RSPTooltip text='Add' position="top">
                    <i className="icon-sd-circle-plus-fill-edge-medium icons-lg blue float-end cursor-pointer"
                        onClick={() => { props.cb(2) }}
                    />
                </RSPTooltip>
            </div>
            <div className="marginT15 marginB25 w-100">
                {usersList?.length ?
                    <Grid
                        data={usersList && usersList.slice(page.skip, page.take + page.skip)}
                        skip={page.skip}
                        take={page.take}
                        total={usersList && usersList.length}
                        scrollable="none"
                        className="grid-pagenation-del grid-replaceable"
                        onPageChange={pageChange}
                        pageable={{
                            //type: 'input',
                            pageSizes: [5, 10, 20],
                            previousNext: true,
                        }}
                    >
                        <GridColumn columnMenu={ColumnMenu} field="firstName" title="Name" cell={NameComponent} />
                        <GridColumn columnMenu={ColumnMenu} field="jobFunctionName" title="Job function" />
                        <GridColumn columnMenu={ColumnMenu} field="role" title="Role" />
                        <GridColumn field="status" title="Status" cell={StatusCellComponent} />
                    </Grid> : <SkeletonTable text='to add user' count={5} isError={listError} />}
            </div>
        </div>
    </>
}

export default UserDetails

const NameCell = props => {
    return <td>
        <div className="d-flex justify-content-between">
            <span className="marginB0 truncate400">{`${props.dataItem.firstName} ${props.dataItem.lastName}`}</span>
            {props.dataItem.isKeyperson && <i className={`icon-sd-crown-fill-medium icons-md cursor-pointer ${props.dataItem.isKeyperson ? "orange" : "grey"}`}></i>}
        </div>
    </td>
}
const StatusCell = props => {
    const context = useContext(authContext)
    const [checked, setChecked] = useState(props.dataItem.statusId === 1 ? true : false)

    return <td>
        <div className="col-md-3">
            <Switch
                className="mt0"
                checked={checked}
                onChange={(value) => {
                    setChecked(!checked)
                    const params = {
                        "userId": props.dataItem.userId,
                        "status": !checked ? "Active" : "Inactive"
                    }
                    connectServer({
                        loading: context.globalStateData.setIsLoadingData,
                        path: USER_STATUS_CHANGE,
                        params,
                        context: context,
                        ok: res => {
                            if (res.status) {
                                props.onStatusUpdate(checked)
                            }
                        },
                        fail: err => {
                        }
                    })
                }}
            />
        </div>
    </td>
}
const ColumnMenu = props => {
    return <div>
        <GridColumnMenuFilter {...props} expanded={true} />
    </div>;
};