import React, { useState } from 'react';
import { Modal, Row, Col } from "react-bootstrap";
import { RSCheckbox } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2 } from 'Components/RSInputs';

const InsertHyperlinkModal = props => {
    const [isShow, setIsShow] = useState(props.isOpen);
    const [webAddress, setWebAddress] = useState("");
    const [text, setText] = useState("");
    const [toolTip, setTooltip] = useState("");
    const [isOpenNewWindow, setIsOpenNewWindow] = useState(false);
    return (
        <Modal backdrop="static"
            keyboard={false} show={isShow} centered onHide={() => {
                setIsShow(!isShow);
                props.onChangeIsOpen(false);
            }}>
            <Modal.Header closeButton>
                <h2>Insert hyperlink</h2>
            </Modal.Header>
            <Modal.Body>
                <Row className="custom-column">
                    <Col md={4}>
                        <label>Web address</label>
                    </Col>
                    <Col md={8}>
                        <RSInput2
                            ph="Enter web address"
                            val={webAddress}
                            cb={(text) => {
                                setWebAddress(text);
                            }} />
                    </Col>
                </Row>
                <Row className="custom-column">
                    <Col md={4}>
                        <label>Text</label>
                    </Col>
                    <Col md={8}>
                        <RSInput2
                            ph="Enter text"
                            val={text}
                            cb={(text) => {
                                setText(text);
                            }} />
                    </Col>
                </Row>
                <Row className="custom-column">
                    <Col md={4}>
                        <label>Tooltip</label>
                    </Col>
                    <Col md={8}>
                        <RSInput2
                            ph="Enter tooltip"
                            val={toolTip}
                            cb={(text) => {
                                setTooltip(text);
                            }} />
                    </Col>
                </Row>
                <Row className="custom-column">
                    <Col md={4}>
                    </Col>
                    <Col md={8}>
                        <RSCheckbox
                            className="marginB0"
                            lbl="Open link in new window"
                            checked={isOpenNewWindow}
                            cb={(status) => {
                                setIsOpenNewWindow(status);
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-container marginT20">
                    <RSSecondaryBtn
                        onClick={() => {
                            setIsShow(!isShow);
                            props.onChangeIsOpen(false);
                        }}>Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn
                        onClick={() => {
                            setIsShow(!isShow);
                            props.onChangeIsOpen(false);
                        }}>Insert
                    </RSPrimaryBtn>
                </div>
            </Modal.Footer>

        </Modal>
    )
}
export default InsertHyperlinkModal;