import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import authContext from 'Helper/StateHandler/auth-context'
import { RSCheckbox, RSTextInputWithIcon } from 'Components/RSInputs'
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons'
import {
    IS_EMAIL_EXIST,
    LOGIN,
    LOGIN_WITH_OTP
} from 'Helper/Constants/endPoint'
import { connectServer } from 'Helper/Network/networkHandler'
import { isEmpty, validateEmail, validatePassword, convertObjectToBase64, getLowerCasedNoSpaceString, convertBase64ToObject, removeSpace, getSourceType } from 'Helper/Utils/Utils'
import {
    ENTER_BUSINESS_EMAIL,
    ENTER_PASSWORD,
    ENTER_VALID_BUSINESS_EMAIL,
    ENTER_VALID_PASSWORD,
    MAXIMUM_LIMIT
} from 'Helper/Constants/validationMessage'
import passwordIcon from 'Assets/Images/icons/icon-password.svg'
import RSPLoader from 'Components/RSPLoader'
import {
    MAX_EMAIL,
    MAX_PASSWORD,
    MAX_LOGIN_OTP
} from 'Helper/Constants/inputLimit'
import emailIcon from 'Assets/Images/icons/icon-email.svg'
import * as Icons from 'Constants/GlobalConstant/Glyphicons/Glyphicons-v5.0'
import { RSPTimer } from 'Components/RSPTimer'

export const PopupLogin = props => {
    const context = useContext(authContext)
    const [emailId, setEmailId] = useState('')
    const [password, setPassword] = useState('')
    const [checked, setChecked] = useState(false)

    const [isOtpRequired, setOtpRequired] = useState(false)
    const [mobData, setMobData] = useState('')
    const [isShowSpinner, setIsShowSpinner] = useState(false)

    const [errorMessage, setErrorMessage] = useState({
        email: null,
        passwd: null
    })

    useEffect(() => {
        let login = localStorage.getItem('login')
        if (login !== null) {
            let loginObj = convertBase64ToObject(login)
            setEmailId(loginObj.email)
            setPassword(loginObj.passwd)
            setChecked(loginObj.checked)
        }
    }, [])

    const checkEmailExistInServer = (response) => {
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            path: IS_EMAIL_EXIST,
            params: { emailId: emailId },
            retry: 2,
            retryTime: 5000,
            ok: data => {
                // setIsEmailExist(data.status)
                if (!data.status) {
                    var errMsg = { ...errorMessage }
                    errMsg.email = data.message
                    setErrorMessage(errMsg)
                    return
                }
                context.globalStateData.setSourceTypeData(data?.data?.sourceType !== "" ? data?.data?.sourceType : "D")
                response(data.status)
            },
            fail: err => {
                var errMsg = { ...errorMessage }
                errMsg.email = "Unexpected happened. Try again later"
                setErrorMessage(errMsg)
                response(false)
            }
        })
    }

    return (
        <div className='form-login-cam'>
            {isShowSpinner ? <RSPLoader className="login-loader" /> : null}
            <div>
                <RSTextInputWithIcon
                    name='email'
                    ph={ENTER_BUSINESS_EMAIL}
                    val={emailId}
                    required={true}
                    // img={emailIcon}
                    icon={'icon-sd-email-medium icons-md'}
                    errorMessage={errorMessage.email}
                    max={MAX_EMAIL}
                    ob={value => {
                        if (validateEmail(value)) {
                            checkEmailExistInServer((res) => {

                            })
                            setErrorMessage({ ...errorMessage, ...{ email: null } })
                        } else {
                            setErrorMessage({
                                ...errorMessage,
                                ...{ email: ENTER_VALID_BUSINESS_EMAIL }
                            })
                            return
                        }
                    }}
                    cb={t => {
                        let _emailId = getLowerCasedNoSpaceString(t)
                        setErrorMessage({ ...errorMessage, ...{ email: null } })
                        if (_emailId.length <= 100) {
                            setEmailId(_emailId)
                        } else {
                            setErrorMessage({
                                ...errorMessage,
                                ...{ email: MAXIMUM_LIMIT }
                            })
                            return
                        }
                    }}
                ></RSTextInputWithIcon>
                <RSTextInputWithIcon
                    name='password'
                    type='password'
                    ph={ENTER_PASSWORD}
                    val={password}
                    required={true}
                    // img={passwordIcon}
                    // icon={`${Icons.key_medium} icons-md`}
                    errorMessage={errorMessage.passwd}
                    max={MAX_PASSWORD}
                    eye
                    cb={t => {
                        let pwd = removeSpace(t)
                        setPassword(pwd)
                        if (validatePassword(pwd)) {
                            setErrorMessage({ ...errorMessage, ...{ passwd: null } })
                        } else {
                            setErrorMessage({ ...errorMessage, ...{ passwd: ENTER_VALID_PASSWORD } })
                            return
                        }
                    }}
                ></RSTextInputWithIcon>
                <RSCheckbox
                    className='font-xsm marginB19'
                    checked={checked}
                    cb={status => setChecked(status)}
                    lbl='Stay signed in'
                />
                <RSLoginBtnContainer

                    isHideLoader={props.isHideLoader}
                    email={emailId}
                    passwd={password}
                    checked={checked}
                    setIsShowSpinner={value => setIsShowSpinner(value)}
                    isOtpRequiredData={data => {
                        // alert(isOtpRequired)
                        setMobData(data)
                        setOtpRequired(true)
                    }}
                    setLoginErrMsg={msg => {
                        setErrorMessage({
                            ...errorMessage,
                            ...msg
                        })
                        // setErrorMessage({ ...errorMessage, ...{ email: msg } })
                        // setTimeout(() =>{
                        //   setErrorMessage({ ...errorMessage, ...{ email: null } })
                        // },2000)
                    }}
                    setErrorMessage={value => {
                        setErrorMessage({ ...errorMessage, ...value })
                    }}
                    isLogin={(res) => {
                        if (res.data.accessToken && res.data.accessToken.length > 0) {
                            const sessionObject = {
                                isAuth: true,
                                accessToken: res.data.accessToken
                            }
                            localStorage.setItem('session', JSON.stringify(sessionObject))

                            localStorage.setItem("lsUserData", convertObjectToBase64(res.data))
                            // localStorage.setItem("lsConfig", JSON.stringify(lsConfig))
                            context.globalStateData.setLoggedInUserData(res.data)
                            context.globalStateData.setEmailIdData(props.email)
                            context.toggleAuth(true)
                            context.globalStateData.setSessionOutData(false)
                        }
                    }}
                />
                <p className='text-orange font-xsm text-center mt10'>
                    Your session expired. Login to continue
                </p>

            </div>

            {isOtpRequired ? (
                <RSOTP
                    setIsShowSpinner={value => setIsShowSpinner(value)}
                    close={() => setOtpRequired(false)}
                    mobData={mobData}
                    isOtpShow={status => setOtpRequired(status)}
                    email={emailId}
                    passwd={password}
                    isHideLoader={props.isHideLoader}
                />
            ) : null}
        </div>
    )
}

const RSLoginBtnContainer = props => {
    const { checked, email, passwd, setLoginErrMsg, setIsShowSpinner } = props
    const context = useContext(authContext)

    const validateLogin = () => {
        let sourceType = getSourceType()
        const params = { emailId: email, password: passwd, sourceType: sourceType }
        setIsShowSpinner(true)

        connectServer({
            path: LOGIN,
            params,
            loading: props.isHideLoader
                ? null
                : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                setIsShowSpinner(false)
                if (res.status && res.isOtpRequired) {
                    props.isOtpRequiredData(res.data)
                } else if (res.status && res.message.toLowerCase().includes('login')) {
                    props.isLogin(res)
                } else {
                    props.setLoginErrMsg({ email: res.message })
                }
            },
            fail: error => {
                setIsShowSpinner(false)
                context.toggleAuth(false)
                setLoginErrMsg({ email: 'Unexpected error. Try again later' })
            }
        })
    }

    return (
        <div className='text-right'>
            <RSPrimaryBtn
                className='w-100 marginT10'
                onClick={() => {
                    if (props.email.length === 0) {
                        props.setErrorMessage({ email: ENTER_VALID_BUSINESS_EMAIL })
                        return
                    }
                    if (!validateEmail(props.email)) {
                        props.setErrorMessage({ email: ENTER_VALID_BUSINESS_EMAIL })
                        return
                    }
                    if (props.passwd.length === 0) {
                        props.setErrorMessage({ passwd: ENTER_PASSWORD })
                        return
                    } else if (!validatePassword(props.passwd)) {
                        props.setErrorMessage({ passwd: ENTER_VALID_PASSWORD })
                        return
                    }

                    props.setErrorMessage({ email: '' })

                    if (checked) {
                        saveLogin(email, passwd, checked)
                    } else {
                        localStorage.removeItem('login')
                    }
                    validateLogin()
                }}
            >
                Sign In
            </RSPrimaryBtn>
        </div>
    )
}

const saveLogin = (email, passwd, checked) => {
    let login = { email: email, passwd: passwd, checked: checked }
    localStorage.setItem('login', convertObjectToBase64(login))
}

const RSOTP = props => {
    const history = useHistory()
    const [otp, setOtp] = useState('')
    const [otpToken, setOtpToken] = useState(props.mobData)
    const [otpErr, setOtpErr] = useState(null)
    const [otpSuccess, setOtpSuccess] = useState(false)
    const [resetTimer, setResetTimer] = useState(true)
    const [isShowResendBtn, setIsShowResendBtn] = useState(false)
    const [otpDefault, setOtpDefault] = useState(true)
    const context = useContext(authContext)

    const validateOTP = (text) => {

        if (isEmpty(text) || isEmpty(otpToken)) {
            setOtpErr('Please enter valid OTP')
            return
        }
        const params = {
            otpToken: otpToken,
            Otp: text
        }
        props.setIsShowSpinner(true)

        connectServer({
            path: LOGIN_WITH_OTP,
            params,
            loading: props.isHideLoader
                ? null
                : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                props.setIsShowSpinner(false)

                if (res.status) {
                    setOtpSuccess(true)
                    checkData(res?.data)
                    props.isOtpShow(false)
                    if (res.data.accessToken && res.data.accessToken.length > 0) {
                        const sessionObject = {
                            isAuth: true,
                            accessToken: res.data.accessToken
                        }
                        localStorage.setItem('session', JSON.stringify(sessionObject))
                        localStorage.setItem("lsUserData", convertObjectToBase64(res.data))
                        localStorage.setItem("lsConfig", JSON.stringify(lsConfig))
                        context.globalStateData.setLoggedInUserData(res.data)
                        context.globalStateData.setEmailIdData(props.email)
                        // context.toggleAuth(res.status)
                        // window.location.reload()
                        history.push('/');
                        history.go(0); // Forces a refresh
                        context.globalStateData.setSessionOutData(false)
                    }
                } else {
                    props.setIsShowSpinner(false)

                    setOtpErr(res?.message)
                    setOtpSuccess(false)
                }
            },
            fail: err => {
                setOtpErr(err.message)
                props.setEmailErr(err.message)
                setOtpSuccess(false)
            }
        })
    }

    const checkData = data => {

        if (data.data?.dateFormat && data.data?.timeZoneName) {
        } else {
            if (data.data?.clientDetailsLocalization?.dateFormat && data.data?.clientDetailsLocalization?.timeZoneName) {

            } else {

            }
        }
    }
    const resendOtp = () => {
        let sourceType = getSourceType()
        const params = { emailId: props.email, password: props.passwd, sourceType: sourceType }
        setResetTimer(false)
        connectServer({
            path: LOGIN,
            params,
            loading: props.isHideLoader ? null : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                setOtpToken(res.data)
                setOtpErr('OTP sent successfully')
                setResetTimer(true)
                resetCounter()
            },
            fail: error => {
                setResetTimer(true)
                resetCounter()
            }
        })
    }

    const resetCounter = () => {
        return Date.now() + 299000
    }

    const otpTimer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return 'Expired'
        } else {
            return <span>valid for 5minutes ({minutes}:{seconds})</span>
        }
    }

    return (
        <div className='forgot-password-modal'>
            <h2 className='blue fs26 mb20'>Enter one time password</h2>
            <p>Enter the one time password(OTP) sent to your registered mobile number/email to login.</p>
            <div className='close-ico-btn cp' onClick={() => props.close()}>
                <i className='icon-sd-close-mini'></i>
            </div>
            <div className='position-relative mt30'>
                <RSTextInputWithIcon
                    name='otp'
                    ph='Enter OTP'
                    // val={otp}
                    icon={`login-valid-icon ${otpSuccess ? 'icon-sd-circle-tick-mini green' : ''} ${otpErr ? "icon-sd-close-mini icon-sm top10 right10 red" : ""}`}
                    errorMessage={otpErr}
                    keyboardType='number'
                    required
                    max={MAX_LOGIN_OTP}
                    cb={text => {
                        let errMsg = !text.length ? 'Please enter valid OTP' : null
                        setOtpErr(errMsg)
                        const re = /^[0-9\b.]+$/
                        if (text === '' || re.test(text)) {
                            if (text.length === 6) {
                                setOtp(text)
                                validateOTP(text)
                            }
                        }

                    }}
                    ob={text => {
                        if (!text.length) {
                            let errMsg = !text.length ? 'Enter OTP' : null
                            setOtpErr(errMsg)
                            setTimeout(() => { setOtpErr('') }, 2000)
                            return
                        }
                        if (text.length < 7) {
                            const re = /^[0-9\b]+$/
                            if (text === '' || re.test(text)) {
                            }
                        }
                    }}
                />
                {!otpSuccess && !isShowResendBtn ?
                    <small className="input-desc">
                        OTP is valid for 5 minutes <RSPTimer
                            onComplete={() => {
                                setIsShowResendBtn(true)
                            }}
                        /></small> : null
                }
                {!otpSuccess && isShowResendBtn ?
                    <small className='input-desc-right right0'>
                        <label className="rs-link orange-medium" onClick={() => {
                            setIsShowResendBtn(false)
                            setOtp('')
                            setOtpErr(null)
                            resendOtp()
                        }}>Resend?</label>
                    </small> : null
                }
            </div>
            <div className='mt20 d-flex justify-content-center'>
                <RSSecondaryBtn onClick={() => props.close()}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn
                    className={`${!otpSuccess ? 'click-off' : ''}`}
                    onClick={() => {
                        if (otpSuccess) {
                            context.toggleAuth(otpSuccess)
                        }
                    }}
                >
                    Confirm
                </RSPrimaryBtn>
            </div>
        </div>
    )
}

const lsConfig = {
    sdc: true,
    etc: true,
    mp: true,
    wp: true,
    sl: true,
    fs: true,
    tmb: true,
    ld: true
}
