export const ch_chartColor = "#333333"
export const ch_chartTitle = "#333333"
export const ch_labelColor = '#333333'
export const ch_legendColor = '#333333'
export const ch_tooltipColor = '#fefefe'
export const ch_tooltipBgColor = '#333333'
export const ch_emailColor = '#fc6a00'
export const ch_mobileColor = '#dfb82b'//#99cc03'
export const ch_smsColor = '#dfb82b'
export const ch_qrColor = '#666666'
export const ch_whatsAppColor = "#009c00"
export const ch_facebookColor = '#2a56a3'
export const ch_facebookAppColor = '#5f77ac'
export const ch_googleColor = '#CD201F'
export const ch_googleplaystoreColor = '#4885ed'
export const ch_twitterColor = '#1d9bf0'
export const ch_pinterestColor = '#e60023'
export const ch_linkedinColor = '#007bb6'
export const ch_slideshareColor = '#0679b4'
export const ch_youtubeColor = '#CD201F'
export const ch_websiteColor = '#ffc000'
export const ch_youtubewatchpageColor = '#e62117'
export const ch_blueColor = '#2896f0'
export const ch_blueLightColor = '#33b5e6'
export const ch_blueLighterColor = '#66c8ec'
export const ch_orange = '#f58332'
export const ch_orangeLightColor = '#f9a263'
export const ch_orangeDarkColor = '#f25e0e'
export const ch_green = '#90c830'
export const ch_greenLightColor = '#aed237'
export const ch_yellowColor = '#e7ca60'
export const ch_maroonColor = '#fe5758'
export const ch_cyanColor = '#40abaf'
export const ch_aquaColor = '#3fabae'
export const ch_greyDarkColor = '#585858'
export const ch_greyLightColor = '#e9e9eb'
export const ch_lightgreyColor = '#e9e9eb'
export const ch_tumblrColor = "#e7ca60"
export const ch_forwardColor = "#fd8f40"
export const ch_desktopColor = "#33b5e6"
export const ch_android2 = "#aadd14"
export const ch_androidPhoneColor = "#A3FE2E"
export const ch_androidTabColor = "#aadd14"
export const ch_whiteColor = '#ffffff'
export const ch_blackColor = '#000000'
export const ch_redmiColor = '#e96529'
export const ch_oneplusColor = '#e62f2d'
export const ch_samsungColor = '#274da3'

export const ch_clicksColor = "#99cc03"
export const ch_maybelaterColor = "#2896f0"
export const ch_dismissColor = "#fe5758"

export const ch_fullyConversedColor = "#ff6900"
export const ch_partiallyConversedColor = "#feab70"
export const ch_tellMeMoreColor = "#b3d942"


export const ch_television = "#2896f0"
export const ch_books = "#fd8f40"
export const ch_sports = "#40abaf"
export const ch_video = "#99cc03"
export const ch_music = "#e7ca60"

export const ch_positive = "#99cc03"
export const ch_negetive = "#fe5758"
export const ch_neutral = "#33b5e6"
export const ch_embeddedplayer = "#fd8f40"

// colors for Browsers
export const ch_ie = "#2ED8FE"
export const ch_chrome = "#1a73e8"
export const ch_firefox = "#ff6611"
export const ch_safari = "#2E8DFE"
export const ch_opera = "#C70039"

// colors for known
export const ch_known = "#fd8f40" // "#22376e"
export const ch_unknown = "#06c" //"#3367ac"
export const ch_identified = "#99cc03" //"#4d9bd6"
export const ch_converted = '#3568c6'


// colors for segments
export const ch_marketing = "#a7c31b"
export const ch_it = "#eb6864"
export const ch_infrastructure = "#7cc7e9"
export const ch_hr = "#ef9451"
export const ch_finance = "#5fa9ad"

// colors for languages
export const ch_english = "#99cc03"
export const ch_spanish = "#2896f0"

// colors for industry
export const ch_banking = "#66c8ec"
export const ch_education = "#ffb35e"
export const ch_hospitality = "#b3d942"
export const ch_healthcare = "#afc236"
export const ch_travel = "#96c6e7"
export const ch_telco = "#d99256"
export const ch_b2b = "#79a7ab"

// colors for devices
export const ch_android = "#99cc03"
export const ch_windows = "#00a2ed"
export const ch_iphone = "#40abaf"
export const ch_ipad = "#5856D6"
export const ch_apple = "#99cc03"
export const ch_gmail = "#33b5e6"
export const ch_outlook = "#fd8f40"
export const ch_outlook2007 = "#40abaf"
export const ch_outlook2010 = "#fd8f40"
export const ch_yahoo = "#fe5758"
export const ch_ios = "#4885ed"
export const ch_linux = "#fe5758"
export const ch_mac = "#008f94"

export const ch_others = "#fe5758"

// Weekdays colors
export const ch_sunday = "#ff7800"
export const ch_monday = "#0048ff"
export const ch_tuesday = "#ff0005"
export const ch_wednesday = "#46c029"
export const ch_thursday = "#ffc000"
export const ch_friday = "#c32c8f"
export const ch_saturday = "#5b5b5b"