import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSCheckbox, RSInput2, RSRadio } from "Components/RSInputs";
import ServerSettings from "./ServerSettings";
import { isEmpty, getAuthUserId, validateEmail } from "Helper/Utils/Utils";
import {
  IP_ADDRESS,
  IP_ADDRESS_VALID,
  PORT_NUMBER,
  PORT_NUMBER_VALID,
  ENTER_EMAIL,
  ENTER_PASSWORD,
  ENTER_VALID_PASSWORD,
  ENTER_DOMAIN_KEY,
  ENTER_DKIMK_KEY,
  SERVER_BOUNCE_EMAIL,
  SERVER_FROM_EMAIL,
  THROTTLE_SETTING,
  ENTER_DOMAIN_NAME,
  SMTP_HOUSING,
} from "Helper/Constants/validationMessage";
import authContext from "Helper/StateHandler/auth-context";
import {
  SAVE_SMTP,
  GET_SMTP,
  GET_SMTP_HOUSING,
  GET_THROTTLE_SETTING,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import { RSPTooltip } from "Components/Tooltip";
import DomainSettings from "./DomainSettings";

const SMTPCreation = (props) => {
  const context = useContext(authContext);

  const [smtpId] = useState(props.mode === "edit" ? props.smtpId : 0);
  const [index, setIndex] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [domainDropdownItem, setDomainDropdownItem] = useState(null);
  const [isDomainNameEdit, setIsDomainNameEdit] = useState(false);
  const [serverIp, setServerIp] = useState("");
  const [portNumber, setPortNumber] = useState("");
  const [userMailId, setUserMailId] = useState("");
  const [password, setPassword] = useState("");
  const [domainKey, setDomainKey] = useState("");
  const [smtpType, setSmtpType] = useState("Dedicated");
  const [isConfigureSpamSettings, setIsConfigureSpamSettings] = useState(false);
  const [dkimKey, setDkimKey] = useState("");
  const [smtpData, setSmtpData] = useState([]);
  const [throttleData, setThrottleData] = useState([]);
  const [throttle, setThrottle] = useState({
    throttleSettingId: 0,
    throttleSetting: "-- Select throttle --",
  });
  const [serverFromEmail, setServerFromEmail] = useState("");
  const [smtpHousing, setSmtpHousing] = useState({
    smtpHouseId: 0,
    smtpHouseName: "-- Select SMTP housing --",
  });
  const [serverBounceEmail, setBounceEmail] = useState("");
  const [isValidateBtnClicked, setIsValidateBtnClicked] = useState(false);
  const [errMsgSmtpHousing, setErrSmtpHousing] = useState(null);
  const [errMsgServerBounceEmail, setErrServerBounceEmail] = useState(null);
  const [errMsgDomainName, setErrDomainName] = useState(null);
  const [errMsgThrottlesetting, setErrThrottleSetting] = useState(null);
  const [errMsgServerFromEmail, setErrServerFromEmail] = useState(null);
  const [errMsgServerIp, setErrServerIp] = useState(null);
  const [errMsgPortNumber, setErrPortNumber] = useState(null);
  const [errMsgUserMailId, setErrUserMailId] = useState(null);
  const [errMsgUserPassword, setErrUserPassword] = useState(null);
  const [errMsgDomainKey, setErrDomainKey] = useState(null);
  const [errMsgConfigureSpamSettings, setErrConfigureSpamSettings] = useState(null);
  const [errMsgDkimKey, setErrDkimKey] = useState(null);
  const [errorMsgFromSave, setErrorMsgFromSave] = useState(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (index === 0) {
      getThrottleData();
      getSmtpHousing();
      getSmtpDataFromServer();
    }
  }, [index]);

  const getSmtpHousing = () => {
    connectServer({
      path: GET_SMTP_HOUSING,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) setSmtpData(res.data || []);
        }
      },
      fail: (err) => { },
    });
  };

  const getSmtpDataFromServer = () => {
    if (props.mode === "edit") {
      const params = {
        userId: getAuthUserId(),
        clientSmtpId: props.smtpId,
      };
      connectServer({
        path: GET_SMTP,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setServerIp(res.data.smtpServer);
            setUserMailId(res.data.smtpUser);
            setPassword(props.smtpId === 1 ? window.btoa(res.data.smtpPassword) : res.data.smtpPassword);
            if (props.smtpId === 1) {
              setIsDomainNameEdit((res.data.smtpDomainSettingId !== 1 && res.data.smtpDomainSettingId !== 0) ? true : false)
              setDomainList(res.data.domainList);
              setDomainDropdownItem(res.data.domainList.filter(item => item.smtpDomainSettingId === res.data.smtpDomainSettingId)[0])
              setDomainName(res.data.domainList.filter(item => item.smtpDomainSettingId === res.data.smtpDomainSettingId)[0]?.domainName);
            } else {
              setDomainName(res.data.domainName);
            }
            setDomainKey(res.data.domainKey);
            setSmtpType(res.data.smtpType);
            setDkimKey(res.data.dkimKey);
            setPortNumber(res.data.smtpPort);
            setBounceEmail(res.data.serverBounceEmail);
            setServerFromEmail(res.data.serverFromMail);
            var throttleParams = {
              throttleSettingId: res.data.throttleSettingId,
              throttleSetting: res.data.throttleSetting,
            };
            setThrottle(throttleParams);
            var smtpParams = {
              smtpHouseId: res.data.smtpHousingId,
              smtpHouseName: res.data.smtpHouseName,
            };
            setSmtpHousing(smtpParams);
          }
        },
        fail: (err) => { },
      });
    }
  };

  const getThrottleData = () => {
    connectServer({
      path: GET_THROTTLE_SETTING,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          if (res.data?.length) setThrottleData(res.data || []);
        }
      },
      fail: (err) => { },
    });
  };

  const validateCreationForm = () => {
    let isValidate = true;
    if (errMsgDomainName === null) {
      if (isEmpty(domainName)) {
        setErrDomainName(ENTER_DOMAIN_NAME);
        isValidate = false;
      } else if (props.smtpId === 1 && domainDropdownItem.dkimStatus !== "SUCCESS") {
        setErrDomainName("Select verified domain name");
        isValidate = false;
      }
    } else {
      isValidate = false;
    }
    if (isEmpty(serverIp)) {
      setErrServerIp(IP_ADDRESS_VALID);
      isValidate = false;
    }
    if (errMsgPortNumber === null) {
      if (isEmpty(portNumber)) {
        setErrPortNumber(PORT_NUMBER_VALID);
        isValidate = false;
      }
    } else {
      isValidate = false
    }
    if (isEmpty(userMailId)) {
      setErrUserMailId(ENTER_EMAIL);
      isValidate = false;
    }
    if (isEmpty(password)) {
      setErrUserPassword(ENTER_VALID_PASSWORD);
      isValidate = false;
    }
    if (errMsgServerFromEmail === null) {
      if (isEmpty(serverFromEmail)) {
        setErrServerFromEmail(SERVER_FROM_EMAIL);
        isValidate = false;
      }
    } else {
      isValidate = false;
    }
    if (errMsgServerBounceEmail === null) {
      if (isEmpty(serverBounceEmail)) {
        setErrServerBounceEmail(SERVER_BOUNCE_EMAIL);
        isValidate = false;
      }
    } else {
      isValidate = false;
    }
    // if (isEmpty(domainKey)) {
    //   setErrDomainKey(ENTER_DOMAIN_KEY);
    //   isValidate = false;
    // }
    // if (throttle.throttleSettingId === 0) {
    //   setErrThrottleSetting(THROTTLE_SETTING);
    //   isValidate = false;
    // }
    // if (smtpHousing.smtpHouseId === 0) {
    //   setErrSmtpHousing(SMTP_HOUSING);
    //   isValidate = false;
    // }
    // if (isEmpty(dkimKey)) {
    //   setErrDkimKey(ENTER_DKIMK_KEY);
    //   isValidate = false;
    // }
    return isValidate;
  };

  const saveSmtpCreation = () => {
    setErrorMsgFromSave(null);
    setIsShowErrorModal(false)
    const params = {
      userId: getAuthUserId(),
      clientSmtpId: smtpId,
      smtpDomainSettingId: props.smtpId === 1 ? domainDropdownItem.smtpDomainSettingId : 1,
      smtpServer: serverIp,
      smtpUser: userMailId,
      smtpPassword: props.smtpId === 1 ? window.atob(password) : password,
      smtpPort: portNumber,
      domainKey: domainKey,
      domainName: props.smtpId === 1 ? domainDropdownItem.domainName : domainName,
      dkimKey: dkimKey,
      isSpamSetting: true,
      throttleSettings: throttle.throttleSetting,
      smtpType: smtpType,
      smtpHousing: smtpHousing.smtpHouseName,
      serverFromMail: serverFromEmail,
      serverBounceEmail: serverBounceEmail,
      smtpHousingId: smtpHousing.smtpHouseId,
      throttleSettingId: throttle.throttleSettingId,
    };
    connectServer({
      path: SAVE_SMTP,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          props.onChangeIndex(0);
        } else {
          setIsShowErrorModal(true)
          setErrorMsgFromSave(res.message);
        }
      },
      fail: (err) => { },
    });
  };

  return (
    <div className="mt15">
      <h3>SMTP</h3>
      {index === 0 ?
        <div className="mt18">
          <Row>
            <div className="ml15">
              <Row>
                <Col md={3}>
                  <label className="label-row">Domain name</label>
                </Col>
                <Col md={4}>
                  {props.smtpId !== 1 ?
                    <>
                      <RSInput2
                        name="websiteName"
                        ph={"Enter domain Name"}
                        required={true}
                        val={domainName}
                        cb={(value) => {
                          setDomainName(value);
                          let errMsg = value.length ? null : ENTER_DOMAIN_NAME;
                          setErrDomainName(errMsg);
                        }}
                        ob={(value) => {
                          let errMsg = value.length ? null : ENTER_DOMAIN_NAME;
                          let regex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                          if (errMsg === null) {
                            if (regex.test(value)) {
                              setDomainName(value);
                              setErrDomainName(errMsg);
                            } else {
                              setErrDomainName("Enter valid domain name");
                            }
                          } else {
                            setDomainName(value);
                            setErrDomainName(errMsg);
                          }
                        }}
                        errorMessage={errMsgDomainName}
                      />
                    </>
                    :
                    <>
                      <div className='position-relative'>
                        {errMsgDomainName && (
                          <div className="validation-message">{errMsgDomainName}</div>
                        )}
                        <RSDropdownList
                          className="required"
                          data={domainList}
                          value={domainDropdownItem || {
                            "smtpDomainSettingId": 0,
                            "domainName": "-- Select domain name --"
                          }}
                          defaultItem={{
                            "smtpDomainSettingId": 0,
                            "domainName": "-- Select domain name --"
                          }}
                          textField='domainName'
                          dataItemKey='smtpDomainSettingId'
                          onChange={(event) => {
                            let errMsg = event.target.value.smtpDomainSettingId !== 0 ? null : "Select domain name";
                            setErrDomainName(errMsg);
                            setDomainDropdownItem(event.target.value);
                            setIsDomainNameEdit((event.target.value.smtpDomainSettingId !== 1 && event.target.value.smtpDomainSettingId !== 0) ? true : false)
                          }}
                        />
                      </div>
                      <div className="input-outer-set">
                        <RSPTooltip text={isDomainNameEdit ? "Edit domain" : "Add new domain"} position="top">
                          <i className={`${isDomainNameEdit ? "icon-sd-pencil-edit-mini" : "icon-sd-circle-plus-fill-edge-medium"} icons-md blue cp icon-shadow`} onClick={() => {
                            setIndex(1);
                          }} />
                        </RSPTooltip>
                      </div>
                    </>
                  }
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Server IP address</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="serverIP"
                    ph={"Enter server IP address"}
                    required={true}
                    val={serverIp}
                    max={100}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      let errMsg = value.length ? null : IP_ADDRESS;
                      setServerIp(value);
                      setErrServerIp(errMsg);
                    }}
                    ob={(value) => {
                      let errMsg = value.length ? null : IP_ADDRESS;
                      setServerIp(value);
                      setErrServerIp(errMsg);
                    }}
                    errorMessage={errMsgServerIp}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Port number</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="portNumber"
                    ph={"Enter port number"}
                    required={true}
                    val={portNumber}
                    max={4}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      const regex = /^[0-9]*$/;
                      let errMsg = value.length ? null : PORT_NUMBER;
                      if (errMsg === null) {
                        if (regex.test(value)) {
                          setPortNumber(value);
                          setErrPortNumber(errMsg);
                        } else {
                          setErrPortNumber("Accept only numbers");
                        }
                      } else {
                        setPortNumber(value);
                        setErrPortNumber(errMsg);
                      }
                    }}
                    ob={(value) => {
                      setPortNumber(value);
                      let errMsg = value.length ? (value.length >= 2 && value.length <= 4) ? null : "Port number digits should between 2-4" : PORT_NUMBER;
                      setErrPortNumber(errMsg);
                    }}
                    errorMessage={errMsgPortNumber}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Email address</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="userMailId"
                    ph={"Enter email address"}
                    val={userMailId}
                    required={true}
                    max={100}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      setUserMailId(value);
                      let errMsg = value.length ? null : ENTER_EMAIL;
                      setErrUserMailId(errMsg);
                    }}
                    ob={(value) => {
                      setUserMailId(value);
                      let errMsg = value.length ? null : ENTER_EMAIL;
                      setErrUserMailId(errMsg);
                    }}
                    errorMessage={errMsgUserMailId}
                  /></Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Password</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="password"
                    ph={"Enter password"}
                    val={password}
                    required={true}
                    type="password"
                    max={255}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      setPassword(value);
                      let errMsg = value.length ? null : ENTER_PASSWORD;
                      setErrUserPassword(errMsg);
                    }}
                    ob={(value) => {
                      setPassword(value);
                      let errMsg = value.length ? null : ENTER_PASSWORD;
                      setErrUserPassword(errMsg);
                    }}
                    errorMessage={errMsgUserPassword}
                  /></Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Server from mail</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="serverFromEmail"
                    ph={"Enter server from email"}
                    val={serverFromEmail}
                    required={true}
                    max={200}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      setServerFromEmail(value);
                      let errMsg = value.length ? null : SERVER_FROM_EMAIL;
                      setErrServerFromEmail(errMsg);
                    }}
                    ob={(value) => {
                      let errMsg = value.length ? null : SERVER_FROM_EMAIL;
                      if (errMsg === null) {
                        if (validateEmail(value)) {
                          setServerFromEmail(value);
                          setErrServerFromEmail(errMsg);
                        } else {
                          setErrServerFromEmail("Enter valid email ID");
                        }
                      } else {
                        setServerFromEmail(value);
                        setErrServerFromEmail(errMsg);
                      }

                    }}
                    errorMessage={errMsgServerFromEmail}
                  /></Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="label-row">Server bounce mail</label>
                </Col>
                <Col md={4}>
                  <RSInput2
                    name="serverBounceEmail"
                    ph={"Enter server bounce email"}
                    val={serverBounceEmail}
                    required={true}
                    max={200}
                    disabled={props.smtpId === 1}
                    cb={(value) => {
                      setBounceEmail(value);
                      let errMsg = value.length ? null : SERVER_BOUNCE_EMAIL;
                      setErrServerBounceEmail(errMsg);
                    }}
                    ob={(value) => {
                      let errMsg = value.length ? null : SERVER_BOUNCE_EMAIL;
                      if (errMsg === null) {
                        if (validateEmail(value)) {
                          setBounceEmail(value);
                          setErrServerBounceEmail(errMsg);
                        } else {
                          setErrServerBounceEmail("Enter valid email ID");
                        }
                      } else {
                        setBounceEmail(value);
                        setErrServerBounceEmail(errMsg);
                      }
                    }}
                    errorMessage={errMsgServerBounceEmail}
                  />
                </Col>
              </Row>
              {/* <RSInput2
              name="domainKey"
              ph={"Enter domain key"}
              required={true}
              val={domainKey}
              max={255}
              cb={(value) => {
                setDomainKey(value);
                let errMsg = value.length ? null : ENTER_DOMAIN_KEY;
                setErrDomainKey(errMsg);
              }}
              ob={(value) => {
                setDomainKey(value);
                let errMsg = value.length ? null : ENTER_DOMAIN_KEY;
                setErrDomainKey(errMsg);
              }}
              errorMessage={errMsgDomainKey}
            /> */}
              {/* <div className="position-relative">
              {errMsgConfigureSpamSettings && (
                <div className="validation-message">
                  {errMsgConfigureSpamSettings}
                </div>
              )}
              <RSCheckbox
                className="marginB0 position-relative"
                lbl="Configure spam settings"
                checked={isConfigureSpamSettings}
                cb={(status) => {
                  setIsConfigureSpamSettings(status);
                }}
              />
            </div> */}
            </div>

            {/* <Col md={6}> */}
            {/* <h3>Throttle setting</h3>
          <Row>
            <Col md="12">

              <div className="ml15">
                {errMsgThrottlesetting && (
                  <div className="validation-message">{errMsgThrottlesetting}</div>
                )}
                <RSDropdownList
                  textField="throttleSetting"
                  dataItemKey="throttleSettingId"
                  className="required"
                  data={throttleData}
                  value={throttle}
                  defaultItem={{
                    throttleSettingId: 0,
                    throttleSetting: "-- Select Throttle --",
                  }}
                  onChange={(event) => {
                    setThrottle(event.target.value);
                    let errMsg = event.target.value.throttleSettingId
                      ? null
                      : THROTTLE_SETTING;
                    setErrThrottleSetting(errMsg);
                  }}
                />
              </div>
            </Col>
          </Row>
          <h3>SMTP type</h3>
          <div className="ml15">
            <RSRadio
              lbl={"Dedicated"}
              name={"smsType"}
              className="marginB30 mr15"
              labelId={"smsType1"}
              cb={(event) => {
                setSmtpType(event.target.value);
              }}
              checked={smtpType === "Dedicated" ? true : false}
            />
            <RSRadio
              lbl={"Shared"}
              name={"smsType"}
              className="marginB20"
              labelId={"smsType2"}
              cb={(event) => {
                setSmtpType(event.target.value);
              }}
              checked={smtpType === "Shared" ? true : false}
            />
          </div>
          <h3>SMTP housing</h3>
          <Row>
            <Col md="12">
              <div className="ml15">
                {errMsgSmtpHousing && (
                  <div className="validation-message">{errMsgSmtpHousing}</div>
                )}
                <RSDropdownList
                  textField="smtpHouseName"
                  dataItemKey="smtpHouseId"
                  className="required"
                  data={smtpData}
                  value={smtpHousing}
                  defaultItem={{
                    smtpHouseId: 0,
                    smtpHouseName: "-- Select SMTP housing --",
                  }}
                  onChange={(event) => {
                    setSmtpHousing(event.target.value);
                    let errMsg = event.target.value.smtpHouseId
                      ? null
                      : SMTP_HOUSING;
                    setErrSmtpHousing(errMsg);
                  }}
                />
              </div>
            </Col>
          </Row>
          <h3>DKIM</h3>
          <div className="ml15">
            <RSInput2
              name="dkimKey"
              ph={"Enter DKIM key"}
              required={true}
              val={dkimKey}
              max={255}
              cb={(value) => {
                setDkimKey(value);
                let errMsg = value.length ? null : ENTER_DKIMK_KEY;
                setErrDkimKey(errMsg);
              }}
              ob={(value) => {
                setDkimKey(value);
                let errMsg = value.length ? null : ENTER_DKIMK_KEY;
                setErrDkimKey(errMsg);
              }}
              errorMessage={errMsgDkimKey}
            />
          </div> */}
            {/* <h3 className="mt8">SPF record</h3>
          <div className="ml15 posr">
            <RSInput2
              name="domainName"
              ph={"Enter spf record"}
              val={spfRecord}
              cls2="mt15"
              cb={(value) => {
                setSpfRecord(value);

              }}
              ob={(value) => {
                setSpfRecord(value);
              }}
            />
            <small className="input-desc text-end width100p">
              <label
                className='rs-link orange-medium'
                onClick={() => setIsShowCopyContent(!isShowCopyContent)}
              >Generate SPF</label>
            </small>

            {isShowCopyContent && (
              <div className='sp-copy-wrapper'>
                <Row>
                  <Col md={9}>
                    <div className="spf-copy">
                      <span className="wsn">v=spf1 include:spf2.resulticks.net -all</span>
                    </div>
                  </Col>
                  <Col md={3} className="text-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className='cp blue'>Copy</span>
                      <i className="icon-share-link-medium icons-md blue ml5" />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div> */}
            {/* </Col> */}
          </Row>
          {index === 0 ?
            <div className="btn-container d-flex justify-content-end marginT15">
              <RSSecondaryBtn onClick={() => props.onChangeIndex(0)}>
                Cancel
              </RSSecondaryBtn>
              <RSPrimaryBtn
                onClick={() => {
                  if (validateCreationForm()) {
                    if (isValidateBtnClicked) {
                      saveSmtpCreation();
                    } else {
                      setIsValidateBtnClicked(!isValidateBtnClicked);
                    }
                  }
                }}
              >
                {isValidateBtnClicked ? "Save" : "Validate settings"}
              </RSPrimaryBtn>
            </div> :
            null}
          {isValidateBtnClicked && (
            <ul className="list-tick-bar ml15">
              <li>
                <i className="icon-sd-circle-tick-medium icons-md green" />
                <span>MX record</span>
              </li>
              <li>
                <i className="icon-sd-circle-tick-medium icons-md green" />
                <span>SSL certificate</span>
              </li>
              <li>
                <i className="icon-sd-circle-tick-medium icons-md green" />
                <span>RDNS</span>
              </li>
              <li>
                <i className="icon-sd-circle-tick-medium icons-md green" />
                <span>Sender ID</span>
              </li>
              {/* <li>
            <i className="icon-sd-circle-tick-medium icons-md green" />
            <span>SPF record</span>
          </li> */}
            </ul>
          )}
          {isConfigureSpamSettings && (
            <ServerSettings
              isOpen={isConfigureSpamSettings}
              onChange={(value) => {
                setIsConfigureSpamSettings(value);
              }}
            />
          )}
          {isShowErrorModal &&
            <RSConfirmAlert
              title={errorMsgFromSave}
              primaryButtonText="OK"
              buttonClicked={(value) => {
                setIsShowErrorModal(false)
              }} />}
        </div> : <DomainSettings
          domainName={isDomainNameEdit ? domainDropdownItem.domainName : ""}
          domainStatus={isDomainNameEdit ? domainDropdownItem.dkimStatus : ""}
          domainId={isDomainNameEdit ? domainDropdownItem.smtpDomainSettingId : 0}
          onChangeIndex={(value) => {
            setIndex(value);
            setIsDomainNameEdit(false);
            setErrDomainName(null);
          }} />}
    </div>
  );
};
export default SMTPCreation;
