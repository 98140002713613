
import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { RSSecondaryBtn, RSPrimaryBtn, RSCancelBtn } from "Components/RSButtons";


export const RSConfirmAlert = (props) => {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={() => {
        setShow(false)
        props.buttonClicked(false)
      }}
      centered
      size='md'
    >
      <Modal.Header className='border-0 mt0 mr-10' closeButton>
        {/* <p className='text-center mb0'>{props.title}</p> */}
        {/* <h2>{props.title}</h2> */}
      </Modal.Header>
      <Modal.Body>
        {props.title && <h4 className='text-center'>{props.title}</h4>}
        {props.subTitle && <p className={`text-center ${props.subTitleClass ?? 'mt30'}`}>{props.subTitle !== "" && props.subTitle}</p>}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end marginT30">
          <RSSecondaryBtn onClick={() => {
            setShow(false)
            props.buttonClicked(false)
          }} variant="outline-success">
            {props.secondaryButtonText}
          </RSSecondaryBtn>
          <RSPrimaryBtn onClick={() => {
            setShow(false)
            props.buttonClicked(true)
          }} variant="outline-success">
            {props.primaryButtonText}
          </RSPrimaryBtn>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export const RSErrorPopup = (props) => {
  const [show, setShow] = useState(true);

  return (
    <Modal show={show} centered size="lg">
      <Modal.Header>

        <h2>{props.headerTitle === undefined ? "Sorry" : props.headerTitle}</h2>
        <div className="close-ico-btn"
          onClick={() => {
            setShow(false)
            props.buttonClicked(false);
          }}
        >
          <i className="icon-sd-close-mini"></i>
        </div>
      </Modal.Header>
      <Modal.Body>
        {props.title ? <h3>{props.title}</h3> : null}
        {props.para ? <p>{props.para}</p> : null}
      </Modal.Body>
      <div className="d-flex justify-content-end marginT30">
        <RSCancelBtn onClick={() => {
          setShow(false)
          props.buttonClicked(false)
        }} variant="outline-success">
          {props.secondaryButtonText}
        </RSCancelBtn>
        <RSPrimaryBtn onClick={() => {
          setShow(false)
          props.buttonClicked(true)
        }} variant="outline-success">
          {props.primaryButtonText}
        </RSPrimaryBtn>
      </div>
    </Modal>
  );
}

