import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { Grid, GridColumn } from '@progress/kendo-react-grid';

class DLTInfoComponent extends Component {

    state = {
        isShow: this.props.isOpen,
        data: UGDocuments
    }

    ShowLinksComponent = (props) => {
        return (<td>
            <div className="d-flex justify-content-end">
                <a className="text-decoration-none" href={props.dataItem.downloadLink} target="_blank">
                    <i className="icon-sd-download-medium icons-md blue cursor-pointer" />
                </a>
            </div>
        </td>)
    }

    render() {

        return (
            <><Modal
                show={this.state.isShow}
                keyboard={false}
                centered
                backdrop="static"
                size='lg'
                className='modal-top-fixed'
                onHide={() => {
                    this.setState({ isShow: false })
                    this.props.onChangeIsOpen(false)
                }}
            >
                <Modal.Header closeButton>
                    <h2>DLT Documentation</h2>
                </Modal.Header>
                <Modal.Body>
                    <Grid
                        data={this.state.data}
                        total={this.state.data?.length}
                        scrollable={"none"}
                        className="no-grid-pagenation-del grid-replaceable"
                    >
                        <GridColumn field="title" title="Title" />
                        <GridColumn field="downloadLink" title="Action" cell={this.ShowLinksComponent} />
                    </Grid>
                </Modal.Body>
            </Modal>
            </>
        );
    }
}

export default DLTInfoComponent;

const UGDocuments = [
    {
        "title": "Principal entity registration",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/Entity_registration.pdf"
    },
    {
        "title": "Header registration by principal entity",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/Entity_Header_registration_user_manual.pdf"
    },
    {
        "title": "Content template registration by principal entity",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/Entity_Content_template_registration.pdf"
    },
    {
        "title": "Consent template registration by principal entity",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/Entity_Consent_template_registration.pdf"
    },
    {
        "title": "Content template creation guidelines",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/ContentTemplateCreationGuidelines.pdf"
    },
    {
        "title": "Consent template creation guidelines",
        "downloadLink": "https://storage.googleapis.com/cdn.mkstr.io/Uploads/DLT%20Documentation/Consent%20Template%20creation%20handbook%20v2.1.pdf"
    }
]