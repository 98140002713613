import React, { useContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import authContext from "Helper/StateHandler/auth-context";
import { RSCheckbox, RSTextInputWithIcon } from "Components/RSInputs"
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { LOGIN, FORGOT_PASSWORD, LOGIN_WITH_OTP, IS_EMAIL_EXIST } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { isEmpty, validateEmail, validatePassword, convertObjectToBase64, getLowerCasedNoSpaceString, convertBase64ToObject, removeSpace, getSourceType, getUserData, getuserIp } from "Helper/Utils/Utils";
import {
    ENTER_BUSINESS_EMAIL,
    ENTER_PASSWORD,
    ENTER_VALID_BUSINESS_EMAIL,
    ENTER_VALID_EMAIL,
    ENTER_VALID_PASSWORD,
    MAXIMUM_LIMIT
} from 'Helper/Constants/validationMessage'
import passwordIcon from 'Assets/Images/icons/icon-password.svg'
import {
    MAX_EMAIL,
    MAX_PASSWORD,
    MAX_LOGIN_OTP
} from 'Helper/Constants/inputLimit'
import { RSPTimer } from "Components/RSPTimer";
import * as Icons from 'Constants/GlobalConstant/Glyphicons/Glyphicons-v5.0'
import { RSConfirmAlert } from "Components/RSConfirmAlert";

export const ExistingUser = props => {

    const context = useContext(authContext)
    const [emailId, setEmailId] = useState('')
    const [password, setPassword] = useState("")
    const [checked, setChecked] = useState(false)
    // const [loginErrMsg, setLoginErrMsg] = useState("")
    const [errorMessage, setErrorMessage] = useState({ email: null, passwd: null })
    const [isShowForgotModal, setIsShowForgotModal] = useState(false)
    const [isOtpRequired, setOtpRequired] = useState(false)
    const [mobData, setMobData] = useState("")
    const [isOpenAccActivation, setIsOpenAccActivation] = useState(false);
    const [accountPopupMessage, setAccountPopupMessage] = useState("");

    useEffect(() => {

        let login = localStorage.getItem("login")
        if (login !== null) {
            let loginObj = convertBase64ToObject(login)
            setEmailId(loginObj.email)
            setPassword(loginObj.passwd)
            setChecked(loginObj.checked)
        }
    }, [])

    const checkEmailExistInServer = (response) => {
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            context: context,
            path: IS_EMAIL_EXIST,
            params: { emailId: emailId },
            retry: 2,
            retryTime: 5000,
            ok: data => {
                // setIsEmailExist(data.status)
                if (!data.status) {
                    var errMsg = { ...errorMessage }
                    errMsg.email = data.message
                    setErrorMessage(errMsg)
                    return
                }
                context.globalStateData.setSourceTypeData(data?.data?.sourceType !== "" ? data?.data?.sourceType : "D")
                response(data.status)
            },
            fail: err => {
                var errMsg = { ...errorMessage }
                errMsg.email = "Unexpected happened. Try again later"
                setErrorMessage(errMsg)
                response(false)
            }
        })
    }

    return (
        <div className="form-login-cam">
            {
                <div>
                    <RSTextInputWithIcon
                        name="email"
                        ph={ENTER_BUSINESS_EMAIL}
                        val={emailId}
                        required={true}
                        icon={'icon-sd-email-medium icons-md'}
                        // img={emailIcon}
                        errorMessage={errorMessage.email}
                        max={MAX_EMAIL}
                        cb={t => {
                            const _emailId = getLowerCasedNoSpaceString(t)
                            if (_emailId.length <= 100) {
                                setEmailId(_emailId)
                            } else {
                                setErrorMessage({ ...errorMessage, ...{ email: MAXIMUM_LIMIT } })
                                return
                            }
                        }}
                        ob={value => {
                            if (validateEmail(getLowerCasedNoSpaceString(value))) {
                                checkEmailExistInServer((res) => {

                                })
                                setErrorMessage({ ...errorMessage, ...{ email: null } })
                            } else {
                                setErrorMessage({
                                    ...errorMessage,
                                    ...{ email: ENTER_VALID_BUSINESS_EMAIL }
                                })
                                return
                            }
                        }}
                    />
                    <RSTextInputWithIcon
                        name="password"
                        type="password"
                        ph={ENTER_PASSWORD}
                        val={password}
                        required={true}
                        // icon={"icon-sd-lock-medium icons-md mr-1"}
                        // img={passwordIcon}
                        // icon={`${Icons.key_medium} icons-md`}
                        errorMessage={errorMessage.passwd}
                        max={MAX_PASSWORD}
                        eye
                        cb={t => {
                            let pwd = removeSpace(t)
                            let errMsg = pwd.length > 0 ? null : ENTER_PASSWORD;
                            setErrorMessage({ ...errorMessage, ...{ passwd: errMsg } })
                            setPassword(pwd)
                        }}
                        ob={t => {
                            let pwd = removeSpace(t)
                            setPassword(pwd)
                            if (validatePassword(pwd)) {
                                setErrorMessage({ ...errorMessage, ...{ passwd: null } })
                            } else {
                                setErrorMessage({ ...errorMessage, ...{ passwd: ENTER_VALID_PASSWORD } })
                                return
                            }
                        }}
                    />
                    <RSCheckbox className="font-xsm marginB19" checked={checked} cb={status => setChecked(status)} lbl="Stay signed in" />
                    <RSLoginBtnContainer
                        isShowForgotButton={props.isShowForgotButton}
                        email={emailId}
                        passwd={password}
                        checked={checked}
                        isOtpRequired={(mobData) => {
                            setMobData(mobData)
                            setOtpRequired(true)
                        }}
                        setLoginErrMsg={(msg) => {
                            if (msg?.email?.toLowerCase().includes("activation")) {
                                setIsOpenAccActivation(true);
                                setAccountPopupMessage(msg.email);
                            } else {
                                setErrorMessage({ ...errorMessage, ...msg })
                            }
                        }}
                        setErrorMessage={(value) => {
                            setErrorMessage(value)
                        }}
                        isHideLoader={props.isHideLoader}
                        isLogin={(res) => {
                            if (res.data.accessToken && res.data.accessToken.length > 0) {
                                const sessionObject = {
                                    isAuth: true,
                                    accessToken: res.data.accessToken
                                }
                                localStorage.setItem('session', JSON.stringify(sessionObject))

                                localStorage.setItem("lsUserData", convertObjectToBase64(res.data))
                                // localStorage.setItem("lsConfig", JSON.stringify(lsConfig))
                                context.globalStateData.setLoggedInUserData(res.data)
                                context.globalStateData.setEmailIdData(props.email)
                                if (res.data.payStatus !== "A") {
                                    context.toggleAuth(true)
                                } else if (res.data.paymentTerms !== 0) {
                                    context.toggleAuth(true)
                                } else {
                                    context.toggleAuth(false)
                                    context.globalStateData.setPaymentMessage("payment")
                                }
                            }
                        }}
                    />
                    {
                        props.isShowForgotButton ? (
                            <p className='text-orange font-xsm text-center mt10'>
                                Your session expired. Login to continue
                            </p>
                        ) : (
                            <RSSecondaryBtn
                                onClick={() => {
                                    if (!isEmpty(emailId) && validateEmail(emailId)) {
                                        setErrorMessage({ ...errorMessage, ...{ email: null } })
                                        checkEmailExistInServer((status) => {
                                            if (status === true) {
                                                setIsShowForgotModal(!isShowForgotModal)
                                            }
                                        })

                                    } else {
                                        setErrorMessage({
                                            ...errorMessage,
                                            ...{ email: ENTER_VALID_BUSINESS_EMAIL }
                                        })
                                        return
                                    }
                                }}
                                className="forgot-txt text-orange font-xsm padding0 animate-forgot-txt">Forgot password?</RSSecondaryBtn>
                        )
                    }
                    {
                        isOpenAccActivation &&
                        <RSConfirmAlert title={accountPopupMessage} subTitle={""} primaryButtonText={"OK"} buttonClicked={(value) => {
                            setIsOpenAccActivation(false)
                            setAccountPopupMessage("")
                        }} />
                    }
                </div>
            }
            {
                isShowForgotModal && <RSPForgotPasswordModal closeForgotPasswordModal={() => setIsShowForgotModal(!isShowForgotModal)} />
            }
            {isOtpRequired && (
                <RSOTP
                    close={() => setOtpRequired(false)}
                    mobData={mobData}
                    email={emailId}
                    passwd={password}
                    isHideLoader={props.isHideLoader} />
            )}
        </div>
    )
}



const RSLoginBtnContainer = (props) => {

    const { checked, email, passwd, setLoginErrMsg } = props
    const [ipAddress, setIPAddress] = useState("");
    const context = useContext(authContext)
    useEffect(async () => {
        let ipAddress = await getuserIp();
        setIPAddress(ipAddress)
    }, [])


    const validateLogin = () => {
        if (props.isShowForgotButton) {
            context.globalStateData.setSessionOutData(false)
        }
        props.setLoginErrMsg({ email: null })
        let sourceType = getSourceType();
        let userAgent = window.navigator.userAgent;
        const params = {
            emailId: getLowerCasedNoSpaceString(email),
            password: passwd,
            sourceType: sourceType,
            userAgent: userAgent,
            ipAddress: ipAddress,
            otpType: 1
        }
        connectServer({
            path: LOGIN,
            params,
            loading: props.isHideLoader ? null : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res.status) {
                    // props.isOtpRequired(res.data)
                    // props.setLoginErrMsg({ email: null })
                    if (res.status && res.isOtpRequired) {
                        props.isOtpRequired(res.data)
                    } else if (res.status && res.message.toLowerCase().includes('login')) {
                        props.isLogin(res)
                    } else {
                        props.setLoginErrMsg({ email: res.message })
                    }
                } else {
                    props.setLoginErrMsg({ email: res.message })
                }
            },
            fail: error => {
                context.toggleAuth(false)
                setLoginErrMsg({ email: "Unexpected error. Try again later" })
            }
        })
        // makeServerCall(
        //     BASE_URL,
        //     LOGIN,
        //     { emailId: email, password: passwd },
        //     context.globalStateData.setIsLoadingData,
        //     null,
        //     res => {
        //         if (res.status) {
        //             if (res.data.accessToken && res.data.accessToken.length > 0) {
        //                 const sessionObject = {
        //                     isAuth: true,
        //                     accessToken: res.data.accessToken
        //                 }
        //                 localStorage.setItem('session', JSON.stringify(sessionObject))
        //                 if (props.isShowForgotButton) {
        //                     context.globalStateData.setIsReloadData(true)
        //                     context.globalStateData.setSessionOutData(false)
        //                 } else {
        //                     localStorage.setItem("lsUserData", convertObjectToBase64(res.data))
        //                     context.globalStateData.setLoggedInUserData(res.data)
        //                     context.globalStateData.setEmailIdData(email)

        //                     context.toggleAuth(res.status)
        //                     history.push('/')
        //                 }
        //             }
        //         } else {
        //             setLoginErrMsg(res.message)
        //         }
        //     },
        //     error => {
        //         context.toggleAuth(false)
        //         setLoginErrMsg(error)
        //     }
        // )
    }

    return <div className="text-right">

        <RSPrimaryBtn
            className="w-100 marginT10"
            onClick={() => {
                let isValidate = true;
                let errorMsg = { email: null, passwd: null }
                if (props.email.length === 0) {
                    errorMsg.email = ENTER_VALID_BUSINESS_EMAIL;
                    isValidate = false;
                } else if (!validateEmail(props.email)) {
                    errorMsg.email = ENTER_VALID_BUSINESS_EMAIL;
                    isValidate = false;
                }
                if (props.passwd.length === 0) {
                    errorMsg.passwd = ENTER_PASSWORD;
                    isValidate = false;
                } else if (!validatePassword(props.passwd)) {
                    errorMsg.passwd = ENTER_VALID_PASSWORD;
                    isValidate = false;
                }

                if (checked) {
                    saveLogin(email, passwd, checked)
                } else {
                    localStorage.removeItem("login")
                }
                if (isValidate) {
                    validateLogin()
                } else {
                    props.setErrorMessage(errorMsg)
                    return
                }

            }}>Sign In</RSPrimaryBtn>
    </div>
}

const saveLogin = (email, passwd, checked) => {
    let login = { email: email, passwd: passwd, checked: checked }
    localStorage.setItem('login', convertObjectToBase64(login))
}

const RSPForgotPasswordModal = props => {
    const [email, setEmail] = useState("")
    const [emailErrorMessage, setEmailErrorMessage] = useState(null)

    return <div className="forgot-password-modal">
        <ForgotPasswordModalHead
            close={props.closeForgotPasswordModal}
        />
        <ForgotPasswordModalBody
            emailErrorMessage={emailErrorMessage}
            email={email}
            setEmail={text => setEmail(text)}
            setEmailErrorMessage={err => setEmailErrorMessage(err)}
        />
        <ForgotPasswordModalFoot
            email={email}
            close={props.closeForgotPasswordModal}
            setEmailErrorMessage={err => setEmailErrorMessage(err)}
        />
    </div>
}

const RSOTP = props => {

    const [otp, setOtp] = useState('')
    const [otpToken, setOtpToken] = useState(props.mobData)
    const [otpErr, setOtpErr] = useState(null)
    const [otpSuccess, setOtpSuccess] = useState(false)
    const [resetTimer, setResetTimer] = useState(true)
    const [isShowResendBtn, setIsShowResendBtn] = useState(false)
    const [otpDefault, setOtpDefault] = useState(true)
    const context = useContext(authContext)
    const history = useHistory();

    const validateOTP = (text) => {
        if (isEmpty(text) || isEmpty(otpToken)) {
            setOtpErr("Please enter a valid OTP")
            return
        }
        const params = {
            "otpToken": otpToken,
            "Otp": text
        }
        connectServer({
            path: LOGIN_WITH_OTP,
            params,
            loading: props.isHideLoader ? null : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                if (res.status) {
                    setOtpSuccess(true)
                    setOtpDefault(false)
                    if (res.data.accessToken && res.data.accessToken.length > 0) {
                        const sessionObject = {
                            isAuth: true,
                            accessToken: res.data.accessToken
                        }
                        localStorage.setItem('session', JSON.stringify(sessionObject))
                        localStorage.setItem("lsUserData", convertObjectToBase64(res.data))
                        context.globalStateData.setLoggedInUserData(res.data)
                        if (res.data.themeName !== "") {
                            context.globalStateData.setSelectedThemeStatus(res.data.themeName)
                        }
                        context.globalStateData.setEmailIdData(props.email)
                        if (!res.data.isPayment) {
                            localStorage.removeItem('lsUserData');
                            localStorage.removeItem('isAuth');
                            context.globalStateData.setReminderMessage("")
                            context.globalStateData.setPaymentMessage("")
                            context.toggleAuth(false);
                            history.push({ pathname: `features`, state: { clientId: res.data.clientId || 0, userId: res.data.userId || 0, isForPaylater: false } })
                        } else if (res.data.paymentTerms <= 7) {
                            let day = res.data.paymentTerms > 1 ? res.data.paymentTerms.toString() + " days" : res.data.paymentTerms.toString() + " day";
                            context.globalStateData.setReminderMessage("Your Marketing Star subscription will expire in " + day + ". Please renew to continue our services.")
                        } else {
                            context.globalStateData.setReminderMessage("")
                        }
                        if (res.data.isPayment && res.data.paymentTerms === 0) {
                            context.globalStateData.setPaymentMessage("payment")
                        }

                    }
                } else {
                    setOtpErr(res.message)
                    setOtpSuccess(false)
                    setOtpDefault(false)
                }
            },
            fail: err => {
                props.setEmailErrorMessage(err.message)
                setOtpSuccess(false)
                setOtpDefault(false)
            }
        })
    }

    const resendOtp = async () => {
        let sourceType = getSourceType()
        let userAgent = window.navigator.userAgent;
        let ipAddress = await getuserIp();
        const params = {
            emailId: props.email,
            password: props.passwd,
            sourceType: sourceType,
            userAgent: userAgent,
            ipAddress: ipAddress,
            otpType: 2
        }
        setResetTimer(false)
        connectServer({
            path: LOGIN,
            params,
            loading: props.isHideLoader ? null : context.globalStateData.setIsLoadingData,
            context: context,
            ok: res => {
                setOtpToken(res.data)
                setOtpErr('OTP sent successfully')
                setResetTimer(true)
                resetCounter()
            },
            fail: error => {
                setResetTimer(true)
                resetCounter()
            }
        })
    }

    const resetCounter = () => {
        return Date.now() + 299000
    }

    const otpTimer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return 'Expired'
        } else {
            return <span>valid for 5minutes ({minutes}:{seconds})</span>
        }
    }

    return (
        <div className='forgot-password-modal'>
            <h3 className='blue fs26 mb20'>Enter one time password</h3>
            <p>Enter the one time password(OTP) sent to your registered mobile number/email to login.</p>
            <div className="close-ico-btn cp" onClick={() => props.close()}>
                <i className='icon-sd-close-mini'></i>
            </div>
            <div className='position-relative'>
                <RSTextInputWithIcon
                    name='otp'
                    disabled={otpSuccess ? "click-off" : ""}
                    ph='Enter OTP'
                    success_msg={otpErr?.includes("success") ? "success_msg" : ''}
                    val={otp}
                    // icon="icon-tick-circle-small icons-md green"
                    icon={`${otpSuccess ? 'icon-sd-circle-tick-mini icons-md green' : otpDefault ? "" : 'icon-sd-close-mini icons-sm red'}`}
                    required
                    errorMessage={otpErr}
                    max={MAX_LOGIN_OTP}
                    keyboardType='number'
                    cb={text => {
                        let errMsg = !text.length ? 'Please enter a valid OTP' : null
                        setOtpErr(errMsg)
                        const re = /^[0-9\b]+$/
                        if (text === '' || re.test(text)) {
                            if (text.length === 6) {
                                validateOTP(text)
                            }
                            setOtpDefault(true)
                            setOtp(text)
                        }
                    }}
                    ob={text => {
                        if (!text.length) {
                            let errMsg = !text.length ? 'Enter OTP' : null
                            setOtpErr(errMsg)
                            setTimeout(() => { setOtpErr('') }, 2000)
                            return
                        }
                        if (text.length < 7) {
                            const re = /^[0-9\b]+$/
                            if (text === '' || re.test(text)) {
                            }
                        }
                    }}
                />
                {!otpSuccess && !isShowResendBtn ?
                    <small className="input-desc">
                        OTP is valid for 5 minutes <RSPTimer
                            onComplete={() => {
                                setIsShowResendBtn(true)
                            }}
                        /></small> : null
                }
                {!otpSuccess && isShowResendBtn ?
                    <small className='input-desc-right right0'>
                        <label className="rs-link orange-medium" onClick={() => {
                            setIsShowResendBtn(false)
                            setOtp('')
                            setOtpErr(null)
                            resendOtp()
                            setOtpDefault(true);
                        }}>Resend?</label>
                    </small> : null
                }
            </div>
            <div className='mt20 d-flex justify-content-center'>
                <RSSecondaryBtn onClick={() => props.close()}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn
                    className={`${!otpSuccess ? 'click-off' : ''}`}
                    onClick={() => {
                        if (otpSuccess) {
                            context.toggleAuth(otpSuccess)
                            let isFirstLogin = getUserData().isFirstTimeLogin;
                            if (isFirstLogin) {
                                // history.push("/launchpad")
                            }
                        }
                    }}
                >
                    Confirm
                </RSPrimaryBtn>
            </div>
        </div>
    )
}

const ForgotPasswordModalHead = props => {
    return <div className="mt15">
        <h3 className="blue fs26 mb20">Request a new password</h3>
        <div className="close-ico-btn cp" onClick={() => props.close()}>
            <i className='icon-sd-close-mini'></i>
        </div>
    </div>
}

const ForgotPasswordModalBody = props => {
    return <div className="position-relative">
        <RSTextInputWithIcon
            name="email"
            ph="Enter your registered email ID"
            val={props.email}
            required={true}
            errorMessage={props.emailErrorMessage}
            max={MAX_EMAIL}
            cb={t => {
                const _emailId = getLowerCasedNoSpaceString(t)
                if (_emailId.length <= 100) {
                    props.setEmail(_emailId)
                } else {
                    props.setEmailErrorMessage(MAXIMUM_LIMIT)
                    return
                }
            }}
        />
        <small className='input-desc mt5'>
            New password will be sent to the above email
        </small>
    </div>
}

const ForgotPasswordModalFoot = props => {
    const context = useContext(authContext)
    const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false)
    return <>
        <div className="mt20 d-flex justify-content-center">
            <RSPrimaryBtn
                className=""
                onClick={() => {
                    if (isEmpty(props.email)) {
                        props.setEmailErrorMessage(ENTER_VALID_EMAIL)
                        return
                    }
                    if (validateEmail(props.email)) {
                        props.setEmailErrorMessage(null)
                    } else {
                        props.setEmailErrorMessage(ENTER_VALID_EMAIL)
                        return
                    }
                    const params = { emailId: props.email }
                    connectServer({
                        path: FORGOT_PASSWORD,
                        params,
                        loading: context.globalStateData.setIsLoadingData,
                        context: context,
                        ok: res => {
                            // setIsShowSuccessAlert(true)
                            if (!res.status) {
                                props.setEmailErrorMessage(res.message)
                                setIsShowSuccessAlert(false)
                            } else {
                                setIsShowSuccessAlert(true)
                            }
                        },
                        fail: err => {
                            props.setEmailErrorMessage(err.message)
                        }
                    })
                }}>Send</RSPrimaryBtn>
        </div>
        {
            isShowSuccessAlert
                ? <div className="alert-forgot-success align-items-center d-flex mt20 p10">
                    <i className="icon-sd-circle-tick-mini icons-md mr10 white"></i>
                    <p>New password will be sent to the above email</p>
                    <i class="icon-sd-close-medium cp position-absolute right5 top5" onClick={() => {
                        setIsShowSuccessAlert(false)
                        props.close()
                    }}></i>
                </div>
                : null
        }
    </>
}



