import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const EDMGalleryModal = (props) => {
  const [isShow, setIsShow] = useState(props.isEdmGalleryOpen)
  const [edmGalleryValue, setEdmGalleryValue] = useState(props.edmGalleryValue)

  return (
    <>
      <Modal
        show={isShow}
        className='modal-top-fixed'
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        onHide={() => {
          setIsShow(!isShow);
          props.onChangeIsOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <h2>Preview</h2>
        </Modal.Header>
        <Modal.Body>
          {edmGalleryValue && (
            <div className="preview-iframe-wrapper">
              <div className="mb30">
                <iframe
                  srcDoc={edmGalleryValue + "<style>body{pointer-events: none;}</style>"}
                  style={{ width: "100%", minHeight: "500px" }}
                ></iframe>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EDMGalleryModal