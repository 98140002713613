import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { GridDetailRow } from "@progress/kendo-react-grid";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { SplitABModal } from "../SplitABModal";
import abIcon from 'Assets/Images/split-ab.png'
import { DateFormatWithMomentForWhatsApp, TimeFormatWithMomentForWhatsApp, dateTimeFormatWithMoment, getServerConfig, getUserData } from "Helper/Utils/Utils";
import { NO_MEDIA_IMAGE } from "Helper/Constants/endPoint";
import camera from 'Assets/Images/camera.png'
import { WhatsAppMessage } from "Components/WhatsAppMessage";
import pdfImage from 'Assets/Images/wapdf.png';
import videoImage from 'Assets/Images/wavideo.png';

class CampaignAnalyticsDetailComponent extends GridDetailRow {

  state = {
    splitAb: "Subject line",
    splitAbModal: false,
    imagePath: getServerConfig().slice(0, -1)
  }

  TableHead = props => {

    return <thead>
      <tr>
        <th width="11.5%" className="text-start">Channel</th>
        <th className="text-center" width="20%">Status</th>
        <th className="text-center" width="18.1%">Total sent</th>
        <th className="text-center" width="16%">Delivered</th>
        <th className="text-center" width="11%">Reach</th>
        <th className="text-center" width="10%">Engagement</th>
        <th className="text-center" width="10%">Conversion</th>
        <th width="10%" className="text-end">Action</th>
      </tr>
    </thead>

  }

  getImage = id => {

    var imgPath = 'icon-social-web-notification-large'
    switch (id) {
      case 1:
        imgPath = 'icon-sd-email-medium icons-lg orange-medium'
        break;
      case 2:
        imgPath = 'icon-sd-mobile-sms-medium icons-lg yellow-medium'
        break;
      case 21:
        imgPath = "icon-sd-social-whatsapp-medium icons-lg blue"
        break;
      case 3:
        imgPath = 'icon-sd-qrcode-medium icons-md grey-dark'
        break;
      default:
        imgPath = 'icon-social-web-notification-large'
    }
    return imgPath

  }

  getTitle = id => {

    var title = ''
    switch (id) {
      case 1:
        title = 'Email'
        break;
      case 2:
        title = 'SMS'
        break;
      case 21:
        title = 'WhatsApp'
        break;
      case 3:
        title = 'QR'
        break;
      default:
        title = ''
    }
    return title

  }

  render() {
    return <>
      <div className="panel-detail-view">
        <table className="accordian-body">
          <this.TableHead />
          <tbody>
            {this.props.dataItem.channels && this.props.dataItem?.channels?.length && this.props.dataItem?.channels?.map((item, index) => {
              return (
                <tr key={index} className={index % 2 === 0 ? '' : 'even'}>
                  <td className="text-center">
                    <div className="d-flex">
                      <RSPTooltip text={this.getTitle(item.channelId)} position="top">
                        <i className={`blue icons-lg ${this.getImage(item.channelId)}`}></i>
                      </RSPTooltip>
                    </div>
                  </td>
                  <td>
                    {
                      item?.isSplitAb
                        ? <div className="d-flex align-items-center justify-content-center">
                          <img src={abIcon} alt={abIcon} />
                          <p className="rs-link paddingL8" onClick={() => this.setState({ splitAbModal: !this.state.splitAbModal })}>split 10% completed</p>
                        </div>
                        : <div className="time-progressbar justify-content-center">
                          <RSPTooltip text={channelStatus(item.channelStatusId).title} position="top">
                            <i className={`${channelStatus(item.channelStatusId).icon} ${channelStatus(item.channelStatusId).className} marginR8 icons-md`}></i>
                          </RSPTooltip>
                          {/* <div className="progress progress-mini">
                              <div style={{ width: "65%" }} className="progress-bar bgOrange-light"></div>
                            </div> */}
                        </div>
                    }
                  </td>
                  <td className="text-center"> <p>{item?.totalSentCount}</p> </td>
                  <td className="text-center"> <p>{item?.deliveredCount}</p> </td>
                  <td className="text-center"> <p>{item?.reachCount}</p> </td>
                  <td className="text-center"> <p>{item?.engagementCount}</p> </td>
                  <td className="text-center"> <p>{item?.conversionCount}</p> </td>
                  <td>
                    <div className="camp-icon float-end">
                      <ul className="camp-icon-pannel">
                        <RSPTooltip text='Preview' position="top" rootClose={true}>
                          <li>
                            {
                              item?.channelId === 1 ? <Tooltip anchorElement="target" position="top">
                                {/* <div class="title-tip title-tip-up" title='Preview'> */}
                                <RSPPophover
                                  position="left"
                                  imgClass='campaign-pop-eye-img'
                                  image={item.previewImage !== "" ? `data:image/jpeg;base64,${item.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                                >
                                  <i className="icon-sd-eye-medium icons-md blue"></i>
                                </RSPPophover>
                                {/* </div> */}
                              </Tooltip>
                                : item?.channelId === 2 ? <RSPPophover
                                  position='left'
                                  imgClass='campaign-pop-eye-img'
                                  className="pophover-sms-con"
                                  pophover={
                                    <>
                                      <div className='campaign-sms-wrapper'>
                                        {/* <div className='campaign-sms-header'>
                                          <div className='blue w100p text-center'>Vision Bank</div>
                                        </div> */}
                                        <div className='campaign-sms-header'>
                                          <div className="camp-sms-left"><span class="arrow-left">&lt;</span>Back</div>
                                          <div className='camp-sms-center blue'>{getUserData()?.clientDetailsLocalization?.clientName}</div>
                                          <div className="camp-sms-right">Contact</div>
                                        </div>
                                        <div className='campaign-sms-body'>
                                          <div className='chat-bubble-left'>
                                            <div className='chat-bubble'>
                                              <div className='chat-content'>
                                                <span className='campaign-message'>
                                                  {item?.previewImage ? item?.previewImage : 'No data'}
                                                </span>
                                              </div>
                                            </div>
                                            <small className='message-date'>{item?.scheduleDate ? dateTimeFormatWithMoment(item?.scheduleDate) : ''}</small>
                                          </div>
                                        </div>
                                        <div className='campaign-sms-footer'>
                                          <img className="in-img" src={camera} alt='camera' />
                                          <div className="in-msg">Message</div>
                                          <div className="in-send">Send</div>
                                        </div>
                                      </div>
                                    </>
                                  }
                                >
                                  <i className='icon-sd-eye-medium icons-md blue'></i>
                                </RSPPophover>
                                  : item?.channelId === 3 ? <Tooltip anchorElement="target" position="top">
                                    <RSPPophover
                                      position='left'
                                      imgClass='campaign-pop-eye-img'
                                      image={item?.previewImage !== "" ? `${item?.previewImage}` : `${this.state.imagePath + NO_MEDIA_IMAGE}`}
                                    >
                                      <i className='icon-sd-eye-medium icons-md blue'></i>
                                    </RSPPophover>
                                  </Tooltip> : <RSPPophover
                                    position='left'
                                    imgClass='campaign-pop-eye-img'
                                    className="pophover-sms-con"
                                    pophover={
                                      <>
                                        <WhatsAppMessage
                                          popup
                                          profile={`${this.state.imagePath}${getUserData()?.clientDetailsLocalization?.logoPath}`}
                                          username={getUserData()?.clientDetailsLocalization?.clientName}
                                          date={item?.scheduleDate ? DateFormatWithMomentForWhatsApp(item?.scheduleDate) : ''}
                                          time={item?.scheduleDate ? TimeFormatWithMomentForWhatsApp(item?.scheduleDate) : ''}
                                          image={item.templateType === 2 ? item.waImagePath !== "" ? item.waImagePath : item.waMediaURL : item.templateType === 3 ? pdfImage : item.templateType === 4 ? videoImage : ""}
                                          message={item?.previewImage ? item?.previewImage : 'No data'}
                                        />
                                      </>
                                    }
                                  >
                                    <i title="Preview" className='icon-sd-eye-medium icons-md blue'></i>
                                  </RSPPophover>
                            }
                          </li>
                        </RSPTooltip>
                        <li>
                          <DetailAnalyticIcon
                            campaignId={this.props.dataItem.campaignId}
                            channelId={item?.channelId}
                            campaignGuid={item?.campaignGuid}
                            campaignName={this.props.dataItem.campaignName}
                            startDate={this.props.dataItem.startDate}
                            endDate={this.props.dataItem.endDate}
                            selectedIndex={index}
                          /></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <SplitABModal show={this.state.splitAbModal} closeSplitAb={() => this.setState({ splitAbModal: false })} />
    </>
  }
}

export default CampaignAnalyticsDetailComponent

const channelStatus = channelStatusId => {
  return channelProgressStatus[channelStatusId]
}

const channelProgressStatus = {

  0: {
    title: 'Draft',
    className: 'ico-status-draft',
    icon: 'icon-sd-form-edit-medium'
  },
  5: {
    title: 'In progress',
    className: 'ico-status-progress',
    icon: 'icon-sd-in-progress-medium'
  },
  52: {
    title: 'Alert',
    className: 'ico-status-alert',
    icon: 'icon-sd-alert-medium'
  },
  9: {
    title: 'Completed',
    className: 'ico-status-completed',
    icon: 'icon-sd-circle-tick-medium'
  },
  7: {
    title: 'Scheduled',
    className: 'ico-status-scheduled',
    icon: 'icon-sd-time-medium'
  },
  6: {
    title: 'Draft',
    className: 'ico-status-draft',
    icon: 'icon-sd-form-edit-medium'
  },
  20: {
    title: 'Multi status',
    className: 'ico-status-multistatus',
    icon: 'icon-multi-status-large'
  }

}

const DetailAnalyticIcon = props => {

  const history = useHistory()
  const match = useRouteMatch()

  const routeToDetailAnalytics = () => {

    history.push({
      pathname: `${match.url}/detail-analytics`,
      state: {
        campaignId: props.campaignId,
        channelId: props.channelId,
        campaignGuid: props.campaignGuid,
        campaignName: props.campaignName,
        selectedIndex: props.selectedIndex,
        startDate: props.startDate,
        endDate: props.endDate
      }
    })

  }

  return (
    <RSPTooltip text="Analytics" position="top">
      <i className="icon-sd-analytics-medium icons-md blue" onClick={() => routeToDetailAnalytics()}></i>
    </RSPTooltip>
  )

}