import { Modal } from 'react-bootstrap';
import { RSPrimaryBtn } from 'Components/RSButtons';
import React, { Component } from 'react';

class GoalModal extends Component {
    state = {
        isShow: this.props.isOpen
    }
    render() {
        return (
            <Modal
                backdrop="static"
                keyboard={false}
                onHide={() => {
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen(false);
                }}
                show={this.state.isShow} centered size="md">
                <Modal.Header closeButton>
                    <h2>Secondary goal info</h2>
                </Modal.Header>
                <Modal.Body>
                    <p className='marginB0'>Secondary goal will not be considered as the communication target.</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end marginT20">
                        <RSPrimaryBtn onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(true);
                        }}>OK</RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default GoalModal;