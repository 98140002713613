import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Modal } from 'react-bootstrap';
import { RSPrimaryBtn } from "Components/RSButtons";
import { connectServer } from "Helper/Network/networkHandler";
import { GET_EDM_VERIFICATION_LINKS } from "Helper/Constants/endPoint";
import authContext from "Helper/StateHandler/auth-context";

const LinkVerificationModal = (props) => {

  const context = useContext(authContext)
  const [isShow, setIsShow] = useState(false)
  const [linkVerifiedData, setLinkVerifiedData] = useState([])

  useEffect(() => {
    getEdmVerificationData()
  }, [])

  const getEdmVerificationData = () => {
    const params = {
      "Links": props.edmLinks
    }
    connectServer({
      path: GET_EDM_VERIFICATION_LINKS,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setLinkVerifiedData(res.data)
          setIsShow(true)
        }
      },
      fail: err => {
      }
    })
  }

  return (
    <>
      <Modal
        show={isShow}
        onHide={() => {
          setIsShow(!isShow);
          props.onChangeIsOpen(false);
        }}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h2>Link verification</h2>
          {/* <div
            className="back-text"
            onClick={() => {
              setIsShow(!isShow);
              props.onChangeIsOpen(false);
            }}
          >
            <i className="icon-close-small icon-mini blue" />
          </div> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            {linkVerifiedData && linkVerifiedData.map((item, index) => {
              const { linkURL, linkStatus } = item;
              return (
                <Row className="p10">
                  <Col>
                    <ul>
                      <li style={{ overflowWrap: 'break-word' }} key={index}>{linkURL}</li>
                    </ul>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end">
                    <i
                      className={
                        linkStatus
                          ? "icon-sd-tick-medium icons-md green"
                          : "icon-sd-circle-close-medium icons-md maroon-medium"
                      }
                    ></i>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container d-flex justify-content-end mt20">
            <RSPrimaryBtn onClick={() => {
              getEdmVerificationData()
            }}>Reverify</RSPrimaryBtn>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LinkVerificationModal;

