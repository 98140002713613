// Mapping of step names to colors.
// custom edit

// data
export default {
        name: "SmartDX",
        children: [
            {
                name: 'Awareness', "size": 532, children: [
                    { name: 'Los Angeles', "size": 342, children: [
                        { name: 'Mobile', children: [
                            { name: 'Known', "size": 436 },
                            { name: 'Unknown', "size": 236 }
                        ] },
                        { name: 'Browser', "size": 1325, children: [
                            { name: 'Known', "size": 834 },
                            { name: 'Unknown', "size": 873 }
                        ] },
                        { name: 'Tablet', "size": 345, children: [
                            { name: 'Unknown', "size": 434 }
                        ] }
                    ] },
                    { name: 'Chicago', "size": 2833, children: [
                        { name: 'iPad', "size": 642, children: [
                            { name: 'Known', "size": 642 },
                            { name: 'Unknown', "size": 832 }
                        ] }
                    ] }
                ]
            },
            { name: 'Greetings', "size": 1445,
                children: [
                    { name: 'California', "size": 145, children: [
                        { name: 'Mobile', children: [
                            { name: 'Known', "size": 347 },
                            { name: 'Unknown', "size": 369 }
                        ] },
                        { name: 'Tablet', "size": 737, children: [
                            { name: 'Unknown', "size": 864 }
                        ] }
                    ] },
                    { name: 'Illinois', "size": 545, children: [
                        { name: 'iPad', "size": 674, children: [
                            { name: 'Known', "size": 115 },
                            { name: 'Unknown', "size": 262 }
                        ] }
                    ] }
                ]
            },
            { name: 'New product launch', "size": 845, children: [
                { name: 'California', "size": 245, children: [
                        { name: 'Mobile', children: [
                            { name: 'Known', "size": 234 },
                            { name: 'Unknown', "size": 126 }
                        ] },
                        { name: 'Tablet', "size": 521, children: [
                            { name: 'Unknown', "size": 326 }
                        ] }
                    ]
                } ]
            },
            { name: 'Promotion', "size": 1445, children: [
                    { name: 'California', "size": 1245, children: [
                        { name: 'Mobile', children: [
                            { name: 'Known', "size": 276 },
                            { name: 'Unknown', "size": 369 }
                        ] },
                        { name: 'Tablet', "size": 521, children: [
                            { name: 'Unknown', "size": 369 }
                        ] }
                    ] }
                ]
            },
            { name: 'Sale', "size": 1445, children: [
                { name: 'Chicago', "size": 2343, children: [
                    { name: 'iPad', "size": 842, children: [
                        { name: 'Known', "size": 234 },
                        { name: 'Unknown', "size": 369 }
                    ] }
                ] }
            ] },
            { name: 'Events', "size": 1445, children: [
                    { name: 'Los Angeles', "size": 652, children: [
                            { name: 'Mobile', children: [
                                { name: 'Known', "size": 322 },
                                { name: 'Unknown', "size": 369 }
                            ] },
                            { name: 'Browser', "size": 135, children: [
                                { name: 'Known', "size": 235 },
                                { name: 'Unknown', "size": 369 }
                            ] },
                            { name: 'Tablet', "size": 521, children: [
                                { name: 'Unknown', "size": 369 }
                            ] }
                        ]
                    },
                    { name: 'Illinois', "size": 545, children: [
                        { name: 'iPad', "size": 521, children: [
                            { name: 'Known', "size": 276 },
                            { name: 'Unknown', "size": 369 }
                        ] }
                    ] }
                ]
            }
        ]
}