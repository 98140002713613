import React, { Component, useState } from "react"
import { Container, Modal, Row } from "react-bootstrap"
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import jsonData from "./NotificationDetails.json"
import '@progress/kendo-react-intl'
import { process } from '@progress/kendo-data-query'
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper"
import { withRouter } from "react-router-dom";
import { BootstrapDropdown } from "Components/BootstrapDropdown/BootstrapDropdown"
import RSPDateRangePicker from "Components/DateRangePicker/RSPDateRangePicker"
import { GET_ALERT_NOTIFICATION } from "Helper/Constants/endPoint"
import SkeletonCampaignList from "Components/Skeleton/SkeletonCampaignList"
import { connectServer } from "Helper/Network/networkHandler"
import { dateTimeFormatWithMomentForReports, getAuthUserId, getUserData } from "Helper/Utils/Utils"
import authContext from "Helper/StateHandler/auth-context"
import { RSPrimaryBtn } from "Components/RSButtons"
import { orderBy } from "@progress/kendo-react-data-tools"

const CustomCell = props => {
    return <td>
        <div className="d-flex align-items-center justify-content-between notificationDetails">
            <div className="d-flex align-items-center float-start ">
                <div className={`pull-left`}>
                    {/* <i className={`circle-view-icon ${props.dataItem.icon ? props.dataItem.icon : 'icon-alert-fill-small'} icons-md ${props.dataItem.statusIndicate}`}></i> */}
                    <i className={`circle-view-icon ${campaignProgressStatus[props.dataItem.notificationTypeID].icon} ${campaignProgressStatus[props.dataItem.notificationTypeID].color}`}></i>
                </div>
                <div className="ml8 description">{props.dataItem.description}</div>
            </div>
            {/* <div className="float-end">
                <i className="icon-sd-circle-info-medium icons-md blue cp" onClick={() => {
                }}></i>
            </div> */}
        </div>
    </td >
}
const campaignProgressStatus = {
    1: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    2: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    3: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    4: { color: 'bgGreen-medium', icon: 'icon-sd-circle-tick-medium icons-md' },
    5: { color: 'bgGreen', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    6: { color: 'bgGreen', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    7: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    8: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    9: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    10: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    11: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    12: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    13: { color: 'bgRed', icon: 'icon-sd-alert-medium icons-md' },
    14: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    15: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    16: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    17: { color: 'bgOrange', icon: 'icon-sd-close-medium icons-md' },
    18: { color: 'bgOrange', icon: 'icon-sd-in-progress-medium icons-md white line-height-xs position-relative top1 left1' },
    19: { color: 'bgOrange', icon: 'icon-sd-fire-medium icons-md' },
    20: { color: 'bgOrange', icon: 'icon-sd-fire-medium icons-md' }
}

const createDataState = (dataState, list) => {
    return {
        // result: process(list.reverse().slice(0), dataState),
        result: process(list.slice(0), dataState),
        dataState: dataState
    }
}

class NotificationDetails extends Component {
    static contextType = authContext
    initialDataState = {
        skip: 0,
        take: 5
    }
    state = {
        result: null,
        userList: [],
        dataState: createDataState(this.initialDataState, []).dataState,
        skip: 0, take: 5,
        listError: false,
        showdateRange: true,
        isShowDateRangePicker: false,
        modalInfo: false,
        totalCount: 0,
        fetchDataParams: {},
        sort: [{
            field: "createdDate",
            dir: "desc",
        }]
    }
    componentDidMount = props => {
        window.scrollTo(0, 0);
        let fetchParams = this.getParams(undefined);
        this.setState({ fetchDataParams: fetchParams })
        this.getAlertNotification(fetchParams, this.state.sort)
    }

    getParams = (searchData) => {
        let params = {
            endDate: searchData !== undefined && searchData.endDate !== '' ? searchData.endDate : new Date(),
            startDate: searchData !== undefined && searchData.startDate !== undefined ? searchData.startDate : new Date(getUserData()?.activationDate),
            userId: getAuthUserId(),
            pageNumber: searchData !== undefined && searchData.pageNumber !== undefined ? searchData.pageNumber : 1,
            itemsPerPage: searchData !== undefined && searchData.itemsPerPage !== undefined ? searchData.itemsPerPage : 5
        }
        return params
    }

    getAlertNotification = (params, sort) => {
        this.setState({ userList: [], listError: false })
        connectServer({
            path: GET_ALERT_NOTIFICATION,
            params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status && res.data?.alertNotifications?.length) {
                    // let sortedArry = res.data?.alertNotifications?.sort((a, b) => a.notificationID > b.notificationID ? 1 : -1).reverse()
                    let sortedArry = orderBy(res.data?.alertNotifications, sort);
                    let currentState = createDataState({
                        take: this.state.dataState.take,
                        skip: 0
                    }, sortedArry);

                    this.setState({
                        // dataState: currentState.dataState,
                        userList: sortedArry,
                        result: currentState.result,
                        listError: false,
                        totalCount: res.data.totalRowCount
                    })
                } else {
                    this.setState({ listError: true })
                }
            },
            fail: error => {
                this.setState({ listError: true })
            }
        })
    }
    closeDateRangePicker = (status, dates) => {
        if (!status) {
            let tempParams = { ...this.state.fetchDataParams };
            tempParams.endDate = new Date(dates.end);
            tempParams.startDate = new Date(dates.start);
            tempParams.pageNumber = 1;
            tempParams.itemsPerPage = 5;
            this.setState({ fetchDataParams: tempParams, dataState: createDataState(this.initialDataState, []).dataState });
            this.getAlertNotification(tempParams, this.state.sort)
        }
        this.setState({ isShowDateRangePicker: status })
    }
    dataStateChange = event => {
        const { skip, take } = event.dataState;
        const currentPage = Math.floor(skip / take + 1)
        this.setState({
            result: process(this.state.userList, event.dataState),
            dataState: event.dataState
        });
        let tempParams = { ...this.state.fetchDataParams };
        tempParams.pageNumber = currentPage;
        tempParams.itemsPerPage = take;
        this.getAlertNotification(tempParams, event.dataState.sort)
        this.setState({ fetchDataParams: tempParams, sort: event.dataState.sort });
    }

    UserDetails = props => {
        const MyCustomCell = (props) => <CustomCell {...props} />
        return <>
            <div className="d-flex justify-content-between mt15 mb15">
                <div></div>
                <div className="d-flex align-items-center">
                    <RSPDateRangePicker
                        class=""
                        isShowDateRangePicker={this.state.isShowDateRangePicker}
                        show={this.state.showdateRange}
                        enable={true}
                        closeDateRangePicker={(status, date) => this.closeDateRangePicker(status, date)}
                        cancelBtnClicked={() => {
                            this.setState({ isShowDateRangePicker: false })
                        }} />
                </div>
            </div>
            {this.state.userList.length > 0 ?
                <>
                    {/* <KendoGrid
                        data={this.state.result}
                        scrollable={'scrollable'}
                        column={CONFILTER}
                        pageable
                        settings={{
                            total: listData?.length
                        }}
                    /> */}
                    <Grid
                        data={this.state.result}
                        {... this.state.dataState}
                        total={this.state.totalCount}
                        onDataStateChange={this.dataStateChange}
                        scrollable={"none"}
                        sortable={true}
                        sort={this.state.sort}
                        className="grid-pagenation-del grid-replaceable"
                        messages={{
                            itemsPerPage: 'data items per page'
                        }}
                        pageable={{
                            //type: 'input',
                            pageSizes: [5, 10, 20],
                            previousNext: true,
                        }}
                    >
                        <GridColumn field="createdDate" title="Date/Time" cell={DateCell} />
                        <GridColumn field="description" title="Description" cell={MyCustomCell} />
                        <GridColumn field="module" title="Module" />
                        <GridColumn field="status" title="Status" />
                    </Grid>
                </>
                : <div className="p0"><SkeletonCampaignList isError={this.state.listError} /></div>
            }
            <Modal
                show={this.state.modalInfo}
                backdrop="static"
                keyboard={false}
                onHide={() => {
                    this.setState({
                        modalInfo: !this.state.modalInfo
                    })
                    // props.buttonClicked(false)
                }}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <h2>Notification details</h2>
                </Modal.Header>
                <Modal.Body>
                    <p className="lh26">Email - Campaign Blasting completed for Campaign Lead Generate Communication Form Based - 04Aug2022</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end marginT20">
                        <RSPrimaryBtn onClick={() => {
                            // setShow(false)
                            // props.buttonClicked(true)
                            this.setState({
                                modalInfo: !this.state.modalInfo
                            })
                        }} variant="outline-success">
                            Ok
                        </RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    }

    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">

                    <div className="header-title d-flex justify-content-between">
                        <h1>Notification details</h1>
                        {/* <div>
                            <BootstrapDropdown
                                data={this.state.brandData}
                                defaultItem={"Vision Bank USA"}
                                customAlignRight={true}
                                className="fs18-btn"
                                onSelect={(item) => {
                                }}
                            />
                            <BootstrapDropdown
                                data={this.state.brandTypeData}
                                defaultItem={"Personal"}
                                customAlignRight={true}
                                className="fs18-btn ml15"
                                onSelect={(item) => {
                                }}
                            />
                        </div> */}
                    </div>
                    <hr className="blue" />
                    <this.UserDetails />
                </Container>
            </LayoutPageWrapper>
        )
    }
}
export default withRouter(NotificationDetails);

const DateCell = (props) => {
    return (
        <td>
            {dateTimeFormatWithMomentForReports(props.dataItem[props.field])}
        </td>
    )
}
