import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2, RSCheckbox } from 'Components/RSInputs';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { RSDropdownList } from 'Components/RSDropdowns';
import { Switch } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import FilterList from './FilterList';
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import EditFilterArray from './EditFilterArray';
import { getAuthUserId, getUserData, numberFormatter } from 'Helper/Utils/Utils';
import { CHECK_IS_LIST_NAME_EXISTS, GET_ATTRIBUTEVALUES_BY_ATTRIBUTENAME, GET_DROPDOWN_VALUES, GET_RS_AUDIENCE_COUNT, GET_SEGMENTATION_LEFTPANEL_LIST, GET_SEGMENTATION_LIST_DETAILS, SAVE_UPDATE_TARGET_LIST } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { connectServer } from 'Helper/Network/networkHandler';
import * as constant from './Constant';
import VSCheckbox from './Components/VSCheckBox';
import { VSKendoIconDropdown } from './Components/VSKendoDropdown';
import { LIST_NAME_LIMIT, UPDATE_LIST_NAME } from 'Helper/Constants/validationMessage';
// import { RSCheckbox } from 'Components/RSInputs2';
import expand from 'Assets/Images/expand-all-mini.svg';
import collapse from 'Assets/Images/collapse-all-mini.svg';
import { PlainSearchView } from 'Components/SearchView/SearchView';
import { filterBy } from "@progress/kendo-data-query";
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import moment from 'moment-timezone'
import AudiencePaymentModal from './Components/AudiencePaymentModal';
import ExpandAllMini from 'Components/SVG/ExpandAllMini';
import { SuccessModal } from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal';


const defaultValue = new Date();
export const TargetListContext = React.createContext();
const SegmentationCreationRS = props => {

    let history = useHistory();
    const context = useContext(authContext)
    const [listName, setListName] = useState("");
    const [update, setUpdate] = useState(0);
    const [activationKey, setActivationKey] = useState("0");
    const [totalAudience, setTotalAudience] = useState(0);
    const [accordionData, setAccordionData] = useState([0]);
    const [conditionType, setConditionType] = useState("");
    const [conditionValue, setConditionValue] = useState("");
    const [betweenCondition, setBetweenCondition] = useState("");
    const [betweenConditionFlag, setBetweenConditionFlag] = useState(true);
    const [audienceCount, setAudienceCount] = useState(0);
    const [isFilterBtnClicked, setIsFilterBtnClicked] = useState(false);
    const [customerAttributes, setCustomerAttributes] = useState(CustomerAttributes);
    const [campaignAttributes, setCampaignAttributes] = useState(CampaignAttributes);
    const [fingerprintAttributes, setFingerprintAttributes] = useState(FingerprintAttributes);
    const [othersAttributes, setOthersAttributes] = useState(OthersAttributes);
    const [filterArray, setFilterArray] = useState({ "inclusion": false, "exclusion": false, "subscribeList": { "filterList": [] } });
    const [activeList, setActiveList] = useState("filterList");
    const [inclusionOrExclusionData, setInclusionOrExclusionData] = useState([{ text: "Inclusion", disabled: false, list: "inclusionList" }, { text: "Exclusion", disabled: false, list: "exclusionList" }])
    const [arrayDetails, setArrayDetails] = useState([{
        "title": "Customer",
        "value": customerAttributes
    },
    {
        "title": "Campaign",
        "value": campaignAttributes
    },
    {
        "title": "Fingerprint",
        "value": fingerprintAttributes
    },
    {
        "title": "Others",
        "value": othersAttributes
    }]);

    const [leftPanelAttributes, setLeftPanelAttributes] = useState([])
    const [attributeData, setAttributeData] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [attributeTypes, setAttributeTypes] = useState([])
    const [isListField, setIsListField] = useState(false);
    const [isExpandedAll, setIsExpandedAll] = useState(true);
    const [activeKey, setActiveKey] = useState(0);
    const [searchedAttribute, setSearchedAttribute] = React.useState('');
    const [searchAttribute, setSearchAttribute] = React.useState([]);
    const [filterGroups, setFilterGroups] = useState({
        groups: ['filterLists'],
        activeGroup: 'filterLists',
        disableGroups: [],
        exclusionListGroup: false,
    })
    const [zeroDayLists, setZeroDayLists] = useState([]);
    const [inclusionLists, setInclusionLists] = useState([]);
    const [exclusionLists, setExclusionLists] = useState([]);
    const [filterLists, setFilterLists] = useState([]);
    const [isValidListname, setIsValidListname] = useState(false);
    const [listNameErrMsg, setListNameErrMsg] = useState(null);
    const [isCheckVersiumEmail, setIsCheckVersiumEmail] = useState(true);
    const [isCheckVersiumMobileNo, setIsCheckVersiumMobileNo] = useState(true);
    const [isCheckVersiumAddress, setIsCheckVersiumAddress] = useState(false);
    const [isCheckExtractionLimit, setIsCheckExtractionLimit] = useState(false);
    const [isCheckPoliticalDonor, setIsCheckPoliticalDonor] = useState(false);
    const [isCheckDemoGraphics, setIsCheckDemoGraphics] = useState(false);
    const [isCheckHouseFinAuto, setIsCheckHouseFinAuto] = useState(false);
    const [isCheckLifeStyleInterest, setIsCheckLifeStyleInterest] = useState(false);
    const [extractionLimit, setExtractionLimit] = useState("");
    const [errMsgExtractionLimit, setErrMsgExtractionLimit] = useState(null);
    const [isShowTickSymbol, setIsShowTickSymbol] = useState(false);
    const [keysErrMsg, setKeysErrMsg] = useState(null);
    const [attributesDropdownData, setAttributesDropdownData] = useState(["All", "Partner Data - Versium"]);
    const [attributeDropdownItem, setAttributeDropdownItem] = useState("All")
    const [isOpenPaymentSummaryModal, setIsOpenPaymentSummaryModal] = useState(false);
    const [segmentationListDetails, setSegmentationListDetails] = useState("");
    const [masterDropDownValues, setMasterDropDownValues] = useState([])
    const [isEditable, setIsEditable] = useState(false);
    const [isVersiumReach, setIsVersiumReach] = useState(true);
    const [isOpenLimitModal, setIsOpenLimitModal] = useState(false);

    const contextValue = {
        leftPanelAttributes,
        attributeData,
        attributes,
        attributeTypes,
    };

    useEffect(() => {

        getMasterDropDownValues();


    }, [])
    let { subscribeList: { filterList } } = filterArray;
    const getExistingListDetails = (listId, masterDropDown) => {
        let params = {
            "segmentationListID": listId,
            "departmentID": 0
        }
        connectServer({
            path: GET_SEGMENTATION_LIST_DETAILS,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    // let expressionString = "{\"Expressions\":[{\"SequenceId\":1,\"Category\":\"sql\",\"Field\":\"d_education\",\"Value\":\"\'B\',\'C\'\",\"ConditionOperator\":\"contains\",\"ExpressionOperator\":null,\"QueryType\":\"hsearch\",\"LiId\":\"filterBuildComp0\",\"ParentdivId\":\"crbGroupContainer\",\"ParentChildIdentify\":\"\",\"SOLRFieldName\":\"Versium_d_education_s\",\"SOLRCountValue\":1,\"LiwaterfallCount\":\"15244391\",\"versiumCreditCount\":\"30488782\",\"LiremainingCount\":\"0\",\"LiarithmeticSymbol\":\"-\",\"LiUIPrintableName\":\"Education Level\",\"WithInOperator\":null,\"WithInOperatorCountdisable\":null,\"WithInOperatorCountStart\":null,\"WithInOperatorCountEnd\":null,\"restype\":\"\",\"ParentSOLRFieldName\":null,\"SeqNo\":0,\"SourceFrom\":null,\"IsVirtualField\":false}],\"GroupingOperator\":\"And\",\"FilterGroup\":\"OriginalBase\",\"TotalIncusionQuery\":\"\",\"TotalInclusionCount\":\"\",\"IsLastLi\":false,\"LiIndex\":\"\"}"
                    // let editExpression = JSON.parse(expressionString)
                    setIsEditable(res.data.isEditable)
                    let editExpression = JSON.parse(res.data.expressionJson)
                    editExpression.expressions.map((attribute, index) => {
                        let tempAttribute = {
                            "sequenceId": attribute.sequenceId,
                            "category": attribute.category,
                            "field": attribute.field,
                            "value": attribute.value,
                            "conditionOperator": attribute.conditionOperator,
                            "expressionOperator": null,
                            "queryType": "hsearch",
                            "liId": attribute.liId,
                            "parentdivId": "crbGroupContainer",
                            "sOLRFieldName": attribute.sOLRFieldName,
                            "parentChildIdentify": "",
                            "sOLRCountValue": "1",
                            "liarithmeticSymbol": "-",
                            "liremainingCount": "0",
                            "liwaterfallCount": "--",
                            "liUIPrintableName": attribute.liUIPrintableName,
                            "restype": "",
                            "isVirtualField": false,
                            "valueType": attribute.valueType,
                            "conditionTypeValues": attribute.conditionTypeValues,
                            "conditionType": attribute.conditionType,
                            "conditionValue": attribute.conditionValue,
                            "textConditionValue": attribute.textConditionValue,
                            "fromValue": attribute.fromValue,
                            "endValue": attribute.endValue,
                            "endMinValue": attribute.endMinValue,
                            "audienceCount": attribute.liwaterfallCount,
                            "versiumCreditCount": attribute.versiumCreditCount,
                            "liwaterfallCount": attribute.liwaterfallCount,
                            "isRunBtnClicked": true,
                            "isAdded": true,
                            "conditionTypeErr": null,
                            "conditionValueErr": null,
                            "fromValueError": null,
                            "endValueError": null
                        }
                        let dropdownValues = masterDropDown.filter(item => item.key === attribute.sOLRFieldName)[0]?.value;
                        tempAttribute.dropdownValues = dropdownValues;
                        tempAttribute.masterDropdownValues = dropdownValues;
                        filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                        setFilterArray({ ...filterArray });
                    })
                    setListName(res.data.recipientsBunchName.substring(3))
                    setAudienceCount(res?.data?.potentialAudience)
                    setIsCheckVersiumEmail(res.data.partnerVal.includes("cbemail"))
                    setIsCheckVersiumMobileNo(res.data.partnerVal.includes("cbphone"))
                    setIsCheckVersiumAddress(res.data.partnerVal.includes("cbaddress"))
                    setIsCheckExtractionLimit(res.data.partnerVal.includes("cbLimit"))
                    setIsCheckDemoGraphics(res.data.selectedCatType?.includes("b2cDemographic"))
                    setIsCheckHouseFinAuto(res.data.selectedCatType?.includes("b2cHouseFinAuto"))
                    setIsCheckLifeStyleInterest(res.data.selectedCatType?.includes("b2cLifestyleInterest"))
                    setIsCheckPoliticalDonor(res.data.selectedCatType?.includes("b2cPoliticalDonor"))
                    let partnerValue = res.data.partnerVal.split(",");
                    setExtractionLimit(res.data.partnerVal.includes("cbLimit") ? numberFormatter(partnerValue[partnerValue.length - 1]) : "")
                } else {
                }
            },
            fail: err => {

            }
        })
    }
    const checkIsNameExists = (listName) => {
        let params = {
            "segmentationName": listName,
            "departmentId": 0,
            "segmentationListId": segmentationListDetails?.segmentationListID || history.location.state.listId || "0"
        }
        connectServer({
            path: CHECK_IS_LIST_NAME_EXISTS,
            params: params,
            // loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (!res.status) {
                    setListNameErrMsg("List name already exists")
                    setIsValidListname(false);
                    setIsShowTickSymbol(false);
                } else {
                    if (filterArray['subscribeList'][activeList].length === 0) {
                        addStateAttribute();
                    }
                    setListNameErrMsg(null)
                    setIsValidListname(true);
                    setIsShowTickSymbol(true);
                }
            },
            fail: err => {

            }
        })
    }
    const addStateAttribute = () => {
        if (validation()) {
            let attribute = null;
            let tempAllAttributes = [...attributeData];
            tempAllAttributes[0]["Partner Data Versium"].map((aItem, aIndex) => {
                if (aItem.solrFieldName === "Versium_d_state[]_s") {
                    attribute = aItem;
                }
            })

            if (filterArray['subscribeList'][activeList].length < 25) {
                let tempAttribute = {
                    "sequenceId": filterArray['subscribeList'][activeList].length + 1,
                    "category": attribute.category,
                    "field": attribute.name,
                    "value": "'Nikiski, AK','Naknek, AK'",
                    "conditionOperator": "",
                    "expressionOperator": null,
                    "queryType": "hsearch",
                    "liId": "filterBuildComp" + filterArray['subscribeList'][activeList].length.toString(),
                    "parentdivId": "crbGroupContainer",
                    "sOLRFieldName": attribute.solrFieldName,
                    "parentChildIdentify": "",
                    "sOLRCountValue": "1",
                    "liarithmeticSymbol": "-",
                    "liremainingCount": "0",
                    "liwaterfallCount": "--",
                    "liUIPrintableName": attribute.labelText,
                    "restype": "",
                    "isVirtualField": false,
                    "valueType": constant.getValueType(attribute.fieldType, attribute.partnerType),
                    "conditionTypeValues": constant.getConditionTypeValues(attribute.fieldType, attribute.partnerType),
                    "dropdownValues": [],
                    "masterDropdownValues": [],
                    "conditionType": constant.getConditionValue(attribute.fieldType, attribute.partnerType),
                    "conditionValue": [],
                    "textConditionValue": "",
                    "fromValue": "",
                    "endValue": "",
                    "endMinValue": "",
                    "audienceCount": 0,
                    "versiumCreditCount": 0,
                    "isRunBtnClicked": false,
                    "isAdded": true,
                    "conditionTypeErr": null,
                    "conditionValueErr": null,
                    "fromValueError": null,
                    "endValueError": null
                }
                if (attribute.fieldType === "1" || attribute.fieldType === "5" || (attribute.fieldType === "9" && attribute.partnerType === "categoricalRange")) {
                    let dropdownValues = masterDropDownValues.filter(item => item.key === attribute.solrFieldName)[0].value;
                    let conditionValue = dropdownValues.filter(item => item.displayLabel === getUserData().clientDetailsLocalization.state);
                    tempAttribute.conditionValue = conditionValue;
                    tempAttribute.dropdownValues = dropdownValues;
                    tempAttribute.masterDropdownValues = dropdownValues;
                    filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                    setFilterArray({ ...filterArray });
                    // getAttributeValues(attribute.solrFieldName, getDropdownValues => {
                    //     dropdownValues = getDropdownValues;
                    //     tempAttribute.dropdownValues = dropdownValues;
                    //     tempAttribute.masterDropdownValues = dropdownValues;
                    //     // let filtertemp = { "conditionType": "", "conditionValue": "", "betweenCondition": "", "dropdownValues1": item.dropdownValues1, "isRunBtnClicked": false, "secondConditionValue": "", "multiSelectValues": [] };
                    //     filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                    //     setFilterArray({ ...filterArray });
                    // })
                } else {
                    filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                    setFilterArray({ ...filterArray });
                }
            }
        }
    }
    const getAttributeValues = (attributeName, getDropdownValues) => {
        let params = {
            "attributeName": attributeName,
            "departmentId": 0
        }
        connectServer({
            path: GET_ATTRIBUTEVALUES_BY_ATTRIBUTENAME,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    getDropdownValues(res.data);
                }
            },
            fail: err => {

            }
        })
    }
    const getMasterDropDownValues = () => {
        const { location: { state: { mode, listId } } } = history;
        let params = {
            "departmentId": 0,
            "dbName": getUserData().clientDetailsLocalization.instanceName
        }
        connectServer({
            path: GET_DROPDOWN_VALUES,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {

                    // let masterDropDownValues = JSON.parse(res.data)
                    let masterDropDownValues = res.data
                    let masterDropDown = [];
                    masterDropDownValues.map((item, index) => {
                        let keyid = Object.keys(item)[0];
                        let key = keyid;
                        let value = item[keyid];
                        let convertedObject = {
                            "key": key,
                            "value": value
                        };
                        masterDropDown.push(convertedObject)
                    })
                    getLeftPanelAttributes();
                    if (mode === 'edit') {
                        filterArray['subscribeList'][activeList] = [];
                        setFilterArray({ ...filterArray });
                        getExistingListDetails(listId, masterDropDown)
                    }
                    let tempExpression = [...filterArray['subscribeList'][activeList]];
                    tempExpression?.map((item, index) => {
                        let dropdownValues = masterDropDown.filter(ditem => ditem.key === item.sOLRFieldName)[0]?.value;
                        item.dropdownValues = dropdownValues;
                        item.masterDropdownValues = dropdownValues;
                    });
                    filterArray['subscribeList'][activeList] = tempExpression;
                    setFilterArray({ ...filterArray });
                    setMasterDropDownValues(masterDropDown);
                }
            },
            fail: err => {
                getLeftPanelAttributes();
                if (mode === 'edit') {
                    filterArray['subscribeList'][activeList] = [];
                    setFilterArray({ ...filterArray });
                    getExistingListDetails(listId)
                }
            }
        })
    }
    const SaveorUpdateList = (filterArray) => {
        let isValidate = true;
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        tempExpression.map((item, index) => {
            let tempValue = [];
            // item.category = "sql"
            item.dropdownValues = [];
            item.masterDropdownValues = [];
            if (item.valueType === "String" || item.valueType === "Boolean") {
                item.conditionValue.map((val, valIndex) => {
                    tempValue.push(val.value)
                })
                if (Array.isArray(tempValue) && tempValue.length > 0) {
                    tempValue = ("'" + tempValue.join("','") + "'").toString()
                }
                item.value = tempValue;
            } else if (item.valueType === "CategoricalRange") {
                if (item.conditionValue.length % 2 === 0) {
                    for (let i = 0; i < item.conditionValue.length; i++) {
                        tempValue.push(item.conditionValue[i].value + "-" + item.conditionValue[i + 1].value);
                        i = i + 1
                    }
                    if (Array.isArray(tempValue) && tempValue.length > 0) {
                        tempValue = ("'" + tempValue.join("','") + "'").toString()
                    }
                    item.value = tempValue;
                } else {
                    isValidate = false
                    return
                }
            } else if (item.valueType === "Range") {
                let tempValue = "'" + item.fromValue + "-" + item.endValue + "'"
                item.value = tempValue.toString();
            } else if (item.valueType === "DateTime") {
                let fromDate = new Date(item.fromValue);
                let endDate = new Date(item.endValue);
                let fromMomentDate = moment(fromDate,).format('YYYYMMDD');
                let endMomentDate = moment(endDate,).format('YYYYMMDD');
                let tempValue = "'" + fromMomentDate + "-" + endMomentDate + "'"
                item.value = tempValue.toString();
            } else if (item.valueType === "Text") {
                item.textConditionValue.split(",").map((val, valIndex) => {
                    tempValue.push(val)
                })
                if (Array.isArray(tempValue) && tempValue.length > 0) {
                    tempValue = ("'" + tempValue.join("','") + "'").toString()
                }
                item.value = tempValue;
            } else {
                item.value = item.conditionValue;
            }
        })
        let patnerValue = [];
        if (isCheckVersiumEmail) {
            patnerValue.push("cbemail")
        }
        if (isCheckVersiumMobileNo) {
            patnerValue.push("cbphone")
        }
        if (isCheckVersiumAddress) {
            patnerValue.push("cbaddress")
        }
        if (isCheckExtractionLimit) {
            patnerValue.push("cbLimit")
            if (extractionLimit === "") {
                isValidate = false;
                setErrMsgExtractionLimit("Enter extraction limit")
            } else {
                let tempExtractionLimit = extractionLimit.replaceAll(",", "");
                let value = parseInt(tempExtractionLimit, 10);
                let errMsg = (value < 1 || value > 2000000) ? "Extraction limit should be 1 to 20,00,000" : value <= audienceCount ? null : "Extraction limit should be less than potential audience";
                isValidate = errMsg === null ? true : false;
                patnerValue.push(tempExtractionLimit);
                setErrMsgExtractionLimit(errMsg)
            }
        } else if (audienceCount > 2000000) {
            isValidate = false;
            setIsOpenLimitModal(true)
        }
        patnerValue = (patnerValue.join(",")).toString()
        let catType = [];
        if (isCheckDemoGraphics) {
            catType.push("b2cDemographic")
        }
        if (isCheckHouseFinAuto) {
            catType.push("b2cHouseFinAuto")
        }
        if (isCheckLifeStyleInterest) {
            catType.push("b2cLifestyleInterest")
        }
        if (isCheckPoliticalDonor) {
            catType.push("b2cPoliticalDonor")
        }
        catType = (catType.join(",")).toString()
        if (isValidate) {
            let params = {
                "originalBaseExprVal": {
                    "expressions": tempExpression,
                    "groupingOperator": "And",
                    "filterGroup": "OriginalBase",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "lookALikeExprVal": {
                    "expressions": [],
                    "groupingOperator": "And",
                    "filterGroup": "LookALike",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "00",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "lookALikeAudienceExprVal": {
                    "expressions": [],
                    "groupingOperator": "And",
                    "filterGroup": "LookALikeAudience",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "00",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "filterGroup2ExprVal": {
                    "expressions": [],
                    "groupingOperator": "",
                    "filterGroup": "MultipleFilter",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "0",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "exclusion1ExprVal": {
                    "expressions": [],
                    "groupingOperator": "NOT",
                    "filterGroup": "ExclusionFilter",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "0",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "ruleCondition": "",
                "departmentId": 0,
                "partnerVal": patnerValue,
                "targetFilterGroup": "All",
                "segmentationName": listName,
                "lAApercentage": 0,
                "segmentationListId": segmentationListDetails?.segmentationListID || history.location.state.listId || "0",
                "segmentationSchedulerID": segmentationListDetails?.segmentationSchedulerID || "0",
                "recipientCount": "0",
                "isRequestApproval": false,
                "zerodaycampaign": "\"\"",
                "requestApproval": null,
                "dbname": getUserData()?.clientDetailsLocalization?.instanceName,
                "isSaveLaterFlag": false,
                "selectedCattype": catType

            }
            connectServer({
                path: SAVE_UPDATE_TARGET_LIST,
                params: params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: res => {
                    if (res.status) {
                        // 
                        setSegmentationListDetails(res.data)
                        // setIsOpenPaymentSummaryModal(true)
                        let temp = { ...res.data }
                        temp.isDownload = false;
                        if (getUserData().clientDetailsLocalization.isPartnerAccount) {
                            history.push({ pathname: "/audience", state: { index: 0 } });
                        } else {
                            history.push({ pathname: `/audience/audience-payment-summary`, state: { segListId: temp } });
                        }
                    }
                },
                fail: err => {

                }
            })
        }
    }
    const getAudienceCount = (filterArray) => {
        let isValidate = true;
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        tempExpression.map((item, index) => {
            let tempValue = [];
            item.sequenceId = index + 1
            item.dropdownValues = [];
            item.masterDropdownValues = [];
            item.liId = "filterBuildComp" + index.toString()
            if (item.valueType === "String" || item.valueType === "Boolean") {
                item.conditionValue.map((val, valIndex) => {
                    tempValue.push(val.value)
                })
                if (Array.isArray(tempValue) && tempValue.length > 0) {
                    tempValue = ("'" + tempValue.join("','") + "'").toString()
                }
                item.value = tempValue;
            } else if (item.valueType === "CategoricalRange") {
                if (item.conditionValue.length % 2 === 0) {
                    for (let i = 0; i < item.conditionValue.length; i++) {
                        tempValue.push(item.conditionValue[i].value + "-" + item.conditionValue[i + 1].value);
                        i = i + 1
                    }
                    if (Array.isArray(tempValue) && tempValue.length > 0) {
                        tempValue = ("'" + tempValue.join("','") + "'").toString()
                    }
                    item.value = tempValue;
                } else {
                    isValidate = false
                    return
                }
            } else if (item.valueType === "Range") {
                if (item.fromValue !== "" && item.endValue !== "") {
                    if (parseInt(item.fromValue, 10) < parseInt(item.endValue, 10)) {
                        let tempValue = "'" + item.fromValue + "-" + item.endValue + "'"
                        item.value = tempValue.toString();
                    } else {
                        item.endValueError = "Enter value less than from value";
                        isValidate = false;
                        setUpdate(update + 1)
                    }
                } else {
                    item.fromValueError = item.fromValue === "" ? "Enter from value" : null
                    item.endValueError = item.endValue === "" ? "Enter end value" : null
                    isValidate = false;
                    setUpdate(update + 1)
                }

            } else if (item.valueType === "DateTime") {
                let fromDate = new Date(item.fromValue);
                let endDate = new Date(item.endValue);
                let fromMomentDate = moment(fromDate,).format('YYYYMMDD');
                let endMomentDate = moment(endDate,).format('YYYYMMDD');
                let tempValue = "'" + fromMomentDate + "-" + endMomentDate + "'"
                item.value = tempValue.toString();
            } else if (item.valueType === "Text") {
                item.textConditionValue.split(",").map((val, valIndex) => {
                    tempValue.push(val)
                })
                if (Array.isArray(tempValue) && tempValue.length > 0) {
                    tempValue = ("'" + tempValue.join("','") + "'").toString()
                }
                item.value = tempValue;
            } else {
                item.value = item.conditionValue;
            }
        })
        let patnerValue = [];
        if (isCheckVersiumEmail) {
            patnerValue.push("cbemail")
        }
        if (isCheckVersiumMobileNo) {
            patnerValue.push("cbphone")
        }
        if (isCheckVersiumAddress) {
            patnerValue.push("cbaddress")
        }
        if (isCheckExtractionLimit) {
            patnerValue.push("cbLimit")
            patnerValue.push(extractionLimit.replaceAll(",", ""));
        }
        patnerValue = (patnerValue.join(",")).toString()
        if (isValidate) {
            let params = {
                "originalBaseExprVal": {
                    "expressions": tempExpression,
                    "groupingOperator": "And",
                    "filterGroup": "OriginalBase",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "filterGroup2ExprVal": {
                    "expressions": [],
                    "groupingOperator": "",
                    "filterGroup": "MultipleFilter",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "0",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "exclusion1ExprVal": {
                    "expressions": [],
                    "groupingOperator": "NOT",
                    "filterGroup": "ExclusionFilter",
                    "totalIncusionQuery": "",
                    "totalInclusionCount": "0",
                    "isLastLi": "false",
                    "liIndex": ""
                },
                "ruleCondition": "",
                "departmentId": 0,
                "partnerVal": patnerValue

            }
            connectServer({
                path: GET_RS_AUDIENCE_COUNT,
                params: params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: res => {
                    if (res.status) {
                        let tempExpression = [...filterArray['subscribeList'][activeList]];
                        tempExpression.map((item, index) => {
                            let dropdownValues = masterDropDownValues.filter(ditem => ditem.key === item.sOLRFieldName)[0]?.value;
                            item.dropdownValues = dropdownValues;
                            item.masterDropdownValues = dropdownValues;
                            item.isRunBtnClicked = true;
                            item.audienceCount = res.data.countValue1["#" + item.parentdivId + " " + "#" + item.liId];
                            item.versiumCreditCount = res.data.versiumCredit["#" + item.parentdivId + " " + "#" + item.liId];
                            item.liwaterfallCount = res.data.countValue1["#" + item.parentdivId + " " + "#" + item.liId];
                        })
                        filterArray['subscribeList'][activeList] = tempExpression;
                        setAudienceCount(res?.data?.potientalAudienceCount)
                        setFilterArray({ ...filterArray });
                    }
                },
                fail: err => {

                }
            })
        }
    }

    const isEnableCreateButton = () => {
        let isEnable = "";
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        isEnable = tempExpression.length === 0 ? "click-off" : tempExpression.filter((item) => item.isRunBtnClicked === false).length === 0 ? "" : "click-off";
        return isEditable ? "click-off" : isEnable !== "" ? isEnable : audienceCount === 0 ? "click-off" : isEnable;
    }

    const validation = () => {
        let isEnable = true;
        // if (!isCheckVersiumEmail || !isCheckVersiumMobileNo) {
        //     setKeysErrMsg("Should enable Email and MobileNo")
        //     isEnable = false;
        // } else {
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        tempExpression.map((item, index) => {
            if (item.conditionType.id === 0 || item.conditionType.value === "-- Select --") {
                item.conditionTypeErr = "Select condition";
                isEnable = false
            } else {
                item.conditionTypeErr = null;
                isEnable = true;
            }
            if (item.valueType === "String" || item.valueType === "CategoricalRange" || item.valueType === "Boolean") {
                if (item.conditionValue.length === 0) {
                    item.conditionValueErr = "Select condition values"
                    isEnable = false
                } else {
                    if (item.valueType === "CategoricalRange" && item.conditionValue.length % 2 !== 0) {
                        item.conditionValueErr = "Select between ranges"
                        isEnable = false
                    } else {
                        item.conditionValueErr = null
                        isEnable = true
                    }
                }
            } else if (item.valueType === "Range") {
                if (item.fromValue === "") {
                    item.fromValueError = "Enter from value"
                    isEnable = false
                } else {
                    item.fromValueError = null
                    isEnable = true
                }
                if (item.endValue === "") {
                    item.endValueError = "Enter end value"
                    isEnable = false
                } else {
                    item.endValueError = null
                    isEnable = true
                }
            } else if (item.valueType === "DateTime") {
                if (item.fromValue === "") {
                    item.fromValueError = "Select start date"
                    isEnable = false
                } else {
                    item.fromValueError = null
                    isEnable = true
                }
                if (item.endValue === "") {
                    item.endValueError = "Select end date"
                    isEnable = false
                } else {
                    item.endValueError = null
                    isEnable = true
                }
            }
        })
        isEnable = tempExpression.filter((item) => item.conditionTypeErr !== null || item.conditionValueErr !== null || item.fromValueError !== null || item.endValueError !== null).length > 0 ? false : true
        filterArray['subscribeList'][activeList] = tempExpression;
        setFilterArray({ ...filterArray })
        // }
        return isEnable
    }

    const filterChange = (event, index) => {
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        let temp = tempExpression[index].masterDropdownValues
        tempExpression[index].dropdownValues = filterBy(temp.slice(), event.filter)
        filterArray['subscribeList'][activeList] = tempExpression;
        setFilterArray({ ...filterArray })
    };

    const getLeftPanelAttributes = () => {
        let params = {
            "departmentId": 0,
            "clientId": getUserData().clientId,
            "userId": getAuthUserId()
        }
        connectServer({
            path: GET_SEGMENTATION_LEFTPANEL_LIST,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    getDataAttributes(res?.data, 'default');
                }
            },
            fail: err => {

            }
        })
    }
    const getDataAttributes = (data, field) => {
        // const res = data;
        setTotalAudience(data?.recipientsCount);
        const tempAttributesData = data?.distinctCategory?.categories?.map((category, _) => {
            const attributesLists = data?.attributesList?.filter((attribute, _) => attribute.category === category);
            return { [category]: attributesLists };
        });
        setAttributeData(tempAttributesData)
        const tempLeftpanelAttributes = tempAttributesData?.map((attribute, _) => {
            const [entries] = Object.entries(attribute);
            const [key, value] = entries;
            let sortedValues = value?.sort((a, b) => b.solrCountValue - a.solrCountValue);
            return { [key]: sortedValues?.slice(0, 23), ['isExpanded']: 0 };
        });
        setLeftPanelAttributes(tempLeftpanelAttributes)
        const expandedKeys = tempLeftpanelAttributes?.map((attribute, _) => Object.keys(attribute)[0]?.toLowerCase());
        const tempAttributes = [];
        tempAttributesData
            ?.filter((attribute, _) => {
                const [entries] = Object.entries(attribute);
                const [key, value] = entries;
                if (expandedKeys?.includes(key?.toLowerCase())) {
                    return value;
                }
            })
            ?.forEach((attribute) => tempAttributes.push(...Object.values(attribute)?.flat()));
        setAttributes(tempAttributes)
        setAttributeData(tempAttributesData)
        setSearchAttribute(tempAttributes)
        setAttributeTypes(constant.filterValue)
        // setListName(location?.state?.recipientsBunchName)
        setIsListField(true);
    };

    const RenderSwitchButton = (subscribeItem) => {
        if (subscribeItem === 'inclusionList' && filterArray['subscribeList'][subscribeItem].length) {
            return <div className="w-100 d-flex justify-content-center switch-divider">
                <Switch onLabel={"And"} offLabel={"Or"} checked={"And"} />
            </div>
        } else if (subscribeItem === 'exclusionList' && filterArray['subscribeList'][subscribeItem].length) {
            return <div className="w-100 d-flex justify-content-center switch-divider">
                <Switch onLabel={"Not"} offLabel={""} checked={"Not"} />
            </div>
        }
    }
    const RemoveInclusionOrExclusionGroup = (subscribeItem) => {
        delete filterArray['subscribeList'][subscribeItem];
        setFilterArray({ ...filterArray });
        inclusionOrExclusionData.forEach(item => {
            if (item.list === subscribeItem) {
                item.disabled = false;
            }
        })
        setInclusionOrExclusionData(inclusionOrExclusionData);
    }
    const deletIconStyle = { float: "right" };
    const expandingCard = (attribute, attributeIdx) => {
        let isExpanded = false;
        let tempData = [...leftPanelAttributes];
        if (activeKey === attributeIdx + 1) {
            setActiveKey(-1);
            setSearchAttribute([]);
        }
        if (!isExpandedAll && activeKey !== attributeIdx + 1) {
            setActiveKey(attributeIdx + 1);
            handleSearchAttributes(Object.keys(attribute)[0]?.toLowerCase());
        }
        if (isExpandedAll && activeKey === 0 && attribute?.isExpanded === attributeIdx + 1) {
            isExpanded = true;
        }
        expandCard(attributeIdx, isExpanded);
        setIsExpandedAll(!isExpandedAll);
        if (isExpandedAll && tempData?.every((item) => item.isExpanded > 0)) {
            setIsExpandedAll(false);
        }
    };

    const expandCollapseAll = (expand) => {
        const templeftPanelAttributes = [...leftPanelAttributes];
        const expandedKeys = leftPanelAttributes?.map((attribute, _) => Object.keys(attribute)[0]?.toLowerCase());
        if (expand) {
            handleSearchAttributes(expandedKeys);
        } else {
            setSearchAttribute([]);
        }
        templeftPanelAttributes?.map((attribute, attributeIdx) => {
            if (expand) {
                attribute.isExpanded = 0;
            } else {
                attribute.isExpanded = attributeIdx + 1;
            }
            return attribute;
        });
        setLeftPanelAttributes(templeftPanelAttributes)
    };

    const expandCard = (cardIndex, isExpanded) => {
        const templeftPanelAttributes = [...leftPanelAttributes];
        if (isExpanded) {
            templeftPanelAttributes[cardIndex].isExpanded = 0;
        } else {
            templeftPanelAttributes[cardIndex].isExpanded = cardIndex + 1;
        }
        setLeftPanelAttributes(templeftPanelAttributes)
    };
    const searchAttr = searchAttribute?.filter((attribute) =>
        attribute.labelText?.toLowerCase()?.includes(searchedAttribute.toLowerCase()),
    );
    const handleSearchAttributes = (expandedKeys) => {
        const tempAttributes = [];
        attributeData
            ?.filter((attribute, _) => {
                const [entries] = Object.entries(attribute);
                const [key, value] = entries;
                if (expandedKeys?.includes(key?.toLowerCase())) {
                    return value;
                }
            })
            ?.forEach((attribute) => tempAttributes.push(...Object.values(attribute)?.flat()));
        setSearchAttribute(tempAttributes);
    };
    const RenderAttribute = (props) => {
        const { item, itemIndex } = props.item;
        return (<VSCheckbox
            key={itemIndex}
            containerClass={`
                    ${item?.sOLRCountValue === 0 ? 'click-off' : ''}
                `}
            // containerClass={`
            //     ${item?.sOLRCountValue === 0 ? 'click-off' : ''}
            //     ${!getListnameState.invalid && !getListerrorState && !_isEmpty ? '' : 'click-off'}
            // `}
            labelName={item.labelText}
            checked={item?.isChecked || false}
            onChange={(checked) => {
                // insertRemoveAttributes(checked, props.item, props.index);
            }}
        />
        );
    };
    const insertRemoveAttributes = (checked, props, index) => {
        // const filterState = watch();
        // const {
        //     zeroDayLists = [],
        //     filterLists = [],
        //     inclusionLists = [],
        //     exclusionLists = [],
        //     lookALikeAudLists = [],
        //     lookALikeAttrLists = [],
        // } = filterState;
        const { item, itemIndex } = props;
        let tempinclusionLists = inclusionLists.length > 0 ? inclusionLists : [];
        let tempexclusionLists = exclusionLists.length > 0 ? exclusionLists : [];
        let tempfilterList = filterLists.length > 0 ? filterLists : [];
        let tempzeroDayLists = zeroDayLists.length > 0 ? zeroDayLists : [];


        if (checked) {
            const findIndex = constant.getallAttributes(tempzeroDayLists, tempinclusionLists, tempexclusionLists, tempfilterList);
            if (findIndex === -1) {
                appendSelectedList(filterGroups.activeGroup, item);
                const attributesTemp = [...attributeData];
                attributesTemp[index][item.category][itemIndex + 23].isChecked = checked;
                let leftpanelTemp = [...leftPanelAttributes];
                leftpanelTemp[index][item.category].push(item);
                setLeftPanelAttributes(leftpanelTemp);
                setAttributeData(attributesTemp)
            } else {
                // trigger();
            }
        } else {
            let leftpanelTemp = [...leftPanelAttributes[index][item.category]];
            leftpanelTemp = leftpanelTemp?.filter((attribute) => attribute.name !== item.name);
            leftPanelAttributes[index][item.category] = [...leftpanelTemp];

            const removeAttributeFromList = (list) => list.filter((attribute) => attribute.name !== item.name);

            const tempZeroDayLists = removeAttributeFromList(tempzeroDayLists);
            const tempFilterLists = removeAttributeFromList(tempfilterList);
            const tempInclusionLists = removeAttributeFromList(tempinclusionLists);
            const tempExclusionLists = removeAttributeFromList(tempexclusionLists);
            // const tempLookAudi = removeAttributeFromList(lookALikeAudLists);
            // const tempLookAttr = removeAttributeFromList(lookALikeAttrLists);

            const attributes = [...attributeData];
            attributes[index][item.category][itemIndex + 23].isChecked = checked;
            setAttributeData(attributes)
            setLeftPanelAttributes([...leftPanelAttributes])
            setZeroDayLists(tempZeroDayLists)
            setFilterLists(tempFilterLists)
            setInclusionLists(tempInclusionLists);
            setExclusionLists(tempExclusionLists);
        }
    };
    const appendSelectedList = (type, payload) => {
        if (isValidListname)
            setIsValidListname(false);
        switch (type) {
            case 'zeroDayLists':
                setZeroDayLists(payload);
                break;
            case 'filterLists':
                setFilterLists(payload);
                break;
            case 'inclusionLists':
                setInclusionLists(payload);
                break;
            case 'exclusionLists':
                setExclusionLists(payload);
                break;
            // case 'lookALikeAudLists':
            //     lookALikeAudGroupRef.current.appendPayload(payload);
            //     break;
            // case 'lookALikeAttrLists':
            //     lookALikeAttrGroupRef.current.appendPayload(payload);
            //     break;
        }
    };
    const attributeClass = (list) => {
        let tempExpression = [...filterArray['subscribeList'][activeList]];
        // return `${list.solrCountValue === 0 ? 'click-off ' : ''}${enabledisableAttribute(
        //     tempExpression,
        //     list.labelText,
        // )}`;
        return `${list.solrCountValue === 0 ? '' : ''}${enabledisableAttribute(
            tempExpression,
            list.labelText,
        )}`;
    };
    const enabledisableAttribute = (fields, labelText) => {
        return fields?.length && fields?.some((value) => value.liUIPrintableName === labelText) ? 'click-off' : '';
    };
    return (
        <LayoutPageWrapper className="new-subscribe">
            <TargetListContext.Provider value={contextValue}>
                <Container className="page-header box-bottom-space">
                    <div className="header-title d-flex justify-content-between">
                        <h1>New subscription list</h1>
                    </div>
                    {/* <Attributes
                        data={leftPanelAttributes}
                        getSelectedList={(activegroup, payload) => {
                            // appendSelectedList(activegroup, payload)
                        }}
                        targetListContext={TargetListContext}
                    /> */}
                    <Row>
                        <Col md={3}>
                            <div className="d-flex align-items-center justify-content-between my8 position-relative">
                                {/* <h4 className="mb0">Attributes</h4> */}
                                <BootstrapDropdown
                                    data={attributesDropdownData}
                                    defaultItem={attributeDropdownItem}
                                    // customAlignRight={true}
                                    className="fs18-btn mr17"
                                    onSelect={(item, index) => {
                                        setAttributeDropdownItem(item);
                                        // let listType = item === "My segment list" ? item : ""
                                        // let tempData = { ...this.state.fetchDataParams };
                                        // tempData.listType = listType;
                                        // this.setState({ segmentList: listType, fetchDataParams: tempData });
                                        // this.getDataFromServerNew(tempData)
                                    }} />
                                <PlainSearchView
                                    isFromSegmentation={true}
                                    enable={true}
                                    cls="position-absolute right8"
                                    onSearch={(searchValue) => {
                                        setSearchedAttribute(searchValue)
                                    }}
                                />
                            </div>
                            {searchedAttribute?.length ?
                                <div className="rs-accordin-container list-segment-accordin bg-white px10 mt15 position-relative" style={{ minHeight: "284px" }}>
                                    {searchAttr?.length ?
                                        <ul className={`list-segment-add-list ${listName !== "" && listNameErrMsg === null ? "" : "click-off"}`}>
                                            {searchAttr?.map((attribute) => {
                                                return (
                                                    <li
                                                        key={attribute.labelText}
                                                        className={attributeClass(attribute)}
                                                        onClick={() => {
                                                            if (validation()) {
                                                                if (filterArray['subscribeList'][activeList].length < 25) {
                                                                    let tempAttribute = {
                                                                        "sequenceId": filterArray['subscribeList'][activeList].length + 1,
                                                                        "category": attribute.category,
                                                                        "field": attribute.name,
                                                                        "value": "'Nikiski, AK','Naknek, AK'",
                                                                        "conditionOperator": "",
                                                                        "expressionOperator": null,
                                                                        "queryType": "hsearch",
                                                                        "liId": "filterBuildComp" + filterArray['subscribeList'][activeList].length.toString(),
                                                                        "parentdivId": "crbGroupContainer",
                                                                        "sOLRFieldName": attribute.solrFieldName,
                                                                        "parentChildIdentify": "",
                                                                        "sOLRCountValue": "1",
                                                                        "liarithmeticSymbol": "-",
                                                                        "liremainingCount": "0",
                                                                        "liwaterfallCount": "--",
                                                                        "liUIPrintableName": attribute.labelText,
                                                                        "restype": "",
                                                                        "isVirtualField": false,
                                                                        "valueType": constant.getValueType(attribute.fieldType, attribute.partnerType),
                                                                        "conditionTypeValues": constant.getConditionTypeValues(attribute.fieldType, attribute.partnerType),
                                                                        "dropdownValues": [],
                                                                        "masterDropdownValues": [],
                                                                        "conditionType": constant.getConditionValue(attribute.fieldType, attribute.partnerType),
                                                                        "conditionValue": [],
                                                                        "textConditionValue": "",
                                                                        "fromValue": "",
                                                                        "endValue": "",
                                                                        "endMinValue": "",
                                                                        "audienceCount": 0,
                                                                        "versiumCreditCount": 0,
                                                                        "isRunBtnClicked": false,
                                                                        "isAdded": true,
                                                                        "conditionTypeErr": null,
                                                                        "conditionValueErr": null,
                                                                        "fromValueError": null,
                                                                        "endValueError": null
                                                                    }
                                                                    if (attribute.fieldType === "1" || attribute.fieldType === "5" || (attribute.fieldType === "9" && attribute.partnerType === "categoricalRange")) {
                                                                        let dropdownValues = masterDropDownValues.filter(item => item.key === attribute.solrFieldName)[0].value;
                                                                        tempAttribute.dropdownValues = dropdownValues;
                                                                        tempAttribute.masterDropdownValues = dropdownValues;
                                                                        filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                        setFilterArray({ ...filterArray });
                                                                        // getAttributeValues(attribute.solrFieldName, getDropdownValues => {
                                                                        //     dropdownValues = getDropdownValues;
                                                                        //     tempAttribute.dropdownValues = dropdownValues;
                                                                        //     tempAttribute.masterDropdownValues = dropdownValues;
                                                                        //     // let filtertemp = { "conditionType": "", "conditionValue": "", "betweenCondition": "", "dropdownValues1": item.dropdownValues1, "isRunBtnClicked": false, "secondConditionValue": "", "multiSelectValues": [] };
                                                                        //     filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                        //     setFilterArray({ ...filterArray });
                                                                        // })
                                                                    } else {
                                                                        filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                        setFilterArray({ ...filterArray });
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {attribute.labelText}
                                                        <span>+</span>
                                                    </li>
                                                );
                                            })}
                                        </ul> :
                                        <>
                                            <div className="nodata-bar align-items-center"><i className="icon-sd-alert-medium icons-md blue cursor-pointer"></i><p>No data available</p></div>
                                        </>
                                    }
                                </div> :
                                <div className="rs-accordin-container list-segment-accordin bg-white px10 mt15">
                                    <div className="d-flex justify-content-end mb10 cp">
                                        <RSPTooltip text={`${isExpandedAll ? 'Collapse all' : 'Expand all'}`} position="top">
                                            <div onClick={() => {
                                                if (isExpandedAll) {
                                                    setActiveKey(-1);
                                                } else {
                                                    setActiveKey(0);
                                                }
                                                expandCollapseAll(!isExpandedAll);
                                                setIsExpandedAll(!isExpandedAll);
                                            }}>
                                                <ExpandAllMini color={`${isExpandedAll ? 'th-primary-fill-disable-color' : 'th-primary-fill-color'} `} />
                                            </div>
                                            {/* <img
                                                src={isExpandedAll ? collapse : expand}
                                                alt="expandcollapse"
                                                width="24"
                                                height="24"
                                                onClick={() => {
                                                    if (isExpandedAll) {
                                                        setActiveKey(-1);
                                                    } else {
                                                        setActiveKey(0);
                                                    }
                                                    expandCollapseAll(!isExpandedAll);
                                                    setIsExpandedAll(!isExpandedAll);
                                                }}
                                            /> */}
                                        </RSPTooltip>
                                    </div>
                                    <Accordion className="rs-accordin" activeKey={activeKey}>
                                        {leftPanelAttributes?.map((attribute, attributeIdx) => {
                                            const groupHead = Object.keys(attribute)[0];
                                            const attributeValues = attribute[groupHead];
                                            const allAtttributes = attributeData[attributeIdx][groupHead];
                                            return (
                                                <Card eventKey={attributeIdx} key={groupHead}>
                                                    <Card.Header>
                                                        <div className="rs-accordin-header">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between"
                                                                onClick={() => expandingCard(attribute, attributeIdx)}>
                                                                <RSPTooltip text={`${activeKey === attribute?.isExpanded ? 'Collapse' : 'Expand'}`} position="top">
                                                                    <i className={`icons-sm ${activeKey === attribute?.isExpanded ? 'icon-sd-square-minus-fill-mini grey-medium' : 'icon-sd-square-plus-fill-mini blue'} marginR8`}></i>
                                                                </RSPTooltip>
                                                                <span className="mb-0 pt4">{Object.keys(attribute)[0] === 'Partner Data Versium' ? 'Versium-Data attributes' : Object.keys(attribute)[0]}</span>
                                                            </div>
                                                            <VSKendoIconDropdown
                                                                className={
                                                                    allAtttributes?.length < 5
                                                                        ? 'click-off'
                                                                        : listName !== "" && listNameErrMsg === null ? "" : "click-off"
                                                                }
                                                                // dir="rtl"
                                                                data={allAtttributes?.slice(23, allAtttributes?.length)}
                                                                icon=" icon-sd-justify-dropdown-mini icons-sm blue cursor-pointer icon-shadow"
                                                                isCustomRender
                                                                itemRender={(props) => (
                                                                    <RenderAttribute item={props} index={attributeIdx} />
                                                                )}
                                                                onItemClick={({ item, itemIndex }) => {
                                                                    // if (
                                                                    //     !getListnameState.invalid &&
                                                                    //     !getListerrorState &&
                                                                    //     !_isEmpty &&
                                                                    //     item?.sOLRCountValue > 0
                                                                    // )
                                                                    // if (item?.solrCountValue > 0) {
                                                                    if (validation()) {
                                                                        if (filterArray['subscribeList'][activeList].length < 25) {
                                                                            if (item?.isChecked) {
                                                                                insertRemoveAttributes(
                                                                                    false,
                                                                                    { item: item, itemIndex: itemIndex },
                                                                                    attributeIdx,
                                                                                );
                                                                            } else {
                                                                                insertRemoveAttributes(
                                                                                    true,
                                                                                    { item: item, itemIndex: itemIndex },
                                                                                    attributeIdx,
                                                                                );
                                                                                let tempAttribute = {
                                                                                    "sequenceId": filterArray['subscribeList'][activeList].length + 1,
                                                                                    "category": item.category,
                                                                                    "field": item.name,
                                                                                    "value": "'Nikiski, AK','Naknek, AK'",
                                                                                    "conditionOperator": "",
                                                                                    "expressionOperator": null,
                                                                                    "queryType": "hsearch",
                                                                                    "liId": "filterBuildComp" + filterArray['subscribeList'][activeList].length.toString(),
                                                                                    "parentdivId": "crbGroupContainer",
                                                                                    "sOLRFieldName": item.solrFieldName,
                                                                                    "parentChildIdentify": "",
                                                                                    "sOLRCountValue": "1",
                                                                                    "liarithmeticSymbol": "-",
                                                                                    "liremainingCount": "0",
                                                                                    "liwaterfallCount": "--",
                                                                                    "liUIPrintableName": item.labelText,
                                                                                    "restype": "",
                                                                                    "isVirtualField": false,
                                                                                    "valueType": constant.getValueType(item.fieldType, item.partnerType),
                                                                                    "conditionTypeValues": constant.getConditionTypeValues(item.fieldType, item.partnerType),
                                                                                    "dropdownValues": [],
                                                                                    "masterDropdownValues": [],
                                                                                    "conditionType": constant.getConditionValue(item.fieldType, item.partnerType),
                                                                                    "conditionValue": [],
                                                                                    "textConditionValue": "",
                                                                                    "fromValue": "",
                                                                                    "endValue": "",
                                                                                    "endMinValue": "",
                                                                                    "audienceCount": 0,
                                                                                    "versiumCreditCount": 0,
                                                                                    "isRunBtnClicked": false,
                                                                                    "isAdded": true,
                                                                                    "conditionTypeErr": null,
                                                                                    "conditionValueErr": null,
                                                                                    "fromValueError": null,
                                                                                    "endValueError": null
                                                                                }
                                                                                if (item.fieldType === "1" || item.fieldType === "5" || (item.fieldType === "9" && item.partnerType === "categoricalRange")) {
                                                                                    let dropdownValues = masterDropDownValues.filter(mitem => mitem.key === item.solrFieldName)[0].value;
                                                                                    tempAttribute.dropdownValues = dropdownValues;
                                                                                    tempAttribute.masterDropdownValues = dropdownValues;
                                                                                    filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                    setFilterArray({ ...filterArray });
                                                                                    // getAttributeValues(item.solrFieldName, getDropdownValues => {
                                                                                    //     dropdownValues = getDropdownValues;
                                                                                    //     tempAttribute.dropdownValues = dropdownValues;
                                                                                    //     tempAttribute.masterDropdownValues = dropdownValues;
                                                                                    //     // let filtertemp = { "conditionType": "", "conditionValue": "", "betweenCondition": "", "dropdownValues1": item.dropdownValues1, "isRunBtnClicked": false, "secondConditionValue": "", "multiSelectValues": [] };
                                                                                    //     filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                    //     setFilterArray({ ...filterArray });
                                                                                    // })
                                                                                } else {
                                                                                    filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                    setFilterArray({ ...filterArray });
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={attribute?.isExpanded}>
                                                        <Card.Body>
                                                            <ul className={`list-segment-add-list ${listName !== "" && listNameErrMsg === null ? "" : "click-off"}`}>
                                                                {attributeValues.map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            key={index}
                                                                            // className={item.isAdded ? "click-off" : ""}
                                                                            className={attributeClass(item)}
                                                                            onClick={() => {
                                                                                if (validation()) {
                                                                                    if (filterArray['subscribeList'][activeList].length < 25) {
                                                                                        let tempAttribute = {
                                                                                            "sequenceId": filterArray['subscribeList'][activeList].length + 1,
                                                                                            "category": item.category,
                                                                                            "field": item.name,
                                                                                            "value": "'Nikiski, AK','Naknek, AK'",
                                                                                            "conditionOperator": "",
                                                                                            "expressionOperator": null,
                                                                                            "queryType": "hsearch",
                                                                                            "liId": "filterBuildComp" + filterArray['subscribeList'][activeList].length.toString(),
                                                                                            "parentdivId": "crbGroupContainer",
                                                                                            "sOLRFieldName": item.solrFieldName,
                                                                                            "parentChildIdentify": "",
                                                                                            "sOLRCountValue": "1",
                                                                                            "liarithmeticSymbol": "-",
                                                                                            "liremainingCount": "0",
                                                                                            "liwaterfallCount": "--",
                                                                                            "liUIPrintableName": item.labelText,
                                                                                            "restype": "",
                                                                                            "isVirtualField": false,
                                                                                            "valueType": constant.getValueType(item.fieldType, item.partnerType),
                                                                                            "conditionTypeValues": constant.getConditionTypeValues(item.fieldType, item.partnerType),
                                                                                            "dropdownValues": [],
                                                                                            "masterDropdownValues": [],
                                                                                            "conditionType": constant.getConditionValue(item.fieldType, item.partnerType),
                                                                                            "conditionValue": [],
                                                                                            "textConditionValue": "",
                                                                                            "fromValue": "",
                                                                                            "endValue": "",
                                                                                            "endMinValue": "",
                                                                                            "audienceCount": 0,
                                                                                            "versiumCreditCount": 0,
                                                                                            "isRunBtnClicked": false,
                                                                                            "isAdded": true,
                                                                                            "conditionTypeErr": null,
                                                                                            "conditionValueErr": null,
                                                                                            "fromValueError": null,
                                                                                            "endValueError": null
                                                                                        }
                                                                                        if (item.fieldType === "1" || item.fieldType === "5" || (item.fieldType === "9" && item.partnerType === "categoricalRange")) {
                                                                                            let dropdownValues = masterDropDownValues.filter(mitem => mitem.key === item.solrFieldName)[0].value;
                                                                                            tempAttribute.dropdownValues = dropdownValues;
                                                                                            tempAttribute.masterDropdownValues = dropdownValues;
                                                                                            filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                            setFilterArray({ ...filterArray });
                                                                                            // getAttributeValues(item.solrFieldName, getDropdownValues => {
                                                                                            //     dropdownValues = getDropdownValues;
                                                                                            //     tempAttribute.dropdownValues = dropdownValues
                                                                                            //     tempAttribute.masterDropdownValues = dropdownValues
                                                                                            //     // let filtertemp = { "conditionType": "", "conditionValue": "", "betweenCondition": "", "dropdownValues1": item.dropdownValues1, "isRunBtnClicked": false, "secondConditionValue": "", "multiSelectValues": [] };
                                                                                            //     filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                            //     setFilterArray({ ...filterArray });
                                                                                            // })
                                                                                        } else {
                                                                                            filterArray['subscribeList'][activeList] = [...filterArray['subscribeList'][activeList], tempAttribute];
                                                                                            setFilterArray({ ...filterArray });
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }} >
                                                                            {item.labelText}
                                                                            <span>+</span>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Card.Body>

                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })}
                                    </Accordion>
                                </div>
                            }
                            <small>Selected attributes will be disabled for further selection.</small>
                        </Col>
                        <Col md={9}>
                            <div className="d-flex mb17 mt4">
                                <Col md={1} className="pr0" style={{ width: "42px" }}>
                                    <RSInput2
                                        cls="mb0 posr pl16 color-none"
                                        val={"SL_"}
                                        required={true}
                                        disabled={true} />
                                </Col>
                                <Col md={6} className="pl0">
                                    <RSInput2
                                        cls="mb0 posr pl16 left-border-none"
                                        ph="Enter list name"
                                        val={listName}
                                        validateCls={"left8"}
                                        errorMessage={listNameErrMsg}
                                        cb={(text) => {
                                            setIsShowTickSymbol(false)
                                            var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                                            // var regex = /^[A-Za-z0-9 ]+$/;
                                            let errMsg = null;
                                            if (text.length <= 30) {
                                                if (text.length > 0) {
                                                    if (regex.test(text)) {
                                                        setListName(text);
                                                    } else {
                                                        errMsg = "Special character(s) are not allowed";
                                                    }
                                                } else {
                                                    setListName(text);
                                                    errMsg = "Enter list name";
                                                }
                                            } else {
                                                errMsg = LIST_NAME_LIMIT;
                                            }
                                            setIsValidListname(false);
                                            setListNameErrMsg(errMsg)
                                        }}
                                        ob={(text) => {
                                            if (text.trim().length > 0) {
                                                checkIsNameExists(text.trim());
                                            } else {
                                                setListName("")
                                                setListNameErrMsg("Enter list name")
                                                setIsValidListname(false);
                                            }
                                        }} />
                                    <div className="input-outer-set ml1" style={{ height: '38px' }}>
                                        <RSPTooltip text="Subscription lists are auto-prefixed with SL" position="top">
                                            <i className='icon-sd-question-mark-mini icons-sm blue cursor-pointer' />
                                        </RSPTooltip>
                                        {isShowTickSymbol &&
                                            <i className="icon-sd-circle-tick-medium icons-md green d-block marginT0" />
                                        }
                                    </div>
                                </Col>
                                <div className="text-end d-flex align-items-end justify-content-end">
                                    {/* <p className="mb0">Total audience:&nbsp;</p>
                                    <h2 className="mb0">{totalAudience}</h2> */}
                                </div>
                            </div>
                            {filterArray['subscribeList'] && Object.keys(filterArray['subscribeList']).length &&
                                Object.keys(filterArray['subscribeList']).map((subscribeItem, subscribeIndex) => {
                                    return (
                                        <>
                                            {RenderSwitchButton(subscribeItem)}
                                            {subscribeIndex != 0 && <div style={deletIconStyle}><i className="icon-sd-close-small icon-mini blue cp" onClick={() => { RemoveInclusionOrExclusionGroup(subscribeItem) }}></i></div>}
                                            <Container className={`portlet-box-theme list-segment-view  ${subscribeItem === activeList ? 'border-dashed' : ''}`} onClick={() => { setActiveList(subscribeItem) }}>
                                                <div className="d-flex align-items-center justify-content-between p15">
                                                    {RenderGroupTitle(Object.keys(filterArray['subscribeList']), subscribeIndex)}
                                                    {filterArray['subscribeList'][subscribeItem] && filterArray['subscribeList'][subscribeItem].length > 0 && <p className="mb0">Potential audience: <span>{numberFormatter(audienceCount)}</span></p>}
                                                </div>

                                                {filterArray['subscribeList'][subscribeItem] && filterArray['subscribeList'][subscribeItem].length > 0 &&
                                                    <>
                                                        <div className="position-relative">
                                                            {keysErrMsg && (
                                                                <div className="validation-message">
                                                                    {keysErrMsg}
                                                                </div>
                                                            )}
                                                            <Row className='custom-column align-items-center mb15 pl3'>
                                                                <Col md={4} >
                                                                    <label className="label-row m0 p0">Select Required Keys: </label>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl="EmailID"
                                                                        checked={isCheckVersiumEmail}
                                                                        cb={(status) => {
                                                                            let tempExpression = [...filterArray['subscribeList'][activeList]];
                                                                            tempExpression.map((item, index) => {
                                                                                item.isRunBtnClicked = false;
                                                                            })
                                                                            filterArray['subscribeList'][activeList] = tempExpression;
                                                                            if (!status && !isCheckVersiumMobileNo) {
                                                                                setIsCheckVersiumMobileNo(true)
                                                                            }
                                                                            setIsCheckVersiumEmail(status)
                                                                            setKeysErrMsg(null)
                                                                            setFilterArray({ ...filterArray });
                                                                            setAudienceCount(0);
                                                                        }} />
                                                                </Col>
                                                                <Col md={3}>
                                                                    <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl="MobileNo"
                                                                        checked={isCheckVersiumMobileNo}
                                                                        cb={(status) => {
                                                                            let tempExpression = [...filterArray['subscribeList'][activeList]];
                                                                            tempExpression.map((item, index) => {
                                                                                item.isRunBtnClicked = false;
                                                                            })
                                                                            filterArray['subscribeList'][activeList] = tempExpression;
                                                                            if (!status && !isCheckVersiumEmail) {
                                                                                setIsCheckVersiumEmail(true)
                                                                            }
                                                                            setIsCheckVersiumMobileNo(status)
                                                                            setKeysErrMsg(null)
                                                                            setFilterArray({ ...filterArray });
                                                                            setAudienceCount(0);
                                                                        }} />
                                                                </Col>
                                                                <Col md={3}>
                                                                    <RSCheckbox
                                                                        className="marginB0"
                                                                        lbl="Address"
                                                                        checked={isCheckVersiumAddress}
                                                                        cb={(status) => {
                                                                            let tempExpression = [...filterArray['subscribeList'][activeList]];
                                                                            tempExpression.map((item, index) => {
                                                                                item.isRunBtnClicked = false;
                                                                            })
                                                                            filterArray['subscribeList'][activeList] = tempExpression;
                                                                            setIsCheckVersiumAddress(status)
                                                                            setKeysErrMsg(null)
                                                                            setFilterArray({ ...filterArray });
                                                                            setAudienceCount(0);
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <ul className="listSegmentsContainer">
                                                            {filterArray['subscribeList'][subscribeItem].map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <li className="list-seg-view">
                                                                            {index > 0 && <div className="w-100 d-flex justify-content-center switch-divider">
                                                                                <Switch
                                                                                    // className='click-off'
                                                                                    onLabel={"And"}
                                                                                    offLabel={"Or"}
                                                                                    onChange={(event) => {
                                                                                        setBetweenConditionFlag(true);
                                                                                        setBetweenCondition("And");
                                                                                        // if (betweenConditionFlag) {
                                                                                        //     setBetweenCondition("And");
                                                                                        // } else {
                                                                                        //     setBetweenCondition("Or");
                                                                                        // }
                                                                                    }}
                                                                                    checked={betweenConditionFlag} />
                                                                            </div>}
                                                                            <div>
                                                                                <h5 className="font-xsm">{item.liUIPrintableName}</h5>
                                                                                <Row className="align-items-end">
                                                                                    <Col md={3}>
                                                                                        <div className="position-relative">
                                                                                            {item.conditionTypeErr && <div className="validation-message">{item.conditionTypeErr}</div>}
                                                                                            <RSDropdownList
                                                                                                data={item.conditionTypeValues}
                                                                                                value={item.conditionType}
                                                                                                textField='label'
                                                                                                dataItemKey='id'
                                                                                                customAlignRight={true}
                                                                                                className="mb0  w-100 mi required"
                                                                                                onChange={(e) => {
                                                                                                    item.conditionType = e.target.value;
                                                                                                    item.conditionOperator = e.target.value.value;
                                                                                                    item.conditionTypeErr = null
                                                                                                    item.isRunBtnClicked = false;
                                                                                                    setUpdate(update + 1);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                {
                                                                                                    (item.valueType === "Range" && item.conditionType.value === "between")
                                                                                                        ? <div className='d-flex justify-content-center'>
                                                                                                            <RSInput2
                                                                                                                required={true}
                                                                                                                cls="mb0 w-100 posr"
                                                                                                                ph={"Enter from value"}
                                                                                                                errorMessage={item.fromValueError}
                                                                                                                val={item.fromValue}
                                                                                                                cb={(text) => {
                                                                                                                    let errMsg = text.length !== 0 ? null : "Enter from value";
                                                                                                                    let regex = /^[0-9]+$/
                                                                                                                    if (errMsg === null) {
                                                                                                                        if (regex.test(text)) {
                                                                                                                            item.fromValue = text.length <= 4 ? text : item.fromValue;
                                                                                                                            item.fromValueError = null;
                                                                                                                            item.isRunBtnClicked = false;
                                                                                                                            setUpdate(update + 1);
                                                                                                                        } else {
                                                                                                                            item.fromValueError = "Accepts numbers only";
                                                                                                                            item.isRunBtnClicked = false;
                                                                                                                            setUpdate(update + 1);
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        item.fromValue = text;
                                                                                                                        item.fromValueError = errMsg;
                                                                                                                        item.isRunBtnClicked = false;
                                                                                                                        setUpdate(update + 1);
                                                                                                                    }


                                                                                                                }}
                                                                                                            />
                                                                                                            <label className='betweenTo betweenTo mx10 position-relative top4'>to</label>
                                                                                                            <RSInput2
                                                                                                                required={true}
                                                                                                                cls="mb0 w-100 posr"
                                                                                                                ph={"Enter end value"}
                                                                                                                errorMessage={item.endValueError}
                                                                                                                val={item.endValue}
                                                                                                                cb={(text) => {
                                                                                                                    let errMsg = text.length !== 0 ? null : "Enter end value";
                                                                                                                    let regex = /^[0-9]+$/
                                                                                                                    if (errMsg === null) {
                                                                                                                        if (regex.test(text) && text.length <= 4) {
                                                                                                                            item.endValue = text;
                                                                                                                            item.endValueError = null;
                                                                                                                            item.isRunBtnClicked = false;
                                                                                                                            setUpdate(update + 1);
                                                                                                                        } else {
                                                                                                                            item.endValueError = "Accepts numbers only";
                                                                                                                            item.isRunBtnClicked = false;
                                                                                                                            setUpdate(update + 1);
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        item.endValue = text;
                                                                                                                        item.endValueError = errMsg;
                                                                                                                        item.isRunBtnClicked = false;
                                                                                                                        setUpdate(update + 1);
                                                                                                                    }
                                                                                                                }}
                                                                                                                ob={(text) => {
                                                                                                                    let errMsg = text === "" ? "Enter end value" : parseInt(item.fromValue, 10) < parseInt(text, 10) ? null : "Enter value less than from value";
                                                                                                                    item.endValue = text;
                                                                                                                    item.endValueError = errMsg;
                                                                                                                    item.isRunBtnClicked = false;
                                                                                                                    setUpdate(update + 1);
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        : (item.valueType === "String" || item.valueType === "CategoricalRange" || item.valueType === "Boolean")
                                                                                                            ?
                                                                                                            <div className="chips-input-container">
                                                                                                                <div className="chips-input-box required">
                                                                                                                    <div className="position-relative">
                                                                                                                        {item.conditionValueErr && (
                                                                                                                            <div className="validation-message">
                                                                                                                                {item.conditionValueErr}
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                        <MultiSelect
                                                                                                                            placeholder="--Select--"
                                                                                                                            data={item.dropdownValues}
                                                                                                                            textField="displayLabel"
                                                                                                                            dataItemKey="value"
                                                                                                                            filterable={true}
                                                                                                                            onFilterChange={(event) => { filterChange(event, index) }}
                                                                                                                            onChange={(event) => {
                                                                                                                                item.conditionValue = event.target.value;
                                                                                                                                item.isRunBtnClicked = false;
                                                                                                                                item.conditionValueErr = event.target.value.length > 0 ? null : "Select condition values"
                                                                                                                                setUpdate(update + 1);
                                                                                                                            }}
                                                                                                                            value={item.conditionValue} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <RSDropdownList
                                                                                                                data={item.dropdownValues}
                                                                                                                value={item.conditionValue}
                                                                                                                customAlignRight={true}
                                                                                                                className="mb0 match-type-input width100p"
                                                                                                                onChange={(e) => {
                                                                                                                    let tempValue = e.target.value;
                                                                                                                    tempValue = tempValue.includes("_") ? e.target.value.split("_")[0] : tempValue;
                                                                                                                    item.conditionValue.push(tempValue);
                                                                                                                    // item.value = e.target.value
                                                                                                                    setUpdate(update + 1);
                                                                                                                }} /> */}
                                                                                                            </div>
                                                                                                            : (item.valueType === "DateTime" && item.conditionType.value === "between")
                                                                                                                ?
                                                                                                                <div className="d-flex justify-content-center">
                                                                                                                    <div className="position-relative">
                                                                                                                        {item.fromValueError && <div className="validation-message">{item.fromValueError}</div>}
                                                                                                                        <div className="rspdate-picker">
                                                                                                                            <DatePicker
                                                                                                                                format={"MMM dd, yyyy"}
                                                                                                                                placeholder={"Choose start date"}
                                                                                                                                value={item.fromValue !== '' ? new Date(item.fromValue) : null}
                                                                                                                                // min={defaultValue}
                                                                                                                                max={new Date()}
                                                                                                                                onChange={(e) => {
                                                                                                                                    item.fromValue = e.target.value;
                                                                                                                                    let someDate = e.target.value;
                                                                                                                                    var result = someDate?.setDate(
                                                                                                                                        someDate.getDate() + 1
                                                                                                                                    );
                                                                                                                                    item.endMinValue = new Date(result);
                                                                                                                                    item.fromValueError = null;
                                                                                                                                    item.isRunBtnClicked = false;
                                                                                                                                    setUpdate(update + 1)
                                                                                                                                }}
                                                                                                                                className="required"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <label className='betweenTo mx10 position-relative top4'>to</label>
                                                                                                                    <div className="position-relative">
                                                                                                                        {item.endValueError && <div className="validation-message">{item.endValueError}</div>}
                                                                                                                        <div className="rspdate-picker">
                                                                                                                            <DatePicker
                                                                                                                                format={"MMM dd, yyyy"}
                                                                                                                                placeholder={"Choose end date"}
                                                                                                                                value={item.endValue !== '' ? new Date(item.endValue) : null}
                                                                                                                                min={new Date(item.endMinValue)}
                                                                                                                                max={new Date()}
                                                                                                                                onChange={(e) => {
                                                                                                                                    item.endValue = e.target.value;
                                                                                                                                    item.endValueError = null;
                                                                                                                                    item.isRunBtnClicked = false;
                                                                                                                                    setUpdate(update + 1)
                                                                                                                                }}
                                                                                                                                className="required"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : item.valueType === "Text"
                                                                                                                    ? <RSInput2
                                                                                                                        cls="mb0 posr top-5"
                                                                                                                        ph={"Enter the value"}
                                                                                                                        val={item.textConditionValue}
                                                                                                                        errorMessage={item.conditionValueErr}
                                                                                                                        cb={(text) => {
                                                                                                                            // item.conditionValue = text;
                                                                                                                            // item.isRunBtnClicked = false;
                                                                                                                            // setUpdate(update + 1);
                                                                                                                            item.textConditionValue = text;
                                                                                                                            item.isRunBtnClicked = false;
                                                                                                                            item.conditionValueErr = null;
                                                                                                                            setUpdate(update + 1);
                                                                                                                        }}
                                                                                                                        ob={(text) => {
                                                                                                                            var regex = /^[a-zA-Z0-9]+$/;
                                                                                                                            let zipCodes = text.split(",");
                                                                                                                            zipCodes.map((zip, index) => {
                                                                                                                                if (zip !== "" && regex.test(zip)) {
                                                                                                                                    let errMsg = zip.length > 0 ? null : "Enter the value"
                                                                                                                                    // item.conditionValue = zip
                                                                                                                                    item.conditionValueErr = errMsg;
                                                                                                                                    item.isRunBtnClicked = false;
                                                                                                                                    setUpdate(update + 1);
                                                                                                                                } else {
                                                                                                                                    item.conditionValueErr = "Only alphanumeric allowed";
                                                                                                                                    setUpdate(update + 1);
                                                                                                                                    return
                                                                                                                                }
                                                                                                                            })

                                                                                                                        }}
                                                                                                                    />
                                                                                                                    : item.valueType === "Domain"
                                                                                                                        || item.valueType === "Country"
                                                                                                                        ? <div className="chips-input-container">
                                                                                                                            <div className="chips-input-box">
                                                                                                                                <MultiSelect
                                                                                                                                    placeholder="--Select--"
                                                                                                                                    data={item.dropdownValues}
                                                                                                                                    onChange={(event) => {
                                                                                                                                        item.multiSelectValues = event.target.value;
                                                                                                                                        setUpdate(update + 1);
                                                                                                                                    }}
                                                                                                                                    value={item.multiSelectValues}
                                                                                                                                    allowCustom={true} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        : null
                                                                                                }
                                                                                            </Col>
                                                                                            {
                                                                                                item.valueType === "Combine" || item.valueType === "CampaignName"
                                                                                                    ? <Col md={6}>
                                                                                                        <RSDropdownList
                                                                                                            data={item.dropdownValues}
                                                                                                            value={item.secondConditionValue !== "" ? item.secondConditionValue : "-- Select --"}
                                                                                                            customAlignRight={true}
                                                                                                            className="required mb0 match-type-input width100p"
                                                                                                            onChange={(e) => {
                                                                                                                item.secondConditionValue = e.target.value;
                                                                                                                setUpdate(update + 1);
                                                                                                            }} /> </Col>
                                                                                                    : item.liUIPrintableName === "Response"
                                                                                                        || item.liUIPrintableName === "Life Time Value"
                                                                                                        ? <Col md={6}>
                                                                                                            <RSInput2
                                                                                                                cls="mb0 posr top-5"
                                                                                                                ph={"Enter the value"}
                                                                                                                val={item.secondConditionValue}
                                                                                                                cb={(text) => {
                                                                                                                    item.secondConditionValue = text;
                                                                                                                    setUpdate(update + 1);
                                                                                                                }}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        : null
                                                                                            }
                                                                                        </Row>
                                                                                    </Col>

                                                                                    <Col md={3}>
                                                                                        <div className="d-flex justify-content-between position-relative top0">
                                                                                            {item.isRunBtnClicked ?
                                                                                                <div>
                                                                                                    <i className="icon-sd-circle-tick-mini icons-md green" />
                                                                                                </div>
                                                                                                :
                                                                                                <RSPTooltip text="Click here to view the count" position={"top"}>
                                                                                                    <i className="icon-sd-circle-arrow-right-fill-mini icons-md blue cp" onClick={() => {
                                                                                                        if (validation()) {
                                                                                                            getAudienceCount(filterArray);
                                                                                                            setUpdate(update + 1);
                                                                                                        }
                                                                                                    }} />
                                                                                                </RSPTooltip>
                                                                                            }
                                                                                            <div className="input-inner-set top-4">
                                                                                                <RSPTooltip text="Duplicate" position="top">
                                                                                                    <i className='icon-sd-duplicate-medium icons-md blue cp' onClick={() => {
                                                                                                        if (filterArray['subscribeList'][activeList].length < 25) {
                                                                                                            let tempArray = [...filterArray['subscribeList'][activeList]];
                                                                                                            let tempItem = { ...item };
                                                                                                            tempItem.isRunBtnClicked = false;
                                                                                                            tempItem.audienceCount = 0;
                                                                                                            tempItem.versiumCreditCount = 0;
                                                                                                            tempItem.liwaterfallCount = 0;
                                                                                                            tempItem.conditionType = { "label": "-- Select --", "value": "", "id": 0 };
                                                                                                            tempItem.conditionValue = [];
                                                                                                            tempItem.fromValue = "";
                                                                                                            tempItem.endValue = "";
                                                                                                            tempArray.push(tempItem);
                                                                                                            filterArray['subscribeList'][activeList] = tempArray;
                                                                                                            setFilterArray({ ...filterArray });
                                                                                                            setUpdate(update + 1)
                                                                                                        }
                                                                                                    }}></i>
                                                                                                </RSPTooltip>
                                                                                                <RSPTooltip text="Remove" position="top">
                                                                                                    <i className='icon-sd-circle-minus-fill-edge-medium icons-md red cp pr0' onClick={() => {
                                                                                                        let tempArray = [...filterArray['subscribeList'][activeList]];
                                                                                                        const index1 = tempArray.indexOf(item);
                                                                                                        let tempAllAttributes = [...attributeData];
                                                                                                        // attributesTemp[index][item.category]
                                                                                                        let leftAttributeItem = null
                                                                                                        tempAllAttributes[0][item.category].map((aItem, aIndex) => {
                                                                                                            if (aItem.solrFieldName === item.sOLRFieldName) {
                                                                                                                aItem.isChecked = false;
                                                                                                                if (aIndex > 22) {
                                                                                                                    let leftpanelTemp = [...leftPanelAttributes];
                                                                                                                    let tempLeftIndex = leftpanelTemp[0][item.category].indexOf(aItem);
                                                                                                                    leftpanelTemp[0][item.category].splice(tempLeftIndex, 1);
                                                                                                                    setLeftPanelAttributes(leftpanelTemp);
                                                                                                                }
                                                                                                            }
                                                                                                        })
                                                                                                        tempArray.splice(index1, 1);
                                                                                                        if (tempArray.length === 0) {
                                                                                                            setAudienceCount(0);
                                                                                                        }
                                                                                                        filterArray['subscribeList'][activeList] = tempArray;
                                                                                                        setAttributeData(tempAllAttributes)
                                                                                                        setFilterArray({ ...filterArray });
                                                                                                        setUpdate(update + 1)
                                                                                                    }}></i>
                                                                                                </RSPTooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>

                                                                                    {item.isRunBtnClicked && <div className="pvi-container blue">
                                                                                        <span>{numberFormatter(item.audienceCount)}</span>
                                                                                    </div>}
                                                                                </Row>
                                                                            </div >
                                                                        </li>
                                                                    </>
                                                                )
                                                            })}
                                                        </ul>
                                                        <Row className='custom-column pl4 py15'>
                                                            <Col md={4}>
                                                                <RSCheckbox
                                                                    className="marginB0 blue"
                                                                    lbl="Set extraction limit"
                                                                    checked={isCheckExtractionLimit}
                                                                    cb={(status) => {
                                                                        setIsCheckExtractionLimit(status)
                                                                        setErrMsgExtractionLimit(null);
                                                                    }} />
                                                            </Col>
                                                            {isCheckExtractionLimit &&
                                                                <Col md={4}>
                                                                    <RSInput2
                                                                        required={true}
                                                                        cls="mb0 posr top-5 pl16  left-1"
                                                                        ph="Enter extraction limit"
                                                                        val={extractionLimit}
                                                                        errorMessage={errMsgExtractionLimit}
                                                                        max={7}
                                                                        cb={(text) => {
                                                                            let errMsg = text.length !== 0 ? null : "Enter extraction limit";
                                                                            text = text.replaceAll(",", "");
                                                                            let regex = /^[0-9]+$/
                                                                            if (errMsg === null) {
                                                                                if (regex.test(text)) {
                                                                                    let value = parseInt(text, 10);
                                                                                    errMsg = (value < 1 || value > 2000000) ? "Extraction limit should be 1 to 20,00,000" : value <= audienceCount ? null : "Extraction limit should be less than potential audience";
                                                                                    setExtractionLimit(errMsg === null ? text : "");
                                                                                } else {
                                                                                    errMsg = "Accepts numbers only"
                                                                                }
                                                                            } else {
                                                                                setExtractionLimit(text);
                                                                            }
                                                                            setErrMsgExtractionLimit(errMsg);
                                                                        }}
                                                                        ob={(value) => {
                                                                            value = value.replaceAll(",", "");
                                                                            setErrMsgExtractionLimit(value === "" ? "Enter extraction limit" : null);
                                                                            setExtractionLimit(value !== "" ? numberFormatter(value) : value);
                                                                        }} />
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </>
                                                }

                                                {filterArray['subscribeList'][subscribeItem] && filterArray['subscribeList'][subscribeItem].length === 0 && <div className='listsegment-nodata'>Choose the attributes to filter the audience</div>}

                                            </Container >
                                            <Container className={`portlet-box-theme list-segment-view  ${subscribeItem === activeList ? 'border-dashed' : ''}`} >
                                                <div className="d-flex align-items-center justify-content-between p15">
                                                    <h4 className="mb0">Additional attributes </h4>
                                                </div>
                                                <Row className='custom-column align-items-center mb15 pl3'>
                                                    <Col md={3} >
                                                        <label className="label-row m0 p0">Attribute name </label>
                                                    </Col>
                                                    <Col md={3}>
                                                        <RSCheckbox
                                                            className="marginB0"
                                                            lbl="Demographic"
                                                            checked={isCheckDemoGraphics}
                                                            cb={(status) => {
                                                                setIsCheckDemoGraphics(status)
                                                            }} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <RSCheckbox
                                                            className="marginB0"
                                                            lbl="HouseFinAuto"
                                                            checked={isCheckHouseFinAuto}
                                                            cb={(status) => {

                                                                setIsCheckHouseFinAuto(status)
                                                            }} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <RSCheckbox
                                                            className="marginB0"
                                                            lbl="LifestyleInterest"
                                                            checked={isCheckLifeStyleInterest}
                                                            cb={(status) => {
                                                                setIsCheckLifeStyleInterest(status)
                                                            }} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <RSCheckbox
                                                            className="marginB0"
                                                            lbl="PoliticalDonor"
                                                            checked={isCheckPoliticalDonor}
                                                            cb={(status) => {
                                                                setIsCheckPoliticalDonor(status)
                                                            }} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <div><small>Max. allowed attributes: 25 / Recommended attributes: 8</small></div>
                                            <div><small>Max. audience per list: 2 million</small></div>
                                            {getUserData().clientDetailsLocalization.isPartnerAccount &&
                                                <div>
                                                    <RSCheckbox
                                                        className="marginB0"
                                                        lbl="Allow Versium REACH account to manage account spending and data usage"
                                                        checked={isVersiumReach}
                                                        cb={value => {
                                                            setIsVersiumReach(true)
                                                        }}
                                                    />
                                                </div>}
                                        </>
                                    )
                                })
                            }

                            <div className="dynamicListAddIcon segment-add-icon d-none">
                                <DropDownButton
                                    className="gallery-dropdown-button"
                                    icon=" icon-sd-circle-plus-fill-edge-medium icons-lg blue cp"
                                    onItemClick={(e) => {
                                        if (e.item.text.toLowerCase() === 'inclusion') {
                                            filterArray['subscribeList']['inclusionList'] = [];
                                            setActiveList('inclusionList');
                                            setFilterArray({ ...filterArray });
                                            inclusionOrExclusionData[0]['disabled'] = true;
                                            setInclusionOrExclusionData(inclusionOrExclusionData);
                                        } else {
                                            filterArray['subscribeList']['exclusionList'] = [];
                                            setActiveList('exclusionList');
                                            setFilterArray({ ...filterArray });
                                            inclusionOrExclusionData[1]['disabled'] = true;
                                            setInclusionOrExclusionData(inclusionOrExclusionData);
                                        }
                                    }}
                                >
                                    {inclusionOrExclusionData.map(item => {
                                        return <DropDownButtonItem text={item.text} disabled={item.disabled} />
                                    })}
                                </DropDownButton>
                            </div>
                            <div className="btn-container d-flex justify-content-end">
                                <RSSecondaryBtn onClick={() => {
                                    history.push({ pathname: "/audience", state: { index: 0 } });
                                }}>Cancel</RSSecondaryBtn>
                                <RSPrimaryBtn className={`${listName !== "" && listNameErrMsg === null ? isEnableCreateButton() : "click-off"}`} onClick={() => {
                                    if (validation()) {
                                        SaveorUpdateList(filterArray);
                                    }
                                    // setIsFilterBtnClicked(!isFilterBtnClicked)
                                }}>Create</RSPrimaryBtn>
                            </div>
                        </Col>

                    </Row>
                    {isFilterBtnClicked && <Row><FilterList /></Row>}
                    {isOpenPaymentSummaryModal &&
                        <AudiencePaymentModal
                            isOpen={isOpenPaymentSummaryModal}
                            segmentationDetails={segmentationListDetails}
                            onChangeIsOpen={(value) => {
                                if (value) {
                                    history.push({ pathname: "/audience", state: { index: 0 } });
                                }
                                setIsOpenPaymentSummaryModal(false)
                            }}
                        />}
                    {isOpenLimitModal &&
                        <SuccessModal isOkButton={false} isOpen={isOpenLimitModal} title={"Failure"} subTitle={"Audience count per list must not exceed 2 million."} buttonClicked={(value) => {
                            setIsOpenLimitModal(false);
                        }} />
                    }
                </Container>
            </TargetListContext.Provider >
        </LayoutPageWrapper >
    );
}
export default withRouter(SegmentationCreationRS);

const RenderGroupTitle = (subscribeListKeys, index) => {
    if (subscribeListKeys.length > 0 && subscribeListKeys.includes('filterList') && subscribeListKeys.includes('inclusionList') && index < 2) {
        return <h4 className="mb0">Inclusion - Group {index + 1} </h4>
    } else if (subscribeListKeys.length > 0 && subscribeListKeys.includes('exclusionList')) {
        return <h4 className="mb0">Exclusion </h4>
    } else {
        return <h4 className="mb0">Filters </h4>
    }
}

const CustomerAttributes = [
    {
        "id": 1001,
        "text": "Age",
        "valueType": "Number",
        "conditionTypeValues": ["Is equal to", "Is not equal to", "Is greater than or equal to", "Is greater than", "Is less than or equal to", "Is less than", "Between", "Has no value", "Has value"]
    },
    {
        "id": 1002,
        "text": "Gender",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Male", "Female", "Transgender"]
    },
    {
        "id": 1003,
        "text": "Income",
        "valueType": "Number",
        "conditionTypeValues": ["Is equal to", "Is not equal to", "Is greater than or equal to", "Is greater than", "Is less than or equal to", "Is less than", "Between", "Has no value", "Has value"]
    },
    {
        "id": 1004,
        "text": "Pan",
        "valueType": "String",
        "dropdownValues": ["Linked", "Not linked", "Available", "Not available"],
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
    },
    {
        "id": 1005,
        "text": "Aadhar",
        "valueType": "String",
        "dropdownValues": ["Linked", "Not linked", "Available", "Not available"],
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
    },
    {
        "id": 1006,
        "text": "Contact type",
        "valueType": "String",
        "dropdownValues": ["Lead", "Contact", "Opportunity"],
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
    },
    {
        "id": 1007,
        "text": "City",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["New York", "Los angeles", "Chicago", "San francisco", "Great falls", "Miami", "Abingdon", "Accrington", "Acton", "Adlington", "Sydney", "Liverpool", "Campbelltown", "Mumbai", "Delhi", "Kolkata", "Bangalore", "Hyderabad", "Chennai", "Pune", "Visakhapatnam", "Nagpur"]
    },
    {
        "id": 1008,
        "text": "Job Function",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Account manager", "Brand Manager", "Business Analyst", "Campaign manager", "CMO", "Creative services", "Customer Insights Manager", "CXO", "Data Scientist", "Director", "Finance Manager", "Head of Digital", "IT/Infrastructure", "Marketing Manager ", "Marketing Strategist", "Procurement", "Product Manager", "Segment Manager", "Vice President", "Others"]
    },
    {
        "id": 1009,
        "text": "Industry",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Advertising", "Apparels", "Automotive", "Banking, Financial Services", "Beauty & Cosmetics", "Career & Education", "Consulting Services", "E - Commerce", "Electronics & Consumer Goods", "Environment & Energy", "Fashion", "FMCG", "Food & Beverages", "Govt. & non-profits", "Healthcare", "Hospitality", "Insurance", "Media & Entertainment", "Quick Service Restaurant", "Real Estate", "Retail", "Technology/Software Services", "Telco", "Travel & tourism"]
    },
    {
        "id": 1010,
        "text": "Campaign response score",
        "valueType": "Combine",
        "conditionTypeValues": ["Below", "Between", "Above", "Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Less than 2 years", "Less than 1 year", "Less than 6 months"]
    }
]

const CampaignAttributes = [
    {
        "id": 1001,
        "text": "Campaign name",
        "valueType": "CampaignName",
        "conditionTypeValues": ["Flipkart Best Buying Days", "Amazon Great shopping festival", "Gold bonds", "Effortless Mobile Banking", "Promotional Offer", "Fixed deposit renewal"],
        "dropdownValues": ["All channel", "Email", "SMS", "Mobile push", "Web push", "Notifications", "Pinterest", "Twitter", "Facebook"],
        "dropdownValues1": ["Reached", "Not reached", "Engaged", "Not engaged", "Converted", "Not converted", "New audience", "Forwarded", "Bounced", "Spammed", "App uninstalls", "DND", "Undelivered", "Expired", "Rejected", "Unsubscribed"]
    },
    {
        "id": 1002,
        "text": "Campaign type",
        "valueType": "CampaignType",
        "dropdownValues": ["Reached", "Not reached", "Engaged", "Not engaged", "Converted", "Not converted", "Unsubscribed"],
        "conditionTypeValues": ["Awareness", "SMS acquisition", "Greetings", "New product launch", "Regulatory", "Non-Regulatory", "Promotion & Sale"]
    },
    {
        "id": 1003,
        "text": "Campaign period",
        "valueType": "Date",
        "conditionTypeValues": ["Before", "After", "Between"]
    },
    {
        "id": 1004,
        "text": "Most active channel",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Email", "SMS", "Web push", "Mobile push", "QR", "WhatsApp"]
    },
    {
        "id": 1005,
        "text": "New visitor",
        "valueType": "Date",
        "conditionTypeValues": ["Before", "After", "Between"]
    },
    {
        "id": 1006,
        "text": "Returning visitor",
        "valueType": "Date",
        "conditionTypeValues": ["Before", "After", "Between"]
    },
    {
        "id": 1007,
        "text": "Visit specific page",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],

    },
    {
        "id": 1008,
        "text": "Page views",
        "valueType": "Number",
        "conditionTypeValues": ["Is equal to", "Is not equal to", "Is greater than or equal to", "Is greater than", "Is less than or equal to", "Is less than", "Between", "Has no value", "Has value"]
    },
    {
        "id": 1009,
        "text": "OS type",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["iOS", "macOS", "Android", "Windows"]
    },
    {
        "id": 1010,
        "text": "Active period",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Weekdays", "Weekends", "Day", "Night"]
    },
    {
        "id": 1011,
        "text": "Propensity",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["0 to 0.25", "0.26 to 0.50", "0.51 to 0.75", "0.76 to 1"]
    }
]
const FingerprintAttributes = [
    {
        "id": 1001,
        "text": "Device type",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Desktop", "Mobile", "Tablet"]
    },
    {
        "id": 1002,
        "text": "OS type",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["iOS", "macOS", "Android", "Windows"]
    },
    {
        "id": 1003,
        "text": "Browser type",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Chrome", "Firefox", "Safari", "Internet explore"]
    },
    {
        "id": 1004,
        "text": "Location",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["New York", "Los angeles", "Chicago", "San francisco", "Great falls", "Miami", "Abingdon", "Accrington", "Acton", "Adlington", "Sydney", "Liverpool", "Campbelltown", "Mumbai", "Delhi", "Kolkata", "Bangalore", "Hyderabad", "Chennai", "Pune", "Visakhapatnam", "Nagpur"]
    },
    {
        "id": 1005,
        "text": "Language",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["English", "Chinese", "French", "German", "Italian", "Jabanese", "Korean", "Portuguese", "Russian", "Spanish", "Thai", "Malay"]
    },
    {
        "id": 1006,
        "text": "Time zone",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["EST (GMT - 5:00) Eastern Time (US & Canada), New York", "EST (GMT – 4:00) Saint Lucia, Virgin Islands", "EST (GMT – 7:00) San Francisco, California", "PST (GMT - 8:00) Pacific (San Francisco)"]
    },
    {
        "id": 1007,
        "text": "Screen resolution",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["1920x1080", "1280x1024", "1024x768", "768x1280", "640x960", "480x800", "320x480"]
    }
]
const OthersAttributes = [
    {
        "id": 1001,
        "text": "Currency",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["US Dollar", "Australian Dollar", "Bahraini Dinar", "Brazilian Real", "British Pound", "Brunei Dollar", "Canadian Dollar", "Chilean Peso", "Chinese Yuan", "Czech Republic Koruna", "Danish Krone", "Euro", "Hong Kong Dollar", "Hungarian Foriont", "Icelandic Krona", "Indian Rupee", "Indonesian Rupiah", "Israeli New Sheqel", "Kazakhistani Tenge", "Kuwaiti Dinar", "Malaysian Ringgit", "Mauritian Rupee", "Mexican Peso", "Nepalese Rupee", "New Taiwan Dollar", "New Zealand Dollar", "Norwegian Krone", "Pakistani Rupee", "Qatari Rial", "Russian Ruble", "Saudi Riyal", "Singapore Dollar", "South African Rand", "South Korean Won", "Sri Lankan Rupee", "Swedish Krona", "Swiss Franc", "Thai Baht", "United Arab Emirates Diham", "Vietnamese dong"]
    },
    {
        "id": 1002,
        "text": "Language",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["English", "Chinese", "French", "German", "Italian", "Jabanese", "Korean", "Portuguese", "Russian", "Spanish", "Thai", "Malay"]
    },
    {
        "id": 1003,
        "text": "Double Opt-in",
        "valueType": "String",
        "conditionTypeValues": ["Contains", "Does not contain", "Is equal to", "Is not equal to", "Changed to", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty", "Has no value", "Has value"],
        "dropdownValues": ["Yes", "No"]
    }
]