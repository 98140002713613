import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Switch } from '@progress/kendo-react-inputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSInput2, RSRadio } from 'Components/RSInputs';
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown';
import { CAMP_PERFORMANCE, PERIOD_TYPE, PERIOD_VALUE } from 'Helper/Constants/validationMessage';
import { isEmpty } from 'Helper/Utils/Utils';


class SplitSettingsModal extends Component {

    state = {
        isShow: this.props.isOpen,
        isAutoSchedule: this.props.edmAutoSchedule.autoSchedule,
        campPerformanceBy: this.props.edmAutoSchedule.performedBy,
        periodValue: this.props.edmAutoSchedule.startIn,
        periodType: this.props.edmAutoSchedule.statusID,
        errCampPerformanceByMsg: null,
        errPeriodValueMsg: null,
        errPeriodTypeMsg: null
    }
    componentDidMount() {
        this.props.edmAutoSchedule.campaignID = this.props.communicationId
    }

    render() {
        return (
            <Modal
                backdrop="static"
                keyboard={false}
                show={this.state.isShow}
                centered
                size='lg'
                onHide={() => {
                    this.setState({ isShow: !this.state.isShow });
                    this.props.onChangeIsOpen(false);
                }}>
                <Modal.Header closeButton>
                    <h2>Schedule</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className='position-relative mb30'>
                        <Row>
                            <Col md={4}>
                                <label className="label-row">Auto schedule</label>
                            </Col>
                            <Col md={7}>
                                <Switch
                                    onLabel={"ON"}
                                    offLabel={"OFF"}
                                    onChange={(event) => {
                                        this.props.edmAutoSchedule.autoSchedule = event.target.value;
                                        this.setState({ isAutoSchedule: event.target.value });
                                    }}
                                    checked={this.state.isAutoSchedule}
                                />
                            </Col>
                        </Row>
                        {this.state.isAutoSchedule &&
                            <>
                                <Row className='mt30'>
                                    <Col md={4}>
                                        <label>Communication performance by</label>
                                    </Col>
                                    <Col md={7}>
                                        <div className="position-relative">
                                            {this.state.errCampPerformanceByMsg && (
                                                <div className="validation-message">
                                                    {this.state.errCampPerformanceByMsg}
                                                </div>
                                            )}
                                            <Row>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Subject line"
                                                        name={"campPerformance"}
                                                        labelId={"campPerformanceId"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === 1 ? true : false}
                                                        cb={(event) => {
                                                            this.props.edmAutoSchedule.performedBy = 1;
                                                            this.setState({ campPerformanceBy: 1, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Content"
                                                        name={"campPerformance1"}
                                                        labelId={"campPerformanceId1"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === 2 ? true : false}
                                                        cb={(event) => {
                                                            this.props.edmAutoSchedule.performedBy = 2;
                                                            this.setState({ campPerformanceBy: 2, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                                <Col md={4}>
                                                    <RSRadio
                                                        lbl="Schedule"
                                                        name={"campPerformance2"}
                                                        labelId={"campPerformanceId2"}
                                                        className="marginB20"
                                                        checked={this.state.campPerformanceBy === 3 ? true : false}
                                                        cb={(event) => {
                                                            this.props.edmAutoSchedule.performedBy = 3;
                                                            this.setState({ campPerformanceBy: 3, errCampPerformanceByMsg: null });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        {/* <label>Auto schedule</label> */}
                                    </Col>
                                    <Col md={7}>
                                        <ul className="time-row">
                                            <li>
                                                <i className='icon-timer-large blue icons-md' />
                                            </li>
                                            <li>
                                                <label>Start in</label>
                                            </li>
                                            <li>
                                                <RSInput2
                                                    ph="Enter value"
                                                    cls='mb0'
                                                    required={true}
                                                    max={3}
                                                    val={this.state.periodValue}
                                                    cb={(text) => {
                                                        let numRegex = /^[0-9]+$/;
                                                        if (text.length > 0) {
                                                            if (numRegex.test(text)) {
                                                                this.props.edmAutoSchedule.startIn = text;
                                                                this.setState({ periodValue: text, errPeriodValueMsg: null });
                                                            } else {
                                                                this.setState({ errPeriodValueMsg: "Accept only numbers" })
                                                            }
                                                        } else {
                                                            this.props.edmAutoSchedule.startIn = text;
                                                            this.setState({ periodValue: text, errPeriodValueMsg: PERIOD_VALUE })
                                                        }
                                                    }}
                                                    errorMessage={this.state.errPeriodValueMsg} />
                                            </li>
                                            <li>
                                                <div className="position-relative">
                                                    {this.state.errPeriodTypeMsg && (
                                                        <div className="validation-message">
                                                            {this.state.errPeriodTypeMsg}
                                                        </div>
                                                    )}
                                                    <BootstrapDropdown
                                                        data={periodDropData}
                                                        defaultItem={periodDropData[this.state.periodType - 1]}
                                                        customAlignRight={true}
                                                        className="fs16-btn"
                                                        onSelect={(item, index) => {
                                                            let errMsg = item === "" ? PERIOD_TYPE : null;
                                                            this.props.edmAutoSchedule.statusID = index + 1;
                                                            this.setState({ periodType: index + 1, errPeriodTypeMsg: errMsg });
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <RSSecondaryBtn
                        onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                    <RSPrimaryBtn
                        onClick={() => {
                            if (this.state.isAutoSchedule) {
                                if (isEmpty(this.state.campPerformanceBy)) {
                                    this.setState({ errCampPerformanceByMsg: CAMP_PERFORMANCE });
                                    return false;
                                }
                                if (this.state.periodValue <= 0) {
                                    this.setState({ errPeriodValueMsg: PERIOD_VALUE });
                                    return false;
                                }
                                if (isEmpty(this.state.periodType)) {
                                    this.setState({ errPeriodTypeMsg: PERIOD_TYPE });
                                    return false;
                                }
                                let i = 0
                                for (i; i < this.props.communicationData.content.length; i++) {
                                    let element = this.props.communicationData.content[i];
                                    if (isEmpty(element.localBlastDateTime)) {
                                        this.setState({ errPeriodValueMsg: "Schedule Split " + element.splitType + " communication to save auto schedule" });
                                        return false;
                                    }
                                }
                            }
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Save</RSPrimaryBtn>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SplitSettingsModal;

const periodDropData = ["Hour(s)", "Day(s)", "Week(s)", "Month(s)"];