import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSCheckbox } from "Components/RSInputs";
import { RSPTooltip } from "Components/Tooltip";
import { RSDropdownList } from "Components/RSDropdowns";
import RESTextEditor from "Components/Editor/RESTextEditor";
import { RSUploadImageComponent } from "Components/UploadImage/RSUploadImageComponent";
import DynamicInfo from "./DynamicInfo";
import SubPreview from "./SubPreview";
import { isEmpty, getAuthUserId, isValidWebsite, getServerConfig, getCampaignMaster } from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import {
  TITLE,
  BODY,
  CAMPAIGN_TYPE,
  PRODUCT_CATEGORY,
  CHANNEL_TYPE,
  INTEREST_TYPE,
  ACCEPT_TERMS,
  SUCCESS_BODY,
  REDIRECTION_URL,
  LIST_NAME,
  UPDATE_LIST_NAME,
  SPECIAL_CHARACTERS,
  VALID_REDIRECTION_URL,
} from "Helper/Constants/validationMessage";
import { SAVE_SUBSCRIPTION, GET_SUBSCRIPTION } from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import WelcomeModal from "./WelcomeModal";
import * as images from 'Assets/Images'

const SubscribeCreation = (props) => {
  const history = useHistory();
  const context = useContext(authContext);
  const location = useLocation();
  const imageUrl = getServerConfig().slice(0, -1);

  const [mode, setMode] = useState(location.state.mode)
  const [logoPath, setLogoPath] = useState(null);
  const [welcomeContent, setWelcomeContent] = useState("We really appreciate you signing up to receive email communications from us.");
  const [welcomeMail, setWelcomeMail] = useState("");
  const [successContent, setSuccessContent] = useState("Thanks for subscribing to us.");
  const [title, setTitle] = useState("");
  const [errMsgTitle, setErrMsgTitle] = useState(null);
  const [errMsgWelcomeContent, setErrMsgWelcomeContent] = useState("");
  const [campaignType, setCampaignType] = useState([]);
  const [errMsgCampaignType, setErrMsgCampaignType] = useState(null);
  const [productCategory, setProductCategory] = useState([]);
  const [errMsgProductType, setErrMsgProductType] = useState(null);
  const [channelData, setChannelData] = useState([]);
  const [errMsgChannel, setErrMsgChannel] = useState(null);
  const [interestSel, setInterestSel] = useState("");
  const [errMsgInterest, setErrMsgInterest] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [errMsgTerm, setErrMsgTerm] = useState(null);
  const [errMsgSuccessContent, setErrMsgSuccessContent] = useState(null);
  const [redirectionUrl, setRedirectionURL] = useState("");
  const [errMsgRedirectionURL, setErrMsgRedirectURL] = useState(null);
  const [subscribeSettingId, setSubscribeSettingId] = useState(0);
  const [interestCategoryData, setInterestCategoryData] = useState([]);
  const [isEnable, setIsEnable] = useState(false);
  const [subjectLine, setSubjectLine] = useState("");
  const [tabId, setTabId] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [isOpenUnsubscribeModal, setisOpenUnsubscribeModal] = useState(false);
  const [listName, setListName] = useState("");
  const [isAdvanceSettingsEnabled, setIsAdvanceSettingsEnabled] = useState(false);
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);
  const [errMsgList, setErrListName] = useState(null);
  const [errMsgLogoPath, setErrMsgLogoPath] = useState(null)
  const [newImg, setNewImg] = useState(null)
  const [othersComments, setOthersComments] = useState("");
  const [errMsgOtherComments, setErrMsgOtherComments] = useState(null);
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [channelTypes, setChannelTypes] = useState([]);
  const [imageType, setImageType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
    getSubscribtionDataFromServer();
    let campaignType = [];
    let productCategory = [];
    let channelType = [];
    let campaignMaster = getCampaignMaster();
    campaignMaster.campaignAttributeList.forEach((item, index) => {
      campaignType.push(item.attributeName)
    })
    campaignMaster.productCategorysList.forEach((item, index) => {
      productCategory.push(item.categoryName)
    })
    campaignMaster.channelType.forEach((item, index) => {
      channelType.push(item.channelId === 1 ? "Email" : item.channelId === 2 ? "SMS" : item.channelId === 3 ? "QR code" : "WhatsApp")
    })
    setCampaignTypes(campaignType)
    setProductCategories(productCategory)
    setChannelTypes(channelType)
  }, [context.globalStateData.getIsReloadData]);

  const getSubscribtionDataFromServer = () => {
    if (location.state.mode === "edit") {
      const params = {
        userId: getAuthUserId(),
        subscribeSettingId: location.state.subscribeSettingId,
      };
      setSubscribeSettingId(location.state.subscribeSettingId);
      connectServer({
        path: GET_SUBSCRIPTION,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            if (res?.data) {
              setTabId(res.data.emailContentType || "");
              setWelcomeContent(res.data.subscribeText || "");
              setSuccessContent(res.data.successMessage || "");
              setWelcomeMail(res.data.welcomeMail || "");
              setLogoPath(res.data.logopath || null);
              setNewImg(res.data.logopath || "");
              setListName(res.data.subscribeName);
              setTitle(res.data.subscribeTitle);
              setRedirectionURL(res.data.redirectionUrl);
              setIsChecked(res.data.termsCondition);
              setIsEnable(res.data.isOtherInterest);
              setIsAdvanceSettingsEnabled(res.data.isAdvSettingEnabled);
              setSubjectLine(res.data.subjectLine);
              setCampaignType(res.data.subscribeCategory.split(","));
              setChannelData(res.data.channelList.split(","));
              setProductCategory(res.data.verificationText.split(","));
              setInterestSel(res.data.interest);
              setInterestCategoryData(res.data.interestList !== "" ? res.data.interestList.split(",") : []);
              setOthersComments(res.data.otherInterestComments || "");
            }
          }
        },
        fail: (err) => { },
      });
    }
  };

  const validateCreationForm = () => {
    let isValidate = true;
    if (isEmpty(title)) {
      setErrMsgTitle(TITLE);
      isValidate = false;
    }
    if (isEmpty(welcomeContent)) {
      setErrMsgWelcomeContent(BODY);
      isValidate = false;
    }
    if (!campaignType.length) {
      setErrMsgCampaignType(CAMPAIGN_TYPE);
      isValidate = false;
    }
    if (!productCategory?.length) {
      setErrMsgProductType(PRODUCT_CATEGORY);
      isValidate = false;
    }
    if (!channelData?.length) {
      setErrMsgChannel(CHANNEL_TYPE);
      isValidate = false;
    }
    // if (!interestSel?.length) {
    //   setErrMsgInterest(INTEREST_TYPE);
    //   return false;
    // }
    if (interestSel === "Others" && isEmpty(othersComments)) {
      setErrMsgOtherComments("Enter your comments")
      isValidate = false;
    }
    if (!isChecked) {
      setErrMsgTerm(ACCEPT_TERMS);
      isValidate = false;
    }
    if (isAdvanceSettingsEnabled) {
      if (isEmpty(successContent)) {
        setErrMsgSuccessContent(SUCCESS_BODY);
        isValidate = false;
      }
      if (isEmpty(redirectionUrl)) {
        setErrMsgRedirectURL(REDIRECTION_URL);
        isValidate = false;
      }
      if (!isValidWebsite(redirectionUrl)) {
        setErrMsgRedirectURL(VALID_REDIRECTION_URL);
        isValidate = false;
      }
    }
    return isValidate;
  };

  const handleInterestType = (interestArr) => {
    setInterestCategoryData(interestArr);
  };

  const handleEnableOthers = (isEnable) => {
    if (!isEnable && interestCategoryData.length === 0) {
      setInterestSel("")
    } else if (!isEnable) {
      setInterestSel(interestSel === "Others" ? "" : interestSel)
    }
    setIsEnable(isEnable);
  };

  const handleSubjectLine = (subjectText) => {
    setSubjectLine(subjectText);
  };

  const handleTab = (tabId) => {
    setTabId(tabId);
  };

  const handleWelcomeMail = (content) => {
    setWelcomeMail(content);
  };

  const getCampaignTypeList = () => {
    var temp = campaignTypes
    var listTemp = []
    temp.forEach(obj => {
      let temp2 = campaignType.filter(
        item => item === obj
      )
      if (temp2.length === 0) {
        listTemp.push(obj)
      }
    })
    return listTemp
  }

  const getProductTypeList = () => {
    var temp = productCategories
    var listTemp = []
    temp.forEach(obj => {
      let temp2 = productCategory.filter(
        item => item === obj
      )
      if (temp2.length === 0) {
        listTemp.push(obj)
      }
    })
    return listTemp
  }

  const getChannelList = () => {
    var temp = channelTypes
    var listTemp = []
    temp.forEach(obj => {
      let temp2 = channelData.filter(
        item => item === obj
      )
      if (temp2.length === 0) {
        listTemp.push(obj)
      }
    })
    return listTemp
  }

  const getInterestTypeList = () => {
    var temp = [...interestCategoryData]
    if (isEnable) {
      temp.push("Others")
    }
    return temp
  }

  const saveSubCreation = () => {
    if (isEmpty(listName)) {
      let errMsg = LIST_NAME;
      setErrListName(errMsg);
      return;
    } else {
      let params = {
        emailContentType: tabId,
        logopath: logoPath,
        subscribeName: listName,
        subscribeSettingId: subscribeSettingId,
        subscribeTitle: title,
        subscribeText: welcomeContent,
        subscribeCategory: campaignType.toString(),
        verificationText: productCategory.toString(),
        channelList: channelData.toString(),
        interestList: interestCategoryData.toString(),
        interest: interestSel,
        termsCondition: isChecked,
        isAdvSettingEnabled: isAdvanceSettingsEnabled,
        isOtherInterest: (isEnable || interestCategoryData.filter(item => item === "Others").length > 0) ? true : false,
        welcomeMail: welcomeMail,
        subjectLine: subjectLine,
        redirectionUrl: redirectionUrl,
        successMessage: successContent,
        isOpenUnSubModal: isOpenUnsubscribeModal,
        otherInterestComments: othersComments,
        logopathType: imageType
      };
      connectServer({
        path: SAVE_SUBSCRIPTION,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setisOpenUnsubscribeModal(!isOpenUnsubscribeModal);
            history.push({
              pathname: `communication-settings`,
              state: { index: 1, updateIndex: 0 },
            });
          } else {
            setErrListName(res.message);
          }
        },
        fail: (err) => { },
      });
    }
  };

  return (
    <LayoutPageWrapper>
      <Container className="page-header box-bottom-space">
        <div className="header-title d-flex justify-content-between">
          <h1>Subscription</h1>
          {/* <div className="back-text" onClick={() => history.goBack()}>
            <i className="icon-sd-arrow-left-mini icon-mini blue"></i>
            Back
          </div> */}
        </div>
        <div className="portlet-box-theme p0">
          <Row className="custom-column mt40 mb25">
            <Col md={12}>
              <div className="d-flex justify-content-center">
                <RSUploadImageComponent
                  className={`enable-remove`}
                  defaultImg={images.Building}
                  defaultValue={logoPath}
                  newImg={newImg ? `${imageUrl}${newImg}` : null}
                  text={`${!newImg && !logoPath ? "Upload" : "Edit"}`}
                  icon={`${!newImg && !logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                  cb={img => {
                    setLogoPath(img)
                    setErrMsgLogoPath(null)
                    setNewImg(null)
                    setMode("add")
                  }}
                  getImageType={(value) => {
                    setImageType(value)
                  }}
                  error={(value) => {
                    setErrMsgLogoPath(value)
                  }}
                  errorMessage={errMsgLogoPath}
                  removeIcon={logoPath !== null}
                  handleRemove={() => {
                    setNewImg(null)
                    setLogoPath(null)
                  }
                  }
                />
              </div>

              {/* <RSUploadImageComponent
                defaultImg={images.User}
                defaultValue={logoPath}
                newImg={newImg ? `${imageUrl}${newImg}` : null}
                text={!newImg && !logoPath ? 'Upload' : 'Edit'}
                icon={!newImg && !logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}
                cb={(img) => {
                  setLogoPath(img)
                  setErrMsgLogoPath(null)
                  setNewImg(null)
                  setMode("add")
                }}
                getErrMsg={errMsg => setErrMsgLogoPath(errMsg)}
              >
                {errMsgLogoPath && <div className="validation-message transformY-6">{errMsgLogoPath}</div>}
              </RSUploadImageComponent> */}

            </Col>
          </Row>
          <Row className="custom-column mt20 mb25">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Title</label>
            </Col>
            <Col md={6}>
              <RSInput2
                required
                name="title"
                ph={"Enter title"}
                val={title}
                max={50}
                cb={(text) => {
                  if (text.length <= 50) {
                    var regex = /^[A-Za-z0-9\-\.\'\g ]+$/;
                    if (text.length > 0) {
                      if (regex.test(text)) {
                        setTitle(text);
                        setErrMsgTitle(null);
                      } else {
                        setErrMsgTitle(SPECIAL_CHARACTERS);
                      }
                    } else {
                      setTitle(text);
                      setErrMsgTitle(TITLE);
                    }
                  } else {
                    setErrMsgTitle("Title should not be more than 50")
                  }
                }}
                ob={(text) => {
                  if (text.length <= 50) {
                    let errMsg = text ? null : TITLE;
                    setErrMsgTitle(errMsg);
                  } else {
                    setErrMsgTitle("Title should not be more than 50")
                  }
                }}
                errorMessage={errMsgTitle}
              />
            </Col>
          </Row>
          <Row className="even py15 mx0 custom-column">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Message</label>
            </Col>
            <Col md={6}>
              <div className="position-relative checkbox-alert">
                {errMsgWelcomeContent && (
                  <div className="validation-message">{errMsgWelcomeContent}</div>
                )}
                <RESTextEditor
                  content={welcomeContent}
                  subUnsub={true}
                  callBack={(text, richText) => {
                    if (text.length <= 500) {
                      setWelcomeContent(richText);
                      let errMsg = text ? null : BODY;
                      setErrMsgWelcomeContent(errMsg);
                    }
                  }}
                />
              </div>
              <small style={{ float: "right", "fontStyle": "italic" }}>
                Up to 500 characters
              </small>
            </Col>
          </Row>
          <Row className="custom-column mt20 mb25">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Communication type</label>
            </Col>
            <Col md={6}>
              <div className="chips-input-container">
                <div className="chips-input-box required">
                  {errMsgCampaignType && (
                    <div className="validation-message">
                      {errMsgCampaignType}
                    </div>
                  )}
                  <MultiSelect
                    placeholder="-- Select --"
                    data={getCampaignTypeList()}
                    onChange={(event) => {
                      setCampaignType(event.target.value);
                      let errMsg = event.target.value.length
                        ? null
                        : CAMPAIGN_TYPE;
                      setErrMsgCampaignType(errMsg);
                    }}
                    value={campaignType}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="custom-column mt20 mb25">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Product type</label>
            </Col>
            <Col md={6}>
              <div className="chips-input-container">
                <div className="chips-input-box required">
                  {errMsgProductType && (
                    <div className="validation-message">
                      {errMsgProductType}
                    </div>
                  )}
                  <MultiSelect
                    placeholder="-- Select --"
                    data={getProductTypeList()}
                    onChange={(event) => {
                      setProductCategory(event.target.value);
                      let errMsg = event.target.value.length
                        ? null
                        : PRODUCT_CATEGORY;
                      setErrMsgProductType(errMsg);
                    }}
                    value={productCategory}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="custom-column mt20 mb25">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Channel type</label>
            </Col>
            <Col md={6}>
              <div className="chips-input-container">
                <div className="chips-input-box required">
                  {errMsgChannel && (
                    <div className="validation-message">{errMsgChannel}</div>
                  )}
                  <MultiSelect
                    placeholder="-- Select --"
                    data={getChannelList()}
                    onChange={(event) => {
                      setChannelData(event.target.value);
                      let errMsg = event.target.value.length
                        ? null
                        : CHANNEL_TYPE;
                      setErrMsgChannel(errMsg);
                    }}
                    value={channelData}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="custom-column mt20 mb25">
            <Col md={{ span: 3, offset: 1 }}>
              <label className="label-row">Interest type</label>
            </Col>
            <Col md={6}>
              <div className="position-relative">
                {errMsgInterest && (
                  <div className="validation-message">{errMsgInterest}</div>
                )}
                <RSDropdownList
                  data={getInterestTypeList()}
                  required={true}
                  onChange={(event) => {
                    setInterestSel(event.target.value);
                    let errMsg = event.target.value.length
                      ? null
                      : INTEREST_TYPE;
                    setErrMsgInterest(errMsg);
                    if (event.target.value === "Others") {
                      setOthersComments("")
                    }
                  }}
                  defaultItem={"-- Select interest type --"}
                  value={interestSel !== "" ? interestSel : "-- Select interest type --"}
                  allowCustom={true}
                />
              </div>
              <div className="input-outer-set">
                <RSPTooltip text="Interest type" position="top">
                  <i
                    className="icon-sd-settings-medium icons-md blue cp"
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                  />
                </RSPTooltip>
              </div>
            </Col>
          </Row>
          {interestSel === "Others" &&
            <Row className="custom-column marginT20 marginB25">
              <Col md={{ span: 3, offset: 1 }}></Col>
              <Col md={6}>
                {errMsgOtherComments && (
                  <div className="validation-message ml12 transY-10">{errMsgOtherComments}</div>
                )}
                <textarea
                  placeholder="Enter your comments"
                  rows={5}
                  cols={50}
                  value={othersComments}
                  onChange={(event) => {
                    let errMsg = event.target.value.length > 0 ? null : "Enter your comments";
                    setErrMsgOtherComments(errMsg);
                    setOthersComments(event.target.value)
                  }} />
              </Col>
            </Row>
          }
          <Row className="custom-column mt20">
            <Col md={{ span: 3, offset: 1 }}></Col>
            <Col md={6}>
              <div className="position-relative checkbox-alert">
                {errMsgTerm && (
                  <div className="validation-message">{errMsgTerm}</div>
                )}
                <RSCheckbox
                  className="marginB20"
                  checked={isChecked}
                  lbl={"I agree to the "}
                  labelClass="sub-dashed-border"
                  cb={(status) => {
                    setIsChecked(status);
                    let errMsg = status ? null : ACCEPT_TERMS;
                    setErrMsgTerm(errMsg);
                  }}
                >
                  <Link
                    to={{
                      pathname: 'https://marketingstar.io/terms-and-conditions.html'
                    }}
                    target='_blank'
                    className='text-orange'
                  >
                    Terms & Conditions.
                  </Link>
                </RSCheckbox>
              </div>
            </Col>
          </Row>
          <Row className="custom-column mt15 mb30">
            <Col md={{ span: 3, offset: 1 }}>
              <label>Advanced settings</label>
            </Col>
            <Col md={1}>
              <div className="d-flex justify-content-center">
                <Switch
                  className="mt0 mr15"
                  checked={isAdvanceSettingsEnabled}
                  onChange={(e) => {
                    setIsAdvanceSettingsEnabled(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
          {isAdvanceSettingsEnabled && (
            <>
              <Row className="custom-column mt40 mb30">
                <Col md={{ span: 3, offset: 1 }}>
                  <label>Welcome mail</label>
                </Col>
                <Col md={6}>
                  <RSPTooltip text="Welcome mail" position="top">
                    <i
                      className="icon-sd-form-edit-medium blue icons-md pl5 cp"
                      onClick={() => {
                        setIsOpenWelcomeModal(!isOpenWelcomeModal);
                      }}
                    ></i>
                  </RSPTooltip>
                </Col>
              </Row>
              <Row className="custom-column mt20 mb25">
                <Col md={{ span: 3, offset: 1 }}>
                  <label className="label-row">Successful message</label>
                </Col>
                <Col md={6}>
                  <div className="position-relative checkbox-alert">
                    {errMsgSuccessContent && (
                      <div className="validation-message ml12 transY-10">
                        {errMsgSuccessContent}
                      </div>
                    )}
                    <RESTextEditor
                      content={successContent}
                      subUnsub={true}
                      callBack={(text, richText) => {
                        if (text.length <= 500) {
                          setSuccessContent(richText);
                          let errMsg = text ? null : SUCCESS_BODY;
                          setErrMsgSuccessContent(errMsg);
                        }
                      }}
                    />
                  </div>
                  <small style={{ float: "right", "fontStyle": "italic" }}>
                    Up to 500 characters
                  </small>
                </Col>
              </Row>
              <Row className="custom-column mt20 mb25">
                <Col md={{ span: 3, offset: 1 }}>
                  <label className="label-row">Redirection URL</label>
                </Col>
                <Col md={6}>
                  <RSInput2
                    name="redirectionUrl"
                    required
                    ph={"Enter redirection URL"}
                    val={redirectionUrl}
                    max={100}
                    cb={(text) => {
                      setRedirectionURL(text);
                      let errMsg = text ? null : REDIRECTION_URL;
                      setErrMsgRedirectURL(errMsg);
                    }}
                    ob={(text) => {
                      if (text.length > 0) {
                        if (isValidWebsite(text)) {
                          setRedirectionURL(text);
                        } else {
                          setErrMsgRedirectURL(VALID_REDIRECTION_URL);
                        }
                      } else {
                        setErrMsgRedirectURL(REDIRECTION_URL);
                      }
                    }}
                    errorMessage={errMsgRedirectionURL}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
        {isOpenModal ? (
          <DynamicInfo
            isOpen={isOpenModal}
            interestType={handleInterestType}
            enableOthers={handleEnableOthers}
            interestCategory={Array.prototype.concat.apply(
              [],
              interestCategoryData
            )}
            isEnabled={isEnable}
            onChangeIsOpen={(value) => {
              setIsOpenModal(!isOpenModal);
            }}
          ></DynamicInfo>
        ) : null}
        {isOpenPreviewModal ? (
          <SubPreview
            mode={mode}
            isOpen={isOpenPreviewModal}
            title={title}
            imgPath={logoPath}
            campaignType={campaignType}
            productType={productCategory}
            channelData={channelData}
            interestType={interestSel}
            isChecked={isChecked}
            welcomeText={welcomeContent}
            onChangeIsOpen={(value) => {
              setIsOpenPreviewModal(!isOpenPreviewModal);
            }}
          ></SubPreview>
        ) : null}
        <div className="btn-container d-flex justify-content-end mt25">
          <RSSecondaryBtn
            className="pr0 mr5"
            onClick={() => {
              history.push({
                pathname: `communication-settings`,
                state: { index: 1, updateIndex: 0 },
              });
            }}
          >
            Cancel
          </RSSecondaryBtn>
          <RSSecondaryBtn
            onClick={() => {
              setIsOpenPreviewModal(true);
            }}
          >
            Preview
          </RSSecondaryBtn>
          <RSPrimaryBtn
            onClick={() => {
              if (validateCreationForm()) {
                if (mode === "edit") {
                  saveSubCreation();
                } else {
                  setisOpenUnsubscribeModal(!isOpenUnsubscribeModal);
                  setErrListName(null);
                  setListName("");
                }
              }
            }}
          >
            {mode === "edit" ? "Update" : "Save"}
          </RSPrimaryBtn>
        </div>
        <Modal
          centered
          backdrop="static"
          keyboard={false}
          size="md"
          show={isOpenUnsubscribeModal}
          onHide={() => {
            setisOpenUnsubscribeModal(!isOpenUnsubscribeModal);
          }}
        >
          <Modal.Header closeButton>
            <h2>Subscription</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <label className="label-row">Give your list a name</label>
              </Col>
            </Row>
            <Row>
              <Col className={subscribeSettingId > 0 ? "click-off" : ""}>
                <RSInput2
                  name="listName"
                  ph={"List name"}
                  val={listName}
                  required={true}
                  max={50}
                  cb={(value) => {
                    setListName(value);
                    let errMsg = value ? null : UPDATE_LIST_NAME;
                    setErrListName(errMsg);
                  }}
                  ob={(text) => {
                    let trimText = text.trimStart().trimEnd();
                    let errMsg = trimText ? null : UPDATE_LIST_NAME;
                    setErrListName(errMsg);
                    setListName(trimText);
                  }}
                  errorMessage={errMsgList}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <RSPrimaryBtn className={listName !== "" ? "" : "click-off"}
              onClick={() => {
                saveSubCreation();
              }}
            >
              Save
            </RSPrimaryBtn>
          </Modal.Footer>
        </Modal>
        {isOpenWelcomeModal && (
          <WelcomeModal
            isOpen={isOpenWelcomeModal}
            subjectLine={handleSubjectLine}
            contentType={handleTab}
            welcomeMail={handleWelcomeMail}
            onChange={(value) => {
              setIsOpenWelcomeModal(!isOpenWelcomeModal);
            }}
            sentSubjectLine={subjectLine}
            sentWelcomeMail={welcomeMail}
            sentTabId={tabId}
          ></WelcomeModal>
        )}
      </Container>
    </LayoutPageWrapper>
  );
};
export default withRouter(SubscribeCreation);
