import { RSPTooltip } from 'Components/Tooltip';
import { NO_MEDIA_IMAGE } from 'Helper/Constants/endPoint';
import React, { Component } from 'react';

class WhatsAppPreviewContent extends Component {
    state = {
        imageSrc: this.props.imageSrc
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ imageSrc: this.props.imageSrc })
        }
    }
    render() {
        return (
            <div className="s-wm-preview ml15">
                <RSPTooltip text="Remove" position="top">
                    <i className='icon-sd-delete-medium blue icon-sm float-end mb5 cp'
                        onClick={() => {
                            this.props.onChangeIsRemove(true);
                        }}></i>
                </RSPTooltip>
                <div className='image-preview-port'>
                    {this.state.imageSrc === undefined || this.state.imageSrc === null || this.state.imageSrc === "" ? <img src={`${this.state.imagePath + NO_MEDIA_IMAGE}`} /> : <>
                        {this.state.imageSrc?.length > 0 &&
                            <>
                                {/* <div>
                                          <i className="icon-sd-delete-medium icons-md blue marginR10" />
                                      </div> */}
                                {
                                    this.state.imageSrc.toLowerCase().includes('png') || this.state.imageSrc.toLowerCase().includes('jpg') || this.state.imageSrc.toLowerCase().includes('jpeg') || this.state.imageSrc.toLowerCase().includes('gif')
                                        ? <img
                                            src={`${this.state.imageSrc}`}
                                            className='preview-img'
                                            // onLoad={handleImageLoaded.bind(this)}
                                            // onError={handleImageErrored.bind(this)}
                                            alt={'Image loading'} />
                                        : null
                                }
                                {this.state.imageSrc.toLowerCase().includes('.mp4') || this.state.imageSrc.toLowerCase().includes('.video') || this.state.imageSrc.toLowerCase().includes('.mp3') || this.state.imageSrc.toLowerCase().includes('.be') ?
                                    <>
                                        {/* <ReactPlayer
                                            style={{ width: '255px', height: '150px' }}
                                            url={this.state.imageSrc}
                                            className="player-wrapper"
                                            controls
                                        /> */}

                                    </>
                                    : null}

                            </>}
                    </>}
                </div>

            </div>
        );
    }
}

export default WhatsAppPreviewContent;