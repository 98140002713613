import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RSRadio } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { BootstrapDropdown } from "Components/BootstrapDropdown/BootstrapDropdown";
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const SplitABModal = props => {

    const [selectedItem, setSelectedItem] = useState(modalData[0])

    return <Modal
        show={props.show}
        centered
        backdrop="static"
        keyboard={false}
        size="md">
        <Modal.Header>
            <h2>Split A/B</h2>
            <BootstrapDropdown
                data={modalData}
                rightAlign
                defaultItem={modalData[0].title}
                fieldKey="title"
                onSelect={(item, index) => { setSelectedItem(item) }}
            />
        </Modal.Header>
        <Modal.Body>
            <Row className="split-ab-modal">
                {
                    selectedItem.data.map(item => {
                        return <Col>
                            <h3>{item.title}</h3>
                            <div className="circular-chart-splitab">

                                <CircularProgressbarWithChildren
                                    value={item.percent}
                                    styles={buildStyles({

                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',

                                        // Text size
                                        textSize: '16px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,

                                        // Colors
                                        pathColor: '#9ccc06',
                                        trailColor: '#eeeeee',
                                        backgroundColor: '#3e98c7',
                                    })}
                                >
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                    <div className="circular-text">
                                        <b>{item.percent}<small>%</small></b>
                                        <span>Split size</span>
                                        <h4>{item.splitSize}</h4>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>

                            <RSRadio
                                lbl={item.switchTitle}
                                name="all"
                                labelId={item.switchTitle}
                                cb={(event) => {

                                }}
                            />
                        </Col>
                    })
                }
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <div className="btn-container d-flex justify-content-end">
                <RSSecondaryBtn paddingR="0px" onClick={() => { props.closeSplitAb() }} >Cancel</RSSecondaryBtn>
                <RSSecondaryBtn className="mx10 blue" onClick={() => { props.closeSplitAb() }} >Analytics</RSSecondaryBtn>
                <RSPrimaryBtn onClick={() => { props.closeSplitAb() }} >Save</RSPrimaryBtn>
            </div>
        </Modal.Footer>
    </Modal>
}

const modalData = [
    {
        title: "Subject line",
        id: "1001",
        "data": [
            {
                title: "Group A Open rate",
                splitSize: "1400",
                percent: 70,
                switchTitle: "Offer of the week",
                img: null
            },
            {
                title: "Group B Open rate",
                splitSize: "600",
                percent: 30,
                switchTitle: "Grab it within this week",
                img: null
            }
        ]
    },
    {
        title: "Email content",
        id: "1002",
        "data": [
            {
                title: "Group A Engagement rate",
                splitSize: "1400",
                percent: 85,
                switchTitle: null,
                img: "img1"
            },
            {
                title: "Group B Engagement rate",
                splitSize: "600",
                percent: 15,
                switchTitle: null,
                img: "img2"
            }
        ]
    },
    {
        title: "Schedule",
        id: "1003",
        "data": [
            {
                title: "Group A Open rate",
                splitSize: "1400",
                percent: 50,
                switchTitle: "Thu, Oct 28, 2021 15:17:37",
                img: null
            },
            {
                title: "Group B Open rate",
                splitSize: "600",
                percent: 50,
                switchTitle: "Wed, Nov 03, 2021 15:17:37",
                img: null
            }
        ]
    }
]