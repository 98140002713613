import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { RSCancelBtn, RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons'

import { FeaturesWrapper } from './Components/Features.styled'
import { Container, Row, Col } from 'react-bootstrap'
import { RSCheckbox, RSInput2, RSRadio, RSTextInputWithIcon } from 'Components/RSInputs'
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'
import Icon from 'Components/Icon/Icon';
import * as icons from "Constants/GlobalConstant/Glyphicons/Glyphicons-v5.0";
import jsonData from './RSPPricing.json'
import { getAuthClientId, getAuthUserId, getUserData, numberWithCommas, rspInternationalFormat } from 'Helper/Utils/Utils'
import { GET_FEATURES_MASTER, GET_OFFER_COUPON, SAVE_PAYMENT_DETAILS, SAVE_SUBSCRIBE_PLAN } from 'Helper/Constants/endPoint'
import authContext from 'Helper/StateHandler/auth-context'
import { connectServer } from 'Helper/Network/networkHandler'
// import queryString from 'query-string';
import { RSErrorPopup } from 'Components/RSConfirmAlert'
import DataProviderTermsModal from './Components/DataProviderTermsModal'
import DataProviderModal from './Components/DataProviderModal'
import ResulPayModal from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/ConfirmPayment/ResulPayModal'

let addOns = [
  {
    "licenseFeatureID": 14,
    "licenseFeatureName": "Branded - Short URL",
    "licenseFeature": "Add-on",
    "licensePrice": 25,
    "checked": false
  },
  {
    "licenseFeatureID": 19,
    "licenseFeatureName": "Addiontal users",
    "licenseFeature": "Add-on",
    "licensePrice": 25,
    "checked": false,
    "noOfUsers": 2
  }
]

let selectFeature = {
  planId: 3,
  addOns: addOns
}

const RSPCatelogue = props => {

  const context = useContext(authContext)
  const history = useHistory()

  //const overallJson =jsonData
  const [message, setMessage] = useState('No data received from IFrame, yet.');
  const [transactionId, settransactionId] = useState(0);
  const [planInvoiceDetails, setPlanInvoiceDetails] = useState(null);
  const [overallJson, setOverallJson] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [selectAddOnsCheckbox, setSelectAddOnsCheckbox] = useState(null)
  const [selectFreeAddOnsCheckbox, setSelectFreeAddOnsCheckbox] = useState(null)
  const [selectPackage, setSelectPackage] = useState(2)
  const defaultTrafficeLimitedData = { Free: freeTrafficLimitArry[0], Essentials: essentialsTrafficLimitArry[0], Premier: premierTrafficLimitArry[0], Ultimate: ultimateTrafficLimitArry[0] }
  const [selectedTrafficLimitData, setSelectedTrafficLimitData] = useState(defaultTrafficeLimitedData)
  const [selectedAdditionalUsers, setSelectedAAdditionalUsers] = useState({ Free: "Select", Essentials: 1, Premier: 1, Ultimate: 1 })
  const [isOpenResulPay, setIsOpenResulPay] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState(null)
  const [promoCode, setPromoCode] = useState("")
  const [promoCodeErr, setPromoCodeErr] = useState(null)
  const [promoSuccess, setPromoSuccess] = useState(false)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [stepperValue, setStepperValue] = useState(1)


  const [currentPackageId, setCurrentPackageId] = useState(0)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [invoiceId, setInvoiceId] = useState(0)

  const [plan1Status, setPlan1Status] = useState('')
  const [plan2Status, setPlan2Status] = useState('')
  const [plan3Status, setPlan3Status] = useState('')
  const [plan4Status, setPlan4Status] = useState('')
  const [planUpgrade, setPlanUpgrade] = useState(false)
  const [addOnUpgrade, setAddOnUpgrate] = useState(false)

  const [frequencyData, setFrequencyData] = useState([])
  const [walletAmount, setWalletAmount] = useState(0)
  const [walletBalence, setWalletBalence] = useState(0)

  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMsg] = useState(null)

  const [scroll, setScroll] = useState(false)
  const [scrollEnd, setScrollEnd] = useState(false)
  const [scrollTab, setScrollTab] = useState(false)
  const [scrollTabOpacity, setScrollTabOpacity] = useState(false)
  const [dataProviders, setDataProviders] = useState([]);
  const [selectedDataProviders, setSelectedDataProviders] = useState([]);
  const [isOpenDataProviderModal, setIsOpenDataProviderModal] = useState(false);
  const [isOpenDataProviderTermsModal, setIsOpenDataProviderTermsModal] = useState(false);

  useEffect(() => {

    // selectFeature.addOns.map(item => {
    //   if (item.licenseFeatureID === 24 && item.checked) {
    //     setStepperValue(item.noOfUsers || 1)
    //   }
    // })
    // setSelectAddOnsCheckbox(selectFeature.addOns)
    getFeatureCost()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 100)
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollEnd(window.scrollY > 1200)
    })
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTabOpacity(window.scrollY > 300)
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTab(window.scrollY > 450)
    })
  }, [])

  React.useEffect(() => {
    if (message.toLowerCase().startsWith('c')) {
      setIsOpenResulPay(false);
    } else if (message.toLowerCase().startsWith('s')) {
      // const payloads = {
      //   clientId: getAuthClientId(),
      //   orderId: orderId,
      //   userId: getAuthUserId(),
      //   clientName: getUserData().clientDetailsLocalization.clientName,
      //   authorizationCode: transactionId,
      //   paymentMode: 1,
      // };
      // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
      // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
    } else if (message.toLowerCase().startsWith('f')) {
      // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
    } else {
      setIsOpenResulPay(false);
    }
  }, [message]);
  React.useEffect(function () {
    var handler = function (ev) {
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'button-click') return;
      if (!ev.data.message) return;
      setMessage(ev.data.message);
      if (ev.data.message.toLowerCase().startsWith('c')) {
        setIsOpenResulPay(false);
      } else if (ev.data.message.toLowerCase().startsWith('s')) {
        settransactionId(ev.data.transactionId);
        setIsOpenResulPay(false);
        // const payloads = {
        //   clientId: getAuthClientId(),
        //   orderId: orderId,
        //   userId: getAuthUserId(),
        //   clientName: getUserData().clientDetailsLocalization.clientName,
        //   authorizationCode: transactionId,
        //   paymentMode: 1,
        // };
        const paymentParams = {
          "status": true, // payment status (response from resul pay)
          "isTenantSubscription": true, // true if Tenant Subscription payment
          "isClientSubscription": false,// true if client Subscription payment(first time subscription)
          "isConsumable": false,// true if consumable payment
          "isRecipient": false,// true if recipient payment
          "transactionNo": ev.data.transactionId, // response from resul pay
          "orderId": ev.data.orderId,//response from resul pay as string
        }
        savePaymentDetails(paymentParams)
        // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
        // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
      } else if (ev.data.message.toLowerCase().startsWith('f')) {
        // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
      } else {
        setIsOpenResulPay(false);
      }
    };
    window.addEventListener('message', handler);
    // Clean up
    return function () {
      return window.removeEventListener('message', handler);
    }; // The "message" param should match with the iframe.js file
  }, []);

  const savePaymentDetails = (params) => {
    connectServer({
      path: SAVE_PAYMENT_DETAILS,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setIsOpenResulPay(false);
          context.toggleAuth(false)
          history.push({ pathname: "thanks", state: { payment: false } });
        }
      },
      fail: err => {
      }
    })
  }

  const updatePlanStatus = (pId) => {
    if (0 < pId) {
      setPlan1Status('click-off')
    }
    if (1 < pId) {
      setPlan2Status('click-off')
    }
    if (2 < pId) {
      setPlan3Status('click-off')
    }
    if (3 < pId) {
      setPlan4Status('click-off')
    }


  }



  const getFeatureCost = () => {
    connectServer({
      path: GET_FEATURES_MASTER,
      params: {
        "invoiceId": getUserData() ? getUserData()?.invoiceId : 0,
        "databaseName": getUserData() ? getUserData()?.clientDetailsLocalization.instanceName : "",
        "pricingDetailsId": getUserData() ? getUserData()?.pricingDetailsId : 0
      },
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: res => {
        if (res?.status) {
          setOverallJson(res || jsonData)
          let planInfo = res?.data?.planInfo || null
          if (planInfo !== null) {
            setCurrentPlanData(planInfo, res)
          } else {
            setSelectedPeriod(res?.data?.frequency[0] || jsonData?.data?.frequency[0])
          }

          // selectDefaultPackage()
          setWalletAmount(res?.data?.planInfo?.remainingAmount)
          setSelectAddOnsCheckbox(getSelectAddons(res?.data?.free?.addOns, res?.data?.planInfo?.addOns))
          setSelectFreeAddOnsCheckbox(getSelectAddons(res?.data?.free?.freeAddOns, res?.data?.planInfo?.freeAddons))
          setDataProviders(res?.data?.dataProviders);
          setSelectedDataProviders([]);
        } else {
          setIsError(true)
          setErrMsg(res?.message || 'Something went wrong')
        }
      },
      fail: error => {
        setIsError(true)
        setErrMsg('Something went wrong')
      }
    })
  }
  const getSelectAddons = (avaliableAddOns, selectaddOns) => {

    avaliableAddOns?.map(aItem => {
      if (selectaddOns !== undefined && selectaddOns !== null && selectaddOns?.length > 0) {
        let tArray = selectaddOns.filter(sItem => sItem.licenseFeatureId === aItem.licenseFeatureId)
        if (tArray.length > 0) {
          if (aItem.licenseFeatureId === 19 && tArray[0].licenseFeatureId) {
            setStepperValue(tArray[0]?.addOnValue || 1)
          }
          aItem.checked = true
        } else {
          aItem.checked = false
        }
      }
      else {
        aItem.checked = false
      }
    })
    return avaliableAddOns
  }
  const setCurrentPlanData = (planData, res) => {
    let pId = (planData?.licenseTypeId - 1 || 0) //(planData?.licenseTypeId || 1) - 1
    let fArray = res?.data?.frequency?.filter(obj => obj.frequencyId === planData?.frequencyId || 0)
    if (fArray?.length > 0) {
      setSelectedPeriod(fArray[0])
    }
    let fdata = res?.data?.frequency.filter(obj => obj.frequencyId >= planData?.frequencyId || 0) || []
    setFrequencyData(fdata)
    setCurrentPlan(planData)
    setSelectPackage(pId)
    updatePlanStatus(pId)
    setCurrentPackageId(pId)
    setInvoiceId(planData?.invoiceId || 0)

  }
  const getOfferCost = (licenseTypeId, frequencyId) => {
    const params = {
      couponCode: promoCode,
      licensingTypeId: licenseTypeId,
      frequencyId: frequencyId,
      offerTypeId: 1
    }
    connectServer({
      path: GET_OFFER_COUPON,
      params,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: res => {
        if (res.status) {
          if (!!res.data) {
            let subTodal = overallJson?.data?.licenseType[selectPackage]?.price + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers);
            let discountAmount = subTodal * res.data.couponPercentage / 100;
            setDiscountAmount(res.data.discountAmount);
            setPromoCodeApplied(true)
            setPromoCodeErr("Coupon code applied successfully")
            setPromoSuccess(true)
            setTimeout(() => {
              setPromoCodeErr(null)
              setPromoSuccess(false)
            }, 2000);
          }
        } else {
          setDiscountAmount(0);
          setPromoCodeApplied(false)
          setPromoCodeErr("Invalid coupon code")
          setPromoSuccess(false)
        }
      },
      fail: error => {
        setDiscountAmount(0);
        setPromoCodeErr("Invalid coupon code")
        setPromoSuccess(false)
        setPromoCodeApplied(false)
        setTimeout(() => {
          setPromoCodeErr(null)
        }, 2000);
      }
    })
  }



  const postPricingDetails = () => {
    let tempAddons = []
    if (currentPlan?.licenseTypeId === overallJson?.data?.licenseType[selectPackage]?.licensingTypeID && currentPlan?.addOns !== null && currentPlan?.addOns !== undefined && currentPlan?.addOns.length > 0) {
      let addOns = selectAddOnsCheckbox
        .filter(item => item.checked)
        .map((item, index) => {
          let addOnId = 0
          let fArray = currentPlan?.addOns?.filter(cItem => cItem.licenseFeatureId === item.licenseFeatureId)
          if (fArray.length > 0) {
            addOnId = fArray[0].addOnId
          }
          tempAddons.push({
            addonId: addOnId,
            licenseFeatureID: item.licenseFeatureId,
            addonValue: item.licenseFeatureId === 19 ? stepperValue : 0,
            licenseValue: item.licenseFeatureId
          })
        })


      //tempAddons = addOns
    } else {
      let addOns = selectAddOnsCheckbox
        .filter(item => item.checked)
        .map((item, index) => {
          return {
            addonId: item.addOnId,
            licenseFeatureID: item.licenseFeatureId,
            addonValue: item.licenseFeatureId === 19 ? stepperValue : 0,
            licenseValue: item.licenseFeatureId
          }
        })
      tempAddons = addOns
    }
    let freeAddOns = selectFreeAddOnsCheckbox
      .filter(item => item.checked)
      .map((item, index) => {
        return {
          addonid: item.addOnId,
          licenseFeatureID: item.licenseFeatureId,
          addonValue: 0,
          licenseValue: item.licenseFeatureId,
          dataProviders: selectedDataProviders,
          licenseGroupId: item.licenseGroupId,
          licensingTypeId: item.licensingTypeId,
        }
      })

    let pupgrate = true
    if (currentPlan?.licenseTypeId === overallJson?.data?.licenseType[selectPackage]?.licensingTypeId) {
      pupgrate = false
    }


    let params = {}
    params.isPlanUpgrade = planUpgrade// false for first time and subscription has ended, true for upgrading te existing plan
    params.isAddOnUpgrade = addOnUpgrade
    params.isPlanActive = true
    params.userId = getAuthUserId() || 0
    params.clientId = getAuthClientId() || 0
    params.licensingTypeID = overallJson?.data?.licenseType[selectPackage]?.licensingTypeID
    params.frequencyId = selectedPeriod.frequencyId
    params.promoCode = promoCode
    params.AddOns = tempAddons
    params.freeAddOns = freeAddOns
    params.tenantInvoiceId = getUserData()?.tenantInvoiceId
    params.clientInvoiceId = getUserData()?.invoiceId
    params.pricingDetailsId = getUserData()?.pricingDetailsId
    params.invoiceId = getUserData() ? getUserData()?.invoiceId : 0// 0 for first time, else invoice id from login data
    params.isNewAccount = false // true for first time, false if account has been activated


    connectServer({
      path: SAVE_SUBSCRIBE_PLAN,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: res => {
        if (res.status) {
          let totalAmount = (featureTitleObj[selectPackage]?.cost + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers) - discountAmount) * selectedPeriod.value
          if (res?.data.isPaymentCompleted) {
            context.toggleAuth(false)
            history.push({ pathname: "thanks", state: { payment: true } });
          } else {
            setPlanInvoiceDetails(res?.data)
            setIsOpenResulPay(true);
          }
          // history.push({
          //   pathname: `payment`,
          //   state: {
          //     amount: res?.data?.amountPayable,
          //     qas: false,
          //     clientId: history?.location?.state?.clientId || getAuthClientId() || 0,
          //     userId: history?.location?.state?.userId || getAuthUserId() || 0,
          //     invoiceId: res?.data?.invoiceId,
          //     pricingDetailsId: res?.data?.pricingDetailsId,
          //     licenceTypeId: overallJson?.data?.licenseType[selectPackage]?.licensingTypeId || 1,
          //     screenName: 'catelog'
          //   }
          // })
        } else {
          //alert(res?.message || "Something went wrong")
          setIsError(true)
          setErrMsg(res?.message || "Something went wrong")
        }
      },
      fail: error => {
        //alert(error?.message || "Something went wrong")
        setIsError(true)
        setErrMsg('Something went wrong')
      }
    })
  }

  const getSubTotal = (arry, selectedAdditionalUsers) => {

    let price = 0


    arry?.map(item => {
      if (item.checked) {
        if (item.licenseFeatureId === 19) {
          price = price + item.licensePrice * stepperValue || 0
        } else {
          price = price + item.licensePrice
        }
      }
    })
    return price
  }

  const CSRowComponent = props => {


    let price = props.item.licensePrice
    const name = props.item.addOnName

    if (props.item.licenseFeatureId === 19) {
      price = props.item.licensePrice * stepperValue || 0
    }

    return <>

      <div className={`cost-row-component`}>
        <div>
          <div className={`comma-seperator`}>
            <small>{name}</small>
          </div>
        </div>
        <div className='price-calc'>
          {
            props.item.licenseFeatureId === 19 ? <div className='rs-add-remove-btn'>
              <div className={`rs-ar-btn ar-remove ${stepperValue === 1 && 'click-off'}`}>
                <Icon
                  icon={icons.circle_minus_fill_edge_medium}
                  size='md'
                  color='color-medium-red'
                  onClick={() => {
                    if (stepperValue > 1) {
                      setStepperValue(stepperValue - 1)
                    }
                  }}
                ></Icon>
              </div>
              <div className='rs-ar-sel'>{numberWithCommas(stepperValue)}</div>
              <div className={`rs-ar-btn ar-add ${stepperValue === 5 && 'click-off'}`}>
                <Icon
                  icon={icons.circle_plus_fill_edge_medium}
                  size='md'
                  color='color-dark-green'
                  onClick={() => {

                    if (stepperValue < 5) {
                      setAddOnUpgrate(true)
                      setStepperValue(stepperValue + 1)
                    }
                  }}
                ></Icon>
              </div>
            </div>
              : null
          }

          <div className='price-amount'>${numberWithCommas(price)}</div>
        </div>
      </div>
    </>
  }

  const calculateTotalAmount = () => {
    let scost = featureTitleObj[selectPackage]?.cost
    if (currentPackageId === selectPackage) {
      // scost = 0
      let totalAmount = ((scost + (getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers))) * selectedPeriod?.value - discountAmount || 0)
      return totalAmount
    }
    else {
      let totalAmount = ((scost + (getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers))) * selectedPeriod?.value - discountAmount || 0)
      if (totalAmount > walletAmount) {
        return (totalAmount - walletAmount).toFixed(2)
      } else {
        //  setWalletBalence(walletAmount - totalAmount)
        return totalAmount
        return 0
      }
    }

  }
  const calculateWalletBalence = () => {
    let subCost = featureTitleObj[selectPackage]?.cost
    // if (currentPackageId === selectPackage) {
    //   subCost = 0
    // }
    let totalAmount = ((subCost + (getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers) - discountAmount)) * selectedPeriod?.value || 0)
    if (totalAmount > walletAmount) {
      return 0
    } else {
      return walletAmount - totalAmount
    }
  }

  const checkPlanSelectorNot = (featureId) => {
    if (selectPackage === currentPlan?.licenseTypeId - 1) {
      let arr = currentPlan?.addOns?.filter(obj => obj.licensefeatureId === featureId)
      if (arr?.length > 0) {
        return 'click-off'
      } else {
        return 'click-on'
      }
    }
    return 'click-on'
  }

  const sdxSubTotal = () => {
    let subCost = featureTitleObj[selectPackage]?.cost
    // if (currentPackageId === selectPackage) {
    //   subCost = 0
    // }
    return subCost + getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers)
  }

  const sdxTotalAmount = () => {
    let subCost = featureTitleObj[selectPackage]?.cost
    if (currentPackageId === selectPackage) {
      subCost = 0
    }
    return ((subCost + (getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers))) * selectedPeriod?.value - discountAmount || 0)
  }

  const getFrequencyData = () => {
    let index = 0
    if (currentPlan?.frequencyId) {
      index = currentPlan?.frequencyId - 1
    }
    return overallJson?.data?.frequency?.slice(index) || []
  }

  // console.log("selectPackage::", selectPackage);

  return <>
    {
      overallJson !== null &&
      <Container fluid className='features-container new-features'>
        <FeaturesWrapper className={`features-subs-wrapper`}>
          {isError && errMessage !== null ?
            <RSErrorPopup
              headerTitle={"Sorry"}
              para={errMessage}
              primaryButtonText='OK'
              buttonClicked={value => {
                setIsError(false)
                setErrMsg(null)
              }}

            />
            : null}
          <Row>

            <Col md={8} className='features-content-wrapper'>

              {/* Pricing */}
              {/* <h1 className='mb23 text-center'>Current plan</h1> */}
              <Row className={`advanced-pricing-button start-panel freeze-panel pricing-catelogue ${scrollTabOpacity ? 'freeze-panel-init-ar' : 'freeze-panel-rest-ar'} ${scrollTab ? 'freeze-panel-init' : 'freeze-panel-rest'}`}>
                <Col md={3} className='rsp-btn-select plain-bg'>
                  <div className='rsp-btn-label text-start'>
                    <h3 className=''>Subscription plan</h3>
                  </div>
                </Col>
                <Col className={`rsp-btn-select cp ${selectPackage === 0 ? 'price-selected' : 'price-disable'} ${plan1Status}`} onClick={(index) => {
                  if (0 === currentPackageId) {
                    setCurrentPlanData(currentPlan)
                    //setFrequencyData(getFrequenceData(selectedPeriod.frequencyId))
                  } else {
                    setFrequencyData(overallJson?.data?.frequency || [])
                    setStepperValue(1)
                  }
                  setSelectPackage(0)
                  setPlanUpgrade(true)
                  setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
                  setSelectedPeriod(overallJson?.data?.frequency[0])
                  if (promoCode !== "") {
                    getOfferCost(1, overallJson?.data?.frequency[0].frequencyId);
                  } else {
                    setDiscountAmount(0)
                    setPromoCodeErr(null)
                    setPromoCode('')
                    setPromoSuccess(false)
                    setPromoCodeApplied(null)
                  }
                }}>
                  <div className='rsp-btn-label'>
                    <h4 className='mb5'>Trial (30) days</h4>
                    <h3 className='font-medium fs26'>$0</h3>
                    <div className='selected-price-list'>{selectPackage === 0
                      ? <Icon icon={icons.circle_tick_medium} color={`white`} size={'md'} />
                      : null}
                    </div>
                    {/* <div className='selected-price-list'>{selectPackage == 0 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div> */}
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(0)
          setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
        }}>{selectPackage == 0 ? 'Selected' : 'Select plan'}</span> */}
                  </div>

                </Col>
                <Col className={`rsp-btn-select cp ${selectPackage === 1 ? 'price-selected' : 'price-disable'} ${plan2Status}`} onClick={(index) => {
                  if (1 === currentPackageId) {
                    setCurrentPlanData(currentPlan)
                    setSelectedPeriod(overallJson?.data?.frequency[currentPlan?.frequencyId - 1 || 0])
                    let temp = [...selectAddOnsCheckbox]
                    temp.map(item => {
                      item.checked = false;
                      item.addonId = 0
                    })
                    setSelectAddOnsCheckbox(temp)
                    setStepperValue(1)
                    // setFrequencyData(getFrequenceData(selectedPeriod.frequencyId))
                  } else {
                    setFrequencyData(overallJson?.data?.frequency || [])
                    setSelectedPeriod(overallJson?.data?.frequency[0])
                    let temp = [...selectAddOnsCheckbox]
                    temp.map(item => {
                      item.checked = false;
                      item.addonId = 0
                    })
                    setSelectAddOnsCheckbox(temp)
                    setStepperValue(1)
                  }
                  setSelectPackage(1)
                  setPlanUpgrade(true)
                  setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })

                  if (promoCode !== "") {
                    getOfferCost(2, overallJson?.data?.frequency[0].frequencyId);
                  } else {
                    setDiscountAmount(0)
                    setPromoCodeErr(null)
                    setPromoCode('')
                    setPromoSuccess(false)
                    setPromoCodeApplied(null)
                  }

                }}>
                  <div className='rsp-btn-label'>
                    <h4 className='mb5'>Essentials</h4>
                    {/* <span className='buy-package'>Starts at</span> */}
                    <h3 className='font-medium fs26'>$100</h3>
                    <div className='selected-price-list'>{selectPackage === 1
                      ? <Icon icon={icons.circle_tick_medium} color={`white`} size={'md'} />
                      : null}
                    </div>
                    {/* <div className='selected-price-list'>{selectPackage == 1 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div> */}
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(1)
          setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
        }}>{selectPackage == 1 ? 'Selected' : 'Select plan'}</span> */}
                  </div>
                </Col>
                <Col className={`rsp-btn-select cp ${selectPackage === 2 ? 'price-selected' : 'price-disable'} ${plan3Status}`} onClick={(index) => {
                  if (2 === currentPackageId) {
                    //setFrequencyData(getFrequenceData(selectedPeriod.frequencyId))
                    setCurrentPlanData(currentPlan)
                    setSelectedPeriod(overallJson?.data?.frequency[currentPlan?.frequencyId - 1 || 0])

                    if (selectPackage === currentPlan?.licenseTypeId) {
                      let temp = [...selectAddOnsCheckbox]
                      temp.map(_item => {
                        let arr = currentPlan?.addOns?.filter(obj => obj.licensefeatureId === _item.licenseFeatureId)
                        if (arr.length > 0) {
                          _item.addonId = arr[0].addonId
                        } else {
                          _item.addonId = arr[0].addonId
                        }
                      })
                      setSelectAddOnsCheckbox(temp)
                    }

                  } else {
                    setFrequencyData(overallJson?.data?.frequency || [])
                    setSelectedPeriod(overallJson?.data?.frequency[0])
                    let temp = [...selectAddOnsCheckbox]
                    temp.map(item => {
                      item.checked = false;
                      item.addonId = 0
                    })
                    setSelectAddOnsCheckbox(temp)
                    setStepperValue(1)
                  }
                  setSelectPackage(2)
                  setPlanUpgrade(true)
                  setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })

                  if (promoCode !== "") {
                    getOfferCost(3, overallJson?.data?.frequency[0].frequencyId);
                  } else {
                    setDiscountAmount(0)
                    setPromoCodeErr(null)
                    setPromoCode('')
                    setPromoSuccess(false)
                    setPromoCodeApplied(null)
                  }

                }}>
                  <div className='rsp-btn-label'>
                    <h4 className='mb5'>Premier</h4>
                    {/* <span className='buy-package'>Starts at</span> */}
                    <h3 className='font-medium fs26'>$300</h3>
                    <div className='selected-price-list'>{selectPackage === 2
                      ? <Icon icon={icons.circle_tick_medium} color={`white`} size={'md'} />
                      : null}
                    </div>
                    {/* <div className='selected-price-list'>{selectPackage == 2 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div> */}
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(2)
          setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
        }}>{selectPackage == 2 ? 'Selected' : 'Select plan'}</span> */}
                  </div>
                </Col>
                <Col className={`rsp-btn-select cp ${selectPackage === 3 ? 'price-selected' : 'price-disable'} ${plan4Status}`} onClick={(index) => {
                  if (3 === currentPackageId) {
                    //setFrequencyData(getFrequenceData(selectedPeriod.frequencyId))
                    setCurrentPlanData(currentPlan)
                    setSelectedPeriod(overallJson?.data?.frequency[currentPlan?.frequencyId - 1 || 0])

                    if (selectPackage === currentPlan?.licenseTypeId) {
                      let temp = [...selectAddOnsCheckbox]
                      temp.map(_item => {
                        let arr = currentPlan?.addOns?.filter(obj => obj.licensefeatureId === _item.licenseFeatureId)
                        if (arr.length > 0) {
                          _item.addonId = arr[0].addonId
                        } else {
                          _item.addonId = arr[0].addonId
                        }
                      })
                      setSelectAddOnsCheckbox(temp)
                    }
                  } else {
                    setFrequencyData(overallJson?.data?.frequency || [])
                    setSelectedPeriod(overallJson?.data?.frequency[0])
                    let temp = [...selectAddOnsCheckbox]
                    temp.map(item => {
                      item.checked = false;
                      item.addonId = 0
                    })
                    setSelectAddOnsCheckbox(temp)
                    setStepperValue(1)
                  }
                  setSelectPackage(3)
                  setPlanUpgrade(true)
                  setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })

                  if (promoCode !== "") {
                    getOfferCost(4, overallJson?.data?.frequency[0].frequencyId);
                  } else {
                    setDiscountAmount(0)
                    setPromoCodeErr(null)
                    setPromoCode('')
                    setPromoSuccess(false)
                    setPromoCodeApplied(null)
                  }

                }}>
                  <div className='rsp-btn-label'>
                    <h4 className='mb5'>Ultimate</h4>
                    {/* <span className='buy-package'>Starts at</span> */}
                    <h3 className='font-medium fs26'>$500</h3>
                    <div className='selected-price-list'>{selectPackage === 3
                      ? <Icon icon={icons.circle_tick_medium} color={`white`} size={'md'} />
                      : null}
                    </div>
                    {/* <div className='selected-price-list d-none'>{selectPackage == 3 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div> */}
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(3)
          setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
        }}>{selectPackage == 3 ? 'Selected' : 'Select plan'}</span> */}
                  </div>
                </Col>
              </Row>
              <Row className='advanced-pricing advanced-price-middle advanced-price-features'>
                <Col md={3} className='pricing-column pricing-text first-pricing'>
                  {
                    overallJson?.data?.free?.commonFeatures?.map((item, index) => item.licenseFeatureName).map((item, index) => {
                      return <div className='price-label'><p>{item}</p></div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 0 ? 'price-selected' : 'price-disable'} ${plan1Status}`}>

                  {
                    overallJson?.data?.free?.commonFeatures?.map((item, index) => {
                      return <div className='price-label'>
                        {/* {
              item.licenseFeature === 'Yes'
                ? <Icon icon={icons.circle_tick_medium} color='green' size={'md'} />
                : item.licenseFeature === 'No'
                  ? <Icon icon={icons.circle_close_medium} color='red' size={'md'} />
                  : item.licenseValue 
                    ? item.licenseValue
                    : null
            } */}

                        {
                          item.licenseValue
                            ? numberWithCommas(item.licenseValue)
                            : item.licenseFeature === 'Yes'
                              ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                              : item.licenseFeature === 'No'
                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                : item.licenseFeature
                        }

                        {/* <div className='pl10'>
              <p>{item.licenseValue}</p>
              {
                item.licenseFeature === 'Yes'
                  ? null
                  : item.licenseFeature === 'No'
                    ? null
                    : <p>{item.licenseFeature}</p>
              }
            </div> */}
                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 1 ? 'price-selected' : 'price-disable'} ${plan2Status}`}>
                  {
                    overallJson?.data?.essentials?.commonFeatures?.map((item, index) => {
                      return <div className='price-label'>
                        {
                          item.licenseValue
                            ? numberWithCommas(item.licenseValue)
                            : item.licenseFeature === 'Yes'
                              ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                              : item.licenseFeature === 'No'
                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                : item.licenseFeature
                        }
                      </div>
                    })
                  }

                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 2 ? 'price-selected' : 'price-disable'} ${plan3Status}`}>
                  {
                    overallJson?.data?.premier?.commonFeatures?.map((item, index) => {
                      return <div className='price-label'>
                        {
                          item.licenseValue
                            ? numberWithCommas(item.licenseValue)
                            : item.licenseFeature === 'Yes'
                              ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                              : item.licenseFeature === 'No'
                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                : item.licenseFeature
                        }
                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 3 ? 'price-selected' : 'price-disable'} ${plan4Status}`}>
                  {
                    overallJson?.data?.ultimate?.commonFeatures?.map((item, index) => {
                      return <div className='price-label'>
                        {
                          item.licenseValue
                            ? numberWithCommas(item.licenseValue)
                            : item.licenseFeature === 'Yes'
                              ? <Icon mainClass='bg-dark-green' nocp={true} icon={icons.tick_medium} color='white' size={'md'} />
                              : item.licenseFeature === 'No'
                                ? <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                                : item.licenseFeature
                        }
                      </div>
                    })
                  }
                </Col>
              </Row>

              {/* Add-ons */}
              {/* <Row>
    <Col md={12} className='rsp-btn-heading'>
      <h3 className='color-th-primary mb0'>Add-ons</h3>
    </Col>
  </Row> */}
              <Row className='advanced-pricing-button ad-head-mid my15'>
                <Col md={12} className='rsp-btn-select'><h3 className='color-th-primary my23'>Add-ons <small>({featureTitleObj[selectPackage]?.title})</small></h3></Col>
                {/* <Col className={`rsp-btn-select ${selectPackage === 0 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(0)
      }}>
        <h3 className='mb5'>Free</h3>
      </div>

    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 1 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(1)
      }}>
        <h3 className='mb5'>Essentials</h3>
      </div>
    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 2 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(2)
      }}>
        <h3 className='mb5'>Premier</h3>
      </div>
    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 3 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(3)
      }}>
        <h3 className='mb5'>Ultimate</h3>
      </div>
    </Col> */}
              </Row>
              <Row className='advanced-pricing advanced-price-middle'>
                <Col md={3} className='pricing-column pricing-text first-pricing'>
                  {
                    overallJson?.data?.essentials?.addOns?.map((item, index) => {
                      return <div className='price-label'><p>{item.addOnName}</p></div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 0 ? 'price-selected' : 'price-disable'} ${plan1Status}`}>
                  {
                    selectAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label'>
                        {
                          overallJson?.data?.free?.addOns[index].isActive
                            ? <RSCheckbox
                              className="marginB0"
                              // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                              lbl={`$${item.licensePrice} `}
                              checked={selectAddOnsCheckbox[index]?.checked || false}
                              cb={val => {

                                // console.log("_newArray: green day: ", some);
                                let _newArray = []
                                selectAddOnsCheckbox?.map((_item, _index) => {

                                  let some = { ...item }
                                  if (some?.checked === undefined) {
                                    if (item.licenseFeatureId === 19) {
                                      setStepperValue(1)
                                    }
                                    some.checked = true
                                  } else {
                                    if (item.licenseFeatureId === 19) {
                                      setStepperValue(1)
                                    }
                                    some.checked = !some?.checked
                                  }

                                  if (index === _index) {
                                    _newArray.push(some)
                                  } else {
                                    _newArray.push(_item)
                                  }
                                })
                                setSelectAddOnsCheckbox(_newArray)
                                setAddOnUpgrate(true)
                              }}
                            /> : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 1 ? 'price-selected' : 'price-disable'} ${plan2Status}`}>
                  {
                    selectAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label'>
                        {
                          overallJson?.data?.essentials?.addOns[index].isActive
                            ? <>
                              {
                                selectPackage === 1
                                  ? <RSCheckbox
                                    className="marginB0"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    lbl={`$${item.licensePrice} `}
                                    checked={selectAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {

                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectAddOnsCheckbox(_newArray)
                                      setAddOnUpgrate(true)
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0"
                                    lbl={`$${item.licensePrice} `}
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 2 ? 'price-selected' : 'price-disable'} ${plan3Status}`}>
                  {
                    selectAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label'>
                        {
                          overallJson?.data?.premier?.addOns[index].isActive
                            ? <>
                              {
                                selectPackage === 2
                                  ? <RSCheckbox
                                    className="marginB0"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    lbl={`$${item.licensePrice} `}
                                    checked={selectAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {

                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectAddOnsCheckbox(_newArray)
                                      setAddOnUpgrate(true)
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0"
                                    lbl={`$${item.licensePrice} `}
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 3 ? 'price-selected' : 'price-disable'} ${plan4Status}`}>
                  {
                    selectAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label'>
                        {
                          overallJson?.data?.ultimate?.addOns[index].isActive
                            ? <>
                              {
                                selectPackage === 3
                                  ? <RSCheckbox
                                    className="marginB0"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    lbl={`$${item.licensePrice} `}
                                    checked={selectAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {
                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectAddOnsCheckbox(_newArray)
                                      setAddOnUpgrate(true)
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0"
                                    lbl={`$${item.licensePrice} `}
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>
              </Row>
              <Row className='advanced-pricing advanced-price-middle'>
                <Col md={3} className={`pricing-column pricing-text first-pricing`}>
                  {overallJson?.data?.free?.freeAddOns?.map((item, index) => {
                    return <div className='price-label'><p>{item.addOnName}</p></div>
                  })}
                </Col>

                <Col className={`pricing-column pricing-field ${selectPackage === 0 ? 'price-selected' : 'price-disable'}`}>
                  {
                    selectFreeAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label position-relative'>
                        {
                          overallJson?.data?.free?.freeAddOns[index].isActive
                            ? <RSCheckbox
                              className="marginB0 top-10"
                              // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                              // lbl={`$${item.licensePrice} ${item.licenseFeatureId === 1 ? '/ User' : ''}`}
                              checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                              cb={val => {

                                // console.log("_newArray: green day: ", some);
                                let _newArray = []
                                selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                  let some = { ...item }
                                  if (some?.checked === undefined) {
                                    if (item.licenseFeatureId === 19) {
                                      setStepperValue(1)
                                    }
                                    some.checked = true
                                  } else {
                                    if (item.licenseFeatureId === 19) {
                                      setStepperValue(1)
                                    }
                                    some.checked = !some?.checked
                                  }

                                  if (index === _index) {
                                    _newArray.push(some)
                                  } else {
                                    _newArray.push(_item)
                                  }
                                })
                                setSelectFreeAddOnsCheckbox(_newArray)
                              }}
                            /> : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>

                <Col className={`pricing-column pricing-field ${selectPackage === 1 ? 'price-selected' : 'price-disable'}`}>
                  {
                    selectFreeAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label position-relative'>
                        {
                          overallJson?.data?.essentials?.freeAddOns[index].isActive
                            ? <>
                              {
                                selectPackage === 1
                                  ? <RSCheckbox
                                    className="marginB0 top-10"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    // lbl={`$${item.licensePrice} ${item.licenseFeatureId === 1 ? '/ User' : ''}`}
                                    checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {

                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectFreeAddOnsCheckbox(_newArray)
                                      setIsOpenDataProviderModal(val)
                                      setSelectedDataProviders([])
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0 top-10"
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>

                <Col className={`pricing-column pricing-field ${selectPackage === 2 ? 'price-selected' : 'price-disable'}`}>
                  {
                    selectFreeAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label position-relative'>
                        {
                          overallJson?.data?.premier?.freeAddOns[index].isActive
                            ? <>
                              {
                                selectPackage === 2
                                  ? <RSCheckbox
                                    className="marginB0 top-10"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    // lbl={`$${item.licensePrice} ${item.licenseFeatureId === 1 ? '/ User' : ''}`}
                                    checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {

                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectFreeAddOnsCheckbox(_newArray)
                                      setIsOpenDataProviderModal(val)
                                      setSelectedDataProviders([])
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0 top-10"
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>

                <Col className={`pricing-column pricing-field ${selectPackage === 3 ? 'price-selected' : 'price-disable'}`}>
                  {
                    selectFreeAddOnsCheckbox?.map((item, index) => {

                      return <div className='price-label position-relative'>
                        {
                          overallJson?.data?.ultimate?.freeAddOns[index].isActive
                            ? <>
                              {
                                selectPackage === 3
                                  ? <RSCheckbox
                                    className="marginB0 top-10"
                                    // disabled={(selectPackage === 0 || selectPackage === 1) ? true : false}
                                    // lbl={`$${item.licensePrice} ${item.licenseFeatureId === 1 ? '/ User' : ''}`}
                                    checked={selectFreeAddOnsCheckbox[index]?.checked || false}
                                    cb={val => {

                                      // console.log("_newArray: green day: ", some);
                                      let _newArray = []
                                      selectFreeAddOnsCheckbox?.map((_item, _index) => {

                                        let some = { ...item }
                                        if (some?.checked === undefined) {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = true
                                        } else {
                                          if (item.licenseFeatureId === 19) {
                                            setStepperValue(1)
                                          }
                                          some.checked = !some?.checked
                                        }

                                        if (index === _index) {
                                          _newArray.push(some)
                                        } else {
                                          _newArray.push(_item)
                                        }
                                      })
                                      setSelectFreeAddOnsCheckbox(_newArray)
                                      setIsOpenDataProviderModal(val)
                                      setSelectedDataProviders([])
                                    }}
                                  />
                                  : <RSCheckbox
                                    className="marginB0 top-10"
                                    checked={false}
                                  />
                              }
                            </>
                            : <Icon mainClass='bg-medium-red' nocp={true} icon={icons.close_mini} color='white' size={'md'} />
                        }

                      </div>
                    })
                  }
                </Col>
              </Row>
              {/* Package includes - Fixed volume per month */}
              {/* <Row>
    <Col md={12} className='rsp-btn-heading'>
      <h3 className='color-th-primary mb0'>Package includes - Fixed volume per month</h3>
    </Col>
  </Row> */}

              <Row className='advanced-pricing-button ad-head-mid my15'>
                <Col md={12} className='rsp-btn-select'><h3 className='color-th-primary my23 text-left'>Package includes <small>({featureTitleObj[selectPackage]?.title})</small></h3></Col>
                {/* <Col md={12} className='rsp-btn-select'><h3 className='color-th-primary my23 text-left'>Package includes - Fixed volume per month</h3></Col> */}
                {/* <Col className={`rsp-btn-select ${selectPackage === 0 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(0)
      }}>
        <h3 className='mb5'>Free</h3>
      </div>
    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 1 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(1)
      }}>
        <h3 className='mb5'>Essentials</h3>
      </div>
    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 2 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(2)
      }}>
        <h3 className='mb5'>Premier</h3>
      </div>
    </Col>
    <Col className={`rsp-btn-select ${selectPackage === 3 ? 'price-selected' : 'price-disable'}`}>
      <div className='rsp-btn-label' onClick={(index) => {
        setSelectPackage(3)
      }}>
        <h3 className='mb5'>Ultimate</h3>
      </div>
    </Col> */}
              </Row>
              <Row className='advanced-pricing advanced-price-middle'>
                <Col md={3} className='pricing-column pricing-text first-pricing'>
                  {
                    overallJson?.data?.free?.packages?.map((item, index) => {
                      return <div className='price-label'><p>{item.packageName}</p></div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 0 ? 'price-selected' : 'price-disable'} ${plan1Status}`}>
                  {
                    overallJson?.data?.free?.packages?.map((item, index) => {
                      return <div className='price-label d-flex flex-column align-items-center justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                          {/* <p className='text-center'>{item.packageName}</p> */}
                          <div className='fs18 font-medium text-center'>{numberWithCommas(item.packageVolume)}</div>
                          {/* <div className='fs18 font-medium text-center'>{item.packageId === 5 && "Max"} {item.packageVolume}</div> */}
                          {/* {item.packageId === 5 && <BootstrapDropdown
                data={freeTrafficLimitArry}
                defaultItem={selectedTrafficLimitData.Free}
                className='fs16-btn'
                onSelect={val => { setSelectedTrafficLimitData({ ...selectedTrafficLimitData, ...{ Free: val } }) }}
              />} */}
                        </div>
                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 1 ? 'price-selected' : 'price-disable'} ${plan2Status}`}>
                  {
                    overallJson?.data?.essentials?.packages?.map((item, index) => {
                      return <div className='price-label d-flex flex-column align-items-center justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                          {/* <p className='text-center'>{item.packageName}</p> */}
                          <div className='fs18 font-medium text-center'>{numberWithCommas(item.packageVolume)}</div>
                          {/* <div className='fs18 font-medium text-center'>{item.packageId === 5 && "Max"} {item.packageVolume}</div> */}
                          {/* {item.packageId === 5 && <BootstrapDropdown
                data={essentialsTrafficLimitArry}
                defaultItem={selectedTrafficLimitData.Essentials}
                className='fs16-btn'
                onSelect={val => { setSelectedTrafficLimitData({ ...selectedTrafficLimitData, ...{ Essentials: val } }) }}
              />} */}
                        </div>
                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 2 ? 'price-selected' : 'price-disable'} ${plan3Status}`}>
                  {
                    overallJson?.data?.premier?.packages?.map((item, index) => {
                      return <div className='price-label d-flex flex-column align-items-center justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                          {/* <p className='text-center'>{item.packageName}</p> */}
                          <div className='fs18 font-medium text-center'>{numberWithCommas(item.packageVolume)}</div>
                          {/* <div className='fs18 font-medium text-center'>{item.packageId === 5 && "Max"} {item.packageVolume}</div> */}
                          {/* {item.packageId === 5 && <BootstrapDropdown
                data={premierTrafficLimitArry}
                defaultItem={selectedTrafficLimitData.Premier}
                className='fs16-btn'
                onSelect={val => { setSelectedTrafficLimitData({ ...selectedTrafficLimitData, ...{ Premier: val } }) }}
              />} */}
                        </div>
                      </div>
                    })
                  }
                </Col>
                <Col className={`pricing-column pricing-field ${selectPackage === 3 ? 'price-selected' : 'price-disable'} ${plan4Status}`}>
                  {
                    overallJson?.data?.ultimate?.packages?.map((item, index) => {
                      return <div className='price-label d-flex flex-column align-items-center justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                          {/* <p className='text-center'>{item.packageName}</p> */}
                          <div className='fs18 font-medium text-center'>{numberWithCommas(item.packageVolume)}</div>
                          {/* <div className='fs18 font-medium text-center'>{item.packageId === 5 && "Max"} {item.packageVolume}</div> */}
                          {/* {item.packageId === 5 && <BootstrapDropdown
                data={ultimateTrafficLimitArry}
                defaultItem={selectedTrafficLimitData.Ultimate}
                className='fs16-btn'
                onSelect={val => { setSelectedTrafficLimitData({ ...selectedTrafficLimitData, ...{ Ultimate: val } }) }}
              />} */}
                        </div>
                      </div>
                    })
                  }
                </Col>
              </Row>

              {/* footer */}
              <Row className='advanced-pricing-button ad-price-footer'>
                <Col md={3}></Col>
                <Col className={`rsp-btn-select ${selectPackage === 0 ? 'price-selected' : 'price-disable'}`}>
                  <div className='rsp-btn-label'>
                    {/* <p className='fs18'>Free</p>
        <h3 className='font-medium fs26'>$0</h3> */}
                    <div className='selected-price-list'>{selectPackage == 0 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div>
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(0)
          setSelectedAAdditionalUsers({ Free: "Select", Essentials: "Select", Premier: 1, Ultimate: 1 })
        }}>{selectPackage == 0 ? 'Selected' : 'Select plan'}</span> */}
                    {/* <span>{selectPackage === 0 ? '' : 'Choose plan'}</span> */}
                  </div>
                </Col>
                <Col className={`rsp-btn-select ${selectPackage === 1 ? 'price-selected' : 'price-disable'}`}>
                  <div className='rsp-btn-label'>
                    {/* <p className='fs18'>Essentials</p>
        <h3 className='font-medium fs26'>$100</h3> */}
                    <div className='selected-price-list'>{selectPackage == 1 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div>
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(1)
        }}>{selectPackage == 1 ? 'Selected' : 'Select plan'}</span> */}
                    {/* <span>{selectPackage === 1 ? '' : 'Choose plan'}</span> */}
                  </div>
                </Col>
                <Col className={`rsp-btn-select ${selectPackage === 2 ? 'price-selected' : 'price-disable'}`}>
                  <div className='rsp-btn-label'>
                    {/* <p className='fs18'>Premier</p>
        <h3 className='font-medium fs26'>$300</h3> */}
                    <div className='selected-price-list'>{selectPackage == 2 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div>
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(2)
        }}>{selectPackage == 2 ? 'Selected' : 'Select plan'}</span> */}
                    {/* <span>{selectPackage === 2 ? '' : 'Choose plan'}</span> */}
                  </div>
                </Col>
                <Col className={`rsp-btn-select ${selectPackage === 3 ? 'price-selected' : 'price-disable'}`}>
                  <div className='rsp-btn-label'>
                    {/* <p className='fs18'>Ultimate</p>
        <h3 className='font-medium fs26'>$500</h3> */}
                    <div className='selected-price-list'>{selectPackage == 3 ? <Icon icon={icons.tick_medium} nocp={true} color={`white`} size={'md'} /> : null}</div>
                    {/* <span className="choose-plan" onClick={(index) => {
          setSelectPackage(3)
        }}>{selectPackage == 3 ? 'Selected' : 'Select plan'}</span> */}
                    {/* <span>{selectPackage === 3 ? '' : 'Choose plan'}</span> */}
                  </div>
                </Col>
              </Row>

            </Col>

            <div className={`features-cost-wrapper fixed-top rs-scroll ${scroll ? 'rs-scroll-init' : 'rs-scroll-rest'} ${scrollEnd ? 'rs-scroll-init-end' : 'rs-scroll-rest-end'}`}>
              <div className='summary-cost-header'>
                <div className='d-flex justify-content-between align-items-end'>
                  <h3 className='paddingB10'>Cost summary</h3>
                  <h4>Amount (USD)</h4>
                </div>
                <hr className='dark' />
              </div>
              <div className='summary-cost-body-wrapper'>
                <div className="summary-cost-body">
                  <div className="summary-cost-list">
                    <div className='cost-row-component'>
                      <div>
                        <div>Subscription cost</div>
                      </div>
                      <div>${featureTitleObj[selectPackage]?.cost}</div>
                    </div>
                    {
                      selectAddOnsCheckbox?.map(item => {


                        return item.checked
                          ?
                          <>
                            <CSRowComponent
                              title="Audience list volume"
                              item={item}
                            />
                          </>
                          : null
                      })
                    }
                  </div>
                  <div className="summary-cost-footer">{/* SubTotal */}
                    <div className='cost-row-component mt23'>
                      <div>
                        <div>Sub total</div>
                      </div>
                      <div className=''>${sdxSubTotal()}</div>
                    </div>

                    {/* PromoCode */}
                    {selectPackage === 0 ? null : <div className='cost-row-component'>
                      <div className='wsn promo-code-text'>Coupon code</div>
                      <div className="position-relative">
                        <div className={`d-flex align-items-end justify-content-end promo position-relative`}>
                          {promoCodeErr && <div className={`validation-message ${promoSuccess ? 'success_msg' : ''}`}>{promoCodeErr}</div>}
                          <div className='coupon-input'>
                            <RSTextInputWithIcon
                              ph='Enter coupon code'
                              disabled={promoCodeApplied}
                              val={promoCode}
                              icon={`${promoCodeApplied === null ? '' : promoCodeApplied ? 'icon-sd-circle-tick-medium green mr10' : 'icon-sd-circle-close-fill-medium red'} icons-md mr10`}
                              max={15}
                              onClickIcon={() => {
                                setPromoCode("")
                                setPromoCodeErr(null)
                                setPromoCodeApplied(null)
                                setDiscountAmount(0)
                                setPromoSuccess(false)
                              }}
                              cb={value => {
                                setPromoCode(value)
                                setPromoCodeApplied(null)

                                // if (value.length) {
                                setPromoCodeErr(null)
                                // } else {
                                //   setPromoCodeErr("Enter promo code")
                                // }
                              }}
                            />
                          </div>
                          <div className="position-relative promo-code-btn">
                            <span
                              className="blue cp"
                              onClick={() => {
                                if (promoCodeApplied) {
                                  setDiscountAmount(0)
                                  setPromoCodeErr(null)
                                  setPromoCode('')
                                  setPromoSuccess(false)
                                  setPromoCodeApplied(null)
                                }
                                else {
                                  if (promoCode.length) {
                                    getOfferCost(overallJson?.data?.licenseType[selectPackage]?.licensingTypeID, selectedPeriod.frequencyId)
                                  } else {
                                    setPromoSuccess(false)
                                    setPromoCodeApplied(null)
                                  }
                                }
                              }}
                            >{promoCodeApplied === null ? "Apply" : promoCodeApplied ? 'Remove' : 'Apply'}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                    }
                    {selectPackage === 0 || discountAmount === 0 ? null :
                      <div className='cost-row-component mt23'>
                        <div>
                          <div>Discount amount</div>
                        </div>
                        <div className={`${discountAmount ? 'color-submit' : ''}`}>- ${numberWithCommas(discountAmount)}</div>
                      </div>
                    }
                    <hr />
                    {selectPackage === 0 ? null :
                      <div className='cost-row-component mt23'>
                        <div>
                          <div>Total amount {`(${selectedPeriod?.frequency || ''})`}</div>
                        </div>
                        <div className={`${discountAmount ? 'color-submit font-bold' : ''}`}> {`$${numberWithCommas(sdxTotalAmount())}`}</div>
                      </div>
                    }
                    {selectPackage === 0 || walletAmount === 0 ? null :
                      <div className='cost-row-component mt23'>
                        <div>
                          <div>Available amount</div>
                        </div>
                        <div className={`${discountAmount ? 'color-submit font-bold' : ''}`}> {`$${numberWithCommas(walletAmount)}`}</div>
                      </div>
                    }
                    {selectPackage === 0 || calculateWalletBalence() === 0 ? null :
                      <div className='cost-row-component mt23'>
                        <div>
                          <div>Remaining balance</div>
                        </div>
                        <div className={`${discountAmount ? 'color-submit font-bold' : ''}`}> {`${calculateWalletBalence() <= 0 ? 0 : numberWithCommas(calculateWalletBalence())}`}</div>
                      </div>
                    }
                  </div>
                </div>
                {/* FOOTER FIXED */}
                <div className="cost-footer-fixed">
                  <div className="cost-row-component my15">
                    <div>
                      <h4 className='font-bold fs18'>Amount (USD)</h4>
                      <div className={`${planUpgrade === true ? 'click-on' : 'click-off'}`}>
                        <BootstrapDropdown
                          // data={overallJson?.data?.Frequency || []}
                          data={selectPackage === 0 ? [overallJson?.data?.frequency[0]] : getFrequencyData() || []}
                          //  data={selectPackage === 0 ? [frequencyData[0]]: frequencyData}
                          fieldKey="frequency"
                          defaultItem={selectedPeriod?.frequency || ''}
                          // customAlignRight={true}
                          // alignRight
                          className='fs16-btn dropdown-btn-primary'
                          onSelect={(item, index) => {
                            if (promoCode !== "") {
                              getOfferCost(overallJson?.data?.licenseType[selectPackage]?.licensingTypeID, item.frequencyId)
                            }
                            let totalAmount = ((currentPackageId === selectPackage ? 0 : featureTitleObj[selectPackage]?.cost + (getSubTotal(selectAddOnsCheckbox, selectedAdditionalUsers))) * item.value - discountAmount)
                            let walletBalence = walletAmount - totalAmount
                            setWalletBalence(walletBalence)
                            setSelectedPeriod(item)
                          }}
                        />
                      </div>
                    </div>
                    <div className='text-end'>
                      <div className='font-bold fs18'>
                        ${numberWithCommas(calculateTotalAmount())}
                      </div>
                      <small>Payable amount</small>
                    </div>
                  </div>
                  <div className='btn-container d-flex justify-content-end mt0 mb23'>
                    <RSCancelBtn
                      onClick={() => {
                        localStorage.setItem('sclog', false)
                        // context.globalStateData.setBodyNameData('')
                        history.push("/preferences")
                        if (getUserData()) {
                          context.toggleAuth(true)
                          history.push("/preferences")
                        }
                      }}
                    >
                      Cancel
                    </RSCancelBtn>
                    <RSPrimaryBtn
                      className={(planUpgrade || addOnUpgrade) ? 'click-on' : 'click-off'}
                      onClick={() => {
                        postPricingDetails()
                      }}>
                      Proceed</RSPrimaryBtn>
                  </div>
                </div>

                {/* Line */}
                <hr />

                <small className='fs13'>* This cost is only for subscription of this product</small>
              </div>
            </div>

          </Row>
        </FeaturesWrapper>
        {isOpenDataProviderModal &&
          <DataProviderModal
            isOpen={isOpenDataProviderModal}
            dataProviders={dataProviders}
            selectedDataProviders={selectedDataProviders}
            dataProviderContent={""}
            onChangeOpen={(value) => {
              if (!value) {
                let _newArray = []
                selectFreeAddOnsCheckbox?.map((_item, _index) => {
                  let some = { ..._item }
                  some.checked = false
                  _newArray.push(some)
                })
                setSelectFreeAddOnsCheckbox(_newArray)
              }
              setIsOpenDataProviderTermsModal(value)
              setIsOpenDataProviderModal(false)
            }}
            getSelectedDataProviders={(value) => { setSelectedDataProviders(value) }}
          />}
        {isOpenDataProviderTermsModal &&
          <DataProviderTermsModal
            isOpen={isOpenDataProviderTermsModal}
            dataProviders={dataProviders}
            selectedDataProviders={selectedDataProviders}
            onChangeOpen={(value) => {
              if (!value) {
                let _newArray = []
                selectFreeAddOnsCheckbox?.map((_item, _index) => {
                  let some = { ..._item }
                  some.checked = false
                  _newArray.push(some)
                })
                setSelectFreeAddOnsCheckbox(_newArray)
              }
              setIsOpenDataProviderTermsModal(false)
            }}
            getSelectedDataProviders={(value) => {
              setAddOnUpgrate(value.length > 0 ? true : false)
              setSelectedDataProviders(value)
            }}
          />}
        {isOpenResulPay &&
          <ResulPayModal
            isOpen={isOpenResulPay}
            orderId={planInvoiceDetails?.tenantInvoiceId}
            isConsumable={false}
            amount={planInvoiceDetails?.encryptedTotalAmount}
            clientName={planInvoiceDetails?.clientname}
            tenantShortCode={planInvoiceDetails?.tenantShortCode}
            onChangeIsOpen={(value) => {
              setIsOpenResulPay(value)
            }}
          />
        }
      </Container>
    }
  </>


}
export default RSPCatelogue

const featureTitleObj = {
  0: { title: "Free", cost: 0 },
  1: { title: "Essentials", cost: 100 },
  2: { title: "Premier", cost: 300 },
  3: { title: "Ultimate", cost: 500 }
}



const additionalUsersArry = [1, 2, 3, 4, 5]

const getTrafficLimitData = (overAllValue, value) => {

  var data = [];
  var overAllValues = overAllValue;
  data.push("-- Select --");
  data.push(rspInternationalFormat(overAllValue));

  for (var i = 0; i < 4; i++) {
    overAllValues = overAllValues - value
    data.push(rspInternationalFormat(overAllValues));
  }
  return data
}

const freeTrafficLimitArry = getTrafficLimitData(5000, 1000)
const essentialsTrafficLimitArry = getTrafficLimitData(100000, 10000)
const premierTrafficLimitArry = getTrafficLimitData(300000, 50000)
const ultimateTrafficLimitArry = getTrafficLimitData(500000, 100000)


// Test