import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { RSInput2, RSRadio } from "Components/RSInputs";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSPTooltip } from "Components/Tooltip";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { isEmpty } from "Helper/Utils/Utils";
import { IconDropdown } from "Components/BootstrapDropdown/BootstrapDropdown";
import ErrorModal from "./ErrorModal";
import { connectServer } from "Helper/Network/networkHandler";

import {
  GET_DATA_ATTRIBUTES_INPUT_DATATYPES,
  GET_DATA_ATTRIBUTE_UI_PRINTABLE_NAME_EXISTS,
  GET_INPUT_TYPES,
  SAVE_DATA_ATTRIBUTE,
  GET_FILTER_GROUPS,
  GET_DATE_FORMAT,
} from "Helper/Constants/endPoint";


const errorIntilization = {
  attibuteName: null,
  inputType: null,
  dataType: null,
  conditionType: null,
  KpiType: null,
  filterGroup: null,
  classification: null,
  relationType: null,
  relationAttribute: null,
  dateFormatError: null,
};

const errorInitailState = {
  isError: false,
  errorMessage: null,
};
class NewAttributeModal extends Component {
  state = {
    isModalOpen: this.props.isOpen,
    attributeName: "",
    inputType: {
      inputId: 0,
      inputType: "-- Select input --",
    },
    dataType: {
      dataId: 0,
      dataType: "-- Select type --",
    },
    availableAttributes: {
      dataAttributeID: 0,
      uiPrintableName: "-- Select Attribute --",
    },
    parentChild: null,
    filterGroup: {
      fgroupId: 0,
      fgroupType: "-- Assign filteration group --",
    },
    conditionAttribute: {
      conditionattributeId: 0,
      uiPrintableName: "-- Select Attribute --",
    },
    attributesData: this.props.availData,
    classification: [],
    kpiType: null,
    importPreference: "",
    isActive: false,
    // ErrMsgAttributeName: null,
    rule: [
      {
        attribute: {
          conditionattributeId: 0,
          uiPrintableName: "-- Select Attribute --",
        },
        value: {
          inputText: "",
        },
        selectedCondition: {},
        error: {
          isError: false,
          errorMessage: "",
        },
        show: {
          startDate: false,
          endDate: false,
        },
      },
    ],
    attributeNameExist: false,
    inputErrorValidation: errorIntilization,
    inputTypeData: [],
    dataTypeAttributes: [],
    isErrorModalOpen: false,
    filterGroups: [],
    isDataSavedModal: false,
    saveParams: {},
    dateFormatData: [],
    dateFormat: {
      formatId: 0,
      formatName: "-- Select Date & Time Format --",
    },
  };

  componentDidMount() {
    if (!this.state.inputTypeData.length) {
      this.getInputType();
    }
    if (!this.state.dataTypeAttributes.length) {
      this.getDataType();
    }
    if (!this.state.filterGroups.length) {
      this.getFilterGroups();
    }
  }

  handleClose = () => {
    this.props.onChangeIsOpen(false);
    this.setState({ isModalOpen: false });
  };

  handleClassification = (id, e) => {
    e.currentTarget.classList.toggle("blue");
    if (id === 3) {
      this.setState({ isActive: !this.state.isActive });
    }

    this.setState({ classificationState: !this.state.classificationState });

    const { classification } = this.state;
    const newList = [...classification];

    const itemIndex = newList.findIndex((item) => item === id);
    if (itemIndex > -1) {
      newList.splice(itemIndex, 1);
    } else {
      newList.push(id);
    }

    this.setState({
      classification: newList,
      inputErrorValidation: {
        ...this.state.inputErrorValidation,
        classification: null,
      },
    });
  };

  changeHandler = (e) => {
    this.setState({ attributeName: e });
  };

  setKpiType = (e) => {
    const { name, value } = e.target;
    this.setState({
      kpiType: value,
    });
  };

  getInputType = () => {
    const params = {
      departmentId: 0,
    };
    connectServer({
      path: GET_INPUT_TYPES,
      params,
      method: "POST",
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (res) => {
        if (res?.status) {
          this.setState({
            inputTypeData: res?.data.map((item) => {
              return {
                inputId: item?.id,
                inputType: item?.name,
              };
            }),
          });
        }
      },
    });
  };

  getDataType = () => {
    const params = {
      departmentId: 0,
    };
    connectServer({
      path: GET_DATA_ATTRIBUTES_INPUT_DATATYPES,
      params,
      method: "POST",
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (res) => {
        if (res?.status) {
          this.setState({
            dataTypeAttributes: res?.data.map((item) => {
              return {
                dataId: item?.id,
                dataType: item?.name,
                disabled: item.name === "KPI Data" ? true : false,
              };
            }),
          });
        }
      },
    });
  };

  getFilterGroups = () => {
    const params = {
      departmentId: 0,
    };
    connectServer({
      path: GET_FILTER_GROUPS,
      params,
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (res) => {
        if (res.status) {
          this.setState({
            filterGroups: res?.data?.map((item) => {
              return {
                fgroupId: item?.id,
                fgroupType: item?.name,
              };
            }),
          });
        }
      },
      fail: (err) => { },
    });
  };

  handleSaveAttribute = () => {
    const {
      attributeName,
      dataType,
      inputType,
      filterGroup,
      parentChild,
      availableAttributes,
      classification,
      rule,
      kpiType,
      dateFormat,
    } = this.state;
    if (isEmpty(attributeName)) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          attibuteName: "Please Enter valid attribute Name",
        },
      });
      return;
    } else if (this.state.attributeNameExist) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          attibuteName: "Attribute Name already Exists",
        },
      });
      return;
    }
    if (inputType?.inputType.toLowerCase().includes("select")) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          inputType: "Please select a valid inputType Name",
        },
      });
      return;
    }
    if (
      dateFormat.formatName.toLowerCase().includes("select") &&
      inputType.inputType === "Date & time"
    ) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          dateFormatError: "Please select Source Date & Time",
        },
      });
      return;
    }
    if (dataType?.dataType.toLowerCase().includes("select")) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          dataType: "Please select a valid dataType Name",
        },
      });
      return;
    }
    if (parentChild === null && dataType.dataId === 2) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          relationType: "Please Select a valid Relation Type",
        },
      });
      return;
    }
    if (
      availableAttributes.uiPrintableName.toLowerCase().includes("select") &&
      dataType.dataId === 2
    ) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          relationAttribute: "Please select a valid relation Type",
        },
      });
      return;
    }

    const ruleValidation = rule.every((item) => {
      const text = item.value.inputText;
      const ArrayValidation = Array.isArray(text)
        ? text.every((item) => String(item).length !== 0) && text.length === 2
        : text.length !== 0;
      return (
        !item.attribute.uiPrintableName.toLowerCase().includes("select") &&
        ArrayValidation
      );
    });

    if (!ruleValidation && dataType.dataId === 3) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          conditionType: "please provide valid condition Type",
        },
      });
      return;
    } else {
      const prevState = this.state.inputErrorValidation;
      prevState.conditionType = null;
      this.setState({ inputErrorValidation: prevState });
    }

    if (kpiType === null && dataType.dataId === 3) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          KpiType: "Please Select a KPI Type",
        },
      });
      return;
    }

    if (filterGroup.fgroupType.toLowerCase().includes("filteration")) {
      this.setState({
        inputErrorValidation: {
          ...this.state.inputErrorValidation,
          filterGroup: "Please select a valid relation Type",
        },
      });
      return;
    }
    // if (classification.length < 1) {
    //   this.setState({
    //     inputErrorValidation: {
    //       ...this.state.inputErrorValidation,
    //       classification: "Please select a Classification Type",
    //     },
    //   });
    //   return;
    // }
    const saveParams = {
      classification: this.state.classification,
      dataType: this.state.dataType.dataId,
      dateTimeFormat: this.state.dateFormat.formatId,
      filterGroup: this.state.filterGroup.fgroupId,
      inputType: this.state.inputType.inputId,
      isMasked: false,
      kpiCondition: buildKPICondition(this.state.rule),
      kpiType: this.state.kpiType,
      name: this.state.attributeName,
      personalizationCallbackName: "",
      solrFieldName: buildSolarFieldName(
        this.state.attributeName,
        this.state.inputType,
        this.state.dataType
      ),
      transactionDataAttribute:
        !this.state.availableAttributes.uiPrintableName.includes(
          "Select Attribute"
        )
          ? this.state.availableAttributes.uiPrintableName
          : "",
      transactionDataradio:
        this.state.dataType.dataId == 2
          ? this.state.parentChild
            ? "Parent of"
            : "Child of"
          : "",
      isAnnualReminder: false,
      departmentId: 0,
    };
    connectServer({
      path: SAVE_DATA_ATTRIBUTE,
      params: saveParams,
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (res) => {
        if (res?.status) {
          this.setState({
            isDataSavedModal: true,
            saveParams: saveParams,
          });
        } else {
          this.setState({
            isErrorModalOpen: true,
          });
        }
      },
      fail: (err) => {
        this.setState({
          isErrorModalOpen: true,
        });
      },
    });
  };
  IconDropdown = (props) => {
    return (
      <IconDropdown
        data={
          props.type === "ad"
            ? [
              "icon-plus-small icon-xs",
              "icon-minus-small icon-xs",
              "icon-lesser-than-small icon-xs",
              "icon-greater-than-small icon-xs",
            ]
            : [
              "icon-lesser-than-small icon-xs",
              "icon-in-between-small icon-xs",
              "icon-greater-than-small icon-xs",
              "icon-equal-to-small icon-xs",
              "icon-not-equal-to-small icon-xs",
            ]
        }
        defaultItem={
          props.type === "ad"
            ? "icon-plus-small icon-xs"
            : "icon-lesser-than-small icon-xs"
        }
        // customAlignRight={true}
        className="symbol-dropdown"
        onSelect={(e) => {
          const onSelectFilter = e.split(" ")[0];
          const splitter = onSelectFilter.slice(5, onSelectFilter.length - 6);
          props.selected(splitter);
          // this.props.selectValues.compareValue = e;
          //   this.forceUpdate();
        }}
      />
    );
  };

  ErrorhandleClose() {
    this.setState({ showPopUp: false, errorMessage: null });
  }

  attibuteNameExistValidation(attributeName) {
    const params = { attributeName: attributeName };
    connectServer({
      path: GET_DATA_ATTRIBUTE_UI_PRINTABLE_NAME_EXISTS,
      params,
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (data) => {
        if (data?.status) {
          this.setState({
            inputErrorValidation: {
              ...this.state.inputErrorValidation,
              attibuteName: "Attribute Name already Exists",
            },
            attributeNameExist: true,
          });
        } else {
          this.setState({
            inputErrorValidation: {
              ...this.state.inputErrorValidation,
              attibuteName: null,
            },
            attributeNameExist: false,
          });
        }
      },
      fail: (error) => {
      },
    });
  }

  getdateFormats() {
    connectServer({
      path: GET_DATE_FORMAT,
      params: {},
      loading: (status) => {
        this.props.context?.globalStateData?.setIsLoadingData(status);
      },
      ok: (res) => {
        if (res?.status) {
          this.setState({
            dateFormatData: res?.data?.map((format) => {
              return {
                formatId: format?.id,
                formatName: format?.name,
              };
            }),
          });
        }
      },
    });
  }

  handleToggleButton = (props) => {
    return (
      <span
        className="k-icon k-i-calendar lg"
        style={{
          fontSize: "24px",
        }}
        onClick={() => {
          const prevState = this.state.rule;
          prevState[props.index].show[props.type] =
            !prevState[props.index].show[props.type];
          this.setState({
            rule: prevState,
          });
        }}
      ></span>
    );
  };

  KPITypeChangehandler(event) {
    const prevState = this.state.inputErrorValidation;
    prevState.KpiType = null;
    this.setState({
      kpiType: event.target.value,
      inputErrorValidation: prevState,
    });
  }

  render() {
    const isActive = this.state.isActive;
    const AvailableAttributesArray = this.state.attributesData;
    const AvailableConditionArray = this.state.attributesData?.map((item) => {
      return {
        conditionattributeId: item.dataAttributeID,
        uiPrintableName: item.uiPrintableName,
      };
    });
    return (
      <>
        <Modal
          className="new-data-catalogue-modal"
          show={this.state.isModalOpen}
          onHide={() => this.handleClose()}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h2>New attribute</h2>
          </Modal.Header>
          <Modal.Body className="">
            <Row className="justify-content-md-center my30 ">
              <Col lg="3">Name</Col>
              <Col lg="7">
                <RSInput2
                  cls="mb0 posr top-5"
                  ph={"Enter attribute name"}
                  name="attributeName"
                  required={true}
                  val={this.state.attributeName}
                  errorMessage={this.state.inputErrorValidation.attibuteName}
                  cb={(t) => {
                    this.setState({ attributeName: t });
                    this.setState({ ErrMsgAttributeName: null });
                  }}
                  ob={(t) => {
                    if (t.length) {
                      this.attibuteNameExistValidation(t);
                    }
                    const validateAttributeName = isEmpty(t)
                      ? "Please enter valid attribute name"
                      : this.state.attributeNameExist
                        ? "Attribute Name already Exists"
                        : null;
                    this.setState({
                      inputErrorValidation: {
                        ...this.state.inputErrorValidation,
                        attibuteName: validateAttributeName,
                      },
                    });
                  }}
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col lg="3">Input type</Col>
              <Col lg="7">
                <div className="position-relative">
                  {this.state.inputErrorValidation.inputType && (
                    <div className="validation-message">
                      {this.state.inputErrorValidation.inputType}
                    </div>
                  )}
                  <RSDropdownList
                    data={this.state.inputTypeData}
                    value={this.state.inputType}
                    defaultItem={{
                      inputId: 0,
                      inputType: "-- Select input --",
                    }}
                    textField="inputType"
                    dataItemKey="inputId"
                    onChange={(e) => {
                      if (
                        e.value?.inputType === "Date & time" &&
                        this.state.dateFormatData.length === 0
                      ) {
                        this.getdateFormats();
                      }
                      this.setState({
                        inputType: e.target.value,
                        dateFormat: {
                          formatId: 0,
                          formatName: "-- Select input --",
                        },
                        inputErrorValidation: {
                          ...this.state.inputErrorValidation,
                          inputType: null,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            {this.state.inputType.inputType === "Date & time" && (
              <Row className="justify-content-md-center">
                <Col lg="3">Source Date & Time</Col>
                <Col lg="7">
                  <div className="position-relative">
                    {this.state.inputErrorValidation.dateFormatError && (
                      <div className="validation-message">
                        {this.state.inputErrorValidation.dateFormatError}
                      </div>
                    )}
                    <RSDropdownList
                      data={this.state.dateFormatData}
                      value={this.state.dateFormat}
                      textField="formatName"
                      dataItemKey="formatId"
                      onChange={(e) => {
                        this.setState({
                          dateFormat: e.target.value,
                          inputErrorValidation: {
                            ...this.state.inputErrorValidation,
                            dateFormatError: null,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row className="justify-content-md-center">
              <Col lg="3">Data type</Col>
              <Col lg="7">
                <div className="position-relative">
                  {this.state.inputErrorValidation.dataType && (
                    <div className="validation-message">
                      {this.state.inputErrorValidation.dataType}
                    </div>
                  )}
                  <RSDropdownList
                    data={this.state.dataTypeAttributes}
                    value={this.state.dataType}
                    defaultItem={{
                      dataId: 0,
                      dataType: "-- Select input --",
                    }}
                    textField="dataType"
                    dataItemKey="dataId"
                    itemRender={(li, itemProps) => {
                      let props = li.props;
                      if (itemProps.dataItem.disabled) {
                        props = {
                          ...props,
                          className: "k-item k-state-disabled",
                        };
                      }
                      const itemChildren = <span>{li.props.children}</span>;

                      return React.cloneElement(li, props, itemChildren);
                    }}
                    onChange={(e) => {
                      this.setState({
                        dataType: e.target.value,
                        inputErrorValidation: {
                          ...this.state.inputErrorValidation,
                          dataType: null,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            {this.state.dataType.dataId == 2 ? (
              <>
                <Row className="justify-content-md-center mb30 ">
                  <Col lg="3"></Col>
                  <Col lg="7">
                    <div className="position-relative">
                      {this.state.inputErrorValidation.relationType && (
                        <div className="validation-message">
                          {this.state.inputErrorValidation.relationType}
                        </div>
                      )}
                      <RSRadio
                        lbl={"Parent of"}
                        name="parentchild"
                        labelId={"parent"}
                        cb={(event) => {
                          this.setState({
                            parentChild: true,
                            inputErrorValidation: {
                              ...this.state.inputErrorValidation,
                              relationType: null,
                            },
                          });
                        }}
                      />

                      <RSRadio
                        className="ml15"
                        lbl={"Child of"}
                        name="parentchild"
                        labelId={"child"}
                        cb={(event) => {
                          this.setState({
                            parentChild: false,
                            inputErrorValidation: {
                              ...this.state.inputErrorValidation,
                              relationType: null,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-md-center mb30 ">
                  <Col lg="3">
                    {this.state.parentChild ? "Parent " : "Child "}
                    attribute{" "}
                  </Col>
                  <Col lg="7">
                    <div className="position-relative">
                      {this.state.inputErrorValidation.relationAttribute && (
                        <div className="validation-message">
                          {this.state.inputErrorValidation.relationAttribute}
                        </div>
                      )}
                      <RSDropdownList
                        data={AvailableAttributesArray}
                        value={this.state.availableAttributes}
                        defaultItem={{
                          dataAttributeID: 0,
                          uiPrintableName: "-- Select Attribute --",
                        }}
                        textField="uiPrintableName"
                        dataItemKey="dataAttributeID"
                        onChange={(e) => {
                          this.setState({
                            availableAttributes: e.target.value,
                            inputErrorValidation: {
                              ...this.state.inputErrorValidation,
                              relationAttribute: null,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
            {this.state.dataType.dataId == 3 ? (
              <div className="position-relative">
                {this.state.inputErrorValidation.conditionType && (
                  <div className="validation-message">
                    {this.state.inputErrorValidation.conditionType}
                  </div>
                )}
                <Row className="justify-content-md-center mb10 ">
                  <Col lg="8">Condition</Col>
                  <Col lg="2 text-end">
                    <i
                      class="icon-sd-circle-plus-fill-edge-medium icon-lg blue cursor-pointer"
                      onClick={() => {
                        const InputValidation = conditionValalidator(
                          this.state.rule
                        );
                        const prevState = this.state.rule;
                        InputValidation.errorIndex.forEach((item) => {
                          prevState[item].error = {
                            isError: true,
                            errorMessage: "Please enter valid details",
                          };
                        });
                        InputValidation.passedIndex.forEach((item) => {
                          prevState[item].error = errorInitailState;
                        });

                        if (
                          InputValidation.status &&
                          this.state.rule.length < 4
                        ) {
                          this.setState({
                            rule: [
                              ...this.state.rule,
                              {
                                attribute: {
                                  conditionattributeId: 0,
                                  uiPrintableName: "-- Select Attribute --",
                                },
                                value: {
                                  inputText: "",
                                },
                                error: {
                                  isError: false,
                                  errorMessage: "",
                                },
                                selectedCondition: {},
                                show: {
                                  startDate: false,
                                  endDate: false,
                                },
                              },
                            ],
                          });
                        } else {
                          this.setState({ rule: prevState });
                        }
                      }}
                    ></i>
                  </Col>
                </Row>
                <Row className="justify-content-md-center mb10 ">
                  {this.state.rule.map((item, ruleindex) => (
                    <>
                      <Col lg="4" key={"condition" + ruleindex}>
                        <div className="position-relative">
                          {this.state.rule[ruleindex].error?.isError &&
                            this.state.rule[ruleindex].attribute.uiPrintableName
                              .toLowerCase()
                              .includes("select") && (
                              <div className="validation-message">
                                {this.state.rule[ruleindex].error?.errorMessage}
                              </div>
                            )}
                        </div>
                        <RSDropdownList
                          data={AvailableConditionArray}
                          value={item?.attribute}
                          defaultItem={item?.attribute}
                          textField="uiPrintableName"
                          dataItemKey="conditionattributeId"
                          onChange={(e) => {
                            const Stateitem = [...this.state.rule];
                            Stateitem[ruleindex].value = {
                              inputText: "",
                            };
                            Stateitem[ruleindex].error = errorInitailState;
                            Stateitem[ruleindex].attribute = e.target.value;
                            Stateitem[ruleindex].selectedCondition = {
                              ...this.state.attributesData?.find(
                                (item) =>
                                  item?.uiPrintableName ===
                                  e.target?.value?.uiPrintableName
                              ),
                            };
                            this.setState({ rule: Stateitem });
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <Row>
                          {(item.selectedCondition?.fieldTypeID === 1 ||
                            item.selectedCondition?.fieldTypeID === 5 ||
                            item.selectedCondition?.fieldTypeID === 6 ||
                            Object.keys(item.selectedCondition)?.length ===
                            0) && (
                              <Col lg="11">
                                <div className="position-relative">
                                  {this.state.rule[ruleindex].error?.isError &&
                                    this.state.rule[ruleindex].value.inputText
                                      .length === 0 && (
                                      <div className="validation-message">
                                        {
                                          this.state.rule[ruleindex].error
                                            ?.errorMessage
                                        }
                                      </div>
                                    )}
                                  <RSInput2
                                    cls="mb0 posr"
                                    ph={`-- ${item.selectedCondition?.uiPrintableName ||
                                      "Text"
                                      } --`}
                                    name="textCondition"
                                    required={true}
                                    val={item?.value?.inputText || ""}
                                    cb={(t) => {
                                      const Stateitem = [...this.state.rule];
                                      Stateitem[ruleindex].value.inputText = t;
                                      this.setState({ rule: Stateitem });
                                    }}
                                  />
                                </div>
                              </Col>
                            )}
                          {(item.selectedCondition?.fieldTypeID === 4 ||
                            item.selectedCondition?.fieldTypeID === 3) && (
                              <>
                                <Col lg="2">
                                  <this.IconDropdown
                                    type={"compare"}
                                    selected={(value) => {
                                      const StateValue = this.state.rule;
                                      StateValue[ruleindex].value = {
                                        inputText: "",
                                      };
                                      StateValue[ruleindex].value = {
                                        ...StateValue[ruleindex].value,
                                        condition: value,
                                      };
                                      StateValue[ruleindex].error =
                                        errorInitailState;
                                      this.setState({ rule: StateValue });
                                    }}
                                  />
                                </Col>
                                <Col lg="8">
                                  {item.value?.condition === "in-between" ? (
                                    <>
                                      <div className="position-relative">
                                        {this.state.rule[ruleindex].value
                                          .inputText?.[0]?.length === 0 ||
                                          (this.state.rule[ruleindex].error
                                            .isError && (
                                              <div className="validation-message">
                                                {
                                                  this.state.rule[ruleindex].error
                                                    ?.errorMessage
                                                }
                                              </div>
                                            ))}

                                        <RSInput2
                                          cls="mb0 posr"
                                          name="textCondition"
                                          val={item?.value?.inputText?.[0] || ""}
                                          cb={(t) => {
                                            const stateValue = this.state.rule;
                                            if (
                                              item.value?.condition ===
                                              "in-between" &&
                                              !Array.isArray(
                                                stateValue[ruleindex].value
                                                  .inputText
                                              )
                                            ) {
                                              stateValue[
                                                ruleindex
                                              ].value.inputText = [];
                                            }
                                            stateValue[
                                              ruleindex
                                            ].value.inputText[0] = t;
                                            this.setState({ rule: stateValue });
                                          }}
                                          ob={(e) => {
                                            const prevState = this.state.rule;
                                            const toInputValue =
                                              Number(
                                                prevState[ruleindex]?.value
                                                  ?.inputText?.[1]
                                              ) || "";
                                            if (
                                              (toInputValue !== "" &&
                                                Number(e) === toInputValue) ||
                                              toInputValue < Number(e)
                                            ) {
                                              prevState[ruleindex].error = {
                                                isError: true,
                                                errorMessage:
                                                  "Please enter Valid Digits",
                                              };
                                            } else {
                                              prevState[ruleindex].error =
                                                errorInitailState;
                                            }
                                            this.setState({ rule: prevState });
                                          }}
                                        />
                                      </div>
                                      <span>to</span>
                                      <div className="position-relative">
                                        {(this.state.rule[ruleindex].value
                                          .inputText?.[1]?.length === 0 ||
                                          this.state.rule[ruleindex].error
                                            .isError) && (
                                            <div className="validation-message">
                                              {
                                                this.state.rule[ruleindex].error
                                                  ?.errorMessage
                                              }
                                            </div>
                                          )}
                                        <RSInput2
                                          cls="mb0 posr"
                                          name="textCondition"
                                          val={item?.value?.inputText?.[1] || ""}
                                          cb={(t) => {
                                            const stateValue = this.state.rule;
                                            if (
                                              item.value?.condition ===
                                              "in-between" &&
                                              !Array.isArray(
                                                stateValue[ruleindex].value
                                                  .inputText
                                              )
                                            ) {
                                              stateValue[
                                                ruleindex
                                              ].value.inputText = [];
                                            }
                                            stateValue[
                                              ruleindex
                                            ].value.inputText[1] = t;
                                            this.setState({ rule: stateValue });
                                          }}
                                          ob={(e) => {
                                            const prevState = this.state.rule;
                                            const toInputValue =
                                              Number(
                                                prevState[ruleindex]?.value
                                                  ?.inputText?.[0]
                                              ) || 0;

                                            if (
                                              (toInputValue !== 0 &&
                                                Number(e) ===
                                                Number(toInputValue)) ||
                                              Number(e) < Number(toInputValue)
                                            ) {
                                              prevState[ruleindex].error = {
                                                isError: true,
                                                errorMessage:
                                                  "Please enter Valid Digits",
                                              };
                                            } else {
                                              prevState[ruleindex].error =
                                                errorInitailState;
                                            }
                                            this.setState({ rule: prevState });
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="position-relative">
                                      {this.state.rule[ruleindex].error
                                        ?.isError &&
                                        this.state.rule[ruleindex].value.inputText
                                          ?.length === 0 && (
                                          <div className="validation-message">
                                            {
                                              this.state.rule[ruleindex].error
                                                ?.errorMessage
                                            }
                                          </div>
                                        )}
                                      <RSInput2
                                        cls="mb0 posr"
                                        ph={`-- All --`}
                                        name="textCondition"
                                        required={true}
                                        val={item?.value?.inputText || ""}
                                        cb={(t) => {
                                          const StateValue = this.state.rule;
                                          if (
                                            !StateValue[ruleindex].value
                                              ?.condition
                                          ) {
                                            StateValue[
                                              ruleindex
                                            ].value.condition = "lesser-than";
                                          }
                                          StateValue[ruleindex].value.inputText =
                                            t;
                                          this.setState({ rule: StateValue });
                                        }}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </>
                            )}
                          {item.selectedCondition?.fieldTypeID === 8 && (
                            <Row>
                              <Col md="5">
                                <div className="rspdate-picker position-relative">
                                  {this.state.rule[ruleindex].error?.isError &&
                                    this.state.rule[ruleindex].value
                                      .inputText?.[0]?.length === 0 && (
                                      <div className="validation-message">
                                        {
                                          this.state.rule[ruleindex].error
                                            ?.errorMessage
                                        }
                                      </div>
                                    )}
                                  <DatePicker
                                    format={"MMM dd, yyyy"}
                                    placeholder={"Start date"}
                                    value={
                                      this.state.rule[ruleindex]?.value
                                        ?.inputText?.[0] || new Date()
                                    }
                                    onChange={(e) => {
                                      const StateValue = this.state.rule;
                                      if (
                                        !Array.isArray(
                                          StateValue[ruleindex].value.inputText
                                        )
                                      ) {
                                        StateValue[ruleindex].value.inputText =
                                          [];
                                      }
                                      StateValue[ruleindex].value.inputText[0] =
                                        e.value;
                                      StateValue[ruleindex].show["startDate"] =
                                        !StateValue[ruleindex].show[
                                        "startDate"
                                        ];
                                      this.setState({
                                        rule: StateValue,
                                      });
                                    }}
                                    required={false}
                                    className="required"
                                    show={
                                      this.state.rule[ruleindex].show[
                                      "startDate"
                                      ]
                                    }
                                    defaultValue={new Date()}
                                    toggleButton={() => (
                                      <this.handleToggleButton
                                        type="startDate"
                                        index={ruleindex}
                                      />
                                    )}
                                  />
                                </div>
                              </Col>
                              <Col md="2" className="text-center mt12">
                                <span>to</span>
                              </Col>
                              <Col md="5">
                                <div className="rspdate-picker">
                                  <DatePicker
                                    format={"MMM dd, yyyy"}
                                    placeholder={"End date"}
                                    value={
                                      this.state.rule[ruleindex]?.value
                                        ?.inputText?.[1] || new Date()
                                    }
                                    onChange={(e) => {
                                      const StateValue = this.state.rule;
                                      if (
                                        !Array.isArray(
                                          StateValue[ruleindex].value.inputText
                                        )
                                      ) {
                                        StateValue[ruleindex].value.inputText =
                                          [];
                                      }
                                      StateValue[ruleindex].value.inputText[1] =
                                        e.value;
                                      StateValue[ruleindex].show.endDate =
                                        !StateValue[ruleindex].show.endDate;
                                      this.setState({
                                        rule: StateValue,
                                      });
                                    }}
                                    min={
                                      this.state.rule[ruleindex]?.value
                                        ?.inputText?.[0] || new Date()
                                    }
                                    max={new Date()}
                                    show={
                                      this.state.rule[ruleindex].show["endDate"]
                                    }
                                    defaultValue={new Date()}
                                    toggleButton={() => (
                                      <this.handleToggleButton
                                        type="endDate"
                                        index={ruleindex}
                                      />
                                    )}
                                    onBlur={(e) => {
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}

                          <Col lg="1">
                            <i
                              class={`icon-sd-circle-minus-fill-edge-medium icon-xs ${ruleindex === 0 && this.state.rule.length < 2
                                ? "grey"
                                : "red"
                                } cursor-pointer`}
                              onClick={() => {
                                const ruleState = this.state.rule;
                                if (this.state.rule.length > 1) {
                                  ruleState.splice(ruleState, 1);
                                }
                                this.setState({ rule: ruleState });
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
                <Row className="justify-content-md-center mb30 ">
                  <Col lg="3">KPI type</Col>
                  <Col lg="7">
                    <div className="position-relative">
                      {this.state.inputErrorValidation.KpiType && (
                        <div className="validation-message">
                          {this.state.inputErrorValidation.KpiType}
                        </div>
                      )}
                      <RSRadio
                        lbl="Count"
                        name={"kpiType"}
                        labelId={"kpiTypeCount"}
                        className="mr10"
                        checked={this.state.kpiType === "Count" ? true : false}
                        cb={(event) => this.KPITypeChangehandler(event)}
                      />

                      {this.state.rule[0].selectedCondition?.fieldTypeID !==
                        1 && (
                          <>
                            <RSRadio
                              lbl="Min"
                              name={"kpiType"}
                              labelId={"kpiTypeMin"}
                              className="mr10"
                              checked={
                                this.state.kpiType === "Min" ? true : false
                              }
                              cb={(event) => this.KPITypeChangehandler(event)}
                            />
                            <RSRadio
                              lbl="Max"
                              name={"kpiType"}
                              labelId={"kpiTypeMax"}
                              className="mr10"
                              checked={
                                this.state.kpiType === "Max" ? true : false
                              }
                              cb={(event) => this.KPITypeChangehandler(event)}
                            />
                            {this.state.rule[0].selectedCondition?.fieldTypeID !==
                              8 && (
                                <>
                                  <RSRadio
                                    lbl="Sum"
                                    name={"kpiType"}
                                    labelId={"kpiTypeSum"}
                                    className="mr10"
                                    checked={
                                      this.state.kpiType === "Sum" ? true : false
                                    }
                                    cb={(event) => this.KPITypeChangehandler(event)}
                                  />
                                  <RSRadio
                                    lbl="Average"
                                    name={"kpiType"}
                                    labelId={"kpiTypeAverage"}
                                    className="mr10"
                                    checked={
                                      this.state.kpiType === "Average"
                                        ? true
                                        : false
                                    }
                                    cb={(event) => this.KPITypeChangehandler(event)}
                                  />
                                </>
                              )}

                            {this.state.rule[0].selectedCondition?.fieldTypeID !==
                              3 &&
                              this.state.rule[0].selectedCondition
                                ?.fieldTypeID !== 4 && (
                                <RSRadio
                                  lbl="Duration"
                                  name={"kpiType"}
                                  labelId={"kpiTypeDuration"}
                                  className="mr10"
                                  checked={
                                    this.state.kpiType === "Duration"
                                      ? true
                                      : false
                                  }
                                  cb={(event) => this.KPITypeChangehandler(event)}
                                />
                              )}
                          </>
                        )}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row className="justify-content-md-center">
              <Col lg="3">Filter group</Col>
              <Col lg="7">
                <div className="position-relative">
                  {this.state.inputErrorValidation.filterGroup && (
                    <div className="validation-message">
                      {this.state.inputErrorValidation.filterGroup}
                    </div>
                  )}

                  <RSDropdownList
                    data={this.state.filterGroups}
                    value={this.state.filterGroup}
                    defaultItem={{
                      fgroupId: 0,
                      fgroupType: "-- Assign filteration group --",
                    }}
                    textField="fgroupType"
                    dataItemKey="fgroupId"
                    onChange={(e) => {
                      this.setState({
                        filterGroup: e.target.value,
                        inputErrorValidation: {
                          ...this.state.inputErrorValidation,
                          filterGroup: null,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-md-center mb30">
              <Col lg="3">Classification</Col>
              <Col lg="7">
                <div className="position-relative">
                  <ul className="classificationIconsList d-flex justify-content-md-between">
                    <li>
                      <RSPTooltip position="top" text="Sensitive data">
                        <i
                          className={`icon-sd-lock-medium icon-lg tag-sensitive-data  cursor-pointer`}
                          onClick={(e) => this.handleClassification(5, e)}
                        ></i>
                      </RSPTooltip>
                    </li>
                    <li>
                      <RSPTooltip position="top" text="Content target">
                        <i
                          className={`icon-content-target-small icon-lg tag-content-target cursor-pointer`}
                          onClick={(e) => this.handleClassification(4, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Profile completeness">
                        <i
                          className={`icon-profile-completeness-small icon-lg tag-profile-completeness cursor-pointer`}
                          onClick={(e) => this.handleClassification(2, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Audience overview">
                        <i
                          className={`icon-audience-overview-small icon-lg tag-audience-overview cursor-pointer`}
                          onClick={(e) => this.handleClassification(1, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Advanced analytics">
                        <i
                          className={`icon-channel-action-medium icon-lg tag-advanced-analytics cursor-pointer`}
                          onClick={(e) => this.handleClassification(6, e)}
                        ></i>
                      </RSPTooltip>
                    </li>
                    <li>
                      <RSPTooltip position="top" text="Offline conversion">
                        <i
                          className={`icon-tag-advanced-analytics icon-offline-conversion-medium icon-lg cursor-pointer`}
                          onClick={(e) => this.handleClassification(7, e)}
                        ></i>
                      </RSPTooltip>
                    </li>
                    <li>
                      <RSPTooltip position="top" text="Audience base">
                        <i
                          className={`icon-audiencebase-medium icon-lg tag-advanced-analytics cursor-pointer`}
                          onClick={(e) => this.handleClassification(8, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Offers">
                        <i
                          className={`icon-offer-data-medium icon-lg tag-advanced-analytics cursor-pointer`}
                          onClick={(e) => this.handleClassification(9, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="GDPR">
                        <i
                          className={`icon-gdpr-medium icon-lg tag-advanced-analytics  cursor-pointer`}
                          onClick={(e) => this.handleClassification(10, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Sub-segment">
                        <i
                          className={`icon-sub-segment-small icon-lg tag-advanced-analytics cursor-pointer`}
                          onClick={(e) => this.handleClassification(11, e)}
                        ></i>
                      </RSPTooltip>
                    </li>

                    <li>
                      <RSPTooltip position="top" text="Personalisation">
                        <i
                          className={`icon-personalisation-small icon-lg tag-personalisation cursor-pointer`}
                          onClick={(e) => this.handleClassification(3, e)}
                        ></i>
                      </RSPTooltip>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            {isActive ? (
              <Row className="justify-content-md-center my30 ">
                <Col lg="3"></Col>
                <Col lg="7">
                  <RSInput2
                    cls="mb0 posr top-5"
                    ph={"Default fallback attribute name"}
                  />
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <RSSecondaryBtn
              onClick={() => {
                this.setState({
                  isModalOpen: !this.state.isModalOpen,
                });
                this.props.onChangeIsOpen(false);
              }}
            >
              Cancel
            </RSSecondaryBtn>
            <RSPrimaryBtn onClick={this.handleSaveAttribute}>Save</RSPrimaryBtn>
          </Modal.Footer>
        </Modal>
        {this.state.isErrorModalOpen && (
          <ErrorModal
            isOpen={this.state.isErrorModalOpen}
            onChangeIsOpen={(status) => {
              this.setState({ isErrorModalOpen: status });
            }}
            title={"Error:"}
            secondaryButton={"Close"}
          >
            Unexpected happened. Try again later
          </ErrorModal>
        )}
        {this.state.isDataSavedModal && (
          <ErrorModal
            isOpen={this.state.isDataSavedModal}
            onChangeIsOpen={(status) => {
              this.setState({ isDataSavedModal: status });
              this.props.onChangeIsOpen(false);
              this.props.isReload(true, this.state.saveParams);
            }}
            secondaryButton={"Ok"}
          >
            Attribute has been saved successfully
          </ErrorModal>
        )}
      </>
    );
  }
}

export default NewAttributeModal;

function conditionValalidator(list) {
  const errorIndex = [];
  const passedIndex = [];
  var status = list?.map((item, itemIndex) => {
    const arrayValidation = Array.isArray(item)
      ? item.value.inputText.length !== 0
      : item.value.inputText !== "";
    if (
      arrayValidation &&
      item.value.inputText !== undefined &&
      item?.attribute?.uiPrintableName.toLowerCase().includes("select") ===
      false
    ) {
      passedIndex.push(itemIndex);
      return true;
    } else {
      errorIndex.push(itemIndex);
      return false;
    }
  });
  return {
    status: status.every((item) => item === true),
    errorIndex,
    passedIndex,
  };
}

function buildSolarFieldName(
  newAttributeName,
  validation_InputType,
  validation_dataType
) {
  let SOLRfieldname = "";
  let { inputId: fieldTypeId } = validation_InputType;
  let { dataId: dataTypeId } = validation_dataType;
  if (
    newAttributeName != "" &&
    !validation_InputType?.inputType.toLowerCase().includes("select input") &&
    !validation_dataType?.dataType.toLowerCase().includes("select type")
  ) {
    if (dataTypeId == "1" || dataTypeId == "3") {
      if (fieldTypeId == "4") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "i";
      } else if (fieldTypeId == "5") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "b";
      } else if (fieldTypeId == "1") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "s";
      } else if (fieldTypeId == "3") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "d";
      } else if (fieldTypeId == "8") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "dt";
      } else if (fieldTypeId == "2") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "p";
      } else {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "s";
      }
    } else if (dataTypeId == "2") {
      if (fieldTypeId == "4") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "is";
      } else if (fieldTypeId == "5") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "bs";
      } else if (fieldTypeId == "1") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "ss";
      } else if (fieldTypeId == "3") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "ds";
      } else if (fieldTypeId == "8") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "dts";
      } else if (fieldTypeId == "2") {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "ps";
      } else {
        SOLRfieldname = newAttributeName.replace(/ /g, "_") + "_" + "ss";
      }
    }
  }
  return SOLRfieldname;
}

function buildKPICondition(rule) {
  const pickCondition = (condition) => {
    switch (condition) {
      case "lesser-than":
        return "<";
      case "in-between":
        return "[]";
      case "greater-than":
        return ">";
      case "equal-to":
        return "=";
      case "not-equal-to":
        return "!=";
    }
  };
  return (
    rule?.map((item) => {
      const compare = item?.value?.condition
        ? pickCondition(item?.value?.condition)
        : "";
      return {
        DataAttributeID: item?.selectedCondition?.dataAttributeID,
        AttributeName: item?.selectedCondition?.attributeName,
        FieldType: item?.selectedCondition?.fieldTypeID,
        CompareValue: compare,
        Value: item?.value?.inputText,
      };
    }) || []
  );
}
