import NewCharts from 'Components/Charts/NewCharts';
import { RTabbar } from 'Components/RChart/RTabbar';
import { HorizontalSkeleton, NoData } from 'Components/Skeleton/Skeleton';
import React, { Component } from 'react';
import { ch_orange, ch_qrColor } from 'Components/Charts/ChartColors';

class QRReachChart extends Component {
    state = {
        tabReachIndex: 0,
        data: this.props.data,
        expandEnable: false
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.setState({ data: this.props.data })
        }
    }
    isEnableExpandIcon = () => {
        let isEnable = true;
        if (this.state.tabReachIndex === 0) {
            if (this.state.data?.reachPerformanceDTOs !== null && this.state.data?.reachPerformanceDTOs.categories !== null && this.state.data?.reachPerformanceDTOs.series !== null) {
                isEnable = true;
            } else {
                isEnable = false;
            }
        } else {
            if (this.state.data?.reachPerformanceHrsDTOs !== null && this.state.data?.reachPerformanceHrsDTOs.categories !== null && this.state.data?.reachPerformanceHrsDTOs.series !== null) {
                isEnable = true;
            } else {
                isEnable = false;
            }
        }
        return isEnable;
    }
    render() {
        return (<div className={`portlet-container portlet-md ${this.state.expandEnable ? "expand-full-view" : ""}`}>
            <div className="portlet-header">
                <h4>Reach</h4>
                <div className="p-nav-right">
                    <RTabbar
                        defaultClass="tabDefault"
                        dynamicTab="mini marginB0"
                        activeClass="tabDefault active"
                        defaultSelectedItem={this.state.tabReachIndex}
                        tabData={tabDataReach}
                        callBack={(item, index) => this.setState({ tabReachIndex: index })}
                    />
                </div>
            </div>
            <div className="portlet-body">
                {
                    this.state.tabReachIndex === 0
                        ? <div className="portlet-chart">
                            {this.state.data?.reachPerformanceDTOs !== null && this.state.data?.reachPerformanceDTOs?.categories !== null && this.state.data?.reachPerformanceDTOs?.series !== null ?
                                <NewCharts options={reachOverallChart(this.state.data)} /> : <><NoData /><HorizontalSkeleton /></>
                            }
                        </div>
                        : <div className="portlet-chart">
                            {this.state.data?.reachPerformanceHrsDTOs !== null && this.state.data?.reachPerformanceHrsDTOs?.categories !== null && this.state.data?.reachPerformanceHrsDTOs?.series !== null ?
                                <NewCharts options={reachFirstHrChart(this.state.data)} /> : <><NoData /><HorizontalSkeleton /></>
                            }
                        </div>
                }
            </div>
            {/* <div class="portlet-info-text">
                (As on: <span>Above counts are based on day-wise unique audience </span>)
            </div> */}
            {this.isEnableExpandIcon() &&
                <div className="expand-icon-wrap" onClick={() => {
                    this.setState({
                        expandEnable: !this.state.expandEnable
                    })
                }}>
                    <i className={`${this.state.expandEnable ? "icon-sd-arrow-left-mini" : "icon-sd-arrow-right-mini"} white`}></i>
                </div>}
        </div>);
    }
}

export default QRReachChart;

const tabDataReach = [
    { "id": 1001, "text": "Overall" },
    { "id": 1002, "text": "First 24 hrs" }
]

// Reach Overall -- area
const reachOverallChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Date'
            },
            // categories: data?.reachPerformanceDTOs?.map(item => {
            //     let newDate = new Date(item?.date).toDateString()
            //     let splitDate = newDate.split(" ")
            //     return splitDate[2] + " " + splitDate[1]
            // }),
            categories: data?.reachPerformanceDTOs?.categories.map(item => {
                let newDate = new Date(item).toDateString()
                let splitDate = newDate.split(" ")
                return splitDate[2] + " " + splitDate[1]
            }),
            labels: {
                rotation: 0
            },
            // tickInterval: 3
        },
        yAxis: {
            title: {
                text: 'Count'
            },
            // tickInterval: 8
        },
        symbolRadius: 0,
        plotOptions: {
            series: {
                marker: {
                    fillColor: null,
                    lineColor: null,
                    lineWidth: 1,
                    radius: 3,
                    symbol: 'circle',
                }
            },
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: '#fff', radius: 3, lineColor: ch_qrColor, lineWidth: 2 },
                name: data?.reachPerformanceDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_qrColor,
                // data: data?.reachPerformanceDTOs?.filter(item => item?.contextAttributeName === 'Unique Opens').map(item => item?.count || 0),
                data: data?.reachPerformanceDTOs?.series[0]?.datas?.map(item => item || 0),
                legendIndex: 0
            }
        ],
    }

}

// Reach First 24 hr -- area
const reachFirstHrChart = data => {

    return {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Hours'
            },
            // categories: data?.reachPerformanceHrsDTOs?.map(item => `${item?.time} hr` || 0),
            categories: data?.reachPerformanceHrsDTOs?.categories?.map(item => `${item} hr` || 0),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Count'
            }
        },
        symbolRadius: 0,
        legend: {
            enabled: true,
        },
        series: [
            {
                marker: { fillColor: 'white', radius: 3, lineWidth: 2, lineColor: ch_qrColor },
                name: data?.reachPerformanceHrsDTOs?.series[0]?.name,
                lineWidth: 1,
                color: ch_qrColor,
                // data: data?.reachPerformanceHrsDTOs?.map(item => item?.count)
                data: data?.reachPerformanceHrsDTOs?.series[0]?.datas.map(item => item || 0)
            }
        ],
    }

}