import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { RSDropdownList, RSPhoneInput } from 'Components/RSDropdowns';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import authContext from 'Helper/StateHandler/auth-context';
import RESEditor from 'Components/Editor/RESEditor';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { CustomPopup } from 'Components/DateRangePicker/ScheduleCustomPopup';
import { AUDIENCE, CAMPAIGN_TEXT, TEMPLATE_LANGUAGE, SCHEDULE_DATE, SELECT_TIMEZONE, SEND_TEST_MSG, TEMPLATE_NAME, SELECT_SENDER_NAME } from 'Helper/Constants/validationMessage';
import { addMinutes, convertObjectToBase64, getCurrentTimeWithTimeZone, getLsMasterDataDropdown, getUserData, isEmpty, numberFormatter } from 'Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_ADHOC_ATTRIBUTES, GET_AUDIENCE_LIST, GET_SHORTEN_URL, GET_WHATSAPP_LANGUAGES, GET_WHATSAPP_SENDER_NAME, GET_WHATSAPP_TEMPLATE, SAVE_WHATSAPP_CAMPAIGN, UPDATE_WHATSAPP_CAMPAIGN } from 'Helper/Constants/endPoint';
import { createMobileWhatsAppCampaign } from '../CamapignWrapper';
import moment from 'moment';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import { SuccessModal } from '../EmailComponents/Components/SuccessModal';
import WhatsAppPreviewContent from './Components/WhatsAppPreviewContent';
import pdfImage from 'Assets/Images/wapdf.png';
import videoImage from 'Assets/Images/wavideo.png';
import WhatsAppLivePreview from './Components/WhatsAppLivePreview';
import { RSConfirmAlert } from 'Components/RSConfirmAlert';

const defaultValue = new Date();

const MobileWhatsappCreation = props => {

  const context = useContext(authContext)
  const history = useHistory();

  const [isOpenWASendSuccessModal, setIsOpenWASendSuccessModal] = useState(false);
  const [isSearchValidate, setIsSearchValidate] = useState(false);
  const [waChannelDetailID, setWaChannelDetailID] = useState(props.data.waChannelDetailID);
  const [senderNameList, setSenderNameList] = useState([]);
  const [senderName, setSenderName] = useState(null);
  const [editClickOff, setEditClickOff] = useState(false);
  const [audienceList, setAudienceList] = useState(props.data.recipientBunchIDs);
  const [language, setLangage] = useState({ templateName: "", templateLanguageCode: "-- Select template language --" });
  const [whatsAppTemplates, setWhatsApptemplates] = useState([]);
  const [templateName, setTemplateName] = useState(null);
  const [campaignText, setCampaignText] = useState("");
  const [previewCampaignText, setPreviewCampaignText] = useState(props.data.waPreviewContent);
  const [waImagePath, setWAImagePath] = useState(props.data.waImagePath);
  const [waMediaURL, setWAMediaURL] = useState(props.data.waMediaURL);
  const [waMediaURLType, setWAMediaURLType] = useState(props.data.waMediaURLType);
  const [shortenLink, setShortenLink] = useState("");
  const [smartUrls, setSmartUrls] = useState([]);
  const [mobileNo, setMobileNo] = useState(props.data.testSmsMobileNo !== "" ? props.data.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryCode, setCountryCode] = useState("in");
  const [countryPhoneNumberLength, setCountryPhoneNumberLength] = useState(0)
  const [countryCodeMobile, setCountryCodeMobile] = useState(props.data.countryCodeMobile !== "" ? props.data.countryCodeMobile : getUserData()?.countryCodeMobile);
  const [isRequestForApproval, setIsRequestApproval] = useState(false);
  const [isDayLightSavings, setIsDayLightSavings] = useState(false);
  const [isSendRecommendation, setIsSendRecommendation] = useState(false);
  const [schedule, setSchedule] = useState(props.data.isSendTestWA !== 2 ? props.data.localBlastDateTime : "");
  const [approverName, setApproverName] = useState("");
  const [errSenderNameMsg, setErrSenderNameMsg] = useState(null);
  const [errAudienceMsg, setErrAudienceMsg] = useState(null);
  const [errTemplateLanguageMsg, setErrTemplateLanguageMsg] = useState(null);
  const [errTemplateNameMsg, setErrTemplateNameMsg] = useState(null);
  const [errCampaignText, setErrCampaignTextMsg] = useState(null);
  const [errSendTestMsg, setErrSendTestMsg] = useState(null);
  const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(null);
  const [errTimeZoneMsg, setErrTimeZoneMsg] = useState(null);
  const [errMbileMsg, setErrMbileMsg] = useState(null);
  const [languageData, setLanguageData] = useState([]);
  const [audienceListFromServer, setAudienceListFromServer] = useState([]);
  const [audienceListForDropDown, setAudienceListForDropDown] = useState([]);
  const [isEditTimeZone, setIsEditTimeZone] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [subjectLinePersonalizeData, setSubjectLinePersonalizeData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bodyTextCount, setBodyTextCount] = useState("");
  const [smsCount, setSMSCount] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isShowRemoveModal, setIsShowRemoveModal] = useState(false);
  const [templateContent, setTemplateContent] = useState('')
  const [editableBraceCount, setEditableBraceCount] = useState(0);
  const [isShortURLCreated, setIsShortURLCreated] = useState(false);
  const [isOpenLiveSuccessModal, setIsOpenLiveSuccessModal] = useState(false);
  const [paymentStatusForList, setPaymentStatusForList] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0);
    const clickOffStatus = props.mode === "add"
      ? false
      : (props.data.channelstatusId !== 9 && props.data.channelstatusId !== 5 && props.data.channelstatusId !== 20 && props.data.channelstatusId !== 52)
        ? false
        : true;
    setEditClickOff(clickOffStatus)
    setSchedule(props.data.isSendTestWA !== 2 ? props.data.localBlastDateTime : "")
    setWaChannelDetailID(props.data.waChannelDetailID);
    if (!!!senderName) {
      getWhatsAppSettings();
    }
    getAudienceList();
    getTimeZoneList();
    setWAImagePath(props.data.waImagePath)
    setWAMediaURL(props.data.waMediaURL);
    setWAMediaURLType(props.data.waMediaURLType);
    const campaignHeader = props.campaignHeader
    setStartDate(new Date(getStartDate(campaignHeader.startDate)))
    // setEndDate(new Date(campaignHeader.endDate))
    setEndDate(new Date(new Date(new Date(campaignHeader.endDate).getFullYear(), new Date(campaignHeader.endDate).getMonth(), new Date(campaignHeader.endDate).getDate(), 23, 59, 59)))
    let paymentStatus = props.data.recipientBunchIDs.length === 0 ? null : props.data.recipientBunchIDs[0]?.isPaid ? null : "Payment for the list is pending";
    setPaymentStatusForList(paymentStatus)
    setAudienceList(props.data.recipientBunchIDs);
    getAdhocAttributeHeaders(props.data.recipientBunchIDs[0]?.segmentationListID);
    setIsShortURLCreated(props.data.isShortenURLEnabled)
    // setMobileNo(props.mobileSMSData.testSmsMobileNo !== "" ? props.mobileSMSData.testSmsMobileNo : getUserData().mobileNo);
    // setCountryCodeMobile(props.mobileSMSData.countryCodeMobile !== "" ? props.mobileSMSData.countryCodeMobile : getUserData().countryCodeMobile);
  }, [props])

  const getSMSCount = (campaignText) => {
    let textCount = campaignText?.length > 0 ? campaignText?.length.toString() + "/" : "";
    // let smsCount = Math.floor(campaignText?.length / 140) + 1;
    let smsCount = 1;
    setBodyTextCount(textCount);
    setSMSCount(smsCount.toString());
  }

  const handleCountryPhoneNumberLength = (country) => {
    let countryFormat = country['format'].split(/ (.*)/s);
    let [countryCodeFormat, countryNumberFormat, empty] = countryFormat;
    let totCount = (countryNumberFormat.match(/\./g) || []).length
    setCountryPhoneNumberLength(totCount);
  }

  const handleMobileNumberValidation = (mobileNumber, maxMobile) => {
    let numberWihoutComma = mobileNumber?.replaceAll(',', '');
    let valid = true;
    let errObj = { err: false, msg: null };
    // let phoneValues = numberWihoutComma?.match(new RegExp('.{1,' + countryPhoneNumberLength + '}', 'g'));
    let phoneValues = mobileNumber.split(/\s*,\s*/);
    if (phoneValues && phoneValues.length <= 5) {
      const numberPattern = /^[+]*[0-9]+$/;
      phoneValues.forEach(item => {
        if (item.length < 5 || item.length > countryPhoneNumberLength || !numberPattern.test(item)) {
          valid = false;
        }
      });
    }
    if (!phoneValues) {
      errObj.err = true;
      errObj.msg = 'Please enter mobile number';
    } else if (!valid) {
      errObj.err = true;
      errObj.msg = 'Enter valid mobile number';
    } else if (maxMobile) {
      errObj.err = true;
      errObj.msg = 'Maximum mobile number reached';
    } else if (phoneValues && phoneValues.length !== new Set(phoneValues).size) {
      errObj.err = true;
      errObj.msg = 'Please remove duplicate number';
    }
    return errObj;
  }

  const getStartDate = (startDate) => {
    let campaignStartDate = new Date(startDate);
    let today = new Date();
    if (campaignStartDate > today) {
      campaignStartDate = new Date(startDate);
    } else {
      campaignStartDate = new Date();
    }
    return campaignStartDate
  }
  const getStartDateForSchedule = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (props.data.timezoneID === 0) {
      filterTimezone = data.timeZone.filter(
        (item) =>
          item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
      )[0];
    } else {
      filterTimezone = data.timeZone.filter(
        (item) => item.timezoneId === props.data.timezoneID
      )[0];
    }
    const campaignHeader = props.campaignHeader
    let startDate = new Date(getStartDate(campaignHeader.startDate));
    let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
    return startDateWithTime;
  }

  const getWhatsAppSettings = () => {
    let params = {
    }
    connectServer({
      path: GET_WHATSAPP_SENDER_NAME,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setSenderNameList(res.data?.waSenderNameattributes);
          let tempSenderName = res.data?.waSenderNameattributes.filter(item => item.clientWASenderID === props.data.senderID)[0];
          setSenderName(tempSenderName);
          if (!!tempSenderName?.clientWASenderID && tempSenderName?.clientWASenderID !== 0) {
            getLanguageDataFromServer(tempSenderName?.clientWASenderID);
          }
        }
      },
      fail: err => {
      }
    })
  }

  const getLanguageDataFromServer = (senderId) => {
    let params = {
      "senderId": senderId
    }
    connectServer({
      path: GET_WHATSAPP_LANGUAGES,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setLanguageData(res.data?.waHSMattribute);
          let tempLanguage = res.data?.waHSMattribute?.filter(item => item.templateLanguageCode === props.data.languageCode)[0];
          setLangage(tempLanguage);
          if (!!tempLanguage) {
            getWhatsAppTemplates(senderId, tempLanguage?.templateLanguageCode);
          }
        }
      },
      fail: err => {
      }
    })
  }

  const getWhatsAppTemplates = (senderId, languageCode) => {
    let params = {
      "senderId": senderId || 0,
      "templateLanguageCode": languageCode || ""
    }
    connectServer({
      path: GET_WHATSAPP_TEMPLATE,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setWhatsApptemplates(res.data?.waHSMattribute);
          let tempWAtemplate = res.data?.waHSMattribute?.filter(item => item.waTemplateId === props.data.waTemplateID)[0];
          if (tempWAtemplate !== null) {
            setTemplateName(tempWAtemplate);
            if (props.data.waContent !== "") {
              setCampaignText(props.data.waContent);
              setPreviewCampaignText(props.data.waPreviewContent);
              const regex = /{{(.*?)}}/g;
              const result = props.data.waPreviewContent?.replace(regex, '');
              const count = (props.data.waPreviewContent?.match(regex) || []).length;
              setEditableBraceCount(count);
              setTemplateContent(result)
              getSMSCount(props.data.waContent);
            } else {
              props.data.waContent = tempWAtemplate?.templateContent;
              props.data.waPreviewContent = tempWAtemplate?.templateContent;
              const regex = /{{(.*?)}}/g;
              const result = tempWAtemplate?.templateContent?.replace(regex, '');
              const count = (tempWAtemplate?.templateContent?.match(regex) || []).length;
              setEditableBraceCount(count);
              setTemplateContent(result)
              setCampaignText(tempWAtemplate?.templateContent);
              getSMSCount(tempWAtemplate?.templateContent);
            }
          }
        }
      },
      fail: err => {
      }
    })
  }

  const getAudienceList = param => {
    let params = {
      searchText: "",
      campaignID: 0,
      channel: "S"
    }
    connectServer({
      path: GET_AUDIENCE_LIST,
      params,
      context: context,
      ok: res => {
        if (res.status && res.message !== "No data") {
          localStorage.setItem(
            'lsAudienceList',
            convertObjectToBase64(res.data)
          )
          setAudienceListFromServer(res.data)
          setAudienceListForDropDown(res.data);
        }
      },
      fail: err => {
      }
    })
  }
  const getTimeZoneList = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (props.mode === "add" || props.data.timezoneID === 0) {
      filterTimezone = data.timeZone.filter((item) => item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId)[0];
    } else {
      filterTimezone = data.timeZone.filter((item) => item.timezoneId === props.data.timezoneID)[0];
    }
    setTimeZoneData(data.timeZone);
    setTimeZone(filterTimezone)
    props.data.timezoneID = filterTimezone?.timezoneId;
  }

  const getAdhocAttributeHeaders = (segmentId) => {
    if (segmentId !== undefined) {
      let params = {
        "segmentationListID": segmentId
      }
      connectServer({
        path: GET_ADHOC_ATTRIBUTES,
        params,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: res => {
          if (res.status) {
            let tempFullPersonal = [];
            res?.data?.full?.forEach((item, index) => {
              tempFullPersonal.push("[[" + item + "]]");
            })
            setSubjectLinePersonalizeData(tempFullPersonal);
          }
        },
        fail: err => {
        }
      })
    }
  }

  const getRemainingAudienceList = () => {
    var temp = [...audienceListFromServer]
    var listTemp = []
    temp.forEach(obj => {
      let temp2 = audienceList.filter(
        audience => audience.segmentationListID === obj.segmentationListID
      )
      if (temp2.length === 0) {
        listTemp.push(obj)
      }
    })
    return listTemp
  }

  const filterChange = (event) => {
    let temp = getRemainingAudienceList();
    setAudienceListForDropDown(filterBy(temp.slice(), event.filter));
  };

  const validation = (type) => {
    let isValidate = true;
    if (senderName === undefined || senderName === null) {
      setErrSenderNameMsg(SELECT_SENDER_NAME);
      isValidate = false;
    }
    if (audienceList.length === 0) {
      setErrAudienceMsg(AUDIENCE);
      isValidate = false;
    }
    if (language === undefined || language === null) {
      setErrTemplateLanguageMsg(TEMPLATE_LANGUAGE);
      isValidate = false;
    }
    if (templateName === undefined || templateName === null) {
      setErrTemplateNameMsg(TEMPLATE_NAME);
      isValidate = false;
    }
    if (isEmpty(previewCampaignText)) {
      setErrCampaignTextMsg(CAMPAIGN_TEXT);
      isValidate = false;
    } else {
      const regex = /{{(.*?)}}/g;
      const result = previewCampaignText?.replace(regex, '');
      let braceContents = previewCampaignText?.match(regex) || [];
      if (braceContents?.length !== editableBraceCount) {
        setErrCampaignTextMsg("Template required editable curly braces {{ }}");
        isValidate = false;
      } else if (braceContents?.filter(item => item.length < 5).length > 0) {
        setErrCampaignTextMsg("Template required editable curly braces {{ }} with values");
        isValidate = false;
      }
      if (result?.replaceAll("\r", "") !== templateContent?.replaceAll("\r", "")) {
        setErrCampaignTextMsg("Edit the text present inside the curly braces {{ }} only");
        isValidate = false;
      }
      if (props.data.waContent?.includes("https") && !isShortURLCreated) {
        setErrCampaignTextMsg("Shorten URL");
        isValidate = false;
      }
      if (props.data.templateType === 2 && (isEmpty(waImagePath) && isEmpty(waMediaURL))) {
        setErrCampaignTextMsg("Template requires image");
        isValidate = false;
      }
      if (props.data.templateType === 3 && (isEmpty(waImagePath) && isEmpty(waMediaURL))) {
        setErrCampaignTextMsg("Template requires PDF");
        isValidate = false;
      }
      if (props.data.templateType === 4 && isEmpty(waMediaURL)) {
        setErrCampaignTextMsg("Template requires video");
        isValidate = false;
      }
    }
    if (isEmpty(mobileNo)) {
      setErrSendTestMsg(SEND_TEST_MSG);
      isValidate = false;
    }
    if (type !== 'draft') {
      if (isEmpty(schedule)) {
        setErrScheduleDateMsg(SCHEDULE_DATE);
        isValidate = false;
      } else {
        let currentTime = getCurrentTimeWithTimeZone(new Date(), timeZone.timezoneId, 15)
        if (new Date(schedule) < new Date(currentTime)) {
          setErrScheduleDateMsg("Select a time greater from (" + currentTime.toLocaleString().trim() + ")")
          isValidate = false;
        }
      }
      if (timeZone.timezoneId === 0) {
        setErrTimeZoneMsg(SELECT_TIMEZONE);
        isValidate = false;
      }
    }
    return isValidate;
  }
  return (
    <div className="marginT20 marginL20-del">
      <Row className={`custom-column ${editClickOff ? "click-off" : ""}`}>
        <Col md={3}>
          <label className="label-row">Sender name</label>
        </Col>
        <Col md={6}>
          <div className="position-relative">
            {errSenderNameMsg && (
              <div className="validation-message">
                {errSenderNameMsg}
              </div>)}
            <RSDropdownList
              className="required"
              data={senderNameList}
              value={senderName || {
                "clientWASenderID": 0,
                "senderID": 0,
                "senderName": "-- Select sender name --"
              }}
              defaultItem={{
                "clientWASenderID": 0,
                "senderID": 0,
                "senderName": "-- Select sender name --"
              }}
              textField='senderName'
              dataItemKey='clientWASenderID'
              onChange={(event) => {
                let errMsg = event.target.value.clientWASenderID !== 0 ? null : SELECT_SENDER_NAME;
                props.data.clientWASettingId = event.target.value.clientWASenderID;
                getLanguageDataFromServer(event.target.value.clientWASenderID)
                setErrSenderNameMsg(errMsg);
                setSenderName(event.target.value);
              }}
            />
          </div>
          {/* <div className="input-outer-set">
            <RSPTooltip text="New sender name" position="top">
              <i className="icon-settings-small icons-md blue" onClick={() => {
                history.push({
                  pathname: '/preferences/communication-settings',
                  state: { hIndex: 1 }
                })
              }} />
            </RSPTooltip>
          </div> */}
        </Col>
      </Row>
      <Row className={`custom-column marginB20 ${editClickOff ? "click-off" : ""}`}>
        <Col md={3}>
          <label className="label-row">Audience</label>
        </Col>
        <Col md={6}>
          <div className="position-relative">
            {errAudienceMsg && (
              <div className="validation-message">
                {errAudienceMsg}
              </div>)}
            <div className="chips-input-container">
              <div className="chips-input-box required">
                <MultiSelect
                  placeholder="-- Select the list --"
                  // data={getRemainingAudienceList()}
                  data={audienceListForDropDown}
                  textField='recipientMobileDisplayName'
                  dataItemKey='segmentationListID'
                  filterable={true}
                  onFilterChange={filterChange}
                  onChange={(event) => {
                    let errMsg = event.target.value.length === 0 ? AUDIENCE : null;
                    errMsg = event.target.value.length > 1 ? "More than one list is not allowed" : null;
                    if (event.target.value.length <= 1) {
                      // let paymentStatus = event.target.value[0]?.isPaid ? null : "Payment for the list is pending";
                      let paymentStatus = event.target.value.length === 0 ? null : event.target.value[0]?.isPaid ? null : "Payment for the list is pending";
                      setPaymentStatusForList(paymentStatus)
                      props.data.recipientBunchIDs = event.target.value;
                      setErrAudienceMsg(errMsg);
                      setAudienceList(event.target.value);
                      if (event.target.value.length > 0) {
                        getAdhocAttributeHeaders(event.target.value[0].segmentationListID);
                      } else {
                        setSubjectLinePersonalizeData([]);
                      }
                    } else {
                      setErrAudienceMsg(errMsg);
                    }
                  }}
                  value={audienceList} />
              </div>
            </div>
            <small>{paymentStatusForList !== null ? paymentStatusForList : ""}</small>
          </div>
          <div className="input-outer-set">
            <RSPTooltip text="Users can only select up to one audience list." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp" />
            </RSPTooltip>
          </div>
        </Col>
      </Row>
      {(!!senderName && senderName.clientWASenderID !== 0) &&
        <Row className={`custom-column mt30 ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Template language</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errTemplateLanguageMsg && (
                <div className="validation-message">
                  {errTemplateLanguageMsg}
                </div>)}
              <RSDropdownList
                className="required"
                data={languageData}
                value={language || { templateName: "", templateLanguageCode: "-- Select template language --" }}
                defaultItem={{ templateName: "", templateLanguageCode: "-- Select template language --" }}
                textField='templateLanguageCode'
                dataItemKey='templateName'
                onChange={(event) => {
                  let errMsg = event.target.value?.templateName !== "" ? null : TEMPLATE_LANGUAGE;
                  props.data.languageCode = event.target.value.templateLanguageCode;
                  getWhatsAppTemplates(senderName.clientWASenderID, event.target.value.templateLanguageCode);
                  setErrTemplateLanguageMsg(errMsg);
                  setLangage(event.target.value);
                }}
              />
            </div>
            <div className="input-outer-set">
              <RSPTooltip text="Select your preferred language for content." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp" />
              </RSPTooltip>
            </div>
          </Col>
        </Row>
      }
      {(!!language && language?.templateName !== "") &&
        <Row className={`custom-column ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Template name</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errTemplateNameMsg && (
                <div className="validation-message">
                  {errTemplateNameMsg}
                </div>)}
              <RSDropdownList
                className="required"
                data={whatsAppTemplates}
                value={templateName || { waTemplateId: 0, templateName: "-- Select template --" }}
                defaultItem={{ waTemplateId: 0, templateName: "-- Select template --" }}
                textField='templateName'
                dataItemKey='waTemplateId'
                onChange={(event) => {
                  let errMsg = event.target.value.waTemplateId !== 0 ? null : TEMPLATE_NAME;
                  setWAImagePath("")
                  setWAMediaURL("")
                  setWAMediaURLType("");
                  setIsShortURLCreated(false);
                  props.data.waImagePath = "";
                  props.data.waMediaURL = "";
                  props.data.waMediaURLType = "";
                  if (errMsg === null) {
                    props.data.waTemplateID = event.target.value.waTemplateId;
                    props.data.templateContent = event.target.value.templateContent;
                    props.data.waContent = event.target.value.templateContent;
                    props.data.waPreviewContent = event.target.value.templateContent;
                    props.data.templateName = event.target.value.templateName;
                    props.data.templateType = event.target.value.templateType;
                    let textCount = event.target.value.templateContent.length > 0 ? event.target.value.templateContent.length.toString() + "/" : "";
                    // let smsCount = Math.floor(event.target.value.templateContent.length / 140) + 1;
                    let smsCount = 1;
                    setBodyTextCount(textCount);
                    setSMSCount(smsCount.toString());
                    const regex = /{{(.*?)}}/g;
                    const result = event.target.value.templateContent.replace(regex, '');
                    const count = (event.target.value.templateContent.match(regex) || []).length;
                    setEditableBraceCount(count);
                    setTemplateContent(result)
                    setCampaignText(event.target.value.templateContent);
                    setPreviewCampaignText(event.target.value.templateContent);
                    setErrTemplateNameMsg(errMsg);
                    setTemplateName(event.target.value);
                    setErrCampaignTextMsg(null);
                  } else {
                    props.data.waTemplateID = event.target.value.waTemplateId;
                    props.data.templateContent = "";
                    props.data.waContent = "";
                    props.data.waPreviewContent = "";
                    props.data.templateName = "";
                    props.data.templateType = 0;
                    // let textCount = event.target.value.templateContent.length > 0 ? event.target.value.templateContent.length.toString() + "/" : "";
                    // let smsCount = Math.floor(event.target.value.templateContent.length / 140) + 1;
                    // setBodyTextCount(textCount);
                    // setSMSCount(smsCount.toString());
                    setCampaignText("");
                    setPreviewCampaignText("");
                    setTemplateContent("");
                    setEditableBraceCount(0);
                    setErrTemplateNameMsg(errMsg);
                    setTemplateName(event.target.value);
                    setErrCampaignTextMsg(null);
                  }
                }}
              />
            </div>
            <div className="input-outer-set">
              <RSPTooltip text="Each template has a pre-defined content that will be loaded in the Text box." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp" />
              </RSPTooltip>
            </div>
          </Col>
        </Row>
      }
      {(!!templateName && templateName?.waTemplateId !== 0) &&
        <Row className={`custom-column mb20 mt10 ${editClickOff ? "click-off" : ""}`}>
          <Col md={3}>
            <label className="label-row">Communication text</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errCampaignText && (
                <div className="validation-message yaxis-7">
                  {errCampaignText}
                </div>)}
              <RESEditor
                personalizeTapData={subjectLinePersonalizeData}
                mobilepush={false}
                isCampaignText={false}
                isWhatsappCampaignText={true}
                bodyContent={props.data.waPreviewContent}
                waTemplateType={props.data.templateType}
                onChangePreviewOpen={(value) => {
                  let isValidate = validation("draft");
                  if (isValidate) {
                    setIsPreviewOpen(value);
                  }
                }}
                callBack={(key, item) => {
                  if (key === "bodytext") {
                    let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                    if (errMsg === null && item.length <= 1000) {
                      let textCount = item.length > 0 ? numberFormatter(item.length).toString() + "/" : "";
                      // let smsCount = Math.floor(item.length / 140) + 1;
                      let smsCount = 1;
                      setBodyTextCount(textCount);
                      setSMSCount(smsCount.toString());
                      props.data.waContent = item;
                      props.data.waPreviewContent = item;
                      setErrCampaignTextMsg(errMsg);
                      setCampaignText(item);
                      setPreviewCampaignText(item);
                      if (item.length > 0 && smartUrls.length > 0 && shortenLink.length > 0) {
                        let tempVal = item.replaceAll(smartUrls[0], shortenLink)
                        setPreviewCampaignText(tempVal);
                        props.data.waContent = tempVal;
                      }
                    } else {
                      setErrCampaignTextMsg(item.length === 0 ? errMsg : "Max. 1,000 characters");
                      if (item.length === 0) {
                        setCampaignText(item);
                        setPreviewCampaignText(item);
                        props.data.waContent = item;
                        props.data.waPreviewContent = item;
                      }
                    }
                  } else if (key === "onBlur") {
                    props.data.bodyPosition = item.target.selectionStart;
                  } else if (key === "imagePath") {
                    setWAImagePath(item);
                    setErrCampaignTextMsg(null);
                    setWAMediaURL("");
                    setWAMediaURLType("");
                    props.data.waMediaURL = "";
                    props.data.waMediaURLType = ""
                    props.data.waImagePath = item;
                  } else if (key === "PDFPath") {
                    setWAImagePath("");
                    setErrCampaignTextMsg(null);
                    setWAMediaURL(item);
                    setWAMediaURLType("");
                    props.data.waMediaURL = item;
                    props.data.waMediaURLType = "PDF"
                    props.data.waImagePath = "";
                  }
                }}
                onMediaCallback={(mediaType, value) => {
                  if (mediaType === "PNG" || mediaType === "JPG") {
                    setWAMediaURL(value);
                    setWAMediaURLType(mediaType);
                    setErrCampaignTextMsg(null);
                    setWAImagePath("");
                    props.data.waImagePath = "";
                    props.data.waMediaURL = value;
                    props.data.waMediaURLType = mediaType
                  } else if (mediaType === "PDF") {
                    setWAMediaURL(value);
                    setWAMediaURLType(mediaType);
                    setErrCampaignTextMsg(null);
                    setWAImagePath("");
                    props.data.waImagePath = "";
                    props.data.waMediaURL = value;
                    props.data.waMediaURLType = mediaType
                  } else if (mediaType === "MP4") {
                    setWAMediaURL(value);
                    setWAMediaURLType(mediaType);
                    setErrCampaignTextMsg(null);
                    setWAImagePath("");
                    props.data.waImagePath = "";
                    props.data.waMediaURL = value;
                    props.data.waMediaURLType = mediaType
                  } else {
                    setWAMediaURL("");
                    setWAMediaURLType("");
                    setErrCampaignTextMsg(null);
                    setWAImagePath("");
                    props.data.waImagePath = "";
                    props.data.waMediaURL = "";
                    props.data.waMediaURLType = ""
                  }
                }} />
              <small class="float-end">{bodyTextCount + "1,000"} <span className="ml10">| ({smsCount} WhatsApp message / Audience)</span></small>
            </div>
            <div className="position-absolute right-15 top10 z-1">
              <RSPTooltip text="Note: Edit the text present inside the curly braces {{ }} only." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
              </RSPTooltip>
            </div>
            <RSPTooltip text="Shortern URL" position="top">
              {/* <i className={`icons-sm icon-link-down-medium icons-md blue float-end cursor-pointer */}
              <i className={`pr4 position-relative top-3 icon-sd-link-medium icons-md blue float-end cursor-pointer ${props.data.waPreviewContent.includes("https") ? "" : "click-off"}`} onClick={() => {
                const regex = /{{(.*?)}}/g;
                const braceContents = props.data.waPreviewContent.match(regex)
                var urlRegex = /^(https?):\/\/(?:([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(?::\d+)?|(?:\[[a-fA-F0-9:]+\]))(?:(\/(?:[^\s/?#]+\/)*[^\s?#]*(?:\?(?:[^#\s]*))*))?$/;
                let smartURLs = []
                braceContents.forEach((item, index) => {
                  item = item.replaceAll("{", "").replaceAll("}", "");
                  if (urlRegex.test(item)) {
                    smartURLs.push(item)
                  }
                })
                let errMsg = smartURLs.length > 0 ? smartURLs.length > 2 ? "Accept only two URL in WhatsApp content" : null : "Invalid URL";
                if (errMsg === null) {
                  errMsg = smartURLs[0] !== smartURLs[1] ? null : "Give different URLs in WhatsApp content";
                  setErrCampaignTextMsg(errMsg);
                  setSmartUrls(smartURLs);
                  if (errMsg === null) {
                    let smartURLParams = [];
                    smartURLs.forEach((item, index) => {
                      let params = {
                        "campaignId": props.campaignId,
                        "goalNo": index + 1,
                        "blastType": "",
                        "smartCode": "",
                        // "domainUrl": DOMAIN_URL,
                        "smartAdaptive": "",
                        "smartUrl": item,
                        "channelId": 21,
                        "blastNo": 1,
                        "actionId": 0,
                        "parentChannelDetailId": 0
                      }
                      smartURLParams.push(params)
                    })
                    let params = {
                      "smartUrlsList": smartURLParams
                    }
                    connectServer({
                      path: GET_SHORTEN_URL,
                      params,
                      loading: context.globalStateData.setIsLoadingData,
                      sessionOut: context.globalStateData.setSessionOutData,
                      context: context,
                      ok: res => {
                        if (res.status) {
                          // let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.mobileSMSData.content[0].bodyPosition);
                          let shortenLinks = [];
                          let tempVal = props.data.waPreviewContent;
                          res.data.forEach((item, index) => {
                            if (index < 2) {
                              tempVal = tempVal.replaceAll(smartURLs[index], item.smartCodePath)
                              shortenLinks.push(item.smartCodePath)
                            }
                          })
                          props.data.waContent = tempVal;
                          props.data.isShortenURLEnabled = res.status;
                          setPreviewCampaignText(tempVal)
                          setShortenLink(shortenLinks)
                          setIsShortURLCreated(res.status)
                          // setCampaignText(tempVal);
                        } else {
                          props.data.isShortenURLEnabled = res.status;
                          setIsShortURLCreated(res.status)
                          setErrCampaignTextMsg(res.message)
                        }
                      },
                      fail: err => {
                      }
                    })
                  }
                } else {
                  setErrCampaignTextMsg(errMsg);
                  setSmartUrls(smartURLs || []);
                }

              }
              }></i>
            </RSPTooltip>
            <div className="position-absolute right-15 z-1">
              <RSPTooltip text="The shortened URL will be shown in the WhatsApp preview section." position="top">
                <i className="icon-sd-question-mark-mini question-unfill icons-sm float-end blue cp"></i>
              </RSPTooltip>
            </div>
          </Col>
          <Col md={3} className="last-column-view mr-wa">
            {
              ((props.data.templateType === 2 && (waImagePath !== "" || waMediaURL !== "")) || (props.data.templateType === 3 && (waMediaURL !== "" || waImagePath !== "")) || (props.data.templateType === 4 && waMediaURL !== "")) &&
              <div className="instant-preview">
                <WhatsAppPreviewContent
                  imageSrc={props.data.templateType === 2 ? waImagePath !== "" ? waImagePath : waMediaURL : props.data.templateType === 3 ? pdfImage : videoImage}
                  onChangeIsRemove={(value) => {
                    setIsShowRemoveModal(value)
                  }} />
              </div>
            }
          </Col>
        </Row>
      }
      {/* {isRequestForApproval
        && <>
          <Row className="custom-column schedule-even marginT30 paddingB10">
            <Col md={3}>
              <label className="label-row">Schedule</label>
            </Col>
          </Row>
          <Row className="custom-column schedule-even pt0">
            <Col md={3}>
              <label className="label-row">Enter the date and time</label>
            </Col>
            <Col md={6} className="pl20">
              <div className="position-relative">
                <div className="rsptimer-picker">
                  <DateTimePicker
                    className="required"
                    value={defaultValue}
                    // show={true}
                    popup={CustomPopup}
                    onChange={(event) => {
                      setSchedule(event.target.value);
                    }}
                  />
                </div>
              </div>
              <Row className="custom-column marginT5">
                <Col md="8">
                  <div className="d-flex align-items-center marginB30">
                    <RSCheckbox
                      className="marginB0"
                      lbl="Use send time recommendation"
                      checked={isSendRecommendation}
                      cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                    />
                    <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                  </div>
                </Col>
                {isEditTimeZone === false ?
                  <Col>
                    <div className="float-end">
                      <label>EST GMT 5.30</label>
                      <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                        setIsEditTimeZone(!isEditTimeZone);
                      }} />
                    </div>
                  </Col> : null}
              </Row>
            </Col>
          </Row>
        </>} */}
      <div className={`schedule-even marginT0 ${editClickOff ? "click-off" : ""}`}>
        <Row className={`custom-column paddingB10`}>
          <Col md={3}>
            <label className="label-row">Schedule</label>
          </Col>
          <Col md={6}>
            <div className="position-relative">
              {errScheduleDateMsg && (
                <div className='validation-message'>
                  {errScheduleDateMsg}
                </div>
              )}
              <div className="rsptimer-picker">
                <DateTimePicker
                  className="required"
                  placeholder='Select schedule date and time'
                  required={true}
                  value={schedule !== "" ? new Date(schedule) : ""}
                  // show={true}
                  // min={addMinutes(new Date(startDate), 15)}
                  min={getStartDateForSchedule()}
                  max={endDate}
                  format={getUserData()?.timeFormat === "24 hours" ? "dd MMM, yyyy HH:mm " : "dd MMM, yyyy hh:mm a"}
                  popup={CustomPopup}
                  // defaultValue={new Date()}
                  onChange={(event) => {
                    if (event.target.value !== null) {
                      setSchedule(event.target.value);
                      let localBlastTime = moment(event.target.value).format(getUserData()?.timeFormat === "24 hours" ? "DD MMM, yyyy HH:mm" : "DD MMM, yyyy hh:mm a")
                      props.data.localBlastDateTime = localBlastTime;
                      let errMsg = props.data.localBlastDateTime !== "" ? null : SCHEDULE_DATE
                      setErrScheduleDateMsg(errMsg)
                    } else {
                      props.data.localBlastDateTime = "";
                      setSchedule("");
                      setErrScheduleDateMsg(SCHEDULE_DATE)
                    }
                  }}
                />
                <div className={`input-outer-set ${(schedule !== "") ? "" : "click-off"} `}>
                  <RSPTooltip text="Reset schedule" position="top">
                    <i
                      className="icon-sd-refresh-medium icons-md blue ml10"
                      onClick={() => {
                        setSchedule("");
                        props.data.localBlastDateTime = "";
                      }}
                    />
                  </RSPTooltip>
                </div>
              </div>
            </div>
            <Row className="marginT5">
              {/* <Col md="8">
                    <div className="d-flex align-items-center marginB30">
                      <RSCheckbox
                        className="marginB0"
                        lbl="Use send time recommendation"
                        checked={isSendRecommendation}
                        cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                      />
                      <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                    </div>
                  </Col> */}
              {isEditTimeZone === false ?
                <Col>
                  <div className="float-end">
                    <label>{timeZone?.gmtOffset}</label>
                    <RSPTooltip text="Edit" position="top">
                      <i className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer" onClick={() => {
                        setIsEditTimeZone(!isEditTimeZone);
                      }} />
                    </RSPTooltip>
                  </div>
                </Col> : null}
            </Row>
          </Col>
        </Row>
        {
          isEditTimeZone
            ? <Row className={`custom-column paddingT20 ${editClickOff ? "click-off" : ""}`}>
              <Col md={3}>
                <label className="label-row">Select time zone</label>
              </Col>
              <Col md={6}>
                <div className="position-relative">
                  {errTimeZoneMsg && (
                    <div className='validation-message'>
                      {errTimeZoneMsg}
                    </div>
                  )}
                  <RSDropdownList
                    className="marginB0 required"
                    data={timeZoneData}
                    value={timeZone}
                    required={true}
                    defaultItem={{
                      "timezoneId": 0,
                      "timezoneName": "-- Select time zone --",
                    }}
                    textField="timezoneName"
                    dataItemKey="timezoneId"
                    onChange={(e) => {
                      let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                      setErrTimeZoneMsg(errMsg);
                      setTimeZone(e.target.value);
                      props.data.timezoneID = e.target.value.timezoneId;
                      getStartDateForSchedule()
                    }} />
                </div>
                <Row className="marginT5">
                  <Col md="8">
                    <div className="d-flex align-items-center">
                      <RSCheckbox
                        className="marginT0 marginB15 click-off"
                        lbl="Daylight saving"
                        checked={isDayLightSavings}
                        cb={(value) => { setIsDayLightSavings(!isDayLightSavings) }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            : null
        }
      </div>
      <Row className={`custom-column mt20 ${editClickOff ? "click-off" : ""}`}>
        <Col md={3}>
          <label className="label-row">Send test message to</label>
        </Col>
        <Col md={6} className="posr zIndex1">
          <div className="position-relative d-flex">
            {errSendTestMsg && (
              <div className="validation-message mobile-valid">
                {errSendTestMsg}
              </div>)}
            <RSPhoneInput
              className="phone-input-short not-required"
              value={countryCodeMobile + mobileNo}
              country={countryCode}
              inputStyle={{ display: 'none' }}
              onMount={(phone, country) => {
                handleCountryPhoneNumberLength(country)
              }}
              onChange={(phone, country) => {
                handleCountryPhoneNumberLength(country)
                let errMsg = phone !== "" ? null : SEND_TEST_MSG;
                props.data.countryCodeMobile = country.dialCode;
                setErrSendTestMsg(errMsg);
                // setMobileNo(phone);
                // setMobileNo(`+${country.dialCode}`);
                if (countryCodeMobile !== country.dialCode) {
                  setMobileNo("");
                }
                setCountryCode(country.countryCode);
                setErrMbileMsg(null)
                setCountryCodeMobile(country.dialCode);
              }}
            // onChange={(phone, country) => {
            //   let errMsg = phone !== "" ? null : SEND_TEST_MSG;
            //   setErrSendTestMsg(errMsg);
            //   setMobileNo(phone);
            //   setCountryCode(country.dialCode);
            //   setCountryCodeMobile(country.dialCode);
            // }} 
            />
            <RSInput2
              ph="+91 7845984022"
              type="tel"
              className=""
              keyboardType="telePhoneNumber"
              dialCode={countryCodeMobile}
              phoneLength={countryPhoneNumberLength}
              val={`+${countryCodeMobile} ${mobileNo}`}
              cb={(phone, maxMobile) => {
                setMobileNo(phone);
                // setMobileNoWithoutDaialCode(phone);
                let isValidNumber = handleMobileNumberValidation(phone, maxMobile);
                const { err, msg } = isValidNumber;
                setErrMbileMsg(msg);

              }}
              errorMessage={errMbileMsg}
            />
          </div>
          <div className="input-outer-set">
            <RSPTooltip text="Send a test message to up to five mobile numbers." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp" />
            </RSPTooltip>
          </div>
          {/* <div className="d-flex align-items-center mt-20 mb20">
            <RSCheckbox
              className="marginB0"
              lbl="Request approval"
              checked={isRequestForApproval}
              cb={(status) => {
                setIsRequestApproval(status);
              }}
            />
            <RSPTooltip text="Get the list approved by the concerned person." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
            </RSPTooltip>
          </div> */}
          {/* {isRequestForApproval &&
            <RSDropdownList
              className="marginB0"
              data={approverList}
              value={approverName !== "" ? approverName : "-- Mail list --"}
              onChange={(event) => {
                setApproverName(event.target.value);
              }}
            />
          } */}
        </Col>
        <Col md={3}>
          <RSPrimaryBtn className={`ml15 ${errMbileMsg === null ? "" : "click-off"}`} onClick={() => {
            let enable = validation("draft");
            if (enable === undefined || enable) {
              props.data.testSmsMobileNo = mobileNo;
              props.data.countryCodeMobile = countryCodeMobile;
              let params = createMobileWhatsAppCampaign("S", props.campaignId, props.data, true, "", "")
              connectServer({
                path: waChannelDetailID !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: res => {
                  if (res.status) {
                    setIsOpenWASendSuccessModal(res.status);
                    setWaChannelDetailID(res.data.waChannelDetailID)
                    props.data.waChannelDetailID = res.data.waChannelDetailID;
                  }
                },
                fail: err => {
                }
              })
            }
          }}>Send</RSPrimaryBtn>
        </Col>
      </Row>
      <div className="btn-container d-flex justify-content-end mt40">
        <RSSecondaryBtn paddingR="0px" onClick={() => {
          props.history.push({ pathname: `/communication` })
        }}>Cancel</RSSecondaryBtn>
        <RSSecondaryBtn className={`mx7 blue ${(editClickOff || (props.mode === "edit" && props.data.channelstatusId === 7)) ? "click-off" : ""}`} onClick={() => {
          let enable = validation("draft");
          if (enable === undefined || enable) {
            props.data.testSmsMobileNo = mobileNo;
            props.data.countryCodeMobile = countryCodeMobile;
            props.data.localBlastDateTime = ""
            let params = createMobileWhatsAppCampaign("S", props.campaignId, props.data, false, "", "")
            connectServer({
              path: waChannelDetailID !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
              params,
              loading: context.globalStateData.setIsLoadingData,
              sessionOut: context.globalStateData.setSessionOutData,
              context: context,
              ok: res => {
                if (res.status) {
                  props.history.push({ pathname: `/communication` })
                }
              },
              fail: err => {
              }
            })

          }
        }}>Save as draft</RSSecondaryBtn>
        <RSPrimaryBtn onClick={() => {
          if (editClickOff) {
            props.moveNext(1)
          } else {
            let enable = validation();
            if (enable === undefined || enable) {
              props.data.testSmsMobileNo = mobileNo;
              props.data.countryCodeMobile = countryCodeMobile;
              let params = createMobileWhatsAppCampaign("S", props.campaignId, props.data, false, "", "")
              connectServer({
                path: waChannelDetailID !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                params,
                loading: context.globalStateData.setIsLoadingData,
                sessionOut: context.globalStateData.setSessionOutData,
                context: context,
                ok: res => {
                  if (res.status) {
                    props.moveNext(1)
                  }
                },
                fail: err => {
                }
              })
            }
          }
        }}>Next</RSPrimaryBtn>
      </div>
      {
        isOpenWASendSuccessModal &&
        <SuccessModal isOkButton={false} isOpen={isOpenWASendSuccessModal} title={"Success"} subTitle={"Test communication will be sent shortly."} buttonClicked={(value) => {
          setIsOpenWASendSuccessModal(false)
        }} />
      }
      {isPreviewOpen &&
        <WhatsAppLivePreview
          isOpen={isPreviewOpen}
          textContent={props.data.waContent}
          imagePath={props.data.templateType === 2 ? waImagePath !== "" ? waImagePath : waMediaURL : props.data.templateType === 3 ? (waMediaURL !== "" || waImagePath !== "") ? pdfImage : "" : waMediaURL !== "" ? videoImage : ""}
          mobileNumber={mobileNo}
          campaignId={props.campaignId}
          waChannelId={waChannelDetailID}
          mobileWhatsAppData={props.data}
          onValidateSearch={(status) => {
            let isValidate = validation("draft");
            setIsSearchValidate(isValidate);
          }}
          isSearchValidate={isSearchValidate}
          onChangeIsSearchValidate={() => {
            setIsSearchValidate(false);
          }}
          onChangeWAChannelId={(value) => {
            props.data.waChannelDetailID = value;
            setWaChannelDetailID(value)
          }}
          onChangeIsOpen={(value) => {
            if (value) {
              setIsOpenLiveSuccessModal(true)
              setIsPreviewOpen(false);
            } else {
              setIsPreviewOpen(false);
            }
          }} />
      }
      {
        isShowRemoveModal &&
        <RSConfirmAlert
          title="Are you sure you want to delete?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              setWAImagePath("");
              setWAMediaURL("");
              setWAMediaURLType("");
            }
            setIsShowRemoveModal(false)
          }}
        />
      }
      {isOpenLiveSuccessModal &&
        <SuccessModal isOkButton={false} isOpen={isOpenLiveSuccessModal} title={"Success"} subTitle={"Live communication will be sent shortly."} buttonClicked={(value) => {
          setIsOpenLiveSuccessModal(false)
        }} />
      }
    </div>
  )
}
export default withRouter(MobileWhatsappCreation);