import { Tooltip } from '@progress/kendo-react-tooltip';
import { BDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'
import { BootstrapDropdownMegamenu } from './SearchFormComponent'
import React, { useContext, useState } from 'react'
import { RSInput2 } from 'Components/RSInputs';
import ErrorModal from './components/ErrorModal';
import { validateEmail } from 'Helper/Utils/Utils';
import { GET_LIST_CREATED_USERS, GET_SEGMENTATION_LIST_NAME } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { RSPTooltip } from 'Components/Tooltip';

export const SearchView1 = props => {
    const [searchBar, setSearchBar] = useState();
    return (
        <div className={`search-view-container ${props.cls ? props.cls : ''} ${searchBar ? "open" : ""}`}>
            <div className="search-icon-box">
                <Tooltip anchorElement="target" position="top">
                    <div className="search-input">
                        <input placeholder="Search" type="text" className="" />
                    </div>
                </Tooltip>
                <div className="search-icon" onClick={() => setSearchBar(!searchBar)}>
                    <i className="icon-sd-zoom-medium icons-sm white"></i>
                </div>
            </div>
        </div>
    )
}
// 8335
export const SearchView = props => {
    const [searchBar, setSearchBar] = useState();
    return (
        <div className={`search-default ${props.open ? 'open' : ''}`}>
            <div className="s-input">
                <RSInput2
                    name="search"
                    ph="Search"
                />
            </div>
            <div className="s-icon">
                <i className="icon-sd-zoom-medium icons-sm white"></i>
            </div>
        </div>
    )
}

export const SearchViewAdvanced1 = props => {

    const [searchBar, setSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState("Email ID");
    const [searchValueId, setSearchValueId] = useState(1);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputErrMsg, setInputErrMsg] = useState(null)

    const validation = () => {
        if (searchValue === 'Email ID') {
            setSearchValueId(1)
            if (validateEmail(inputValue)) {
                setInputErrMsg(null)
                return true;
            } else {
                setInputErrMsg('Enter correct Email ID')
                return false;
            }
        } else if (searchValue === 'Mobile no') {
            setSearchValueId(2)
            if (inputValue !== '') {
                setInputErrMsg(null)
                return true;
            } else {
                setInputErrMsg('Enter correct Mobile no')
                return false;
            }
        }
    }

    return (
        <div onClick={() => {
            props.closeDateRangePicker && props.closeDateRangePicker(false)
        }}
            className={`search-container search-advanced ${props.cls ? props.cls : ''} ${searchBar ? "open" : ""}`}>
            {inputErrMsg && <div className='validation-message modal-prev-valid-search'>{inputErrMsg}</div>}
            <div className="search-icon-box">
                <Tooltip anchorElement="target" position="top">
                    <div className="search-input">
                        <BDropdown
                            data={props.dropdownData}
                            onSelect={(item) => {
                                setSearchValue(item)
                                if (item === "Mobile no") {
                                    setSearchValueId(2);
                                } else {
                                    setSearchValueId(1);
                                }
                                setInputErrMsg(null)
                                setInputValue("")
                            }}
                            defaultItem={""}
                            aln={props.align || "start"}
                            className=""
                        />
                        <div className="expand-menu-icon"></div>
                        <input
                            onChange={(e) => {
                                let regex = /^\d+$/;
                                if (searchValue === 'Mobile no') {
                                    if (e.target.value !== "" && regex.test(e.target.value)) {
                                        setInputValue(e.target.value)
                                        let errMsg = e.target.value !== '' ? null : `Enter valid ${searchValue}`
                                        setInputErrMsg(errMsg)
                                    } else {
                                        if (e.target.value === "") {
                                            setInputValue(e.target.value)
                                        }
                                        let errMsg = e.target.value !== "" ? "Accept only numbers" : `Enter valid ${searchValue}`
                                        setInputErrMsg(errMsg)
                                    }
                                } else {
                                    setInputValue(e.target.value)
                                    let errMsg = e.target.value !== '' ? null : `Enter valid ${searchValue}`
                                    setInputErrMsg(errMsg)
                                }

                            }}
                            placeholder={searchValue === "" ? 'Enter Email ID' : `${searchValue}`}
                            type={searchValue === 'Mobile no' ? 'text' : 'text'}
                            className=""
                            value={inputValue}
                        />
                        <RSPTooltip text="Search" position="top" className={props.modalTooltipCls ? props.modalTooltipCls : ''}>
                            <div className="input-search-icon" onClick={() => {
                                if (inputErrMsg === null && validation()) {
                                    props.getSearchData({
                                        id: searchValueId,
                                        value: inputValue
                                    })
                                    props.onValidateSearch && props.onValidateSearch(true, {
                                        id: searchValueId,
                                        value: inputValue
                                    })
                                    setIsOpenErrorModal(!isOpenErrorModal)
                                }
                            }}>

                                <i className="icon-sd-zoom-medium icons-sm"></i>
                            </div>
                        </RSPTooltip>
                    </div>
                    <div className="search-icon right0 top0 position-absolute" onClick={() => {
                        setSearchBar(!searchBar)
                        setInputErrMsg(null)
                        setInputValue("")
                    }}>
                        <RSPTooltip text={searchBar ? "Close" : "Search"} position="top" className={props.modalTooltipCls ? props.modalTooltipCls : ''}>
                            <i className={`${searchBar ? 'icons-sm icon-sd-close' : 'icons-svm icon-sd-zoom'}-mini white d-flex align-items-center height31`}></i>
                        </RSPTooltip>
                    </div>
                </Tooltip>
            </div>
            {(!props.isSearchValidate && isOpenErrorModal) &&
                <ErrorModal
                    isOpen={isOpenErrorModal}
                    onChangeIsOpen={(value) => {
                        setIsOpenErrorModal(value);
                    }}
                />
            }
        </div>
    )
}

export const SearchViewAdvanced = props => {

    const context = useContext(authContext)
    const [searchBar, setSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [valueForSearch, setValueForSearch] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [categoryItem, setCategoryItem] = useState(props.dropdownData[0])
    const [recipientsBunchName, setRecipientsBunchName] = useState("")
    const [campaignName, setCampaignName] = useState("")

    const getSegmentationListName = (data) => {
        setFilteredItems([])
        let params = { "name": data }
        connectServer({
            path: GET_SEGMENTATION_LIST_NAME,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status && res?.data) {
                    if (res?.message !== "No data") {
                        setFilteredItems(res?.data)
                    } else {
                        setFilteredItems([]);
                    }
                }
            },
            fail: err => { }
        })
    }
    const getSegmentationListCreatorsName = (data) => {
        setFilteredItems([])
        let params = {
            "userName": data
        }
        connectServer({
            path: GET_LIST_CREATED_USERS,
            params,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status && res?.data) {
                    if (res?.message !== "No data") {
                        setFilteredItems(res?.data)
                    } else {
                        setFilteredItems([])
                    }
                }
            },
            fail: err => { }
        })
    }

    return (
        <div onClick={() => {
            props.closeDateRangePicker && props.closeDateRangePicker(false)
        }}
            className={`search-container search-advanced ${props.cls ? props.cls : ''} ${searchBar ? "open" : ""}${props.enable ? '' : 'click-off'}`}>
            <div className="search-icon-box">
                {/* <Tooltip anchorElement="target" position="top"> */}
                <div className="search-input"
                    onClick={() => {
                        setFilteredItems([])
                    }}>
                    <BDropdown
                        data={props.dropdownData}
                        onSelect={(item) => {
                            setCategoryItem(item)
                            setSearchValue("")
                            setValueForSearch("")
                            setFilteredItems([])
                            if (item === "My communication" || item === "All communication") {
                                let p1 = {
                                    "userId": item
                                }
                                if (props.isCampaign) {
                                    props.onDataUpdate(p1)
                                }
                            }
                        }}
                        defaultItem={props.dropdownData[0]}
                        aln={props.align || "start"}
                        className=""
                    />
                    <input
                        onChange={(e) => {
                            setCampaignName(e.target.value)
                            if (props.isAudience && e.target.value.length > 0) {
                                setSearchValue("")
                                setValueForSearch("")
                                if (categoryItem === "List name") {
                                    getSegmentationListName(e.target.value)
                                } else {
                                    getSegmentationListCreatorsName(e.target.value)
                                }
                            }
                            var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                            if (e.target.value?.length > 0) {
                                if (regex.test(e.target.value)) {
                                    setSearchValue(e.target.value)
                                    //setValueForSearch(e.target.value)
                                } else {
                                    setSearchValue(searchValue);
                                }
                            } else {
                                setSearchValue(e.target.value)
                                //setValueForSearch(e.target.value)
                            }

                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                let keyName = props.isCampaign ? "campaignName" : "listName"
                                if (categoryItem === "Communication type") {
                                    keyName = "campaignType";
                                } else if (categoryItem === "Delivery type") {
                                    keyName = "deliveryType";
                                } else if (categoryItem === "Product type") {
                                    keyName = "productType";
                                } else if (categoryItem === "My communication") {

                                } else if (categoryItem === "All communication") {

                                } else {
                                    keyName = "campaignName"
                                }
                                let p1 = {
                                    [keyName]: event.target.value
                                }
                                if (props.isCampaign) {
                                    props.onDataUpdate(p1)
                                }
                            }

                        }}
                        placeholder={categoryItem !== "" ? categoryItem : 'Search'}
                        type="text"
                        className=""
                        value={searchValue}
                        maxLength={props.isAudience ? 30 : 100}
                    />
                    {
                        filteredItems?.length
                            ? <div className="css-scrollbar filterSection">
                                <ul>
                                    {filteredItems?.length ? filteredItems?.map(item => {
                                        return <li><span onClick={() => {
                                            if (categoryItem === "List name") {
                                                setSearchValue(item?.recipientsBunchName)
                                                setValueForSearch(item?.recipientsBunchName)
                                            } else {
                                                setSearchValue(item?.name)
                                                setValueForSearch(item?.userID)
                                            }
                                            // setRecipientsBunchName(item?.recipientsBunchName)
                                            setFilteredItems([])
                                        }}
                                        >{categoryItem === "List name" ? item?.recipientsBunchName : item?.name}</span></li>
                                    }) : null}
                                </ul>
                            </div>
                            : null
                    }
                    <RSPTooltip text="Search" position="top">
                        <div className={`input-search-icon cp ${props.isAudience ? valueForSearch !== "" ? "" : "click-off" : ""}`} onClick={() => {
                            let data = props.isAudience && {
                                searchTextField: valueForSearch !== "" ? categoryItem : "",
                                searchText: valueForSearch !== "" ? valueForSearch : ""
                            }
                            if (props.isAudience) {
                                props.onDataUpdate(data)
                                // setSearchValue("")
                                // setValueForSearch("")
                            }
                            if (props.isCampaign) {
                                let keyName = props.isCampaign ? "campaignName" : "listName"
                                if (categoryItem === "Communication type") {
                                    keyName = "campaignType";
                                } else if (categoryItem === "Delivery type") {
                                    keyName = "deliveryType";
                                } else if (categoryItem === "Product type") {
                                    keyName = "productType";
                                } else if (categoryItem === "My communication") {

                                } else if (categoryItem === "All communication") {

                                } else {
                                    keyName = "campaignName"
                                }
                                let p1 = {
                                    [keyName]: searchValue
                                }
                                if (props.isCampaign) {
                                    props.onDataUpdate(p1)
                                }
                            }
                        }}>
                            <i className={`icon-sd-zoom-medium icons-sm ${props.isAudience ? valueForSearch !== "" ? "" : "click-off" : ""}`}></i>
                        </div>
                    </RSPTooltip>
                    <div className="expand-menu-icon"></div>
                </div>
                {/* </Tooltip> */}
                {/* <BootstrapDropdownMegamenu
                    data={props.dropdownData}
                    onSelect={(item) => {
                        setSearchValue(item)
                    }}
                    defaultItem={""}
                    aln={props.align || "start"}
                    className="mega-menu-icon"
                    setFilteredItems={setFilteredItems}
                    filteredItems={filteredItems}
                    searchBarOpen={searchBar}
                /> */}
                <RSPTooltip text={`${searchBar ? 'Close' : 'Search'}`} position="top">
                    <div className="search-icon" onClick={() => {
                        setCampaignName("")
                        setSearchValue("")
                        setValueForSearch("")
                        setFilteredItems([]);
                        setCategoryItem(props.dropdownData[0])
                        if (searchBar && props.isCampaign) {
                            let data = {
                                "campaignName": "",
                                "userId": "My communication"
                            }
                            props.onDataUpdate(data)
                        } else if (searchBar && props.isAudience) {
                            let data = {
                                searchTextField: "",
                                searchText: ""
                            }
                            props.onDataUpdate(data)
                        }
                        setSearchBar(!searchBar)
                    }}>
                        <i className={`${searchBar ? 'icons-sm icon-sd-close' : 'icons-svm icon-sd-zoom'}-mini white`}></i>
                    </div>
                </RSPTooltip>
            </div>
        </div>
    )
}

export const PlainSearchView = props => {

    const context = useContext(authContext)
    const [searchBar, setSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    return (
        <div onClick={() => {
            props.closeDateRangePicker && props.closeDateRangePicker(false)
        }}
            className={`search-container search-advanced search-no-dropdown ${props.cls ? props.cls : ''} ${searchBar ? "open" : ""}${props.enable ? '' : 'click-off'}`}>
            <div className="search-icon-box">
                <Tooltip anchorElement="target" position="top">
                    <div className="search-input"
                        onClick={() => {
                        }}>
                        <input
                            onChange={(e) => {
                                var regex = /^[A-Za-z0-9\w\-\g ]+$/;
                                if (e.target.value?.length > 0) {
                                    if (regex.test(e.target.value)) {
                                        setSearchValue(e.target.value)
                                    }
                                } else {
                                    setSearchValue(e.target.value)
                                    props.onSearch(e.target.value);
                                }

                            }}
                            onKeyDown={(event) => {
                                if (props.isFromSegmentation || event.key === "Enter") {
                                    props.onSearch(searchValue);
                                }

                            }}
                            placeholder={props.placeholder ? props.placeholder : 'Search'}
                            type="text"
                            className=""
                            value={searchValue}
                            maxLength={100}

                        />
                        <RSPTooltip text="Search" position="top">
                            <div className={`input-search-icon cp `} onClick={() => {
                                props.onSearch(searchValue);
                            }}>
                                <i className={`icon-sd-zoom-medium icons-sm `}></i>
                            </div>
                        </RSPTooltip>
                        <div className="expand-menu-icon"></div>
                    </div>
                </Tooltip>
                <RSPTooltip text={`${searchBar ? 'Close' : 'Search'}`} position="top">
                    <div className="search-icon" onClick={() => {
                        setSearchValue("")
                        if (searchBar) {
                            props.onSearch("");
                        }
                        setSearchBar(!searchBar)
                    }}>
                        <i className={`${searchBar ? 'icons-sm icon-sd-close' : 'icons-svm icon-sd-zoom'}-mini white`}></i>
                    </div>
                </RSPTooltip>
            </div>
        </div>
    )
}