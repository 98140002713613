import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RTabbar } from 'Components/RChart/RTabbar';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox, RSInput2, RSRadio } from 'Components/RSInputs';
import React, { Component } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ImportDataModal from './ImportDataModal';
import TabContent from './TabContent';
import { withRouter } from "react-router-dom";
import TableDetails from 'Container/AuthenticatedModule/Audience/Components/AddAudience/Components/TableDetails';
import { RSPTooltip } from 'Components/Tooltip';

class DataExchangeCreation extends Component {
    state = {
        apiURL: "",
        apiUserName: "",
        apiPassword: "",
        consumerKey: "",
        consumerSecretKey: "",
        isConnectBtnClicked: false,
        isImportExistingData: false,
        isGetSelected: false,
        index: 0,
        selectedList: [],
        tableDetails: "",
        leftColumnValues: [],
        importPreference: "",
        updateCycle: "",
        updateCheck: ""
    }

    SplitComponents = (props) => {
        return (
            <>
                <Tabs
                    activeKey={"tab" + this.state.index}
                    id="uncontrolled-tab-example"
                    className="mb15 borderB0"
                >
                    {this.state.selectedList.map((item, index1) => {
                        return (
                            <Tab eventKey={"tab" + index1}>
                                <TabContent />
                            </Tab>
                        );
                    })}
                </Tabs>
            </>
        );
    }
    render() {
        return (
            <LayoutPageWrapper>
                <Container className="page-header box-bottom-space">
                    <div className=''>
                        <div className="header-title d-flex justify-content-between">
                            <h1>Data exchange</h1>
                        </div>
                        <div className="portlet-box-theme pb0">
                            <label className="fs18 mb10">{"E-commerce - " + this.props.history.location.state.EcommerceType}</label>
                            <Row>
                                <Col md={4}>
                                    <RSInput2
                                        ph="API URL"
                                        val={this.state.apiURL}
                                        cb={(text) => {
                                            this.setState({ apiURL: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="API User name"
                                        val={this.state.apiUserName}
                                        cb={(text) => {
                                            this.setState({ apiUserName: text });
                                        }} />
                                </Col>
                                <Col md={4}>
                                    <RSInput2
                                        ph="API password"
                                        val={this.state.apiPassword}
                                        cb={(text) => {
                                            this.setState({ apiPassword: text });
                                        }} />
                                </Col>
                            </Row>

                            {this.props.history.location.state.EcommerceType === "Magento" &&
                                <Row>
                                    <Col md={4}>
                                        <RSInput2
                                            ph="Consumer key"
                                            val={this.state.consumerKey}
                                            cb={(text) => {
                                                this.setState({ consumerKey: text });
                                            }} />
                                    </Col>
                                    <Col md={4}>
                                        <RSInput2
                                            ph="Consumer secret key"
                                            val={this.state.consumerSecretKey}
                                            cb={(text) => {
                                                this.setState({ consumerSecretKey: text });
                                            }} />
                                    </Col>
                                </Row>
                            }
                        </div>

                        <div className="d-flex align-items-center mt10">
                            <RSCheckbox
                                className="mb0 fs14"
                                lbl={'Import existing customer data'}
                                checked={this.state.isImportExistingData}
                                cb={status => {
                                    this.setState({ isImportExistingData: status });
                                }} />
                            <i className="icon-sd-pencil-edit-mini blue fs12 blue marginT-2 ml5 cp"></i>
                        </div>

                        <div className="btn-container d-flex justify-content-end">
                            <RSPrimaryBtn className={this.state.isGetSelected ? "click-off" : ""} onClick={() => {
                                this.setState({ isConnectBtnClicked: !this.state.isConnectBtnClicked });
                            }}>Connect</RSPrimaryBtn>
                        </div>

                        {this.state.isGetSelected &&
                            <>
                                <div className="split-container mt30">
                                    <div className="tabs-filled-component-del">
                                        <RTabbar
                                            defaultSelectedItem={this.state.splitTabIndex}
                                            defaultClass="tabDefault"
                                            dynamicTab="addtab"
                                            activeClass="tabDefault active"
                                            tabData={this.state.selectedList}
                                            callBack={(item, index) => {
                                                this.setState({ splitTabIndex: index });
                                            }}
                                        />
                                    </div>
                                    {<this.SplitComponents />}
                                </div>
                            </>
                        }
                        {(this.state.isConnectBtnClicked && this.props.history.location.state.EcommerceType === "Magento") &&
                            <div className="portlet-box-theme p0 mb20">
                                <Row className="even py15 mx0">
                                    <Col md={{ span: 2, offset: 3 }} className="text-end">
                                        <label className="label-row">Table</label>
                                    </Col>
                                    <Col md={3}>
                                        <RSDropdownList
                                            data={["Consumer data", "Subscription data"]}
                                            className="mb0"
                                            value={this.state.tableDetails !== "" ? this.state.tableDetails : "-- Table Details --"}
                                            onChange={(e) => {
                                                this.setState({ tableDetails: e.target.value });
                                                if (e.target.value === "Consumer data") {
                                                    this.setState({ leftColumnValues: ConsumerData })
                                                } else {
                                                    this.setState({ leftColumnValues: SubscriptionData })
                                                }
                                            }} />
                                    </Col>
                                </Row>
                                {this.state.tableDetails !== "" &&
                                    <div className="p15"><TableDetails tabledetails={this.state.tableDetails} leftColumnValues={this.state.leftColumnValues} /></div>
                                }
                            </div>
                        }
                        {(this.state.tableDetails !== "" || this.state.isGetSelected) &&
                            <>
                                <div className="d-flex align-items-center mb15">
                                    <h4 className="mb0">{"Import preferences"}</h4>
                                    <RSPTooltip position="top" text="Dedupe settings">
                                        <i className="icon-settings-small icons-md blue cp ml10"></i>
                                    </RSPTooltip>
                                </div>
                                <div className="mb5">
                                    <div>
                                        <Row>
                                            <RSRadio
                                                lbl="Upload new data - if a match is found, overwrite the older record"
                                                name={"importPreference"}
                                                labelId={"importPreferenceId"}
                                                className="marginB5"
                                                checked={this.state.importPreference === "Upload new data - if a match is found, overwrite the older record" ? true : false}
                                                cb={(event) => {
                                                    this.setState({ importPreference: event.target.value });
                                                }} />
                                        </Row>
                                        <Row>
                                            <RSRadio
                                                lbl="Preserve older data - if a match is found, reject the recent record"
                                                name={"importPreference1"}
                                                labelId={"importPreferenceId1"}
                                                className="marginB5"
                                                checked={this.state.importPreference === "Preserve older data - if a match is found, reject the recent record" ? true : false}
                                                cb={(event) => {
                                                    this.setState({ importPreference: event.target.value });
                                                }} />
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col md={4}>
                                            <Row>
                                                <Col md={4}>
                                                    <label className="label-row">Update cycle</label>
                                                </Col>
                                                <Col md={7}>
                                                    <RSDropdownList
                                                        data={["Immediate", "15 minutes", "30 minutes", "Hourly", "Daily", "Weekly", "Monthly"]}
                                                        className="mb0"
                                                        value={this.state.updateCycle !== "" ? this.state.updateCycle : "-- Select --"}
                                                        onChange={(e) => {
                                                            this.setState({ updateCycle: e.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <label className="label-row">Select the field to check for updates</label>
                                                </Col>
                                                <Col md={5}>
                                                    <RSDropdownList
                                                        data={["Modified date & time"]}
                                                        className="mb0"
                                                        value={this.state.updateCheck !== "" ? this.state.updateCheck : "-- Select --"}
                                                        onChange={(e) => {
                                                            this.setState({ updateCheck: e.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="btn-container d-flex justify-content-end">
                                        <RSPrimaryBtn onClick={() => {
                                            this.props.history.push("/preferences/audiance-list");
                                        }}>Upload Data</RSPrimaryBtn>
                                    </div>
                                </div>
                            </>}
                    </div>
                    {this.props.history.location.state.EcommerceType !== "Magento" && this.state.isConnectBtnClicked &&
                        <ImportDataModal
                            isOpen={this.state.isConnectBtnClicked}
                            onSelectedDetails={(value) => {
                                this.setState({ selectedList: value })
                            }}
                            onChangeIsOpen={(value) => {
                                this.setState({ isConnectBtnClicked: value, isGetSelected: true });
                            }} />
                    }
                </Container>
            </LayoutPageWrapper>
        );
    }
}

export default withRouter(DataExchangeCreation);

const ConsumerData = [
    { name: "Name", selected: false },
    { name: "Mobile", selected: false },
    { name: "City", selected: false },
    { name: "Email", selected: false },
    { name: "State", selected: false },
    { name: "Country", selected: false },
    { name: "Age", selected: false },
    { name: "Gender", selected: false }
];
const SubscriptionData = [
    { name: "Product category", selected: false },
    { name: "Product name", selected: false },
    { name: "Membership no", selected: false },
    { name: "Date of purchase", selected: false },
    { name: "Booking ID", selected: false },
    { name: "Booking source", selected: false },
    { name: "Purchase value", selected: false },
    { name: "Offer opted", selected: false },
    { name: "Offer ID", selected: false }
];