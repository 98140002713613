
import React, { useEffect, useRef, useState } from 'react';
import { Popup } from "@progress/kendo-react-popup";
// import Picker from 'emoji-picker-react';

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ch_redmiColor } from 'Components/Charts/ChartColors';

// const useOutsideAlerter = (ref) => {
// }

const RSEmojiPicker = (props)=> {

  const wrapperRef = useRef(null)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event)=> {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.emojiClose()
        // alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <Popup
        anchor={props.anchor.current}
        show={props.show}
        popupClass={`popup-content emoji-popup-wrap emoji-popup-wrap-modal ${props.popupClass ? props.popupClass : ''}`}
        onOpen={props.onOpen}
      >
        {/* close button */}
        {/* <div className='d-flex align-items-center justify-content-end' ref={wrapperRef}>
          <div class="close-ico-btn cp margin5" onClick={() => {
            props.emojiClose()
          }}>
            <i class="icon-close-small"></i>
          </div>
        </div> */}
        {/* close button */}
        
        <div
          className='d-inline-block'
          ref={wrapperRef}
          // ref={props.ref}
          tabindex={props.tabindex}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        >
          {/* <Picker onEmojiClick={(event, emojiObject) => {
            props.onEmojiClick(emojiObject.emoji)
          }} /> */}
          <Picker
            data={data}
            theme={'light'}
            showPreview={false}
            onEmojiSelect={(event) => {
              props.onEmojiClick(event.native)
            }}
          />
        </div>
      </Popup>
    </>
  )
}
export default RSEmojiPicker;