import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Switch } from "@progress/kendo-react-inputs";
import { RTabbar, RTabbar1 } from "Components/RChart/RTabbar";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import RSEmojiPicker from "Components/Emoji/EmojiPicker";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { BootstrapDropdownIcon } from "Components/BootstrapDropdown/BootstrapDropdown";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import RESTextEditor from "Components/Editor/RESTextEditor";
import { RSDropdownList } from "Components/RSDropdowns";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import { CustomPopup } from "Components/DateRangePicker/ScheduleCustomPopup";
import ImportTabContent from "./ImportTabContent";
import ApprovalSettings from "./ApprovalSettings";
/*SPLIT A/B */
import SubjectLineModal from "./SubjectLineModal";
import SplitContent from "./SplitContent";
import SplitSizeModal from "./SplitSizeModal";
import SplitSettingsModal from "./SplitSettingsModal";

import EmailPreviewModal from "./EmailPreviewModal";
import emailNew from "./EmailNew.json";
import { createEmailCampaign } from "./CamapignWrapper";
import {
  addMinutes,
  getUserData,
  validateEmail,
  getAuthUserId,
  dateFormat,
  getContent,
  getLsMasterDataDropdown,
  getAuthClientId,
  isEmpty,
  getServerConfig,
  getCurrentTimeWithTimeZone,
} from "Helper/Utils/Utils";
import authContext from "Helper/StateHandler/auth-context";
import {
  BODY,
  REPLY_EMAIL,
  ENTER_VALID_EMAIL,
  SENDER_NAME,
  SUBJECT_LINE,
  PRE_HEADER_MESSAGE,
  SCHEDULE_DATE,
  SENDER_EMAIL_ADDRESS,
  EMAIL_CONTENT,
  SELECT_DOMAIN,
  APPROVAL_MAIL,
} from "Helper/Constants/validationMessage";
import {
  CREATE_EMAIL_CAMPAIGN,
  GET_UNSUBSCRIPTION_LIST,
  GET_FOOTER_LIST,
  GET_POTENTIAL_AUD,
  UPDATE_EMAIL_CAMPAIGN,
  GET_ADHOC_ATTRIBUTES,
  GET_APPROVER_LIST,
  GET_CAMPAIGN_HEADER,
  MDC_CANVAS_URL,
  GET_NOTIFICATION_DATA,
  SENDER_DOMAIN_VALIDATION,
  GET_ACTIVE_DOMAINS,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import { Buffer } from "buffer";
import { SuccessModal } from "../Notification/MSComponents/EmailComponents/Components/SuccessModal";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Template from "../Notification/MSComponents/EmailComponents/Components/Template";

class MdcEmail extends Component {
  static contextType = authContext;

  tabs = [
    {
      text: "Text",
      iconLeft: "icon-sd-text-document-edge-large icons-lg marginB10",
      disable: false,
      contentType: "R",
    },
    {
      text: "Import",
      iconLeft: "icon-sd-import-file-edge-large icons-lg marginB10",
      disable: false,
      contentType: "Z",
    },
    {
      text: "Template",
      iconLeft: "icon-sd-template-generator-large icons-lg marginB10",
      disable: false,
      contentType: "T",
    },
  ];

  componentDidMount = () => {
    if (this.state.emailData.data.senderName === "") {
      let senderName = getUserData()?.clientDetailsLocalization.clientName;
      let tempEmailData = { ...this.state.emailData };
      tempEmailData.data.senderName = senderName;
      this.setState({ senderName: senderName, emailData: tempEmailData })
    }
    this.getUnsubscriptionList();
    this.getIframeHeight();
    this.getDataFromServer();
    this.getActiveDomains();
    this.getApproverList();
    this.getEmailFooterList();
    this.getAdhocAttributeHeaders();

  };

  componentDidUpdate = (prevProps) => {
    this.getIframeHeight()
  }

  /*componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const authResult = new URLSearchParams(window.location.search);
      let mode = authResult.get("mode");
      if (mode != "edit") {
        this.setState({
          senderEmailAddress: this.state.emailData.data.senderEmail,
          alternateEmailAddress: this.state.emailData.data.replyEmail,
          isReplyMail: this.state.emailData.data.isReplyMailEnabled,
          audienceList: this.state.emailData.data.targetListTargetAudience,
          isSplit: this.state.emailData.data.isSplitAB,
          subjectLine: this.state.emailData.data.content[0].subjectLine,
          schedule: this.state.emailData.data.content[0].blastDateTime,
          timezoneId: this.state.emailData.data.content[0].timezoneId,
        });
      }
    }
  };*/

  state = {
    mdcUrl: getServerConfig() + MDC_CANVAS_URL,
    emailData: {
      data: emailNew,//makeImmutableObject({ ...emailNew }),
    },
    emailFooterList: [],
    isRefreshModal: false,
    campaignId: "",
    bodyContent: "",
    levelNumber: "",
    splitTabIndex: 0,
    edmChannelId: 0,
    potentialAudience: "",
    subjectLinePersonalizeData: [],
    approverListFromServer: [],
    unsubscriptionList: [],
    senderName: emailNew.senderName,
    senderEmailAddress: emailNew.senderEmail,
    alternateEmailAddress: "",
    unsubscription: {
      unsubscribeSettingId: 0,
      unsubscribeName: '-- Select --'
    },
    isReplyMail: "",
    audienceCount: [],
    emailFooterHtml: "",
    isSplit: false,
    sendMailToAddress: "",
    isOpenSplitResetModal: false,
    isAddBrow: true,
    isOpenSendSuccessModal: false,
    isSpamCheckValidate: false,
    index: 0,
    mode: "",
    approvalList: [
      {
        approverEmail: {
          "email": "-- Select --",
          "userid": 0
        },
        ismandatory: false,
        edmworlflowdetails: 0,
        priority: 1,
        ApprovalSentcount: 1,
        name: "approval",
        input: false,
        appErr: null
      }
    ],
    approvalSettings: {
      approvalMatchType: "All",
      approvalMatchCount: 0,
      isFollowHierarchyEnabled: false,
    },
    requestForErrorMsg: [
      {
        approverEmailMsg: null,
      },
    ],
    subjectLine: "",
    checked: false,
    activeTab: -1,
    startCampaignDate: "",
    campaignName: "",
    endCampaignDate: "",
    isAddViewBrowser: true,
    isOpenSubjectModal: false,
    isSplitSettings: false,
    isShowSplitSizeModal: false,
    clicked: "",
    attributeName: "",
    isShow: false,
    headerMsg: "",
    isModalOpen: false,
    iconClass: "icon-sd-expand-large",
    isEditTimeZone: false,
    timeZone: null,
    timeZoneData: [],
    isDayLightSavings: false,
    isSendRecommendation: false,
    schedule: "",
    scheduleMinimumDateTime: Date(),
    errSelectUnSubMsg: null,
    errSenderNameMsg: null,
    errSendTestMailMsg: null,
    errSenderEmailMsg: null,
    errSenderDomainMsg: null,
    errReplyEmailMsg: null,
    errReplyDomainMsg: null,
    errSubjectLineMsg: null,
    errBodyMsg: null,
    errEdmMsg: null,
    errTabSelect: null,
    errScheduleDateMsg: null,
    errPreHeaderMsg: null,
    defaultContentError: {
      errBodyMsg: null,
      errPreHeaderMsg: null,
      errSendTestMailMsg: null,
      errImportContent: null

    },
    errEmailContentMsg: null,
    splitContentError: [
      {
        errBodyMsg: null,
        errSendTestMailMsg: null,
        errSubjectLineMsg: null,
        errScheduleDateMsg: null,
        errEnableUnSubMsg: null,
        errUnSubMsg: null,
      },
      {
        errBodyMsg: null,
        errSendTestMailMsg: null,
        errSubjectLineMsg: null,
        errScheduleDateMsg: null,
        errEnableUnSubMsg: null,
        errUnSubMsg: null,
      },
    ],
    splitTabData: [
      { id: 1001, text: "Split A" },
      { id: 1002, text: "Split B" },
    ],
    disableEdit: '',
    segList: "",
    activeDomainList: [],
    activeDomain: { "smtpDomainSettingId": -1, "domainName": "-- Select domain --" },
    activeReplyDomain: { "smtpDomainSettingId": -1, "domainName": "-- Select domain --" }
  };

  constructor(props) {
    super(props);
    this.anchor = React.createRef();
    this.emojianochor = React.createRef();
    this.contentRef = React.createRef();
    this.blurTimeoutRef = React.createRef();
    this.updateTabStatus();
    this.inputRef = React.createRef();
    let prevState = this.state;
    prevState.emailData.data.senderName = this.state.senderName;
    this.setState({ ...prevState })
  }

  onOpen = (e) => {
    this.contentRef.current.focus();
  };

  onFocus = () => {
    // the user is still inside the content
    clearTimeout(this.blurTimeoutRef.current);
  };

  onBlurTimeout = () => {
    // the user is now outside the popup
    this.setState({ clicked: 0, isShow: false });
  };

  onBlur = () => {
    clearTimeout(this.blurTimeoutRef.current);
    this.blurTimeoutRef.current = setTimeout(this.onBlurTimeout, 200);
  };

  updateTabStatus = (index) => {
    if (this.state.emailData.data.content[0].contentType === "R") {
      this.setState({ activeTab: 0 });
      const newData = this.tabs;
      newData[0].disable = false;
      newData[1].disable = true;
      newData[2].disable = true;
    } else if (this.state.emailData.data.content[0].contentType === "Z") {
      this.setState({ activeTab: 1 });
      const newData = this.tabs;
      newData[0].disable = true;
      newData[1].disable = false;
      newData[2].disable = true;
    } else if (this.state.emailData.data.content[0].contentType === "T") {
      this.setState({ activeTab: 2 });
      const newData = this.tabs;
      newData[0].disable = true;
      newData[1].disable = true;
      newData[2].disable = false;
    } else {
      this.setState({ activeTab: -1 });
      const newData = this.tabs;
      newData[0].disable = false;
      newData[1].disable = false;
      newData[2].disable = false;
    }
  };

  handleTimeDiff = () => {
    let currentDate = new Date();
    let currentTime = currentDate.getTime();
    let scheduleDate = new Date(this.state.schedule);
    let scheduleTime = scheduleDate.getTime();
    let timeDiffInSecs = (scheduleTime - currentTime) / 1000;
    let timeDiffInMins = Math.round(timeDiffInSecs / 60);
    return timeDiffInMins < 15 ? false : true;
  }

  getStartDateForSchedule = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (this.state.emailData.data.content[0].timezoneId === 0) {
      filterTimezone = data.timeZone.filter(
        (item) =>
          item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
      )[0];
    } else {
      filterTimezone = data.timeZone.filter(
        (item) => item.timezoneId === this.state.emailData.data.content[0].timezoneId
      )[0];
    }
    let startDate = new Date(this.getStartDate(this.state.startDate));
    let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
    return startDateWithTime;
  }

  getDataFromServer = () => {
    const authResult = new URLSearchParams(window.location.search);
    let campId = window.atob(authResult.get("CampaignId"));
    let templateID = authResult.get("templateId");
    let templateId = (!!templateID && templateID !== "null") ? window.atob(templateID) : null


    const queryString = window.location.search.split("?");
    const urlParams = new URLSearchParams(queryString[1]).get("ChannelDetails");
    const resSave = new URLSearchParams(queryString[1]).get("resSave");
    const builderParams = JSON.parse(urlParams)

    const params = {
      userId: getAuthUserId(),
      campaignId: resSave ? builderParams?.campaignId : campId,
    };
    this.setState({ campaignId: params.campaignId, levelNumber: builderParams.LevelNumber });
    connectServer({
      path: GET_CAMPAIGN_HEADER,
      params: params,
      retryTime: 5000,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          if (res?.data) {
            this.setState({
              campaignName: res.data.campaignName,
              startCampaignDate: res.data.startDate,
              // endCampaignDate: res.data.endDate,
              endCampaignDate: new Date(new Date(new Date(res.data.endDate).getFullYear(), new Date(res.data.endDate).getMonth(), new Date(res.data.endDate).getDate(), 23, 59, 59)),
              startDate: new Date(this.getStartDate(res.data.startDate)),
              endDate: new Date(res.data.endDate),
              attributeName: res.data.attributeName,
              primaryCampaignGoal:
                res.data.primaryTargetCode === "E"
                  ? "Engagement"
                  : res.data.primaryTargetCode === "R"
                    ? "Reach"
                    : "Conversion",
            });
          }
        }
      },
      fail: (err) => { },
    });

    let channelDetails = authResult.get("ChannelDetails");
    const obj2Temp = JSON.parse(channelDetails);

    const obj2 = { ...obj2Temp, ...builderParams }
    var segmentationId = obj2?.segmentationListID;
    if (resSave) {
      obj2.segmentationListID = !!builderParams.segList ? [parseInt(builderParams.segList, 10)] : []
      this.setState({ edmChannelId: builderParams.channelDetailId, levelNumber: obj2?.LevelNumber, })
    }
    this.setState({

      audienceCount: obj2?.segmentationListID,
      segList: obj2?.segmentationListID
    });
    const prevState = this.state.emailData;
    prevState.data.targetListTargetAudience = obj2?.segmentationListID;
    this.setState({ emailData: prevState });
    if (!!obj2.segmentationListID && obj2.segmentationListID.length > 0) {
      const potentialparams = {
        userId: getAuthUserId(),
        segmentationListids: obj2.segmentationListID,
        campaignId: resSave ? builderParams?.campaignId : campId,
      };
      connectServer({
        path: GET_POTENTIAL_AUD,
        params: potentialparams,
        retryTime: 5000,
        context: this.context,
        ok: (res) => {
          if (res.status) {
            let tempEmailData = { ...this.state.emailData };
            let resultData = res.data;
            let totalListCount = resultData.filter(
              (item) => item.channelType === "Email"
            );
            tempEmailData.data.totalaudience = parseInt(totalListCount[0]["channelDescription"], 10);
            // tempEmailData.data.totalaudience = parseInt(
            //   res.data[4].channelDescription
            // );
            this.setState({ potentialAudience: tempEmailData.data.totalaudience, emailData: tempEmailData });
          }
        },
        fail: (err) => { },
      });
    }
    let mode = (authResult.get("mode")) || (resSave && "edit");
    this.setState({ mode: mode });
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    this.setState({ timeZoneData: data?.timeZone });
    if (mode === "edit" || (builderParams.LevelNumber === 1 && !!templateId)) {
      let channelId = "";
      const urlResult = new URLSearchParams(window.location.search);
      let channelDetails = urlResult.get("ChannelDetails");
      const obj = JSON.parse(channelDetails);
      if (!resSave) {
        channelId = parseInt(obj?.ChannelId.substr(4), 10);
        this.setState({
          levelNumber: obj?.LevelNumber
        })
      }
      // if (obj?.LevelNumber === 1) {
      //   let getScheduledDate = obj?.ScheduleDate
      //   let splitDate = getScheduledDate?.split(",")
      //   let scheduledDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0], splitDate[3], splitDate[4]).toUTCString()
      //   let currentDate = new Date()
      //   let sdate = new Date(scheduledDate)
      //   if (currentDate >= sdate) {
      //     this.setState({ disableEdit: 'click-off' })
      //   }
      // }
      const params = {
        campaignId: resSave ? parseInt(builderParams?.campaignId, 10) : campId,
        channelId: resSave ? builderParams?.channelid : channelId,
        campaignType: "M",
        channelDetailId: resSave ? builderParams?.channelDetailId : obj.ChannelDetailID,
        templateId: !!templateId ? templateId : obj.templateId,
      };

      connectServer({
        path: GET_NOTIFICATION_DATA,
        params: params,
        retryTime: 5000,
        loading: this.context.globalStateData.setIsLoadingData,
        context: this.context,
        ok: (res) => {
          if (res.status) {
            let tempObject = JSON.parse(res.data.channelJson);
            let editClickOff = (tempObject.channelstatusId !== 12 && tempObject.channelstatusId !== 51 && tempObject.channelstatusId !== 9 && tempObject.channelstatusId !== 5 && tempObject.channelstatusId !== 20 && tempObject.channelstatusId !== 52) ? "" : "click-off"
            this.setState({
              isReplyMail: tempObject.isReplyMailEnabled || false,
              // alternateEmailAddress: tempObject.replyEmail,
              disableEdit: editClickOff
            })
            if (tempObject.isSplitAB) {
              this.setState({
                isSplit: tempObject.isSplitAB,
              });
              let framedContent = [...tempObject.content];
              framedContent.forEach((item, index) => {
                // if (item.contentType === "Z") {
                item.body = Buffer.from(item.body, "base64").toString();
                item.textContent = item.body;
                // }
              });
              tempObject.content = framedContent;
              let tempEmailData = { ...this.state.emailData };
              tempEmailData.data.content = tempObject.content;
              this.setState({ emailData: tempEmailData })
            } else {
              let tempEmailData = { ...this.state.emailData };
              tempEmailData.data.senderName = tempObject.senderName || getUserData()?.clientDetailsLocalization?.clientName;
              tempEmailData.data.senderEmail = tempObject.senderEmail
              tempEmailData.data.replyEmail = tempObject.replyEmail
              tempEmailData.data.channelstatusId = tempObject.channelstatusId;
              tempEmailData.data.isSplitAB = tempObject.isSplitAB || false;
              tempEmailData.data.levelNumber = tempObject.levelNumber;
              tempEmailData.data.content[0].edmChannelId = tempObject.content[0].edmChannelId;
              tempEmailData.data.content[0].parentChannelDetailId = tempObject.content[0].parentChannelDetailId;
              tempEmailData.data.content[0].parentChannelDetailType = tempObject.content[0].parentChannelDetailType;
              tempEmailData.data.content[0].templateId = tempObject.content[0].templateId;
              tempEmailData.data.content[0].timezoneId = tempObject.content[0].timezoneId;
              tempEmailData.data.content[0].isFooterEnabled = tempObject.content[0].isFooterEnabled;
              tempEmailData.data.content[0].subjectLine = tempObject.content[0].subjectLine;
              tempEmailData.data.content[0].localBlastDateTime = tempObject.content[0].localBlastDateTime;
              tempEmailData.data.content[0].schedule = tempObject.content[0].schedule;
              tempEmailData.data.content[0].actionId = tempObject.content[0].actionId;
              tempEmailData.data.content[0].actionTime = tempObject.content[0].actionTime;
              tempEmailData.data.content[0].addOnLevel = tempObject.content[0].addOnLevel;
              tempEmailData.data.content[0].domId = tempObject.content[0].domId;
              tempEmailData.data.content[0].channelFriendlyName = tempObject.content[0].channelFriendlyName;
              tempEmailData.data.content[0].actionTimeDuration = tempObject.content[0].actionTimeDuration;
              tempEmailData.data.content[0].textContent = tempObject.content[0].textContent;
              // tempEmailData.data.content[0].body =
              //   tempObject.content[0].contentType === "Z"
              //     ? Buffer.from(tempObject.content[0].body, "base64").toString()
              //     : tempObject.content[0].body;
              tempEmailData.data.content[0].body = Buffer.from(tempObject.content[0].body, "base64").toString()
              tempEmailData.data.content[0].contentType = tempObject.content[0].contentType;
              tempEmailData.data.content[0].preHeaderMessage = tempObject.content[0].preHeaderMessage;
              tempEmailData.data.content[0].footerId = tempObject.content[0].footerId;
              tempEmailData.data.content[0].edmDimension = tempObject.content[0].edmDimension;
              tempEmailData.data.content[0].edmFileWeight = tempObject.content[0].edmFileWeight;
              tempEmailData.data.content[0].edmImageDimension = tempObject.content[0].edmImageDimension;
              tempEmailData.data.content[0].unsubscribeSettingId = tempObject.content[0].unsubscribeSettingId;
              this.setState({
                isSplit: tempObject.isSplitAB || false,
                subjectLine: tempObject.content[0].subjectLine,
                schedule: tempObject.content[0].localBlastDateTime,
                isAddFooter: tempObject.content[0].isFooterEnabled,
                checked:
                  tempObject.content[0].unsubscribeSettingId !== 0
                    ? true
                    : false,
                isAddBrow: tempObject.content[0].isViewinBrowserChecked,
                isShowFooterComponent: tempEmailData.data.content[0].body !== "" ? true : false,
                emailData: tempEmailData,
                senderName: tempEmailData.data.senderName || getUserData()?.clientDetailsLocalization?.clientName,
                headerMsg: tempObject.content[0].preHeaderMessage,
                // levelNumber: tempObject.levelNumber
              });
              if (!!!tempEmailData.data.senderName || tempEmailData.data.senderName === "") {
                tempEmailData.data.senderName = getUserData()?.clientDetailsLocalization?.clientName;;
              }
              if (this.state.senderEmailAddress === "" || this.state.domainName) {
                let senderEmail = tempEmailData.data.senderEmail.split("@")
                let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                this.setState({
                  senderEmailAddress: senderEmail[0],
                  activeDomain: domainName,
                })
              }
              if (this.state.alternateEmailAddress === "" || this.state.domainName) {
                let senderEmail = tempEmailData.data.replyEmail.split("@")
                let domainName = this.state.activeDomainList.filter(item => item.domainName === senderEmail[1])[0];
                this.setState({
                  alternateEmailAddress: senderEmail[0],
                  activeReplyDomain: domainName,
                })
              }
              if (tempObject.isRequestForApprovalEnabled && tempObject.channelstatusId !== 54) {
                let requestForApproval = { ...tempObject.requestForApproval };
                let approveList = [...requestForApproval.approvarList];
                let tempApprovalList = [];
                let tempApprovalSettings = {
                  approvalMatchType: requestForApproval.matchType,
                  approvalMatchCount: requestForApproval.matchCount,
                  isFollowHierarchyEnabled: requestForApproval.IsFollowHierarchy,
                }
                approveList.map((item, index) => {
                  let tempItem = {
                    approverEmail: {
                      "email": item.approvarName,
                      "userid": parseInt(item.approvarId, 10)
                    },
                    ismandatory: item.ismandatory,
                    edmworlflowdetails: 0,
                    priority: item.priority,
                    ApprovalSentcount: item.ApprovalSentcount || 1,
                    name: "approval",
                    input: item.approvarId === "" ? true : false,
                    appErr: null
                  }
                  tempApprovalList.push(tempItem)
                })
                this.setState({ approvalList: tempApprovalList, approvalSettings: tempApprovalSettings, isRequestForApproval: tempObject.isRequestForApprovalEnabled })
              }
              // if (!!!tempEmailData.data.senderEmail || tempEmailData.data.senderEmail === "") {
              //   tempEmailData.data.senderEmail = this.state.senderEmailAddress;
              // }
              // if (!!!tempEmailData.data.replyEmail || tempEmailData.data.replyEmail === "") {
              //   tempEmailData.data.replyEmail = this.state.alternateEmailAddress;
              // }
              if (tempObject.content[0].contentType === "R") {
                this.setState({ activeTab: 0 });
                const newData = this.tabs;
                newData[0].disable = false;
                newData[1].disable = true;
                newData[2].disable = true;
              } else if (tempObject.content[0].contentType === "Z") {
                this.setState({ activeTab: 1 });
                const newData = this.tabs;
                newData[0].disable = true;
                newData[1].disable = false;
                newData[2].disable = true;
              } else if (tempObject.content[0].contentType === "T") {
                this.setState({ activeTab: 2 });
                const newData = this.tabs;
                newData[0].disable = true;
                newData[1].disable = true;
                newData[2].disable = false;
              } else {
                this.setState({ activeTab: -1 });
                const newData = this.tabs;
                newData[0].disable = false;
                newData[1].disable = false;
                newData[2].disable = false;
              }
              let edittimeZoneId = this.state.emailData.data.content[0].timezoneId !== 0 ? this.state.emailData.data.content[0].timezoneId : getUserData().clientDetailsLocalization?.timeZoneId;
              let filterTimezone = data.timeZone.filter(function (item) {
                return item.timezoneId === edittimeZoneId;
              });
              this.setState({ timeZone: filterTimezone[0] });
              let unsubscribe = this.state.unsubscriptionList.filter((item) => item.unsubscribeSettingId === tempObject.content[0].unsubscribeSettingId);
              this.setState({
                unsubscription: unsubscribe[0],
              });
            }
          }
        },
        fail: (err) => { },
      });
    } else {
      let filterTimezone = data.timeZone.filter(function (item) {
        return (
          item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId
        );
      });
      let tempEmailData = { ...this.state.emailData };
      tempEmailData.data.content[0].timezoneId = filterTimezone[0].timezoneId;
      this.setState({ timeZone: filterTimezone[0], emailData: tempEmailData, levelNumber: builderParams.LevelNumber });
    }
  };

  getIframeHeight = () => {
    let iframe = document.getElementById("footerFrame");

    if (iframe !== null) {
      // Get the height of the content inside the iframe
      let contentHeight = iframe?.contentWindow?.document?.body?.scrollHeight;

      // Set the height of the iframe to match the content height
      iframe.style.height = contentHeight + 25 + "px";
    }
  }

  getUnsubscriptionList = () => {
    const params = {};
    connectServer({
      path: GET_UNSUBSCRIPTION_LIST,
      params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          let unsubscribe = res.data.filter((item) => item.unsubscribeSettingId === this.state.emailData.data.content[0].unsubscribeSettingId);
          this.setState({
            unsubscriptionList: res.data || [],
            unsubscription: unsubscribe[0],
          });
        }
      },
      fail: (err) => {
      },
    });
  };

  getAdhocAttributeHeaders = () => {
    const authResult = new URLSearchParams(window.location.search);
    let channelDetails = authResult.get("ChannelDetails");


    const queryString = window.location.search.split("?");
    const urlParams = new URLSearchParams(queryString[1]).get("ChannelDetails");
    const resSave = new URLSearchParams(queryString[1]).get("resSave");
    const builderParams = JSON.parse(urlParams)

    const obj2 = JSON.parse(channelDetails);

    let audienceID = resSave ? builderParams?.segList?.toString() : obj2?.segmentationListID?.toString();
    if (!!audienceID && audienceID !== "") {
      let params = {
        segmentationListID: audienceID,
      };
      connectServer({
        path: GET_ADHOC_ATTRIBUTES,
        params,
        sessionOut: this.context.globalStateData.setSessionOutData,
        context: this.context,
        ok: (res) => {
          if (res.status) {
            let tempNamePersonal = [];
            res.data.names.forEach((item, index) => {
              tempNamePersonal.push("[[" + item + "]]");
            });
            let tempEmailPersonal = [];
            res.data.emails.forEach((item, index) => {
              tempEmailPersonal.push("[[" + item + "]]");
            });
            let tempFullPersonal = [];
            res.data.full.forEach((item, index) => {
              tempFullPersonal.push("[[" + item + "]]");
            });
            this.setState({
              personalizeTabData: tempNamePersonal,
              emailPersonlizeData: tempEmailPersonal,
              subjectLinePersonalizeData: tempFullPersonal,
            });
          }
        },
        fail: (err) => {
        },
      });
    }
  };
  handleEmailFotterDisable = (tempContent) => {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = tempContent;
    if (tempElement.querySelector("table")) {
      tempElement.querySelector("table").style.pointerEvents = 'none';
    }
    return tempElement.innerHTML;
  }
  getEmailFooterList = () => {
    const params = {};
    connectServer({
      path: GET_FOOTER_LIST,
      params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          let footerRawHtml = res.data.emailfooterList.filter(
            (item) =>
              item.emailfooterID ===
              this.state.emailData.data.content[0].footerId
          )[0]?.emailFooterRawcode;
          this.setState({
            emailFooterList: res.data.emailfooterList || [],
            emailFooterHtml: footerRawHtml,
          });
          setTimeout(() => {
            this.getIframeHeight()
          }, 2000)
        }
      },
      fail: (err) => {
      },
    });
  };

  getApproverList = (param) => {
    let params = {
      clinetId: getAuthClientId(),
    };
    connectServer({
      path: GET_APPROVER_LIST,
      params,
      context: this.context,
      ok: (res) => {
        if (res.status) {
          this.setState({
            approverListFromServer: res.data,
          });
        }
      },
      fail: (err) => { },
    });
  };

  getActiveDomains = () => {
    let params = {}
    connectServer({
      path: GET_ACTIVE_DOMAINS,
      params,
      context: this.context,
      ok: res => {
        if (res.status) {
          let senderEmail = this.state.emailData.data.senderEmail?.split("@")
          let replyEmail = this.state.emailData.data.replyEmail?.split("@");
          let replyDomainName = res.data.filter(item => item.domainName === replyEmail[1])[0];
          let domainName = res.data.filter(item => item.domainName === senderEmail[1])[0];
          this.setState({
            activeDomainList: res.data,
            senderEmailAddress: senderEmail[0],
            activeDomain: domainName,
            alternateEmailAddress: replyEmail[0],
            activeReplyDomain: replyDomainName
          })
        }
      },
      fail: err => {
      }
    })
  }

  validateSenderDomain = (Domain) => {
    let params = {
      "domainname": Domain
    }
    connectServer({
      path: SENDER_DOMAIN_VALIDATION,
      params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: (res) => {
        if (!res?.status) {
          this.setState({ errSenderEmailMsg: res.message })
        }
      },
      fail: (err) => {
      },
    });

  }

  createSplitObject = (sectionName) => {
    const urlResult = new URLSearchParams(window.location.search);
    let channelDetails = urlResult.get("ChannelDetails");
    const obj = JSON.parse(channelDetails);
    let split = {
      edmChannelId: obj.ChannelDetailID,
      subjectLine: "",
      contentType: "",
      splitType: sectionName,
      statusId: 0,
      body: "",
      textContent: "",
      preHeaderMessage: "",
      spamScore: 3.0,
      isFooterEnabled: false,
      footerId: 0,
      unsubscribeSettingId: 0,
      contentUrl: "",
      htmlFilePath: "",
      previewId: 0,
      zipFilePath: "",
      isViewinBrowserChecked: true,
      templateId: 0,
      dataSource: obj.dataSource,
      dynamiclistId: obj.DynamiclistID,
      timezoneId: this.state.timeZone.timezoneId,
      localBlastDateTime: "",
      linkVerificationLogDetail: [],
      edmFileWeight: 0,
      edmDimension: 0,
      parentChannelDetailId: obj.ParentChannelDetailID,
      parentChannelDetailType: obj.ParentChannelDetailType,
      actionId: obj.ActionID,
      actionTime: obj.ActionTime,
      actionTimeDuration: obj.ActionTimeDuration,
      channelFriendlyName: obj.ChannelFriendlyName,
      allOrAny: obj.IsALLorAny,
      addOnLevel: obj.addOnLevel,
      domId: obj.DomId,
      channelDetailType: obj.ChannelDetailType,
      channelId: obj.ChannelId,
      flowChannel: obj.FlowChannel,
      contentThumbnailPath: obj.ContentThumbnailPath,
      IsSplitAbEnabled: obj.IsSplitAbEnabled,
      activeChannel: obj.ActiveChannel,
      channelDetailId: obj.ChannelDetailID,
      scheduleDate: obj.ScheduleDate,
    };
    return split;
  };

  createSplitError = () => {
    let splitError = {
      errBodyMsg: null,
      errSendTestMailMsg: null,
      errSubjectLineMsg: null,
      errScheduleDateMsg: null,
      errEnableUnSubMsg: null,
      errUnSubMsg: null,
    };
    return splitError;
  };

  buttonClick = (clicked) => {
    this.setState({ clicked: clicked, isShow: true });
  };

  changeFullScreen = () => {
    var isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
      this.setState({
        iconClass: "icon-sd-collapse-large",
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.setState({
        iconClass: "icon-sd-expand-large",
      });
    }
  };

  backToCanvas = () => {
    const sessionStr = localStorage.getItem("session");
    const sessionObject = JSON.parse(sessionStr);
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    const campId = window.btoa(this.state.campaignId);
    let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
    window.location.href =
      this.state.mdcUrl +
      "?CampId=" +
      campId +
      "&accessToken=" +
      encodeURIComponent(accessToken) + '&from=' + fromEnvi;
  };

  header = (props) => {
    return (
      <div className={`even mt10 rich-push-tab s ${props.editClickOff}`}>
        <div className="campaign-tab-bar my0 position-relative">
          {this.state.errTabSelect && (
            <div className="validation-message top-30">{this.state.errTabSelect}</div>
          )}
          <Row className="d-flex align-items-center custom-column column-tab">
            <Col md={{ span: 3, offset: 0 }}>
              <label>Email content </label>
            </Col>
            <Col md={6} className="pl0">
              <RTabbar
                defaultSelectedItem={this.state.activeTab}
                defaultClass="tabDefault"
                dynamicTab="mb0 rs-tab-icon or-tab flex-tab"
                activeClass="tabDefault active"
                tabData={this.tabs}
                or={true}
                callBack={(item, index) => {
                  let tempEmailData = { ...this.state.emailData };
                  tempEmailData.data.content[0].contentType = item.contentType;
                  this.setState({ errTabSelect: null, emailData: tempEmailData });
                  this.updateTabStatus(index);
                }}
              />
            </Col>
          </Row>
          {this.state.activeTab !== -1 && (
            <div className="refresh-icon">
              <RSPTooltip text="Refresh" position="top">
                <i
                  className="icon-sd-refresh-medium icons-md blue"
                  onClick={() => {
                    this.setState({
                      isRefreshModal: !this.state.isRefreshModal,
                      headerMsg: ''
                    });
                  }}
                />
              </RSPTooltip>
            </div>
          )}
        </div>
      </div>
    );
  };

  splitValidation = (index) => {
    let splitContent = this.state.emailData.data.content[index];
    if (isEmpty(splitContent.subjectLine)) {
      let temp = [...this.state.splitContentError];
      temp[index].errSubjectLineMsg = SUBJECT_LINE;
      this.setState({ splitContentError: temp });
      return false;
    } else if (splitContent.subjectLine.length > 500) {
      let temp = [...this.state.splitContentError];
      temp[index].errSubjectLineMsg = "Subject line should not be more than 500 characters";
      this.setState({ splitContentError: temp });
      return false;
    }
    if (isEmpty(splitContent.body)) {
      let temp = [...this.state.splitContentError];
      temp[index].errBodyMsg = BODY;
      this.setState({ splitContentError: temp });
      return false;
    }
    if (
      splitContent.isFooterEnabled &&
      splitContent.unsubscribeSettingId === 0
    ) {
      let temp = [...this.state.splitContentError];
      temp[index].errEnableUnSubMsg = "Enable unsubscribe";
      temp[index].errUnSubMsg = "Select unsubscribe";
      this.setState({ splitContentError: temp });
      return false;
    }
    if (isEmpty(splitContent.localBlastDateTime)) {
      let temp = [...this.state.splitContentError];
      temp[index].errScheduleDateMsg = SCHEDULE_DATE;
      this.setState({ splitContentError: temp });
      return false;
    }
    return true;
  };

  getStartDate = (startDate) => {
    let campaignStartDate = new Date(startDate);
    let today = new Date();
    if (campaignStartDate > today) {
      campaignStartDate = new Date(startDate);
    } else {
      campaignStartDate = new Date();
    }
    return campaignStartDate
  }

  templateValidation = () => {
    let validate = true;
    if (isEmpty(this.state.senderName)) {
      this.setState({ errSenderNameMsg: SENDER_NAME });
      validate = false;
    }
    if (this.state.errSenderEmailMsg === null) {
      if (isEmpty(this.state.senderEmailAddress)) {
        this.setState({ errSenderEmailMsg: SENDER_EMAIL_ADDRESS });
        validate = false;
      }
    } else {
      validate = false;
    }
    if (this.state.errSenderDomainMsg === null) {
      if (!!!this.state.activeDomain || this.state.activeDomain?.smtpDomainSettingId === -1) {
        this.setState({ errSenderDomainMsg: SELECT_DOMAIN });
        validate = false;
      }
    } else {
      validate = false;
    }
    if (this.state.isReplyMail) {
      if (this.state.errReplyEmailMsg === null) {
        if (isEmpty(this.state.alternateEmailAddress)) {
          this.setState({ errReplyEmailMsg: SENDER_EMAIL_ADDRESS });
          validate = false;
        }
      } else {
        validate = false;
      }
      if (this.state.errReplyDomainMsg === null) {
        if (!!!this.state.activeReplyDomain || this.state.activeReplyDomain?.smtpDomainSettingId === -1) {
          this.setState({ errReplyDomainMsg: SELECT_DOMAIN });
          validate = false;
        }
      } else {
        validate = false;
      }
    }

    if (isEmpty(this.state.subjectLine)) {
      this.setState({ errSubjectLineMsg: SUBJECT_LINE });
      validate = false;
    }

    return validate
  }

  validation = (testPreviewMail = false) => {
    if (isEmpty(this.state.senderName)) {
      this.setState({ errSenderNameMsg: SENDER_NAME });
      this.inputRef.current.focus();
      return false;
    }
    if (this.state.errSenderEmailMsg === null) {
      if (isEmpty(this.state.senderEmailAddress)) {
        this.setState({ errSenderEmailMsg: SENDER_EMAIL_ADDRESS });
        return false;
      }
    } else {
      return false;
    }
    if (this.state.errSenderDomainMsg === null) {
      if (!!!this.state.activeDomain || this.state.activeDomain?.smtpDomainSettingId === -1) {
        this.setState({ errSenderDomainMsg: SELECT_DOMAIN });
        return false;
      }
    } else {
      return false;
    }
    if (this.state.isReplyMail) {
      if (this.state.errReplyEmailMsg === null) {
        if (isEmpty(this.state.alternateEmailAddress)) {
          this.setState({ errReplyEmailMsg: SENDER_EMAIL_ADDRESS });
          return false;
        }
      } else {
        return false;
      }
      if (this.state.errReplyDomainMsg === null) {
        if (this.state.activeReplyDomain.smtpDomainSettingId === -1) {
          this.setState({ errReplyDomainMsg: SELECT_DOMAIN });
          return false;
        }
      } else {
        return false;
      }
    }

    let isSplitComplete = true;
    if (!this.state.isSplit) {
      if (isEmpty(this.state.subjectLine)) {
        this.setState({ errSubjectLineMsg: SUBJECT_LINE });
        return false;
      } else if (this.state.subjectLine.length > 500) {
        this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
        return false;
      }
      if (this.state.activeTab === -1) {
        this.setState({ errTabSelect: EMAIL_CONTENT });
        return false;
      }
      if (this.state.emailData.data.content[0].contentType === "R") {
        if (isEmpty(this.state.emailData.data.content[0].body)) {
          this.setState({ errBodyMsg: BODY });
          return false;
        }
      }
      if (this.state.emailData.data.content[0].contentType === "Z") {
        if (isEmpty(this.state.emailData.data.content[0].body)) {
          this.setState(prevState => ({
            defaultContentError: { ...prevState.defaultContentError, errImportContent: 'Please upload EDM' }
          }))
          return false;
        }
      }
      if (this.state.emailData.data.content[0].body === null) {
        return false;
      }
      if (this.state.isAddFooter && !this.state.checked) {
        this.setState({
          errEnableUnSubMsg: "Enable unsubscribe",
        });
        return false;
      }
      if (this.state.isAddFooter && this.state.checked && (!this.state.unsubscription || this.state.unsubscription.unsubscribeSettingId === 0)) {
        this.setState({ errSelectUnSubMsg: 'Select unsubscribe' })
        return false;
      }
      if (this.state.checked && (!this.state.unsubscription || this.state.unsubscription.unsubscribeSettingId === 0)) {
        this.setState({ errSelectUnSubMsg: 'Select unsubscribe' })
        return false;
      }
      if (this.state.levelNumber <= 1 && !testPreviewMail) {
        if (isEmpty(this.state.schedule)) {
          this.setState({ errScheduleDateMsg: SCHEDULE_DATE });
          return false;
        } else {
          let currentTime = getCurrentTimeWithTimeZone(new Date(), this.state.timeZone.timezoneId, 15)
          if (new Date(this.state.schedule) < new Date(currentTime)) {
            this.setState({ errScheduleDateMsg: "Select a time greater from (" + currentTime.toLocaleString().trim() + ")" });
            return false;
          }
        }
        // if (new Date() > new Date(this.state.schedule)) {
        //   this.setState({ errScheduleDateMsg: "Choose at least 15 minutes greater than current time" });
        //   return false;
        // }
        // if (new Date() < new Date(this.state.schedule) && !this.handleTimeDiff()) {
        //   this.setState({ errScheduleDateMsg: "Choose at least 15 minutes greater than current time" });
        //   this.handleScheduleMinimumDate(this.state.startCampaignDate);
        //   return false;
        // }
      }

    } else {
      let i = 0;
      this.state.emailData.data.content.forEach((element) => {
        isSplitComplete = this.splitValidation(i);
        if (!isSplitComplete) {
          this.setState({ splitTabIndex: i });
          return;
        }
        i++;
      });
    }
    /*
    if(isEmpty(this.state.headerMsg)) {
     this.setState({ errPreHeaderMsg : PRE_HEADER_MESSAGE });
      return false;
    }
    */
    let enable = true;
    if (this.state.isRequestForApproval && this.state.approvalList.filter((item) => item.appErr !== null).length === 0) {
      this.state.approvalList.map((item, index) => {
        if (item.approverEmail.email === "" || item.approverEmail.email === "-- Select --") {
          enable = false;
          let temp = [...this.state.approvalList];
          temp[index].appErr = APPROVAL_MAIL;
          this.setState({ approvalList: temp });
          return;
        } else {
          let temp = [...this.state.approvalList];
          temp[index].appErr = null;
          this.setState({ approvalList: temp });
          enable = true;
        }
      });
    } else {
      enable = this.state.isRequestForApproval ? false : true
    }
    if (!isSplitComplete || !enable) {
      return false;
    } else {
      return true;
    }
  };

  SplitComponents = (props) => {
    let components = [...this.state.emailData.data.content];
    let errorIndex = 0;
    let tempTabData = [...this.state.splitTabData];
    this.state.emailData.data.content.forEach((element) => {
      if (this.state.splitContentError[errorIndex] === undefined) {
        this.state.splitContentError.push(this.createSplitError());
      }
      if (tempTabData[errorIndex] === undefined) {
        var add = tempTabData.length;
        var charCode = "A".charCodeAt(0);
        add = charCode + add;
        var obj = {
          id: add,
          text: "Split " + String.fromCharCode(add),
        };
        tempTabData.push(obj);
        this.setState({ splitTabData: tempTabData });
      }
      errorIndex++;
    });

    return (
      <>
        <Tabs
          activeKey={"tab" + this.state.splitTabIndex}
          id="uncontrolled-tab-example"
          className="mb30 borderB0"
        >
          {this.state.emailData.data.content.map((item, index1) => {
            return (
              <Tab eventKey={"tab" + index1} key={index1}>
                <SplitContent
                  personalizeTabData={this.state.subjectLinePersonalizeData}
                  emailData={this.state.emailData}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  splitEmailData={components[index1]}
                  splitError={this.state.splitContentError[index1]}
                  //campaignHeader={this.props.history.location.state.campaignHeader}
                  getEmailFooterContent={(value) => {
                    this.setState({ emailfooterContent: value });
                  }}
                  onValidateSearch={(status) => {
                    let isValidate = this.validation();
                    this.setState({ isSearchValidate: isValidate });
                  }}
                  isSearchValidate={this.state.isSearchValidate}
                  onChangeIsSearchValidate={() => {
                    this.setState({ isSearchValidate: false });
                  }}
                  onValidateSpamCheck={(status) => {
                    let isValidateSpam = this.validation('draft');
                    this.setState({ isSpamCheckValidate: isValidateSpam });
                  }}
                  isSpamCheckValidate={this.state.isSpamCheckValidate}
                />
              </Tab>
            );
          })}
        </Tabs>
      </>
    );
  };
  // method for clear input in approval request
  handleCloseInput = (index) => {
    let temp = [...this.state.approvalList];
    temp[index].input = false
    temp[index].appErr = null
    temp[index].approverEmail = {
      email: "-- Select --",
      userid: 0,
    };
    // let tempErrorMsg = [...this.state.requestForErrorMsg];
    // tempErrorMsg[index].approverEmailMsg = null;
    this.setState({
      approvalList: temp,
      //   requestForErrorMsg: tempErrorMsg,
      isSendMsgTo: false,
      sendMailToAddress: "",
    });
  };



  // method for onblur in request approval inputs fields

  hanldeBlurInputs = (text, index) => {
    let emails = text.split(",");
    emails.map((item, eIndex) => {
      if (!validateEmail(item)) {
        let tempApprovalList = [...this.state.approvalList];
        tempApprovalList[index].appErr = ENTER_VALID_EMAIL
        this.setState({
          approvalList: tempApprovalList,
        });
        return;
      } else if (this.state.approvalList.filter((approval) => approval.approverEmail.email === item).length > 1) {
        let tempApprovalList = [...this.state.approvalList];
        tempApprovalList[index].appErr = "Approver's mail shouldn't be the same"
        this.setState({
          approvalList: tempApprovalList,
        });
        return;
      }
    });
  };

  // method for onchange in request approval inputs fields
  handleChnageInputs = (text, index) => {
    let getMailvalid = this.getValidMail(text)

    if (!getMailvalid) {
      let tempValid = [...this.state.approvalList]
      tempValid[index].appErr = "Approver's mail shouldn't be the same"
      this.setState({
        errSendMailMsg: tempValid,
      });
    }
    if (text.split(",").length <= 5) {
      let tempEmailData = { ...this.state.emailData };
      tempEmailData.testCampaignEmailAddress = text;
      this.setState({
        sendMailToAddress: text,
        errSendMailMsg: null,
        emailData: tempEmailData
      });
      let temp = [...this.state.approvalList];
      temp[index].approverEmail = {
        "email": text,
        "userid": 0
      }
      temp[index].appErr = null;
      let tempErrorMsg = [...this.state.requestForErrorMsg];
      //   tempErrorMsg[index].approverEmailMsg = null;
      this.setState({
        approvalList: temp,
        requestForErrorMsg: tempErrorMsg,
        isSendMsgTo: false,
        sendMailToAddress: text,
      });
    } else {
      let temp = [...this.state.approvalList];
      temp[index].appErr = "Send email ids should not be more than 5";
      this.setState({
        approvalList: temp,
        errSendMailMsg: "Send email ids should not be more than 5",
      });
    }
  };
  getValidMail = (mail) => {
    let temp = [...this.state.approvalList];
    let valid = true
    temp.forEach((e) => {
      if (mail === e.approverEmail.email) {
        valid = false
      }
    })
    return valid
  }
  // method for onchange on request approval dropdown
  handleChangeRequest = (event, index) => {
    let getMailvalid = this.getValidMail(event.target.value.email)
    let tempValid = [...this.state.approvalList]

    if (!getMailvalid) {
      tempValid[index].appErr = "Approver's mail shouldn't be the same"
    } else {
      tempValid[index].appErr = null
    }
    this.setState({
      errSendMailMsg: tempValid,
    });
    if (event.target.value.userid !== -1) {
      let temp = [...this.state.approvalList];
      temp[index].approverEmail = event.target.value;
      let tempErrorMsg = [...this.state.requestForErrorMsg];
      //   tempErrorMsg[index].approverEmailMsg = null;
      let tempEmailData = { ...this.state.emailData };
      tempEmailData.testCampaignEmailAddress = event.target.value.email;
      this.setState({
        approvalList: temp,
        requestForErrorMsg: tempErrorMsg,
        isSendMsgTo: false,
        sendMailToAddress: event.target.value.email,
        emailData: tempEmailData
      });
    } else {
      let temp = [...this.state.approvalList];
      temp[index].input = true;
      temp[index].approverEmail = {
        "email": "",
        "userId": -1
      }
      let tempErrorMsg = [...this.state.requestForErrorMsg];
      this.setState({
        approvalList: temp,
        requestForErrorMsg: tempErrorMsg,
        isSendMsgTo: false,
        sendMailToAddress: event.target.value.email,
      });
    }
  };

  // method for create or add a new inputs in approval for request
  handleAddRequest = (index) => {
    let app = [...this.state.approvalList];

    let dummyObj_ = {
      approverEmail: {
        "email": "-- Select --",
        "userid": 0
      },
      ismandatory: false,
      edmworlflowdetails: 0,
      priority: 1,
      ApprovalSentcount: 1,
      name: "approval",
      input: false,
      appErr: null
    }
    app.push(dummyObj_);
    this.setState({ approvalList: [...app] });
  };


  // method for remove inputs in request approval 
  handleRemoveRequest = (index) => {
    let app = [...this.state.approvalList];
    app.splice(index, 1);
    let tempApprovalSettings = { ...this.state.approvalSettings };
    tempApprovalSettings.approvalMatchCount = app.length > 2 ? 2 : 1;
    this.setState({
      approvalList: app,
      approvalSettings: tempApprovalSettings
    });
  };

  getVerificationLinks = (edmHtml) => {
    const hrefValues = [];
    const htmlDoc = new DOMParser().parseFromString(edmHtml, 'text/html');
    const anchorElements = htmlDoc.querySelectorAll('a');

    anchorElements.forEach(anchorElement => {
        const href = anchorElement.getAttribute('href');
        if (href) {
            hrefValues.push(href);
        }
    });

    let tempEdmLinks = [];
    hrefValues.forEach((item) => {
        let isValidLink = item.includes("http") ? item : '';
        if (isValidLink !== '') {
            let tempObject = { "LinkURL": isValidLink, "SDKStatus": false, "LinkStatus": false };
            tempEdmLinks.push(tempObject);
        }
    });
    let tempEmailData = { ...this.state.emailData };

    tempEmailData.data.content[0].linkVerificationLogDetail = tempEdmLinks;
    this.setState({emailData:tempEmailData})
}
  render() {
    const sessionStr = localStorage.getItem("session");
    const sessionObject = JSON.parse(sessionStr);
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    return (
      <LayoutPageWrapper>
        <Container>
          <Row>
            <Col md={12}>
              <div className="progress-steps-box mr--mdcemail">
                <div className="steps-label">
                  <small>Communication name:</small>
                  <RSPTooltip
                    text={
                      this.state.campaignName
                        ? window.atob(this.state.campaignName)
                        : ""
                    }
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {this.state.campaignName
                        ? window.atob(this.state.campaignName)
                        : ""}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label" style={{ "paddingRight": "-130px" }}>
                  <small>Duration:</small>
                  <RSPTooltip
                    text={
                      this.state.startCampaignDate && this.state.endCampaignDate
                        ? `${dateFormat(this.state.startCampaignDate)} - ${dateFormat(
                          this.state.endCampaignDate
                        )}`
                        : ""
                    }
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {this.state.startCampaignDate && this.state.endCampaignDate
                        ? `${dateFormat(this.state.startCampaignDate)} - ${dateFormat(
                          this.state.endCampaignDate
                        )}`
                        : ""}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label" style={{ "paddingRight": "-130px" }}>
                  <small>Type:</small>
                  <RSPTooltip
                    text={this.state.attributeName ? this.state.attributeName : ""}
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {this.state.attributeName ? this.state.attributeName : ""}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label" style={{ "paddingRight": "-130px" }}>
                  <small>Primary communication goal:</small>
                  <RSPTooltip
                    text={
                      this.state.primaryCampaignGoal
                        ? this.state.primaryCampaignGoal
                        : ""
                    }
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {this.state.primaryCampaignGoal
                        ? this.state.primaryCampaignGoal
                        : ""}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Potential audience:</small>
                  <RSPTooltip
                    text={
                      this.state.potentialAudience !== "" ? this.state.potentialAudience : "NA"
                    }
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {this.state.potentialAudience !== ""
                        ? this.state.potentialAudience
                        : "NA"}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label-row-icon d-flex align-items-center justify-content-end">
                  <RSPTooltip
                    text={this.state.iconClass.includes("collapse") ? "Normal view" : "Full screen view"}
                    position="bottom"
                  ><i
                    onClick={this.changeFullScreen}
                    className={`icons-md white large-icon cp bgBlue ${this.state.iconClass}`}
                  ></i>
                  </RSPTooltip>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={12}>
              <div className="header-title d-flex justify-content-between">
                <h1>e-mail content</h1>
                <div className="back-text">
                  <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                  <a onClick={this.backToCanvas}>Back to canvas</a>
                </div>
              </div>
              <div className={`portlet-box-theme bgWhite padding20 `}>
                <div className="mt20 ml20-del posr">
                  {/* split ab */}
                  {this.state.isShowSplitSizeModal && <div class="darkfade"></div>}
                  <Row className={`custom-column ${this.state.disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Sender name</label>
                    </Col>
                    <Col md={6}>
                      <RSInput2
                        ph="Enter sender name"
                        val={this.state.senderName}
                        required={true}
                        max={100}
                        cb={(text) => {
                          let errMsg = text === "" ? SENDER_NAME : null;
                          // this.state.emailData.data.senderName = text;
                          // this.setState({
                          //   senderName: text,
                          //   errSenderNameMsg: errMsg,
                          // });
                          let prevState = this.state;
                          prevState.errSenderNameMsg = errMsg;
                          prevState.senderName = text;
                          prevState.emailData.data.senderName = text;
                          this.setState({ ...prevState })
                        }}
                        errorMessage={this.state.errSenderNameMsg}
                      ></RSInput2>
                      {this.state?.personalizeTabData?.length > 0 ?
                        <RSPTooltip text="Personalize" position="top">
                          <div className="input-outer-set">

                            {/* <div className="title-tip title-tip-up" title='Personalize'> */}
                            <Tooltip anchorElement="target" position="top">
                              <DropDownButton
                                className={this.state?.personalizeTabData?.length > 0 ? "" : "click-off"}
                                items={this.state.personalizeTabData}
                                icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
                                onItemClick={(e) => {
                                  let prevState = this.state;
                                  prevState.senderName = e.item;
                                  prevState.emailData.data.senderName = e.item;
                                  this.setState({ ...prevState })
                                }}
                              />
                            </Tooltip>
                            {/* </div> */}
                          </div>
                        </RSPTooltip> :
                        <div className="input-outer-set">
                          <DropDownButton
                            className={this.state?.personalizeTabData?.length > 0 ? "" : "click-off"}
                            items={this.state.personalizeTabData}
                            icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
                            onItemClick={(e) => {
                              let prevState = this.state;
                              prevState.senderName = e.item;
                              prevState.emailData.data.senderName = e.item;
                              this.setState({ ...prevState })
                            }}
                          />
                        </div>}
                    </Col>
                  </Row>
                  <Row className={`custom-column ${this.state.disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Sender's email address</label>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          <RSInput2
                            ph="Username"
                            val={this.state.senderEmailAddress}
                            required={true}
                            max={64}
                            cb={(text) => {
                              let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                              if (errMsg === null) {
                                let regex = /^[A-Za-z0-9._-]*$/
                                if (regex.test(text)) {
                                  this.setState({
                                    senderEmailAddress: text,
                                    errSenderEmailMsg: errMsg,
                                  });
                                } else {
                                  this.setState({
                                    errSenderEmailMsg: "Special characters are not allowed (except - _ .)",
                                  });
                                }
                              } else {
                                this.setState({
                                  senderEmailAddress: text,
                                  errSenderEmailMsg: errMsg,
                                });
                              }
                            }}
                            ob={(text) => {
                              // let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                              // if (errMsg === null) {
                              //     errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                              //     if (errMsg === null) {
                              //         this.validateSenderDomain(text.split("@")[1])
                              //     }
                              // }
                              // this.setState({ errSenderEmailMsg: errMsg });
                            }}
                            errorMessage={this.state.errSenderEmailMsg}
                          />
                        </Col>
                        <Col md={1}>
                          <div className="mt6">@</div>
                        </Col>
                        <Col md={6}>
                          <div className="position-relative">
                            {this.state.errSenderDomainMsg && (
                              <div className="validation-message">
                                {this.state.errSenderDomainMsg}
                              </div>)}
                            <RSDropdownList
                              data={this.state.activeDomainList}
                              className="mb10 required"
                              value={this.state.activeDomain}
                              defaultItem={{
                                "smtpDomainSettingId": -1,
                                "domainName": "-- Select domain --"
                              }}
                              textField='domainName'
                              dataItemKey='smtpDomainSettingId'
                              onChange={(event) => {
                                let errMsg = event.target.value.smtpDomainSettingId !== -1 ? null : SELECT_DOMAIN;
                                this.setState({
                                  activeDomain: event.target.value,
                                  errSenderDomainMsg: errMsg,
                                })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <RSInput2
                    ph="{info@domain.com}"
                    val={this.state.senderEmailAddress}
                    required={true}
                    cb={(text) => {
                      let errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                      let tempEmailData = { ...this.state.emailData };
                      tempEmailData.data.senderEmail = text;
                      this.setState({
                        emailData: tempEmailData,
                        senderEmailAddress: text,
                        errSenderEmailMsg: errMsg,
                      });
                    }}
                    ob={(text) => {
                      let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                      if (errMsg === null) {
                        errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                        if (errMsg === null) {
                          this.validateSenderDomain(text.split("@")[1])
                        }
                      }
                      this.setState({ errSenderEmailMsg: errMsg });
                    }}
                    errorMessage={this.state.errSenderEmailMsg}
                  /> */}
                      {/*
                  <div className="input-outer-set">
                    <RSPTooltip text="Personalize" position="top">
                      <div>
                        <DropDownButton
                          className=""
                          items={this.state.emailPersonlizeData}
                          icon=" icon-user-help-small blue icons-md"
                          onItemClick={(e) => {
                            this.setState({ senderEmailAddress: e.item });
                          }}
                        />
                      </div>
                    </RSPTooltip>
                  </div> */}
                      <div className="d-flex align-items-center mt-20 mb20">
                        <RSCheckbox
                          className="marginB0"
                          lbl="Reply email"
                          checked={this.state.isReplyMail}
                          cb={(status) => {
                            let tempEmailData = { ...this.state.emailData };
                            tempEmailData.data.isReplyMailEnabled = status;

                            let tempAlternateEmailAddress = this.state.alternateEmailAddress === "" ? this.state.senderEmailAddress : this.state.alternateEmailAddress;
                            let tempReplyDomain = (this.state.activeReplyDomain === undefined || this.state.activeReplyDomain.smtpDomainSettingId === -1) ? this.state.activeDomain : this.state.activeReplyDomain;
                            this.setState({ isReplyMail: status, emailData: tempEmailData, errReplyEmailMsg: null, alternateEmailAddress: tempAlternateEmailAddress, activeReplyDomain: tempReplyDomain, errReplyDomainMsg: null });
                            // if (!status) {
                            //   this.setState({ errReplyEmailMsg: null, alternateEmailAddress: '' });
                            // }
                          }}
                        />
                        <RSPPophover
                          text="Please note that the reply email will be sent to the set Sender’s email address"
                          position="top"
                        >
                          <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                        </RSPPophover>
                      </div>
                      {this.state.isReplyMail && (
                        <Row>
                          <Col md={5}>
                            <RSInput2
                              ph="Username"
                              val={this.state.alternateEmailAddress}
                              required={true}
                              max={64}
                              cb={(text) => {
                                let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                if (errMsg === null) {
                                  let regex = /^[A-Za-z0-9._-]*$/
                                  if (regex.test(text)) {
                                    this.setState({
                                      alternateEmailAddress: text,
                                      errReplyEmailMsg: errMsg,
                                    });
                                  } else {
                                    this.setState({
                                      errReplyEmailMsg: "Special characters are not allowed (except - _ .)",
                                    });
                                  }
                                } else {
                                  this.setState({
                                    alternateEmailAddress: text,
                                    errReplyEmailMsg: errMsg,
                                  });
                                }
                              }}
                              ob={(text) => {
                                // let errMsg = text === "" ? SENDER_EMAIL_ADDRESS : null;
                                // if (errMsg === null) {
                                //     errMsg = validateEmail(text) ? null : ENTER_VALID_EMAIL;
                                //     if (errMsg === null) {
                                //         this.validateSenderDomain(text.split("@")[1])
                                //     }
                                // }
                                // this.setState({ errSenderEmailMsg: errMsg });
                              }}
                              errorMessage={this.state.errReplyEmailMsg}
                            />
                          </Col>
                          <Col md={1}>
                            <div className="mt6">@</div>
                          </Col>
                          <Col md={6}>
                            <div className="position-relative">
                              {this.state.errReplyDomainMsg && (
                                <div className="validation-message">
                                  {this.state.errReplyDomainMsg}
                                </div>)}
                              <RSDropdownList
                                data={this.state.activeDomainList}
                                className="mb10 required"
                                value={this.state.activeReplyDomain}
                                defaultItem={{
                                  "smtpDomainSettingId": -1,
                                  "domainName": "-- Select domain --"
                                }}
                                textField='domainName'
                                dataItemKey='smtpDomainSettingId'
                                onChange={(event) => {
                                  let errMsg = event.target.value.smtpDomainSettingId !== -1 ? null : SELECT_DOMAIN;
                                  this.setState({ activeReplyDomain: event.target.value, errReplyDomainMsg: errMsg })
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        // <RSInput2
                        //   ph="Alternate email address"
                        //   className=""
                        //   required={true}
                        //   max={255}
                        //   val={this.state.alternateEmailAddress}
                        //   cb={(text) => {
                        //     let errMsg = text === "" ? REPLY_EMAIL : null;
                        //     let tempEmailData = { ...this.state.emailData };
                        //     tempEmailData.data.replyEmail = text;
                        //     this.setState({
                        //       emailData: tempEmailData,
                        //       alternateEmailAddress: text,
                        //       errReplyEmailMsg: errMsg,
                        //     });
                        //   }}
                        //   errorMessage={this.state.errReplyEmailMsg}
                        // />
                      )}
                    </Col>
                  </Row>
                  {this.state.levelNumber === 1 && (
                    <Row className="custom-column mb20 split-ab-row click-off">
                      <Col md={3}>
                        <label className="paddingT10">Split A/B test</label>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex align-items-center mb20">
                          <Switch
                            className={
                              this.state.potentialAudience < 100
                                ? "click-off"
                                : "mt0"
                            }
                            onLabel={"ON"}
                            offLabel={"OFF"}
                            onChange={(event) => {
                              let tempEmailData = { ...this.state.emailData };
                              tempEmailData.data.isSplitAB = event.target.value;
                              if (event.target.value) {
                                let temp = [...tempEmailData.data.content];
                                const urlResult = new URLSearchParams(
                                  window.location.search
                                );
                                let channelDetails =
                                  urlResult.get("ChannelDetails");
                                const object = JSON.parse(channelDetails);
                                temp[0].edmChannelId = object.ChannelDetailID;
                                temp[0].subjectLine = "";
                                temp[0].contentType = "";
                                temp[0].splitType = "A";
                                temp[0].statusId = 0;
                                temp[0].body = "";
                                temp[0].textContent = "";
                                temp[0].preHeaderMessage = "";
                                temp[0].spamScore = 3.0;
                                temp[0].isFooterEnabled = false;
                                temp[0].footerId = 0;
                                temp[0].unsubscribeSettingId = 0;
                                temp[0].contentUrl = "";
                                temp[0].htmlFilePath = "";
                                temp[0].previewId = 0;
                                temp[0].zipFilePath = "";
                                temp[0].isViewinBrowserChecked = true;
                                temp[0].templateId = 0;
                                temp[0].dynamiclistId = object.DynamiclistID;
                                temp[0].dataSource = object.dataSource;
                                temp[0].timezoneId =
                                  this.state.emailData.data.content[0].timezoneId;
                                temp[0].localBlastDateTime = "";
                                temp[0].actionId = object.ActionID;
                                temp[0].actionTime = object.ActionTime;
                                temp[0].actionTimeDuration =
                                  object.ActionTimeDuration;
                                temp[0].channelFriendlyName =
                                  object.ChannelFriendlyName;
                                temp[0].allOrAny = object.IsALLorAny;
                                temp[0].addOnLevel = object.addOnLevel;
                                temp[0].domId = object.DomId;
                                temp[0].channelDetailType =
                                  object.ChannelDetailType;
                                temp[0].channelId = object.ChannelId;
                                temp[0].flowChannel = object.FlowChannel;
                                temp[0].contentThumbnailPath =
                                  object.ContentThumbnailPath;
                                temp[0].IsSplitAbEnabled = object.IsSplitAbEnabled;
                                temp[0].activeChannel = object.ActiveChannel;
                                temp[0].channelDetailId = object.ChannelDetailID;
                                temp[0].scheduleDate = object.ScheduleDate;

                                var add = this.state.splitTabData.length;
                                var charCode = "A".charCodeAt(0);
                                add = charCode + (add - 1);
                                temp.push(
                                  this.createSplitObject(String.fromCharCode(add))
                                );
                                tempEmailData.data.content = temp;
                                this.setState({ isSplit: !this.state.isSplit, emailData: tempEmailData });
                              } else {
                                this.setState({
                                  isOpenSplitResetModal:
                                    !this.state.isOpenSplitResetModal,
                                });
                              }
                            }}
                            checked={this.state.isSplit}
                          />
                          {this.state.isSplit && (
                            <div className="d-flex align-items-center">
                              <i
                                className="icon-dropdown-small cp ml15"
                                onClick={() => {
                                  this.setState({
                                    isShowSplitSizeModal:
                                      !this.state.isShowSplitSizeModal,
                                  });
                                }}
                              ></i>
                              <i
                                className="icon-sd-settings-mini icons-md blue cp ml10"
                                onClick={() => {
                                  this.setState({
                                    isSplitSettings: !this.state.isSplitSettings,
                                  });
                                }}
                              />
                            </div>
                          )}
                          <RSPPophover
                            text="A minimum of 100 audience are required for split A/B testing. Check the communication listing page for results."
                            position="top"
                          >
                            <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp ml10"></i>
                          </RSPPophover>
                        </div>
                      </Col>
                      {/* split ab */}
                      {this.state.isShowSplitSizeModal && (
                        <>
                          <SplitSizeModal
                            isOpen={this.state.isShowSplitSizeModal}
                            splitSizeDetails={this.state.emailData.data.edmSplit}
                            onChangeIsOpen={(value) => {
                              this.setState({ isShowSplitSizeModal: value });
                            }}
                          />
                        </>
                      )}
                    </Row>
                  )}
                  {!this.state.isSplit && (
                    <Row className={`custom-column ${this.state.disableEdit}`}>
                      <Col md={3}>
                        <label className="label-row">Subject line</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          {this.state.errSubjectLineMsg && (
                            <div className="validation-message">
                              {this.state.errSubjectLineMsg}
                            </div>
                          )}
                          <input
                            type="textarea"
                            className="marginB5 required emoji-font"
                            placeholder="Enter subject line"
                            value={this.state.subjectLine}
                            onBlur={(event) => {
                              let tempEmailData = { ...this.state.emailData };
                              tempEmailData.data.content[0].subTitlePosition = event.target.selectionStart;
                              this.setState({ emailData: tempEmailData });
                            }}
                            onChange={(event) => {
                              if (event.target.value.length > 500) {
                                this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                                return;
                              } else {
                                let tempEmailData = { ...this.state.emailData };
                                tempEmailData.data.content[0].subjectLine = event.target.value;
                                let errMsg = event.target.value.length === 0 ? SUBJECT_LINE : null;
                                this.setState({
                                  subjectLine: event.target.value,
                                  errSubjectLineMsg: errMsg,
                                  emailData: tempEmailData
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="input-below-icon-set">
                          <div>
                            <DropDownButton
                              className={this.state?.personalizeTabData?.length > 0 ? "" : "click-off"}
                              items={this.state.subjectLinePersonalizeData}
                              // icon=" icon-user-help-small blue icons-md"
                              text={
                                <RSPTooltip text="Personalize" position="top">
                                  <i className="icon-sd-user-question-mark-edge-medium blue icons-md"></i>
                                </RSPTooltip>
                              }
                              onItemClick={(e) => {
                                let obj = this.state.emailData.data.content[0];
                                let value = " " + e.item + " ";
                                let val = getContent(obj.subjectLine, value, obj.subTitlePosition);
                                if (val.length > 500) {
                                  this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                                } else {
                                  let tempEmailData = { ...this.state.emailData };
                                  tempEmailData.data.content[0].subjectLine = val;
                                  tempEmailData.data.content[0].subTitlePosition = obj.subTitlePosition + value.length;
                                  let errMsg = val.length === 0 ? SUBJECT_LINE : null;
                                  this.setState({ subjectLine: val, errSubjectLineMsg: errMsg, emailData: tempEmailData });
                                }
                              }}
                            />
                          </div>
                          <RSPTooltip text="Emoji" position="top">
                            <div className="per-ico-ht marginT3 marginL10">
                              <i
                                className="icon-sd-circle-smile-medium blue icons-md cp"
                                ref={this.emojianochor}
                                onClick={() => {
                                  this.buttonClick(1);
                                }}
                              ></i>
                            </div>
                          </RSPTooltip>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {this.state.isSplit ? (
                    <div className="split-container">
                      <div className="tabs-filled-component-del d-flex align-items-center">
                        <RTabbar1
                          defaultSelectedItem={this.state.splitTabIndex}
                          defaultClass="tabDefault"
                          dynamicTab="addtab"
                          activeClass="tabDefault active"
                          tabData={this.state.splitTabData}
                          delete={true}
                          type="split"
                          mainClass="even-space-4"
                          deleteTabData={(removeIndex) => {
                            let tempData = [...this.state.splitTabData];
                            tempData.splice(removeIndex, 1);
                            let tempEmailData = { ...this.state.emailData }
                            let temp = [...tempEmailData.data.content];
                            temp.splice(removeIndex, 1);
                            tempEmailData.data.content = temp;
                            this.setState({ splitTabData: tempData, emailData: tempEmailData });
                          }}
                          callBack={(item, index) => {
                            this.setState({ splitTabIndex: index });
                          }}
                        />
                        {this.state.splitTabData.length < 2 && (
                          <i
                            className={`d-inline-block icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer ml15 ${this.state.editClickOff ? "click-off" : ""
                              }`}
                            onClick={() => {
                              let enable = true;
                              for (var i = 0; i < this.state.emailData.data.content.length; i++) {
                                enable = this.splitValidation(i);
                                if (!enable) {
                                  this.setState({ splitTabIndex: i });
                                  break;
                                }
                              }
                              // let enable = this.splitValidation();
                              if (enable) {
                                if (this.state.splitTabData.length < 2) {
                                  var add = this.state.splitTabData.length;
                                  var charCode = "A".charCodeAt(0);
                                  add = charCode + add;

                                  var obj = {
                                    id: add,
                                    text: "Split " + String.fromCharCode(add),
                                  };
                                  var list = [...this.state.splitTabData];
                                  list.push(obj);
                                  let tempEmailData = { ...this.state.emailData };
                                  let temp = [...tempEmailData.data.content];
                                  temp.push(
                                    this.createSplitObject(String.fromCharCode(add))
                                  );
                                  tempEmailData.data.content = temp;
                                  let errTemp = [...this.state.splitContentError];
                                  errTemp.push(this.createSplitError());
                                  this.setState({
                                    splitTabData: list,
                                    splitContentError: errTemp,
                                    splitTabIndex: this.state.splitTabData.length,
                                    emailData: tempEmailData
                                  });
                                }
                              }
                            }}
                          />
                        )}
                      </div>
                      {<this.SplitComponents />}
                    </div>
                  ) : (
                    <>
                      <this.header editClickOff={this.state.disableEdit} />
                      <div className={`marginT23`}>
                        {this.state.emailData.data.content[0].contentType === "R" ? (
                          <div className={`position-relative ${this.state.disableEdit}`}>
                            {this.state.errBodyMsg && (
                              <div className="validation-message switch-valid">
                                {this.state.errBodyMsg}
                              </div>
                            )}
                            <RESTextEditor
                              personalizeTabData={this.state.subjectLinePersonalizeData}
                              content={this.state.emailData.data.content[0].body}
                              callBack={(text, textContent) => {
                                let tempEmailData = { ...this.state.emailData };
                                tempEmailData.data.content[0].body = textContent;
                                tempEmailData.data.content[0].textContent = text;
                                let errMsg = text !== "" ? null : BODY;
                                this.setState({ errBodyMsg: errMsg, emailData: tempEmailData });
                              }}
                              getErrorMessage={(errorMessage) => {
                                this.setState({ errBodyMsg: errorMessage });
                              }}
                            />
                          </div>
                        ) : this.state.emailData.data.content[0].contentType === "Z" ? (
                          <ImportTabContent
                            emailData={this.state.emailData.data}
                            contentData={this.state.emailData.data.content[0]}
                            defaultError={this.state.defaultContentError}
                            getBodyContent={(bodyContent) => {
                              let tempEmailData = { ...this.state.emailData };
                              tempEmailData.data.content[0].body = bodyContent;
                              this.setState({ emailData: tempEmailData });

                              if (tempEmailData.data.content[0].body === null) {
                                this.setState(prevState => ({
                                  defaultContentError: { ...prevState.defaultContentError, errImportContent: 'Invalid file upload' }
                                }))
                              } else if (!isEmpty(bodyContent)) {
                                this.setState(prevState => ({
                                  defaultContentError: { ...prevState.defaultContentError, errImportContent: null }
                                }))
                              }
                            }}
                            getHeader={(value) => {
                              let tempEmailData = { ...this.state.emailData };
                              tempEmailData.data.content[0].preHeaderMessage = value;
                              this.setState({ emailData: tempEmailData, headerMsg: value });
                            }}
                            getBroweser={(value) => {
                              let tempEmailData = { ...this.state.emailData };
                              tempEmailData.data.content[0].isViewinBrowserChecked = value;
                              this.setState({ emailData: tempEmailData, isAddBrow: value });
                            }}
                            headerData={this.state.headerMsg}
                            isAddBrowser={this.state.isAddBrow}
                            onShowFooter={(value) => {
                              this.setState({ isShowFooterComponent: value });
                            }}
                            onValidateSpamCheck={(status) => {
                              let isValidateSpam = this.validation('draft');
                              this.setState({ isSpamCheckValidate: isValidateSpam });
                            }}
                            isSpamCheckValidate={this.state.isSpamCheckValidate}
                          />
                        ) : this.state.emailData.data.content[0].contentType === "T" ? (
                          <div className="tabs-filled-component-del">
                            <Template
                              segList={this.state.segList}
                              validation={this.templateValidation}
                              emailData={this.state.emailData.data}
                              activeDomain={this.state.activeDomain}
                              senderEmailAddress={this.state.senderEmailAddress}
                              activeReplyDomain={this.state.activeReplyDomain}
                              alternateEmailAddress={this.state.alternateEmailAddress}
                              isReplyMail={this.state.isReplyMail}
                              campaignId={this.state.campaignId}
                              isUpdate={this.state.mode === "edit" ? true : false}
                              edmChannelId={this.state.edmChannelId}
                              sendMailToAddress={this.state.sendMailToAddress}
                              createEmail={createEmailCampaign}
                              levelNumber={this.state.levelNumber}
                            />
                          </div>
                        ) : null}
                        {this.state.isAddFooter && (
                          <div className="mt20 mb30">
                            <iframe
                              id='footerFrame'
                              title="footerFrame"
                              className="teeeeeest2"
                              srcDoc={this.handleEmailFotterDisable(this.state.emailFooterHtml)}
                              style={{
                                width: "100%",
                                // pointerEvents: "none"
                              }}
                            ></iframe>
                          </div>
                        )}

                      </div>

                      {this.state.emailData.data.content[0].contentType === "R" && (
                        <Row className={`custom-column mt30 ${this.state.disableEdit}`}>
                          <Col md={3}>
                            <label className="label-row">Pre header message</label>
                          </Col>
                          <Col md={6}>
                            <RSInput2
                              ph="Enter inbox first line message"
                              val={this.state.headerMsg}
                              cb={(text) => {
                                let errMsg = text === "" ? PRE_HEADER_MESSAGE : null;
                                let tempEmailData = { ...this.state.emailData };
                                tempEmailData.data.content[0].preHeaderMessage = text;
                                let tempError = { ...this.state.defaultContentError };
                                tempError.errPreHeaderMsg = errMsg;
                                this.setState({
                                  headerMsg: text,
                                  errPreHeaderMsg: errMsg,
                                  emailData: tempEmailData,
                                  defaultContentError: tempError
                                });
                              }}
                              errorMessage={this.state.errPreHeaderMsg}
                            />
                          </Col>
                          <Col md={4}>
                            <RSCheckbox
                              className="mb0 mt10"
                              lbl="Add view in browser"
                              checked={this.state.isAddViewBrowser}
                              cb={(status) => {
                                let tempEmailData = { ...this.state.emailData };
                                tempEmailData.data.content[0].isViewinBrowserChecked = status;
                                this.setState({ isAddViewBrowser: status, emailData: tempEmailData });
                              }}
                            />
                          </Col>
                        </Row>
                      )}

                      {this.state.emailData.data.content[0].contentType === "R" ||
                        this.state.isShowFooterComponent ? (
                        <Row className={`custom-column mt30 mb30 d-flex align-items-center ${this.state.disableEdit}`}>
                          <Col md={4}>
                            <RSPTooltip text="Unsubscription message" position="top">
                              <label className="label-row truncate-line-1 top3 posr">Unsubscription message</label>
                            </RSPTooltip>
                          </Col>
                          <Col md={1}>
                            <div className="position-relative">
                              {this.state.errEnableUnSubMsg && (
                                <div className="validation-message switch-valid wsp">
                                  {this.state.errEnableUnSubMsg}
                                </div>
                              )}
                              <Switch
                                className="mt0 mr15"
                                checked={this.state.checked}
                                onChange={(e) => {
                                  this.setState({
                                    checked: !this.state.checked,
                                    errEnableUnSubMsg: null,
                                  });
                                  if (!e.value) {
                                    this.setState({ unsubscription: { unsubscribeSettingId: 0, unsubscribeName: "-- Select --" } })
                                  }
                                  if (this.state.isAddFooter && !e.value) {
                                    this.setState({
                                      checked: !this.state.checked,
                                      errEnableUnSubMsg: "Enable unsubscribe",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            {this.state.checked && (
                              <div className="position-relative top-3">
                                {this.state.errSelectUnSubMsg && (
                                  <div className="validation-message switch-valid">
                                    {this.state.errSelectUnSubMsg}
                                  </div>
                                )}
                                <RSDropdownList
                                  className="mb0 required"
                                  data={this.state.unsubscriptionList || []}
                                  defaultItem={{
                                    unsubscribeSettingId: 0,
                                    unsubscribeName: "-- Select --",
                                  }}
                                  textField="unsubscribeName"
                                  dataItemKey="unsubscribeSettingId"
                                  value={this.state.unsubscription}
                                  onChange={(event) => {
                                    if (event.target.value.unsubscribeSettingId === 0 && this.state.isAddFooter) {
                                      let prevState = this.state;
                                      prevState.errSelectUnSubMsg = 'Select unsubscribe';
                                      prevState.unsubscription = event.target.value;
                                      prevState.emailData.data.content[0].unsubscribeSettingId = event.target.value.unsubscribeSettingId;
                                      this.setState({ ...prevState })
                                    } else {
                                      let prevState = this.state;
                                      prevState.errSelectUnSubMsg = null;
                                      prevState.unsubscription = event.target.value;
                                      prevState.emailData.data.content[0].unsubscribeSettingId = event.target.value.unsubscribeSettingId;
                                      this.setState({ ...prevState })

                                    }
                                  }}
                                />
                              </div>
                            )}

                          </Col>
                          <Col className="padding0">
                            <div className="d-flex justify-content-end">
                              <ul className="d-flex  align-items-center justify-content-center">
                                <li>
                                  <RSCheckbox
                                    className="mt0 mb0"
                                    lbl="Add email footer"
                                    checked={this.state.isAddFooter}
                                    cb={(status) => {
                                      (status && !this.state.checked) ? this.setState({ errEnableUnSubMsg: "Enable unsubscribe" }) : this.setState({ errEnableUnSubMsg: null })
                                      let tempEmailData = { ...this.state.emailData };
                                      tempEmailData.data.content[0].isFooterEnabled = status;
                                      tempEmailData.data.content[0].footerId =
                                        this.state.emailFooterList.length > 0
                                          ? this.state.emailFooterList[0]
                                            .emailfooterID
                                          : 0;
                                      this.setState({
                                        isAddFooter: status,
                                        emailData: tempEmailData,
                                        emailFooterHtml:
                                          this.state.emailFooterList.length > 0
                                            ? this.state.emailFooterList[0]
                                              .emailFooterRawcode
                                            : "",
                                      });
                                      try {
                                        setTimeout(() => {
                                          // Get the height of the content inside the iframe
                                          let iframe = document.getElementById("footerFrame");
                                          let contentHeight = iframe?.contentWindow?.document?.body?.scrollHeight;

                                          // Set the height of the iframe to match the content height
                                          iframe.style.height = contentHeight + 25 + "px";
                                        }, 2000)
                                      } catch (error) {
                                      }
                                    }}
                                  />
                                </li>
                                <li className="d-flex justify-content-center cp" onClick={() => {
                                  this.setState({ isEmailPreviewModal: !this.state.isEmailPreviewModal });
                                }}>
                                  <span>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;Email preview
                                  </span>
                                  {/* <RSPTooltip text="Email preview" position="top"> */}
                                  <i className="icon-sd-email-preview-medium icons-md blue ml5 mr15 cp position-relative top-5" />
                                  {/* </RSPTooltip> */}
                                </li>
                                {this.state.isAddFooter && (
                                  <>
                                    <li>
                                      <RSPTooltip
                                        text="Email footer template"
                                        position="top"
                                      >
                                        <div>
                                          {this.state.emailFooterList && (
                                            <BootstrapDropdownIcon
                                              data={this.state.emailFooterList}
                                              defaultItem={this.state.emailFooter}
                                              customAlignRight={true}
                                              className="fs18-btn mr0 position-relative top-3"
                                              alignRight
                                              fieldKey={"footername"}
                                              fixedIcon={
                                                <i className="icon-sd-email-footer-medium icons-md blue posr t2" />
                                              }
                                              onSelect={(item, index) => {
                                                let tempEmailData = { ...this.state.emailData };
                                                tempEmailData.data.content[0].footerId = item.emailfooterID;
                                                this.setState({
                                                  emailFooterHtml: item.emailFooterRawcode,
                                                  emailData: tempEmailData
                                                });
                                              }}
                                            />
                                          )}
                                        </div>
                                      </RSPTooltip>
                                    </li>
                                    {/* <li id="1">
                                          <i className="icon-sd-circle-plus-fill-edge-medium icons-md blue posr t2 cp"
                                              onClick={() => {
                                                  this.props.history.push({
                                                      pathname: '/preference/communication-settings',
                                                      state: { index: 2 }
                                                  })
                                              }}
                                          />
                                      </li> */}
                                  </>
                                )}
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {this.state.isEmailPreviewModal && (
                        // <EmailPreviewModal
                        //   isOpen={this.state.isEmailPreviewModal}
                        //   previewContent={
                        //     this.state.isAddFooter
                        //       ? this.state.emailData.data.content[0].body +
                        //       this.state.emailFooterHtml
                        //       : this.state.emailData.data.content[0].body
                        //   }
                        //   onChangeIsOpen={(value) => {
                        //     this.setState({ isEmailPreviewModal: value });
                        //   }}
                        //   onValidateSearch={(status) =>
                        //     this.state.onValidateSearch(status)
                        //   }
                        //   isSearchValidate={this.state.isSearchValidate}
                        // />
                        <EmailPreviewModal
                          isOpen={this.state.isEmailPreviewModal}
                          previewContent={this.state.emailData.data.content[0]}
                          emailData={this.state.emailData}
                          campaignId={this.state.campaignId}
                          edmChannelId={this.state.edmChannelId}
                          activeDomain={this.state.activeDomain}
                          senderEmailAddress={this.state.senderEmailAddress}
                          activeReplyDomain={this.state.activeReplyDomain}
                          alternateEmailAddress={this.state.alternateEmailAddress}
                          isReplyMail={this.state.isReplyMail}
                          footerContent={!!this.state.emailFooterHtml ? this.state.emailFooterHtml : ""}
                          onChangeIsOpen={(value) => {
                            if (value) {
                              this.setState({ isOpenLiveSuccessModal: true, isEmailPreviewModal: false });
                            } else {
                              this.setState({ isEmailPreviewModal: false });
                            }
                          }}
                          getEdmChannelId={(value) => {
                            let temp = { ...this.state.emailData }
                            temp.data.content[0].edmChannelId = value;
                            this.setState({ edmChannelId: value, emailData: temp });
                          }}
                          onValidateSearch={(status) => {
                            let isValidate = this.validation(true);
                            this.setState({ isSearchValidate: isValidate });
                          }}
                          isSearchValidate={this.state.isSearchValidate}
                        />
                      )}
                      {this.state.levelNumber === 1 && (
                        <div className="mark-mdc-email schedule-even marginT30">
                          <Row className={`custom-column paddingB10 ${this.state.disableEdit}`}>
                            <Col md={3}>
                              <label className="label-row">Schedule</label>
                            </Col>
                            <Col md={6}>
                              <div className="position-relative">
                                {this.state.errScheduleDateMsg && (
                                  <div className="validation-message">
                                    {this.state.errScheduleDateMsg}
                                  </div>
                                )}
                                <div className="rsptimer-picker">
                                  <DateTimePicker
                                    className="required"
                                    placeholder="Select schedule date and time"
                                    value={
                                      this.state.schedule
                                        ? new Date(this.state.schedule)
                                        : null
                                    }
                                    // show={true}
                                    // min={this.state.startDate}

                                    min={this.getStartDateForSchedule()}
                                    max={new Date(this.state.endCampaignDate)}
                                    format={"dd MMM, yyyy HH:mm"}
                                    popup={CustomPopup}
                                    onChange={(event) => {
                                      if (event.target.value !== null) {
                                        const date = new Date(event.target.value);
                                        //  date.setDate(date.getDate() + 1);
                                        // ✅ 1 Day added
                                        const month = date.getMonth() + 1;
                                        const newDateTime =
                                          date.getFullYear() +
                                          "-" +
                                          month +
                                          "-" +
                                          date.getDate() +
                                          " " +
                                          date.getHours() +
                                          ":" +
                                          date.getMinutes();
                                        let tempEmailData = { ...this.state.emailData };
                                        tempEmailData.data.content[0].localBlastDateTime = newDateTime;
                                        // event.target.value
                                        let errMsg =
                                          (tempEmailData.data.content[0]
                                            .localBlastDateTime !== "") || date <= new Date()
                                            ? null
                                            : SCHEDULE_DATE;
                                        this.setState({
                                          emailData: tempEmailData,
                                          schedule: event.target.value,
                                          errScheduleDateMsg: errMsg,
                                        });
                                      } else {
                                        let tempEmailData = { ...this.state.emailData };
                                        tempEmailData.data.content[0].localBlastDateTime = "";
                                        this.setState({
                                          emailData: tempEmailData,
                                          schedule: "",
                                          errScheduleDateMsg: SCHEDULE_DATE,
                                        });
                                      }
                                    }}
                                  />
                                  <div className={`input-outer-set ${(this.state.schedule !== "") ? "" : "click-off"}`}>
                                    <RSPTooltip text="Reset schedule" position="top">
                                      <i
                                        className="icon-sd-refresh-medium icons-md blue ml10"
                                        onClick={() => {
                                          let tempEmailData = { ...this.state.emailData };
                                          tempEmailData.data.content[0].localBlastDateTime = "";
                                          this.setState({ schedule: "", emailData: tempEmailData });
                                        }}
                                      />
                                    </RSPTooltip>
                                  </div>
                                </div>
                              </div>
                              <Row className="marginT5">
                                {/* <Col md="8">
                                        <div className="d-flex align-items-center marginB30">
                                            <RSCheckbox
                                                className="marginB0"
                                                lbl="Use send time recommendation"
                                                checked={this.state.isSendRecommendation}
                                                cb={(value) => {
                                                    this.setState({
                                                        isSendRecommendation:
                                                            !this.state.isSendRecommendation,
                                                    });
                                                }}
                                            />
                                            <RSPPophover text="Send time will be based on Resulticks recommendation model, arrived at using individual audience's open time propensity" position="top">
                                                <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                                            </RSPPophover>
                                        </div>
                                    </Col> */}
                                {this.state.isEditTimeZone === false ? (
                                  <Col>
                                    <div className="float-end">
                                      <label>
                                        {!!this.state.timeZone &&
                                          this.state.timeZone.gmtOffset}
                                      </label>
                                      <RSPTooltip text="Edit" position="top" >
                                        <i
                                          className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer"
                                          onClick={() => {
                                            this.setState({ isEditTimeZone: !this.state.isEditTimeZone });
                                          }}
                                        />
                                      </RSPTooltip>
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                          {this.state.isEditTimeZone ? (
                            <Row className={`custom-column paddingT20 ${this.state.disableEdit}`}>
                              <Col md={3}>
                                <label className="label-row">Select time zone</label>
                              </Col>
                              <Col md={6}>
                                <div className="position-relative">
                                  <RSDropdownList
                                    className="marginB0 required"
                                    data={this.state.timeZoneData || []}
                                    value={this.state.timeZone}
                                    defaultItem={{
                                      timezoneId: 0,
                                      timezoneName: "-- Select time zone --",
                                    }}
                                    textField="timezoneName"
                                    dataItemKey="timezoneId"
                                    onChange={(e) => {
                                      let tempEmailData = { ...this.state.emailData };
                                      tempEmailData.data.content[0].timezoneId = e.target.value.timezoneId;
                                      this.setState({ timeZone: e.target.value, emailData: tempEmailData });
                                      this.getStartDateForSchedule();
                                    }}
                                  />
                                </div>
                                <Row className="marginT5">
                                  <Col md="8">
                                    <div className="d-flex align-items-center">
                                      <RSCheckbox
                                        className="marginT0 marginB15 click-off"
                                        lbl="Daylight saving"
                                        checked={this.state.isDayLightSavings}
                                        cb={(value) => {
                                          this.setState({
                                            isDayLightSavings: !this.state.isDayLightSavings,
                                          });
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      )}
                    </>
                  )}
                  <>
                    {(this.state.activeTab !== -1 || this.state.isSplit) && (
                      <>
                        {this.state.approvalList.map((item, index) => {
                          return (
                            <Row className={`custom-column marginT20 ${this.state.disableEdit}`}>
                              <Col md={3}>
                                {index === 0 &&
                                  <label className="label-row">
                                    {this.state.isRequestForApproval
                                      ? "Send approval request to"
                                      : "Send test mail to"}
                                  </label>
                                }
                              </Col>
                              <Col md={6} key={index}>
                                <div className="position-relative">
                                  {item.appErr && (
                                    <div className="validation-message">
                                      {item.appErr}
                                    </div>
                                  )}
                                  {!item.input ? (
                                    <RSDropdownList
                                      className="mb10"
                                      data={this.state.approverListFromServer || []}
                                      textField="email"
                                      dataItemKey="userid"
                                      value={item.approverEmail}
                                      defaultItem={{
                                        email: "-- Select --",
                                        userid: 0,
                                      }}
                                      onChange={(event) => { this.handleChangeRequest(event, index) }}
                                    // onChange={(event) => {
                                    //   if (
                                    //     event.target.value.email !==
                                    //     "Enter new email here"
                                    //   ) {
                                    //     let temp = [...this.state.approvalList];
                                    //     temp[index].approverEmail =
                                    //       event.target.value;
                                    //     let tempErrorMsg = [
                                    //       ...this.state.requestForErrorMsg,
                                    //     ];
                                    //     tempErrorMsg[index].approverEmailMsg =
                                    //       null;
                                    //     let tempEmailData = { ...this.state.emailData };
                                    //     tempEmailData.data.testCampaignEmailAddress = event.target.value.email;
                                    //     this.setState({
                                    //       approvalList: temp,
                                    //       requestForErrorMsg: tempErrorMsg,
                                    //       isSendMsgTo: false,
                                    //       sendMailToAddress: event.target.value.email,
                                    //       emailData: tempEmailData
                                    //     });
                                    //   } else {
                                    //     this.setState({ isSendMsgTo: true, sendMailToAddress: "" });
                                    //   }
                                    // }}
                                    />
                                  ) : (
                                    <div>
                                      <RSInput2
                                        required={this.state.isRequestForApproval}
                                        ph="Enter the approver's email address"
                                        val={item.approverEmail.email}
                                        cls="mb10 paddingR30"
                                        cb={(text) => { this.handleChnageInputs(text, index) }}
                                        ob={(text) => { this.hanldeBlurInputs(text, index) }}
                                        errorMessage={item.appErr}
                                      // cb={(text) => {
                                      //   if (text.split(",").length <= 5) {
                                      //     let tempEmailData = { ...this.state.emailData };
                                      //     tempEmailData.data.testCampaignEmailAddress = text;
                                      //     this.setState({
                                      //       sendMailToAddress: text,
                                      //       errSendTestMailMsg: null,
                                      //       emailData: tempEmailData
                                      //     });
                                      //   } else {
                                      //     this.setState({ errSendTestMailMsg: "Send email ids should not be more than 5" });
                                      //   }
                                      // }}
                                      // ob={(text) => {
                                      //   let emails = text.split(",");
                                      //   emails.map((item, index) => {
                                      //     if (!validateEmail(item)) {
                                      //       this.setState({ errSendTestMailMsg: ENTER_VALID_EMAIL });
                                      //       return;
                                      //     }
                                      //   })
                                      // }}
                                      // errorMessage={this.state.errSendTestMailMsg}
                                      />
                                      <div className="input-inner-set">
                                        {/* <i
                                          className="icon-sd-close-mini icons-sm red mr-10"
                                          onClick={() => {
                                            let temp = [...this.state.approvalList];
                                            temp[index].approverEmail = {
                                              "email": "-- Select --",
                                              "userId": 0
                                            };
                                            let tempErrorMsg = [
                                              ...this.state.requestForErrorMsg,
                                            ];
                                            tempErrorMsg[index].approverEmailMsg = null;
                                            this.setState({
                                              approvalList: temp,
                                              requestForErrorMsg: tempErrorMsg,
                                              isSendMsgTo: false,
                                              sendMailToAddress: ""
                                            });
                                          }}
                                        /> */}
                                        <i className="icon-sd-close-mini icons-sm blue mr-10"
                                          onClick={() => { this.handleCloseInput(index) }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="input-outer-set">
                                    {(getUserData().clientId === 16 || getUserData().clientId === 1604) &&
                                      <>
                                        {index === 0 ?
                                          <RSPTooltip text="Add" position="top" >
                                            <i className={`icon-sd-circle-plus-fill-edge-medium icons-md blue cursor-pointer d-block ml15 ${((this.state.approvalList.length > 2 || !this.state.isRequestForApproval)) && "click-off"}`}
                                              onClick={() => {
                                                this.handleAddRequest(index)
                                              }}
                                            />
                                          </RSPTooltip>
                                          :
                                          <RSPTooltip text="Remove" position="top" >
                                            <i className="icon-sd-circle-minus-fill-edge-medium icons-md red cursor-pointer ml15"
                                              onClick={() => {
                                                this.handleRemoveRequest(index)
                                              }}
                                            />
                                          </RSPTooltip>}
                                      </>}
                                    {((getUserData().clientId === 16 || getUserData().clientId === 1604) && this.state.approvalSettings.approvalMatchType.toLowerCase() === 'any') &&
                                      <RSPTooltip text="Set mandatory" position="top" >
                                        <i className={item.ismandatory ? "icon-sd-asterisk-mini red icons-sm mand-field cursor-pointer" : "icon-sd-asterisk-mini icons-sm mand-field cursor-pointer position-relative z-1"}
                                          onClick={() => {
                                            if (item.ismandatory) {
                                              item.ismandatory = !item.ismandatory;
                                              let tempApprovalList = [...this.state.approvalList]
                                              tempApprovalList[index].ismandatory = item.ismandatory;
                                              this.setState({ approvalList: tempApprovalList })
                                            }
                                            else if (this.state.approvalList.length > this.state.approvalSettings.approvalMatchCount) {
                                              if (this.state.approvalList.filter(obj => obj.ismandatory === true).length !== this.state.approvalSettings.approvalMatchCount) {
                                                item.ismandatory = !item.ismandatory;
                                                let tempApprovalList = [...this.state.approvalList]
                                                tempApprovalList[index].ismandatory = item.ismandatory;
                                                this.setState({ approvalList: tempApprovalList })
                                              }
                                            }
                                          }}
                                        />
                                      </RSPTooltip>
                                    }
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="ml30">
                                {index === 0 && (
                                  <RSPrimaryBtn
                                    className={this.state.errBodyMsg !== null ? "click-off" : (this.state.sendMailToAddress !== "" && this.state.sendMailToAddress.includes("@") && this.state.errSenderEmailMsg === null) ? "ml10" : this.state.isRequestForApproval ? "ml10" : "click-off"}
                                    onClick={() => {
                                      let tempEmailData = { ...this.state.emailData };
                                      if (this.state.isRequestForApproval) {
                                        let approvarList = [...this.state.approvalList].map((item, index) => {
                                          return {
                                            approvarId: item.approverEmail?.userid || "",
                                            approvarName: item.approverEmail.email,
                                            flag: false,
                                            ismandatory: item.ismandatory,
                                            priority: item.priority,
                                            ApprovalSentcount: item.ApprovalSentcount,
                                          };
                                        });
                                        let approval_object = {
                                          IsFollowHierarchy: this.state.approvalSettings.isFollowHierarchyEnabled,
                                          matchType: this.state.approvalSettings.approvalMatchType,
                                          matchCount: this.state.approvalSettings.approvalMatchCount,
                                          approvarList: approvarList,
                                        };
                                        tempEmailData.data.requestForApproval = approval_object;
                                      }
                                      let enable = true;
                                      if (this.state.isRequestForApproval) {
                                        enable = this.validation();
                                      } else {
                                        enable = this.validation(true);
                                        let emails = this.state.sendMailToAddress.split(",");
                                        emails.forEach((item, index) => {
                                          if (!validateEmail(item)) {
                                            this.setState({ errSendTestMailMsg: ENTER_VALID_EMAIL });
                                            enable = false;
                                            return;
                                          }
                                        })
                                      }
                                      if (enable === undefined || enable) {
                                        let databaseName = getUserData()?.clientDetailsLocalization.instanceName;
                                        const urlResult = new URLSearchParams(window.location.search);
                                        let channelDetails = urlResult.get("ChannelDetails");
                                        const obj = JSON.parse(channelDetails);
                                        // let tempEmailData = { ...this.state.emailData };
                                        tempEmailData.data.senderEmail = this.state.senderEmailAddress + "@" + this.state.activeDomain.domainName;
                                        if (this.state.isReplyMail) {
                                          tempEmailData.data.replyEmail = this.state.alternateEmailAddress + "@" + this.state.activeReplyDomain.domainName;
                                        } else {
                                          tempEmailData.data.replyEmail = "";
                                        }
                                        let temp = [...tempEmailData.data.content];
                                        if (temp[0].contentType === "Z" && temp[0].edmDimension === 0) {
                                          let element = document.getElementById("edmFrame")
                                          let edmHeight = element.contentWindow.document.body.scrollHeight
                                          let edmWidth = element.contentWindow.document.body.scrollWidth
                                          let edmDimension = edmHeight * edmWidth
                                          temp[0].edmDimension = edmDimension
                                        }
                                        if (temp[0].edmChannelId === 0) {
                                          temp[0].edmChannelId = obj.ChannelDetailID;
                                        }
                                        if (this.state.isRequestForApproval) {
                                          if (!!!urlResult.get("resSave")) {
                                            if (temp[0].edmChannelId === 0) {
                                              temp[0].edmChannelId = obj.ChannelDetailID;
                                            }
                                            temp[0].dataSource = obj.dataSource;
                                            temp[0].dynamiclistId = obj.DynamiclistID;
                                            temp[0].parentChannelDetailId = obj.ParentChannelDetailID;
                                            temp[0].parentChannelDetailType = obj.ParentChannelDetailType;
                                            temp[0].actionId = obj.ActionID;
                                            temp[0].actionTime = obj.ActionTime;
                                            temp[0].actionTimeDuration = obj.ActionTimeDuration;
                                            temp[0].channelFriendlyName = obj.ChannelFriendlyName;
                                            temp[0].allOrAny = obj.IsALLorAny;
                                            temp[0].addOnLevel = obj.addOnLevel;
                                            temp[0].domId = obj.DomId;
                                            temp[0].channelDetailType = obj.ChannelDetailType;
                                            temp[0].channelId = obj.ChannelId;
                                            temp[0].flowChannel = obj.FlowChannel;
                                            temp[0].contentThumbnailPath = obj.ContentThumbnailPath;
                                            temp[0].IsSplitAbEnabled = obj.IsSplitAbEnabled;
                                            temp[0].activeChannel = obj.ActiveChannel;
                                            temp[0].channelDetailId = obj.ChannelDetailID;
                                            if (this.state.levelNumber > 1) {
                                              var today = new Date();
                                              var date =
                                                today.getFullYear() +
                                                "-" +
                                                (today.getMonth() + 1) +
                                                "-" +
                                                today.getDate();

                                              var time =
                                                today.getHours() +
                                                ":" +
                                                today.getMinutes() +
                                                ":" +
                                                today.getSeconds();

                                              var dateTime = date + " " + time;
                                              temp[0].localBlastDateTime = dateTime;
                                              temp[0].schedule = obj.ScheduleDate;
                                            }
                                          } else {
                                            if (this.state.levelNumber > 1) {
                                              var today = new Date();
                                              var date =
                                                today.getFullYear() +
                                                "-" +
                                                (today.getMonth() + 1) +
                                                "-" +
                                                today.getDate();

                                              var time =
                                                today.getHours() +
                                                ":" +
                                                today.getMinutes() +
                                                ":" +
                                                today.getSeconds();

                                              var dateTime = date + " " + time;
                                              temp[0].localBlastDateTime = dateTime;
                                            }
                                          }
                                        }
                                        let params = createEmailCampaign(
                                          "M",
                                          this.state.campaignId,
                                          tempEmailData,
                                          databaseName,
                                          this.state.isRequestForApproval ? "" : this.state.sendMailToAddress,
                                          this.state.isRequestForApproval ? false : true,
                                          obj,
                                          this.state.mode === "edit" ? "edit" : this.state.edmChannelId !== 0 ? "edit" : "",
                                        );
                                        connectServer({
                                          path: this.state.mode === "edit" ? UPDATE_EMAIL_CAMPAIGN : this.state.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                                          params,
                                          loading: this.context.globalStateData.setIsLoadingData,
                                          sessionOut: this.context.globalStateData.setSessionOutData,
                                          context: this.context,
                                          ok: (res) => {
                                            if (res.status) {
                                              if (this.state.edmChannelId === 0) {
                                                let tempEmailData = { ...this.state.emailData }
                                                tempEmailData['data']['content'][0]['edmChannelId'] = res.edmChannelId;
                                                this.setState({ emailData: tempEmailData, edmChannelId: res.edmChannelId })
                                              }
                                              this.setState({
                                                isOpenSendSuccessModal: true,
                                                // isRequestForApproval: this.props.emailData.isRequestForApprovalEnabled,
                                                // schedule: this.props.emailData.content[0].localBlastDateTime
                                              });
                                            }
                                          },
                                          fail: (err) => {
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    Send
                                  </RSPrimaryBtn>
                                )}
                              </Col>
                            </Row>
                          )
                        })}
                      </>
                    )}
                    {((getUserData().clientId === 16 || getUserData().clientId === 1604) && (this.state.activeTab !== -1 || this.state.isSplit)) && (
                      <Row className={`custom-column marginT0 ${this.state.disableEdit}`}>
                        <Col md={3}>
                        </Col>
                        <Col md={6} className="pr0">
                          <RSCheckbox
                            className="marginB0"
                            lbl="Request for approval"
                            checked={this.state.isRequestForApproval}
                            cb={(status) => {
                              let tempEmailData = { ...this.state.emailData }
                              let errMsg = this.state.approvalList[0].approverEmail.email !== "-- Select --" ? null : "Select email id";
                              let tempApprovalList = [
                                {
                                  approverEmail: {
                                    "email": "-- Select --",
                                    "userid": 0
                                  },
                                  ismandatory: false,
                                  edmworlflowdetails: 0,
                                  priority: 1,
                                  ApprovalSentcount: 1,
                                  name: "approval",
                                  input: false,
                                  appErr: null
                                }
                              ]
                              let tempApprovalSettings = {
                                approvalMatchType: "All",
                                approvalMatchCount: 0,
                                isFollowHierarchyEnabled: false,
                              }
                              if (!status) {
                                tempEmailData.data.isRequestForApprovalEnabled = status;
                                tempEmailData.data.requestForApproval = {
                                  "IsFollowHierarchy": false,
                                  "matchType": "All",
                                  "matchCount": "0",
                                  "approvarList": [
                                    {
                                      "approvarId": "1",
                                      "approvarName": "murthy.ch14@gmail.com",
                                      "flag": false,
                                      "ismandatory": false,
                                      "priority": 1,
                                      "ApprovalSentcount": 1
                                    }
                                  ]
                                }
                                this.setState({ sendMailToAddress: "", emailData: tempEmailData, approvalList: tempApprovalList, approvalSettings: tempApprovalSettings, isRequestForApproval: status })
                              } else {
                                let tempApproveList = [...this.state.approvalList];
                                tempApproveList[0].appErr = errMsg;
                                tempEmailData.data.isRequestForApprovalEnabled = errMsg === null ? status : false;
                                this.setState({ isRequestForApproval: errMsg === null ? status : false, emailData: tempEmailData, approvalList: tempApproveList });

                              }
                            }}
                          />
                          <RSPPophover
                            text="Get the communication content approved by the concerned person."
                            position="top"
                          >
                            <i className="icon-sd-question-mark-mini question-unfill icons-sm blue mt0 cp"></i>
                          </RSPPophover>

                        </Col>
                        <Col md={3} className="pl0">
                          {((getUserData().clientId === 16 || getUserData().clientId === 1604) && this.state.approvalList.length > 1) &&
                            <div className="left-11 position-relative">
                              <RSPTooltip position="top" text="Approval settings">
                                <i className={`blue cursor-pointer icon-sd-settings-medium icons-md ${this.state.isRequestForApproval ? "" : "click-off"}`}
                                  onClick={() => {
                                    this.setState({ isModalOpen: true });
                                  }}
                                ></i>
                              </RSPTooltip>
                            </div>
                          }
                        </Col>
                      </Row>
                    )}
                  </>
                  {this.state.isModalOpen && (
                    <ApprovalSettings
                      isOpen={this.state.isModalOpen}
                      approvalSettings={this.state.approvalSettings}
                      approvalListCount={this.state.approvalList.length}
                      onOpenChange={(value) => {
                        if (value) {
                          let tempApprovalList = [...this.state.approvalList]
                          tempApprovalList.map((item, index) => {
                            item.ismandatory = false
                          })
                          this.setState({ approvalList: tempApprovalList, isModalOpen: false });
                        } else {
                          this.setState({ isModalOpen: value });
                        }
                      }}
                    ></ApprovalSettings>
                  )}
                  {this.state.isOpenSubjectModal && (
                    <SubjectLineModal
                      isOpen={this.state.isOpenSubjectModal}
                      onChangeIsOpen={(value) => {
                        this.setState({ isOpenSubjectModal: value });
                      }}
                    />
                  )}
                  {this.state.isSplitSettings && (
                    <SplitSettingsModal
                      isOpen={this.state.isSplitSettings}
                      splitDetails={this.state.emailData.data.splitAB}
                      onChangeIsOpen={(value) => {
                        this.setState({ isSplitSettings: value });
                      }}
                    />
                  )}
                  {
                    this.state.isOpenSendSuccessModal &&
                    <SuccessModal
                      isOkButton={false}
                      isOpen={this.state.isOpenSendSuccessModal}
                      title={"Success"}
                      subTitle={this.state.isRequestForApproval ? "Communication has been sent successfully for approval." : "Test communication will be sent shortly."}
                      buttonClicked={(value) => {
                        if (this.state.isRequestForApproval) {
                          this.setState({ isOpenSendSuccessModal: false })
                          const sessionStr = localStorage.getItem("session");
                          const sessionObject = JSON.parse(sessionStr);
                          const accessToken = sessionObject
                            ? sessionObject.accessToken
                            : null;
                          const campId = window.btoa(this.state.campaignId);
                          const edmChannelId = window.btoa(this.state.edmChannelId);
                          let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                          //return false;
                          window.location.href =
                            this.state.mdcUrl +
                            "?CampId=" +
                            campId +
                            "&accessToken=" +
                            encodeURIComponent(accessToken) +
                            '&from=' + fromEnvi +
                            "&channelResponseDetailId=" +
                            edmChannelId +
                            "&channelType=" +
                            "E";
                          // this.props.moveNext(1);
                        } else {
                          this.setState({ isOpenSendSuccessModal: false })
                        }
                        this.setState({ isOpenSendSuccessModal: false });
                      }} />
                  }
                  {this.state.isRefreshModal && (
                    <RSConfirmAlert
                      subTitle="Selecting a new source will clear the existing content. Do you want to continue?"
                      secondaryButtonText="Cancel"
                      primaryButtonText="OK"
                      buttonClicked={(value) => {
                        if (value) {
                          let tempEmailData = { ...this.state.emailData };
                          tempEmailData.data.content[0].contentType = "";
                          tempEmailData.data.content[0].body = "";
                          tempEmailData.data.content[0].textContent = "";
                          tempEmailData.data.content[0].isFooterEnabled = false;
                          tempEmailData.data.content[0].preHeaderMessage = "";
                          tempEmailData.data.content[0].footerId = 0;
                          tempEmailData.data.content[0].unsubscribeSettingId = 0;
                          this.setState({
                            isShowFooterComponent: false,
                            isAddFooter: false,
                            emailData: tempEmailData,
                            checked: false,
                            unsubscription: {
                              unsubscribeSettingId: 0,
                              unsubscribeName: "-- Select --",
                            }
                          });
                          this.updateTabStatus();
                        }
                        this.setState({ isRefreshModal: false });
                      }}
                    />
                  )}
                  {this.state.isOpenSplitResetModal ? (
                    <RSConfirmAlert
                      title="Are you sure you want to reset it?"
                      secondaryButtonText="Cancel"
                      primaryButtonText="OK"
                      buttonClicked={(value) => {
                        if (value) {
                          let tempEmailData = { ...this.state.emailData };
                          let temp = [...tempEmailData.data.content];
                          temp[0].edmChannelId = 0;
                          temp[0].subjectLine = "";
                          temp[0].contentType = "";
                          temp[0].splitType = "";
                          temp[0].statusId = 0;
                          temp[0].body = "";
                          temp[0].textContent = "";
                          temp[0].preHeaderMessage = "";
                          temp[0].spamScore = 3.0;
                          temp[0].isFooterEnabled = false;
                          temp[0].footerId = 0;
                          temp[0].unsubscribeSettingId = 0;
                          temp[0].contentUrl = null;
                          temp[0].htmlFilePath = null;
                          temp[0].previewId = 0;
                          temp[0].zipFilePath = null;
                          temp[0].isViewinBrowserChecked = true;
                          temp[0].templateId = 0;
                          temp[0].schedule = "";
                          temp[0].dynamiclistId = 0;
                          temp[0].timezoneId = tempEmailData.data.content[0].timezoneId;
                          tempEmailData.data.content = [temp[0]];
                          tempEmailData.data.isSplitAB = false;
                          this.setState({ isSplit: !this.state.isSplit, emailData: tempEmailData });
                        }
                        this.setState({
                          isOpenSplitResetModal: !this.state.isOpenSplitResetModal,
                        });
                      }}
                    />
                  ) : null}
                  <div className="btn-container d-flex justify-content-end mt40">
                    <RSSecondaryBtn paddingR="0px" onClick={this.backToCanvas}>
                      Cancel
                    </RSSecondaryBtn>
                    <RSPrimaryBtn className={this.state.errBodyMsg === null ? this.state.disableEdit : "click-off"}
                      onClick={() => {
                        if(this.state.emailData.data.content[0].contentType === "R"){
                          const edmHtml  = this.state.emailData.data.content[0].body; 
                          this.getVerificationLinks(edmHtml);
                      }
                        let enable = this.validation();
                        if (enable === undefined || enable) {
                          const urlResult = new URLSearchParams(
                            window.location.search
                          );

                          let databaseName =
                            getUserData()?.clientDetailsLocalization.instanceName;
                          let channelDetails = urlResult.get("ChannelDetails");
                          const obj = JSON.parse(channelDetails);
                          let tempEmailData = { ...this.state.emailData };
                          if (this.state.isRequestForApproval) {
                            let approvarList = [...this.state.approvalList].map((item, index) => {
                              return {
                                approvarId: item.approverEmail?.userid || "",
                                approvarName: item.approverEmail.email,
                                flag: false,
                                ismandatory: item.ismandatory,
                                priority: item.priority,
                                ApprovalSentcount: item.ApprovalSentcount,
                              };
                            });
                            let approval_object = {
                              IsFollowHierarchy: this.state.approvalSettings.isFollowHierarchyEnabled,
                              matchType: this.state.approvalSettings.approvalMatchType,
                              matchCount: this.state.approvalSettings.approvalMatchCount,
                              approvarList: approvarList,
                            };
                            tempEmailData.data.requestForApproval = approval_object;

                          }
                          tempEmailData.data.senderEmail = this.state.senderEmailAddress + "@" + this.state.activeDomain.domainName;
                          if (this.state.isReplyMail) {
                            tempEmailData.data.replyEmail = this.state.alternateEmailAddress + "@" + this.state.activeReplyDomain.domainName;
                          } else {
                            tempEmailData.data.replyEmail = "";
                          }
                          if (!this.state.isSplit) {
                            let temp = [...tempEmailData.data.content];
                            if (temp[0].contentType === "Z" && temp[0].edmDimension === 0) {
                              let element = document.getElementById("edmFrame")
                              let edmHeight = element.contentWindow.document.body.scrollHeight
                              let edmWidth = element.contentWindow.document.body.scrollWidth
                              let edmDimension = edmHeight * edmWidth
                              temp[0].edmDimension = edmDimension
                            }
                            if (!!!urlResult.get("resSave")) {
                              if (temp[0].edmChannelId === 0) {
                                temp[0].edmChannelId = obj.ChannelDetailID;
                              }
                              temp[0].dataSource = obj.dataSource;
                              temp[0].dynamiclistId = obj.DynamiclistID;
                              temp[0].parentChannelDetailId = obj.ParentChannelDetailID;
                              temp[0].parentChannelDetailType = obj.ParentChannelDetailType;
                              temp[0].actionId = obj.ActionID;
                              temp[0].actionTime = obj.ActionTime;
                              temp[0].actionTimeDuration = obj.ActionTimeDuration;
                              temp[0].channelFriendlyName = obj.ChannelFriendlyName;
                              temp[0].allOrAny = obj.IsALLorAny;
                              temp[0].addOnLevel = obj.addOnLevel;
                              temp[0].domId = obj.DomId;
                              temp[0].channelDetailType = obj.ChannelDetailType;
                              temp[0].channelId = obj.ChannelId;
                              temp[0].flowChannel = obj.FlowChannel;
                              temp[0].contentThumbnailPath = obj.ContentThumbnailPath;
                              temp[0].IsSplitAbEnabled = obj.IsSplitAbEnabled;
                              temp[0].activeChannel = obj.ActiveChannel;
                              temp[0].channelDetailId = obj.ChannelDetailID;
                              if (this.state.levelNumber > 1) {
                                var today = new Date();
                                var date =
                                  today.getFullYear() +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getDate();

                                var time =
                                  today.getHours() +
                                  ":" +
                                  today.getMinutes() +
                                  ":" +
                                  today.getSeconds();

                                var dateTime = date + " " + time;
                                temp[0].localBlastDateTime = dateTime;
                                temp[0].schedule = obj.ScheduleDate;
                              }
                            } else {
                              if (this.state.levelNumber > 1) {
                                var today = new Date();
                                var date =
                                  today.getFullYear() +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getDate();

                                var time =
                                  today.getHours() +
                                  ":" +
                                  today.getMinutes() +
                                  ":" +
                                  today.getSeconds();

                                var dateTime = date + " " + time;
                                temp[0].localBlastDateTime = dateTime;
                              }
                            }
                            this.setState({ emailData: tempEmailData });
                          }
                          // let params = createEmailCampaign(
                          //   "M",
                          //   this.state.campaignId,
                          //   obj,
                          //   this.state.emailData
                          // );

                          let params = createEmailCampaign(
                            "M",
                            this.state.campaignId,
                            tempEmailData,
                            databaseName,
                            "",
                            false,
                            obj,
                            this.state.mode === "edit" ? "edit" : this.state.edmChannelId !== 0 ? "edit" : ""
                          );
                          connectServer({
                            path: this.state.mode === "edit" ? UPDATE_EMAIL_CAMPAIGN : this.state.edmChannelId !== 0 ? UPDATE_EMAIL_CAMPAIGN : CREATE_EMAIL_CAMPAIGN,
                            params,
                            loading: this.context.globalStateData.setIsLoadingData,
                            context: this.context,
                            ok: (res) => {
                              if (res.status) {
                                const sessionStr = localStorage.getItem("session");
                                const sessionObject = JSON.parse(sessionStr);
                                const accessToken = sessionObject
                                  ? sessionObject.accessToken
                                  : null;
                                const campId = window.btoa(this.state.campaignId);
                                const edmChannelId = window.btoa(res.edmChannelId);
                                let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                                //return false;
                                window.location.href =
                                  this.state.mdcUrl +
                                  "?CampId=" +
                                  campId +
                                  "&accessToken=" +
                                  encodeURIComponent(accessToken) +
                                  '&from=' + fromEnvi +
                                  "&channelResponseDetailId=" +
                                  edmChannelId +
                                  "&channelType=" +
                                  "E";
                              }
                            },
                            fail: (err) => { },
                          });
                        }
                      }
                      }
                    >
                      {this.state.mode === "edit"
                        ? "Update email content"
                        : "Create email content"}
                    </RSPrimaryBtn>
                  </div>
                  {this.state.clicked === 1 && (
                    <RSEmojiPicker
                      anchor={this.emojianochor}
                      show={this.state.isShow}
                      onOpen={this.onOpen}
                      onFocus={this.onFocus}
                      ref={this.contentRef}
                      onBlur={this.onBlur}
                      tabindex={0}
                      emojiClose={() => {
                        this.setState({ clicked: 0, isShow: false })
                      }}
                      onEmojiClick={(value) => {
                        let obj = this.state.emailData.data.content[0];
                        let val = getContent(obj.subjectLine, value, obj.subTitlePosition);
                        if (val.length > 500) {
                          this.setState({ errSubjectLineMsg: "Subject line should not be more than 500 characters" });
                        } else {
                          let tempEmailData = { ...this.state.emailData };
                          tempEmailData.data.content[0].subjectLine = val;
                          tempEmailData.data.content[0].subTitlePosition = obj.subTitlePosition + value.length;
                          let errMsg = val.length === 0 ? SUBJECT_LINE : null;
                          this.setState({ subjectLine: val, errSubjectLineMsg: errMsg, emailData: tempEmailData });
                        }

                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </LayoutPageWrapper>
    );
  }
}
export default withRouter(MdcEmail);
const templateData = [
  { id: 1001, text: "My Templates" },
  { id: 1002, text: "Create your own template" },
];
