import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Container } from "react-bootstrap"
import authContext from 'Helper/StateHandler/auth-context';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { RSBrandLogo } from 'Components/RSBrandLogo/RSBrandLogo';
import { getUserData } from 'Helper/Utils/Utils';
import { keyContactInfoInitialState } from 'AppJsImport';

const Thanks = props => {
    const history = useHistory()
    const context = useContext(authContext)
    useEffect(() => {
        if (getUserData()) {
            setTimeout(() => {
                context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState)
                context.toggleAuth(true)
                history.push({
                    pathname: '/',
                    state: { isPaymentSuccess: true }
                })
            }, 10000);
        } else {
            setTimeout(() => {
                context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState)
                history.push('/login')
            }, 15000);
        }
        // setTimeout(() => {
        //     history.push('/login')
        // }, 15000);
    })
    return (
        <LayoutPageWrapper>
            <Container>
                <div onClick={() => {
                    context.globalStateData.setKeyContactInfoData(keyContactInfoInitialState);
                    history.push('/login');
                }}>
                    <RSBrandLogo />
                </div>
                <div className="portlet-box-theme thanks-theme-box-temp">
                    <div className="vertical-space text-center thanks-temp">
                        <p><i className="icon-sd-circle-thumbs-up-fill-large icons-xxl space-top green-medium"></i></p>
                        <h2 className="blue-text font-l">{!history?.location?.state?.payment ? "Thank you for the payment." : "Thank you for setting up your account."}</h2>
                        <h5 className="line-height-md mt20 mb0 small-text fontR">
                            {!history?.location?.state?.payment ? "The invoice mail has been sent to your email address." : "Your account has been registered and a confirmation mail for account activation has been sent to your email address."}<br />
                            If you do not receive the email, please email us at <span className="rs-link orange-medium"
                                onClick={() => {
                                    // localStorage.removeItem('isAuth')
                                    // localStorage.removeItem('session')
                                    // context.toggleAuth(false)
                                    // setTimeout(() => {
                                    //     history.push("/login")
                                    // }, 1000);
                                    window.location.href = "mailto:support@marketingstar.io.";
                                    context.globalStateData.setSelectedDataStatus('default')
                                }
                                }>support@marketingstar.io</span><br />
                        </h5>
                    </div>
                </div>
            </Container>
        </LayoutPageWrapper>
    );
};


export default Thanks;