import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { SearchViewAdvanced } from 'Components/SearchView/SearchView';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import AdminListingDetailComponent from './Components/AdminListingDetailComponent';
import AdminListingCell from './Components/AdminListingCell';
import AdminListinglist from './AdminListing.json'
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import { BootstrapDropdown } from "Components/BootstrapDropdown";
// import { getDataFromServer } from 'Helper/Network/networkHandler';
import { GET_CLIENT_LIST } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { getServerConfig } from 'Helper/Utils/Utils';

class AdminListing extends Component {

  static contextType = authContext

  state = {
    dataResult: process(AdminListinglist, dataState),
    dataState: dataState,
    data: AdminListinglist,
    titleDropdown: ["All", "Requested", "Approved", "Rejected", "Deactivated"]
  }

  componentDidMount = () => {
    // this.getAdminDataFromServer()
  }

  // getAdminDataFromServer = () => {

  //   this.context.globalStateData.setIsLoadingData(true)
  //   let BaseURL = getServerConfig();
  //   getDataFromServer(BaseURL, GET_CLIENT_LIST, (data => {
  //     this.context.globalStateData.setIsLoadingData(false)

  //     RSLog("Admin list", data, true)
  //   }), (error => {
  //     this.context.globalStateData.setIsLoadingData(false)
  //     // alert(`Server call failed: ${error}`)
  //   }))
  // }

  AdminListingRowCell = (props) => <AdminListingCell {...props} />;

  dataStateChange = (event) => {
    this.setState({
      dataResult: process(AdminListinglist, event.dataState),
      dataState: event.dataState,
    });
  };
  expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    this.setState({ ...this.state });
  };

  TitleView = props => {
    return <div className="d-flex justify-content-between marginT15 marginB15">
      <h1 className="marginB0">Account gate</h1>
      <div className="d-flex align-items-center">
        <RSPDateRangePicker class="marginR15" />
        <BootstrapDropdown
          data={this.state.titleDropdown}
          defaultItem={"All"}
          customAlignRight={true}
          className="fs18-btn mr15"
          onSelect={(item) => {
          }}
        />
        <SearchViewAdvanced
          cls=""
          dropdownData={['All', 'Personal', 'Business', 'Privilege']}
        />
      </div>
    </div>
  }

  GridView = props => {
    return <Row>
      <Col>
        <Grid
          className="grid-pagenation-del rs-grid-listing admin-listing-table"
          sortable={false}
          scrollable={"none"}
          groupable={false}
          pageable={{
            //type: 'input',
            pageSizes: true,
            previousNext: true,
          }}
          data={this.state.dataResult}
          {...this.state.dataState}
          onDataStateChange={this.dataStateChange}
          detail={AdminListingDetailComponent}
          expandField="expanded"
          onExpandChange={this.expandChange}
        >
          <GridColumn
            field="customerID"
            cell={this.AdminListingRowCell}
          />
        </Grid>
      </Col>
    </Row>
  }

  render() {

    return (
      <LayoutPageWrapper>
        <Container className="page-header">
          <this.TitleView />
          <hr className="blue" />
          <this.GridView />
        </Container>
      </LayoutPageWrapper>
    )
  }

}
export default withRouter(AdminListing);

const dataState = {
  skip: 0,
  take: 5,
  sort: [
    {
      field: 'orderDate',
      dir: 'desc',
    },
  ],
};
