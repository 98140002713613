import React, { useState } from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";

import SOCIAL_ICONS from "./constant";
import { RSPTooltip } from "Components/Tooltip";
import { RSInput2 } from "Components/RSInputs";
import { getServerConfig } from "Helper/Utils/Utils";

const SocialFollow = ({ data }) => {
  const {
    socialContentAll,
    removeSocialContent,
    setSocialContentAll,
    setSocialFlag,
    socialFlag,
  } = data;

  const [socialError, setSocialError] = useState(false);

  // sidebar for social content
  const addSocialContents = (ele) => {
    let social = [...socialContentAll];
    if (!social.some((e) => e.id == ele.id) && social.length < 6) {
      social.push(ele);
      setSocialContentAll(social);
    } else {
      setSocialError(true);
    }
  };

  const handleClose = () => {
    setSocialFlag(false);
  };

  let path = '\\Images\\EmailFooter\\SocilaIMedia\\'
  return (
    <Offcanvas
      show={socialFlag}
      onHide={handleClose}
      placement="end"
      className="rs-builder-properties-wrapper"
    >
      {/* <Offcanvas show onHide={handleClose} placement='end' className="rs-builder-properties-wrapper"> */}
      <Offcanvas.Header className="mt60" closeButton>
        <Offcanvas.Title>Social properties</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="css-scrollbar">
        <div className="rsbpw-icons-wrapper">
          <p className="mb10">Please select social media</p>
          <Row>
            {[...SOCIAL_ICONS].map((ele) => {
              return (
                <Col sm={2} className="mb10" key={ele.key + 1}>
                  <div
                  //  className={_find(socialContentAll, ['id', ele.id]) ? 'click-off' : ''}
                  >
                    <RSPTooltip text={ele.title} position="top">
                      <img
                        src={`${getServerConfig()}${path}${ele.icon}`}
                        onClick={() => {
                          addSocialContents(ele);
                        }}
                      />
                    </RSPTooltip>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>

        {socialError && (
          <p className="alert alert-warning flex-center py10">
            Max. of 6 allowed.
          </p>
        )}

        {socialContentAll.map((ele, ind) => {
          return (
            <div className="builder-social-properties-block" key={ele?.key}>
              <div className="bspb-top">
                <div className="bspbt-logo">
                  <img  src={`${getServerConfig()}${path}${ele.icon}`} title={ele.title} />
                  <span>{ele.title}</span>
                </div>
                <div className="bspbt-delete">
                  <RSPTooltip text="Remove" position="top">
                    <i
                      className={`  icon-md color-primary-blue`}
                      onClick={() => {
                        if (socialError) setSocialError(false);
                        removeSocialContent(ind);
                      }}
                    ></i>
                  </RSPTooltip>
                </div>
              </div>

              <div className="bspb-content">
                <RSInput2
                  placeholder={"enter title"}
                  name={ele.title}
                  defaultValue={ele.title}
                />
                <RSInput2 placeholder={"URL"} name={ele.link} />
              </div>
            </div>
          );
        })}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default React.memo(SocialFollow);
