import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSCheckbox } from 'Components/RSInputs2';
import { RSInput2 } from 'Components/RSInputs';
import { GET_AUDIENCE_PAYMENT_SUMMARY, SAVE_FOR_LATER } from 'Helper/Constants/endPoint';
import React, { Component } from 'react';
import authContext from 'Helper/StateHandler/auth-context'
import { connectServer } from 'Helper/Network/networkHandler';
import { Modal, Row, Col } from 'react-bootstrap';

class AudiencePaymentModal extends Component {

    static contextType = authContext

    state = {
        isShow: this.props.isOpen,
        paymentSummary: null,
        isTermsCheck: false
    }

    componentDidMount = () => {
        this.getPaymentSummaryDetails(this.props.segmentationDetails.segmentationListID);
    }

    getPaymentSummaryDetails = (segmentationListId) => {
        let params = {
            "segmantationId": segmentationListId
        }
        connectServer({
            path: GET_AUDIENCE_PAYMENT_SUMMARY,
            params: params,
            // loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ paymentSummary: res?.data })
                }
            },
            fail: err => {
            }
        })
    }

    postPaymentConfirmation = (segmentationSchedularId) => {
        let params = {
            "segmentationSchedulerID": segmentationSchedularId
        }
        connectServer({
            path: SAVE_FOR_LATER,
            params: params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.props.onChangeIsOpen(true);
                }
            },
            fail: err => {
            }
        })
    }

    // postPaymentConfirmation = (segmentationSchedularId) => {
    //     let params = {
    //         "segmantationId": segmentationSchedularId
    //     }
    //     connectServer({
    //         path: POST_PAY_CONFIRMATION,
    //         params: params,
    //         // loading: this.context.globalStateData.setIsLoadingData,
    //         sessionOut: this.context.globalStateData.setSessionOutData,
    //         context: this.context,
    //         ok: res => {
    //             if (res.status) {
    //                 this.props.onChangeIsOpen(true);
    //             }
    //         },
    //         fail: err => {
    //         }
    //     })
    // }

    render() {
        return (
            <>
                <Modal
                    show={this.state.isShow}
                    centered
                    size='md'
                    backdrop="static"
                    keyboard={false}
                    onHide={() => {
                        this.setState({ isShow: !this.state.isShow });
                        this.props.onChangeIsOpen(false);
                    }}>
                    <Modal.Header closeButton>
                        <h2>Payment summary</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <label>List name</label>
                            </Col>
                            <Col md={6}>
                                <RSInput2
                                    required={true}
                                    disabled={true}
                                    val={this.state?.paymentSummary?.recipientsBunchName || "Sample List"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label>Total audience count</label>
                            </Col>
                            <Col md={6}>
                                <RSInput2
                                    required={true}
                                    disabled={true}
                                    val={this.state?.paymentSummary?.totalAudienceCount || 0}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label>Credits charged (Approx.)</label>
                            </Col>
                            <Col md={6}>
                                <RSInput2
                                    required={true}
                                    disabled={true}
                                    val={this.state?.paymentSummary?.unitsUsed || 0}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <RSCheckbox
                                className="font-xsm marginB19"
                                checked={this.state.isTermsCheck}
                                cb={status => {
                                    this.setState({ isTermsCheck: status })
                                }
                                }
                                lbl="I agree to proceed with Credits"
                            // labelClass="sub-dashed-border"
                            />
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <RSSecondaryBtn onClick={() => {
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn className={this.state.isTermsCheck ? "" : "click-off"}
                            onClick={() => {

                                if (this.state.isTermsCheck) {
                                    this.postPaymentConfirmation(this.props.segmentationDetails.segmentationSchedulerID)
                                }
                            }}
                        >Proceed
                        </RSPrimaryBtn>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default AudiencePaymentModal;