import React, { Component, useEffect, useState } from "react";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { DropDownButton } from "@progress/kendo-react-buttons";
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  OrderedList,
  UnorderedList,
  FormatBlock,
  Link,
  InsertImage,
} = EditorTools;

class RESTextEditor extends Component {

  state = {
    personalizeTabData: this.props.personalizeTabData,
    bodyContent: this.props.content,
    bodyContentPosition: this.props.bodyContentPosition
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({ personalizeTabData: this.props.personalizeTabData })
    }
  }


  InsertPersonalize = (props) => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes["text"];
    view && EditorUtils.canInsert(view.state, nodeType);
    return (
      <div className="" title='Personalize'>
        <DropDownButton
          className={this.state.personalizeTabData?.length > 0 ? "" : "click-off"}
          items={this.state.personalizeTabData}
          icon=" icon-sd-user-question-mark-edge-medium blue icons-md"
          onItemClick={(e) => {
            EditorUtils.insertNode(view, view.state.schema.text(` ${e.item}`));
          }}
        />
      </div>
    );
  };
  fancyCount = (str) => {
    return Array.from(str.split(/[\ufe00-\ufe0f]/).join("")).length;
  }
  render() {
    return this.props.subUnsub ? (
      <Editor
        tools={[
          [Bold, Italic, Underline],
          [OrderedList, UnorderedList],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        ]}
        contentStyle={{
          height: 200,
        }}
        onChange={(event) => {
          let text = event.html.replace(/<\/?[^>]+(>|$)/g, "");
          let richText = event.html;
          if (text.length <= 500) {
            this.props.callBack(text, richText);
          }
        }}
        value={this.props.content}
      />
    ) : (
      <Editor
        tools={[
          FormatBlock,
          [Bold, Italic, Underline],
          [Link, InsertImage],
          [OrderedList, UnorderedList],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          this.InsertPersonalize,
        ]}
        contentStyle={{
          height: 200,
        }}
        onBlur={(event) => {
          let richTest = event.target.value.textContent
          let output = richTest.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g, "");
          if ((this.fancyCount(richTest) - this.fancyCount(output)) > 0) {
            this.props.getErrorMessage("Emoji's are not accepted")
          } else {
            this.props.getErrorMessage(null)
          }
          this.setState({ bodyContentPosition: event.target.selectionStart })
        }}
        onChange={(event) => {

          let text = event.html.replace(/<\/?[^>]+(>|$)/g, "");
          let richText = event.html;
          this.props.callBack(text, richText);
          this.setState({ bodyContent: this.props.content })
        }}
        defaultContent={this.props.content}
      />
    );
  }
};
export default RESTextEditor;
