import { convertBase64ToObject } from "Helper/Utils/Utils";

/*export const createEmailCampaign = (type, campaignId,object,campaignObject) => {
    let tempAudienceList = [3];
    let tempAudienceCount = 0;
    let targetListTargetAudience = []
    targetListTargetAudience.map((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCount;
    })
    let params = {
        "campaignId": campaignId,
        "campaignType": type,
        "levelNumber": object.LevelNumber,
        "senderName": "MarketingStar Notification",
        "senderEmail": "noreply@info.marketingstar.us",
        "replyEmail": campaignObject.data.replyEmail,
        "testCampaignEmailAddress": object.testCampaignEmailAddress,
        "targetListTargetAudience": tempAudienceList,
        "totalaudience": campaignObject.data.totalaudience,
        "isReplyMailEnabled": campaignObject.data.isReplyMailEnabled,
        "isSplitAB": campaignObject.data.isSplitAB,
        "splitAB": {
            "isAutoSchedule": false,
            "campaignPerFormanceBy": "",
            "duration": 0,
            "period": ""
        },
        "edmSplit": {
            "edmChannelId": 0,
            "splitPercentage": 0,
            "splitAudience": 0,
            "totalAudience": 0
        },
        "content": campaignObject.data.content
    }
    return params;
}*/

export const createEmailCampaign = (type, campaignId, campaignObject, databaseName, sendToMeEmail, isSend, urlParamData, mode = "") => {
    /*let tempAudienceList = [];
    let tempAudienceCount = 0;
    campaignObject.targetListTargetAudience.map((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCountEmail;
    })*/
    if (mode === "") {
        const { ActionID, ActionTime, ActionTimeDuration, ActiveChannel, ChannelDetailID, ChannelDetailType, ChannelFriendlyName, ChannelId, DomId, DynamiclistID,
            FlowChannel, IsALLorAny, IsSplitAbEnabled, LevelNumber, Name, ParentChannelDetailID, ParentChannelDetailType, ScheduleDate, addOnLevel, dataSource, segmentationListID } = urlParamData;
        campaignObject.data.content[0]['IsSplitAbEnabled'] = IsSplitAbEnabled;
        campaignObject.data.content[0]['actionId'] = ActionID;
        campaignObject.data.content[0]['actionTime'] = ActionTime;
        campaignObject.data.content[0]['actionTimeDuration'] = ActionTimeDuration;
        campaignObject.data.content[0]['activeChannel'] = ActiveChannel;
        campaignObject.data.content[0]['addOnLevel'] = addOnLevel;
        campaignObject.data.content[0]['allOrAny'] = IsALLorAny;
        campaignObject.data.content[0]['channelDetailId'] = ChannelDetailID;
        campaignObject.data.content[0]['channelDetailType'] = ChannelDetailType;
        campaignObject.data.content[0]['channelFriendlyName'] = ChannelFriendlyName;
        campaignObject.data.content[0]['channelId'] = ChannelId;
        campaignObject.data.content[0]['dataSource'] = dataSource;
        campaignObject.data.content[0]['domId'] = DomId;
        campaignObject.data.content[0]['dynamiclistId'] = DynamiclistID;
        campaignObject.data.content[0]['flowChannel'] = FlowChannel;
        campaignObject.data.content[0]['parentChannelDetailId'] = ParentChannelDetailID;
        campaignObject.data.content[0]['parentChannelDetailType'] = ParentChannelDetailType;
        campaignObject.data.content[0]['schedule'] = ScheduleDate;
    }

    let tempContent = [...campaignObject.data.content];
    tempContent[0].splitType = tempContent[0].splitType || "";
    tempContent[0].preHeaderMessage = tempContent[0].preHeaderMessage || "";
    tempContent[0].contentUrl = tempContent[0].contentUrl || "";
    tempContent[0].htmlFilePath = tempContent[0].htmlFilePath || "";
    tempContent[0].zipFilePath = tempContent[0].zipFilePath || "";
    campaignObject.data.content = tempContent;

    let params = {
        "campaignId": campaignId,
        "campaignType": type,
        "campaignGuid": "",
        "levelNumber": urlParamData.LevelNumber,
        "databaseName": databaseName,
        "senderName": campaignObject.data.senderName,
        "senderEmail": campaignObject.data.senderEmail,
        "replyEmail": campaignObject.data.replyEmail,
        "testCampaignEmailAddress": sendToMeEmail !== "" ? sendToMeEmail : campaignObject.data.testCampaignEmailAddress,
        "isSendTestMailContent": isSend ? 2 : sendToMeEmail !== "" ? 4 : campaignObject.data.isRequestForApprovalEnabled ? 1 : 0,
        "targetListTargetAudience": campaignObject.data.targetListTargetAudience,
        "totalaudience": campaignObject.data.totalaudience,
        "isReplyMailEnabled": campaignObject.data.isReplyMailEnabled || false,
        "isSplitAB": campaignObject.data.isSplitAB || false,
        "splitAB": campaignObject.data.splitAB,
        "emailSchedule": campaignObject.data.emailSchedule,
        "edmSplit": campaignObject.data.edmSplit,
        "content": campaignObject.data.content,
        "isRequestForApprovalEnabled": campaignObject.data.isRequestForApprovalEnabled,
        "requestForApproval": campaignObject.data.requestForApproval
    }
    return params;
}

export const createMobileSMSCampaignMDC = (type, campaignId, campaignObject, senderID, language, schedule, mobileNo, countryDialCodeMobile, channelDetails, isSend, lpSMSCreatedBY, lpSMSPassportID) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    campaignObject.targetList.map((item, index) => {
        tempAudienceList.push(item.segmentationListID);
        tempAudienceCount = tempAudienceCount + item.recipientCount;
    })

    let framedContent = [...campaignObject.content];
    framedContent.forEach((item, index) => {
        item.body = item.body.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        item.previewBody = item.previewBody.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
    })
    campaignObject.content = framedContent;

    let currentSchedule = '';
    if (channelDetails.LevelNumber > 1) {
        if (channelDetails.ScheduleDate !== "") {
            let tmpScheduleDate = channelDetails.ScheduleDate.split(",");
            const [date, month, year, hours, minutes] = tmpScheduleDate;
            let newDate = new Date(year, (month - 1), date, hours, minutes);
            const newMonth = newDate.getMonth() + 1;
            currentSchedule =
                newDate.getFullYear() +
                "-" +
                newMonth +
                "-" +
                newDate.getDate() +
                " " +
                newDate.getHours() +
                ":" +
                newDate.getMinutes();
        } else {
            var today = new Date();
            var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();

            var time =
                today.getHours() +
                ":" +
                today.getMinutes();

            currentSchedule = date + " " + time;
            channelDetails.ScheduleDate = today.getDate()
                + "," +
                (today.getMonth() + 1)
                + "," +
                today.getFullYear()
                + "," +
                today.getHours()
                + "," +
                today.getMinutes();
        }

    } else {
        const date = new Date(schedule);
        //  date.setDate(date.getDate() + 1);
        // ✅ 1 Day added
        const month = date.getMonth() + 1;
        currentSchedule =
            date.getFullYear() +
            "-" +
            month +
            "-" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes();
    }
    let params = {
        "senderId": senderID.clientSmsSettingId,
        "smsProviderTemplateID": campaignObject.smsProviderTemplateID,
        "isFlashMessageEnabled": campaignObject.isFlashMessageEnabled,
        "campaignId": campaignId,
        "campaignType": type,
        "testCampaignEmailAddress": "murthy.ch14@gmail.com",
        "levelNumber": channelDetails.LevelNumber,
        "targetList": channelDetails.segmentationListID,
        "totalaudience": parseInt(channelDetails.totalaudience, 10),
        "languageId": language,
        "isSplitAB": campaignObject.isSplitAB,
        "isRequestForApprovalEnabled": campaignObject.isRequestForApprovalEnabled,
        "testSmsMobileNo": mobileNo,
        "countryCodeMobile": countryDialCodeMobile,
        "lpSMSCreatedBY": lpSMSCreatedBY,
        "lpSMSPassportID": lpSMSPassportID,
        "isSendTestSMS": isSend ? 2 : lpSMSPassportID !== "" ? 4 : campaignObject.isRequestForApprovalEnabled ? 1 : 0,
        "isSendTimeOptEnable": false,
        "splitAB": campaignObject.splitAB,
        "smsSplit": campaignObject.smsSplit,
        "content": [
            {
                "smsChannelDetailId": channelDetails.ChannelDetailID,
                "body": campaignObject.content[0]['body'],
                "bodyPosition": campaignObject.content[0]['bodyPosition'],
                "previewBody": campaignObject.content[0]['previewBody'],
                "isShortenURLEnabled": campaignObject.content[0]['isShortenURLEnabled'],
                "timezoneId": campaignObject.content[0]['timezoneId'],
                "localBlastDateTime": isSend ? "" : lpSMSPassportID !== "" ? "" : currentSchedule,
                ...channelDetails

            }
        ],
        "requestForApproval": campaignObject.requestForApproval

    }
    return params;
}
export const createMobileWhatsAppCampaignMDC = (type, campaignId, campaignObject, schedule, mobileNo, countryDialCodeMobile, channelDetails, isSend, lpWACreatedBY, lpWAPassportID) => {
    let tempAudienceList = [];
    let tempAudienceCount = 0;
    let tempChannelDetails = { ...channelDetails }
    if (tempChannelDetails.segmentationListID.length > 0) {
        tempAudienceList.push(parseInt(tempChannelDetails.segmentationListID[0], 10));
    }
    let currentSchedule = '';
    if (tempChannelDetails.LevelNumber > 1) {
        if (tempChannelDetails.ScheduleDate !== "") {
            let tmpScheduleDate = tempChannelDetails.ScheduleDate.split(",");
            const [date, month, year, hours, minutes] = tmpScheduleDate;
            let newDate = new Date(year, (month - 1), date, hours, minutes);
            const newMonth = newDate.getMonth() + 1;
            currentSchedule =
                newDate.getFullYear() +
                "-" +
                newMonth +
                "-" +
                newDate.getDate() +
                " " +
                newDate.getHours() +
                ":" +
                newDate.getMinutes();
        } else {
            var today = new Date();
            var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();

            var time =
                today.getHours() +
                ":" +
                today.getMinutes();
            currentSchedule = date + " " + time;
            tempChannelDetails.ScheduleDate = today.getDate()
                + "," +
                (today.getMonth() + 1)
                + "," +
                today.getFullYear()
                + "," +
                today.getHours()
                + "," +
                today.getMinutes();

        }

    } else {
        const date = new Date(schedule);
        //  date.setDate(date.getDate() + 1);
        // ✅ 1 Day added
        const month = date.getMonth() + 1;
        currentSchedule =
            date.getFullYear() +
            "-" +
            month +
            "-" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes();
    }
    delete tempChannelDetails["IsSplitAbEnabled"];
    delete tempChannelDetails["segmentationListID"];
    let params = {
        "campaignId": campaignId,
        "waChannelDetailID": campaignObject.waChannelDetailID,
        "waContentType": campaignObject.waContentType,
        "clientWASettingId": campaignObject.clientWASettingId,
        "recipientBunchIDs": tempAudienceList,
        "totalRecipientWA": parseInt(tempChannelDetails.totalaudience, 10),
        "senderID": campaignObject.clientWASettingId,
        "isTemplateUsed": false,
        "templateType": campaignObject.templateType,
        "templateName": campaignObject.templateName,
        "templateContent": campaignObject.waContent,
        "templateNamespace": campaignObject.templateNamespace,
        "templateParameters": campaignObject.templateParameters,
        "waContent": campaignObject.waContent,
        "waPreviewContent": campaignObject.waPreviewContent,
        "languageCode": campaignObject.languageCode,
        "statusId": campaignObject.statusId,
        "tempLanguageCode": campaignObject.languageCode,
        "waTemplateID": campaignObject.waTemplateID,
        "levelNumber": tempChannelDetails.LevelNumber,
        "sFrequencyCap": false,
        "splitType": campaignObject.splitType,
        "isSplitABEnabled": campaignObject.isSplitABEnabled || false,
        "caption": campaignObject.caption,
        "liveURL": campaignObject.liveURL,
        "waMoblieScheduleDate": isSend ? "" : lpWAPassportID !== "" ? "" : currentSchedule,
        "blastDateTime": campaignObject.blastDateTime,
        "localBlastDateTime": isSend ? "" : lpWAPassportID !== "" ? "" : currentSchedule,
        "timezoneID": campaignObject.timezoneID,
        "confirmationEmailAddress": campaignObject.confirmationEmailAddress,
        "waScheduleGUID": campaignObject.waScheduleGUID,
        "requestForApproval": campaignObject.requestForApproval,
        "confirmationSMS": campaignObject.confirmationSMS,
        "approvalSentTo": campaignObject.approvalSentTo,
        "approvalEmailSentTo": campaignObject.approvalEmailSentTo,
        "isApproved": campaignObject.isApproved,
        "makeChangesComments": campaignObject.makeChangesComments,
        "validationKey": campaignObject.validationKey,
        "countryID": campaignObject.countryID,
        "campaignType": type,
        "caLanguages": campaignObject.caLanguages,
        "caDayLightList": campaignObject.caDayLightList,
        "clientWappSmsSettingId": campaignObject.clientWappSmsSettingId,
        "campaignName": campaignObject.campaignName,
        "waSenderNameAttributes": campaignObject.waSenderNameAttributes,
        "waRecipientAttributes": campaignObject.waRecipientAttributes,
        "waRecipientId": campaignObject.waRecipientId,
        "domId": campaignObject.domId,
        "flowChannel": campaignObject.flowChannel,
        "isMDCNewFlow": campaignObject.isMDCNewFlow,
        "blastScheduleGuId": campaignObject.blastScheduleGuId,
        "dataSource": campaignObject.dataSource,
        "dynamiclistID": campaignObject.dynamiclistID,
        "channelFriendlyName": campaignObject.channelFriendlyName,
        "parentChannelDetailId": campaignObject.parentChannelDetailId,
        "parentChannelDetailType": campaignObject.parentChannelDetailType,
        "actionId": campaignObject.actionId,
        "actionTime": campaignObject.actionTime,
        "actionTimeDuration": campaignObject.actionTimeDuration,
        "allOrAny": campaignObject.allOrAny,
        "addOnLevel": campaignObject.addOnLevel,
        "waTempLanguages": campaignObject.waTempLanguages,
        "isSendTestWA": isSend ? 2 : lpWAPassportID !== "" ? 4 : 0,
        "testSmsMobileNo": mobileNo,
        "countryCodeMobile": countryDialCodeMobile,
        "originalTemplateContent": campaignObject.originalTemplateContent,
        "waImagePath": campaignObject.waImagePath,
        "waMediaURL": campaignObject.waMediaURL,
        "waMediaURLType": campaignObject.waMediaURLType,
        "thumbNailPath": campaignObject.thumbNailPath,
        "isShortenURLEnabled": campaignObject.isShortenURLEnabled,
        "lpWACreatedBY": lpWACreatedBY,
        "lpWAPassportID": lpWAPassportID,
        ...tempChannelDetails

    }
    return params;
}
export const prepareEmailData = (object, passdata) => {
    let output = [];
    for (let i = 0; i < passdata.data.content.length; i++) {
        const tmp = {
            "edmChannelId": object.ChannelDetailID,
            "subjectLine": passdata.data.content[i].subjectLine,
            "contentType": passdata.data.content[i].contentType,
            "splitType": passdata.data.content[i].splitType,
            "statusId": 0,
            "body": passdata.data.content[i].body,
            "textContent": passdata.data.content[i].textContent,
            "preHeaderMessage": passdata.data.content[i].preHeaderMessage,
            "spamScore": 3.0,
            "isFooterEnabled": passdata.data.content[i].isFooterEnabled,
            "footerId": passdata.data.content[i].footerId,
            "unsubscribeSettingId": passdata.data.content[i].unsubscribeSettingId,
            "contentUrl": "",
            "htmlFilePath": "",
            "previewId": 0,
            "zipFilePath": "",
            dataSource: object.dataSource,
            "isViewinBrowserChecked": true,
            "templateId": 0,
            "dynamiclistId": object.DynamiclistID,
            "timezoneId": passdata.data.content[i].timezoneId,
            "localBlastDateTime": passdata.data.content[i].localBlastDateTime,
            "parentChannelDetailId": object.parentChannelDetailId,
            "parentChannelDetailType": object.ParentChannelDetailType,
            "actionId": object.ActionID,
            "actionTime": object.ActionTime,
            "actionTimeDuration": object.ActionTimeDuration,
            "channelFriendlyName": object.ChannelFriendlyName,
            "allOrAny": object.IsALLorAny,
            "addOnLevel": object.addOnLevel,
            "domId": object.DomId,
            "channelDetailType": object.ChannelDetailType,
            "channelId": object.ChannelId,
            "flowChannel": object.FlowChannel,
            "contentThumbnailPath": object.ContentThumbnailPath,
            "IsSplitAbEnabled": object.IsSplitAbEnabled,
            "activeChannel": object.ActiveChannel,
            "channelDetailId": object.ChannelDetailID,
            "scheduleDate": object.ScheduleDate,
        }
        output.push(tmp);
    }
    let emailData = {
        "channelstatusId": object.channelstatusId,
        "senderName": "MarketingStar Notification",
        "senderEmail": "noreply@promo.marketingstar.in",
        "replyEmail": passdata.data.replyEmail,
        "testCampaignEmailAddress": object.testCampaignEmailAddress,
        "targetListTargetAudience": [],
        "totalaudience": passdata.data.totalaudience,
        "isReplyMailEnabled": passdata.data.isReplyMailEnabled,
        "isSplitAB": passdata.data.isSplitAB,
        "levelNumber": object.LevelNumber,
        "isRequestForApprovalEnabled": false,
        "splitAB": {
            "isAutoSchedule": false,
            "campaignPerFormanceBy": "",
            "duration": 0,
            "period": ""
        },
        "edmSplit": {
            "edmChannelId": 0,
            "splitPercentage": 0,
            "splitAudience": 0,
            "totalAudience": 0
        },
        "content": output,
        "emailSchedule": [
            {
                "edmChannelId": 0,
                "timezoneId": 1,
                "localBlastDateTime": "2022-02-27 08:59:00",
                "splitType": null,
                "isSendTimeOptEnable": null
            }
        ],
        "requestForApproval": {
            "isWorkflowEnabled": "",
            "matchType": "All",
            "matchCount": "0",
            "approvarList": [
                {
                    "approvarId": "1",
                    "approvarName": "murthy.ch14@gmail.com",
                    "flag": false
                }
            ]
        }
    }
    return emailData;
}
export const prepareMobileSMSData = (object) => {
    const audienceList = convertBase64ToObject(localStorage.getItem('lsAudienceList'))
    const languageData = convertBase64ToObject(localStorage.getItem('lsLanguageData'))
    let framedAudienceList = [];
    object.targetList.map((item, index) => {
        framedAudienceList.push(audienceList.filter(audience => audience.segmentationListID === item)[0])
    })
    let smsData = {
        "campaignId": object.campaignId,
        "channelstatusId": object.channelstatusId,
        "smsProviderTemplateID": object.smsProviderTemplateID,
        "campaignType": object.campaignType,
        "levelNumber": object.levelNumber,
        "senderId": object.senderId,
        "isFlashMessageEnabled": object.isFlashMessageEnabled,
        "testCampaignEmailAddress": object.testCampaignEmailAddress,
        "targetList": framedAudienceList,
        "totalaudience": object.totalaudience,
        "languageId": languageData.filter(item => item.languageId === object.languageId)[0] || null,
        "isSplitAB": object.isSplitAB,
        "splitAB": object.splitAB,
        "testSmsMobileNo": object.testSmsMobileNo,
        "isSendTestSMS": object.isSendTestSMS,
        "isSendTimeOptEnable": object.IsSendTimeOptEnable,
        "smsSplit": object.smsSplit,
        "content": object.content,
        "smsSchedule": object.smsSchedule,
        "requestForApproval": object.requestForApproval,
        "isRequestForApprovalEnabled": object.isRequestForApprovalEnabled,
    }
    return smsData;
}