import { RTabbar } from "Components/RChart/RTabbar";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import React from "react";
import { BootstrapDropdown } from 'Components/BootstrapDropdown/BootstrapDropdown'
import { Row, Col } from "react-bootstrap";

const widthAlign = {
  overflowWrap: 'break-word',
  width: '80%'
}
class Content extends React.Component {

  state = {
    linkVerify: false,
    mdcLevels: [],
    tabIndex: 0
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      if (this.props.campaignType === "M" && (this.state.mdcLevels.length === 0 || prevProps.data.channelName !== this.props.data.channelName)) {
        let tempMDCLevels = [];
        this.props.data?.contentDetailEmail?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailSMS?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailWA?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.props.data?.contentDetailQR?.map((item, index) => {
          let title = "Level " + (index + 1).toString();
          tempMDCLevels.push({ "text": title, "index": index })
        })
        this.setState({ mdcLevels: tempMDCLevels, tabIndex: 0 })
      }
      this.setState({ linkVerify: false })
    }
  }

  getEmailLinkStatus = () => {
    let status = false;
    let invalidLinks = this.props.data?.contentDetailEmail[0]?.links?.filter(item => item.isValid === false)
    if (invalidLinks?.length === 0) {
      status = true;
    } else {
      status = false
    }

    return status;
  }

  getSMSLinkStatus = () => {
    let status = false;
    let invalidLinks = this.props.data?.contentDetailSMS[0]?.links?.filter(item => item.isValid === false)
    if (invalidLinks?.length === 0) {
      status = true;
    } else {
      status = false
    }

    return status;
  }
  getWhatsAppLinkStatus = () => {
    let status = false;
    let invalidLinks = this.props.data?.contentDetailWA[0]?.links?.filter(item => item.isValid === false)
    if (invalidLinks?.length === 0) {
      status = true;
    } else {
      status = false
    }

    return status;
  }
  getQRLinkStatus = () => {
    let status = false;
    let invalidLinks = this.props.data?.contentDetailQR[0]?.links?.filter(item => item.isValid === false)
    if (invalidLinks?.length === 0) {
      status = true;
    } else {
      status = false
    }

    return status;
  }

  render() {
    return (
      <div className={`portlet-container portlet-md ${this.props.campaignType === "M" ? "mdc-precampaign" : ""} ${this.state.linkVerify ? 'show-mini-chart' : ''}`}>
        <div className="portlet-header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center position-relative">
            <h3> Content analysis </h3>
            <RSPPophover text="Score your subject line content predict its open rates, while ensuring the Image to text ratio and File weight are within acceptable standards. Verify if the links used are valid." position="top">
              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue cp" />
            </RSPPophover>
          </div>
          {this.state.mdcLevels.length !== 0 &&
            // <RTabbar
            //   defaultSelectedItem={this.state.tabIndex}
            //   defaultClass="tabDefault"
            //   // dynamicTab="mini"
            //   dynamicTab={`tabs-filled-component marginB15 ${this.state.mdcLevels.length === 1 ? 'one-level' : ''}`}
            //   activeClass="tabDefault active"
            //   tabData={this.state.mdcLevels}
            //   callBack={(item, index) => {
            //     this.setState({ tabIndex: index });
            //   }}
            // />
            <BootstrapDropdown
              data={this.state.mdcLevels || []}
              fieldKey="text"
              defaultItem={this.state.mdcLevels[this.state.tabIndex].text}
              customAlignRight={true}
              alignRight
              // className={`marginR10 fs19-btn float-end`}
              onSelect={(item, index) => {
                this.setState({ tabIndex: index });
              }}
            />
          }
        </div>


        {this.props.data?.channelName?.toLowerCase() === "email"
          ? <Row className="mb20 split-line-col">
            <Col className="split-line">
              <div className="d-flex justify-content-center flex-column text-center">
                <p className="wsn">Spam score</p>
                <div className="d-block mx-auto">
                  <div className="d-flex align-items-center">
                    {
                      this.props.data?.contentDetailEmail?.length > 0
                        ? <>
                          <h1 className="mb0">
                            {this.props.data?.contentDetailEmail[this.state.tabIndex]?.spamScore}
                            <small>%</small>
                          </h1>
                          <i className="icon-sd-thumbs-up-fill-large icons-md paddingR5 green-medium pl5" />
                        </>
                        : <h1 className="mb0">NA</h1>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col className="split-line">
              <div className="d-flex justify-content-center flex-column text-center">
                <p>Image to text ratio</p>
                <div className="d-block mx-auto">
                  <div className="d-flex align-items-center">
                    {
                      this.props.data?.contentDetailEmail?.length > 0
                        ? <>
                          <h1 className="mb0">{this.props.data?.contentDetailEmail[this.state.tabIndex]?.ratio}</h1>
                          <i className="icon-sd-tick-medium icons-md fw-bold paddingR5 green-medium pl5" />
                        </>
                        : <h1 className="mb0">NA</h1>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col className="split-line">
              <div className="d-flex justify-content-center flex-column text-center">
                <p>File weight</p>
                <div className="d-block mx-auto">
                  <div className="d-flex align-items-center">
                    {
                      this.props.data?.contentDetailEmail?.length
                        ? <>
                          <h1 className="mb0">{this.props.data?.contentDetailEmail[this.state.tabIndex]?.fileWeight} <small>kb</small></h1>
                          <i className="icon-sd-arrow-up-bold-medium icons-md paddingR5 green-medium pl3" />
                        </>
                        : <h1 className="mb0">NA</h1>
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          : this.props.data?.channelName?.toLowerCase() === "sms" ?
            <Row className="mb20 split-line-col">
              <Col className="split-line">
                <div className="d-flex justify-content-center flex-column text-center">
                  <p className="wsn">No. of SMS</p>
                  <div className="d-block mx-auto">
                    <div className="d-flex align-items-center">
                      {
                        this.props.data?.contentDetailSMS?.length
                          ? <h1>{this.props.data?.contentDetailSMS[this.state.tabIndex]?.noofSMS}</h1>
                          : <h1>NA</h1>
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="split-line">
                <div className="d-flex justify-content-center flex-column text-center">
                  <p className="wsn">Personalization enabled</p>
                  <div className="d-block mx-auto">
                    <div className="d-flex align-items-center">
                      {
                        this.props.data?.contentDetailSMS?.length
                          ? <h1>{this.props.data?.contentDetailSMS[this.state.tabIndex]?.personalization}</h1>
                          : <h1>NA</h1>
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="split-line">
                <div className="d-flex justify-content-center flex-column text-center">
                  <p>Unicode</p>
                  <div className="d-block mx-auto">
                    <div className="d-flex align-items-center">
                      {
                        this.props.data?.contentDetailSMS?.length
                          ? <h1>{this.props.data?.contentDetailSMS[this.state.tabIndex]?.unicode}</h1>
                          : <h1>NA</h1>
                      }
                    </div>
                  </div>
                </div>
              </Col>
            </Row> : this.props.data?.channelName?.toLowerCase() === "qrcode" ?
              <Row className="mb20 split-line-col">
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p className="wsn">QR type</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailQR?.length
                            ? <h1>{this.props.data?.contentDetailQR[this.state.tabIndex]?.qrType}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p className="wsn">Shorten URL enabled</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailQR?.length
                            ? <h1>{this.props.data?.contentDetailQR[this.state.tabIndex]?.shortenURLEnabled}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p>KYC</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailQR?.length
                            ? <h1>{this.props.data?.contentDetailQR[this.state.tabIndex]?.kyc}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> :
              <Row className="mb20 split-line-col">
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p className="wsn">Content length</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailWA?.length
                            ? <h1>{this.props.data?.contentDetailWA[this.state.tabIndex]?.waContent.length}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p className="wsn">Personalization enabled</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailWA?.length
                            ? <h1>{this.props.data?.contentDetailWA[this.state.tabIndex]?.personalization}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="split-line">
                  <div className="d-flex justify-content-center flex-column text-center">
                    <p>Language</p>
                    <div className="d-block mx-auto">
                      <div className="d-flex align-items-center">
                        {
                          this.props.data?.contentDetailWA?.length
                            ? <h1>{this.props.data?.contentDetailWA[this.state.tabIndex]?.languageCode}</h1>
                            : <h1>NA</h1>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
        }
        {((this.props.data?.contentDetailEmail?.length > 0 && this.props.data?.contentDetailEmail[this.state.tabIndex]?.links?.length > 0) ||
          (this.props.data?.contentDetailSMS?.length > 0 && this.props.data?.contentDetailSMS[this.state.tabIndex]?.links?.length > 0) ||
          (this.props.data?.contentDetailWA?.length > 0 && this.props.data?.contentDetailWA[this.state.tabIndex]?.links?.length > 0) ||
          (this.props.data?.contentDetailQR?.length > 0 && this.props.data?.contentDetailQR[this.state.tabIndex]?.links?.length > 0)) &&
          <div className="info-popup">
            <RSPTooltip text="Content analysis info" position="left">
              <i className="icon-sd-circle-info-medium icons-md blue cp"
                onClick={() => {
                  this.setState({
                    linkVerify: true
                  })
                }}
              ></i>
            </RSPTooltip>
          </div>
        }
        {this.props.data?.contentDetailEmail !== null ?
          <>
            <Row>
              <Col>
                <hr />
                <p className="mt20">{this.props.data?.contentDetailEmail?.length > 0 && this.props.data?.contentDetailEmail[this.state.tabIndex]?.links?.length > 0 ? "Link verification status" : ""}</p>
              </Col>
            </Row>
            <Row>
              {(this.props.data?.contentDetailEmail?.length > 0 && this.props.data?.contentDetailEmail[this.state.tabIndex]?.links?.length > 0)
                &&
                <div className="d-flex align-items-center">
                  <i className={`icon-sd-link-medium icons-lg ${this.getEmailLinkStatus() ? "green-medium" : "orange-medium"}`}></i>
                  <span className="marginL10 text-underline-h cp" onClick={() => {
                    this.setState({
                      linkVerify: true
                    })
                  }}>{this.getEmailLinkStatus() ? "Link(s) are correct" : "Some links having an error"}</span>
                </div>
              }
            </Row>
          </> :
          this.props.data?.contentDetailSMS !== null ?
            <>
              <Row>
                <Col>
                  <hr />
                  <p className="mt20">{this.props.data?.contentDetailSMS?.length > 0 && this.props.data?.contentDetailSMS[this.state.tabIndex]?.links?.length > 0 ? "Link verification status" : ""}</p>
                </Col>
              </Row>

              <Row>
                {(this.props.data?.contentDetailSMS?.length > 0 && this.props.data?.contentDetailSMS[this.state.tabIndex]?.links?.length > 0)
                  &&
                  <div className="d-flex align-items-center">
                    <i className={`icon-sd-link-medium icons-lg ${this.getSMSLinkStatus() ? "green-medium" : "orange-medium"}`}></i>
                    <span className="marginL10 text-underline-h cp" onClick={() => {
                      this.setState({
                        linkVerify: true
                      })
                    }}>{this.getSMSLinkStatus() ? "Link(s) are correct" : "Some links having an error"}</span>
                  </div>
                }

              </Row>
            </> : this.props.data?.contentDetailWA !== null ?
              <>
                <Row>
                  <Col>
                    <hr />
                    <p className="mt20">{this.props.data?.contentDetailWA?.length > 0 && this.props.data?.contentDetailWA[this.state.tabIndex]?.links?.length > 0 ? "Link verification status" : ""}</p>
                  </Col>
                </Row>

                <Row>
                  {(this.props.data?.contentDetailWA?.length > 0 && this.props.data?.contentDetailWA[this.state.tabIndex]?.links?.length > 0)
                    &&
                    <div className="d-flex align-items-center">
                      <i className={`icon-sd-link-medium icons-lg ${this.getWhatsAppLinkStatus() ? "green-medium" : "orange-medium"}`}></i>
                      <span className="marginL10 text-underline-h cp" onClick={() => {
                        this.setState({
                          linkVerify: true
                        })
                      }}>{this.getWhatsAppLinkStatus() ? "Link(s) are correct" : "Some links having an error"}</span>
                    </div>
                  }

                </Row>
              </> :
              <>
                <Row>
                  <Col>
                    <hr />
                    <p className="mt20">{this.props.data?.contentDetailQR?.length > 0 && this.props.data?.contentDetailQR[this.state.tabIndex]?.links?.length > 0 ? "Link verification status" : ""}</p>
                  </Col>
                </Row>

                <Row>
                  {(this.props.data?.contentDetailQR?.length > 0 && this.props.data?.contentDetailQR[this.state.tabIndex]?.links?.length > 0)
                    &&
                    <div className="d-flex align-items-center">
                      <i className={`icon-sd-link-medium icons-lg ${this.getQRLinkStatus() ? "green-medium" : "orange-medium"}`}></i>
                      <span className="marginL10 text-underline-h cp" onClick={() => {
                        this.setState({
                          linkVerify: true
                        })
                      }}>{this.getQRLinkStatus() ? "Link(s) are correct" : "Some links having an error"}</span>
                    </div>
                  }

                </Row>
              </>}

        <div className="hori-column-chart p20">
          <i class="icon-sd-close-mini icon-sm cp close-btn"
            onClick={() => {
              this.setState({
                linkVerify: false
              })
            }}></i>
          <div className="portlet-md">
            {!this.props?.data?.links &&
              <>
                <p>Link verification status</p>
                <div className="css-scrollbar link-status-height">
                  {(this.props.data?.contentDetailEmail !== null && this.props.data?.contentDetailEmail?.length > 0) ? this.props.data?.contentDetailEmail[this.state.tabIndex]?.links?.map((item) => {
                    return <div className="d-flex align-items-center mb15">
                      <i className="icon-sd-link-medium icons-lg blue-medium"></i>
                      {/* <span className="marginL10 text-underline-h cp">{item.link}</span> */}
                      <a style={widthAlign} className="marginL10 text-underline-h cp" href={item.link} target="_blank">{item.link}</a>
                      <span className={`${item.isValid ? `icon-sd-circle-tick-mini icons-md green-medium ml10 posr top-2` : `icon-sd-circle-close-medium icons-md maroon-medium ml10 posr top-2`}`}></span>
                    </div>
                  }) : (this.props.data?.contentDetailSMS !== null && this.props.data?.contentDetailSMS?.length > 0) ? this.props.data?.contentDetailSMS[this.state.tabIndex]?.links?.map((item) => {
                    return <div className="d-flex align-items-center mb15">
                      <i className="icon-sd-link-medium icons-lg blue-medium"></i>
                      {/* <span className="marginL10 text-underline-h cp">{item.link}</span> */}
                      <a style={widthAlign} className="marginL10 text-underline-h cp" href={item.link} target="_blank">{item.link}</a>
                      <span className={`${item.isValid ? `icon-sd-circle-tick-mini icons-md green-medium ml10 posr top-2` : `icon-sd-circle-close-medium icons-md maroon-medium ml10 posr top-2`}`}></span>
                    </div>
                  }) : (this.props.data?.contentDetailWA !== null && this.props.data?.contentDetailWA?.length > 0) ? this.props.data?.contentDetailWA[this.state.tabIndex]?.links?.map((item) => {
                    return <div className="d-flex align-items-center mb15">
                      <i className="icon-sd-link-medium icons-lg blue-medium"></i>
                      {/* <span className="marginL10 text-underline-h cp">{item.link}</span> */}
                      <a style={widthAlign} className="marginL10 text-underline-h cp" href={item.link} target="_blank">{item.link}</a>
                      <span className={`${item.isValid ? `icon-sd-circle-tick-mini icons-md green-medium ml10 posr top-2` : `icon-sd-circle-close-medium icons-md maroon-medium ml10 posr top-2`}`}></span>
                    </div>
                  }) : (this.props.data?.contentDetailQR !== null && this.props.data?.contentDetailQR?.length > 0) && this.props.data?.contentDetailQR[this.state.tabIndex]?.links?.map((item) => {
                    return <div className="d-flex align-items-center mb15">
                      <i className="icon-sd-link-medium icons-lg blue-medium"></i>
                      {/* <span className="marginL10 text-underline-h cp">{item.link}</span> */}
                      <a style={widthAlign} className="marginL10 text-underline-h cp" href={item.link} target="_blank">{item.link}</a>
                      <span className={`${item.isValid ? `icon-sd-circle-tick-mini icons-md green-medium ml10 posr top-2` : `icon-sd-circle-close-medium icons-md maroon-medium ml10 posr top-2`}`}></span>
                    </div>
                  })}
                </div>
              </>}
          </div>
        </div>
      </div >
    );
  }
}

export default Content;
