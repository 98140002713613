import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import CampaignAnalyticsDetailComponent from './Components/CampaignAnalyticsDetailComponent';
import CampaignAnalyticsCell from './Components/CampaignAnalyticsCell';
import RSPDateRangePicker from 'Components/DateRangePicker/RSPDateRangePicker';
import SkeletonCampaignList from 'Components/Skeleton/SkeletonCampaignList';
import authContext from 'Helper/StateHandler/auth-context';
import { SearchViewAdvanced } from 'Components/SearchView/SearchView';
import { LayoutPageWrapper } from 'Components/LayoutWrapper/LayoutPageWrapper';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_CAMPAIGN_ANALYTICS_LIST, GET_CAMPAIGN_CHANNEL_INFOLIST } from 'Helper/Constants/endPoint';
import { getAuthClientId, getAuthUserId, getUserData } from 'Helper/Utils/Utils';

const dataState = {
  skip: 0,
  take: 5,
  sort: [
    {
      field: 'orderDate',
      dir: 'desc',
    },
  ],
};

class CampaignAnalytics extends Component {

  static contextType = authContext

  state = {
    showdateRange: true,
    dataResult: null,
    dataState: dataState,
    data: [],
    isShowDateRangePicker: false,
    listError: false,
    totalCount: 0,
    fetchDataParams: {}
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ fetchDataParams: this.getParams(undefined) });
    this.getCampaignAnalyticsList(this.getParams(undefined));
  }

  getParams = searchData => {

    let params = {
      pagination: true,
      recordLimit: searchData !== undefined && searchData.PageSize !== undefined ? searchData.PageSize : 5,
      pageNumber: searchData !== undefined && searchData.PageCount !== undefined ? searchData.PageCount : 1,
      departmentId: 0,
      userId: getAuthUserId(),
      clientId: getAuthClientId(),
      endDate: searchData !== undefined && searchData.to !== '' ? searchData.to : new Date(),
      startDate: searchData !== undefined && searchData.from !== undefined ? searchData.from : new Date(getUserData()?.activationDate),
      campaignType: searchData !== undefined && searchData.campaignType !== undefined ? searchData.campaignType : "",
      deliveryMethod: searchData !== undefined && searchData.deliveryType !== undefined ? searchData.deliveryType : "",
      searchCampaignName: searchData !== undefined && searchData.campaignName !== undefined ? searchData.campaignName : "",
      productType: (searchData !== undefined && searchData.productType !== undefined) ? searchData.productType : ""
    }
    return params
  }

  getCampaignAnalyticsList = params => {
    this.setState({ listError: false, data: [], dataResult: null })
    connectServer({
      path: GET_CAMPAIGN_ANALYTICS_LIST,
      params,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      ok: res => {
        if (res.status && res?.data?.campaignsList && res?.data?.campaignsList?.length) {
          let newTemp = [...res?.data?.campaignsList]
          const tempDataState = {
            skip: 0,
            take: this.state.dataState?.take,
            sort: [
              {
                field: 'orderDate',
                dir: 'desc',
              },
            ],
          };
          this.setState({
            dataResult: process(newTemp, tempDataState),
            data: res?.data?.campaignsList,
            totalCount: res?.data?.totalNoRecord
          })
        } else {
          this.setState({ listError: true, data: [], dataResult: [] })
        }
      },
      fail: err => { }
    })
  }

  CampaignAnalyticsRowCell = props => <CampaignAnalyticsCell {...props} />;

  dataStateChange = event => {
    const { skip, take } = event.dataState;
    const currentPage = Math.floor(skip / take + 1)
    this.setState({
      dataResult: process(this.state?.data, event?.dataState),
      dataState: event?.dataState
    });
    let tempFetchDataParams = { ...this.state.fetchDataParams };
    tempFetchDataParams.recordLimit = take;
    tempFetchDataParams.pageNumber = currentPage;
    this.setState({ fetchDataParams: tempFetchDataParams })
    this.getCampaignAnalyticsList(tempFetchDataParams)

  };

  expandChange = event => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    if (!isExpanded) {
      this.getCampaignChannelInfoList(event.dataIndex, event.dataItem.campaignId)
    }
    this.setState({ ...this.state });

  };

  getCampaignChannelInfoList = (dataIndex, campaignId) => {

    const params = {
      "campaignId": campaignId
    }

    connectServer({
      path: GET_CAMPAIGN_CHANNEL_INFOLIST,
      loading: this.context.globalStateData.setIsLoadingData,
      sessionOut: this.context.globalStateData.setSessionOutData,
      context: this.context,
      params,
      ok: res => {
        if (res.status) {
          let tempData = [...this.state?.data]
          let tempDataIndex = Math.floor(dataIndex % this.state.dataState.take)
          let item = tempData[tempDataIndex]
          item.channels = res?.data?.channelList
          this.setState({ data: tempData })
        }
      },
      fail: err => { }
    })

  }

  TitleView = props => {

    return <div className="d-flex justify-content-between marginT15 marginB15">
      <h1 className="marginB0">Communication analytics</h1>
    </div>

  }

  closeDateRangePicker = (status, dates) => {
    let tempFetchDataParams = { ...this.state.fetchDataParams }
    tempFetchDataParams.startDate = new Date(dates.start);
    tempFetchDataParams.endDate = new Date(dates.end);
    tempFetchDataParams.recordLimit = 5;
    tempFetchDataParams.pageNumber = 1;
    this.setState({ fetchDataParams: tempFetchDataParams, dataState: dataState });
    if (!status)
      this.getCampaignAnalyticsList(tempFetchDataParams)

    this.setState({ isShowDateRangePicker: status })

  }

  searchBarCallBack = (status, dates) => {
  }

  HeaderView = props => {

    return <Row>
      <Col>
        <div className="d-flex justify-content-between marginT5 padding0">
          <div></div>
          <div className="d-flex align-items-center">
            <RSPDateRangePicker
              class="marginR15"
              isShowDateRangePicker={this.state.isShowDateRangePicker}
              enable={true}
              closeDateRangePicker={(status, date) => this.closeDateRangePicker(status, date)}
              cancelBtnClicked={() => {
                this.setState({ isShowDateRangePicker: false })
              }}
            />
            <SearchViewAdvanced
              cls=""
              dropdownData={['Communication name', 'Communication type', "Delivery type", 'Product type', 'My communication', 'All communication']}
              enable={true}
              isCampaign={true}
              closeDateRangePicker={() => this.searchBarCallBack()}
              onClickOutside={() => this.setState({ showdateRange: false })}
              onDataUpdate={data => {
                let tempFetchDataParams = { ...this.state.fetchDataParams };
                tempFetchDataParams.searchCampaignName = (data !== undefined && data.campaignName !== undefined) ? data.campaignName : ""
                tempFetchDataParams.campaignType = (data !== undefined && data.campaignType !== undefined) ? data.campaignType : ""
                tempFetchDataParams.deliveryMethod = (data !== undefined && data.deliveryType !== undefined) ? data.deliveryType : ""
                tempFetchDataParams.productType = (data !== undefined && data.productType !== undefined) ? data.productType : ""
                tempFetchDataParams.recordLimit = 5;
                tempFetchDataParams.pageNumber = 1;
                tempFetchDataParams.userId = (data !== undefined && data.userId !== undefined) ? data.userId === "My communication" ? getAuthUserId() : 0 : tempFetchDataParams.userId
                this.setState({ fetchDataParams: tempFetchDataParams, dataState: dataState });
                this.getCampaignAnalyticsList(tempFetchDataParams);
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  }

  CampaignAnalyticsDetailCell = props => {
    return <CampaignAnalyticsDetailComponent data={this.state.data} {...props} />
  }

  GridView = props => {

    return <Row>
      <Col>
        {this.state.dataResult &&
          <Grid
            className="grid-pagenation-del rs-grid-listing report-listing"
            data={this.state.dataResult}
            {...this.state.dataState}
            sortable={false}
            scrollable={"none"}
            groupable={false}
            total={this.state.totalCount}
            onDataStateChange={this.dataStateChange}
            detail={this.CampaignAnalyticsDetailCell}
            expandField="expanded"
            onExpandChange={this.expandChange}
            pageable={{
              //type: 'input',
              pageSizes: [5, 10, 20],
              previousNext: true,
            }}
          >
            <GridColumn
              field="customerID"
              cell={this.CampaignAnalyticsRowCell}
            />
          </Grid>
        }
      </Col>
    </Row>

  }

  render() {
    return (
      <LayoutPageWrapper>
        <Container className="page-header box-bottom-space">
          <this.TitleView />
          <hr className="blue" />
          <this.HeaderView />
          {
            this.state.dataResult?.data?.length
              ? <this.GridView />
              : <div className="p0 mt15"><SkeletonCampaignList isError={this.state.listError} /></div>
          }
        </Container>
      </LayoutPageWrapper>
    )
  }

}

export default withRouter(CampaignAnalytics);
