import React, { useState, useEffect, useContext } from 'react'
import { Row, Col } from 'react-bootstrap';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSCheckbox, RSInput2 } from 'Components/RSInputs';
import { RSPrimaryBtn } from 'Components/RSButtons';
import { useHistory } from 'react-router-dom';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import authContext from 'Helper/StateHandler/auth-context';
import { getAuthClientId, getCampaignMasterData, getLsMasterDataDropdown, getUserData, isEmpty } from 'Helper/Utils/Utils';
import { GET_CLIENT_LOCALIZATION, GET_THEME, SAVE_THEME, UPDATE_CLIENT_LOCALE_SETTING } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
// import { ChipList, Chip, ChipListDataChangeEvent, ChipProps } from "@progress/kendo-react-buttons";
import Threshold from "./Threshold";
import iconVisa from "Assets/Images/icon_visa.svg";
import { ENTER_DOMAIN, SELECT_CURRENCY, SELECT_DATE_FORMAT, SELECT_LANGUAGE, SELECT_TIMEZONE, SELECT_TIME_FORMAT, SPECIAL_CHARACTERS } from 'Helper/Constants/validationMessage';
import { RSPTooltip } from 'Components/Tooltip';
const Settings = (props) => {
  const history = useHistory()
  const context = useContext(authContext)
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [campaginTypeData, setCampaignTypeData] = useState([])
  const [campaignTypeValue, setCampaignTypeValue] = useState([])

  const [productCategories, setProductCategories] = useState([])
  const [productCategoryValue, setProductCategoryValue] = useState([])

  const [engagementData, setEngagementData] = useState([])
  const [engagementValue, setEngagementValue] = useState([])

  const [conversionTypeData, setConversionTypeData] = useState([])
  const [conversionTypeValue, setConversionTypeValue] = useState([])

  const [currencyDropdownData, setCurrencyDropdownData] = useState([])
  const [currencyDropdownItem, setCurrencyDropdownItem] = useState({
    currencyId: 0,
    currencyName: '-- Currency --'
  })

  const [dateDropdownData, setDateDropdownData] = useState([])
  const [dateDropdownItem, setDateDropdownItem] = useState({
    dateFormatId: 0,
    dateFormat: '-- Date format --'
  })

  const [languageDropdownData, setLanguageDropdownData] = useState([])
  const [languageDropdownItem, setLanguageDropdownItem] = useState({
    languageId: 0,
    languageName: '-- Language --'
  })

  const [timeDropdownData, setTimeDropdownData] = useState([])
  const [timeDropdownItem, setTimeDropdownItem] = useState({
    timeFormatId: 0,
    timeFormat: '-- Time format --'
  })

  const [timezoneDropdownData, setTimeZoneDropdownData] = useState([])
  const [timezoneDropdownItem, setTimeZoneDropdownItem] = useState({
    timezoneId: 0,
    timezoneName: '-- Timezone --'
  })

  const [dayLightSavings, setDayLightSavings] = useState(false)

  const [errCampaignType, setErrCampaignType] = useState(null)
  const [errEngagementType, setErrEngagementType] = useState(null)
  const [errConversionType, setErrConversionType] = useState(null)
  const [errProductCategories, setErrProductCategories] = useState(null)
  const [currencyErrMsg, setCurrencyErrMsg] = useState(null)
  const [languageErrMsg, setLanguageErrMsg] = useState(null)
  const [timeZoneErrMsg, setTimeZoneErrMsg] = useState(null)
  const [dateFormatErrMsg, setDateFormatErrMsg] = useState(null)
  const [timeFormatErrMsg, setTimeFormatErrMsg] = useState(null)
  const [themeSelected, setThemeSelected] = useState('')
  const [isError, setIsError] = useState(false)
  const [activeTheme, setActiveTheme] = useState(0)
  const [shortenURL, setShortenURL] = useState("");
  const [errShortenURL, setErrShortenURL] = useState(null);

  const setDropdownStates = () => {
    const masterData = getLsMasterDataDropdown()
    setCurrencyDropdownData(masterData.currencyMaster || [])
    setDateDropdownData(masterData.dateFormat || [])
    setLanguageDropdownData(masterData.languageMaster || [])
    setTimeDropdownData(masterData.timeFormat || [])
    setTimeZoneDropdownData(masterData.timeZone || [])
  }

  useEffect(() => {

    if (context.globalStateData.getIsPrototype) {
      return
    }
    setDropdownStates()
    getThemeData()
    const params = {
      clientId: getAuthClientId(),
    }
    connectServer({
      path: GET_CLIENT_LOCALIZATION,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          setStateData(res.data)
        }
      },
      fail: err => {
      }
    })
  }, [context.globalStateData.getIsReloadData])

  const setStateData = (data) => {
    const masterData = getLsMasterDataDropdown()
    setCurrencyDropdownItem(
      masterData.currencyMaster.filter(item => item.currencyId === data.currencyId)[0] || null
    )
    setDateDropdownItem(
      masterData.dateFormat.filter(item => item.dateFormatId === data.dateFormatId)[0] || null
    )
    setLanguageDropdownItem(
      masterData.languageMaster.filter(item => item.languageId === data.languageId)[0] || null
    )
    setTimeDropdownItem(
      masterData.timeFormat.filter(item => item.timeFormatId === data.timeformatId)[0] || null
    )
    setTimeZoneDropdownItem(
      masterData.timeZone.filter(item => item.timezoneId === data.timezoneId)[0] || null
    )

    let tempCampaignType = [...data.campaignTypeMaster];
    tempCampaignType.forEach((item, index) => {
      item.isDelete = false;
    })
    let tempProductCategory = [...data.productCategoryMaster];
    tempProductCategory.forEach((item, index) => {
      item.isDelete = false;
    })
    let tempEngagementType = [...data.engagementMaster];
    tempEngagementType.forEach((item, index) => {
      item.isDelete = false;
    })
    let tempConversionType = [...data.conversionMaster];
    tempConversionType.forEach((item, index) => {
      item.isDelete = false;
    })
    setCampaignTypeData(tempCampaignType)
    setCampaignTypeValue(tempCampaignType)

    setProductCategories(tempProductCategory)
    setProductCategoryValue(tempProductCategory)

    setEngagementData(tempEngagementType)
    setEngagementValue(tempEngagementType)

    setConversionTypeData(tempConversionType)
    setConversionTypeValue(tempConversionType)

    setDayLightSavings(data.isDayLight)
    setShortenURL(data.brandedShortUrl)
  }

  const handleChange = event => {
    let values = event.target.value;
    if (values.length > campaignTypeValue.length) {
      let lastItem = values[values.length - 1];

      if (lastItem) {
        values.pop();
        const sameItem = values.find(value => value === lastItem);

        if (sameItem === undefined) {
          let item = { campaignTypeId: 0, campaignTypeValue: lastItem.campaignTypeValue, isDelete: false }
          let tempCampaignData = [...campaginTypeData]
          tempCampaignData.push(item);
          setCampaignTypeData(tempCampaignData);
          values.push(item);
        }
      }
    }
    setErrCampaignType(values.length > 0 ? null : "Add communication type");
    setCampaignTypeValue(values);
  };

  const handleChange2 = event => {
    let values = event.target.value;
    if (values.length > engagementValue.length) {
      let lastItem = values[values.length - 1];

      if (lastItem) {
        values.pop();
        let sameItem = values.find(value => value === lastItem);

        if (sameItem === undefined) {
          let item = { conversionTypeId: 0, conversionName: lastItem.conversionName, isDelete: false }
          let tempEngagementData = [...engagementData]
          tempEngagementData.push(item);
          setEngagementData(tempEngagementData);
          values.push(lastItem);
        }
      }
    }
    setErrEngagementType(values.length > 0 ? null : "Add engagement type");
    setEngagementValue(values);
  };


  const handleChange3 = event => {
    let values = event.target.value;
    if (values.length > 0) {
      let lastAddedItem = values[values.length - 1]
      var regex = /^[A-Za-z0-9 ]+$/;
      let errMsg = null
      errMsg = regex.test(lastAddedItem.friendlyName) ? lastAddedItem.friendlyName.length > 50 ? "Product category can have 50 characters" : null : SPECIAL_CHARACTERS;
      if (errMsg === null) {
        const hasDuplicates = (arr) => {
          let duplicates = arr.filter((item) => item.friendlyName === lastAddedItem.friendlyName);
          return duplicates.length > 0 ? true : false;
        };
        if (values.length > productCategoryValue.length && values.length < 11) {
          if (!hasDuplicates(productCategoryValue)) {
            let lastItem = values[values.length - 1];
            if (lastItem) {
              values.pop();
              let sameItem = values.find(value => value.friendlyName === lastItem.friendlyName);

              if (sameItem === undefined) {
                let item = { categoryId: values.length + 1, friendlyName: lastItem.friendlyName.trim(), isDelete: false, isNew: true }
                let tempProductCategoryData = [...productCategories]
                tempProductCategoryData.push(item);
                setProductCategories(tempProductCategoryData);
                values.push(item);
              }
            }
            setErrProductCategories(values.length > 0 ? null : "Add product category");
            setProductCategoryValue(values);
          } else {
            setErrProductCategories("Can't accept duplicate product category");
          }
        } else {
          if (values.length > productCategoryValue.length) {
            setErrProductCategories(values.length < 11 ? null : "Reached maximum limit");
          } else {
            const results = productCategoryValue.filter(({ friendlyName: fn1 }) => !values.some(({ friendlyName: fn2 }) => fn2 === fn1));
            if (!!results[0].categoryId && !results[0].isNew) {
              setErrProductCategories("Unable to remove existing category");
            } else {
              setProductCategoryValue(values);
              setProductCategories(values);
            }
          }
        }
      } else {
        setErrProductCategories(errMsg);
      }
    } else {
      setErrProductCategories(values.length > 0 ? null : "Add product category");
      setProductCategoryValue(values);
      setProductCategories(values);
    }
  };

  const handleChange4 = event => {
    let values = event.target.value;
    if (values.length > conversionTypeValue.length) {
      let lastItem = values[values.length - 1];

      if (lastItem) {
        values.pop();
        let sameItem = values.find(value => value === lastItem);

        if (sameItem === undefined) {
          let item = { conversionTypeId: 0, conversionName: lastItem.conversionName, isDelete: false }
          let tempConversionTypeData = [...conversionTypeData]
          tempConversionTypeData.push(item);
          setConversionTypeData(tempConversionTypeData);
          values.push(lastItem);
        }
      }
    }
    setErrConversionType(values.length > 0 ? null : "Add conversion type");
    setConversionTypeValue(values);
  };

  const handleTheme = event => {
    setActiveTheme(event)
  }
  const getThemeData = () => {
    const params = {}
    connectServer({
      path: GET_THEME,
      params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res.status) {
          let index = themeName.indexOf(res?.data?.themeData?.themeName)
          setActiveTheme(index)
        }
      },
      fail: err => {

      }
    })
  }
  // const activeClass = event => {

  // }

  // const msTheme = themeSelected
  // console.log("Marketing star Theme: ", msTheme);

  return (
    <div>
      <div className="settings-container">
        <h4>Localization details</h4>
        {/* firstrow */}
        <Row>
          <Col>
            <div className='position-relative'>
              {currencyErrMsg != null && <div className='validation-message'>{currencyErrMsg}</div>}
              <RSDropdownList
                data={currencyDropdownData}
                className='required'
                defaultItem={{
                  currencyId: -1,
                  currencyName: '-- Select currency --'
                }}
                value={currencyDropdownItem}
                textField='currencyName'
                dataItemKey='currencyId'
                onChange={(e) => {
                  let errMsg = e.target.value.currencyId !== -1 ? null : SELECT_CURRENCY;
                  setCurrencyErrMsg(errMsg)
                  setCurrencyDropdownItem(e.target.value)
                }}
              />
            </div>
          </Col>
          <Col>
            <div className='position-relative'>
              {dateFormatErrMsg != null && <div className='validation-message'>{dateFormatErrMsg}</div>}
              <RSDropdownList
                data={dateDropdownData}
                className='required'
                defaultItem={{
                  dateFormatId: -1,
                  dateFormat: '-- Select date format --'
                }}
                value={dateDropdownItem}
                textField='dateFormat'
                dataItemKey='dateFormatId'
                onChange={(e) => {
                  let errMsg = e.target.value.dateFormatId !== -1 ? null : SELECT_DATE_FORMAT;
                  setDateFormatErrMsg(errMsg)
                  setDateDropdownItem(e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>

        {/* second row */}
        <Row>
          <Col>
            <div className='position-relative'>
              {languageErrMsg != null && <div className='validation-message'>{languageErrMsg}</div>}
              <RSDropdownList
                data={languageDropdownData}
                className='required'
                defaultItem={{
                  languageId: -1,
                  languageName: '-- Select language --'
                }}
                value={languageDropdownItem}
                textField='languageName'
                dataItemKey='languageId'
                onChange={(e) => {
                  let errMsg = e.target.value.languageId !== -1 ? null : SELECT_LANGUAGE;
                  setLanguageErrMsg(errMsg)
                  setLanguageDropdownItem(e.target.value)
                }}
              />
            </div>
          </Col>
          <Col>
            <div className='position-relative'>
              {timeFormatErrMsg != null && <div className='validation-message'>{timeFormatErrMsg}</div>}
              <RSDropdownList
                data={timeDropdownData}
                className='required'
                defaultItem={{
                  timeFormatId: -1,
                  timeFormat: '-- Select time format --'
                }}
                value={timeDropdownItem}
                textField='timeFormat'
                dataItemKey='timeFormatId'
                onChange={(e) => {
                  let errMsg = e.target.value.timeFormatId !== -1 ? null : SELECT_TIME_FORMAT;
                  setTimeFormatErrMsg(errMsg)
                  setTimeDropdownItem(e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>

        {/* third row */}
        <Row>
          <Col>
            <div className='position-relative'>
              {timeZoneErrMsg != null && <div className='validation-message'>{timeZoneErrMsg}</div>}
              <RSDropdownList
                data={timezoneDropdownData}
                className='required'
                defaultItem={{
                  timezoneId: -1,
                  timezoneName: '-- Select time zone --'
                }}
                value={timezoneDropdownItem}
                textField='timezoneName'
                dataItemKey='timezoneId'
                onChange={(e) => {
                  let errMsg = e.target.value.timezoneId !== -1 ? null : SELECT_TIMEZONE;
                  setTimeZoneErrMsg(errMsg)
                  setTimeZoneDropdownItem(e.target.value)
                }}
              />
            </div>
          </Col>
          <Col>
            <div className={`${timezoneDropdownItem?.isDayLight ? "" : "click-off"}`}>
              <RSCheckbox
                className='marginT10'
                checked={dayLightSavings}
                cb={status => setDayLightSavings(status)}
                lbl='Daylight saving'
              />
            </div>
          </Col>
        </Row>

        <div className="marginT20">
          <Row>
            <Col md={4}>
              <h4>Communication types</h4>
              <div className="chips-container mb43">
                <div className="chips-box">
                  <div className='multi-camp-type-max'>
                    <div className="position-relative click-off">
                      {errCampaignType !== null && <div className='validation-message top-35 left-5'>{errCampaignType}</div>}
                      <MultiSelect
                        data={campaginTypeData || []}
                        onChange={handleChange}
                        value={campaignTypeValue || []}
                        textField='campaignTypeValue'
                        dataItemKey='campaignTypeId'
                        placeholder='Enter communication type'
                      />
                    </div>
                    {/* <ChipList data={fruits} selection="multiple" onDataChange={handleDataChange} chip={props => <Chip removable={true} {...props} />} /> */}
                  </div>
                </div>
                <small>Up to 10 communication types can be added</small>
              </div>
            </Col>

            <Col md={4}>
              <h4>Goal types</h4>
              <div className="chips-container mb43">
                <div className="chips-box">
                  <h6>Engagement</h6>
                  <div className="multi-engage-max">
                    <div className="position-relative click-off">
                      {errEngagementType !== null && <div className='validation-message top-35 left-5'>{errEngagementType}</div>}
                      <MultiSelect
                        data={engagementData || []}
                        onChange={handleChange2}
                        value={engagementValue || []}
                        textField='conversionName'
                        dataItemKey='conversionTypeId'
                        placeholder='Enter engagement type'
                      />
                    </div>
                  </div>
                  <div className="dotted-line"></div>
                  <h6>Conversion</h6>
                  <div className="multi-conversion-max">
                    <div className="position-relative click-off">
                      {errConversionType !== null && <div className='validation-message top-35 left-5'>{errConversionType}</div>}
                      <MultiSelect
                        data={conversionTypeData || []}
                        onChange={handleChange4}
                        value={conversionTypeValue || []}
                        textField='conversionName'
                        dataItemKey='conversionTypeId'
                        placeholder='Enter conversion type'
                      />
                    </div>
                    {/* <ChipList data={fruits} selection="multiple" onDataChange={handleDataChange} chip={props => <Chip removable={true} {...props} />} /> */}
                  </div>
                </div>
                <small>Up to 10 conversion types can be added</small>
              </div>
            </Col>

            <Col md={4}>
              <h4>Product types</h4>
              <div className="chips-container mb43">
                <div className="chips-box">
                  <div className='multi-product-cate-max'>
                    <div className="position-relative">
                      {errProductCategories !== null && <div className='validation-message left-5'>{errProductCategories}</div>}
                      <MultiSelect
                        data={productCategories || []}
                        onChange={handleChange3}
                        value={productCategoryValue || []}
                        textField='friendlyName'
                        dataItemKey='categoryId'
                        placeholder='Enter product category'
                        allowCustom={true}
                      />
                    </div>
                    {/* <ChipList data={fruits} selection="multiple" onDataChange={handleDataChange} chip={props => <Chip removable={true} {...props} />} /> */}
                  </div>
                </div>
                <small>Up to 10 product categories can be added</small>
              </div>
            </Col>
          </Row>
        </div>

        <div className="marginB20">
          <Row>
            <Col md={4}>
              <h4>Branded - Short URL</h4>
              <RSInput2
                name="shortUrl"
                ph="mkstr.io"
                cls='mb0'
                val={shortenURL}
                cb={(value) => {
                  let errMsg = value.length ? null : ENTER_DOMAIN;
                  setShortenURL(value);
                  setErrShortenURL(errMsg)
                }}
                ob={(value) => {
                  let errMsg = value.length ? null : ENTER_DOMAIN;
                  let regex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                  if (errMsg === null) {
                    if (value.length > 253) {
                      setErrShortenURL("Domain length does not exists 253")
                    } else {
                      let domainArray = value.split(".");
                      if (domainArray.length > 3) {
                        setErrShortenURL("Domain can have maximum 2 dots only")
                      } else {
                        let morethan = domainArray.filter(item => item.length > 63).length > 0 ? true : false;
                        if (morethan) {
                          setErrShortenURL("Each labels length does not exists 63")
                        } else {
                          if (regex.test(value)) {
                            setShortenURL(value);
                            setErrShortenURL(errMsg)
                          } else {
                            setErrShortenURL("Enter valid domain")
                          }
                        }
                      }

                    }
                  } else {
                    setShortenURL(value);
                    setErrShortenURL(errMsg)
                  }
                }}
                errorMessage={errShortenURL}
              />
              <small>Ex - mkstr.io</small>
            </Col>
          </Row>
        </div>


        {isOpenModal ? (
          <Threshold
            isOpen={isOpenModal}
            onChangeIsOpen={(value) => {
              setIsOpenModal(!isOpenModal);
            }}
          ></Threshold>
        ) : null}
        <Row className='d-none'>
          <Col>
            <h4 className="margin-T20 margin-B20 col-sm-12">
              Payment settings
            </h4>
            <div className="position-relative">
              <Row>
                <Col>
                  <div className="well consume-portlet-box  h-auto">
                    <Row
                      style={{
                        borderBottom: "1px solid #b8b8b8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "13px 17px",
                      }}
                    >
                      <Col md="6">
                        <h4
                          style={{
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Next bill
                        </h4>
                      </Col>

                      <Col md="6">
                        <RSPrimaryBtn
                          className='float-end mb10 click-off'
                          onClick={() => {
                            setIsOpenModal(true);
                          }}
                        >
                          Manage
                        </RSPrimaryBtn>
                      </Col>
                    </Row>
                    <Row
                      className='mt10 mb7'
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "13px 17px;",
                      }}
                    >
                      <Col md="6">
                        <h3 className='p10 fs18'
                          style={{
                            backgroundColor: "#f5f7f8",
                            borderRadius: "6px",
                          }}
                        >31 August 2022</h3>
                      </Col>

                      <Col md="6">
                        <h3 className='text-end fontB m0'>$32.21</h3>
                        <p className='text-end mb10'>Amount due</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div className="well consume-portlet-box h-auto">
                    <Row
                      style={{
                        borderBottom: "1px solid #b8b8b8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "13px 17px;",
                      }}
                    >
                      <Col md="6">
                        <h4
                          className='my15'
                          style={{ fontSize: "18px", fontWeight: "400" }}
                        >
                          Payment methods
                        </h4>
                      </Col>
                    </Row>
                    <Row
                      className='mt20 mb10'
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "13px 17px;",
                      }}
                    >
                      <Col md="4">
                        <img
                          style={{
                            maxWidth: "65px",
                            border: "1px solid #b8b8b8",
                            padding: "8px",
                            borderRadius: "3px",
                          }}
                          src={iconVisa}
                        ></img>
                      </Col>

                      <Col md="8">
                        <div className='float-end mr10'>
                          <RSPrimaryBtn className='mr20 click-off'>Reconfirm</RSPrimaryBtn>
                          <RSPrimaryBtn className="click-off">Remove</RSPrimaryBtn>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className=''>
          <Col>
            <h4 className="margin-T20 margin-B20 col-sm-12">Theme settings</h4>
            <div className="position-relative">
              <div className="well consume-portlet-box h-auto">
                <ul className='theme-setting-lists row m0'>
                  {
                    themeName.map((item, index) => {
                      let label = item
                      let title = label.charAt(0).toUpperCase() + label.slice(1)
                      return <li className={`col-md-4 ${activeTheme === index ? 'active' : ''}`} onClick={() => {
                        context.globalStateData.setSelectedThemeStatus(item)
                        // setActiveTheme(!activeTheme[index])
                        // console.log("Selected Theme: ", item);
                        setThemeSelected(item)
                        handleTheme(index)
                      }
                      } key={index}>
                        <label>{title}</label>
                        <div className='theme-list'>
                          <ul>
                            {
                              themeSetColors.map((item2, index2) => {
                                let str = item2
                                let name = str.charAt(0).toUpperCase() + str.slice(1)
                                return <RSPTooltip text={`${name}`} position="top">
                                  <li className={`theme-${item}-${item2}`}></li>
                                </RSPTooltip>
                              })
                            }
                          </ul>
                        </div>
                        {
                          activeTheme === index
                            ? <i className='icon-sd-circle-tick-medium icons-md blue ml10'></i>
                            : null
                        }
                      </li>
                    })
                  }

                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <div className='btn-container d-flex justify-content-end mt0 box-bottom-space'>
          <RSPrimaryBtn
            onClick={() => {
              let isValidate = true;
              if (currencyDropdownItem.currencyId === -1) {
                setCurrencyErrMsg("Select currency")
                isValidate = false;
              }
              if (dateDropdownItem.dateFormatId === -1) {
                setDateFormatErrMsg("Select date format")
                isValidate = false;
              }
              if (languageDropdownItem.languageId === -1) {
                setLanguageErrMsg("Select language")
                isValidate = false;
              }
              if (timeDropdownItem.timeFormatId === -1) {
                setTimeFormatErrMsg("Select time format")
                isValidate = false;
              }
              if (timezoneDropdownItem.timezoneId === -1) {
                setTimeZoneErrMsg("Select time zone")
                isValidate = false;
              }
              // if (errShortenURL === null) {
              //   if (isEmpty(shortenURL)) {
              //     setErrShortenURL("Enter valid domain")
              //     isValidate = false;
              //   }
              // } else {
              //   isValidate = false;
              // }
              const params = {
                clientId: getAuthClientId(),
                currencyId: currencyDropdownItem.currencyId,
                dateFormatId: dateDropdownItem.dateFormatId,
                languageId: languageDropdownItem.languageId,
                timeformatId: timeDropdownItem.timeFormatId,
                timezoneId: timezoneDropdownItem.timezoneId,
                brandedShortUrl: shortenURL,
                isDayLight: dayLightSavings,
              }
              if (campaignTypeValue.length === 0) {
                let errMsg = "Add communication types"
                setErrCampaignType(errMsg)
                isValidate = false;
              }
              let tempCType = [...campaginTypeData];
              tempCType.forEach(litem => {
                let data = campaignTypeValue.filter(ritem => litem.campaignTypeValue === ritem.campaignTypeValue)
                if (data.length === 0) {
                  litem.isDelete = true
                }
              })
              params.campaignTypeMaster = tempCType

              let tempEngagementType = [...engagementData]
              if (engagementValue === null || engagementValue === undefined || engagementValue.length === 0) {
                let errMsg = "Add engagement types"
                setErrEngagementType(errMsg)
                isValidate = false;
              }
              tempEngagementType.forEach(litem => {
                let data = engagementValue.filter(ritem => litem.conversionName === ritem.conversionName)
                if (data.length === 0) {
                  litem.isDelete = true
                }
              })
              params.engagementMaster = tempEngagementType

              let tempConversionType = [...conversionTypeData];
              if (conversionTypeValue === null || conversionTypeValue === undefined || conversionTypeValue.length === 0) {
                let errMsg = "Add conversion types"
                setErrConversionType(errMsg)
                isValidate = false;
              }

              tempConversionType.forEach(litem => {
                let data = conversionTypeValue.filter(ritem => litem.conversionName === ritem.conversionName)
                if (data.length === 0) {
                  litem.isDelete = true
                }
              })
              params.conversionMaster = tempConversionType

              if (productCategoryValue === null || productCategoryValue === undefined || productCategoryValue.length === 0) {
                let errMsg = "Add product types"
                setErrProductCategories(errMsg)
                isValidate = false;
              }

              let tempProductType = [...productCategories]
              tempProductType.forEach(litem => {
                if (!!litem.isNew && litem.isNew) {
                  litem.categoryId = 0
                }
                let data = productCategoryValue.filter(ritem => litem.friendlyName === ritem.friendlyName)
                if (data.length === 0) {
                  litem.isDelete = true
                }
              })
              params.productCategoryMaster = tempProductType


              const userData = getUserData()
              // params.sourceType = getSourceType()
              params.tenantName = userData?.clientDetailsLocalization?.instanceName || null

              if (isValidate) {
                //Save Theme
                let themeParams = {
                  "themeName": themeSelected,
                  "themeJson": "test json"
                }
                connectServer({
                  path: SAVE_THEME,
                  params: themeParams,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: res => {
                    if (res.status) {
                      history.push(`/preferences`)
                    } else {
                      setIsError(true)
                    }
                  },
                  fail: err => { }
                })
                connectServer({
                  path: UPDATE_CLIENT_LOCALE_SETTING,
                  params,
                  loading: context.globalStateData.setIsLoadingData,
                  sessionOut: context.globalStateData.setSessionOutData,
                  context: context,
                  ok: res => {
                    if (res.status) {
                      // campaignPlanAssets(context, response => {
                      // })
                      getCampaignMasterData(context);
                      history.push(`/preferences`)
                    } else {
                      setIsError(true)
                    }
                  },
                  fail: err => { }
                })
              }
            }}
          >
            Save
          </RSPrimaryBtn>
        </div>
      </div>

    </div>

  )
}
export default Settings

const themeSetColors = ['header', 'primary', 'secondary', 'table', 'even', 'button']
const themeName = ['default', 'earthy', 'coolfresh', 'striking', 'yetwarm', 'sleek', 'glamorous', 'aquagreens']
let colorJson = {
  headerColor: '#4a051b',
  primaryColor: '#4a051b',
  secondaryColor: '#fc6a00',
  tableColor: '#722620',
  evenColor: '#fae9db',
  buttonColor: '#722620'
}