import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSInput2, RSRadio } from "Components/RSInputs";
import {
  FRIENDLY_NAME_EXIST,
  GET_LIST_DATA_EXCHANGE,
  REMOTE_SETTING_COLUMN,
} from "Helper/Constants/endPoint";
import {
  FRIENDLY_NAME,
  PASS_WORD,
  URL,
} from "Helper/Constants/validationMessage";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import { isEmpty, isValidWebsite, validateIPAddress } from "Helper/Utils/Utils";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DedupeSettingModal from "./DedupeSettingModal";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TableDetails from "./TableDetails";

const Versium = (props) => {
  const context = useContext(authContext);
  const history = useHistory();
  const {
    state: { id = 0, mode = "" },
  } = useLocation();


  const [friendlyName, setFriendlyName] = useState("");
  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [errMsgFriendlyName, setErrFriendlyName] = useState(null);
  const [errMsgPassword, setErrPassword] = useState(null);
  const [errURL, setErrURL] = useState(null);
  const [connection, setConnection] = useState(false);
  const [dedupModalFlag, setDedupModalFlag] = useState(false);
  const [importPreference, setImportPreference] = useState("");
  const [tableName, setTableName] = useState("versium");
  const [state, setState] = useState({
    leftColumnValues: [],
    rightColumnValues: [],
  });
  let fillterData = [
    "Contact",
    "Demographic",
    "Online audience",
    "Firmographic",
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    function fetchData() {
      let params = { DepartmentID: 0, RemotesettingID: id };
      connectServer({
        path: GET_LIST_DATA_EXCHANGE,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            let {
              friendlyName = "",
              FileLocation = "",
              Password = "",
            } = res.data[0];
            let { data } = res;
            setFriendlyName(friendlyName);
            setUrl(FileLocation);
            setPassword(Password);
            setConnection(true);
            let getColumn = data && data.map((e) => e.SourceColumnName);

            let leftCol = [];
            let rightCol = [];
            fillterData.map((e) => {
              let obj = {
                name: e,
                tablename: "versium",
                selected: false,
              };
              if (getColumn.includes(e)) {
                rightCol.push(obj);
              } else {
                leftCol.push(obj);
              }
            });

            setState((pre) => ({
              ...pre,
              leftColumnValues: leftCol,
              rightColumnValues: rightCol,
            }));
          }
        },
        fail: (err) => { },
      });
    }
    if (id !== null && mode === "edit") {
      fetchData();
    }
  }, []);

  const validateForm = () => {
    let error = false;
    if (isEmpty(friendlyName)) {
      setErrFriendlyName(FRIENDLY_NAME);
      error = true;
    }
    if (isEmpty(url)) {
      setErrURL(URL);
      error = true;
    }

    if (isEmpty(userPassword)) {
      setErrPassword(PASS_WORD);
      error = true;
    }

    return error;
  };

  const handleConnect = () => {
    let valid = !validateForm();

    if (valid) {
      context.globalStateData.setIsLoadingData(true);
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "x-versium-api-key": userPassword,
        },
      };
      fetch(url + "/contact?output[]=address&phone=10001", options)
        .then((data) => data.json())
        .then((d) => {
          let fillterData = [
            "Contact",
            "Demographic",
            "Online audience",
            "Firmographic",
          ].map((e) => {
            return {
              name: e,
              tablename: "versium",
              selected: false,
            };
          });
          setState((pre) => ({ ...pre, leftColumnValues: fillterData }));
          setConnection(true);
        })
        .catch((err) => {
          setConnection(false);
        });
      context.globalStateData.setIsLoadingData(false);
    }
  };
  const [columnName, setColumnName] = useState("");
  const getColumnVal = (data) => {
    let dummyData = data.map((e) => {
      return e.name;
    });
    setColumnName(dummyData);
    return data;
  };
  const handleUpload = () => {
    let params = {
      IPAddress: "",
      UserName: userName,
      Password: userPassword,
      FileLocation: url,
      DBName: "",
      TableName: "",
      CreatedBy: "1452",
      DepartmentID: 0,
      FriendlyName: friendlyName,
      Columname: columnName.join(","),
      ScheduleFrequency: "2",
      UpdateDatecolumn: "",
      ConnectionType: "C",
      RemotesettingID: id || 0,
      RemoteDataSourceID: 40,
      IsUpdated: true,
    };
    context.globalStateData.setIsLoadingData(true);
    connectServer({
      path: REMOTE_SETTING_COLUMN,
      params: params,
      loading: context.globalStateData.setIsLoadingData,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          history.push({
            pathname: "/preferences/data-exchange",
            state: {
              params: {
                url: url,
                username: userName,
                password: userPassword,
                tablename: tableName,
                columns: columnName.join(","),
              },
              mode: "crm",
              remoteSettingId: res?.data,
            },
          });
        } else {
        }
      },
      fail: (err) => { },
    });
  };
  const handleFriendlyName = (text) => {
    if (text == "" || text == null) {
      setFriendlyName(text);
      setErrFriendlyName(FRIENDLY_NAME);
      return;
    } else {
      let params = {
        friendlyname: text,
      };
      context.globalStateData.setIsLoadingData(true);
      connectServer({
        path: FRIENDLY_NAME_EXIST,
        params: params,
        loading: context.globalStateData.setIsLoadingData,
        sessionOut: context.globalStateData.setSessionOutData,
        context: context,
        ok: (res) => {
          if (res.status) {
            setFriendlyName(text);
            setErrFriendlyName(null);
          } else {
            setFriendlyName(text);
            setErrFriendlyName("FriendlyName already exists");
          }
        },
        fail: (err) => { },
      });
    }
  };

  return (
    <LayoutPageWrapper className="data-exchange-wrapper">
      <Container className="page-header box-bottom-space">
        <div className="header-title d-flex justify-content-between">
          <h1>Data exchange </h1>
        </div>
        <div className="portlet-box-theme">
          <Row className="even-del mb8">
            <Col className="text-start">
              <h4 className="m0 p0">Data services - Versium</h4>
            </Col>
          </Row>

          <Row className="data-exchange-border mx0 pt20">
            <Col md={4}>
              <RSInput2
                ph="Friendly name"
                name="friendlyName"
                val={friendlyName}
                disabled={connection}
                required={true}
                max={50}
                cb={(text) => {
                  let errMsg = text.trim() !== "" ? null : FRIENDLY_NAME;
                  setFriendlyName(text);
                  setErrFriendlyName(errMsg);
                }}
                ob={(text) => {
                  handleFriendlyName(text);
                }}
                errorMessage={errMsgFriendlyName}
              />
            </Col>
            <Col md={4}>
              <RSInput2
                ph="URL"
                name="url"
                disabled={connection}
                val={url}
                required={true}
                cb={(text) => {
                  setUrl(text);
                  let errMsg = text ? null : URL;
                  setErrURL(errMsg);
                }}
                ob={(text) => {
                  if (text.length > 0) {
                    if (isValidWebsite(text)) {
                      setUrl(text);
                    } else {
                      setErrURL(URL);
                    }
                  } else {
                    setErrURL(URL);
                  }
                }}
                errorMessage={errURL}
              />
            </Col>

            <Col md={4}>
              <RSInput2
                ph="Token"
                disabled={connection}
                name="userPassword"
                val={userPassword}
                type="password"
                required={true}
                cb={(text) => {
                  let errMsg = text.trim() !== "" ? null : PASS_WORD;
                  setPassword(text);
                  setErrPassword(errMsg);
                }}
                errorMessage={errMsgPassword}
              />
            </Col>
          </Row>

          <div className="btn-container d-flex justify-content-end align-items-center mb20">
            <span style={{ color: "green" }}>
              {connection && "Successfully connected"}
            </span>
            <RSSecondaryBtn
              onClick={() => {
                history.push("/preferences/data-exchange");
              }}
            >
              Cancel
            </RSSecondaryBtn>
            <RSPrimaryBtn
              className={connection ? "click-off" : ""}
              onClick={handleConnect}
            >
              Connect
            </RSPrimaryBtn>
          </div>

          {connection && (
            <TableDetails
              tabledetails={"Contact"}
              leftColumnValues={state.leftColumnValues}
              rightColumnValues={state.rightColumnValues}
              getSelectedData={(first, second) => {
                setState({
                  leftColumnValues: first.leftColumnValues,
                  rightColumnValues: first.rightColumnValues,
                });
                getColumnVal(first.rightColumnValues);
              }}
              nameLeft="Available APIs"
              nameRight="Selected APIs"
            />
          )}
        </div>
        {connection && (
          <div className="my15">
            <Row>
              <div className="d-flex align-items-center my15">
                <h4 className="mb0">Import preferences</h4>
                {/* <i
                  className={"icon-settings-large icon-lg blue cp ml10"}
                  onClick={() => {
                    setDedupModalFlag(!dedupModalFlag);
                  }}
                ></i> */}
              </div>
              <div className="mb5">
                <Row>
                  <div className="position-relative">
                    <RSRadio
                      name="importPreference"
                      lbl="Upload new data - if a match is found, overwrite the older record"
                      className="marginB5"
                      labelId={"ip1"}
                      cb={(event) => {
                        setImportPreference(event.target.value);
                      }}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="position-relative">
                    <RSRadio
                      name="importPreference"
                      lbl="Preserve older data - if a match is found, reject the recent record"
                      className="marginB5"
                      labelId={"ip2"}
                      cb={(event) => {
                        setImportPreference(event.target.value);
                      }}
                    />
                  </div>
                </Row>
              </div>

              <div className={`btn-container d-flex justify-content-end mb20 mr15`}>
                <RSSecondaryBtn onClick={() => {
                  history.push({
                    pathname: "/preferences/data-exchange"
                  });
                }}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn className={`${columnName.length === 0 ? "click-off" : ""}`} onClick={handleUpload}>
                  Upload
                </RSPrimaryBtn>
              </div>
            </Row>
          </div>
        )}
        {dedupModalFlag && (
          <>
            <DedupeSettingModal
              callBack={(value) => {
                setDedupModalFlag(false);
              }}
              isShowModal={dedupModalFlag}
              title="Configure dedupe input settings"
              PrimaryBtn="Save"
              SecondaryBtn="Cancel"
            />
          </>
        )}
      </Container>
    </LayoutPageWrapper>
  );
};

export default Versium;
