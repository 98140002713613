import React, { useEffect, useState } from 'react';
const ListInvoiceById = props => {

    const [invoiceDetails, setInvoiceDetails] = useState(props.invoiceDetails)
    useEffect(() => {
        setInvoiceDetails(props.invoiceDetails)
    }, [props])
    return (
        <>
            <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table" style={{ paddingTop: "20px" }}>
                <thead>
                    <tr>
                        <th width="5%">S.no</th>
                        <th width="25%">Description</th>
                        <th width="10%">Count</th>
                        <th width="20%">Credits</th>
                        <th width="15%">Unit charge (USD)</th>
                        <th width="10%">Amount (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceDetails?.paymentMapping?.map((item, index) => {
                        return <tr>
                            <td className="camp-icon">
                                <div className="d-flex align-items-center">
                                    {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                    <span style={{ verticalAlign: 'middle' }}>{index + 1}</span>
                                </div>
                            </td>
                            <td className="camp-icon">
                                <div className="d-flex align-items-center">
                                    {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                    <span style={{ verticalAlign: 'middle' }}>{item?.description}</span>
                                </div>
                            </td>
                            <td className="camp-icon">
                                <div className="d-flex align-items-center">
                                    {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                    <span style={{ verticalAlign: 'middle' }}>{item?.totalAudienceCount}</span>
                                </div>
                            </td>
                            <td className="camp-icon">
                                <div className="d-flex align-items-center">
                                    <span style={{ verticalAlign: 'middle' }}>{item?.contactPoints}</span>
                                </div>
                            </td>
                            <td className="text-end">$ {item?.unitPrice}</td>
                            <td className="text-end">$ {item?.totalRecipientAmount}</td>
                        </tr>
                    })}
                    <tr>
                        <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan={"4"} rowSpan="5"></td>
                        <td className="text-start">Sub total:</td>
                        <td className="text-end">${invoiceDetails?.subTotal}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Adjustments :</td>
                        <td className="text-end">${0}</td>
                    </tr>
                    <tr className="bgWhite even">
                        <td className="text-start"> Total: </td>
                        <td className="text-end"> (+) ${invoiceDetails?.subTotal}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start"> Tax ({invoiceDetails?.taxPercentage}%): </td>
                        <td className="text-end"> (+) ${invoiceDetails?.taxAmount}</td>
                    </tr>
                    <tr className="even">
                        <td className="text-start">Amount payable</td>
                        <td className="text-end blue">${invoiceDetails?.totalAmount}</td>
                    </tr>
                </tbody>
            </table></>
    );
}

export default ListInvoiceById;