import React, { useContext, useEffect, useState } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import { Col, Container, Row } from "react-bootstrap";
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { useHistory, withRouter } from 'react-router-dom';
import { RSPTooltip } from 'Components/Tooltip';
import { connectServer } from 'Helper/Network/networkHandler';
import { GET_AUDIENCE_PAYMENT_SUMMARY, SAVE_PAYMENT_DETAILS, SAVE_FOR_LATER } from 'Helper/Constants/endPoint';
import authContext from 'Helper/StateHandler/auth-context';
import { dateFormat, dateFormatWithMomentForInvoice, getUserData } from 'Helper/Utils/Utils';
import { RSCheckbox } from 'Components/RSInputs';
import { DownloadAlertModal } from './DownloadAlertModal';
import ResulPayModal from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Analyze-Execution/ConfirmPayment/ResulPayModal';

const AudiencePayment = props => {
    let history = useHistory();
    const context = useContext(authContext)
    const [message, setMessage] = useState('No data received from IFrame, yet.');
    const [transactionId, settransactionId] = useState(0);
    const [isOpenResulPay, setIsOpenResulPay] = useState(false);
    const [invoiceSummary, setInvoiceSummary] = useState(null)
    const [isDownload, setIsDownload] = useState(false);
    const [isOpenDownloadModal, setIsDownloadModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.history.location && props.history.location.state) {
            const state = props.history.location.state
            const { segListId } = state
            setIsDownload(segListId.isDownload)
            getAudiencePaymentSummary(segListId, segListId.isDownload)
        }
    }, [])

    React.useEffect(() => {
        if (message.toLowerCase().startsWith('c')) {
            setIsOpenResulPay(false);
        } else if (message.toLowerCase().startsWith('s')) {
            // const payloads = {
            //   clientId: getAuthClientId(),
            //   orderId: orderId,
            //   userId: getAuthUserId(),
            //   clientName: getUserData().clientDetailsLocalization.clientName,
            //   authorizationCode: transactionId,
            //   paymentMode: 1,
            // };
            // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
            // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
        } else if (message.toLowerCase().startsWith('f')) {
            // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
        } else {
            setIsOpenResulPay(false);
        }
    }, [message]);
    React.useEffect(function () {
        var handler = function (ev) {
            if (typeof ev.data !== 'object') return;
            if (!ev.data.type) return;
            if (ev.data.type !== 'button-click') return;
            if (!ev.data.message) return;
            setMessage(ev.data.message);
            if (ev.data.message.toLowerCase().startsWith('c')) {
                setIsOpenResulPay(false);
            } else if (ev.data.message.toLowerCase().startsWith('s')) {
                settransactionId(ev.data.transactionId);
                setIsOpenResulPay(false);
                // const payloads = {
                //   clientId: getAuthClientId(),
                //   orderId: orderId,
                //   userId: getAuthUserId(),
                //   clientName: getUserData().clientDetailsLocalization.clientName,
                //   authorizationCode: transactionId,
                //   paymentMode: 1,
                // };
                const paymentParams = {
                    "status": true, // payment status (response from resul pay)
                    "isTenantSubscription": false, // true if Tenant Subscription payment
                    "isClientSubscription": false,// true if client Subscription payment(first time subscription)
                    "isConsumable": false,// true if consumable payment
                    "isRecipient": true,// true if recipient payment
                    "transactionNo": ev.data.transactionId, // response from resul pay
                    "orderId": ev.data.orderId,//response from resul pay as string
                }
                savePaymentDetails(paymentParams)
                // dispatch(paymentSubmit({ payloads, navigate, from: state?.from }));
                // navigate('/setup-complete', { state: { status: 'success', isPayment: true } });
            } else if (ev.data.message.toLowerCase().startsWith('f')) {
                // navigate('/setup-complete', { state: { status: 'failure', isPayment: true } });
            } else {
                setIsOpenResulPay(false);
            }
        };
        window.addEventListener('message', handler);
        // Clean up
        return function () {
            return window.removeEventListener('message', handler);
        }; // The "message" param should match with the iframe.js file
    }, []);

    const savePaymentDetails = (params) => {
        connectServer({
            path: SAVE_PAYMENT_DETAILS,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status) {
                    setIsOpenResulPay(false);
                    history.push({ pathname: "/audience", state: { index: 0 } });
                }
            },
            fail: err => {
            }
        })
    }

    const saveForLaterAndListCreation = (segmentationDetails) => {
        let params = {
            "segmentationSchedulerID": segmentationDetails.segmentationSchedulerID,
            "segmentationId": segmentationDetails.segmentationListID
        }
        connectServer({
            path: SAVE_FOR_LATER,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res?.status) {
                    history.push({ pathname: "/audience", state: { index: 0 } });
                }
            },
            fail: err => {
            }
        })
    }

    const getAudiencePaymentSummary = (segmentationListId, isDownload) => {
        let params = {
            "segmantationId": segmentationListId.segmentationListID,
            "isDownload": isDownload
        }
        connectServer({
            path: GET_AUDIENCE_PAYMENT_SUMMARY,
            params: params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    setInvoiceSummary(res.data)
                }
            },
            fail: err => {

            }
        })
    }

    return (
        <LayoutPageWrapper>
            <Container className="page-header box-bottom-space">
                <h1>Payment summary</h1>
                <div className="portlet-container">
                    <div className="marginY20">
                        <Row className="progress-steps-box theme-space-mt" style={{ backgroundColor: "#fae9db" }}>
                            <RSPTooltip text={invoiceSummary?.recipientsBunchName} position="top">
                                <Col md={3} className="steps-label">
                                    <small className="mr5">Name:</small>
                                    <label className="label-row label-dark">{invoiceSummary?.recipientsBunchName}</label>
                                </Col>
                            </RSPTooltip>
                            <RSPTooltip text={dateFormatWithMomentForInvoice(invoiceSummary?.createdDate)} position="top">
                                <Col md={3} className="steps-label">
                                    <small className="mr5">Date:</small>
                                    <label className="label-row label-dark">{dateFormatWithMomentForInvoice(invoiceSummary?.createdDate)}</label>
                                </Col>
                            </RSPTooltip>
                            <RSPTooltip text={"Versium"} position="top">
                                <Col md={3} className="steps-label">
                                    <small className="mr5">Partner:</small>
                                    <label className="label-row label-dark">{"Versium"}</label>
                                </Col>
                            </RSPTooltip>
                            <Col md={3} className="steps-label">

                                <RSCheckbox
                                    className="marginB0 blue pr23 checkboxTop0"
                                    checked={isDownload}
                                    lbl="Enable download"
                                    cb={(status) => {
                                        setIsDownload(status);
                                        if (status) {
                                            setIsDownloadModal(status)
                                        } else {
                                            getAudiencePaymentSummary(props.history.location.state.segListId, status)
                                        }
                                    }} />

                                {/* <label className="label-row">Downloadable:</label>
                                <Switch
                                    onChange={() => {
                                        setIsDownload(!isDownload)
                                    }}
                                    checked={isDownload}
                                /> */}
                            </Col>
                        </Row>
                    </div>
                    <table width="100%" border="0" cellPadding="0" cellSpacing="0" className="table rs-table" style={{ paddingTop: "20px" }}>
                        <thead>
                            <tr>
                                <th width="5%">S.no</th>
                                <th width="35%">Description</th>
                                <th width="10%">Count</th>
                                <th width="10%">Credits</th>
                                <th width="15%">Unit charge (USD)</th>
                                <th width="10%">Amount (USD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {invoiceSummary?.consumableMapping && invoiceSummary?.consumableMapping?.map(item => {
                                return  */}
                            {invoiceSummary?.paymentMapping && invoiceSummary?.paymentMapping?.map((item, index) => {
                                return (
                                    <tr>
                                        <td className="camp-icon">
                                            <div className="d-flex align-items-center">
                                                {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                                <span style={{ verticalAlign: 'middle' }}>{index + 1}</span>
                                            </div>
                                        </td>
                                        <td className="camp-icon">
                                            <div className="d-flex align-items-center">
                                                {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                                <span style={{ verticalAlign: 'middle' }}>{item?.description}</span>
                                            </div>
                                        </td>
                                        <td className="camp-icon">
                                            <div className="d-flex align-items-center">
                                                {/* <i className={`icon-sd-users-persona-large icons-md blue mr8`} style={{ verticalAlign: 'middle' }} /> */}
                                                <span style={{ verticalAlign: 'middle' }}>{item?.totalAudienceCount}</span>
                                            </div>
                                        </td>
                                        <td className="camp-icon">
                                            <div className="d-flex align-items-center">
                                                <span style={{ verticalAlign: 'middle' }}>{item?.contactPoints}</span>
                                            </div>
                                        </td>
                                        <td className="text-end">$ {item?.unitPrice}</td>
                                        <td className="text-end">$ {item?.totalRecipientAmount}</td>
                                    </tr>
                                )
                            })}

                            {/* })} */}
                            <tr>
                                <td className="border-left-0 border-bottom-0 bgGrey-lighter1" colSpan={invoiceSummary?.campaignType === "M" ? "3" : "4"} rowSpan="5"></td>
                                <td className="text-start">Sub total:</td>
                                <td className="text-end">$ {invoiceSummary?.subTotal}</td>
                            </tr>
                            <tr className="bgWhite even">
                                <td className="text-start"> Tax ({invoiceSummary?.taxPercentage}%): </td>
                                <td className="text-end"> (+) $ {invoiceSummary?.taxAmount}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Amount payable</td>
                                <td className="text-end blue">$ {invoiceSummary?.totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* } */}
                    <div className="btn-container d-flex justify-content-end mt20">
                        <RSSecondaryBtn
                            paddingR="0px"
                            className="mr0 mr25"
                            onClick={() => {
                                if (props.history.location.state.segListId.isDownload) {
                                    history.push("/audience");
                                } else {
                                    history.push({ pathname: `/audience/new-subscription-list`, state: { mode: 'edit', listId: props.history.location.state.segListId.segmentationListID } })
                                }
                            }}>
                            Back
                        </RSSecondaryBtn>
                        {!props.history.location.state.segListId.isDownload &&
                            <RSPrimaryBtn
                                className={`mx7 mr30`}
                                onClick={() => {
                                    saveForLaterAndListCreation(props.history.location.state.segListId)

                                }}>
                                Save for later
                            </RSPrimaryBtn>
                        }
                        <RSPrimaryBtn onClick={() => {

                            // history.push({
                            //     pathname: "/communication/communication-payment",
                            //     state: {
                            //         mode: props.history.location.state.mode,
                            //         campaignId: props.history.location.state.campaignId,
                            //         campaignHeader: props.history.location.state.campaignHeader,
                            //         channelType: [props.history.location.state.Channeltype],
                            //         totalAmount: invoiceSummary?.totalAmount
                            //     }
                            // });
                            // history.push({
                            //     pathname: `/audience/audience-pay`,
                            //     state: {
                            //         totalAmount: invoiceSummary?.totalAmount,
                            //         segmentationDetail: props.history.location.state.segListId,
                            //         listName: invoiceSummary?.recipientsBunchName,
                            //         createdDate: invoiceSummary?.createdDate,
                            //         isDownload: isDownload
                            //     }
                            // })
                            setIsOpenResulPay(true)
                        }}>Confirm</RSPrimaryBtn>
                    </div>
                </div>
                {isOpenDownloadModal &&
                    <DownloadAlertModal
                        subTitle="Additional charges applicable for download inclusion. Do you wish to proceed?"
                        secondaryButtonText="Cancel"
                        primaryButtonText="Confirm"
                        buttonClicked={(value) => {
                            if (value) {
                                getAudiencePaymentSummary(props.history.location.state.segListId, value)
                            } else {
                                setIsDownload(false);
                            }
                            setIsDownloadModal(false);
                        }}
                    />
                }
                {isOpenResulPay &&
                    <ResulPayModal
                        isOpen={isOpenResulPay}
                        orderId={invoiceSummary?.audienceInvoiceId}
                        isConsumable={true}
                        amount={invoiceSummary?.encryptedTotalAmount}
                        clientName={getUserData().clientDetailsLocalization.clientName}
                        tenantShortCode={getUserData().clientDetailsLocalization.tenantShortCode}
                        onChangeIsOpen={(value) => {
                            setIsOpenResulPay(value)
                        }}
                    />
                }
            </Container >
        </LayoutPageWrapper >
    )

}
export default withRouter(AudiencePayment);