import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { RSDropdownList, RSPhoneInput } from "Components/RSDropdowns";
import { LayoutPageWrapper } from "Components/LayoutWrapper/LayoutPageWrapper";
import RESEditor from "Components/Editor/RESEditor";
import { RSPrimaryBtn, RSSecondaryBtn } from "Components/RSButtons";
import { RSCheckbox, RSInput2 } from "Components/RSInputs";
import { CustomPopup } from "Components/DateRangePicker/ScheduleCustomPopup";
import { RSPPophover, RSPTooltip } from "Components/Tooltip";
import { createMobileWhatsAppCampaignMDC } from "./CamapignWrapper";
import ApprovalSettings from "./ApprovalSettings";
import {
  CAMPAIGN_TEXT,
  TEMPLATE_LANGUAGE,
  SEND_TEST_MSG,
  SCHEDULE_DATE,
  SELECT_TIMEZONE,
  TEMPLATE_NAME,
  SELECT_SENDER_NAME,
} from "Helper/Constants/validationMessage";
import {
  convertBase64ToObject,
  addMinutes,
  convertObjectToBase64,
  getLsMasterDataDropdown,
  getUserData,
  isEmpty,
  getAuthUserId,
  dateFormat,
  dateTimeFormatMdc,
  getServerConfig,
  makeImmutableObject,
  getCurrentTimeWithTimeZone,
  numberFormatter,
} from "Helper/Utils/Utils";
import {
  GET_ADHOC_ATTRIBUTES,
  GET_SHORTEN_URL,
  GET_CAMPAIGN_HEADER,
  GET_POTENTIAL_AUD,
  GET_NOTIFICATION_DATA,
  MDC_CANVAS_URL,
  GET_WHATSAPP_SENDER_NAME,
  GET_WHATSAPP_LANGUAGES,
  GET_WHATSAPP_TEMPLATE,
  UPDATE_WHATSAPP_CAMPAIGN,
  SAVE_WHATSAPP_CAMPAIGN,
} from "Helper/Constants/endPoint";
import { connectServer } from "Helper/Network/networkHandler";
import authContext from "Helper/StateHandler/auth-context";
import whatsAppJson from "./WhatsAppNew.json";
import { SuccessModal } from "../Notification/MSComponents/EmailComponents/Components/SuccessModal";
import pdfImage from 'Assets/Images/wapdf.png';
import videoImage from 'Assets/Images/wavideo.png';
import WhatsAppPreviewContent from "../Notification/MSComponents/MobileComponents/Components/WhatsAppPreviewContent";
import { RSConfirmAlert } from "Components/RSConfirmAlert";
import WhatsAppLivePreview from "../Notification/MSComponents/MobileComponents/Components/WhatsAppLivePreview";

const MdcWhatsAppCreation = (props) => {
  const context = useContext(authContext);
  const history = useHistory();


  const { search } = useLocation();
  let queryParam = React.useMemo(() => new URLSearchParams(search), [search]);
  let decodeCampaignId = convertBase64ToObject(queryParam.get("CampaignId"));
  let getTokenFromParam = decodeURIComponent(queryParam.get("accessToken"));
  let campaignMode = decodeURIComponent(queryParam.get("mode"));
  let getChannelDetailFromParam = JSON.parse(
    decodeURIComponent(queryParam.get("ChannelDetails"))
  );
  const languageList = localStorage.getItem("lsLanguageData");
  const [channelDetails, setChannelDetails] = useState(
    getChannelDetailFromParam
  );
  const [segmentationListId, setSegmentationListId] = useState(
    channelDetails["segmentationListID"]
  );
  const [smsChannelId, setSmsChannelId] = useState(0);
  const [potentialAudienceCount, setPotentialAudienceCount] = useState(0);
  const [campaignModeAddOrEdit, setCampaignModeAddOrEdit] = useState(campaignMode);
  const [campaignId, setCampaignId] = useState(decodeCampaignId);
  const [accessToken, setAccessToken] = useState(getTokenFromParam);
  const [waChannelDetailID, setWaChannelDetailID] = useState(whatsAppJson.waChannelDetailID);
  const [senderNameList, setSenderNameList] = useState([]);
  const [senderName, setSenderName] = useState(null);
  const [whatsAppTemplates, setWhatsApptemplates] = useState([]);
  const [templateName, setTemplateName] = useState(null);
  const [campaignText, setCampaignText] = useState(whatsAppJson.previewBody);
  const [previewCampaignText, setPreviewCampaignText] = useState(whatsAppJson.body)
  const [waImagePath, setWAImagePath] = useState(whatsAppJson.waImagePath);
  const [waMediaURL, setWAMediaURL] = useState(whatsAppJson.waMediaURL);
  const [waMediaURLType, setWAMediaURLType] = useState(whatsAppJson.waMediaURLType);
  const [shortenLinks, setShortenLinks] = useState("");
  const [smartUrls, setSmartUrls] = useState([]);
  const [mobileNo, setMobileNo] = useState(whatsAppJson.testSmsMobileNo !== "" ? whatsAppJson.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryDialCodeMobile, setCountryDialCodeMobile] = useState(whatsAppJson.countryCodeMobile !== "" ? whatsAppJson.countryCodeMobile : getUserData()?.countryCodeMobile);
  const [mobileNoWithoutDaialCode, setMobileNoWithoutDaialCode] = useState(whatsAppJson.testSmsMobileNo !== "" ? whatsAppJson.testSmsMobileNo : getUserData()?.mobileNo);
  const [countryCode, setCountryCode] = useState("in");
  const [countryPhoneNumberLength, setCountryPhoneNumberLength] = useState(0)
  const [isRequestForApproval, setIsRequestApproval] = useState();
  const [isEditTimeZone, setIsEditTimeZone] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [isDayLightSavings, setIsDayLightSavings] = useState(false);
  const [schedule, setSchedule] = useState("");
  const [scheduleMinimumDateTime, setScheduleMinimumDateTime] = useState(new Date());
  const [approverName, setApproverName] = useState("");
  const [errSenderNameMsg, setErrSenderNameMsg] = useState(null);
  const [errAudienceMsg, setErrAudienceMsg] = useState(null);
  const [errTemplateLanguageMsg, setErrTemplateLanguageMsg] = useState(null);
  const [errTemplateNameMsg, setErrTemplateNameMsg] = useState(null);
  const [errCampaignText, setErrCampaignTextMsg] = useState(null);
  const [errSendTestMsg, setErrSendTestMsg] = useState(null);
  const [errScheduleDateMsg, setErrScheduleDateMsg] = useState(null);
  const [errTimeZoneMsg, setErrTimeZoneMsg] = useState(null);
  const [errApproverMsg, setErrApproverMsg] = useState(null);
  const [errMbileMsg, setErrMbileMsg] = useState(null);
  const [languageData, setLanguageData] = useState([]);
  const [language, setLangage] = useState({ templateName: "", templateLanguageCode: "-- Select template language --" });
  const [audienceListFromServer, setAudienceListFromServer] = useState([]);
  const [bodyTextCount, setBodyTextCount] = useState("");
  const [smsCount, setSMSCount] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [startCampaignDate, setStartCampaignDate] = useState("");
  const [endCampaignDate, setEndCampaignDate] = useState("");
  const [disableEdit, setDisableEdit] = useState("");
  const [subjectLinePersonalizeData, setSubjectLinePersonalizeData] = useState(
    []
  );
  const [approverListFromServer, setApproverListFromServer] = useState([]);
  const [isOpenSendSuccessModal, setIsOpenSendSuccessModal] = useState(false);
  const [attributeName, setAttributeName] = useState("");
  const [primayGoal, setPrimaryGoal] = useState("");
  const [toggleScreenTitle, setToggleScreenTitle] = useState("Full screen view");
  const [fullScreen, setFullScreen] = useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isShowRemoveModal, setIsShowRemoveModal] = useState(false);
  const [approvalList, setApprovalList] = useState([
    {
      approverEmail: {
        email: "-- Select approval mail --",
        userId: 0,
      },
      ismandatory: false,
      edmworlflowdetails: 0,
      priority: 1,
      ApprovalSentcount: 1,
    },
  ]);
  const [requestForErrorMsg, setRequestForErrorMsg] = useState([
    {
      approverEmailMsg: null,
    },
  ]);
  const [approvalSettings, setApprovalSettings] = useState({
    approvalMatchType: "All",
    approvalMatchCount: 0,
    isFollowHierarchyEnabled: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [splitContentError, setSplitContentError] = useState([
    {
      errBodyMsg: null,
    },
    {
      errBodyMsg: null,
    },
  ]);
  const [editModeCampaignData, setEditModeCampaignData] = useState(makeImmutableObject(whatsAppJson));
  const [templateContent, setTemplateContent] = useState('')
  const [editableBraceCount, setEditableBraceCount] = useState(0);
  const [isShortURLCreated, setIsShortURLCreated] = useState(false);
  const [isOpenLiveSuccessModal, setIsOpenLiveSuccessModal] = useState(false);
  const [isSearchValidate, setIsSearchValidate] = useState(false);

  useEffect(() => {
    //if (senderIDList.length === 0) {
    getWhatsAppSettings();
    getDatFromServer();
    getAudienceList();
    getApproverList();
    getTimeZoneList();
    if (!!segmentationListId && segmentationListId.length > 0) {
      getPotentialAudienceCount();
      getAdhocAttributeHeaders();
    }
    //}
  }, [])

  useEffect(() => {
    if (campaignModeAddOrEdit === "edit") {
      getCampaignDetailsById();
    }
    // handleScheduleMinimumDate();
  }, [senderNameList]);

  const getSMSCount = (campaignText) => {
    let textCount = campaignText?.length > 0 ? campaignText?.length.toString() + "/" : "";
    // let smsCount = Math.floor(campaignText?.length / 140) + 1;
    let smsCount = 1;
    setBodyTextCount(textCount);
    setSMSCount(smsCount.toString());
  }

  const getWhatsAppSettings = () => {
    let params = {
    }
    connectServer({
      path: GET_WHATSAPP_SENDER_NAME,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res?.status) {
          setSenderNameList(res.data?.waSenderNameattributes);
          let tempSenderName = res.data?.waSenderNameattributes?.filter(item => item.clientWASenderID === editModeCampaignData.senderID)[0];
          setSenderName(tempSenderName);
          if (!!tempSenderName) {
            getLanguageDataFromServer(tempSenderName?.clientWASenderID, editModeCampaignData);
          }
        }
      },
      fail: (err) => {
      },
    });
  };

  const getLanguageDataFromServer = (senderId, data) => {
    let params = {
      "senderId": senderId || 0
    }
    connectServer({
      path: GET_WHATSAPP_LANGUAGES,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setLanguageData(res.data?.waHSMattribute);
          let tempLanguage = res.data?.waHSMattribute?.filter(item => item.templateLanguageCode === data?.languageCode)[0];
          setLangage(tempLanguage);
          if (!!tempLanguage) {
            getWhatsAppTemplates(senderId, tempLanguage?.templateLanguageCode, data);
          }
        }
      },
      fail: err => {
      }
    })
  }

  const getWhatsAppTemplates = (senderId, languageCode, data) => {
    let params = {
      "senderId": senderId || 0,
      "templateLanguageCode": languageCode || ""
    }
    connectServer({
      path: GET_WHATSAPP_TEMPLATE,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: res => {
        if (res?.status) {
          setWhatsApptemplates(res.data?.waHSMattribute);
          let tempWAtemplate = res.data?.waHSMattribute?.filter(item => item.waTemplateId === data?.waTemplateID)[0];
          if (tempWAtemplate !== null) {
            setTemplateName(tempWAtemplate);
            if (data.waContent !== "") {
              setCampaignText(data.waContent);
              setPreviewCampaignText(data.waPreviewContent);
              const regex = /{{(.*?)}}/g;
              const result = data.waPreviewContent?.replace(regex, '');
              const count = (data.waPreviewContent?.match(regex) || []).length;
              setEditableBraceCount(count);
              setTemplateContent(result)
              getSMSCount(data.waContent);
            } else {
              data.waContent = tempWAtemplate?.templateContent;
              data.waPreviewContent = tempWAtemplate?.templateContent;
              const regex = /{{(.*?)}}/g;
              const result = tempWAtemplate?.templateContent?.replace(regex, '');
              const count = (tempWAtemplate?.templateContent?.match(regex) || []).length;
              setEditableBraceCount(count);
              setTemplateContent(result)
              setCampaignText(tempWAtemplate?.templateContent);
              getSMSCount(tempWAtemplate?.templateContent);
            }
          }
        }
      },
      fail: err => {
      }
    })
  }


  const getAudienceList = () => {
    const audienceList = localStorage.getItem("lsAudienceList");
    setAudienceListFromServer(
      audienceList ? convertBase64ToObject(audienceList) : []
    );
  };

  const getApproverList = () => {
    const approverList = localStorage.getItem("lsApproverList");
    setApproverListFromServer(
      approverList ? convertBase64ToObject(approverList) : []
    );
  };

  const getAdhocAttributeHeaders = (segmentId) => {
    let params = {
      segmentationListID: parseInt(segmentationListId.toString(), 10),
    };
    connectServer({
      path: GET_ADHOC_ATTRIBUTES,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let tempNamePersonal = [];
          res.data.names.forEach((item, index) => {
            tempNamePersonal.push("[[" + item + "]]");
          });
          let tempEmailPersonal = [];
          res.data.emails.forEach((item, index) => {
            tempEmailPersonal.push("[[" + item + "]]");
          });
          let tempFullPersonal = [];
          res.data.full.forEach((item, index) => {
            tempFullPersonal.push("[[" + item + "]]");
          });
          setSubjectLinePersonalizeData(tempFullPersonal);
        }
      },
      fail: (err) => {
      },
    });
  };

  const getDatFromServer = () => {
    const params = {
      userId: getAuthUserId(),
      campaignId: campaignId,
    };
    connectServer({
      path: GET_CAMPAIGN_HEADER,
      params: params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      retryTime: 5000,
      ok: (res) => {
        if (res.status) {
          const {
            campaignName,
            startDate,
            endDate,
            attributeName,
            primaryTargetCode,
          } = res.data;
          setCampaignName(campaignName);
          setStartCampaignDate(getStartDate(startDate));
          //  handleScheduleMinimumDate();
          setEndCampaignDate(new Date(new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate(), 23, 59, 59)))
          // setEndCampaignDate(endDate);
          setAttributeName(attributeName);
          let goal = goalList.filter(
            (item) => item.shortCode === primaryTargetCode
          );
          setPrimaryGoal(goal[0]["name"]);
        }
      },
      fail: (err) => { },
    });
  };

  const getPotentialAudienceCount = () => {
    let params = {
      segmentationListids: segmentationListId,
      campaignID: campaignId,
    };
    connectServer({
      path: GET_POTENTIAL_AUD,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      loading: context.globalStateData.setIsLoadingData,
      context: context,
      ok: (res) => {
        if (res.status) {
          let resultData = res.data;
          let totalListCount = resultData.filter(
            (item) => item.channelType === "WhatsApp"
          );
          totalListCount = parseInt(totalListCount[0]["channelDescription"], 10);
          setPotentialAudienceCount(totalListCount);
        }
      },
      fail: (err) => {
      },
    });
  };

  const getCampaignDetailsById = () => {
    let params = {
      campaignId: campaignId,
      channelId: parseInt(channelDetails.ChannelId.substr(4), 10),
      campaignType: "M",
      channelDetailId: channelDetails.ChannelDetailID,
    };
    connectServer({
      path: GET_NOTIFICATION_DATA,
      params,
      sessionOut: context.globalStateData.setSessionOutData,
      context: context,
      ok: (res) => {
        if (res.status) {
          const { channelJson } = res?.data;
          let data = JSON.parse(channelJson);
          // if (data?.levelNumber === 1) {
          //   let getScheduledDate = data?.ScheduleDate
          //   let scheduledDate = new Date(data['content'][0]['blastDateTime'])
          //   let currentDate = new Date()
          //   let sdate = new Date(scheduledDate)
          //   if (currentDate >= sdate) {
          //     setDisableEdit('click-off')
          //   }
          // }
          setEditModeCampaignData(data);
          let editClickOff = (data.channelstatusId !== 9 && data.channelstatusId !== 5 && data.channelstatusId !== 20 && data.channelstatusId !== 52) ? "" : "click-off"
          setDisableEdit(editClickOff)
          let filterSenderName = senderNameList.filter(item => item.clientWASenderID === data.clientWASettingId)[0];
          setSenderName(filterSenderName);
          getLanguageDataFromServer(filterSenderName?.clientWASenderID, data);
          // editModeCampaignData.timezoneID = data.timezoneID;
          // editModeCampaignData.waPreviewContent = data.waPreviewContent;
          const masterData = getLsMasterDataDropdown();
          let filterTimezone = masterData.timeZone.filter(item => item.timezoneId === data.timezoneID)[0];
          setTimeZone(filterTimezone);
          setCampaignText(data.waPreviewContent);
          setPreviewCampaignText(data.waContent);
          setWAImagePath(data.waImagePath)
          setWAMediaURL(data.waMediaURL);
          setWAMediaURLType(data.waMediaURLType);
          getSMSCount(data.waContent);
          setIsShortURLCreated(data.isShortenURLEnabled);
          let date = dateTimeFormatMdc(data.localBlastDateTime);
          setSchedule(date);

          // if (data.content[0].statusId === 5 || data.content[0].statusId === 9) {
          //   setDisableEdit('click-off')
          // }
        }
      },
      fail: (err) => {
      },
    });
  };

  const getTimeZoneList = () => {
    const data = getLsMasterDataDropdown();
    setTimeZoneData(data.timeZone);
    if (campaignModeAddOrEdit === "add") {
      const loggedDetails = getUserData();
      let filterTimezone = data.timeZone.filter(function (item) {
        return (
          item.timezoneId === loggedDetails?.clientDetailsLocalization.timeZoneId
        );
      });
      setTimeZone(filterTimezone[0]);
      editModeCampaignData.timezoneID = filterTimezone[0].timezoneId;
      setMobileNo(editModeCampaignData.testSmsMobileNo !== "" ? editModeCampaignData.testSmsMobileNo : getUserData()?.mobileNo);
      setCountryDialCodeMobile(editModeCampaignData.countryCodeMobile !== "" ? editModeCampaignData.countryCodeMobile : getUserData()?.countryCodeMobile)
      setMobileNoWithoutDaialCode(editModeCampaignData.testSmsMobileNo !== "" ? editModeCampaignData.testSmsMobileNo : getUserData()?.mobileNo)
    } else {
      /* getSMSCount(campaignText);
       setSmartURL(mobileSMSNew.content[0].smartURL);
         */
      //setMobileNo(mobileSMSNew.testSmsMobileNo);
    }
  };

  const backToCanvas = () => {
    const sessionStr = localStorage.getItem("session");
    const sessionObject = JSON.parse(sessionStr);
    const accessToken = sessionObject ? sessionObject.accessToken : null;
    const campId = window.btoa(campaignId);
    let baseURL = getServerConfig();
    let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
    window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${campId}&accessToken=${encodeURIComponent(accessToken)}&from=${fromEnvi}`;
  };
  const validation = (testSms = false) => {
    let isValidate = true;
    if (senderName === undefined || senderName === null) {
      setErrSenderNameMsg(SELECT_SENDER_NAME);
      isValidate = false;
    }
    if (language === undefined || language === null) {
      setErrTemplateLanguageMsg(TEMPLATE_LANGUAGE);
      isValidate = false;
    }
    if (templateName === undefined || templateName === null) {
      setErrTemplateNameMsg(TEMPLATE_NAME);
      isValidate = false;
    }
    if (isEmpty(previewCampaignText)) {
      setErrCampaignTextMsg(CAMPAIGN_TEXT);
      isValidate = false;
    } else {
      const regex = /{{(.*?)}}/g;
      const result = previewCampaignText?.replace(regex, '');
      let braceContents = previewCampaignText?.match(regex) || [];
      if (braceContents?.length !== editableBraceCount) {
        setErrCampaignTextMsg("Template required editable curly braces {{ }}");
        isValidate = false;
      } else if (braceContents?.filter(item => item.length < 5).length > 0) {
        setErrCampaignTextMsg("Template required editable curly braces {{ }} with values");
        isValidate = false;
      }
      if (result?.replaceAll("\r", "") !== templateContent?.replaceAll("\r", "")) {
        setErrCampaignTextMsg("Edit the text present inside the curly braces {{ }} only");
        isValidate = false;
      }
      if (editModeCampaignData?.waContent?.includes("https") && !isShortURLCreated) {
        setErrCampaignTextMsg("Shorten URL");
        isValidate = false;
      }
      if (editModeCampaignData?.templateType === 2 && (isEmpty(waImagePath) && isEmpty(waMediaURL))) {
        setErrCampaignTextMsg("Template requires image");
        isValidate = false;
      }
      if (editModeCampaignData?.templateType === 3 && (isEmpty(waImagePath) && isEmpty(waMediaURL))) {
        setErrCampaignTextMsg("Template requires PDF");
        isValidate = false;
      }
      if (editModeCampaignData?.templateType === 4 && isEmpty(waMediaURL)) {
        setErrCampaignTextMsg("Template requires video");
        isValidate = false;
      }
      // if (previewCampaignText.length > 500) {
      //   setErrCampaignTextMsg("Communication content should not be more than 500");
      //   isValidate = false;
      // }
    }
    if (channelDetails.LevelNumber === 1 && testSms === false) {
      if (isEmpty(schedule)) {
        setErrScheduleDateMsg(SCHEDULE_DATE);
        setSchedule("");
        return false;
      } else {
        let currentTime = getCurrentTimeWithTimeZone(new Date(), timeZone.timezoneId, 15)
        if (new Date(schedule) < new Date(currentTime)) {
          setErrScheduleDateMsg("Select a time greater from (" + currentTime.toLocaleString().trim() + ")");
          return false;
        }
      }
      // if (new Date() > new Date(schedule)) {
      //   setErrScheduleDateMsg("Choose at least 15 minutes greater than current time");
      //   handleScheduleMinimumDate(startCampaignDate)
      //   return false;
      // }
      // if (new Date() < new Date(schedule) && !handleTimeDiff()) {
      //   handleScheduleMinimumDate(startCampaignDate);
      //   setErrScheduleDateMsg("Choose at least 15 minutes greater than current time");
      //   return false;
      // }
      if (timeZone.timezoneId === 0) {
        setErrTimeZoneMsg(SELECT_TIMEZONE);
        return false;
      }
    }
    if (testSms) {
      if (isEmpty(mobileNo)) {
        setErrMbileMsg('')
        setErrSendTestMsg(SEND_TEST_MSG);
        return false;
      }
      let rslt = handleMobileNumberValidation(mobileNoWithoutDaialCode, false);
      if (rslt.err) {
        setErrSendTestMsg('');
        setErrMbileMsg(rslt.msg)
        return false;
      } else {
        setErrSendTestMsg('');
        setErrMbileMsg('')
      }
    }
    // let enable = true;
    // if (isRequestForApproval) {
    //   approvalList?.map((item, index) => {
    //     if (item.approverEmail.userId === 0) {
    //       enable = false;
    //       let tempErrorMsg = [...requestForErrorMsg];
    //       tempErrorMsg[index].approverEmailMsg = APPROVAL_MAIL;
    //       setRequestForErrorMsg(tempErrorMsg);
    //       return;
    //     } else {
    //       let tempErrorMsg = [...requestForErrorMsg];
    //       tempErrorMsg[index].approverEmailMsg = null;
    //       setRequestForErrorMsg(tempErrorMsg);
    //       enable = true;
    //     }
    //   });
    // }

    return isValidate;
  };

  const handleTimeDiff = () => {
    let currentDate = new Date();
    let currentTime = currentDate.getTime();
    let scheduleDate = new Date(schedule);
    let scheduleTime = scheduleDate.getTime();
    let timeDiffInSecs = (scheduleTime - currentTime) / 1000;
    let timeDiffInMins = Math.round(timeDiffInSecs / 60);
    return timeDiffInMins < 15 ? false : true;
  }
  const handleCountryPhoneNumberLength = (country) => {
    let countryFormat = country['format'].split(/ (.*)/s);
    let [countryCodeFormat, countryNumberFormat, empty] = countryFormat;
    let totCount = (countryNumberFormat.match(/\./g) || []).length
    setCountryPhoneNumberLength(totCount);
  }

  const handleMobileNumberValidation = (mobileNumber, maxMobile) => {
    let numberWihoutComma = mobileNumber?.replaceAll(',', '');
    let valid = true;
    let errObj = { err: false, msg: null };
    // let phoneValues = numberWihoutComma?.match(new RegExp('.{1,' + countryPhoneNumberLength + '}', 'g'));
    let phoneValues = mobileNumber.split(/\s*,\s*/);
    if (phoneValues && phoneValues.length <= 5) {
      const numberPattern = /^[+]*[0-9]+$/;
      phoneValues.forEach(item => {
        if (item.length < 5 || item.length > countryPhoneNumberLength || !numberPattern.test(item)) {
          valid = false;
        }
      });
    }
    if (!phoneValues) {
      errObj.err = true;
      errObj.msg = 'Please enter mobile number';
    } else if (!valid) {
      errObj.err = true;
      errObj.msg = 'Enter valid mobile number';
    } else if (maxMobile) {
      errObj.err = true;
      errObj.msg = 'Maximum mobile number reached';
    } else if (phoneValues && phoneValues.length !== new Set(phoneValues).size) {
      errObj.err = true;
      errObj.msg = 'Please remove duplicate number';
    }

    return errObj;
  }
  const getStartDate = (startDate) => {
    let campaignStartDate = new Date(startDate);
    let today = new Date();
    if (campaignStartDate > today) {
      campaignStartDate = new Date(startDate);
    } else {
      campaignStartDate = new Date();
    }
    return campaignStartDate
  }
  const getStartDateForSchedule = () => {
    const data = getLsMasterDataDropdown();
    const loggedDetails = getUserData();
    let filterTimezone = null;
    if (editModeCampaignData.timezoneID === 0) {
      filterTimezone = data.timeZone.filter(
        (item) =>
          item.timezoneId === loggedDetails?.clientDetailsLocalization?.timeZoneId
      )[0];
    } else {
      filterTimezone = data.timeZone.filter(
        (item) => item.timezoneId === editModeCampaignData.timezoneID
      )[0];
    }
    let startDate = new Date(getStartDate(startCampaignDate));
    let startDateWithTime = new Date(startDate).getDate() === new Date().getDate() ? new Date(getCurrentTimeWithTimeZone(new Date(startDate), filterTimezone.timezoneId, 16)) : startDate
    return startDateWithTime;
  }
  const toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      setToggleScreenTitle("Normal view")
      setFullScreen(true)
    } else {
      cancelFullScreen.call(doc);
      setToggleScreenTitle("Full screen view")
      setFullScreen(false)
    }
  }
  return (
    <>
      <LayoutPageWrapper>
        <Container>
          <Row>
            <Col md={12}>
              <div className="progress-steps-box mr--mdcwa">
                <div className="steps-label">
                  <small>Communication name:</small>
                  <RSPTooltip text={window.atob(campaignName)} position="bottom">
                    <label className="label-row label-dark">
                      {window.atob(campaignName)}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Duration:</small>
                  <RSPTooltip
                    text={` ${dateFormat(startCampaignDate)} - ${dateFormat(
                      endCampaignDate
                    )}`}
                    position="bottom"
                  >
                    <label className="label-row label-dark">
                      {dateFormat(startCampaignDate)} - {dateFormat(endCampaignDate)}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Type:</small>
                  <RSPTooltip text={attributeName} position="bottom">
                    <label className="label-row label-dark">{attributeName}</label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Primary communication goal:</small>
                  <RSPTooltip text={primayGoal} position="bottom">
                    <label className="label-row label-dark">{primayGoal}</label>
                  </RSPTooltip>
                </div>
                <div className="steps-label">
                  <small>Potential audience:</small>
                  <RSPTooltip text={potentialAudienceCount !== 0 ? potentialAudienceCount : "NA"} position="bottom">
                    <label className="label-row label-dark">
                      {potentialAudienceCount !== 0 ? potentialAudienceCount : "NA"}
                    </label>
                  </RSPTooltip>
                </div>
                <div className="">
                  <RSPTooltip text={toggleScreenTitle} position="bottom">
                    <div className="steps-label-row-icon d-flex align-items-center justify-content-end">
                      <i
                        onClick={() => toggleFullScreen()}
                        className={`icon-sd-${fullScreen ? "collapse" : "expand"
                          }-large icons-md white large-icon cp bgBlue`}
                      ></i>
                    </div>
                  </RSPTooltip>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={12}>
              <div className="header-title d-flex justify-content-between">
                <h1>WhatsApp content</h1>
                <div className="back-text">
                  <i className="icon-sd-arrow-left-mini icon-mini blue cursor-pointer"></i>
                  <a onClick={backToCanvas}>Back to canvas</a>
                </div>
              </div>
              <div className={`portlet-box-theme bgWhite padding20 mb50`}>
                <div className="mt20 ml20">
                  <Row className={`custom-column ${disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Sender name</label>
                    </Col>
                    <Col md={6}>
                      <div className="position-relative">
                        {errSenderNameMsg && (
                          <div className="validation-message">
                            {errSenderNameMsg}
                          </div>)}
                        <RSDropdownList
                          className="required"
                          data={senderNameList}
                          value={senderName || {
                            "clientWASenderID": 0,
                            "senderID": 0,
                            "senderName": "-- Select sender name --"
                          }}
                          defaultItem={{
                            "clientWASenderID": 0,
                            "senderID": 0,
                            "senderName": "-- Select sender name --"
                          }}
                          textField='senderName'
                          dataItemKey='clientWASenderID'
                          onChange={(event) => {
                            let errMsg = event.target.value.clientWASenderID !== 0 ? null : SELECT_SENDER_NAME;
                            editModeCampaignData.clientWASettingId = event.target.value.clientWASenderID;
                            getLanguageDataFromServer(event.target.value.clientWASenderID, editModeCampaignData)
                            setErrSenderNameMsg(errMsg);
                            setSenderName(event.target.value);
                          }}
                        />
                      </div>
                      {/* <div className="input-outer-set">
                    <RSPTooltip text="New sender name" position="top">
                      <i className="icon-settings-small icons-md blue" onClick={() => {
                        history.push({
                          pathname: '/preferences/communication-settings',
                          state: { hIndex: 1 }
                        })
                      }} />
                    </RSPTooltip>
                  </div> */}
                    </Col>
                  </Row>
                  {(!!senderName && senderName.clientWASenderID !== 0) &&
                    <Row className={`custom-column ${disableEdit}`}>
                      <Col md={3}>
                        <label className="label-row">Template language</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          {errTemplateLanguageMsg && (
                            <div className="validation-message">
                              {errTemplateLanguageMsg}
                            </div>)}
                          <RSDropdownList
                            className="required"
                            data={languageData}
                            value={language || { templateName: "", templateLanguageCode: "-- Select template language --" }}
                            defaultItem={{ templateName: "", templateLanguageCode: "-- Select template language --" }}
                            textField='templateLanguageCode'
                            dataItemKey='templateName'
                            onChange={(event) => {
                              let errMsg = event.target.value?.templateName !== "" ? null : TEMPLATE_LANGUAGE;
                              editModeCampaignData.languageCode = event.target.value.templateLanguageCode;
                              getWhatsAppTemplates(senderName.clientWASenderID, event.target.value.templateLanguageCode, editModeCampaignData);
                              setErrTemplateLanguageMsg(errMsg);
                              setLangage(event.target.value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  {(!!language && language?.templateName !== "") &&
                    <Row className={`custom-column ${disableEdit}`}>
                      <Col md={3}>
                        <label className="label-row">Template name</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          {errTemplateNameMsg && (
                            <div className="validation-message">
                              {errTemplateNameMsg}
                            </div>)}
                          <RSDropdownList
                            className="required"
                            data={whatsAppTemplates}
                            value={templateName || { waTemplateId: 0, templateName: "-- Select template --" }}
                            defaultItem={{ waTemplateId: 0, templateName: "-- Select template --" }}
                            textField='templateName'
                            dataItemKey='waTemplateId'
                            onChange={(event) => {
                              let errMsg = event.target.value.waTemplateId !== 0 ? null : TEMPLATE_NAME;
                              setWAImagePath("")
                              setWAMediaURL("")
                              setWAMediaURLType("");
                              setIsShortURLCreated(false);
                              editModeCampaignData.waImagePath = "";
                              editModeCampaignData.waMediaURL = "";
                              editModeCampaignData.waMediaURLType = "";
                              if (errMsg === null) {
                                editModeCampaignData.waTemplateID = event.target.value.waTemplateId;
                                editModeCampaignData.templateContent = event.target.value.templateContent;
                                editModeCampaignData.waContent = event.target.value.templateContent;
                                editModeCampaignData.waPreviewContent = event.target.value.templateContent;
                                editModeCampaignData.templateName = event.target.value.templateName;
                                editModeCampaignData.templateType = event.target.value.templateType;
                                let textCount = event.target.value.templateContent.length > 0 ? event.target.value.templateContent.length.toString() + "/" : "";
                                // let smsCount = Math.floor(event.target.value.templateContent.length / 140) + 1;
                                let smsCount = 1;
                                setBodyTextCount(textCount);
                                setSMSCount(smsCount.toString());
                                const regex = /{{(.*?)}}/g;
                                const result = event.target.value.templateContent.replace(regex, '');
                                const count = (event.target.value.templateContent.match(regex) || []).length;
                                setEditableBraceCount(count);
                                setTemplateContent(result)
                                setCampaignText(event.target.value.templateContent);
                                setPreviewCampaignText(event.target.value.templateContent);
                                setErrTemplateNameMsg(errMsg);
                                setTemplateName(event.target.value);
                                setErrCampaignTextMsg(null);
                              } else {
                                editModeCampaignData.waTemplateID = event.target.value.waTemplateId;
                                editModeCampaignData.templateContent = "";
                                editModeCampaignData.waContent = "";
                                editModeCampaignData.waPreviewContent = "";
                                editModeCampaignData.templateName = "";
                                editModeCampaignData.templateType = 0;
                                // setBodyTextCount(0);
                                // setSMSCount(smsCount.toString());
                                setCampaignText("");
                                setPreviewCampaignText("");
                                setTemplateContent("");
                                setEditableBraceCount(0);
                                setErrTemplateNameMsg(errMsg);
                                setTemplateName(event.target.value);
                                setErrCampaignTextMsg(null);
                              }

                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  {(!!templateName && templateName?.waTemplateId !== 0) &&
                    <Row className={`custom-column mb20 mt10 ${disableEdit}`}>
                      <Col md={3}>
                        <label className="label-row">Communication text</label>
                      </Col>
                      <Col md={6}>
                        <div className="position-relative">
                          {errCampaignText && (
                            <div className="validation-message yaxis-7">
                              {errCampaignText}
                            </div>)}
                          <RESEditor
                            personalizeTapData={subjectLinePersonalizeData}
                            mobilepush={false}
                            isCampaignText={false}
                            isWhatsappCampaignText={true}
                            bodyContent={editModeCampaignData.waPreviewContent}
                            waTemplateType={editModeCampaignData.templateType}
                            onChangePreviewOpen={(value) => {
                              setIsPreviewOpen(value);
                            }}
                            callBack={(key, item) => {
                              if (key === "bodytext") {
                                let errMsg = item.length > 0 ? null : CAMPAIGN_TEXT;
                                if (errMsg === null && item.length <= 1000) {
                                  let textCount = item.length > 0 ? numberFormatter(item.length).toString() + "/" : "";
                                  // let smsCount = Math.floor(item.length / 140) + 1;
                                  let smsCount = 1;
                                  setBodyTextCount(textCount);
                                  setSMSCount(smsCount.toString());
                                  editModeCampaignData.waContent = item;
                                  editModeCampaignData.waPreviewContent = item;
                                  setErrCampaignTextMsg(errMsg);
                                  setCampaignText(item);
                                  setPreviewCampaignText(item);
                                  if (item.length > 0 && smartUrls.length > 0 && shortenLinks.length > 0) {
                                    let tempVal = item.replaceAll(smartUrls[0], shortenLinks)
                                    setPreviewCampaignText(tempVal);
                                    editModeCampaignData.waContent = tempVal;
                                  }
                                } else {
                                  setErrCampaignTextMsg(item.length === 0 ? errMsg : "Max. 1,000 characters");
                                  if (item.length === 0) {
                                    setCampaignText(item);
                                    setPreviewCampaignText(item);
                                    editModeCampaignData.waContent = item;
                                    editModeCampaignData.waPreviewContent = item;
                                  }
                                }
                              } else if (key === "onBlur") {
                                editModeCampaignData.bodyPosition = item.target.selectionStart;
                              } else if (key === "imagePath") {
                                setWAImagePath(item);
                                setErrCampaignTextMsg(null);
                                setWAMediaURL("");
                                setWAMediaURLType("");
                                editModeCampaignData.waMediaURL = "";
                                editModeCampaignData.waMediaURLType = ""
                                editModeCampaignData.waImagePath = item;
                              } else if (key === "PDFPath") {
                                setWAImagePath("");
                                setErrCampaignTextMsg(null);
                                setWAMediaURL(item);
                                setWAMediaURLType("");
                                editModeCampaignData.waMediaURL = item;
                                editModeCampaignData.waMediaURLType = "PDF"
                                editModeCampaignData.waImagePath = "";
                              }
                            }}
                            onMediaCallback={(mediaType, value) => {
                              if (mediaType === "PNG" || mediaType === "JPG") {
                                setWAMediaURL(value);
                                setWAMediaURLType(mediaType);
                                setErrCampaignTextMsg(null);
                                setWAImagePath("");
                                editModeCampaignData.waImagePath = "";
                                editModeCampaignData.waMediaURL = value;
                                editModeCampaignData.waMediaURLType = mediaType
                              } else if (mediaType === "PDF") {
                                setWAMediaURL(value);
                                setWAMediaURLType(mediaType);
                                setErrCampaignTextMsg(null);
                                setWAImagePath("");
                                editModeCampaignData.waImagePath = "";
                                editModeCampaignData.waMediaURL = value;
                                editModeCampaignData.waMediaURLType = mediaType
                              } else if (mediaType === "MP4") {
                                setWAMediaURL(value);
                                setWAMediaURLType(mediaType);
                                setErrCampaignTextMsg(null);
                                setWAImagePath("");
                                editModeCampaignData.waImagePath = "";
                                editModeCampaignData.waMediaURL = value;
                                editModeCampaignData.waMediaURLType = mediaType
                              } else {
                                setWAMediaURL("");
                                setWAMediaURLType("");
                                setErrCampaignTextMsg(null);
                                setWAImagePath("");
                                editModeCampaignData.waImagePath = "";
                                editModeCampaignData.waMediaURL = "";
                                editModeCampaignData.waMediaURLType = ""
                              }
                            }} />
                          <small class="float-end">{bodyTextCount + "1,000"} <span className="ml10">| ({smsCount} WhatsApp message / Audience)</span></small>
                        </div>
                        <div className="input-outer-set">
                          <RSPTooltip text="Note: Edit the text present inside the curly braces {{ }} only." position="top">
                            <i className="icon-sd-question-mark-mini icons-sm blue cp" />
                          </RSPTooltip>
                        </div>
                        <RSPTooltip text="Shortern URL" position="top">
                          <i className={`icons-sm icon-sd-link-medium icons-md blue float-end cursor-pointer ${editModeCampaignData.waPreviewContent.includes("https") ? "" : "click-off"}`} onClick={() => {
                            // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)[{}]/g;
                            const regex = /{{(.*?)}}/g;
                            const braceContents = editModeCampaignData.waPreviewContent.match(regex)
                            var urlRegex = /^(https?):\/\/(?:([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(?::\d+)?|(?:\[[a-fA-F0-9:]+\]))(?:(\/(?:[^\s/?#]+\/)*[^\s?#]*(?:\?(?:[^#\s]*))*))?$/;
                            let smartURLs = []
                            braceContents.forEach((item, index) => {
                              item = item.replaceAll("{", "").replaceAll("}", "");
                              if (urlRegex.test(item)) {
                                smartURLs.push(item)
                              }
                            })
                            let errMsg = smartURLs.length > 0 ? smartURLs.length > 2 ? "Accept only two URL in WhatsApp content" : null : "Invalid URL";
                            if (errMsg === null) {
                              errMsg = smartURLs[0] !== smartURLs[1] ? null : "Give different URLs in WhatsApp content";
                              setErrCampaignTextMsg(errMsg);
                              setSmartUrls(smartURLs);
                              if (errMsg === null) {
                                let smartURLParams = [];
                                smartURLs.forEach((item, index) => {
                                  let params = {
                                    "campaignId": campaignId,
                                    "goalNo": index + 1,
                                    "blastType": "",
                                    "smartCode": "",
                                    // "domainUrl": DOMAIN_URL,
                                    "smartAdaptive": "",
                                    "smartUrl": item,
                                    "channelId": 21,
                                    "blastNo": channelDetails["LevelNumber"],
                                    "actionId": channelDetails["ActionID"],
                                    "parentChannelDetailId": channelDetails["ParentChannelDetailID"]
                                  }
                                  smartURLParams.push(params)
                                })
                                let params = {
                                  "smartUrlsList": smartURLParams
                                }
                                connectServer({
                                  path: GET_SHORTEN_URL,
                                  params,
                                  loading: context.globalStateData.setIsLoadingData,
                                  sessionOut: context.globalStateData.setSessionOutData,
                                  context: context,
                                  ok: res => {
                                    if (res.status) {
                                      // let val = getContent(campaignText, " " + res.data[0].smartCodePath + " ", props.mobileSMSData.content[0].bodyPosition);
                                      let shortenLinks = [];
                                      let tempVal = editModeCampaignData.waPreviewContent;
                                      res.data.forEach((item, index) => {
                                        if (index < 2) {
                                          tempVal = tempVal.replaceAll(smartURLs[index], item.smartCodePath)
                                          shortenLinks.push(item.smartCodePath)
                                        }
                                      })
                                      editModeCampaignData.waContent = tempVal;
                                      editModeCampaignData.isShortenURLEnabled = res.status;
                                      setPreviewCampaignText(tempVal)
                                      setShortenLinks(res.data[0].smartCodePath)
                                      setIsShortURLCreated(res.status)
                                      // setCampaignText(tempVal);
                                    } else {
                                      editModeCampaignData.isShortenURLEnabled = res.status;
                                      setIsShortURLCreated(res.status)
                                      setErrCampaignTextMsg(res.message)
                                    }
                                  },
                                  fail: err => {
                                  }
                                })
                              }
                            } else {
                              setErrCampaignTextMsg(errMsg);
                              setSmartUrls(smartURLs || []);
                            }

                          }}></i>
                        </RSPTooltip>
                        <div className="position-absolute right-15 z-1">
                          <RSPTooltip text="Shorten URL will reflect in WhatsApp preview content." position="top">
                            <i className="icon-sd-question-mark-mini question-unfill icons-sm float-end blue cp"></i>
                          </RSPTooltip>
                        </div>
                      </Col>
                      <Col md={3} className="last-column-view mr-wa-mdc">
                        {
                          ((editModeCampaignData.templateType === 2 && (waImagePath !== "" || waMediaURL !== "")) || (editModeCampaignData.templateType === 3 && (waMediaURL !== "" || waImagePath !== "")) || (editModeCampaignData.templateType === 4 && waMediaURL !== "")) &&
                          <div className="instant-preview">
                            <WhatsAppPreviewContent
                              imageSrc={editModeCampaignData.templateType === 2 ? waImagePath !== "" ? waImagePath : waMediaURL : editModeCampaignData.templateType === 3 ? pdfImage : videoImage}
                              onChangeIsRemove={(value) => {
                                setIsShowRemoveModal(value)
                              }} />
                          </div>
                        }
                      </Col>
                    </Row>
                  }

                  {channelDetails.LevelNumber === 1 && (
                    <div className={`schedule-even ${(channelDetails.LevelNumber === 1 || isEditTimeZone) ? 'paddingT10 mt13' : ''}`}>
                      <Row className={`custom-column paddingB10 ${disableEdit}`}>
                        <Col md={3}>
                          <label className="label-row">Schedule</label>
                        </Col>
                        <Col md={6}>
                          <div className="position-relative">
                            {errScheduleDateMsg && (
                              <div className="validation-message top-18">
                                {errScheduleDateMsg}
                              </div>
                            )}
                            <div className="rsptimer-picker ">
                              <DateTimePicker
                                placeholder="Select schedule date and time "
                                className="required"
                                value={schedule !== "" ? new Date(schedule) : null}
                                // //show={true}
                                min={getStartDateForSchedule()}
                                max={new Date(endCampaignDate)}
                                format={"dd MMM, yyyy HH:mm"}
                                popup={CustomPopup}
                                onChange={(event) => {
                                  if (event.target.value !== null) {
                                    setSchedule(event.target.value);
                                    let date = new Date(event.target.value);
                                    let errMsg =
                                      event.target.value !== "" || date <= new Date()
                                        ? null
                                        : SCHEDULE_DATE;
                                    setErrScheduleDateMsg(errMsg);
                                  } else {
                                    setSchedule("");
                                    setErrScheduleDateMsg(SCHEDULE_DATE);
                                  }
                                }}
                              />
                              <div className={`input-outer-set ${(schedule !== "") ? "" : "click-off"} `}>
                                <RSPTooltip text="Reset schedule" position="top">
                                  <i
                                    className="icon-sd-refresh-medium icons-md blue ml10"
                                    onClick={() => {
                                      setSchedule("");
                                    }}
                                  />
                                </RSPTooltip>
                              </div>
                            </div>
                          </div>
                          <Row className="marginT5">
                            {/*<Col md="8">
                            <div className="d-flex align-items-center marginB30">
                              <RSCheckbox
                                className="marginB0"
                                lbl="Use send time recommendation"
                                checked={isSendRecommendation}
                                cb={(value) => { setIsSendRecommendation(!isSendRecommendation) }}
                              />
                              <RSPTooltip
                                text="Send time will be based on Resulticks recommendation model, arrived at using individual audience's open time propensit"
                                position="top"
                              >
                              <i className="icon-sd-question-mark-mini question-unfill icons-sm blue marginT-6 cursor-pointer"></i>
                              </RSPTooltip>
                            </div>
                          </Col>*/}
                            {isEditTimeZone === false ? (
                              <Col>
                                <div className="float-end">
                                  <label>
                                    {!!timeZone && timeZone.gmtOffset}
                                  </label>
                                  <i
                                    className="icon-sd-pencil-edit-mini icon-sm blue ml5 cursor-pointer"
                                    onClick={() => {
                                      setIsEditTimeZone(!isEditTimeZone);
                                    }}
                                  />
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </Col>
                      </Row>
                      {isEditTimeZone ? (
                        <Row className={`custom-column pt10 ${disableEdit}`}>
                          <Col md={3}>
                            <label className="label-row">Select time zone</label>
                          </Col>
                          <Col md={6}>
                            <div className="position-relative">
                              {errTimeZoneMsg && (
                                <div className='validation-message'>
                                  {errTimeZoneMsg}
                                </div>
                              )}
                              <RSDropdownList
                                className="marginB0 required"
                                data={timeZoneData || []}
                                value={timeZone}
                                defaultItem={{
                                  timezoneId: 0,
                                  timezoneName: "-- Select time zone --",
                                }}
                                textField="timezoneName"
                                dataItemKey="timezoneId"
                                onChange={(e) => {
                                  let errMsg = e.target.value.timezoneId === 0 ? SELECT_TIMEZONE : null;
                                  setErrTimeZoneMsg(errMsg);
                                  setTimeZone(e.target.value);
                                  let tempEditModeCampaignData = { ...editModeCampaignData }
                                  tempEditModeCampaignData.timezoneID = e.target.value.timezoneId;
                                  setEditModeCampaignData(tempEditModeCampaignData)
                                  channelDetails.timezoneId = e.target.value.timezoneId;
                                  getStartDateForSchedule();
                                }}
                              />
                            </div>
                            <Row className="marginT5">
                              <Col md="8">
                                <div className="d-flex align-items-center">
                                  <RSCheckbox
                                    className="marginT0 marginB15 click-off"
                                    lbl="Daylight saving"
                                    checked={isDayLightSavings}
                                    cb={(value) => {
                                      setIsDayLightSavings(!isDayLightSavings);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  )}

                  <Row className={`custom-column mt30 ${disableEdit}`}>
                    <Col md={3}>
                      <label className="label-row">Send test WhatsApp message to</label>
                    </Col>
                    <Col md={5} className="posr zIndex1">
                      <div className="position-relative d-flex">
                        {errSendTestMsg && (
                          <div className="validation-message">
                            {errSendTestMsg}
                          </div>
                        )}
                        <RSPhoneInput
                          className="phone-input-short not-required"
                          value={countryDialCodeMobile + mobileNo}
                          country={countryCode}
                          inputStyle={{ display: 'none' }}
                          onMount={(phone, country) => {
                            handleCountryPhoneNumberLength(country)
                          }}
                          onChange={(phone, country) => {
                            handleCountryPhoneNumberLength(country)
                            let errMsg = phone !== "" ? null : SEND_TEST_MSG;
                            setErrSendTestMsg(errMsg);
                            if (countryDialCodeMobile !== country.dialCode) {
                              setMobileNo("");
                            }
                            setCountryCode(country.countryCode);
                            setErrMbileMsg(null)
                            setCountryDialCodeMobile(country.dialCode);
                          }}
                        />
                        <RSInput2
                          ph="+91 7845984022"
                          type="tel"
                          className=""
                          keyboardType="telePhoneNumber"
                          dialCode={countryDialCodeMobile}
                          phoneLength={countryPhoneNumberLength}
                          val={`+${countryDialCodeMobile} ${mobileNo}`}
                          cb={(phone, maxMobile) => {
                            setMobileNo(phone);
                            setMobileNoWithoutDaialCode(phone);
                            let isValidNumber = handleMobileNumberValidation(phone, maxMobile);
                            const { err, msg } = isValidNumber;
                            setErrMbileMsg(msg);

                          }}
                          errorMessage={errMbileMsg}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <RSPrimaryBtn
                        onClick={() => {
                          let enable = validation(true);
                          if (enable === undefined || enable) {
                            editModeCampaignData.campaignId = campaignId;
                            editModeCampaignData.levelNumber = channelDetails.LevelNumber;
                            editModeCampaignData.localBlastDateTime = schedule;
                            editModeCampaignData.timezoneID = timeZone.timezoneId;
                            channelDetails.totalaudience = potentialAudienceCount;
                            let params = createMobileWhatsAppCampaignMDC("M",
                              campaignId,
                              editModeCampaignData,
                              schedule,
                              mobileNo,
                              countryDialCodeMobile,
                              channelDetails,
                              true,
                              "",
                              "")
                            connectServer({
                              path: waChannelDetailID !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                              params,
                              loading: context.globalStateData.setIsLoadingData,
                              sessionOut: context.globalStateData.setSessionOutData,
                              context: context,
                              ok: (res) => {
                                if (res.status) {
                                  editModeCampaignData.waChannelDetailID = res.data.waChannelDetailID;
                                  setWaChannelDetailID(res.data.waChannelDetailID)
                                  channelDetails.ChannelDetailID = res.data.waChannelDetailID;
                                  setIsOpenSendSuccessModal(
                                    !isOpenSendSuccessModal
                                  );
                                }
                              },
                              fail: (err) => { },
                            });
                          }
                        }}
                      >
                        Send
                      </RSPrimaryBtn>
                    </Col>
                  </Row>
                </div>
                <div className="btn-container d-flex justify-content-end mt40">
                  <RSSecondaryBtn paddingR="0px" onClick={backToCanvas}>
                    Cancel
                  </RSSecondaryBtn>
                  <RSPrimaryBtn className={disableEdit}
                    onClick={() => {
                      let enable = validation();
                      if (enable === undefined || enable) {
                        editModeCampaignData.campaignId = campaignId;
                        editModeCampaignData.levelNumber = channelDetails.LevelNumber;
                        editModeCampaignData.localBlastDateTime = schedule;
                        editModeCampaignData.timezoneID = timeZone.timezoneId;
                        channelDetails.totalaudience = potentialAudienceCount;
                        //channelDetails.targetList = segmentationListId;
                        let params = createMobileWhatsAppCampaignMDC("M",
                          campaignId,
                          editModeCampaignData,
                          schedule,
                          mobileNo,
                          countryDialCodeMobile,
                          channelDetails,
                          false,
                          "",
                          "")
                        if (!props.isUpdate) {
                          connectServer({
                            path:
                              campaignModeAddOrEdit === "edit"
                                ? UPDATE_WHATSAPP_CAMPAIGN
                                : waChannelDetailID !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                            params,
                            loading: context.globalStateData.setIsLoadingData,
                            sessionOut: context.globalStateData.setSessionOutData,
                            context: context,
                            ok: (res) => {
                              if (res.status) {
                                let baseURL = getServerConfig();
                                let fromEnvi = window.location.origin.includes("app") ? "app" : window.location.origin.includes("sit") ? "sit" : "run";
                                window.location.href = `${baseURL}${MDC_CANVAS_URL}?CampId=${convertObjectToBase64(
                                  campaignId
                                )}&accessToken=${encodeURIComponent(
                                  accessToken
                                )}&from=${fromEnvi}&channelResponseDetailId=${convertObjectToBase64(
                                  res.data.waChannelDetailID
                                )}&channelType=W`;
                              }
                            },
                            fail: (err) => { },
                          });
                        }
                      }
                    }}
                  >
                    {" "}
                    {campaignModeAddOrEdit === "add"
                      ? "Create WhatsApp content"
                      : "Update WhatsApp content"}
                  </RSPrimaryBtn>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </LayoutPageWrapper>
      {
        isModalOpen && (
          <ApprovalSettings
            isOpen={isModalOpen}
            approvalSettings={approvalSettings}
            onOpenChange={(value) => {
              setIsModalOpen(value);
            }}
          ></ApprovalSettings>
        )
      }
      {
        isOpenSendSuccessModal && (
          <SuccessModal
            isOkButton={false}
            isOpen={isOpenSendSuccessModal}
            title={"Success"}
            subTitle={"Test communication will be sent shortly."}
            buttonClicked={(value) => {
              setIsOpenSendSuccessModal(false);
            }}
          />
        )
      }
      {isPreviewOpen &&
        <WhatsAppLivePreview
          isOpen={isPreviewOpen}
          textContent={editModeCampaignData.waContent}
          imagePath={editModeCampaignData.templateType === 2 ? waImagePath !== "" ? waImagePath : waMediaURL : editModeCampaignData.templateType === 3 ? (waMediaURL !== "" || waImagePath !== "") ? pdfImage : "" : waMediaURL !== "" ? videoImage : ""}
          mobileNumber={mobileNo}
          campaignId={campaignId}
          waChannelId={waChannelDetailID}
          mobileWhatsAppData={editModeCampaignData}
          schedule={schedule}
          countryCodeMobile={countryDialCodeMobile}
          channelDetails={channelDetails}
          totalaudience={potentialAudienceCount}
          onValidateSearch={(status) => {
            let isValidate = validation(true);
            setIsSearchValidate(isValidate);
          }}
          isSearchValidate={isSearchValidate}
          onChangeIsSearchValidate={() => {
            setIsSearchValidate(false);
          }}
          onChangeWAChannelId={(value) => {
            editModeCampaignData.waChannelDetailID = value;
            setWaChannelDetailID(value)
            channelDetails.ChannelDetailID = value;
          }}
          onChangeIsOpen={(value) => {
            if (value) {
              setIsOpenLiveSuccessModal(true)
              setIsPreviewOpen(false);
            } else {
              setIsPreviewOpen(false);
            }
          }} />
      }
      {
        isShowRemoveModal &&
        <RSConfirmAlert
          title="Are you sure you want to delete?"
          secondaryButtonText="Cancel"
          primaryButtonText="OK"
          buttonClicked={(value) => {
            if (value) {
              setWAImagePath("");
              setWAMediaURL("");
              setWAMediaURLType("");
            }
            setIsShowRemoveModal(false)
          }}
        />
      }
      {isOpenLiveSuccessModal &&
        <SuccessModal
          isOkButton={false}
          isOpen={isOpenLiveSuccessModal}
          title={"Success"}
          subTitle={"Live communication will be sent shortly."}
          buttonClicked={(value) => {
            setIsOpenLiveSuccessModal(false)
          }} />
      }
    </>
  );
};
export default withRouter(MdcWhatsAppCreation);
const goalList = [
  { shortCode: "R", name: "Reach" },
  { shortCode: "C", name: "Conversion" },
  { shortCode: "E", name: "Engagement" },
];
