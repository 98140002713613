import React, { useState, useEffect, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  BrowserRouter as Router,
  Switch, Route, NavLink, useHistory
} from "react-router-dom";
import { RSPTooltip } from 'Components/Tooltip';
import * as MyC from './AppJsImport'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { connectServer } from 'Helper/Network/networkHandler';
import { CA_PORTAL, EDIT_SVG, GET_KEY_CONTACT_INFO_DROPDOWNS, GET_THEME, TICKET_SUBMIT } from 'Helper/Constants/endPoint';
import { convertObjectToBase64, getUserData, isEmpty } from 'Helper/Utils/Utils'
import { PopupLogin } from 'Container/RegistrationModule/Login/Components/PopupLogin'
import ErrorBoundary from 'Components/ErrorBoundary'
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import authContext from 'Helper/StateHandler/auth-context';
import ticketImg from './Assets/Images/ticket.png'
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSInput2 } from 'Components/RSInputs';
import UploadComponent from 'Container/AuthenticatedModule/Preference/Components/CommunicationSettings/UploadComponent';
import { SuccessModal } from 'Container/AuthenticatedModule/Campaign/CampaignCreation/Steps/Creation/Notification/MSComponents/EmailComponents/Components/SuccessModal';
import FeaturePlan from 'Container/RegistrationModule/Features/FeaturePlan';
import { Crisp } from 'crisp-sdk-web';
import CrispTicketComponent from 'Components/Crisp/CrispTicketComponent';
import CryptoES from 'crypto-es';
const secretKey = 'dc4faffc46e2a3910244de2be3ae5b0792abae3957ef7026a7f28dd4a585fbd2'

const App = props => {
  const context = useContext(authContext)
  const [isPrototype, setIsPrototype] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState('default')
  const [selectedData, setSelectedData] = useState('default')
  const [emailId, setEmailId] = useState('')
  const [userTypeId, setUserTypeId] = useState("")
  const [userId, setUserId] = useState("")
  const [myProfile, setMyProfile] = useState(MyC.myProfileInitialState)
  const [keyContactInfo, setKeyContactInfo] = useState(MyC.keyContactInfoInitialState)
  const [loggedInUserInfo, setLoggedInUserInfo] = useState({})
  const [masterDropdown, setMasterDropdown] = useState({})
  const [campaignMasterDropdown, setCampaignMasterDropdown] = useState({})
  const authVaule = localStorage.getItem("isAuth")
  const isAuthValue = authVaule === "true" ? true : false
  const [isAuth, setIsAuth] = useState(isAuthValue || false)
  const [isLoading, setIsLoading] = useState(false)
  const [isReload, setIsReload] = useState(false)
  const [currentPage, setCurrentPage] = useState(window.location.pathname)
  const [sessionOut, setSessionOut] = useState(false);
  const [sourceType, setSourceType] = useState("")
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [isShowOnlineWarning, setIsShowOnlineWarning] = useState(false);
  const [toastMessage, setToastMessage] = useState("")
  const [paymentMessage, setPaymentMessage] = useState("")
  const [reminderMessage, setReminderMessage] = useState("")
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [headerIcons, setHeaderIcons] = useState(MyC.headerIcons);
  const [selectedItem, setSelectedItem] = useState(null);

  const setOnline = () => {
    setIsShowOnlineWarning(true)
    setOnlineStatus(true);

    const timer = setTimeout(() => {
      hideWarningComponent()
    }, 3000);
    return () => clearTimeout(timer);
  };

  const setOffline = () => {
    setIsShowOnlineWarning(true)
    setOnlineStatus(false);
  };

  const hideWarningComponent = () => {
    setIsShowOnlineWarning(false)
  }

  useEffect(() => {
    document.body.setAttribute('data-theme', selectedTheme)
    let isFirstLogin = getUserData()?.isFirstTimeLogin;
    setIsFirstLogin(isFirstLogin);
    let isPayment = getUserData()?.isPayment
    let paymentTerms = getUserData()?.paymentTerms;
    if (!!isPayment && isPayment) {
      if (paymentTerms <= 7) {
        let day = paymentTerms > 1 ? paymentTerms.toString() + " days" : paymentTerms.toString() + " day";
        setReminderMessage("Your Marketing Star subscription will expire in " + day + ". Please renew to continue our services.")
      }
    }
    // let tempHeader = [...headerIcons];
    // tempHeader.map((item, index) => {
    //   if (index !== 4) {
    //     item.disable = !isFirstLogin
    //   } else {
    //     item.disable = false
    //   }
    // })
    // setHeaderIcons(tempHeader)
  }, [selectedTheme])

  useEffect(() => {
    document.body.setAttribute('data-class', selectedData)

    let isFirstLogin = getUserData()?.isFirstTimeLogin;
    setIsFirstLogin(isFirstLogin);
    // let tempHeader = [...headerIcons];
    // tempHeader.map((item, index) => {
    //   if (index !== 4) {
    //     item.disable = !isFirstLogin;
    //   } else {
    //     item.disable = false
    //   }
    // })
    // setHeaderIcons(tempHeader)
  }, [selectedData])

  // Register the event listeners
  useEffect(() => {
    getMasterDataFromServer()
    getThemeData()
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);
    // window.addEventListener("beforeunload", clearUserDetails);
    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);

    }
  }, []);

  useEffect(() => {
    Crisp.configure("8fa3d8fa-b47f-4098-aee4-a0cae8d6e699");
  }, [])

  const clearUserDetails = () => {
    localStorage.removeItem('lsUserData');
    localStorage.removeItem('isAuth');
  }

  const getMasterDataFromServer = () => {
    connectServer({
      path: GET_KEY_CONTACT_INFO_DROPDOWNS,
      context: context,
      ok: res => {
        globalStateData.setMasterDropdownData(res.data || {})
        localStorage.setItem(
          'lsMasterDropdownData',
          convertObjectToBase64(res.data)
        )
      },
      fail: err => { }
    })
  }

  const getThemeData = () => {
    const params = {}
    connectServer({
      path: GET_THEME,
      params,
      context: context,
      ok: res => {
        if (res.status) {
          setSelectedTheme(res?.data?.themeData?.themeName);
          document.body.setAttribute('data-theme', res?.data?.themeData?.themeName)
        }
      },
      fail: err => {

      }
    })
  }

  const globalStateData = {
    getIsPrototype: isPrototype,
    setSelectedThemeStatus: id => {
      setSelectedTheme(id)
      document.body.setAttribute('data-theme', selectedTheme)
    },
    getSelectedThemeStatus: selectedTheme,

    setSelectedSubItem: (value) => {
      setSelectedItem(value)
    }, getSelectedSubItem: selectedItem,

    setSelectedDataStatus: id => {
      setSelectedData(id)
    },
    getSelectedDataStatus: selectedData,

    setEmailIdData: id => {
      setEmailId(id)
    },
    getEmailIdData: emailId,
    setUserTypeIdData: (id) => {
      setUserTypeId(id);
    },
    getUserTypeIdData: userTypeId,
    setUserIdData: (id) => {
      setUserId(id);
    },
    getUserIdData: userId,
    setMyProfileData: (data) => {
      setMyProfile(data);
    },
    getMyProfileData: myProfile,
    setKeyContactInfoData: (data) => {
      setKeyContactInfo(data);
    },
    getKeyContactInfoData: keyContactInfo,
    setIsLoadingData: (data) => {
      setIsLoading(data)
    },
    getIsLoadingData: isLoading,
    setLoggedInUserData: (data) => {
      setLoggedInUserInfo(data)
    },
    getIsReloadData: isReload,
    setIsReloadData: data => {
      setIsReload(data)
    },
    getLoggedInUserData: loggedInUserInfo,
    setSessionOutData: (code) => {
      if (sessionOut === true && code === 401) {
        setToastMessage("Internal server error")
      } else if (code === 500) {
        setToastMessage("Internal server error")

      } else {
        setSessionOut(code === 401 && sessionOut === false && true)
      }
    },
    getSessionOutData: sessionOut,
    setMasterDropdownData: data => {
      setMasterDropdown(data)
    },
    getSourceTypeData: sourceType,
    setSourceTypeData: data => {
      localStorage.setItem('nbase', data)
      setSourceType(data)
    },
    getMasterDropdownData: masterDropdown,
    setCampaignMasterDropdownData: data => {
      setCampaignMasterDropdown(data)
    },
    getCampaignMasterDropdownData: campaignMasterDropdown,
    setToastMessage: (message => {
      setToastMessage(message)
    }),
    getToastMessage: toastMessage,
    setPaymentMessage: (message => {
      setPaymentMessage(message)
    }),
    getPaymentMessage: paymentMessage,
    setReminderMessage: (message => {
      setReminderMessage(message)
    }),
    getReminderMessage: reminderMessage
  }

  const toggleAuth = status => {
    localStorage.setItem("isAuth", status)
    setIsAuth(status)
    setIsReload(true)
  }

  const OnlineComponent = props => {
    return <div className="network-status status-online">
      <p>You are online</p>
      <i className="icon-tick-medium"></i>
    </div>
  }

  const OfflineComponent = props => {
    return <div className="network-status status-offline">
      <p>You are offline</p>
      <i className="icon-sd-alert-medium"></i>
      <i onClick={() => hideWarningComponent()} className="icon-sd-close-mini"></i>
    </div>
  }

  const handleSessionOut = () => {
    setSessionOut(false)
    localStorage.removeItem('isAuth')
    localStorage.removeItem('session')
    setIsAuth(false)
  }


  const Toast = props => {
    setTimeout(() => {
      setToastMessage("")
    }, 3000)
    return (
      <div className='network-status status-offline'>
        <p>{toastMessage}</p>
        {/* <i className='icon-tick-medium'></i> */}
      </div>
    )
  }

  const HelpComponent = props => {
    const [editButtonClick, setEditButtonClick] = useState(false)

    const [ticketComponent, setTicketComponent] = useState(false)

    const context = useContext(authContext)
    let userData = getUserData()

    let hmac = CryptoES.HmacSHA256(userData?.emailId, secretKey)

    const linkCAPortal = () => {
      setEditButtonClick(false)
      window.open(`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/8fa3d8fa-b47f-4098-aee4-a0cae8d6e699?email=${userData?.emailId}&hmac=${hmac.toString()}&filter=all`)
      // connectServer({
      //   path: CA_PORTAL,
      //   loading: context.globalStateData.setIsLoadingData,
      //   context: context,
      //   ok: res => {
      //     if (res.result) {
      //       window.open(res.message)
      //     }
      //   },
      //   fail: err => {

      //   }
      // })
    }
    return (
      <>
        <div className={`network-status-wrapper click-on ${editButtonClick ? 'net-status-open' : 'net-status-close'}`}>
          <div className="n-status-edit">
            <RSPTooltip position="left" text="Support">
              <img
                className='n-status-img cp'
                alt="network-status"
                src={EDIT_SVG}
                onClick={() => setEditButtonClick(!editButtonClick)}
              />
            </RSPTooltip>
            <ul>
              <li>
                <RSPTooltip position="top" text="Ticket">
                  <img
                    src={ticketImg}
                    className="nc-status-img cp"
                    alt="network-status"
                    onClick={() => {
                      setTicketComponent(true)
                      setEditButtonClick(!editButtonClick)
                    }}
                  />
                </RSPTooltip>
              </li>
              <li>
                <RSPTooltip position="top" text="CA Portal">
                  <img
                    className='nc-globe-img cp'
                    alt="network-status"
                    src={"https://run.resulticks.com/images/web-g.svg"}
                    onClick={() => linkCAPortal()}
                  />
                </RSPTooltip>
              </li>
            </ul>
          </div>
          {/* <div className='n-status-adon'></div> */}
        </div>
        {
          editButtonClick
            ? <div className='overlay-screen'></div>
            : null
        }
        {
          ticketComponent === true
          && <TicketComponent
            isShown={ticketComponent}
            onChangeIsOpen={(status) => {
              setTicketComponent(status)
            }} />
        }

      </>

    )
  }

  const TicketComponent = props => {
    const context = useContext(authContext)
    const navigation = useHistory()
    const [modelShown, setModelShown] = useState(props.isShown)

    const [severity, setSeverity] = useState({ "sevirityId": 2, "sevirityText": "-- Select severity --" })
    const [category, setCategory] = useState({ "categoryId": 0, "categoryText": "-- Select category --" })
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [attachment, setAttachment] = useState('')
    const [fileName, setFileName] = useState("");

    const [errSeverity, setErrSeverity] = useState(null)
    const [errCategory, setErrCategory] = useState(null)
    const [errSubject, setErrSubject] = useState(null)
    const [errMessage, setErrMessage] = useState(null)
    const [errAttachment, setErrAttachment] = useState(null);



    const submitBtnClick = () => {
      let isValidate = true;
      if (severity.sevirityId === 2) {
        setErrSeverity("Select severity");
        isValidate = false;
      }
      if (category.categoryId === 0) {
        setErrCategory("Select category");
        isValidate = false;
      }
      if (isEmpty(subject)) {
        setErrSubject('Enter subject');
        isValidate = false;
      }
      if (isEmpty(message)) {
        setErrMessage('Enter message');
        isValidate = false;
      }
      if (isValidate) {
        let params = buildParams()
        connectServer({
          path: TICKET_SUBMIT,
          params,
          loading: context.globalStateData.setIsLoadingData,
          sessionOut: context.globalStateData.setSessionOutData,
          context: context,
          ok: res => {
            if (res.result) {
              setIsOpenSuccessModal(true)
              setModelShown(false)
              props.onChangeIsOpen(true);
            }
          },
          fail: err => {

          }
        })
      }
    }

    const buildParams = () => {
      return {
        "filename": fileName,
        "image": attachment,
        "subject": subject,
        "priority": severity.sevirityId.toString(),
        "category": category.categoryId.toString(),
        "producttype": "1",
        "content": message
      }
    }
    const uploadToServer = () => {
      var fileInput = document.querySelector('[name="uploaded_file"]').files[0];
      let fileSize = Math.round((fileInput.size / 1024));
      const validExtensions = [
        "csv",
        "jpeg",
        "png",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "pdf",
        "zip",
        "x-zip-compressed",
        "rar",
        "gz",
        "7z",
        "sitx",
      ];
      const fileExtension = fileInput.type.split("/")[1];
      let isValidExtension = validExtensions.includes(fileExtension);
      if (isValidExtension) {
        if (fileSize > 2048) {
          setErrAttachment("File size should not be greater than 2MB");
          setFileName("");
        } else {
          setErrAttachment(null);
          setFileName(fileInput.name)
          var getFileByte = "";
          var reader = new FileReader();
          reader.readAsDataURL(fileInput);
          reader.onload = function () {
            getFileByte = reader.result;
            setAttachment(getFileByte);
          };
          reader.onerror = function (error) {
          };
        }
      } else {
        setErrAttachment("Upload only JPEG,PNG,Doc,Xls,PDF,CSV or ZIP files");
        setFileName("")
      }
    };

    return (
      <Modal show={modelShown} className='modal-top-fixed' centered onHide={() => { }}>
        <Modal.Header>
          <h2>Submit a ticket</h2>
          <div className="close-ico-btn"
            onClick={() => {
              setModelShown(false)
              props.onChangeIsOpen(false);
            }}
          >
            <i className="icon-sd-close-mini"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CrispTicketComponent />
          {/* <div>
            <Row>
              <Col md={3}>
                <label>Severity</label>
              </Col>
              <Col md={9}>
                <div className="position-relative">
                  {errSeverity && (
                    <div className="validation-message">{errSeverity}</div>
                  )}
                  <RSDropdownList
                    className='required'
                    data={sevirityData}
                    value={severity}
                    defaultValue={{ "sevirityId": 2, "sevirityText": "-- Select severity --" }}
                    textField='sevirityText'
                    dataItemKey='sevirityId'
                    onChange={(e) => {
                      let errMsg = e.target.value.sevirityId !== 2 ? null : "Select severity";
                      setErrSeverity(errMsg);
                      setSeverity(e.target.value);
                    }}

                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label>Category</label>
              </Col>
              <Col md={9}>
                <div className="position-relative">
                  {errCategory && (
                    <div className="validation-message">{errCategory}</div>
                  )}
                  <RSDropdownList
                    className='required'
                    data={categoryData}
                    value={category}
                    defaultValue={{ "categoryId": 0, "categoryText": "-- Select category --" }}
                    textField='categoryText'
                    dataItemKey='categoryId'
                    onChange={(e) => {
                      let errMsg = e.target.value.categoryId !== 0 ? null : "Select category"
                      setErrCategory(errMsg);
                      setCategory(e.target.value);
                    }}

                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label>Subject</label>
              </Col>
              <Col md={9}>
                <RSInput2
                  required={true}
                  ph="Enter subject"
                  val={subject}
                  cb={(value) => {
                    let errMsg = value.length > 0 ? null : "Enter Subject"
                    setSubject(value)
                    setErrSubject(errMsg)
                  }}
                  ob={(text) => {
                    let errMsg = text.length > 0 ? null : 'Enter subject'
                    setSubject(text)
                    setErrSubject(errMsg)
                  }}
                  errorMessage={errSubject}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label>Message</label>
              </Col>
              <Col md={9}>
                <div className="position-relative">
                  {errMessage && (
                    <div className="validation-message">{errMessage}</div>
                  )}
                  <textarea
                    placeholder="Enter message"
                    rows={5}
                    cols={50}
                    value={message}
                    onChange={(event) => {
                      let errMsg = event.target.value.length > 0 ? null : "Enter message";
                      setErrMessage(errMsg);
                      setMessage(event.target.value)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={3}>
                <label>Attachment</label>
              </Col>
              <Col md={3}>
                <div className='position-relative'>
                  {errAttachment !== null && <div className="validation-message">{errAttachment}</div>}
                  <UploadComponent
                    text="Browse"
                    type={'all'}
                    error={(error) => {
                      // setErrUploadLogo(error)
                    }}
                    onChange={value => {
                      setFileName("")
                      uploadToServer();
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <label>{fileName !== "" ? fileName : "JPEG, PNG, Doc, Xls, PDF, CSV, ZIP files of size not more than 2 MB can be uploaded"}</label>
              </Col>
            </Row>
            <Row>
              <div className="btn-container d-flex justify-content-end">
                <RSSecondaryBtn onClick={() => {
                  setModelShown(false)
                  props.onChangeIsOpen(false);
                }}>Cancel</RSSecondaryBtn>
                <RSPrimaryBtn onClick={() => {
                  submitBtnClick()
                }}>Submit</RSPrimaryBtn>
              </div>
            </Row>
          </div> */}
        </Modal.Body>

      </Modal>

    )
  }

  const PaymentComponent = props => {
    const context = useContext(authContext)
    const navigation = useHistory()

    return (
      <Modal show={true} centered>
        <div>

          <Row md={12}>
            <p className='text-center marginT15 red'>Your Marketing Star subscription has expired</p>
            <p className='text-center'>Your subscription has expired. To keep using Marketing Star without interruption, we kindly ask you to renew license.</p>
          </Row>

          <Row>
            <div className="btn-container d-flex justify-content-end">
              <RSPrimaryBtn onClick={() => {
                let userData = getUserData();
                clearUserDetails()
                context.globalStateData.setPaymentMessage("")
                context.toggleAuth(false);
                navigation.push({ pathname: `features`, state: { clientId: userData.clientId || 0, userId: userData.userId || 0, isForPaylater: true } })
              }}>Renew Now</RSPrimaryBtn>
            </div>
          </Row>
        </div>
      </Modal>
    )
  }

  const AccountExpiryReminder = props => {
    return (
      <>
        {/* <div className='status-bar-head status-warning' style={{ width: 300, right: 10, top: 78, height: 50, borderRadius: 40 }}>
          <p>{props.message}</p>
          <i
            onClick={() => setReminderMessage("")}
            className='icon-sd-close-mini'
          ></i>
        </div> */}
        <Container className='position-relative' style={{ top: 90 }}>
          <div className='status-bar-head clearfix status-warning'>
            <div>
              <span className='status-icon-Bg'><i className="icon-sd-alert-medium icons-md" /></span>
              <span><p className='paddingT15'>{props.message}</p></span>
            </div>
            <div className='license-close'>
              <i className="icon-sd-close-mini icons-sm white float-end cp" onClick={() => setReminderMessage("")} />
            </div>
          </div>
        </Container>
      </>
    )
  }
  const mountedStyle = { display: "block" };
  const unmountedStyle = {
    display: "none",
  };
  return (
    <MyC.AuthContext.Provider value={{ isAuth, toggleAuth, globalStateData }} >
      <div className={`App`}>
        <ErrorBoundary>
          <Router>
            {paymentMessage.length > 0 && <PaymentComponent />}
            {toastMessage.length > 0 && <Toast />}
            {(isAuth && reminderMessage.length > 0) && <AccountExpiryReminder message={reminderMessage} />}
            <MyC.RSPLoader style={isLoading ? mountedStyle : unmountedStyle} />
            {sessionOut ? <SessionOutLoginPopup sessionOut={sessionOut} handleSessionOut={handleSessionOut} /> : null}
            {isAuth && (currentPage !== '/communication/mdc-email-creation' && currentPage !== '/communication/mdc-sms-creation' && currentPage !== '/communication/mdc-whatsapp-creation' && currentPage !== '/communication/mdc-qr-creation') && <HeaderComponent userTypeId={45} headerIcons={headerIcons} clickOff={sessionOut ? "click-off" : ""} />}
            {isAuth && (currentPage !== '/communication/mdc-email-creation' && currentPage !== '/communication/mdc-sms-creation' && currentPage !== '/communication/mdc-whatsapp-creation' && currentPage !== '/communication/mdc-qr-creation') && <HelpComponent />}
            {
              (isAuth && isOpenSuccessModal) &&
              <SuccessModal isOkButton={true} isOpen={isOpenSuccessModal} title={"Success"} subTitle={"Your request has been submitted successfully."} buttonClicked={(value) => {
                setIsOpenSuccessModal(false)
              }} />
            }

            {/* {isAuth && <HelpComponent />} */}
            {isShowOnlineWarning
              ? onlineStatus ? <OnlineComponent /> : <OfflineComponent /> : null}
            <Switch>
              <MyC.ProtectedRoute exact path="/" component={MyC.Dashboard} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/admin" component={MyC.Admin} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/audience" component={MyC.Audience} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/launchpad" component={MyC.LaunchPad} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/communication" component={MyC.Campaign} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/analytics" component={MyC.AnalyticsHome} isAuth={isAuth} />
              <MyC.ProtectedRoute path="/preferences" component={MyC.Preference} isAuth={isAuth} />

              <MyC.NonProtectedRoute
                path='/login'
                component={MyC.Login}
                isAuth={isAuth}
              />

              <MyC.NonProtectedRoute
                path='/key-contact-info'
                component={MyC.KeyContactInfo}
                isAuth={isAuth}
              />
              {/* <MyC.ProtectedRoute
                path='/service-catalogue'
                component={MyC.ServiceCatalogue}
                isAuth={isAuth}
              /> */}
              <MyC.NonProtectedRoute
                path='/features'
                component={FeaturePlan}
                isAuth={isAuth}
              />
              {isAuth ? <MyC.ProtectedRoute path='/payment'
                component={MyC.Payment}
                isAuth={isAuth} /> :
                <MyC.NonProtectedRoute
                  path='/payment'
                  component={MyC.Payment}
                  isAuth={isAuth}
                />
              }

              <MyC.NonProtectedRoute
                path='/thanks'
                component={MyC.Thanks}
                isAuth={isAuth}
              />
              <MyC.NonProtectedRoute
                path='/thanks-signing-up'
                component={MyC.ThanksSignup}
                isAuth={isAuth}
              />

              <MyC.NonProtectedRoute
                path='/launchpad'
                component={MyC.LaunchPad}
                isAuth={isAuth}
              />

              <MyC.NonProtectedRoute
                path='/communication-completion'
                component={MyC.CampaignCompletion}
                isAuth={isAuth}
              />

              <MyC.NonProtectedRoute
                path='/documentation'
                component={MyC.Documentation}
                isAuth={isAuth}
              />
              <Route path="*"> <MyC.NotFound /> </Route>
            </Switch>
          </Router>
          <MyC.RSFooter isAuth={isAuth} hideFooter={false} />
        </ErrorBoundary>
      </div>
    </MyC.AuthContext.Provider>
  )
}

export default App

const SessionOutLoginPopup = props => {

  const [isPresent, setIsPresent] = useState(false)

  useEffect(() => {
    setIsPresent(props.sessionOut)
  }, [props.sessionOut])
  return (
    <Modal
      backdrop='static'
      show={isPresent}
      centered
      className='logged-in-otp'
      onHide={props.handleSessionOut && props.handleSessionOut}
    >
      <Modal.Header closeButton>
        <h2>Login</h2>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex align-items-center justify-content-between'>
          <PopupLogin isShowForgotButton={true} isHideLoader={true} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

const HeaderComponent = (props) => {
  // const config = localStorageStrToObject("lsConfig")
  const history = useHistory()
  const context = useContext(authContext)
  const [state, setSatte] = useState("")
  const [selectSubItem, setSelectSubitem] = useState(null)
  return (
    <MyC.RSHeader clickOff={props.clickOff}>
      <div className="rs-navbar-header">
        {props.userTypeId === 44 ? null : (
          <ul>
            {props.headerIcons.map((item, index) => (
              <li key={index} className={`rs-dropdown-wrapper ${index === 3 ? context.globalStateData.getSelectedSubItem !== null ? 'passitive' : 'negative' : ''}`} onClick={() => {
                if (index !== 3) {
                  context.globalStateData.setSelectedSubItem(null)
                }

              }}>
                {item.path === "/" ? (
                  <RSPTooltip position="bottom" text={`${item.name}`}>
                    <NavLink exact activeClassName={`${index === 3 ? context.globalStateData.getSelectedSubItem !== null ? 'active' : 'active3' : 'active'} `} to={`${item.path}`}>
                      <span
                        className={`${item.disable ? "hicon click-off" : "hicon"}`}
                      ></span>
                    </NavLink>
                  </RSPTooltip>
                ) : item.submenu ? (

                  <Dropdown

                    onSelect={(e) => {
                      context.globalStateData.setSelectedSubItem(1)
                      history.push(e)
                    }}
                  >
                    <Dropdown.Toggle className={`${index === 3 ? context.globalStateData.getSelectedSubItem !== null ? 'active' : '' : 'active'} `}><RSPTooltip position="bottom" text={`${item.name}`}>
                      <span className="hicon tooltip-menu"></span></RSPTooltip>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                      {item.submenuData?.map((menu, index) => (
                        <Dropdown.Item eventKey={menu.page} >
                          <i className={`${menu.icon} icon-lg mr10`}></i>
                          {menu.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                ) : (
                  <RSPTooltip position="bottom" text={`${item.name}`}>
                    <NavLink activeClassName="active" to={`${item.path}`}>
                      <span
                        className={item.disable ? "hicon click-off" : "hicon"}
                      ></span>
                    </NavLink>
                  </RSPTooltip>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </MyC.RSHeader>
  );
};
// Removed builder

const sevirityData = [
  { "sevirityId": 2, "sevirityText": "-- Select severity --" },
  { "sevirityId": 20, "sevirityText": "Low" },
  { "sevirityId": 19, "sevirityText": "Medium" },
  { "sevirityId": 18, "sevirityText": "High" },
  { "sevirityId": 36, "sevirityText": "Critical" }]

const categoryData = [
  { "categoryId": 0, "categoryText": "-- Select category --" },
  { "categoryId": 1, "categoryText": "Product Defects" },
  { "categoryId": 2, "categoryText": "Questions" },
  { "categoryId": 3, "categoryText": "Others" }]



