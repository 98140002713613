import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import authContext from 'Helper/StateHandler/auth-context';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSTextInputWithIcon } from 'Components/RSInputs';
import { RSPTimer } from 'Components/RSPTimer';
import { getUserData } from 'Helper/Utils/Utils';
import { connectServer } from 'Helper/Network/networkHandler';
import { DOWNLOAD_LIST, GET_KEY_ACCOUNT_DETAILS, GET_OTP_FOR_LIST_DOWNLOAD, VALIDATE_LIST_DOWNLOAD_OTP } from 'Helper/Constants/endPoint';
import { MAX_LOGIN_OTP } from 'Helper/Constants/inputLimit';
import { SELECT_OTP_SOURCE } from 'Helper/Constants/validationMessage';
class ListDownloadModal extends Component {
    static contextType = authContext;
    state = {
        show: this.props.isOpen,
        downloadOTP: "",
        errDownloadOTP: null,
        OTPSourceValue: null,
        errOTPSource: null,
        OTPSourceMasked: "",
        OTPToken: null,
        isOTPSuccess: false,
        isDefaultOTP: true,
        isRequestSuccess: false,
        isShowResendBtn: false,
        keyAccountDetails: null
    };
    componentDidMount = props => {
        this.getKeyAccountDetails()
    }
    getKeyAccountDetails = () => {
        let params = {};
        connectServer({
            path: GET_KEY_ACCOUNT_DETAILS,
            params: params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status) {
                    this.setState({ keyAccountDetails: res.data });
                } else {
                    this.setState({ keyAccountDetails: null });
                }
            },
            fail: (err) => { },
        });
    };

    requestOTPForDownloadCSV = (SourceId) => {
        this.setState({ isShowResendBtn: false });
        let params = {
            otpType: SourceId
        };
        connectServer({
            path: GET_OTP_FOR_LIST_DOWNLOAD,
            params: params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status) {
                    let errMsg = "OTP sent successfully";
                    this.setState({ OTPToken: res.data, errDownloadOTP: errMsg });
                } else {
                    this.setState({ errDownloadOTP: res.message });
                }
            },
            fail: (err) => { },
        });
    };

    validateOTPForDownloadCSV = () => {
        this.setState({ isPasswordShowResendBtn: false });
        let params = {
            otpKey: this.state.OTPToken,
            otpValue: this.state.downloadOTP,
        };
        connectServer({
            path: VALIDATE_LIST_DOWNLOAD_OTP,
            params: params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status) {
                    this.setState({ isOTPSuccess: true, isDefaultOTP: false });
                } else {
                    this.setState({
                        isOTPSuccess: false,
                        isDefaultOTP: false,
                        errDownloadOTP: res.message,
                    });
                }
            },
            fail: (err) => { },
        });
    };

    downloadCommunicationStatusCSV = () => {

        let params = {
            "segmentationListId": this.props.selectedListId,
            "Otp": this.state.downloadOTP
        };
        connectServer({
            path: DOWNLOAD_LIST,
            params: params,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: (res) => {
                if (res.status) {
                    this.setState({ isRequestSuccess: true });
                } else {
                    this.setState({ isRequestSuccess: false });
                }
            },
            fail: (err) => { },
        });
    };
    render() {

        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={(value) => {
                        this.props.onChangeIsOpen(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="md"
                >
                    <>
                        <Modal.Header closeButton>
                            <h2>Download CSV</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <Row>
                                    <p>
                                        {this.state.isRequestSuccess
                                            ? "Thank you!. Your request has been received."
                                            : "A one-time password will be sent to the Key contact. Enter the OTP to proceed with your request."}
                                    </p>
                                </Row>
                                {this.state.isRequestSuccess ? (
                                    <>
                                        <p>
                                            You will receive the link for downloading the CSV report
                                            in your registered email address shortly.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <Row>
                                            <Col md={4}>
                                                <label className="label-row">Send OTP to</label>
                                            </Col>
                                            <Col md={4}>
                                                <div className="position-relative">
                                                    {this.state.errOTPSource && (
                                                        <div className="validation-message">
                                                            {this.state.errOTPSource}
                                                        </div>
                                                    )}
                                                    <RSDropdownList
                                                        value={this.state.OTPSourceValue}
                                                        defaultItem={{
                                                            sourceId: 0,
                                                            source: "-- Source --",
                                                        }}
                                                        textField="source"
                                                        dataItemKey="sourceId"
                                                        className="required"
                                                        data={OTPSource}
                                                        onChange={(e) => {
                                                            let errMsg =
                                                                e.target.value.sourceId !== 0
                                                                    ? null
                                                                    : SELECT_OTP_SOURCE;
                                                            let maskedDetails = "";
                                                            if (errMsg === null) {
                                                                if (e.target.value.sourceId === 1) {
                                                                    maskedDetails = this.state.keyAccountDetails.mobileNo;
                                                                } else {
                                                                    maskedDetails = this.state.keyAccountDetails.emailId;
                                                                }
                                                                this.requestOTPForDownloadCSV(e.target.value.sourceId);
                                                            } else {
                                                                maskedDetails = "";
                                                            }
                                                            this.setState({
                                                                OTPSourceValue: e.target.value,
                                                                errOTPSource: errMsg,
                                                                OTPSourceMasked: maskedDetails,
                                                                errDownloadOTP: null,
                                                                isShowResendBtn: false,
                                                                OTPToken: null,
                                                                isOTPSuccess: false,
                                                                downloadOTP: "",
                                                                isDefaultOTP: true,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <label className="label-row truncate">
                                                    {this.state.OTPSourceMasked}
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <label className="label-row">Enter OTP</label>
                                            </Col>
                                            <Col md={4}>
                                                <RSTextInputWithIcon
                                                    ph="Enter OTP"
                                                    max={MAX_LOGIN_OTP}
                                                    keyboardType="number"
                                                    required={true}
                                                    disabled={this.state.isOTPSuccess ? "click-off" : ""}
                                                    val={this.state.downloadOTP}
                                                    icon={`${this.state.isOTPSuccess
                                                        ? "icon-sd-circle-tick-mini icons-sm green text-end top10"
                                                        : this.state.isDefaultOTP
                                                            ? ""
                                                            : "icon-sd-close-mini icons-sm red text-end top9"
                                                        }`}
                                                    success_msg={
                                                        this.state.errDownloadOTP?.includes("success")
                                                            ? "success_msg"
                                                            : ""
                                                    }
                                                    errorMessage={this.state.errDownloadOTP}
                                                    validClass="w-max-content"
                                                    cb={(t) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (t === "" || re.test(t)) {
                                                            this.setState({
                                                                downloadOTP: t,
                                                                errDownloadOTP: null,
                                                                isDefaultOTP: true,
                                                            });
                                                        }
                                                    }}
                                                    ob={(t) => {
                                                        if (!t.length) {
                                                            this.setState({
                                                                downloadOTP: t,
                                                                errDownloadOTP: "Enter OTP",
                                                            });
                                                            return;
                                                        }
                                                        if (t.length === 6) {
                                                            this.setState({
                                                                downloadOTP: t,
                                                                errDownloadOTP: null,
                                                            });
                                                        }

                                                        this.validateOTPForDownloadCSV();
                                                    }}
                                                />
                                                {this.state.OTPToken !== null &&
                                                    !this.state.isOTPSuccess &&
                                                    !this.state.isShowResendBtn ? (
                                                    <small className="input-desc marginX11">
                                                        OTP validity:{" "}
                                                        <RSPTimer
                                                            onComplete={() => {
                                                                this.setState({
                                                                    isShowResendBtn: true,
                                                                    errDownloadOTP: "",
                                                                });
                                                            }}
                                                        />
                                                    </small>
                                                ) : null}
                                                {this.state.OTPToken !== null &&
                                                    !this.state.isOTPSuccess &&
                                                    this.state.isShowResendBtn ? (
                                                    <small className="input-desc-right">
                                                        <label
                                                            className="rs-link orange-medium"
                                                            onClick={() => {
                                                                this.requestOTPForDownloadCSV(
                                                                    this.state.OTPSourceValue.sourceId,
                                                                    "resend"
                                                                );
                                                                this.setState({
                                                                    isShowResendBtn: false,
                                                                    downloadOTP: "",
                                                                    isDefaultOTP: true,
                                                                });
                                                            }}
                                                        >
                                                            Resend?
                                                        </label>
                                                    </small>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="btn-container d-flex justify-content-end">
                                {!this.state.isRequestSuccess && (
                                    <RSSecondaryBtn
                                        onClick={() => {
                                            this.props.onChangeIsOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </RSSecondaryBtn>
                                )}
                                {this.state.isRequestSuccess ? (
                                    <RSPrimaryBtn
                                        className={this.state.isOTPSuccess ? "" : "click-off"}
                                        onClick={() => {
                                            this.props.onChangeIsOpen(true);
                                            if (this.props.isForm) this.props.onChangeIsOpen(false);
                                        }}
                                    >
                                        OK
                                    </RSPrimaryBtn>
                                ) : (
                                    <RSPrimaryBtn
                                        className={this.state.isOTPSuccess ? "" : "click-off"}
                                        onClick={() => {
                                            this.downloadCommunicationStatusCSV();
                                        }}
                                    >
                                        Confirm
                                    </RSPrimaryBtn>
                                )}
                            </div>
                        </Modal.Footer>
                    </>
                </Modal>
            </>
        );
    }
}

export default ListDownloadModal;

const OTPSource = [
    {
        sourceId: 2,
        source: "Email ID",
    },
    {
        sourceId: 1,
        source: "Mobile No",
    },
];