import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { TimeFormatWithMomentForWhatsApp, getServerConfig, getUserData, DateFormatWithMomentForWhatsApp } from 'Helper/Utils/Utils';
import { WhatsAppMessage } from 'Components/WhatsAppMessage';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { LIVE_SMS_PREVIEW, SAVE_WHATSAPP_CAMPAIGN, UPDATE_WHATSAPP_CAMPAIGN } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { createMobileWhatsAppCampaign } from '../../CamapignWrapper';
import { SearchViewAdvanced1 } from 'Components/SearchView/SearchView';
import { createMobileWhatsAppCampaignMDC } from '../../../../MDCComponents/CamapignWrapper';
import { SuccessModal } from '../../EmailComponents/Components/SuccessModal';

class WhatsAppLivePreview extends Component {

    static contextType = authContext;

    state = {
        isShow: this.props.isOpen,
        isEnable: false,
        whatsAppData: this.props.mobileWhatsAppData,
        searchData: null,
        livePreviewData: null,
        isSuccess: true,
        imageURL: getServerConfig().slice(0, -1)
    }
    liveWhatsAppPreview = (searchDataFromChild) => {
        let tempAudienceList = [];
        let tempAudienceCount = 0;
        if (this.props.mobileWhatsAppData.campaignType === "S") {
            this.props.mobileWhatsAppData.recipientBunchIDs.map((item, index) => {
                tempAudienceList.push(item.segmentationListID);
                tempAudienceCount = tempAudienceCount + item.recipientCount;
            });
        } else {
            this.props.channelDetails.segmentationListID.map((item, index) => {
                tempAudienceList.push(parseInt(item, 10));
            });
            tempAudienceCount = this.props.totalaudience;
        }
        let params = {
            campaignType: this.props.mobileWhatsAppData.campaignType,
            campaignId: this.props.campaignId,
            levelNumber: 1,
            senderId: this.props.mobileWhatsAppData.clientWASettingId,
            testSmsMobileNo: this.props.mobileNumber,
            isFlashMessageEnabled: false,
            targetList: tempAudienceList,
            livePreviewType: searchDataFromChild?.id,
            livePreviewInput: searchDataFromChild?.value,
            // content: this.props.textContent,
            content: [
                {
                    "splitType": "",
                    "body": this.props.mobileWhatsAppData.waContent,
                    "previewBody": this.props.mobileWhatsAppData.waPreviewContent,
                    "smsChannelDetailId": this.props.waChannelId,
                    "timezoneId": this.props.mobileWhatsAppData.timezoneID,
                    "localBlastDateTime": this.props.mobileWhatsAppData.localBlastDateTime,
                    "isShortenURLEnabled": this.props.mobileWhatsAppData.isShortenURLEnabled,
                    "bodyPosition": 0
                }
            ],
            smsType: "WAPP",
            waTemplateName: this.props.mobileWhatsAppData.templateName,
            waTemplateLang: this.props.mobileWhatsAppData.languageCode,
            waSenderId: this.props.mobileWhatsAppData.clientWASettingId,
        }
        connectServer({
            path: LIVE_SMS_PREVIEW,
            params,
            loading: this.context.globalStateData.setIsLoadingData,
            sessionOut: this.context.globalStateData.setSessionOutData,
            context: this.context,
            ok: res => {
                if (res.status) {
                    this.setState({ livePreviewData: res.data, isEnable: true, isSuccess: true })
                } else {
                    this.setState({ isEnable: false, isSuccess: false })
                }
            },
            fail: err => {
                // 
            }
        })
    }
    render() {
        let currentTime = new Date().toLocaleTimeString('fr', { hour: '2-digit', minute: '2-digit' })
        return (
            <Modal
                show={this.state.isShow}
                onHide={() => {
                    this.setState({ isShow: false });
                    this.props.onChangeIsOpen(false);
                }}
                backdrop="static"
                className='modal-top-fixed'
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <h2>Preview</h2>
                    <div className="d-flex justify-content-end live-prev-search">
                        <SearchViewAdvanced1
                            cls={`mr25 `}
                            modalTooltipCls='modal-tooltip'
                            dropdownData={['Email ID', 'Mobile no']}
                            onValidateSearch={(status, searchData) => {
                                this.props.onValidateSearch(status)
                                if (status) {
                                    this.liveWhatsAppPreview(searchData)
                                }
                            }}
                            isSearchValidate={this.props.isSearchValidate}
                            getSearchData={(item) => {
                                this.setState({ searchData: item })
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <WhatsAppMessage
                        modal
                        profile={`${this.state.imageURL}${getUserData()?.clientDetailsLocalization?.logoPath}`}
                        username={getUserData().clientDetailsLocalization.clientName}
                        date={DateFormatWithMomentForWhatsApp(new Date())}
                        time={TimeFormatWithMomentForWhatsApp(new Date())}
                        image={this.props.imagePath}
                        message={this.state.livePreviewData ? this.state.livePreviewData.livePreviewBody : this.props.textContent ? this.props.textContent : 'No data'}
                        currentTime
                        fade
                    />
                    {!this.state.isSuccess &&
                        <SuccessModal isOkButton={false} isOpen={!this.state.isSuccess} title={"Failure"} subTitle={"Invalid audience."} buttonClicked={(value) => {
                            this.setState({ isSuccess: true });
                        }} />
                    }
                </Modal.Body >
                <Modal.Footer>
                    <div className={`${!this.state.isEnable ? ' click-off' : ''}`}>
                        <RSSecondaryBtn onClick={() => {
                            this.setState({ isShow: !this.state.isShow });
                            this.props.onChangeIsOpen(false);
                        }}>Cancel</RSSecondaryBtn>
                        <RSPrimaryBtn onClick={() => {
                            let lpWACreatedBY = getUserData()?.emailId;
                            let lpWAPassportID = this.state.livePreviewData.passportID;
                            let databaseName = getUserData()?.clientDetailsLocalization?.instanceName;
                            if (this.props.mobileWhatsAppData.campaignType === "S") {
                                let params = createMobileWhatsAppCampaign("S", this.props.campaignId, this.props.mobileWhatsAppData, false, lpWACreatedBY, lpWAPassportID)
                                connectServer({
                                    path: this.props.waChannelId !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                                    params,
                                    loading: this.context.globalStateData.setIsLoadingData,
                                    sessionOut: this.context.globalStateData.setSessionOutData,
                                    context: this.context,
                                    ok: (res) => {
                                        if (res.status) {
                                            this.props.onChangeWAChannelId(res.data.waChannelDetailID)
                                            this.setState({ isShow: !this.state.isShow });
                                            this.props.onChangeIsOpen(true);
                                        } else {
                                            this.props.onChangeIsOpen(false);
                                        }
                                    },
                                    fail: (err) => {
                                    },
                                });
                            } else {
                                this.props.mobileWhatsAppData.campaignId = this.props.campaignId;
                                this.props.mobileWhatsAppData.levelNumber = this.props.channelDetails.LevelNumber;
                                this.props.channelDetails.totalaudience = this.props.totalaudience;
                                let params = createMobileWhatsAppCampaignMDC("M",
                                    this.props.campaignId,
                                    this.props.mobileWhatsAppData,
                                    this.props.schedule,
                                    this.props.mobileNumber,
                                    this.props.countryCodeMobile,
                                    this.props.channelDetails,
                                    false,
                                    lpWACreatedBY,
                                    lpWAPassportID)
                                connectServer({
                                    path: this.props.waChannelId !== 0 ? UPDATE_WHATSAPP_CAMPAIGN : SAVE_WHATSAPP_CAMPAIGN,
                                    params,
                                    loading: this.context.globalStateData.setIsLoadingData,
                                    sessionOut: this.context.globalStateData.setSessionOutData,
                                    context: this.context,
                                    ok: (res) => {
                                        if (res.status) {
                                            this.props.onChangeWAChannelId(res.data.waChannelDetailID)
                                            this.setState({ isShow: !this.state.isShow });
                                            this.props.onChangeIsOpen(true);
                                        } else {
                                            this.props.onChangeIsOpen(false);
                                        }
                                    },
                                    fail: (err) => { },
                                });
                            }
                        }}>Send to me</RSPrimaryBtn>
                    </div>
                </Modal.Footer>
            </Modal >
        );
    }
}

export default WhatsAppLivePreview;