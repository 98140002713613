import { Slider, Switch } from '@progress/kendo-react-inputs';
import { RSPPophover, RSPTooltip } from 'Components/Tooltip';
import React, { Component } from 'react';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { Col, Row } from 'react-bootstrap';
import QRImage from 'Assets/Images/qr-code.png';

class QRPreviewComponent extends Component {
    state = {
        generateQR: false,
        sizeValue: 400
    }
    render() {
        return (
            <div className="qr-generator click-off">
                <div className="qr-refresh">
                    <RSPTooltip text="Apply" position="top">
                        <i className='icon-refresh-small icons-md blue cursor-pointer' />
                    </RSPTooltip>
                </div>
                <Row className="mb15">
                    <Col>
                        <div className="d-flex align-items-center">
                            <small>Generate QR</small>
                            <Switch
                                className="mt0 ml10"
                                onLabel={"YES"}
                                offLabel={"NO"}
                                onChange={(event) => {
                                    this.setState({ generateQR: event.target.value });
                                }}
                                checked={this.state.generateQR}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <figure>
                        <img src={QRImage} alt={QRImage} />
                    </figure>
                </Row>
                <ul className="qrlink-slider">
                    <li className="qrslider">
                        <Slider buttons={false} min={1} max={10} step={1} defaultValue={7} onChange={(event) => {
                            this.setState({ sizeValue: Math.round(event.value * 100) })
                        }} />
                        <small>Size:<span>{this.state.sizeValue + "px"}</span></small>
                    </li>
                    <li className="qricon">
                        <RSPPophover text="Adjust the pixel size or download" position="top">
                            <i className="icon-sd-question-mark-mini question-unfill icon-sm blue marginT-6 cp ml5"></i>
                        </RSPPophover>
                    </li>
                    <li className="qrdropwdown">
                        <RSPTooltip text="Download" position="top">
                            <div>
                                <DropDownButton
                                    className=""
                                    items={["PNG", "PDF", "EPS"]}
                                    icon=" icon-sd-download-medium icons-md blue"
                                    onItemClick={(e) => {
                                    }} />
                            </div>
                        </RSPTooltip>
                    </li>
                </ul>
                <div className="d-flex justify-content-between mt10">
                    <div className="smartlink-table">
                        <small className="blue s-title">Short URL: </small>
                        <small className="s-link">https://mtkgstr.io/ap7Xgb</small>
                    </div>
                    <div className="back-text">
                        <small className="blue cursor-pointer">Copy</small>
                        <i className="icon-share-link-medium icons-md blue icon-sd-arrow-left-mini cursor-pointer paddingL10"></i>
                    </div>
                </div>
            </div>
        );
    }
}

export default QRPreviewComponent;