import React from 'react';
import { ListGroup } from 'react-bootstrap';

const ProgressSteps = (props) => {
  const progressSteps = props.progressSteps.map(
    (value,index) => <ListGroup.Item className={`${value.status} ${(props.progressType === "tick")?'tick-mark':''}`} key={index}>
                        {(value.steps) ? <span className="step">{(value.steps !=='') ? value.steps:index+1}</span>:'' }
                        {(value.icons) ? <span className="step"><i className={value.icons}></i></span>:''}
                        <span className="title">{value.steps_title}</span>
                      </ListGroup.Item>
  )
  return (
    <>
      <div className={`justify-content-center ${props.className ? props.className : ''}`} >
        <ListGroup variant="steps">
          {progressSteps}
        </ListGroup>
      </div>
    </>
  );
}
export default ProgressSteps;


export const ProgressQualityBar = (props) => {
  const progressSteps = props.progressSteps.map(
    (value,index) => <ListGroup.Item className={`${value.status} ${(props.progressType === "tick")?'tick-mark':''}`} key={index}>
                        {(value.steps) ? <span className="step">{(value.steps !=='') ? value.steps:index+1}</span>:'' }
                        {(value.icons) ? <span className="step"><i className={value.icons}></i></span>:''}
                        <span className="title">
                          {value.steps_title}
                        </span>
                      </ListGroup.Item>
  )
  return (
    <>
      <div className={`justify-content-center progress-quality-check ${props.className ? props.className : ''}`} >
        <ListGroup variant="steps">
          {progressSteps}
        </ListGroup>
      </div>
    </>
  );
}

