import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { RSDropdownList } from 'Components/RSDropdowns';
import { RSInput2 } from 'Components/RSInputs';
import { RSPrimaryBtn, RSSecondaryBtn } from 'Components/RSButtons';
import { useHistory } from 'react-router-dom';
import { CHECK_CLIENT_NAME, CHECK_WEBSITE_NAME, GET_CITY_BY_STATE, GET_CLIENT_DETAILS, GET_STATE_BY_COUNTRY, UPDATE_CLIENT_DETAILS } from 'Helper/Constants/endPoint';
import { connectServer } from 'Helper/Network/networkHandler';
import authContext from 'Helper/StateHandler/auth-context';
import { RSUploadImageComponent } from 'Components/UploadImage/RSUploadImageComponent';
import { convertObjectToBase64, getAuthClientId, getLsMasterDataDropdown, getServerConfig, getUserData, isEmpty, isValidWebsite } from "Helper/Utils/Utils"
import { ENTER_ADDRESS1, ENTER_ADDRESS2, ENTER_COMPANY_NAME, ENTER_FIRST_NAME_3CHARS, ENTER_SECURE_WEBSITE, ENTER_STATE, ENTER_VALID_WEBSITE, ENTER_WEBSITE, ENTER_ZIP, SELECT_BRAND_POSITION, SELECT_BUSINESS_TYPE, SELECT_CITY, SELECT_COUNTRY, SELECT_INDUSTRY, SELECT_STATE, SPECIAL_NUM_CHARACTERS, UPLOAD_COMPANY_IMAGE } from 'Helper/Constants/validationMessage';
import * as images from 'Assets/Images'

const CompanyDetails = (props) => {
    const history = useHistory()
    const context = useContext(authContext)
    const imageUrl = getServerConfig().slice(0, -1)
    const [companyName, setCompanyName] = useState("")
    const [errCompanyName, setErrCompanyName] = useState(null);
    const [weburl, setWeburl] = useState("")
    const [errWebURL, setErrWebURL] = useState(null);
    const [addressLine1, setAddressLine1] = useState("")
    const [errAddressLine1, setErrAddressLine1] = useState(null);
    const [addressLine2, setAddressLine2] = useState("")
    const [errAddressLine2, setErrAddressLine2] = useState(null);
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [errState, setErrState] = useState(null);
    const [errCity, setErrCity] = useState(null)
    const [zipCode, setZipCode] = useState("")
    const [errZipCode, setErrZipCode] = useState(null);
    const [errCountry, setErrCountry] = useState(null);
    const [companySize, setCompanySize] = useState("")
    const [logoPath, setLogoPath] = useState(null)
    const [errLogoPath, setErrLogoPath] = useState(null);

    const [newImg, setNewImg] = useState(null)
    const [imageType, setImageType] = useState("");

    const [industryDropdownItem, setIndustryDropdownItem] = useState({
        industryId: 0,
        industryName: '-- Industry --'
    })
    const [errIndustryType, setErrIndustryType] = useState(null);
    const [industryDropdownData, setIndustryDropdownData] = useState([])

    const [countryDropdownItem, setCountryDropdownItem] = useState({
        countryId: 0,
        country: '-- Country --'
    })
    const [countryDropdownData, setCountryDropdownData] = useState([])
    const [stateDropdownItem, setStateDropdownItem] = useState({
        stateID: -1,
        state: '-- State --'
    })
    const [stateDropdownData, setStateDropdownData] = useState([])
    const [cityDropdownItem, setCityDropdownItem] = useState({
        cityId: -1,
        city: '-- City --'
    })
    const [cityDropdownData, setCityDropdownData] = useState([])

    const [businessTypeDropdownItem, setBusinessTypeDropdownItem] = useState({
        businessTypeId: 0,
        businessType: '-- Business type --'
    })
    const [errBusinessType, setErrBusinessType] = useState(null);
    const [businessTypeDropdownData, setBusinessTypeDropdownData] = useState([])

    const [brandPositionItem, setBrandPositionItem] = useState({
        brandPositionId: 0,
        brandPositionName: '-- Brand positioning --'
    })
    const [errBrandPosition, setErrBrandPossition] = useState(null);
    const [brandPositionData, setBrandPositionData] = useState([])


    const setDropdownStates = () => {

        const data = getLsMasterDataDropdown()
        setCountryDropdownData(data.countryMaster || [])
        setIndustryDropdownData(data.industry || [])
        setBusinessTypeDropdownData(data.businessType || [])
        setBrandPositionData(data.brandPosition || [])
    }
    useEffect(() => {

        if (context.globalStateData.getIsPrototype) {
            return
        }
        setDropdownStates()
        const params = { clientId: getAuthClientId() }
        connectServer({
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            path: GET_CLIENT_DETAILS,
            params,
            ok: res => {
                if (res.status) {
                    let tempUserData = { ...getUserData() };
                    tempUserData.clientDetailsLocalization.logoPath = res.data.logoPath;
                    localStorage.setItem("lsUserData", convertObjectToBase64(tempUserData))
                    setStateData(res.data)
                }
            },
            fail: err => {
            }
        })
        //setStateData(jsonData)
    }, [context.globalStateData.getIsReloadData])

    const handledropdown = (countryId, stateId, cityId) => {
        const params = {
            countryID: countryId
        }
        connectServer({
            path: GET_STATE_BY_COUNTRY,
            params,
            context: context,
            ok: res => {
                if (res.status) {
                    setStateDropdownData(res.data)
                    setStateDropdownItem(
                        res.data.filter(
                            item => item.stateID === stateId
                        )[0] || null
                    )
                    handledropdownCity(stateId, cityId)
                }
            },
            fail: error => {
            }
        })

    }
    const handledropdownCity = (stateId, cityId) => {
        const params = {
            stateId: stateId
        }
        connectServer({
            path: GET_CITY_BY_STATE,
            params,
            context: context,
            ok: res => {
                if (res.status) {
                    setCityDropdownData(res.data)
                    setCityDropdownItem(
                        res.data.filter(
                            item => item.cityId === cityId
                        )[0] || {
                            "cityId": -1,
                            "city": "-- Select city --",
                        }
                    )
                }
            },
            fail: error => {
            }
        })

    }
    const setStateData = data => {
        const ddata = getLsMasterDataDropdown()
        handledropdown(data.countryId, data.stateId, data.cityId)
        setCompanyName(data.clientName || "")
        setWeburl(data.website || "")
        setAddressLine1(data.addressLine1 || "")
        setAddressLine2(data.addressLine2 || "")
        setState(data.state || "")
        setZipCode(data.zipCode || "")
        setCompanySize(data.companySize || '')
        setCity(data?.city || '')
        setLogoPath(data.logoPath || '')
        setNewImg(data.logoPath || null)
        setCountryDropdownItem(
            ddata.countryMaster.filter(
                item => item.countryId === data.countryId
            )[0] || null
        )
        setIndustryDropdownItem(
            ddata.industry.filter(item => item.industryId === data.industryId)[0] ||
            null
        )
        setBusinessTypeDropdownItem(
            ddata.businessType.filter(
                item => item.businessTypeId === data.businessTypeId
            )[0] || null
        )
        setBrandPositionItem(
            ddata.brandPosition.filter(
                item => item.brandPositionId === data.brandPositionId
            )[0] || null
        )
        let brandPos = ddata.brandPosition.filter(item => item.clienttype === data.businessTypeId)
        setBrandPositionData(brandPos)

        // setCompanySizeDropdownData(data.companySize || "")
        //setCompanySize(data.companySize || "")

    }
    const checkClientExist = clientName => {
        const params = { clientName: clientName }
        connectServer({
            path: CHECK_CLIENT_NAME,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            params,
            ok: res => {
                if (res.status) {
                    const some = companyName
                    setErrCompanyName(`${some} already exists`)
                }
            },
            fail: error => { }
        })
    }
    const checkWebsiteExist = websiteName => {
        const params = { website: websiteName }
        connectServer({
            path: CHECK_WEBSITE_NAME,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            params,
            ok: res => {
                if (!res.status) {
                    setErrWebURL(ENTER_VALID_WEBSITE)
                }
            },
            fail: error => {
                setErrWebURL(ENTER_VALID_WEBSITE)
            }
        })
    }
    const validateState = () => {
        if (!logoPath) {
            setErrLogoPath(UPLOAD_COMPANY_IMAGE)
            return
        }
        if (isEmpty(companyName)) {
            setErrCompanyName(ENTER_COMPANY_NAME);
            return;
        }
        if (errWebURL === null) {
            if (isEmpty(weburl)) {
                setErrWebURL(ENTER_WEBSITE);
                return;
            }
        } else {
            return;
        }
        if (isEmpty(addressLine1)) {
            setErrAddressLine1(ENTER_ADDRESS1);
            return;
        }
        if (isEmpty(addressLine2)) {
            setErrAddressLine2(ENTER_ADDRESS2);
            return;
        }
        // if (isEmpty(city)) {
        //     setErrCity("Enter city"); return
        // }
        // if (city.length < 3) {
        //     setErrCity(ENTER_FIRST_NAME_3CHARS); return
        // }
        if (isEmpty(zipCode)) {
            setErrZipCode(ENTER_ZIP);
            return;
        }
        if (countryDropdownItem.countryId === 0) {
            setErrCountry(SELECT_COUNTRY);
            return;
        }
        if (stateDropdownItem.stateID === -1) {
            setErrState(SELECT_STATE);
            return;
        }
        if (cityDropdownItem.cityId === -1) {
            setErrCity(SELECT_CITY);
            return;
        }
        if (industryDropdownItem.industryId === 0) {
            setErrIndustryType(SELECT_INDUSTRY);
            return;
        }
        if (businessTypeDropdownItem.businessTypeId === 0) {
            setErrBusinessType(SELECT_BUSINESS_TYPE);
            return;
        }
        if (brandPositionItem.brandPositionId === 0) {
            setErrBrandPossition("Select brand positioning");
            return;
        }
        const params = {
            clientId: getAuthClientId(),
            clientName: companyName,
            logoPath: logoPath,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            state: state,
            stateId: stateDropdownItem.stateID,
            city: city,
            cityId: cityDropdownItem.cityId,
            website: weburl,
            countryId: countryDropdownItem.countryId,
            zipCode: zipCode,
            industryId: industryDropdownItem.industryId,
            businessTypeId: businessTypeDropdownItem.businessTypeId,
            brandPositionId: brandPositionItem.brandPositionId,
            clientLogoType: imageType
        }
        connectServer({
            path: UPDATE_CLIENT_DETAILS,
            params,
            loading: context.globalStateData.setIsLoadingData,
            sessionOut: context.globalStateData.setSessionOutData,
            context: context,
            ok: res => {
                if (res.status) {
                    let tempUserData = { ...getUserData() };
                    tempUserData.clientDetailsLocalization.logoPath = res.data;
                    localStorage.setItem("lsUserData", convertObjectToBase64(tempUserData))
                    props.setIndex(props.index + 1)
                }
            },
            fail: err => {
            }
        })
    }

    return (
        <div className="page-wrapper">
            <div className="page-header box-bottom-space settings-container">
                <Row>
                    <Col md={2} sm={3}>
                        <RSUploadImageComponent
                            className={`enable-remove`}
                            defaultImg={images.Building}
                            defaultValue={logoPath}
                            tooltipText={"brand logo"}
                            newImg={newImg ? `${imageUrl}${newImg}` : null}
                            text={`${!newImg && !logoPath ? "Upload logo" : "Edit logo"}`}
                            icon={`${!newImg && !logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                            cb={img => {
                                setNewImg(null)
                                setLogoPath(img)
                                setErrLogoPath(null)
                            }}
                            getImageType={(value) => {
                                setImageType(value)
                            }}
                            error={(value) => {
                                setErrLogoPath(value)
                            }}
                            errorMessage={errLogoPath}
                            removeIcon={logoPath !== null}
                            handleRemove={() => {
                                setNewImg(null)
                                setLogoPath(null)
                            }
                            }
                        />
                        {/* <RSUploadImageComponent
                            className={`enable-remove`}
                            newImg={newImg ? `${imageUrl}${newImg}` : null}
                            defaultImg={images.User}
                            defaultValue={logoPath}
                            text={`${!newImg && !logoPath ? "Upload logo" : "Remove logo"}`}
                            icon={`${!newImg && !logoPath ? "icon-sd-circle-plus-medium icons-md" : "icon-sd-pencil-edit-mini icon-sm"}`}
                            cb={img => {
                                setNewImg(null)
                                setLogoPath(img)
                                setErrLogoPath(null)
                            }}
                            getErrMsg={(errMsg) => { setErrLogoPath(errMsg) }}
                        />
                        {logoPath !== null &&
                            <div className="remove-profile cp" onClick={() => {
                                setNewImg(null)
                                setLogoPath(null)
                            }}>
                                <i className="icon-sd-circle-close-medium icons-md"></i>
                                <span className="fs13 cp ml5">Remove</span>
                            </div>
                        } */}
                    </Col>
                    <Col>
                        {/* <h4>About your company</h4> */}
                        {/* First Row */}
                        <Row>
                            <Col>
                                <RSInput2
                                    name="company name"
                                    ph="Enter your company name"
                                    className="disabled"
                                    disabled={true}
                                    val={companyName}
                                    required={true}
                                    max={255}
                                    cb={text => {
                                        let errMsg = text.length > 0 ? null : ENTER_COMPANY_NAME
                                        setCompanyName(text);
                                        setErrCompanyName(errMsg);
                                    }}
                                    ob={text => {
                                        if (text.length > 0) {
                                            checkClientExist(text)
                                        }
                                    }}
                                    errorMessage={errCompanyName}
                                />
                            </Col>
                            <Col>
                                <RSInput2
                                    name="website"
                                    ph="Enter your website URL"
                                    val={weburl}
                                    required={true}
                                    errorMessage={errWebURL}
                                    max={150}
                                    cb={text => {
                                        let errMsg = text.length > 0 ? null : ENTER_WEBSITE
                                        setWeburl(text)
                                        setErrWebURL(errMsg);
                                    }}
                                    ob={text => {
                                        const httpsPattern = /https:\/\//g;
                                        if (text.length > 0 && text.match(httpsPattern)?.length === 1) {
                                            if (isValidWebsite(text)) {
                                                checkWebsiteExist(text)
                                            }
                                        } else {
                                            const httpPattern = /http:\/\//g;
                                            setErrWebURL(text.length > 0 ? text.match(httpPattern)?.length === 1 ? ENTER_SECURE_WEBSITE : ENTER_VALID_WEBSITE : ENTER_WEBSITE);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* second Row */}
                        <Row>
                            <Col>
                                <RSInput2
                                    name="addressLine1"
                                    ph="Enter your address line 1"
                                    val={addressLine1}
                                    required={true}
                                    errorMessage={errAddressLine1}
                                    max={255}
                                    cb={text => {
                                        let errMsg = text.length > 0 ? null : ENTER_ADDRESS1
                                        setErrAddressLine1(errMsg);
                                        setAddressLine1(text);
                                    }}
                                />
                            </Col>
                            <Col>
                                <RSInput2
                                    name="addressLine2"
                                    ph="Enter your address line 2"
                                    val={addressLine2}
                                    required={true}
                                    max={255}
                                    errorMessage={errAddressLine2}
                                    cb={text => {
                                        let errMsg = text.length > 0 ? null : ENTER_ADDRESS2
                                        setAddressLine2(text);
                                        setErrAddressLine2(errMsg)
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Third Row */}
                        <Row>
                            {/* City */}
                            <Col>
                                <div className="position-relative">
                                    {errCountry && <div className="validation-message">{errCountry}</div>}
                                    <RSDropdownList
                                        textField='country'
                                        dataItemKey='countryId'
                                        className={"required"}
                                        disabled={true}
                                        data={countryDropdownData}
                                        value={countryDropdownItem}
                                        defaultItem={
                                            {
                                                countryId: 0,
                                                country: '-- Country --'
                                            }
                                        }
                                        onChange={(e) => {
                                            let errMsg = e.target.value.countryId !== 0 ? null : SELECT_COUNTRY
                                            setCountryDropdownItem(e.target.value)
                                            setErrCountry(errMsg);
                                        }}
                                    />
                                </div>
                                {/* <RSInput2
                                    name='city'
                                    ph='Enter city'
                                    val={city}
                                    max={255}
                                    required={true}
                                    errorMessage={errCity !== null ? errCity : null}
                                    cb={value => {
                                        var regex = /^[a-zA-Z ]*$/;
                                        let errMsg = value.length > 0 ? null : "Enter city"
                                        if (!errMsg) {
                                            if (regex.test(value)) {
                                                setCity(value)
                                                setErrCity(errMsg)
                                            } else {
                                                setErrCity(SPECIAL_NUM_CHARACTERS)
                                            }
                                        } else {
                                            setCity(value)
                                            setErrCity(errMsg)
                                        }
                                    }}
                                    ob={value => {
                                        if (value.length === 0) {
                                            setErrCity("Enter city")
                                        } else if (value.length < 3) {
                                            setErrCity(ENTER_FIRST_NAME_3CHARS)
                                        } else {
                                            setErrCity(null)
                                        }
                                    }}
                                /> */}
                            </Col>
                            {/* State */}
                            <Col>
                                {/* <RSInput2
                                            name="state"
                                            ph="Province/State"
                                            val={state}
                                            required={true}
                                            max={255}
                                            errorMessage={errState}
                                            cb={text => {
                                                let errMsg = text.length > 0 ? null : ENTER_STATE
                                                var regex = /^[A-Za-z\s]+$/;
                                                if (!errMsg) {
                                                    if (regex.test(text)) {
                                                        setState(text)
                                                        setErrState(errMsg)
                                                    } else {
                                                        setErrState(SPECIAL_NUM_CHARACTERS)
                                                    }
                                                } else {
                                                    setState(text)
                                                    setErrState(errMsg)
                                                }
                                            }}
                                            ob={(text) => {
                                                let errMsg = text.length > 0 ? null : ENTER_STATE
                                                setErrState(errMsg)
                                            }}
                                        /> */}
                                <div className='position-relative'>
                                    {errState && <div className="validation-message">{errState}</div>}
                                    <RSDropdownList
                                        className="required"
                                        textField="state"
                                        dataItemKey="stateID"
                                        data={stateDropdownData}
                                        value={stateDropdownItem}
                                        defaultItem={{
                                            "stateID": -1,
                                            "state": "-- Select state --",
                                        }}
                                        onChange={(e) => {
                                            let errMsg = e.target.value.state.length > 0 ? null : SELECT_STATE
                                            setStateDropdownItem(e.target.value)
                                            setCityDropdownItem({
                                                "cityId": -1,
                                                "city": "-- Select city --",
                                            })
                                            handledropdownCity(e.target.value.stateID, "")
                                            setErrState(errMsg)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* Fourth Row */}

                        <Row>

                            <Col>
                                <div className='position-relative'>
                                    {errCity && <div className="validation-message">{errCity}</div>}
                                    <RSDropdownList
                                        className="required"
                                        textField="city"
                                        dataItemKey="cityId"
                                        data={cityDropdownData}
                                        value={cityDropdownItem}
                                        defaultItem={{
                                            "cityId": -1,
                                            "city": "-- Select city --",
                                        }}
                                        onChange={(e) => {
                                            let errMsg = e.target.value.city.length > 0 ? null : SELECT_CITY
                                            setCityDropdownItem(e.target.value)
                                            setErrCity(errMsg)
                                        }}
                                    />
                                </div>

                            </Col>
                            <Col>
                                <RSInput2
                                    name="zipcode"
                                    ph={ENTER_ZIP}
                                    val={zipCode}
                                    required={true}
                                    max={16}
                                    errorMessage={errZipCode}
                                    cb={text => {
                                        if (text === "") {
                                            setZipCode("");
                                            return
                                        }
                                        var regex = /^[a-zA-Z0-9]+$/;
                                        if (text !== "" && regex.test(text)) {
                                            let errMsg = text.length > 0 ? null : ENTER_ZIP
                                            setZipCode(text);
                                            setErrZipCode(errMsg)
                                        } else {
                                            setErrZipCode("Only alphanumeric allowed")
                                        }
                                    }}
                                    ob={(text) => {
                                        if (text === "") {
                                            setZipCode("");
                                            setErrZipCode(ENTER_ZIP);
                                            return
                                        }
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="position-relative">
                                    {errIndustryType && <div className="validation-message">{errIndustryType}</div>}
                                    <RSDropdownList
                                        textField='industryName'
                                        dataItemKey='industryId'
                                        className={"required"}
                                        disabled={true}
                                        data={industryDropdownData}
                                        value={industryDropdownItem}
                                        defaultItem={
                                            {
                                                industryId: 0,
                                                industryName: '-- Industry --'
                                            }
                                        }
                                        onChange={(e) => {
                                            let errMsg = e.target.value.industryId !== 0 ? null : SELECT_INDUSTRY
                                            setErrIndustryType(errMsg)
                                            setIndustryDropdownItem(e.target.value)
                                        }} />
                                </div>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="position-relative">
                                            {errBusinessType && <div className="validation-message">{errBusinessType}</div>}
                                            <RSDropdownList
                                                textField='businessType'
                                                dataItemKey='businessTypeId'
                                                className={"required"}
                                                disabled={true}
                                                data={businessTypeDropdownData}
                                                value={businessTypeDropdownItem}
                                                defaultItem={
                                                    {
                                                        businessTypeId: 0,
                                                        businessType: '-- Business type --'
                                                    }
                                                }
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.businessTypeId !== 0 ? null : SELECT_BUSINESS_TYPE
                                                    setErrBusinessType(errMsg);
                                                    setBusinessTypeDropdownItem(e.target.value)
                                                    let master = getLsMasterDataDropdown();
                                                    const brandPosition = master.brandPosition.filter(item => {
                                                        return item.clienttype === e.target.value.businessTypeId
                                                    })
                                                    setBrandPositionData(brandPosition);
                                                    setBrandPositionItem({
                                                        brandPositionId: 0,
                                                        brandPositionName: '-- Brand positioning --'
                                                    })

                                                }} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="position-relative">
                                            {errBrandPosition && <div className="validation-message">{errBrandPosition}</div>}
                                            <RSDropdownList
                                                textField='brandPositionName'
                                                dataItemKey='brandPositionId'
                                                className={"required"}
                                                disabled={true}
                                                data={brandPositionData}
                                                value={brandPositionItem}
                                                defaultItem={
                                                    {
                                                        brandPositionId: 0,
                                                        brandPositionName: '-- Brand positioning --'
                                                    }
                                                }
                                                onChange={(e) => {
                                                    let errMsg = e.target.value.brandPositionId !== 0 ? null : SELECT_BRAND_POSITION
                                                    setErrBrandPossition(errMsg);
                                                    setBrandPositionItem(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="btn-container d-flex justify-content-end mt0">
                    <RSSecondaryBtn onClick={() => {
                        history.push(`/preferences`)
                    }}>Cancel</RSSecondaryBtn>

                    <RSPrimaryBtn onClick={() => {

                        if (context.globalStateData.getIsPrototype) {
                            props.setIndex(props.index + 1)
                            return
                        }
                        validateState();
                    }}>Save</RSPrimaryBtn>
                </div>
            </div>
        </div>
    )
}
export default CompanyDetails;