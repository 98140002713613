import React, { useState } from "react";
import SMTPCreation from "./SMTPCreation";
import SMTPList from "./SMTPList";

const SMTP = () => {
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("add");
  const [smtpId, setSmtpId] = useState("");

  const renderComponent = [
    <SMTPList
      onChangeIndex={(index) => setIndex(index)}
      onChangeMode={(mode) => setMode(mode)}
      getSmtpId={(smtpId) => setSmtpId(smtpId)}
    />,
    <SMTPCreation
      onChangeIndex={(index) => setIndex(index)}
      smtpId={smtpId}
      mode={mode}
    />,
  ];

  return <>{renderComponent[index]}</>;
};
export default SMTP;
