// CHART
export const chartColorCode         = "#6e6e6e";
export const chartColor             = '#424242'; // Chart data label color
export const titleColor             = '#424242'; // X-axis & Y-axis label color
export const labelColor             = '#424242'; // X-axis & Y-axis series color
export const legendColor            = '#424242'; // Legend color
export const tooltipColor           = '#fefefe'; // Tooltip text color
export const tooltipBgColor         = '#424242'; // Tooltip background color - old #5f5f5f

// COLORS
export const blueColor              = '#2896f0';
export const blueLightColor         = '#33b5e6';
export const greenColor             = '#99cc03';
export const greenLightColor        = '#b3d942';
export const yellowColor            = '#e7ca60';
export const orangeColor            = '#fd8f40';
export const cyanColor              = '#40abaf';
export const greyLightColor         = '#e9e9eb';
export const greyDarkColor          = '#585858';
export const othersColor            = "#fe5758";

//STATUS
export const draftColor             = '#b8b8b8';
export const completed         = '#5ba529';
export const scheduled         = '#fc6a00';
export const inprogress        = '#009bdf';
export const alert             = '#dfb82b';
export const multiStatus       = '#c43896';

// WEEK
export const sunday            = "#ff7800";
export const monday            = "#0048ff";
export const tuesday           = "#ff0005";
export const wednesday         = "#46c029";
export const thursday          = "#f5ec00";
export const friday            = "#a9bde4";
export const saturday          = "#5b5b5b";

// DAYS
export const earlyMorningColor      = "#ff7800";
export const morningColor           = "#a9bde4";
export const afternoonColor         = "#5b5b5b"; // 98
export const eveningColor           = "#46c029";
export const nightColor             = "#ff0005";

// CHANNEL
export const emailColor             = '#fc6a00';
export const smsColor               = '#dfb82b';
export const webPushColor           = '#5ba529';
export const mobilePushColor        = '#99cc03';
export const vmsColor               = '#008489';
export const callCenterColor        = '#33b5e6';
export const whatsappColor          = '#00d954';
export const qrColor                = '#d4d6de';
export const directMailColor        = '#fd8f40';
export const paidMediaColor         = '#40abaf';
export const twitterColor           = '#1d9bf0';
export const facebookColor          = '#1877f2';
export const linkedinColor          = '#2967ad';
export const instaColor             = '#c4219c';
export const pinterestColor         = '#e60023';
export const notificationColor      = '#f05455';
export const googleColor            = '#bc2f20';

// KNOWN UNKNOWN
export const knownColor             = '#99cc03'; // '#c6401d';
export const unKnownColor           = '#fd8f40';
export const identifiedColor        = '#274da3'; // '#34c382';
export const convertedColor         = '#3568c6';

// LANGUAGE
export const englishColor           = '#99cc03';
export const spanishColor           = '#2896f0';

// OS VERSION
export const android           = "#4885ed";
export const iosColor               = "#99cc03";
export const macColor               = "#008f94";
export const windowsColor           = "#33b5e6";
export const googlePlaystoreColor   = "#4885ed";
export const youtubeWatchpageColor  = "#e62117";
export const televisionColor        = "#2896f0";
export const fullyConversedColor    = "#ff6900"; // NOT USE

// DEVICE
export const desktopColor           = "#33b5e6";
export const androidPhoneColor      = "#A3FE2E";
export const androidTabColor        = "#aadd14";
export const iphoneColor            = "#40abaf";
export const ipadColor              = "#5856D6";
export const mobileColor            = '#99cc03';
export const samsungColor           = '#274da3';

// SENTIMENT
export const positiveColor          = "#5ba529";
export const neutral           = "#26ade0";
export const negative          = "#ff0005";

// OTHERS
export const clicksColor            = "#99cc03";
export const maybelaterColor        = "#2896f0";
export const dismissColor           = "#fe5758";

// CHART COMMON COLORS
export const chartColor01           = "#26ADE0";
export const chartColor02           = "#90C830";
export const chartColor03           = "#F58332";
export const chartColor04           = "#DDB219";
export const chartColor05           = "#F05455";
export const chartColor06           = "#35A8AD";
export const chartColor07           = "#D1569E";
export const chartColor08           = "#9B5FAA";
export const chartColor09           = "#B8B6B7";