import { Modal } from 'react-bootstrap';
import { RSSecondaryBtn, RSPrimaryBtn } from 'Components/RSButtons';
import React, { Component } from 'react';

class CSVTermsModal extends Component {
    state = {
        isShow: this.props.isOpen
    }
    render() {
        return (
            <>
                <Modal
                    show={this.state.isShow}
                    onHide={() => {
                        this.setState({
                            isShow: false
                        }, () => this.props.onChangeOpen(false));
                    }}
                    // backdrop="static"
                    keyboard={false}
                    size='full modal-adoc-popup'
                    centered>
                    <Modal.Header>
                        <h2>Upload list consideration:</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb25">
                            <ul className='adhoc-listpermit'>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>A maximum of 5 files are allowed per import. Each file size should not exceed 20 MB.</span>
                                </li>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>A total of 2 million audience records can be imported with a maximum of 25 attributes per list.</span>
                                </li>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>Multiple files upload is allowed only if column headers match across all the CSV files.</span>
                                </li>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>To avoid import failure, keep the column header format as EmailID & MobileNo for the respective columns.</span>
                                </li>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>It may take a maximum of 3 hours to process 1 million records.</span>
                                </li>
                                <li>
                                    <i className='icon-sd-arrow-right-mini icon-mini' />
                                    <span>The created list will expire permanently in 90 days.</span>
                                </li>
                            </ul>
                            <h3>
                                <i className='icon-sd-alert-mini icon-mini' />
                                <span> Presence of bad/spam trap email ids could potentially affect the sender domain reputation.</span>
                            </h3>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-container d-flex justify-content-end">
                            <RSSecondaryBtn onClick={() => this.props.onChangeOpen(false)}>Cancel</RSSecondaryBtn>
                            <RSPrimaryBtn className='bg-active' onClick={() => {
                                this.props.onChangeOpen(true)
                            }}>{"I agree & proceed"}</RSPrimaryBtn>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default CSVTermsModal;